import './App.css';
import { Routes, Route } from "react-router-dom";
import Index from './components';
import Calendar from './components/calendar';
import Rasiphalalu from './components/rasiphalalu';
import Panchangam from './components/panchagam';
import Bhavishyavani from './components/astrology/bhavishyavani';
import Grahalu from './components/astrology/grahalu';
import Sankyashastram from './components/astrology/sankyashastram';
import Vastuchitkalu from './components/astrology/vastuchitkalu';
import Pillishastram from './components/shakunashastram/pillishastram';
import Ballishastram from './components/shakunashastram/ballishastram';
import Villuvinasamacharam from './components/villuvinasamacharam/villuvinasamacharam';
import Samskrutham from './components/villuvinasamacharam/samskrutham';
import Samethalu from './components/villuvinasamacharam/samethalu';
import Nithipadhyalu from './components/villuvinasamacharam/nithipadhyalu';
import Nithyasathyalu from './components/villuvinasamacharam/nithyasathyalu';
import Rastralabashalu from './components/villuvinasamacharam/rastralabashalu';
import Grahanam from './components/villuvinasamacharam/grahanam';
import Sankyapradanyata from './components/etharamulu/sankyapradanyata';
import Telugusamskrutham from './components/etharamulu/telugusamskrutham';
import Namakaranam from './components/etharamulu/namakaranam';
import Upavasam from './components/bhakthiranjini/upavasam';
import Thirdayathra from './components/bhakthiranjini/thirdayathra';
import Slokalu from './components/bhakthiranjini/slokalu';
import Shotramlu from './components/bhakthiranjini/shotramlu';
import Ashtotharalu from './components/bhakthiranjini/ashtotharalu';
import Athyadhimikashakthi from './components/bhakthiranjini/athyadhimikashakthi';
import Muhurthalu from './components/nakshatrapontana/muhurthalu';
import Vivahanakshatralu from './components/nakshatrapontana/vivahanakshatralu';
import Dharmasandehalu from './components/hindudarmavaibavam/dharmasandehalu';
import Jeevanashaili from './components/hindudarmavaibavam/jeevanashaili';
import Masavishistatha from './components/hindudarmavaibavam/masavishistatha';
import Srustirahasyalu from './components/hindudarmavaibavam/srustirahasyalu';
import Hindudarmashastram from './components/hindudarmavaibavam/hindudarmashastram';
import Pandugapramukyata from './components/hindudarmavaibavam/pandugapramukyata';
import Mukhyamainasamacharam from './components/mukhyamainasamacharam';
import Astakalu from './components/astakalu';
import Karthikapuranam from './components/karthikapuranam';
import Pandugalu from './components/pandugalu';
import Panchangam1 from './components/etharamulu/panchangam1';
import Ramayanams from './components/ramayanam';
import Mahabharatam from './components/mahabharatham/mahabharatam';
import Adhiparvam from './components/mahabharatham/adhiparvam';
import Sabhaparvam from './components/mahabharatham/sabhaparvam';
import Vanaparvam from './components/mahabharatham/vanaparvam';
import Virataparvam from './components/mahabharatham/virataparvam';
import Vudhyogaparvam from './components/mahabharatham/vudhyogaparvam';
import Bhishmaparvam from './components/mahabharatham/bhishmaparvam';
import Dhronaparvam from './components/mahabharatham/dhronaparvam';
import Karnaparvam from './components/mahabharatham/karnaparvam';
import Salyaparvam from './components/mahabharatham/salyaparvam';
import Swothikaparvam from './components/mahabharatham/swothikaparvam';
import Sriparvam from './components/mahabharatham/sriparvam';
import Santhiparvam from './components/mahabharatham/santhiparvam';
import Anusasanikaparvam from './components/mahabharatham/anusasanikaparvam';
import Aswamedhaparvam from './components/mahabharatham/aswamedhaparvam';
import Asramavasaparvam from './components/mahabharatham/asramavasaparvam';
import Mousalaparvam from './components/mahabharatham/mousalaparvam';
import Mahaprasthanikaparvam from './components/mahabharatham/mahaprasthanikaparvam';
import Swargarohanaparvam from './components/mahabharatham/swargarohanaparvam';
import Krishnajananam from './components/bhagavathgita/srikrishunijivitham';
import Pandugavisistatha from './components/bhagavathgita/pandugavisistatha';
import Bhagavathgita from './components/bhagavathgita/bhagavathgita';
import Pandugaluu from './components/bhagavathgita/pandugalu';
import Srikrishnaastrotharalu from './components/bhagavathgita/srikrishnaastrotharalu';
import Srikrishnastakam from './components/bhagavathgita/srikrishnastakam';
import Sukthulu from './components/bhagavathgita/sukthulu';
import Vasthavalu from './components/bhagavathgita/vasthavalu';
import Geethasaramsam from './components/bhagavathgita/geethasaramsam'
import Panchagammain from './components/panchagammain';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { PanchangamData } from '../src/assets/jsons/panchangam1';
import SearchUI from './components/searchfile';

function App() {
  const location = useLocation();
    const [dayData, setDayData] = useState(null);
   const [dayDataMap, setDayDataMap] = useState('');
    const loadDayData = () => {
      const date = new Date();
      const year = date.getFullYear();
     
      const monthIndex = date.getMonth();
      const dayIndex = date.getDate() - 1;
  
      const yearData = PanchangamData.PanchangamUpdated[year];
  
      if (yearData && yearData[monthIndex] && yearData[monthIndex].day) {
        const monthData = yearData[monthIndex].day;
        if (monthData && monthData[dayIndex]) {
        const dayval = monthData[dayIndex]
         
          const dateParts = dayval.Date.split(', '); // Split into parts
          const formattedDate = `${dateParts[1]}, ${dateParts[2]}, ${dateParts[0]}`; // Reorder the parts
          setDayData(formattedDate);
          setDayDataMap(dayval)
  
        } else {
          setDayData(null);
        }
      } else {
        setDayData(null);
      }
    };
    useEffect(() => {
     
        if (location.pathname === '/Panchangam') {
            loadDayData();
        }
    }, [location.pathname]);
    
  const getPageTitle = () => {
      switch (location.pathname) {
          case '/Index':
              return {
                title:'Telugu Calendar Panchangam App | 2025 Holidays | Raasi Phalalu | Horoscope | iPhone & Android Apps',
                description:"Discover the comprehensive Telugu Panchangam featuring today's tithi (ఈరోజు తిథి పంచాంగం), daily horoscope (రాశి ఫలాలు), and astrology insights in Telugu. Explore Telugu Calendar updates with details on festivals, 2025 holidays, and auspicious timings. Access predictions on jathakam, graham positions, and raasi phalalu. Dive into spiritual texts like Ramayanam, Mahabharatham, and Bhagavad Geetha while enjoying the richness of Telugu culture through astrology and historical stories.",
                keywords:'telugu panchangam,panchangam today,panchangam telugu,ఈరోజు తిథి పంచాంగం,today panchangam in telugu,calendar panchangam,telugu panchangam calendar,తెలుగు క్యాలెండర్,horoscope,horoscope in telugu,rasi phalalu,రాశి ఫలాలు,daily,festivals,2025 holidays,jathakam,raasi,graham,typing,fm,Rashi Phalalu, Telugu Horoscope, Ramayanam, Mahabharatham, Bhagavad Geetha, Telugu Astrology',

            };
          case '/Panchangam':
                return {
                    title: dayData
                    ? `Telugu Calendar Panchangam App | ${dayData} | ${dayDataMap?.Masam}`
                    : 'Telugu Calendar Panchangam App | తెలుగు | పంచాంగం',
                    description:`ఈ రోజు పంచాంగం|Today telugu panchangam: ${dayDataMap?.Date} శ్రీ ${dayDataMap?.Shaka} నామ సంవత్సరం ${dayDataMap?.Ayanam} ${dayDataMap?.Masam} ${dayDataMap?.Ruthuvu} సూర్యోదయం: ${dayDataMap?.Sunrise} సూర్యాస్తమయం: ${dayDataMap?.Sunset}.`,
                    keywords:'panchangam,telugu panchangam,ఈరోజు తిథి పంచాంగం,Day Panchangam,Panchangam Today,Today’s Tithi,Today’s Tithi,Telugu Panchangam Today,Daily Panchangam,E Roju Panchangam (ఈరోజు పంచాంగం),Today Tithi in Telugu,Sunrise and Sunset Today,Nalla Neram Today,Panchangam Calendar,Today Panchangam in Telugu for Auspicious Events,Daily Panchangam with Telugu Tithi and Nakshatram,Telugu Panchangam Timings for Today’s Muhurtham,What is Today’s Panchangam?,Telugu Calendar Panchangam for Today’s Date,Accurate Daily Panchangam for Telugu Festivals,Panchangam Details for Today (Telugu),Telugu Astrology Daily Panchangam,ఈరోజు పంచాంగం,నక్షత్రం, తిథి ఈ రోజు,తెలుగు పంచాంగం డేట్స్,ముహూర్తం ఈ రోజు తెలుగు,పంచాంగం టైమింగ్స్ తెలుగులో',
                };
            
            
          case '/calendar':
             return {
                title:'Telugu Calendar Panchangam App | Calendar  | తెలుగు | క్యాలెండర్',
                description:'Explore the Telugu Calendar with detailed Panchangam, daily tithi, nakshatram, and muhurtham timings. Get updates on Telugu festivals,2025  holidays, and auspicious events. Access a complete Telugu Calendar for important dates and astrological insights.',
                keywords:"Telugu Calendar, Telugu Calendar, Panchangam, Daily Telugu Calendar, Calendar with Panchangam, Telugu Festivals, 2025 Telugu Holidays, Tithi, Nakshatram, Muhurtham, Telugu Panchangam Calendar, Auspicious Days,Calendar in Telugu"

             };
          case '/Rasi-Phalalu':
              return {
                title:'Telugu Calendar Panchangam App | RasiPhalalu  | తెలుగు | రాశి ఫలాలు',
                description:`శ్రీ ${dayDataMap?.Shaka} నామ సంవత్సరం ${dayDataMap?.Ayanam} ${dayDataMap?.Masam} ${dayDataMap?.Ruthuvu},${dayDataMap?.Thiti}, ${dayDataMap?.Nakshatra1}, అమృతకాలం :${dayDataMap?.Amrutakalam},దుర్ముహూర్తం:${dayDataMap?.DurMuhurtamulu}. Get accurate astrology predictions for all 12 zodiac signs (రాశి) based on the latest Panchangam insights. Stay updated with your daily horoscope, auspicious timings, and astrology forecasts for a better day ahead.`,
                keywords:"Rasi Phalalu, Telugu Horoscope, Daily Horoscope in Telugu, Telugu Astrology, Zodiac Predictions, Astrology Today, రాశి ఫలాలు, Telugu Zodiac, Rasi Phalalu Today, Astrology in Telugu",
              };
          case '/Upavasam':
              return {
                title: 'Telugu Calendar Panchangam App | Upavasam  | తెలుగు | ఉపవాసం',
                description:"Explore the significance of Upavasam (fasting) in the Telugu calendar. Learn about auspicious days for fasting, including spiritual practices, benefits, and related Panchangam details. Stay informed about fasting events and traditions in Telugu culture.",
                keywords:"Upavasam, Telugu Fasting Days, Telugu Calendar Fasting, Auspicious Fasting Days, Fasting in Telugu, Upavasam Dates, Telugu Panchangam, Fasting in Telugu Culture, Spiritual Practices, Upavasam Calendar, Telugu Festivals"};
            case '/Athyadhimika-shakthi':
                return {
                    title: 'Telugu Calendar Panchangam App | Athyadhimika Shakthi | తెలుగు | ఆథ్యాధిమిక శక్తి',
                    description: "Discover the powerful concepts of Athyadhimika Shakthi (ఆథ్యాధిమిక శక్తి) in Telugu culture. Learn about spiritual strength, rituals, and practices associated with this ancient concept. Explore its significance in daily life and its connection to astrology and Panchangam in Telugu.",
                    keywords: "Athyadhimika Shakthi, Spiritual Power, Telugu Astrology, Athyadhimika Shakti, Athyadhimika Shakti in Telugu, Astrological Strength, Spiritual Practices, Panchangam and Shakti, Telugu Culture, Ancient Telugu Rituals, Astro-Spiritual Power"
                };
                
          case '/Thidra-Yatra':
              return {
                title: 'Telugu Calendar Panchangam App | Pilgrimages | Thidra Yatra  | తెలుగు | తీర్థయాత్ర',
                description:"Explore the significance of Thidra Yatra (తీర్థయాత్ర) in Telugu culture. Learn about sacred pilgrimages, spiritual journeys, and the importance of visiting holy places as per the Telugu calendar. Discover auspicious dates and rituals associated with Thidra Yatra.",
                keywords:"Thidra Yatra, Telugu Pilgrimages, Sacred Journeys, Spiritual Yatra, Telugu Culture, Pilgrimage Dates, Thidra Yatra in Telugu, Sacred Places in Telugu Culture, Pilgrimage Rituals, Holy Places in Telugu, Telugu Calendar Yatra"
              };
          case '/Shotramlu':
              return {
                title:'Telugu Calendar Panchangam App | Shotramlu | తెలుగు | శోత్రములు',
                description:"Devatulu (దేవతలు), Rishulu (ఋషులు), and Guruvulu (గురువులు) have blessed us with powerful Shotramlu (స్తోత్రములు). Each stotram is filled with divine energy and, when recited with devotion, brings immense joy and spiritual fulfillment. This section provides a collection of sacred stotrams that, when recited with faith and precision, can lead to a deeper connection with the divine and a blissful experience. Learn and practice these stotrams to receive divine blessings and achieve peace of mind.",
                keywords:"Shotramlu, Telugu Stotrams, Sacred Mantras, Devotional Stotrams, Divine Blessings, Spiritual Chants, Stotrams in Telugu, Rishulu and Guruvulu Stotrams, Bhakti and Stotrams, Stotrams for Peace, Divine Bliss, Holy Stotrams"
            };
          case '/Muhurthalu':
              return {
                title:'Telugu Calendar Panchangam App | Muhurthalu | తెలుగు | ముహూర్తాలు',
                description:"Discover the significance of Muhurthalu (ముహూర్తాలు) in Telugu culture. Learn about the auspicious times for performing rituals, ceremonies, and important life events. Find the perfect Muhurtham for weddings, housewarming, and other celebrations based on the Telugu Panchangam.",
                keywords:"Muhurthalu, Telugu Muhurtham, Auspicious Times,వివాహ శుభ ముహూర్తాలు, Muhurtham for Weddings, గృహ ప్రవేశ ముహూర్తం,నామకరణ ముహూర్తాలు,Telugu Panchangam, Muhurtham Calendar, Life Events Muhurtham, Sacred Times in Telugu, Telugu Rituals, Muhurtham for Ceremonies, Telugu Festivals and Muhurtham"
            };
          case '/Vivaha-Nakshatralu':
              return {
                title: 'Telugu Calendar Panchangam App | Vivaha Nakshatralu | తెలుగు | వివాహ నక్షత్రాలు',
                description:"Explore the significance of Vivaha Nakshatralu (వివాహ నక్షత్రాలు) in Telugu culture. Learn about the most auspicious star constellations for performing weddings according to the Telugu Panchangam. Discover the best Nakshatras for starting new life journeys, ensuring marital happiness and prosperity.",
                keywords:"Vivaha Nakshatralu, Telugu Wedding Nakshatras, Wedding Stars, Auspicious Nakshatras for Weddings, Telugu Panchangam, Vivaha Nakshatra Timing, Marriage Nakshatra, Nakshatras for Wedding, Telugu Astrology, Wedding Astrology, Best Nakshatras for Weddings"};
          case '/Dharmasandehalu':
              return {
                title: 'Telugu Calendar Panchangam App | Dharmasandehalu | తెలుగు | ధర్మసందేహాలు',
                description:"Explore Dharmasandehalu (ధర్మసందేహాలు) in Telugu culture, where we address common doubts and questions related to Dharma (righteousness), spiritual practices, and religious rituals. Gain clarity on the ethical and moral aspects of life through the wisdom of Telugu traditions and Panchangam.",
                keywords:"Dharmasandehalu, Telugu Dharma Questions, Spiritual Doubts, Ethical Queries, Telugu Spirituality, Righteousness in Telugu Culture, Religious Practices, Dharma in Telugu, Hindu Ethics, Telugu Religious Rituals, Dharmic Guidance"
            };
          case '/Hindu-Darmashastram':
              return {
                title:'Telugu Calendar Panchangam App | Hindu Darmashastram | తెలుగు | హిందూ ధర్మశాస్త్రం',
                description:"Explore the principles and teachings of Hindu Dharma Shastram (హిందూ ధర్మశాస్త్రం) in Telugu. Learn about ancient scriptures, laws, and moral codes that guide righteous living, spiritual practices, and social conduct. Gain deeper insight into the ethical and philosophical foundations of Hinduism according to the Telugu traditions.",
                keywords:"Hindu Dharma Shastram, Telugu Dharma Shastra, Hindu Laws, Moral Codes in Hinduism, Hindu Ethics, Righteous Living, Dharma Shastram Telugu, Spiritual Teachings, Hindu Scriptures, Ethical Guidelines in Hinduism, Telugu Hindu Philosophy"
            };
          case '/Pandugapramukyata':
              return {
                title:'Telugu Calendar Panchangam App | Pandugapramukyata | తెలుగు | పండుగ ప్రాముఖ్యత',
                description:"Explore the significance of Pandugapramukyata (పండుగ ప్రాముఖ్యత) in Telugu culture. Learn about the importance of festivals, their spiritual meaning, and how they connect us to our traditions and customs. Discover the cultural and religious significance of Telugu festivals, as observed in the Telugu calendar.",
                keywords:"Pandugapramukyata, 2025 Telugu Festivals, Importance of Festivals, Telugu Culture and Festivals, Festival Significance, Spiritual Meaning of Festivals, Panduga Importance, Telugu Calendar Festivals, Religious Festivals in Telugu, Cultural Significance of Pandugas"
            };
          case '/Masa-Vishistatha':
               return {
                title:'Telugu Calendar Panchangam App | Masa Vishistatha | తెలుగు | మాస విశిష్టత',
                description:"Explore the unique significance of Masa Vishistatha (మాస విశిష్టత) in the 2025 Telugu calendar. Learn about the special qualities and spiritual importance of each month in the Telugu lunar calendar. Discover the festivals, rituals, and auspicious events associated with each month, guiding you through the year with cultural and religious insights.",
                keywords:"Masa Vishistatha, 2025 Telugu Calendar, Significance of Months, Special Qualities of Months, Telugu Lunar Calendar, Monthly Festivals in Telugu, Rituals for Each Month, Telugu Festivals and Rituals, Spiritual Significance of Months, Auspicious Events in Telugu Culture"
               };
          case '/Srusti-Rahasyalu':
                return {
                    title:  'Telugu Calendar Panchangam App | Srusti Rahasyalu | తెలుగు | సృష్టి రహస్యాలు',
                    description:"Explore the mysteries of creation (Srusti Rahasyalu - సృష్టి రహస్యాలు) in Telugu culture. Unveil the divine secrets of the universe, cosmology, and the origins of life as described in ancient scriptures. Understand the spiritual and philosophical teachings about the creation of the world and its deeper meanings in Hinduism.",
                    keywords:"Srusti Rahasyalu, Creation Secrets, Telugu Cosmology, Divine Secrets of Universe, Hindu Creation Stories, Spiritual Meaning of Creation, Creation Mysteries, Telugu Hindu Philosophy, Origins of Life, Hindu Scriptures, Telugu Cosmological Teachings"
                };
          case '/Jeevanashaili':
              return {
                title:'Telugu Calendar Panchangam App | Jeevanashaili | తెలుగు | జీవనశైలి',
                description:"Explore the concept of Jeevanashaili (జీవనశైలి) in Telugu culture, which focuses on lifestyle, well-being, and balanced living. Learn about the principles of leading a healthy, spiritually fulfilling life through proper diet, routines, values, and practices. Gain insights into holistic living inspired by Telugu traditions and philosophies.",
                keywords:"Jeevanashaili, Telugu Lifestyle, Healthy Living in Telugu, Holistic Lifestyle, Spiritual Lifestyle, Balanced Living, Telugu Culture and Lifestyle, Well-being Practices, Jeevanashaili in Telugu, Traditional Telugu Living, Spiritual Health"

              };
          case '/Sankya-Shastram':
              return {
                title:'Telugu Calendar Panchangam App | Sankya Shastram | తెలుగు | సంఖ్యా శాస్త్రం',
                description:"Explore the ancient science of Sankya Shastram (సంఖ్యా శాస్త్రం) in Telugu culture, which focuses on the study of numbers, their cosmic significance, and their influence on life and the universe. Learn how Sankya Shastram integrates with astrology, numerology, and spiritual practices to bring harmony and understanding to daily life.",
                keywords:"Sankya Shastram, Telugu Numerology, Numbers in Telugu Culture, Cosmic Significance of Numbers, Numerology in Telugu, Sankya Shastram in Telugu, Spiritual Numerology, Astrological Influence of Numbers, Ancient Indian Sciences, Telugu Astrology and Numbers"
              };
          case '/Grahalu':
              return {
                title: 'Telugu Calendar Panchangam App | Grahalu | తెలుగు | గ్రహాలు',
                description:"Discover the significance of Grahalu (గ్రహాలు) in Telugu astrology. Learn about the influence of planets and celestial bodies on human life, behavior, and destiny. Explore how Grahalu shape daily events, relationships, and spiritual growth according to the Telugu Panchangam and Vedic astrology.",
                keywords:"Grahalu, Telugu Planets, Planetary Influence, Vedic Astrology, Telugu Panchangam, Celestial Bodies in Astrology, Astrology in Telugu, Grahalu and Destiny, Planetary Movements, Spiritual Growth through Grahalu, Grahalu in Telugu Culture"
              };
          case '/Bhavishyavani':
              return{
                title: 'Telugu Calendar Panchangam App | Bhavishyavani | తెలుగు | భవిష్యవాణి',
                description:"Explore the ancient art of Bhavishyavani (భవిష్యవాణి) in Telugu culture, where astrology and spiritual insights predict future events and life patterns. Learn how Telugu astrology provides guidance on personal, professional, and spiritual aspects of life, helping you navigate your future with wisdom and clarity.",
                keywords:"Bhavishyavani, Telugu Astrology, Future Predictions, Astrology in Telugu, Life Predictions, Bhavishyavani in Telugu, Vedic Astrology, Future Guidance, Personal Horoscope, Professional Predictions, Spiritual Insights, Telugu Panchangam",
            };
          case '/Vasthu-Chitkalu':
              return {
                title: 'Telugu Calendar Panchangam App | Vasthu Chitkalu | తెలుగు | వాస్తు చిట్కాలు',
                description:"Explore the principles of Vasthu Chitkalu (వాస్తు చిట్కాలు) in Telugu culture, which offers practical tips and guidelines for creating harmony and balance in living spaces. Learn how Vasthu Shastra, the ancient science of architecture, can influence health, prosperity, and happiness through proper spatial arrangements.",
                keywords:"Vasthu Chitkalu, Telugu Vasthu Tips, Vasthu Shastra, Vastu Shastra in Telugu, Vastu Tips for Homes, Harmony in Living Spaces, Vasthu Guidelines, Positive Energy in Telugu Homes, Vasthu and Prosperity, Vasthu Shastra for Happiness"
              };
          case '/Balli-Shastram':
              return {
                title: 'Telugu Calendar Panchangam App | Balli Shastram | తెలుగు | బల్లి శాస్త్రం',
                description:"Discover the ancient practices of Balli Shastram (బల్లి శాస్త్రం) in Telugu culture, a unique science that deals with the art of balancing energies and aligning them with nature. Learn how these traditional practices help in maintaining physical, mental, and spiritual well-being, as well as enhancing positive energies around you.",
                keywords:"Balli Shastram, Telugu Balli Shastra, Balancing Energies, Energy Alignment, Traditional Practices in Telugu, Spiritual Well-being, Positive Energy Practices, Vastu and Balli Shastra, Balli Shastram for Health, Balli Shastram Techniques"
              };
          case '/Pillishastram':
              return {
                title:'Telugu Calendar Panchangam App | Pillishastram | తెలుగు | పిల్లిశాస్త్రం',
                description:"Explore the ancient practice of Pillishastram (పిల్లిశాస్త్రం) in Telugu culture, a traditional science that focuses on the study and practices related to children and their well-being. Learn about rituals, astrological influences, and methods to ensure the physical, mental, and spiritual health of children according to Telugu customs.",
                keywords:"Pillishastram, Telugu Pillishastra, Child Well-being, Rituals for Children, Telugu Traditions for Children, Child Health in Telugu Culture, Pillishastra and Astrology, Spiritual Guidance for Children, Children and Astrological Influence, Telugu Rituals for Kids"
              };
          case '/Villuvina-Samacharam':
              return {
                title: 'Telugu Calendar Panchangam App | Villuvina Samacharam | తెలుగు | విలువైన సమాచారం',
                description:"Discover Villuvina Samacharam (విలువైన సమాచారం) in Telugu culture, a collection of valuable information, insights, and knowledge. Learn about various important topics, including spiritual wisdom, cultural practices, health tips, and other aspects that enrich life and help make informed decisions.",
                keywords:"Villuvina Samacharam, Valuable Information, Telugu Knowledge, Important Information in Telugu, Life Insights, Cultural Wisdom, Spiritual Knowledge, Health Tips in Telugu, Telugu Culture, Valuable Resources in Telugu"
              };
          case '/villuvina-Samacharam':
                return {
                    title: 'Telugu Calendar Panchangam App | Villuvina Samacharam | తెలుగు | విలువైన సమాచారం',
                    description:"Discover Villuvina Samacharam (విలువైన సమాచారం) in Telugu culture, a collection of valuable information, insights, and knowledge. Learn about various important topics, including spiritual wisdom, cultural practices, health tips, and other aspects that enrich life and help make informed decisions.",
                    keywords:"Villuvina Samacharam, Valuable Information, Telugu Knowledge, Important Information in Telugu, Life Insights, Cultural Wisdom, Spiritual Knowledge, Health Tips in Telugu, Telugu Culture, Valuable Resources in Telugu"
                  };
  
          case '/Samskrutham':
                return {
                    title: 'Telugu Calendar Panchangam App | Samskrutham | తెలుగు | సంస్కృతం',
                    description:"Explore Samskrutham (సంస్కృతం), the ancient language that holds the roots of many sacred texts and spiritual teachings in Telugu culture. Learn about the significance of Sanskrit in Hindu rituals, Vedic scriptures, and its role in preserving the rich traditions and philosophies of India.",
                    keywords:"Samskrutham, Sanskrit Language, Telugu Samskrutham, Vedic Sanskrit, Sanskrit in Telugu Culture, Hindu Rituals in Sanskrit, Ancient Indian Language, Spiritual Teachings in Sanskrit, Sanskrit Scriptures, Sanskrit in Telugu Traditions"
                };
          case '/Samethalu':
                return {
                    title: 'Telugu Calendar Panchangam App | Proverbs | Samethalu | తెలుగు | సామెతలు',
                    description:"Explore the wisdom of Samethalu (సామెతలు), traditional Telugu proverbs that offer valuable insights into life, relationships, and ethics. Learn how these age-old sayings, passed down through generations, provide guidance and reflect the cultural values and wisdom of Telugu-speaking communities.",
                    keywords:"Samethalu, Telugu Proverbs, Wisdom in Telugu, Telugu Culture, Life Lessons in Telugu, Traditional Sayings, Telugu Ethics, Cultural Wisdom, Proverbs for Life, Telugu Idioms, Ethical Teachings in Telugu"
                };
          case '/Rastrala-Bashalu':
                return {
                    title: 'Telugu Calendar Panchangam App | Rastra Bashalu | తెలుగు | రాష్ట్ర భాషలు',
                    description:"Discover the diversity of Rastra Bashalu (రాష్ట్ర భాషలు), the official languages spoken across India. Explore the rich linguistic heritage of different states, learn about their cultural significance, and understand how each regional language contributes to the country's unity in diversity.",
                    keywords:"Rastra Bashalu, Indian Languages, Official State Languages, Telugu Language, Regional Languages of India, Language Diversity in India, State Languages, Telugu Culture and Language, Indian Linguistic Heritage, Unity in Diversity"
                };
          case '/Nithya-Sathyalu':
                return {
                    title:'Telugu Calendar Panchangam App | Nithya Sathyalu | తెలుగు | నిత్య సత్యలు',
                    description:"Explore Nithya Sathyalu (నిత్య సత్యలు), timeless truths and philosophical principles that guide the way of life in Telugu culture. Learn about the eternal wisdom that helps us live with integrity, harmony, and spiritual growth. Discover how these daily truths offer clarity and direction in our personal and spiritual journeys.",
                    keywords:"Nithya Sathyalu, Eternal Truths, Telugu Philosophy, Spiritual Wisdom, Telugu Culture, Life Principles, Daily Truths, Timeless Teachings, Integrity in Life, Spiritual Growth in Telugu, Telugu Life Principles"
                };
          case '/Nithi-Padhyalu':
                  return {
                    title:'Telugu Calendar Panchangam App | Nithi Padhyalu | తెలుగు | నీతి పద్యాలు',
                    description:"Discover Nithi Padhyalu (నీతి పద్యాలు), a collection of moral and ethical verses in Telugu literature. These verses provide valuable lessons on integrity, righteousness, and wisdom, helping individuals lead virtuous lives. Learn about the significance of these teachings in shaping character and guiding daily actions.",
                    keywords:"Nithi Padhyalu, Telugu Ethics, Moral Verses, Telugu Literature, Virtuous Living, Ethical Teachings in Telugu, Life Lessons in Telugu, Wisdom in Telugu, Righteousness and Integrity, Telugu Proverbs, Nithi in Telugu Culture"
                };
          case '/Grahanam':
                return {
                    title:'Telugu Calendar Panchangam App | Eclipses | తెలుగు | గ్రహణం',
                    description:"Learn about Grahanam (గ్రహణం), the celestial phenomenon of eclipses, and its significance in Telugu culture and astrology. Explore the spiritual, cultural, and scientific aspects of solar and lunar eclipses, including their impact on daily life, rituals, and astrological beliefs in Telugu tradition.",
                    keywords:"Grahanam, Eclipses in Telugu, Solar Eclipse, Lunar Eclipse, Telugu Astrology, Grahanam in Telugu Culture, Eclipses and Rituals, Spiritual Significance of Eclipses, Grahanam Effects, Telugu Panchangam, Solar and Lunar Eclipses in Astrology"

                };
          case '/Telugu-Samskrutham':
                return {
                    title:'Telugu Calendar Panchangam App | Telugu Samskrutham | తెలుగు | తెలుగు సంస్కృతం',
                    description:"Explore the rich heritage of Telugu Samskrutham (తెలుగు సంస్కృతం), the classical art and culture of the Telugu-speaking community. Learn about the traditions, rituals, literature, and philosophies that have shaped the identity of Telugu culture, and understand their significance in preserving language, arts, and spiritual practices.",
                    keywords:"Telugu Samskrutham, Telugu Culture, Telugu Traditions, Telugu Language Heritage, Classical Telugu Art, Telugu Literature, Spiritual Practices in Telugu, Telugu Philosophy, Telugu Rituals, Telugu Identity, Telugu History" };
          case '/Sankya-Pradanyata':
                return {
                    title:'Telugu Calendar Panchangam App | Sankya Pradanyata | తెలుగు | సంఖ్య ప్రాధాన్యత',
                    description:"Explore the significance of Sankya Pradanyata (సంఖ్య ప్రాధాన్యత) in Telugu culture, where numbers and their symbolism play a key role in various spiritual and cultural practices. Learn about the importance of numbers in astrology, rituals, and their connection to cosmic energies, shaping our understanding of life and the universe.",
                    keywords:"Sankya Pradanyata, Significance of Numbers, Telugu Numerology, Cosmic Numbers, Sankya in Telugu Culture, Number Symbolism, Astrological Significance of Numbers, Numerology in Telugu, Sankya and Spirituality, Telugu Rituals and Numbers"
                };
          case '/Mukhyamainasamacharam':
                return {
                    title: 'Telugu Calendar Panchangam App | Mukhyamainasamacharam | తెలుగు | ముఖ్యమైనసమాచారం',
                    description:"Discover Mukhyamainasamacharam (ముఖ్యమైన సమాచారం), a collection of important and essential information that helps guide daily life and decision-making in Telugu culture. Learn about significant events, rituals, and spiritual teachings that offer wisdom and direction in various aspects of life.",
                    keywords:"Mukhyamainasamacharam, Important Information, Essential Knowledge in Telugu, Telugu Culture, Significant Events, Spiritual Guidance, Daily Life Wisdom, Key Rituals in Telugu, Life Lessons in Telugu, Telugu Traditions"
                };
          case '/Astakalu':
                return {
                    title:'Telugu Calendar Panchangam App | Astakalu | తెలుగు | అష్టకాలు',
                    description:"Learn about Astakalu (అష్టకాలు), a sacred concept in Telugu culture that refers to the eight essential elements or principles that guide spiritual practices and rituals. Understand their significance in daily life, religious observances, and how they help maintain harmony and balance in both physical and spiritual realms.",
                    keywords:"Astakalu, Eight Principles, Telugu Spiritual Practices, Sacred Elements in Telugu, Astakalu in Rituals, Telugu Culture, Spiritual Balance, Religious Observances, Hindu Rituals, Telugu Traditions, Astakalu and Harmony"
                };
          case '/Karthikapuranam':
               return{ 
                title: 'Telugu Calendar Panchangam App | Karthikapuranam | తెలుగు | కార్తీకపురాణం',
                description:"Explore the sacred Karthikapuranam (కార్తీకపురాణం), an important text in Hindu mythology that narrates the stories of Lord Kartikeya, the significance of the Karthika month, and its spiritual importance. Learn about the rituals, festivals, and teachings associated with Karthikapuranam in Telugu culture.",
                keywords:"Karthikapuranam, Lord Kartikeya, Karthika Month, Hindu Mythology, Telugu Culture, Karthika Festival, Spiritual Teachings, Puranic Stories, Telugu Rituals, Kartikeya Worship, Karthika Puranam Significance"
               };
          case '/Namakaranam':
               return {
                title: 'Telugu Calendar Panchangam App | Namakaranam | తెలుగు | నామకరణం',
                description:"Discover the significance of Namakaranam (నామకరణం), the sacred naming ceremony in Telugu culture. Learn about the customs, rituals, and spiritual importance of naming a child, and how it reflects the family’s cultural values, beliefs, and connection to divine blessings.",
                keywords:"Namakaranam, Naming Ceremony, Telugu Traditions, Sacred Rituals, Child Naming in Telugu, Spiritual Significance of Namakaranam, Telugu Culture, Hindu Naming Ceremonies, Family Traditions, Divine Blessings in Telugu Culture"

               };
          case '/Pandugalu':
                return {
                    title: 'Telugu Calendar Panchangam App | 2025 festivals | తెలుగు | పండుగలు',
                    description:"Explore Pandugalu (పండుగలు), the vibrant festivals celebrated in Telugu culture. Learn about the spiritual, cultural, and historical significance of major festivals like Sankranti, Ugadi, Deepavali, and more. Discover the rituals, customs, and festivities that bring communities together in celebration.",
                    keywords:"Pandugalu, Telugu Festivals, Sankranti, Ugadi, Deepavali, Telugu Culture, Festival Rituals, Cultural Significance of Festivals, Telugu Traditions, Hindu Festivals, Celebrations in Telugu Culture, Major Festivals in Telugu"
                };
          case '/Telugu-Panchangam':
                return {
                    title: 'Telugu Calendar Panchangam App | Telugu Panchangam | తెలుగు | తెలుగు పంచాంగం',
                    description:"Discover the Telugu Panchangam (తెలుగు పంచాంగం), an essential astrological guide that provides detailed information on dates, times, festivals, and daily predictions based on the traditional lunar calendar. Explore the significance of Nakshatras, Tithis, and other celestial factors, helping you align your life with the cosmic rhythms.",
                    keywords:"Telugu Panchangam, Panchangam 2025, Telugu Calendar, Nakshatras, Tithis, Hindu Astrology, Telugu Astrological Calendar, Panchangam Predictions, Festivals in Telugu, Panchangam Daily Guide, Telugu Panchangam App"
                };
          case '/Ramayanam':
                return {
                    title: 'Telugu Calendar Panchangam App | Ramayanam | తెలుగు | రామాయణం',
                    description:"Explore the sacred Ramayanam (రామాయణం), an ancient Hindu epic that narrates the story of Lord Rama, his virtues, his exile, and his battle against the demon king Ravana. Learn about the moral lessons, spiritual teachings, and cultural significance of Ramayanam in Telugu tradition, highlighting its impact on religion, rituals, and daily life.",
                    keywords:"Ramayanam, Lord Rama, Hindu Epic, Telugu Ramayanam, Ramayanam in Telugu, Spiritual Teachings, Epic Stories, Ravana, Moral Lessons, Hindu Rituals, Telugu Culture, Ramayanam Significance, Rama's Story"
                };
          case '/Mahabharatam':
              return {
                title: 'Telugu Calendar Panchangam App | Mahabharatam | తెలుగు | మహాభారతం',
                description:"Discover the Mahabharatam (మహాభారతం), one of the greatest epics of Hindu mythology, which chronicles the story of the Pandavas, Kauravas, and the battle of Kurukshetra. Learn about the profound spiritual lessons, philosophical teachings, and historical events that shape the core of Hindu values and influence Telugu culture.",
                keywords:"Mahabharatam, Hindu Epic, Pandavas, Kurukshetra Battle, Telugu Mahabharatam, Spiritual Teachings, Hindu Philosophy, Epic Stories, Kauravas, Bhagavad Gita, Mahabharata Lessons, Hindu Culture, Telugu Traditions"
              };
          case '/Adhi-Parvam':
                return {
                    title: 'Telugu Calendar Panchangam App | Adi Parvam | తెలుగు | ఆది పర్వం',
                    description:"Explore Adi Parvam (ఆది పర్వం), the first book of the Mahabharatam, which sets the stage for the epic saga. Learn about the origins of the Kuru dynasty, the lineage of the Pandavas and Kauravas, and the philosophical and historical events that lead to the great battle of Kurukshetra. Delve into its profound teachings and cultural significance in Telugu tradition.",
                    keywords:"Adi Parvam, Mahabharatam, Kuru Dynasty, Pandavas, Kauravas, Mahabharata Origins, Telugu Adi Parvam, Hindu Epic Stories, Mahabharata Teachings, Historical Events, Epic Literature, Kurukshetra Prelude, Telugu Culture"
                };
          case '/Sabha-Parvam':
                return {
                 title:'Telugu Calendar Panchangam App | Sabha Parvam | తెలుగు | సభ పర్వం',
                description:"Discover Sabha Parvam (సభ పర్వం), the second book of the Mahabharatam, which narrates the events surrounding the royal court of Hastinapura. Learn about Yudhishthira’s Rajasuya Yagna, the game of dice, and the humiliation of Draupadi. Explore its profound moral lessons, political insights, and cultural significance in Telugu tradition.",
                keywords:"Sabha Parvam, Mahabharatam, Game of Dice, Draupadi Humiliation, Rajasuya Yagna, Telugu Sabha Parvam, Mahabharata Stories, Political Insights, Moral Lessons, Telugu Epic Literature, Hastinapura Court, Kauravas, Pandavas"
            };
          case '/Vana-Parvam':
                return {
                    title:'Telugu Calendar Panchangam App | Vana Parvam | తెలుగు | వన పర్వం',
                    description:"Explore Vana Parvam (వన పర్వం), the third book of the Mahabharatam, which recounts the exile of the Pandavas to the forest for 13 years. Discover the trials, spiritual teachings, and significant encounters that define their journey, including the story of Arjuna’s penance, Draupadi’s resilience, and their quest for wisdom and strength.",
                    keywords:"Vana Parvam, Mahabharatam, Pandavas Exile, Arjuna’s Penance, Draupadi’s Resilience, Forest Stories, Telugu Vana Parvam, Mahabharata Teachings, Hindu Epics, Spiritual Wisdom, Epic Literature, Pandavas Journey, Telugu Culture"
                };
          case '/Virata-Parvam':
                return {
                    title: 'Telugu Calendar Panchangam App | Virata Parvam | తెలుగు | విరాట పర్వం',
                    description:"Discover Virata Parvam (విరాట పర్వం), the fourth book of the Mahabharatam, which narrates the Pandavas’ incognito exile in the kingdom of Virata. Learn about their strategic disguises, significant events, and the challenges they faced while hiding their true identities. Explore the cultural and moral lessons embedded in this critical phase of their journey.",
                    keywords:"Virata Parvam, Mahabharatam, Pandavas Incognito, Kingdom of Virata, Telugu Virata Parvam, Epic Stories, Mahabharata Lessons, Hindu Epics, Pandavas Challenges, Cultural Teachings, Disguises of Pandavas, Epic Literature, Telugu Culture"
                };
          case '/Vudhyoga-Parvam':
                return {
                    title: 'Telugu Calendar Panchangam App | Vudhyoga Parvam | తెలుగు | ఉద్యోగ పర్వం',
                    description:"Explore Udyoga Parvam (ఉద్యోగ పర్వం), the fifth book of the Mahabharatam, which details the preparations for the great Kurukshetra war. Discover the intense negotiations for peace, Krishna’s diplomatic mission, and the moral dilemmas faced by the Pandavas and Kauravas. Dive into the profound teachings and the turning points that shape this epic conflict.",
                    keywords:"Udyoga Parvam, Mahabharatam, Kurukshetra War Preparations, Krishna’s Mission, Pandavas vs Kauravas, Telugu Udyoga Parvam, Peace Negotiations, Mahabharata Stories, Epic Literature, Hindu Epics, Telugu Culture, Pandavas Challenges, Moral Dilemmas"
                };
          case '/Bhishma-Parvam':
                return {
                    title:'Telugu Calendar Panchangam App | Bhishma Parvam | తెలుగు | భీష్మ పర్వం',
                    description:"Delve into Bhishma Parvam (భీష్మ పర్వం), the sixth book of the Mahabharatam, which narrates the beginning of the Kurukshetra war. Discover Bhishma’s leadership on the battlefield, Krishna’s role as Arjuna’s charioteer, and the profound lessons of the Bhagavad Gita. Explore the strategies, valor, and spiritual teachings that define this pivotal chapter in Telugu tradition.",
                    keywords:"Bhishma Parvam, Mahabharatam, Kurukshetra War, Bhagavad Gita, Bhishma’s Leadership, Krishna and Arjuna, Telugu Bhishma Parvam, Hindu Epics, Mahabharata Stories, Epic Literature, Telugu Culture, Spiritual Teachings, Pandavas vs Kauravas" };
          case '/Dhrona-Parvam':
              return {
                title:'Telugu Calendar Panchangam App | Dhrona Parvam | తెలుగు | ద్రోణ పర్వం',
                description:"Discover Drona Parvam (ద్రోణ పర్వం), the seventh book of the Mahabharatam, which narrates the appointment of Dronacharya as the commander of the Kaurava army. Learn about the fierce battles, the tragic deaths of Abhimanyu and Jayadratha, and the strategies employed by both sides. Explore the heroism, sacrifice, and moral complexities of this critical chapter in the epic.",
                keywords:"Drona Parvam, Mahabharatam, Dronacharya Leadership, Abhimanyu’s Death, Jayadratha’s Death, Kurukshetra War, Telugu Drona Parvam, Mahabharata Stories, Epic Battles, Hindu Epics, Telugu Culture, Heroism in War, Pandavas and Kauravas, Epic Literature" };
          case '/Karna-Parvam':
                return {
                    title:'Telugu Calendar Panchangam App | Karna Parvam | తెలుగు | కర్ణ పర్వం',
                    description:"Explore Karna Parvam (కర్ణ పర్వం), the eighth book of the Mahabharatam, focusing on Karna’s rise as the commander of the Kaurava army. Witness the intense battles, Karna’s tragic confrontation with Arjuna, and the emotional revelations about his lineage. Dive into the themes of loyalty, destiny, and valor that make this chapter unforgettable in Telugu tradition.",
                    keywords:"Karna Parvam, Mahabharatam, Karna vs Arjuna, Kaurava Commander, Kurukshetra War, Telugu Karna Parvam, Mahabharata Stories, Epic Battles, Karna’s Tragedy, Loyalty and Valor, Hindu Epics, Telugu Culture, Karna’s Lineage, Epic Literature"
                };
          case '/Salya-Parvam':
              return {
                title: 'Telugu Calendar Panchangam App | Salya Parvam | తెలుగు | శల్య పర్వం',
                description:"Discover Salya Parvam (శల్య పర్వం), the ninth book of the Mahabharatam, detailing the appointment of King Salya as the commander of the Kaurava army. Explore the dramatic end of the Kurukshetra war, the fierce battles, and the fall of great warriors. This chapter reflects themes of determination, strategy, and the inevitability of fate in Telugu tradition.",
                keywords:"Salya Parvam, Mahabharatam, Kurukshetra War, King Salya, Kaurava Commander, Telugu Salya Parvam, Epic Battles, Hindu Epics, Mahabharata Stories, Telugu Culture, Warrior Ethics, Fall of Kauravas, Fate and Strategy, Epic Literature"
              };
          case '/Swothika-Parvam':
                return {
                    title:'Telugu Calendar Panchangam App | Swothika Parvam | తెలుగు | స్వోతిక పర్వం',
                    description:"Explore Swothika Parvam (స్వోతిక పర్వం), the eleventh book of the Mahabharatam, which reflects on the aftermath of the Kurukshetra war. Learn about the grief and mourning of the Kuru dynasty, the funeral rites for the fallen warriors, and the profound philosophical discussions about life, death, and dharma. This chapter embodies the essence of reflection and healing in Telugu tradition.",
                    keywords:"Swothika Parvam, Mahabharatam, Aftermath of Kurukshetra War, Telugu Swothika Parvam, Mourning and Grief, Kuru Dynasty, Hindu Epics, Funeral Rites, Life and Death Philosophy, Dharma Reflections, Mahabharata Stories, Telugu Culture, Post-War Analysis, Epic Literature"
                };
          case '/Sri-Parvam':
              return {
                title: 'Telugu Calendar Panchangam App | Sri Parvam | తెలుగు | శ్రీ పర్వం',
                description:"Discover Sri Parvam (శ్రీ పర్వం), an essential part of the Mahabharatam, that emphasizes the divine aspects and spiritual teachings associated with Lord Vishnu and other deities. Learn about the significance of Sri Parvam in promoting dharma, peace, and spiritual well-being. This chapter provides a deep understanding of the divine interventions and moral lessons in Telugu tradition.",
                keywords:"Sri Parvam, Mahabharatam, Lord Vishnu, Spiritual Teachings, Telugu Sri Parvam, Divine Interventions, Dharma and Peace, Hindu Epics, Telugu Culture, Moral Lessons, Mahabharata Stories, Vishnu Worship, Epic Literature"
              };
          case '/Santhi-Parvam':
              return {
                title:'Telugu Calendar Panchangam App | Santhi Parvam | తెలుగు | శాంతి పర్వం',
                description:"Explore Santhi Parvam (శాంతి పర్వం), the peaceful and philosophical segment of the Mahabharatam, which emphasizes the importance of peace, reconciliation, and the restoration of harmony after the great war. Learn about the efforts made by Yudhishthira and the Pandavas to establish peace and prosperity, focusing on the wisdom imparted by the sages and divine figures. This chapter embodies the values of peace, moral responsibility, and the pursuit of justice in Telugu tradition.",
                keywords:"Santhi Parvam, Mahabharatam, Peace and Reconciliation, Yudhishthira, Pandavas, Telugu Santhi Parvam, Dharma and Justice, Hindu Epics, Restoration of Harmony, Moral Responsibility, Wisdom of Sages, Post-War Peace, Telugu Culture, Mahabharata Stories"
            };
          case '/Anusasanika-Parvam':
              return {title:'Telugu Calendar Panchangam App | Anusasanika Parvam | తెలుగు | అనుశాసనిక పర్వం',
                description:"Explore Anusasanika Parvam (అనుశాసనిక పర్వం), the eighteenth book of the Mahabharatam, which delves into the teachings of dharma, governance, and moral duties. This chapter focuses on the instructions given by Bhishma to Yudhishthira, offering profound wisdom on leadership, ethics, and responsibility. Anusasanika Parvam is rich with guidance on how to rule justly and live virtuously in Telugu tradition.",
                keywords:"Anusasanika Parvam, Mahabharatam, Teachings of Bhishma, Telugu Anusasanika Parvam, Dharma and Governance, Leadership Ethics, Moral Duties, Yudhishthira, Hindu Epics, Telugu Culture, Mahabharata Wisdom, Ruling with Justice, Virtuous Living"
              };
          case '/Aswamedha-Parvam':
              return {
                title:'Telugu Calendar Panchangam App | Aswamedha Parvam | తెలుగు | అశ్వమేధ పర్వం',
                description:"Explore Aswamedha Parvam (అశ్వమేధ పర్వం), an important segment of the Mahabharatam, which focuses on the royal rituals and the profound significance of the Ashwamedha Yagna (horse sacrifice) performed by the Pandavas. This chapter delves into the themes of sovereignty, religious practices, and the restoration of honor and prosperity. Learn about the spiritual and societal implications of the Ashwamedha Yagna in Telugu tradition.",
                keywords:"Aswamedha Parvam, Mahabharatam, Ashwamedha Yagna, Telugu Aswamedha Parvam, Horse Sacrifice, Sovereignty and Honor, Pandavas, Hindu Rituals, Spiritual Significance, Telugu Culture, Mahabharata Stories, Restoration of Prosperity, Religious Practices"
              };
          case '/Asramavasa-Parvam':
                return {
                    title: 'Telugu Calendar Panchangam App | Asramavasa Parvam | తెలుగు | ఆశ్రమవాస పర్వం',
                    description:"Explore Asramavasa Parvam (ఆశ్రమవాస పర్వం), a significant part of the Mahabharatam that chronicles the exile and the life of the Pandavas in the forest. This chapter focuses on the hardships, spiritual practices, and moral lessons learned during their time in the forest, emphasizing the importance of self-discipline, renunciation, and devotion. Discover the deeper teachings about life in exile and the values of sacrifice in Telugu tradition.",
                    keywords:"Asramavasa Parvam, Mahabharatam, Pandavas Exile, Telugu Asramavasa Parvam, Forest Life, Spiritual Practices, Moral Lessons, Renunciation, Devotion, Telugu Culture, Mahabharata Stories, Self-Discipline, Life in Exile"
                };
          case '/Mousala-Parvam':
                return {
                    title: 'Telugu Calendar Panchangam App | Mousala Parvam | తెలుగు | మౌసల పర్వం',
                    description:"Mousala Parvam (మౌసల పర్వం) is the fifteenth book of the Mahabharatam, which deals with the tragic end of the Yadava dynasty and the destruction of Lord Krishna's family. This chapter delves into the complexities of fate, the inevitability of death, and the cycle of destruction. It marks the culmination of the epic, bringing lessons about the transient nature of power and life, and the importance of righteousness. Learn about the demise of the Yadavas and the final moments of Krishna in Telugu tradition.",
                    keywords:"Mousala Parvam, Mahabharatam, Yadava Dynasty, Krishna's Family, Telugu Mousala Parvam, Cycle of Destruction, Fate and Death, Tragic End, Righteousness in Mahabharata, Lord Krishna's End, Telugu Culture, Mahabharata Final Moments",
                };
          case '/Mahaprasthanika-Parvam':
                return { 
                    title: 'Telugu Calendar Panchangam App | Mahaprasthanika Parvam | తెలుగు | మహాప్రస్థానిక పర్వం',
                    description:"Mahaprasthanika Parvam (మహాప్రస్థానిక పర్వం) is the eighteenth book of the Mahabharatam, marking the final journey of the Pandavas. This chapter narrates their journey to the Himalayas, their ascension to the heavens, and the philosophical significance of the end of their mortal lives. It explores the concepts of detachment, the final stages of human life, and the ultimate goal of achieving salvation (Moksha). Learn about the Pandavas' last journey and the teachings of life, death, and eternity in Telugu tradition",
                    keywords:"Mahaprasthanika Parvam, Mahabharatam, Final Journey of Pandavas, Telugu Mahaprasthanika Parvam, Ascension to Heavens, Detachment, Salvation, Moksha, Philosophical Teachings, Pandavas' End, Telugu Culture, Mahabharata Teachings, Life and Death"
                };
          case '/Swargarohana-Parvam':
                return {
                    title: 'Telugu Calendar Panchangam App | Swargarohana Parvam | తెలుగు | స్వర్గారోహణ పర్వం',
                    description:"Mahaprasthanika Parvam (మహాప్రస్థానిక పర్వం) is the eighteenth book of the Mahabharatam, marking the final journey of the Pandavas. This chapter narrates their journey to the Himalayas, their ascension to the heavens, and the philosophical significance of the end of their mortal lives. It explores the concepts of detachment, the final stages of human life, and the ultimate goal of achieving salvation (Moksha). Learn about the Pandavas' last journey and the teachings of life, death, and eternity in Telugu tradition.",
                    keywords:"Mahaprasthanika Parvam, Mahabharatam, Final Journey of Pandavas, Telugu Mahaprasthanika Parvam, Ascension to Heavens, Detachment, Salvation, Moksha, Philosophical Teachings, Pandavas' End, Telugu Culture, Mahabharata Teachings, Life and Death"
                };
          case '/Krishnani-Jananam':
                  return {
                    title: 'Telugu Calendar Panchangam App | Krishna Jananam | తెలుగు | కృష్ణుడు జననం',
                    description:'Krishna Jananam (కృష్ణుడు జననం) marks the birth of Lord Krishna, one of the most revered deities in Hinduism. This event, celebrated as Janmashtami, signifies the arrival of divine incarnation on Earth to restore cosmic order and protect dharma. Learn about the significance of Krishna’s birth, the legends surrounding his early life, his miracles, and his teachings. Discover how his birth is celebrated and revered in Telugu culture through rituals, prayers, and festivities.',
                    keywords:"Krishna Jananam, Lord Krishna Birth, Telugu Janmashtami, Krishna Janmashtami 2024, Krishna Legends, Divine Incarnation, Telugu Culture, Janmashtami Celebration, Krishna Teachings, Lord Krishna Miracles, Krishna Story, Hindu Festivals, Telugu Religious Events"
                  };
          case '/Pandugala-Visistatha':
              return {
                title:'Telugu Calendar Panchangam App | Pandugala Visistatha | తెలుగు | పండుగల విశిష్టత',
                description:"Pandugala Visistatha (పండుగల విశిష్టత) explores the significance and importance of various festivals in Telugu culture. Festivals are an integral part of Telugu traditions, celebrated with joy, devotion, and community involvement. This section covers the spiritual, cultural, and social significance of major festivals, including their historical roots, rituals, and the meaning behind their celebrations. Learn about the distinctiveness of each festival and its role in strengthening cultural values and family bonds in Telugu households.",
                keywords:"Pandugala Visistatha, Telugu Festivals, Importance of Festivals, Festival Significance, Telugu Traditions, Cultural Celebrations, Spiritual Festivals, Historical Festivals, Telugu Community Festivals, Festival Rituals, Social Importance of Festivals, Telugu Culture"  };
          case '/Geetha-Saramsam':
                return {
                    title: 'Telugu Calendar Panchangam App | Geetha Saramsam | తెలుగు | గీతా సారంసం',
                    description:"Geetha Saramsam (గీతా సారంసం) offers a concise and powerful summary of the Bhagavad Gita, one of the most revered scriptures in Hindu philosophy. This section provides insights into the core teachings of Lord Krishna to Arjuna, focusing on the concepts of Dharma, Karma, Bhakti, and Yoga. Explore how the Bhagavad Gita guides individuals towards self-realization, spiritual growth, and living a righteous life according to the divine wisdom shared by Krishna. Learn the essence of the Geetha and its relevance in modern-day life.",
                    keywords:"Geetha Saramsam, Bhagavad Gita Summary, Bhagavad Gita Teachings, Lord Krishna Teachings, Dharma and Karma, Gita in Telugu, Spiritual Growth, Hindu Philosophy, Yoga and Bhakti, Self-Realization, Telugu Geetha, Geetha Essence, Bhagavad Gita in Telugu"};
          case '/Bhagavathgita':
              return {
                title: 'Telugu Calendar Panchangam App | Bhagavathgita | తెలుగు | భగవద్గీత',
                description:"Bhagavad Gita (భగవద్గీత) is one of the most profound spiritual texts of Hinduism, offering timeless wisdom on life's purpose, the nature of the soul, and the path to liberation. In this section, explore the teachings of Lord Krishna to Arjuna on the battlefield, focusing on topics like Dharma, Karma, Bhakti, and Yoga. Understand the significance of devotion, selfless action, and spiritual enlightenment in achieving a balanced and fulfilled life. Discover how the Bhagavad Gita provides guidance for inner peace and spiritual progress.",
                keywords:"Bhagavad Gita, Bhagavad Gita Teachings, Lord Krishna, Dharma, Karma, Bhakti, Yoga, Selfless Action, Hindu Philosophy, Gita in Telugu, Spiritual Enlightenment, Telugu Bhagavad Gita, Bhagavad Gita Summary, Ancient Wisdom, Divine Knowledge"};
          case '/Pandugaluu':
                return {
                    title: 'Telugu Calendar Panchangam App | Pandugaluu | తెలుగు | పండుగలు',
                    description:"Pandugalu (పండుగలు) are an essential part of Telugu culture, celebrating festivals and rituals that honor traditions, deities, and significant events in history. This section highlights various important festivals in the Telugu calendar, their meanings, celebrations, and significance in spiritual and cultural contexts. Whether it's Ugadi, Sankranti, or Deepavali, discover the rich diversity of Telugu festivals and how they contribute to the community’s spiritual and cultural well-being.",
                    keywords:"Pandugalu, Telugu Festivals, Ugadi, Sankranti, Deepavali, Telugu Traditions, Festival Celebrations, Indian Festivals, Telugu Calendar, Cultural Festivals, Spiritual Festivals, Telugu Puranas, Festival Significance"
                };
          case '/Sri-Krishna-Astrotharalu':
                return {
                    title: 'Telugu Calendar Panchangam App | Sri Krishna Astrotharalu | తెలుగు | శ్రీకృష్ణ అస్త్రోత్తరాలు',
                    description:"Explore the divine and spiritual significance of Sri Krishna's Astrotharalu (అస్త్రోత్తరాలు) in this section. These sacred mantras and prayers are believed to invoke the blessings of Lord Krishna for spiritual growth, protection, and prosperity. Delve into the ancient wisdom of astrology, spirituality, and rituals associated with Lord Krishna and understand how his blessings can guide your life's journey. The Astrotharalu of Sri Krishna are powerful tools for spiritual elevation and alignment with cosmic energies.",
                    keywords:"Sri Krishna Astrotharalu, Astrotharalu, Lord Krishna Prayers, Krishna Mantras, Spiritual Growth, Protection Mantras, Telugu Astrology, Krishna Blessings, Divine Prayers, Sri Krishna Devotion, Telugu Spirituality, Krishna Rituals, Lord Krishna Worship"
                };
          case '/Sri-Krishnastakam':
                return {
                    title:'Telugu Calendar Panchangam App | Sri Krishnastakam | తెలుగు | శ్రీ కృష్ణాష్టకం',
                    description:"Discover the profound spiritual meaning of Sri Krishnastakam (శ్రీ కృష్ణాష్టకం), a powerful hymn dedicated to Lord Krishna. This eight-verse prayer extolls the divine qualities and greatness of Lord Krishna, invoking his blessings for peace, prosperity, and spiritual awakening. Chanting the Sri Krishnastakam with devotion brings harmony and enlightenment, connecting the soul to the divine. Explore the beauty and significance of this sacred prayer and experience its transformative power.",
                    keywords:"Sri Krishnastakam, Krishna Ashtakam, Lord Krishna Prayer, Krishna Hymn, Spiritual Awakening, Divine Blessings, Krishna Devotion, Telugu Spirituality, Krishna Worship, Krishna Stotra, Lord Krishna Devotees, Astrological Influence of Krishna, Krishna Mantras, Sri Krishna Praise"
                };
          case '/Bhakthi-Sukthulu':
              return {
                title:'Telugu Calendar Panchangam App | Bhakthi Sukthulu | తెలుగు | భక్తి సూక్తులు',
                description:"Explore the power of Bhakthi Sukthulu (భక్తి సూక్తులు), a collection of divine sayings that inspire and strengthen devotion to God. These sacred verses and quotes encourage a deeper connection with the divine, promoting spiritual growth and enlightenment. Bhakthi Sukthulu highlights the importance of faith, love, and surrender in the path of spirituality, offering wisdom that guides individuals towards inner peace and devotion.",
                keywords:"Bhakthi Sukthulu, Devotional Quotes, Divine Sayings, Telugu Bhakthi, Spiritual Wisdom, God’s Teachings, Bhakthi Devotion, Religious Quotes, Telugu Spirituality, Devotion to God, Bhakti Philosophy, Spiritual Guidance, Faith in God, God’s Blessings"

              };
          case '/Vasthavalu':
                return {
                    title:'Telugu Calendar Panchangam App | Vasthavalu | తెలుగు | వాస్తవాలు',
                    description:"Discover the significant facts and insights on various topics through Vasthavalu (వాస్తవాలు). This section presents a collection of practical and useful truths that help understand the world around us. From general knowledge to the fascinating aspects of life, Vasthavalu aims to inform, educate, and guide individuals in their daily lives with valuable information.",
                    keywords:"Vasthavalu, Facts and Insights, Telugu Facts, Useful Information, General Knowledge, Telugu Culture, Life Facts, Educational Insights, Practical Knowledge, Everyday Wisdom, Telugu Vastu, Real-life Facts"
                };
          default:
              return {
                title: 'Telugu Calendar Panchangam App | 2025 Holidays | Raasi Phalalu | Horoscope | iPhone & Android Apps',
                description:"Discover the comprehensive Telugu Panchangam featuring today's tithi (ఈరోజు తిథి పంచాంగం), daily horoscope (రాశి ఫలాలు), and astrology insights in Telugu. Explore Telugu Calendar updates with details on festivals, holidays, and auspicious timings. Access predictions on jathakam, graham positions, and raasi phalalu. Dive into spiritual texts like Ramayanam, Mahabharatham, and Bhagavad Geetha while enjoying the richness of Telugu culture through astrology and historical stories.",
                keywords:'telugu panchangam, panchangam today, panchangam telugu, ఈరోజు తిథి పంచాంగం, today panchangam in telugu, calendar panchangam, telugu panchangam calendar, తెలుగు క్యాలెండర్, horoscope, horoscope in telugu, rasi phalalu, రాశి ఫలాలు, daily, festivals, holidays, jathakam, raasi, graham, typing, fm, Rashi Phalalu, Telugu Horoscope, Ramayanam, Mahabharatham, Bhagavad Geetha, Telugu Astrology',
            };
              
      }
  };
  const { title, description, keywords } = getPageTitle(location.pathname);
//   const handleContextMenu = (event) => {
//     event.preventDefault();
//   };
//   oncontextmenu={handleContextMenu}
//    useEffect(() => {
//     const handleContextMenu = (event) => event.preventDefault();
//     window.addEventListener("contextmenu", handleContextMenu);
//     return () => {
//       window.removeEventListener("contextmenu", handleContextMenu);
//     };
//   }, []);
  return (
    <div className="App" >
        <Helmet>
                <title>{title}</title>
                <meta 
                    name="description" 
                    content={description}
                    />
                <meta name="keywords" content={keywords} />
        </Helmet>
      <Routes>
        <Route exact path="/" element={<Index />} />
        <Route path="/Index" element={<Index />} />
        <Route path="/panchagam" element={<Panchangam />} />
        <Route path="/Panchangam" element={<Panchagammain />} />
        <Route path="/Calendar" element={<Calendar />} />
        <Route path="/Rasi-Phalalu" element={<Rasiphalalu />} />
        <Route path="/Upavasam" element={<Upavasam />} />
        <Route path="/Athyadhimika-shakthi" element={<Athyadhimikashakthi />} />
        <Route path="/Thidra-Yatra" element={<Thirdayathra />} />
        <Route path="/Shotramlu" element={<Shotramlu />} />
        <Route path="/Ashtotharalu" element={<Ashtotharalu />} />
        <Route path="/Slokalu" element={<Slokalu />} />
        <Route path="/Muhurthalu" element={<Muhurthalu />} />
        <Route path="/Vivaha-Nakshatralu" element={<Vivahanakshatralu />} />
        <Route path="/Dharmasandehalu" element={<Dharmasandehalu />} />
        <Route path="/Hindu-Darmashastram" element={<Hindudarmashastram />} />
        <Route path="/Pandugapramukyata" element={<Pandugapramukyata />} />
        <Route path="/Masa-Vishistatha" element={<Masavishistatha />} />
        <Route path="/Srusti-Rahasyalu" element={<Srustirahasyalu />} />
        <Route path="/Jeevanashaili" element={<Jeevanashaili />} />
        <Route path="/Sankya-Shastram" element={<Sankyashastram />} />
        <Route path="/Grahalu" element={<Grahalu />} />
        <Route path="/Bhavishyavani" element={<Bhavishyavani />} />
        <Route path="/Vasthu-Chitkalu" element={<Vastuchitkalu />} />
        <Route path="/Balli-Shastram" element={<Ballishastram />} />
        <Route path="/Pillishastram" element={<Pillishastram />} />
        <Route path="/Villuvina-Samacharam" element={<Villuvinasamacharam />} />
        <Route path="/Samskrutham" element={<Samskrutham />} />
        <Route path="/Samethalu" element={<Samethalu />} />
        <Route path="/Rastrala-Bashalu" element={<Rastralabashalu />} />
        <Route path="/Nithya-Sathyalu" element={<Nithyasathyalu />} />
        <Route path="/Nithi-Padhyalu" element={<Nithipadhyalu />} />
        <Route path="/Grahanam" element={<Grahanam />} />
        <Route path="/Telugu-Samskrutham" element={<Telugusamskrutham />} />
        <Route path="/Panchangam" element={<Panchangam />} />
        <Route path="/Sankya-Pradanyata" element={<Sankyapradanyata />} />
        <Route path="/Mukhyamainasamacharam" element={<Mukhyamainasamacharam />} />
        <Route path="/Astakalu" element={<Astakalu />} />
        <Route path="/Karthikapuranam" element={<Karthikapuranam />} />
        <Route path="/Namakaranam" element={<Namakaranam />} />
        <Route path="/Pandugalu" element={<Pandugalu />} />
        <Route path="/Telugu-Panchangam" element={<Panchangam1 />} />
        <Route path="/Ramayanam" element={<Ramayanams />} />
        <Route path="/Mahabharatam" element={<Mahabharatam />} />
        <Route path="/Adhi-Parvam" element={<Adhiparvam />} />
        <Route path="/Sabha-Parvam" element={<Sabhaparvam />} />
        <Route path="/Vana-Parvam" element={<Vanaparvam />} />
        <Route path="/Virata-Parvam" element={<Virataparvam />} />
        <Route path="/Vudhyoga-Parvam" element={<Vudhyogaparvam />} />
        <Route path="/Bhishma-Parvam" element={<Bhishmaparvam />} />
        <Route path="/Dhrona-Parvam" element={<Dhronaparvam />} />
        <Route path="/Karna-Parvam" element={<Karnaparvam />} />
        <Route path="/Salya-Parvam" element={<Salyaparvam />} />
        <Route path="/Swothika-Parvam" element={<Swothikaparvam />} />
        <Route path="/Sri-Parvam" element={<Sriparvam />} />
        <Route path="/Santhi-Parvam" element={<Santhiparvam />} />
        <Route path="/Anusasanika-Parvam" element={<Anusasanikaparvam />} />
        <Route path="/Aswamedha-Parvam" element={<Aswamedhaparvam />} />
        <Route path="/Asramavasa-Parvam" element={<Asramavasaparvam />} />
        <Route path="/Mousala-Parvam" element={<Mousalaparvam />} />
        <Route path="/Mahaprasthanika-Parvam" element={<Mahaprasthanikaparvam />} />
        <Route path="/Swargarohana-Parvam" element={<Swargarohanaparvam />} />
        <Route path="/Krishnani-Jananam" element={<Krishnajananam />} />
        <Route path="/Pandugala-Visistatha" element={<Pandugavisistatha />} />
        <Route path="/Geetha-Saramsam" element={<Geethasaramsam />} />
        <Route path="/Bhagavathgita" element={<Bhagavathgita />} />
        <Route path="/Pandugaluu" element={<Pandugaluu />} />
        <Route path="/Sri-Krishna-Astrotharalu" element={<Srikrishnaastrotharalu />} />
        <Route path="/Sri-Krishnastakam" element={<Srikrishnastakam />} />
        <Route path="/Bhakthi-Sukthulu" element={<Sukthulu />} />
        <Route path="/Vasthavalu" element={<Vasthavalu />} />
        <Route path="/Search" element={  <SearchUI /> } />
      </Routes>
    </div>
  );
}

export default App;
