import React, { useState } from 'react';
import Ramayanam from '../assets/jsons/Ramayanam';
import Footer from './footer';
import Header from './header';
import Navbar from './navbar';

const Ramayanams = () => {
  // Initialize states with the first item in Ramayanam array
  const [activeKey, setActiveKey] = useState(0);
  const [selectedContent, setSelectedContent] = useState(Ramayanam[0].subtitle);
  const [expandedPart, setExpandedPart] = useState(null);

  const handleToggle = (index) => {
    if (activeKey === index) {
      setActiveKey(null);
      setSelectedContent(null);
      setExpandedPart(null); // Clear expanded part when title is toggled off
    } else {
      setActiveKey(index);
      setSelectedContent(Ramayanam[index].subtitle);
      setExpandedPart(null); // Reset expanded part when a new title is clicked
    }
  };

  const handlePartToggle = (partIndex) => {
    if (expandedPart === partIndex) {
      setExpandedPart(null); // Close part if already open
    } else {
      setExpandedPart(partIndex); // Open new part
    }
  };
  const [leftSticky, setLeftSticky] = useState(true);
  const [rightSticky, setRightSticky] = useState(true);
  return (
    <div>
  <Header />
  <Navbar />
  <div className="container-fluid" style={{ background: '#FFE6D0' }}>
    <div className="row">
      <div className="col-lg-1 col-md-1 d-none d-md-block leftFlowerImage"></div>
      <div
        className="col-lg-5 col-md-5 col-sm-12 p-3"
        onMouseEnter={() => {
          setLeftSticky(false);
          setRightSticky(true);
        }}
        style={{
          overflowY: leftSticky ? 'hidden' : 'auto',
          height: window.innerWidth > 768 ? '80vh' : 'fit-content',
          position: leftSticky && window.innerWidth > 768 ? 'sticky' : 'static',
          top: 0,
        }}
      >
        <div className="row">
          <div>
            {Ramayanam.map((book, index) => (
              <div
                key={index}
                id={`heading${index}`}
                onClick={() => handleToggle(index)}
                className="card-header card mb-3"
                style={{
                  color: "#FFFFFF",
                  backgroundColor: activeKey === index ? '#B35C0C' : '#754213',
                }}
              >
                <h5 className="mb-0">
                  <div
                    className="btn btn"
                    aria-expanded={activeKey === index}
                    aria-controls={`collapse${index}`}
                    style={{ color: '#FFFFFF' }}
                  >
                    {book.title}
                  </div>
                </h5>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="col-lg-5 col-md-5 col-sm-12 p-3"
        onMouseEnter={() => {
          setLeftSticky(true);
          setRightSticky(false);
        }}
        style={{
          overflowY: rightSticky ? 'hidden' : 'auto',
          height: '80vh',
          position: rightSticky && window.innerWidth > 768 ? 'sticky' : 'static',
          top: 0,
        }}
      >
        {selectedContent && selectedContent.map((part, partIndex) => (
          <div key={partIndex}
            className="card-body rightPanel"
            onClick={() => handlePartToggle(partIndex)}
            style={{ cursor: 'pointer' }}
          >
            <h5>{part.partname}</h5>
            {expandedPart === partIndex && (
              <>
                {part.description.map((desc, descIndex) => (
                  <div key={descIndex} className="card-body rightPanel">
                    <ul>{desc.des}</ul>
                    <br />
                  </div>
                ))}
                <br />
              </>
            )}
          </div>
        ))}
      </div>
      <div className="col-lg-1 col-md-1 d-none d-md-block rightFlowerImage"></div>
    </div>
  </div>
  <Footer />
</div>

  );
};

export default Ramayanams;
