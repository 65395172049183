import React, { useState, useEffect } from 'react';
import Header from '../header';
import Navbar from '../navbar';
import Footer from '../footer';
import ItaramuluData from '../../assets/jsons/ItaramuluData';

const Athyadhimikashakthi = () => {
  const filteredCategories = [
    'beejaksharalu',
    'Beejaksharasankatamulu',
    'homalu',
    'yagnalu',
  ].reduce((acc, key) => {
    if (ItaramuluData[key]) {
      acc[key] = ItaramuluData[key];
    }
    return acc;
  }, {});

  const firstCategoryKey = Object.keys(filteredCategories)[0];
  const firstCategory = filteredCategories[firstCategoryKey];

  const [activeCategory, setActiveCategory] = useState(firstCategory);

  const handleToggle = (category) => {
    setActiveCategory(category);
  };

  const renderCatIDList = (category) => {
    if (category && Array.isArray(category.CatID)) {
      return category.CatID.map((item, index) => (
        <div key={index}>{item.title}</div>
      ));
    }
    return null;
  };

  useEffect(() => {
    setActiveCategory(firstCategory);
  }, [firstCategory]);

  const [leftSticky, setLeftSticky] = useState(true);
  const [rightSticky, setRightSticky] = useState(true);
  
  return (
    <div>
      <Header />
      <Navbar />
      <div className="container-fluid bgColor">
        <div className="row">
          <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
          <div
          className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
          onMouseEnter={() => {
            setLeftSticky(false);
            setRightSticky(true);
          }}
      
        >
            <div className="row">
              <div id="accordion"    style={{
            overflowY: leftSticky ? 'scroll' : 'auto',
            height: '80vh',
            position: leftSticky ? 'sticky' : 'sticky',
            top: 0,
          }}>
                {Object.keys(filteredCategories).map((key, index) => (
                  <div
                    key={index}
                    id={`heading${index}`}
                    onClick={() => handleToggle(filteredCategories[key])}
                    className="card-header card mb-3"
                    style={{ backgroundColor: activeCategory === filteredCategories[key] ? '#B35C0C' : '#754213' }}
                  >
                    <h5 className="mb-0">
                      <button
                        className="btn btn"
                        aria-expanded={activeCategory === filteredCategories[key]}
                        aria-controls={`collapse${index}`}
                        style={{ color: '#FFFFFF' }}
                      >
                        {filteredCategories[key].title}
                      </button>
                    </h5>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
          className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
          onMouseEnter={() => {
            setLeftSticky(true);
            setRightSticky(false);
          }}
          style={{
            overflowY: rightSticky ? 'hidden' : 'auto',
            height: '80vh',
            position: rightSticky ? 'sticky' : 'static',
            top: 0,
          }}
        >
            {Object.keys(filteredCategories).map((key, index) => (
              <div
                key={index}
                id={`collapse${index}`}
                className={`collapse ${activeCategory === filteredCategories[key] ? 'show' : ''}`}
                aria-labelledby={`heading${index}`}
                data-parent="#accordion"
              >
                <div className="card-body rightPanel">
                  <h5>{filteredCategories[key].title}</h5>
                  <ul>
                    {renderCatIDList(filteredCategories[key])}
                  </ul>
                  <br />
                </div>
              </div>
            ))}
          </div>
          <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Athyadhimikashakthi;
