import React, { useState } from 'react';
import unknownfacts from '../../assets/jsons/Telugu/unkonownFacts';
import Header from '../header';
import Navbar from '../navbar';
import Footer from '../footer';
import Flower from '../../assets/images/image.png';

const Vasthavalu = () => {
  const [highlight, setHighlight] = useState(false);
  const [leftSticky, setLeftSticky] = useState(true);
    const [rightSticky, setRightSticky] = useState(true);

  return (
    <div>
      <Header />
      <Navbar />
      <div className="container-fluid" style={{ background: '#ffe6d0' }}>
        <div className="row">
          <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
          <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 p-3"></div>
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 p-3"
            onMouseEnter={() => {
              setLeftSticky(false);
              setRightSticky(true);
            }}
            style={{
              overflowY: leftSticky ? 'hidden' : 'auto',
              height: '80vh',
              position: leftSticky ? 'sticky' : 'static',
              top: 0,
            }}
          >
            <div className="row">
              <div style={{ textAlign: 'center' }}>
                <div className="card-body rightPanel">
                  <h5>తెలియని వాస్తవాలు</h5>
                  {unknownfacts.map((facts, index) => {
                    const isPreviousEmptyTitle = index > 0 && unknownfacts[index - 1].title === "";

                    return (
                      <div key={index}>
                        {isPreviousEmptyTitle && (
                          <span style={{ color: 'yellow' }}>{facts.title}</span>
                        )}
                        {!isPreviousEmptyTitle && (
                          <>
                            {facts.title && (
                              <>
                                <p>
                                  <img
                                    src={Flower}
                                    alt="Flower"
                                    style={{ width: '20px', marginRight: '10px' }}
                                  />
                                  {facts.title}</p>
                              </>
                            )}
                          </>
                        )}
                        <br />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 p-3"></div>
          <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Vasthavalu;
