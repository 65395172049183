import React, { createContext, useState } from "react";

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
    const [inputText, setInputText] = useState("");
    const [filteredData, setFilteredData] = useState([]);

    return (
        <SearchContext.Provider
            value={{ inputText, setInputText, filteredData, setFilteredData }}
        >
            {children}
        </SearchContext.Provider>
    );
};
