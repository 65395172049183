import React from 'react';
import Header from '../header';
import Navbar from '../navbar';
import Footer from '../footer';
import ItaramuluData from '../../assets/jsons/ItaramuluData';

const Ballishastram = () => {
  const genderMapping = {
    Male: 'పురుషుడు',
    FeMale: 'స్త్రీ',
  };

  return (
    <div>
      <Header />
      <Navbar />
      <div className="container-fluid" style={{ background: '#ffe6d0' }}>
        <div className="row">
          <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
          <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 p-3">
            <div className="row">
              {ItaramuluData.BalliSastram.map((genderGroup, index) => (
                <React.Fragment key={index}>
                  {genderGroup.Gender === 'Male' ? (
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-3">
                      <div className="card-body rightPanel">
                        <h5>{genderMapping[genderGroup.Gender]}</h5>
                        {genderGroup.Parts.map((part, idx) => (
                          <div className="holiday-item" key={idx}>
                            <ul>
                              <span
                                className="holiday-title"
                                style={{ fontWeight: 'bold' }}
                              >
                                {part.actionId}
                              </span>
                              <span> - </span>
                              <span className="holiday-description">
                                {part.Description}
                              </span>
                            </ul>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-3">
                      <div className="card-body rightPanel">
                        <h5>{genderMapping[genderGroup.Gender]}</h5>
                        {genderGroup.Parts.map((part, idx) => (
                          <div className="holiday-item" key={idx}>
                            <ul>
                              <span
                                className="holiday-title"
                                style={{ fontWeight: 'bold' }}
                              >
                                {part.actionId}
                              </span>
                              <span> - </span>
                              <span className="holiday-description">
                                {part.Description}
                              </span>
                            </ul>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Ballishastram;
