const Ramayanam = [

    {
        "title": "బాలకాండము",
        "subtitle": [
            {
                "partname": "1.కావ్యావతరణ",
                "description": [
                    {
                        "des": "నారదుడు ఒకమారు వాల్మీకి మహర్షి ఆశ్రమానికి వచ్చాడు. సర్వోన్నత లక్షణాలున్న పురుషుడెవరైనా ఉన్నాడా అని వాల్మీకి అడుగగా నారదుడు శ్రీరాముని కథ చెప్పాడు. రాముడు సత్యధర్మవ్రతుడు. మహావీరుడు. "
                    },
                    {
                        "des": "అనితర రూప గుణ సంపన్నుడు. సకల గుణాభిరాముడు. ఆ రాముని చరిత్రం పరమ పవిత్రం. సకల వేద సారం. అని చెప్పాడు.తరువాత వాల్మీకి తమసా నదిలో స్నానానికి వెళ్ళినపుడు ఒక నిషాదుడు క్రౌంచపక్షిని సంహరించడం చూచాడు. "
                    },
                    {
                        "des": "అప్రయత్నంగా వాల్మీకి నోట మానిషాద! ప్రతిష్ఠాం... అనే ఛందోబద్ధమైన శ్లోకం వెలువడింది. అనంతరం బ్రహ్మ వాల్మీకి చెంతకు వచ్చి అటువంటి శ్లోకాలలోనే రామాయణాన్ని రచించమని ఆదేశించాడు. పర్వతాలు, నదులూ ఉన్నంతకాలం లోకంలో సీతారామచరితం నిలిచి ఉంటుందని అనుగ్రహించాడు."
                    },
                    {
                        "des": "వాల్మీకి ధ్యానమగ్నుడైనపుడు అతనికి రామాయణం మొత్తం సకల రహస్యాలతో అవగతం అయ్యింది. అపుడు వాల్మీకి రామచరితాన్ని సమస్త కామార్ధాలతోను, గుణార్ధాలతోను మధురంగా రచించాడు. అందులో మొత్తం 24 వేల శ్లోకాఉన్నాయి. 500 సర్గలు (ఉత్తర కాండతో కలిపి) ఉన్నాయి. ఆ రామాయణాన్ని వాల్మీకినుండి నేర్డి కుశలవులు అందరూ ఆనందించి ప్రశంసించేలా గానం చేశారు."
                    },
                ]
            },
            {
                "partname": "2.అయోధ్యాపురం, రాముని జననం",
                "description": [
                    {
                        "des": "ఘనమైన ఇక్ష్వాకు వంశానికి చెందిన దశరథుడు వేదవేత్త, పరాక్రమశాలి, ధర్మ పరాయణుడు. సుసంపన్నమైన కోసల దేశాన్ని అతడు అయోధ్యా నగరం రాజధానిగా జనరంజకంగా పరిపాలిస్తున్నాడు. కాని సంతానం లేనందున ఆ మహారాజు చింతాక్రాంతుడైనాడు. మంత్రులు, గురువుల దీవనలతో యాగం చేయ తలపెట్టాడు. "
                    },
                    {
                        "des": "అందుకు తన సోదర సముడైన అంగరాజు రోమపాదుని కూతురు శాంత, అల్లుడు ఋష్యశృంగులను అయోధ్యకు ఆహ్వానించాడు. ఋష్యశృంగుడు ఋత్విక్కుగా అశ్వమేధయాగం జరిగింది. తరువాత ఋష్యశృంగుడే పుత్రకామేష్టి యాగం కూడా చేయించాడు."
                    },
                    {
                        "des": "ఆ యాగం వల్ల సంతుష్టులైన దేవతల ప్రార్థనలను మన్నించి, శ్రీ మహావిష్ణువు తాను రావణ సంహారార్ధమై తన నాలుగు అంశలతో దశరథ మహారాజునకు నలుగురు కుమారులుగా మానవ జన్మ ఎత్తడానికి సంకల్పించాడు. దేవ దానవ గంధర్వ యక్ష రాక్షసులచే మరణం సంభవించదని వరం పొందిన రావణుడు దేవతలను పీడిస్తున్నాడు."
                    },
                    {
                        "des": "కనుక నర వానరుల చేత మాత్రమే వాడు మరణించే అవకాశం ఉంది.యజ్ఞపురుషుడిచ్చిన పాయసాన్ని దశరథుడు తన భార్యలైన కౌసల్య, కైక, సుమిత్రలకిచ్చాడు. వారు తేజోవతులై గర్భము దాల్చారు. చైత్ర నవమి నాడు, పునర్వసు నక్షత్ర కర్కాటక లగ్నంలో, గురూదయ సమయంలో కౌసల్యకు రాముడు జన్మించాడు. కైకకు పుష్యమీ నక్షత్రయుక్త మీన లగ్నంలో భరతుడు జన్మించాడు"
                    },
                    {
                        "des": "సుమిత్రకు ఆశ్లేషా నక్షత్రయుక్త కర్కాటక లగ్నంలో లక్ష్మణ, శత్రుఘ్నులు జన్మించారు. వారికి కులగురువు వశిష్ఠుడు నామకరణం జరిపించాడు. అన్నదమ్ములు నలువురూ సకల గుణ తేజో సంపన్నులై విద్యాభ్యాసం చేస్తూ అందరికీ ప్రీతిపాత్రులైనారు. అన్నదమ్ములలో రాముడు ప్రత్యేకించి దశరథునకూ, సకల జనులకూ ప్రాణసమానుడై ప్రకాశించాడు. లక్ష్మణుడు ఎప్పుడూ రాముని ఎడబాసి ఉండేవాడు కాదు."
                    },
                    {
                        "des": "ఇంద్రాది దేవతలు తమ అంశలతో వానర, భల్లూక వీరులకు జన్మలనిచ్చారు. బ్రహ్మ అంశతో జాంబవంతుడు, ఇంద్రుని అంశతో వాలి, సూర్యుని అంశతో సుగ్రీవుడు, బృహస్పతి అంశతో తారుడు, విశ్వకర్మ అంశతో నలుడు, అగ్ని అంశతో నీలుడు, కుబేరుని అంశతో గంధమాదనుడు, అశ్వినీ దేవతల అంశలతో మైంద ద్వివిధులు, వరుణాంశతో సుషేణుడు, పర్జన్యాంశతో శరభుడు - ఇలా మహావీరులైన వానర భల్లూక సమూహం శ్రీరామునకు రావణ సంహారంలో సహాయపడడానికి వృద్ధి చెందింది. వాయుదేవుని అంశతో అసమాన పరాక్రమశాలి, తేజో వేగ సంపన్నుడు, వజ్ర సదృశ దేహుడు అయిన ఆంజనేయుడు జన్మించాడు."
                    }
                ]
            },
            {
                "partname": "3.విశ్వామిత్రుని వద్ద శిష్యరికం",
                "description": [
                    {
                        "des": "ఒకమారు విశ్వామిత్ర మహర్షి అయోధ్యకు వచ్చి, తాను చేసే యాగాన్ని మారీచ సుబాహులనే రాక్షసులు విధ్వంసం చేస్తున్నారనీ, వారిని సంహరించి యాగాన్ని పరిరక్షించడానికి రాముని తనతో పంపమనీ దశరథుని కోరాడు. పదిహేను సంవత్సరాల బాలుడు కౄర రాక్షసును నిలువరించ జాలడనీ, కనుక తానే సైన్య సమేతంగా వచ్చి యాగ రక్షణ చేయడానికి అనుమతించమనీ దశరథుడు అర్ధించాడు. విశ్వామిత్రుడు సమ్మతించలేదు. "
                    },
                    {
                        "des": "ఇక కులగురువు వశిష్ఠుని ప్రోత్సాహంతో దశరథుడు రామునీ, లక్ష్మణునీ విశ్వామిత్రునితో పంపాడు.మార్గంలో ముందుగా విశ్వామిత్రుడు బల, అతిబల అనే తేజోవంతమైన విద్యలను రామునకుపదేశించాడు. వాటివలన అలసట, ఆకలిదప్పులు కలుగవు. మువ్వురూ రాత్రి విశ్రమించారు. తెల్లవారి విశ్వామిత్రుడు కౌసల్య కడుపు చల్లగా ఉండుగాక. రామా. తెల్లవారింది. మేలుకో, ఆహ్నికాలు నెరవేర్చుకో అని ప్రబోధించాడు."
                    },
                ]
            },
            {
                "partname": "4.తాటక వధ, యాగ పరిరక్షణ",
                "description": [
                    {
                        "des": "మరొక రాత్రి మన్మధాశ్రమంలో విశ్రమించి, మరునాడు వారు భయంకరమైన తాటకా వనంలో ప్రవేశించారు. అక్కడ మహాబలవంతురాలు, మాయావి అయిన తాటక వారిని వేధించసాగింది. గురువుఆజ్ఞపై రాముడు తాటకను వాడిబాణంతో సంహరించాడు.మరునాడు విశ్వామిత్రుడు రామలక్ష్మణులను దీవించి, వారికి అనేక దివ్య శస్త్రాస్త్రాలు, వాని ప్రయోగ ఉపసంహార క్రమాలు ప్రసాదించాడు. అనంతరం వారు విశ్వామిత్రుని సిద్ధాశ్రమం ప్రవేశించారు."
                    },
                    {
                        "des": "ఒకప్పుడు అది త్రివిక్రముడైన శ్రీ వామనమూర్తి ఆశ్రమం. అక్కడ రామలక్ష్మణులకు ఆశ్రమ, యాగ సంరక్షణా బాధ్యతను అప్పగించి విశ్వామిత్రుడు యజ్ఞదీక్ష వహించాడు. యజ్ఞాన్ని ధ్వంసం చేయడానికి మారీచ సుబాహలు రాక్షస సమూహాలతో ఆకాశంలో ముసురుకున్నారు. రాముడు శేతేశువుతో మారీచుని నూరామడల దూరంలోని సముద్రంలోకి విసిరేశాడు. ఆగ్నేయాస్త్రంతో సుబాహుని దండించాడు. "
                    },
                    {
                        "des": "వాయువ్యాస్త్రంతో అందరినీ తరిమికొట్టాడు. యజ్ఞం నిరుపద్రవంగా నిర్విఘ్నంగా ముగిసింది. ఆనందించిన విశ్వామిత్రుడు రామలక్ష్మణులను దీవించాడు.మరునాడు విశ్వామిత్రుడు రామలక్ష్మణులను వెంటనిడుకొని జనక మహారాజు చేసే యజ్ఞాన్ని చూడడానికి మిధిలానగరానికి బయలుదేరాడు."
                    }
                ]
            },
            {
                "partname": "5.విశ్వామిత్రుని వంశము",
                "description": [
                    {
                        "des": "అన్వేషణ దారిలో శోణానది తీరాన విశ్వామిత్రుడు రామలక్ష్మణులకు తన వంశగాధ చెప్పాడు – కుశుడనే ధర్మ వ్రతుడైన తపశ్శాలి భార్య విదర్భ రాజపుత్రి. వారి నలుగురు కొడుకులలో కుశనాభుడనే వానికి భార్య ఘృతాచి అనే అప్సరస. వారికి నూరుగురు కూతుళ్ళు. ఒక మారు వారు వాయుదేవుని కోరికను తిరస్కరించడంవలన అతని శాపానికి గురై వారు కుబ్జలయ్యారు."
                    },
                    {
                        "des": "ఊర్మిళ కూతురు సోమద అనే గంధర్వ కన్యకకు చూళి అనే బ్రహ్మర్షి అనుగ్రహం వలన బ్రహ్మదత్తుడనే కొడుకు లభించాడు. మహారాజయిన ఆ బ్రహ్మదత్తుడు కుశనాభుని కుమార్తెలు అయిన నూరుగురు కుబ్జలనూ పెళ్ళాడడానికి అంగీకరించాడు. అతను చేత్తో తాకగానే ఆ నూరుగురు కుబ్జలూ యధాప్రకారం త్రిలోక సుందరులైనారు."
                    },
                    {
                        "des": "తరువాత కుశనాభుడు పుత్రునికోసం యాగం చేసి గాధి అనే కొడుకును కన్నాడు. ఆ గాధి, సోమదల కుమారుడు విశ్వామిత్రుడు. విశ్వామిత్రుని అక్క సత్యవతి ఋచీకుడనే మహామునిని పెండ్లాడింది. ఆమే తరువాత కౌశికీ నదిగా భూలోకంలో అవతరించింది. ఆ నది ఒడ్డునే విశ్వామిత్రుడు నివసిస్తున్నాడు."
                    }
                ]
            },
            {
                "partname": "6.గంగావతరణ గాధ",
                "description": [
                    {
                        "des": "తరువాత రాముని కోరికపై విశ్వామిత్రుడు కుమారస్వామి జన్మ వృత్తాంతాన్ని, త్రిపధ గామిని అయిన గంగానది అవతరణ గాథను వినిపించాడు.తేజోమయమైన శివుని రేతస్సును అగ్ని గంగానదికిచ్చాడు. ఆమె ఆ తేజస్సును భరించలేక హిమవత్పర్వత పాదంపై తన గర్భాన్నుంచింది. అందుండి శ్వేత పర్వతమూ, రెల్లుగడ్డీ జన్మించాయి. "
                    },
                    {
                        "des": "అందులో ఉద్భవించిన కుమారస్వామిని షట్కృత్తికలు పాలిచ్చి పెంచారు. ఆ కార్తికేయునికి దేవతలందరూ దేవసేనాధీపత్యం ఇచ్చి అభిషేకించారు.అయోధ్యాధిపతి సగరునకు పెద్దభార్య కేశిని వల్ల అసమంజసుడనే కొడుకు, రెండవ భార్య సుమతి వల్ల అరవై వేలమంది కొడుకులు జన్మించారు."
                    },
                    {
                        "des": "అసమంజసుని కొడుకు అంశుమంతుడు అందరికీ ప్రీతిపాత్రుడయ్యాడు. సగరుడు అశ్వమేధయాగం చేసినపుడు దేవేంద్రుడు యజ్ఞాశ్వాన్ని హరించుకుపోయాడు. అశ్వాన్ని వెదుకుతూ సగరుని అరవై వేల మంది కొడుకులు భూమి అంతా గాలించి, ఆ పై భూమిని త్రవ్వుతూ పాతాళానికి పోయి దిగ్గజాలకు నమస్కరించి, ఇంకా త్రవ్వసాగారు. కపిలుని సమీపంలో యజ్ఞాశ్వాన్ని చూచి కపిలుని నిందించారు. కపిలుని కోపాగ్నికి భస్మమైపోయారు."
                    },
                ]
            },
            {
                "partname": "7.అహల్య వృత్తాంతము",
                "description": [
                    {
                        "des": "ఆపై మిథిలకు వెళ్ళే దారిలో వారికి శూన్యమైన గౌతమాశ్రమం కనిపించింది. ఒకప్పుడు ఇంద్రుడు గౌతమ రూపం ధరించివచ్చి గౌతముని ముని అయిన అహల్యతో కలిసాడు. అందుకు గౌతముడు కుపితుడై ఇంద్రుని, అహల్యను శపించాడు. తత్కారణంగా ఇంద్రుడు మేషవృషణుడూ (శరీరము అన్తటా 1000 కన్నులు కలవాడూ) అయ్యాడు."
                    },
                    {
                        "des": "అహల్య అదృశ్యరూపంలో వాయుభక్షణ మాత్రమే చేస్తూ, ధూళిలో పొరలాడుతూ ఆ ఆశ్రమంలోనే, మంచుతెరచే కప్పబడిన పూర్ణ చంద్రబింబంలా, ధూమావృతమైన అగ్నిజ్వాలలా ఉంది. శ్రీరాముడు ఆశ్రమంలోకి వచ్చినపుడు ఆమె శాపం తొలగిపోవడం వలన ఆమె అందరికీ కనిపించింది. రామ లక్ష్మణులు ఆమె పాదాలు స్పృశించారు. "
                    },
                    {
                        "des": "ఆమె భక్తి శ్రద్ధలతో వారిని పూజించింది. గౌతముడు కూడా వచ్చి అహల్యతో కలిసి అతిథులను పూజించాడు.సంతుష్ఠులైన విశ్వామిత్ర రామ లక్ష్మణులు ప్రయాణం కొనసాగించి జనకుని పాలనలో ఉన్న మిథిలా నగరాన్ని చేరుకొన్నారు. జనకుడు సవినయంగా వారిని తన యజ్ఞశాలకు ఆహ్వానించాడు. విశ్వామిత్రుడు రామలక్ష్మణులను పరిచయం చేశాడు."
                    },
                ]
            },
            {
                "partname": "8.విశ్వామిత్రుని కథ",
                "description": [
                    {
                        "des": "జనకుని పురోహితుడైన శతానందుడు అహల్య, గౌతముల కొడుకు. తన తల్లి శాపవిమోచనయై భర్తను చేరుకోవడం విని మిక్కిలి సంతసించాడు. విశ్వామిత్రుని తపోబల చరిత్ర రామునికి చెప్పాడు గాధి కొడుకైన విశ్వామిత్రుడు మహారాజుగా ఉన్నపుడు ఒకమారు వశిష్ఠుని ఆశ్రమానికి వెళ్ళాడు. వశిష్ఠుడు తనవద్దనున్న శబళ అనే కామధేనువు సాయంతో విశ్వామిత్రునికీ, అతని చతురంగ బలాలకూ గొప్పగా విందు చేశాడు."
                    },
                    {
                        "des": "విశ్వామిత్రుడు సంతోషించి ఆ శబళను తనకీయమని, అందుకు ప్రతిగా కోటి గోవులు, కావలసినంత ధనం ఇస్తానని, కోరాడు. వశిష్ఠుడు నిరాకరించగా విశ్వామిత్రుడు బలవంతంగా శబళను తీసుకుపోదలచాడు."
                    },
                    {
                        "des": "ఆత్మ రక్షణార్ధం వశిష్ఠుని అనుమతి తీసుకొని శబళ తననుండి సృష్టించిన మహాసైన్యంతో విశ్వామిత్రుని సేనను నాశనం చేసింది. విశ్వామిత్రుడు ప్రయోగించిన శస్త్రాస్త్రాలు కూడా వ్యర్ధమయ్యాయి. పంతం పట్టి విశ్వామిత్రుడు శివుని గూర్చి తపస్సు చేసి సకల దివ్య శస్త్రాస్త్రాలూ సాధించి, మరల వశిష్ఠుని ఆశ్రమంపై దండెత్తాడు. కాని వశిష్ఠుని బ్రహ్మదండం ముందు అవీ విఫలమయ్యాయి. బ్రహ్మాస్త్రం కూడా పనిచేయలేదు."
                    },
                    {
                        "des": "క్షాత్రబలం వ్యర్ధమని గ్రహించి విశ్వామిత్రుడు తీవ్రంగా తపస్సు ఆచరించాడు. అతనికి బ్రహ్మదేవుడు రాజర్షి స్థాయినొసగినా విశ్వామిత్రుడు సంతుష్ఠుడు కాలేదు. తరువాత ఇక్ష్వాకు వంశీయుడైన త్రిశంకు మహారాజు కోరికను నెరవేర్చే ప్రయత్నంలో విశ్వామిత్రుడు మరో స్వర్గాన్నే సృష్టించాడు. లోకాలన్నీ ఉన్నంతకాలం విశ్వామిత్రుని సృష్టి కూడా వైశ్వానర పథానికి బయట శాశ్వతంగా ఉంటుంది."
                    },
                    {
                        "des": "పుష్కర తీర్ధంలో తపసు చేసుకొంటున్న విశ్వామిత్రుడు అంబరీషుని యజ్ఞంలో నరపశువుగా వాడబడుతున్న శునశ్శేపుడనే వాడిని రక్షించాడు. తరువాత మరో వెయ్యి సంవత్సరాలు ఉగ్రమైన తపస్సు చేశాడు. అతడిని మహర్షి అని బ్రహ్మ నిర్ణయించినా తృప్తుడు కాలేదు. కొంత కాలం మేనక, ఇంద్రుడు, రంభ విఘ్నాలు కలిగించినా గాని మరలా తపసు కొనసాగించాడు. ఆ తపసుకు మెచ్చి చివరకు బ్రహ్మాది దేవతలు, వశిష్ఠుడు వచ్చి విశ్వామిత్రుని బ్రహ్మర్షి అని కొనియాడి గౌరవించారు."
                    },
                ]
            },
            {
                "partname": "9.వివాహ నిశ్చయము",
                "description": [
                    {
                        "des": "జనకుడు యాగ సమయంలో భూమిని దున్నుతున్నపుడు నాగేటి చాళ్ళలో ఒక ఆడు బిడ్డ లభించింది. అయోనిజయైన ఆమెకు సీత అని సార్థకనామం ఉంచి జనకుడు పెంచాడు. ఆమె వీర్యశుల్క అనీ, శివధనుస్సును ఎక్కుపెట్టిన వారికే ఇస్తాననీ ప్రకటించాడు. జనకుని ఇంట పూర్వులకాలం నుండి శివధనుస్సు పూజలందుకొంటున్నది. దానిని అంతకు పూర్వం ఎవరూ ఎక్కుపెట్టలేకపోయారు."
                    },
                    {
                        "des": "విశ్వామిత్రుని కోరికపై జనకుడు శివధనుస్సును తెప్పించి రాఘవులకు చూపించాడు. విశ్వామిత్రుని అనుజ్ఞ తీసికొని రాముడు అవలీలగా నారి ఎక్కుపెట్టాడు. బ్రహ్మాండమైన ధ్వనితో విల్లు విరిగిపోయింది. జనకుడు సంతోషించి వీర్యశుల్క అని తాను ప్రతిజ్ఞ చేసిన సీతకు రాముడు తగిన వరుడని నిశ్చయించాడు, విశ్వామిత్రుని అంగీకారంతో దశరథ మహారాజుకు కబురు పంపాడు."
                    },
                    {
                        "des": "వర్తమానం తెలుసుకొని దశరథుడు సపరివారంగా మిథిలకు వెళ్ళి జనక మహారాజు పూజలందుకొన్నాడు. కన్యాదాత ఔదార్యాన్ని బట్టి తాము సీతను కోడలిగా ప్రతిగ్రహించడానికి సిద్ధమని చెప్పాడు. జనకుని యజ్ఞం ఆనందంగా ముగిసింది.మరునాడు సభకు జనకుని తమ్ముడైన కుశధ్వజుడు కూడా వచ్చాడు. సభలో విశ్వామిత్రుని అనుమతితో వశిష్ఠుడు ఇక్ష్వాకు వంశక్రమాన్ని వివరించాడు."
                    },
                    {
                        "des": "తమ వంశక్రమాన్ని వివరించాడు. వశిష్ఠుడూ విశ్వామిత్రుడూ సంప్రదించి జనకుని కుమార్తెలైన సీతకు రాముడూ, ఊర్మిళకు లక్ష్మణుడూ, కుశధ్వజుని కుమార్తెలైన మాండవికి భరతుడూ, శ్రుతకీర్తికి శత్రుఘ్నుడూ తగిన వరులని నిర్ణయించారు. వారి ప్రతిపాదనకు జనకుడు ఎంతో సంతోషించి, దోసిలియొగ్గి వందనమొనర్చి, భగదేవతానీకమైన ఉత్తర ఫల్గునీ నక్షత్రంలో నలుగురు జంటల వివాహం కావాలని ఆకాంక్షించాడు."
                    },
                ]
            },
            {
                "partname": "10.సీతారామ కల్యాణము",
                "description": [
                    {
                        "des": "దశరథుడు గోదానాది కర్మలు ముగించుకొని వచ్చి, కృతకౌతుక మంగళులైన కుమారులతో కూడా వచ్చి కన్యాదాతలకోసం నిరీక్షించాడు. సర్వాలంకృతమైన పందిరిలో జనకుడు వశిష్ఠ, విశ్వామిత్ర, శతానందుల సమక్షంలో అగ్నిని ప్రతిష్ఠింపజేసి, హోమాలు చేశాడు. సర్వాభరణ భూషితురాలైన సీతను తీసుకొని వచ్చి జనకుడు కౌసల్యానంద వర్ధనా! రామా! ఇదిగో నా కూతురు సీత. ఈమె నీకు సహధర్మచారిణి. ఈమెనంగీకరించి పాణి గ్రహణం చెయ్యి."
                    },
                    {
                        "des": "పతివ్రత అయిన మా సీత నిన్నెప్పుడూ నీడలాగ అనుసరిస్తుంది అని చెప్పి మంత్రపూరితమైన జలం రాముని చేతిలో విడచాడు. ఆకాశంలో దేవ దుందుభులు మ్రోగాయి. పూలవాన కురిసింది. వశిష్ఠుని అనుమతి పొంది రాముడు సీతను, లక్ష్మణుడు ఊర్మిళను, భరతుడు మాండవిని, శత్రుఘ్నుడు శృతకీర్తిని పాణి గ్రహణం చేశారు. "
                    },
                    {
                        "des": "వారందరూ అగ్నిహోత్రానికి, జనకునకు, ఋషులకు ప్రదక్షిణాలు చేశారు. సీతారాముల, వారి సహజన్ముల కళ్యాణం వైభవంగా, లోక కళ్యాణంగా జరిగింది. రామ లక్ష్మణ భరత శత్రుఘ్నులు తమ నవోఢలతో ఋషి బంధు సమేతంగా విడిదికి వెళ్ళారు."
                    },
                ]
            },
            {
                "partname": "11.పరశురామ గర్వ భంగము",
                "description": [
                    {
                        "des": "అందరి పూజలు అందుకొని విశ్వామిత్రుడు హిమవత్పర్వతానికి వెళ్ళిపోయాడు. జనకుని వీడ్కోలు గ్రహించి దశరథుడు నూతన వధూవరులతో అయోధ్యకు బయలుదేరాడు. అప్పుడు భీకరమైన గాలి దుమారం లేచింది. కాలాగ్నిలా ప్రజ్వరిల్లుతూ కైలాసగిరి సదృశుడైన పరశురాముడు వారియెదుట ప్రత్యక్షమయ్యాడు. వశిష్ఠాది మహర్షులు అతనిని పూజించారు."
                    },
                    {
                        "des": "దశరథ రామా! నీవు శివుని విల్లు విరచిన వృత్తాంతాన్ని విన్నాను. ఇదిగో నా ధనుస్సు జామదగ్న్యాన్ని ఎక్కుపెట్టి, బాణం తొడిగి నీ బలం చూపు. అపుడు నీతో ద్వంద్వ యుద్ధం చేస్తాను – అని భార్గవరాముడు దశరథరాముని ఆక్షేపించాడు. దశరథుని అభ్యర్ధనలను లెక్కచేయలేదు. శివధనస్సుతో సమానంగా చేయబడిన తన విష్ణు ధనస్సును ఎక్కుపెట్టి చూపమని మళ్ళీ అన్నాడు."
                    },
                    {
                        "des": "క్రుద్ధుడైన రాముడు అవలీలగా పరశురాముని ధవస్సుకు బాణం తొడిగాడు. దివ్యాస్త్రం వృధా కారాదు గనుక పరశురాముని పాదగతిని కానీ, లేదా అతను తపస్సుతో సాధించుకొన్న లోకాలను గానీ ఏదో ఒకటి నాశనం చేస్తానని చెప్పాడు."
                    },
                    {
                        "des": "పరశురాముడు నిర్వీర్యుడైపోయాడు. తాను కశ్యపునకిచ్చిన మాట ప్రకారం భూమిమీద నివసించజాలడు గనుక తన పాదగతిని నిరోధించవద్దనీ, తాను తపస్సుతో సాధించుకొన్న దివ్యలోకాలను తనకు కాకుండా చేయవచ్చనీ కోరాడు. రాముడు నిత్యుడైన శ్రీహరి అని గ్రహించినందున అలా జరగడం తనకు లజ్జాస్పదం కాదన్నాడు. రాముడు బాణం విడచాడు. తరువాత పరశురాముడు మళ్ళీ తపస్సు చేసుకోవడానికి మహేంద్రగిరికి వెళ్ళిపోయాడు."
                    },
                ]
            },
            {
                "partname": "12.అయోధ్యాగమనం",
                "description": [
                    {
                        "des": "తమకు పునర్జన్మ లభించిందని ఆనందించిన దశరథుడు సేనా సమేతంగా గురువులను, పుత్రులను, కోడళ్ళను వెంటబెట్టుకొని అయోధ్యలో ప్రవేశించాడు. కోడళ్ళు అత్తలకు నమస్కరించి, మంగళాశీర్వచనాలు పొంది, దేవాలయాలలో పూజలు చేసి వచ్చారు. సోదరులు పెద్దల ఆశీర్వచనాలు పొంది తమ తమ వధువులతో తమ నివాస గృహాలలో ప్రవేశించారు."
                    },
                    {
                        "des": "కొన్ని దినాల తరువాత భరతుడూ, శత్రుఘ్నుడూ మేనమామ ఇంటికి వెళ్ళారు. సీతారాములు పెద్దలను సేవిస్తూ, పరస్పరం ప్రీతిపాత్రులై సుఖించారు. ఉత్తమ రాజకన్య అయిన సీతతో కలిసి శ్రీరాముడు లక్ష్మితో కూడిన విష్ణువు లాగా ప్రకాశించాడు."
                    },
                ]
            }
        ]
    },
    {
        "title": "అయోధ్యా కాండము",
        "subtitle": [
            {
                "partname": "1.శ్రీరామ పట్టాభిషేకానికి",
                "description": [
                    {
                        "des": "కల గుణాభిరాముడు, ధర్మ పరుడు, తేజో మయుడు, అయిన శ్రీరాముడు అయోధ్యా నగర వాసులకు ప్రాణప్రథమయ్యాడు. దశరధుడు రాజ్యభారాన్ని పెద్దకొడుకైన రామునకప్పగించి, తాను విశ్రాంతి తీసికొనవలెనని సంకల్పించాడు. తక్కువ వ్యవధిలో చైత్ర పుష్యమినాడే పట్టాభిషేకానికి సర్వమూ సిద్ధమైనది. పుర వాసులంతా హర్షించారు."
                    },
                    {
                        "des": "సిద్ధమౌతున్నాయి. వశిష్ఠుడు రామునకు పట్టాభిషేక దీక్షనిచ్చి సీతారాములను ఉపవసించమని, మరునాడే పట్టాభిషేకమని చెప్పాడు. సీతారాములు శ్రీమన్నారాయణ మూర్తిని పూజించి, హోమాది కర్మలు చేసి, నియతమానసులై ఉపవసించారు. అయోధ్యానగర వాసులు నగరాన్ని సర్వాంగ సుందరంగా అలంకరించి సంబరాలు చేసికొనసాగారు."
                    },
                ]
            },
            {
                "partname": "2.కైకకు మంధర దుర్బోధ",
                "description": [
                    {
                        "des": "రాముని సవతి తల్లియైన కైకకు రాముడంటే ఎంతో వాత్సల్యము. రాముని పట్టాభిషేక సమాచారం విని ఆమె సంతోషించింది. కాని ఆమె చెలికత్తె మంధర కైకకు ఇలా నూరిపోసింది - రాముడు రాజయితే కౌసల్య రాజమాతవుతుంది. నీ స్థానం బలహీనపడుతుంది. నీవూ, నీ కొడుకూ తరతరాలుగా రాముని వంశానికి దాసులవుతారు. "
                    },
                    {
                        "des": "అంతే గాక రాముని తరువాత భరతునికి రాజ్యాధికారం ఉంది గనుక భరతుని అడ్డు తొలగించుకోవడానికి రాముడు యత్నించవచ్చును. కనుక భరతుని రాజుగా చేసి, రాముని దూరంగా పంపే మార్గం ఆలోచించు. అలా మంధర చెప్పిన మాటలు కైకేయి వంటబట్టాయి. అంతకు పూర్వము దశరథుడు ఆమెకు రెండు కోరికలు ప్రసాదించిన సంగతి గుర్తు చేసి వాటిని ఇప్పుడు వాడుకోమని మంధర కైకకు ఉపాయం చెప్పింది."
                    },
                ]
            },
            {
                "partname": "3.కైక కోరికలు",
                "description": [
                    {
                        "des": "దశరథుడు అంతఃపురానికి వచ్చేసరికి కైక సకలాలంకారాలూ త్యజించి కోపగృహంలో విషణ్ణవదనయై ఉంది. ఆమెను అనునయిస్తూ దశరథుడు ఆమె అడిగిందిస్తానని రామునిమీద, తాను చేసుకున్న పుణ్యం మీద ఒట్టు పెట్టి చెప్పాడు. ఆ అదను చూసుకొని అంతకు పూర్వం దేవాసుర యుద్ధంలో దశరథుడు తనకిచ్చిన రెండు వరాలు ఇప్పుడు చెల్లించాలని కోరింది. ఆ రెండు కోరికలు - (1) భరతుని పట్టాభిషేకము (2) రామునకు 14 ఏండ్ల వనవాసము. కైక మాటలు విని దశరథుడు కుప్పకూలిపోయాడు. అది అధర్మమనీ, అందుకు భరతుడు కూడా సమ్మతించడనీ, అంతే గాక తాను పట్టాభిషేకాన్ని అందరిముందూ ప్రకటించాననీ, కనుక ఆ రెండు కోరికలను ఉపసంహరించుకోమనీ కైకను బ్రతిమాలాడు. నిందించాడు. అయినా కైక తన పట్టు వీడలేదు."
                    },
                    {
                        "des": "మరునాడు వశిష్ఠుడు, సుమంతుడు పట్టాభిషేకం జరిపించడానికి దశరథుని వద్దకు వచ్చారు. దశరథుడు దీనుడై నోటమాటరాని స్థితిలో ఉన్నాడు. కైకయే రాముని పిలిపించి దశరథుని సమక్షంలోనే అంతకుముందు దశరథుడు తనకిచ్చిన వరాల గురించి చెప్పింది. రాముడు కించిత్తైనా దుఃఖం లేకుండా తండ్రి మాట ప్రకారం వనవాసానికి వెళ్ళడానికి తాను సిద్ధమనీ, వెంటనే భరతుని పిలిచి పట్టం కట్టమనీ చెప్పాడు."
                    },
                ]
            },
            {
                "partname": "4.సీతారామలక్ష్మణుల వనవాస దీక్ష",
                "description": [
                    {
                        "des": "కౌసల్యా లక్ష్మణుడూ రాముని వనవాసాన్ని నిరోధింప యత్నించారు కాని రాముడు కృత నిశ్చయుడై ఉన్నాడు. మతిమాలిన రాజు మాటలు లెక్క జేయకుండా రాముడు రాజ్యాన్ని హస్తగతం చేసుకోవడం ధర్మమేననీ, అన్న కోసం ఎందరినైనా ఎదిరించడానికి తాను సిద్ధమనీ లక్ష్మణుడన్నాడు. తండ్రిలానే బిడ్డపై హక్కు కలిగిన తాను రాముని వనవాసానికి అనుజ్ఞ ఇవ్వనని కౌసల్య చెప్పింది. తండ్రి మాట నిలబెట్టడం లక్ష్మణుని బాధ్యత కూడాననీ, భర్త మాట నిలబెట్టడం కౌసల్య ధర్మమనీ వారిద్దరికీ నచ్చచెప్పి రాముడు వనవాస దీక్షకు ఉద్యుక్తుడయ్యాడు."
                    },
                    {
                        "des": "ఇక సంగతి తెలిసిన సీత తాను కూడా వనవాసానికి రామునితోడుగా వస్తానన్నది. సుకుమారియైన రాజబిడ్డకు వనవాసం దుస్సహమని రాముడు చెప్పినా సీత వినలేదు.- తనకు వనవాస యోగమున్నదని జ్యోతిష్కులు చెప్పారు. రాముడు తోడుంటే తనకు కష్టాలు, భయాలు ఉండనే ఉండవు. తనను తోడు తీసుకువెళ్ళకుంటే రాముడు భార్యను రక్షించుకోవడం చేతకాని భయస్తుడే – ఇలా మొండిగా వాదించి సీత రాముని వెంట బయలుదేరింది. తల్లి అనుజ్ఞ తీసికొని, అన్నతో వాదించి, వనవాస సమయంలో అన్నా వదినల సేవ జేయడానికి లక్ష్మణుడు కూడా వారివెంట ప్రయాణమయ్యాడు."
                    },
                ]
            },
            {
                "partname": "5.వన ప్రయాణం",
                "description": [
                    {
                        "des": "సీతా రామ లక్ష్మణులు తమ సంపదలను అందరికీ దానాలు చేశారు. రాముడు తన ఆభరణాలను సుయజ్ఞునికీ, సీత తన ఆభరణాలను సుయజ్ఞుని భార్యకూ ఇచ్చారు. తల్లిదండ్రుల సెలవు తీసుకొన్నారు. కటువుగా కైక వారికి నారచీరలు ఇప్పించింది. అన్నను సేవించమని సుమిత్ర లక్ష్మణునికి ఆనతిచ్చింది. సుమంత్రుడు రథంపై వారిని తీసుకొని ప్రయాణమైనాడు. రాజపరివారం దుఃఖించింది. దశరథుడు నేలపై బడి యేడుస్తున్నాడు. సుమిత్ర కౌసల్యను ఓదార్చింది. అయోధ్యాపుర వాసులు వారిని వెంబడించసాగారు. వెనుదిరగడానికి నిరాకరించారు."
                    },
                    {
                        "des": "తమసానది ఒడ్డున మొదటి రాత్రి విశ్రమించిన సీతారామలక్ష్మణులు ఎలాగో అయోధ్యాపుర వాసులను ఏమరచి, చీకటిలో కోసలదేశం దాటిపోయారు. వేదశృతి, గోమతి, స్యందిక నదులను దాటి గంగానది ఒడ్డున ఉన్న శృంగిబేరపురం చేరుకున్నారు. ఒక చెట్టుక్రింద విశ్రమించారు."
                    },
                ]
            },
            {
                "partname": "6.గుహుని ఆతిథ్యం",
                "description": [
                    {
                        "des": "క్కడి బోయ రాజైన గుహుడు సపరివారంగా వచ్చి రాముని కౌగిలించుకొని ఉత్తమమైన ఆతిథ్యాన్ని అందించాడు. తన రాజ్యాన్ని ఏలుకోమని రాముని ప్రార్థిచాడు. రాముడు వనవాస దీక్షలో ఉన్నందున వారు సమర్పించిన భక్ష్యాదులను నిరాకరించి, రాజుగారి గుర్రాలకు మాత్రం మేత ఇమ్మన్నాడు. సీతారాములు మాత్రం నేల మీదే విశ్రమించారు. వారిని చూచి లక్ష్మణుడు దుఃఖించాడు."
                    },
                    {
                        "des": "వెనుకకు వెళ్ళడానికి మనసొప్పని సుమంత్రునికి నచ్చజెప్పి రాముడు అతనిని అయోధ్యకు పంపాడు. గుహుడు ఏర్పాటు చేసిన నావలో సీతారామలక్ష్మణులు గంగానదిని దాటారు. సీత గంగమ్మకు నమస్కరించి తమను కాపాడమని ప్రార్థిచింది."
                    },
                ]
            },
            {
                "partname": "7.చిత్రకూట నివాసం",
                "description": [
                    {
                        "des": "సీతారామలక్ష్మణులు ప్రయాణం కొనసాగించి గంగా యమునా సంగమ స్థానమైన ప్రయాగ వద్ద భరద్వాజాశ్రమాన్ని చేరుకొన్నారు. ముని వారిని ఆదరించి అక్కడే వనవాస కాలాన్ని గడపమన్నాడు. కాని అది జనావాసాలకు సమీపంలో ఉన్నందున అక్కడ ఉండడానికి రాముడు ఇష్టపడలేదు."
                    },
                    {
                        "des": "భరద్వాజ మహర్షి సూచన ప్రకారం వారు ఒక తెప్ప తయారు చేసుకొని యమునానదిని దాటి వెళ్ళారు. దారిలో మహత్తు గల ఒక పెద్ద మర్రిచెట్టుకు సీత నమస్కరించింది. అరణ్యంలో ముందుకు సాగి వారు చిత్రకూటం అనే సుందరమైన ప్రదేశం చేరుకొన్నారు. అక్కడ లక్ష్మణుడు దృఢమైన పర్ణశాలను నిర్మించాడు. రాముడు మాల్యవతీ నదిలో స్నానం చేసి, వాస్తు పూజావిధులు నెరవేర్చాడు. అక్కడ వారి నివాసం ఆరంభమైంది."
                    },
                ]
            },
            {
                "partname": "8. దశరధుని మరణం",
                "description": [
                    {
                        "des": "సుమంత్రుడు గుహుని వద్ద వీడ్కోలు తీసికొని శోకదగ్ధమైన అయోధ్యానగరానికి తిరిగి వచ్చాడు. పరితపిస్తూ అంతఃపురానికి వెళ్ళి దశరథునికి జరిగినది వివరించాడు. దశరథుడు కృశించి దీనుడై దుఃఖిస్తూ ఉన్నాడు.దశరథుడు తన యౌవనంలో శబ్దవేధ విద్యలో ప్రజ్ఞుడు. ఒకమారు కారుచీకటిలో అతను నీటిలో శబ్దాన్ని బట్టి, ఏదో ఏనుగు తొండంతో నీళ్ళు త్రాగుతుందనుకొని బాణం వేశాడు. "
                    },
                    {
                        "des": "కాని ఒక మునికుమారుడు అంధులైన తన తల్లిదండ్రులకోసం కుండలో నీరు పట్టుడం వల్ల ఆ శబ్దం వచ్చింది. దశరథుని బాణానికి ఆ మునికుమారుడు మరణించాడు. పశ్చాత్తాపంతో హతాశుడైన దశరథుడు ఆ ముని కుమారుని తల్లిదండ్రులకు తన వల్ల జరిగిన తప్పిదం విన్నవించాడు. వారు కొడుకు శవంపై బడి విలపించారు. దశరథుడు కూడా పుత్రశోకంతోనే కాలం చేస్తాడని ఆ తండ్రి శపించాడు.ఈ శాప వృత్తాంతాన్ని కౌసల్య, సుమిత్రలకు చెప్పి దశరథుడు తన దుష్కృత్యానికి తగిన ఫలం అనుభవిస్తున్నానని శోకించాడు. రామునికోసం విలపిస్తూనే మృతిపొందాడు."
                    },
                ]
            },
            {
                "partname": "9.భరతుని దుఃఖం",
                "description": [
                    {
                        "des": "అయోధ్య మరింత శోకంలో మునిగిపోయింది. వెంటనే రాజ్యాభిషేకానికి రమ్మని వశిష్ఠుడు గిరివ్రజంలో మేనమామల ఇంట్లో ఉన్న భరతునికి కబురు పంపాడు. అప్పటికే భరతుడు దుస్వప్నం కారణంగా వ్యాకులచిత్తుడై ఉన్నాడు. అతనికి జరిగిన సంగతులు అన్నీ చెప్పకుండా దూతలు అయోధ్యకు తోడ్కొనివచ్చారు. కైక భరతుని త్వరగా పట్టాభిషేకం చేయించుకోమని తొందర చేసింది. దశరథుడు మరణించిన సంగతీ, సీతారామలక్ష్మణులు అడవులకు పోయిన సంగతీ చెప్పింది. అంతా తన కొడుకు మేలు కోసమే చేశానని చెప్పింది."
                    },
                    {
                        "des": "కోపంతోనూ, రోషంతోనూ, దుఃఖంతోనూ భరతుడు మండిపడ్డాడు. అధర్మానికి ఒడిగట్టిన తల్లిని తీవ్రంగా నిందించాడు. రాముని వనవాసం మాన్పించి తిరిగి అయోధ్యకు పిలచి పట్టం గట్టి, తాను అన్నను సేవిస్తానని ఖండితంగా చెప్పాడు. తనకేమీ తెలియదని అమాత్యులతో చెప్పి దుఃఖించాడు. మన్నించమని కౌసల్యను వేడుకున్నాడు."
                    },
                    {
                        "des": "వశిష్ఠుని ఆదేశంపై భరతుడు తండ్రికి అగ్ని సంస్కారం చేశాడు. పండ్రెండో దినాన శ్రాద్ధ కర్మలన్నీ పూర్తి చేశాడు. పధ్నాలుగవ నాడు భరతుని రాజ్యాభిషిక్తుని కమ్మని రాజోద్యోగులు కోరారు. భరతుడు వారికి నమస్కరించి, నిరాకరించాడు. రాముడే రాజు కావాలని, రాముని అయోధ్యకుతెచ్చి అభిషిక్తుడిని చేసి తాను మాత్రం తల్లి కోరికకు వ్యతిరేకంగా అడవులకు పోతానని దృఢంగా అన్నాడు."
                    }
                ]
            },
            {
                "partname": "10.భరతుని ప్రయాణం",
                "description": [
                    {
                        "des": "రాముణ్ణి రాజుగా చేయడానికి అయోధ్యకు పిలవాలని భరతుడు సపరివారంగా బయలు దేరాడు. దారిలో గంగాజలంతో తండ్రికి తర్పణం చేశాడు. గుహుని కలసి జరిగిన సంగతులు తెలిసికొని విలపించాడు. గంగను దాటి భరద్వాజాశ్రమం చేరుకొని మునిని ప్రసన్నం చేసుకొన్నాడు. సీతారామలక్ష్మణులు చిత్రకూటంలో ఉన్నారని తెలిసికొన్నాడు.చిత్రకూటంలో సీతారాములు మందాకినీ పరిసర సౌందర్యం చూసి పరవశిస్తున్నారు. "
                    },
                    {
                        "des": "పెద్ద కోలాహలం విని లక్ష్మణుడు చెట్టుపైకెక్కి గొప్ప సైన్యాన్ని చూశాడు. కోవిదార ధ్వజాన్ని బట్టి అది భరతుని సైన్యమే అని గ్రహించాడు. తన రాజ్యం నిష్కంటకం చేసుకోవడానికి భరతుడు ససైన్యంగా వస్తున్నాడని భావించి రోషంతో యుద్ధానికి సన్నద్ధుడయ్యాడు. అయితే భరతుని ధర్మ నిరతిని సంశయింపవద్దని రాముడు లక్ష్మణునికి చెప్పగా అతను తన తొందరపాటుకు సిగ్గుపడ్డాడు."
                    },
                    {
                        "des": "సైన్యాన్ని దూరంగా ఉంచి, భరతుడు, శత్రుఘ్నుడు, గుహుడు, వశిష్ఠ మహర్షి, సుమంత్రుడు, మరి కొందరు అమాత్య బ్రాహ్మణ ప్రముఖులు రాముని పర్ణశాలకు చేరుకున్నారు. భరత శత్రుఘ్నులు సీతారామలక్ష్మణుల పాదాలపైబడి శోకంతో నోట మాట రాక విలపించారు."
                    }
                ]
            },
            {
                "partname": "11.పితృవాక్య పాలన",
                "description": [
                    {
                        "des": "రాముడు భరతుని లేవనెత్తి కుశలమడిగాడు. పుత్రశోకంతో తండ్రి మరణించిన వార్త తెలియగానే రాముడు మూర్ఛిల్లాడు. పిదప లేచిన రాముడు అమితంగా దుఃఖిస్తూ మందాకినీ జలాలతో దశరథునికి తర్పణం వదిలాడు. తరువాత దశరథుని భార్యలు కూడా పర్ణశాలకు చేరుకున్నారు. రాముడు, లక్ష్మణుడు తల్లులకు పాదాభివందనాలు చేశారు. సీత కన్నీటితో వచ్చి అత్తల కాళ్ళకు మ్రొక్కింది."
                    },
                    {
                        "des": "భరతుడు దీనుడై రాముని పాదాలకు మ్రొక్కి – “నేను నీ భృత్యుడిని. నీకు చెందవలసిన రాజ్యాన్ని నేను పొందలేను. నాపై దయ వుంచి నీ రాజ్యం నీవు గ్రహించి మమ్ములను అనుగ్రహించు” అని కోరాడు. అప్పుడు రాముడు “తండ్రి నీకు రాజ్యమూ, నాకు వనవాస దీక్షా ఇచ్చాడు. ఇద్దరమూ వాటిని అలా అనుభవించాల్సిందే అని బదులు చెప్పాడు."
                    },
                    {
                        "des": "ఎవరెన్ని విధాలుగా చెప్పినా వనవాసం విరమించుకోవడానికి రాముడు అంగీకరించలేదు. తండ్రి ఋణం తీర్చుకోవడానికి, ఆయనకు అసత్య దోషం అంటకుండా ఉండడానికి అదే మార్గమని స్థిరంగా చెప్పాడు."
                    }
                ]
            },
            {
                "partname": "12. శ్రీరాముని పాదుకల రాజ్యం",
                "description": [
                    {
                        "des": "శ్రీరాముని తిరస్కారంతో భరతుడు దర్భలు పరచుకొని అడ్డంగా పడుకున్నాడు. అన్నయ్య తన ప్రార్థన అంగీకరించే వరకు అన్నం నీళ్ళు ముట్టనన్నాడు. రాముడు భరతుని అనునయించి పితృఋణం తీర్చుకునే ధన్యత నుండి తనను దూరం చేయవద్దని కోరాడు. ఖిన్నుడైన భరతుడు ధర్మమార్గమేదో నిర్ణయించి ఆజ్ఞాపించమని రాముని పాదాలపై వాలాడు. రాముడు భరతునకు రాజధర్మం బోధించి, రాజ్యం చేయమని ఆదేశించాడు. ప్రలోభం వల్ల చేసిన కైక తప్పిదాన్ని మరచి తల్లిని భక్తితో సేవించమని చెప్పాడు."
                    },
                    {
                        "des": "భరతుడు శ్రీరాముని పాదుకలను అనుగ్రహించమని కోరాడు. అందుకు రాముడు సమ్మతించాడు. పధ్నాలుగు సంవత్సరాలు తాను కందమూలాలు మాత్రం తింటూ, నగరం బయటనే నివసిస్తూ, అన్నగారి పాదుకల పేరునే రాజ్యం చేస్తానని భరతుడు చెప్పాడు. పధ్నాలుగు సంవత్సరాలు అయిన మరునాడు రాముడిని చూడకపోతే తాను అగ్నిలో దూకుతానన్నాడు."
                    },
                    {
                        "des": "అందరికీ నమస్కరించి పాదుకలు శిరసున ధరించి అయోధ్యకు తిరిగి ప్రయాణమయ్యాడు. దారిలో భరద్వాజ మహర్షికి జరిగిన విషయం విన్నవించాడు.అందరినీ అయోధ్యకు పంపి భరతుడు తాను మాత్రం నందిగ్రామంలోనే ఉండిపోయాడు. పాదుకలకు సకల రాజమర్యాదలూ జరిపించాడు. తాను నారచీరలు ధరించి అన్నగారి పాదుకల పేరున రాజ్యపాలన సాగించాడు."
                    }
                ]
            },
            {
                "partname": "13.అత్రి, అనసూయ, సీత ",
                "description": [
                    {
                        "des": "రాముని మనసు వికలమైపోయింది. చిత్రకూటంలో ఉండ మనసు కాలేదు. అంతే గాక అక్కడి మునులు ఖర దూషణాది రాక్షసుల వలన భయపడుతున్నట్లు గ్రహించాడు.సీతారామలక్ష్మణులు అత్రి మహర్షి ఆశ్రమాన్ని దర్శించారు. అత్రి భార్య అనసూయ. ఆమెకు సీత పాదాభివందనం చేసింది. అనసూయ సీతకు పతివ్రతా ధర్మాలను ఉపదేశించి మహత్తు గల పూలదండ, చందనం, వస్త్రాభరణాలు ఇచ్చింది. అనసూయ కోరికపై సీత తన స్వయంవరం, కళ్యాణం కథను ఆమెకు చెప్పింది. "
                    },
                    {
                        "des": "అనసూయ మురిసిపోయింది. ఆమె ఇచ్చిన వస్త్రాభరణాదులు ధరించి సీత ఆమెకు మరల పాదాభివందనం చేసింది.మరువాడు వారివద్ద సెలవు పుచ్చుకొని సీతారామలక్ష్మణులు, సూర్యుడు మేఘ మండలంలో ప్రవేశించినట్లు, ఇంకా దట్టమైన అరణ్యంలో ప్రవేశించారు."
                    },
                ]
            }
        ]
    },
    {
        "title": "అరణ్యకాండము",
        "subtitle": [
            {
                "partname": "1.విరాధ వధ",
                "description": [
                    {
                        "des": "అత్రి మహర్షి, అనసూయల ఆశీర్వచనాలు పొంది సీతారామలక్ష్మణులు దండకారణ్యంలో ముందుకు సాగారు. ఒక మునిపల్లెలో ఋషులు వారికి మంగళాశీర్వచనాలు చేసి సత్కరించారు. తమను రక్షించమని రాముని కోరారు. ఆ రాత్రికి అక్కడ విశ్రమించి వారు ప్రయాణం కొనసాగించి ఒక భయంకరమైన కీకారణ్యంలో ప్రవేశించారు."
                    },
                    {
                        "des": "వారికి భారీ శరీరంతో వికృతంగా ఉన్న విరాధుడనే రాక్షసుడు ఎదురుపడ్డాడు. వాడు సావకాశంగా తినడానికి తన శూలానికి మూడు సింహాలను, నాలుగు పులులను, రెండు తోడేళ్ళను, పది జింకలను, పెద్ద యేనుగు తలను గుచ్చి భుజాన పెట్టుకొని ఉన్నాడు. అతడు అసలు తుంబురుడనే గంధర్వుడు కాని కుబేరుని శాప కారణంగా రాక్షసుడయ్యాడు. అతనికి ఏ శస్త్రంతోనూ చావకుండా వరముంది. రామునిచేతనే అతనికి శాపవిముక్తి కావాలి."
                    },
                    {
                        "des": "ఆ విరాధుడు సీతను పట్టుకుపోసాగాడు. కాని రాముని పదును బాణాలవలన కోపించి, సీతను విడచి, రామ లక్ష్మణులను చేతులలో ఇరికించుకుపోసాగాడు.రామ లక్ష్మణులు విరాధుని చేతులు నరికేశారు. శాపవిముక్తి కలిగిన విరాధుడు వారెవరో తెలిసికొని రాముని శరభంగ మహర్షి ఆశ్రమానికి వెళ్ళమని చెప్పాడు. ఏనుగు పట్టేటంత గోతిని తీసి రామక్ష్మణులు వాడి దేహాన్ని పూడ్చిపెట్టారు."
                    },
                ]
            },
            {
                "partname": "2.ముని ఆశ్రమాల సందర్శనం",
                "description": [
                    {
                        "des": "బ్రహ్మ సాక్షాత్కారం పొందిన శరభంగ మహర్షి రాముని కోసమే తాను బ్రహ్మలోకానికి వెళ్ళకుండా వేచియున్నాడు. తన తపస్సు పుణ్యాన్ని రామునికి సమర్పించి, వారిని సుతీక్ష్ణ మహర్షి వద్దకు వెళ్ళమన్నాడు. తరువాత శరభంగుడు యోగశక్తితో అగ్నిని ప్రజ్వలింపజేసి అందులో భస్మమై బ్రహ్మలోకం చేరుకొన్నాడు.ఎందరో మునులు రాముని కలిసికొని తాము రాక్షసులవలన పడే బాధలు చెప్పుకొన్నారు. రాక్షస బాధ లేకుండా వారిని కాపాడుతానని రాముడు అభయమిచ్చాడు."
                    },
                    {
                        "des": "తరువాత సీతారామలక్ష్మణులు సుతీక్ష్ణ మహర్షి ఆశ్రమానికి వెళ్ళారు. ఆ మహర్షి కూడా రాముని దర్శనం కోసమే దేవలోకం వెళ్ళకుండా వేచియున్నాడు. స్వయంగా ఫలమూలాలు వారికి వడ్డించి ఆదరించాడు. మహర్షికి ప్రణమిల్లి, సెలవు తీసికొని రామ లక్ష్మణులు ఆయుధాలు ధరించి సీతా సమేతంగా దండకారణ్యంలో ఉన్న అవేక మునుల ఆశ్రమాలను చూడడానికి బయలుదేరారు."
                    },
                    {
                        "des": "ఆయుధ ధారులైన రామ లక్ష్మణులకు సీత ఇలా తన మనసులోని మాట చెప్పింది – ముని ధర్మం చాలా క్లిష్టమైనది. అసత్య వాక్యం, పరస్త్రీ గమనం, అకారణ హింస అనే మూడు అనుచిత ప్రవర్తనలు తపోదీక్షకు భంగం చేసే అవకాశం ఉంది. ధనుర్బాణాలు ధరించినందువలన ఆయుధ ప్రయోగానికి అనవుసర ప్రోత్సాహం లభించే అవకాశం కలుగుతుంది. కనుక ఆర్తులను కాపాడడానికి మాత్రమే శస్త్రాలను వాడదగును."
                    },
                    {
                        "des": "కాని సమయంలో అరణ్యాలకు తగిన మునివృత్తి అవలంబించడం ఉచితం. నేను స్త్రీ సహజమైన చాపల్యం వల్లనే ఇలా చెబుతున్నాను. మీరు కర్తవ్యం తెలియని వారు కాదు. సీత మాటలను రాముడు ప్రశంసించి, ఆర్తులై తనను శరణు జొచ్చిన మునుల రక్షణ కోసమే ఆయుధ ప్రయోగం చేస్తానని చెప్పాడు."
                    },
                    {
                        "des": "రమ్యమైన తపోవనాలగుండా సీతారామలక్ష్మణులు ముందుకు సాగారు. దారిలో పంచాప్సరసం అనే సుందరమైన తటాకాన్ని చూశారు. మాండకర్ణి అనే మహర్షి తన తపోబలంతో దానిని నిర్మించి, అందులో నీటి అడుగున అంతర్గృహంలో అప్సరసలతో క్రీడిస్తున్నందున అక్కడ ఎప్పుడూ మధురమైన సంగీతం వినవస్తున్నది. ఇలా అనేక ఆశ్రమాలు దర్శించి మరల సుతీక్ష్ణ మహర్షి ఆశ్రమానికి తిరిగి వచ్చారు. ఆయనను అగస్త్య మహర్షి ఆశ్రమానికి దారి అడిగారు."
                    },
                ]
            },
            {
                "partname": "3.అగస్త్యాశ్రమ దర్శనం",
                "description": [
                    {
                        "des": "సుతీక్ష్ణ మహర్షి చెప్పిన ప్రకారం సీతారామలక్ష్మణులు ముందుగా అగస్త్యభ్రాత ఆశ్రమానికి వెళ్ళి ఆ ముని ఆతిథ్యాన్ని స్వీకరించారు. ముందుకు సాగి అగస్త్య మహర్షి ఆశ్రమానికి చేరుకొన్నారు. అగస్త్యుడు మృత్యువును జయించిన మహాతపస్వి. వింధ్య పర్వతం పెరుగుదలను నిలిపాడు. నరమాంస భుక్కులైన వాతాపి ఇల్వలులను నాశనం చేశాడు. దక్షిణ దిక్కును మునులకు ఆవాస యోగ్యంగా చేశాడు."
                    },
                    {
                        "des": "సీతారామలక్ష్మణులు అగస్త్యునికి పాదాభివందనం చేశారు. అగస్త్యుడు వారిని ఆదరించి వానప్రస్థధర్మానుసారం భోజనాలు వడ్డించి కుశలమడిగాడు. విశ్వకర్మ నిర్మించిన గొప్ప వైష్ణవధనుస్సును రామునకిచ్చాడు. సీతాదేవి పతివ్రతాధర్మాన్ని శ్లాఘించాడు."
                    },
                ]
            },
            {
                "partname": "4.పంచవటిలో నివాసం",
                "description": [
                    {
                        "des": "వారిని గోదావరీతటాన పంచవటిలో ఆశ్రమం నిర్మించుకొని నివసించమని అగస్త్యుడు సూచించాడు. పంచవటికి వెళ్ళేదారిలో వారికి జటాయువు అనే పెద్ద గ్రద్ద రాజు కనిపించాడు. తాను దశరధుని మిత్రుడనని, ఆశ్రమసమీపంలో సీతను కనిపెట్టుకొని ఉంటానని అన్నాడు.పంచవటిలో రాముడు చూపిన స్థలంలో లక్ష్మణుడు చక్కని పర్ణశాల నిర్మించాడు. అది సీతాములకు స్వర్గంలా అనిపించింది. అక్కడ వారు చాలా కాలం సంతోషంగా గడిపారు."
                    },
                ]
            },
            {
                "partname": "5.శూర్పణఖ భంగం",
                "description": [
                    {
                        "des": " రావణుని చెల్లెలు శూర్పణఖ అనే రాక్షసి ఆ అడవిలో స్వేచ్ఛగా సంచరిస్తున్నది. ఆమె కామరూపి. ఒకమారు వారి పర్ణశాలకు వచ్చి రాముని చూచి మోహించి తనను పెళ్ళి చేసుకోమని అడిగింది. రాముడు, లక్ష్మణుడు ఆమెతో పరిహాసాలాడారు. ఆమె కోపించి సీతను తినివేయబోయింది. అపుడు రాముని ఆజ్ఞతో లక్ష్మణుడు శూర్పణఖ ముక్కూ, చెవులూ కోసివేశాడు."
                    },
                    {
                        "des": "శూర్పణఖ యేడుస్తూ తన సోదరుడైన ఖరునితో జరిగిన విషయం మొరపెట్టుకుంది. ఖరుడు యముళ్ళాంటి పధ్నాలుగు రాక్షసులను పిలిచి రామలక్ష్మణులను చంపిరమ్మని ఆజ్ఞాపించాడు. పదునాలుగు బాణాలతో రాముడు వారిని సంహరించేశాడు. శూర్పణఖ బావురుమంటూ ఖరునివద్దకుపోయి అతను చేతకానివాడని దెప్పిపొడిచింది. ఉద్రిక్తుడైన ఖరుడూ, అతని సేనాధిపతి దూషణుడూ వీరాధివీరులైన పధ్నాలుగు వేల రాక్షససేనతో దిక్కులు పిక్కటిల్లే పెడబొబ్బలతో, భేరీభాంకారాలతో, సాగరంవలె పొంగుతూ రామలక్ష్మణులపై దండెత్తారు."
                    },
                ]
            },
            {
                "partname": "6.ఖరదూషణాదుల సంహారం",
                "description": [
                    {
                        "des": "ఆకాశంలో పుట్టిన ఉత్పాతాలను గమనించాడు రాముడు. రాముని ఆజ్ఞతో లక్ష్మణుడు ధనసు ధరించి, సీతను వెంటబెట్టుకొని, ఒక దుర్గంలా ఉన్న గుహలోనికి వెళ్ళిపోయాడు. అగ్నిలాగా వెలుగుతున్న రాముడు కవచం తొడుగుకొని ధనుర్ధారియై నారి మోగిస్తూ రాక్షసులకు ఎదురు వచ్చాడు. వారి యుద్ధం చూడడానికి ఆకాశంలో మహర్షులు, దేవ గంధర్వ సిద్ధ చారణాదులు గుమికూడి రామునకు మంగళం పలికారు."
                    },
                    {
                        "des": "ఖరుడు, అతని సైన్యం పెద్ద పొలికేకతో రామునిపై యుద్ధానికి దిగారు. యమపాశాల వంటి రాముని బాణాలకు ఎందరో రాక్షసవీరులు నేలకూలారు. మిగిలినవారు పారిపోయి ఖరుని శరణు జొచ్చారు. దూషణుడు క్రోధంతో రామునిపైకి వచ్చాడు. వాడి బాణాలతో రాముడు దూషణుని మహాధనుస్సునూ, గుర్రాలనూ, సారధినీ నేలకూల్చాడు. మరో మూడు బాణాలతో వాడి గుండెలు పగులగొట్టి రెండు బాణాలతో వాడి రెండు చేతులూ నరికేశాడు."
                    },
                    {
                        "des": "నేలబడిన దూషణుని చూసి క్రుద్ధుడైపోయిన ఖరుడు తన పన్నెండుగురు ముఖ్యసేనానులతో రామునిమీదకురికారు. వజ్రసమానమైన రాముని బాణాలతో ఆ సేనాపతులు, వారి సైన్యం ఖండఖండాలుగా నేలబడ్డారు. రణభూమి అంతా రక్త మాంసాలతో నిండిపోయింది.ఖరుడూ, త్రిశిరుడూ మాత్రమే మిగిలారు. ముందుగా త్రిశిరుడు బీరాలు పలికి రామునిపైకి వచ్చి రామబాణాలకు బలయ్యాడు."
                    },
                    {
                        "des": "ఇక ఖరుడు రాముడు ఎదురుపడ్డారు. ధనుర్విద్యా కౌశలంతో వారిద్దరూ ప్రయోగించిన బాణాలతో ఆకాశం కప్పుకొని దిక్కుల భేదం తెలియకుండా పోయింది. తరువాత ఖరుడు పెరికి తెచ్చిన పెద్ద చెట్టును రాముడు తునకలు చేసివేశాడు. ఖరుని దేహం తూట్లుపడేలా బాణాలతో కొట్టాడు. తన దేహం అంతా రక్తం ధారలు కారుతుండగా ఖరుడు రామునిమీదకు ఉరికాడు. దానితో రాముడు అగ్నిలా మెరుస్తున్న బాణం తొడిగివిడిచాడు. ఖరుడు నేలకొరిగాడు."
                    }
                ]
            },
            {
                "partname": "7.రావణునితో శూర్పణఖ గోడు",
                "description": [
                    {
                        "des": "పధ్నాలుగు వేల మంది రాక్షసులూ, ఖరుడు, దూషణుడు, త్రిశిరుడు మరణించడంతో శూర్పణఖ పెడబొబ్బలు పెడుతూ లంకకు పోయింది. తన పెద్దన్న రావణుని తీవ్రంగా నిందించి హెచ్చరించింది – నువ్వు స్త్రీలోలుడవై రానున్న అపాయాన్ని తెలిసికోలేకపోతున్నావు. రాజు అనేవాడికి సరైన చారులుండాలి. రాజు బొక్కసం నిండి ఉండాలి. రాజనీతిని అనుసరించాలి. ఈ మూడూ లేకపోతే ఆరాజు పతనం ఖాయం. జన స్థానంలో మనవాళ్ళందరూ రాముడనే నరుని చేతిలో హతులయ్యారు."
                    },
                    {
                        "des": "దశరధుని కొడుకులైన రామ లక్ష్మణులు జనస్థానాన్ని రాక్షసవిహీనం చేస్తున్నారు. ఆ రాముని భార్య సీత నీకు తగిన అందాల రాశి. కనుక రాముని సంహరించి, సీతను నీదానిని చేసికొని రాక్షస జాతి ఋణం తీర్చుకో – అని శూర్పణఖ రావణునితో మొత్తుకొంది.ఆమె మాటలతో రావణుడు సీతను అపహరించి తేవడానికి సిద్ధమయ్యాడు. అందుకు ఒక పన్నాగం ఆలోచించి అడవిలో తపస్సు చేసుకొంటున్న మారీచుని వద్దకు వెళ్ళాడు. సీతాపహరణం కోసం మాయలేడిగా మారి రాముని తప్పించడానికి సహాయపడమన్నాడు."
                    },
                ]
            },
            {
                "partname": "8.రావణునకు మారీచుని",
                "description": [
                    {
                        "des": "రాముడి పేరు వినేసరికి మారీచుని ముఖంలో ప్రేతకళ వచ్చేసింది. భయంతో కొయ్యబారిపోయి, రావణునికిలా హితం బోధించాడు – రాముడు మహావీరుడు. సత్యధర్మ వ్రతుడు. అతనితో వైరం పెట్టుకుంటే నీకు, నీ జాతికి పోగాలం దాపురించినట్లే. ఇదివరకు నేను వెయ్యేనుగుల బలంతో ఎదురు లేకుండా భూలోకం అంతా తిరిగేవాడిని. అప్పుడు నేను విశ్వామిత్రుని యాగం ధ్వంసం చేయబోయాను. యాగాన్ని రక్షిస్తున్న రాముడు సుబాహుడిని, నా రాక్షస గణాలను సంహరించి నన్ను మాత్రం శరాఘాతంతో నూరామడల దూరాన సముద్రంలో పారవేశాడు. అప్పటి రాముడు పన్నెండేళ్ళ బాలుడు మాత్రమే. అప్పటినుండి నాకు ‘ర’ శబ్దంతో మొదలయ్యే రథం, రత్నం వంటి పదాలంటేనే వణుకు పట్టుకుంది. కనుక మన ఇరువురి మేలు కోరి చెబుతున్నాను. ఈ ఆలోచన నీకు కలిగించినవారు రాక్షసజాతి నాశనం కోరినవారే. రామునితో వైరం మాని, శరణు వేడుకో – అన్నాడు."
                    },
                    {
                        "des": " రావణుడు కఠినంగా ఇలా అన్నాడు – దేవతలు ప్రార్ధించినా నా నిశ్చయం మారదు. నువ్వు బంగారు లేడివై సీతను ఆకర్షించాలి. అందుద్వారా రామలక్ష్మణులను పర్ణశాలనుండి దూరంగా పంపాలి. నేను నిన్ను సలహా అడుగలేదు. పని చెబుతున్నాను. అది చేయకపోతే నా చేతిలో నీకు చావు తప్పదు.ఇక మారీచుడికి మార్గం తోచలేదు – రావణా మనిద్దరికీ రాముని చేత చావు తప్పదు. నీ చేత కంటే రాముని చేత చావడమే నాకు ఉత్తమం అనుకొన్నాడు. వారిద్దరూ పర్ణశాల వైపుకు వెళ్ళారు."
                    },
                ]
            },
            {
                "partname": "9.మాయలేడితో మోసం",
                "description": [
                    {
                        "des": "సీత పర్ణశాల వద్ద పూవులు కోసుకొంటూ ఇక అరణ్యవాసం ఎంతోకాలం లేదనుకొంటున్నది. ఆ సమయంలో ఆమెకు వెండి చుక్కలతో మెరుస్తున్న అపూర్వమైన బంగారు లేడి కంటబడింది. వయ్యారాలు పోతూ గెంతుతున్న ఆ లేడిని చూచి సీత అది తనకు నచ్చిందనీ, దాన్ని తెచ్చిపెట్టమనీ రాముని కోరింది. అది రాక్షస మాయ అని, లోకంలో అలాంటి లేడులుండవని లక్ష్మణుడు గట్టిగా చెప్పాడు. రాక్షసుడైతే చంపి వస్తానని, అంతవరకు సీతను జాగ్రత్తగా కనిపెట్టుకొని ఉండమని లక్ష్మణునకు చెప్పి రాముడు బయలు దేరాడు.ఆ లేడి గెంతుతూ, మాయమౌతూ, మళ్ళీ కనబడుతూ రాముడిని చాలా దూరం తీసుకుపోయింది. ఇక లాభం లేదనుకొని రాముడు ధనుస్సు ఎక్కుపెట్టి బాణం విడిచాడు. "
                    },
                    {
                        "des": "దానితో ఆ లేడి మారీచునిగా నిజరూపం ధరించింది. మారీచుడు అయ్యో సీతా, అయ్యో లక్ష్మణా అని బిగ్గరగా అరుస్తూ ప్రాణాలు విడిచాడు. ఆపదను శంకించిన రాముడు వడివడిగా పర్ణశాలవైపు సాగాడు.ఆ ఆర్తనాదం విని, రామునికి ఏదో అపాయం సంభవించిందని సీత భయ విహ్వల అయ్యింది. త్వరగా రామునికి సహాయంగా వెళ్ళమని లక్ష్మణుని కోరింది. మూడు లోకాలూ ఎదురై వచ్చినా రాముని జయించలేరనీ, కనుక అది రాక్షస మాయయే అనీ, తాను అన్నగారి మాట ప్రకారం సీతకు రక్షణగా ఉంటాననీ లక్ష్మణుడు అన్నాడు."
                    },
                    {
                        "des": "దానితో సీత కోపించి లక్ష్మణుని పరుషంగా నిందించింది. దుర్బుద్ధితో రామునికి కీడు జరుగాలని అతను కోరుకొంటున్నాడని దూషించింది.లక్ష్మణుడు ఆ నిందలకు చింతించాడు. తనను అలా సందేహిస్తున్న ఆ తల్లి ఆపదల పాలౌతుందని వగచాడు. వన దేవతలు సీతను రక్షించాలని కోరుకొని, దుర్నిమిత్తాలకు భయపడుతూనే రాముని అన్వేషణకు బయలుదేరాడు."
                    }
                ]
            },
            {
                "partname": "10.సీతాపహరణం",
                "description": [
                    {
                        "des": "ఇదే అదనుగా చూచుకొని రావణుడు సన్యాసి వేషంలో వచ్చి సీతను పలకరించాడు. రామ లక్ష్మణులకోసం ఎదురు చూస్తూనే సీత ఆ కపట సన్యాసికి అతిథి మర్యాదలు చేయసాగింది. మాటల్లో తమ వృత్తాంతాన్ని, వనవాస కారణాన్ని వివరించింది. రావణుడు తన నిజరూపం ధరించి సీతను తనకు భార్యగా కమ్మన్నాడు. సీత అగ్ని శిఖలా మండిపడి రావణుని గడ్డిపరకలా తృణీకరించింది. తనను వాంఛిస్తే రావణునికి రాముని చేత వినాశనం తప్పదని ధిక్కరించింది.రావణుడు బలవంతంగా సీతను ఒడిసిపట్టుకొని తన రథం ఎక్కాడు. సీత ఆర్తనాదాలు విన్న జటాయువు రావణునిమీద విజృంభించాడు."
                    },

                ]
            },
            {
                "partname": "11.జటాయువు మరణం",
                "description": [
                    {
                        "des": "జటాయువు, రావణుడు రెండు పర్వతాలు కలియబడ్డట్లుగా ఢీకొన్నారు. రావణుని బాణాలను లెక్కచేయకుండా జటాయువు రావణుని రక్కేశాడు. రథాన్ని నేలపడగొట్టాడు. దానితో కోపించి రావణుడు జటాయువు పక్కలూ, రెక్కలూ, డొక్కలూ తెగనరికాడు. జటాయువు నేలబడి కొనప్రాణంతో మూలుగుతుండగా రావణుడు ఆకాశమార్గంలో లంకవైపు సాగిపోయాడు. దారిలో ఒక కొండపై కొందరు వానరులు కనబడ్డారు. రావణుడు చూడకుండా సీత తన నగలు కొన్ని మూటగట్టి వారి మధ్యకు పడవేసింది. రావణుడు సీతతో లంకకు చేరి ఆక్కడ ఆమెను అశోకవనంలో ఉంచాడు. రాక్షస స్త్రీలు ఆమెకు కాపలాగా ఉన్నారు."
                    },
                    {
                        "des": "వెనుదిరిగిన రామునికి లక్ష్మణుడు ఎదురయ్యాడు. సీతను ఒంటరిగా విడచి వచ్చినందుకు లక్ష్మణుని రాముడు తప్పుబట్టాడు. వదిన తనను అమంగళకరమైన పరుషవాక్కులతో నిష్ఠూరంగా మాట్లాడి తరిమిందని లక్ష్మణుడు దుఃఖిస్తూ చెప్పాడు. ఇద్దరూ పర్ణశాలకు వచ్చి, సీత కనపడకపోవడంతో హతాశులయ్యారు. పరమ దీనులై దుఃఖిస్తూ అంతటా వెదుకసాగారు. రాముడు గొల్లుమన్నాడు. కొన్ని లేళ్ళు దక్షిణ దిక్కును సూచించగా అటువైపు గాలిస్తూ బయలుదేరారు. వారికి రక్తసిక్తమై నేలనుబడిఉన్న జటాయువు కనిపించాడు. రావణుడు సీతను ఎత్తుకుపోయిన సంగతీ, తన రెక్కలు తెగనరికిన సంగతీ చెప్పి రాముని సమక్షంలో ప్రాణాలు విడచాడు. రాముడు అమితంగా దుఃఖించి జటాయువుకు అగ్ని సంస్కారాలు చేశాడు. మళ్ళీ అన్నదమ్ములు సీతను వెదుకుతూ బయలుదేరారు."
                    },
                ]
            },
            {
                "partname": "12.కబంధుని శాప విమోచన",
                "description": [
                    {
                        "des": "మతంగాశ్రమం సమీపంలో వారికి కబంధుడనే మహాకాయుడైన ఒక రాక్షసుడు ఎదురుపడ్డాడు. వాడికి తలా మెడా కూడా లేవు. వాని ముఖం కడుపుమీద ఉంది. వాడి చేతులు ఆమడ పొడవున్నాయి. శరీరం పర్వతంలా ఉంది. అతడు శాపవశాన రాక్షసుడైన గంధర్వుడు. వాడు తన చేతులతో రామలక్ష్మణులను ఒడిసి పట్టి తినబోయాడు. రామలక్ష్మణులు వాడి చేతులు నరికేశారు. తన శాపాన్నించి విముక్తి కలిగించే రామలక్ష్మణులు వారేనని కబంధుడు గ్రహించాడు. అతని కోరికపై వారు అతని శరీరాన్ని అగ్నికి ఆహుతి చేశారు. అప్పుడు కబంధుడు సకలాభరుణుడైన గంధర్వుడై హంసల విమానంలో ఆకాశానికి వెళుతూ – రామా! ప్రస్తుతం నీవు దుర్దశాపన్నుడవు."
                    },
                    {
                        "des": "నీవు సుగ్రీవునితో స్నేహం చేసుకొంటే సీతను వెదకడంలో అతను నీకు సహాయపడతాడు. పంపా సరస్సుకు పశ్చిమాన మాతంగముని ఆశ్రమంలో నీకోసం వేచి ఉన్న శబరికి నీ దర్శనం ప్రసాదించు. తరువాత ఆవల ఉన్న ఋష్యమూక పర్వతంపై సుగ్రీవుని కలుసుకోవచ్చు. అతని స్నేహంతో రాక్షసులనందరినీ సంహరించి నీ జీవితేశ్వరిని పొందగలవు అని చెప్పాడు."
                    },
                ]
            },
            {
                "partname": "13. శబరి సేవ",
                "description": [
                    {
                        "des": "సిద్ధురాలయిన శబరి మతంగాశ్రమంలో మునులకు సపర్యలు చేస్తుండేది. ఆమె గురువులు అంతకు పూర్వమే విమానారూఢులై స్వర్గానికి వేంచేశారు. ఆమె మాత్రం శ్రీరాముని దర్శనార్ధమై వేచిఉంది. రామలక్ష్మణుల పాదాలకు మ్రొక్కింది. మధురమైన ఫలాలతో వారికి అతిథి పూజ చేసింది. రాముడు ఆమెను కుశలమడిగాడు. ఆమె వారిని పూజించి ఆశ్రమం అంతా చూపించింది. ఆ మునులు తమ తపోప్రభావంతో సప్తసాగరాలను అక్కడికి రప్పించుకొన్నారు. ఆపై మహాత్ములైన తన గురువుల వద్దకు పోవడానికి సెలవడిగింది."
                    },
                    {
                        "des": " రాముడు ఆనందించి ఆదరంతో శబరీ! నువ్వు నన్ను చాలా భక్తితో కొలిచావు. ఇక సుఖంగా నీ ఇష్టం వచ్చిన లోకాలకు వెళ్ళు అన్నాడు. వెంటనే వృద్ధ శబరి తన జీర్ణదేహాన్ని అగ్నిలో ఆహుతి చేసుకొని సుకృతాత్ములైన తన గురువులున్న చోటికి విమానం ఎక్కి వెళ్ళిపోయంది."
                    },
                ]
            },
            {
                "partname": "14.పంపా సరస్",
                "description": [
                    {
                        "des": "రామలక్ష్మణులు మాతంగాశ్రమంలో వింతలను తిలకించారు. అక్కడ ఏర్పడిన సప్తసాగర తీరాలలో స్నానం చేసి పితృదేవులకు తర్పణాలు విడచారు. అమంగళాలన్నీ నశించి శుభం కలుగబోతున్నదనే భావం వారికి కలిగింది. వేగంగా బయలుదేరి పంపాసరస్సుకు చేరుకొన్నారు. రంగురంగుల తామర, కలువ పూలతో అది రత్నకంబళంలా ఉంది."
                    },
                    {
                        "des": " చుట్టుప్రక్కల అనేక చెట్లు పూసి, కాసి కన్నుల పండువుగా ఉన్నాయి. ఎన్నో పక్షులు, పూలతోను, నిర్మలమైన నీటితోను ఆ సరస్సు అతిమనోహరంగా ఉంది. సీత గుర్తుకు వచ్చి రాముడు మరల విచారించాడు. సుగ్రీవునికోసం ధాతుమండితమైన ఋష్యమూకపర్వతంవైపు సాగారు."
                    }
                ]
            }
        ]
    },
    {
        "title": "కిష్కింధ కాండము",
        "subtitle": [
            {
                "partname": "1.హనుమంతుడు రామ లక్ష్మణులను",
                "description": [
                    {
                        "des": "రామ లక్ష్మణులు శబరి ఆతిధ్యాన్ని స్వీకరించిన తరువాత పంపాసరోవరం అందాలను చూస్తూ ముందుకు సాగారు. విలపిస్తున్న రామునికి లక్ష్మణుడు ధైర్యం చెప్పాడు. క్రమంగా వారు ఋష్యమూక పర్వతాన్ని సమీపించారు.తన అన్న వాలి కోపానికి గురై తరిమి వేయబడిన సుగ్రీవుడనే వానరుడు తనవారితో కలసి ఆ పర్వతం పైననే సంచరిస్తున్నాడు. మహా ధనుర్ధారులైన రామలక్ష్మణులను చూచి సుగ్రీవుడు భయం చెందాడు. వారిని గురించి తెలిసికోమని హనుమంతుని పంపాడు."
                    },
                    {
                        "des": "హనుమంతుడు బ్రహ్మచారి రూపంతో వారిని సమీపించి – ఓ పుణ్యపురుషులారా! తమరు వేషధారణను బట్టి తాపసులవలెనున్నారు. ధరించిన ఆయుధాలను బట్టి సర్వ శత్రు సంహారణాదక్షుల వలె ఉన్నారు. నర నారాయణుల వలెను, సూర్యచంద్రులవలెను, అశ్వినీ దేవతలవలెను కనుపిస్తున్నారు. నేను సుగ్రీవుడనే వానరుని మంత్రిని. అతడు తన అన్న ఆగ్రహానికి గురై దీనుడైయున్నాడు. మీ స్నేహాన్ని కోరుతున్నాడు. నేను కామరూపుడను గనుక వటువు వేషంలో మిమ్ములను కలవ వచ్చాను. తమ పరిచయ భాగ్యాన్ని ప్రసాదించండి అని మృదువైన మాటలతో అన్నాడు."
                    },
                    {
                        "des": "హనుమంతుని మాటలకు, వినయానికి రాముడు ముగ్ధుడయ్యాడు. తన తమ్మునితో ఇలా అన్నాడు – ఈతని మాటలలో ఎక్కడా అనవుసర శబ్దం గాని, అపశబ్దం గాని లేవు. వేదాలను, వ్యాకరణాన్ని క్షుణ్ణంగా అధ్యయనం చేసినవాడే ఇలా మాట్లాడగలడు. ఇటువంటి వానిని మంత్రిగా కలిగిన రాజు ఏమయినా సాధించగలడు."
                    },
                    {
                        "des": "రాముని ఆనతిపై లక్ష్మణుడు తమ రాకకు కారణాన్ని హనుమంతునికి వివరించాడు. కార్యార్ధులమై సుగ్రీవునితో స్నేహం కోరుతున్నామని చెప్పాడు. హనుమంతుడు తన నిజరూపం ధరించి రామలక్ష్మణులను తన భుజాలపై ఎక్కించుకొని సుగ్రీవునివద్దకు తీసికొనివెళ్ళాడు."
                    },
                ]
            },
            {
                "partname": "2.శ్రీరామ, సుగ్రీవుల మైత్రి ",
                "description": [
                    {
                        "des": "హనుమంతుని ద్వారా వారి వృత్తాంతాన్ని విని సుగ్రీవుడు రామలక్ష్మణులను స్వాగతించి ఆదరించాడు. సీతాపహరణ వృత్తాంతాన్ని విని, సీతమ్మను వెదకడానికి తాను సహాయపడగలన్నాడు. కొద్దిరోజుల క్రితం ఒక స్త్రీ వేరొకరిచే బలాత్కారంగా తీసికొనిపోబడుతూ ఆక్రోశిస్తున్నది. ఆమె జారవిడచిన నగలను వానరులు రామునికి చూపించారు. వాటిని చూచి రాముడు బావురుమన్నాడు. రాముడూ సుగ్రీవుడూ అగ్నిసాక్షిగా మైత్రి నెరపుకొన్నారు.. సీతను వెదకి రామునికి అప్పగిస్తానని సుగ్రీవుడు ప్రతిన బూనాడు."
                    },
                    {
                        "des": "రాముడు ప్రశ్నించగా సుగ్రీవుడు తనకూ తన అన్నకూ వైరం ఏర్పడిన కారణాన్ని వివరించాడు. కిష్కింధ రాజైన వాలికి సుగ్రీవుడు తమ్ముడు. విధేయుడు. ఒకమారు మాయావి అనే రాక్షసునితో యుద్ధం చేస్తూ వాలి ఒక బిలంలోపలికి వెళ్ళాడు. బిలం వెలుపలే ఉండమని వాలి తన తమ్ముడు సుగ్రీవునికి చెప్పాడు. ఒకమాసం గడచినా వారు వెలుపలికి రాలేదు. రాక్షసుని చేతిలో వాలి మరణించి ఉంటాడని భయపడ్డ సుగ్రీవుడు బిలం ద్వారాన్ని ఒక బండరాతితో మూసి నగరానికి తిరిగివచ్చాడు. "
                    },
                    {
                        "des": "మంత్రులు సుగ్రీవుడిని రాజుగా అభిషేకం చేశారు.వాలి తిరిగివచ్చి సుగ్రీవుడిని నిందించి దండించాడు. అతని భార్య రుమను చేబట్టి సుగ్రీవుని రాజ్యంనుంచి తరిమేశాడు. సుగ్రీవుడు ఋష్యమూక పర్వతంపై తనకు విశ్వాసపాత్రులైన నలుగురు మంత్రులతో తలదాచుకొన్నాడు. మతంగమహర్షి శాపంవలన వాలి ఋష్యమూక పర్వతం సమీపానికి రాడు."
                    },
                    {
                        "des": "దీనుడైన సుగ్రీవుని కథ విని రాముడు తాను వాలిని సంహరిస్తానని మాట యిచ్చాడు. వాలి అసమాన బల పరాక్రమాల గురించి సుగ్రీవుడు రామునికి వివరించాడు. సుగ్రీవునకు నమ్మకం కలిగించడానికి రాముడు కొండ లాంటి దుందుభి అనే రాక్షసుని కళేబరాన్ని పది క్రోసుల దూరంలో పడేలా తన్నాడు. ఒక్క బాణంతో ఏడు సాల వృక్షాలను ఛేదించాడు. సుగ్రీవుడిని ఆలింగనం చేసుకొని, అతనికి అభయమిచ్చాడు"
                    },
                ]
            },
            {
                "partname": "3.సుగ్రీవుడు, వాలి పోరాటం",
                "description": [
                    {
                        "des": "రాముడి అండ చూసుకొని సుగ్రీవుడు వాలిని యుద్ధానికి పిలిచాడు. అన్నదమ్ములు భీకరంగా పోరాడారు. రెండు కొండల్లా ఢీకొంటున్న వారిరువురూ ఒకే విధంగా ఉన్నారు. వారిలో వాలి ఎవరో పోల్చుకోలేక రాముడు మౌనంగా ఉండిపోయాడు. క్రమంగా సుగ్రీవుని శక్తి క్షీణించింది. వాలి అతనిని తీవ్రంగా దండించి తరిమేశాడు. లేనిపోని ఆశలు కల్పించి యుద్ధసమయంలో ఉపేక్షించినందుకు రామునితో నిష్ఠూరంగా మొరపెట్టుకొన్నాడు సుగ్రీవుడు."
                    },
                    {
                        "des": "అసలు కారణం వివరించి రాముడు సుగ్రీవునకు ధైర్యం చెప్పాడు. ఆనవాలుగా ఒక గజపుష్పి లతను సుగ్రీవుని మెడలో అలంకరించాడు. మళ్ళీ సుగ్రీవుడు కిష్కింధకు వెళ్ళి వాలిని యుద్ధానికి కవ్వించాడు. కోపంతో బయలు దేరిన వాలిని అతని భార్య తార వారింప ప్రయత్నించింది. అంతకు ముందే దెబ్బలు తిన్న సుగ్రీవుడు మళ్ళీ యుద్ధానికి రావడానికి అయోధ్యా రాకుమారుల అండయే కారణం కావచ్చు అని హితం పలికింది. కాని వాలి వినలేదు."
                    },
                    {
                        "des": "తనకు ఇక్ష్వాకు రాకుమారులతో వైరం లేదు గనుక ఆ ధర్మపరులు తనకు హాని చేయరన్నాడు. కోపంతో బుసలు కొడుతూ యుద్ధానికి బయలుదేరాడు.అన్నదమ్ములు మళ్ళీ భీకరంగా పోరాడసాగారు. వాలికి ఇంద్రుడు ఇచ్చిన కాంచనమాలా వర ప్రభావం వలన ఎదురుగా పోరాడే వారి శక్తిలో సగం వాలికి సంక్రమిస్తుంది. కనుక క్రమంగా సుగ్రీవుని బలం క్షీణించసాగింది."
                    },
                    {
                        "des": "ఆ సమయంలోనే రామచంద్రుడు కోదండాన్ని ఎక్కుపెట్టి వజ్రసమానమైన బాణాన్ని వాలి గుండెలపై కొట్టాడు. వాలి హాహాకారాలు చేస్తూ మూర్ఛపోయాడు."
                    },
                ]
            },
            {
                "partname": "4.వాలి మరణం",
                "description": [
                    {
                        "des": "కొంత సేపటికి వాలికి తెలివి వచ్చింది. అతని గుండెలనుండి రక్తం ధారలుగా పారుతోంది. ప్రాణాలు కడగడుతున్నాయి. ఎదురుగా రాముడు, అతనికి ఇరుప్రక్కలా లక్ష్మణుడూ, సుగ్రీవుడూ కనుపించారు. నీరసంగా రాముని చూచి వాలి ఇలా నిందించాడు."
                    },
                ]
            },
            {
                "partname": "5.రాముని వాలి నిందించుట",
                "description": [
                    {
                        "des": "రామా! నీవు మహా తేజోవంతుడవు. కాని నీవు చేసిన ఈ నీచమైన పని వలన నీ వంశానికీ, తండ్రికీ అపకీర్తి తెచ్చావు. నేను నీకుగాని, వీ దేశానికి గాని ఏ విధమైన కీడూ చేయలేదు. అయినా నన్ను వధిస్తున్నావు. నీవు సౌమ్య మూర్తిగా నటిస్తున్న మాయమయుడివి. ఇంద్రియ లోభాలకు వశుడవయ్యావు. అన్ని దోషాలు నీలో కనబడుతున్నాయి. నీవు క్షుద్రుడవు, మహాపాపివి."
                    },
                    {
                        "des": "నా చర్మం, గోళ్ళు, రోమాలు, రక్తమాంసాలు నీకు నిరుపయోగం కనుక నన్ను మృగయావినోదం కోసం చంపావనే సాకు కూడా నీకు చెల్లదు. నీ కపటత్వం గ్రహించే నా ఇల్లాలు తార నన్ను ఎన్నో విధాలుగా వారించింది. కాని పోగాలం దాపురించిన నేను ఆమె హితవాక్యాలను పెడచెవినబెట్టాను."
                    },
                    {
                        "des": "నా యెదుటపడి యుద్ధం చేసే లావు నీకు లేదు. మధ్యపాన మత్తుడై నిద్రపోయేవాడిని పాము కాటు వేసినట్లుగా చెట్టుమాటునుండి నాపై బాణం వేశావు. ఇందుకు నీకు సిగ్గు కలగడంలేదా! నా సహాయమే కోరి వుంటే క్షణాలమీద రావణుడిని నీ కాళ్ళవద్ద పడవేసి నీ భార్యను నీకు అప్పగించేవాడిని."
                    },
                    {
                        "des": "నేను చావుకు భయపడేవాడిని కాను. సుగ్రీవుడు నా అనంతరం రాజ్యార్హుడే. కాని ఇలా కుట్రతో నన్ను చంపి నా తమ్ముడికి రాజ్యం కట్టబెట్టడం నీకు తగినపని కాదు. నీ చేతలను ఎలా సమర్ధించుకొంటావు? నా గొంతు ఎండుకు పోతోంది. ఈ బాణం నా ప్రాణాలు హరిస్తున్నది. నిస్సత్తువలో ఎక్కువ మాట్లాడలేను. కాని నీ సమాధానాన్ని వినగలను.అని వాలి అన్నాడు."
                    }
                ]
            },
            {
                "partname": "6.రాముని సమాధానం",
                "description": [
                    {
                        "des": "వాలి పలుకులను ఆలకించి రాముడు శాంతంగా ఇలా అన్నాడు – ఇంద్ర నందనా! నీ సందేహాలు తీర్చడం నా కర్తవ్యం. అందువలన నీ అంత్యకాలం ప్రశాంతంగా ముగియవచ్చును.నేను వేట మిష మీద నిన్ను చంపలేదు కనుక భష్యాభక్ష్య విచికిత్స అనవుసరం. ధర్మ రక్షణార్ధమే నిన్ను చంపాను. ప్రభువైన భరతుని ప్రతినిధులం గనుక మా రాజ్యంలో ధర్మహీనులను దండించే బాధ్యతా, హక్కూ మాకున్నాయి. నీ తమ్ముడు జీవించి ఉండగానే అతని భార్యను నీవు వశం చేసుకొన్నావు. నీ ప్రవర్తనలో దుష్టత్వం ఉన్నది. "
                    },
                    {
                        "des": "నేను వేట మిష మీద నిన్ను చంపలేదు కనుక భష్యాభక్ష్య విచికిత్స అనవుసరం. ధర్మ రక్షణార్ధమే నిన్ను చంపాను. ప్రభువైన భరతుని ప్రతినిధులం గనుక మా రాజ్యంలో ధర్మహీనులను దండించే బాధ్యతా, హక్కూ మాకున్నాయి. నీ తమ్ముడు జీవించి ఉండగానే అతని భార్యను నీవు వశం చేసుకొన్నావు. నీ ప్రవర్తనలో దుష్టత్వం ఉన్నది. "
                    },
                    {
                        "des": "అందుకు మరణ దండనయే సరైన శిక్ష. కనుకనే మన మధ్య ప్రత్యక్ష వైరం లేకున్నా నిన్ను శిక్షించాను. ధర్మానికి శత్రు మిత్ర తత్వాలుండవని కిష్కింధకు రాజైన నీకు తెలుసు.ఇక చెట్టుమాటునుండి చంపడం గురించి. నీ మెడలోని కాంచనా మాలా వర ప్రభావాన్ని నేను మన్నించాలి గనుక ఉపాయాంతరంగా కూల్చాను."
                    },
                    {
                        "des": "ధర్మ పరాఙ్ముఖుడైన వధ్యుని వధించడానికి యుద్ధ ధర్మాలు వర్తించవు. ఇక నీవు శిక్షార్హుడవు గనుక నీతో నేను నా కార్యాలు సాధించుకో దగదు. అన్యుల సహాయం పైని ఆధారపడేవాడిని కానని నా చరిత్రే చెబుతుంది. కనుక స్వలాభం కోసం నిన్ను వధించాననుకోవడం అవివేకం."
                    },
                    {
                        "des": "నీ వధకు మరొక అలౌకిక పరమార్ధ కారణం ఉంది. నీవు ఇంద్రుని పుత్రుడవు. సృష్టి కర్త ఆజ్ఞ మేరకు రావణ వధలో వానరులు నాకు సహకరించాలి. కానీ నీవు రావణుడి మిత్రుడవయ్యావు. కనుక నీవు నాకు సహాయ పడితే మిత్ర ద్రోహివవుతావు. రావణుడి పక్షాన ఉంటే పితృద్రోహివవుతావు. అటువంటి మహాపాతకాలు నీకు అంటకుండా నిన్ను రక్షించాను. ఇకనైనా నా చేతలో ధర్మాన్ని తెలిసికొని చిత్త క్షోభను వర్జించి శాంతిని పొందు."
                    },
                ]
            },
            {
                "partname": "7.వాలి చివరి కోరికలు",
                "description": [
                    {
                        "des": "రామా! సర్వజ్ఞుడవైన నీకు బదులు చెప్పగలిగేవాడను కాను. నీ చేతిలో మరణించడం నా పూర్వ జన్మ సుకృతం. గారాబంగా పెరిగిన నా కొడుకు అంగదుని కూడా సుగ్రీవునిలాగానే నీవు రక్షించు. నామీద వున్న ద్వేషంతో సుగ్రీవుడు తారను హింసించకుండా చూడు. నా ప్రేలాపననూ, అపరాధాలనూ మన్నించు."
                    },
                    {
                        "des": "తరువాత వాలి సుగ్రీవుని పిలచి తన మెడలోని కాంచనమాలను అతనికిచ్చాడు. అంగదుడిని స్వంత కొడుకులాగానే చూసుకోమన్నాడు. తార హితోక్తులను అమలు చేయమన్నాడు. రాఘవుల కార్యాలను అలక్ష్యం చేయవద్దన్నాడు.పిన తండ్రిని కూడా తండ్రిని లాగానే గౌరవించమని అంగదునికి చెప్పాడు. దేశ కాలాలు గుర్తించి ప్రభువు పట్ల విధేయుడై ఉండాలన్నాడు."
                    },
                    {
                        "des": "అందరినీ ఓదార్చి రాముడు వాలికి అంత్య క్రియలను జరపమన్నాడు. అనంతరం రాముని ఆనతిపై రాజుగా సుగ్రీవుడూ, యువరాజుగా అంగదుడూ అభిషిక్తులయ్యారు. తన వనవాస నియమం ప్రకారం పదునాలుగు సంవత్సరాలు నగరంలో ప్రవేశించకూడదు గనుక రాముడు కిష్కింధకు వెళ్ళలేదు."
                    }

                ]
            },
            {
                "partname": "8.శ్రీరాముని వేదన",
                "description": [
                    {
                        "des": "వర్షఋతువులో అన్వేషణా యత్నం సాధ్యం కాదు గనుక నాలుగు మాసాలపాటు కిష్కింధలో సుఖభోగాలు అనుభవించమని, కార్తిక మాసం రాగానే రావణ వధకు సిద్ధం కావాలని రాముడు సుగ్రీవునకు చెప్పాడు. రామ లక్ష్మణులు ధాతు సంపన్నమైన ప్రస్రవణ పర్వతంపై నివశించసాగారు. ఆ రమణీయ ప్రదేశం వర్షాకాలంలో మరింత సుందరంగా ఉంది."
                    },
                    {
                        "des": "అందువలన రాముడు మరింతగా సీతాస్మరణంతో రోదించ సాగాడు. దుఃఖించేవాడికి సర్వ కార్యాలు చెడుతాయని, ఒక్క నాలుగు నెలలాగితే కార్యసాధన సానుకూలమౌతుందని లక్ష్మణుడు ధైర్యం చెప్పాడు. అందుకు సాంత్వన పొందిన రాముడు తాను విచారాన్ని విడచిపెట్టి పరాక్రమాన్నే అవలంబిస్తానని, శత్రు వధకు సన్నద్ధమౌతానని మాట ఇచ్చాడు."
                    }
                ]
            },
            {
                "partname": "9.సుగ్రీవుని పట్ల లక్ష్మణుని ఆగ్రహం",
                "description": [
                    {
                        "des": "వర్షాలు వెనుకబడి ఆకాశం నిర్మలమయ్యింది. కాని సుగ్రీవుడు ధర్మార్ధవిముఖుడై రేయింబగళ్ళు కామభోగాలలోనే గడుపుతున్నాడు. ఆ సమయంలో హనుమంతుడు సుగ్రీవుని వద్దకు వెళ్ళి మహావీరా! నీవు రాజ్యాన్ని యశస్సును పొందడానికి కారణభూతుడైన శ్రీరామ చంద్రుని కార్యాన్ని ఉపేక్షించడం తగదు. మిత్రకార్యాన్ని విస్మరిస్తే అనర్ధాలు తప్పవు. నీ కులాభివృద్ధికి హేతువైన శ్రీరామ చంద్రునికి అప్రియం కలిగించవద్దు. వెంటనే సీతాన్వేషణకు మమ్ములను ఆజ్ఞాపించు – అని హితం పలికాడు. "
                    },
                    {
                        "des": "సుగ్రీవునికి కర్తవ్యం స్ఫురణకు వచ్చింది. నీలుని పిలిచి, అన్ని దిశలనుండి వానరులను వెంటనే పిలిపించమన్నాడు. పదిహేను రోజుల్లోపు రాని వానరులకు మరణదండన అని శాసించాడు.రాముడు సీతా వియోగంతో కుములుతున్నాడు. తాను చేసిన మేలు మరచి అలసత్వం వహించిన సుగ్రీవుని వర్తన రామునికి మరీ బాధ కలిగించింది. అది చూసి లక్ష్మణునికి ఆగ్రహం పెల్లుబుకింది."
                    },
                    {
                        "des": "అగ్ని హోత్రునిలా మండిపడుతూ కిష్కింధకు వెళ్ళాడు. కాలసర్ప సదృశమైన ధనుస్సు ధరించి క్రోధారుణ నేత్రుడై వచ్చిన లక్ష్మణుని చూచి వానరులు భయంతో వణికిపోయారు. అంగదుడు, మంత్రులు లక్ష్మణుని రాకను సుగ్రీవునికి తెలియజేశారు. వినయంతో మెలిగి ఆ రామానుజుని ప్రసన్నం చేసుకోమని హనుమంతుడు హితవు చెప్పాడు."
                    },
                    {
                        "des": "ముందుగా తార వచ్చి సుగ్రీవుని చాపల్యాన్ని మన్నించమనీ, అతడు తన మిత్ర ధర్మాన్ని తప్పక నెరవేరస్తాడనీ లక్ష్మణునికి ప్రాధేయపడుతూ విన్నవించుకొని అతనిని శాంతింపజేసింది. దానితో సుగ్రీవుడు కాస్త ధైర్యం తెచ్చికొని తన అపరాధాన్ని మన్నించమని వేడుకొన్నాడు. సమస్త వానర గణాలనూ కిష్కింధకు రావాలని ఆజ్ఞాపించాడు. తన సేనా గణంతో లక్ష్మణుని వెంట శ్రీరాముని చెంతకు వెళ్ళి రాముని పాదాలపై బడ్డాడు. అతనిని రాముడు ఆలింగనం చేసుకొన్నాడు. స్నేహితులిద్దరూ కలసి సీతాన్వేషణా పధకాన్ని సిద్ధం చేసుకొన్నారు."
                    }
                ]
            },
            {
                "partname": "10.సీతాన్వేషణ ఆరంభం",
                "description": [
                    {
                        "des": "సుగ్రీవుని ఆజ్ఞపై వినతుడనే వానర వీరుడు వేల కొలది సేనతో తూర్పు దిక్కున సీతా మాత అన్వేషణకు వెళ్ళాడు. పడమటి దిక్కుకు సుషేణుడు, ఉత్తర దిశకు శతబలుడు పెద్ద పెద్ద సేనలతో బయలుదేరి వెళ్ళారు. అంగదుడు దక్షిణ దిశాన్వేషణా బృందానికి నాయకుడు. అన్ని దిశలలో వెళ్ళేవారికీ వారు వెతక వలసిన స్థలాలను, తీసికొనవలసిన జాగ్రత్తలను సుగ్రీవుడు వివరించి చెప్పాడు. ఒక మాసం లోపు అన్వేషణ పూర్తి కావాలనీ, సీతమ్మ జాడ తెలిపినవారికి తనతో సమానంగా రాజ్య భోగాలు కల్పిస్తాననీ మాట ఇచ్చాడు. "
                    },
                    {
                        "des": "దక్షిణం వైపుకు నిర్దేశించిన బృందంలో అంగదుడు, జాంబవంతుడు, నీలుడు, హనుమంతుడు వంటి మహావీరులున్నారు. దక్షిణ దిశవైపు సీతను తీసికొని పోయిన రాక్షసుడు లంకాధిపతి రావణుడే కావచ్చునని సుగ్రీవుని అభిప్రాయం. అది గ్రహించిన రాముడు తన అంగుళీయాన్ని సీతకు ఆనవాలుగా ఇమ్మని హనుమంతునికిచ్చాడు.సుగ్రీవుని భౌగోళిక జ్ఞానం రాముని ఆశ్చర్య చకితుని చేసింది. దానికి కారణం అడిగాడు."
                    },
                    {
                        "des": "తాను వాలి వలన భయంతో ప్రాణాలు రక్షించుకోవడానికి భూమండలమంతా తిరిగినందువలన ఆ విధంగా లోక పరిచయం అయ్యిందని సుగ్రీవుడు చెప్పాడు. ఒక మాసం కాలానికి తూర్పు, పడమర, ఉత్తర దిశలుగా వెళ్ళిన వానర సేనలు తమ అన్వేషణ ముగించి తిరిగి వచ్చారు. సీత కానరాలేదని చింతాక్రాంతులై మనవి చేశారు."
                    }
                ]
            },
            {
                "partname": "11.దక్షిణ దిశలో సాగిన అన్వేషణ",
                "description": [
                    {
                        "des": "దక్షిణ దిశగా వెళ్ళిన వీరులు ప్రతిచోటా గాలిస్తూ వింధ్య పర్వతం దాటారు. రజత పర్వతంపైని వెదికారు. అప్పటికే సుగ్రీవుడు పెట్టిన నెల గడువు ముగిసింది. దాహార్తులై ఋక్షబిలం అనే ఒక సొరంగంలో ప్రవేశించారు. అందులోంచి బయట పడే మార్గం కానరాలేదు. అక్కడ మేరు సావర్ణి పుత్రిక స్వయంప్రభ తపస్సు చేసుకొంటూ మహా తేజస్వినియై వెలిగిపోతున్నది. వారి కథ విని ఆమె వారికి ఆతిధ్యం ఇచ్చింది. వారిని కనులు మూసుకోమని, తన తపశ్శక్తితో దక్షిణ దిశలో సాగర తీరానికి చేర్చింది."
                    },
                    {
                        "des": "అంతు లేని సాగరాన్ని చూసేసరికి వారి ఆశ అడుగంటింది. సుగ్రీవుడిచ్చిన గడువు అప్పటికే ముగిసిపోయింది. సీతమ్మ జాడ కానరాలేదు. వట్టిచేతులతో కిష్కింధకు పోలేరు. ఇక మరణమే తమకు శరణమని అంగదుడూ అతని అనుచరులూ ప్రాయోపవేశానికి సిద్ధమయ్యారు. వారిలో వారు జరిగిన విషయాలు నెమరు వేసుకొంటుండగా అక్కడికి సంపాతి అనే మహాకాయుడైన గ్రద్ద వచ్చాడు."
                    },
                    {
                        "des": "వారి ప్రసంగాన్ని పట్టి తన తమ్ముడైన జటాయువు మరణించాడని తెలిసికొని దుఃఖించాడు. రావణుడనే రాక్షసుడు సీతను ఎత్తుకొని పోయి సముద్రంలో నూరు యోజనాల అవతల లంకానగరంలో దాచాడని వారికి చెప్పాడు."
                    }
                ]
            },
            {
                "partname": "12.హనుమంతుని సంకల్పం",
                "description": [
                    {
                        "des": "సీత జాడ తెలిసి సంతోషించిన వానరుల ఉత్సాహం అపార సాగరాన్ని చూడగానే నీరుగారిపోయింది. గజుడు పది ఆమడలు గెంతగలనన్నాడు. గవాక్షుడు ఇరవై ఆమడలూ, గంధమాదనుడు ఏభై ఆమడలూ, మైందుడు అరవై ఆమడలూ, ద్వివిదుడు డెబ్భై ఆమడలూ, సుషేణుడు ఎనభై ఆమడలూ లంఘించగలమన్నారు. వృద్ధుడైన జాంబవంతుడు తొంభై యోజనాలు మాత్రం ఎగురగలనన్నాడు. అంగదుడు నూరు యోజనాలు లంఘించగలను గాని తిరిగిరావడం కష్టమైతే పని చెడుతుందని అన్నాడు."
                    },
                    {
                        "des": "అంగదుని వారించి జాంబవంతుడు హనుమంతునితో ఇలాగన్నాడు – నాయనా! ఈ కష్టాన్ని తరింపజేయడానికి నిన్ను మించిన సమర్ధుడు లేడు. గరుత్మంతునితో సమానమైన వేగ విక్రమాలు కలవాడవు. నీకు సమానమైన బలం, తేజం, బుద్ధి కుశలత, పరాక్రమం మరెవరికీ లేవు. నీ శక్తి నీకు తెలియదు. నీవు బహువర సంపన్నుడవు. వాయుపుత్రుడవు. ఈ సముద్రం దాటడం నీకు కష్టం కాదు. త్రివిక్రముడివై విజృంభించు, లేవయ్యా ఆంజనేయా! - అని ఉత్సాహపరచాడు."
                    },
                    {
                        "des": "ఆంజనేయుడు పర్వకాల సముద్రంలా ఉప్పొంగిపోయాడు. దీర్ఘ దేహుడై విజృంభించాడు. అతని ముఖం ధూమం లేని అగ్నిలాగా ప్రకాశించింది. జాంబవంతునికీ, అన్య వానర ప్రముఖులకూ వందనం చేశాడు. అంగదుని ఆశీర్వదించి ఇలా అన్నాడు – మహనీయులారా! మా తండ్రికి సాటియైన నేను అవశ్యం సాగరాన్ని గోష్పదంలా లంఘిస్తాను. నా వేగానికి సాగరం అల్లకల్లోలం అవుతుంది. సీతమ్మను చూచి రామకార్యాన్ని నెరవేరుస్తాను."
                    },
                    {
                        "des": "అవసరమైతే లంకా నగరాన్ని పెళ్ళగించుకువస్తాను. అనేక శుభశకునాలు అగుపడుతున్నాయి. మీరు నిశ్చింతగా ఉండండి. లంఘనా సమయంలో నా పద ఘట్టనకును భూమి తట్టుకోలేదు. కనుక ఈ మహేంద్రగిరిపైనుండి లంఘిస్తాను – అన్నాడు."
                    },
                    {
                        "des": "కార్య సాధకుడవై తిరిగి రమ్మని జాంబవంతుడు ఆశీర్వదించాడు. మహాకాయుడైన హనుమంతుడు వాయుదేవునికి మ్రొక్కి మహేంద్రగిరిపై కొంతసేపు విహరించాడు. అతడు కాలూనిన చోట పర్వతం బీటలువారి కొత్త సెలయేళ్ళు పుట్టాయి. శత్రు నాశన సమర్ధుడు, అత్యంత వేగగామి అయిన హనుమ లంకాపట్టణం చేరడానికి సంకల్పించి లంకాభిముఖంగా నిలుచున్నాడు."
                    }
                ]
            }
        ]
    },
    {
        "title": "సుందర కాండము",
        "subtitle": [
            {
                "partname": "1.హనుమంతుని సాగర తరణ",
                "description": [
                    {
                        "des": "హనుమంతుడు పర్వత సమానంగా దేహాన్ని పెంచి, సాగరాన్ని దాటడానికి సన్నద్ధుడై మహేంద్రగిరిపైకి ఎక్కాడు. సూర్యునికి, ఇంద్రునికి, బ్రహ్మకు, భూతకోటికి నమస్కరించాడు. పిక్కలు బిగబట్టి, చేతులు అదిమి, ఒక్కుదుటున లంఘించాడు. అ అదురుకు పర్వతం బీటలు వారింది. ఆకాశంలో మేఘంలా, విడచిన రామబాణంలా, హనుమంతుడు వేగంగా లంకవైపుకు వెళ్ళసాగాడు."
                    },
                    {
                        "des": "రామ కార్యానికి సహాయపడదలచి, దారిలో మైనాకుడనే పర్వతం తనపై విశ్రాంతి తీసికోమని కోరాడు. ఆ ఆతిథ్యాన్ని వినయంతో తిరస్కరించి హనుమంతుడు ముందుకు సాగాడు. సురస అనే నాగమాత హనుమను పరీక్షింపదలచి, మృత్యుగహ్వరంలాంటి తన నోరు తెరచి అతని దారికి అడ్డు నిలచింది. యుక్తిగా ఆమె నోట ప్రవేశించి, మళ్ళీ బయటకు వచ్చి, ఆమె ఆశీర్వచనం పొంది హనుమంతుడు ముందుకు సాగాడు. సింహిక అనే ఛాయాగ్రాహక రాక్షసి హనుమంతుని నీడను పట్టి లాగసాగింది."
                    },
                ]
            },
            {
                "partname": "2.లంకా నగర ప్రవేశం",
                "description": [
                    {
                        "des": "దూరంనుండి లంకానగరం శోభను, సౌందర్యాన్ని, సురక్షిత వ్యవస్థను చూసి హనుమంతుడు ఆశ్చర్యపోయాడు. చీకటి పడిన తరువాత చిన్నపాటి దేహం దాల్చి నగరంలో ప్రవేశించబోయాడు. ద్వారం వద్ద లంకా నగరాధిదేవత లంకిణి అతనిని అడ్డగించిండి. హనుమంతుడు ఆమెను దండించాడు."
                    },
                    {
                        "des": "అతడు కారణజన్ముడనీ, బ్రహ్మ చెప్పిన విధంగా లంకకు కీడు వాటిల్లనుందనీ లంకిణి గ్రహించింది. ద్వారం గుండా కాకుండా ప్రాకారాన్ని లంఘించి, ఎడమ కాలు ముందుంచి, హనుమంతుడు మయుని అపూర్వ సృష్టియైన లంకలో ప్రవేశించి, సీతను వెదుకసాగాడు. ఆ సమయంలో చంద్రోదయం జరిగి, లంకానగరం మరింత శోభాయమానం అయింది."
                    },
                ]
            },
            {
                "partname": "3.అంతఃపురంలో సీతాన్వేషణ",
                "description": [
                    {
                        "des": "చిన్నశరీరము ధరించి, హనుమంతుడు రావణుని మందిరములోనూ, పానశాలలోనూ, పుష్పక విమానములోనూ అన్నిచోట్లా సీతను వెదకాడు. రాత్రి వేళ రావణుని మందిరంలో కాంతలు భోగ లాలసులై, చిత్ర విచిత్ర రీతులలో నిద్రిస్తూ ఉన్నారు. ఆ దృశ్యాలను చూచి కలవరపడిన హనుమంతుడు, తాను రామ కార్యాచరణ నిమిత్తం ఏ విధమైన వికారాలకూ లోను గాకుండా సీతాన్వేషణ చేస్తున్నందున తనకు దోషం అంటదని, తన బ్రహ్మచర్య దీక్షకు భంగం వాటిల్లదని సమాధానపడ్డాడు."
                    },
                    {
                        "des": "పుష్పక నిమానం అందాన్ని, రావణుని ఐశ్వర్యాన్ని చూసి అబ్బురపడ్డాడు. నిద్రించుచున్న స్త్రీలలో మండోదరిని చూచి సీత అని భ్రమించాడు. మరల తప్పు తెలుసుకొని అన్వేషణ కొనసాగించాడు. సీతమ్మ జాడ కానక చింతించాడు. ఏమిచేయాలో తోచలేదు. ఊరకే వెనుకకు మరలి అందరినీ నిరాశపరచడానికి ఇష్టం లేదు. తన కార్యం విఫలమైతే సుగ్రీవుడు, రామ లక్ష్మణులు, మరెందరో హతాశులౌతారని వగచాడు.ఆత్మహత్య గురించి కూడా ఆలోచించాడు. సీత కనుపించకుండా తాను వెనుకకు వెళ్ళేది లేదని నిశ్చయంచుకొన్నాడు. ఆ సమయంలో అశోక వనం కనిపించింది."
                    },

                ]
            },
            {
                "partname": "4.సీతమ్మ దర్శనం",
                "description": [
                    {
                        "des": "అశోకవనం అనన్య సుందరమైనది. అందులో చక్కని వృక్షాలు, పూలు, చిత్ర విచిత్రములైన కృతక పర్వతాలు, జలధారలు ఉన్నాయి. మణిమయాలైన సరస్సులున్నాయి. అద్భుతమైన చైత్య ప్రాసాదములున్నాయి. వాటిలో అతి మనోహరమైన ఒక శింశుపా వృక్షాన్ని ఎక్కి హనుమంతుడు చుట్టుప్రక్కల పరిశీలింపసాగాడు."
                    },
                    {
                        "des": "అక్కడ శింశుపా వృక్షము క్రింద, రాక్షసకాంతలచే పీడింపబడుతూ, సింహముల మధ్యనున్న లేడివలే భీతయై కృశించిన ఒక స్త్రీని చూచాడు. ఆమె ఏకవస్త్రయై, ధూమావృతమైన అగ్ని శిఖవలె, మిధ్యాపవాదువలన భంగపడిన కీర్తివలె, మేఘాచ్ఛాదితమైన చంద్రబింబంవలె ఉంది."
                    },
                    {
                        "des": "ఆమె ధరించిన ఆభరణాలు, ఆమె తీరు, ఉన్న స్థితిని బట్టి హనుమంతుడు ఈమె సీతయే అని నిర్ధారించుకొన్నాడు. ఆమె దీనావస్థను, రామలక్ష్మణాదుల దుఃఖమును తలచుకొని, కాలం ఎంతటివారికైనా అతిక్రమింపరాని బలీయమైనది అనుకొని, హనుమంతుడు దుఃఖించాడు."
                    }

                ]
            },
            {
                "partname": "5.త్రిజటాస్వప్నం",
                "description": [
                    {
                        "des": "డికి కామాతురుడైన రావణుడు వచ్చి సీతను బెదరించి, తనకు వశముకావలెనని ఆదేశించాడు. సీత ఒక గడ్డిపరకను అడ్డముగా పెట్టుకొని, రావణుని ధర్మహీనతను, భీరత్వాన్ని నిందించింది. పోగాలము దాపురించినందువల్లనే ఈ నీచ సంకల్పము అతనికి కలిగిందని హెచ్చరించింది. శ్రీరాముని బాణాగ్నితో లంక భస్మమగుట తథ్యమని రావణునకు గట్టిగా చెప్పినది. ఒక నెల మాత్రము గడువు పెట్టి రావణుడు వెళ్ళిపోయాడు. రాక్షసకాంతలు సీతను నయానా, భయానా అంగీకరింపచేయాలి."
                    },
                    {
                        "des": "అని ప్రయత్నించ సాగారు. రావణునికి వశం కాకపోతే ఆమెను తినేస్తామని బెదరించారు. భయ విహ్వలయై, ఆశను కోల్పోయిన సీత ప్రాణత్యాగం చేయాలని నిశ్చయించుకొన్నది.వారిలో సహృదయయైన త్రిజట అనే రాక్షసకాంత మిగిలిన రాక్షస స్త్రీలను గద్దించి, సీతవంటి పుణ్యస్త్రీకి హాని చేయడం రాక్షస జాతికి వినాశకరమని హెచ్చరించింది. తనకు వచ్చిన కలలో ఇలా జరిగిందని చెప్పింది వేయి హంసలు పూన్చిన తెల్లని ఏనుగుదంతపు పల్లకీలో రామలక్ష్మణులు లంకకు వచ్చారు. తెల్లని పర్వతాగ్రంపై సీత ఆసీనయై ఉంది."
                    },
                    {
                        "des": "ఆమె సూర్య చంద్రులను స్పృశించింది. నాలుగు దంతాలు కలిగిన తెల్లని ఏనుగు నెక్కి, రాముని ఒడిలో సీత కూర్చుని ఉంది. సీతారామలక్ష్మణులు అధివసించిన భద్రగజం ఆకాశంలో లంకపైభాగాన నిలిచింది. ఎనిమిది వృషభములు పూన్చిన రథంపై రాముడు తెల్లని వస్త్రాలతో, సీతా లక్ష్మణులతో లంకలో కనిపించాడు. తరువాత, వారంతా పుష్పకం ఎక్కి ఉత్తర దిశగా వెళ్ళారు."
                    },
                    {
                        "des": "ఎర్రని వస్త్రములు ధరించి, తైలము పూసుకొని రావణుడు మత్తిల్లి, పుష్పకంనుండి క్రింద పడ్డాడు. గాడిదలు పూన్చిన రధంలో ఉన్నాడు. అతని మెడలో త్రాడు కట్టి, నల్లని వస్త్రములు ధరించిన ఒక స్త్రీ దక్షిణానికి లాగుచుంన్నది. అతడు దుర్గంధ నరక కూపంలో పడిపోయాడు. రావణుడు పందినెక్కి, కుంభకర్ణుడు పెద్ద ఒంటెనెక్కి, ఇంద్రజిత్తు మొసలినెక్కి దక్షిణ దిశగా పోయారు. విభీషణుడు మాత్రం తెల్లని గొడుగుతో, దివ్యాభరణాలతో, తెల్లని గజం అధిరోహించి, మంత్రులతో కూడి ఆకాశంలో ఉన్నాడు."
                    },
                    {
                        "des": "లంకా నగరం ధ్వంసమై సముద్రంలో కూలింది. రాక్షస స్త్రీలంతా తైలము త్రాగుచు, పిచ్చివారివలె లంకలో గంతులు వేయుచున్నారు.ఇలా చెప్పి త్రిజట తమను ఆపదనుండి కాపాడమని సీతాదేవిని వేడుకొనమని తక్కిన రాక్షస కాంతలకు హితవు పలికింది. భయంకరమైన ఆ కల విని రాక్షసకాంతలు భీతిల్లారు. ఆత్మహత్యకు సిద్ధపడిన సీతకు శుభ శకునములు కనిపించసాగాయి."
                    }

                ]
            },
            {
                "partname": "6.శ్రీరామ వర్ణన",
                "description": [
                    {
                        "des": "చెట్టుపైనుండి ఇదంతా గమనించిన హనుమంతుడు ఇంక ఆలస్యము చేసినచో సీత ప్రాణత్యాగము చేయగలదని ఊహించాడు. కాని ఒక్కమారుగా ఆమెకు కనిపించినట్లయితే ఆమె ఖంగారుపడి కేకలు వేయవచ్చనీ, అలాగయితే అసలు పని చెడుతుందని భావించాడు. చెట్టుపైనుండి మెల్లగా దశరథ కుమారుడైన రాముని కథ చెప్పనారంభించాడు."
                    },
                    {
                        "des": "ఆ రాముడు సీతను వెదకడానికి పంపిన దూతలలో ఒకడైన తాను ప్రస్తుతం లంకను చేరి, చెట్టుపైనుండి, సీతను చూచానని ఆ కథాక్రమంలో తెలియజేశాడు. ఆ రామకథా శ్రవణంతో సీత కొంత ఆనందించింది. కానీ తాను కలగంటున్నానేమోనని భ్రమ పడింది. తల పైకెత్తి, మెరుపు తీగవలె, అశోక పుష్పము వలె ప్రకాశిస్తున్న వానరుని చూచి కలవరపడింది."
                    },
                    {
                        "des": "తాను విన్న విషయాలు సత్యాలు కావాలని బ్రహ్మకు, మహేంద్రునికి, బృహస్పతికి, అగ్నికి నమస్కరించింది. హనుమంతుడు మెల్లగా చెట్టు దిగివచ్చి ఆమెకు శుభం పలికాడు. సీతకు తన వృత్తాంతమునూ, రాముని దుఃఖమునూ వివరించాడు. శ్రీరాముని పరాక్రమాన్నీ, గుణగణాలనూ ప్రశంసించి ఆమెకు త్వరలో విముక్తి కలుగుతుందని అనునయ వచనాలు పలికాడు. సీత దుఃఖించి, అందరి క్షేమసమాచారములు అడిగి, ఆపై రాముని వర్ణించమని కోరింది."
                    },
                    {
                        "des": "హనుమంతుడు భక్తితో అనన్య సుందరుడు అయిన రాముని, అతని సోదరుడైన లక్ష్మణుని వర్ణించాడు. రాముడు ఆజానుబాహుడు. కమల పత్రాక్షుడు. రూప దాక్షిణ్య సంపన్నుడు. శుభలక్షణములు గలవాడు, తేజోమూర్తి, ధర్మ రక్షకుడు, సర్వ విద్యాపారంగతుడు, లోకమర్యాదలను పాటించువాడు. సమ విభక్తములైన శరీరాంగములు కలవాడు. దీర్ఘములైన బాహువులు, శంఖమువంటి కంఠము, కండరములతో మూసుకొనిఫోయిన సంధి యెముకలు కలవాడు. "
                    },
                    {
                        "des": "మూడు దృఢమైన స్థానములు, మూడు దైర్ఘ్యముగల అవయవములు, మూడు సమ అవయవములు, మూడు ఎఱ్ఱని ఆవయవములు కలవాడు. పదునాలుగు సమమైన అవయవములు కలవాడు. నాలుగు విధములైన నడక గలవాడు. ఉత్తముడు, వీరుడు. నల్లనివాడు. అతని తమ్ముడు లక్ష్మణుడు అట్టి శుభలక్షణములే కలిగి, ఎర్రని మేని ఛాయ గలవాడు - అట్టి రామలక్ష్మణులు నీకై పరితపించుచున్నారు. "
                    },
                    {
                        "des": "సుగ్రీవునితో చెలిమి జేసి, నిన్ను వెదులుటకై నలు తెరగుల వానరులను పంపియున్నారు. ఓ సీతా దేవీ! త్వరలోనే శ్రీరాముడు నిన్ను ఇచటనుండి తీసికొని పోనున్నాడు అని హనుమంతుడు చెప్పాడు.రాముని గురించి విని, సీత ఊరడిల్లింది. తరువాత హనుమంతుడు ఆమెకు శ్రీరాముని ఆనవాలైన అంగుళీయకమును ఇచ్చాడు. రాముడు చెప్పిన మాటలు తెలియజేశాడు. ఆమెకు శుభం పలికాడు. తనతో వస్తే ఆమెను తీసికొని వెళ్ళగలనని కోరాడు. "
                    },
                    {
                        "des": "సీత హనుమంతుని పలుకులకు సంతోషించి అతని పరాక్రమాన్ని ప్రశంసించింది. కాని స్వయంగా శ్రీరాముడే వచ్చి, రావణుని పరిమార్చి, తనను తీసికొని వెళ్ళాలని చెప్పింది. రాముని పరాక్రమానికి ముల్లోకాలలోను ఎదురు లేదని తెలిపింది. రామలక్ష్మణులకు, సుగ్రీవునకు, భల్లూక వానరులకు ధర్మక్రమ మనుసరించి కుశలం అడిగినట్లు తెలుపమని పలికింది. హనుమంతుని ఆశీర్వదించి, తన చూడామణిని ఆనవాలుగా ఇచ్చింది."
                    },
                    {
                        "des": "ఒక్క నెలలో రాముడు తనను కాపాడకున్న తాను బ్రతుకనని చెప్పినది. ఆ మహాంబుధిని దాటడం (హనుమంతుడు, వాయుదేవుడు, గరుత్మంతుడు తప్ప) ఇతరులకు ఎలా శక్యమని సంశయించింది.అందుకు హనుమంతుడు తనకంటే గొప్పవారైన మహావీరులు వానరులలో ఎందరో ఉన్నారని, తాను సామాన్యుడను గనుకనే ముందుగా తనను దూత కార్యానికి (యుద్ధానికి కాదు) పంపారని ఆమెకు నచ్చచెప్పాడు."
                    },
                    {
                        "des": "మహావీరులైన రామలక్ష్మణులు కపి భల్లూక సేనా సమేతంగా, త్వరలో లంకకు వచ్చి సూర్య చంద్రుల వలె, అగ్ని వాయువులవలె లంకను వాశనం చేసి రావణ సంహారం సాగించడం తథ్యమని ఆమెను అనునయించాడు. హనుమంతుని సీతమ్మ ఆశీర్వదించింది."
                    }

                ]
            },
            {
                "partname": "7.రాక్షసులను దండించడం",
                "description": [
                    {
                        "des": "సీతా దర్శనంతో సంతుష్టుడైన హనుమంతుడు ఇక పనిలో పనిగా రావణునితో భాషింపవలెననీ, లంకను పరిశీలింపవలెననీ నిశ్చయించుకొన్నాడు. అలా చేయడం వల్ల రావణుని హెచ్చరించడానికీ, లంక రక్షణా వ్యవస్థను తెలుసుకోవడానికీ వీలవుతుంది. అంతే గాకుండా ఆ ప్రయత్నంలో లంకకు వీలయినంత నష్టం కలిగించవచ్చును. ఇలా సంకల్పించిన హనుమంతుడు వెంటనే ఉగ్రాకారుడై వనమునూ, అడ్డు వచ్చిన వేలాది రాక్షసులనూ ధ్వంసం చేసి మకరతోరణాన్ని అధిష్ఠించి కూర్చున్నాడు."
                    },
                    {
                        "des": "ఆ కపిని బంధించమని రావణుడు ఎనుబదివేల మంది సైన్యాన్ని పంపాడు. హనుమంతుడు - జయత్యతిబలో రామో, లక్ష్మణశ్చ మహాబలః, రాజా జయతి సుగ్రీవో, రాఘవేణాభిపాలితః, దాసోహం కోసలేంద్రస్య, రామస్యా క్లిష్ట కర్మణః, హనుమాన్ శత్రు సైన్యానాం నిహన్తా మారుతాత్మజః అని జయఘోష చేశాడు - మహా బలవంతుడైన శ్రీరామునకు జయము. మిక్కిలి పరాక్రమశాలియైన లక్ష్మణునకు జయము. "
                    },
                    {
                        "des": "రాఘవుల విధేయుడైన కిష్కింధ ప్రభువు సుగ్రీవునకు జయము. నేను శ్రీరామ దాసుడను, వాయుపుత్రుడను, హనుమంతుడను. శత్రు సైన్యాన్ని నాశనం చేస్తాను. వేయి మంది రావణులైనా యుద్ధంలో నన్నెదిరించలేరు. వేల కొలది శిలలతోను, వృక్షాలతోను సకల రాక్షసులను, లంకాపురిని నాశనం చేస్తాను. నా పని ముగించుకొని, సీతమ్మకు నమస్కరించి వెళతాను. రాక్షసులు ఏమీ చేయలేక చూచుచుందురు గాక - ఇలా గర్జిస్తూ హనుమంతుడు ముఖద్వారానికి బిగించిన ఇనుప గడియతో రాక్షసులనందరినీ చావగొట్టాడు. పర్వతాకారంలో దేహాన్ని పెంచి, చైత్య ప్రాసాదాన్ని కూలగొట్టి, ఆ ప్రాసాదము యొక్క ఒక పెద్ద స్తంభాన్ని పరిఘలా త్రిప్పుతూ అందరినీ చావగొట్టాడు."
                    },
                    {
                        "des": "అప్పుడు రావణుడు, ప్రహస్తుని కుమారుడు మహా బలశాలీ అయిన జంబుమాలిని పంపాడు. హనుమంతుని చేతి పరిఘతో జంబుమాలి శరీరం చూర్ణమయ్యింది. ఆపై అగ్నివలె తేజరిల్లే యుద్ధవిద్యా నిపుణులైన ఏడుగురు మంత్రి పుత్రులు పెద్ద సేనతో కలిసి హనుమంతునిపై దండెత్తారు. హనుమంతుడు భయంకరంగా గర్జించి కొందరిని అఱచేతితోను, కొందరిని ముష్టిఘాతాలతోను, కొందరిని తన వాడిగోళ్ళతోనూ చంపగా యుద్ధ భూమి అంతా శత్రువుల రక్త మాంసాలు చెల్లాచెదరయ్యాయి."
                    },
                    {
                        "des": "పిమ్మట విరూపాక్షుడు, యూపాక్షుడు, దుర్ధరుడు, ప్రఘసుడు, భాసకర్ణుడు అనే గొప్ప సేనా నాయకులు తమ సేనలతో వచ్చి వన ముఖ ద్వారంపై కూర్చున్న హనుమంతునిపై విజృంభించారు. వారంతా కూడా హనుమ చేత నిహతులైపోయారు. రణ భూమి అంతా రాక్షస, వాహన కళేబరాలతోను, ఆయుధ, రథ శకలాలతోను నిండిపోయింది."
                    },
                    {
                        "des": "ఇక దుర్ధరమైన ప్రతాపశాలి, వర సంపన్నుడు, గొప్ప రథము కలవాడును అయిన అక్షకుమారుడు సకలబలములతో హనుమంతుని సమీపించాడు. వారి మధ్య జరిగిన యుద్ధం సురాసురులను సంభ్రమపరచింది. వర్షంలాంటి అక్షకుమారుని బాణాలు హనుమంతుని చాలా నొప్పించాయి. అతని పరాక్రమానికి హనుమంతుడు ముచ్చటపడ్డాడు. "
                    },
                    {
                        "des": "అంతటి పరాక్రమశాలిని, తేజోమయుని చంపడానికి తటపటాయించాడు. కాని అతనిని ఉపేక్షిస్తే తనకు పరాభవం తప్పదని తెలిసికొని హనుమంతుడు విజృంభించాడు. ఆకాశానికెగిరి వాయువేగంతో సంచరిస్తూ అరచేతితో అక్షకుమారుని గుర్రాలను చరచి చంపేశాడు."
                    },
                    {
                        "des": "తరువాత, గరుత్మంతుడు మహా సర్పాన్ని పట్టుకొన్నట్లుగా అక్షకుమారుని కాళ్ళను గట్టిగా చేజిక్కించుకొని, గిరగిర త్రిప్పి వేలకు విసరికొట్టాడు. అక్షకుమారుని శరీరం నుగ్గునుగ్గయ్యింది.ఈ సంగతి తెలిసిన రావణుడు కలవరపడి, పెక్కు జాగ్రత్తలు చెప్పి, ఇంద్రజిత్తును యుద్ధానికి పంపాడు. ఇంద్రజిత్తు బ్రహ్మవర సంపన్నుడు, ఇంద్రాదులకు కూడా నిలువరింప శక్యంగాని పరాక్రమశాలి, మంత్ర తంత్ర యుద్ధవిద్యానిపుణుడూను."
                    },
                    {
                        "des": "అతడు తండ్రికి నమస్కరించి, రణోత్సాహంతో పొంగిపోతూ, సేనలు లేకుండా ఒకడే దివ్యరథాన్ని అధిరోహించి హనుమంతునిపైకి వెళ్ళాడు. వారిద్దరి మధ్య యుద్ధం చిత్ర విచిత్ర రీతులలో సకల గణాలకు సంభ్రమం కలిగించింది. ఒకరిని ఒకరు జయించడం అశక్యమని ఇద్దరికీ తెలిసిపోయింది. ఇక లాభం లేదు, కనీసం ఆ వానరుని బంధించాలని సంకల్పించి ఇంద్రజిత్తు బ్రహ్మాస్త్రాన్ని ప్రయోగించాడు."
                    },
                    {
                        "des": "అది హనుమంతుని బంధించింది. బ్రహ్మ హనుమంతునకిచ్చిన వరం ప్రకారం ఆ అస్త్రం అతనిని బాధించకుండా మరుక్షణమే తొలగిపోయింది. అయినా బ్రహ్మదేవునిపట్ల గౌరవసూచకంగా ఆ అస్త్రానికి కట్టుబడిపోయినట్లుగా నటించాడు. ఆ విధంగా రావణునితో సంభాషించ దలచాడు. రాక్షస సేనలు హనుమంతుని బంధించి, బాధిస్తూ రావణుని సభా ప్రాంగణానికి తీసుకుపోయారు."
                    },

                ]
            },
            {
                "partname": "8.రావణునితో సంవాదం",
                "description": [
                    {
                        "des": "బ్రహ్మాస్త్రానికి వశుడైనట్లు నటించి, హనుమంతుడు రావణుని సభాభవనంలో ప్రవేశించి రావణుని చూశాడు. రావణాసురుని సభాప్రాంగణం మణిమయమై శోభిల్లుతున్నది. రావణుని కిరీటం, ఆభరణాలు, వస్త్రాలు, అనులేపనాదులు అత్యద్భుతంగా ఉన్నాయి. మహా తేజశ్శాలియు, వీరుడును ఐన రావణుడు పది శిరస్సులతో ఒప్పుచు, అనేక కౄర మృగములతో నిండిన శిఖరములు గల మందరగిరి వలె ప్రకాశిస్తున్నాడు. మణిమయాలంకృతమైన ఉన్నతాసనంపై కూర్చొని ఉన్నాడు. మంత్రాంగ నిపుణులైన నలుగురు మంత్రులచే పరివేష్టితుడై కాటుక కొండవలె ఉన్నాడు."
                    },
                    {
                        "des": "అప్పుడు హనుమంతుడు ఇలా అనుకొన్నాడు. - ’ఆహా! ఈ రావణుని రూపం అత్యద్భుతం. ధైర్యం నిరుపమానం. సత్వం ప్రశంసార్హం. తేజస్సు అసదృశం. నిజముగా ఈ రాక్షస రాజు సర్వ లక్షణ శోభితుడు. ఈ అధర్మానికి ఒడి గట్టకపోతే సురలోకానికి సైతం ప్రభువయ్యేవాడు. లోకాలన్నీ ఇతనికి భయపడుతున్నాయి. ఇతడు కృద్ధుడైనచో సమస్త జగత్తునూ సముద్రమున ముంచి ప్రళయం సృష్టించగల సమర్ధుడు గదా!"
                    },
                    {
                        "des": "హనుమంతుడు ఎవరు? ఎందుకు వచ్చాడు? ఎవరు పంపారు? - తెలిసికోమని రావణుడు మంత్రులకు ఆదేశించాడు. హనుమంతుడు రావణునకు ఇలా చెప్పాడు - రాజా! నేను సుగ్రీవుని మంత్రిని. రాముని దూతను. హనుమంతుడనే వానరుడను. నీ కుశలము తెలిసికొమ్మని సుగ్రీవుడు స్నేహ భావంతో చెప్పాడు. రాముని పత్ని సీతను తెచ్చి నువ్వు పెద్ద తప్పిదం చేశావు. దీని వలన నీవు చేసుకొన్న పుణ్యమంతా నిష్ఫలమై పోతుంది. వాలిని రాముడే సంహరించాడు. రాముని బాణాల ధాటికి నీవు గాని, మరెవరు గాని నిలువజాలరు."
                    },
                    {
                        "des": "ఈ అకృత్యం వలన నీకు, లంకకూ చేటు దాపురించింది. రాముడు మానవుడు. నీవు రాక్షసుడవు. నేను వానరుడను, నాకు పక్షపాతం లేదు. కనుక నా మాట విని సీతను అప్పగించి రాముని శరణు వేడుకో. రాముని క్రోధానికి గురియైనవానిని ముల్లోకాలలో ఎవరూ రక్షింపజాలరు.అని హితవు చెప్పాడు.రావణుడు కోపించి ఆ వానరుని చంపమని ఆదేశించాడు. అంతలో విభీషణుడు అడ్డుపడి - దూతను చంపడం రాజ ధర్మం కాదు."
                    },
                    {
                        "des": "అంతే కాకుండా ఇతను తిరిగి వెళ్ళకపోతే నీను శతృవులతో యుద్ధం చేసి వారిని నిర్జించే అవకాశం కోల్పోతావు. కనుక, దండించి వదలమని సూచించాడు. ఆ మాటలకు కాస్త నెమ్మదించిన రావణుడు ఆ వానరుని తోకకు నిప్పంటించి వూరంతా త్రిప్పమని ఆనతిచ్చాడు."
                    },

                ]
            },
            {
                "partname": "9.లంకా దహనం",
                "description": [
                    {
                        "des": "రావణుడు ఉగ్రుడై హనుమంతుని తోకకు నిప్పు పెట్టమని ఆదేశించాడు. రాక్షస కింకరులు హనుమంతుని తోకకు పాత గుడ్డలు చుట్టి నిప్పు పెట్టారు. ఊరంతా త్రిప్పసాగారు. ఈ అవకాశం చూసుకొని హనుమంతుడు లంకా నగరాన్ని నిశితంగా పరిశీలించాడు. జరిగిన సంగతి విన్న సీతాదేవి హనుమంతుని చల్లగా చూడమని అగ్నిదేవుని ప్రార్థించింది. తన తోక కాలుతున్నా గాని ఏ మాత్రం బాధ లేకపోవడం సీతమ్మ మహిమ వలన అని, తన తండ్రి వాయుదేవుని మిత్రుడైన అగ్ని కరుణ వలన అని గ్రహించిన హనుమంతుడు ఆ అగ్నికి లంకను ఆహుతినీయ సంకల్పించాడు."
                    },
                    {
                        "des": "తన బంధాలను త్రెంచుకొని, ఒక పరిఘతో రాక్షస మూకను చావబాదాడు. పైకెగిరి, మండుతున్న సూర్యునిలా విజృంభించాడు. ప్రహస్తుని ఇంటితో మొదలుపెట్టి లంకలోని అద్భుతభవనాలకు నిప్పంటించాడు. ఒక్క విభీషణుని ఇల్లు తప్ప లంకలో భవనాలను బుగ్గి చేశాడు.అప్పుడు ఒక్కమారుగా సీత సంగతి గుర్తు వచ్చి హనుమంతుడు హతాశుడయ్యాడు. తన తొందరపాటువలన లంకతో పాటు సీతమ్మ కూడా అగ్నికి ఆహుతయ్యిందేమో అన్న ఊహతో విలవిలలాడిపోయాడు."
                    },
                    {
                        "des": "తన చాంచల్యం వలన తన జాతికి, పనికి కీడు తెచ్చినందుకు రోదించి ప్రాణత్యాగానికి సిద్ధమయ్యాడు. కాని సీత క్షేమంగా ఉన్నదని తెలిసి, ఊరట చెందాడు. మరొక్కమారు సీతను దర్శించి, ఆమెకు సాంత్వన వచనాలు పలికి, రాముడు సకల వానరసేనతో త్వరలో రాగలడని అభయం పలికాడు. ఆమెకు ప్రణమిల్లి, తిరిగి ఉత్తరదిశకు బయలుదేరాడు.ఇలా హనుమంతుడు రాముని దూతగా సాగరాన్ని లంఘించి, సీతను కనుగొని, రాక్షసులను సంహరించి, లంకను భయభ్రాంతమొనర్చి, రావణుని మదమణచి, సీతకు సాంత్వన కూర్చి, తిరుగు ప్రయాణానికి అరిష్టము అనే పర్వతాన్ని అధిరోహించాడు."
                    },
                ]
            },
            {
                "partname": "10. తిరుగు లంఘనం",
                "description": [
                    {
                        "des": "హనుమంతుడి పద ఘట్టనంతో అరిష్ట పర్వతం నేలలో క్రుంగిపోయింది. ఒక మహానౌక సముద్రాన్ని దాటినట్లుగా హనుమంతుడు సునాయాసంగా ఆకాశాన్ని దాటాడు. దారిలో మైనాక పర్వతాన్ని గౌరవంగా స్పృశించి, ఉత్తర సాగర తీరం సమీపించగానే పెద్దయెత్తున గర్జించాడు. ఆ కేక విని జాంబవంతాదులు ఇది హనుమంతుని విజయసూచక ధ్వానమని గ్రహించి హర్షంతో గంతులు వేయసాగారు. మేఘంలాగా హనుమంతుడు మహేంద్రగిరిపై దిగి గురువులకు, జాంబవంతాది వృద్ధులకు, యువరాజు అంగదునకు ప్రణామం చేశాడు.కనుగొంటిని సీతమ్మను; ఆమె రాక్షసుల బంధీయై, రాముని కొరకు ఎదురు చూచుచు కృశించియున్నది. అని హనుమంతుడు చెప్పాడు."
                    },
                    {
                        "des": "కనుగొంటిన అన్న మాటలతో వానరు లందరూ పరమానందము పొందారు. అతనిని కౌగలించుకొని సంతోషంతో చిందులు వేశారు. తరువాత తన లంకా నగర సందర్శనా విశేషాలను అన్నింటినీ తన బృందంలోనివారికి వివరంగా చెప్పాడు హనుమంతుడు.ఇంక అంతా కలసి వెళ్ళి లంకను నాశనం చేసి, రావణుని ఓడించి, సీతను తెచ్చి రామునకు అప్పగించాలని అంగదుడు అభిప్రాయపడ్డాడు. కాని జాంబవంతుడు అందుకు వారించి, ముందుగా జరిగిన సంగతిని రామునకు, సుగ్రీవునకు నివేదించుట సరైనపని అని చెప్పాడు. అందరూ సంరంభంగా కిష్కింధకు బయలుదేరారు."
                    },

                ]
            },
            {
                "partname": "11.మధువనం",
                "description": [
                    {
                        "des": "సీత జాడ తెలియడం వలన అంగదాది వానరులంతా ఉత్సాహంగా హనుమంతుని పరివేష్టించి కిష్కింధకు బయలుదేరారు. దారిలో మధువనమనే మనోహరమైన వనాన్ని చేరుకొన్నారు. అది సుగ్రీవునిది. దధిముఖుడనే వృద్ధ వానర వీరుని పరిరక్షణలో ఉంది. అంగదుని అనుమతితో వానరులంతా ఆ వనంలో ఫలాలను కోసుకొని తింటూ, మధువులను గ్రోలుతూ, చిందులు వేస్తూ, మత్తెక్కి పిచ్చిగా ఆడుతూ వనాన్ని ధ్వంసం చేయసాగారు. అడ్డు వచ్చిన దధిముఖుని తీవ్రంగా దండించారు."
                    },
                    {
                        "des": "దిక్కు తోచని దధిముఖుడు తన తోటి వన రక్షకులతో కలిసి వేగంగా సుగ్రీవుని వద్దకు ఎగిరిపోయి జరిగిన అకృత్యం గురించి మొరపెట్టుకొన్నాడు.సీతాన్వేషణా కార్యం సఫలమయి ఉండకపోతే తన భృత్యులైన వానరులు అంతటి సాహసం చేయజాలరని సుగ్రీవుడు ఊహించాడు. వనభంగం అనే నెపంతో దధిముఖుడు సీతాన్వేషణా సాఫల్య సమాచారాన్ని ముందుగా సూచిస్తున్నాడని, శుభవార్త వినే అవకాశం ఉన్నదని రామలక్ష్మణులకు సుగ్రీవుడు చెప్పాడు. శుభవార్త తెలిపినందుకు దధిముఖుని అభినందించాడు."
                    },
                    {
                        "des": "దధిముఖుడు మధువనానికి తిరిగి వెళ్ళి అంగదాదులతో సాదరంగా మాట్లాడి త్వరగా సుగ్రీవుని వద్దకు వెళ్ళమన్నాడు. అంగదుడు, హనుమంతుడు, తక్కిన బృందం రివ్వున ఆకాశానికెగిరి ఝంఝూమారుతంలాగా సుగ్రీవుని వద్దకు బయలుదేరారు."
                    }

                ]
            },
            {
                "partname": "12.సీత జాడ తెలుపుట",
                "description": [
                    {
                        "des": "అంగదాది ప్రముఖులు, హనుమంతుడు మహోత్సాహంతో సుగ్రీవుడు, రామలక్ష్మణులు మొదలైనవారున్న ప్రస్రవణగిరిపై దిగారు. దృష్టా దేవీ (చూచాను సీతను) అని హనుమంతుడు చెప్పగానే రామలక్ష్మణులు మహదానంద భరితులయ్యారు. హనుమంతుని కార్య సాధనపై విశ్వాసము గల లక్ష్మణుడు సుగ్రీవునివంక ఆదరంగా చూశాడు. తక్కిన వానరుల ప్రోద్బలంతో హనుమంతుడు దక్షిణ దిక్కుకు తిరిగి సీతమ్మకు ప్రణమిల్లి, ఆమె ఇచ్చిన చూడామణిని రామునికి సమర్పించి, తన సాగర లంఘనా వృత్తాంతమును రామలక్ష్మణసుగ్రీవులకు వివరించాడు."
                    },
                    {
                        "des": "ఓ రామా! సీతామాత ఏకవేణియై, రాక్షస స్త్రీల నిర్బంధములో దీనురాలై నిరంతరము నిన్నే స్మరించుచున్నది. అందరిని కుశలమడిగినది. నీవు అనతి కాలములోనే వచ్చి ఆమెను విముక్తురాలను చేసి స్వీకరింతువనే ఆశ మాత్రముననే జీవించియున్నది. ఒక మాసము లోపల అట్లు కాకున్నచో తాను ప్రాణములతో ఉండజాలనన్నది. రామా! సింహ పరాక్రముడైన రాముని, ధనుష్పాణియైన లక్ష్మణుని త్వరలో లంకా ద్వారమున చూడగలవని చెప్పి ఆమెను అనునయించితిని. శుభకరమైన వచనములతో ఆమెను ఓదార్చి ఇటు వచ్చితిని.అని హనుమంతుడు శ్రీరామునకు విన్నవించాడు."
                    },
                    {
                        "des": "(యుద్ధకాండము - మొదటి సర్గము నుండి) - హనుమంతున మాటలు విని శ్రీరాముడు ప్రసన్నుడై ఇట్లు పలికెను. హనుమంతుడొనర్చిన ఘన కార్యములు లోకములోనే అత్యద్భుతములైనవి. ఊహకు అందనివి. అనితర సాధ్యములు. హనుమంతునితో సమానుడైన తేజోబల సంపన్నుడు ఎవ్వడును లేడు. దుష్కరమైన ప్రభుకార్యములను సాదించుటయే గాక, దానికి భంగము కలుగకుండ, తదనురూపములైన ఇతర కార్యములను కూడ సాధించు సేవకుడు అత్యుత్తముడు. ఈ కార్య సాధన ద్వారా హనుమంతుడు మాయందరి ప్రాణములను కాపాడినాడు."
                    },
                    {
                        "des": "నాకిట్టి మహోపకారమొనర్చిన హనుమంతునకు తగిన ప్రత్యుపకారము చేయలేని దీనుడనై యున్నాను. గాఢాలింగన సౌఖ్యమును మాత్రమే ఈయగలను. ప్రస్తుతము నేనీయగలిగిన నా సర్వస్వమిదియే, అని శ్రీరాముడు పులకిత గాత్రుడై తాను అప్పగించిన కార్యమును సాఫల్యమొనర్చి, పవిత్రాత్ముడై వచ్చిన హనుమంతుని తన హృదయమునకు హత్తుకొనెను."
                    },
                    {
                        "des": "ఆధ్యాత్మ రామాయణము: శ్రీరాముని పాదపద్మములను తులసీ దళాదులతో పూజించినవారు సాటిలేని పరమపదమును పొందెదరు. అట్టి శ్రీరామ చంద్రుడే పుణ్యముల రాశియైన హనుమంతుని అనుగ్రహించి, స్వయముగా ఆయనకు తన ఆలింగన సౌఖ్యమును ప్రసాదించెను. ఆ మారుతి భాగ్యమును ఎంతని కొనియాడగలము."
                    },
                ]
            }
        ]
    },
    {
        "title": "యుద్ధకాండము",
        "subtitle": [
            {
                "partname": "1.యుద్ధానికి సిద్ధం",
                "description": [
                    {
                        "des": "హనుమంతుడు చేసిన మహోపకారానికి రాముడు ఇంతటి క్లిష్టకార్యమును మరెవ్వరు సాధింపలేరు. మా అందరి ప్రాణములను నిలిపిన ఆప్తుడవు నీవు. నీవంటి దూత మరొకరు లేరు. గాఢాలింగనము కంటె నీకు నేనేమి బహుమానము ఇవ్వగలను అని హనుమను కౌగిలించుకొన్నాడు. విచారిస్తున్న రాముని సుగ్రీవుడు ధైర్యం చెప్పి ఓదార్చాడు. రాముని జయం తప్పదన్నాడు. హనుమంతుడు లంకా నగరం రక్షణా వ్వవస్థను విశదంగా తెలిపాడు. తరువాత అందరూ తర్కించి యుద్ధమునకు నిశ్చయించారు"
                    },
                    {
                        "des": "సరైన సమయము చూసి, నీలుని నాయకత్వములో బ్రహ్మాండమైన కపిసేన కోలాహలంగా దక్షిణమునకు పయనమైంది. రాముని ఆజ్ఞపై ఆ వానర సేన జనావాసాలమీద పడకుండా అడవులు, కొండలు, గుట్టలు, నదులు, సరస్సుల మీదుగా నడచింది. రాముని ఆజ్ఞలో నడచిన ఆ సేనకు నీలుడు సేనా నాయకుడు. సుగ్రీవుడు పాలకుడు. హనుమంతుని భుజాలపైన రాముడు, అంగదుని భుజాలపైన లక్ష్మణుడు అధిరోహించారు. "
                    },
                    {
                        "des": "జాంబవంతుడు, సుషేణుడు, వేగదర్శి, శతబలి, కేసరి, పనసుడు, గజుడు, అర్కుడు, వలీముఖుడు, ప్రజంఘుడు, జంభుడు, రభసుడు వంటి ఎందరో మహా వీరులు ఆ వానర భల్లూక సైన్యాన్ని ఉత్సాహపరుస్తూ ముందుకు సాగారు. రామకార్యం సాధించడానికి విక్రమోజ్వలులై ఉల్లాసంగా సాగరతీరము చేరుకొన్న ఆ సేన మరొక సాగరంలా ఉంది. వానర వీరులు ఒక చోట, గోలాంగూల వీరులు ఒకచోట, భల్లూకవీరులు మరొక చోట ఇలా మూడు భాగాలుగా విడిది చేశారు."
                    },
                ]
            },
            {
                "partname": "2.విభీషణ శరణాగతి",
                "description": [
                    {
                        "des": "అక్కడ లంకలో రావణుడు యుద్ధము విషయమై తనవారితో మంత్రాంగం నెరప సాగాడు. పుర భద్రత కట్టుదిట్టంగా ఉండాలని ఆనతిచ్చాడు. రాక్షస వీరులు రావణుని ప్రతాపాన్ని, తమ శక్తి సామర్ధ్యాలను కీర్తించుకొని, నిర్భయంగా ఉండమన్నారు. ఒక్కొక్కరు తానే రామలక్ష్మణులను కడతేర్చగలమన్నారు. కాని రావణుని తమ్ముడైన విభీషణుడు రావణునితో విభేదించాడు. రామలక్ష్మణుల క్రోధాగ్నికి లంక భస్మమవ్వడం తథ్యమని, రావణుడు అనాలోచితంగా సీత అనే కాలనాగును తన మెడకు చుట్టుకొన్నాడని నచ్చచెప్పడానికి యత్నించాడు."
                    },
                    {
                        "des": "సీతను రామునకప్పగించి చేసిన తప్పిదాన్ని సరిదిద్దుకోమన్నాడు. మళ్ళీ రావణుని సౌధానికి వెళ్ళి లంకలో అశుభ నిమిత్తాలనేకం ముప్పిరిగొన్నాయని, రానున్న విపత్తునుండి రాక్షసజాతిని కాపాడమని ప్రార్థించాడు.మరునాడు రావణుడు మంత్రగృహంలో కొలువుతీరినపుడు సకల అమాత్య బంధుగణంతో పాటు కుంభకర్ణుడు కూడా ఉన్నాడు. రావణుడు పొరపాటు చేశాడని, అయినా తాను విజృంభించి అతని కార్యం సిద్ధింపజేస్తానని కుంభకర్ణుడు అన్నాడు. మళ్ళీ విభీషణుడు హితవు చెప్పబోగా ఇంద్రజిత్తు, రావణుడు అతనిని నిందించారు."
                    },
                    {
                        "des": "తనకు ఆప్తులైన నలుగురు రాక్షసులతోకలిసి విభీషణుడు అన్నగారి సెలవు తీసికొని ఆకాశానికి ఎగిరి, సాగరముదాటి, రాముని శరణు జొచ్చాడు. అతనిని నమ్మవద్దని కపివీరులన్నారు. విభీషణుడు సౌమ్యుడని, నమ్మదగినవాడని హనుమంతుడు చెప్పాడు. శరణుకోరిన సకల భూతాలకు అభయం ఇవ్వడం తన వ్రతమని చెప్పి రాముడు విభీషణునకు ఆశ్రయమిచ్చాడు. రావణుడు, కుంభకర్ణుడు, ఇంద్రజిత్తు, ప్రహస్తుడు వంటి మహాయోధుల పరాక్రమాన్ని, లంకా నగరం పటిష్ఠతను విభీషణుడు వివరించాడు. "
                    },
                    {
                        "des": "సపుత్ర బాంధవంగా రావణుడిని చంపిగాని తాను అయోధ్యకు మరలనని రాముడు తన తమ్ములు ముగ్గురిమీదా ఒట్టుపెట్టి చెప్పాడు. కానున్న లంకాధిపతిగా విభీషణునికి సాగరజలాలతో అభిషిక్తుని చేయించాడు రాముడు."
                    },
                ]
            },
            {
                "partname": "3.సాగరంపై వారధి",
                "description": [
                    {
                        "des": "తమ మధ్య వైరం ఏమీ లేదు గనుక రామునికి సాయం చేయవద్దని రావణుడు సుగ్రీవునికి శుకుడనే దూత ద్వారా దౌత్యం పంపాడు. అందుకు సుగ్రీవుడు రావణా! నాకు నువ్వు చేసిన సాయం లేదుగనుక నాకు ప్రియుడవు కావు. రామునికి విరోధివి గనుక నాకు కూడా విరోధివే. రాముని కోపానికి గురైనందున నిన్ను రక్షించే శక్తి ముల్లోకాలలోనూ లేదు అని సమాధానం పంపాడు. సముద్ర తరణానికి మార్గం ఏమిటని హనుమంతుడు, సుగ్రీవుడు విభీషణుని అడిగారు. రాముడు సముద్రుని సాయం కోసం అర్ధించాలని విభీషణుడు సలహా ఇచ్చాడు."
                    },
                    {
                        "des": "సముద్రుని సహాయం కోరి రాముడు మూడు రాత్రులు నియమంగా దీక్ష వహించినా సముద్రుడు ప్రత్యక్షం కాలేదు. కోపించి రాముడు సముద్రాన్ని ఎండగట్టేస్తానని ధనుస్సు ఎక్కుపెట్టాడు. సముద్రుడు వచ్చి, వినయంగా నమస్కరించి, తన స్వభావాన్ని త్యజింపలేనని మనవి చేశాడు. విశ్వకర్మ కొడుకైన నలుని ప్రజ్ఞతో వారధిని నిర్మింపవచ్చునని తెలిపాడు. సముద్రుని కోరికపై రాముడు తన అస్త్రాన్ని ద్రుమకుల్యంలోని దస్యులపై విడిచిపెట్టాడు.క సాగరమును దాటుటకు అద్భుతమైన వారధి నిర్మాణము నలుని పర్యవేక్షణలో ప్రారంభమైనది."
                    },
                    {
                        "des": "మొదటి రోజు 14 ఆమడలు, రెండవ రోజు 20 ఆమడలు, మూడవ రోజు 21 ఆమడలు, నాలుగవ రోజు 22 ఆమడలు, ఆయిదవరోజు 23 ఆమడలు - ఇలా అయిదు దినములలో 100 యోజనముల పొడవు, 10 యోజనముల వెడల్పు గల వారధి నిర్మించారు. విభీషణుడు ఒక ప్రక్క వారధికి రక్షణగా నిలిచాడు. వానర భల్లూకసేనల, రామలక్ష్మణులు వారధి దాటి లంకను చేరారు. నీలుని నాయకత్వంలో ఆ సేన మరో సాగరంలా ఉండి, రామకార్యానికి సన్నద్ధమై ఉంది. దుంపలు, ఫలాలు, జలం పుష్కలంగా ఉన్నచోట విడిది చేశారు."
                    },
                    {
                        "des": "త్రికూట పర్వతం పైన లంకా పట్టణం సంకల్పమాత్రాన విశ్వకర్మ నిర్మించినట్లు వైభనంగా ఉంది. ఆ శోభను గమనించి రాముడు ఆశ్చర్య పోయాడు. భూమి రక్తంతో తడిసిపోయేంత యుద్ధం నిశ్చయమని రామునికి శకునాలు తోచాయి. తన సేనను జాగరూకతతో ఉండమని వ్యూహనిర్దేశనం చేశాడు. తరువాత తమకు బందీగా ఉన్న శుకుడు అనే దూతను విడుదల చేయించాడు."
                    },
                ]
            },
            {
                "partname": "4.భల్లూక వానర వీర సేన",
                "description": [
                    {
                        "des": "రావణుడి చారులైన శుక సారణులు రామ, లక్ష్మణ, సుగ్రీవ, జాంబవంత, హనుమంతాది వీరుల పరాక్రమాన్ని రావణునికి వివరించారు. వానరసేన ఎంత ఉందో లెక్కపెట్టడం అసాధ్యమన్నారు. సీతను రామునకప్పగించడం మంచిదని తమకు తోచిందన్నారు. ప్రాసాదం పైకి తీసుకెళ్ళి వానరవీరుల సేనానాయకులలోని ముఖ్యులను చూపించారు వేనవేల సేనాధిపతులతో కలిసి సింహనాదం చేస్తున్నవాడు సకల వానర సైన్యాధిపతి నీలుడు. అంగదుడు తనతండ్రితో సమానుడైన వాడు, రాముని విజయంకోసం కృతనిశ్చయుడు."
                    },
                    {
                        "des": "సముద్రం మీద సేతువును నిర్మించిన ఘనుడు నలుడు. త్రిలోకాలలోనూ ప్రఖ్యాతుడైన శ్వేతుడు వెండిలా మెరిసిపోతున్నాడు. యుద్ధం అంటే అతిప్రీతి కలిగిన కుముదుడు గోమతీతీరం నుండి వచ్చాడు. కపిలవర్ణుడై న రంభుడు వింధ్య పర్వతాలనుండి వచ్చాడు. చెవులు రిక్కించిన శరభుడు మృత్యుభీతి లేనివాడు. పర్వతంలాంటి పనసుడు పారియాత్రంనుండి, వినతుడు కృష్ణవేణీ తీరంనుండి లక్షలాది సేనతో వచ్చారు."
                    },
                    {
                        "des": "అత్యంత బలశాలి క్రోధుడు 60 లక్షల సైన్యంతోను, గైరిక వర్ణంలో ఉన్న మహాదేహుడు గవయుడు 70 లక్షల సైన్యంతోను వచ్చారు. కోట్లాది అనుచరులతో వచ్చిన హరుడు అనే సేనానాయకుడు మహాఘోరంగా యుద్ధం చేస్తాడు.కాటుక కొండలలాంటి లెక్కలేనన్ని భల్లూకాలతో నర్మదా నదీతీరపు ఋక్షవంతం నుండి వచ్చిన నాయకుడు ధూమ్రుడు చాలా భయంకరుడు. "
                    },
                    {
                        "des": "ధూమ్రుని తమ్ముడు జాంబవంతుడు దేవాసుర యుద్ధంలో దేవేంద్రునకు సాయపడ్డాడు. వానర యోధులలో ప్రఖ్యాతుడు దంభుడు చాలా బలశాలి. కొండలాంటి రూపం కలిగిన వానర పితామహుడు సంనాదుడు కలియబడితే ఒకసారి దేవేంద్రుడే తగ్గిపోయాడు. పదికోట్ల వీరులతో హిమవద్గిరినుండి వచ్చిన క్రధనుడు పరాజయం ఎరుగనివాడు. ఏనుగులను దండించడం వినోదంగా భావించే ప్రమాధి, అతని అనుచరులు లంకపై పడడానికి ఉవ్విళ్ళురుతున్నారు. "
                    },
                    {
                        "des": "గవాక్షునివెంట ఇంకా కోట్లకొలది వానరులు సేతువును దాటి వస్తూనే ఉన్నారు. మేరుపర్వతం నుండి వచ్చిన వానరుల అధిపతి కేసరి. కాలసర్పాలలా ఘోర భీకరమైన వానరుల నాయకుడు శతబలి కాంచనపర్వత ప్రాంతంనుండి వచ్చాడు. గజుడు, గవాక్షుడు, గవయుడు, నలుడు, నీలుడు - వీరి వద్దనున్న వానరుల సంఖ్యాబలం చెప్పనలవి కానిది. సుగ్రీవుని ఆజ్ఞను జవదాటని వానరవీరులు మహా బలవంతులు, దుర్జయులు, కామరూపులు. మైందుడు, ద్వివిధుడు అనే సోదరులు బ్రహ్మ దేవుని అనుగ్రహం సంపాదించి అమృతపానం చేశారు. సుముఖుడు, దుర్ధరుడు అనే సోదరులు మృత్యుదేవత కొడుకులు."
                    },
                    {
                        "des": "కేసరి నందనుడు, వాతాత్మజుడు అనబడే హనుమంతునికి ముల్లోకాలలోను ఎదురులేదు. అతనికి కోపం వస్తే సముద్రాన్ని కలచివేయగలడు. వేదవేదాంగాలు నేర్చినవాడు, ధర్మం తప్పని వాడు, నీలమేఘ శ్యాముడు, పద్మనేత్రుడు అయిన రాముని క్రోధం మృత్యువుతో సమం. బుద్ధిమంతుడు, పట్టరాని కోపంగలవాడు, అన్నకోసం జీవితాన్ని విడిచిపెట్టేవాడు, గురి తప్పని బాణం కలవాడు లక్ష్మణుడు. రాముని ప్రక్కనున్న నీ తమ్ముడు విభీషణుని లంకాధిపతిగా రాముడు పట్టాభిషిక్తుని చేశాడు. సర్వశాఖామృగాధిపతి సుగ్రీవుడు కిష్కింధనేలుతున్నాడు. హిమవత్పర్వతంలా వానరులనందరినీ మించినవాడు. "
                    },
                    {
                        "des": "సీతారాములను కలపడానికి దీక్షాబద్ధుడు. ఇలా మహా బృందాలు, మహౌఘాల సంఖ్యలో వానరులు లంకపై దండెత్తి వచ్చారు.రావణుడు పంపిన మరొక చారుడు శార్దూలుడు కూడా ఇదే విషయాన్ని ధృవీకరించాడు. వానర భల్లూక వీరుల పరాక్రమం గురించి మరిన్ని విషయాలు చెప్పాడు. ఋక్షరజసుని కొడుకు సుగ్రీవుడు గద్గదుని కొడుకులు ధూమ్రుడు, జాంబవంతుడు బృహస్పతి కొడుకు కేసరి వరుణుని కొడుకు సుషేణుడు చంద్రుని కొడుకు దధిముఖుడు - కృంతాంతుని అంశలలాంటి సుముఖుడు, దుర్ముఖుడు, వేగదర్శి పావకుని పుత్రుడు నీలుడు వాయుపుత్రుడు హనుమంతుడు పురుహూతుని పౌత్రుడు అంగదుడు"
                    },
                    {
                        "des": " అశ్వనీ దేవతల అంశలతో పుట్టినవారు మైందుడు, ద్వివిధుడు సమవర్తి సుతులలాంటి గజుడు, గవాక్షుడు, గవయుడు, గంధమాదనుడు, శరభుడు భాస్కర సుతులు శ్వేతుడు, జ్యోతిర్ముఖుడు వరుణుని ఆత్మజుడు హేమకూటుడు దేవశిల్పి కుమారుడు నలుడు వసుపుత్రుడు దుర్ధరుడు అందరూ దేవతాంశ సంభూతులు, యుద్ధం కోసం ఎదురు చూస్తున్నవారు. ఖరదూషణాది రాక్షసులను 14 వేలమందిని ఒక్కుమ్మడిగా చంపినవాడు రాముడు. అతనికి దీటైన తమ్ముడు లక్ష్మణుడు. రాక్షసులలో వివేకవంతుడు విభీషణుడు.   ఇలా మొహరించిన సేననెదుర్కొని విజయమో వీర స్వర్గమో పొదడం రావణుని చేతిలో ఉంది."
                    }
                ]
            },
            {
                "partname": "5.యుద్ధానికి ముందు",
                "description": [
                    {
                        "des": "రావణుడు విద్యుజ్జిహ్వుడనే మాయలమారి రాక్షసుని పిలిపించి రాముని శిరస్సును పోలిన ఒక మాయా శిరస్సును, ధనుర్బాణాలను చేయించాడు. యుద్ధంలో రామలక్ష్మణులు, వానర సైన్యం నశించారని సీతతో చెప్పి ఆ మాయా శిరస్సును, ధనుర్బాణాలను చూపాడు. సీత కన్నీరు మున్నీరుగా విలపించసాగింది. రావణుడు వెళ్ళిపోయాక విభీషణుని భార్య, సరమ అనే సాధ్వి సీతను ఓదార్చి అది మాయ అని, రహస్యంగా తాను అంతా విన్నానని చెప్పింది. యుద్ధానికి భల్లూక వానర సమేతంగా రాముడు సిద్ధంగా ఉన్నాడని, సీతకు శుభ సౌభాగ్య సమయం ఆసన్నమయందని అనునయించింది. రావణుని వినాశనం అనివార్యమంది."
                    },
                    {
                        "des": "రావణుని తల్లికి పినతండ్రి అయిన మాల్యవంతుడనే వృద్ధుడు రావణునికి యుద్ధం మానమని హితవుపలుకబోయాడు. అతనిని రావణుడు కఠినంగా దూషించాడు. వానర సేనా, రామలక్ష్మణులూ అజేయులు, అసమానులు అని రావణుడు విన్నాడు కాని కాని ప్రహస్తుని నాయకత్వములోని రావణ సేనాబలం కూడా పరాజయం ఎరుగనిది. ముఖ్యంగా ప్రహస్తుడూ, ఇంద్రజిత్తూ, నికుంభుడూ - వీరిలో ఎవరైనా తప్పక రామలక్ష్మణులను కడతేర్చగలరనీ, కనుక ఇక ఇంద్రుని వజ్రాయుధాన్ని గడ్డిపోచలా తలిచే కుంభకర్ణుడూ, తనూ యుద్ధానికి రావలసిన అవుసరమే రాదనీ రావణుడి విశ్వాసం."
                    },
                    {
                        "des": "సైన్యాన్ని సమాయత్తపరచి అన్ని యెడలా రక్షణకు పటిష్ఠమైన ఏర్పాట్లు చేయించాడు రావణుడు. తూర్ప ద్వారంలో ప్రహస్తుడు, దక్షిణాన మహాపార్శ్వ మహోదరులు, పశ్చిమాన ఇంద్రజిత్తు, ఉత్తరాన శుక సారణులు అప్రమత్తులై యున్నారు. విరూపాక్షుడు లంకానగరం మధ్యనున్నాడు. రావణుడు స్వయంగా ఇతర స్థలాలతో పాటు ఉత్తర ద్వారాన్ని పర్యవేక్షిస్తానని చెప్పాడు. ఇవన్నీ విభీషణుని చారుల ద్వారా రాముడు తెలుసుకొన్నాడు. తూర్పు ద్వారం వైపు నీలుడు, దక్షిణానికి అంగదుడు, పశ్చిమం ప్రక్కకు హనుమంతుడు తమ సైన్యాలతో దండు వెళ్ళేలాగా నియమించాడు. "
                    },
                    {
                        "des": "తాను స్వయంగా ఉత్తర ద్వారం వైపు విజయం చేస్తానన్నాడు. సుగ్రీవుడూ, జాంబవంతుడూ, విభీషణుడూ సైన్యం మధ్యలో ఉండి అంతా చక్కబెడతారు. ఇలా నియమించి, సువేల శిఖరంపైకి ముఖ్య నాయకులతో వెళ్ళి రాముడు లంకానగరాన్ని పర్యవేక్షించాడు. దూరాన ఒక గోపురాగ్రాన రావణుడు కనిపించాడు. అతనిని చూడగానే క్రోధంతో సుగ్రీవుడు ఎగిరి వెళ్ళి భీకరమైన మల్లయుద్ధం చేశాడు. రావణుడు మాయలు ప్రయోగించడానికి సన్నద్ధమయ్యేసరికి ఒక్కగెంతున తిరిగి వచ్చేశాడు."
                    },
                    {
                        "des": "రాముని పంపున అంగదుడు దూతగా వెళ్ళి చివరిసారిగా రావణునికి రాముని సందేశాన్ని వినిపించాడు. సీతనిచ్చి శరణు కోరితే రాముడు క్షమించి వదిలి వేస్తాడని చెప్పాడు. అంగదుని రావణుడు తృణీకరించాడు. అంగదుడు రావణుని గోపుర శిఖరాన్ని కాలితో తన్ని పడగొట్టి తిరిగి వచ్చాడు."
                    }
                ]
            },
            {
                "partname": "6.రామ లక్ష్మణ సుగ్రీవులకు జయం",
                "description": [
                    {
                        "des": "అంటూ వానరసేన లంకను ముట్టడించింది. ముందుగా మట్టితోను, బండరాళ్ళతోను, చెట్లతోను అగడ్తను పూడ్చివేసి ప్రాకరాలు ఆక్రమించారు. గోపురాలు, ప్రాకార తోరణాలు పడగొట్టేశారు. వీరబాహువు, సుబాహుడు, నలుడు ప్రాకారాలు భేదించ సాగారు. కుముద, ప్రఘన, పనసులు తూర్పున, శతవలి దక్షిణాన, సుషేణుడు పడమర, మహాకాయ గవాక్ష ధూమ్రులు ఉత్తరాన ద్వారాలు బ్రద్దలుకొట్టసాగారు. ప్రళయకాల సముద్రంలాగా కాల మేఘాల్లాంటి రాక్షససేన సింహనాదాలతో, దుందుభి ధ్వానాలతో వానరులను ఎదుర్కొంది. మహాయద్ధంతో భునభోంతరాళాలు కంపిస్తున్నాయి. ఒక్క క్షణంలో యుద్ధం భీకరం అయి భూమి అంతా రక్త మాంసాలతో నిండిపోయింది."
                    },
                    {
                        "des": "వానర రాక్షస ప్రముఖుల మధ్య ద్వంద్వ యుద్ధాలు ఆరంభమయ్యాయి. అంగదుడు ఇంద్రజిత్తుతోను - (విభీషణుని సచివుడు) సంపాతి ప్రజంఘునితోను - హనుమంతుడు జంబుమాలితోను - విభీషణుడు శత్రుఘ్నునితోను - గజుడు తపనునితోను - నీలుడు నికుంభునితోను - సుగ్రీవుడు ప్రఘసునితోను - లక్ష్మణుడు విరూపాక్షునితోను - మైందుడు వజ్రముష్ఠితోను - ద్వివిధుడు అశనిప్రభునితోను - నలుడు ప్రతపనునితోను - సుషేణుడు విద్యున్మాలితోను - తలపడ్డారు. అగ్నికేతువు, రశ్మికేతువు, సుప్తఘ్నుడు, మధ్యకోపుడు అనే రాక్షసులు రాముని చుట్టుముట్టి, అతని చేత మరణించారు. రాత్రి అయినా కొనసాగుతున్న యుద్ధం వల్ల రక్తం నదులుగా ప్రవహించింది. అది లోకక్షయమైన కాలరాత్రిగా మారింది."
                    },
                ]
            },
            {
                "partname": "7.నాగపాశ విమోచన",
                "description": [
                    {
                        "des": "అంగదుని చేత పరాభవం పొందిన ఇంద్రజిత్తు ఒక్కసారిగా మాయమైపోయాడు. మాయాయుద్ధమారంభించి నాగాస్త్రంతో రామలక్ష్మణులను వివశులను చేసి శతృసైన్యాన్ని భయకంపితులను చేశాడు. రామలక్ష్మణుల దేహంపై ప్రతి అంగుళం బాణాలతో నిండిపోయింది. వానరసేన సిగ్గువిడిచి పరుగులు తీసింది. అందరు వానర సైన్యాధిపతులూ ఇంద్రజిత్తు బాణాలతో గాయపడినవారే. ఆ నాగాస్త్ర బంధాలనుండి తప్పుకోవడం ఎవరివల్లా కాదని విజయ గర్వంతో ఇంద్రజిత్తు తండ్రివద్దకు వెళ్ళి తాను రామలక్ష్మణులను చంపి వానరసేనను నిర్వీర్యం చేసేశానని చెప్పాడు. రావణుడు ఆనందించి, ఆ రాఘవుల మృతదేహాలను సీతకు చూపమని ఆదేశించాడు. "
                    },
                    {
                        "des": "పుష్పకంపై సీతను తెచ్చి ఆ దృశ్యం చూపగా ఆమె విలపించ సాగింది. రామలక్ష్మణులు కేవలం వివశులయ్యారని, త్వరలో కోలుకొంటారని చెప్పి,త్రిజట సీతను ఊరడించింది.భయభీతులైన వానరులంతా రాఘవుల చుట్టూ కూర్చుని దుఃఖించసాగారు. మళ్ళీ రాక్షసులు వస్తారేమోనని కంగారుపడసాగారు. కొద్దిగా సృహ వచ్చిన రాముడు లక్ష్మణుని చూచి వ్యాకులపడి, తన ప్రతిజ్ఞలన్నీ మిధ్యలయ్యాయని వగచి, ప్రాయోపవేశానికి సిద్ధనయ్యాడు. తనకు చేసిన సాయానికి కృతజ్ఞతలు తెలిపి వానరులను తిరిగి వెళ్ళిపొమ్మన్నాడు."
                    },
                    {
                        "des": "వారు శక్తి వంచన లేకుండా మిత్ర కార్యం నిర్వహించారని, అయినా ఈశ్వరాజ్ఞ ఉల్లంఘించరానిదని చెప్పాడు. వానరులందరూ కళ్ళనీళ్ళు పెట్టుకొన్నారు. శరతల్పగతులైన రామలక్ష్మణులను చూచి విభీషణుడు హతాశుడయ్యాడు సుగ్రీవుడు మాత్రం విభీషణుడిని ఓదార్చి అతనికి లంకాధిపత్యం నిశ్చయమన్నాడు. రామలక్ష్మణులను తీసికొని కిష్కింధకు వెళ్ళమని తన మామ సుషేణుడికి ఆనతిచ్చాడు. తాను రావణుడిని సపుత్ర బాంధవంగా నాశనం చేసి సీతమ్మను తీసుకొని వస్తానన్నాడు. సంపాతి, పనసుడు, హనుమంతుడు వెళ్ళి దివ్యౌషధాలను తెస్తే ప్రయోజనం ఉంటుందని సుషేణుడన్నాడు."
                    },
                    {
                        "des": "అంతా విషణ్ణులైన సమయానికి పెద్ద సుడిగాలి వీచి సముద్రం కల్లోలమయ్యింది.గరుత్మంతుడు మహాప్రభంజనంలా వచ్చాడు. అతని రాకతో శరరూపంలో రామ సౌమిత్రులను పట్టుకొని ఉన్న సర్పాలన్నీ పారిపోయాయి. గరుడుడు తన రెండు చేతులతోను రామలక్ష్మణుల సర్వావయవాలను నిమిరాడు. వెంటనే వారి గాయాలు మాయమై వారికి మునుపటికంటె ఎక్కువ తేజస్సు, బల వీర్య పరాక్రమాలు సమకూరాయి. వారిని కౌగలించుకొని గరుడుడు మీరు జాగరూకతతో ఉండండి. నేను మీకు స్నేహితుడనెలా అయ్యానో తరువాత తెలుస్తుంది. రామా! నువ్వు లంకను నాశనం చేసి రావణుని చంపి సీతను పొదడం తథ్యం అని చెప్పి, రామలక్ష్మణులకు ప్రదక్షిణం చేసి ఆకాశానికి ఎగిరి పోయాడు"
                    },
                    {
                        "des": "రామలక్ష్మణులు స్వస్థులవ్వడంతో వానరులు కుప్పిగంతులు వేస్తూ, సింహనాదాలు చేస్తూ, పరవళ్ళు తొక్కుతూ చెట్లు పీకి యుద్ధానికి సన్నద్ధులయ్యారు. తెల్లబోయిన రావణుడు ధూమ్రాక్షుని యుద్ధానికి పంపాడు. ధూమ్రాక్షుడు పెద్ద సైన్యాన్ని వెంటబెట్టుకొని దక్షిణద్వారానికి వెళ్ళి వానరులను చెండాడసాగాడు. ప్రతి వానరవీరుడూ తన పేరు చెప్పుకొంటూ రాక్షసులను చావబాదసాగాడు. హనుమంతుడు విసిరి వేసిన పెద్ద గిరిశిఖరం క్రింద పడి ధూమ్రాక్షుడు పచ్చడి అయిపోయాడు.0"
                    }
                ]
            },
            {
                "partname": "8.రాక్షస వీరుల మరణం",
                "description": [
                    {
                        "des": "రామలక్ష్మణులు స్వస్థులవ్వడంతో వానరులు కుప్పిగంతులు వేస్తూ, సింహనాదాలు చేస్తూ, పరవళ్ళు తొక్కుతూ చెట్లు పీకి యుద్ధానికి సన్నద్ధులయ్యారు. తెల్లబోయిన రావణుడు ధూమ్రాక్షుని యుద్ధానికి పంపాడు. ధూమ్రాక్షుడు పెద్ద సైన్యాన్ని వెంటబెట్టుకొని దక్షిణద్వారానికి వెళ్ళి వానరులను చెండాడసాగాడు. ప్రతి వానరవీరుడూ తన పేరు చెప్పుకొంటూ రాక్షసులను చావబాదసాగాడు. హనుమంతుడు విసిరి వేసిన పెద్ద గిరిశిఖరం క్రింద పడి ధూమ్రాక్షుడు పచ్చడి అయిపోయాడు."
                    },
                    {
                        "des": "ధూమ్రక్షుని మరణంతో నిట్టూర్చిన రావణుడు గొప్ప శూరుడు, వీరుడు, మాయావి అయిన వజ్ర దంష్ట్రుడిని యుద్ధానికి వెళ్ళమన్నాడు. వజ్ర దంష్ట్రుని సేన పర్వతాలే నడచి వచ్చినట్లు దక్షిణ ద్వారంవైపు నడచింది. అప్పుడు జరిగిన సంకుల సమరం వల్ల మృత కళేబరాలతోను, అవయవాలతోను, శస్త్రాస్త్రాలతోను, అవయవాలతోను, ఆభరణాలతోను యుద్ధభూమి నిండిపోయింది. రక్తంలో తేలుతున్న శరీరావయవాలను గ్రద్దలు, రాబందులు, నక్కలు పీక్కుతినసాగాయి. అంగదుని ధాటికి రాక్షసులు కంపించిపోయారు.అంగదుడు, వజ్రదంష్ట్రుడు ఘోరంగా ముష్టియుద్ధం చేశారు. చివరకు అంగదుడు వజ్రదంష్ట్రుని తల నరికేశాడు. రాక్షసులు లంకలోకి పరుగులు పెట్టారు. "
                    },
                    {
                        "des": "పిదప మహావీరుడైన అకంపనుడు యుద్ధోత్సాహంతో, సముద్రంలాంటి తన సేనను వెంటబెట్టుకొని బయలుదేరాడు. వానరులు, రాక్షసులు ఒకరికొకరు తీసిపోకుండా భీకరమైన పోరు సాగించారు. ముందుగా రేగిన దుమారంలాంటి దుమ్ము తరువాత రక్తపుటేరుల కారణంగా అణగిపోయింది. ఉత్సాహంగా రాక్షసులను చుట్టుముట్టిన వానరులను అకంపనుడు తన బాణవర్షంతో వాశనం చేయసాగాడు. హనుమంతుడు అతనిని నిలువరించాడు. హనుమ పాదతాడనంతో భూమి కంపించింది. ఒక మహావృక్షాన్ని పెరికి దానితో అకంపనుని చూర్ణం చేసేశాడు. రాక్షసులు ఒక్కుమ్మడిగా ఒకరినొకరు తోసుకుంటూ, తొక్కుకుంటూ నగరంలోకి పారిపోయారు."
                    },
                    {
                        "des": "ఇప్పుడేమి చేయాలని రావణుడు ప్రహస్తుని అడిగాడు. ప్రహస్తుడు రావణుని సేనానాయకుడు. శస్త్రాస్త్రవేది. మహావీరుడు. అంతకుముందు మంత్రాంగ సమయంలో సీతను ఇచ్చివేయడమే క్షేమమని హితవు చెప్పినవాడు. రావణుని ఆదరానికి బదులు తీర్చుకోవడమే తన బ్రతుకుకు లక్ష్యమని చెప్పి, ప్రహస్తుడు హోమాదికాలు పూర్తి చేసుకొని, సర్ప ధ్వజంతో కూడిన గొప్ప రథం పూన్చి, శత్రుభీకరమైన మహోన్నత సేనను సమకూర్చుకొని తూర్పు ద్వారంవైపు యుద్ధానికి బయలుదేరాడు. అతనివెంట నరాంతకుడు, కుంభహనువు, మహానాధుడు, సమున్నతుడు అనే సమర్ధులైన అనుచరులున్నారు. రాక్షస వానర వీరులు జబ్బలు చరిచి యుద్ధానికి తలపడ్డారు. ఇరుపక్షాల సింహనాదాలు, రోదనలతో నింగీ నేలా దద్దరిల్లాయి."
                    },
                    {
                        "des": "ద్వివిధుడు పర్వత శిఖరంతో కొట్టి నరాంతకుడిని చంపేశాడు. దుర్ముఖుడు పెద్ద చెట్టుతో బాది సమున్నతుడిని కూల్చేశాడు. జాంబవంతుడు పెద్దరాతితో మహావాదుడిని పచ్చడి చేసేశాడు. తారుడు పెద్ద చెట్టుతో బాది కుంభహనువును మట్టిలో కలిపాడు. ప్రహస్తుడు ఎందరో వానరవీరులను ఒకేసారి సంహరించాడు. వానరుల కళేబరాలతోను, రాక్షసుల కళేబరాలతోను యుద్ధభూమి ఎర్రని పర్వతాల మయమైపోయింది. వాటిమధ్య రక్తం నదులుగా పారుతోంది. మేఘంపైకి సుడిగాలిలాగా నీలుడు ప్రహస్తుని మీదకురికాడు. ఇద్దరూ మదపుటేనుగుల్లాగా కలియబడ్డారు. ఒక పెద్ద బండరాతితో నీలుడు ప్రహస్తుని తల బద్దలుకొట్టి చంపేశాడు. రాక్షససేన కట్టతెగిన నదీప్రవాహంలాగా లంకలోకి పారిపోయింది."
                    }
                ]
            },
            {
                "partname": "9.రావణునికి పరాభవం",
                "description": [
                    {
                        "des": "ఇక లాభం లేదనుకొని రావణుడు స్వయంగా మహావీరులైన రాక్షసగణాలను వెంటబెట్టుకొని దివ్యరథంపై యుద్ధానికి వెడలాడు. అతనివెంట రాక్షససేన కదలి వచ్చే కాటుక కొండలలాగా ఉంది. ఆ సైన్యం అగ్ర భాగాన ముందు సూర్యబింబంలా ప్రజ్వలించే అకంపనుడు, గొప్ప ధనుసును ధరించిన ఇంద్రజిత్తు, వింధ్యపర్వత సదృశ దేహుడైన మహోదరుడు, పిడుగులాంటి పిశాచుడు, మెరుపులాంటి శూలం పట్టుకొన్న త్రిశిరుడు, సర్ప ధ్వజుడైన కుంభుడు, వజ్రాలు పొదిగిన పరిఘను పట్టుకొన్న నికుంభుడు, అగ్నిలాంటి రథం అధిరోహించిన నరాంతకుడు రణోత్సాహులై ఉన్నారు. వారి మధ్య శ్వేతఛత్ర ధారియై రావణుడు ప్రచండ భానునిలా మెరిసిపోతున్నాడు."
                    },
                    {
                        "des": "రావణుని తేజస్సును చూసి రాముడు ఆశ్చర్యపోయాడు. సీతను అపహరించిన పాపానికి రావణునికి అంత్యకాలం సమీపించిందని చెబుతూ రాముడు ధనుస్సు ఎక్కుపెట్టాడు.లంకా నగర రక్షణార్ధమై తక్కిన రాక్షస నాయకులను రావణుడు వెనుకకు పంపేశాడు. కాలనాగులవంటి తన బణాలతో వానర సైన్యాన్ని చిందరవందర చేయసాగాడు. సుగ్రీవుడు మూర్ఛపోయాడు. గవాక్షుడు, ఋషభుడు, గవయుడు, జ్యోతిర్ముఖుడు, నలుడు వేసిన పర్వత శిఖరాలను రావణుడు పిండి పిండి చేసేశాడు. హనుమంతుని అరచేతి చరుపుకు రావణుడు, రావణుని పిడికిలి పోటుకు హనుమంతుడు కంపించిపోయారు. నీలుడు అంగుష్ఠమాతృడై రావణుని చికాకు పరచాడు"
                    },
                    {
                        "des": "నీలుడు అగ్ని పుత్రుడు గనుక రావణుని ఆగ్నేయాస్త్రం నీలుని సంహరించలేకపోయింది. లక్ష్మణుడి బాణాలతో రావణుడి ధనుసు విరిగిపోయింది. రావణుడి శక్తితో లక్ష్మణుడు తెలివి తప్పాడు. అతనిని రావణుడు ఎత్తలేకపోయడు. హనుమంతుడు లక్ష్మణుని ఎత్తి రామునివద్ద పడుకోబెట్టాడు. హనుమంతుని గుద్దుకు రావణుడు నెత్తురు కక్కి మూర్ఛిల్లాడు. తెలివి తెచ్చుకొని మళ్ళీ శరాఘాతం ప్రాంభించాడు."
                    },
                    {
                        "des": "హనుమంతుని భుజాలపై అధిరోహించి రాముడు రావణునిపై పోరు సాగించాడు. రావణుని వాడి బాణాలకు ఆంజనేయుడు జంకలేదు. అప్పుడు జరిగిన భీకరసంగ్రామంలో రావణుని రథం, గుర్రాలు, ఛత్రం, ధ్వజం ధ్వంసమయ్యాయి. కిరీటము నేలబడింది. ధనుసు చేజారింది. విశ్రాంతి తీసికొని మరునాడు యుద్ధానికి రమ్మని రాముడు రావణునికి చెప్పాడు. సిగ్గుతో రావణుడు లంకలోకి వెళ్ళిపోయాడు."
                    },
                ]
            },
            {
                "partname": "10.కుంభకర్ణుని మరణం",
                "description": [
                    {
                        "des": "అవమాన భారంతో కృంగిన రావణుడు తన సోదరుడైన కుంభకర్ణుని నిదురలేపమని మంత్రులను పంపాడు. భేరీ భాంకారాలు చేసి, శూలాలతో పొడిచి, ముసలాలతో మోది, ఏనుగులతో త్రొక్కించి, ఏనుగులతో త్రొక్కించి వారు కుంభకర్ణుని నిదురనుండి లేపారు. లేవగానే కుంభకర్ణుడు మాంసరాసులను సుష్ఠుగా తిని, కుండలతో రక్తం త్రాగి, త్రేవ్చాడు. విషయం తెలుసుకొని, స్నానం చేసి, సర్వాభరణాలు ధరించి, బలకరమైన మద్యం భాండాలతో త్రాగి రావణుని చెంతకు వెళ్ళాడు. అతను నగరంలో నడుస్తుంటేనే భయంతో వానరసేనలు కకావికలమయ్యాయి."
                    },
                    {
                        "des": "అంత వీరుడు రాక్షసులలో మరొకరు లేరని విభీషణుడు చెప్పాడు. అది ఒక యంత్రమని, రాక్షసుడు కాదని, వారు చెప్పి వానర సేనను స్థిమిత పరచారు.రావణుడు కుంభకర్ణుడికి జరిగిన విషయం వివరించాడు. అనాలోచితంగా రావణుడు చేసిన చెడ్డపనులను సోదర ప్రేమతో నిందించాడు కుంభకర్ణుడు. కపటంతో సీతను మోసపుచ్చాలన్న మహోదరుని సూచన కూడా కుంభకర్ణునికి రుచించలేదు. తాను రామలక్ష్మణులను, సకల వానర సేనను భక్షించి పరిస్థితిని చక్కదిదద్దుతానని, రావణుడికి మాట యిచ్చి, అగ్నిలా వెలిగిపోతూ, కాలపాశ సదృశమైన పరిఘను పట్టుకొని, కోట గోడను ఒక్క అడుగులో దాటి యుద్ధానికి బయలుదేరాడు. "
                    },
                    {
                        "des": "ఆరు వందల ధనువుల యెత్తూ, వంద అడుగుల కైవారం ఉన్న ఆ మహాకాయుని చూస్తూనే వానర సేనలు పారిపోసాగాయి. ధైర్యం చెప్పి వారిని అతికష్టంమీద అంగదుడు నిలువరించాడు.కుంభకర్ణుడు వానరులను కరకర నమలి మ్రింగుతూ, ఎండు అడవిని అగ్ని కాల్చినట్లుగా వానరసేనను నాశనం చేయసాగాడు. అంగదుడు, ఋషభుడు, శరభుడు, మైందుడు, నీలుడు వంటివారు విసిరిన కొండలు కుంభకర్ణుని వంటికి తగిలి పొడి అయిపోయాయి. వందలాది వానరులను వాడు కరకర నమిలి మ్రింగ సాగాడు. హనుమంతుని దెబ్బకు కుంభకర్ణుడు రక్తం కక్కాడు."
                    },
                    {
                        "des": " కుంభకర్ణుడి శూలం పోటుకు హనుమంతుడు రక్తం కక్కాడు. ఆ రాక్షసునికి ఎదురు పడిన అంగదాది వీరులు వాడి విదిలింపులకే సృహ తప్పి పడిపోయారు. సుగ్రీవుడు కుంభకర్ణుడి శూలాన్ని తన మోకాటికి అడ్డంగా పెట్టుకొని విరిచేశాడు. అప్పుడు కుంభకర్ణుడు విసిరిన పర్వత శిఖరం తగిలి సుగ్రీవుడు తెలివి తప్పాడు. మూర్ఛపోయిన సుగ్రీవుడిని పట్టుకొని లంకవైపు వెళ్ళాడు కుంభకర్ణుడు. తెలివి తెచ్చుకొన్న సుగ్రీవుడు ఒక్కసారి విదిలించుకొని, రాక్షసుని ముక్కు, చెవులు కొరికివేసి ఒక్కగెంతులో వానర సైన్యం మధ్యకు వచ్చిపడ్డాడు."
                    },
                    {
                        "des": "తిరిగి వచ్చిన కుంభకర్ణుడు మత్తిల్లినవాడై అందరినీ ఎడా పెడా మట్టుపెట్ట సాగాడు. వాలిని చంపిన రాముని బాణం కుంభకర్ణుని పట్ల నిష్ప్రయోజనమైంది. కడకు రామ లక్ష్మణుల బాణాలు కుంభకర్ణుని ఆయుధ విహీనుని చేశాయి. . రాముడు వాయువ్యాస్త్రంతోను, ఐంద్రాస్త్రంతోను వాడి చేతులు నరికేశాడు. రెండు మహిమాన్విత బాణాలతో తొడలు కూడా నరికేశాడు. ఐనా నోరు తెరుచుకొని రాహువులా వస్తున్న ఆ వీరుని ఐంద్రాస్త్రంతో చంపేశాడు. వాడు పర్వతంలా క్రిందపడ్డాడు. వాడి క్రింద పడి ఎందరో వానరులు, రాక్షసులు కూడా నశించారు."
                    }
                ]
            },
            {
                "partname": "11.ఇంకా రాక్షస వీరుల మరణం",
                "description": [
                    {
                        "des": "శోకిస్తున్న రావణుడిని ఊరడించి మరునాడు దేవాంతక, నరాంతక, అతికాయ, త్రిశిరులనే రావణ నందనులు, మత్తుడు ఉన్మత్తుడు అనే రావణ సోదరులు - అందరూ మహా శూరులు- యుద్ధానికి పయనమయ్యారు. వారికి తోడుగా మహోదరుడు, మహాపార్శ్వుడు కూడా వెళ్ళారు. వానర రాక్షస వీరుల మధ్య యుద్ధం మళ్ళీ భీకరంగా సాగింది. నరాంతకుని వీరవిహారానికి రణరంగం వానర కళేబరాలతో నిండిపోయింది. సుగ్రీవుని ఆజ్ఞపై అంగదుడు నరాంతకునిపైకురికాడు. అంగదుని పిడకిలిపోటుకు నరాంతకుడు నెత్తురు కక్కి విలవిల తన్నుకొని మరణించాడు."
                    },
                    {
                        "des": "నరాంతకుడి మరణంతో దేవాంతకుడు, త్రిశిరుడు, మహోదరుడు దుఃఖంతో అంగదుని మీదికి ఉరికారు. తగ్గకుండా అంగదుడూ ముగ్గురిపైనా పోరు సాగించాడు. అది చూసి హనుమంతుడు, నీలుడు అంగదునికి తోడు వచ్చారు. హనుమంతుడి పిడికిలి దెబ్బకు దేవాంతకుడి శిరస్సు వ్రక్కలై మరణించాడు. నీలుడు ఒక మహాపర్వతంతో కొట్టి మహోదరుని చంపేశాడు. త్రిశిరుడు వేసిన మహాశక్తిని హనుమంతుడు పెళ్ళున విరిచి వేశాడు. త్రిశిరుని మూడు తలలను వాడి కత్తితోనే ఒక్క వేటుతో నరికేశాడు. మహా పార్శ్వుడి గదను లాగుకొని వృషభుడు దానితోనే వాడి తలను పగులగొట్టాడు."
                    },
                    {
                        "des": "పినతండ్రులు, సోదరుల మరణం చూసి మహా తేశ్శాలి అయిన అతికాయుడు మరొక కుంభకర్ణుడిలా యుద్ధంలోకి దూకాడు. వానర నాయకులు విసిరిన చెట్లు, పర్వతాలు, బండరాళ్ళూ పిండి చేసేశాడు. లక్ష్మణుడు, అతికాయుడు ఒకరికి తీసిపోకుండా ఒకరు మెరుపులలాంటి శస్త్రాస్త్రాలతో యుద్ధం చేశారు. చివరకు వాయుదేవుని సలహాపై సౌమిత్రి బ్రహ్మాస్త్రాన్ని సంధించి అతికాయుని తల తెగనరికాడు. భయభ్రాంతులై రాక్షస సేన అంతా లంకలోకి పరుగులు తీశారు."
                    },
                ]
            },
            {
                "partname": "12.ఓషధ పర్వతా0",
                "description": [
                    {
                        "des": "పుత్రుల, సోదరుల మరణానికి చింతాక్రాంతుడై యున్న రావణునికి ధైర్యం చెప్పి ఇంద్రజిత్తు యుద్ధరంగానికి వెళ్ళాడు. హోమం చేసి అస్త్రాలను అభిమంత్రించి అదృశ్యరూపుడై వానర సేనను నిశిత శరాలతో చీల్చి చెండాడ సాగాడు. వానర వీరులంతా సంజ్ఞా విహీనులై పోయారు. ఇక ఇంద్రజిత్తు బ్రహ్మాస్త్రాన్ని సంధించాడు. దానితో అందరూ మూర్ఛిల్లారు. రామ లక్ష్మణ హనుమంతులు కూడా బ్రహ్మాస్త్రాన్ని మన్నించక తప్పలేదు. అందరూ మరణించారనుకొని సింహనాదం చేసి ఇంద్రజిత్తు విజయోత్సాహంతో లంకలోకి వెళ్ళాడు."
                    },
                    {
                        "des": "మృత ప్రాయులై ఉన్నవారిలో బ్రతికినవారికోసం విభీషణుడు, హనుమంతుడు వెదుకసాగారు. అప్పుడు జాంబవంతుడు కొద్దిగా తెలివి తెచ్చుకొని అంజనాకుమారుడు ఆంజనేయుడు చిరంజీవిగానే ఉన్నాడు గదా? అని అడిగాడు. అలా అడిగినందుకు విభీషణుడు ఆశ్చర్యపడగా జాంబవంతుడు ఇలా అన్నాడు హనుమంతుడు సజీవుడుగా ఉంటే వానరసేన చచ్చినా బతికి తీరుతుందన్నమాటే. దీనికి వ్యతిరేకంగా జరిగితే మేము బ్రతికియున్నా మృతులమే! వేగంలో వాయువుతోనూ, పరాక్రమములో అగ్నితోనూ సరిసమానుడయిన హనుమంతుడుంటేనే మాకు ప్రాణాలపై ఆశ ఉంటుంది"
                    },
                    {
                        "des": "అని జాంబవంతుడు హిమాలయపర్వతం మధ్యలో ఉన్న ఓషధీ పర్వతము మీది మృత సంజీవని, విశల్యకరణి, సౌవర్ణకరణి, సంధాన కరణి అనే ఔషధాలను తీసుకు రమ్మని హనుమను కోరాడు.జాంబవంతుని కోరికపై హనుమంతుడు రామ చంద్రునికీ, సాగరునికీ నమస్కరించి, తానే ఒక పర్వతంలా పెరిగి సుదర్శనంలా ఆకాశంలోకి దూసుకుపోయాడు. ఆకాశమార్గాన సంజీవని పర్వతం మీదికి వెళ్లి ఓషధులకోసం వెదకసాగాడు"
                    },
                    {
                        "des": "ఓషధులు కనిపించనందున హనుమ ఆ పర్వతాన్నే సమూలంగా ఎత్తిపట్టుకొని, నింగిలో మరో సూర్యునిలా, యుద్ధరంగానికి వచ్చాడు. రామ లక్ష్మణులూ, వానరులూ సృహలోకి వచ్చారు. విగతులైన వానరులు కూడా పునరుజ్జీవితులైనారు. అయితే ఎప్పటికప్పుడు మరణించిన రాక్షసులను సముద్రంలో త్రోసివేయమని రావణుడు ఆజ్ఞనిచ్చినందువలన రాక్షసులకు ఏమీ ప్రయోజనం లేకుండా పోయింది. తరువాత మళ్ళీ పర్వతాన్ని తీసికొని వెళ్ళి హనుమంతుడు యథాస్థానంలో ఉంచి వచ్చాడు.సుగ్రీవాజ్ఞ ప్రకారం కొరకంచులు చేతబట్టి వానరులు లంకానగరంలోకి ప్రవేశించి నగరాన్ని తగులబెట్టారు."
                    },
                ]
            },
            {
                "partname": "13.కుంభ, నికుంభుల మరణం",
                "description": [
                    {
                        "des": "మండిపడిన రావణుడు కుంభకర్ణుడి కొడుకులైన కుంభుణ్ణీ, నికుంభుణ్ణీ యుద్ధానికి బయలుదేరారు. వారివెంట యూపాక్షుడు, శోణితాక్షుడు, ప్రజంఘుడు, కంపనుడు కూడా వెళ్ళారు. వీరంతా మహాయోధులు. వారిని వానరసేన చుట్టుముట్టింది. అంగదుడు విసిరిన పర్వత శిఖరం క్రింద పడి కంపనుడు పిండి పిండి అయి చనిపోయాడు.శోణితాక్షుడు, యూపాక్షుడూ, ప్రజంఘుడూ అంగదునిపైకి దుమికారు. అంగదునికి బాసటగా మైందుడు, ద్వివిధుడు నిలిచారు. వారి ఆరుగురి మధ్య భయంకరమైన యుద్ధం జరిగింది. ప్రజంఘుని కత్తితోనే అంగదుడు వాడి తల ఎగురగొట్టాడు. ద్వివిధుడు శోణితాక్షుడి మొహం అంతా రక్కేసి నేలబెట్టి రాసేశాడు. మైందుడు యూపాక్షుడిని నేలకు తొక్కిపట్టి పీక పిసికేశాడు."
                    },
                    {
                        "des": "కుంభుడు రెట్టించిన రోషంతో వాడి బాణాలు వేసి మైందుడిని, ద్వివిధుడిని మూర్ఛపోగొట్టాడు. తన మేనమామలు పడిపోవడం చూసి అంగదుడు కుంభునిపై లంఘించాడు. కుంభుని గుద్దుకు అంగదుడు కూడా సొమ్మసిల్లాడు. దానితో సుగ్రీవుడు యుద్ధానికి వచ్చి చాలాసేపు భీకరమైన బాహు యుద్ధం చేశారు. చివరకు సుగ్రీవుడి పిడిగుద్దులతో కుంభుడు మరణించాడు.నికుంభుడు పెద్దపరిఘతో వానరులమీదికొచ్చాడు. హనుమంతుని వక్షస్థలానికి తగిలి ఆ పరిఘ ముక్కలయ్యింది. హనుమంతున గుద్దుకు నికుంభుని కవచం పగిలింది. ఇద్దరూ ఒకరినొకరు గుద్దుకున్నారు."
                    },
                    {
                        "des": "చివరకు హనుమంతుడు నికుంభుని తల పట్టుకొని, మెడలు మెలిపెట్టి విరిచేశాడు. వానరులు సంతోషంతో గెంతులు వేశారు. రాక్షసులు భయభ్రాంతులయ్యారు.కుంభ నికుంభుల మరణవార్త విని రావణుడు మూర్ఛితుడయ్యాడు. తేరుకొని, ఖరుని కొడుకైన మకరాక్షుని యుద్ధానికి పంపాడు. అతనితో కామరూపులైన అనేక రాక్షసుల సేన ఉంది. తన తండ్రిని దండకారణ్యంలో చాంపిన రామునిపై పగ తీర్చుకోవాలని మకరాక్షుని సంకల్పం. మళ్ళీ తరుచర నిశాచరులకు సంకుల సమరం ప్రారంభమయ్యింది. రాముడి పావకాస్త్రంతో మకరాక్షుడు భస్మమైపోయాడు."
                    },
                ]
            },
            {
                "partname": "14.ఇంద్రజిత్తు మరణం",
                "description": [
                    {
                        "des": "కుంభ నికుంభులు, మకరాక్షుడు కూలిపోయారని వినగానే రావణుని శోక రోష భయాలు ముప్పిరిగొన్నాయి. ఆ నరవానరులను చంపివేసి తన మనస్తాపాన్ని తీర్చవలసిందిగా ఇంద్రజిత్తును ఆశీర్వదించి యుద్ధరంగానికి పంపాడు. హోమం చేసి, శస్త్రాస్త్రాలు ధరించి యుద్ధరంగానికి వచ్చి అదృశ్యరూపంలో వానరసేనను, రామలక్ష్మణులను కలచివేయసాగాడు. లక్ష్మణుడు బ్రహ్మాస్త్రం వేయాలంటే రాముడు అతనిని వారించాడు. ఇంతలో దృశ్యరూపుడై మాయాసీత తలను అందరి యెదుటా తెగనరికాడు. అది చూసి అంతా శోకంలో మునిగిపోయారు. రావణుని తత్వం తెలిసిన విభీషణుడు అది కేవలం మాయ అని వారికి నచ్చచెప్పాడు. ఇంద్రజిత్తు నికుంభిలా యాగం చేయడానికి వెళ్ళాడు. అతని చుట్టూ రాక్షసులు వ్యూహం తీరి కవచంలా ఉన్నారు."
                    },
                    {
                        "des": "యాగం చేయడడానికి ఇంద్రజిత్తు నికుంభిలకు వెళ్ళకుండా ఎవరు ఆపగలరో వారిచేతులోనే ఇంద్రజిత్తు చావు రాసిపెట్టి ఉందని విభీషణుడు చెప్పాడు. రాముని అనుజ్ఞ తీసుకొని, లక్ష్మణుడు ఆయుధ ధారియై, హనుమంతుని భుజాలపై ఆసీనుడై, జాంబవంత, విభీషణ, అంగదాది వీరులతో కూడి నికుంభిలవైపు కదిలాడు. భయానక సంగ్రామానంతరం చుట్టూరా ఉన్న రాక్షసులను ఛేదించి ఇంద్రజిత్తును ఎదుర్కొన్నారు. హోమం ఆపి, బంధుద్రోహియైన విభీషణుని నిందిస్తూ, కాలాంతకరూపుడై ఇంద్రజిత్తు సౌమిత్రితో యుద్ధానికి తలపడ్డాడు. విశ్వామిత్రుని శిష్యుడు లక్ష్మణుడు తగువిధంగా సమాధానం చెప్పాడు. ఆ భీకర సమరంలో ఆకాశాన్ని బాణాలు కప్పివేశాయి."
                    },
                    {
                        "des": "సౌమిత్రి మహేశ్వరాస్త్రాన్ని సంధించి ఇక్ష్వాకు వంశీయుడు రాముడు ధర్మస్వరూపుడూ, సత్యవ్రతుడూ అయితే ఈ మహేశ్వరాస్త్రం ఇంద్రజిత్తును వధించుగాక అని సమంత్రకంగా అస్త్రాన్ని విడచాడు. ఇంద్రజిత్తు తల తెగిపడింది. రాక్షసులు పారిపోయారు. వానరులు లక్ష్మణస్వామికి జయం పలుకుతూ చిందులు వేస్తూ రామునికడకు చేరుకొన్నారు."
                    },
                ]
            },
            {
                "partname": "15.రామరావణ యుద్ధం",
                "description": [
                    {
                        "des": "ఇంద్రజిత్తు మరణంతో రావణుడు తెలివితప్పి పడిపోయాడు. లేచి, కోపంతో సీతను చంప బోయాడు. సుపార్శ్వుడు అనే బుద్ధిమంతుడైన అమాత్యుడు అతనిని వారించి, వీరోచితంగా యుద్ధంచేసి విజయుడవు కమ్మని చెప్పాడు. ఇక రావణుడు అన్నింటికీ తెగించి మహోదరుడు, మహాపార్శ్వుడు, విరూపాక్షుడు వంటి మహావీరులతోను, సైన్యంతోను ఉత్తర ద్వారంగుండా యుద్ధరంగంలో అడుగుపెట్టాడు. శ్రీరామచంద్రునికి జయం, లక్ష్మణునికి జయం, సుగ్రీవునికి జయం, ఆంజనేయునికి జయం, అంగదునికి జయం, జాంబవంతునికి జయం అని పేరుపేరునా జయజయ ధ్వానాలు చేస్తూ, ఉత్సాహంతో పరవళ్ళు తొక్కుతూ వానర వీరులు రాక్షసులనెదుర్కోవడానికి ముందుకు దూకారు."
                    },
                    {
                        "des": "రావణుని మహోగ్రశరధాటికివానర సైన్యము ఛిన్నాభిన్నమైంది. అతనికితోడు విరూపాక్షుడు కూడా విజృంభించాడు. రావణుడు రామునిపైకి ఉరికాడు. విరూపాక్షుడు వానరులను నాశనం చేయసాగాడు. సుగ్రీవుడు ప్రళయకాల ప్రభంజనంలా విజృంభించి, పర్వతాలకు పర్వతాలే విసిరేసి, అనేక రాక్షసవీరులను ఛిన్నాభిన్నం చేశాడు. అడ్డుకొన్న విరూపాక్షుని ముఖంపై సుగ్రీవుడు ఒక్క చరుపు చరిచేసరికి విరూపాక్షుడు రక్తంకక్కుకొని చచ్చిపోయాడు. దావితో మహోదరుడు అద్భుత పరాక్రమంతో వానరులను కూల్చసాగాడు. అతనిని నిలువరించిన సుగ్రీవునికి, మహోదరునికి దారుణమైన మల్లయుద్ధం జరిగింది."
                    },
                    {
                        "des": "సుగ్రీవుడి దెబ్బకు మహోదరుని తల వ్రక్కలయ్యింది. మహాపార్శ్వుడు తన ఖడ్గంతో బీభత్సంగా వానరులను చీల్చేయసాగాడు. అతనిని అంగదుడు ఒక ఇనుప పరిఘతో ఎదుర్కొన్నాడు. జాంబవంతుడు, గవాక్షుడు కలిసి మహాపార్శ్వుని రథాన్ని విరిచేసి గుర్రాలను చంపేశారు. అంగదుడి దెబ్బకు మహాపార్శ్వుడు మరణించాడు."
                    },
                ]
            },
            {
                "partname": "16.లక్ష్మణ మూర్ఛ",
                "description": [
                    {
                        "des": "రావణుడు తన వాడి బాణాలతో రామలక్ష్మణాదులను వేధించ సాగాడు. లక్ష్మణుడు రావణుని సారథి తల యెగురగొట్టాడు. ధనస్సు విరిచేశాడు. మనుష్యశీర్షం చిత్రించి ఉన్న రావణ పతాకాన్ని ముక్కలు చేశాడు. విభీషణుడు రావణుని గుర్రాలను చావగొట్టాడు. విభీషణునిపై రావణుడు వేసిన అస్త్రాలను, శక్తిని లక్ష్మణుడు నిర్వీర్యం చేసేశాడు. వానరులు జయజయధ్వానాలు చేశారు. రావణుడు విసిరేసిన శక్తి వక్షస్థలానికి తగిలి లక్ష్మణుడు మూర్ఛపోయాడు. సోదరుని అవస్థకు పరితపిస్తూనే ప్రళయాగ్నిలా రాముడు రావణునిపై బాణవర్షం కరిపించసాగాడు. కొంత సేపటికి శక్తి లక్ష్మణుని విడచిపెట్టింది. "
                    },
                    {
                        "des": " అప్పుడు రాముడు తనవారిని ఉద్దేశించి మీరు సౌమిత్రిని రక్షిస్తూ, యుద్ధం చూస్తూ ఉండండి. నేను రాముడంటే ఏమిటో చూపిస్తాను. జగత్తు అరావణం కానాలి అన్నాడు. రామ రావణ సంగ్రామం చెలరేగింది. ఎంతో సమయం యుద్ధం చేసిన రావణుడు గాలిలోకి ఎగిరి మేఘంలోకి దూరిపోయి లంకలోకి వెళ్ళిపోయాడు.యుద్ధభూమిలో అచేతనంగా పడిఉన్న లక్ష్మణుని చూసి రాముడు విలపించసాగాడు. లక్ష్మణుడు కేవలం మూర్ఛిల్లాడని ధైర్యం చెప్పి సుషేణుడు మరల హనుమను మరల గిరిశిఖరానికి వెళ్ళమన్నాడు."
                    },
                    {
                        "des": "హనుమంతుడు గరుడగమనంతో వెళ్ళాడు. మూలికలను గుర్తించలేక పర్వతాన్నే పెకలించుకొని ఓషధులతో సహా తెచ్చేశాడు. సుషేణుడు మూలికలను ఏరి రసం తీసి లక్ష్మణుని నాలుకపై పోశాడు. తెలివి వచ్చిన లక్ష్మణుని కౌగలించుకొని రాముడు బావురుమన్నాడు. లక్ష్మణుడు లేచి నిలబడి, అన్నా ముందు నువ్వు ప్రతిజ్ఞ చెల్లించుకో. నీ కంట పడ్డాక శత్రువు బతికి ఉండగలడా? ఈ సాయంసంధ్యలో సూర్యుడు అస్తమించకుండానే రావణుడు కడతేరాలి అన్నాడు."
                    },
                ]
            },
            {
                "partname": "17.రావణ సంహారం",
                "description": [
                    {
                        "des": "అదే సమయంలో ఇంద్రుడు పంపగా మాతలి దివ్యమైన రథంతో సారథిగా వచ్చాడు. అగ్ని సమానమైన కవచం, ఐంద్రచాపం, సూర్య సంకాశాలైన శరాలు, తీక్ష్ణమైన శక్తి కూడా ఆ రథంలో ఉన్నాయి. రాముడు సంతోషించి ప్రదక్షిణం చేసి రథం యెక్కాడు. రావణుడు వజ్రసదృశమైన శూలాన్ని చేతబట్టి మళ్ళీ యుద్ధానికి వచ్చాడు. రావణుడు విసిరేసిన శూలం ఎదురుపడి రాముని బాణాలు కాలిపోయాయి. అప్పుడు రాముడు మాతలి తెచ్చిన ఇంద్రశక్తిని విసిరేశాడు. అది రావణుని శూలాన్ని నిర్మూలించింది. రావణుడు కూడా శరపరంపరతో రాముని ముంచెత్తాడు. రాముడు విడచిన తీవ్ర బాణాలతో రావణుడి శరీరం రక్తసిక్తమయ్యింది. చివరకు అస్త్రవిహీనుడైన రావణుని పరిస్థితి గమనించి అతని సారథి రథాన్ని దూరంగా తీసుకుపోయాడు. "
                    },
                    {
                        "des": " అగస్త్యుడు అక్కడికి వచ్చి యుద్ధ పరిశ్రాంతుడై యున్న రామునకు సనాతనము, పరమ రహస్యము అయిన ఆదిత్య హృదయమును ఉపదేశించాడు. సమస్త లోక సాక్షి అయిన సూర్యుని స్తుతించే ఆ మంత్రం జయావహం. అక్షయం. పరమ మంగళకరం. సర్వపాప ప్రణాశనం. చింతా శోకప్రశమనం. ఆయుర్వర్ధనం. సమస్త ఆపదాపహరణం. రాముడు ఆచమించి ఆ మంత్రరాజాన్ని మూడు మార్లు జపించాడు. జ్యోతిర్గణాధిపతి, దినకరుడు, జయభద్రుడు, సహస్రాంశుడు, తమోఘ్నుడు, శత్రుఘ్నుడు అయిన ఆదిత్యునకు నమస్కరించాడు. ధనుస్సు ధరించి యుద్ధానికి సిద్ధపడ్డాడు. రావణ సంహారానికి దీక్ష పూనాడు.రావణుని సారధి మళ్ళీ రధాన్ని రాముని ముందుకు తెచ్చాడు."
                    },
                    {
                        "des": "సకలాయుధ సంపన్నమై, ఒక గంధర్వ నగరంలా ఉన్న ఆ రథం అప్రదక్షిణంగా వచ్చింది. ఇక రావణుని మరణం తప్పదని గ్రహించిన రాముడు తమ రధాన్ని ప్రదక్షిణ మార్గంలో పోనిమ్మని మాతలికి చెప్పాడు. సర్వ శక్తులనూ ఒడ్డి రాముడు, రావణుడు శరవర్షాన్ని కురిపింప సాగారు. వారి బాణాలు ఆకాశాన్ని కప్పేశాయి. రామరావణ యుద్ధం రామరావణ యోరివ - వారి యుద్ధానికి మరొకటి పోలిక లేదు - అని దేవగణాలు ఘోషిస్తున్నాయి. వారి రథాలు యుద్ధరంగమంతా కలియదిరిగాయి. రాముని బాణాలకు రావణుని పతాకం కూలింది. గుర్రాలు తొలగిపోయాయి."
                    },
                    {
                        "des": "మహా సర్పాలవంటి రాముని బాణాలకు రావణుని తల తెగిపడింది. కాని వెంటనే మరొకటి మొలిచి ఉంది. ఇలా నూటొక్కసార్లు రావణుని తలలు తెగగొట్టినా మరల మరల మొలుస్తూనే ఉన్నాయి. రామా! ఇలా కాదు. బ్రహ్మాస్త్రాన్ని సంధించు అని మాతలి అన్నాడు. అప్పుడు రాముడు తనకు అగస్త్యుడిచ్చిన బ్రహ్మాస్త్రాన్ని తీశాడు. అది బుసలు కొడుతున్న సర్పంలా ప్రకాశిస్తున్నది. దాని వేగ సాధనములైన రెక్కలలో వాయువు, ములికిలో అగ్ని సూర్యులు, బరువులో మేరు మందర పర్వతాలు అధిష్టాన దేవతలుగా ఉన్నారు. దాని శరీరం బ్రహ్మమయం."
                    },
                    {
                        "des": "రాముడు ఆ దివ్యాస్త్రాన్ని వేదోక్తంగా అభిమంత్రించి, ధనుస్సును బాగుగా లాగి సావధాన చిత్తుడై విడచాడు. వజ్ర సంకల్పంతో, రాముని వజ్ర హస్తాలనుండి విడువడిన వజ్రసమానమైన బ్రహ్మాస్త్రం నిప్పులు చిమ్ముతూ రావణుని గుండెను చీల్చి, అతని రక్తంతో పూయబడినదై, ఉపశమనం కోసం భూమిలో ప్రవేశించి, సావధావంగా తిరిగి వచ్చి రాముని అమ్ముల పొదిలో చేరింది."
                    },
                    {
                        "des": "రాముడు ఎరుపెక్కిన కన్నులతో, శరదళితదేహంతో, కోటి సూర్యుల ప్రకాశంతో, ధనుస్సును నేలకానించి, మరో చేత బాణాన్ని త్రిప్పుతూ వీరశ్రీబంధురాంగుడై త్రిదశపతినుతుడై శోభిల్లాడు. సకలదేవతలు రామునకు అంజలి ఘటించారు. సుగ్రీవ విభీషణ అంగదాదులు, లక్ష్మణుడు, ఇతర సహమిత్రులు విజయోత్సాహంతో రణాభిరాముడైన రాముని యధావిధిగా పూజించారు"
                    }
                ]
            },
            {
                "partname": "18.సీత అగ్ని ప్రవేశం",
                "description": [
                    {
                        "des": "భీకరంగా జరిగిన యుద్ధంలో రావణుడు కడతేరాడు. రాముని ఆనతిపై విభీషణుడు రావణునికి అంత్య క్రియలు చేశాడు. పిదప విభీషణుడు పట్టాభిషిక్తుడయ్యాడు. విభీషణుని అనుజ్ఞతో హనుమ లంకలోనికి వెళ్ళి, విజయవార్తను సీతకు నివేదించాడు. విభీషణుని అంతఃపుర పరివారం సీతకు మంగళ స్నానం చేయించి, పల్లకీలో రాముని వద్దకు తీసికొనివచ్చారు."
                    },
                    {
                        "des": "రాముడు సీతా, ఇక్ష్వాకుకుల ప్రతిష్ఠకోసం నేనీ యుద్ధం చేశాను. రావణుడు నిన్ను దుష్టదృష్టితో చూశాడు గనుక నేను నిన్ను స్వీకరించలేను. కలక బారిన కనులకు దీపం వలె నీవు నాకు చాలా బాధాకరంగా కనుపిస్తున్నావు. నువ్వు యధేచ్ఛగా ఎక్కడికైనా వెళ్ళవచ్చును. అని కఠినంగా మాట్లాడాడు. సీత దుఃఖంతో బావురుమంది. ఆర్యపుత్రా, వీరాధివీరా, నీవు పామరునివలె మాట్లాడుతున్నావు. రావణుడు నన్ను తాకిన దోషం నాది కాదు. దైవానిది. నా హృదయం నీమీదే లగ్నం అయి ఉన్నది. నేను జనకుని పెంపుడు కూతురిని. భూమి సుతను. నా భక్తినీ శీలాన్నీ విశ్వసించలేక పోతున్నావా? అని విలపించింది."
                    },
                    {
                        "des": "సీత లక్ష్మణునివైపు తిరిగి లక్ష్మణా, కళంకిని అనిపించుకొని నేనింక బ్రతుకలేను. నా సుగుణాలని కీర్తించని నా భర్త నలుగురిముందు నన్ననరాని మాటలన్నాడు. అగ్నిని ప్రజ్వలింపజేయి అన్నది. సీత అవనత శిరస్కయై రామునకు, దేవతలకు, దిక్పాలురకు మ్రొక్కి నా హృదయం సదా రామచంద్రుడినే పూజిస్తున్నట్లయితే సర్వభక్షకుడైన అగ్ని నన్ను పునీతురాలిని చేయాలిఅని పలికి మంటలలోనికి నడచింది. అందరూ హాహాకారాలు చేశారు. అప్పుడు బ్రహ్మ రాముని సమక్షంలో నిలిచి రామా నువ్వు లోక కర్తవు. ఉత్తమ జ్ఞానివి. అలా చూస్తూ ఊరుకుంటావేం? ప్రాకృతునిలా సీతను ఉపేక్షిస్తావేమీ అన్నాడు. నీవు విష్ణువు అవతారానివని చెప్పాడు."
                    },
                    {
                        "des": "అగ్ని సీతను వెంటబెట్టుకొని రాముని వద్దకు వచ్చాడు. సీత అప్పుడు ఉదయసూర్యబింబంలా ఉంది. రామా! ఇదిగో నీ సీత. ఈమె పునీత. పాపహీన. నిన్నే కోరిన సాధ్వి. ఈమెను అవశ్యం పరిగ్రహించు. నాకడ్డు చెప్పవద్దు. నిన్ను ఆజ్ఞాపిస్తున్నాను అని చెప్పాడు. రాముడు సీత పరమపవిత్రురాలని నేనెరుగుదును. కాని దశరధ పుత్రుడనైన నేను కామాతురుని వలె ప్రవర్తింప జాలను. ఈమె మహిమను లోకం గుర్తించాలని అగ్ని ప్రవేశాన్ని మౌనంగా వీక్షించాను. నా పట్ల వాత్సల్యం కలవారు గనుక మీరు చెప్పినట్లే చేస్తాను అని తన దక్షిణ హస్తాన్ని చాచి సీత చేతినందుకొన్నాడు."
                    },
                ]
            },
            {
                "partname": "19.అయోధ్యకు పునరాగమనం",
                "description": [
                    {
                        "des": "రాముని కోరికపై ఇంద్రుడు చనిపోయిన వానరులందరినీ బ్రతికించాడు. సైన్యమంతా సంబరాలలో మునిగిపోయింది. అయోధ్య చేరాలన్న ఆతురతలో ఉన్న రాముడు విభీషణుని సత్కారాలను తిరస్కరించాడు. విభీషణుడు రాముని అనుజ్ఞతో వానరులను సత్కరించాడు. విభీషణుడు, వానరులు తోడు రాగా పుష్పక విమానంపై సీతారామలక్ష్మణులు అయోధ్యకు తిరుగు ప్రయాణమయ్యారు. దారిలో తమ లంకా యుద్ధ, సీతాపహరణ, వనవాస ప్రదేశాలను చూసుకొంటూ కిష్కింధను, గోదావరిని, యమునను, గంగను దాటి భరద్వాజ ముని ఆశ్రమం చేరుకొన్నారు. వానరుల సంతోషం కోసం అకాలంలో కూడా వృక్షాలన్నీ తియ్యటి పళ్ళతో విరగబూసేటట్లుగా రాముని కోరికపై భరద్వాజ ముని వరమిచ్చాడు"
                    },
                    {
                        "des": "ముందుగా హనుమంతుడు నందిగ్రామం చేరుకొని హనుమంతుడు భరతునికి సీతారామలక్ష్మణుల పునరాగమన సందేశాన్ని అందించాడు. ఆనందంతో భరతుడు అయోధ్యను అలంకరింపమని ఆనతిచ్చాడు. రాముని పాదుకలు శిరస్సుపై ధరించి పరివారసహితుడై ఎదురేగి రామునికి, సీతకు, లక్ష్మణునికి ప్రణమిల్లాడు. సుగ్రీవాది వీరులను ఆలింగనం చేసుకొన్నాడు. సౌహార్దంవల్ల తమకు సుగ్రీవుడు ఐదవ సోదరుడని పలికాడు. రాముని పేరుతో తాను రాజ్య సంరక్షణ చేస్తున్నానని, రాజ్యభారాన్ని తిరిగి చేకొనమని శ్రీరాముని ప్రార్థించాడు. గోశాలలు, ధాన్యశాలలు, ధనాగారం, సైన్యాలను పరీక్షించుకోమని కోరాడు."
                    },
                    {
                        "des": " భరతుడే సారథ్యం వహించగా, శత్రుఘ్నుడు శ్వేతచ్ఛత్రం పట్టగా, లక్ష్మణ విభీషణులు వింజామరలు వీయగా, వానరవీరులు వెంటరాగా సీతారాములు పరివార సమేతులై శంఖభేరీ భాంకారాలతోఅయోధ్యలోనికి ప్రవేశించారు. తల్లులకు, పెద్దలకు, గురువులకు మ్రొక్కారు. వనవాస విశేషాలు, సీతాపహరణం, కిష్కింధలో సుగ్రీవుని మైత్రి, హనుమంతుని అసమాన కార్య సాధన, విభీషణ శరణాగతి, లంకాయుద్ధాది వివరాలు విని అయోధ్యాపౌరులు అత్యాశ్చర్యభరితులయ్యారు."
                    },
                ]
            },
            {
                "partname": "20. శ్రీరామ పట్టాభిషేకం",
                "description": [
                    {
                        "des": "శ్రీరామ పట్టాభిషేకానికి ముహూర్తం నిశ్చయమయ్యింది. సుగ్రీవాజ్ఞతో జాంబవంతుడు, హనుమంతుడు, వేగదర్శి, ఋషభుడు సుషేణుడు, గవయుడు, నలుడు నదీనద సముద్ర జలాలు తెచ్చారు. వసిష్ఠ మహర్షి ఋత్విక్కులతో కలిసి సీతారాములను రత్న సింహాసనంపై కూర్చుండబెట్టారు. వసిష్ఠుడు, వామదేవుడు, జాబాలి, కాశ్యపుడు, కాత్యాయనుడు, సుయజ్ఞుడు, గౌతముడు, విజయుడు, తరువాత ఋత్విక్కులు, బ్రాహ్నణులు, కన్యలు, యోధులు వారిని అభిషేకించారు. వారితోబాటే లోకపాలకులు, దేవతలు శ్రీరామచంద్రుడిని అభిషేకించారు. వాయుదేవుడు స్వయంగా బంగారు తామరపూల మాలికను రాముని మెడలో వేశాడు. ఆ సమయాన వసుధ సకల సస్యాలతోనూ రాణించింది."
                    },
                    {
                        "des": "రాముడు బ్రాహ్మణులకు అనేక దానాలు చేశాడు. సుగ్రీవ, విభీషణ, జాంబవంతాది మహావీరులకు అనేక బహుమతులిచ్చి సత్కరించాడు. శ్రీరాముడు సీతకొక నవరత్నాలూ పొదిగిన ముత్యాల దండను ఇచ్చాడు. అప్పుడు సీత శ్రీరామచంద్రుని ఇంగితం గుర్తించి ఒకజత గొప్ప విలువైన వస్త్రాలూ, గొప్ప ఆభరణాలూ హనుమంతునకిచ్చింది. అంతటితో తృప్తి తీరక ఆమె తన మెడలో ఉన్న ముత్యాల హారం తీసి చేతబట్టుకొని ఒకసారి రాముడినీ, మరొకసారి వానరుల్నీ చూడసాగింది. సీత మనసు తెలిసికొన్న శ్రీ రాముడు జానకీ! బలమూ, పరాక్రమమూ, బుద్ధీ ఉండి, నీకు అమితానందం కలిగించినవారికి ఆ ముత్యాలసరం ఇమ్మున్నాడు."
                    },
                    {
                        "des": "అన్న మరుక్షణంలోనే దాన్ని సీతమ్మతల్లి హనుమంతుని చేతిలో పెట్టింది. హారంతో హనుమంతుడు చంద్రకాంతి తగిలిన తెల్ల మబ్బులా ప్రకాశించాడు. రాముని సెలవు తీసికొని విభీషణుడు లంకకు, వానరులు కిష్కింధకు తరలిపోయారు.యువరాజుగా ఉండడానికి లక్ష్మణుడు సమ్మతించలేదు. భరతునకు యౌవరాజ్యాభిషేకం చేశాడు రాముడు. తరువాత శ్రీరాముడు అశ్వమేధం, పౌండరీకం, మరెన్నో క్రతువులు చేశాడు. లక్ష్మణుడు తనకు సాయపడుతూ ఉండగా పదకొండువేల సంవత్సరాలు రాజ్యపాలవం చేశాడు. శ్రీరాముని రాజ్యంలో జనులు సుఖసంతోషాలతో ఉన్నారు. స్త్రీలు వైధవ్యం ఎరుగరు. వృద్ధులు జీవించియుండగా పిన్నవాళ్ళు మరణించలేదు. మేఘాలు కాలానుగుణంగా వర్షించేవి. ధరణి సస్యశ్యామలంగా ఉండేది. ప్రజలు కులవృత్తులను నిర్వహిస్తూ ధర్మపరాయణులై ఉండేవారు."
                    },
                ]
            },
        ]
    },
    {
        "title": "ఉత్తరకాండము",
        "subtitle": [
            {
                "partname": "1.రామరాజ్యం",
                "description": [
                    {
                        "des": "శ్రీ రామ పట్టాభిషేకం తరువాత అయోధ్యలో అంతటా సుఖ సంతోషాలు వెల్లివిరిసాయి. శ్రీ రాముని పాలనలో ప్రజలు ఏ కష్టం లేకుండా సుఖంగా జీవనం సాగించేవారు. అందుకే ఇప్పటికీ శ్రేయో రాజ్య పరిపాలనకు రామ రాజ్యాన్నిఉదాహరణగా వాడతారు. "
                    },
                    {
                        "des": "ఇలా ఉండగా ఒక రోజు రాముడు ఏకాంతసమయంలో సీతను చేరి దేవీ! నీవు తల్లివి కాబోతున్నావు. నీ మనస్సులో ఏమైనా కోరిక ఉంటే చెప్పు. ` అని అడిగాడు. అందుకు సీత ` నాధా గంగా తీరంలో ఉన్న ముని ఆశ్రమాలలో పళ్ళు, కందమూలాలు ఆరగిస్తూ ఒక్కరోజు గడపాలని ఉంది. : "
                    },
                    {
                        "des": "అంటుంది. అందుకు సరే నంటాడు రాముడు. కానీ సీత కోరిక వినగానే వ్యాకులచిత్తుడవుతాడు."
                    },
                ]
            },
            {
                "partname": "2.సీత గురించిన నింద",
                "description": [
                    {
                        "des": "అక్కడనుండి సభామంటపానికి వెళ్ళిన రాముడిని విజయుడు, మధుమత్తుడు, కాశ్యపుడు, పింగళుడు, కుటుడు, సురాజు, మొదలైన వారు హాస్య కథలు చెప్పి రాముడిని సంతోషపరుస్తారు. "
                    },
                    {
                        "des": "రాముడు ప్రసన్నుడై భద్రునితో భద్రా! నా పరిపాలన ఎలావున్నది? ప్రజలకు ఎలాంటి సమస్యలు లేవుగదా? నిజం చెప్పు.? అని అడుగుతాడు. అందుకు భద్రుడు మహారాజా! సత్యసంధుడివైన నీకు నిజం చెప్తున్నాను. ప్రజలు నీ పరక్రమాలను, రావణ సంహారాన్ని వేనోళ్ల పొగడుతున్నారు. అయితే, రావణ చెరలో కొన్నాళ్ళు ఉన్న సీతను తిరిగి మీరు భార్యగా స్వీకరించడం గురించి మాత్రం రక రకాలుగా చెప్పుకొంటున్నారు. ఇలా అంటున్నందుకు నన్ను మన్నించండి అన్నాడు. రాముడు సరేనని వారినందరిని పంపించి విషాదచిత్తుడై తమ్ముళ్ళను పిలిపిస్తాడు. వారు రావడంతోనే రాముని వదనం చూసి నిశ్చేష్టులవుతారు."
                    },
                    {
                        "des": "రాముడు వారిని కూర్చోమని జరిగిన సంగతి అంతా వివరిస్తాడు.  లక్ష్మణా! సూర్య చంద్రులు, అగ్ని,ఇంద్రాది దేవతలు కూడా ఆమె సౌశీల్యాన్ని శ్లాఘించారు. కానీ ఆమెపై అయోధ్యలో ఇంకా అపవాదు తొలగలేదు. ప్రజాభీష్టం లేని పరిపాలన సూర్యుడులేని పగలు వంటిది. ఇప్పుడు నాకు ఆమెను పరిత్యజించడం తప్ప వేరు మార్గం కనపడ్డం లేదు. కొద్ది సమయం కిందటే సీత తనకు మున్యాశ్రమాలు చూడాలని కోరికగా ఉన్నదని కోరగా ఆమెకు సరే అని అనుమతిచ్చాను. నువ్వు మారు మాటాడక ఆమెను గంగానదీ తీరంలోని ఆశ్రమాల వద్ద వదిలిరా. ఇది నా ఆజ్ఞ అంటాడు. "
                    },
                ]
            },
            {
                "partname": "3.అడవుల పాలైన సీత",
                "description": [
                    {
                        "des": "లక్ష్మణుడు మారుమాటాడక ఉదయాన్నే రథం సిద్ధం చేయమని మంత్రి సుమంతుడుకి చెప్పి సీత వద్దకు వెళ్ళి తల్లీ. ఆశ్రమంలొ గడపాలన్న నీకోరిక మేరకు నేడు నిన్ను మున్యాశ్రమాలకు గంగా నదివద్దకు తీసుకువెళ్లమని అన్న ఆనతిచ్చారు అనగానే సీత సంతోషంగా అతనితో గంగానదికి ప్రయాణమవుతుంది. "
                    },
                    {
                        "des": "గంగానదిని దాటిన పిదప మున్యాశ్రమతీరంవద్ద  తల్లీ! నా పాపాన్ని క్షమించు. నిన్ను నేను ఇక్కడకు తీసుకువచ్చినది ఈ తీరంలో వదిలి వెళ్లడానికే గాని తిరిగి అయోధ్యాపురికి తీసుకు వెళ్ళడానికి కాదు అని అసలు సంగతి చెప్పగా ఆమె మూర్చపోయి తేరుకొని నాయనా సౌమిత్రీ! నేను కష్టాలు అనుభవించడానికే పుట్టాను అని అనిపిస్తున్నది. పూర్వజన్మ పాపం పట్టి పీడించక తప్పదుమరి. "
                    },
                    {
                        "des": "అప్పుడు అరణ్యాలలో భర్త తోడుతో గడిపాను. ఇప్పుడు ఒంటరిగా ఉండగలనా? నీభర్త నిన్నెందుకు విడిచిపెట్టాడని అడిగే ముని పత్నులకు ఏమి జవాబు చెప్పేది? సరే. విధిరాత అనుభవింపకతప్పదు. ఆయన మాటను గౌరవిస్తానని చెప్పు. నా నమస్కారాలు తెలియచెయ్యి"
                    },
                ]
            },
            {
                "partname": "4.ముని ఆశ్రమం, కుశలవులు",
                "description": [
                    {
                        "des": "సీత అతను వెళ్ళేంతవరకూ అక్కడే ఉండి పెద్దగా ఏడుస్తూ కుప్పకూలిపోయింది. ముని బాలకుల ద్వారా ఈసంగతి తెలుసుకొన్న వాల్మీకి ఆమెను తన ఆశ్రమానికి తీసుకొని వచ్చి"
                    },
                    {
                        "des": "అమ్మాయీ! నీవు జనకుని కూతురువు. దశరధుని కోడలివి. రాముని ఇల్లాలువు. నీవు అతి పవిత్రురాలివి. నేను నా తపశ్శక్తితో సర్వం గ్రహించాను. నీవు నిశ్చింతగా ఉండు. ఇక్కడి మునిసతులందరూ నిన్ను కన్న కూతురివలె చూసుకొంటారు.  అని ఓదారుస్తాడు. "
                    },
                    {
                        "des": "ఆయన ఆశ్రమంలో ఉన్న అందరినీ పేరు పేరునా పిలచి జానికీ దేవికి ఎలాంటి కష్టం కలుగకుండా చూసుకొనే బాధ్యతను అప్పగిస్తాడు. అక్కడ కొంతకాలానికి జానకీ దేవి ఇద్దరు బాలలకు జన్మనిస్తుంది. వారు లవకుశనామధేయులై దినదిన ప్రవర్దమానులౌతూ అటు వేద విద్యలోనూ, ఇటు క్షాత్ర విద్యల్లోనూ తిరుగులేని బాలురుగా ప్రకాశిస్తుంటారు. "
                    },
                ]
            },
            {
                "partname": "5.రాజసూయం",
                "description": [
                    {
                        "des": "ఇదిలా ఉండగా ఒక రోజు రాముడు తమ్ములను పిలిచి తనకు రాజసూయ యాగం చేయాలనున్నది అని చెపుతూ వారి సలహా అడుగుతాడు. భరతుడు అన్నకు అంజలి ఘటించి ప్రభూ! నీ పాలనలో ధర్మదేవత చక్కగా నడుస్తోంది. కీర్తి చంద్రుడ్ని ఆశ్రియించిన వెన్నెలలా నిన్ను అంటిపెట్టుకొనే ఉన్నది. పాప కర్ములు అయిన రాజులు లేరు. "
                    },
                    {
                        "des": "ఈ భూమ్మీద ఉన్న సకల ప్రాణులకు ఏలికవు గతివి నువ్వే అని మరిచావా? రాజసూయం వల్ల అనేక రాజవంశాలు నేలమట్టం అవుతాయి. అందువల్ల రాజసూయం అనవసరమని నా అభిప్రాయం  అనగానే లక్ష్మణుడు అందుకొని అన్నా! భరతుడు చెప్పింది నూటికి నూరుపాళ్ళూ నిజం, ధర్మయుక్తం. నీకు యాగం చేయాలని కోరిక ఉంది గనుక అశ్వమేధం చేయి. ఇది నిర్వహించి పూర్వం ఇంద్రుడు వౄతాసురవధ వల్ల కలిగిన బ్రహ్మహత్యాపాతకం వదిలించుకొన్నాడు అంటాడు."
                    },
                    {
                        "des": "శ్రీ రాముడికి వారి మాటలు బాగా నచ్చాయి.  సోదరులారా. మీరు చెప్పినమాటలు నాకు సమ్మతంగానే ఉన్నాయి. ఈ యాగం నిర్వహించేందుకు తగిన ఏర్పాట్లు చేయండి.  అని అనుమతిస్తాడు."
                    },
                    {
                        "des": "సుగ్రీవుడు, విభీషణుడు మొదలైన దేశాధిపతులు, మునులు, నటులు, గాయకులు రాగా నైమిశారణ్యంలో గోమతీ నదీ తీరాన యజ్ఞవాటికను సమస్త వైభవోపేతంగా నిర్మించి అది చూడ్డానికి వచ్చేవారికై సకల సౌకర్యాలు సమకూరుస్తారు. మంచి లక్షణాలు కలిగిన గుర్రాన్ని రాముడు అర్చించి వదిలిపెట్టాడు."
                    },
                    {
                        "des": "రక్షకుడిగా లక్ష్మణుడు ఋత్విజులతో సహా బయలుదేరాడు. తరువాత రాముడు యజ్ఙవాటికలోకి ప్రవేశించాడు. అప్పుడు భూమండలంపై ఉన్న రాజులు అందరూ రాముడిని అభినందించి కానుకలు సమర్పించుకోసాగారు. ఇలా అత్యంత ప్రతిష్ఠాత్మకంగా ఏడాది పాటు అశ్వమేధ యాగం కొనసాగింది.దీన్ని మెచ్చుకోని వారు లేరు.  "
                    },
                    {
                        "des": "అప్పుడు వాల్మీకి మహర్షి శిష్యసమేతంగా విచ్చేసాడు. భరత శత్రుఘ్నులు ఆయన కోసం సౌకర్యవంతమైన పర్ణశాలను ప్రత్యేకించి నిర్మించి విడిది ఏర్పాటు చేసారు. విడిదిలోకి చేరిన తరువాత వాల్మీకి లవకుశలను కూర్చోపెట్టుకొని చిరంజీవులారా! మీకు నేర్పిన రామాయణాన్ని రాజమార్గాల్లోనూ, మును వాసాల్లోనూ, యజ్ఞవాటిక దగ్గర రాముని మందరం దగ్గర శ్రావ్యంగా, శ్రుతి బద్ధంగా మధురంగా ఆలపించండి. రోజుకు ఇరవై సర్గలు పాడండి. ఫలాలు దుంపలే ఆరగించండి. ఎవరైనా ధనం ఇస్తే స్వీకరించకండి. మీరు ఎవరు అని ప్రశ్నిస్తే  వాల్మీకి శిష్యులం అని మాత్రమే చెప్పాలి. ప్రభువయిన శ్రీరాముడ్నిమాత్రం చులకనగా చూడకండి. అని బోధిస్తాడు.",
                    },
                ]
            },
            {
                "partname": "6.రామాయణ గానం",
                "description": [
                    {
                        "des": "మరునాటి ఉదయం లవకుశులు వాల్మీకి మునికి నమస్కారం చేసి, ఆయన ఆశీర్వాదంతో రామాయణ గానం అయోధ్య నలుదెసలా ఆరంభిస్తారు. ఆ గానామృత మాధుర్యానికి జనులు సమ్మోహితులై వారిని వెంబడిస్తారు. దేశం నలుమూలలా కుశలవుల గాన మాధుర్యం గురించే చర్చ జరుగుతూంది. "
                    },
                    {
                        "des": "రాముడు కూడా ఆ గానాన్ని విని ముగ్దుదౌతాడు. యజ్ఞకర్మ పూర్తి కాగానే ఒక సభను ఏర్పాటు చేసి మునులు, రాజులు, పండితులు, సంగీత విద్వాంసులు, భాషావేత్తలు, వేదకోవిదులు, సకలవిద్యాపారంగతులు ఆసీనులై ఉన్న సమయాన రాముడు కుశలవులను తమ గాన మాధుర్యాన్ని వినిపించమని కోరతాడు. మొదటి సర్గనుంచి ఇరవై సర్గలు వరకూ వారు అతి రమ్యగా గానంచేయగా సభాసదులు చప్పట్లు చరిచి వారి గాన మాధుర్యానికి జేజేలు చెప్తారు."
                    },
                    {
                        "des": "రాముడు భరతునితో ఈ బాలురకు పద్దెనిమిదివేల బంగారు నాణేలు బహూకరించమని కోరగా లవకుశులు తమకు ఎలాణ్టి ధనమూ కానుకలూ అవసరం లేదని తిరస్కరిస్తారు. అప్పుడు రాముడు . మీరు పాడిన కావ్యం ఏమిటి? అని ప్రశ్నించగా లవకుశులు  దీని కర్త వాల్మీకి మహర్షి. ఇప్పుడాయన ఇక్కడే ఉన్నారు. ఆయనే మాగురువు. మీ చరిత్రనే ఆయన ఇరవై నాలుగువేల శ్లోకాలుగా వ్రాసాడు. దీనిలో 7 కాండాలున్నాయి. అయిదువందల సర్గలున్నాయి. వంద కథలున్నాయి. మీకంతగా కోరిక ఉంటే పాడి వినిపిస్తాం అన్నారు."
                    },
                ]
            },
            {
                "partname": "7.జానకీదేవి కళంక రహిత",
                "description": [
                    {
                        "des": "రాముడు సరే అని అంగీకరిస్తాడు. ఆయన కోరికమేరకు వారు ప్రతిరోజూ రామాయణాన్ని గానం చేసారు. అది విని రాముడు వీరు సీతాపుత్రులే అని గ్రహించాడు. దూతలను వెంటనే పిలిచి మీరు వాల్మీకి మహాముని వద్దకు వెళ్ళి. నామాటలుగా ఇలా చెప్పండి. మహర్షీ రాముడు నమస్కరించి మీకు విన్నవిస్తున్నదేమంటే మీ కావ్యం విన్నాను. అతి రమ్యంగా ఉన్నది. మీరు నిజంగా జానకీ దేవి కళంక రహిత అనిభావిస్తే ఆమెను సభాముఖానికి తీసుకొనివచ్చి ఆవిషయం ఆమెను నిరూపించుకోవాలి అని చెప్పగా వారు వాల్మీకిని కలసి తిరిగి వచ్చి రేపు సీత తన నిర్దోషిత్వావ్వి ప్రకటిస్తుంది. కాబట్టి ఆమెపై అభాండాలు వేసిన వారుకూడా సభకు రావచ్చునని వాల్మీకి సెలవిచ్చారని చెప్తారు.  "
                    },
                    {
                        "des": "రాముడు సభనుద్దేశించి రేపు సీత తన నిర్దోషిత్వాన్ని ప్రకటిస్తుంది. మీరు తప్పక రావాలి అని చెబుతాడు. ఆయన ప్రకటన విన్న వాళ్ళందరూ ఇటువంటి ధర్మ పాలన నీకే చెల్లుతుంది అని మెచ్చుకొంటారు. సభలోకి రాగానే శ్రీరాముడితో పాటు సభాసదులందరూ వినయంగ లేచి నిలబడి మునీశ్వరుల అనుమతితో తిరిగి ఆసీనులయ్యారు. ముగ్ద సౌందర్యమూర్తి అయిన జానకీ దేవిని చాలా కాలం తరువాత చూసిన జనులు కన్నుల నీరుడుకున్నారు. అప్పుడు హృదయభారభరితమైన మౌనాన్ని చేదిస్తూ మేఘ గంభీర స్వరంతో వాల్మీకి ఇలా అన్నారు సభికులారా! ఈమె పరమసాధ్వి జానకీ దేవి. "
                    },
                    {
                        "des": "దశరధుని కోడలు. శ్రీరామచంద్రుని ఇల్లాలు. ఈమెను శ్రీరామ చంద్రుడు లోక నిందకు భయపడి పరిత్యజించినాడు. నేను చెప్పునది సత్యము. ఇందులో ఏమైన అనృతమున్నట్టయితే ఇన్ని వేల సంవత్సరాల నా తపస్సు నిర్వీర్యమై పోగలదు సభికులు మహా ముని పలుకులు విని చేష్టలు దక్కినవారయ్యారు. "
                    },
                    {
                        "des": "శ్రీ రాముడు చిరునవ్వుతో లేచి మునిని శాంతి పరుస్తూ మునీంద్రా! దివ్యజ్ఞాన సంపన్నులైన తమ వాక్యములు సత్యభూషణములు. నా దేవేరి శీలమును గురించి నాకు ఏమాత్రమూ సందేహము లేదు. ఆమె మహా సాధ్వి అని నాకు తెలియును. మరి లోకులకు కూడా తెలియవడం అవసరమని నే నట్లు నడుచుకోవలసి వచ్చింది. "
                    },
                    {
                        "des": "ఆ తరువాత ఈ కుర్రవాళ్ళను చూస్తే నా కుమారులే అని నా అంతరాత్మ తెలుపుతూంది. లోకం కోసమే సీత తన సాధుశీలాన్ని చాటుకోవాలి అన్నాడు. ఆ మాటలకు అంతా సీత వైపు చూసారు."
                    }
                ]
            },
            {
                "partname": "8.సీత భూప్రవేశం",
                "description": [
                    {
                        "des": "సీత కాషాయాంబరాలు ధరించి ఒక్క మాటు చూసింది. రెండు చేతులు జోడించింది. సభా భవనంలోని గాలిలో చల్లని కమ్మని పరిమళాలు వ్యాపించసాగాయి. అప్పుడు సీత ఇలా అంది నేను రాముడ్ని తప్ప అన్యుల్ని తలచనిదాననే అయితే భూదేవి నా ప్రవేశానికి వీలుగా దారి ఈయాలి. త్రికరణ శుద్ధిగా నేనెప్పుడూ రాముని పూజించేదాన్ని అయితే భూదేవి నా ప్రవేశానికి మార్గం చూపాలి అని ప్రార్ధించింది సీతా దేవి ప్రార్థన ముగించీ ముగించగానే భూమి బద్దలు అయింది. నాగరాజులు మోస్తున్న దివ్య సింహాసనమొకటి పైకి వచ్చింది. దానిలో ఆసీనురాలయిన భూమాత రెండు చేతులతో సీతను తీసుకొని పక్కన పొదవి కూర్చోపెట్టుకొంది. ఆకాశం నుంచి పూల వాన కురుస్తుండగా సింహాసనం పాతాళంలోకి దిగిపోగా అక్కడ ఏమీ జరగనట్టు మళ్ళీ మామూలుగా అయిపోయింది."
                    },
                    {
                        "des": "సభాసదులు దీనులై విలపిస్తూ రాముడి వంక చూడసాగారు. రాముడి దుఃఖానికి అంతే లేదు. నా కన్నుల ముందే నా భార్య మాయమయింది. లంకలో నుంచి తీసుకొని వచ్చిన ఆమెను భూమినుండి తెచ్చుకొనలేనా? భూదేవీ! అత్తగారివైన నిన్ను మర్యాదగా అడుగుతున్నాను. తక్షణం సీతను తెచ్చి ఈయకుంటే జగత్ప్రళయం సృష్టిస్తాను. అన్నాడు. అప్పుడు బ్రహ్మ వారించి రామా ! ఇది నీకు తగదు. నిన్ను స్వర్గధామంలో తప్పక కలుసుకొంటుంది. నీ చరిత్ర ఇతిహాసంగా ఉండిపోతుంది. ఇప్పటి దాకా నువ్ జరిగినది విన్నావు. ఇక జరగబోయేది కూడా మహాముని రాసి ఉన్నాడు. అది నీవు, మునులు మాత్రమే వినాలి  అని చెప్పి వెళ్ళిపోయాడు."
                    },
                ]
            },
            {
                "partname": "9.లక్ష్మణునికి ధర్మ సంకటం",
                "description": [
                    {
                        "des": "మరునాడు మిగతా గాథ రాముడు విన్నాడు. అశ్వమేధం ముగిసింది. లవకుశులతో రాముడు అయోధ్యకేగాడు. కాలం ఎవరికోసమూ ఆగదు. ఒక నాడు ఒక ముని వచ్చి రాముడ్ని చూడాలని లక్ష్మణుడ్ని కోరాడు. రామాజ్ఞతో లక్ష్మణుడు మునిని రాముని గదిలోకి ప్రవేశపెట్టాడు. వచ్చిన ముని  రామా! మనం మాటాడే విషయాలు పరులెవరికీ తెలియరాదు. ఒక వేళ అలా మధ్యలో ప్రవేశించినా విన్నా మరణదండన విధిస్తానంటే నీతో ముచ్చటిస్తాను అన్నాడు. "
                    },
                    {
                        "des": "రాముడు సరేనని లక్ష్మణుడ్ని ద్వారానికి కాపలాగా ఉండమన్నాడు. ఆ తరువాత ముని ఇలా అన్నాడు రామ చంద్రా! నేను మునిని కాదు. యమధర్మరాజుని. మానవులను సమయానుసారంగా మరణాన్ని సిద్ధపరచే సమవర్తిని. నీవు ఈ లోకానికి వచ్చిన కార్య నెరవేరింది. బ్రహ్మ పుణ్యలోకాలకు వచ్చి పరిపాలించమని కోరాడు.  అన్నాడు. రాముడు నవ్వి యమధర్మరాజా! ముల్లోకాలను రక్షించడమే నా బాధ్యత. నా స్వస్థానానికే రావడానికి నేను సిద్ధమౌతున్నాను. అన్నాడు."
                    },
                    {
                        "des": "ఇలా వీరు సంభాషించుకొంటున్న వేళ దుర్వాసుడు రాముడి దర్శనానికి వచ్చాడు. లక్ష్మణుడు దర్శనం చేయించేందుకు వ్యవధి కావాలన్నాడు. ముక్కోపి అయిన దుర్వాసుడు ఓరీ! ఈ.. రామ దర్శనానికి నేను వేచివుండాలా? తక్షణం నేను రాముడ్ని కలవాలి. లేకుంటే నీ దేశం, వంశం , మీ అన్నదమ్ములు నాశనం కావాలని శపిస్తాను  అన్నాడు. "
                    },
                    {
                        "des": "దుర్వాసుని కోఫం ఎరిగిన వాడైన లక్ష్మణుడు తన వంశం దేశం నాశనమయ్యే కంటే తాను రాముడు ఆజ్ఞను ధిక్కరించి తానొక్కడూ మరణశిక్షపొందడం మేలని తలచి యముడు రాముడు సంభాషణకు అంతరాయం కలిగిస్తూ  అన్నా! నీకోసం దుర్వాసుల వారు వచ్చారు అని అన్నాడు."
                    },
                ]
            },
            {
                "partname": "10.లక్ష్మణుడి యోగ సమాధి",
                "description": [
                    {
                        "des": "రాముడు యముని వడి వడిగా పంపి దుర్వాసునికి ఎదురేగి స్వాగతించాడు. దుర్వాసుడు తనకు ఆకలిగా ఉన్నదని మృష్టాన్నం కావాలనీ కోరాడు. ఆయనను కోరిక మేరకు తృప్తి పరచి తాను యముడికి ఇచ్చిన మాటను గుర్తుతెచ్చుకొని విచారించసాగాడు. లక్ష్మణుడు వచ్చి అన్నా! నీవు మాట తప్పవద్దు."
                    },
                    {
                        "des": "ఏ సంకోచమూ లేకుండా శిక్ష విధించి ధర్మాన్ని నిర్వర్తించు అని ధైర్య చెప్పాడు. రాముడు నిలువెల్లా కుంగిపోతూ వశిష్ట, భరత, శతృఘ్నులను సమావేశ పరచి విషయం విని విచారించాడు. వశిష్ఠుడు  రాజా! ఆడి తప్ప రాదు. నీవు లక్ష్మణుడికి దేశ బహిష్కరణ విధించు. అన్నాడు. "
                    },
                    {
                        "des": "సాధు పరిత్యాగం మరణసమానమవుతుంది కనుక నిన్ను బహిష్కరిస్తున్నాను. అన్నాడు. వెంటనే సౌమిత్రి తన ఇంటి వైపు కూడా చూడక సరాసరి సరయూ నది ఒడ్డువద్దకు చేరి యోగసమాధి అయ్యాడు. ఇంద్రుడు తన విమానంలో అతన్ని అమరావతికి తీసుకుకుపోయాడు. విష్ణు అంశలో నాల్గవభాగం తమ దగ్గరకు వచ్చినందుకు దేవతలు సంతోషించారు."
                    },
                    {
                        "des": "లక్ష్మణుడికి దేశ బహిష్కారం చేసాక భరతుని రాజుగా చేసి తాను కూడా వెళ్ళి పోతానని ప్రకటించాడు శ్రీ రాముడు. ఈ మాట విని యావత్తు రాజ్యం దుఃఖించింది. భరతుడయితే మూర్చపోయాడు. కొంతసేపటికి తేరుకొని భరతుడు అన్నా! నువ్వులేని రాజ్యం నాకెందుకు? నిన్నువదిలి నేనుండలేను. కోసల రాజ్యం రెండుభాగాలు చేసి దక్షిణం కుశుడికి ఉత్తరం లవుడుకి ఇచ్చేద్దాం. ఇదే ధర్మబద్ధం . "
                    },
                    {
                        "des": "వెంటనే శతృఘ్నునికి కబురుపెడదాం  అన్నాడు వరసగా జరుగుతున్న ఘటనలు ప్రజలకు దిగ్భ్రాంతి కలిగించాయి. వశిష్ఠుడు  రామా! ప్రజల అభీష్టాన్ని కూడా తెలుసుకొని నిర్ణయం తీసుకోవాలి అన్నాడు. రాజు ప్రజలతో సభ జరిపి నా నిర్ణయం రాజ్యాన్ని త్యజించి పోవడం. మీ నిర్ణయం ఏమిటి? అని అడిగాడు. ప్రభూ మీ నిర్ణయమే మాకు శిరో ధార్యం. తమతో పాటు అనుసరించాలని మాలో చాలా మందికి ఉన్నది. అందుకు అనుమతించండి. అదే మాకోరిక అన్నారు. రాముడు సరేనన్నాడు.",
                    },
                ]
            },
            {
                "partname": "11.రాముని నిర్ణయం",
                "description": [
                    {
                        "des": "ఆరోజే కుశలవులకు పట్టాభిషేకం జరిపి కొడుకులిద్దరను తొడమీద చేకొని వారి శరస్సులను ఆఘ్రూణించి వారికి హితవచనాలు చెప్పి ధనకనక వస్తు వాహనాలతో సైన్యాన్ని ఇచ్చి కుశుణ్ణి కుశాపతికి, లవుణ్ణి శ్రావస్తికి పంపాడు. తరువాత దూతల ద్వారా జరిగిన సంగతులన్నీ శత్రుఘ్నుడికి తెలియ చేసాడు. శతృఘ్నుడు మంత్రి పురోహితులను పిలచి తన అన్నతో తానూవెళ్ళిపోతానని తెలిపి తన రాజ్యాన్ని రెండుగా విభజించి మధురను సుబాహుడికి, విదిశానగరాన్ని చిన్నవాడు శత్రుఘాతికీ ఇచ్చి అభిషిక్తులను చేసాడు. "
                    },
                    {
                        "des": "రాముడు వెళ్ళి పోతున్నాడన్న విషయం తెలిసిన వానరులు, భల్లూకాలు, రాక్షసులు తండోప తండాలుగా అయోధ్యకు తరలి వచ్చారు. అంగదుడు చేతులు జోడించి రామా ! అంగదుడికి నా రాజ్యం అప్పగించి వచ్చేసాను. నన్నూ నీతో తీసుకొని పో అన్నాడు. రాముని వద్దకు విభీషణుడు వచ్చి ఏదో రామునికి చెప్పబోగా రాముడు వారించి విభీషణా సూర్యచంద్రులున్నంత దాకా , నా కథ ఈ లోకంలో ప్రజలు చెప్పుకొన్నంత కాలం నువ్ ధర్మబధ్ధమైన పాలన గురించి కూడా పొగిడేలా చక్కని రాజ్య పాలన చేయాలి. "
                    },
                    {
                        "des": "ఇది స్నేహితునిగా నా ఆజ్ఞ. అంతే కాదు మా ఇక్ష్వాకువంశ కులనాధుడు జగన్నాధుడు. ఆయనను సదా సేవించడం మానకు. అన్నాడు. అప్పుడు ఆంజనేయుడిని పిలిచి నాయనా!నీవు, మైందుడు , ద్వివిదుడు. మీ ముగ్గురూ కలికాలం అంతమయ్యేదాకా చిరాయువులై ఉండండి. అని ఆశీర్వదించి, మిగిలిన వానర భల్లూక వీరులనందరినీ తనతో తీసుకొని వెళ్ళడానికి అనుజ్ఞ ఇచ్చాడు."
                    },
                ]
            },
            {
                "partname": "12.రామరాజ్యం",
                "description": [
                    {
                        "des": "మరునాడు తెల్లవారింది. బ్రాహ్మణులు అగ్ని హోత్రాలు, వాజపేయచ్చత్రాన్ని పట్టుకొని ముందుకు నడుస్తుండగా రాముడు సన్నని వస్త్రాలు ధరించి, చేతి వేళ్ళ మధ్య ధర్భలు పట్టుకొని, మంత్రోచ్చారణ చేస్తూ వెడుతునాడు. ఆయనకు రెండు పక్కలా శ్రీ దేవి, హ్రీదేవి, ముందు భూదేవి ఉన్నారు. ధనుర్భాణాలు పురుష రూపంతో ఆయన్ని అనుగమించినాయి. వేదాలు, గాయత్రి , ఓంకారవషట్కారాలు ఆ పురాణ పురుషుణ్ణి అనుసరించాయి. "
                    },
                    {
                        "des": " బ్రహ్మర్షులు, విప్రులు, భరత శత్రుఘ్నులు, అంతఃపుర ప్రజలు, వానరులు, జనగణం, రాక్షసులు ఆమర్యాద పురుషోత్తముని వెంట నడిచారు. అయోధ్యలో ఉన్న పశు పక్ష్యాదులు కూడా రాముని వెంట పోగా అయోధ్య అంతా పాడుపడినట్టు ఖాళీ అయిపోయింది. శ్రీ రాముడు అర్ధ యోజన దూరం నడచి, పడమట దిక్కుగా ప్రవహిస్తున్న సరయూ నది చేరుకొన్నాడు. అప్పటెకే దేవతలతో ముని బృందాలతో బ్రహ్మదేవుడు అక్కడ వేంచేసి ఎదురుచూస్తున్నాడు. ఆకాశం దివ్య విమానాలతో నిండిపోయింది. అర్చకుల మంత్రోచ్చారణలు జరుపుతునారు. "
                    },
                    {
                        "des": "దేవతలు దుందుభులు మోగించారు. పరిమళాలతో గాలి చల్లగా వీస్తోంది. పూలవాన కురవడం మొదలయింది. అప్పుడు సరయూ నదిలోకి పాదాన్ని పెట్టాడు రాముడు. బ్రహ్మ అప్పుడు రామునితో ఇలా అన్నాడు మహావిష్ణూ ! నీకు శుభమగుగాక! నీ తమ్ముళ్ళతో కూడా స్థూల శరీరాలు విడిచి దివ్యశరీరాన్ని ధరించు. నీకు కావలసిన రూపం అందుకో తండ్రీ!సకల భువనాలకూ నువ్వే ఆధారం."
                    },
                    {
                        "des": "పితామహుడి మాటలు విని రాముడు వైష్ణవ రూపం స్వీకరించాడు. సోదరులుకూడా అలాగే చేసారు. కిన్నెరులు కింపురుషులు, యక్షులు, దేవతలు ఇలా సకల లోకాలకు చెందినవారంతా జయజయ ధ్వానాలు చేసి విష్ణువుకు భక్తిగా మొక్కారు. అప్పుడు బ్రహ్మతో విష్ణువు నావెంట వచ్చిన వారంతా నా భక్తులు. సర్వం త్యజించి నన్ను అనుసరించినవారు. వారికి పుణ్యలోకాలు ప్రసాదించు అని అజ్ఞాపించాడు."
                    },
                    {
                        "des": "బ్రహ్మ రెండుచేతులా విష్ణువుకు మొక్కి దేవా! నిన్ను నమ్మిన వారు ఆశ్రయించినవారు పశువులైన పక్షైనా సంతానకమనే దివ్యలోకం చేరతారు. ఇప్పుడు వీరినందరినీ ఆ లోకానికే చేరుస్తాను. వానరాదులు ఏ దేవతాంశం నుంచి జన్మించారో ఆ దేవతాంశం పొందుతారు. సుగ్రీవుడు సూర్యునిలో లేనమై పోతాడు అన్నాడు. రాముడ్ని అనుసరించిన వారు  గో ప్రతారం  అనే తీర్ధంలో మునిగారు. వాళ్ళకి పూర్వ శరీరాలు పోయి దివ్యశరీరాలు వచ్చాయి.అప్పుడు వారు తమకు కేటాయించిన విమానాల్లో పుణ్యలోకాలు వెళ్ళిపోయారు."
                    }
                ]
            },

        ]
    }


]
export default Ramayanam;