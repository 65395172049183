import React, { useState, useEffect } from 'react';
import Header from './header';
import Navbar from './navbar';
import Footer from './footer';
import LocalData from '../assets/jsons/Telugu/festivals_2025'
const Pandugalu = () => {
  const [selectedFestival, setSelectedFestival] = useState(null);

  // Set default festival on component mount
  useEffect(() => {
    if (LocalData.FestivalUpdated.length > 0) {
      const firstFestival = LocalData.FestivalUpdated[0][0].day[0];
      setSelectedFestival(firstFestival);
    }
  }, []);

  const [leftSticky, setLeftSticky] = useState(true);
  const [rightSticky, setRightSticky] = useState(true);
  
  const handleDateClick = (festival) => {
    setSelectedFestival(festival);
  };

  return (
    <div>
      <Header />
      <Navbar />

      <div className="container-fluid bgColor">
      <div className="row">
        {/* Decorative left side */}
        <div className="col-lg-1 col-md-1 leftFlowerImage"></div>

        {/* Left Panel */}
        <div
          className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
          onMouseEnter={() => {
            setLeftSticky(false);
            setRightSticky(true);
          }}
          style={{ overflowY: leftSticky ? 'hidden' : 'auto', height: '80vh' }}
        >
          <div className="row">
            <div id="accordion">
              <div>
                {LocalData.FestivalUpdated.map((yearData, index) =>
                  yearData.map((monthData, monthIndex) =>
                    monthData.day.map((dateInfo, dateIndex) => (
                      <div
                        className="card-header card mb-3"
                        style={{
                          backgroundColor:
                            selectedFestival === dateInfo
                              ? '#B35C0C'
                              : '#754213',
                        }}
                        onClick={() => handleDateClick(dateInfo)}
                        key={`${index}-${monthIndex}-${dateIndex}`}
                      >
                        <h5 className="mb-0">
                          <button className="btn btn" style={{ color: '#FFFFFF' }}>
                            {dateInfo.Date}
                          </button>
                        </h5>
                      </div>
                    ))
                  )
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Right Panel */}
        <div
          className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
          onMouseEnter={() => {
            setLeftSticky(true);
            setRightSticky(false);
          }}
          style={{ overflowY: rightSticky ? 'hidden' : 'auto', height: '80vh' }}
        >
          <div style={{ flex: 1 }}>
            {selectedFestival ? (
              <div>
                <div className="card-body rightPanel">
                  <h5>{selectedFestival.Date}</h5>
                  <ul>{selectedFestival.Festival}</ul>
                  <br />
                </div>
              </div>
            ) : (
              <p>దయచేసి తేదీని ఎంచుకోండి</p>
            )}
          </div>
        </div>

        {/* Decorative right side */}
        <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
      </div>
    </div>

      {/* <div className="container-fluid bgColor">
        <div className="row">
          <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3">
            <div className="row">
              <div id="accordion">
                <div>
                  {LocalData.FestivalUpdated.map((yearData, index) =>
                    yearData.map((monthData, monthIndex) =>
                      monthData.day.map((dateInfo, dateIndex) => (
                        <div
                          className="card-header card mb-3"
                          style={{
                            backgroundColor: selectedFestival === dateInfo ? '#B35C0C ' : '#754213'
                          }}
                          onClick={() => handleDateClick(dateInfo)}
                          key={`${index}-${monthIndex}-${dateIndex}`}
                        >
                          <h5 className="mb-0">
                            <button
                              className="btn btn" 
                              style={{ color: '#FFFFFF' }}
                            >
                              {dateInfo.Date}
                            </button>
                          </h5>
                        </div>
                      ))
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3">
            <div style={{ flex: 1 }}>
              {selectedFestival ? (
                <div>
                  <div className="card-body rightPanel">
                    <h5>{selectedFestival.Date}</h5>
                    <ul>{selectedFestival.Festival}</ul>
                    <br />
                  </div>
                </div>
              ) : (
                <p>దయచేసి తేదీని ఎంచుకోండి</p>
              )}
            </div>
          </div>
          <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
};

export default Pandugalu;
