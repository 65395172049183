const astakamuluData = {
  astakamuludescription: [
    {
      CatID: [
        // {
        //   title: "#అచ్యుతాష్టకం",
        // },
        // {
        //   title: "",
        // },
        {
          title: "అచ్యుతం కేశవం రామనారాయణం",
        },
        {
          title: "కృష్ణదామోదరం వాసుదేవం హరిమ్ ।",
        },
        {
          title: "శ్రీధరం మాధవం గోపికా వల్లభం",
        },
        {
          title: "జానకీనాయకం రామచంద్రం భజే ॥ 1 ॥",
        },
        {
          title: "",
        },
        {
          title: "అచ్యుతం కేశవం సత్యభామాధవం",
        },
        {
          title: "మాధవం శ్రీధరం రాధికా రాధితమ్ ।",
        },
        {
          title: "ఇందిరామందిరం చేతసా సుందరం",
        },
        {
          title: "దేవకీనందనం నందజం సందధే ॥ 2 ॥",
        },
        {
          title: "",
        },
        {
          title: "విష్ణవే జిష్ణవే శంకనే చక్రిణే",
        },
        {
          title: "రుక్మిణీ రాగిణే జానకీ జానయే ।",
        },
        {
          title: "వల్లవీ వల్లభాయార్చితా యాత్మనే",
        },
        {
          title: "కంస విధ్వంసినే వంశినే తే నమః ॥ 3 ॥",
        },
        {
          title: "",
        },
        {
          title: "కృష్ణ గోవింద హే రామ నారాయణ",
        },
        {
          title: "శ్రీపతే వాసుదేవాజిత శ్రీనిధే ।",
        },
        {
          title: "అచ్యుతానంత హే మాధవాధోక్షజ",
        },
        {
          title: "ద్వారకానాయక ద్రౌపదీరక్షక ॥ 4 ॥",
        },
        {
          title: "",
        },
        {
          title: "రాక్షస క్షోభితః సీతయా శోభితో",
        },
        {
          title: "దండకారణ్యభూ పుణ్యతాకారణః ।",
        },
        {
          title: "లక్ష్మణోనాన్వితో వానరైః సేవితో",
        },
        {
          title: "అగస్త్య సంపూజితో రాఘవః పాతు మామ్ ॥ 5 ॥",
        },
        {
          title: "",
        },
        {
          title: "ధేనుకారిష్టకోనిష్టకృద్ద్వేషిణాం",
        },
        {
          title: "కేశిహా కంసహృద్వణ్శికావాదకః ।",
        },
        {
          title: "పూతనాకోపకః సూరజాఖేలనో",
        },
        {
          title: "బాలగోపాలకః పాతు మాం సర్వదా ॥ 6 ॥",
        },
        {
          title: "",
        },
        {
          title: "విద్యుదుద్యోతవత్ప్రస్ఫురద్వాససం",
        },
        {
          title: "ప్రావృడంభోదవత్ప్రోల్లసద్విగ్రహమ్ ।",
        },
        {
          title: "వన్యయా మాలయా శోభితోరఃస్థలం",
        },
        {
          title: "లోహితాంఘ్రిద్వయం వారిజాక్షం భజే ॥ 7॥",
        },
        {
          title: "",
        },
        {
          title: "కుంచితైః కుంతలై భ్రాజమానాననం",
        },
        {
          title: "రత్నమౌళిం లసత్-కుండలం గండయోః ।",
        },
        {
          title: "హారకేయూరకం కంకణ ప్రోజ్జ్వలం",
        },
        {
          title: "కింకిణీ మంజులం శ్యామలం తం భజే ॥ 8 ॥",
        },
        {
          title: "",
        },
        {
          title: "అచ్యుతస్యాష్టకం యః పఠేదిష్టదం",
        },
        {
          title: "ప్రేమతః ప్రత్యహం పూరుషః సస్పృహమ్ ।",
        },
        {
          title: "వృత్తతః సుందరం కర్తృ విశ్వంభరః",
        },
        {
          title: "తస్య వశ్యో హరి ర్జాయతే సత్వరమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "॥ ఇతి శ్రీశంకరాచార్యవిరచితమచ్యుతాష్టకం సంపూర్ణమ్ ॥",
        },
        {
          title: "",
        },
      ],
      title: "అచ్యుతాష్టకం",
      // img: require('../images/devotional/image1.png')
      // img: require('../imgs/astakamulu_images/achyutastakam.png'),
    },
    {
      CatID: [
        // {
        //   title: "#కాశీ విశ్వనాథాష్టకం",
        // },
        // {
        //   title: "",
        // },
        {
          title: "గంగా తరంగ రమణీయ జటా కలాపం",
        },
        {
          title: "గౌరీ నిరంతర విభూషిత వామ భాగం",
        },
        {
          title: "నారాయణ ప్రియమనంగ మదాపహారం",
        },
        {
          title: "వారాణసీ పురపతిం భజ విశ్వనాథమ్ ॥ 1 ॥",
        },
        {
          title: "",
        },
        {
          title: "వాచామగోచరమనేక గుణ స్వరూపం",
        },
        {
          title: "వాగీశ విష్ణు సుర సేవిత పాద పద్మం",
        },
        {
          title: "వామేణ విగ్రహ వరేన కలత్రవంతం",
        },
        {
          title: "వారాణసీ పురపతిం భజ విశ్వనాథమ్ ॥ 2 ॥",
        },
        {
          title: "",
        },
        {
          title: "భూతాదిపం భుజగ భూషణ భూషితాంగం",
        },
        {
          title: "వ్యాఘ్రాంజినాం బరధరం, జటిలం, త్రినేత్రం",
        },
        {
          title: "పాశాంకుశాభయ వరప్రద శూలపాణిం",
        },
        {
          title: "వారాణసీ పురపతిం భజ విశ్వనాథమ్ ॥ 3 ॥",
        },
        {
          title: "",
        },
        {
          title: "సీతాంశు శోభిత కిరీట విరాజమానం",
        },
        {
          title: "బాలేక్షణాతల విశోషిత పంచబాణం",
        },
        {
          title: "నాగాధిపా రచిత బాసుర కర్ణ పూరం",
        },
        {
          title: "వారాణసీ పురపతిం భజ విశ్వనాథమ్ ॥ 4 ॥",
        },
        {
          title: "",
        },
        {
          title: "పంచాననం దురిత మత్త మతంగజానాం",
        },
        {
          title: "నాగాంతకం ధనుజ పుంగవ పన్నాగానాం",
        },
        {
          title: "దావానలం మరణ శోక జరాటవీనాం",
        },
        {
          title: "వారాణసీ పురపతిం భజ విశ్వనాథమ్ ॥ 5 ॥",
        },
        {
          title: "",
        },
        {
          title: "తేజోమయం సగుణ నిర్గుణమద్వితీయం",
        },
        {
          title: "ఆనంద కందమపరాజిత మప్రమేయం",
        },
        {
          title: "నాగాత్మకం సకల నిష్కళమాత్మ రూపం",
        },
        {
          title: "వారాణసీ పురపతిం భజ విశ్వనాథమ్ ॥ 6 ॥",
        },
        {
          title: "",
        },
        {
          title: "ఆశాం విహాయ పరిహృత్య పరశ్య నిందాం",
        },
        {
          title: "పాపే రథిం చ సునివార్య మనస్సమాధౌ",
        },
        {
          title: "ఆధాయ హృత్-కమల మధ్య గతం పరేశం",
        },
        {
          title: "వారాణసీ పురపతిం భజ విశ్వనాథమ్ ॥ 7 ॥",
        },
        {
          title: "",
        },
        {
          title: "రాగాధి దోష రహితం స్వజనానురాగం",
        },
        {
          title: "వైరాగ్య శాంతి నిలయం గిరిజా సహాయం",
        },
        {
          title: "మాధుర్య ధైర్య సుభగం గరళాభిరామం",
        },
        {
          title: "వారాణసీ పురపతిం భజ విశ్వనాథమ్ ॥ 8 ॥",
        },
        {
          title: "",
        },
        {
          title: "వారాణసీ పుర పతే స్థవనం శివస్",
        },
        {
          title: "వ్యాఖ్యాతం అష్టకమిదం పఠతే మనుష్య",
        },
        {
          title: "విద్యాం శ్రియం విపుల సౌఖ్యమనంత కీర్తిం",
        },
        {
          title: "సంప్రాప్య దేవ నిలయే లభతే చ మోక్షమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "విశ్వనాథాష్టకమిదం పుణ్యం యః పఠేః శివ సన్నిధౌ",
        },
        {
          title: "శివలోకమవాప్నోతి శివేనసహ మోదతే ॥",
        },
        {
          title: "",
        },
      ],
      title: "కాశీ విశ్వనాథాష్టకం",
      // img: require('../imgs/astakamulu_images/sivastakam.png'),
    },
    {
      CatID: [
        // {
        //   title: "#కృష్ణాష్టకం",
        // },
        // {
        //   title: "",
        // },
        {
          title: "వసుదేవ సుతం దేవం కంస చాణూర మర్దనమ్ ।",
        },
        {
          title: "దేవకీ పరమానందం కృష్ణం వందే జగద్గురుమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "అతసీ పుష్ప సంకాశం హార నూపుర శోభితమ్ ।",
        },
        {
          title: "రత్న కంకణ కేయూరం కృష్ణం వందే జగద్గురుమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "కుటిలాలక సంయుక్తం పూర్ణచంద్ర నిభాననమ్ ।",
        },
        {
          title: "విలసత్ కుండలధరం కృష్ణం వందే జగద్గురమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "మందార గంధ సంయుక్తం చారుహాసం చతుర్భుజమ్ ।",
        },
        {
          title: "బర్హి పింఛావ చూడాంగం కృష్ణం వందే జగద్గురుమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "ఉత్ఫుల్ల పద్మపత్రాక్షం నీల జీమూత సన్నిభమ్ ।",
        },
        {
          title: "యాదవానాం శిరోరత్నం కృష్ణం వందే జగద్గురుమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "రుక్మిణీ కేళి సంయుక్తం పీతాంబర సుశోభితమ్ ।",
        },
        {
          title: "అవాప్త తులసీ గంధం కృష్ణం వందే జగద్గురుమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "గోపికానాం కుచద్వంద కుంకుమాంకిత వక్షసమ్ ।",
        },
        {
          title: "శ్రీనికేతం మహేష్వాసం కృష్ణం వందే జగద్గురుమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "శ్రీవత్సాంకం మహోరస్కం వనమాలా విరాజితమ్ ।",
        },
        {
          title: "శంఖచక్ర ధరం దేవం కృష్ణం వందే జగద్గురుమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "కృష్ణాష్టక మిదం పుణ్యం ప్రాతరుత్థాయ యః పఠేత్ ।",
        },
        {
          title: "కోటిజన్మ కృతం పాపం స్మరణేన వినశ్యతి ॥",
        },
        {
          title: "",
        },
        ],
      title: "కృష్ణాష్టకం",
      // img: require('../imgs/astakamulu_images/krishnastakam.png'),
    },
    {
      CatID: [
        // {
        //   title: "#చంద్రశేఖరాష్టకం",
        // },
        // {
        //   title: "",
        // },
        {
          title: "చంద్రశేఖర చంద్రశేఖర చంద్రశేఖర పాహిమామ్ ।",
        },
        {
          title: "చంద్రశేఖర చంద్రశేఖర చంద్రశేఖర రక్షమామ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "రత్నసాను శరాసనం రజతాద్రి శృంగ నికేతనం",
        },
        {
          title: "శింజినీకృత పన్నగేశ్వర మచ్యుతానల సాయకమ్ ।",
        },
        {
          title: "క్షిప్రదగ్ద పురత్రయం త్రిదశాలయై-రభివందితం",
        },
        {
          title: "చంద్రశేఖరమాశ్రయే మమ కిం కరిష్యతి వై యమః ॥ 1 ॥",
        },
        {
          title: "",
        },
        {
          title: "పంచపాదప పుష్పగంధ పదాంబుజ ద్వయశోభితం",
        },
        {
          title: "ఫాలలోచన జాతపావక దగ్ధ మన్మధ విగ్రహమ్ ।",
        },
        {
          title: "భస్మదిగ్ధ కళేబరం భవనాశనం భవ మవ్యయం",
        },
        {
          title: "చంద్రశేఖర మాశ్రయే మమ కిం కరిష్యతి వై యమ: ॥ 2 ॥",
        },
        {
          title: "",
        },
        {
          title: "మత్తవారణ ముఖ్యచర్మ కృతోత్తరీయ మనోహరం",
        },
        {
          title: "పంకజాసన పద్మలోచన పూజితాంఘ్రి సరోరుహమ్ ।",
        },
        {
          title: "దేవ సింధు తరంగ శ్రీకర సిక్త శుభ్ర జటాధరం",
        },
        {
          title: "చంద్రశేఖర మాశ్రయే మమ కిం కరిష్యతి వై యమ: ॥ 3 ॥",
        },
        {
          title: "",
        },
        {
          title: "యక్ష రాజసఖం భగాక్ష హరం భుజంగ విభూషణం",
        },
        {
          title: "శైలరాజ సుతా పరిష్కృత చారువామ కళేబరమ్ ।",
        },
        {
          title: "క్షేళ నీలగళం పరశ్వధ ధారిణం మృగధారిణం",
        },
        {
          title: "చంద్రశేఖర మాశ్రయే మమ కిం కరిష్యతి వై యమ: ॥ 4 ॥",
        },
        {
          title: "",
        },
        {
          title: "కుండలీకృత కుండలీశ్వర కుండలం వృషవాహనం",
        },
        {
          title: "నారదాది మునీశ్వర స్తుతవైభవం భువనేశ్వరమ్ ।",
        },
        {
          title: "అంధకాంతక మాశ్రితామర పాదపం శమనాంతకం",
        },
        {
          title: "చంద్రశేఖర మాశ్రయే మమ కిం కరిష్యతి వై యమ: ॥ 5 ॥",
        },
        {
          title: "",
        },
        {
          title: "భేషజం భవరోగిణా మఖిలాపదా మపహారిణం",
        },
        {
          title: "దక్షయజ్ఞ వినాశనం త్రిగుణాత్మకం త్రివిలోచనమ్ ।",
        },
        {
          title: "భక్తి ముక్తి ఫలప్రదం సకలాఘ సంఘ నిబర్హణం",
        },
        {
          title: "చంద్రశేఖర మాశ్రయే మమ కిం కరిష్యతి వై యమ: ॥ 6 ॥",
        },
        {
          title: "",
        },
        {
          title: "భక్తవత్సల-మర్చితం నిధిమక్షయం హరిదంబరం",
        },
        {
          title: "సర్వభూత పతిం పరాత్పర-మప్రమేయ మనుత్తమమ్ ।",
        },
        {
          title: "సోమవారిన భూహుతాశన సోమ పాద్యఖిలాకృతిం",
        },
        {
          title: "చంద్రశేఖర మాశ్రయే మమ కిం కరిష్యతి వై యమ: ॥ 7 ॥",
        },
        {
          title: "",
        },
        {
          title: "విశ్వసృష్టి విధాయకం పునరేవపాలన తత్పరం",
        },
        {
          title: "సంహరం తమపి ప్రపంచ మశేషలోక నివాసినమ్ ।",
        },
        {
          title: "క్రీడయంత మహర్నిశం గణనాథ యూథ సమన్వితం",
        },
        {
          title: "చంద్రశేఖర మాశ్రయే మమ కిం కరిష్యతి వై యమ: ॥ 8 ॥",
        },
        {
          title: "",
        },
        {
          title: "మృత్యుభీత మృకండుసూనుకృతస్తవం శివసన్నిధౌ",
        },
        {
          title: "యత్ర కుత్ర చ యః పఠేన్న హి తస్య మృత్యుభయం భవేత్ ।",
        },
        {
          title: "పూర్ణమాయురరోగతామఖిలార్థసంపదమాదరం",
        },
        {
          title: "చంద్రశేఖర ఏవ తస్య దదాతి ముక్తిమయత్నతః ॥ 9 ॥",
        },
        {
          title: "",
        },
        {
          title: "|| ఇతి శ్రీ చంద్రశేఖరాష్టకం సంపూర్ణం ||",
        },
        {
          title: "",
        },
      ],
      title: "చంద్రశేఖరాష్టకం",
      // img: require('../imgs/astakamulu_images/sivastakam.png'),
    },
    {
      CatID: [
        // {
        //   title: "#బిల్వాష్టకం",
        // },
        // {
        //   title: "",
        // },
        {
          title: "త్రిదళం త్రిగుణాకారం త్రినేత్రం చ త్రియాయుధమ్ ।",
        },
        {
          title: "త్రిజన్మ పాపసంహారం ఏకబిల్వం శివార్పణమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "త్రిశాఖైః బిల్వపత్రైశ్చ అచ్ఛిద్రైః కోమలైః శుభైః ।",
        },
        {
          title: "తవపూజాం కరిష్యామి ఏకబిల్వం శివార్పణమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "కోటి కన్యా మహాదానం తిలపర్వత కోటయః ।",
        },
        {
          title: "కాంచనం శైలదానేన ఏకబిల్వం శివార్పణమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "కాశీక్షేత్ర నివాసం చ కాలభైరవ దర్శనమ్ ।",
        },
        {
          title: "ప్రయాగే మాధవం దృష్ట్వా ఏకబిల్వం శివార్పణమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "ఇందువారే వ్రతం స్థిత్వా నిరాహారో మహేశ్వరాః ।",
        },
        {
          title: "నక్తం హౌష్యామి దేవేశ ఏకబిల్వం శివార్పణమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "రామలింగ ప్రతిష్ఠా చ వైవాహిక కృతం తథా ।",
        },
        {
          title: "తటాకానిచ సంధానం ఏకబిల్వం శివార్పణమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "అఖండ బిల్వపత్రం చ ఆయుతం శివపూజనమ్ ।",
        },
        {
          title: "కృతం నామ సహస్రేణ ఏకబిల్వం శివార్పణమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "ఉమయా సహదేవేశ నంది వాహనమేవ చ ।",
        },
        {
          title: "భస్మలేపన సర్వాంగం ఏకబిల్వం శివార్పణమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "సాలగ్రామేషు విప్రాణాం తటాకం దశకూపయోః ।",
        },
        {
          title: "యజ్ఞ్నకోటి సహస్రస్య ఏకబిల్వం శివార్పణమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "దంతి కోటి సహస్రేషు అశ్వమేధశతక్రతౌ ।",
        },
        {
          title: "కోటికన్యా మహాదానం ఏకబిల్వం శివార్పణమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "బిల్వాణాం దర్శనం పుణ్యం స్పర్శనం పాపనాశనమ్ ।",
        },
        {
          title: "అఘోర పాపసంహారం ఏకబిల్వం శివార్పణమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "సహస్రవేద పాటేషు బ్రహ్మస్తాపనముచ్యతే ।",
        },
        {
          title: "అనేకవ్రత కోటీనాం ఏకబిల్వం శివార్పణమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "అన్నదాన సహస్రేషు సహస్రోపనయనం తధా ।",
        },
        {
          title: "అనేక జన్మపాపాని ఏకబిల్వం శివార్పణమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "బిల్వాష్టకమిదం పుణ్యం యః పఠేశ్శివ సన్నిధౌ ।",
        },
        {
          title: "శివలోకమవాప్నోతి ఏకబిల్వం శివార్పణమ్ ॥",
        },
        {
          title: "",
        },
      ],
      title: "బిల్వాష్టకం",
      // img: require('../imgs/astakamulu_images/sivastakam.png'),
    },
    {
      CatID: [
        // {
        //   title: "#మహాలక్ష్మీ అష్టకం",
        // },
        // {
        //   title: "",
        // },
        {
          title: <span class='highlight'>ఇంద్ర ఉవాచ :</span>,
        },
        {
          title: "",
        },
        {
          title: "నమస్తేఽస్తు మహామాయే శ్రీపీఠే సురపూజితే ।",
        },
        {
          title: "శంఖచక్ర గదాహస్తే మహాలక్ష్మీ నమోఽస్తుతే ॥ 1 ॥",
        },
        {
          title: "",
        },
        {
          title: "నమస్తే గరుడారూఢే కోలాసుర భయంకరి ।",
        },
        {
          title: "సర్వపాపహరే దేవి మహాలక్ష్మీ నమోఽస్తుతే ॥ 2 ॥",
        },
        {
          title: "",
        },
        {
          title: "సర్వజ్ఞే సర్వవరదే సర్వ దుష్ట భయంకరి ।",
        },
        {
          title: "సర్వదుఃఖ హరే దేవి మహాలక్ష్మీ నమోఽస్తుతే ॥ 3 ॥",
        },
        {
          title: "",
        },
        {
          title: "సిద్ధి బుద్ధి ప్రదే దేవి భుక్తి ముక్తి ప్రదాయిని ।",
        },
        {
          title: "మంత్ర మూర్తే సదా దేవి మహాలక్ష్మీ నమోఽస్తుతే ॥ 4 ॥",
        },
        {
          title: "",
        },
        {
          title: "ఆద్యంత రహితే దేవి ఆదిశక్తి మహేశ్వరి ।",
        },
        {
          title: "యోగజ్ఞే యోగ సంభూతే మహాలక్ష్మీ నమోఽస్తుతే ॥ 5 ॥",
        },
        {
          title: "",
        },
        {
          title: "స్థూల సూక్ష్మ మహారౌద్రే మహాశక్తి మహోదరే ।",
        },
        {
          title: "మహా పాప హరే దేవి మహాలక్ష్మీ నమోఽస్తుతే ॥ 6 ॥",
        },
        {
          title: "",
        },
        {
          title: "పద్మాసన స్థితే దేవి పరబ్రహ్మ స్వరూపిణి ।",
        },
        {
          title: "పరమేశి జగన్మాతః మహాలక్ష్మీ నమోఽస్తుతే ॥ 7 ॥",
        },
        {
          title: "",
        },
        {
          title: "శ్వేతాంబరధరే దేవి నానాలంకార భూషితే ।",
        },
        {
          title: "జగస్థితే జగన్మాతః మహాలక్ష్మీ నమోఽస్తుతే ॥ 8 ॥",
        },
        {
          title: "",
        },
        {
          title: "మహాలక్ష్మష్టకం స్తోత్రం యః పఠేద్ భక్తిమాన్ నరః ।",
        },
        {
          title: "సర్వ సిద్ధి మవాప్నోతి రాజ్యం ప్రాప్నోతి సర్వదా ॥",
        },
        {
          title: "",
        },
        {
          title: "ఏకకాలే పఠేన్నిత్యం మహాపాప వినాశనమ్ ।",
        },
        {
          title: "ద్వికాలం యః పఠేన్నిత్యం ధన ధాన్య సమన్వితః ॥",
        },
        {
          title: "",
        },
        {
          title: "త్రికాలం యః పఠేన్నిత్యం మహాశత్రు వినాశనమ్ ।",
        },
        {
          title: "మహాలక్ష్మీ ర్భవేన్ నిత్యం ప్రసన్నా వరదా శుభా ॥",
        },
        {
          title: "",
        },
        {
          title: "|| ఇతి ఇంత్యకృత శ్రీ మహాలక్ష్మీ అష్టకస్తోత్రం సంపూర్ణం ||",
        },
        {
          title: "",
        },
      ],
      title: "మహాలక్ష్మీ అష్టకం",
      // img: require('../imgs/astakamulu_images/mahalakshmastakam.png'),
    },
    {
      CatID: [
        // {
        //   title: "#రుద్రాష్టకం",
        // },
        // {
        //   title: "",
        // },
        {
          title: "నమామీశమీశాన నిర్వాణరూపం",
        },
        {
          title: "విభుం వ్యాపకం బ్రహ్మవేదస్వరూపమ్ ।",
        },
        {
          title: "నిజం నిర్గుణం నిర్వికల్పం నిరీహం",
        },
        {
          title: "చిదాకాశమాకాశవాసం భజేహమ్ ॥ 1 ॥",
        },
        {
          title: "",
        },
        {
          title: "నిరాకారమోంకారమూలం తురీయం",
        },
        {
          title: "గిరాజ్ఞానగోతీతమీశం గిరీశమ్ ।",
        },
        {
          title: "కరాలం మహాకాలకాలం కృపాలుం",
        },
        {
          title: "గుణాగారసంసారపారం నతోహమ్ ॥ 2 ॥",
        },
        {
          title: "",
        },
        {
          title: "తుషారాద్రిసంకాశగౌరం గభీరం",
        },
        {
          title: "మనోభూతకోటిప్రభాసీ శరీరమ్ ।",
        },
        {
          title: "స్ఫురన్మౌలికల్లోలినీ చారుగంగా",
        },
        {
          title: "లసద్భాలబాలేందు కంఠే భుజంగమ్ ॥ 3 ॥",
        },
        {
          title: "",
        },
        {
          title: "చలత్కుండలం శుభ్రనేత్రం విశాలం",
        },
        {
          title: "ప్రసన్నాననం నీలకంఠం దయాలుమ్ ।",
        },
        {
          title: "మృగాధీశచర్మాంబరం ముండమాలం",
        },
        {
          title: "ప్రియం శంకరం సర్వనాథం భజామి ॥ 4 ॥",
        },
        {
          title: "",
        },
        {
          title: "ప్రచండం ప్రకృష్టం ప్రగల్భం పరేశం",
        },
        {
          title: "అఖండం భజే భానుకోటిప్రకాశమ్ ।",
        },
        {
          title: "త్రయీశూలనిర్మూలనం శూలపాణిం",
        },
        {
          title: "భజేఽహం భవానీపతిం భావగమ్యమ్ ॥ 5 ॥",
        },
        {
          title: "",
        },
        {
          title: "కలాతీతకల్యాణకల్పాంతకారీ",
        },
        {
          title: "సదాసజ్జనానందదాతా పురారీ ।",
        },
        {
          title: "చిదానందసందోహమోహాపహారీ",
        },
        {
          title: "ప్రసీద ప్రసీద ప్రభో మన్మథారీ ॥ 6 ॥",
        },
        {
          title: "",
        },
        {
          title: "న యావదుమానాథపాదారవిందం",
        },
        {
          title: "భజంతీహ లోకే పరే వా నరాణామ్ ।",
        },
        {
          title: "న తావత్సుఖం శాంతి సంతాపనాశం",
        },
        {
          title: "ప్రసీద ప్రభో సర్వభూతాధివాసమ్ ॥ 7 ॥",
        },
        {
          title: "",
        },
        {
          title: "న జానామి యోగం జపం నైవ పూజాం",
        },
        {
          title: "నతోఽహం సదా సర్వదా దేవ తుభ్యమ్ ।",
        },
        {
          title: "జరాజన్మదుఃఖౌఘతాతప్యమానం",
        },
        {
          title: "ప్రభో పాహి శాపాన్నమామీశ శంభో ॥ 8 ॥",
        },
        {
          title: "",
        },
        {
          title: "రుద్రాష్టకమిదం ప్రోక్తం విప్రేణ హరతుష్టయే ।",
        },
        {
          title: "యే పఠంతి నరా భక్త్యా తేషాం శంభుః ప్రసీదతి ॥",
        },
        {
          title: "",
        },
        {
          title: "॥ ఇతి శ్రీరామచరితమానసే ఉత్తరకాండే శ్రీగోస్వామితులసీదాసకృతం శ్రీరుద్రాష్టకం సంపూర్ణమ్ ॥",
        },
        {
          title: "",
        },
      ],
      title: "రుద్రాష్టకం",
      // img: require('../imgs/astakamulu_images/sivastakam.png'),
    },
    {
      CatID: [
        // {
        //   title: "#లింగాష్టకం",
        // },
        // {
        //   title: "",
        // },
        {
          title: "బ్రహ్మమురారి సురార్చిత లింగం",
        },
        {
          title: "నిర్మలభాసిత శోభిత లింగమ్ ।",
        },
        {
          title: "జన్మజ దుఃఖ వినాశక లింగం",
        },
        {
          title: "తత్ప్రణమామి సదాశివ లింగమ్ ॥ 1 ॥",
        },
        {
          title: "",
        },
        {
          title: "దేవముని ప్రవరార్చిత లింగం",
        },
        {
          title: "కామదహన కరుణాకర లింగమ్ ।",
        },
        {
          title: "రావణ దర్ప వినాశన లింగం",
        },
        {
          title: "తత్ప్రణమామి సదాశివ లింగమ్ ॥ 2 ॥",
        },
        {
          title: "",
        },
        {
          title: "సర్వ సుగంధ సులేపిత లింగం",
        },
        {
          title: "బుద్ధి వివర్ధన కారణ లింగమ్ ।",
        },
        {
          title: "సిద్ధ సురాసుర వందిత లింగం",
        },
        {
          title: "తత్ప్రణమామి సదాశివ లింగమ్ ॥ 3 ॥",
        },
        {
          title: "",
        },
        {
          title: "కనక మహామణి భూషిత లింగం",
        },
        {
          title: "ఫణిపతి వేష్టిత శోభిత లింగమ్ ।",
        },
        {
          title: "దక్షసుయజ్ఞ వినాశన లింగం",
        },
        {
          title: "తత్ప్రణమామి సదాశివ లింగమ్ ॥ 4 ॥",
        },
        {
          title: "",
        },
        {
          title: "కుంకుమ చందన లేపిత లింగం",
        },
        {
          title: "పంకజ హార సుశోభిత లింగమ్ ।",
        },
        {
          title: "సంచిత పాప వినాశన లింగం",
        },
        {
          title: "తత్ప్రణమామి సదాశివ లింగమ్ ॥ 5 ॥",
        },
        {
          title: "",
        },
        {
          title: "దేవగణార్చిత సేవిత లింగం",
        },
        {
          title: "భావైర్భక్తిభిరేవ చ లింగమ్ ।",
        },
        {
          title: "దినకర కోటి ప్రభాకర లింగం",
        },
        {
          title: "తత్ప్రణమామి సదాశివ లింగమ్ ॥ 6 ॥",
        },
        {
          title: "",
        },
        {
          title: "అష్టదళోపరివేష్టిత లింగం",
        },
        {
          title: "సర్వసముద్భవ కారణ లింగమ్ ।",
        },
        {
          title: "అష్టదరిద్ర వినాశన లింగం",
        },
        {
          title: "తత్ప్రణమామి సదాశివ లింగమ్ ॥ 7 ॥",
        },
        {
          title: "",
        },
        {
          title: "సురగురు సురవర పూజిత లింగం",
        },
        {
          title: "సురవన పుష్ప సదార్చిత లింగమ్ ।",
        },
        {
          title: "పరమపదం పరమాత్మక లింగం",
        },
        {
          title: "తత్ప్రణమామి సదాశివ లింగమ్ ॥ 8 ॥",
        },
        {
          title: "",
        },
        {
          title: "లింగాష్టకమిదం పుణ్యం యః పఠేశ్శివ సన్నిధౌ ।",
        },
        {
          title: "శివలోకమవాప్నోతి శివేన సహ మోదతే ॥",
        },
        {
          title: "",
        },
      ],
      title: "లింగాష్టకం",
      // img: require('../imgs/astakamulu_images/sivastakam.png'),
    },
    {
      CatID: [
        // {
        //   title: "#శివాష్టకం",
        // },
        // {
        //   title: "",
        // },
        {
          title: "ప్రభుం ప్రాణనాథం విభుం విశ్వనాథం జగన్నాథ నాథం సదానంద భాజామ్ ।",
        },
        {
          title: "భవద్భవ్య భూతేశ్వరం భూతనాథం, శివం శంకరం శంభు మీశానమీడే ॥ 1 ॥",
        },
        {
          title: "",
        },
        {
          title: "గళే రుండమాలం తనౌ సర్పజాలం మహాకాల కాలం గణేశాది పాలమ్ ।",
        },
        {
          title: "జటాజూట గంగోత్తరంగైర్విశాలం, శివం శంకరం శంభు మీశానమీడే ॥ 2॥",
        },
        {
          title: "",
        },
        {
          title: "ముదామాకరం మండనం మండయంతం మహా మండలం భస్మ భూషాధరంతమ్ ।",
        },
        {
          title: "అనాదిం హ్యపారం మహా మోహమారం, శివం శంకరం శంభు మీశానమీడే ॥ 3 ॥",
        },
        {
          title: "",
        },
        {
          title: "వటాధో నివాసం మహాట్టాట్టహాసం మహాపాప నాశం సదా సుప్రకాశమ్ ।",
        },
        {
          title: "గిరీశం గణేశం సురేశం మహేశం, శివం శంకరం శంభు మీశానమీడే ॥ 4 ॥",
        },
        {
          title: "",
        },
        {
          title: "గిరీంద్రాత్మజా సంగృహీతార్ధదేహం గిరౌ సంస్థితం సర్వదాపన్న గేహమ్ ।",
        },
        {
          title: "పరబ్రహ్మ బ్రహ్మాదిభిర్-వంద్యమానం, శివం శంకరం శంభు మీశానమీడే ॥ 5 ॥",
        },
        {
          title: "",
        },
        {
          title: "కపాలం త్రిశూలం కరాభ్యాం దధానం పదాంభోజ నమ్రాయ కామం దదానమ్ ।",
        },
        {
          title: "బలీవర్ధమానం సురాణాం ప్రధానం, శివం శంకరం శంభు మీశానమీడే ॥ 6 ॥",
        },
        {
          title: "",
        },
        {
          title: "శరచ్చంద్ర గాత్రం గణానందపాత్రం త్రినేత్రం పవిత్రం ధనేశస్య మిత్రమ్ ।",
        },
        {
          title: "అపర్ణా కళత్రం సదా సచ్చరిత్రం, శివం శంకరం శంభు మీశానమీడే ॥ 7 ॥",
        },
        {
          title: "",
        },
        {
          title: "హరం సర్పహారం చితా భూవిహారం భవం వేదసారం సదా నిర్వికారం।",
        },{
          title: "శ్మశానే వసంతం మనోజం దహంతం, శివం శంకరం శంభు మీశానమీడే ॥ 8 ॥",
        },
        {
          title: "",
        },
        {
          title: "స్వయం యః ప్రభాతే నరశ్శూల పాణే పఠేత్ స్తోత్రరత్నం త్విహప్రాప్యరత్నమ్ ।",
        },
        {
          title: "సుపుత్రం సుధాన్యం సుమిత్రం కళత్రం విచిత్రైస్సమారాధ్య మోక్షం ప్రయాతి ॥",
        },
        {
          title: "",
        },
      ],
      title: "శివాష్టకం",
      // img: require('../imgs/astakamulu_images/sivastakam.png'),
    },
    {
      CatID: [
        // {
        //   title: "#శ్రీ పార్వతీవల్లభాష్టకం",
        // },
        // {
        //   title: "",
        // },
        {
          title: "నమో భూతనాథం నమో దేవదేవం",
        },
        {
          title: "నమః కాలకాలం నమో దివ్యతేజమ్ |",
        },
        {
          title: "నమః కామభస్మం నమశ్శాంతశీలం",
        },
        {
          title: "భజే పార్వతీవల్లభం నీలకంఠమ్ || 1 ||",
        },
        {
          title: "",
        },
        {
          title: "సదా తీర్థసిద్ధం సదా భక్తరక్షం",
        },
        {
          title: "సదా శైవపూజ్యం సదా శుభ్రభస్మమ్ |",
        },
        {
          title: "సదా ధ్యానయుక్తం సదా జ్ఞానతల్పం",
        },
        {
          title: "భజే పార్వతీవల్లభం నీలకంఠమ్ || 2 ||",
        },
        {
          title: "",
        },
        {
          title: "శ్మశానం శయానం మహాస్థానవాసం",
        },
        {
          title: "శరీరం గజానాం సదా చర్మవేష్టమ్ |",
        },
        {
          title: "పిశాచం నిశోచం పశూనాం ప్రతిష్ఠం",
        },
        {
          title: "భజే పార్వతీవల్లభం నీలకంఠమ్ || 3 ||",
        },
        {
          title: "",
        },
        {
          title: "ఫణీనాగకంఠే భుజంగాద్యనేకం",
        },
        {
          title: "గళే రుండమాలం మహావీర శూరమ్ |",
        },
        {
          title: "కటివ్యాఘ్రచర్మం చితాభస్మలేపం",
        },
        {
          title: "భజే పార్వతీవల్లభం నీలకంఠమ్ || 4 ||",
        },
        {
          title: "",
        },
        {
          title: "శిరశ్శుద్ధగంగా శివా వామభాగం",
        },
        {
          title: "బృహద్దీర్ఘకేశం సదా మాం త్రిణేత్రమ్ |",
        },
        {
          title: "ఫణీనాగకర్ణం సదా ఫాలచంద్రం",
        },
        {
          title: "భజే పార్వతీవల్లభం నీలకంఠమ్ || 5 ||",
        },
        {
          title: "",
        },
        {
          title: "కరే శూలధారం మహాకష్టనాశం",
        },
        {
          title: "సురేశం వరేశం మహేశం జనేశమ్ |",
        },
        {
          title: "ధనేశామరేశం ధ్వజేశం గిరీశం",
        },
        {
          title: "భజే పార్వతీవల్లభం నీలకంఠమ్ || 6 ||",
        },
        {
          title: "",
        },
        {
          title: "ఉదాసం సుదాసం సుకైలాసవాసం",
        },
        {
          title: "ధరానిర్ధరం సంస్థితం హ్యాదిదేవమ్ |",
        },
        {
          title: "అజాహేమకల్పద్రుమం కల్పసేవ్యం",
        },
        {
          title: "భజే పార్వతీవల్లభం నీలకంఠమ్ || 7 ||",
        },
        {
          title: "",
        },
        {
          title: "మునీనాం వరేణ్యం గుణం రూపవర్ణం",
        },
        {
          title: "ద్విజైస్సంపఠంతం శివం వేదశాస్త్రమ్ |",
        },
        {
          title: "అహో దీనవత్సం కృపాలం మహేశం",
        },
        {
          title: "భజే పార్వతీవల్లభం నీలకంఠమ్ || 8 ||",
        },
        {
          title: "",
        },
        {
          title: "సదా భావనాథం సదా సేవ్యమానం",
        },
        {
          title: "సదా భక్తిదేవం సదా పూజ్యమానమ్ |",
        },
        {
          title: "మయా తీర్థవాసం సదా సేవ్యమేకం",
        },
        {
          title: "భజే పార్వతీవల్లభం నీలకంఠమ్ ||  9 ||",
        },
        {
          title: "",
        },
        {
          title: "|| ఇతి శ్రీమచ్ఛంకరయోగీంద్ర విరచితం పార్వతీవల్లభాష్టకం సంపూర్ణం ||",
        },
        {
          title: "",
        },
      ],
      title: "శ్రీ పార్వతీవల్లభాష్టకం",
      // img: require('../imgs/astakamulu_images/parvathivallabhastakam.png'),
    },
    {
      CatID: [
        // {
        //   title: "#శ్రీ శివ మంగళాష్టకం",
        // },
        // {
        //   title: "",
        // },
        {
          title: "భవాయ చంద్రచూడాయ నిర్గుణాయ గుణాత్మనే ।",
        },
        {
          title: "కాలకాలాయ రుద్రాయ నీలగ్రీవాయ మంగళం ॥ 1 ॥",
        },
        {
          title: "",
        },
        {
          title: "వృషారూఢాయ భీమాయ వ్యాఘ్రచర్మాంబరాయ చ ।",
        },
        {
          title: "పశూనాంపతయే తుభ్యం గౌరీకాంతాయ మంగళం ॥ 2 ॥",
        },
        {
          title: "",
        },
        {
          title: "భస్మోద్ధూళితదేహాయ నాగయజ్ఞోపవీతినే ।",
        },
        {
          title: "రుద్రాక్షమాలాభూషాయ వ్యోమకేశాయ మంగళం ॥ 3 ॥",
        },
        {
          title: "",
        },
        {
          title: "సూర్యచంద్రాగ్నినేత్రాయ నమః కైలాసవాసినే ।",
        },
        {
          title: "సచ్చిదానందరూపాయ ప్రమథేశాయ మంగళం ॥ 4 ॥",
        },
        {
          title: "",
        },
        {
          title: "మృత్యుంజయాయ సాంబాయ సృష్టిస్థిత్యంతకారిణే ।",
        },
        {
          title: "త్రయంబకాయ శాంతాయ త్రిలోకేశాయ మంగళం ॥ 5 ॥",
        },
        {
          title: "",
        },
        {
          title: "గంగాధరాయ సోమాయ నమో హరిహరాత్మనే ।",
        },
        {
          title: "ఉగ్రాయ త్రిపురఘ్నాయ వామదేవాయ మంగళం ॥ 6 ॥",
        },
        {
          title: "",
        },
        {
          title: "సద్యోజాతాయ శర్వాయ భవ్య జ్ఞానప్రదాయినే ।",
        },
        {
          title: "ఈశానాయ నమస్తుభ్యం పంచవక్రాయ మంగళం ॥ 7 ॥",
        },
        {
          title: "",
        },
        {
          title: "సదాశివ స్వరూపాయ నమస్తత్పురుషాయ చ ।",
        },
        {
          title: "అఘోరాయ చ ఘోరాయ మహాదేవాయ మంగళం ॥ 8 ॥",
        },
        {
          title: "",
        },
        {
          title: "మహాదేవస్య దేవస్య యః పఠేన్మంగళాష్టకమ్ ।",
        },
        {
          title: "సర్వార్థ సిద్ధి మాప్నోతి స సాయుజ్యం తతః పరమ్ ॥ 9 ॥",
        },
        {
          title: "",
        },
        {
          title: "|| ఇతి శ్రీ శివ మంగళాష్టకం సంపూర్ణం ||",
        },
        {
          title: "",
        },
      ],
      title: "శ్రీ శివ మంగళాష్టకం",
      // img: require('../imgs/astakamulu_images/sivastakam.png'),
    },

    {
      CatID: [
        // {
        //   title: "#శ్రీ శివ చాలీసా",
        // },
        // {
        //   title: "",
        // },
        {
          title: <span class='highlight'>దోహా :</span>,
        },
        {
          title: "",
        },
        {
          title: "జయ గణేశ గిరిజాసువన మంగల మూల సుజాన ।",
        },
        {
          title: "కహత అయోధ్యాదాస తుమ దే-ఉ అభయ వరదాన ॥",
        },
        {
          title: "",
        },
        {
          title: <span class='highlight'>చౌపాయీ:</span>,
        },
        {
          title: "",
        },
        {
          title: "జయ గిరిజాపతి దీనదయాలా ।",
        },
        {
          title: "సదా కరత సంతన ప్రతిపాలా ॥",
        },
        {
          title: "",
        },
        {
          title: "భాల చంద్రమా సోహత నీకే ।",
        },
        {
          title: "కానన కుండల నాగ ఫనీ కే ॥",
        },
        {
          title: "",
        },
        {
          title: "అంగ గౌర శిర గంగ బహాయే ।",
        },
        {
          title: "ముండమాల తన క్షార లగాయే ॥",
        },
        {
          title: "",
        },
        {
          title: "వస్త్ర ఖాల బాఘంబర సోహే ।",
        },
        {
          title: "ఛవి కో దేఖి నాగ మన మోహే ॥",
        },
        {
          title: "",
        },
        {
          title: "మైనా మాతు కి హవే దులారీ ।",
        },
        {
          title: "వామ అంగ సోహత ఛవి న్యారీ ॥",
        },
        {
          title: "",
        },
        {
          title: "కర త్రిశూల సోహత ఛవి భారీ ।",
        },
        {
          title: "కరత సదా శత్రున క్షయకారీ ॥",
        },
        {
          title: "",
        },
        {
          title: "నందీ గణేశ సోహైం తహం కైసే ।",
        },
        {
          title: "సాగర మధ్య కమల హైం జైసే ॥",
        },
        {
          title: "",
        },
        {
          title: "కార్తిక శ్యామ ఔర గణరా-ఊ ।",
        },
        {
          title: "యా ఛవి కౌ కహి జాత న కా-ఊ ॥",
        },
        {
          title: "",
        },
        {
          title: "దేవన జబహీం జాయ పుకారా ।",
        },
        {
          title: "తబహిం దుఖ ప్రభు ఆప నివారా ॥",
        },
        {
          title: "",
        },
        {
          title: "కియా ఉపద్రవ తారక భారీ ।",
        },
        {
          title: "దేవన సబ మిలి తుమహిం జుహారీ ॥",
        },
        {
          title: "",
        },
        {
          title: "తురత షడానన ఆప పఠాయౌ ।",
        },
        {
          title: "లవ నిమేష మహం మారి గిరాయౌ ॥",
        },
        {
          title: "",
        },
        {
          title: "ఆప జలంధర అసుర సంహారా ।",
        },
        {
          title: "సుయశ తుమ్హార విదిత సంసారా ॥",
        },
        {
          title: "",
        },
        {
          title: "త్రిపురాసుర సన యుద్ధ మచాయీ ।",
        },
        {
          title: "తబహిం కృపా కర లీన బచాయీ ॥",
        },
        {
          title: "",
        },
        {
          title: "కియా తపహిం భాగీరథ భారీ ।",
        },
        {
          title: "పురబ ప్రతిజ్ఞా తాసు పురారీ ॥",
        },
        {
          title: "",
        },
        {
          title: "దానిన మహం తుమ సమ కో-ఉ నాహీమ్ ।",
        },
        {
          title: "సేవక స్తుతి కరత సదాహీమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "వేద మాహి మహిమా తుమ గాయీ ।",
        },
        {
          title: "అకథ అనాది భేద నహీం పాయీ ॥",
        },
        {
          title: "",
        },
        {
          title: "ప్రకటే ఉదధి మంథన మేం జ్వాలా ।",
        },
        {
          title: "జరత సురాసుర భే విహాలా ॥",
        },
        {
          title: "",
        },
        {
          title: "కీన్హ దయా తహం కరీ సహాయీ ।",
        },
        {
          title: "నీలకంఠ తబ నామ కహాయీ ॥",
        },
        {
          title: "",
        },
        {
          title: "పూజన రామచంద్ర జబ కీన్హామ్ ।",
        },
        {
          title: "జీత కే లంక విభీషణ దీన్హా ॥",
        },
        {
          title: "",
        },
        {
          title: "సహస కమల మేం హో రహే ధారీ ।",
        },
        {
          title: "కీన్హ పరీక్షా తబహిం త్రిపురారీ ॥",
        },
        {
          title: "",
        },
        {
          title: "ఏక కమల ప్రభు రాఖే-ఉ జోయీ ।",
        },
        {
          title: "కమల నయన పూజన చహం సోయీ ॥",
        },
        {
          title: "",
        },
        {
          title: "కఠిన భక్తి దేఖీ ప్రభు శంకర ।",
        },
        {
          title: "భయే ప్రసన్న దిఏ ఇచ్ఛిత వర ॥",
        },
        {
          title: "",
        },
        {
          title: "జయ జయ జయ అనంత అవినాశీ ।",
        },
        {
          title: "కరత కృపా సబకే ఘట వాసీ ॥",
        },
        {
          title: "",
        },
        {
          title: "దుష్ట సకల నిత మోహి సతావైమ్ ।",
        },
        {
          title: "భ్రమత రహౌం మోహే చైన న ఆవైమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "త్రాహి త్రాహి మైం నాథ పుకారో ।",
        },
        {
          title: "యహ అవసర మోహి ఆన ఉబారో ॥",
        },
        {
          title: "",
        },
        {
          title: "లే త్రిశూల శత్రున కో మారో ।",
        },
        {
          title: "సంకట సే మోహిం ఆన ఉబారో ॥",
        },
        {
          title: "",
        },
        {
          title: "మాత పితా భ్రాతా సబ కోయీ ।",
        },
        {
          title: "సంకట మేం పూఛత నహిం కోయీ ॥",
        },
        {
          title: "",
        },
        {
          title: "స్వామీ ఏక హై ఆస తుమ్హారీ ।",
        },
        {
          title: "ఆయ హరహు మమ సంకట భారీ ॥",
        },
        {
          title: "",
        },
        {
          title: "ధన నిర్ధన కో దేత సదా హీ ।",
        },
        {
          title: "జో కోయీ జాంచే సో ఫల పాహీమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "అస్తుతి కేహి విధి కరోం తుమ్హారీ ।",
        },
        {
          title: "క్షమహు నాథ అబ చూక హమారీ ॥",
        },
        {
          title: "",
        },
        {
          title: "శంకర హో సంకట కే నాశన ।",
        },
        {
          title: "మంగల కారణ విఘ్న వినాశన ॥",
        },
        {
          title: "",
        },
        {
          title: "యోగీ యతి ముని ధ్యాన లగావైమ్ ।",
        },
        {
          title: "శారద నారద శీశ నవావైమ్ ॥",
        },
        {
          title: "",
        },
        {
          title: "నమో నమో జయ నమః శివాయ ।",
        },
        {
          title: "సుర బ్రహ్మాదిక పార న పాయ ॥",
        },
        {
          title: "",
        },
        {
          title: "జో యహ పాఠ కరే మన లాయీ ।",
        },
        {
          title: "తా పర హోత హైం శంభు సహాయీ ॥",
        },
        {
          title: "",
        },
        {
          title: "రనియాం జో కోయీ హో అధికారీ ।",
        },
        {
          title: "పాఠ కరే సో పావన హారీ ॥",
        },
        {
          title: "",
        },
        {
          title: "పుత్ర హోన కీ ఇచ్ఛా జోయీ ।",
        },
        {
          title: "నిశ్చయ శివ ప్రసాద తేహి హోయీ ॥",
        },
        {
          title: "",
        },
        {
          title: "పండిత త్రయోదశీ కో లావే ।",
        },
        {
          title: "ధ్యాన పూర్వక హోమ కరావే ॥",
        },
        {
          title: "",
        },
        {
          title: "త్రయోదశీ వ్రత కరై హమేశా ।",
        },
        {
          title: "తన నహిం తాకే రహై కలేశా ॥",
        },
        {
          title: "",
        },
        {
          title: "ధూప దీప నైవేద్య చఢావే ।",
        },
        {
          title: "శంకర సమ్ముఖ పాఠ సునావే ॥",
        },
        {
          title: "",
        },
        {
          title: "జన్మ జన్మ కే పాప నసావే ।",
        },
        {
          title: "అంత ధామ శివపుర మేం పావే ॥",
        },
        {
          title: "",
        },
        {
          title: "కహైం అయోధ్యాదాస ఆస తుమ్హారీ ।",
        },
        {
          title: "జాని సకల దుఖ హరహు హమారీ ॥",
        },
        {
          title: "",
        },
        {
          title: <span class='highlight'>దోహా</span>,
        },
        {
          title: "",
        },
        {
          title: "నిత నేమ ఉఠి ప్రాతఃహీ పాఠ కరో చాలీస ।",
        },
        {
          title: "తుమ మేరీ మనకామనా పూర్ణ కరో జగదీశ ॥",
        },
        {
          title: "",
        },
      ],
      title: "శ్రీ శివ చాలీసా",
      // img: require('../imgs/astakamulu_images/sivastakam.png'),
    },
  ],
};
export default astakamuluData;
