import React, { useState } from 'react'
import Adhiparvam from './adhiparvam'

const Mahabharatam = () => {
    const [leftSticky, setLeftSticky] = useState(true);
    const [rightSticky, setRightSticky] = useState(true);
    return (
        <div>
            <div>
                 <Adhiparvam/>
            </div>
        </div>
    )
}

export default Mahabharatam