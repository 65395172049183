const LocalData = {

  FestivalUpdated: [
    [
      {
        day: [
          {
            Date: "బుధవారము, 01 జనవరి 2025",
            Festival: "ఆంగ్ల సంవత్సరాది, చంద్రోదయం",
          },
          {
            Date: "గురువారము, 02 జనవరి 2025",
            Festival: "ప్రపంచ ప్రకృతి దినోత్సవం",
          },
          {
            Date: "శుక్రవారము, 03 జనవరి 2025",
            Festival: "చతుర్థి వ్రతం",
          },
          {
            Date: "ఆదివారము, 05 జనవరి 2025",
            Festival: "స్కంద షష్ఠి",
          },
          {
            Date: "సోమవారము, 06 జనవరి 2025",
            Festival: "ఎపిఫని",
          },
          {
            Date: "మంగళవారము, 07 జనవరి 2025",
            Festival: "దుర్గా అష్టమి వ్రతం",
          },
          {
            Date: "శుక్రవారము, 10 జనవరి 2025",
            Festival: "పుష్యము పుత్రాద ఏకాదశీ, కుర్మా ద్వాదశి, ఉత్తరాషాడ కార్తె, ముక్కోటి ఏకాదశి",
          },
          {
            Date: "శనివారము, 11 జనవరి 2025",
            Festival: "శని త్రయోదశి, ప్రదోష్ వ్రతం",
          },
          {
            Date: "ఆదివారము, 12 జనవరి 2025",
            Festival: "నేషనల్ యూత్ డే, స్వామి వివేకానంద జయంతి",
          },
          {
            Date: "సోమవారము, 13 జనవరి 2025",
            Festival: "భోగి, పౌర్ణమి, శ్రీ సత్య నారాయణ పూజ",
          },
          {
            Date: "మంగళవారము, 14 జనవరి 2025",
            Festival: "మకర సంక్రాంతి, పొంగల్, ఉత్తరాయణ పుణ్య కాలం ప్రారంభం, హజారత్ అలీ జయంతి",
          },
          {
            Date: "బుధవారము, 15 జనవరి 2025",
            Festival: "కనుమ",
          },
          {
            Date: "గురువారము, 16 జనవరి 2025",
            Festival: "ముక్కనుము, బొమ్మల నోము",
          },
          {
            Date: "శుక్రవారము, 17 జనవరి 2025",
            Festival: "లంబోదర సంకష్టహర చతుర్థి",
          },
          {
            Date: "శనివారము, 18 జనవరి 2025",
            Festival: "త్యాగరాజ స్వామి ఆరాధన",
          },
          {
            Date: "మంగళవారము, 21 జనవరి 2025",
            Festival: "భాను సప్తమి",
          },
          {
            Date: "గురువారము, 23 జనవరి 2025",
            Festival: "శ్రావణ కార్తె, నేతాజీ జయంతి",
          },
          {
            Date: "శనివారము, 25 జనవరి 2025",
            Festival: "షట్తిలా ఏకాదశీ",
          },
          {
            Date: "ఆదివారము, 26 జనవరి 2025",
            Festival: "రిపబ్లిక్ డే",
          },
          {
            Date: "సోమవారము, 27 జనవరి 2025",
            Festival: "సోమా ప్రదోష్ వ్రతం, మాస శివరాత్రి",
          },
          {
            Date: "మంగళవారము, 28 జనవరి 2025",
            Festival: "లాలా లజపత్ రాయ్ జయంతి",
          },
          {
            Date: "బుధవారము, 29 జనవరి 2025",
            Festival: "అమావాస్య,  చొల్లంగి అమావాస్య",
          },
          {
            Date: "గురువారము, 30 జనవరి 2025",
            Festival: "చంద్రోదయం, మాఘ గుప్త నవరాత్రి, మహాత్మా గాంధీ వర్దంతి",
          },
          {
            Date: "శుక్రవారము, 31 జనవరి 2025",
            Festival: "అవతార్ మెహర్ బాబా అమర తిథి",
          },
        ],
      },
      {
        day: [
          {
            Date: "శనివారము, 1 ఫిబ్రవరి 2025",
            Festival: "గణేష్ చతుర్థి, చతుర్థి వ్రతం, శ్రీ మార్కండేయ మహర్షి జయంతి",
          },
          {
            Date: "సోమవారము, 3 ఫిబ్రవరి 2025",
            Festival: "సరస్వతీ పూజ, సోమవార వ్రతం, స్కంద షష్ఠి",
          },
          {
            Date: "మంగళవారము, 4 ఫిబ్రవరి 2025",
            Festival: "రధసప్తమి, ప్రపంచ క్యాన్సర్ రోజు",
          },
          {
            Date: "బుధవారము, 5 ఫిబ్రవరి 2025",
            Festival: "దుర్గాష్టమి వ్రతం, బుద్ధ అష్టమి, బీష్మాష్టమి",
          },
          {
            Date: "గురువారము, 6 ఫిబ్రవరి 2025",
            Festival: "ధనిష్ఠ కార్తె, మధ్వ నవమి",
          },
          {
            Date: "శనివారము, 8 ఫిబ్రవరి 2025",
            Festival: "జయ ఏకాదశీ",
          },
          {
            Date: "ఆదివారము, 9 ఫిబ్రవరి 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
          {
            Date: "బుధవారము, 12 ఫిబ్రవరి 2025",
            Festival: "పౌర్ణమి, మాఘ పౌర్ణమి, పౌర్ణమి వ్రతం, కుంభ సంక్రమణం, సింధూస్నానం, శ్రీ సత్యనారాయణ స్వామి వ్రత",
          },
          {
            Date: "శుక్రవారము, 14 ఫిబ్రవరి 2025",
            Festival: "షబ్-ఎ-బరాత్, ప్రేమికుల రోజు",
          },
          {
            Date: "ఆదివారము, 16 ఫిబ్రవరి 2025",
            Festival: "ద్విజప్రియ సంకష్టహర చతుర్థి",
          },
          {
            Date: "బుధవారము, 19 ఫిబ్రవరి 2025",
            Festival: "శతభిష కార్తె",
          },
          {
            Date: "ఆదివారము, 23 ఫిబ్రవరి 2025",
            Festival: "స్వామి దయానంద సరస్వతి జయంతి",
          },
          {
            Date: "సోమవారము, 24 ఫిబ్రవరి 2025",
            Festival: "విజయ ఏకాదశీ",
          },
          {
            Date: "మంగళవారము, 25 ఫిబ్రవరి 2025",
            Festival: "ప్రదోష్ వ్రతం, మెహర్ బాబా జయంతి",
          },
          {
            Date: "బుధవారము, 26 ఫిబ్రవరి 2025",
            Festival: "మహా శివరాత్రి, మాస శివరాత్రి",
          },
          {
            Date: "గురువారము, 27 ఫిబ్రవరి 2025",
            Festival: "అమావాస్య",
          },
          {
            Date: "శుక్రవారము, 28 ఫిబ్రవరి 2025",
            Festival: "జాతీయ సైన్స్ దినోత్సవం",
          },
        ],
      },
      {
        day: [
          {
            Date: "శనివారము, 1 మార్చి 2025",
            Festival: "యాదాద్రి శ్రీ లక్ష్మీ నరసింహ స్వామి వారి బ్రహ్మోత్సవాలు ప్రారంభం, చంద్రోదయం, రంజాన్ ఉపవాస దీక్షలు ప్రారంభం",
          },
          {
            Date: "సోమవారము, 3 మార్చి 2025",
            Festival: "సోమవార వ్రతం, చతుర్థి వ్రతం",
          },
          {
            Date: "మంగళవారము, 4 మార్చి 2025",
            Festival: "పూర్వాభాద్ర కార్తె",
          },
          {
            Date: "బుధవారము, 5 మార్చి 2025",
            Festival: "స్కంద షష్ఠి",
          },
          {
            Date: "శుక్రవారము, 7 మార్చి 2025",
            Festival: "దుర్గాష్టమి వ్రతం",
          },
          {
            Date: "శనివారము, 8 మార్చి 2025",
            Festival: "యాదాద్రి శ్రీ లక్ష్మీ నరసింహ స్వామి వారి బ్రహ్మోత్సవాలు తిరు కల్యాణం, అంతర్జాతీయ మహిళా దినోత్సవం",
          },
          {
            Date: "సోమవారము, 10 మార్చి 2025",
            Festival: "తిరుమల శ్రీవారి తెప్పోత్సవం ప్రారంభం, కోరుకొండ తీర్థం, ఆమలకీ ఏకాదశీ",
          },
          {
            Date: "మంగళవారము, 11 మార్చి 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
          {
            Date: "గురువారము, 13 మార్చి 2025",
            Festival: "హోలికా దహన్,  శ్రీ సత్య నారాయణ పూజ, పౌర్ణమి వ్రతం",
          },
          {
            Date: "శుక్రవారము, 14 మార్చి 2025",
            Festival: "హోళీ, పౌర్ణమి, శ్రీ లక్ష్మీ జయంతి, మీన సంక్రమణం, తిరుమల శ్రీవారి తెప్పోత్సవం సమాప్తి",
          },
          {
            Date: "ఆదివారము, 16 మార్చి 2025",
            Festival: "పొట్టి శ్రీరాములు జయంతి",
          },
          {
            Date: "సోమవారము, 17 మార్చి 2025",
            Festival: "బాలచంద్ర సంకష్టహర చతుర్థి, ఉత్తరాబాధ్ర కార్తె",
          },
          {
            Date: "మంగళవారము, 18 మార్చి 2025",
            Festival: "అంగార్కి చతుర్థి, సంకటహర చతుర్థి",
          },
          {
            Date: "బుధవారము, 19 మార్చి 2025",
            Festival: "రంగ పంచమి",
          },
          {
            Date: "శుక్రవారము, 21 మార్చి 2025",
            Festival: "శీతల సప్తమి, షహదత్ హజ్రత్ అలీ",
          },
          {
            Date: "మంగళవారము, 25 మార్చి 2025",
            Festival: "పాపమొచనీ ఏకాదశీ",
          },
          {
            Date: "బుధవారము, 26 మార్చి 2025",
            Festival: "వైష్ణవ పాపమొచనీ ఏకాదశీ",
          },
          {
            Date: "గురువారము, 27 మార్చి 2025",
            Festival: "మాస శివరాత్రి, ప్రదోష్ వ్రతం",
          },
          {
            Date: "శుక్రవారము, 28 మార్చి 2025",
            Festival: "షబ్-ఎ-ఖదర్(లైలతుల్ ఖదర్), జుముఅతుల్-విదా",
          },
          {
            Date: "శనివారము, 29 మార్చి 2025",
            Festival: "అమావాస్య",
          },
          {
            Date: "ఆదివారము, 30 మార్చి 2025",
            Festival: "ఉగాది, శ్రీ విశ్వావసు నామ సంవత్సరం, చంద్రోదయం, వసంత నవరాత్రులు ప్రారంభం",
          },
          {
            Date: "సోమవారము, 31 మార్చి 2025",
            Festival: "గౌరీ పూజ, రంజాన్, ఈద్ అల్ ఫితర్, సోమవార వ్రతం, మత్స్య జయంతి, రేవతి కార్తె",
          },
        ],
      },
      {
        day: [
          {
            Date: "మంగళవారము, 01 ఏప్రిల్ 2025",
            Festival: "ఏప్రిల్ ఫూల్, చతుర్థి వ్రతం, వార్షిక ఖాతాల ముగింపు (బ్యాంకు సెలవు)",
          },
          {
            Date: "బుధవారము, 02 ఏప్రిల్ 2025",
            Festival: "వసంత పంచమి",
          },
          {
            Date: "గురువారము, 03 ఏప్రిల్ 2025",
            Festival: "స్కంద షష్ఠి",
          },
          {
            Date: "శనివారము, 05 ఏప్రిల్ 2025",
            Festival: "దుర్గాష్టమి వ్రతం, బాబూ జగజ్జీవన్ రామ్ జయంతి",
          },
          {
            Date: "ఆదివారము, 06 ఏప్రిల్ 2025",
            Festival: "శ్రీరామ నవమి, తాటాకు ఆదివారం",
          },
          {
            Date: "సోమవారము, 07 ఏప్రిల్ 2025",
            Festival: "ధర్మరాజ దశమి, ప్రపంచ ఆరోగ్య దినోత్సవం",
          },
          {
            Date: "మంగళవారము, 08 ఏప్రిల్ 2025",
            Festival: "వైష్ణవ కామద ఏకాదశీ",
          },
          {
            Date: "గురువారము, 10 ఏప్రిల్ 2025",
            Festival: "ప్రదోష్ వ్రతం, అనంగ్ త్రయోదశి, మహావీర్ జయంతి, పస్కా పండుగ",
          },
          {
            Date: "శుక్రవారము, 11 ఏప్రిల్ 2025",
            Festival: "జ్యోతిరావు ఫూలే జయంతి",
          },
          {
            Date: "శనివారము, 12 ఏప్రిల్ 2025",
            Festival: "చైత్రము పూర్ణిమ, హనుమాన్ జయంతి, శ్రీ సత్యనారాయణ పూజ, పౌర్ణమి వ్రతం",
          },
          {
            Date: "ఆదివారము, 13 ఏప్రిల్ 2025",
            Festival: "అశ్వని కార్తె",
          },
          {
            Date: "సోమవారము, 14 ఏప్రిల్ 2025",
            Festival: "మేష సంక్రమణం, అంబేద్కర్ జయంతి",
          },
          {
            Date: "బుధవారము, 16 ఏప్రిల్ 2025",
            Festival: "వికట్ సంకష్టహర చతుర్థి",
          },
          {
            Date: "శుక్రవారము, 18 ఏప్రిల్ 2025",
            Festival: "గుడ్ ఫ్రైడే",
          },
          {
            Date: "ఆదివారము, 20 ఏప్రిల్ 2025",
            Festival: "ఈస్టర్",
          },
          {
            Date: "మంగళవారము, 22 ఏప్రిల్ 2025",
            Festival: "ఎర్త్ డే",
          },
          {
            Date: "గురువారము, 24 ఏప్రిల్ 2025",
            Festival: "వరుతిని ఏకాదశీ",
          },
          {
            Date: "శుక్రవారము, 25 ఏప్రిల్ 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
          {
            Date: "శనివారము, 26 ఏప్రిల్ 2025",
            Festival: "మాస శివరాత్రి",
          },
          {
            Date: "ఆదివారము, 27 ఏప్రిల్ 2025",
            Festival: "భరణి కార్తె, అమావాస్య",
          },
          {
            Date: "సోమవారము, 28 ఏప్రిల్ 2025",
            Festival: "సోమవార వ్రతం, చంద్రోదయం",
          },
          {
            Date: "మంగళవారము, 29 ఏప్రిల్ 2025",
            Festival: "పరశురామ జయంతి",
          },
          {
            Date: "బుధవారము, 30 ఏప్రిల్ 2025",
            Festival: "సింహాచల చందనోత్సవం, అక్షయ తృతీయ, శ్రీ శ్రీ జయంతి, బసవ జయంతి",
          }
        ],
      },
      {
        day: [
          {
            Date: "గురువారము, 01 మే 2025",
            Festival: "చతుర్థి వ్రతం, అంతర్జాతీయ కార్మిక దినోత్సవం",
          },
          {
            Date: "శుక్రవారము, 02 మే 2025",
            Festival: "విశ్వజ్ఞాని ఆది శంకరాచార్యుల జయంతి, స్కంద షష్ఠి, శ్రీరామానుజ జయంతి",
          },
          {
            Date: "సోమవారము, 05 మే 2025",
            Festival: "దుర్గా అష్టమి వ్రతం",
          },
          {
            Date: "బుధవారము, 07 మే 2025",
            Festival: "శ్రీ పోతులూరి వీరబ్రహ్మేంద్ర స్వామి ఆరాధన, వాసవీ కన్యకా పరమేశ్వరి జయంతి, రవీంద్రనాథ్ ఠాగూర్ జయంతి",
          },
          {
            Date: "గురువారము, 08 మే 2025",
            Festival: "మోహినీ ఏకాదశీ, పరశురామ ద్వాదశి, శ్రీ అన్నవరం సత్యదేవుని కళ్యాణం",
          },
          {
            Date: "శుక్రవారము, 09 మే 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
          {
            Date: "ఆదివారము, 11 మే 2025",
            Festival: "కృత్తికా కార్తె, నృసింహ జయంతి, అంతర్జాతీయ మాతృ దినోత్సవం",
          },
          {
            Date: "సోమవారము, 12 మే 2025",
            Festival: "బుద్ధ పూర్ణిమ, శ్రీ సత్యనారాయణ స్వామి పూజ, పౌర్ణమి వ్రతం, పౌర్ణమి, శ్రీ కూర్మ జయంతి, వైశాఖ పూర్ణిమ, చైత్ర పూర్ణిమ, అన్నమయ్య జయంతి ",
          },
          {
            Date: "గురువారము, 15 మే 2025",
            Festival: "వృషభ సంక్రాంతి",
          },
          {
            Date: "శుక్రవారము, 16 మే 2025",
            Festival: "ఏకదంత సంకష్టహర చతుర్థి",
          },
          {
            Date: "శుక్రవారము, 23 మే 2025",
            Festival: "అపర ఏకాదశీ",
          },
          {
            Date: "శనివారము, 24 మే 2025",
            Festival: "ప్రదోష్ వ్రతం, శని త్రయోదశి",
          },
          {
            Date: "ఆదివారము, 25 మే 2025",
            Festival: "రోహిణి కార్తె, మాస శివరాత్రి",
          },
          {
            Date: "మంగళవారము, 27 మే 2025",
            Festival: "అమావాస్య",
          },
          {
            Date: "బుధవారము, 28 మే 2025",
            Festival: "చంద్రోదయం",
          },
          {
            Date: "శుక్రవారము, 30 మే 2025",
            Festival: "చతుర్థి వ్రతం",
          },
          {
            Date: "శనివారము, 31 మే 2025",
            Festival: "శీతల షష్ఠి",
          },
        ],
      },
      {
        day: [
          {
            Date: "ఆదివారము, 01 జూన్ 2025",
            Festival: "స్కంద షష్ఠి",
          },
          {
            Date: "సోమవారము, 02 జూన్ 2025",
            Festival: "సోమవార వ్రతం, తెలంగాణ అవతరణ దినోత్సవం",
          },
          {
            Date: "మంగళవారము, 03 జూన్ 2025",
            Festival: "దుర్గా అష్టమి వ్రతం, వృషభ వ్రతం",
          },
          {
            Date: "గురువారము, 05 జూన్ 2025",
            Festival: "ప్రపంచ పర్యావరణ దినోత్సవము, దశపాపహర దశమి",
          },
          {
            Date: "శుక్రవారము, 06 జూన్ 2025",
            Festival: "గాయత్రీ జయంతి, నిర్జల ఏకాదశీ",
          },
          {
            Date: "శనివారము, 07 జూన్ 2025",
            Festival: "రామ లక్ష్మణ ద్వాదశి, బక్రీదు",
          },
          {
            Date: "ఆదివారము, 08 జూన్ 2025",
            Festival: "ప్రదోష్ వ్రతం, మృగశిర కార్తె, తిరుమల శ్రీవారి జ్యేష్టాభిషేకం ప్రారంభం",
          },
          {
            Date: "శనివారము, 10 జూన్ 2025",
            Festival: "శ్రీ సత్యనారాయణ స్వామి పూజ, పౌర్ణమి వ్రతం, వట సావిత్రి పూర్ణిమ, తిరుమల శ్రీవారి జ్యేష్టాభిషేకం సమాప్తి",
          },
          {
            Date: "బుధవారము, 11 జూన్ 2025",
            Festival: "ఏరువాక పౌర్ణమి, పౌర్ణమి",
          },
          {
            Date: "శనివారము, 14 జూన్ 2025",
            Festival: "కృష్ణపింగళా సంకష్టహర చతుర్థి",
          },
          {
            Date: "ఆదివారము, 15 జూన్ 2025",
            Festival: "ఫాదర్స్ డే, మిథున సంక్రమణం, ఈద్ అల్-గదీర్",
          },
          {
            Date: "బుధవారము, 18 జూన్ 2025",
            Festival: "బుద్ధ అష్టమి",
          },
          {
            Date: "ఆదివారము, 22 జూన్ 2025",
            Festival: "ఆరుద్ర కార్తె, యోగిని ఏకాదశీ",
          },
          {
            Date: "సోమవారము, 23 జూన్ 2025",
            Festival: "ప్రదోష్ వ్రతం, సోమ ప్రదోష వ్రతం, మాస శివరాత్రి",
          },
          {
            Date: "బుధవారము, 25 జూన్ 2025",
            Festival: "అమావాస్య",
          },
          {
            Date: "గురువారము, 26 జూన్ 2025",
            Festival: "చంద్రోదయం, ఆషాఢ గుప్త నవరాత్రి",
          },
          {
            Date: "శుక్రవారము, 27 జూన్ 2025",
            Festival: "పూరి జగన్నాథ స్వామి రథోత్సవం, ఇస్లామీయ సంవత్సరాది",
          },
          {
            Date: "శనివారము, 28 జూన్ 2025",
            Festival: "చతుర్థి వ్రతం",
          },
          {
            Date: "ఆదివారము, 29 జూన్ 2025",
            Festival: "బోనాలు ప్రారంభం",
          },
          {
            Date: "సోమవారము, 30 జూన్ 2025",
            Festival: "స్కంద పంచమి, కుమార షష్టి, సోమవార వ్రతం",
          },
        ],
      },
      {
        day: [
          {
            Date: "మంగళవారము, 01 జూలై 2025",
            Festival: "కుసుమహర జయంతి, స్కంద షష్ఠి",
          },
          {
            Date: "బుధవారము, 02 జూలై 2025",
            Festival: "బుద్ధ అష్టమి",
          },
          {
            Date: "గురువారము, 03 జూలై 2025",
            Festival: "దుర్గా అష్టమి వ్రతం, సెయింట్ థామస్ రోజు",
          },
          {
            Date: "శుక్రవారము, 04 జూలై 2025",
            Festival: "అల్లూరి సీతారామరాజు జయంతి",
          },
          {
            Date: "శనివారము, 05 జూలై 2025",
            Festival: "పునర్వసు కార్తె",
          },
          {
            Date: "ఆదివారము, 06 జూలై 2025",
            Festival: "బోనాలు, చాతుర్మాస్య గోపద్మ వ్రతారంభం, దేవశయని ఏకాదశి / తొలి ఏకాదశి, మొహర్రం",
          },
          {
            Date: "సోమవారము, 07 జూలై 2025",
            Festival: "వాసుదేవ ద్వాదశి",
          },
          {
            Date: "మంగళవారము, 08 జూలై 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
          {
            Date: "గురువారము, 10 జూలై 2025",
            Festival: "వ్యాస పూజ, గురు పూర్ణిమ, శ్రీ సత్యనారాయణ స్వామి పూజ, పౌర్ణమి వ్రతం, పౌర్ణమి",
          },
          {
            Date: "శుక్రవారము, 11 జూలై 2025",
            Festival: "మంగళ గౌరీ వ్రతం, ప్రపంచ జనాభా దినోత్సవం",
          },
          {
            Date: "శనివారము, 12 జూలై 2025",
            Festival: "చాతుర్మాస్య ద్వితీయ అశూన్య శ‌య‌న వ్ర‌తం",
          },
          {
            Date: "ఆదివారము, 13 జూలై 2025",
            Festival: "బోనాలు",
          },
          {
            Date: "సోమవారము, 14 జూలై 2025",
            Festival: "గజానన సంకష్టహర చతుర్థి",
          },
          {
            Date: "బుధవారము, 16 జూలై 2025",
            Festival: "కర్కాటక సంక్రమణం, దక్షిణాయనం ప్రారంభం",
          },
          {
            Date: "ఆదివారము, 20 జూలై 2025",
            Festival: "పుష్యమి కార్తె, బోనాలు",
          },
          {
            Date: "సోమవారము, 21 జూలై 2025",
            Festival: "కామిక ఏకాదశీ, బోనాలు",
          },
          {
            Date: "మంగళవారము, 22 జూలై 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
          {
            Date: "బుధవారము, 23 జూలై 2025",
            Festival: "మాస శివరాత్రి",
          },
          {
            Date: "గురువారము, 24 జూలై 2025",
            Festival: "అమావాస్య",
          },
          {
            Date: "శుక్రవారము, 25 జూలై 2025",
            Festival: "చంద్రోదయం",
          },
          {
            Date: "శనివారము, 26 జూలై 2025",
            Festival: "ముహర్రం ముగుస్తుంది",
          },
          {
            Date: "సోమవారము, 28 జూలై 2025",
            Festival: "చతుర్థి వ్రతం, సోమవార వ్రతం",
          },
          {
            Date: "మంగళవారము, 29 జూలై 2025",
            Festival: "గరుడ పంచమి, నాగ పంచమి",
          },
          {
            Date: "బుధవారము, 30 జూలై 2025",
            Festival: "కల్కి జయంతి, స్కంద షష్ఠి",
          },
        ],
      },
      {
        day: [
          {
            Date: "శుక్రవారము, 01 ఆగష్టు 2025",
            Festival: "దుర్గా అష్టమి వ్రతం",
          },
          {
            Date: "ఆదివారము, 03 ఆగష్టు 2025",
            Festival: "ఆశ్లేష కార్తె, తిరుమల శ్రీవారి పవిత్రోత్సవ ప్రారంభం, స్నేహితుల దినోత్సవం",
          },
          {
            Date: "మంగళవారము, 05 ఆగష్టు 2025",
            Festival: "శ్రావణ పుత్రదా ఏకాదశి",
          },
          {
            Date: "బుధవారము, 06 ఆగష్టు 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
          {
            Date: "గురువారము, 07 ఆగస్టు 2025",
            Festival: "తిరుమల శ్రీవారి తెప్పోత్సవం సమాప్తి",
          },
          {
            Date: "శుక్రవారము, 08 ఆగస్టు 2025",
            Festival: "వరలక్ష్మి వ్రతం",
          },
          {
            Date: "శనివారము, 09 ఆగష్టు 2025",
            Festival: "రక్షా బంధన్, జంద్యాల పూర్ణిమ, వైఖాసన హయగ్రీవ జయంతి, శ్రీ సత్య నారాయణ పూజ, పౌర్ణమి వ్రతం, పౌర్ణమి, శ్రావణ పౌర్ణమి",
          },
          {
            Date: "మంగళవారము, 12 ఆగస్టు 2025",
            Festival: "అంగారక సంకష్టి చతుర్థి, సంకష్టహర చతుర్థి",
          },
          {
            Date: "బుధవారము, 13 ఆగస్టు 2025",
            Festival: "రక్షా పంచమి",
          },
          {
            Date: "బుధవారము, 14 ఆగస్టు 2025",
            Festival: "బలరామ జయంతి",
          },
          {
            Date: "శుక్రవారము, 15 ఆగస్టు 2025",
            Festival: "భారత స్వాతంత్య్ర దినోత్సవం",
          },
          {
            Date: "శనివారము, 16 ఆగస్టు 2025",
            Festival: "శ్రీ కృష్ణ జన్మాష్టమి",
          },
          {
            Date: "ఆదివారము, 17 ఆగస్టు 2025",
            Festival: "మఖ కార్తె, సింహ సంక్రమణం",
          },
          {
            Date: "మంగళవారము, 19 ఆగస్టు 2025",
            Festival: "అజ ఏకాదశీ, ప్రపంచ ఛాయాచిత్ర దినోత్సవం",
          },
          {
            Date: "బుధవారము, 20 ఆగష్టు 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
          {
            Date: "గురువారము, 21 ఆగష్టు 2025",
            Festival: "మాస శివరాత్రి",
          },
          {
            Date: "శనివారము, 23 ఆగష్టు 2025",
            Festival: "పోలాల అమవాస్య, అమావాస్య",
          },
          {
            Date: "ఆదివారము, 24 ఆగష్టు 2025",
            Festival: "చంద్రోదయం",
          },
          {
            Date: "సోమవారము, 25 ఆగష్టు 2025",
            Festival: "సోమవార వ్రతం, వరాహ జయంతి",
          },
          {
            Date: "మంగళవారము, 26 ఆగష్టు 2025",
            Festival: "సామవేద ఉపకర్మ",
          },
          {
            Date: "బుధవారము, 27 ఆగష్టు 2025",
            Festival: "చతుర్థి వ్రతం, సిద్ధి వినాయక చవితి",
          },
          {
            Date: "గురువారము, 28 ఆగష్టు 2025",
            Festival: "ఋషి పంచమి",
          },
          {
            Date: "శుక్రవారము, 29 ఆగష్టు 2025",
            Festival: "స్కంద షష్ఠి",
          },
          {
            Date: "శనివారము, 30 ఆగష్టు 2025",
            Festival: "పుబ్బ కార్తె",
          },
          {
            Date: "ఆదివారము, 31 ఆగష్టు 2025",
            Festival: "దుర్గా అష్టమి వ్రతం, మహాలక్ష్మీ వ్రతం, రాధాష్టమి",
          },
        ],
      },
      {
        day: [
          {
            Date: "బుధవారము, 03 సెప్టెంబర్ 2025",
            Festival: "పార్శ్వ ఏకాదశి",
          },
          {
            Date: "గురువారము, 04 సెప్టెంబర్ 2025",
            Festival: "వామన జయంతి",
          },
          {
            Date: "శుక్రవారము, 05 సెప్టెంబర్ 2025",
            Festival: "ఉపాధ్యాయుల దినోత్సవం, మిలాద్ ఉన్ నబీ, ప్రదోష్ వ్రతం, ఓనం, గురు పూజ మహోత్సవం",
          },
          {
            Date: "శనివారము, 06 సెప్టెంబర్ 2025",
            Festival: "అనంత పద్మనాభ వ్రతం, వినాయక నిమజ్జనం",
          },
          {
            Date: "ఆదివారము, 07 సెప్టెంబర్ 2025",
            Festival: "శ్రీ సత్యనారాయణ పూజ, పౌర్ణమి వ్రతం, పౌర్ణమి",
          },
          {
            Date: "సోమవారము, 08 సెప్టెంబర్ 2025",
            Festival: "మహాలయ పక్ష ప్రారంభం",
          },
          {
            Date: "బుధవారము, 10 సెప్టెంబర్ 2025",
            Festival: "సంకటహర చతుర్థి ",
          },
          {
            Date: "గురువారము, 11 సెప్టెంబర్ 2025",
            Festival: "మహా భరణి",
          },
          {
            Date: "శనివారము, 13 సెప్టెంబర్ 2025",
            Festival: "ఉత్తర కార్తె",
          },
          {
            Date: "ఆదివారము, 14 సెప్టెంబర్ 2025",
            Festival: "మహాలక్ష్మి వ్రతం సమాప్తి",
          },
          {
            Date: "బుధవారము, 17 సెప్టెంబర్ 2025",
            Festival: "ఇందిరా ఏకాదశీ, విశ్వకర్మ జయంతి, కన్య సంక్రాంతి",
          },
          {
            Date: "గురువారము, 18 సెప్టెంబర్ 2025",
            Festival: "యతి మహాలయ",
          },
          {
            Date: "శుక్రవారము, 19 సెప్టెంబర్ 2025",
            Festival: "ప్రదోష్ వ్రతం, మాస శివరాత్రి, మాఘ స్మారక",
          },
          {
            Date: "ఆదివారము, 21 సెప్టెంబర్ 2025",
            Festival: "బతుకమ్మ ప్రారంభము, అమావాస్య, మహాలయ అమావాస్య",
          },
          {
            Date: "సోమవారము, 22 సెప్టెంబర్ 2025",
            Festival: "శరన్నవరాత్రులు, దేవీనవరాత్రులు ప్రారంభము, సోమవార వ్రతం",
          },
          {
            Date: "మంగళవారము, 23 సెప్టెంబర్ 2025",
            Festival: "చంద్రోదయం",
          },
          {
            Date: "గురువారము, 25 సెప్టెంబర్ 2025",
            Festival: "చతుర్థి వ్రతం",
          },
          {
            Date: "శుక్రవారము, 26 సెప్టెంబర్ 2025",
            Festival: "లలితా పంచమి",
          },
          {
            Date: "శనివారము, 27 సెప్టెంబర్ 2025",
            Festival: "ప్రపంచ పర్యాటక దినోత్సవం, హస్త కార్తె",
          },
          {
            Date: "ఆదివారము, 28 సెప్టెంబర్ 2025",
            Festival: "దుర్గాదేవి పూజ, స్కంద షష్ఠి",
          },
          {
            Date: "సోమవారము, 29 సెప్టెంబర్ 2025",
            Festival: "సద్దుల బతుకమ్మ పండుగ, సరస్వతి పూజా ప్రారంభం",
          },
          {
            Date: "మంగళవారము, 30 సెప్టెంబర్ 2025",
            Festival: "దుర్గాష్టమి వ్రతం, సరస్వతి పూజ, దుర్గాష్టమి",
          },
        ],
      },
      {
        day: [
          {
            Date: "బుధవారము, 01 అక్టోబర్ 2025",
            Festival: "మహా నవమి, సరస్వతి పూజ",
          },
          {
            Date: "గురువారము, 02 అక్టోబర్ 2025",
            Festival: "విజయ దశమి, మహ్మత్మాగాంధీ జయంతి, లాల్ బహదూర్ శాస్త్రి జయంతి",
          },
          {
            Date: "శుక్రవారము, 03 అక్టోబర్ 2025",
            Festival: "పాశాంకుశ ఏకాదశీ",
          },
          {
            Date: "శనివారము, 04 అక్టోబర్ 2025",
            Festival: "శని త్రయోదశి, ప్రదోష్ వ్రతం, ప్రపంచ వన్యప్రాణుల దినోత్సవం, యాజ్ దాహం",
          },
          {
            Date: "సోమవారము, 06 అక్టోబర్ 2025",
            Festival: "శ్రీ సత్య నారాయణ పూజ, పౌర్ణమి",
          },
          {
            Date: "మంగళవారము, 07 అక్టోబర్ 2025",
            Festival: "మహర్షి వాల్మీకి జయంతి, పౌర్ణమి",
          },
          {
            Date: "గురువారము, 09 అక్టోబర్ 2025",
            Festival: "అట్లతద్ది",
          },
          {
            Date: "శుక్రవారము, 10 అక్టోబర్ 2025",
            Festival: "వక్రతుండా సంకష్టహర చతుర్థి, చిత్త కార్తె, కర్వా చౌత్",
          },
          {
            Date: "శుక్రవారము, 17 అక్టోబర్ 2025",
            Festival: "రమా ఏకాదశీ, కావేరీ తులా స్నానం, తులా సంక్రమణం",
          },
          {
            Date: "శనివారము, 18 అక్టోబర్ 2025",
            Festival: "శని త్రయోదశి, ప్రదోష్ వ్రతం, ధంతేరాస్",
          },
          {
            Date: "ఆదివారము, 19 అక్టోబర్ 2025",
            Festival: "ధన్వంతరి జయంతి, నరక చతుర్దశి, ధన త్రయోదశి, మాస శివరాత్రి",
          },
          {
            Date: "మంగళవారము, 21 అక్టోబర్ 2025",
            Festival: "దీపావళి, అమావాస్య, కేదార గౌరీ వ్రతం",
          },
          {
            Date: "బుధవారము, 22 అక్టోబర్ 2025",
            Festival: "ఆకాశ దీపం ప్రారంభం, చంద్రోదయం, గోవర్ధన పూజ",
          },
          {
            Date: "గురువారము, 23 అక్టోబర్ 2025",
            Festival: "యమ ద్వితీయ, భగినీ హస్త భోజనం",
          },
          {
            Date: "శుక్రవారము, 24 అక్టోబర్ 2025",
            Festival: "స్వాతి కార్తె",
          },
          {
            Date: "శనివారము, 25 అక్టోబర్ 2025",
            Festival: "నాగుల చవితి, చతుర్థి వ్రతం",
          },
          {
            Date: "సోమవారము, 27 అక్టోబర్ 2025",
            Festival: "సోమవార వ్రతం, స్కంద షష్ఠి, సూర్య షష్ఠి",
          },
          {
            Date: "బుధవారము, 29 అక్టోబర్ 2025",
            Festival: "బుద్ధ అష్టమి",
          },
          {
            Date: "గురువారము, 30 అక్టోబర్ 2025",
            Festival: "గోపాష్టమి, దుర్గా అష్టమి వ్రతం",
          },
        ],
      },
      {
        day: [
          {
            Date: "శనివారము, 01 నవంబర్ 2025",
            Festival: "దేవుత్తన ఏకాదశీ, ప్రబోధిని ఏకాదశి, కార్తిక శుద్ధ ఏకాదశి",
          },
          {
            Date: "ఆదివారము, 02 నవంబర్ 2025",
            Festival: "యోగేశ్వర ద్వాదశి, తులసి వివాహం, క్షీరాబ్ది ద్వాదశి, కైశిక ద్వాదశి, చాతుర్మాస్య వ్రత సమాప్తి",
          },
          {
            Date: "సోమవారము, 03 నవంబర్ 2025",
            Festival: "ప్రదోష్ వ్రతం, సోమా ప్రదోష వ్రతం, విశ్వేశ్వర వ్రతం",
          },
          {
            Date: "బుధవారము, 05 నవంబర్ 2025",
            Festival: "శ్రీ సత్యనారాయణ పూజ, పౌర్ణమి, శ్రీ ఉమా‌మ‍హేశ్వర వ్రతం, పౌర్ణమి వ్రతం, జ్వాలా తోరణం, కార్తీక పౌర్ణమి, గురునానక్ జయంతి",
          },
          {
            Date: "గురువారము, 06 నవంబర్ 2025",
            Festival: "విశాఖ కార్తే",
          },
          {
            Date: "శనివారము, 08 నవంబర్ 2025",
            Festival: "గణాధిప సంకష్టహర చతుర్థి, సౌభాగ్య సుందరి తీజ్",
          },
          {
            Date: "బుధవారము, 12 నవంబర్ 2025",
            Festival: "బుద్ధ అష్టమి",
          },
          {
            Date: "శుక్రవారము, 14 నవంబర్ 2025",
            Festival: "బాలల దినోత్సవం, నెహ్రూ జయంతి",
          },
          {
            Date: "శనివారము, 15 నవంబర్ 2025",
            Festival: "ఉత్పన్న ఏకాదశీ",
          },
          {
            Date: "ఆదివారము, 16 నవంబర్ 2025",
            Festival: "వృశ్చిక సంక్రమణం",
          },
          {
            Date: "సోమవారము, 17 నవంబర్ 2025",
            Festival: "శబరిమల మండల కలాం ఆరంభం, ప్రదోష్ వ్రతం, సోమా ప్రదోష వ్రతం",
          },
          {
            Date: "మంగళవారము, 18 నవంబర్ 2025",
            Festival: "మాస శివరాత్రి",
          },
          {
            Date: "గురువారము, 20 నవంబర్ 2025",
            Festival: "అనురాధ కార్తె, అమావాస్య",
          },
          {
            Date: "శుక్రవారము, 21 నవంబర్ 2025",
            Festival: "చంద్రోదయం",
          },
          {
            Date: "ఆదివారము, 23 నవంబర్ 2025",
            Festival: "శ్రీ సత్యసాయి బాబా జయంతి",
          },
          {
            Date: "సోమవారము, 24 నవంబర్ 2025",
            Festival: "చతుర్థి వ్రతం, సోమవార వ్రతం",
          },
          {
            Date: "బుధవారము, 26 నవంబర్ 2025",
            Festival: "సుబ్రహ్మణ్య షష్ఠి, స్కంద షష్ఠి",
          },
          {
            Date: "శుక్రవారము, 28 నవంబర్ 2025",
            Festival: "దుర్గా అష్టమి వ్రతం",
          }
        ],
      },
      {
        day: [
          {
            Date: "సోమవారము, 01 డిసెంబర్ 2025",
            Festival: "మొక్షద ఏకాదశీ, ప్రపంచ ఎయిడ్స్ దినోత్సవం, గీతా జయంతి",
          },
          {
            Date: "మంగళవారము, 02 డిసెంబర్ 2025",
            Festival: "మత్స్య ద్వాదశి, ప్రదోష్ వ్రతం",
          },
          {
            Date: "బుధవారము, 03 డిసెంబర్ 2025",
            Festival: "జ్యేష్ఠ కార్తె, శ్రీ హనుమద్ర్వతం",
          },
          {
            Date: "గురువారము, 04 డిసెంబర్ 2025",
            Festival: "దత్త జయంతి, పౌర్ణమి వ్రతం, శ్రీ సత్య నారాయణ పూజ, పౌర్ణమి",
          },
          {
            Date: "ఆదివారము, 07 డిసెంబర్ 2025",
            Festival: "ఆఖురత సంకష్టహర చతుర్థి",
          },
          {
            Date: "శుక్రవారము, 12 డిసెంబర్ 2025",
            Festival: "బాలాజీ జయంతి",
          },
          {
            Date: "సోమవారము, 15 డిసెంబర్ 2025",
            Festival: "సఫల ఏకాదశీ, మూల కార్తె",
          },
          {
            Date: "మంగళవారము, 16 డిసెంబర్ 2025",
            Festival: "ధనుర్మాసం పూజ, ధనుస్సంక్రమణం",
          },
          {
            Date: "బుధవారము, 17 డిసెంబర్ 2025",
            Festival: "ప్రదోష్ వ్రతం",
          },
          {
            Date: "గురువారము, 18 డిసెంబర్ 2025",
            Festival: "మాస శివరాత్రి",
          },
          {
            Date: "శుక్రవారము, 19 డిసెంబర్ 2025",
            Festival: "అమావాస్య",
          },
          {
            Date: "ఆదివారము, 21 డిసెంబర్ 2025",
            Festival: "చంద్రోదయం",
          },
          {
            Date: "సోమవారము, 22 డిసెంబర్ 2025",
            Festival: "సోమవార వ్రతం",
          },
          {
            Date: "బుధవారము, 24 డిసెంబర్ 2025",
            Festival: "ప్రదోష్ వ్రతం, చతుర్థి వ్రతం, క్రిస్మస్ ఈవ్",
          },
          {
            Date: "గురువారము, 25 డిసెంబర్ 2025",
            Festival: "క్రిస్మస్",
          },
          {
            Date: "శుక్రవారము, 26 డిసెంబర్ 2025",
            Festival: "స్కంద షష్ఠి, బాక్సింగ్ డే",
          },
          {
            Date: "శనివారము, 27 డిసెంబర్ 2025",
            Festival: "మండల పూజ",
          },
          {
            Date: "ఆదివారము, 28 డిసెంబర్ 2025",
            Festival: "పూర్వాషాఢ కార్తె, దుర్గా అష్టమి వ్రతం",
          },
          {
            Date: "మంగళవారము, 30 డిసెంబర్ 2025",
            Festival: "పుష్యము పుత్రాద ఏకాదశీ, ముక్కోటి ఏకాదశి",
          },
          {
            Date: "బుధవారము, 31 డిసెంబర్ 2025",
            Festival: "వైష్ణవ పుష్యము పుత్రాద ఏకాదశీ, కుర్మా ద్వాదశి",
          },
        ],
      },
    ],
    [
      {
        day: [
          {
            Date: "గురువారము, జనవరి 1, 2026",
            Festival: "ప్రదోష్ వ్రతం"
          },
          {
            Date: "మంగళవారము, జనవరి 6, 2026",
            Festival: "లంబోదర సంకష్టహర చతుర్థి"
          },
          {
            Date: "బుధవారము, జనవరి 14, 2026",
            Festival: "షట్తిలా ఏకాదశీ"
          },
          {
            Date: "శుక్రవారము, జనవరి 16, 2026",
            Festival: "ప్రదోష్ వ్రతం"
          },
          {
            Date: "గురువారము, జనవరి 29, 2026",
            Festival: "జయ ఏకాదశీ, భీష్మ ద్వాదశి"
          },
          {
            Date: "శుక్రవారము, జనవరి 30, 2026",
            Festival: "ప్రదోష్ వ్రతం"
          }
        ]
      },
      {
        day: [
          {
            Date: "గురువారము, ఫిబ్రవరి 5, 2026",
            Festival: "ద్విజప్రియ సంకష్టహర చతుర్థి"
          },
          {
            Date: "శుక్రవారము, ఫిబ్రవరి 13, 2026",
            Festival: "విజయ ఏకాదశీ"
          },
          {
            Date: "శనివారము, ఫిబ్రవరి 14, 2026",
            Festival: "శని త్రయోదశి, ప్రదోష్ వ్రతం"
          },
          {
            Date: "ఆదివారము, ఫిబ్రవరి 15, 2026",
            Festival: "మహా శివరాత్రి"
          },
          {
            Date: "శుక్రవారము, ఫిబ్రవరి 27, 2026",
            Festival: "ఆమలకీ ఏకాదశీ"
          },
          {
            Date: "శనివారము, ఫిబ్రవరి 28, 2026",
            Festival: "నరసింహ ద్వాదశి"
          }
        ]
      },
      {
        day: [
          {
            Date: "ఆదివారము, మార్చి 1, 2026",
            Festival: "ప్రదోష్ వ్రతం"
          },
          {
            Date: "మంగళవారము, మార్చి 3, 2026",
            Festival: "హోలీ, హోళికా దహన"
          },
          {
            Date: "బుధవారము, మార్చి 4, 2026",
            Festival: "హోళి"
          },
          {
            Date: "శుక్రవారము, మార్చి 6, 2026",
            Festival: "బాలచంద్ర సంకష్టహర చతుర్థి"
          },
          {
            Date: "ఆదివారము, మార్చి 15, 2026",
            Festival: "పాపమొచనీ ఏకాదశీ"
          },
          {
            Date: "సోమవారము, మార్చి 16, 2026",
            Festival: "ప్రదోష్ వ్రతం"
          },
          {
            Date: "గురువారము, మార్చి 19, 2026",
            Festival: "ఉగాది"
          },
          {
            Date: "శనివారము, మార్చి 21, 2026",
            Festival: "డోల గౌరీ వ్రతం, గౌరీ పూజ"
          },
          {
            Date: "గురువారము, మార్చి 26, 2026",
            Festival: "శ్రీ రామనవమి"
          },
          {
            Date: "ఆదివారము, మార్చి 29, 2026",
            Festival: "కామద ఏకాదశీ, వామన ద్వాదశి"
          },
          {
            Date: "సోమవారము, మార్చి 30, 2026",
            Festival: "ప్రదోష్ వ్రతం"
          }
        ]
      },
      {
        day: [
          {
            Date: "గురువారము, ఏప్రిల్ 2, 2026",
            Festival: "చైత్రము పూర్ణిమ"
          },
          {
            Date: "ఆదివారము, ఏప్రిల్ 5, 2026",
            Festival: "వికట్ సంకష్టహర చతుర్థి"
          },
          {
            Date: "సోమవారము, ఏప్రిల్ 13, 2026",
            Festival: "వరుతిని ఏకాదశీ"
          },
          {
            Date: "బుధవారము, ఏప్రిల్ 15, 2026",
            Festival: "ప్రదోష్ వ్రతం"
          },
          {
            Date: "ఆదివారము, ఏప్రిల్ 19, 2026",
            Festival: "అక్షయ తృతీయ"
          },
          {
            Date: "సోమవారము, ఏప్రిల్ 27, 2026",
            Festival: "మోహినీ ఏకాదశీ"
          },
          {
            Date: "మంగళవారము, ఏప్రిల్ 28, 2026",
            Festival: "పరశురామ ద్వాదశి, ప్రదోష్ వ్రతం"
          }
        ]
      },
      {
        day: [
          {
            Date: "మంగళవారము, మే 5, 2026",
            Festival: "ఏకదంట సంకష్టహర చతుర్థి"
          },
          {
            Date: "మంగళవారము, మే 12, 2026",
            Festival: "హనుమాన్ జయంతి"
          },
          {
            Date: "బుధవారము, మే 13, 2026",
            Festival: "అపర ఏకాదశీ"
          },
          {
            Date: "గురువారము, మే 14, 2026",
            Festival: "ప్రదోష్ వ్రతం"
          },
          {
            Date: "బుధవారము, మే 27, 2026",
            Festival: "పద్మినీ ఏకాదశీ"
          },
          {
            Date: "గురువారము, మే 28, 2026",
            Festival: "అధిక ప్రదోష్ వ్రతం"
          }
        ]
      },
      {
        day: [
          {
            Date: "బుధవారము, జూన్ 3, 2026",
            Festival: "విభువన సంకష్టహర చతుర్థి"
          },
          {
            Date: "గురువారము, జూన్ 11, 2026",
            Festival: "పరమా ఏకాదశీ"
          },
          {
            Date: "శుక్రవారము, జూన్ 12, 2026",
            Festival: "అధిక ప్రదోష్ వ్రతం"
          },
          {
            Date: "గురువారము, జూన్ 25, 2026",
            Festival: "నిర్జల ఏకాదశీ"
          },
          {
            Date: "శుక్రవారము, జూన్ 26, 2026",
            Festival: "రామలక్ష్మణ ద్వాదశి"
          },
          {
            Date: "శనివారము, జూన్ 27, 2026",
            Festival: "ప్రదోష్ వ్రతం, శని త్రయోదశి"
          }
        ]
      },
      {
        day: [
          {
            Date: "శుక్రవారము, జూలై 3, 2026",
            Festival: "కృష్ణపింగళా సంకష్టహర చతుర్థి"
          },
          {
            Date: "శుక్రవారము, జూలై 10, 2026",
            Festival: "యోగిని ఏకాదశీ"
          },
          {
            Date: "శనివారము, జూలై 11, 2026",
            Festival: "తదనంతర యోగిని ఏకాదశీ, వైష్ణవ యోగిని ఏకాదశీ "
          },
          {
            Date: "ఆదివారము, జూలై 12, 2026",
            Festival: "ప్రదోష్ వ్రతం"
          },
          {
            Date: "శనివారము, జూలై 25, 2026",
            Festival: "దేవశయనీ ఏకాదశీ, వాసుదేవ ద్వాదశి"
          },
          {
            Date: "ఆదివారము, జూలై 26, 2026",
            Festival: "ప్రదోష్ వ్రతం"
          }
        ]
      },
      {
        day: [
          {
            Date: "ఆదివారము, ఆగష్టు 2, 2026",
            Festival: "గజానన సంకష్టహర చతుర్థి"
          },
          {
            Date: "ఆదివారము, ఆగష్టు 9, 2026",
            Festival: "అగస్త్య అర్ఘ్య, కామిక ఏకాదశీ"
          },
          {
            Date: "సోమవారము, ఆగష్టు 10, 2026",
            Festival: "ప్రదోష్ వ్రతం"
          },
          {
            Date: "ఆదివారము, ఆగష్టు 23, 2026",
            Festival: "శ్రావణ పుత్రాద ఏకాదశీ"
          },
          {
            Date: "సోమవారము, ఆగష్టు 24, 2026",
            Festival: "వైష్ణవ శ్రావణ పుత్రాద ఏకాదశీ, దామోదర ద్వాదశి"
          },
          {
            Date: "మంగళవారము, ఆగష్టు 25, 2026",
            Festival: "ప్రదోష్ వ్రతం"
          },
          {
            Date: "గురువారము, ఆగష్టు 27, 2026",
            Festival: "యజుర్వేద ఉపాకర్మ"
          },
          {
            Date: "శుక్రవారము, ఆగష్టు 28, 2026",
            Festival: "రక్షా బంధన్, వరలక్ష్మి వ్రతం "
          },
          {
            Date: "సోమవారము, ఆగష్టు 31, 2026",
            Festival: "హేరంబ సంకష్టహర చతుర్థి"
          },
        ]
      },
      {
        day: [
          {
            Date: "శుక్రవారము, సెప్టెంబర్ 4, 2026",
            Festival: "కృష్ణ జన్మాష్టమి"
          },
          {
            Date: "సోమవారము, సెప్టెంబర్ 7, 2026",
            Festival: "అజ ఏకాదశీ"
          },
          {
            Date: "మంగళవారము, సెప్టెంబర్ 8, 2026",
            Festival: "ప్రదోష్ వ్రతం"
          },
          {
            Date: "సోమవారము, సెప్టెంబర్ 14, 2026",
            Festival: "గణేష్ చతుర్థి"
          },
          {
            Date: "మంగళవారము, సెప్టెంబర్ 22, 2026",
            Festival: "పరివర్తినీ ఏకాదశీ"
          },
          {
            Date: "బుధవారము, సెప్టెంబర్ 23, 2026",
            Festival: "కల్కి ద్వాదశి"
          },
          {
            Date: "గురువారము, సెప్టెంబర్ 24, 2026",
            Festival: "ప్రదోష్ వ్రతం"
          },
          {
            Date: "శుక్రవారము, సెప్టెంబర్ 25, 2026",
            Festival: "గణేష్ విసర్జన్"
          },
          {
            Date: "మంగళవారము, సెప్టెంబర్ 29, 2026",
            Festival: "విఘ్నరాజ్ సంకష్టహర చతుర్థి"
          }
        ]
      },
      {
        day: [
          {
            Date: "మంగళవారము, అక్టోబర్ 6, 2026",
            Festival: "ఇందిరా ఏకాదశీ"
          },
          {
            Date: "గురువారము, అక్టోబర్ 8, 2026",
            Festival: "ప్రదోష్ వ్రతం"
          },
          {
            Date: "ఆదివారము, అక్టోబర్ 11, 2026",
            Festival: "నవరాత్రి ప్రారంభమవుతుంది"
          },
          {
            Date: "సోమవారము, అక్టోబర్ 19, 2026",
            Festival: "దుర్గ అష్టమి, మహా నవమి"
          },
          {
            Date: "మంగళవారము, అక్టోబర్ 20, 2026",
            Festival: "దశహర"
          },
          {
            Date: "గురువారము, అక్టోబర్ 22, 2026",
            Festival: "పాశాంకుశ ఏకాదశీ, పద్మనాభ ద్వాదశి"
          },
          {
            Date: "శుక్రవారము, అక్టోబర్ 23, 2026",
            Festival: "ప్రదోష్ వ్రతం"
          },
          {
            Date: "బుధవారము, అక్టోబర్ 28, 2026",
            Festival: "అట్లతద్ది"
          },
          {
            Date: "గురువారము, అక్టోబర్ 29, 2026",
            Festival: "వక్రతుండా సంకష్టహర చతుర్థి"
          }
        ]
      },
      {
        day: [
          {
            Date: "గురువారము, నవంబర్ 5, 2026",
            Festival: "రమా ఏకాదశీ"
          },
          {
            Date: "శుక్రవారము, నవంబర్ 6, 2026",
            Festival: "ప్రదోష్ వ్రతం"
          },
          {
            Date: "ఆదివారము, నవంబర్ 8, 2026",
            Festival: "దివాలీ, లక్ష్మి పూజ"
          },
          {
            Date: "శుక్రవారము, నవంబర్ 13, 2026",
            Festival: "నాగుల చవితి"
          },
          {
            Date: "శుక్రవారము, నవంబర్ 20, 2026",
            Festival: "దేవుత్తన ఏకాదశీ"
          },
          {
            Date: "శనివారము, నవంబర్ 21, 2026",
            Festival: "వైష్ణవ దేవుత్తన ఏకాదశీ, తదనంతర దేవుత్తన ఏకాదశీ, యోగేశ్వర ద్వాదశి"
          },
          {
            Date: "ఆదివారము, నవంబర్ 22, 2026",
            Festival: "ప్రదోష్ వ్రతం"
          },
          {
            Date: "శుక్రవారము, నవంబర్ 27, 2026",
            Festival: "గణాధిప సంకష్టహర చతుర్థి"
          }
        ]
      },
      {
        day: [
          {
            Date: "శుక్రవారము, డిసెంబర్ 4, 2026",
            Festival: "ఉత్పన్న ఏకాదశీ"
          },
          {
            Date: "ఆదివారము, డిసెంబర్ 6, 2026",
            Festival: "ప్రదోష్ వ్రతం"
          },
          {
            Date: "సోమవారము, డిసెంబర్ 14, 2026",
            Festival: "నాగ పంచమి"
          },
          {
            Date: "ఆదివారము, డిసెంబర్ 20, 2026",
            Festival: "మొక్షద ఏకాదశీ"
          },
          {
            Date: "సోమవారము, డిసెంబర్ 21, 2026",
            Festival: "మత్స్య ద్వాదశి, ప్రదోష్ వ్రతం"
          },
          {
            Date: "శనివారము, డిసెంబర్ 26, 2026",
            Festival: "ఆఖురత సంకష్టహర చతుర్థి"
          }
        ]
      }
    ]
  ],
 
}
export default LocalData;