import React, { useState, useEffect } from 'react';
import Header from '../header';
import Navbar from '../navbar';
import Footer from '../footer';
import ItaramuluData from '../../assets/jsons/ItaramuluData';

const Dharmasandehalu = () => {
    const data = ItaramuluData.DharmaSandehalu;
    const [activeKey, setActiveKey] = useState(0);
    const [selectedContent, setSelectedContent] = useState(null);

    useEffect(() => {
        // Set the default content to the first item in the data array
        if (data.length > 0) {
            setSelectedContent(data[0]);
        }
    }, [data]);

    const handleToggle = (index) => {
        setActiveKey(activeKey === index ? null : index);
        setSelectedContent(data[index]);
    };
    const [leftSticky, setLeftSticky] = useState(true);
    const [rightSticky, setRightSticky] = useState(true);

    return (
        <>
            <Header />
            <Navbar />
            <div className="container-fluid bgColor">
                <div className="row">
                    <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
                    <div
                        className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
                        onMouseEnter={() => {
                            setLeftSticky(false);
                            setRightSticky(true);
                        }}
                        style={{
                            overflowY: leftSticky ? 'hidden' : 'auto',
                            height: '80vh',
                            position: leftSticky ? 'sticky' : 'static',
                            top: 0,
                        }}
                    >
                        <div className="row">
                            <div id="accordion">
                                {data.map((item, index) => (
                                    <div
                                        key={index}
                                        id={`heading${index}`}
                                        onClick={() => handleToggle(index)}
                                        className="card-header card mb-3"
                                        style={{ color: "#FFFFFF", backgroundColor: activeKey === index ? '#B35C0C' : '#754213' }}
                                    >
                                        <h5 className="mb-0">
                                            <button
                                                className="btn btn"
                                                aria-expanded={activeKey === index}
                                                aria-controls={`collapse${index}`}
                                                style={{ color: "#FFFFFF",border:"none", }}
                                            >
                                                {item.title}
                                            </button>
                                        </h5>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
                        onMouseEnter={() => {
                            setLeftSticky(true);
                            setRightSticky(false);
                        }}
                    
                    >
                        <div className="card-body rightPanel"     style={{
                            overflowY: rightSticky ? 'scroll' : 'auto',
                            height: '80vh',
                            position: rightSticky ? 'sticky' : 'sticky',
                            top: 0,
                        }}>
                            {selectedContent && (
                                <div>
                                    <h5>{selectedContent.title}</h5>
                                    {selectedContent.CatID.map((catItem, catIndex) => (
                                        <div key={catIndex} style={{marginTop:"5%"}}>
                                            <ul style={{marginTop:"-5%"}}>{catItem.title}</ul>
                                            <br></br>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Dharmasandehalu;
