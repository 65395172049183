const Quotes=[
  {
    CatID: [
      {
        title:"మన మనసును మనం అదుపు చేసుకోలేకపోతే అదే మనకు ప్రధాన శత్రువుగా మారుతుంది"
      },
      {
        title:"చావు పుట్టుకలు సహజం ఎవరూ తప్పించుకోలేరు వివేకవంతులు వాటి గురించి ఆలోచించారు."
      },
      {
        title:"నీవు బ్రతికుండేది కేవలం ఈరోజు మాత్రమే అన్నట్లు నీ కర్తవ్యాన్ని నిర్వహించు. ఫలితాన్ని మాత్రం భగవంతునికి వదిలివేయు. అప్పుడు ప్రపంచంలోనే ఏ బాధ మీ ధరి చేరదు."
      },
      {
        title:"కుండలు వేరైన మట్టి ఒక్కటే నగలు వేరైన బంగారం ఒక్కటే ఆవులు వేరైనా పాలు ఒక్కటే అల్లాగే దేహాలు వేరైన పరమాత్మ ఒక్కటే అని తెలుసుకున్న వాడే జ్ఞాని"
      },
      {
        title:"అందరిలో ఉండేది ఆత్మ ఒక్కటే కనుక ఒకరిని ద్వేషించడం అనేది. తనను తాను ద్వేషించుకోవడమే అవుతుంది."
      },
      {
        title:"మనస్సును స్వాధీనపరుచుకున్నవాడికి తన మనస్సే బంధువు. మనస్సును జయించలేని వాడికి మనస్సే ప్రబల శత్రువులాగా ప్రవర్తిస్తుంది"
      },
      {
        title:"మరణం అనివార్యం పుట్టిన ప్రతి ప్రాణి గిట్టక తప్పదు ఎవరూ అమరులు కాదు"
      },
      {
        title:"భగవద్గీత అంటే శవాల దగ్గర పెట్టే పాట కాదు. మనం శవంగా మారేలోపు జీవితపరమార్ధాన్ని తెలియజేసే దివ్య జ్ఞానోపదేశం"
      },
      {
        title:"కోపం మనసులో కాదు మాటలో మాత్రమే ఉండాలి..ప్రేమ మాటలో మాత్రమే కాదు మనసులోనూ ఉండాలి"
      },
      {
        title:"కర్మలను ఆచరించుటయందే నీకు అధికారం కలదు కాని. వాటి ఫలితాలయందు కాదు."
      },
      {
        title:"అందరిలో ఉండే ఆత్మ ఒకటే కనుక ఒకరిని ద్వేషించడం అనేది తనను తాను ద్వేషించుకోవడమే అవుతుంది."
      },
      {
        title: "కామం, కోపం మరియు దురాశ ఈ మూడు నరకానికి మూడు తలుపులు"
      },
      {
        title:"ఈ సృష్టి ని సృష్టించింది నేనే, మధ్యలో ఉన్నది నేనే , మరియు ముగించేది కూడా నేనే"
      },
      {
        title:"జరిగినాదంత మన మంచి కోసమే జరిగింది. జరుగుతున్నదంతా మన మంచి కోసం జరుగుతోంది. భవిష్యత్తులో ఏది జరిగినా కూడా మన మంచి కోసం జరుగుతుంది."
      },
      {
        title: "మార్పు విశ్వం యొక్క నియమం"
      },
      {
        title: "మనిషి తన నమ్మకంతో తయారవుతాడు. అతను నమ్మిందే తాను"
      },
      {
        title: "ఆశించడం మానేసినప్పుడే నీకు నిజమైన శాంతి దొరుకుతుంది"
      },
      {
        title:"నువ్వు ఏం చేసినా ఆ చర్యలు  శ్రీకృష్ణుడి ప్రకారమే. నేను నీలో ఎప్పుడూ ఉంటాను"
      },
      {
        title:"నీదంటూ ఏదీ లేదు. నువ్వు మరణించిన తరువాత దేన్నీ తీసుకెళ్లలేవు. భౌతిక, అవాస్తవిక అంశాలు అన్నీ ఇక్కడే వదిలి వెళ్లాలి."
      },
      {
        title:"గుర్తుంచుకో...ఏం జరిగినా అంతా మన మంచికే జరుగుతుంది అని నమ్ము. ఇప్పుడ ఏ జరుగుతోందో అదే మంచికే జరుగుతోంది. భవిష్యత్తులో జరగనున్నది కూడా మంచికే జరగనున్నది."
      },
      {
        title:"మరణం అనివార్యం. పుట్టిన ప్రతి ప్రాణి గిట్టక తప్పదు. ఎవరూ అమరులు కాదు."
      },
      {
        title:"మానసిక శాంతి చాలా ముఖ్యం. కోపం అనేది భ్రమలాంటిది. అది బుద్ధిని ఇబ్బంది పెడుతుంది. "
      },
      {
        title:"అతిగా స్పందించడం, అది కోపం, అతి ప్రేమ, అతి లోభం ఇలా అతి మంచిది కాదు. ప్రతి విషయంలో స్థిరంగా ఉండు. స్థిత ప్రజ్ఞతతో జీవించు. అతిగా సంతోషపడటం, అతిగా బాధ పడటం రెండూ మంచివి కావు."
      },
      {
        title:"స్వార్థం నీ వివేకాన్ని చంపేస్తుంది. స్వార్థపరుడు తన లాభాల కోసమే చూస్తాడు. ఇతరులే సంక్షేమం గురించి ఆలోచిస్తాడు."
      },
      {
        title:"అన్ని పనులకు మూల్యం చెల్లించాల్సి ఉంటుంది. కర్మ అనుభవించాల్సి ఉంటుంది. అందుకే మంచి కర్మలు చేయాలి."
      },
      {
        title:"కర్తవ్యం నిర్వర్తించడంలో సిగ్గుపడకూడదు. మనిషి తన కర్మల వల్ల గుర్తింపు సాధిస్తాడు. సరైన పనిచేసిన వ్యక్తిని ప్రపంచం కీర్తిస్తుంది. నీ కర్తవ్యం నుంచి పారిపోవడం మంచిది కాదు"
      },
      {
        title:"మీరు కేవలం కర్మకు మాత్రమే అర్హులు, దాని ఫలాలు ఎన్నటికీ పొందలేరు"
      },
      {
        title:"కార్యక్రియలో, క్రియాసంరలలో, క్రియాసంతో, క్రియాసంరలలో, కార్యోన్మకక్రియలను చూసే వాడు, మనుష్యులలో తెలివైనవాడు."
      },
      {
        title:"నిస్వార్థ సేవ ద్వారా మీరు ఎల్లప్పుడూ ఫలప్రదంగా ఉంటారు మరియు మీ కోరికలను నెరవేర్చగలుగుతారు."
      },
      {
        title:"అన్నము వలన జంతుజాలము పుట్టును. వర్షము వలన అన్నము సమకూరును. యజ్ఞము వలన వర్షము కలుగును. ఆ యజ్ఞము కర్మ వలననే సంభవమగును."
      },
      {
        title:"ఉత్తములైన వారు దేని నాచరింతురో, దానినే ఇతరులును ఆచరింతురు. ఉత్తములు వేనిని ప్రమాణముగా అంగీకరింతురో లోకమంతయు దానినే అనుసరించును."
      },
      {
        title:"పొగచేత అగ్ని, మురికిచేత అద్దము, మావిచేత శిశువు యెట్లు కప్పబడునో, అట్లు కామముచేత జ్ఞానము కప్పబడి యున్నది."
      },
      {
        title:"ఏ కాలమున ధర్మమునకు హాని కలుగునో, అధర్మము వృద్దినొందునో, ఆయా సమయములయందు శిష్టరక్షణ, దుష్టశిక్షణ, ధర్మ సంరక్షణముల కొఱకు ప్రతీయుగమునా అవతారము దాల్చుచున్నాను."
      },
      {
        title:"అనురాగము, భయము, క్రోధము వదలి నా యందు మనస్సు లగ్నము చేసి, ఆశ్రయించిన సత్పురుషులు జ్ఞానయోగము చేత పరిశుద్ధులై నా సాన్నిధ్యమును పొందిరి."
      },
      {
        title:"శ్రద్ధ, ఇంద్రియ నిగ్రహము గలవాడు జ్ఞానమును పొందుటకు సమర్ధుడగును. అట్టి జ్ఞాని ఉత్కృష్టమైన మోక్షమును పొందును."
      },
      {
        title:"ఎవని అజ్ఞానము జ్ఞానము చేత నశింపబడునో అతనికి జ్ఞానము సూర్యునివలె ప్రకాశించి పరమార్థతత్వమును జూపును."
      },
      {
        title:"గాలిలేనిచోట పెట్టిన దీపము నిశ్చలముగా ప్రకాశించులాగుననే మనోనిగ్రహము కలిగి అత్మయోగమభ్యసించిన వాని చిత్తము నిశ్చలముగా నుండును."
      },
      {
        title:"ఎవడు అంత్య కాలమున నన్ను స్మరించుచు శరీరమును వదలుచున్నాడో, వాడు నన్నే చెందుచున్నాడు."
      },
      {
        title:"రాక్షసులలో ప్రహ్లాదుడు, గణికులలో కాలము, మృగములలో సింహము, పక్షులలో గరుత్మంతుడు నేనే."
      },
      {
        title:"కామ, క్రోధ, లోభములు ఆత్మను నాశమును చేయును. అవి నరకప్రాప్తికి హేతువులు కావున, వానిని వదలి వేయవలెను."
      },
      {
        title:"జీవులకు గల శ్రద్ధ, పూర్వజన్మవాసనాబలము వలన లభ్యము. అది రాజసము, సాత్వికము, తామసములని మూడు విధములుగా నున్నవి."
      },
    ],
    title: "భగవద్గీత సూక్తులు"
  }
]
export default Quotes