import React, { useState } from 'react'
import krishnaastakam from '../../assets/jsons/Telugu/krishna_astotaramu'
import Header from '../header'
import Navbar from '../navbar'
import Footer from '../footer'

const Srikrishnaastrotharalu = () => {
  const [leftSticky, setLeftSticky] = useState(true);
  const [rightSticky, setRightSticky] = useState(true);
  return (
    <div>
      <Header />
      <Navbar />
      <div className="container-fluid" style={{ background: '#ffe6d0' }}>
        <div className="row">
          <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
          <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 p-3"></div>
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 p-3"
            onMouseEnter={() => {
              setLeftSticky(true);
              setRightSticky(false);
            }}
            style={{
              overflowY: rightSticky ? 'hidden' : 'auto',
              height: '80vh',
              position: rightSticky ? 'sticky' : 'static',
              top: 0,
            }}
          >
            <div className="card-body rightPanel">
              <h5>శ్రీ కృష్ణ అష్టోత్తరము</h5>
              {krishnaastakam.map((krishna, index) => (
                <div key={index}>
                  {krishna.title ? (
                    <ul>
                      {krishna.title}
                    </ul>
                  ) : (
                    <div style={{ marginBottom: '50px' }}></div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 p-3"></div>
          <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Srikrishnaastrotharalu
