export const PanchangamData = {
  PanchangamUpdated: {
    2024:  [
      {
        day: [
          {
            Abhijit: "11:57 నుండి 12:42 వరకు",
            Amrutakalam: "04:28 జనవరి 02 నుండి 06:17 జనవరి 02 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహ",
            Date: "సోమవారము, జనవరి 01, 2024",
            DurMuhurtamulu: "12:42 నుండి 13:26 వరకు, 14:55 నుండి 15:40 వరకు",
            GulikaiKalam: "13:43 నుండి 15:06 వరకు",
            Karanamulu: "తైతిల 14:28 వరకు, బవ 03:49 జనవరి 02 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "మఖ 08:36 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "08:10 నుండి 09:33 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:46",
            Sunset: "17:53",
            Thiti: "పంచమి 14:28 వరకు",
            Yamaganda: "10:56 నుండి 12:20 వరకు",
            Yoga: "ఆయుష్మాన్ 04:36, జనవరి 02 వరకు",
            Varjyam: "17:38 నుండి 19:26 వరకు",
            Moonrise: "22:29",
            Moonset: "10:35",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "1",
            LordImage: require("../images/holidays/0-0)NewYear.png"),
          },
          {
            Abhijit: "11:58 నుండి 12:42 వరకు",
            Amrutakalam: "06:39 జనవరి 03 నుండి 08:27 జనవరి 03 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం 18:29 వరకు",
            Date: "మంగళవారము, జనవరి 2, 2024",
            DurMuhurtamulu: "09:00 నుండి 09:44 వరకు, 23:03 నుండి 23:54 వరకు",
            GulikaiKalam: "12:20 నుండి 13:43 వరకు",
            Karanamulu: "వణిజ 17:10 వరకు, విష్టి 06:30 జనవరి 03 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "పుబ్బ 11:42 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:07 నుండి 16:30 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:47",
            Sunset: "17:53",
            Thiti: "షష్టి 17:10 వరకు",
            Yamaganda: "09:33 నుండి 10:57 వరకు",
            Yoga: "సౌభాగ్య 05:33, జనవరి 03 వరకు",
            Varjyam: "19:49 నుండి 21:38 వరకు",
            Moonrise: "23:15",
            Moonset: "11:08",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "ఏమిలేదు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య",
            Date: "బుధవారము, జనవరి 3, 2024",
            DurMuhurtamulu: "11:58 నుండి 12:43 వరకు",
            GulikaiKalam: "10:57 నుండి 12:20 వరకు",
            Karanamulu: "బవ 19:48 వరకు, బాలవ నిండా రాత్రి వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "ఉత్తర 14:46 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:20 నుండి 13:44 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:47",
            Sunset: "17:54",
            Thiti: "సప్తమి 19:48 వరకు",
            Yamaganda: "08:10 నుండి 09:34 వరకు",
            Yoga: "శోభన 06:21, జనవరి 04 వరకు",
            Varjyam: "00:09, జనవరి 04 నుండి 01:56, జనవరి 04 వరకు",
            Moonrise: "00:02 జనవరి 04",
            Moonset: "11:39",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:59 నుండి 12:43 వరకు",
            Amrutakalam: "10:52 నుండి 12:39 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య 06:46, జనవరి 05 వరకు",
            Date: "గురువారము, జనవరి 4, 2024",
            DurMuhurtamulu: "14:57 నుండి 15:41 వరకు",
            GulikaiKalam: "09:34 నుండి 10:57 వరకు",
            Karanamulu: "బాలవ 08:59 వరకు, కౌలవ 22:04 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "హస్త 17:33 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:44 నుండి 15:08 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:47",
            Sunset: "17:55",
            Thiti: "అష్టమి 22:04 వరకు",
            Yamaganda: "06:47 నుండి 08:11 వరకు",
            Yoga: "అతిగణ్డ నిండా రాత్రి వరకు",
            Varjyam: "02:19, జనవరి 05 నుండి 04:04, జనవరి 05 వరకు",
            Moonrise: "00:49 జనవరి 05",
            Moonset: "12:11",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:59 నుండి 12:44 వరకు",
            Amrutakalam: "12:49 నుండి 14:34 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల",
            Date: "శుక్రవారము, జనవరి 5, 2024",
            DurMuhurtamulu: "09:01 నుండి 09:46 వరకు, 12:44 నుండి 13:28 వరకు",
            GulikaiKalam: "08:11 నుండి 09:34 వరకు",
            Karanamulu: "తైతిల 11:00 వరకు, బవ 23:46 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "చిత్తా 19:50 వరకు",
            MainNakshatra: "చిత్తా",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:58 నుండి 12:21 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:48",
            Sunset: "17:55",
            Thiti: "నవమి 23:46 వరకు",
            Yamaganda: "15:08 నుండి 16:32 వరకు",
            Yoga: "అతిగణ్డ 06:49 వరకు",
            Varjyam: "01:47, జనవరి 06 నుండి 03:30, జనవరి 06 వరకు",
            Moonrise: "01:39, జనవరి 06",
            Moonset: "12:45",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:00 నుండి 12:44 వరకు",
            Amrutakalam: "12:01 నుండి 13:43 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల",
            Date: "శనివారము, జనవరి 6, 2024",
            DurMuhurtamulu: "06:48 నుండి 07:32 వరకు, 07:32 నుండి 08:17 వరకు",
            GulikaiKalam: "06:48 నుండి 08:11 వరకు",
            Karanamulu: "వణిజ 12:20 వరకు, విష్టి 00:41, జనవరి 07 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "స్వాతి 21:23 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:35 నుండి 10:58 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:48",
            Sunset: "17:56",
            Thiti: "దశమి 00:41, జనవరి 07 వరకు",
            Yamaganda: "13:45 నుండి 15:09 వరకు",
            Yoga: "సుకర్మా 06:48 వరకు",
            Varjyam: "03:10, జనవరి 07 నుండి 04:49, జనవరి 07 వరకు",
            Moonrise: "02:32, జనవరి 07",
            Moonset: "13:22",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:00 నుండి 12:45 వరకు",
            Amrutakalam: "13:04 నుండి 14:43 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల 16:02 వరకు",
            Date: "ఆదివారము, జనవరి 7, 2024",
            DurMuhurtamulu: "16:27 నుండి 17:12 వరకు",
            GulikaiKalam: "15:09 నుండి 16:33 వరకు",
            Karanamulu: "బవ 12:50 వరకు, బాలవ 00:46, జనవరి 08 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "విశాఖ 22:08 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:33 నుండి 17:56 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:48",
            Sunset: "17:56",
            Thiti: "ఏకాదశి 00:46, జనవరి 08 వరకు",
            Yamaganda: "12:22 నుండి 13:46 వరకు",
            Yoga: "శూల 04:53, జనవరి 08 వరకు",
            Varjyam: "02:07, జనవరి 08 నుండి 03:43, జనవరి 08 వరకు",
            Moonrise: "03:30, జనవరి 08",
            Moonset: "14:03",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:01 నుండి 12:45 వరకు",
            Amrutakalam: "11:41 నుండి 13:17 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "సోమవారము, జనవరి 8, 2024",
            DurMuhurtamulu: "12:45 నుండి 13:30 వరకు, 14:59 నుండి 15:43 వరకు",
            GulikaiKalam: "13:46 నుండి 15:10 వరకు",
            Karanamulu: "కౌలవ 12:28 వరకు, తైతిల 23:58 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "అనూరాధ 22:03 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "08:12 నుండి 09:36 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:48",
            Sunset: "17:57",
            Thiti: "ద్వాదశి 23:58 వరకు",
            Yamaganda: "10:59 నుండి 12:23 వరకు",
            Yoga: "గణ్డ 02:56, జనవరి 09 వరకు",
            Varjyam: "03:27, జనవరి 09 నుండి 04:59, జనవరి 09 వరకు",
            Moonrise: "04:31, జనవరి 09",
            Moonset: "14:50",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:01 నుండి 12:45 వరకు",
            Amrutakalam: "12:42 నుండి 14:15 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము 21:11 వరకు",
            Date: "మంగళవారము, జనవరి 9, 2024",
            DurMuhurtamulu: "09:02 నుండి 09:47 వరకు, 23:06 నుండి 23:58 వరకు",
            GulikaiKalam: "12:23 నుండి 13:47 వరకు",
            Karanamulu: "బవ 11:17 వరకు, వణిజ 22:24 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "జ్యేష్ఠ 21:11 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:10 నుండి 16:34 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:49",
            Sunset: "17:58",
            Thiti: "త్రయోదశి 22:24 వరకు",
            Yamaganda: "09:36 నుండి 10:59 వరకు",
            Yoga: "వృద్ధి 00:22, జనవరి 10 వరకు",
            Varjyam: "04:41, జనవరి 10 నుండి 06:11, జనవరి 10 వరకు",
            Moonrise: "05:35 జనవరి 10",
            Moonset: "15:44",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "13:40 నుండి 15:10 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "బుధవారము, జనవరి 10, 2024",
            DurMuhurtamulu: "12:01 నుండి 12:46 వరకు",
            GulikaiKalam: "11:00 నుండి 12:24 వరకు",
            Karanamulu: "విష్టి 09:22 వరకు, శకుని 20:10 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "మూల 19:40 వరకు",
            MainNakshatra: "మూల",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:24 నుండి 13:47 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:49",
            Sunset: "17:58",
            Thiti: "చతుర్దశి 20:10 వరకు",
            Yamaganda: "08:12 నుండి 09:36 వరకు",
            Yoga: "ధ్రువ 21:18 వరకు",
            Varjyam: "18:10 నుండి 19:40 వరకు",
            Moonrise: "06:38, జనవరి 11",
            Moonset: "16:46",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:02 నుండి 12:46 వరకు",
            Amrutakalam: "13:15 నుండి 14:43 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు 23:05 వరకు",
            Date: "గురువారము, జనవరి 11, 2024",
            DurMuhurtamulu: "10:32 నుండి 11:17 వరకు, 15:00 నుండి 15:45 వరకు",
            GulikaiKalam: "09:36 నుండి 11:00 వరకు",
            Karanamulu: "చతుష్పాద 06:51 వరకు, నాగ 17:26 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "పూర్వాషాఢ 17:39 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:48 నుండి 15:11 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:49",
            Sunset: "17:59",
            Thiti: "అమావాస్య 17:26 వరకు",
            Yamaganda: "06:49 నుండి 08:13 వరకు",
            Yoga: "వ్యాఘాత 17:49 వరకు",
            Varjyam: "00:52, జనవరి 12 నుండి 02:18, జనవరి 12 వరకు",
            Moonrise: "ఏమిలేదు",
            Moonset: "17:52",
            Shaka: "1945 శోభకృత్",
            Amavasya: "amavasya",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:02 నుండి 12:47 వరకు",
            Amrutakalam:
              "09:32 నుండి 10:58 వరకు, 03:30, జనవరి 13 నుండి 04:56, జనవరి 13 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము",
            Date: "శుక్రవారము, జనవరి 12, 2024",
            DurMuhurtamulu: "09:03 నుండి 09:48 వరకు, 12:47 నుండి 13:31 వరకు",
            GulikaiKalam: "08:13 నుండి 09:37 వరకు",
            Karanamulu: "బవ 14:23 వరకు, బాలవ 00:47, జనవరి 13 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "ఉత్తరాషాఢ 15:18 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "11:01 నుండి 12:24 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:49",
            Sunset: "18:00",
            Thiti: "పాడ్యమి 14:23 వరకు",
            Yamaganda: "15:12 నుండి 16:36 వరకు",
            Yoga: "హర్షణ 14:05 వరకు",
            Varjyam: "18:53 నుండి 20:19 వరకు",
            Moonrise: "07:37",
            Moonset: "19:00",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:02 నుండి 12:47 వరకు",
            Amrutakalam: "01:02, జనవరి 14 నుండి 02:28, జనవరి 14 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము 23:35 వరకు",
            Date: "శనివారము, జనవరి 13, 2024",
            DurMuhurtamulu: "06:49 నుండి 07:34 వరకు, 07:34 నుండి 08:19 వరకు",
            GulikaiKalam: "06:49 నుండి 08:13 వరకు",
            Karanamulu: "కౌలవ 11:11 వరకు, తైతిల 21:34 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "శ్రవణం 12:49 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:37 నుండి 11:01 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:49",
            Sunset: "18:00",
            Thiti: "విదియ 11:11 వరకు",
            Yamaganda: "13:49 నుండి 15:12 వరకు",
            Yoga: "వజ్ర 10:14 వరకు",
            Varjyam: "16:25 నుండి 17:51 వరకు",
            Moonrise: "08:30",
            Moonset: "20:06",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:03 నుండి 12:47 వరకు",
            Amrutakalam: "01:35, జనవరి 15 నుండి 03:02, జనవరి 15 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము",
            Date: "ఆదివారము, జనవరి 14, 2024",
            DurMuhurtamulu: "16:31 నుండి 17:16 వరకు",
            GulikaiKalam: "15:13 నుండి 16:37 వరకు",
            Karanamulu: "గర 07:59 వరకు, వణిజ 18:27 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "ధనిష్ఠ 10:22 వరకు",
            MainNakshatra: "ధనిష్ఠ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "16:37 నుండి 18:01 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:49",
            Sunset: "18:01",
            Thiti: "తదియ 07:59 వరకు, తదుపరి చవితి 09:59 జనవరి 15 వరకు",
            Yamaganda: "12:25 నుండి 13:49 వరకు",
            Yoga: "వ్యతీపాత 02:40, జనవరి 15 వరకు",
            Varjyam: "16:54 నుండి 18:21 వరకు",
            Moonrise: "09:18",
            Moonset: "21:08",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "14",
            LordImage: require("../images/holidays/0-3)Bhogi.png"),
          },
          {
            Abhijit: "12:03 నుండి 12:48 వరకు",
            Amrutakalam: "22:49 నుండి 00:17, జనవరి 16 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము 00:37, జనవరి 16 వరకు",
            Date: "సోమవారము, జనవరి 15, 2024",
            DurMuhurtamulu: "12:48 నుండి 13:33 వరకు, 15:02 నుండి 15:47 వరకు",
            GulikaiKalam: "13:49 నుండి 15:13 వరకు",
            Karanamulu: "బవ 15:35 వరకు, బాలవ 02:16, జనవరి 16 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "శతభిషం 08:07 వరకు, పూర్వాభాద్ర 06:10, జనవరి 16 వరకు",
            MainNakshatra: "శతభిషం, పూర్వాభాద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "08:13 నుండి 09:37 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:50",
            Sunset: "18:01",
            Thiti: "పంచమి 02:16, జనవరి 16 వరకు",
            Yamaganda: "11:01 నుండి 12:25 వరకు",
            Yoga: "వారీయన 23:11 వరకు",
            Varjyam: "14:00 నుండి 15:28 వరకు",
            Moonrise: "10:01",
            Moonset: "22:08",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "15",
            LordImage: require("../images/holidays/0-4)Sankranthi.png"),
          },
          {
            Abhijit: "12:03 నుండి 12:48 వరకు",
            Amrutakalam: "00:08, జనవరి 17 నుండి 01:38, జనవరి 17 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం",
            Date: "మంగళవారము, జనవరి 16, 2024",
            DurMuhurtamulu: "23:09 నుండి 00:00, జనవరి 17 వరకు",
            GulikaiKalam: "12:26 నుండి 13:50 వరకు",
            Karanamulu: "కౌలవ 13:03 వరకు, తైతిల 23:57 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "ఉత్తరాభాద్ర 04:38, జనవరి 17 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:14 నుండి 16:38 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:50",
            Sunset: "18:02",
            Thiti: "షష్టి 23:57 వరకు",
            Yamaganda: "09:38 నుండి 11:02 వరకు",
            Yoga: "పరిఘ 20:01 వరకు",
            Varjyam: "15:09 నుండి 16:39 వరకు",
            Moonrise: "10:41",
            Moonset: "23:06",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "16",
            LordImage: require("../images/holidays/0-5)Kanuma.png"),
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "01:16, జనవరి 18 నుండి 02:48, జనవరి 18 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం 03:33, జనవరి 18 వరకు",
            Date: "బుధవారము, జనవరి 17, 2024",
            DurMuhurtamulu: "12:04 నుండి 12:49 వరకు",
            GulikaiKalam: "11:02 నుండి 12:26 వరకు",
            Karanamulu: "గర 10:58 వరకు, వణిజ 22:06 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "రేవతి 03:33, జనవరి 18 వరకు",
            MainNakshatra: "రేవతి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:26 నుండి 13:50 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:50",
            Sunset: "18:03",
            Thiti: "సప్తమి 22:06 వరకు",
            Yamaganda: "08:14 నుండి 09:38 వరకు",
            Yoga: "శివ 17:13 వరకు",
            Varjyam: "16:06 నుండి 17:37 వరకు",
            Moonrise: "11:20",
            Moonset: "00:02, జనవరి 18",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:04 నుండి 12:49 వరకు",
            Amrutakalam: "19:57 నుండి 21:30 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం",
            Date: "గురువారము, జనవరి 18, 2024",
            DurMuhurtamulu: "10:34 నుండి 11:19 వరకు, 15:04 నుండి 15:49 వరకు",
            GulikaiKalam: "09:38 నుండి 11:02 వరకు",
            Karanamulu: "విష్టి 09:22 వరకు, బవ 20:44 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "అశ్విని 02:58, జనవరి 19 వరకు",
            MainNakshatra: "అశ్విని",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:51 నుండి 15:15 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:50",
            Sunset: "18:03",
            Thiti: "అష్టమి 20:44 వరకు",
            Yamaganda: "06:50 నుండి 08:14 వరకు",
            Yoga: "సిద్ధ 14:48 వరకు",
            Varjyam: "23:04 నుండి 00:37, జనవరి 19 వరకు",
            Moonrise: "11:59",
            Moonset: "00:59, జనవరి 19",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:04 నుండి 12:49 వరకు",
            Amrutakalam: "22:04 నుండి 23:39 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం",
            Date: "శుక్రవారము, జనవరి 19, 2024",
            DurMuhurtamulu: "09:05 నుండి 09:50 వరకు, 12:49 నుండి 13:34 వరకు",
            GulikaiKalam: "08:14 నుండి 09:38 వరకు",
            Karanamulu: "బాలవ 08:14 వరకు, కౌలవ 19:51 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "భరణి 02:50, జనవరి 20 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "11:03 నుండి 12:27 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:50",
            Sunset: "18:04",
            Thiti: "నవమి 19:51 వరకు",
            Yamaganda: "15:15 నుండి 16:40 వరకు",
            Yoga: "సాధ్య 12:46 వరకు",
            Varjyam: "12:31 నుండి 14:06 వరకు",
            Moonrise: "12:40",
            Moonset: "01:57, జనవరి 20",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:05 నుండి 12:50 వరకు",
            Amrutakalam: "00:43, జనవరి 21 నుండి 02:20, జనవరి 21 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం 08:53 వరకు",
            Date: "శనివారము, జనవరి 20, 2024",
            DurMuhurtamulu: "06:50 నుండి 07:35 వరకు, 07:35 నుండి 08:20 వరకు",
            GulikaiKalam: "06:50 నుండి 08:14 వరకు",
            Karanamulu: "తైతిల 07:35 వరకు, బవ 19:26 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "కృత్తిక 03:09, జనవరి 21 వరకు",
            MainNakshatra: "కృత్తిక",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:38 నుండి 11:03 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:50",
            Sunset: "18:04",
            Thiti: "దశమి 19:26 వరకు",
            Yamaganda: "13:51 నుండి 15:16 వరకు",
            Yoga: "శుభ 11:06 వరకు",
            Varjyam: "15:00 నుండి 16:37 వరకు",
            Moonrise: "13:24",
            Moonset: "02:55, జనవరి 21",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:05 నుండి 12:50 వరకు",
            Amrutakalam: "00:34, జనవరి 22 నుండి 02:13, జనవరి 22 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం",
            Date: "ఆదివారము, జనవరి 21, 2024",
            DurMuhurtamulu: "16:35 నుండి 17:20 వరకు",
            GulikaiKalam: "15:16 నుండి 16:41 వరకు",
            Karanamulu: "వణిజ 07:23 వరకు, విష్టి 19:26 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "రోహిణి 03:52, జనవరి 22 వరకు",
            MainNakshatra: "రోహిణి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "16:41 నుండి 18:05 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:50",
            Sunset: "18:05",
            Thiti: "ఏకాదశి 19:26 వరకు",
            Yamaganda: "12:27 నుండి 13:52 వరకు",
            Yoga: "శుక్ల 09:47 వరకు",
            Varjyam: "19:38 నుండి 21:17 వరకు",
            Moonrise: "14:12",
            Moonset: "03:54, జనవరి 22",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:05 నుండి 12:50 వరకు",
            Amrutakalam: "19:46 నుండి 21:26 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం 16:22 వరకు",
            Date: "సోమవారము, జనవరి 22, 2024",
            DurMuhurtamulu: "12:50 నుండి 13:35 వరకు, 15:05 నుండి 15:50 వరకు",
            GulikaiKalam: "13:52 నుండి 15:17 వరకు",
            Karanamulu: "బవ 07:36 వరకు, బాలవ 19:51 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "మృగశిర 04:58, జనవరి 23 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "08:14 నుండి 09:39 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:50",
            Sunset: "18:06",
            Thiti: "ద్వాదశి 19:51 వరకు",
            Yamaganda: "11:03 నుండి 12:28 వరకు",
            Yoga: "బ్రహ్మ 08:47 వరకు",
            Varjyam: "09:44 నుండి 11:24 వరకు",
            Moonrise: "15:04",
            Moonset: "04:51, జనవరి 23",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:05 నుండి 12:51 వరకు",
            Amrutakalam: "19:50 నుండి 21:31 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం",
            Date: "మంగళవారము, జనవరి 23, 2024",
            DurMuhurtamulu:
              "09:05 నుండి 09:50 వరకు, 23:12 నుండి 00:02, జనవరి 24 వరకు",
            GulikaiKalam: "12:28 నుండి 13:53 వరకు",
            Karanamulu: "కౌలవ 08:12 వరకు, తైతిల 20:39 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "ఆరుద్ర 06:26, జనవరి 24 వరకు",
            MainNakshatra: "ఆరుద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:17 నుండి 16:42 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:50",
            Sunset: "18:06",
            Thiti: "త్రయోదశి 20:39 వరకు",
            Yamaganda: "09:39 నుండి 11:03 వరకు",
            Yoga: "ఐన్ద్ర 08:05 వరకు",
            Varjyam: "13:53 నుండి 15:35 వరకు",
            Moonrise: "15:59",
            Moonset: "05:45, జనవరి 24",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "05:41, జనవరి 25 నుండి 07:25, జనవరి 25 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం 01:47, జనవరి 25 వరకు",
            Date: "బుధవారము, జనవరి 24, 2024",
            DurMuhurtamulu: "12:06 నుండి 12:51 వరకు",
            GulikaiKalam: "11:04 నుండి 12:28 వరకు",
            Karanamulu: "బవ 09:11 వరకు, వణిజ 21:49 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "పునర్వసు నిండా రాత్రి వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:28 నుండి 13:53 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:50",
            Sunset: "18:07",
            Thiti: "చతుర్దశి 21:49 వరకు",
            Yamaganda: "08:14 నుండి 09:39 వరకు",
            Yoga: "వైధృతి 07:40 వరకు",
            Varjyam: "19:21 నుండి 21:05 వరకు",
            Moonrise: "16:55",
            Moonset: "06:34, జనవరి 25",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:06 నుండి 12:51 వరకు",
            Amrutakalam: "03:29, జనవరి 26 నుండి 05:14, జనవరి 26 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "గురువారము, జనవరి 25, 2024",
            DurMuhurtamulu: "10:35 నుండి 11:21 వరకు, 15:07 నుండి 15:52 వరకు",
            GulikaiKalam: "09:39 నుండి 11:04 వరకు",
            Karanamulu: "విష్టి 10:33 వరకు, బవ 23:23 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "పునర్వసు 08:16 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:53 నుండి 15:18 వరకు",
            Ruthuvu: "హేమంతఋతువు",
            Sunrise: "06:50",
            Sunset: "18:07",
            Thiti: "పౌర్ణమి 23:23 వరకు",
            Yamaganda: "06:50 నుండి 08:14 వరకు",
            Yoga: "విష్కుమ్భ 07:32 వరకు",
            Varjyam: "17:00 నుండి 18:45 వరకు",
            Moonrise: "17:50",
            Moonset: "ఏమిలేదు",
            Shaka: "1945 శోభకృత్",
            Amavasya: "pournami",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:06 నుండి 12:51 వరకు",
            Amrutakalam: "ఏమిలేదు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "శుక్రవారము, జనవరి 26, 2024",
            DurMuhurtamulu: "09:05 నుండి 09:50 వరకు, 12:51 నుండి 13:37 వరకు",
            GulikaiKalam: "08:14 నుండి 09:39 వరకు",
            Karanamulu: "బాలవ 12:18 వరకు, కౌలవ 01:19, జనవరి 27 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "పుష్యమి 10:28 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "11:04 నుండి 12:29 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:49",
            Sunset: "18:08",
            Thiti: "పాడ్యమి 01:19, జనవరి 27 వరకు",
            Yamaganda: "15:18 నుండి 16:43 వరకు",
            Yoga: "ప్రీతి 07:42 వరకు",
            Varjyam: "00:38, జనవరి 27 నుండి 02:24, జనవరి 27 వరకు",
            Moonrise: "18:43",
            Moonset: "07:19",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "26",
            LordImage: require("../images/holidays/0-8)Independenceday_Republic.png"),
          },
          {
            Abhijit: "12:06 నుండి 12:52 వరకు",
            Amrutakalam: "11:15 నుండి 13:01 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం 13:01 వరకు",
            Date: "శనివారము, జనవరి 27, 2024",
            DurMuhurtamulu: "06:49 నుండి 07:35 వరకు, 07:35 నుండి 08:20 వరకు",
            GulikaiKalam: "06:49 నుండి 08:14 వరకు",
            Karanamulu: "తైతిల 14:25 వరకు, బవ 03:36, జనవరి 28 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "ఆశ్లేష 13:01 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:39 నుండి 11:04 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:49",
            Sunset: "18:09",
            Thiti: "విదియ 03:36, జనవరి 28 వరకు",
            Yamaganda: "13:54 నుండి 15:19 వరకు",
            Yoga: "ఆయుష్మాన్ 08:09 వరకు",
            Varjyam: "02:27, జనవరి 28 నుండి 04:14, జనవరి 28 వరకు",
            Moonrise: "19:34",
            Moonset: "07:58",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:06 నుండి 12:52 వరకు",
            Amrutakalam: "13:11 నుండి 14:59 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం",
            Date: "ఆదివారము, జనవరి 28, 2024",
            DurMuhurtamulu: "16:38 నుండి 17:24 వరకు",
            GulikaiKalam: "15:19 నుండి 16:44 వరకు",
            Karanamulu: "వణిజ 16:51 వరకు, విష్టి 06:10, జనవరి 29 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "మఖ 15:53 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:44 నుండి 18:09 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:49",
            Sunset: "18:09",
            Thiti: "తదియ 06:10, జనవరి 29 వరకు",
            Yamaganda: "12:29 నుండి 13:54 వరకు",
            Yoga: "సౌభాగ్య 08:51 వరకు",
            Varjyam: "00:54, జనవరి 29 నుండి 02:42, జనవరి 29 వరకు",
            Moonrise: "20:23",
            Moonset: "08:34",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:52 వరకు",
            Amrutakalam: "11:44 నుండి 13:32 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం 01:44, జనవరి 30 వరకు",
            Date: "సోమవారము, జనవరి 29, 2024",
            DurMuhurtamulu: "12:52 నుండి 13:37 వరకు, 15:08 నుండి 15:53 వరకు",
            GulikaiKalam: "13:54 నుండి 15:19 వరకు",
            Karanamulu: "బవ 19:31 వరకు, బాలవ నిండా రాత్రి వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "పుబ్బ 18:57 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "08:14 నుండి 09:39 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:49",
            Sunset: "18:10",
            Thiti: "చవితి నిండా రాత్రి వరకు",
            Yamaganda: "11:04 నుండి 12:29 వరకు",
            Yoga: "శోభన 09:44 వరకు",
            Varjyam: "03:06, జనవరి 30 నుండి 04:54, జనవరి 30 వరకు",
            Moonrise: "21:10",
            Moonset: "09:07",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:52 వరకు",
            Amrutakalam: "13:57 నుండి 15:46 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య",
            Date: "మంగళవారము, జనవరి 30, 2024",
            DurMuhurtamulu:
              "09:05 నుండి 09:50 వరకు, 23:14 నుండి 00:04, జనవరి 31 వరకు",
            GulikaiKalam: "12:29 నుండి 13:55 వరకు",
            Karanamulu: "బాలవ 08:54 వరకు, కౌలవ 22:16 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "ఉత్తర 22:06 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:20 నుండి 16:45 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:49",
            Sunset: "18:10",
            Thiti: "చవితి 08:54 వరకు",
            Yamaganda: "09:39 నుండి 11:04 వరకు",
            Yoga: "అతిగణ్డ 10:43 వరకు",
            Varjyam: "ఏమిలేదు",
            Moonrise: "21:56",
            Moonset: "09:39",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "18:22 నుండి 20:10 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య",
            Date: "బుధవారము, జనవరి 31, 2024",
            DurMuhurtamulu: "12:07 నుండి 12:52 వరకు",
            GulikaiKalam: "11:04 నుండి 12:30 వరకు",
            Karanamulu: "తైతిల 11:36 వరకు, బవ 00:52, ఫిబ్రవరి 01 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "హస్త 01:08, ఫిబ్రవరి 01 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:30 నుండి 13:55 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:49",
            Sunset: "18:11",
            Thiti: "పంచమి 11:36 వరకు",
            Yamaganda: "08:14 నుండి 09:39 వరకు",
            Yoga: "సుకర్మా 11:41 వరకు",
            Varjyam: "07:34 నుండి 09:22 వరకు",
            Moonrise: "22:43",
            Moonset: "10:11",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
        ],
      },
      {
        day: [
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "20:42 నుండి 22:29 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య 14:32 వరకు",
            Date: "గురువారము, ఫిబ్రవరి 1, 2024",
            DurMuhurtamulu: "10:36 నుండి 11:21 వరకు, 15:09 నుండి 15:55 వరకు",
            GulikaiKalam: "09:39 నుండి 11:04 వరకు",
            Karanamulu: "వణిజ 14:03 వరకు, విష్టి 03:07, ఫిబ్రవరి 02 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "చిత్తా 03:49, ఫిబ్రవరి 02 వరకు",
            MainNakshatra: "చిత్తా",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:55 నుండి 15:20 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:48",
            Sunset: "18:11",
            Thiti: "షష్టి 14:03 వరకు",
            Yamaganda: "06:48 నుండి 08:14 వరకు",
            Yoga: "ధృతి 12:28 వరకు",
            Varjyam: "10:02 నుండి 11:48 వరకు",
            Moonrise: "23:31",
            Moonset: "10:43",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "20:22 నుండి 22:07 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల",
            Date: "శుక్రవారము, ఫిబ్రవరి 2, 2024",
            DurMuhurtamulu: "09:05 నుండి 09:50 వరకు, 12:53 నుండి 13:38 వరకు",
            GulikaiKalam: "08:14 నుండి 09:39 వరకు",
            Karanamulu: "బవ 16:02 వరకు, బాలవ 04:47, ఫిబ్రవరి 03 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "స్వాతి 05:57, ఫిబ్రవరి 03 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "11:04 నుండి 12:30 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:48",
            Sunset: "18:12",
            Thiti: "సప్తమి 16:02 వరకు",
            Yamaganda: "15:21 నుండి 16:46 వరకు",
            Yoga: "శూల 12:55 వరకు",
            Varjyam: "09:55 నుండి 11:39 వరకు",
            Moonrise: "00:21, ఫిబ్రవరి 03",
            Moonset: "11:17",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "22:02 నుండి 23:43 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల 01:04, ఫిబ్రవరి 04 వరకు",
            Date: "శనివారము, ఫిబ్రవరి 3, 2024",
            DurMuhurtamulu: "06:48 నుండి 07:33 వరకు, 07:33 నుండి 08:19 వరకు",
            GulikaiKalam: "06:48 నుండి 08:13 వరకు",
            Karanamulu: "కౌలవ 17:20 వరకు, తైతిల 05:41, ఫిబ్రవరి 04 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "విశాఖ నిండా రాత్రి వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:39 నుండి 11:04 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:48",
            Sunset: "18:12",
            Thiti: "అష్టమి 17:20 వరకు",
            Yamaganda: "13:56 నుండి 15:21 వరకు",
            Yoga: "గణ్డ 12:52 వరకు",
            Varjyam: "11:52 నుండి 13:34 వరకు",
            Moonrise: "01:15, ఫిబ్రవరి 04",
            Moonset: "11:55",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "21:15 నుండి 22:54 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "ఆదివారము, ఫిబ్రవరి 4, 2024",
            DurMuhurtamulu: "16:41 నుండి 17:27 వరకు",
            GulikaiKalam: "15:21 నుండి 16:47 వరకు",
            Karanamulu: "బవ 17:49 వరకు, వణిజ 05:44, ఫిబ్రవరి 05 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "విశాఖ 07:21 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:47 నుండి 18:13 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:48",
            Sunset: "18:13",
            Thiti: "నవమి 17:49 వరకు",
            Yamaganda: "12:30 నుండి 13:56 వరకు",
            Yoga: "వృద్ధి 12:13 వరకు",
            Varjyam: "11:26 నుండి 13:04 వరకు",
            Moonrise: "02:13, ఫిబ్రవరి 05",
            Moonset: "12:39",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "22:54 నుండి 22:54 నుండి 00:29, ఫిబ్రవరి 06 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "సోమవారము, ఫిబ్రవరి 5, 2024",
            DurMuhurtamulu: "12:53 నుండి 13:39 వరకు, 15:10 నుండి 15:56 వరకు",
            GulikaiKalam: "13:56 నుండి 15:22 వరకు",
            Karanamulu: "విష్టి 17:24 వరకు, బవ 04:52, ఫిబ్రవరి 06 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "అనూరాధ 07:54 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "08:13 నుండి 09:39 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:47",
            Sunset: "18:13",
            Thiti: "దశమి 17:24 వరకు",
            Yamaganda: "11:04 నుండి 12:30 వరకు",
            Yoga: "ధ్రువ 10:52 వరకు",
            Varjyam: "13:26 నుండి 15:00 వరకు",
            Moonrise: "03:15, ఫిబ్రవరి 06",
            Moonset: "13:28",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "00:21, ఫిబ్రవరి 07 నుండి 01:53, ఫిబ్రవరి 07 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము 07:35 వరకు",
            Date: "మంగళవారము, ఫిబ్రవరి 6, 2024",
            DurMuhurtamulu:
              "09:04 నుండి 09:50 వరకు, 23:15 నుండి 00:05, ఫిబ్రవరి 07 వరకు",
            GulikaiKalam: "12:30 నుండి 13:56 వరకు",
            Karanamulu: "బాలవ 16:07 వరకు, కౌలవ 03:10, ఫిబ్రవరి 07 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "జ్యేష్ఠ 07:35 వరకు, మూల 06:27, ఫిబ్రవరి 07 వరకు",
            MainNakshatra: "జ్యేష్ఠ, మూల",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:22 నుండి 16:48 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:47",
            Sunset: "18:14",
            Thiti: "ఏకాదశి 16:07 వరకు",
            Yamaganda: "09:39 నుండి 11:04 వరకు",
            Yoga: "వ్యాఘాత 08:50 వరకు",
            Varjyam:
              "15:12 నుండి 16:44 వరకు, 04:56, ఫిబ్రవరి 07 నుండి 06:27, ఫిబ్రవరి 07 వరకు",
            Moonrise: "04:17, ఫిబ్రవరి 07",
            Moonset: "14:25",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "00:11, ఫిబ్రవరి 08 నుండి 01:40, ఫిబ్రవరి 08 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "బుధవారము, ఫిబ్రవరి 7, 2024",
            DurMuhurtamulu: "12:07 నుండి 12:53 వరకు",
            GulikaiKalam: "11:04 నుండి 12:30 వరకు",
            Karanamulu: "తైతిల 14:02 వరకు, బవ 00:43, ఫిబ్రవరి 08 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "పూర్వాషాఢ 04:37, ఫిబ్రవరి 08 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:30 నుండి 13:56 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:47",
            Sunset: "18:14",
            Thiti: "ద్వాదశి 14:02 వరకు",
            Yamaganda: "08:13 నుండి 09:38 వరకు",
            Yoga: "వజ్ర 02:53, ఫిబ్రవరి 08 వరకు",
            Varjyam: "15:19 నుండి 16:48 వరకు",
            Moonrise: "05:18, ఫిబ్రవరి 08",
            Moonset: "15:28",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "20:28 నుండి 21:55 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు 10:04 వరకు",
            Date: "గురువారము, ఫిబ్రవరి 8, 2024",
            DurMuhurtamulu: "10:36 నుండి 11:22 వరకు, 15:11 నుండి 15:57 వరకు",
            GulikaiKalam: "09:38 నుండి 11:04 వరకు",
            Karanamulu: "వణిజ 11:17 వరకు, విష్టి 21:42 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "ఉత్తరాషాఢ 02:14, ఫిబ్రవరి 09 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:56 నుండి 15:23 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:46",
            Sunset: "18:15",
            Thiti: "త్రయోదశి 11:17 వరకు",
            Yamaganda: "06:46 నుండి 08:12 వరకు",
            Yoga: "సిద్ధి 23:10 వరకు",
            Varjyam:
              "11:49 నుండి 13:16 వరకు, 05:47, ఫిబ్రవరి 09 నుండి 07:12, ఫిబ్రవరి 09 వరకు",
            Moonrise: "06:14, ఫిబ్రవరి 09",
            Moonset: "16:35",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "14:17 నుండి 15:42 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము",
            Date: "శుక్రవారము, ఫిబ్రవరి 9, 2024",
            DurMuhurtamulu: "09:04 నుండి 09:50 వరకు, 12:53 నుండి 13:39 వరకు",
            GulikaiKalam: "08:12 నుండి 09:38 వరకు",
            Karanamulu: "శకుని 08:02 వరకు, చతుష్పాద 18:17 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "శ్రవణం 23:29 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "11:04 నుండి 12:30 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:46",
            Sunset: "18:15",
            Thiti: "చతుర్దశి 08:02 వరకు",
            Yamaganda: "15:23 నుండి 16:49 వరకు",
            Yoga: "వ్యతీపాత 19:07 వరకు",
            Varjyam: "03:00, ఫిబ్రవరి 10 నుండి 04:24, ఫిబ్రవరి 10 వరకు",
            Moonrise: "ఏమిలేదు",
            Moonset: "17:43",
            Shaka: "1945 శోభకృత్",
            Amavasya: "amavasya",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "11:26 నుండి 12:50 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము 10:02 వరకు",
            Date: "శనివారము, ఫిబ్రవరి 10, 2024",
            DurMuhurtamulu: "06:45 నుండి 07:31 వరకు, 07:31 నుండి 08:17 వరకు",
            GulikaiKalam: "06:45 నుండి 08:12 వరకు",
            Karanamulu: "కింస్తుఘ్న 14:38 వరకు, బవ 00:47, ఫిబ్రవరి 11 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "ధనిష్ఠ 20:34 వరకు",
            MainNakshatra: "ధనిష్ఠ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:38 నుండి 11:04 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:45",
            Sunset: "18:16",
            Thiti: "పాడ్యమి 00:47, ఫిబ్రవరి 11 వరకు",
            Yamaganda: "13:57 నుండి 15:23 వరకు",
            Yoga: "వారీయన 14:54 వరకు",
            Varjyam: "02:53, ఫిబ్రవరి 11 నుండి 04:18, ఫిబ్రవరి 11 వరకు",
            Moonrise: "07:05",
            Moonset: "18:49",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:54 వరకు",
            Amrutakalam: "11:19 నుండి 12:44 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము",
            Date: "ఆదివారము, ఫిబ్రవరి 11, 2024",
            DurMuhurtamulu: "16:44 నుండి 17:30 వరకు",
            GulikaiKalam: "15:23 నుండి 16:50 వరకు",
            Karanamulu: "బాలవ 10:57 వరకు, కౌలవ 21:09 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "శతభిషం 17:39 వరకు",
            MainNakshatra: "శతభిషం",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "16:50 నుండి 18:16 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:45",
            Sunset: "18:16",
            Thiti: "విదియ 21:09 వరకు",
            Yamaganda: "12:31 నుండి 13:57 వరకు",
            Yoga: "పరిఘ 10:39 వరకు",
            Varjyam: "23:20 నుండి 00:45, ఫిబ్రవరి 12 వరకు",
            Moonrise: "07:52",
            Moonset: "19:52",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:54 వరకు",
            Amrutakalam: "07:51 నుండి 09:16 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము 09:35 వరకు",
            Date: "సోమవారము, ఫిబ్రవరి 12, 2024",
            DurMuhurtamulu: "12:54 నుండి 13:40 వరకు, 15:12 నుండి 15:58 వరకు",
            GulikaiKalam: "13:57 నుండి 15:23 వరకు",
            Karanamulu: "తైతిల 07:24 వరకు, గర 17:44 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "పూర్వాభాద్ర 14:56 వరకు",
            MainNakshatra: "పూర్వాభాద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "08:11 నుండి 09:38 వరకు",
            Ruthuvu: "శిశిరఋతువు",
            Sunrise: "06:45",
            Sunset: "18:16",
            Thiti: "తదియ 17:44 వరకు",
            Yamaganda: "11:04 నుండి 12:31 వరకు",
            Yoga: "సిద్ధ 02:37, ఫిబ్రవరి 13 వరకు",
            Varjyam: "23:36 నుండి 01:02, ఫిబ్రవరి 13 వరకు",
            Moonrise: "08:35",
            Moonset: "20:53",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:54 వరకు",
            Amrutakalam: "08:15 నుండి 09:42 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం",
            Date: "మంగళవారము, ఫిబ్రవరి 13, 2024",
            DurMuhurtamulu:
              "09:03 నుండి 09:49 వరకు, 23:16 నుండి 00:05, ఫిబ్రవరి 14 వరకు",
            GulikaiKalam: "12:30 నుండి 13:57 వరకు",
            Karanamulu: "విష్టి 14:41 వరకు, బవ 01:21, ఫిబ్రవరి 14 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "ఉత్తరాభాద్ర 12:35 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:24 నుండి 16:50 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:44",
            Sunset: "18:17",
            Thiti: "చవితి 14:41 వరకు",
            Yamaganda: "09:37 నుండి 11:04 వరకు",
            Yoga: "సాధ్య 23:05 వరకు",
            Varjyam: "23:39 నుండి 01:07, ఫిబ్రవరి 14 వరకు",
            Moonrise: "09:15",
            Moonset: "21:52",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:54 వరకు",
            Amrutakalam:
              "08:30 నుండి 09:59 వరకు, 02:37, ఫిబ్రవరి 15 నుండి 04:08, ఫిబ్రవరి 15 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం 10:43 వరకు",
            Date: "బుధవారము, ఫిబ్రవరి 14, 2024",
            DurMuhurtamulu: "12:07 నుండి 12:54 వరకు",
            GulikaiKalam: "11:04 నుండి 12:30 వరకు",
            Karanamulu: "బాలవ 12:09 వరకు, కౌలవ 23:06 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "రేవతి 10:43 వరకు",
            MainNakshatra: "రేవతి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:30 నుండి 13:57 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:44",
            Sunset: "18:17",
            Thiti: "పంచమి 12:09 వరకు",
            Yamaganda: "08:10 నుండి 09:37 వరకు",
            Yoga: "శుభ 19:59 వరకు",
            Varjyam: "05:39, ఫిబ్రవరి 15 నుండి 07:09, ఫిబ్రవరి 15 వరకు",
            Moonrise: "09:56",
            Moonset: "22:51",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:54 వరకు",
            Amrutakalam: "04:06, ఫిబ్రవరి 16 నుండి 05:40, ఫిబ్రవరి 16 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం",
            Date: "గురువారము, ఫిబ్రవరి 15, 2024",
            DurMuhurtamulu: "10:35 నుండి 11:21 వరకు, 15:12 నుండి 15:59 వరకు",
            GulikaiKalam: "09:37 నుండి 11:04 వరకు",
            Karanamulu: "తైతిల 10:12 వరకు, గర 21:28 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "అశ్విని 09:26 వరకు",
            MainNakshatra: "అశ్విని",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:57 నుండి 15:24 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:43",
            Sunset: "18:18",
            Thiti: "షష్టి 10:12 వరకు",
            Yamaganda: "06:43 నుండి 08:10 వరకు",
            Yoga: "శుక్ల 17:23 వరకు",
            Varjyam: "18:46 నుండి 20:19 వరకు",
            Moonrise: "10:38",
            Moonset: "23:50",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:54 వరకు",
            Amrutakalam: "06:22, ఫిబ్రవరి 17 నుండి 07:58, ఫిబ్రవరి 17 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం 14:43 వరకు",
            Date: "శుక్రవారము, ఫిబ్రవరి 16, 2024",
            DurMuhurtamulu: "09:02 నుండి 09:48 వరకు, 12:54 నుండి 13:40 వరకు",
            GulikaiKalam: "08:10 నుండి 09:37 వరకు",
            Karanamulu: "వణిజ 08:54 వరకు, విష్టి 20:30 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "భరణి 08:47 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "11:03 నుండి 12:30 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:43",
            Sunset: "18:18",
            Thiti: "సప్తమి 08:54 వరకు",
            Yamaganda: "15:24 నుండి 16:51 వరకు",
            Yoga: "బ్రహ్మ 15:18 వరకు",
            Varjyam: "20:46 నుండి 22:22 వరకు",
            Moonrise: "11:22",
            Moonset: "00:50, ఫిబ్రవరి 17",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:54 వరకు",
            Amrutakalam: "06:06, ఫిబ్రవరి 18 నుండి 07:44, ఫిబ్రవరి 18 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం",
            Date: "శనివారము, ఫిబ్రవరి 17, 2024",
            DurMuhurtamulu: "06:42 నుండి 07:29 వరకు, 07:29 నుండి 08:15 వరకు",
            GulikaiKalam: "06:42 నుండి 08:09 వరకు",
            Karanamulu: "బవ 08:15 వరకు,బాలవ 20:11 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "కృత్తిక 08:46 వరకు",
            MainNakshatra: "కృత్తిక",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:36 నుండి 11:03 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:42",
            Sunset: "18:18",
            Thiti: "అష్టమి 08:15 వరకు",
            Yamaganda: "13:57 నుండి 15:24 వరకు",
            Yoga: "ఐన్ద్ర 13:44 వరకు",
            Varjyam: "01:10, ఫిబ్రవరి 18 నుండి 02:49, ఫిబ్రవరి 18 వరకు",
            Moonrise: "12:09",
            Moonset: "01:49, ఫిబ్రవరి 18",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "01:19, ఫిబ్రవరి 19 నుండి 03:00, ఫిబ్రవరి 19 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం 21:54 వరకు",
            Date: "ఆదివారము, ఫిబ్రవరి 18, 2024",
            DurMuhurtamulu: "16:46 నుండి 17:32 వరకు",
            GulikaiKalam: "15:25 నుండి 16:52 వరకు",
            Karanamulu: "కౌలవ 08:15 వరకు, తైతిల 20:28 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "రోహిణి 09:23 వరకు",
            MainNakshatra: "రోహిణి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "16:52 నుండి 18:19 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:42",
            Sunset: "18:19",
            Thiti: "నవమి 08:15 వరకు",
            Yamaganda: "12:30 నుండి 13:57 వరకు",
            Yoga: "వైధృతి 12:39 వరకు",
            Varjyam: "15:15 నుండి 16:56 వరకు",
            Moonrise: "13:00",
            Moonset: "16:19",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "01:31, ఫిబ్రవరి 20 నుండి 03:14, ఫిబ్రవరి 20 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం",
            Date: "సోమవారము, ఫిబ్రవరి 19, 2024",
            DurMuhurtamulu: "12:53 నుండి 13:40 వరకు, 15:13 నుండి 16:00 వరకు",
            GulikaiKalam: "13:57 నుండి 15:25 వరకు",
            Karanamulu: "బవ 08:49 వరకు, వణిజ 21:19 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "మృగశిర 10:33 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "08:08 నుండి 09:36 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:41",
            Sunset: "18:19",
            Thiti: "దశమి 08:49 వరకు",
            Yamaganda: "11:03 నుండి 12:30 వరకు",
            Yoga: "విష్కుమ్భ 12:01 వరకు",
            Varjyam: "19:32 నుండి 21:14 వరకు",
            Moonrise: "13:54",
            Moonset: "03:42, ఫిబ్రవరి 20",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "ఏమిలేదు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం",
            Date: "మంగళవారము, ఫిబ్రవరి 20, 2024",
            DurMuhurtamulu:
              "09:00 నుండి 09:47 వరకు, 23:16 నుండి 00:05, ఫిబ్రవరి 21 వరకు",
            GulikaiKalam: "12:30 నుండి 13:57 వరకు",
            Karanamulu: "విష్టి 09:55 వరకు,  బవ 22:38 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "ఆరుద్ర 12:13 వరకు",
            MainNakshatra: "ఆరుద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:25 నుండి 16:52 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:41",
            Sunset: "18:20",
            Thiti: "ఏకాదశి 09:55 వరకు",
            Yamaganda: "09:35 నుండి 11:03 వరకు",
            Yoga: "ప్రీతి 11:46 వరకు",
            Varjyam: "01:15, ఫిబ్రవరి 21 నుండి 02:59, ఫిబ్రవరి 21 వరకు",
            Moonrise: "14:49",
            Moonset: "04:32, ఫిబ్రవరి 21",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "11:41 నుండి 13:25 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం 07:44 వరకు",
            Date: "బుధవారము, ఫిబ్రవరి 21, 2024",
            DurMuhurtamulu: "12:07 నుండి 12:53 వరకు",
            GulikaiKalam: "11:02 నుండి 12:30 వరకు",
            Karanamulu: "బాలవ 11:27 వరకు, కౌలవ 00:21, ఫిబ్రవరి 22 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "పునర్వసు 14:18 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:30 నుండి 13:57 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:40",
            Sunset: "18:20",
            Thiti: "ద్వాదశి 11:27 వరకు",
            Yamaganda: "08:07 నుండి 09:35 వరకు",
            Yoga: "ఆయుష్మాన్ 11:51 వరకు",
            Varjyam: "23:06 నుండి 00:52, ఫిబ్రవరి 22 వరకు",
            Moonrise: "15:45",
            Moonset: "05:17, ఫిబ్రవరి 22",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:06 నుండి 12:53 వరకు",
            Amrutakalam: "09:40 నుండి 11:26 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "గురువారము, ఫిబ్రవరి 22, 2024",
            DurMuhurtamulu: "10:33 నుండి 11:20 వరకు, 15:13 నుండి 16:00 వరకు",
            GulikaiKalam: "09:35 నుండి 11:02 వరకు",
            Karanamulu: "తైతిల 13:21 వరకు, బవ 02:25, ఫిబ్రవరి 23 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "పుష్యమి 16:43 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:57 నుండి 15:25 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:39",
            Sunset: "18:20",
            Thiti: "త్రయోదశి 13:21 వరకు",
            Yamaganda: "06:39 నుండి 08:07 వరకు",
            Yoga: "సౌభాగ్య 12:13 వరకు",
            Varjyam: "ఏమిలేదు",
            Moonrise: "16:38",
            Moonset: "05:58, ఫిబ్రవరి 23",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:06 నుండి 12:53 వరకు",
            Amrutakalam: "17:39 నుండి 19:25 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం 19:25 వరకు",
            Date: "శుక్రవారము, ఫిబ్రవరి 23, 2024",
            DurMuhurtamulu: "08:59 నుండి 09:46 వరకు, 12:53 నుండి 13:40 వరకు",
            GulikaiKalam: "08:07 నుండి 09:34 వరకు",
            Karanamulu: "వణిజ 15:33 వరకు, విష్టి 04:45, ఫిబ్రవరి 24 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "ఆశ్లేష 19:25 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "11:02 నుండి 12:30 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:39",
            Sunset: "18:21",
            Thiti: "చతుర్దశి 15:33 వరకు",
            Yamaganda: "15:25 నుండి 16:53 వరకు",
            Yoga: "శోభన 12:48 వరకు",
            Varjyam: "06:58 నుండి 08:44 వరకు",
            Moonrise: "17:30",
            Moonset: "06:35, ఫిబ్రవరి 24",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:06 నుండి 12:53 వరకు",
            Amrutakalam: "19:39 నుండి 21:27 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం",
            Date: "శనివారము, ఫిబ్రవరి 24, 2024",
            DurMuhurtamulu: "06:38 నుండి 07:25 వరకు, 07:25 నుండి 08:12 వరకు",
            GulikaiKalam: "06:38 నుండి 08:06 వరకు",
            Karanamulu: "బవ 17:59 వరకు, బాలవ నిండా రాత్రి వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "మఖ 22:20 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:34 నుండి 11:02 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:38",
            Sunset: "18:21",
            Thiti: "పౌర్ణమి 17:59 వరకు",
            Yamaganda: "13:57 నుండి 15:25 వరకు",
            Yoga: "అతిగణ్డ 13:35 వరకు",
            Varjyam: "08:53 నుండి 10:41 వరకు",
            Moonrise: "18:19",
            Moonset: "ఏమిలేదు",
            Shaka: "1945 శోభకృత్",
            Amavasya: "pournami",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:06 నుండి 12:53 వరకు",
            Amrutakalam: "18:11 నుండి 19:59 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం",
            Date: "ఆదివారము, ఫిబ్రవరి 25, 2024",
            DurMuhurtamulu: "16:47 నుండి 17:34 వరకు",
            GulikaiKalam: "15:25 నుండి 16:53 వరకు",
            Karanamulu: "బాలవ 07:16 వరకు, కౌలవ 20:35 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "పుబ్బ 01:24, ఫిబ్రవరి 26 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:53 నుండి 18:21 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:38",
            Sunset: "18:21",
            Thiti: "పాడ్యమి 20:35 వరకు",
            Yamaganda: "12:29 నుండి 13:57 వరకు",
            Yoga: "సుకర్మా 14:29 వరకు",
            Varjyam: "07:22 నుండి 09:10 వరకు",
            Moonrise: "19:06",
            Moonset: "07:08",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:06 నుండి 12:53 వరకు",
            Amrutakalam: "20:23 నుండి 22:11 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం 08:11 వరకు",
            Date: "సోమవారము, ఫిబ్రవరి 26, 2024",
            DurMuhurtamulu: "12:53 నుండి 13:40 వరకు, 15:14 నుండి 16:01 వరకు",
            GulikaiKalam: "13:57 నుండి 15:25 వరకు",
            Karanamulu: "తైతిల 09:55 వరకు, బవ 23:15 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "ఉత్తర 04:31, ఫిబ్రవరి 27 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "08:05 నుండి 09:33 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:37",
            Sunset: "18:22",
            Thiti: "విదియ 23:15 వరకు",
            Yamaganda: "11:01 నుండి 12:29 వరకు",
            Yoga: "ధృతి 15:27 వరకు",
            Varjyam: "09:32 నుండి 11:21 వరకు",
            Moonrise: "19:53",
            Moonset: "07:41",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:06 నుండి 12:53 వరకు",
            Amrutakalam: "00:47, ఫిబ్రవరి 28 నుండి 02:36, ఫిబ్రవరి 28 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య",
            Date: "మంగళవారము, ఫిబ్రవరి 27, 2024",
            DurMuhurtamulu:
              "08:57 నుండి 09:44 వరకు, 23:15 నుండి 00:04, ఫిబ్రవరి 28 వరకు",
            GulikaiKalam: "12:29 నుండి 13:57 వరకు",
            Karanamulu: "వణిజ 12:35 వరకు, విష్టి 01:53, ఫిబ్రవరి 28 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "హస్త నిండా రాత్రి వరకు",
            MainNakshatra: "హస్త",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:25 నుండి 16:54 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:36",
            Sunset: "18:22",
            Thiti: "తదియ 01:53, ఫిబ్రవరి 28 వరకు",
            Yamaganda: "09:33 నుండి 11:01 వరకు",
            Yoga: "శూల 16:25 వరకు",
            Varjyam: "13:58 నుండి 15:47 వరకు",
            Moonrise: "20:39",
            Moonset: "08:12",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "03:13, ఫిబ్రవరి 29 నుండి 05:00, ఫిబ్రవరి 29 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య 21:00 వరకు",
            Date: "బుధవారము, ఫిబ్రవరి 28, 2024",
            DurMuhurtamulu: "12:05 నుండి 12:52 వరకు",
            GulikaiKalam: "11:01 నుండి 12:29 వరకు",
            Karanamulu: "బవ 15:07 వరకు, బాలవ 04:18, ఫిబ్రవరి 29 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "హస్త 07:33 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:29 నుండి 13:57 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:36",
            Sunset: "18:22",
            Thiti: "చవితి 04:18, ఫిబ్రవరి 29 వరకు",
            Yamaganda: "08:04 నుండి 09:32 వరకు",
            Yoga: "గణ్డ 17:17 వరకు",
            Varjyam: "16:29 నుండి 18:17 వరకు",
            Moonrise: "21:26",
            Moonset: "08:44",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:05 నుండి 12:52 వరకు",
            Amrutakalam: "03:07, మార్చి 01 నుండి 04:53, మార్చి 01 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల",
            Date: "గురువారము, ఫిబ్రవరి 29, 2024",
            DurMuhurtamulu: "10:31 నుండి 11:18 వరకు, 15:14 నుండి 16:01 వరకు",
            GulikaiKalam: "09:32 నుండి 11:00 వరకు",
            Karanamulu: "కౌలవ 17:23 వరకు, తైతిల 06:21, మార్చి 01 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "చిత్తా 10:22 వరకు",
            MainNakshatra: "చిత్తా",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:57 నుండి 15:26 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:35",
            Sunset: "18:22",
            Thiti: "పంచమి 06:21, మార్చి 01 వరకు",
            Yamaganda: "06:35 నుండి 08:03 వరకు",
            Yoga: "వృద్ధి 17:56 వరకు",
            Varjyam: "16:32 నుండి 18:18 వరకు",
            Moonrise: "22:16",
            Moonset: "09:17",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
        ],
      },
      {
        day: [
          {
            Abhijit: "12:05 నుండి 12:52 వరకు",
            Amrutakalam: "05:12, మార్చి 02 నుండి 06:56, మార్చి 02 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల",
            Date: "శుక్రవారము, మార్చి 1, 2024",
            DurMuhurtamulu: "08:56 నుండి 09:43 వరకు, 12:52 నుండి 13:39 వరకు",
            GulikaiKalam: "08:03 నుండి 09:31 వరకు",
            Karanamulu: "బవ 19:12 వరకు, వణిజ నిండా రాత్రి వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "స్వాతి 12:48 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "11:00 నుండి 12:29 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:34",
            Sunset: "18:23",
            Thiti: "షష్టి నిండా రాత్రి వరకు",
            Yamaganda: "15:26 నుండి 16:54 వరకు",
            Yoga: "ధ్రువ 18:15 వరకు",
            Varjyam: "18:51 నుండి 20:35 వరకు",
            Moonrise: "23:08",
            Moonset: "09:53",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:05 నుండి 12:52 వరకు",
            Amrutakalam: "04:59, మార్చి 03 నుండి 06:40, మార్చి 03 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల 08:17 వరకు",
            Date: "శనివారము, మార్చి 2, 2024",
            DurMuhurtamulu: "06:34 నుండి 07:21 వరకు, 07:21 నుండి 08:08 వరకు",
            GulikaiKalam: "06:34 నుండి 08:02 వరకు",
            Karanamulu: "వణిజ 07:53 వరకు, విష్టి 20:24 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "విశాఖ 14:42 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:31 నుండి 11:00 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:34",
            Sunset: "18:23",
            Thiti: "షష్టి 07:53 వరకు",
            Yamaganda: "13:57 నుండి 15:26 వరకు",
            Yoga: "వ్యాఘాత 18:07 వరకు",
            Varjyam: "18:54 నుండి 20:35 వరకు",
            Moonrise: "00:03, మార్చి 03",
            Moonset: "10:33",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:04 నుండి 12:52 వరకు",
            Amrutakalam: "ఏమిలేదు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "ఆదివారము, మార్చి 3, 2024",
            DurMuhurtamulu: "16:49 నుండి 17:36 వరకు",
            GulikaiKalam: "15:26 నుండి 16:54 వరకు",
            Karanamulu: "బవ 08:44 వరకు,బాలవ 20:53 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "అనూరాధ 15:55 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:54 నుండి 18:23 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:33",
            Sunset: "18:23",
            Thiti: "సప్తమి 08:44 వరకు",
            Yamaganda: "12:28 నుండి 13:57 వరకు",
            Yoga: "హర్షణ 17:25 వరకు",
            Varjyam: "21:37 నుండి 23:15 వరకు",
            Moonrise: "01:01, మార్చి 04",
            Moonset: "11:19",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:04 నుండి 12:52 వరకు",
            Amrutakalam: "07:24 నుండి 09:02 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము 16:21 వరకు",
            Date: "సోమవారము, మార్చి 4, 2024",
            DurMuhurtamulu: "12:52 నుండి 13:39 వరకు, 15:14 నుండి 16:01 వరకు",
            GulikaiKalam: "13:57 నుండి 15:26 వరకు",
            Karanamulu: "కౌలవ 08:49 వరకు, తైతిల 20:32 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "జ్యేష్ఠ 16:21 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "08:01 నుండి 09:30 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:32",
            Sunset: "18:24",
            Thiti: "అష్టమి 08:49 వరకు",
            Yamaganda: "10:59 నుండి 12:28 వరకు",
            Yoga: "వజ్ర 16:06 వరకు",
            Varjyam: "00:14, మార్చి 05 నుండి 01:49, మార్చి 05 వరకు",
            Moonrise: "02:01, మార్చి 05",
            Moonset: "12:11",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:04 నుండి 12:51 వరకు",
            Amrutakalam: "09:42 నుండి 11:16 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "మంగళవారము, మార్చి 5, 2024",
            DurMuhurtamulu:
              "08:54 నుండి 09:41 వరకు, 23:15 నుండి 00:03, మార్చి 06 వరకు",
            GulikaiKalam: "12:28 నుండి 13:57 వరకు",
            Karanamulu:
              "బవ 08:04 వరకు, వణిజ 19:23 వరకు, విష్టి 06:30, మార్చి 06 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "మూల 16:00 వరకు",
            MainNakshatra: "మూల",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:26 నుండి 16:55 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:32",
            Sunset: "18:24",
            Thiti: "నవమి 08:04 వరకు",
            Yamaganda: "09:30 నుండి 10:59 వరకు",
            Yoga: "సిద్ధి 14:09 వరకు",
            Varjyam:
              "14:25 నుండి 16:00 వరకు, 01:09, మార్చి 06 నుండి 02:40, మార్చి 06 వరకు",
            Moonrise: "03:01, మార్చి 06",
            Moonset: "13:10",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "10:17 నుండి 11:49 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు 20:28 వరకు",
            Date: "బుధవారము, మార్చి 6, 2024",
            DurMuhurtamulu: "12:04 నుండి 12:51 వరకు",
            GulikaiKalam: "10:58 నుండి 12:27 వరకు",
            Karanamulu: "బవ 17:27 వరకు, బాలవ 04:13, మార్చి 07 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "పూర్వాషాఢ 14:52 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:27 నుండి 13:57 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:31",
            Sunset: "18:24",
            Thiti: "ఏకాదశి 04:13, మార్చి 07 వరకు",
            Yamaganda: "08:00 నుండి 09:29 వరకు",
            Yoga: "వ్యతీపాత 11:33 వరకు",
            Varjyam: "22:16 నుండి 23:44 వరకు",
            Moonrise: "03:58, మార్చి 07",
            Moonset: "14:13",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:03 నుండి 12:51 వరకు",
            Amrutakalam:
              "07:08 నుండి 08:37 వరకు, 01:18, మార్చి 08 నుండి 02:45, మార్చి 08 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము",
            Date: "గురువారము, మార్చి 7, 2024",
            DurMuhurtamulu: "10:28 నుండి 11:16 వరకు, 15:14 నుండి 16:01 వరకు",
            GulikaiKalam: "09:29 నుండి 10:58 వరకు",
            Karanamulu: "కౌలవ 14:50 వరకు, తైతిల 01:19, మార్చి 08 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "ఉత్తరాషాఢ 13:03 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:56 నుండి 15:26 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:30",
            Sunset: "18:24",
            Thiti: "ద్వాదశి 01:19, మార్చి 08 వరకు",
            Yamaganda: "06:30 నుండి 07:59 వరకు",
            Yoga: "వారీయన 08:24 వరకు",
            Varjyam: "16:39 నుండి 18:06 వరకు",
            Moonrise: "04:50, మార్చి 08",
            Moonset: "15:19",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:03 నుండి 12:51 వరకు",
            Amrutakalam: "22:43 నుండి 00:08, మార్చి 09 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము 21:20 వరకు",
            Date: "శుక్రవారము, మార్చి 8, 2024",
            DurMuhurtamulu: "08:52 నుండి 09:40 వరకు, 12:51 నుండి 13:38 వరకు",
            GulikaiKalam: "07:59 నుండి 09:28 వరకు",
            Karanamulu: "బవ 11:41 వరకు, వణిజ 21:57 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "శ్రవణం 10:41 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:58 నుండి 12:27 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:29",
            Sunset: "18:25",
            Thiti: "త్రయోదశి 21:57 వరకు",
            Yamaganda: "15:26 నుండి 16:55 వరకు",
            Yoga: "శివ 00:46, మార్చి 09 వరకు",
            Varjyam: "14:13 నుండి 15:38 వరకు",
            Moonrise: "05:39, మార్చి 09",
            Moonset: "16:25",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "8",
            LordImage: require("../images/holidays/2-4)Shiva.png"),
            // LordImage1: require("../images/holidays/2-1)Womendsday.png"),
          },
          {
            Abhijit: "12:03 నుండి 12:51 వరకు",
            Amrutakalam: "22:38 నుండి 00:02, మార్చి 10 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము",
            Date: "శనివారము, మార్చి 9, 2024",
            DurMuhurtamulu: "06:29 నుండి 07:16 వరకు, 07:16 నుండి 08:04 వరకు",
            GulikaiKalam: "06:29 నుండి 07:58 వరకు",
            Karanamulu: "విష్టి 08:09 వరకు, శకుని 18:17 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "ధనిష్ఠ 07:55 వరకు, శతభిషం 04:56, మార్చి 10 వరకు",
            MainNakshatra: "ధనిష్ఠ, శతభిషం",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:28 నుండి 10:57 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:29",
            Sunset: "18:25",
            Thiti: "చతుర్దశి 18:17 వరకు",
            Yamaganda: "13:56 నుండి 15:26 వరకు",
            Yoga: "సిద్ధ 20:32 వరకు",
            Varjyam: "14:13 నుండి 15:37 వరకు",
            Moonrise: "06:23, మార్చి 10",
            Moonset: "17:30",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:03 నుండి 12:50 వరకు",
            Amrutakalam: "18:55 నుండి 20:19 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము 20:40 వరకు",
            Date: "ఆదివారము, మార్చి 10, 2024",
            DurMuhurtamulu: "16:49 నుండి 17:37 వరకు",
            GulikaiKalam: "15:26 నుండి 16:55 వరకు",
            Karanamulu: "నాగ 14:29 వరకు, కింస్తుఘ్న 00:36, మార్చి 11 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "పూర్వాభాద్ర 01:55, మార్చి 11 వరకు",
            MainNakshatra: "పూర్వాభాద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:55 నుండి 18:25 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:28",
            Sunset: "18:25",
            Thiti: "అమావాస్య 14:29 వరకు",
            Yamaganda: "12:26 నుండి 13:56 వరకు",
            Yoga: "సాధ్య 16:14 వరకు",
            Varjyam: "10:32 నుండి 11:55 వరకు",
            Moonrise: "ఏమిలేదు",
            Moonset: "18:32",
            Shaka: "1945 శోభకృత్",
            Amavasya: "amavasya",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:02 నుండి 12:50 వరకు",
            Amrutakalam: "18:49 నుండి 20:13 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం",
            Date: "సోమవారము, మార్చి 11, 2024",
            DurMuhurtamulu: "12:50 నుండి 13:38 వరకు, 15:14 నుండి 16:02 వరకు",
            GulikaiKalam: "13:56 నుండి 15:26 వరకు",
            Karanamulu: "బవ 10:44 వరకు, బాలవ 20:56 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "ఉత్తరాభాద్ర 23:02 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:57 నుండి 09:27 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:27",
            Sunset: "18:25",
            Thiti: "పాడ్యమి 10:44 వరకు",
            Yamaganda: "10:56 నుండి 12:26 వరకు",
            Yoga: "శుభ 11:58 వరకు",
            Varjyam: "10:22 నుండి 11:46 వరకు",
            Moonrise: "07:06",
            Moonset: "19:34",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:02 నుండి 12:50 వరకు",
            Amrutakalam: "18:21 నుండి 19:46 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం 20:29 వరకు",
            Date: "మంగళవారము, మార్చి 12, 2024",
            DurMuhurtamulu:
              "08:50 నుండి 09:38 వరకు, 23:13 నుండి 00:01, మార్చి 13 వరకు",
            GulikaiKalam: "12:26 నుండి 13:56 వరకు",
            Karanamulu: "కౌలవ 07:13 వరకు, తైతిల 17:34 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "రేవతి 20:29 వరకు",
            MainNakshatra: "రేవతి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:26 నుండి 16:56 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:26",
            Sunset: "18:25",
            Thiti: "విదియ 07:13 వరకు",
            Yamaganda: "09:26 నుండి 10:56 వరకు",
            Yoga: "శుక్ల 07:53 వరకు",
            Varjyam: "09:46 నుండి 11:12 వరకు",
            Moonrise: "07:47",
            Moonset: "20:35",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "11:50 నుండి 13:18 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం",
            Date: "బుధవారము, మార్చి 13, 2024",
            DurMuhurtamulu: "12:02 నుండి 12:50 వరకు",
            GulikaiKalam: "10:56 నుండి 12:26 వరకు",
            Karanamulu: "వణిజ 14:40 వరకు, విష్టి 01:25, మార్చి 14 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "అశ్విని 18:24 వరకు",
            MainNakshatra: "అశ్విని",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:26 నుండి 13:56 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:26",
            Sunset: "18:26",
            Thiti: "చవితి 01:25, మార్చి 14 వరకు",
            Yamaganda: "07:56 నుండి 09:26 వరకు",
            Yoga: "ఐన్ద్ర 00:49, మార్చి 14 వరకు",
            Varjyam:
              "14:45 నుండి 16:13 వరకు, 03:25, మార్చి 14 నుండి 04:55, మార్చి 14 వరకు",
            Moonrise: "08:30",
            Moonset: "21:36",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:01 నుండి 12:49 వరకు",
            Amrutakalam: "12:25 నుండి 13:55 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం 22:40 వరకు",
            Date: "గురువారము, మార్చి 14, 2024",
            DurMuhurtamulu: "10:25 నుండి 11:13 వరకు, 15:14 నుండి 16:02 వరకు",
            GulikaiKalam: "09:25 నుండి 10:55 వరకు",
            Karanamulu: "బవ 12:20 వరకు, బాలవ 23:25 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "భరణి 16:56 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:55 నుండి 15:26 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:25",
            Sunset: "18:26",
            Thiti: "పంచమి 23:25 వరకు",
            Yamaganda: "06:25 నుండి 07:55 వరకు",
            Yoga: "వైధృతి 22:00 వరకు",
            Varjyam: "04:32, మార్చి 15 నుండి 06:05, మార్చి 15 వరకు",
            Moonrise: "09:14",
            Moonset: "22:38",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:01 నుండి 12:49 వరకు",
            Amrutakalam: "13:49 నుండి 15:22 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం",
            Date: "శుక్రవారము, మార్చి 15, 2024",
            DurMuhurtamulu: "08:48 నుండి 09:37 వరకు, 12:49 నుండి 13:37 వరకు",
            GulikaiKalam: "07:54 నుండి 09:25 వరకు",
            Karanamulu: "కౌలవ 10:42 వరకు, తైతిల 22:09 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "కృత్తిక 16:08 వరకు",
            MainNakshatra: "కృత్తిక",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:55 నుండి 12:25 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:24",
            Sunset: "18:26",
            Thiti: "షష్టి 22:09 వరకు",
            Yamaganda: "15:26 నుండి 16:56 వరకు",
            Yoga: "విష్కుమ్భ 19:46 వరకు",
            Varjyam: "ఏమిలేదు",
            Moonrise: "10:02",
            Moonset: "23:40",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:01 నుండి 12:49",
            Amrutakalam: "12:54 నుండి 14:30 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం 04:21, మార్చి 17 వరకు",
            Date: "శనివారము, మార్చి 16, 2024",
            DurMuhurtamulu: "06:23 నుండి 07:11 వరకు, 07:11 నుండి 08:00 వరకు",
            GulikaiKalam: "06:23 నుండి 07:54 వరకు",
            Karanamulu: "గర 09:48 వరకు, వణిజ 21:38 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "రోహిణి 16:05 వరకు",
            MainNakshatra: "రోహిణి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:24 నుండి 10:54 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:23",
            Sunset: "18:26",
            Thiti: "సప్తమి 21:38 వరకు",
            Yamaganda: "13:55 నుండి 15:26 వరకు",
            Yoga: "ప్రీతి 18:08 వరకు",
            Varjyam: "08:06 నుండి 09:42 వరకు, 21:51 నుండి 23:30 వరకు",
            Moonrise: "10:53",
            Moonset: "00:40, మార్చి 17",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:00 నుండి 12:49 వరకు",
            Amrutakalam: "07:44 నుండి 09:23 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం",
            Date: "ఆదివారము, మార్చి 17, 2024",
            DurMuhurtamulu: "16:50 నుండి 17:38 వరకు",
            GulikaiKalam: "15:25 నుండి 16:56 వరకు",
            Karanamulu: "విష్టి 09:40 వరకు, బవ 21:52 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "మృగశిర 16:47 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "16:56 నుండి 18:26 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:22",
            Sunset: "18:26",
            Thiti: "అష్టమి 21:52 వరకు",
            Yamaganda: "12:24 నుండి 13:55 వరకు",
            Yoga: "ఆయుష్మాన్ 17:06 వరకు",
            Varjyam: "01:40, మార్చి 18 నుండి 03:22, మార్చి 18 వరకు",
            Moonrise: "11:48",
            Moonset: "01:37, మార్చి 18",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:00 నుండి 12:48 వరకు",
            Amrutakalam: "07:36 నుండి 09:17 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం",
            Date: "సోమవారము, మార్చి 18, 2024",
            DurMuhurtamulu: "12:48 నుండి 13:37 వరకు, 15:13 నుండి 16:02 వరకు",
            GulikaiKalam: "13:55 నుండి 15:25 వరకు",
            Karanamulu: "బాలవ 10:15 వరకు, కౌలవ 22:49 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "ఆరుద్ర 18:10 వరకు",
            MainNakshatra: "ఆరుద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:52 నుండి 09:23 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:22",
            Sunset: "18:27",
            Thiti: "నవమి 22:49 వరకు",
            Yamaganda: "10:54 నుండి 12:24 వరకు",
            Yoga: "సౌభాగ్య 16:37 వరకు",
            Varjyam: "ఏమిలేదు",
            Moonrise: "12:44",
            Moonset: "02:29, మార్చి 19",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:00 నుండి 12:48 వరకు",
            Amrutakalam: "17:34 నుండి 19:18 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం 13:37 వరకు",
            Date: "మంగళవారము, మార్చి 19, 2024",
            DurMuhurtamulu: "08:46 నుండి 09:34 వరకు, 23:12 నుండి 24:00 వరకు",
            GulikaiKalam: "12:24 నుండి 13:55 వరకు",
            Karanamulu: "తైతిల 11:31 వరకు, బవ 00:21, మార్చి 20 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "పునర్వసు 20:10 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:25 నుండి 16:56 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:21",
            Sunset: "18:27",
            Thiti: "దశమి 00:21, మార్చి 20 వరకు",
            Yamaganda: "09:22 నుండి 10:53 వరకు",
            Yoga: "శోభన 16:37 వరకు",
            Varjyam:
              "07:10 నుండి 08:54 వరకు, 04:59, మార్చి 20 నుండి 06:45, మార్చి 20 వరకు",
            Moonrise: "13:40",
            Moonset: "03:16, మార్చి 20",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "15:35 నుండి 17:20 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "బుధవారము, మార్చి 20, 2024",
            DurMuhurtamulu: "11:59 నుండి 12:48 వరకు",
            GulikaiKalam: "10:53 నుండి 12:24 వరకు",
            Karanamulu: "వణిజ 13:19 వరకు, విష్టి 02:22, మార్చి 21 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "పుష్యమి 22:38 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:24 నుండి 13:54 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:20",
            Sunset: "18:27",
            Thiti: "ఏకాదశి 02:22, మార్చి 21 వరకు",
            Yamaganda: "07:51 నుండి 09:22 వరకు",
            Yoga: "అతిగణ్డ 17:01 వరకు",
            Varjyam: "ఏమిలేదు",
            Moonrise: "14:34",
            Moonset: "03:58, మార్చి 21",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:59 నుండి 12:48 వరకు",
            Amrutakalam: "23:40 నుండి 01:27, మార్చి 22 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం 01:27, మార్చి 22 వరకు",
            Date: "గురువారము, మార్చి 21, 2024",
            DurMuhurtamulu: "10:22 నుండి 11:10 వరకు, 15:13 నుండి 16:02 వరకు",
            GulikaiKalam: "09:21 నుండి 10:52 వరకు",
            Karanamulu: "బవ 15:31 వరకు, బాలవ 04:44, మార్చి 22 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "ఆశ్లేష 01:27, మార్చి 22 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:54 నుండి 15:25 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:19",
            Sunset: "18:27",
            Thiti: "ద్వాదశి 04:44, మార్చి 22 వరకు",
            Yamaganda: "06:19 నుండి 07:50 వరకు",
            Yoga: "సుకర్మా 17:42 వరకు",
            Varjyam: "12:56 నుండి 14:43 వరకు",
            Moonrise: "15:26",
            Moonset: "04:36, మార్చి 22",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:59 నుండి 12:47 వరకు",
            Amrutakalam: "01:46, మార్చి 23 నుండి 03:34, మార్చి 23 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం",
            Date: "శుక్రవారము, మార్చి 22, 2024",
            DurMuhurtamulu: "08:44 నుండి 09:33 వరకు, 12:47 నుండి 13:36 వరకు",
            GulikaiKalam: "07:50 నుండి 09:21 వరకు",
            Karanamulu: "కౌలవ 17:59 వరకు, తైతిల నిండా రాత్రి వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "మఖ 04:28, మార్చి 23 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:52 నుండి 12:23 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:19",
            Sunset: "18:27",
            Thiti: "త్రయోదశి నిండా రాత్రి వరకు",
            Yamaganda: "15:25 నుండి 16:56 వరకు",
            Yoga: "ధృతి 18:36 వరకు",
            Varjyam: "14:57 నుండి 16:45 వరకు",
            Moonrise: "16:16",
            Moonset: "05:10, మార్చి 23",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:58 నుండి 12:47 వరకు",
            Amrutakalam: "00:20, మార్చి 24 నుండి 02:09, మార్చి 24 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం",
            Date: "శనివారము, మార్చి 23, 2024",
            DurMuhurtamulu: "06:18 నుండి 07:06 వరకు, 07:06 నుండి 07:55 వరకు,",
            GulikaiKalam: "06:18 నుండి 07:49 వరకు",
            Karanamulu: "తైతిల 07:17 వరకు, బవ 20:36 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "పుబ్బ నిండా రాత్రి వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:20 నుండి 10:51 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:18",
            Sunset: "18:28",
            Thiti: "త్రయోదశి 07:17 వరకు",
            Yamaganda: "13:54 నుండి 15:25 వరకు",
            Yoga: "శూల 19:35 వరకు",
            Varjyam: "13:30 నుండి 15:18 వరకు",
            Moonrise: "17:03",
            Moonset: "05:43, మార్చి 24",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:58 నుండి 12:47 వరకు",
            Amrutakalam: "02:30, మార్చి 25 నుండి 04:19, మార్చి 25 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం 14:20 వరకు",
            Date: "ఆదివారము, మార్చి 24, 2024",
            DurMuhurtamulu: "16:50 నుండి 17:39 వరకు",
            GulikaiKalam: "15:25 నుండి 16:56 వరకు",
            Karanamulu: "వణిజ 09:54 వరకు, విష్టి 23:13 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "పుబ్బ 07:34 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "16:56 నుండి 18:28 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:17",
            Sunset: "18:28",
            Thiti: "చతుర్దశి 09:54 వరకు",
            Yamaganda: "12:22 నుండి 13:54 వరకు",
            Yoga: "గణ్డ 20:34 వరకు",
            Varjyam: "15:41 నుండి 17:29 వరకు",
            Moonrise: "17:50",
            Moonset: "06:14, మార్చి 25",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:58 నుండి 12:46 వరకు",
            Amrutakalam: "ఏమిలేదు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య",
            Date: "సోమవారము, మార్చి 25, 2024",
            DurMuhurtamulu: "12:46 నుండి 13:35 వరకు, 15:13 నుండి 16:02 వరకు",
            GulikaiKalam: "13:54 నుండి 15:25 వరకు",
            Karanamulu: "బవ 12:29 వరకు, బాలవ 01:44, మార్చి 26 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "ఉత్తర 10:38 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:48 నుండి 09:19 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:16",
            Sunset: "18:28",
            Thiti: "పౌర్ణమి 12:29 వరకు",
            Yamaganda: "10:51 నుండి 12:22 వరకు",
            Yoga: "వృద్ధి 21:30 వరకు",
            Varjyam: "20:03 నుండి 21:51 వరకు",
            Moonrise: "18:37",
            Moonset: "ఏమిలేదు",
            Shaka: "1945 శోభకృత్",
            Amavasya: "pournami",
            FestDate: "25",
            LordImage: require("../images/holidays/2-3)Holi.png"),
          },
          {
            Abhijit: "11:57 నుండి 12:46 వరకు",
            Amrutakalam: "06:50 నుండి 08:37 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య 02:57, మార్చి 27 వరకు",
            Date: "మంగళవారము, మార్చి 26, 2024",
            DurMuhurtamulu: "08:42 నుండి 09:31 వరకు, 23:11 నుండి 23:58 వరకు",
            GulikaiKalam: "12:22 నుండి 13:53 వరకు",
            Karanamulu: "కౌలవ 14:55 వరకు, తైతిల 04:03, మార్చి 27 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "హస్త 13:34 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:25 నుండి 16:57 వరకు",
            Ruthuvu: "శిశిరఋతువు",
            Sunrise: "06:15",
            Sunset: "18:28",
            Thiti: "పాడ్యమి 14:55 వరకు",
            Yamaganda: "09:19 నుండి 10:50 వరకు",
            Yoga: "ధ్రువ 22:18 వరకు",
            Varjyam: "22:28 నుండి 00:14, మార్చి 27 వరకు",
            Moonrise: "19:24",
            Moonset: "06:46",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "09:08 నుండి 10:55 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల",
            Date: "బుధవారము, మార్చి 27, 2024",
            DurMuhurtamulu: "11:57 నుండి 12:46 వరకు",
            GulikaiKalam: "10:50 నుండి 12:21 వరకు",
            Karanamulu: "బవ 17:06 వరకు, వణిజ 06:04, మార్చి 28 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "చిత్తా 16:16 వరకు",
            MainNakshatra: "చిత్తా",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:21 నుండి 13:53 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:15",
            Sunset: "18:28",
            Thiti: "విదియ 17:06 వరకు",
            Yamaganda: "07:46 నుండి 09:18 వరకు",
            Yoga: "వ్యాఘాత 22:54 వరకు",
            Varjyam: "22:25 నుండి 00:10, మార్చి 28 వరకు",
            Moonrise: "20:12",
            Moonset: "07:19",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:46 వరకు",
            Amrutakalam: "08:58 నుండి 10:43 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల",
            Date: "గురువారము, మార్చి 28, 2024",
            DurMuhurtamulu: "10:19 నుండి 11:08 వరకు, 15:13 నుండి 16:02 వరకు",
            GulikaiKalam: "09:17 నుండి 10:49 వరకు",
            Karanamulu: "విష్టి 18:56 వరకు, బవ నిండా రాత్రి వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "స్వాతి 18:38 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:53 నుండి 15:25 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:14",
            Sunset: "18:29",
            Thiti: "తదియ 18:56 వరకు",
            Yamaganda: "06:14 నుండి 07:46 వరకు",
            Yoga: "హర్షణ 23:13 వరకు",
            Varjyam: "00:42, మార్చి 29 నుండి 02:25, మార్చి 29 వరకు",
            Moonrise: "21:03",
            Moonset: "07:54",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:45 వరకు",
            Amrutakalam: "11:05 నుండి 12:49 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల 14:09 వరకు",
            Date: "శుక్రవారము, మార్చి 29, 2024",
            DurMuhurtamulu: "08:40 నుండి 09:29 వరకు, 12:45 నుండి 13:34 వరకు",
            GulikaiKalam: "07:45 నుండి 09:17 వరకు",
            Karanamulu: "బవ 07:42 వరకు, బాలవ 20:20 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "విశాఖ 20:36 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:49 నుండి 12:21 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:13",
            Sunset: "18:29",
            Thiti: "చవితి 20:20 వరకు",
            Yamaganda: "15:25 నుండి 16:57 వరకు",
            Yoga: "వజ్ర 23:12 వరకు",
            Varjyam: "00:50, మార్చి 30 నుండి 02:32, మార్చి 30 వరకు",
            Moonrise: "21:57",
            Moonset: "08:32",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "29",
            LordImage: require("../images/holidays/3-6)GoodFriday.png"),
          },
          {
            Abhijit: "11:56 నుండి 12:45",
            Amrutakalam: "11:02 నుండి 12:43 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "శనివారము, మార్చి 30, 2024",
            DurMuhurtamulu: "06:12 నుండి 07:01 వరకు, 07:01 నుండి 07:50 వరకు",
            GulikaiKalam: "06:12 నుండి 07:44 వరకు",
            Karanamulu: "కౌలవ 08:51 వరకు, తైతిల 21:13 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "అనూరాధ 22:03 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:16 నుండి 10:48 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:12",
            Sunset: "18:29",
            Thiti: "పంచమి 21:13 వరకు",
            Yamaganda: "13:53 నుండి 15:25 వరకు",
            Yoga: "సిద్ధి 22:47 వరకు",
            Varjyam: "03:52, మార్చి 31 నుండి 05:31, మార్చి 31 వరకు",
            Moonrise: "22:54",
            Moonset: "09:16",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:45 వరకు",
            Amrutakalam: "13:49 నుండి 15:29 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము 22:57 వరకు",
            Date: "ఆదివారము, మార్చి 31, 2024",
            DurMuhurtamulu: "16:51 నుండి 17:40 వరకు",
            GulikaiKalam: "15:25 నుండి 16:57 వరకు",
            Karanamulu: "బవ 09:26 వరకు, వణిజ 21:30 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "జ్యేష్ఠ 22:57 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:57 నుండి 18:29 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:11",
            Sunset: "18:29",
            Thiti: "షష్టి 21:30 వరకు",
            Yamaganda: "12:20 నుండి 13:53 వరకు",
            Yoga: "వ్యతీపాత 21:53 వరకు",
            Varjyam: "ఏమిలేదు",
            Moonrise: "23:53",
            Moonset: "10:05",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
        ],
      },
      {
        day: [
          {
            Abhijit: "11:55 నుండి 12:45 వరకు",
            Amrutakalam: "16:44 నుండి 18:21 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "సోమవారము, ఏప్రిల్ 1, 2024",
            DurMuhurtamulu: "12:29 నుండి 13:17 వరకు",
            GulikaiKalam: "13:52 నుండి 15:25 వరకు",
            Karanamulu: "విష్టి 09:25 వరకు, బవ 21:09 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "మూల 19:07 వరకు",
            MainNakshatra: "మూల",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "07:43 నుండి 09:15 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:11",
            Sunset: "18:29",
            Thiti: "సప్తమి 16:37 వరకు",
            Yamaganda: "10:48 నుండి 12:20 వరకు",
            Yoga: "వారీయన 20:30 వరకు",
            Varjyam: "17:33 నుండి 19:05 వరకు",
            Moonrise: "00:51, ఏప్రిల్ 02",
            Moonset: "11:00",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:55 నుండి 12:44 వరకు",
            Amrutakalam: "18:05 నుండి 19:40 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు 04:37, ఏప్రిల్ 03 వరకు",
            Date: "మంగళవారము, ఏప్రిల్ 2, 2024",
            DurMuhurtamulu: "08:22 నుండి 09:10 వరకు",
            GulikaiKalam: "12:20 నుండి 13:52 వరకు",
            Karanamulu: "బాలవ 08:44 వరకు, కౌలవ 20:08 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "పూర్వాషాఢ 18:37 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:25 నుండి 16:57 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:10",
            Sunset: "18:30",
            Thiti: "అష్టమి 15:27 వరకు",
            Yamaganda: "09:15 నుండి 10:47 వరకు",
            Yoga: "పరిఘ 18:36 వరకు",
            Varjyam: "02:20 నుండి 03:52 వరకు",
            Moonrise: "01:47, ఏప్రిల్ 03",
            Moonset: "12:00",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "15:40 నుండి 17:12 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము",
            Date: "బుధవారము, ఏప్రిల్ 3, 2024",
            DurMuhurtamulu: "11:39 నుండి 12:27 వరకు",
            GulikaiKalam: "10:47 నుండి 12:19 వరకు",
            Karanamulu: "తైతిల 07:23 వరకు, బవ 18:29 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "ఉత్తరాషాఢ 17:42 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:19 నుండి 13:52 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:09",
            Sunset: "18:30",
            Thiti: "నవమి 13:53 వరకు",
            Yamaganda: "07:42 నుండి 09:14 వరకు",
            Yoga: "శివ 16:10 వరకు",
            Varjyam: "21:31 నుండి, 22:59 వరకు",
            Moonrise: "02:40, ఏప్రిల్ 04",
            Moonset: "13:03",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:54 నుండి 12:44 వరకు",
            Amrutakalam: "10:29 నుండి 11:59 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము",
            Date: "గురువారము, ఏప్రిల్ 4, 2024",
            DurMuhurtamulu: "10:00 నుండి 10:48 వరకు",
            GulikaiKalam: "09:14 నుండి 10:46 వరకు",
            Karanamulu: "విష్టి 16:14 వరకు, బవ 02:54, ఏప్రిల్ 05 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "శ్రవణం 16:31 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:52 నుండి 15:25 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:08",
            Sunset: "18:30",
            Thiti: "దశమి 12:02 వరకు",
            Yamaganda: "06:08 నుండి 07:41 వరకు",
            Yoga: "సిద్ధ 13:16 వరకు",
            Varjyam: "20:18 నుండి 21:46 వరకు",
            Moonrise: "03:28, ఏప్రిల్ 05",
            Moonset: "14:07",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:54 నుండి 12:44 వరకు",
            Amrutakalam: "08:37 నుండి 10:04 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము 07:12 వరకు",
            Date: "శుక్రవారము, ఏప్రిల్ 5, 2024",
            DurMuhurtamulu: "08:21 నుండి 09:09 వరకు",
            GulikaiKalam: "07:40 నుండి 09:13 వరకు",
            Karanamulu: "బాలవ 13:28 వరకు, కౌలవ 23:56 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "ధనిష్ఠ 15:05 వరకు",
            MainNakshatra: "ధనిష్ఠ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:46 నుండి 12:19 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:07",
            Sunset: "18:30",
            Thiti: "ఏకాదశి 09:55 వరకు",
            Yamaganda: "15:25 నుండి 16:57 వరకు",
            Yoga: "సాధ్య 09:56 వరకు",
            Varjyam: "21:49 నుండి 11:17 వరకు",
            Moonrise: "04:13, ఏప్రిల్ 06",
            Moonset: "15:10",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "5",
            LordImage: require("../images/holidays/3-2)babu-jagjivan-ram-original-imafjz89pyrevwnu.png"),
          },
          {
            Abhijit: "11:54 నుండి 12:43 వరకు",
            Amrutakalam:
              "09:12 నుండి 10:38 వరకు, 05:52, ఏప్రిల్ 07 నుండి 07:17, ఏప్రిల్ 07 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము",
            Date: "శనివారము, ఏప్రిల్ 6, 2024",
            DurMuhurtamulu: "07:29 వరకు",
            GulikaiKalam: "06:07 నుండి 07:40 వరకు",
            Karanamulu: "తైతిల 10:19 వరకు, బవ 20:38 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "శతభిషం 13:29 వరకు",
            MainNakshatra: "శతభిషం",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:13 నుండి 10:46 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:07",
            Sunset: "18:30",
            Thiti: "ద్వాదశి 07:36 వరకు, త్రయోదశి 05:13 వరకు",
            Yamaganda: "13:52 నుండి 15:24 వరకు",
            Yoga: "శుభ 06:15 వరకు",
            Varjyam: "19:28 నుండి 20:56 వరకు",
            Moonrise: "04:55, ఏప్రిల్ 07",
            Moonset: "16:12",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:43 వరకు",
            Amrutakalam: "05:58, ఏప్రిల్ 08 నుండి, 07:23, ఏప్రిల్ 08 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము 07:39 వరకు",
            Date: "ఆదివారము, ఏప్రిల్ 7, 2024",
            DurMuhurtamulu: "16:35 నుండి 17:23 వరకు",
            GulikaiKalam: "15:24 నుండి 16:58 వరకు",
            Karanamulu: "వణిజ 06:53 వరకు, విష్టి 17:07 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "పూర్వాభాద్ర 11:51 వరకు",
            MainNakshatra: "పూర్వాభాద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:58 నుండి 18:31 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:06",
            Sunset: "18:31",
            Thiti: "చతుర్దశి 02:47 వరకు",
            Yamaganda: "12:18 నుండి 13:51 వరకు",
            Yoga: "బ్రహ్మ 22:17 వరకు",
            Varjyam: "20:48 నుండి 22:16 వరకు",
            Moonrise: "05:36, ఏప్రిల్ 08",
            Moonset: "17:13",
            Shaka: "1945 శోభకృత్",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:43 వరకు",
            Amrutakalam: "05:24, ఏప్రిల్ 09 నుండి, 06:49, ఏప్రిల్ 09 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం",
            Date: "సోమవారము, ఏప్రిల్ 8, 2024",
            DurMuhurtamulu: "10:27 నుండి 13:15 వరకు",
            GulikaiKalam: "13:51 నుండి 15:24 వరకు",
            Karanamulu: "చతుష్పాద 13:34 వరకు, నాగ 23:50 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "ఉత్తరాభాద్ర 10:10 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "07:38 నుండి 09:12 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:05",
            Sunset: "18:31",
            Thiti: "అమావాస్య 00:23 వరకు",
            Yamaganda: "10:45 నుండి 12:18 వరకు",
            Yoga: "ఐన్ద్ర 18:14 వరకు",
            Varjyam: "ఏమిలేదు",
            Moonrise: "ఏమిలేదు",
            Moonset: "18:14",
            Shaka: "1945 శోభకృత్",
            Amavasya: "amavasya",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:43 వరకు",
            Amrutakalam: "22:38 నుండి 00:04, ఏప్రిల్ 10 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం 07:32 వరకు",
            Date: "మంగళవారము, ఏప్రిల్ 9, 2024",
            DurMuhurtamulu: "08:23 నుండి 09:12 వరకు",
            GulikaiKalam: "12:18 నుండి 13:51 వరకు",
            Karanamulu: "కింస్తుఘ్న 10:08 వరకు, బవ 20:30 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "రేవతి 07:31 వరకు, అశ్విని 05:06 వరకు",
            MainNakshatra: "రేవతి, అశ్విని",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:24 నుండి 16:58 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "06:04",
            Sunset: "18:31",
            Thiti: "పాడ్యమి 20:31 వరకు",
            Yamaganda: "09:11 నుండి 10:44 వరకు",
            Yoga: "వైధృతి 14:18 వరకు",
            Varjyam: "01:30 నుండి 02:56 వరకు",
            Moonrise: "06:18",
            Moonset: "19:15",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "9",
            LordImage: require("../images/holidays/3-1)Ugadi.png"),
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "22:42 నుండి 00:10, ఏప్రిల్ 11 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం",
            Date: "బుధవారము, ఏప్రిల్ 10, 2024",
            DurMuhurtamulu: "11:53 నుండి 12:42 వరకు",
            GulikaiKalam: "10:44 నుండి 12:17 వరకు",
            Karanamulu: "బాలవ 06:58 వరకు, కౌలవ 17:32 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "భరణి 03:05, ఏప్రిల్ 11 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:17 నుండి 13:51 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "06:04",
            Sunset: "18:31",
            Thiti: "విదియ 17:32 వరకు",
            Yamaganda: "07:37 నుండి 09:11 వరకు",
            Yoga: "విష్కుమ్భ 10:38 వరకు",
            Varjyam: "13:54 నుండి 15:22 వరకు",
            Moonrise: "07:03",
            Moonset: "20:18",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "10",
            LordImage: require("../images/holidays/Jamat_Ul_Vida.png"),
          },
          {
            Abhijit: "11:52 నుండి 12:42 వరకు",
            Amrutakalam: "23:23 నుండి 00:53, ఏప్రిల్ 12 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం 08:40 వరకు",
            Date: "గురువారము, ఏప్రిల్ 11, 2024",
            DurMuhurtamulu: "10:00 నుండి 10:49 వరకు",
            GulikaiKalam: "09:10 నుండి 10:44 వరకు",
            Karanamulu: "గర 15:03 వరకు, వణిజ 02:02, ఏప్రిల్ 12 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "కృత్తిక 01:37 వరకు",
            MainNakshatra: "కృత్తిక",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:51 నుండి 15:24 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "06:03",
            Sunset: "18:31",
            Thiti: "తదియ 15:04 వరకు",
            Yamaganda: "06:03 నుండి 07:37 వరకు",
            Yoga: "ప్రీతి 07:19 వరకు",
            Varjyam: "14:21 నుండి 15:51 వరకు",
            Moonrise: "07:50",
            Moonset: "21:22",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:52 నుండి 12:42 వరకు",
            Amrutakalam: "21:45 నుండి 23:18 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం",
            Date: "శుక్రవారము, ఏప్రిల్ 12, 2024",
            DurMuhurtamulu: "08:21 నుండి 09:10 వరకు",
            GulikaiKalam: "07:36 నుండి 09:10 వరకు",
            Karanamulu: "విష్టి 13:11 వరకు, బవ 00:32, ఏప్రిల్ 13 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "రోహిణి 00:50 వరకు",
            MainNakshatra: "రోహిణి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:43 నుండి 12:17 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "06:02",
            Sunset: "18:32",
            Thiti: "చవితి 13:12 వరకు",
            Yamaganda: "15:24 నుండి 16:58 వరకు",
            Yoga: "సౌభాగ్య 02:13, ఏప్రిల్ 13 వరకు",
            Varjyam: "17:06 నుండి 18:39 వరకు",
            Moonrise: "08:41",
            Moonset: "22:26",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:52 నుండి 12:42 వరకు",
            Amrutakalam: "16:02 నుండి 17:38 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం 12:44 వరకు",
            Date: "శనివారము, ఏప్రిల్ 13, 2024",
            DurMuhurtamulu: "05:51 నుండి 07:30 వరకు",
            GulikaiKalam: "06:02 నుండి 07:35 వరకు",
            Karanamulu: "బాలవ 12:04 వరకు, కౌలవ 23:47 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "మృగశిర 00:48 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:09 నుండి 10:43 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "06:02",
            Sunset: "18:32",
            Thiti: "పంచమి 12:04 వరకు",
            Yamaganda: "13:50 నుండి 15:24 వరకు",
            Yoga: "శోభన 00:34, ఏప్రిల్ 14 వరకు",
            Varjyam: "06:26 నుండి 08:02 వరకు",
            Moonrise: "09:36",
            Moonset: "23:26",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:51 నుండి 12:41 వరకు",
            Amrutakalam: "15:16 నుండి 16:55 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం",
            Date: "ఆదివారము, ఏప్రిల్ 14, 2024",
            DurMuhurtamulu: "16:36 నుండి 17:25 వరకు",
            GulikaiKalam: "15:24 నుండి 16:58 వరకు",
            Karanamulu: "తైతిల 11:43 వరకు, గర 23:51 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "ఆరుద్ర 01:34 వరకు",
            MainNakshatra: "ఆరుద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "16:58 నుండి 18:32 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "06:01",
            Sunset: "18:32",
            Thiti: "షష్టి 11:44 వరకు",
            Yamaganda: "12:16 నుండి 13:50 వరకు",
            Yoga: "అతిగణ్డ 23:33 వరకు",
            Varjyam: "09:29 నుండి 11:08 వరకు",
            Moonrise: "10:34",
            Moonset: "00:22, ఏప్రిల్ 15",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "14",
            LordImage: require("../images/holidays/3-4)Ambedkar.png"),
          },
          {
            Abhijit: "11:51 నుండి 12:41 వరకు",
            Amrutakalam: "00:32, ఏప్రిల్ 16 నుండి, 02:14, ఏప్రిల్ 16 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం 20:39 వరకు",
            Date: "సోమవారము, ఏప్రిల్ 15, 2024",
            DurMuhurtamulu: "12:28 నుండి 13:17 వరకు",
            GulikaiKalam: "11:51 నుండి 12:41 వరకు",
            Karanamulu: "వణిజ 12:11 వరకు, విష్టి 00:42, ఏప్రిల్ 16 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "పునర్వసు 03:05 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:34 నుండి 09:08 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "06:00",
            Sunset: "18:32",
            Thiti: "సప్తమి 12:12 వరకు",
            Yamaganda: "10:42 నుండి 12:16 వరకు",
            Yoga: "సుకర్మా 23:09 వరకు",
            Varjyam: "14:20 నుండి 16:02 వరకు",
            Moonrise: "11:31",
            Moonset: "01:12, ఏప్రిల్ 16",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:51 నుండి 12:41 వరకు",
            Amrutakalam: "22:17 నుండి 00:02, ఏప్రిల్ 17 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "మంగళవారము, ఏప్రిల్ 16, 2024",
            DurMuhurtamulu: "08:19 నుండి 09:09 వరకు",
            GulikaiKalam: "12:16 నుండి 13:50 వరకు",
            Karanamulu: "బవ 13:23 వరకు, బాలవ 02:14, ఏప్రిల్ 17 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "పుష్యమి 05:15 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:24 నుండి 16:58 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:59",
            Sunset: "18:33",
            Thiti: "అష్టమి 13:24 వరకు",
            Yamaganda: "09:08 నుండి 10:42 వరకు",
            Yoga: "ధృతి 23:17 వరకు",
            Varjyam: "11:48 నుండి 13:33 వరకు",
            Moonrise: "12:27",
            Moonset: "01:56, ఏప్రిల్ 17",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "ఏమిలేదు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "బుధవారము, ఏప్రిల్ 17, 2024",
            DurMuhurtamulu: "11:38 నుండి 12:28 వరకు",
            GulikaiKalam: "10:41 నుండి 12:16 వరకు",
            Karanamulu: "కౌలవ 15:14 వరకు, తైతిల 04:20, ఏప్రిల్ 18 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "ఆశ్లేష నిండా రాత్రి వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:16 నుండి 13:50 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:59",
            Sunset: "18:33",
            Thiti: "నవమి 15:15 వరకు",
            Yamaganda: "07:33 నుండి 09:07 వరకు",
            Yoga: "శూల 23:51 వరకు",
            Varjyam: "19:29 నుండి 21:16 వరకు",
            Moonrise: "13:21",
            Moonset: "02:36, ఏప్రిల్ 18",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "17",
            LordImage: require("../images/holidays/3-3)Sriramanavami.png"),
          },
          {
            Abhijit: "11:50 నుండి 12:41 వరకు",
            Amrutakalam: "06:10 నుండి 07:57 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం 07:57 వరకు",
            Date: "గురువారము, ఏప్రిల్ 18, 2024",
            DurMuhurtamulu: "09:58 నుండి 10:48 వరకు",
            GulikaiKalam: "09:07 నుండి 10:41 వరకు",
            Karanamulu: "బవ 17:31 వరకు, వణిజ నిండా రాత్రి వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "ఆశ్లేష 07:56 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:50 నుండి 15:24 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:58",
            Sunset: "18:33",
            Thiti: "దశమి 17:32 వరకు",
            Yamaganda: "05:58 నుండి 07:32 వరకు",
            Yoga: "గణ్డ 00:44, ఏప్రిల్ 19 వరకు",
            Varjyam: "21:26 నుండి 23:14 వరకు",
            Moonrise: "14:11",
            Moonset: "03:11, ఏప్రిల్ 19",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:50 నుండి 12:41 వరకు",
            Amrutakalam: "08:15 నుండి 10:03 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం",
            Date: "శుక్రవారము, ఏప్రిల్ 19, 2024",
            DurMuhurtamulu: "08:18 నుండి 09:08 వరకు",
            GulikaiKalam: "07:32 నుండి 09:06 వరకు",
            Karanamulu: "వణిజ 06:46 వరకు, విష్టి 20:04 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "మఖ 10:56 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:41 నుండి 12:15 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:57",
            Sunset: "18:33",
            Thiti: "ఏకాదశి 20:05 వరకు",
            Yamaganda: "15:24 నుండి 16:59 వరకు",
            Yoga: "వృద్ధి 01:45, ఏప్రిల్ 20 వరకు",
            Varjyam: "19:59 నుండి 21:48 వరకు",
            Moonrise: "15:00",
            Moonset: "03:44, ఏప్రిల్ 20",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:50 నుండి 12:40 వరకు",
            Amrutakalam: "06:50 నుండి 08:39 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం 20:51 వరకు",
            Date: "శనివారము, ఏప్రిల్ 20, 2024",
            DurMuhurtamulu: "05:47 నుండి 07:27 వరకు",
            GulikaiKalam: "05:57 నుండి 07:31 వరకు",
            Karanamulu: "బవ 09:23 వరకు, బాలవ 22:41 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "పుబ్బ 14:04 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:06 నుండి 10:40 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:57",
            Sunset: "18:34",
            Thiti: "ద్వాదశి 22:42 వరకు",
            Yamaganda: "13:50 నుండి 15:24 వరకు",
            Yoga: "ధ్రువ 02:48, ఏప్రిల్ 21 వరకు",
            Varjyam: "22:11 నుండి 23:54 వరకు",
            Moonrise: "15:47",
            Moonset: "04:16, ఏప్రిల్ 21",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:50 నుండి 12:40 వరకు",
            Amrutakalam: "09:01 నుండి 10:49 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య",
            Date: "ఆదివారము, ఏప్రిల్ 21, 2024",
            DurMuhurtamulu: "16:53 నుండి 17:43 వరకు",
            GulikaiKalam: "16:36 నుండి 17:26 వరకు",
            Karanamulu: "కౌలవ 11:57 వరకు, తైతిల 01:11, ఏప్రిల్ 22 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "ఉత్తర 17:07 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "16:59 నుండి 18:34 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:56",
            Sunset: "18:34",
            Thiti: "త్రయోదశి 01:12 వరకు",
            Yamaganda: "12:15 నుండి 13:50 వరకు",
            Yoga: "వ్యాఘాత 03:45, ఏప్రిల్ 22 వరకు",
            Varjyam: "02:32 నుండి 04:19 వరకు",
            Moonrise: "16:33",
            Moonset: "04:47, ఏప్రిల్ 22",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:40 వరకు",
            Amrutakalam: "13:17 నుండి 15:04 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య",
            Date: "సోమవారము, ఏప్రిల్ 22, 2024",
            DurMuhurtamulu: "12:26 నుండి 13:15 వరకు",
            GulikaiKalam: "13:50 నుండి 15:24 వరకు",
            Karanamulu: "బవ 14:20 వరకు, వణిజ 03:25, ఏప్రిల్ 23 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "హస్త 19:59 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:30 నుండి 09:05 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:55",
            Sunset: "18:34",
            Thiti: "చతుర్దశి 03:26 వరకు",
            Yamaganda: "10:40 నుండి 12:15 వరకు",
            Yoga: "హర్షణ 04:29, ఏప్రిల్ 23 వరకు",
            Varjyam: "04:50 లగాయతు",
            Moonrise: "17:20",
            Moonset: "05:20, ఏప్రిల్ 23",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:40 వరకు",
            Amrutakalam: "15:27 నుండి 17:14 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య 09:19 వరకు",
            Date: "మంగళవారము, ఏప్రిల్ 23, 2024",
            DurMuhurtamulu: "08:15 నుండి 09:05 వరకు",
            GulikaiKalam: "12:15 నుండి 13:49 వరకు",
            Karanamulu: "విష్టి 16:25 వరకు, బవ 05:18, ఏప్రిల్ 24 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "చిత్త 22:31 వరకు",
            MainNakshatra: "చిత్త",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:24 నుండి 16:59 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:55",
            Sunset: "18:34",
            Thiti: "పౌర్ణమి 05:19 వరకు",
            Yamaganda: "09:05 నుండి 10:40 వరకు",
            Yoga: "వజ్ర 04:57, ఏప్రిల్ 24 వరకు",
            Varjyam: "శే.వ. 06:36 వరకు",
            Moonrise: "18:08",
            Moonset: "ఏమిలేదు",
            Shaka: "1946 క్రోధి",
            Amavasya: "pournami",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "15:06 నుండి 16:50 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల",
            Date: "బుధవారము, ఏప్రిల్ 24, 2024",
            DurMuhurtamulu: "11:36 నుండి 12:26 వరకు",
            GulikaiKalam: "10:39 నుండి 12:14 వరకు",
            Karanamulu: "బాలవ 18:05 వరకు, కౌలవ నిండా రాత్రి వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "స్వాతి 00:40 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:14 నుండి 13:49 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:54",
            Sunset: "18:35",
            Thiti: "పాడ్యమి నిండా రాత్రి వరకు",
            Yamaganda: "07:29 నుండి 09:04 వరకు",
            Yoga: "సిద్ధి 05:06, ఏప్రిల్ 25 వరకు",
            Varjyam: "శే.వ.06:22 వరకు",
            Moonrise: "18:59",
            Moonset: "05:54",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:40 వరకు",
            Amrutakalam: "16:58 నుండి 18:41 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల 20:01 వరకు",
            Date: "గురువారము, ఏప్రిల్ 25, 2024",
            DurMuhurtamulu: "09:55 నుండి 10:45 వరకు",
            GulikaiKalam: "09:04 నుండి 10:39 వరకు",
            Karanamulu: "కౌలవ 06:45 వరకు, తైతిల 19:19 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "విశాఖ 02:23 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:49 నుండి 15:24 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:54",
            Sunset: "18:35",
            Thiti: "పాడ్యమి 06:46 వరకు",
            Yamaganda: "05:54 నుండి 07:29 వరకు",
            Yoga: "వ్యతీపాత 04:54, ఏప్రిల్ 26 వరకు",
            Varjyam: "06:40 నుండి 08:24 వరకు",
            Moonrise: "19:52",
            Moonset: "06:32",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:39 వరకు",
            Amrutakalam: "16:43 నుండి 18:24 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "శుక్రవారము, ఏప్రిల్ 26, 2024",
            DurMuhurtamulu: "08:15 నుండి 09:05 వరకు",
            GulikaiKalam: "07:28 నుండి 09:03 వరకు",
            Karanamulu: "బవ 07:45 వరకు, వణిజ 20:05 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "అనూరాధ 03:39 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:39 నుండి 12:14 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:53",
            Sunset: "18:35",
            Thiti: "విదియ 07:47 వరకు",
            Yamaganda: "15:25 నుండి 17:00 వరకు",
            Yoga: "వారీయన 04:20, ఏప్రిల్ 27 వరకు",
            Varjyam: "06:46 నుండి 08:27 వరకు",
            Moonrise: "20:49",
            Moonset: "07:14",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:39 వరకు",
            Amrutakalam: "19:22 నుండి 21:01 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము 04:28, ఏప్రిల్ 28 వరకు",
            Date: "శనివారము, ఏప్రిల్ 27, 2024",
            DurMuhurtamulu: "05:543 నుండి 07:24 వరకు",
            GulikaiKalam: "05:52 నుండి 07:28 వరకు",
            Karanamulu: "విష్టి 08:17 వరకు, బవ 20:23 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "జ్యేష్ఠ 04:27 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:03 నుండి 10:38 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:52",
            Sunset: "18:35",
            Thiti: "తదియ 08:18 వరకు",
            Yamaganda: "13:49 నుండి 15:25 వరకు",
            Yoga: "పరిఘ 03:24, ఏప్రిల్ 28 వరకు",
            Varjyam: "09:25 నుండి 11:04 వరకు",
            Moonrise: "21:47",
            Moonset: "08:02",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:39 వరకు",
            Amrutakalam: "22:19 నుండి 23:57 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "ఆదివారము, ఏప్రిల్ 28, 2024",
            DurMuhurtamulu: "16:37 నుండి 17:27 వరకు",
            GulikaiKalam: "15:25 నుండి 17:00 వరకు",
            Karanamulu: "బాలవ 08:21 వరకు, కౌలవ 20:12 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "మూల 04:48 వరకు",
            MainNakshatra: "మూల",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "17:00 నుండి 18:36 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:52",
            Sunset: "18:36",
            Thiti: "చవితి 08:22 వరకు",
            Yamaganda: "12:14 నుండి 13:49 వరకు",
            Yoga: "శివ 02:06, ఏప్రిల్ 29 వరకు",
            Varjyam: "03:10 నుండి 04:47 వరకు",
            Moonrise: "22:46",
            Moonset: "08:56",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:39 వరకు",
            Amrutakalam: "23:56 నుండి 01:31, ఏప్రిల్ 30 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "సోమవారము, ఏప్రిల్ 29, 2024",
            DurMuhurtamulu: "12:25 నుండి 13:14 వరకు",
            GulikaiKalam: "13:49 నుండి 15:25 వరకు",
            Karanamulu: "తైతిల 07:57 వరకు, బవ 19:34 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "పూర్వాషాఢ 04:42 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "07:27 నుండి 09:02 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:51",
            Sunset: "18:36",
            Thiti: "పంచమి 07:58 వరకు",
            Yamaganda: "10:38 నుండి 12:14 వరకు",
            Yoga: "సిద్ధ 00:26, ఏప్రిల్ 30 వరకు",
            Varjyam: "14:21 నుండి 15:57 వరకు",
            Moonrise: "23:42",
            Moonset: "09:54",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:39 వరకు",
            Amrutakalam: "21:54 నుండి 23:28 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు 10:36 వరకు",
            Date: "మంగళవారము, ఏప్రిల్ 30, 2024",
            DurMuhurtamulu: "08:13 నుండి 09:04 వరకు",
            GulikaiKalam: "12:13 నుండి 13:49 వరకు",
            Karanamulu:
              "వణిజ 07:05 వరకు, విష్టి 18:28 వరకు, బవ 05:45, మే 01 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "ఉత్తరాషాఢ 04:09 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:25 నుండి 17:01 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:51",
            Sunset: "18:36",
            Thiti: "షష్టి 07:05 వరకు",
            Yamaganda: "09:02 నుండి 10:38 వరకు",
            Yoga: "సాధ్య 22:24 వరకు",
            Varjyam: "12:31 నుండి 14:05 వరకు",
            Moonrise: "00:35, మే 01",
            Moonset: "10:55",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
        ],
      },
      {
        day: [
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "17:12 నుండి 18:44 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము",
            Date: "బుధవారము, మే 1, 2024",
            DurMuhurtamulu: "11:35 నుండి 12:26 వరకు",
            GulikaiKalam: "10:38 నుండి 12:13 వరకు",
            Karanamulu: "బాలవ 16:56 వరకు, కౌలవ 04:01, మే 02 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "శ్రవణం 03:10 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:13 నుండి 13:49 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:50",
            Sunset: "18:37",
            Thiti: "సప్తమి 05:46 వరకు, అష్టమి 04:02 వరకు",
            Yamaganda: "07:26 నుండి 09:02 వరకు",
            Yoga: "శుభ 20:02 వరకు",
            Varjyam: "07:59 నుండి 09:31 వరకు",
            Moonrise: "01:23, మే 02",
            Moonset: "11:57",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "1",
            LordImage: require("../images/holidays/4-0)Labourday.png"),
          },
          {
            Abhijit: "11:48 నుండి 12:39 వరకు",
            Amrutakalam: "16:00 నుండి 17:31 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము 14:32 వరకు",
            Date: "గురువారము, మే 2, 2024",
            DurMuhurtamulu: "09:53 నుండి 10:44 వరకు",
            GulikaiKalam: "09:01 నుండి 10:37 వరకు",
            Karanamulu: "తైతిల 14:59 వరకు, బవ 01:52, మే 03 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "ధనిష్ఠ 01:48 వరకు",
            MainNakshatra: "ధనిష్ఠ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:49 నుండి 15:25 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:50",
            Sunset: "18:37",
            Thiti: "నవమి 01:52, మే 03 వరకు",
            Yamaganda: "05:50 నుండి 07:26 వరకు",
            Yoga: "శుక్ల 17:19 వరకు",
            Varjyam: "06:56 నుండి 08:27 వరకు",
            Moonrise: "02:08, మే 03",
            Moonset: "12:59",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:39 వరకు",
            Amrutakalam: "17:25 నుండి 18:54 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము",
            Date: "శుక్రవారము, మే 3, 2024",
            DurMuhurtamulu: "08:12 నుండి 09:03 వరకు",
            GulikaiKalam: "07:25 నుండి 09:01 వరకు",
            Karanamulu: "వణిజ 12:40 వరకు, విష్టి 23:24 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "శతభిషం 00:05 వరకు",
            MainNakshatra: "శతభిషం",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:37 నుండి 12:13 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:49",
            Sunset: "18:37",
            Thiti: "దశమి 23:24 వరకు",
            Yamaganda: "15:25 నుండి 17:01 వరకు",
            Yoga: "బ్రహ్మ 14:19 వరకు",
            Varjyam: "08:29 నుండి 09:58 వరకు",
            Moonrise: "02:49, మే 04",
            Moonset: "13:59",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "14:47 నుండి 16:15 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము 16:38 వరకు",
            Date: "శనివారము, మే 4, 2024",
            DurMuhurtamulu: "05:39 నుండి 07:20 వరకు",
            GulikaiKalam: "05:49 నుండి 07:25 వరకు",
            Karanamulu: "బవ 10:03 వరకు, బాలవ 20:38 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "పూర్వాభాద్ర 22:06 వరకు",
            MainNakshatra: "పూర్వాభాద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:01 నుండి 10:37 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:49",
            Sunset: "18:37",
            Thiti: "ఏకాదశి 20:39 వరకు",
            Yamaganda: "13:49 నుండి 15:25 వరకు",
            Yoga: "ఐన్ద్ర 11:04 వరకు",
            Varjyam: "05:59 నుండి 07:25 వరకు",
            Moonrise: "03:29, మే 05",
            Moonset: "14:58",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "15:35 నుండి 17:02 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం",
            Date: "ఆదివారము, మే 5, 2024",
            DurMuhurtamulu: "16:38 నుండి 17:29 వరకు",
            GulikaiKalam: "15:25 నుండి 17:02 వరకు",
            Karanamulu: "కౌలవ 07:11 వరకు, తైతిల 17:41 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "ఉత్తరాభాద్ర 19:57 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "17:02 నుండి 18:38 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:48",
            Sunset: "18:38",
            Thiti: "ద్వాదశి 17:42 వరకు",
            Yamaganda: "12:13 నుండి 13:49 వరకు",
            Yoga: "వైధృతి 07:37 వరకు",
            Varjyam: "06:50 నుండి 08:17 వరకు",
            Moonrise: "04:10, మే 06",
            Moonset: "15:56",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "15:32 నుండి 16:59 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం 17:43 వరకు",
            Date: "సోమవారము, మే 6, 2024",
            DurMuhurtamulu: "12:24 నుండి 13:15 వరకు",
            GulikaiKalam: "13:49 నుండి 15:25 వరకు",
            Karanamulu: "వణిజ 14:40 వరకు, విష్టి 01:09, మే 07 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "రేవతి 17:42 వరకు",
            MainNakshatra: "రేవతి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "07:24 నుండి 09:00 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:48",
            Sunset: "18:38",
            Thiti: "త్రయోదశి 14:40 వరకు",
            Yamaganda: "10:37 నుండి 12:13 వరకు",
            Yoga: "ప్రీతి 00:29, మే 07 వరకు",
            Varjyam: "06:50 నుండి 08:17 వరకు",
            Moonrise: "04:52, మే 07",
            Moonset: "16:56",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "08:59 నుండి 10:27 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం",
            Date: "మంగళవారము, మే 7, 2024",
            DurMuhurtamulu: "08:11 నుండి 09:02 వరకు",
            GulikaiKalam: "12:13 నుండి 13:49 వరకు",
            Karanamulu: "శకుని 11:40 వరకు, చతుష్పాద 22:14 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "అశ్విని 15:32 వరకు",
            MainNakshatra: "అశ్విని",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:26 నుండి 17:02 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:47",
            Sunset: "18:38",
            Thiti: "చతుర్దశి 11:41 వరకు",
            Yamaganda: "09:00 నుండి 10:36 వరకు",
            Yoga: "ఆయుష్మాన్ 20:59 వరకు",
            Varjyam: "11:53 నుండి 13:20 వరకు",
            Moonrise: "05:37, మే 08",
            Moonset: "17:58",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "09:09 నుండి 10:37 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం 19:07 వరకు",
            Date: "బుధవారము, మే 8, 2024",
            DurMuhurtamulu: "11:34 నుండి 12:25 వరకు",
            GulikaiKalam: "10:36 నుండి 12:13 వరకు",
            Karanamulu: "నాగ 08:51 వరకు, కింస్తుఘ్న 19:33 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "భరణి 13:33 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:13 నుండి 13:49 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:47",
            Sunset: "18:39",
            Thiti: "అమావాస్య 08:52 వరకు",
            Yamaganda: "07:23 నుండి 09:00 వరకు",
            Yoga: "సౌభాగ్య 17:41 వరకు",
            Varjyam: "00:44 నుండి 02:14 వరకు",
            Moonrise: "ఏమిలేదు",
            Moonset: "19:01",
            Shaka: "1946 క్రోధి",
            Amavasya: "amavasya",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:38 వరకు",
            Amrutakalam: "09:41 నుండి 11:11 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం",
            Date: "గురువారము, మే 9, 2024",
            DurMuhurtamulu: "03:09 నుండి 04:40 వరకు",
            GulikaiKalam: "09:00 నుండి 10:36 వరకు",
            Karanamulu: "బవ 06:21 వరకు, బాలవ 17:15 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "కృత్తిక 11:55 వరకు",
            MainNakshatra: "కృత్తిక",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:49 నుండి 15:26 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:46",
            Sunset: "18:39",
            Thiti: "పాడ్యమి 06:21 వరకు, విదియ 04:18 వరకు",
            Yamaganda: "05:46 నుండి 07:23 వరకు",
            Yoga: "శోభన 14:42 వరకు",
            Varjyam: "03:09 నుండి 04:40 వరకు",
            Moonrise: "06:27",
            Moonset: "20:06",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:38 వరకు",
            Amrutakalam:
              "07:44 నుండి 09:15 వరకు, 01:39, మే 11 నుండి 03:13, మే 11 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం 22:26 వరకు",
            Date: "శుక్రవారము, మే 10, 2024",
            DurMuhurtamulu: "08:10 నుండి 09:01 వరకు",
            GulikaiKalam: "07:23 నుండి 08:59 వరకు",
            Karanamulu: "తైతిల 15:29 వరకు, గర 02:50, మే 11 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "రోహిణి 10:46 వరకు",
            MainNakshatra: "రోహిణి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:36 నుండి 12:13 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:46",
            Sunset: "18:39",
            Thiti: "తదియ 02:50 వరకు",
            Yamaganda: "15:26 నుండి 17:03 వరకు",
            Yoga: "అతిగణ్డ 12:07 వరకు",
            Varjyam: "16:14 నుండి 17:48 వరకు",
            Moonrise: "07:21",
            Moonset: "21:09",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:38 వరకు",
            Amrutakalam: "00:22, మే 12 నుండి , 01:59, మే 12 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం",
            Date: "శనివారము, మే 11, 2024",
            DurMuhurtamulu: "05:46 నుండి 06:37 వరకు, 06:37 నుండి 07:29 వరకు",
            GulikaiKalam: "05:46 నుండి 07:22 వరకు",
            Karanamulu: "వణిజ 14:21 వరకు, విష్టి 02:03, మే 12 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "మృగశిర 10:15 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "08:59 నుండి 10:36 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:46",
            Sunset: "18:40",
            Thiti: "చవితి 02:04 వరకు",
            Yamaganda: "13:49 నుండి 15:26 వరకు",
            Yoga: "సుకర్మా 10:03 వరకు",
            Varjyam: "18:43 నుండి 20:20 వరకు",
            Moonrise: "08:19",
            Moonset: "22:09",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:38 వరకు",
            Amrutakalam: "ఏమిలేదు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం 05:05, మే 13 వరకు",
            Date: "ఆదివారము, మే 12, 2024",
            DurMuhurtamulu: "16:40 నుండి 17:31 వరకు",
            GulikaiKalam: "15:26 నుండి 17:03 వరకు",
            Karanamulu: "బవ 13:57 వరకు, బాలవ 02:03, మే 13 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "ఆరుద్ర 10:26 వరకు",
            MainNakshatra: "ఆరుద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "17:03 నుండి 18:40 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:45",
            Sunset: "18:40",
            Thiti: "పంచమి 02:04 వరకు",
            Yamaganda: "12:13 నుండి 13:49 వరకు",
            Yoga: "ధృతి 08:34 వరకు",
            Varjyam: "22:55 నుండి 00:35, మే 13 వరకు",
            Moonrise: "09:18",
            Moonset: "23:03",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:38 వరకు",
            Amrutakalam: "08:54 నుండి 10:34 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "సోమవారము, మే 13, 2024",
            DurMuhurtamulu: "12:25 నుండి 13:16 వరకు",
            GulikaiKalam: "13:50 నుండి 15:27 వరకు",
            Karanamulu: "కౌలవ 14:21 వరకు, తైతిల 02:50, మే 14 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "పునర్వసు 11:23 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:22 నుండి 08:59 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:45",
            Sunset: "18:40",
            Thiti: "షష్టి 02:51 వరకు",
            Yamaganda: "10:36 నుండి 12:13 వరకు",
            Yoga: "శూల 07:42 వరకు",
            Varjyam: "19:57 నుండి 21:40 వరకు",
            Moonrise: "10:16",
            Moonset: "23:51",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "06:14 నుండి 07:57 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "మంగళవారము, మే 14, 2024",
            DurMuhurtamulu: "08:09 నుండి 09:00 వరకు",
            GulikaiKalam: "12:13 నుండి 13:50 వరకు",
            Karanamulu: "గర 15:29 వరకు, వణిజ 04:19, మే 15 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "పుష్యమి 13:04 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:27 నుండి 17:04 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:44",
            Sunset: "18:41",
            Thiti: "సప్తమి 04:20 వరకు",
            Yamaganda: "08:59 నుండి 10:36 వరకు",
            Yoga: "గణ్డ 07:26 వరకు",
            Varjyam: "03:07 నుండి 04:52 వరకు",
            Moonrise: "11:12",
            Moonset: "00:33, మే 15",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "13:40 నుండి 15:25 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం 15:25 వరకు",
            Date: "బుధవారము, మే 15, 2024",
            DurMuhurtamulu: "11:33 నుండి 12:24 వరకు",
            GulikaiKalam: "10:36 నుండి 12:13 వరకు",
            Karanamulu: "విష్టి 17:17 వరకు, బవ నిండా రాత్రి వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "ఆశ్లేష 15:24 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:13 నుండి 13:50 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:44",
            Sunset: "18:41",
            Thiti: "అష్టమి నిండా రాత్రి వరకు",
            Yamaganda: "07:21 నుండి 08:58 వరకు",
            Yoga: "వృద్ధి 07:42 వరకు",
            Varjyam: "16:49 నుండి 18:36 వరకు",
            Moonrise: "12:04",
            Moonset: "01:10, మే 16",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "15:33 నుండి 17:20 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం",
            Date: "గురువారము, మే 16, 2024",
            DurMuhurtamulu: "09:51 నుండి 10:42 వరకు",
            GulikaiKalam: "08:58 నుండి 10:35 వరకు",
            Karanamulu: "బవ 06:22 వరకు, బాలవ 19:33 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "మఖ 18:13 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:50 నుండి 15:27 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:44",
            Sunset: "18:41",
            Thiti: "అష్టమి 06:23 వరకు",
            Yamaganda: "05:44 నుండి 07:21 వరకు",
            Yoga: "ధ్రువ 08:23 వరకు",
            Varjyam: "03:14 నుండి 05:02 వరకు",
            Moonrise: "12:54",
            Moonset: "01:44, మే 17",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "14:05 నుండి 15:53 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం 04:05, మే 18 వరకు",
            Date: "శుక్రవారము, మే 17, 2024",
            DurMuhurtamulu: "20:09 నుండి 21:00 వరకు",
            GulikaiKalam: "07:21 నుండి 08:58 వరకు",
            Karanamulu: "కౌలవ 08:48 వరకు, తైతిల 22:05 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "పుబ్బ 21:17 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:35 నుండి 12:13 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:44",
            Sunset: "18:42",
            Thiti: "నవమి 08:49 వరకు",
            Yamaganda: "15:27 నుండి 17:05 వరకు",
            Yoga: "వ్యాఘాత 09:21 వరకు",
            Varjyam: "05:25 లగాయతు",
            Moonrise: "13:41",
            Moonset: "02:16, మే 18",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "16:16 నుండి 18:04 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య",
            Date: "శనివారము, మే 18, 2024",
            DurMuhurtamulu: "05:34 నుండి 07:17 వరకు",
            GulikaiKalam: "05:43 నుండి 07:21 వరకు",
            Karanamulu: "బవ 11:22 వరకు, వణిజ 00:37, మే 19 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "ఉత్తర 00:23 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "08:58 నుండి 10:35 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:43",
            Sunset: "18:42",
            Thiti: "దశమి 11:23 వరకు",
            Yamaganda: "13:50 నుండి 15:27 వరకు",
            Yoga: "హర్షణ 10:25 వరకు",
            Varjyam: "శే.వ. 07:13 వరకు",
            Moonrise: "14:28",
            Moonset: "02:47, మే 19",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "20:33 నుండి 22:20 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య",
            Date: "ఆదివారము, మే 19, 2024",
            DurMuhurtamulu: "16:42 నుండి 17:32 వరకు",
            GulikaiKalam: "15:28 నుండి 17:05 వరకు",
            Karanamulu: "విష్టి 13:50 వరకు, బవ 02:57, మే 20 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "హస్త 03:15 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "17:05 నుండి 18:43 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:43",
            Sunset: "18:43",
            Thiti: "ఏకాదశి 13:51 వరకు",
            Yamaganda: "12:13 నుండి 13:50 వరకు",
            Yoga: "వజ్ర 11:25 వరకు",
            Varjyam: "09:48 నుండి 11:35 వరకు",
            Moonrise: "15:14",
            Moonset: "03:19, మే 20",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "22:42 నుండి 00:28, మే 21 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య 16:34 వరకు",
            Date: "సోమవారము, మే 20, 2024",
            DurMuhurtamulu: "12:25 నుండి 13:16 వరకు",
            GulikaiKalam: "13:50 నుండి 15:28 వరకు",
            Karanamulu: "బాలవ 15:58 వరకు, కౌలవ 04:52, మే 21 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "చిత్తా నిండా రాత్రి వరకు",
            MainNakshatra: "చిత్త",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:20 నుండి 08:58 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:43",
            Sunset: "18:43",
            Thiti: "ద్వాదశి 15:59 వరకు",
            Yamaganda: "10:35 నుండి 12:13 వరకు",
            Yoga: "సిద్ధి 12:11 వరకు",
            Varjyam: "12:05 నుండి 13:51 వరకు",
            Moonrise: "16:02",
            Moonset: "03:53, మే 21",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "22:14 నుండి 23:58 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల",
            Date: "మంగళవారము, మే 21, 2024",
            DurMuhurtamulu: "08:08 నుండి 08:59 వరకు",
            GulikaiKalam: "12:13 నుండి 13:50 వరకు",
            Karanamulu: "తైతిల 17:39 వరకు, బవ నిండా రాత్రి వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "చిత్తా 05:45 వరకు",
            MainNakshatra: "చిత్త",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:28 నుండి 17:06 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:43",
            Sunset: "18:43",
            Thiti: "త్రయోదశి 17:40 వరకు",
            Yamaganda: "08:58 నుండి 10:35 వరకు",
            Yoga: "వ్యతీపాత 12:36 వరకు",
            Varjyam: "11:49 నుండి 13:33 వరకు",
            Moonrise: "16:52",
            Moonset: "04:30, మే 22",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "23:54 నుండి 01:36, మే 23 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల 02:56, మే 23 వరకు",
            Date: "బుధవారము, మే 22, 2024",
            DurMuhurtamulu: "11:34 నుండి 12:26 వరకు",
            GulikaiKalam: "10:35 నుండి 12:13 వరకు",
            Karanamulu: "బవ 06:17 వరకు, వణిజ 18:47 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "స్వాతి 07:46 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:13 నుండి 13:51 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:42",
            Sunset: "18:44",
            Thiti: "చతుర్దశి 18:48 వరకు",
            Yamaganda: "07:20 నుండి 08:58 వరకు",
            Yoga: "వారీయన 12:37 వరకు",
            Varjyam: "13:43 నుండి 15:25 వరకు",
            Moonrise: "17:45",
            Moonset: "05:11, మే 23",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "23:22 నుండి 01:02, మే 24 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "గురువారము, మే 23, 2024",
            DurMuhurtamulu: "09:51 నుండి 10:42 వరకు",
            GulikaiKalam: "08:58 నుండి 10:35 వరకు",
            Karanamulu: "విష్టి 07:09 వరకు, బవ 19:22 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "విశాఖ 09:14 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:51 నుండి 15:29 వ",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:42",
            Sunset: "18:44",
            Thiti: "పౌర్ణమి 19:23 వరకు",
            Yamaganda: "05:42 నుండి 07:20 వరకు",
            Yoga: "పరిఘ 12:12 వరకు",
            Varjyam: "13:13 నుండి 14:53 వరకు",
            Moonrise: "18:41",
            Moonset: "ఏమిలేదు",
            Shaka: "1946 క్రోధి",
            Amavasya: "pournami",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "01:38, మే 25 నుండి, 03:16, మే 25 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "శుక్రవారము, మే 24, 2024",
            DurMuhurtamulu: "08:08 నుండి 09:00 వరకు",
            GulikaiKalam: "07:20 నుండి 08:58 వరకు",
            Karanamulu: "బాలవ 07:27 వరకు, కౌలవ 19:24 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "అనూరాధ 10:10 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:35 నుండి 12:13 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:42",
            Sunset: "18:44",
            Thiti: "పాడ్యమి 19:25 వరకు",
            Yamaganda: "15:29 నుండి 17:07 వరకు",
            Yoga: "శివ 11:22 వరకు",
            Varjyam: "15:52 నుండి 17:30 వరకు",
            Moonrise: "19:40",
            Moonset: "05:58",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "04:12, మే 26 నుండి, 05:48, మే 26 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము 10:36 వరకు",
            Date: "శనివారము, మే 25, 2024",
            DurMuhurtamulu: "05:33 నుండి 07:16 వరకు",
            GulikaiKalam: "05:42 నుండి 07:20 వరకు",
            Karanamulu: "తైతిల 07:14 వరకు, బవ 18:58 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "జ్యేష్ఠ 10:35 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "08:58 నుండి 10:35 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:42",
            Sunset: "18:45",
            Thiti: "విదియ 18:59 వరకు",
            Yamaganda: "13:51 నుండి 15:29 వరకు",
            Yoga: "సిద్ధ 10:07 వరకు",
            Varjyam: "ఏమిలేదు",
            Moonrise: "20:39",
            Moonset: "06:50",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "05:30, మే 27 నుండి, 07:04, మే 27 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "ఆదివారము, మే 26, 2024",
            DurMuhurtamulu: "16:44 నుండి 17:36 వరకు",
            GulikaiKalam: "15:29 నుండి 17:07 వరకు",
            Karanamulu: "వణిజ 06:34 వరకు, విష్టి 18:06 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "మూల 10:35 వరకు",
            MainNakshatra: "మూల",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "17:07 నుండి 18:45 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:42",
            Sunset: "18:45",
            Thiti: "తదియ 18:07 వరకు",
            Yamaganda: "12:13 నుండి 13:51 వరకు",
            Yoga: "సాధ్య 08:31 వరకు",
            Varjyam: "08:59 నుండి 10:35 వరకు",
            Moonrise: "21:37",
            Moonset: "07:48",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:40 వరకు",
            Amrutakalam: "03:20, మే 28 నుండి, 04:53, మే 28 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు 16:05 వరకు",
            Date: "సోమవారము, మే 27, 2024",
            DurMuhurtamulu: "12:26 నుండి 13:17 వరకు",
            GulikaiKalam: "13:51 నుండి 15:29 వరకు",
            Karanamulu: "బాలవ 16:53 వరకు, కౌలవ 04:10, మే 28 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "పూర్వాషాఢ 10:13 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "07:19 నుండి 08:57 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:41",
            Sunset: "18:45",
            Thiti: "చవితి 16:54 వరకు",
            Yamaganda: "10:35 నుండి 12:13 వరకు",
            Yoga: "శుభ 06:37 వరకు",
            Varjyam: "18:00 నుండి 19:33 వరకు",
            Moonrise: "22:32",
            Moonset: "08:49",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:40 వరకు",
            Amrutakalam: "22:38 నుండి 00:10, మే 29 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము",
            Date: "మంగళవారము, మే 28, 2024",
            DurMuhurtamulu: "08:08 నుండి 09:00 వరకు",
            GulikaiKalam: "12:14 నుండి 13:52 వరకు",
            Karanamulu: "తైతిల 15:23 వరకు, బవ 02:33, మే 29 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "ఉత్తరాషాఢ 19:33 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:30 నుండి 17:08 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:41",
            Sunset: "18:46",
            Thiti: "పంచమి 15:24 వరకు",
            Yamaganda: "08:57 నుండి 10:36 వరకు",
            Yoga: "బ్రహ్మ 02:06, మే 29 వరకు",
            Varjyam: "13:24 నుండి 14:56 వరకు",
            Moonrise: "23:21",
            Moonset: "09:51",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "21:36 నుండి 23:08 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము 20:06 వరకు",
            Date: "బుధవారము, మే 29, 2024",
            DurMuhurtamulu: "11:34 నుండి 12:26 వరకు",
            GulikaiKalam: "10:36 నుండి 12:14 వరకు",
            Karanamulu: "వణిజ 13:39 వరకు, విష్టి 00:43, మే 30 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "శ్రవణం 08:38 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:14 నుండి 13:52 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:41",
            Sunset: "18:46",
            Thiti: "షష్టి 13:40 వరకు",
            Yamaganda: "07:19 నుండి 08:58 వరకు",
            Yoga: "ఐన్ద్ర 23:34 వరకు",
            Varjyam: "12:27 నుండి 13:59 వరకు",
            Moonrise: "00:06, మే 30 వరకు",
            Moonset: "10:53",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:40 వరకు",
            Amrutakalam: "23:25 నుండి 00:56, మే 31 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము",
            Date: "గురువారము, మే 30, 2024",
            DurMuhurtamulu: "10:03 నుండి 10:55 వరకు, 15:17 నుండి 16:09 వరకు",
            GulikaiKalam: "08:58 నుండి 10:36 వరకు",
            Karanamulu: "బవ 11:43 వరకు, బాలవ 22:42 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "ధనిష్ఠ 07:31 వరకు",
            MainNakshatra: "ధనిష్ఠ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:52 నుండి 15:30 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:41",
            Sunset: "18:47",
            Thiti: "సప్తమి 11:44 వరకు",
            Yamaganda: "05:41 నుండి 07:19 వరకు",
            Yoga: "వైధృతి 20:53 వరకు",
            Varjyam: "14:20 నుండి 15:51 వరకు",
            Moonrise: "00:48, మే 31",
            Moonset: "11:52",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:40 వరకు",
            Amrutakalam: "21:16 నుండి 22:47 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము 23:10 వరకు",
            Date: "శుక్రవారము, మే 31, 2024",
            DurMuhurtamulu: "08:07 నుండి 08:59 వరకు",
            GulikaiKalam: "07:19 నుండి 08:58 వరకు",
            Karanamulu: "కౌలవ 09:38 వరకు, తైతిల 20:32 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "శతభిషం 06:13 వరకు, పూర్వాభాద్ర 04:47 వరకు",
            MainNakshatra: "పూర్వాభాద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:36 నుండి 12:14 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:41",
            Sunset: "18:47",
            Thiti: "అష్టమి 09:39 వరకు",
            Yamaganda: "15:30 నుండి 17:09 వరకు",
            Yoga: "విష్కుమ్భ 18:05 వరకు",
            Varjyam: "12:14 నుండి 13:44 వరకు",
            Moonrise: "01:27, జూన్ 01",
            Moonset: "12:50",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
        ],
      },
      {
        day: [
          {
            Abhijit: "11:48 నుండి 12:40 వరకు",
            Amrutakalam: "22:46 నుండి 00:16, జూన్ 02 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం",
            Date: "శనివారము, జూన్ 1, 2024",
            DurMuhurtamulu: "05:32 నుండి 07:16 వరకు",
            GulikaiKalam: "05:41 నుండి 07:19 వరకు",
            Karanamulu:
              "బవ 07:24 వరకు, వణిజ 18:15 వరకు, విష్టి 05:04, జూన్ 02 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "ఉత్తరాభాద్ర 03:15 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "08:58 నుండి 10:36 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:41",
            Sunset: "18:47",
            Thiti: "నవమి 07:25 వరకు, దశమి 05:05 వరకు",
            Yamaganda: "13:52 నుండి 15:31 వరకు",
            Yoga: "ప్రీతి 15:10 వరకు",
            Varjyam: "13:46 నుండి 15:16 వరకు",
            Moonrise: "02:06, జూన్ 02",
            Moonset: "13:47",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:41 వరకు",
            Amrutakalam: "23:26 నుండి 00:55, జూన్ 03 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం 01:40, జూన్ 03 వరకు",
            Date: "ఆదివారము, జూన్ 2, 2024",
            DurMuhurtamulu: "16:46 నుండి 17:38 వరకు",
            GulikaiKalam: "15:31 నుండి 17:09 వరకు",
            Karanamulu: "బవ 15:53 వరకు, బాలవ 02:41, జూన్ 03 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "రేవతి 01:40 వరకు",
            MainNakshatra: "రేవతి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "17:09 నుండి 18:48 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:41",
            Sunset: "18:48",
            Thiti: "ఏకాదశి 02:42 వరకు",
            Yamaganda: "12:14 నుండి 13:53 వరకు",
            Yoga: "ఆయుష్మాన్ 12:12 వరకు",
            Varjyam: "14:27 నుండి 15:57 వరకు",
            Moonrise: "02:46, జూన్ 03",
            Moonset: "14:45",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:41 వరకు",
            Amrutakalam: "17:21 నుండి 18:51 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం",
            Date: "సోమవారము, జూన్ 3, 2024",
            DurMuhurtamulu: "12:27 నుండి 13:19 వరకు",
            GulikaiKalam: "13:53 నుండి 15:31 వరకు",
            Karanamulu: "కౌలవ 13:29 వరకు, తైతిల 00:18, జూన్ 04 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "అశ్విని 00:04 వరకు",
            MainNakshatra: "అశ్విని",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "07:19 నుండి 08:58 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:41",
            Sunset: "18:48",
            Thiti: "ద్వాదశి 00:19 వరకు",
            Yamaganda: "10:36 నుండి 12:14 వరకు",
            Yoga: "సౌభాగ్య 09:11 వరకు",
            Varjyam: "20:21 నుండి 21:51 వరకు",
            Moonrise: "03:29, జూన్ 04",
            Moonset: "15:44",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:41 వరకు",
            Amrutakalam: "18:05 నుండి 19:35 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం 04:14, జూన్ 05 వరకు",
            Date: "మంగళవారము, జూన్ 4, 2024",
            DurMuhurtamulu: "08:08 నుండి 09:00 వరకు",
            GulikaiKalam: "12:15 నుండి 13:53 వరకు",
            Karanamulu: "బవ 11:08 వరకు, వణిజ 22:01 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "భరణి 22:34 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:31 నుండి 17:10 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:41",
            Sunset: "18:48",
            Thiti: "త్రయోదశి 22:01 వరకు",
            Yamaganda: "08:58 నుండి 10:36 వరకు",
            Yoga: "శోభన 06:12 వరకు",
            Varjyam: "09:04 నుండి 10:34 వరకు",
            Moonrise: "04:16, జూన్ 05",
            Moonset: "16:45",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "19:00 నుండి 20:31 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం",
            Date: "బుధవారము, జూన్ 5, 2024",
            DurMuhurtamulu: "11:36 నుండి 12:28 వరకు",
            GulikaiKalam: "10:36 నుండి 12:15 వరకు",
            Karanamulu: "విష్టి 08:56 వరకు, శకుని 19:54 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "కృత్తిక 21:16 వరకు",
            MainNakshatra: "కృత్తిక",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:15 నుండి 13:53 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:41",
            Sunset: "18:49",
            Thiti: "చతుర్దశి 19:55 వరకు",
            Yamaganda: "07:19 నుండి 08:58 వరకు",
            Yoga: "సుకర్మా 00:36, జూన్ 06 వరకు",
            Varjyam: "09:55 నుండి 11:26 వరకు",
            Moonrise: "05:07, జూన్ 06",
            Moonset: "17:48",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:41 వరకు",
            Amrutakalam: "17:12 నుండి 18:44 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం",
            Date: "గురువారము, జూన్ 6, 2024",
            DurMuhurtamulu: "09:52 నుండి 10:44 వరకు",
            GulikaiKalam: "08:58 నుండి 10:37 వరకు",
            Karanamulu: "చతుష్పాద 06:58 వరకు, నాగ 18:07 వరకు",
            Masam: "వైశాఖ మాసం",
            Nakshatra1: "రోహిణి 20:16 వరకు",
            MainNakshatra: "రోహిణి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:54 నుండి 15:32 వరకు",
            Ruthuvu: "వసంతఋతువు",
            Sunrise: "05:41",
            Sunset: "18:49",
            Thiti: "అమావాస్య 18:08 వరకు",
            Yamaganda: "05:41 నుండి 07:20 వరకు",
            Yoga: "ధృతి 22:09 వరకు",
            Varjyam: "12:35 నుండి 14:07 వరకు",
            Moonrise: "ఏమిలేదు",
            Moonset: "18:52",
            Shaka: "1946 క్రోధి",
            Amavasya: "amavasya",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:41 వరకు",
            Amrutakalam: "11:07 నుండి 12:41 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం 07:56 వరకు",
            Date: "శుక్రవారము, జూన్ 7, 2024",
            DurMuhurtamulu: "08:08 నుండి 09:00 వరకు",
            GulikaiKalam: "07:20 నుండి 08:58 వరకు",
            Karanamulu: "బవ 16:44 వరకు, బాలవ 04:15, జూన్ 08 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "మృగశిర 19:42 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:37 నుండి 12:15 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:41",
            Sunset: "18:49",
            Thiti: "పాడ్యమి 16:45 వరకు",
            Yamaganda: "15:32 నుండి 17:11 వరకు",
            Yoga: "శూల 20:05 వరకు",
            Varjyam: "04:06 లగాయతు",
            Moonrise: "06:03",
            Moonset: "19:54",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:42 వరకు",
            Amrutakalam: "09:42 నుండి 11:18 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం",
            Date: "శనివారము, జూన్ 8, 2024",
            DurMuhurtamulu: "05:32 నుండి 07:16 వరకు",
            GulikaiKalam: "05:41 నుండి 07:20 వరకు",
            Karanamulu: "కౌలవ 15:55 వరకు, తైతిల 03:44, జూన్ 09 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "ఆరుద్ర 19:42 వరకు",
            MainNakshatra: "ఆరుద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "08:58 నుండి 10:37 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:41",
            Sunset: "18:50",
            Thiti: "విదియ 15:56 వరకు",
            Yamaganda: "13:54 నుండి 15:33 వరకు",
            Yoga: "గణ్డ 18:27 వరకు",
            Varjyam: "శే.వ.05:42 వరకు",
            Moonrise: "07:02",
            Moonset: "20:51",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:42 వరకు",
            Amrutakalam: "17:52 నుండి 19:31 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం 14:07 వరకు",
            Date: "ఆదివారము, జూన్ 9, 2024",
            DurMuhurtamulu: "16:48 నుండి 17:40 వరకు",
            GulikaiKalam: "15:33 నుండి 17:11 వరకు",
            Karanamulu: "గర 15:44 వరకు, వణిజ 03:54, జూన్ 10 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "పునర్వసు 20:20 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "17:11 నుండి 18:50 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:41",
            Sunset: "18:50",
            Thiti: "తదియ 15:45 వరకు",
            Yamaganda: "12:16 నుండి 13:54 వరకు",
            Yoga: "వృద్ధి 17:21 వరకు",
            Varjyam: "08:01 నుండి 09:40 వరకు",
            Moonrise: "08:02",
            Moonset: "21:42",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:42 వరకు",
            Amrutakalam: "14:54 నుండి 16:36 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "సోమవారము, జూన్ 10, 2024",
            DurMuhurtamulu: "12:29 నుండి 13:21 వరకు",
            GulikaiKalam: "13:54 నుండి 15:33 వరకు",
            Karanamulu: "విష్టి 19:17 వరకు, బవ 04:45, జూన్ 11 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "పుష్యమి 21:39 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:20 నుండి 08:59 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:41",
            Sunset: "18:50",
            Thiti: "చవితి 16:15 వరకు",
            Yamaganda: "10:37 నుండి 12:16 వరకు",
            Yoga: "ధ్రువ 16:48 వరకు",
            Varjyam: "శే.వ.06:27 వరకు",
            Moonrise: "09:00",
            Moonset: "22:27",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:50 నుండి 12:42 వరకు",
            Amrutakalam: "21:55 నుండి 23:39 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం 23:39 వరకు",
            Date: "మంగళవారము, జూన్ 11, 2024",
            DurMuhurtamulu: "08:09 నుండి 09:01 వరకు",
            GulikaiKalam: "12:16 నుండి 13:55 వరకు",
            Karanamulu: "బాలవ 17:27 వరకు, కౌలవ నిండా రాత్రి వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "ఆశ్లేష 23:38 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:33 నుండి 17:12 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:41",
            Sunset: "18:51",
            Thiti: "పంచమి 17:28 వరకు",
            Yamaganda: "08:59 నుండి 10:37 వరకు",
            Yoga: "వ్యాఘాత 16:47 వరకు",
            Varjyam: "11:30 నుండి 13:14 వరకు",
            Moonrise: "09:54",
            Moonset: "23:06",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "23:33 నుండి 01:19, జూన్ 13 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం",
            Date: "బుధవారము, జూన్ 12, 2024",
            DurMuhurtamulu: "11:38 నుండి 12:30 వరకు",
            GulikaiKalam: "10:38 నుండి 12:16 వరకు",
            Karanamulu: "కౌలవ 06:17 వరకు, తైతిల 19:16 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "మఖ 02:11 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:16 నుండి 13:55 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:41",
            Sunset: "18:51",
            Thiti: "షష్టి 19:17 వరకు",
            Yamaganda: "07:20 నుండి 08:59 వరకు",
            Yoga: "హర్షణ 17:16 వరకు",
            Varjyam: "12:54 నుండి 14:40 వరకు",
            Moonrise: "10:46",
            Moonset: "23:42",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:50 నుండి 12:43 వరకు",
            Amrutakalam: "21:57 నుండి 23:45 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం",
            Date: "గురువారము, జూన్ 13, 2024",
            DurMuhurtamulu: "09:53 నుండి 10:45 వరకు",
            GulikaiKalam: "08:59 నుండి 10:38 వరకు",
            Karanamulu: "గర 08:22 వరకు, వణిజ 21:33 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "పుబ్బ 05:08 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:55 నుండి 15:34 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:42",
            Sunset: "18:51",
            Thiti: "సప్తమి 21:34 వరకు",
            Yamaganda: "05:42 నుండి 07:20 వరకు",
            Yoga: "వజ్ర 18:06 వరకు",
            Varjyam: "11:10 నుండి 12:58 వరకు",
            Moonrise: "11:34",
            Moonset: "00:15, జూన్ 14",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:50 నుండి 12:43 వరకు",
            Amrutakalam: "00:06, జూన్ 15 నుండి, 01:55, జూన్ 15 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం 11:55 వరకు",
            Date: "శుక్రవారము, జూన్ 14, 2024",
            DurMuhurtamulu: "08:09 నుండి 09:01 వరకు",
            GulikaiKalam: "07:20 నుండి 08:59 వరకు",
            Karanamulu: "విష్టి 10:47 వరకు, బవ 00:03, జూన్ 15 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "ఉత్తర నిండా రాత్రి వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:38 నుండి 12:17 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:42",
            Sunset: "18:51",
            Thiti: "అష్టమి 00:04 వరకు",
            Yamaganda: "15:34 నుండి 17:13 వరకు",
            Yoga: "సిద్ధి 19:08 వరకు",
            Varjyam: "13:16 నుండి 15:04 వరకు",
            Moonrise: "12:21",
            Moonset: "00:47, జూన్ 15",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:50 నుండి 12:43 వరకు",
            Amrutakalam: "04:28, జూన్ 16 నుండి, 06:16, జూన్ 16 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య",
            Date: "శనివారము, జూన్ 15, 2024",
            DurMuhurtamulu: "05:33 నుండి 07:18 వరకు",
            GulikaiKalam: "05:42 నుండి 07:21 వరకు",
            Karanamulu: "బాలవ 13:19 వరకు, కౌలవ 02:32, జూన్ 16 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "ఉత్తర 08:13 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "08:59 నుండి 10:38 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:42",
            Sunset: "18:52",
            Thiti: "నవమి 02:33, జూన్ 16 వరకు",
            Yamaganda: "13:56 నుండి 15:34 వరకు",
            Yoga: "వ్యతీపాత 20:11 వరకు",
            Varjyam: "16:41 నుండి 18:41 వరకు",
            Moonrise: "13:08",
            Moonset: "01:18, జూన్ 16",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:51 నుండి 12:43 వరకు",
            Amrutakalam: "ఏమిలేదు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య 00:35, జూన్ 17 వరకు",
            Date: "ఆదివారము, జూన్ 16, 2024",
            DurMuhurtamulu: "16:50 నుండి 17:42 వరకు",
            GulikaiKalam: "15:35 నుండి 17:13 వరకు",
            Karanamulu: "తైతిల 15:41 వరకు, బవ 04:43, జూన్ 17 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "హస్త 11:12 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "17:13 నుండి 18:52 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:42",
            Sunset: "18:52",
            Thiti: "దశమి 04:44 వరకు",
            Yamaganda: "12:17 నుండి 13:56 వరకు",
            Yoga: "వారీయన 21:03 వరకు",
            Varjyam: "20:05 నుండి 21:52 వరకు",
            Moonrise: "13:55",
            Moonset: "01:51, జూన్ 17",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:51 నుండి 12:44 వరకు",
            Amrutakalam: "06:44 నుండి 08:31 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల",
            Date: "సోమవారము, జూన్ 17, 2024",
            DurMuhurtamulu: "12:30 నుండి 13:22 వరకు",
            GulikaiKalam: "13:56 నుండి 15:35 వరకు",
            Karanamulu: "వణిజ 17:38 వరకు, విష్టి నిండా రాత్రి వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "చిత్త 13:50 వరకు",
            MainNakshatra: "చిత్త",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:21 నుండి 09:00 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:42",
            Sunset: "18:52",
            Thiti: "ఏకాదశి నిండా రాత్రి వరకు",
            Yamaganda: "10:38 నుండి 12:17 వరకు",
            Yoga: "పరిఘ 21:35 వరకు",
            Varjyam: "19:55 నుండి 21:39 వరకు",
            Moonrise: "14:43",
            Moonset: "02:26, జూన్ 18",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "17",
            LordImage: require("../images/holidays/6-0)Bakrid.png"),
          },
          {
            Abhijit: "11:51 నుండి 12:44 వరకు",
            Amrutakalam: "06:22 నుండి 08:06 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల",
            Date: "మంగళవారము, జూన్ 18, 2024",
            DurMuhurtamulu: "08:10 నుండి 09:02 వరకు",
            GulikaiKalam: "12:17 నుండి 13:56 వరకు",
            Karanamulu: "విష్టి 06:24 వరకు, బవ 19:01 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "స్వాతి 15:55 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:35 నుండి 17:14 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:42",
            Sunset: "18:53",
            Thiti: "ఏకాదశి 06:25 వరకు",
            Yamaganda: "09:00 నుండి 10:39 వరకు",
            Yoga: "శివ 21:39 వరకు",
            Varjyam: "21:52 నుండి 23:34 వరకు",
            Moonrise: "15:35",
            Moonset: "03:05, జూన్ 19",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "08:03 నుండి 09:45 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల 11:05 వరకు",
            Date: "బుధవారము, జూన్ 19, 2024",
            DurMuhurtamulu: "11:38 నుండి 12:30 వరకు",
            GulikaiKalam: "10:39 నుండి 12:18 వరకు",
            Karanamulu: "బాలవ 07:28 వరకు, కౌలవ 19:44 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "విశాఖ 17:23 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:18 నుండి 13:56 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:43",
            Sunset: "18:53",
            Thiti: "ద్వాదశి 07:28 వరకు",
            Yamaganda: "07:21 నుండి 09:00 వరకు",
            Yoga: "సిద్ధ 21:12 వరకు",
            Varjyam: "21:31 నుండి 23:10 వరకు",
            Moonrise: "16:30",
            Moonset: "03:50, జూన్ 20",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:52 నుండి 12:44 వరకు",
            Amrutakalam: "07:26 నుండి 09:05 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "గురువారము, జూన్ 20, 2024",
            DurMuhurtamulu: "09:54 నుండి 10:46 వరకు",
            GulikaiKalam: "09:00 నుండి 10:39 వరకు",
            Karanamulu: "తైతిల 07:49 వరకు, బవ 19:45 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "అనూరాధ 18:09 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:57 నుండి 15:35 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:43",
            Sunset: "18:53",
            Thiti: "త్రయోదశి 07:50 వరకు",
            Yamaganda: "05:43 నుండి 07:22 వరకు",
            Yoga: "సాధ్య 20:13 వరకు",
            Varjyam: "23:47 నుండి 01:24, జూన్ 21 వరకు",
            Moonrise: "17:28",
            Moonset: "04:40, జూన్ 21",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:52 నుండి 12:44 వరకు",
            Amrutakalam: "09:27 నుండి 11:04 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము 18:19 వరకు",
            Date: "శుక్రవారము, జూన్ 21, 2024",
            DurMuhurtamulu: "08:10 నుండి 09:02 వరకు",
            GulikaiKalam: "07:22 నుండి 09:01 వరకు",
            Karanamulu: "వణిజ 07:31 వరకు, విష్టి 19:08 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "జ్యేష్ఠ 18:18 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:39 నుండి 12:18 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:43",
            Sunset: "18:53",
            Thiti: "చతుర్దశి 07:32 వరకు",
            Yamaganda: "15:36 నుండి 17:14 వరకు",
            Yoga: "శుభ 18:42 వరకు",
            Varjyam: "ఏమిలేదు",
            Moonrise: "18:28",
            Moonset: "05:37, జూన్ 22",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:52 నుండి 12:45 వరకు",
            Amrutakalam: "11:37 నుండి 13:11 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "శనివారము, జూన్ 22, 2024",
            DurMuhurtamulu: "05:35 నుండి 07:19 వరకు",
            GulikaiKalam: "05:43 నుండి 07:22 వరకు",
            Karanamulu:
              "బవ 06:37 వరకు, బాలవ 17:58 వరకు, కౌలవ 05:12, జూన్ 23 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "మూల 17:54 వరకు",
            MainNakshatra: "మూల",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:01 నుండి 10:40 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:43",
            Sunset: "18:53",
            Thiti: "పౌర్ణమి 06:38 వరకు, పాడ్యమి 05:13 వరకు",
            Yamaganda: "13:57 నుండి 15:36 వరకు",
            Yoga: "శుక్ల 16:45 వరకు",
            Varjyam: "16:20 నుండి 17:54 వరకు",
            Moonrise: "19:28",
            Moonset: "ఏమిలేదు",
            Shaka: "1946 క్రోధి",
            Amavasya: "pournami",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:52 నుండి 12:45 వరకు",
            Amrutakalam: "12:26 నుండి 13:58 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు 22:48 వరకు",
            Date: "ఆదివారము, జూన్ 23, 2024",
            DurMuhurtamulu: "16:52 నుండి 17:44 వరకు",
            GulikaiKalam: "15:36 నుండి 17:15 వరకు",
            Karanamulu: "తైతిల 16:21 వరకు, బవ 03:25, జూన్ 24 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "పూర్వాషాఢ 17:03 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "17:15 నుండి 18:54 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:43",
            Sunset: "18:54",
            Thiti: "విదియ 03:26 వరకు",
            Yamaganda: "12:19 నుండి 13:57 వరకు",
            Yoga: "బ్రహ్మ 14:27 వరకు",
            Varjyam: "00:40 నుండి 02:21 వరకు",
            Moonrise: "20:25",
            Moonset: "06:39",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:52 నుండి 12:45 వరకు",
            Amrutakalam:
              "09:48 నుండి 11:20 వరకు, 04:44, జూన్ 25 నుండి, 06:14, జూన్ 25 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము",
            Date: "సోమవారము, జూన్ 24, 2024",
            DurMuhurtamulu: "12:32 నుండి 13:24 వరకు",
            GulikaiKalam: "13:57 నుండి 15:36 వరకు",
            Karanamulu: "వణిజ 14:25 వరకు, విష్టి 01:23, జూన్ 25 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "ఉత్తరాషాఢ 15:53 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "07:22 నుండి 09:01 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:44",
            Sunset: "18:54",
            Thiti: "తదియ 01:23, జూన్ 25 వరకు",
            Yamaganda: "10:40 నుండి 12:19 వరకు",
            Yoga: "ఐన్ద్ర 11:52 వరకు",
            Varjyam: "19:40 నుండి 21:11 వరకు",
            Moonrise: "21:17",
            Moonset: "07:42",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:45 వరకు",
            Amrutakalam: "03:19, జూన్ 26 నుండి 04:49, జూన్ 26 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము 01:49, జూన్ 26 వరకు",
            Date: "మంగళవారము, జూన్ 25, 2024",
            DurMuhurtamulu: "08:11 నుండి 09:03 వరకు",
            GulikaiKalam: "12:19 నుండి 13:58 వరకు",
            Karanamulu: "బవ 12:17 వరకు, బాలవ 23:10 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "శ్రవణం 14:32 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:36 నుండి 17:15 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:44",
            Sunset: "18:54",
            Thiti: "చవితి 23:11 వరకు",
            Yamaganda: "09:01 నుండి 10:40 వరకు",
            Yoga: "వైధృతి 09:06 వరకు",
            Varjyam: "18:17 నుండి 19:47 వరకు",
            Moonrise: "22:05",
            Moonset: "08:46",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "04:51, జూన్ 27 నుండి 06:21, జూన్ 27 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము",
            Date: "బుధవారము, జూన్ 26, 2024",
            DurMuhurtamulu: "11:40 నుండి 12:32 వరకు",
            GulikaiKalam: "10:40 నుండి 12:19 వరకు",
            Karanamulu: "కౌలవ 10:03 వరకు, తైతిల 20:55 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "ధనిష్ఠ 13:04 వరకు",
            MainNakshatra: "ధనిష్ఠ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:19 నుండి 13:58 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:44",
            Sunset: "18:54",
            Thiti: "పంచమి 20:55 వరకు",
            Yamaganda: "07:23 నుండి 09:02 వరకు",
            Yoga: "విష్కుమ్భ 06:14 వరకు",
            Varjyam: "19:50 నుండి 21:20 వరకు",
            Moonrise: "22:48",
            Moonset: "09:47",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:46 వరకు",
            Amrutakalam: "02:39, జూన్ 28 నుండి 04:09, జూన్ 28 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము 04:32, జూన్ 28 వరకు",
            Date: "గురువారము, జూన్ 27, 2024",
            DurMuhurtamulu: "09:56 నుండి 10:48 వరకు",
            GulikaiKalam: "09:02 నుండి 10:41 వరకు",
            Karanamulu: "బవ 07:47 వరకు, వణిజ 18:39 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "శతభిషం 11:36 వరకు",
            MainNakshatra: "శతభిషం",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:58 నుండి 15:37 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:44",
            Sunset: "18:54",
            Thiti: "షష్టి 18:40 వరకు",
            Yamaganda: "05:44 నుండి 07:23 వరకు",
            Yoga: "ఆయుష్మాన్ 00:28, జూన్ 28 వరకు",
            Varjyam: "17:37 నుండి 19:07 వరకు",
            Moonrise: "23:28",
            Moonset: "10:46",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:46 వరకు",
            Amrutakalam: "04:17, జూన్ 29 నుండి 05:48, జూన్ 29 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం",
            Date: "శుక్రవారము, జూన్ 28, 2024",
            DurMuhurtamulu: "08:12 నుండి 09:04 వరకు",
            GulikaiKalam: "07:23 నుండి 09:02 వరకు",
            Karanamulu: "బవ 16:27 వరకు, బాలవ 03:22, జూన్ 29 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "పూర్వాభాద్ర 10:10 వరకు",
            MainNakshatra: "పూర్వాభాద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:41 నుండి 12:20 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:45",
            Sunset: "18:54",
            Thiti: "సప్తమి 16:28 వరకు",
            Yamaganda: "15:37 నుండి 17:16 వరకు",
            Yoga: "సౌభాగ్య 21:39 వరకు",
            Varjyam: "19:13 నుండి 20:44 వరకు",
            Moonrise: "00:06, జూన్ 29",
            Moonset: "11:43",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:46 వరకు",
            Amrutakalam: "05:17, జూన్ 30 నుండి 06:48, జూన్ 30 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం",
            Date: "శనివారము, జూన్ 29, 2024",
            DurMuhurtamulu: "05:37 నుండి 07:21 వరకు",
            GulikaiKalam: "05:45 నుండి 07:24 వరకు",
            Karanamulu: "కౌలవ 14:19 వరకు, తైతిల 01:18, జూన్ 30 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "ఉత్తరాభాద్ర 08:49 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:02 నుండి 10:41 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:45",
            Sunset: "18:54",
            Thiti: "అష్టమి 14:20 వరకు",
            Yamaganda: "13:58 నుండి 15:37 వరకు",
            Yoga: "శోభన 18:54 వరకు",
            Varjyam: "20:11 నుండి 21:42 వరకు",
            Moonrise: "00:45, జూన్ 30",
            Moonset: "12:39",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:54 నుండి 12:46 వరకు",
            Amrutakalam: "23:34 నుండి 01:06, జూలై 01 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం 07:34 వరకు",
            Date: "ఆదివారము, జూన్ 30, 2024",
            DurMuhurtamulu: "16:53 నుండి 17:45 వరకు",
            GulikaiKalam: "15:37 నుండి 17:16 వరకు",
            Karanamulu: "బవ 12:19 వరకు, వణిజ 23:21 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "రేవతి 07:33 వరకు",
            MainNakshatra: "రేవతి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "17:16 నుండి 18:55 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:45",
            Sunset: "18:55",
            Thiti: "నవమి 12:19 వరకు",
            Yamaganda: "12:20 నుండి 13:59 వరకు",
            Yoga: "అతిగణ్డ 16:15 వరకు",
            Varjyam: "02:37 నుండి 04:09 వరకు",
            Moonrise: "01:26, జూలై 01",
            Moonset: "13:37",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
        ],
      },
      {
        day: [
          {
            Abhijit: "11:54 నుండి 12:46 వరకు",
            Amrutakalam: "00:51, జూలై 02 నుండి 02:23, జూలై 02 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం",
            Date: "సోమవారము, జూలై 1, 2024",
            DurMuhurtamulu: "12:33 నుండి 13:25 వరకు",
            GulikaiKalam: "13:59 నుండి 15:37 వరకు",
            Karanamulu: "విష్టి 10:26 వరకు, బవ 21:32 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "అశ్విని 06:26 వరకు, భరణి 05:27 వరకు",
            MainNakshatra: "అశ్విని, భరణి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:03 నుండి 10:41 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:46",
            Sunset: "18:55",
            Thiti: "దశమి 10:26 వరకు",
            Yamaganda: "10:42 నుండి 12:20 వరకు",
            Yoga: "సుకర్మా 13:42 వరకు",
            Varjyam: "15:39 నుండి 17:11 వరకు",
            Moonrise: "02:10, జూలై 02",
            Moonset: "14:36",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:54 నుండి 12:47 వరకు",
            Amrutakalam: "02:20, జూలై 03 నుండి 03:53, జూలై 03 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం 11:14 వరకు",
            Date: "మంగళవారము, జూలై 2, 2024",
            DurMuhurtamulu: "08:13 నుండి 09:05 వరకు",
            GulikaiKalam: "12:20 నుండి 13:59 వరకు",
            Karanamulu: "బాలవ 08:42 వరకు, కౌలవ 19:54 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "కృత్తిక 04:39 వరకు",
            MainNakshatra: "కృత్తిక",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:38 నుండి 17:16 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:46",
            Sunset: "18:55",
            Thiti: "ఏకాదశి 08:43 వరకు",
            Yamaganda: "09:03 నుండి 10:42 వరకు",
            Yoga: "ధృతి 11:17 వరకు",
            Varjyam: "17:03 నుండి 18:36 వరకు",
            Moonrise: "02:59, జూలై 03",
            Moonset: "15:37",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "01:00, జూలై 04 నుండి 02:33, జూలై 04 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం",
            Date: "బుధవారము, జూలై 3, 2024",
            DurMuhurtamulu: "11:42 నుండి 12:34 వరకు",
            GulikaiKalam: "10:42 నుండి 12:21 వరకు",
            Karanamulu: "తైతిల 07:10 వరకు, బవ 18:29 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "రోహిణి 04:07 వరకు",
            MainNakshatra: "రోహిణి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:21 నుండి 13:59 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:46",
            Sunset: "18:55",
            Thiti: "ద్వాదశి 07:11 వరకు",
            Yamaganda: "07:25 నుండి 09:03 వరకు",
            Yoga: "శూల 09:02 వరకు",
            Varjyam: "20:18 నుండి 21:52 వరకు",
            Moonrise: "03:52, జూలై 04",
            Moonset: "16:39",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:54 నుండి 12:47 వరకు",
            Amrutakalam: "19:11 నుండి 20:46 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం 15:58 వరకు",
            Date: "గురువారము, జూలై 4, 2024",
            DurMuhurtamulu: "09:58 నుండి 10:50 వరకు",
            GulikaiKalam: "09:04 నుండి 10:42 వరకు",
            Karanamulu: "వణిజ 05:54 వరకు, విష్టి 17:23 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "మృగశిర 03:54 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:59 నుండి 15:38 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:47",
            Sunset: "18:55",
            Thiti: "త్రయోదశి 05:54 వరకు, చతుర్దశి 04:58 వరకు",
            Yamaganda: "05:47 నుండి 07:25 వరకు",
            Yoga: "గణ్డ 07:00 వరకు",
            Varjyam: "09:40 నుండి 11:15 వరకు",
            Moonrise: "04:50, జూలై 05",
            Moonset: "17:41",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:55 నుండి 12:47 వరకు",
            Amrutakalam: "18:01 నుండి 19:38 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం",
            Date: "శుక్రవారము, జూలై 5, 2024",
            DurMuhurtamulu: "08:14 నుండి 09:06 వరకు",
            GulikaiKalam: "07:25 నుండి 09:04 వరకు",
            Karanamulu: "చతుష్పాద 16:38 వరకు, నాగ 04:26, జూలై 06 వరకు",
            Masam: "జ్యేష్ఠ మాసం",
            Nakshatra1: "ఆరుద్ర 04:06 వరకు",
            MainNakshatra: "ఆరుద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:42 నుండి 12:21 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:47",
            Sunset: "18:55",
            Thiti: "అమావాస్య 04:26, జూలై 06 వరకు",
            Yamaganda: "15:38 నుండి 17:16 వరకు",
            Yoga: "ధ్రువ 03:49, జూలై 06 వరకు",
            Varjyam: "12:22 నుండి 13:59 వరకు",
            Moonrise: "ఏమిలేదు",
            Moonset: "18:39",
            Shaka: "1946 క్రోధి",
            Amavasya: "amavasya",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:55 నుండి 12:47 వరకు",
            Amrutakalam: "02:20, జూలై 07 నుండి 03:58, జూలై 07 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం 22:34 వరకు",
            Date: "శనివారము, జూలై 6, 2024",
            DurMuhurtamulu: "05:39 నుండి 07:23 వరకు",
            GulikaiKalam: "05:47 నుండి 07:26 వరకు",
            Karanamulu: "కింస్తుఘ్న 16:22 వరకు, బవ 04:26, జూలై 07 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "పునర్వసు 04:47 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:04 నుండి 10:43 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:47",
            Sunset: "18:55",
            Thiti: "పాడ్యమి 04:27 వరకు",
            Yamaganda: "13:59 నుండి 15:38 వరకు",
            Yoga: "వ్యాఘాత 02:47, జూలై 07 వరకు",
            Varjyam: "16:26 నుండి 18:05 వరకు",
            Moonrise: "05:49",
            Moonset: "19:33",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:55 నుండి 12:47 వరకు",
            Amrutakalam: "23:19 నుండి 01:00, జూలై 08 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "ఆదివారము, జూలై 7, 2024",
            DurMuhurtamulu: "16:53 నుండి 17:45 వరకు",
            GulikaiKalam: "15:38 నుండి 17:16 వరకు",
            Karanamulu: "బాలవ 16:38 వరకు, కౌలవ 04:59, జూలై 08 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "పుష్యమి పూర్తి",
            MainNakshatra: "పుష్యమి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "17:16 నుండి 18:55 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:47",
            Sunset: "18:55",
            Thiti: "విదియ 05:00 వరకు",
            Yamaganda: "12:21 నుండి 14:00 వరకు",
            Yoga: "హర్షణ 02:13, జూలై 08 వరకు",
            Varjyam: "13:12 నుండి 14:53 వరకు",
            Moonrise: "06:48",
            Moonset: "20:20",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:55 నుండి 12:48 వరకు",
            Amrutakalam: "ఏమిలేదు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "సోమవారము, జూలై 8, 2024",
            DurMuhurtamulu: "12:34 నుండి 13:26 వరకు",
            GulikaiKalam: "14:00 నుండి 15:38 వరకు",
            Karanamulu: "తైతిల 17:29 వరకు, గర నిండా రాత్రి వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "పుష్యమి 06:02 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:26 నుండి 09:05 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:48",
            Sunset: "18:55",
            Thiti: "తదియ పూర్తి",
            Yamaganda: "10:43 నుండి 12:21 వరకు",
            Yoga: "వజ్ర 02:06, జూలై 09 వరకు",
            Varjyam: "19:48 నుండి 21:31 వరకు",
            Moonrise: "07:44",
            Moonset: "21:02",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:55 నుండి 12:48 వరకు",
            Amrutakalam: "06:09 నుండి 07:52 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం 07:52 వరకు",
            Date: "మంగళవారము, జూలై 9, 2024",
            DurMuhurtamulu: "08:16 నుండి 09:08 వరకు",
            GulikaiKalam: "12:21 నుండి 14:00 వరకు",
            Karanamulu: "గర 06:08 వరకు, వణిజ 18:56 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "ఆశ్లేష 07:52 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:38 నుండి 17:16 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:48",
            Sunset: "18:55",
            Thiti: "తదియ 06:09 వరకు",
            Yamaganda: "09:05 నుండి 10:43 వరకు",
            Yoga: "సిద్ధి 02:27, జూలై 10 వరకు",
            Varjyam: "21:03 నుండి 22:48 వరకు",
            Moonrise: "08:37",
            Moonset: "21:39",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "07:37 నుండి 09:22 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం",
            Date: "బుధవారము, జూలై 10, 2024",
            DurMuhurtamulu: "11:43 నుండి 12:35 వరకు",
            GulikaiKalam: "10:43 నుండి 12:22 వరకు",
            Karanamulu: "విష్టి 07:51 వరకు, బవ 20:54 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "మఖ 10:14 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:22 నుండి 14:00 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:48",
            Sunset: "18:55",
            Thiti: "చవితి 07:53 వరకు",
            Yamaganda: "07:27 నుండి 09:05 వరకు",
            Yoga: "వ్యతీపాత 03:10, జూలై 11 వరకు",
            Varjyam: "19:10 నుండి 20:57 వరకు",
            Moonrise: "09:27",
            Moonset: "22:13",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:48 వరకు",
            Amrutakalam: "05:55 నుండి 07:42 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం 19:49 వరకు",
            Date: "గురువారము, జూలై 11, 2024",
            DurMuhurtamulu: "09:59 నుండి 10:51 వరకు",
            GulikaiKalam: "09:05 నుండి 10:43 వరకు",
            Karanamulu: "బాలవ 10:03 వరకు, కౌలవ 23:16 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "పుబ్బ 13:03 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "14:00 నుండి 15:38 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:49",
            Sunset: "18:55",
            Thiti: "పంచమి 10:04 వరకు",
            Yamaganda: "05:49 నుండి 07:27 వరకు",
            Yoga: "వారీయన 04:09, జూలై 12 వరకు",
            Varjyam: "21:11 నుండి 22:59 వరకు",
            Moonrise: "10:15",
            Moonset: "22:45",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:48 వరకు",
            Amrutakalam: "08:01 నుండి 09:50 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య",
            Date: "శుక్రవారము, జూలై 12, 2024",
            DurMuhurtamulu: "08:16 నుండి 09:08 వరకు",
            GulikaiKalam: "07:27 నుండి 09:05 వరకు",
            Karanamulu: "తైతిల 12:32 వరకు, గర 01:49, జూలై 13 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "ఉత్తర 16:08 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:44 నుండి 12:22 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:49",
            Sunset: "18:55",
            Thiti: "షష్టి 12:33 వరకు",
            Yamaganda: "15:38 నుండి 17:16 వరకు",
            Yoga: "పరిఘ 05:15, జూలై 13 వరకు",
            Varjyam: "23:37 నుండి 01:25 వరకు",
            Moonrise: "11:01",
            Moonset: "23:17",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:48 వరకు",
            Amrutakalam: "12:28 నుండి 14:17 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య",
            Date: "శనివారము, జూలై 13, 2024",
            DurMuhurtamulu: "05:41 నుండి 07:24 వరకు",
            GulikaiKalam: "05:49 నుండి 07:28 వరకు",
            Karanamulu: "వణిజ 15:05 వరకు, విష్టి 04:18, జూలై 14 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "హస్త 19:14 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:06 నుండి 10:44 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:49",
            Sunset: "18:54",
            Thiti: "సప్తమి 15:06 వరకు",
            Yamaganda: "14:00 నుండి 15:38 వరకు",
            Yoga: "శివ నిండా రాత్రి వరకు",
            Varjyam: "04:11 లగాయతు",
            Moonrise: "11:47",
            Moonset: "23:49",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:48 వరకు",
            Amrutakalam: "14:57 నుండి 16:44 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య 08:43 వరకు",
            Date: "ఆదివారము, జూలై 14, 2024",
            DurMuhurtamulu: "16:53 నుండి 17:45 వరకు",
            GulikaiKalam: "15:38 నుండి 17:16 వరకు",
            Karanamulu: "బవ 17:25 వరకు, బాలవ నిండా రాత్రి వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "చిత్త 22:06 వరకు",
            MainNakshatra: "చిత్త",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "17:16 నుండి 18:54 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:50",
            Sunset: "18:54",
            Thiti: "అష్టమి 17:26 వరకు",
            Yamaganda: "12:22 నుండి 14:00 వరకు",
            Yoga: "శివ 06:16 వరకు",
            Varjyam: "శే.వ. 05:58 వరకు",
            Moonrise: "12:35",
            Moonset: "00:22, జూలై 15",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:48 వరకు",
            Amrutakalam: "14:49 నుండి 16:35 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల",
            Date: "సోమవారము, జూలై 15, 2024",
            DurMuhurtamulu: "12:34 నుండి 13:26 వరకు",
            GulikaiKalam: "14:00 నుండి 15:38 వరకు",
            Karanamulu: "బాలవ 06:26 వరకు, కౌలవ 19:19 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "స్వాతి 00:29 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:28 నుండి 09:06 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:50",
            Sunset: "18:54",
            Thiti: "నవమి 07:20 వరకు",
            Yamaganda: "10:44 నుండి 12:22 వరకు",
            Yoga: "సిద్ధ 07:00 వరకు",
            Varjyam: "శే.వ. 06:02 వరకు",
            Moonrise: "13:25",
            Moonset: "00:59, జూలై 16",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:48 వరకు",
            Amrutakalam: "16:48 నుండి 18:31 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల 19:52 వరకు",
            Date: "మంగళవారము, జూలై 16, 2024",
            DurMuhurtamulu: "08:17 నుండి 09:09 వరకు",
            GulikaiKalam: "12:22 నుండి 14:00 వరకు",
            Karanamulu: "తైతిల 08:01 వరకు, బవ 20:33 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "విశాఖ 02:13 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:38 నుండి 17:16 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:50",
            Sunset: "18:54",
            Thiti: "దశమి 20:34 వరకు",
            Yamaganda: "09:06 నుండి 10:44 వరకు",
            Yoga: "సాధ్య 07:19 వరకు",
            Varjyam: "06:30 నుండి 08:13 వరకు",
            Moonrise: "14:17",
            Moonset: "01:41, జూలై 17",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "16:23 నుండి 18:03 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "బుధవారము, జూలై 17, 2024",
            DurMuhurtamulu: "11:43 నుండి 12:35 వరకు",
            GulikaiKalam: "10:44 నుండి 12:22 వరకు",
            Karanamulu: "వణిజ 08:54 వరకు, విష్టి 21:02 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "అనూరాధ 03:12 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:22 నుండి 14:00 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:51",
            Sunset: "18:54",
            Thiti: "ఏకాదశి 09:03 వరకు",
            Yamaganda: "07:29 నుండి 09:07 వరకు",
            Yoga: "శుభ 07:05 వరకు",
            Varjyam: "06:23 నుండి 08:03 వరకు",
            Moonrise: "15:13",
            Moonset: "02:28, జూలై 18",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "17",
            LordImage: require("../images/holidays/7-5)Moharam.png"),
          },
          {
            Abhijit: "11:56 నుండి 12:48 వరకు",
            Amrutakalam: "18:33 నుండి 20:09 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము 03:25, జూలై 19 వరకు",
            Date: "గురువారము, జూలై 18, 2024",
            DurMuhurtamulu: "10:00 నుండి 10:52 వరకు",
            GulikaiKalam: "09:07 నుండి 10:45 వరకు",
            Karanamulu: "బవ 08:59 వరకు, బాలవ 20:44 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "జ్యేష్ఠ 03:25 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "14:00 నుండి 15:38 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:51",
            Sunset: "18:54",
            Thiti: "ద్వాదశి 20:45 వరకు",
            Yamaganda: "05:51 నుండి 07:29 వరకు",
            Yoga: "శుక్ల 06:13 వరకు",
            Varjyam: "08:51 నుండి 10:28 వరకు",
            Moonrise: "16:13",
            Moonset: "03:22, జూలై 19",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:49 వరకు",
            Amrutakalam: "20:39 నుండి 22:13 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "శుక్రవారము, జూలై 19, 2024",
            DurMuhurtamulu: "08:18 నుండి 09:10 వరకు",
            GulikaiKalam: "07:29 నుండి 09:07 వరకు",
            Karanamulu: "కౌలవ 08:18 వరకు, తైతిల 19:41 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "మూల 02:54 వరకు",
            MainNakshatra: "మూల",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:45 నుండి 12:22 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:51",
            Sunset: "18:53",
            Thiti: "త్రయోదశి 07:42 వరకు",
            Yamaganda: "15:38 నుండి 17:16 వరకు",
            Yoga: "ఐన్ద్ర 02:41, జూలై 20 వరకు",
            Varjyam: "01:20 నుండి 02:54 వరకు",
            Moonrise: "17:13",
            Moonset: "04:23, జూలై 20",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:49 వరకు",
            Amrutakalam: "21:14 నుండి 22:45 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "శనివారము, జూలై 20, 2024",
            DurMuhurtamulu: "05:43 నుండి 07:26 వరకు",
            GulikaiKalam: "05:52 నుండి 07:29 వరకు",
            Karanamulu: "బవ 06:54 వరకు, వణిజ 17:59 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "పూర్వాషాఢ 01:48 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:07 నుండి 10:45 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:52",
            Sunset: "18:53",
            Thiti: "చతుర్దశి 18:00 వరకు",
            Yamaganda: "14:00 నుండి 15:38 వరకు",
            Yoga: "వైధృతి 00:08, జూలై 21 వరకు",
            Varjyam: "12:03 నుండి 13:35 వరకు",
            Moonrise: "18:12",
            Moonset: "05:27, జూలై 21",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:49 వరకు",
            Amrutakalam: "18:15 నుండి 19:45 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు 07:27 వరకు",
            Date: "ఆదివారము, జూలై 21, 2024",
            DurMuhurtamulu: "16:52 నుండి 17:43 వరకు",
            GulikaiKalam: "15:38 నుండి 17:15 వరకు",
            Karanamulu: "బవ 15:46 వరకు, బాలవ 02:31, జూలై 22 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "ఉత్తరాషాఢ 00:14 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "17:15 నుండి 18:53 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:52",
            Sunset: "18:53",
            Thiti: "పౌర్ణమి 15:47 వరకు",
            Yamaganda: "12:23 నుండి 14:00 వరకు",
            Yoga: "విష్కుమ్భ 21:11 వరకు",
            Varjyam: "09:17 నుండి 10:47 వరకు",
            Moonrise: "19:08",
            Moonset: "ఏమిలేదు",
            Shaka: "1946 క్రోధి",
            Amavasya: "pournami",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:49 వరకు",
            Amrutakalam: "12:46 నుండి 14:14 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము",
            Date: "సోమవారము, జూలై 22, 2024",
            DurMuhurtamulu: "12:35 నుండి 13:27 వరకు",
            GulikaiKalam: "14:00 నుండి 15:38 వరకు",
            Karanamulu: "కౌలవ 13:11 వరకు, తైతిల 23:48 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "శ్రవణం 22:20 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "07:30 నుండి 09:08 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:52",
            Sunset: "18:53",
            Thiti: "పాడ్యమి 13:12 వరకు",
            Yamaganda: "10:45 నుండి 12:23 వరకు",
            Yoga: "ప్రీతి 17:58 వరకు",
            Varjyam: "02:00 నుండి 03:28 వరకు",
            Moonrise: "19:58",
            Moonset: "06:32",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:49 వరకు",
            Amrutakalam: "10:47 నుండి 12:15 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము 09:20 వరకు",
            Date: "మంగళవారము, జూలై 23, 2024",
            DurMuhurtamulu: "08:18 నుండి 09:09 వరకు",
            GulikaiKalam: "12:23 నుండి 14:00 వరకు",
            Karanamulu: "బవ 10:23 వరకు, వణిజ 20:56 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "ధనిష్ఠ 20:18 వరకు",
            MainNakshatra: "ధనిష్ఠ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:38 నుండి 17:15 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:53",
            Sunset: "18:52",
            Thiti: "విదియ 10:24 వరకు",
            Yamaganda: "09:08 నుండి 10:45 వరకు",
            Yoga: "ఆయుష్మాన్ 14:36 వరకు",
            Varjyam: "02:53 నుండి 04:21 వరకు",
            Moonrise: "20:44",
            Moonset: "07:36",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "11:39 నుండి 13:07 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము",
            Date: "బుధవారము, జూలై 24, 2024",
            DurMuhurtamulu: "11:44 నుండి 12:35 వరకు",
            GulikaiKalam: "10:45 నుండి 12:23 వరకు",
            Karanamulu: "విష్టి 07:30 వరకు, బవ 18:04 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "శతభిషం 18:14 వరకు",
            MainNakshatra: "శతభిషం",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:23 నుండి 14:00 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:53",
            Sunset: "18:52",
            Thiti: "తదియ 07:31 వరకు, చవితి 04:40 వరకు",
            Yamaganda: "07:30 నుండి 09:08 వరకు",
            Yoga: "సౌభాగ్య 11:11 వరకు",
            Varjyam: "00:06 నుండి 01:34 వరకు",
            Moonrise: "21:26",
            Moonset: "08:37",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:49 వరకు",
            Amrutakalam: "08:55 నుండి 10:24 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము 10:45 వరకు",
            Date: "గురువారము, జూలై 25, 2024",
            DurMuhurtamulu: "08:19 నుండి 09:10 వరకు",
            GulikaiKalam: "09:08 నుండి 10:45 వరకు",
            Karanamulu: "కౌలవ 15:17 వరకు, తైతిల 01:58, జూలై 26 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "పూర్వాభాద్ర 16:16 వరకు",
            MainNakshatra: "పూర్వాభాద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "14:00 నుండి 15:37 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:53",
            Sunset: "18:52",
            Thiti: "పంచమి 01:59 వరకు",
            Yamaganda: "05:53 నుండి 07:31 వరకు",
            Yoga: "శోభన 07:49 వరకు",
            Varjyam: "01:44 నుండి 03:14 వరకు",
            Moonrise: "22:06",
            Moonset: "09:36",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:49 వరకు",
            Amrutakalam: "10:03 నుండి 11:32 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం",
            Date: "శుక్రవారము, జూలై 26, 2024",
            DurMuhurtamulu: "08:19 నుండి 09:10 వరకు",
            GulikaiKalam: "07:31 నుండి 09:08 వరకు",
            Karanamulu: "బవ 12:42 వరకు, వణిజ 23:30 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "ఉత్తరాభాద్ర 14:29 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:45 నుండి 12:23 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:54",
            Sunset: "18:52",
            Thiti: "షష్టి 23:31 వరకు",
            Yamaganda: "15:37 నుండి 17:14 వరకు",
            Yoga: "సుకర్మా 01:32, జూలై 27 వరకు",
            Varjyam: "01:44 నుండి 03:14 వరకు",
            Moonrise: "22:45",
            Moonset: "10:34",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:49 వరకు",
            Amrutakalam: "10:45 నుండి 12:15 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం 13:00 వరకు",
            Date: "శనివారము, జూలై 27, 2024",
            DurMuhurtamulu: "05:54 నుండి 07:28 వరకు",
            GulikaiKalam: "05:54 నుండి 07:31 వరకు",
            Karanamulu: "విష్టి 10:22 వరకు, బవ 21:19 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "రేవతి 12:59 వరకు",
            MainNakshatra: "రేవతి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:08 నుండి 10:45 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:54",
            Sunset: "18:51",
            Thiti: "సప్తమి 21:20 వరకు",
            Yamaganda: "14:00 నుండి 15:37 వరకు",
            Yoga: "ధృతి 22:44 వరకు",
            Varjyam: "ఏమిలేదు",
            Moonrise: "23:26",
            Moonset: "11:32",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:48 వరకు",
            Amrutakalam: "ఏమిలేదు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం",
            Date: "ఆదివారము, జూలై 28, 2024",
            DurMuhurtamulu: "16:51 నుండి 17:42 వరకు",
            GulikaiKalam: "15:37 నుండి 17:14 వరకు",
            Karanamulu: "బాలవ 08:20 వరకు, కౌలవ 19:27 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "అశ్విని 11:47 వరకు",
            MainNakshatra: "అశ్విని",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "17:14 నుండి 18:51 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:54",
            Sunset: "18:51",
            Thiti: "అష్టమి 19:28 వరకు",
            Yamaganda: "12:23 నుండి 14:00 వరకు",
            Yoga: "శూల 20:11 వరకు",
            Varjyam: "07:59 నుండి 09:30 వరకు",
            Moonrise: "00:09, జూలై 29",
            Moonset: "12:31",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:48 వరకు",
            Amrutakalam: "06:17 నుండి 07:50 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం 16:45 వరకు",
            Date: "సోమవారము, జూలై 29, 2024",
            DurMuhurtamulu: "12:35 నుండి 13:26 వరకు",
            GulikaiKalam: "14:00 నుండి 15:37 వరకు",
            Karanamulu: "తైతిల 06:38 వరకు, బవ 17:55 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "భరణి 10:54 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "07:32 నుండి 09:09 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:55",
            Sunset: "18:50",
            Thiti: "నవమి 17:56 వరకు",
            Yamaganda: "10:46 నుండి 12:23 వరకు",
            Yoga: "గణ్డ 17:55 వరకు",
            Varjyam: "22:39 నుండి 00:13 వరకు",
            Moonrise: "00:56, జూలై 30",
            Moonset: "13:31",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:48 వరకు",
            Amrutakalam: "08:02 నుండి 09:36 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృషభం",
            Date: "మంగళవారము, జూలై 30, 2024",
            DurMuhurtamulu: "08:19 నుండి 09:10 వరకు",
            GulikaiKalam: "12:23 నుండి 13:59 వరకు",
            Karanamulu: "విష్టి 16:44 వరకు, బవ 04:17, జూలై 31 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "కృత్తిక 10:23 వరకు",
            MainNakshatra: "కృత్తిక",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:36 నుండి 17:13 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:55",
            Sunset: "18:50",
            Thiti: "దశమి 16:45 వరకు",
            Yamaganda: "09:09 నుండి 10:46 వరకు",
            Yoga: "వృద్ధి 15:56 వరకు",
            Varjyam: "02:16 నుండి 03:51 వరకు",
            Moonrise: "01:47, జూలై 31",
            Moonset: "14:32",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam:
              "07:02 నుండి 08:37 వరకు, 01:32, ఆగష్టు 01 నుండి 03:08, ఆగష్టు 01 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృషభం 22:15 వరకు",
            Date: "బుధవారము, జూలై 31, 2024",
            DurMuhurtamulu: "11:43 నుండి 12:34 వరకు",
            GulikaiKalam: "10:46 నుండి 12:22 వరకు",
            Karanamulu: "బాలవ 15:55 వరకు, కౌలవ 03:39, ఆగష్టు 01 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "రోహిణి 10:12 వరకు",
            MainNakshatra: "రోహిణి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:22 నుండి 13:59 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:55",
            Sunset: "18:50",
            Thiti: "ఏకాదశి 03:56 వరకు",
            Yamaganda: "07:32 నుండి 09:09 వరకు",
            Yoga: "ధ్రువ 14:14 వరకు",
            Varjyam: "15:51 నుండి 17:28 వరకు",
            Moonrise: "02:42, ఆగష్టు 01",
            Moonset: "15:33",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
        ],
      },
      {
        day: [
          {
            Abhijit: "11:57 నుండి 12:48 వరకు",
            Amrutakalam: "00:44, ఆగష్టు 02 నుండి 02:22, ఆగష్టు 02 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము",
            Date: "గురువారము, ఆగష్టు 1, 2024",
            DurMuhurtamulu: "10:02 నుండి 10:53 వరకు",
            GulikaiKalam: "09:09 నుండి 10:46 వరకు",
            Karanamulu: "తైతిల 15:28 వరకు, బవ 03:24, ఆగష్టు 02 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "మృగశిర 10:23 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:59 నుండి 15:36 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:56",
            Sunset: "18:49",
            Thiti: "ద్వాదశి 15:29 వరకు",
            Yamaganda: "05:56 నుండి 07:32 వరకు",
            Yoga: "వ్యాఘాత 12:50 వరకు",
            Varjyam: "19:00 నుండి 20:38 వరకు",
            Moonrise: "03:40, ఆగష్టు 02",
            Moonset: "16:32",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:48 వరకు",
            Amrutakalam: "ఏమిలేదు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం 05:41, ఆగష్టు 03 వరకు",
            Date: "శుక్రవారము, ఆగష్టు 2, 2024",
            DurMuhurtamulu: "08:20 నుండి 09:11 వరకు",
            GulikaiKalam: "07:32 నుండి 09:09 వరకు",
            Karanamulu: "వణిజ 15:26 వరకు, విష్టి 03:35, ఆగష్టు 03 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "ఆరుద్ర 10:58 వరకు",
            MainNakshatra: "ఆరుద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:46 నుండి 12:22 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:56",
            Sunset: "18:49",
            Thiti: "త్రయోదశి 15:27 వరకు",
            Yamaganda: "15:36 నుండి 17:12 వరకు",
            Yoga: "హర్షణ 11:45 వరకు",
            Varjyam: "23:28 నుండి 01:08",
            Moonrise: "04:38, ఆగష్టు 03",
            Moonset: "17:27",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:48 వరకు",
            Amrutakalam: "09:29 నుండి 11:09 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "శనివారము, ఆగష్టు 3, 2024",
            DurMuhurtamulu: "05:47 నుండి 07:29 వరకు",
            GulikaiKalam: "05:56 నుండి 07:33 వరకు",
            Karanamulu: "శకుని 15:50 వరకు, చతుష్పాద 04:12, ఆగష్టు 04 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "పునర్వసు 11:58 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:09 నుండి 10:46 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:56",
            Sunset: "18:48",
            Thiti: "చతుర్దశి 15:51 వరకు",
            Yamaganda: "13:59 నుండి 15:35 వరకు",
            Yoga: "వజ్ర 11:01 వరకు",
            Varjyam: "20:27 నుండి 10:09 వరకు",
            Moonrise: "05:35, ఆగష్టు 04",
            Moonset: "18:15",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:48 వరకు",
            Amrutakalam: "06:39 నుండి 08:21 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "ఆదివారము, ఆగష్టు 4, 2024",
            DurMuhurtamulu: "16:49 నుండి 17:40 వరకు",
            GulikaiKalam: "15:35 నుండి 17:11 వరకు",
            Karanamulu: "నాగ 16:42 వరకు, కింస్తుఘ్న 05:19, ఆగష్టు 05 వరకు",
            Masam: "ఆషాఢ మాసం",
            Nakshatra1: "పుష్యమి 13:25 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "17:11 నుండి 18:48 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:56",
            Sunset: "18:48",
            Thiti: "అమావాస్య 16:43 వరకు",
            Yamaganda: "12:22 నుండి 13:59 వరకు",
            Yoga: "సిద్ధి 10:38 వరకు",
            Varjyam: "03:15 నుండి 04:59 వరకు",
            Moonrise: "ఏమిలేదు",
            Moonset: "18:59",
            Shaka: "1946 క్రోధి",
            Amavasya: "amavasya",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:48 వరకు",
            Amrutakalam: "13:38 నుండి 15:21 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం 15:21 వరకు",
            Date: "సోమవారము, ఆగష్టు 5, 2024",
            DurMuhurtamulu: "12:34 నుండి 13:25 వరకు",
            GulikaiKalam: "13:58 నుండి 15:35 వరకు",
            Karanamulu: "బవ 18:03 వరకు, బాలవ నిండా రాత్రి వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "ఆశ్లేష 15:21 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:33 నుండి 09:09 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "05:57",
            Sunset: "18:47",
            Thiti: "పాడ్యమి 18:04 వరకు",
            Yamaganda: "10:46 నుండి 12:22 వరకు",
            Yoga: "వ్యతీపాత 10:38 వరకు",
            Varjyam: "04:32 నుండి 05:54 వరకు",
            Moonrise: "06:29",
            Moonset: "19:37",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:48 వరకు",
            Amrutakalam: "15:06 నుండి 16:51 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం",
            Date: "మంగళవారము, ఆగష్టు 6, 2024",
            DurMuhurtamulu: "08:20 నుండి 09:11 వరకు",
            GulikaiKalam: "12:22 నుండి 13:58 వరకు",
            Karanamulu: "బాలవ 06:54 వరకు, కౌలవ 19:52 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "మఖ 17:43 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:34 నుండి 17:11 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "05:57",
            Sunset: "18:47",
            Thiti: "విదియ 19:53 వరకు",
            Yamaganda: "09:09 నుండి 10:46 వరకు",
            Yoga: "వారీయన 11:00 వరకు",
            Varjyam: "శే.వ.06:17 వరకు",
            Moonrise: "07:20",
            Moonset: "20:12",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:48 వరకు",
            Amrutakalam: "18:12 నుండి 19:47 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం",
            Date: "బుధవారము, ఆగష్టు 7, 2024",
            DurMuhurtamulu: "11:43 నుండి 12:34 వరకు",
            GulikaiKalam: "13:58 నుండి 15:34 వరకు",
            Karanamulu: "విష్టి 16:41 వరకు, బవ 04:14, ఆగష్టు 08 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "పుబ్బ 20:30 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:22 నుండి 13:58 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "05:57",
            Sunset: "18:46",
            Thiti: "తదియ 22:06 వరకు",
            Yamaganda: "07:33 నుండి 09:10 వరకు",
            Yoga: "పరిఘ 11:42 వరకు",
            Varjyam: "03:36 నుండి 05:22 వరకు",
            Moonrise: "08:09",
            Moonset: "20:45",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:47 వరకు",
            Amrutakalam: "15:27 నుండి 17:15 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య",
            Date: "గురువారము, ఆగష్టు 8, 2024",
            DurMuhurtamulu: "10:02 నుండి 10:53 వరకు",
            GulikaiKalam: "09:10 నుండి 10:46 వరకు",
            Karanamulu: "వణిజ 11:19 వరకు, విష్టి 00:36, ఆగష్టు 09 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "ఉత్తర 23:33 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:58 నుండి 15:34 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "05:57",
            Sunset: "18:46",
            Thiti: "చవితి 00:37 వరకు",
            Yamaganda: "05:57 నుండి 07:34 వరకు",
            Yoga: "శివ 12:39 వరకు",
            Varjyam: "శే.వ.06:25 వరకు",
            Moonrise: "08:56",
            Moonset: "21:17",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:47 వరకు",
            Amrutakalam: "19:57 నుండి 21:45 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య",
            Date: "శుక్రవారము, ఆగష్టు 9, 2024",
            DurMuhurtamulu: "08:21 నుండి 09:12 వరకు",
            GulikaiKalam: "07:34 నుండి 09:10 వరకు",
            Karanamulu: "బవ 13:55 వరకు, బాలవ 03:14, ఆగష్టు 10 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "హస్త 02:44 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:46 నుండి 12:22 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "05:58",
            Sunset: "18:45",
            Thiti: "పంచమి 03:15 వరకు",
            Yamaganda: "15:33 నుండి 17:09 వరకు",
            Yoga: "సిద్ధ 13:46 వరకు",
            Varjyam: "09:04 నుండి 10:53 వరకు",
            Moonrise: " 09:42",
            Moonset: "21:48",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:47 వరకు",
            Amrutakalam: "22:35 నుండి 00:24, ఆగష్టు 11 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య 16:18 వరకు",
            Date: "శనివారము, ఆగష్టు 10, 2024",
            DurMuhurtamulu: "05:49 నుండి 07:30 వరకు",
            GulikaiKalam: "05:58 నుండి 07:34 వరకు",
            Karanamulu: "కౌలవ 16:31 వరకు, తైతిల 05:44, ఆగష్టు 11 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "చిత్తా 05:48 వరకు",
            MainNakshatra: "చిత్త",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:10 నుండి 10:46 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "05:58",
            Sunset: "18:45",
            Thiti: "షష్టి 05:46 వరకు",
            Yamaganda: "13:57 నుండి 15:33 వరకు",
            Yoga: "సాధ్య 14:52 వరకు",
            Varjyam: "11:45 నుండి 13:33 వరకు",
            Moonrise: "10:28",
            Moonset: "22:21",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:47 వరకు",
            Amrutakalam: "22:45 నుండి 00:32, ఆగష్టు 12 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల",
            Date: "ఆదివారము, ఆగష్టు 11, 2024",
            DurMuhurtamulu: "16:46 నుండి 17:37 వరకు",
            GulikaiKalam: "15:33 నుండి 17:08 వరకు",
            Karanamulu: "గర 18:53 వరకు, వణిజ నిండా రాత్రి వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "స్వాతి నిండా రాత్రి వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "17:08 నుండి 18:44 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "05:58",
            Sunset: "18:44",
            Thiti: "సప్తమి నిండా రాత్రి వరకు",
            Yamaganda: "12:21 నుండి 13:57 వరకు",
            Yoga: "శుభ 15:49 వరకు",
            Varjyam: "12:03 నుండి 13:50 వరకు",
            Moonrise: "11:17",
            Moonset: "22:56",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:47 వరకు",
            Amrutakalam: "01:08, ఆగష్టు 13 నుండి 02:53, ఆగష్టు 13 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల 04:15, ఆగష్టు 13 వరకు",
            Date: "సోమవారము, ఆగష్టు 12, 2024",
            DurMuhurtamulu: "12:33 నుండి 13:24 వరకు",
            GulikaiKalam: "13:57 నుండి 15:32 వరకు",
            Karanamulu: "వణిజ 07:55 వరకు, విష్టి 20:48 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "స్వాతి 08:32 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:34 నుండి 09:10 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "05:58",
            Sunset: "18:44",
            Thiti: "సప్తమి 07:56 వరకు",
            Yamaganda: "10:45 నుండి 12:21 వరకు",
            Yoga: "శుక్ల 16:26 వరకు",
            Varjyam: "14:39 నుండి 16:24 వరకు",
            Moonrise: "12:07",
            Moonset: "23:34",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:55 నుండి 12:46 వరకు",
            Amrutakalam: "01:10, ఆగష్టు 14 నుండి 02:52, ఆగష్టు 14 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "మంగళవారము, ఆగష్టు 13, 2024",
            DurMuhurtamulu: "08:21 నుండి 09:11 వరకు",
            GulikaiKalam: "12:21 నుండి 13:56 వరకు",
            Karanamulu: "బవ 09:31 వరకు, బాలవ 22:03 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "విశాఖ 10:44 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:32 నుండి 17:07 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "05:59",
            Sunset: "18:43",
            Thiti: "అష్టమి 09:32 వరకు",
            Yamaganda: "09:10 నుండి 10:45 వరకు",
            Yoga: "బ్రహ్మ 16:34 వరకు",
            Varjyam: "14:59 నుండి 16:41 వరకు",
            Moonrise: "13:01",
            Moonset: "00:18, ఆగష్టు 14",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "03:50, ఆగష్టు 15 నుండి 05:29, ఆగష్టు 15 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "బుధవారము, ఆగష్టు 14, 2024",
            DurMuhurtamulu: "11:42 నుండి 12:32 వరకు",
            GulikaiKalam: "10:45 నుండి 12:21 వరకు",
            Karanamulu: "కౌలవ 10:23 వరకు, తైతిల 22:31 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "అనూరాధ 12:12 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:21 నుండి 13:56 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "05:59",
            Sunset: "18:42",
            Thiti: "నవమి 10:24 వరకు",
            Yamaganda: "07:34 నుండి 09:10 వరకు",
            Yoga: "ఐన్ద్ర 16:06 వరకు",
            Varjyam: "17:58 నుండి 19:37 వరకు",
            Moonrise: "13:58",
            Moonset: "01:08, ఆగష్టు 15",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:55 నుండి 12:46 వరకు",
            Amrutakalam: "ఏమిలేదు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "గురువారము, ఆగస్టు 15, 2024",
            DurMuhurtamulu: "10:02 నుండి 10:52 వరకు",
            GulikaiKalam: "09:10 నుండి 10:45 వరకు",
            Karanamulu: "బవ 10:26 వరకు, వణిజ 22:09 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "జ్యేష్ఠ 12:52 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:56 నుండి 15:31 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "05:59",
            Sunset: "18:42",
            Thiti: "దశమి 10:27 వరకు",
            Yamaganda: "05:59 నుండి 07:34 వరకు",
            Yoga: "వైధృతి 14:59 వరకు",
            Varjyam: "ఏమిలేదు",
            Moonrise: "14:57",
            Moonset: "02:05, ఆగష్టు 16",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "15",
            LordImage: require("../images/holidays/7-3)Independenceday.png"),
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "06:22 నుండి 07:57 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "శుక్రవారము, ఆగష్టు 16, 2024",
            DurMuhurtamulu: "08:21 నుండి 09:11 వరకు",
            GulikaiKalam: "07:35 నుండి 09:10 వరకు",
            Karanamulu: "విష్టి 09:39 వరకు, బవ 20:58 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "మూల 12:43 వరకు",
            MainNakshatra: "మూల",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:45 నుండి 12:20 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "05:59",
            Sunset: "18:41",
            Thiti: "ఏకాదశి 09:40 వరకు",
            Yamaganda: "15:31 నుండి 17:06 వరకు",
            Yoga: "విష్కుమ్భ 13:12 వరకు",
            Varjyam: "11:07 నుండి 12:42 వరకు",
            Moonrise: "15:56",
            Moonset: "03:07, ఆగష్టు 17",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:55 నుండి 12:45 వరకు",
            Amrutakalam: "07:12 నుండి 08:44 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు 17:29 వరకు",
            Date: "శనివారము, ఆగష్టు 17, 2024",
            DurMuhurtamulu: "05:51 నుండి 07:31 వరకు",
            GulikaiKalam: "06:00 నుండి 07:35 వరకు",
            Karanamulu: "బాలవ 08:05 వరకు, కౌలవ 19:03 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "పూర్వాషాఢ 11:48 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:10 నుండి 10:45 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:00",
            Sunset: "18:40",
            Thiti: "ద్వాదశి 08:06 వరకు",
            Yamaganda: "13:55 నుండి 15:30 వరకు",
            Yoga: "ప్రీతి 10:48 వరకు",
            Varjyam: "18:57 నుండి 20:23 వరకు",
            Moonrise: "16:53",
            Moonset: "04:12, ఆగష్టు 18",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:54 నుండి 12:45 వరకు",
            Amrutakalam: "22:40 నుండి 00:08, ఆగష్టు 19 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము",
            Date: "ఆదివారము, ఆగష్టు 18, 2024",
            DurMuhurtamulu: "16:43 నుండి 17:33 వరకు",
            GulikaiKalam: "15:30 నుండి 17:05 వరకు",
            Karanamulu: "బవ 16:31 వరకు, వణిజ 03:04, ఆగష్టు 19 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "ఉత్తరాషాఢ 10:14 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "17:05 నుండి 18:40 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:00",
            Sunset: "18:40",
            Thiti: "త్రయోదశి 05:52 వరకు, చతుర్దశి 03:05 వరకు",
            Yamaganda: "12:20 నుండి 13:55 వరకు",
            Yoga: "ఆయుష్మాన్ 07:51 వరకు",
            Varjyam: "13:53 నుండి 15:21 వరకు",
            Moonrise: "17:46",
            Moonset: "05:17, ఆగష్టు 19",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:54 నుండి 12:45 వరకు",
            Amrutakalam: "20:24 నుండి 21:50 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము 19:00 వరకు",
            Date: "సోమవారము, ఆగష్టు 19, 2024",
            DurMuhurtamulu: "12:31 నుండి 13:22 వరకు",
            GulikaiKalam: "13:54 నుండి 15:29 వరకు",
            Karanamulu: "విష్టి 13:32 వరకు, బవ 23:55 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "శ్రవణం 08:10 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:35 నుండి 09:10 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:00",
            Sunset: "18:39",
            Thiti: "పౌర్ణమి 23:56 వరకు",
            Yamaganda: "10:45 నుండి 12:20 వరకు",
            Yoga: "సిద్ధ 21:19 వరకు",
            Varjyam: "11:53 నుండి 13:19 వరకు",
            Moonrise: "18:34",
            Moonset: "ఏమిలేదు",
            Shaka: "1946 క్రోధి",
            Amavasya: "pournami",
            FestDate: "19",
            LordImage: require("../images/holidays/7-1)Rakshabandhan.png"),
          },
          {
            Abhijit: "11:54 నుండి 12:45 వరకు",
            Amrutakalam: "20:44 నుండి 22:10 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము",
            Date: "మంగళవారము, ఆగష్టు 20, 2024",
            DurMuhurtamulu: "08:21 నుండి 09:11 వరకు",
            GulikaiKalam: "12:19 నుండి 13:54 వరకు",
            Karanamulu: "బాలవ 10:15 వరకు, కౌలవ 20:32 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "శతభిషం 03:09 వరకు",
            MainNakshatra: "శతభిషం",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:29 నుండి 17:04 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:00",
            Sunset: "18:38",
            Thiti: "పాడ్యమి 20:33 వరకు",
            Yamaganda: "09:10 నుండి 10:45 వరకు",
            Yoga: "అతిగణ్డ 20:55 వరకు",
            Varjyam: "12:10 నుండి 13:36 వరకు",
            Moonrise: "19:19",
            Moonset: "06:21",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "17:25 నుండి 18:51 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము 19:12 వరకు",
            Date: "బుధవారము, ఆగష్టు 21, 2024",
            DurMuhurtamulu: "11:41 నుండి 12:31 వరకు",
            GulikaiKalam: "10:44 నుండి 12:19 వరకు",
            Karanamulu: "తైతిల 06:49 వరకు, బవ 17:06 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "పూర్వాభాద్ర 00:33 వరకు",
            MainNakshatra: "పూర్వాభాద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:19 నుండి 13:54 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:00",
            Sunset: "18:38",
            Thiti: "విదియ 17:07 వరకు",
            Yamaganda: "07:35 నుండి 09:10 వరకు",
            Yoga: "సుకర్మా 17:01 వరకు",
            Varjyam: "08:51 నుండి 10:17 వరకు",
            Moonrise: "20:01",
            Moonset: "07:22",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:54 నుండి 12:44 వరకు",
            Amrutakalam: "17:47 నుండి 19:13 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం",
            Date: "గురువారము, ఆగష్టు 22, 2024",
            DurMuhurtamulu: "10:01 నుండి 10:51 వరకు",
            GulikaiKalam: "09:10 నుండి 10:44 వరకు",
            Karanamulu: "విష్టి 13:46 వరకు, బవ 00:10, ఆగష్టు 23 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "ఉత్తరాభాద్ర 22:05 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:53 నుండి 15:28 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:01",
            Sunset: "18:37",
            Thiti: "తదియ 13:46 వరకు",
            Yamaganda: "06:01 నుండి 07:35 వరకు",
            Yoga: "ధృతి 13:11 వరకు",
            Varjyam: "09:10 నుండి 10:36 వరకు",
            Moonrise: "20:42",
            Moonset: "08:23",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:44 వరకు",
            Amrutakalam: "17:43 నుండి 19:10 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం 19:54 వరకు",
            Date: "శుక్రవారము, ఆగష్టు 23, 2024",
            DurMuhurtamulu: "08:21 నుండి 09:11 వరకు",
            GulikaiKalam: "07:35 నుండి 09:10 వరకు",
            Karanamulu: "బాలవ 10:38 వరకు, కౌలవ 21:12 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "రేవతి 19:54 వరకు",
            MainNakshatra: "రేవతి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:44 నుండి 12:19 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:01",
            Sunset: "18:36",
            Thiti: "చవితి 10:39 వరకు",
            Yamaganda: "15:27 నుండి 17:02 వరకు",
            Yoga: "శూల 09:31 వరకు",
            Varjyam: "08:59 నుండి 10:26 వరకు",
            Moonrise: "21:23",
            Moonset: "09:23",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:43 వరకు",
            Amrutakalam: "22:46 నుండి 00:22, ఆగష్టు 25 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "శనివారము, ఆగష్టు 24, 2024",
            DurMuhurtamulu: "05:52 నుండి 07:32 వరకు",
            GulikaiKalam: "09:10 నుండి 10:44 వరకు",
            Karanamulu: "విష్టి 15:26 వరకు, బవ 03:10, ఆగష్టు 25 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "అశ్విని 18:05 వరకు",
            MainNakshatra: "అశ్విని",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:10 నుండి 10:44 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:01",
            Sunset: "18:36",
            Thiti: "పంచమి 07:52 వరకు, షష్టి 05:31 వరకు",
            Yamaganda: "13:53 నుండి 15:27 వరకు",
            Yoga: "గణ్డ 06:09 వరకు",
            Varjyam: "14:24 నుండి 15:53 వరకు",
            Moonrise: "22:06",
            Moonset: "10:23",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:43 వరకు",
            Amrutakalam: "12:13 నుండి 13:44 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం 22:29 వరకు",
            Date: "ఆదివారము, ఆగష్టు 25, 2024",
            DurMuhurtamulu: "16:39 నుండి 17:29 వరకు",
            GulikaiKalam: "15:26 నుండి 17:01 వరకు",
            Karanamulu: "విష్టి 16:30 వరకు, కౌలవ 02:02, ఆగష్టు 26 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "భరణి 16:44 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "17:01 నుండి 18:35 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:01",
            Sunset: "18:35",
            Thiti: "సప్తమి 03:40 వరకు",
            Yamaganda: "12:18 నుండి 13:52 వరకు",
            Yoga: "ధ్రువ 00:29, ఆగష్టు 26 వరకు",
            Varjyam: "04:18 నుండి 05:51 వరకు",
            Moonrise: "22:53",
            Moonset: "11:24",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:43 వరకు",
            Amrutakalam: "01:14, ఆగష్టు 27 నుండి 02:44, ఆగష్టు 27 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము 08:37 వరకు",
            Date: "సోమవారము, ఆగష్టు 26, 2024",
            DurMuhurtamulu: "12:29 నుండి 13:20 వరకు",
            GulikaiKalam: "06:01 నుండి 07:35 వరకు",
            Karanamulu: "తైతిల 13:10 వరకు, బవ 00:08, ఆగష్టు 27 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "కృత్తిక 15:55 వరకు",
            MainNakshatra: "కృత్తిక",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:10 నుండి 10:44 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:01",
            Sunset: "18:34",
            Thiti: "అష్టమి 02:20 వరకు",
            Yamaganda: "10:44 నుండి 12:18 వరకు",
            Yoga: "వ్యాఘాత 22:17 వరకు",
            Varjyam: "ఏమిలేదు",
            Moonrise: "23:43",
            Moonset: "12:26",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "26",
            LordImage: require("../images/holidays/7-6)Krishna.png"),
          },
          {
            Abhijit: "11:52 నుండి 12:43 వరకు",
            Amrutakalam: "12:28 నుండి 14:03 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృషభం 03:41, ఆగష్టు 28 వరకు",
            Date: "మంగళవారము, ఆగష్టు 27, 2024",
            DurMuhurtamulu: "08:21 నుండి 09:11 వరకు",
            GulikaiKalam: "12:17 నుండి 13:51 వరకు",
            Karanamulu: "తైతిల 13:52 వరకు, బవ 01:33, ఆగష్టు 28 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "రోహిణి 15:37 వరకు",
            MainNakshatra: "రోహిణి, పూర్వాషాఢ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:25 నుండి 16:59 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:01",
            Sunset: "18:33",
            Thiti: "నవమి 01:34 వరకు",
            Yamaganda: "09:09 నుండి 10:43 వరకు",
            Yoga: "హర్షణ 20:31 వరకు",
            Varjyam: "07:43 నుండి 09:18 వరకు",
            Moonrise: "00:38, ఆగష్టు 28",
            Moonset: "13:28",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "06:59 నుండి 08:36 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం",
            Date: "బుధవారము, ఆగష్టు 28, 2024",
            DurMuhurtamulu: "11:39 నుండి 12:29 వరకు",
            GulikaiKalam: "10:43 నుండి 12:17 వరకు",
            Karanamulu: "వణిజ 13:22 వరకు, విష్టి 01:19, ఆగష్టు 29 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "మృగశిర 15:52 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:17 నుండి 13:51 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:02",
            Sunset: "18:33",
            Thiti: "దశమి 01:20 వరకు",
            Yamaganda: "07:36 నుండి 09:09 వరకు",
            Yoga: "వజ్ర 19:12 వరకు",
            Varjyam: "00:33 నుండి 02:12 వరకు",
            Moonrise: "01:35, ఆగష్టు 29",
            Moonset: "14:28",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:52 నుండి 12:42 వరకు",
            Amrutakalam: "06:20 నుండి 07:59 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం",
            Date: "గురువారము, ఆగష్టు 29, 2024",
            DurMuhurtamulu: "10:00 నుండి 10:50 వరకు",
            GulikaiKalam: "09:09 నుండి 10:43 వరకు",
            Karanamulu: "బవ 13:24 వరకు, బాలవ 01:37, ఆగష్టు 30 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "ఆరుద్ర 16:39 వరకు",
            MainNakshatra: "ఆరుద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:51 నుండి 15:24 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:02",
            Sunset: "18:32",
            Thiti: "ఏకాదశి 01:38 వరకు",
            Yamaganda: "06:02 నుండి 07:36 వరకు",
            Yoga: "సిద్ధి 18:18 వరకు",
            Varjyam: "05:17 నుండి 06:31 వరకు",
            Moonrise: "02:33, ఆగష్టు 30",
            Moonset: "15:23",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:52 నుండి 12:42 వరకు",
            Amrutakalam: "15:24 నుండి 17:05 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం 11:34 వరకు",
            Date: "శుక్రవారము, ఆగష్టు 30, 2024",
            DurMuhurtamulu: "08:21 నుండి 09:10 వరకు",
            GulikaiKalam: "07:36 నుండి 09:09 వరకు",
            Karanamulu: "కౌలవ 13:57 వరకు, తైతిల 02:25, ఆగష్టు 31 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "పునర్వసు 17:55 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:43 నుండి 12:17 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:02",
            Sunset: "18:31",
            Thiti: "ద్వాదశి 02:26 వరకు",
            Yamaganda: "15:24 నుండి 16:57 వరకు",
            Yoga: "వ్యతీపాత 17:47 వరకు",
            Varjyam: "శే.వ.06:58 వరకు",
            Moonrise: "03:29, ఆగష్టు 31",
            Moonset: "16:13",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:51 నుండి 12:41 వరకు",
            Amrutakalam: "12:48 నుండి 14:31 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "శనివారము, ఆగష్టు 31, 2024",
            DurMuhurtamulu: "05:53 నుండి 07:32 వరకు",
            GulikaiKalam: "06:02 నుండి 07:36 వరకు",
            Karanamulu: "బవ 14:59 వరకు, వణిజ 03:40, సెప్టెంబర్ 01 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "పుష్యమి 19:39 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:09 నుండి 10:43 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:02",
            Sunset: "18:30",
            Thiti: "త్రయోదశి 03:41 వరకు",
            Yamaganda: "13:50 నుండి 15:23 వరకు",
            Yoga: "వారీయన 17:39 వరకు",
            Varjyam: "ఏమిలేదు",
            Moonrise: "04:24, సెప్టెంబర్ 01",
            Moonset: "16:58",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
        ],
      },
      {
        day: [
          {
            Abhijit: "11:51 నుండి 12:41 వరకు",
            Amrutakalam: "20:04 నుండి 21:49 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం 21:49 వరకు",
            Date: "ఆదివారము, సెప్టెంబర్ 1, 2024",
            DurMuhurtamulu: "16:34 నుండి 17:23 వరకు",
            GulikaiKalam: "15:23 నుండి 16:56 వరకు",
            Karanamulu: "విష్టి 16:28 వరకు, శకుని 05:21, సెప్టెంబర్ 02 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "ఆశ్లేష 21:48 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:56 నుండి 18:30 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:02",
            Sunset: "18:30",
            Thiti: "చతుర్దశి 05:22 వరకు",
            Yamaganda: "12:16 నుండి 13:49 వరకు",
            Yoga: "పరిఘ 17:50 వరకు",
            Varjyam: "09:35 నుండి 11:20 వరకు",
            Moonrise: "05:15, సెప్టెంబర్ 02",
            Moonset: "17:37",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:51 నుండి 12:40 వరకు",
            Amrutakalam: "21:41 నుండి 23:27 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం",
            Date: "సోమవారము, సెప్టెంబర్ 2, 2024",
            DurMuhurtamulu: "12:27 నుండి 13:16 వరకు",
            GulikaiKalam: "13:49 నుండి 15:22 వరకు",
            Karanamulu: "చతుష్పాద 18:20 వరకు, నాగ నిండా రాత్రి వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "మఖ 00:19 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "07:36 నుండి 09:09 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:02",
            Sunset: "18:29",
            Thiti: "అమావాస్య నిండా రాత్రి వరకు",
            Yamaganda: "10:42 నుండి 12:16 వరకు",
            Yoga: "శివ 18:20 వరకు",
            Varjyam: "11:04 నుండి 12:50 వరకు",
            Moonrise: "ఏమిలేదు",
            Moonset: "18:13",
            Shaka: "1946 క్రోధి",
            Amavasya: "amavasya",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:50 నుండి 12:40 వరకు",
            Amrutakalam: "20:01 నుండి 21:48 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం",
            Date: "మంగళవారము, సెప్టెంబర్ 3, 2024",
            DurMuhurtamulu: "08:21 నుండి 09:10 వరకు",
            GulikaiKalam: "12:15 నుండి 13:48 వరకు",
            Karanamulu: "నాగ 07:24 వరకు, కింస్తుఘ్న 20:33 వరకు",
            Masam: "శ్రావణ మాసం",
            Nakshatra1: "పుబ్బ 03:10 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:22 నుండి 16:55 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:03",
            Sunset: "18:28",
            Thiti: "అమావాస్య 07:26 వరకు",
            Yamaganda: "09:09 నుండి 10:42 వరకు",
            Yoga: "సిద్ధ 19:05 వరకు",
            Varjyam: "09:16 నుండి 11:03 వరకు",
            Moonrise: "06:04",
            Moonset: "18:46",
            Shaka: "1946 క్రోధి",
            Amavasya: "amavasya",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "22:07 నుండి 23:55 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం 09:55 వరకు",
            Date: "బుధవారము, సెప్టెంబర్ 4, 2024",
            DurMuhurtamulu: "11:37 నుండి 12:26 వరకు",
            GulikaiKalam: "10:42 నుండి 12:15 వరకు",
            Karanamulu: "బవ 09:46 వరకు, బాలవ 23:02 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "ఉత్తర పూర్తి",
            MainNakshatra: "ఉత్తర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:15 నుండి 13:48 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:03",
            Sunset: "18:27",
            Thiti: "పాడ్యమి 09:47 వరకు",
            Yamaganda: "07:36 నుండి 09:09 వరకు",
            Yoga: "సాధ్య 20:03 వరకు",
            Varjyam: "11:17 నుండి 13:05 వరకు",
            Moonrise: "06:52",
            Moonset: "19:18",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:50 నుండి 12:39 వరకు",
            Amrutakalam: "ఏమిలేదు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య",
            Date: "గురువారము, సెప్టెంబర్ 5, 2024",
            DurMuhurtamulu: "09:59 నుండి 10:48 వరకు",
            GulikaiKalam: "09:09 నుండి 10:42 వరకు",
            Karanamulu: "కౌలవ 12:21 వరకు, తైతిల 01:41, సెప్టెంబర్ 06 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "ఉత్తర 06:14 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:48 నుండి 15:20 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:03",
            Sunset: "18:26",
            Thiti: "విదియ 12:22 వరకు",
            Yamaganda: "06:03 నుండి 07:36 వరకు",
            Yoga: "శుభ 21:08 వరకు",
            Varjyam: "15:45 నుండి 17:34 వరకు",
            Moonrise: "07:38",
            Moonset: "19:49",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "5",
            LordImage: require("../images/holidays/8-0)TeachersDay.png"),
          },
          {
            Abhijit: "11:50 నుండి 12:39 వరకు",
            Amrutakalam: "05:20, సెప్టెంబర్ 07 నుండి 07:08, సెప్టెంబర్ 07 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య 23:00 వరకు",
            Date: "శుక్రవారము, సెప్టెంబర్ 6, 2024",
            DurMuhurtamulu: "08:21 నుండి 09:10 వరకు",
            GulikaiKalam: "07:36 నుండి 09:09 వరకు",
            Karanamulu: "గర 15:01 వరకు, వణిజ 04:20, సెప్టెంబర్ 07 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "హస్త 09:24 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:41 నుండి 12:14 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:03",
            Sunset: "18:26",
            Thiti: "తదియ 15:02 వరకు",
            Yamaganda: "15:20 నుండి 16:53 వరకు",
            Yoga: "శుక్ల 22:15 వరకు",
            Varjyam: "18:27 నుండి 20:16 వరకు",
            Moonrise: "08:24",
            Moonset: "12:01",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:39 వరకు",
            Amrutakalam: "05:38, సెప్టెంబర్ 08 నుండి 07:26, సెప్టెంబర్ 08 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల",
            Date: "శనివారము, సెప్టెంబర్ 7, 2024",
            DurMuhurtamulu: "05:54 నుండి 07:32 వరకు",
            GulikaiKalam: "06:03 నుండి 07:36 వరకు",
            Karanamulu: "విష్టి 17:37 వరకు, బవ నిండా రాత్రి వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "చిత్త 12:33 వరకు",
            MainNakshatra: "చిత్త",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:09 నుండి 10:41 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:03",
            Sunset: "18:25",
            Thiti: "చవితి 17:38 వరకు",
            Yamaganda: "13:47 నుండి 15:19 వరకు",
            Yoga: "బ్రహ్మ 23:17 వరకు",
            Varjyam: "18:51 నుండి 20:39 వరకు",
            Moonrise: "09:12",
            Moonset: "20:55",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "7",
            LordImage: require("../images/holidays/7-4)VinayakaChavithi.png"),
          },
          {
            Abhijit: "11:49 నుండి 12:38 వరకు",
            Amrutakalam: "ఏమిలేదు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల",
            Date: "ఆదివారము, సెప్టెంబర్ 8, 2024",
            DurMuhurtamulu: "16:29 నుండి 17:18 వరకు",
            GulikaiKalam: "15:19 నుండి 16:51 వరకు",
            Karanamulu: "బవ 06:50 వరకు, బాలవ 19:58 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "స్వాతి 15:30 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "16:51 నుండి 18:24 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:03",
            Sunset: "18:24",
            Thiti: "పంచమి 07:59 వరకు",
            Yamaganda: "12:14 నుండి 13:46 వరకు",
            Yoga: "ఐన్ద్ర 00:05, సెప్టెంబర్ 09 వరకు",
            Varjyam: "21:43 నుండి 23:29 వరకు",
            Moonrise: "10:01",
            Moonset: "21:32",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:38 వరకు",
            Amrutakalam: "08:20 నుండి 10:06 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల 11:28 వరకు",
            Date: "సోమవారము, సెప్టెంబర్ 9, 2024",
            DurMuhurtamulu: "12:24 నుండి 13:13 వరకు",
            GulikaiKalam: "13:46 నుండి 15:18 వరకు",
            Karanamulu: "కౌలవ 08:59 వరకు, తైతిల 21:53 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "విశాఖ 18:04 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:36 నుండి 09:08 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:03",
            Sunset: "18:23",
            Thiti: "షష్టి 21:54 వరకు",
            Yamaganda: "10:41 నుండి 12:13 వరకు",
            Yoga: "వైధృతి 00:33, సెప్టెంబర్ 10 వరకు",
            Varjyam: "22:24 నుండి 00:08 వరకు",
            Moonrise: "10:53",
            Moonset: "10:53",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:38 వరకు",
            Amrutakalam: "08:48 నుండి 10:32 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "మంగళవారము, సెప్టెంబర్ 10, 2024",
            DurMuhurtamulu: "08:20 నుండి 09:09 వరకు",
            GulikaiKalam: "12:13 నుండి 13:45 వరకు",
            Karanamulu: "గర 10:37 వరకు, వణిజ 23:11 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "అనూరాధ 20:03 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:18 నుండి 16:50 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:04",
            Sunset: "18:22",
            Thiti: "సప్తమి 23:12 వరకు",
            Yamaganda: "09:08 నుండి 10:41 వరకు",
            Yoga: "విష్కుమ్భ 00:31, సెప్టెంబర్ 11 వరకు",
            Varjyam: "01:57 నుండి 03:38 వరకు",
            Moonrise: "11:48",
            Moonset: "22:59",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "12:05 నుండి 13:46 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము 21:22 వరకు",
            Date: "బుధవారము, సెప్టెంబర్ 11, 2024",
            DurMuhurtamulu: "11:35 నుండి 12:24 వరకు",
            GulikaiKalam: "10:40 నుండి 12:13 వరకు",
            Karanamulu: "విష్టి 11:35 వరకు, బవ 23:46 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "జ్యేష్ఠ 21:21 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:13 నుండి 13:45 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:04",
            Sunset: "18:22",
            Thiti: "అష్టమి 11:47 వరకు",
            Yamaganda: "07:36 నుండి 09:08 వరకు",
            Yoga: "ప్రీతి 23:55 వరకు",
            Varjyam: "ఏమిలేదు",
            Moonrise: "12:44",
            Moonset: "23:52",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:37 వరకు",
            Amrutakalam: "15:20 నుండి 16:59 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "గురువారము, సెప్టెంబర్ 12, 2024",
            DurMuhurtamulu: "09:57 నుండి 10:46 వరకు",
            GulikaiKalam: "09:08 నుండి 10:40 వరకు",
            Karanamulu: "బాలవ 11:45 వరకు, కౌలవ 23:32 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "మూల 21:51 వరకు",
            MainNakshatra: "మూల",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:44 నుండి 15:16 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:04",
            Sunset: "18:21",
            Thiti: "నవమి 23:33 వరకు",
            Yamaganda: "06:04 నుండి 07:36 వరకు",
            Yoga: "ఆయుష్మాన్ 22:41 వరకు",
            Varjyam: "20:13 నుండి 21:51 వరకు",
            Moonrise: "13:42",
            Moonset: "00:50, సెప్టెంబర్ 13",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:36 వరకు",
            Amrutakalam: "16:51 నుండి 18:26 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు 03:24, సెప్టెంబర్ 14 వరకు",
            Date: "శుక్రవారము, సెప్టెంబర్ 13, 2024",
            DurMuhurtamulu: "08:20 నుండి 09:09 వరకు",
            GulikaiKalam: "07:36 నుండి 09:08 వరకు",
            Karanamulu: "తైతిల 11:07 వరకు, బవ 22:30 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "పూర్వాషాఢ 21:35 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:40 నుండి 12:12 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:04",
            Sunset: "18:20",
            Thiti: "దశమి 22:30 వరకు",
            Yamaganda: "15:16 నుండి 16:48 వరకు",
            Yoga: "సౌభాగ్య 20:48 వరకు",
            Varjyam: "07:21 నుండి 08:56 వరకు",
            Moonrise: "14:39",
            Moonset: "01:52, సెప్టెంబర్ 14",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:36 వరకు",
            Amrutakalam: "14:25 నుండి 15:57 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము",
            Date: "శనివారము, సెప్టెంబర్ 14, 2024",
            DurMuhurtamulu: "05:55 నుండి 07:32 వరకు",
            GulikaiKalam: "06:04 నుండి 07:36 వరకు",
            Karanamulu: "వణిజ 09:41 వరకు, విష్టి 20:41 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "ఉత్తరాషాఢ 20:32 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ, పుబ్బ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:08 నుండి 10:40 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:04",
            Sunset: "18:19",
            Thiti: "ఏకాదశి 20:42 వరకు",
            Yamaganda: "13:43 నుండి 15:15 వరకు",
            Yoga: "శోభన 18:18 వరకు",
            Varjyam: "06:45 వరకు",
            Moonrise: "15:32",
            Moonset: "02:55, సెప్టెంబర్ 15",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:36 వరకు",
            Amrutakalam: "09:10 నుండి 10:39 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము 05:44, సెప్టెంబర్ 16 వరకు",
            Date: "ఆదివారము, సెప్టెంబర్ 15, 2024",
            DurMuhurtamulu: "16:24 నుండి 17:12 వరకు",
            GulikaiKalam: "15:15 నుండి 16:46 వరకు",
            Karanamulu: "బవ 07:31 వరకు, బాలవ 18:12 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "శ్రవణం 18:48 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "16:46 నుండి 18:18 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:04",
            Sunset: "18:18",
            Thiti: "ద్వాదశి 18:12 వరకు",
            Yamaganda: "12:11 నుండి 13:43 వరకు",
            Yoga: "అతిగణ్డ 15:14 వరకు",
            Varjyam: "22:25 నుండి 23:52 వరకు",
            Moonrise: "16:22",
            Moonset: "03:59, సెప్టెంబర్ 16",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:46 నుండి 12:35 వరకు",
            Amrutakalam: "07:08 నుండి 08:35 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము",
            Date: "సోమవారము, సెప్టెంబర్ 16, 2024",
            DurMuhurtamulu: "12:22 నుండి 13:10 వరకు",
            GulikaiKalam: "13:42 నుండి 15:14 వరకు",
            Karanamulu: "తైతిల 15:10 వరకు, బవ 01:29, సెప్టెంబర్ 17 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "ధనిష్ఠ 16:32 వరకు",
            MainNakshatra: "ధనిష్ఠ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:36 నుండి 09:08 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:04",
            Sunset: "18:17",
            Thiti: "త్రయోదశి 15:10 వరకు",
            Yamaganda: "10:39 నుండి 12:11 వరకు",
            Yoga: "సుకర్మా 11:42 వరకు",
            Varjyam: "00:56 నుండి 02:21 వరకు",
            Moonrise: "17:08",
            Moonset: "05:02, సెప్టెంబర్ 17",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "16",
            LordImage: require("../images/holidays/Jamat_Ul_Vida.png"),
          },
          {
            Abhijit: "11:46 నుండి 12:35 వరకు",
            Amrutakalam:
              "07:29 నుండి 08:54 వరకు, 03:58, సెప్టెంబర్ 18 నుండి 05:22, సెప్టెంబర్ 18 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము 05:44, సెప్టెంబర్ 18 వరకు",
            Date: "మంగళవారము, సెప్టెంబర్ 17, 2024",
            DurMuhurtamulu: "08:20 నుండి 09:08 వరకు",
            GulikaiKalam: "12:10 నుండి 13:42 వరకు",
            Karanamulu: "వణిజ 11:44 వరకు, విష్టి 21:55 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "శతభిషం 13:53 వరకు",
            MainNakshatra: "శతభిషం",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:13 నుండి 16:45 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:04",
            Sunset: "18:16",
            Thiti: "చతుర్దశి 11:45 వరకు",
            Yamaganda: "09:07 నుండి 10:39 వరకు",
            Yoga: "ధృతి 07:48 వరకు",
            Varjyam: "19:31 నుండి 20:55 వరకు",
            Moonrise: "17:51",
            Moonset: "06:04, సెప్టెంబర్ 18",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "03:51, సెప్టెంబర్ 19 నుండి 05:15, సెప్టెంబర్ 19 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల",
            Date: "బుధవారము, సెప్టెంబర్ 18, 2024",
            DurMuhurtamulu: "11:33 నుండి 12:21 వరకు",
            GulikaiKalam: "10:39 నుండి 12:10 వరకు",
            Karanamulu: "బవ 08:04 వరకు, బాలవ 18:11 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "పూర్వాభాద్ర 11:00 వరకు",
            MainNakshatra: "పూర్వాభాద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:10 నుండి 13:41 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:05",
            Sunset: "18:16",
            Thiti: "పౌర్ణమి 08:04 వరకు, పాడ్యమి 04:20 వరకు",
            Yamaganda: "07:36 నుండి 09:07 వరకు",
            Yoga: "ఐన్ద్ర 04:24, సెప్టెంబర్ 19 వరకు",
            Varjyam: "19:24 నుండి 20:48 వరకు",
            Moonrise: "18:33",
            Moonset: "ఏమిలేదు",
            Shaka: "1946 క్రోధి",
            Amavasya: "pournami",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:45 నుండి 12:34 వరకు",
            Amrutakalam: "03:08, సెప్టెంబర్ 20 నుండి 04:32, సెప్టెంబర్ 20 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం 05:15, సెప్టెంబర్ 20 వరకు",
            Date: "గురువారము, సెప్టెంబర్ 19, 2024",
            DurMuhurtamulu: "09:56 నుండి 10:45 వరకు",
            GulikaiKalam: "09:07 నుండి 10:38 వరకు",
            Karanamulu: "తైతిల 14:28 వరకు, బవ 00:39, సెప్టెంబర్ 20 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "ఉత్తరాభాద్ర 08:03 వరకు, రేవతి 05:14 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర, రేవతి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:41 నుండి 15:12 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:05",
            Sunset: "18:15",
            Thiti: "విదియ 00:40 వరకు",
            Yamaganda: "06:05 నుండి 07:36 వరకు",
            Yoga: "వృద్ధి 19:19 వరకు",
            Varjyam: "18:39 నుండి 20:04 వరకు",
            Moonrise: "19:15",
            Moonset: "07:05",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:45 నుండి 12:34 వరకు",
            Amrutakalam: "20:16 నుండి 21:42 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం",
            Date: "శుక్రవారము, సెప్టెంబర్ 20, 2024",
            DurMuhurtamulu: "08:19 నుండి 09:07 వరకు",
            GulikaiKalam: "07:36 నుండి 09:07 వరకు",
            Karanamulu: "వణిజ 10:55 వరకు, విష్టి 21:15 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "అశ్విని 02:42 వరకు",
            MainNakshatra: "అశ్విని",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:38 నుండి 12:09 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:05",
            Sunset: "18:14",
            Thiti: "తదియ 21:15 వరకు",
            Yamaganda: "15:12 నుండి 16:43 వరకు",
            Yoga: "ధ్రువ 15:19 వరకు",
            Varjyam: "23:08 నుండి 00:34 వరకు",
            Moonrise: "19:58",
            Moonset: "08:07",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:45 నుండి 12:33 వరకు",
            Amrutakalam: "ఏమిలేదు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం",
            Date: "శనివారము, సెప్టెంబర్ 21, 2024",
            DurMuhurtamulu: "05:55 నుండి 07:31 వరకు",
            GulikaiKalam: "06:05 నుండి 07:36 వరకు",
            Karanamulu: "బవ 07:40 వరకు, బాలవ 18:13 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "భరణి 00:35 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:07 నుండి 10:38 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:05",
            Sunset: "18:13",
            Thiti: "చవితి 18:14 వరకు",
            Yamaganda: "13:40 నుండి 15:11 వరకు",
            Yoga: "వ్యాఘాత 11:36 వరకు",
            Varjyam: "11:27 నుండి 12:55 వరకు",
            Moonrise: "20:45",
            Moonset: "09:10",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:44 నుండి 12:33 వరకు",
            Amrutakalam: "20:48 నుండి 22:17 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం 06:09 వరకు",
            Date: "ఆదివారము, సెప్టెంబర్ 22, 2024",
            DurMuhurtamulu: "16:20 నుండి 17:08 వరకు",
            GulikaiKalam: "07:36 నుండి 09:07 వరకు",
            Karanamulu: "వణిజ 13:35 వరకు, విష్టి 01:01, సెప్టెంబర్ 23 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "కృత్తిక 23:02 వరకు",
            MainNakshatra: "కృత్తిక",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:41 నుండి 18:12 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:05",
            Sunset: "18:12",
            Thiti: "పంచమి 15:44 వరకు",
            Yamaganda: "12:09 నుండి 13:40 వరకు",
            Yoga: "హర్షణ 08:18 వరకు",
            Varjyam: "11:49 నుండి 13:19 వరకు",
            Moonrise: "21:36",
            Moonset: "10:14",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:44 నుండి 12:32 వరకు",
            Amrutakalam: "19:02 నుండి 20:35 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "సోమవారము, సెప్టెంబర్ 23, 2024",
            DurMuhurtamulu: "12:19 నుండి 13:07 వరకు",
            GulikaiKalam: "13:39 నుండి 15:10 వరకు",
            Karanamulu: "వణిజ 13:50 వరకు, విష్టి 01:09, సెప్టెంబర్ 24 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "రోహిణి 22:07 వరకు",
            MainNakshatra: "రోహిణి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "07:36 నుండి 09:07 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:05",
            Sunset: "18:12",
            Thiti: "షష్టి 13:51 వరకు",
            Yamaganda: "10:38 నుండి 12:08 వరకు",
            Yoga: "సిద్ధి 03:10, సెప్టెంబర్ 24 వరకు",
            Varjyam: "14:25 నుండి 15:57 వరకు",
            Moonrise: "22:31",
            Moonset: "11:19",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:44 నుండి 12:32 వరకు",
            Amrutakalam: "13:11 నుండి 14:46 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృషభం 09:55 వరకు",
            Date: "మంగళవారము, సెప్టెంబర్ 24, 2024",
            DurMuhurtamulu: "08:20 నుండి 09:08 వరకు",
            GulikaiKalam: "12:08 నుండి 13:39 వరకు",
            Karanamulu: "బవ 12:38 వరకు, బాలవ 00:19, సెప్టెంబర్ 25 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "మృగశిర 21:53 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:09 నుండి 16:40 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:05",
            Sunset: "18:11",
            Thiti: "సప్తమి 12:39 వరకు",
            Yamaganda: "09:07 నుండి 10:37 వరకు",
            Yoga: "వ్యతీపాత 01:27, సెప్టెంబర్ 25 వరకు",
            Varjyam: "ఏమిలేదు",
            Moonrise: "23:28",
            Moonset: "12:21",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "12:11 నుండి 13:49 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం",
            Date: "బుధవారము, సెప్టెంబర్ 25, 2024",
            DurMuhurtamulu: "11:30 నుండి 12:28 వరకు",
            GulikaiKalam: "10:37 నుండి 12:08 వరకు",
            Karanamulu: "కౌలవ 12:10 వరకు, తైతిల 00:12, సెప్టెంబర్ 26 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "ఆరుద్ర 22:23 వరకు",
            MainNakshatra: "ఆరుద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:08 నుండి 13:38 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:06",
            Sunset: "18:10",
            Thiti: "అష్టమి 12:11 వరకు",
            Yamaganda: "07:36 నుండి 09:07 వరకు",
            Yoga: "వారీయన 00:18, సెప్టెంబర్ 26 వరకు",
            Varjyam: "06:28 నుండి 08:06 వరకు",
            Moonrise: "00:27, సెప్టెంబర్ 26",
            Moonset: "13:19",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:43 నుండి 12:31 వరకు",
            Amrutakalam: "21:03 నుండి 22:43 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం 17:13 వరకు",
            Date: "గురువారము, సెప్టెంబర్ 26, 2024",
            DurMuhurtamulu: "09:55 నుండి 10:44 వరకు, 14:56 నుండి 15:44 వరకు",
            GulikaiKalam: "09:06 నుండి 10:37 వరకు",
            Karanamulu: "బవ 12:25 వరకు, వణిజ 00:48, సెప్టెంబర్ 27 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "పునర్వసు 23:33 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:38 నుండి 15:08 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:06",
            Sunset: "18:09",
            Thiti: "నవమి 12:26 వరకు",
            Yamaganda: "06:06 నుండి 07:36 వరకు",
            Yoga: "పరిఘ 23:41 వరకు",
            Varjyam: "10:58 నుండి 12:39 వరకు",
            Moonrise: "01:25, సెప్టెంబర్ 27",
            Moonset: "14:11",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:43 నుండి 12:31 వరకు",
            Amrutakalam: "18:28 నుండి 20:11 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "శుక్రవారము, సెప్టెంబర్ 27, 2024",
            DurMuhurtamulu: "08:19 నుండి 09:07 వరకు",
            GulikaiKalam: "07:36 నుండి 09:06 వరకు",
            Karanamulu: "విష్టి 13:20 వరకు, బవ 02:01, సెప్టెంబర్ 28 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "పుష్యమి 01:20 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:37 నుండి 12:07 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:06",
            Sunset: "18:08",
            Thiti: "దశమి 13:21 వరకు",
            Yamaganda: "15:07 నుండి 16:38 వరకు",
            Yoga: "శివ 23:34 వరకు",
            Varjyam: "08:09 నుండి 09:52 వరకు",
            Moonrise: "02:20, సెప్టెంబర్ 28",
            Moonset: "14:57",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:43 నుండి 12:31 వరకు",
            Amrutakalam: "01:53, సెప్టెంబర్ 29 నుండి 03:38, సెప్టెంబర్ 29 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం 03:38, సెప్టెంబర్ 29 వరకు",
            Date: "శనివారము, సెప్టెంబర్ 28, 2024",
            DurMuhurtamulu: "05:56 నుండి 07:31 వరకు",
            GulikaiKalam: "06:06 నుండి 07:36 వరకు",
            Karanamulu: "బాలవ 14:49 వరకు, కౌలవ 03:45, సెప్టెంబర్ 29 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "ఆశ్లేష 03:37 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:06 నుండి 10:36 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:06",
            Sunset: "18:07",
            Thiti: "ఏకాదశి 14:50 వరకు",
            Yamaganda: "13:37 నుండి 15:07 వరకు",
            Yoga: "సిద్ధ 23:51 వరకు",
            Varjyam: "15:21 నుండి 17:06 వరకు",
            Moonrise: "03:12, సెప్టెంబర్ 29",
            Moonset: "15:38",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:42 నుండి 12:30 వరకు",
            Amrutakalam: "03:39, సెప్టెంబర్ 30 నుండి 05:25, సెప్టెంబర్ 30 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం",
            Date: "ఆదివారము, సెప్టెంబర్ 29, 2024",
            DurMuhurtamulu: "16:15 నుండి 17:03 వరకు",
            GulikaiKalam: "15:06 నుండి 16:36 వరకు",
            Karanamulu: "తైతిల 16:47 వరకు, బవ 05:54, సెప్టెంబర్ 30 వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "మఖ పూర్తి",
            MainNakshatra: "మఖ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:36 నుండి 18:06 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:06",
            Sunset: "18:06",
            Thiti: "ద్వాదశి 16:48 వరకు",
            Yamaganda: "12:06 నుండి 13:36 వరకు",
            Yoga: "సాధ్య 00:28, సెప్టెంబర్ 30 వరకు",
            Varjyam: "16:58 నుండి 18:45 వరకు",
            Moonrise: "04:01, సెప్టెంబర్ 30",
            Moonset: "16:15",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:42 నుండి 12:30 వరకు",
            Amrutakalam: "02:05, అక్టోబర్ 01 నుండి 03:52, అక్టోబర్ 01 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం",
            Date: "సోమవారము, సెప్టెంబర్ 30, 2024",
            DurMuhurtamulu: "12:17 నుండి 13:05 వరకు, 15:17 నుండి 17:05 వరకు",
            GulikaiKalam: "13:36 నుండి 15:06 వరకు",
            Karanamulu: "వణిజ 19:06 వరకు, విష్టి నిండా రాత్రి వరకు",
            Masam: "భాద్రపద మాసం",
            Nakshatra1: "మఖ 06:18 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "07:36 నుండి 09:06 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:06",
            Sunset: "18:06",
            Thiti: "త్రయోదశి 19:07 వరకు",
            Yamaganda: "10:36 నుండి 12:06 వరకు",
            Yoga: "శుభ 01:18, అక్టోబర్ 01 వరకు",
            Varjyam: "15:17 నుండి 17:05 వరకు",
            Moonrise: "04:49, అక్టోబర్ 01",
            Moonset: "16:48",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
        ],
      },
      {
        day: [
          {
            Abhijit: "11:42 నుండి 12:30 వరకు",
            Amrutakalam: "04:15, అక్టోబర్ 02 నుండి 06:03, అక్టోబర్ 02 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం 16:02 వరకు",
            Date: "మంగళవారము, అక్టోబర్ 1, 2024",
            DurMuhurtamulu: "08:19 నుండి 09:06 వరకు",
            GulikaiKalam: "12:06 నుండి 13:35 వరకు",
            Karanamulu: "విష్టి 08:21 వరకు, శకుని 21:39 వరకు",
            Masam: "బాధ్రపద మాసం",
            Nakshatra1: "పుబ్బ 09:15 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:05 నుండి 16:35 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:06",
            Sunset: " 18:05",
            Thiti: "చతుర్దశి 21:40 వరకు",
            Yamaganda: "09:06 నుండి 10:36 వరకు",
            Yoga: "శుక్ల 02:18, అక్టోబర్ 02 వరకు",
            Varjyam: "17:23 నుండి 19:11 వరకు",
            Moonrise: "05:35, అక్టోబర్ 02",
            Moonset: "17:20",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "04:15, అక్టోబర్ 02 నుండి 06:03, అక్టోబర్ 02 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య",
            Date: "బుధవారము, అక్టోబర్ 2, 2024",
            DurMuhurtamulu: "11:29 నుండి 12:16 వరకు",
            GulikaiKalam: "10:36 నుండి 12:05 వరకు",
            Karanamulu: "చతుష్పాద 10:58 వరకు, నాగ 00:18, అక్టోబర్ 03 వరకు",
            Masam: "బాధ్రపద మాసం",
            Nakshatra1: "ఉత్తర 12:22 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:05 నుండి 13:35 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:07",
            Sunset: "18:04",
            Thiti: "అమావాస్య 00:19 వరకు",
            Yamaganda: "07:36 నుండి 09:06 వరకు",
            Yoga: "బ్రహ్మ 03:22, అక్టోబర్ 03 వరకు",
            Varjyam: "21:53 నుండి 23:42 వరకు",
            Moonrise: "ఏమిలేదు",
            Moonset: "17:51",
            Shaka: "1946 క్రోధి",
            Amavasya: "amavasya",
            FestDate: "2",
            LordImage: require("../images/holidays/9-0)Gandhijayanthi.png"),
          },
          {
            Abhijit: "11:41 నుండి 12:29 వరకు",
            Amrutakalam: "08:45 నుండి 10:33 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య 05:06, అక్టోబర్ 04 వరకు",
            Date: "గురువారము, అక్టోబర్ 3, 2024",
            DurMuhurtamulu: "09:54 నుండి 10:41 వరకు",
            GulikaiKalam: "09:06 నుండి 10:35 వరకు",
            Karanamulu: "కింస్తుఘ్న 13:38 వరకు, బవ 02:58, అక్టోబర్ 04 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "హస్త 15:32 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:34 నుండి 15:04 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:07",
            Sunset: "18:03",
            Thiti: "పాడ్యమి 02:59 వరకు",
            Yamaganda: "06:07 నుండి 07:36 వరకు",
            Yoga: "ఐన్ద్ర 04:24, అక్టోబర్ 04 వరకు",
            Varjyam: "00:34 నుండి 02:22 వరకు",
            Moonrise: "06:21",
            Moonset: "18:23",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:41 నుండి 12:28 వరకు",
            Amrutakalam: "11:24 నుండి 13:13 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల",
            Date: "శుక్రవారము, అక్టోబర్ 4, 2024",
            DurMuhurtamulu: "08:19 నుండి 09:06 వరకు",
            GulikaiKalam: "07:36 నుండి 09:06 వరకు",
            Karanamulu: "బాలవ 16:15 వరకు, కౌలవ 05:30, అక్టోబర్ 05 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "చిత్త 18:37 వరకు",
            MainNakshatra: "చిత్త",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:35 నుండి 12:05 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:07",
            Sunset: "18:02",
            Thiti: "విదియ 05:31 వరకు",
            Yamaganda: "15:03 నుండి 16:33 వరకు",
            Yoga: "వైధృతి 05:22, అక్టోబర్ 05 వరకు",
            Varjyam: "00:55 నుండి 02:43 వరకు",
            Moonrise: "07:09",
            Moonset: "18:56",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:41 నుండి 12:28 వరకు",
            Amrutakalam: "11:41 నుండి 13:29 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల",
            Date: "శనివారము, అక్టోబర్ 5, 2024",
            DurMuhurtamulu: "05:57 నుండి 07:31 వరకు",
            GulikaiKalam: "06:07 నుండి 07:36 వరకు",
            Karanamulu: "తైతిల 18:42 వరకు, గర నిండా రాత్రి వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "స్వాతి 21:32 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:06 నుండి 10:35 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:07",
            Sunset: "18:02",
            Thiti: "తదియ పూర్తి",
            Yamaganda: "13:34 నుండి 15:03 వరకు",
            Yoga: "విష్కుమ్భ నిండా రాత్రి వరకు",
            Varjyam: "03:45 నుండి 05:32 వరకు",
            Moonrise: "07:57",
            Moonset: "19:32",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:40 నుండి 12:28 వరకు",
            Amrutakalam: "14:25 నుండి 16:12 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల 17:34 వరకు",
            Date: "ఆదివారము, అక్టోబర్ 6, 2024",
            DurMuhurtamulu: "16:10 నుండి 16:57 వరకు",
            GulikaiKalam: "15:02 నుండి 16:32 వరకు",
            Karanamulu: "గర 07:49 వరకు, వణిజ 20:51 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "విశాఖ 00:10 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "16:32 నుండి 18:01 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:07",
            Sunset: "18:01",
            Thiti: "తదియ 07:50 వరకు",
            Yamaganda: "12:04 నుండి 13:33 వరకు",
            Yoga: "విష్కుమ్భ 06:09 వరకు",
            Varjyam: "02:20 నుండి 04:03 వరకు",
            Moonrise: "08:48",
            Moonset: "20:11",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:40 నుండి 12:27 వరకు",
            Amrutakalam: "15:03 నుండి 16:48 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "సోమవారము, అక్టోబర్ 7, 2024",
            DurMuhurtamulu: "12:14 నుండి 13:01 వరకు",
            GulikaiKalam: "13:33 నుండి 15:02 వరకు",
            Karanamulu: "విష్టి 09:47 వరకు, బవ 22:36 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "అనూరాధ 02:24 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:37 నుండి 09:06 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:07",
            Sunset: "18:00",
            Thiti: "చవితి 09:48 వరకు",
            Yamaganda: "10:35 నుండి 12:04 వరకు",
            Yoga: "ప్రీతి 06:40 వరకు",
            Varjyam: "శే.వ. 06:17 వరకు",
            Moonrise: "09:42",
            Moonset: "20:56",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:40 నుండి 12:27 వరకు",
            Amrutakalam: "18:42 నుండి 20:25 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము 04:08, అక్టోబర్ 09 వరకు",
            Date: "మంగళవారము, అక్టోబర్ 8, 2024",
            DurMuhurtamulu: "08:19 నుండి 09:06 వరకు",
            GulikaiKalam: "12:03 నుండి 13:32 వరకు",
            Karanamulu: "బాలవ 11:17 వరకు, కౌలవ 23:50 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "జ్యేష్ఠ 04:08 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:01 నుండి 16:30 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:08",
            Sunset: "17:59",
            Thiti: "పంచమి 11:18 వరకు",
            Yamaganda: "09:06 నుండి 10:35 వరకు",
            Yoga: "ఆయుష్మాన్ 06:51 వరకు",
            Varjyam: "08:25 నుండి 10:08 వరకు",
            Moonrise: "10:37",
            Moonset: "21:45",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "22:33 నుండి 00:14, అక్టోబర్ 10 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "బుధవారము, అక్టోబర్ 9, 2024",
            DurMuhurtamulu: "11:26 నుండి 12:13 వరకు",
            GulikaiKalam: "10:34 నుండి 12:03 వరకు",
            Karanamulu: "తైతిల 12:14 వరకు, గర 00:28, అక్టోబర్ 10 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "మూల 05:14 వరకు",
            MainNakshatra: "మూల",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:03 నుండి 13:32 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:08",
            Sunset: "17:58",
            Thiti: "షష్టి 12:15 వరకు",
            Yamaganda: "07:37 నుండి 09:06 వరకు",
            Yoga: "సౌభాగ్య 06:37 వరకు",
            Varjyam: "03:34 నుండి 05:14 వరకు",
            Moonrise: "11:34",
            Moonset: "22:40",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:39 నుండి 12:27 వరకు",
            Amrutakalam: "00:48, అక్టోబర్ 11 నుండి 02:26, అక్టోబర్ 11 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "గురువారము, అక్టోబర్ 10, 2024",
            DurMuhurtamulu: "09:52 నుండి 10:39 వరకు",
            GulikaiKalam: "09:06 నుండి 10:34 వరకు",
            Karanamulu: "వణిజ 12:31 వరకు, విష్టి 00:24, అక్టోబర్ 11 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "పూర్వాషాఢ 05:41 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:32 నుండి 15:00 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:08",
            Sunset: "17:58",
            Thiti: "సప్తమి 12:32 వరకు",
            Yamaganda: "06:08 నుండి 07:37 వరకు",
            Yoga: "అతిగణ్డ 04:37, అక్టోబర్ 11 వరకు",
            Varjyam: "15:01 నుండి 16:39 వరకు",
            Moonrise: "12:29",
            Moonset: "23:38",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:39 నుండి 12:26 వరకు",
            Amrutakalam: "23:05 నుండి 00:40, అక్టోబర్ 12 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు 11:41 వరకు",
            Date: "శుక్రవారము, అక్టోబర్ 11, 2024",
            DurMuhurtamulu: "08:19 నుండి 09:06 వరకు",
            GulikaiKalam: "07:37 నుండి 09:05 వరకు",
            Karanamulu: "బవ 12:06 వరకు, బాలవ 23:37 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "ఉత్తరాషాఢ 05:25 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:34 నుండి 12:03 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:08",
            Sunset: "17:57",
            Thiti: "అష్టమి 12:07 వరకు",
            Yamaganda: "15:00 నుండి 16:28 వరకు",
            Yoga: "సుకర్మా 02:47, అక్టోబర్ 12 వరకు",
            Varjyam: "13:36 నుండి 15:11 వరకు",
            Moonrise: "13:22",
            Moonset: "00:40, అక్టోబర్ 12",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "11",
            LordImage: require("../images/holidays/9-1)Durgashtami.png"),
          },
          {
            Abhijit: "11:39 నుండి 12:26 వరకు",
            Amrutakalam: "18:28 నుండి 20:01 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము",
            Date: "శనివారము, అక్టోబర్ 12, 2024",
            DurMuhurtamulu: "05:59 నుండి 07:32 వరకు",
            GulikaiKalam: "06:09 నుండి 07:37 వరకు",
            Karanamulu: "కౌలవ 10:58 వరకు, తైతిల 22:08 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "శ్రవణం 04:27 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:05 నుండి 10:34 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:09",
            Sunset: "17:56",
            Thiti: "నవమి 10:59 వరకు",
            Yamaganda: "13:31 నుండి 14:59 వరకు",
            Yoga: "ధృతి 00:22, అక్టోబర్ 13 వరకు",
            Varjyam: "09:15 నుండి 10:47 వరకు",
            Moonrise: "14:12",
            Moonset: "01:41, అక్టోబర్ 13",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "12",
            LordImage: require("../images/holidays/9-2)Dasara.png"),
          },
          {
            Abhijit: "11:39 నుండి 12:26 వరకు",
            Amrutakalam: "17:09 నుండి 18:39 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము 15:44 వరకు",
            Date: "ఆదివారము, అక్టోబర్ 13, 2024",
            DurMuhurtamulu: "16:06 నుండి 16:53 వరకు",
            GulikaiKalam: "14:59 నుండి 16:27 వరకు",
            Karanamulu: "బవ 09:08 వరకు, వణిజ 19:59 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "ధనిష్ఠ 02:51 వరకు",
            MainNakshatra: "ధనిష్ఠ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "16:27 నుండి 17:56 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:09",
            Sunset: "17:56",
            Thiti: "దశమి 09:09 వరకు",
            Yamaganda: "12:02 నుండి 13:31 వరకు",
            Yoga: "శూల 21:26 వరకు",
            Varjyam: "08:11 నుండి 09:41 వరకు",
            Moonrise: "14:58",
            Moonset: "02:42, అక్టోబర్ 14",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:38 నుండి 12:25 వరకు",
            Amrutakalam: "18:09 నుండి 19:37 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము",
            Date: "సోమవారము, అక్టోబర్ 14, 2024",
            DurMuhurtamulu: "12:12 నుండి 12:59 వరకు",
            GulikaiKalam: "13:30 నుండి 14:58 వరకు",
            Karanamulu: "విష్టి 06:41 వరకు, బవ 17:15 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "శతభిషం 00:42 వరకు",
            MainNakshatra: "శతభిషం",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:37 నుండి 09:05 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:09",
            Sunset: "17:55",
            Thiti: "ఏకాదశి 06:42 వరకు, ద్వాదశి 03:43 వరకు",
            Yamaganda: "10:34 నుండి 12:02 వరకు",
            Yoga: "గణ్డ 18:01 వరకు",
            Varjyam: "09:24 నుండి 10:51 వరకు",
            Moonrise: "15:41",
            Moonset: "03:43, అక్టోబర్ 15",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:38 నుండి 12:25 వరకు",
            Amrutakalam: "15:00 నుండి 16:26 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము 16:49 వరకు",
            Date: "మంగళవారము, అక్టోబర్ 15, 2024",
            DurMuhurtamulu: "08:19 నుండి 09:06 వరకు",
            GulikaiKalam: "12:02 నుండి 13:30 వరకు",
            Karanamulu: "కౌలవ 14:03 వరకు, తైతిల 00:19, అక్టోబర్ 16 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "పూర్వాభాద్ర 22:08 వరకు",
            MainNakshatra: "పూర్వాభాద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "14:58 నుండి 16:26 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:09",
            Sunset: "17:54",
            Thiti: "త్రయోదశి 00:19 వరకు",
            Yamaganda: "09:05 నుండి 10:34 వరకు",
            Yoga: "వృద్ధి 14:14 వరకు",
            Varjyam: "06:25 నుండి 07:51 వరకు",
            Moonrise: "16:22",
            Moonset: "04:43, అక్టోబర్ 16",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "15:04 నుండి 16:28 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం",
            Date: "బుధవారము, అక్టోబర్ 16, 2024",
            DurMuhurtamulu: "11:25 నుండి 12:11 వరకు",
            GulikaiKalam: "10:33 నుండి 12:01 వరకు",
            Karanamulu: "బవ 10:31 వరకు, వణిజ 20:40 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "ఉత్తరాభాద్ర 19:17 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:01 నుండి 13:29 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:09",
            Sunset: "17:54",
            Thiti: "చతుర్దశి 20:41 వరకు",
            Yamaganda: "07:37 నుండి 09:05 వరకు",
            Yoga: "ధ్రువ 10:10 వరకు",
            Varjyam: "06:35 నుండి 08:00 వరకు",
            Moonrise: "17:04",
            Moonset: "05:45, అక్టోబర్ 17",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:38 నుండి 12:25 వరకు",
            Amrutakalam: "14:14 నుండి 15:38 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం 16:20 వరకు",
            Date: "గురువారము, అక్టోబర్ 17, 2024",
            DurMuhurtamulu: "09:52 నుండి 10:38 వరకు",
            GulikaiKalam: "09:06 నుండి 10:33 వరకు",
            Karanamulu: "విష్టి 06:48 వరకు, బవ 16:55 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "రేవతి 16:20 వరకు",
            MainNakshatra: "రేవతి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:29 నుండి 14:57 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:10",
            Sunset: "17:53",
            Thiti: "పౌర్ణమి 16:56 వరకు",
            Yamaganda: "06:10 నుండి 07:38 వరకు",
            Yoga: "హర్షణ 01:42, అక్టోబర్ 18 వరకు",
            Varjyam: "07:12 వరకు",
            Moonrise: "17:47",
            Moonset: "చంద్రాస్తమయం కాదు",
            Shaka: "1946 క్రోధి",
            Amavasya: "pournami",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:38 నుండి 12:25 వరకు",
            Amrutakalam: "07:06 నుండి 08:31 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం",
            Date: "శుక్రవారము, అక్టోబర్ 18, 2024",
            DurMuhurtamulu: "08:19 నుండి 09:06 వరకు",
            GulikaiKalam: "07:38 నుండి 09:06 వరకు",
            Karanamulu: "కౌలవ 13:15 వరకు, తైతిల 23:29 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "అశ్విని 13:26 వరకు",
            MainNakshatra: "అశ్విని",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:33 నుండి 12:01 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:10",
            Sunset: "17:52",
            Thiti: "పాడ్యమి 13:16 వరకు",
            Yamaganda: "14:57 నుండి 16:24 వరకు",
            Yoga: "వజ్ర 21:34 వరకు",
            Varjyam: "09:55 నుండి 11:19 వరకు",
            Moonrise: "18:32",
            Moonset: "06:48",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:38 నుండి 12:24 వరకు",
            Amrutakalam: "06:30 నుండి 07:56 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం 16:10 వరకు",
            Date: "శనివారము, అక్టోబర్ 19, 2024",
            DurMuhurtamulu: "06:01 నుండి 07:33 వరకు",
            GulikaiKalam: "06:10 నుండి 07:38 వరకు",
            Karanamulu: "బవ 09:48 వరకు, వణిజ 20:13 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "భరణి 10:46 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:06 నుండి 10:33 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:10",
            Sunset: "17:52",
            Thiti: "విదియ 09:49 వరకు",
            Yamaganda: "13:29 నుండి 14:56 వరకు",
            Yoga: "సిద్ధి 17:42 వరకు",
            Varjyam: "21:39 నుండి 23:06 వరకు",
            Moonrise: "19:23",
            Moonset: "07:54",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:24 వరకు",
            Amrutakalam:
              "06:21 నుండి 07:48 వరకు, 03:52, అక్టోబర్ 21 నుండి 05:21, అక్టోబర్ 21 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృషభం",
            Date: "ఆదివారము, అక్టోబర్ 20, 2024",
            DurMuhurtamulu: "16:02 నుండి 16:48 వరకు",
            GulikaiKalam: "14:56 నుండి 16:23 వరకు",
            Karanamulu: "విష్టి 06:46 వరకు, బవ 17:26 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "కృత్తిక 08:31 వరకు",
            MainNakshatra: "కృత్తిక",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:23 నుండి 17:51 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:11",
            Sunset: "17:51",
            Thiti: "తదియ 06:47 వరకు, చవితి 04:17 వరకు",
            Yamaganda: "12:01 నుండి 13:28 వరకు",
            Yoga: "వ్యతీపాత 14:12 వరకు",
            Varjyam: "23:24 నుండి 00:53 వరకు",
            Moonrise: "20:18",
            Moonset: "09:01",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:24 వరకు",
            Amrutakalam: "21:25 నుండి 22:57 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృషభం 18:15 వరకు",
            Date: "సోమవారము, అక్టోబర్ 21, 2024",
            DurMuhurtamulu: "12:10 నుండి 12:56 వరకు",
            GulikaiKalam: "13:28 నుండి 14:55 వరకు",
            Karanamulu: "కౌలవ 15:17 వరకు, తైతిల 02:29, అక్టోబర్ 22 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "రోహిణి 06:50 వరకు, మృగశిర 05:50 వరకు",
            MainNakshatra: "రోహిణి, మృగశిర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "07:38 నుండి 09:06 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:11",
            Sunset: "17:50",
            Thiti: "పంచమి 02:30 వరకు",
            Yamaganda: "10:33 నుండి 12:01 వరకు",
            Yoga: "వారీయన 11:11 వరకు",
            Varjyam: "12:12 నుండి 13:44 వరకు",
            Moonrise: "21:16",
            Moonset: "10:06",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:24 వరకు",
            Amrutakalam: "19:44 నుండి 21:19 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం",
            Date: "మంగళవారము, అక్టోబర్ 22, 2024",
            DurMuhurtamulu: "08:20 నుండి 09:06 వరకు",
            GulikaiKalam: "12:00 నుండి 13:28 వరకు",
            Karanamulu: "బవ 13:52 వరకు, వణిజ 01:28, అక్టోబర్ 23 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "ఆరుద్ర 05:38 వరకు",
            MainNakshatra: "ఆరుద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "14:55 నుండి 16:22 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:11",
            Sunset: "17:50",
            Thiti: "షష్టి 01:29 వరకు",
            Yamaganda: "09:06 నుండి 10:33 వరకు",
            Yoga: "పరిఘ 08:46 వరకు",
            Varjyam: "14:10 నుండి 15:45 వరకు",
            Moonrise: "22:17",
            Moonset: "11:09",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "03:48, అక్టోబర్ 24 నుండి 05:26, అక్టోబర్ 24 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం 00:02, అక్టోబర్ 24 వరకు",
            Date: "బుధవారము, అక్టోబర్ 23, 2024",
            DurMuhurtamulu: "11:24 నుండి 12:10 వరకు",
            GulikaiKalam: "10:33 నుండి 12:00 వరకు",
            Karanamulu: "విష్టి 13:17 వరకు, బవ 01:18, అక్టోబర్ 24 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "పునర్వసు పూర్తి",
            MainNakshatra: "పునర్వసు",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:00 నుండి 13:27 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:11",
            Sunset: "17:49",
            Thiti: "సప్తమి 01:19 వరకు",
            Yamaganda: "07:39 నుండి 09:06 వరకు",
            Yoga: "శివ 06:59 వరకు",
            Varjyam: "17:57 నుండి 19:35 వరకు",
            Moonrise: "23:17",
            Moonset: "12:05",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "00:53, అక్టోబర్ 25 నుండి 02:35, అక్టోబర్ 25 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "గురువారము, అక్టోబర్ 24, 2024",
            DurMuhurtamulu: "09:52 నుండి 10:38 వరకు",
            GulikaiKalam: "09:06 నుండి 10:33 వరకు",
            Karanamulu: "బాలవ 13:32 వరకు, కౌలవ 01:58, అక్టోబర్ 25 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "పునర్వసు 06:15 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:27 నుండి 14:54 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:12",
            Sunset: "17:49",
            Thiti: "అష్టమి 01:59 వరకు",
            Yamaganda: "06:12 నుండి 07:39 వరకు",
            Yoga: "సాధ్య 05:23, అక్టోబర్ 25 వరకు",
            Varjyam: "14:43 నుండి 16:25 వరకు",
            Moonrise: "00:14, అక్టోబర్ 25",
            Moonset: "12:54",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "ఏమిలేదు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "శుక్రవారము, అక్టోబర్ 25, 2024",
            DurMuhurtamulu: "08:20 నుండి 09:06 వరకు",
            GulikaiKalam: "07:39 నుండి 09:06 వరకు",
            Karanamulu: "తైతిల 14:35 వరకు, బవ 03:22, అక్టోబర్ 26 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "పుష్యమి 07:39 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:33 నుండి 12:00 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:12",
            Sunset: "17:48",
            Thiti: "నవమి 03:23 వరకు",
            Yamaganda: "14:54 నుండి 16:21 వరకు",
            Yoga: "శుభ 05:27, అక్టోబర్ 26 వరకు",
            Varjyam: "21:34 నుండి 23:18 వరకు",
            Moonrise: "01:07, అక్టోబర్ 26",
            Moonset: "13:37",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "08:01 నుండి 09:46 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం 09:46 వరకు",
            Date: "శనివారము, అక్టోబర్ 26, 2024",
            DurMuhurtamulu: "06:03 నుండి 07:35 వరకు",
            GulikaiKalam: "06:12 నుండి 07:39 వరకు",
            Karanamulu: "వణిజ 16:19 వరకు, విష్టి 05:23, అక్టోబర్ 27 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "ఆశ్లేష 09:45 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:06 నుండి 10:33 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:12",
            Sunset: "17:47",
            Thiti: "దశమి 05:24 వరకు",
            Yamaganda: "13:27 నుండి 14:54 వరకు",
            Yoga: "శుక్ల 05:58, అక్టోబర్ 27 వరకు",
            Varjyam: "23:04 నుండి 00:51",
            Moonrise: "01:58, అక్టోబర్ 27",
            Moonset: "14:15",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "09:44 నుండి 11:31 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం",
            Date: "ఆదివారము, అక్టోబర్ 27, 2024",
            DurMuhurtamulu: "15:59 నుండి 16:45 వరకు",
            GulikaiKalam: "14:53 నుండి 16:20 వరకు",
            Karanamulu: "బవ 18:34 వరకు, బాలవ నిండా రాత్రి వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "మఖ 12:23 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:20 నుండి 17:47 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:13",
            Sunset: "17:47",
            Thiti: "ఏకాదశి పూర్తి",
            Yamaganda: "12:00 నుండి 13:27 వరకు",
            Yoga: "బ్రహ్మ నిండా రాత్రి వరకు",
            Varjyam: "21:23 నుండి 23:11 వరకు",
            Moonrise: "02:46, అక్టోబర్ 28",
            Moonset: "14:50",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "08:12 నుండి 10:00 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం 22:11 వరకు",
            Date: "సోమవారము, అక్టోబర్ 28, 2024",
            DurMuhurtamulu: "12:09 నుండి 12:55 వరకు",
            GulikaiKalam: "13:26 నుండి 14:53 వరకు",
            Karanamulu: "బాలవ 07:50 వరకు, కౌలవ 21:10 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "పుబ్బ 15:23 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "07:40 నుండి 09:06 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:13",
            Sunset: "17:46",
            Thiti: "ఏకాదశి 07:51 వరకు",
            Yamaganda: "10:33 నుండి 12:00 వరకు",
            Yoga: "బ్రహ్మ 06:48 వరకు",
            Varjyam: "23:32 నుండి 01:21 వరకు",
            Moonrise: "03:32, అక్టోబర్ 29",
            Moonset: "15:22",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "10:25 నుండి 12:13 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య",
            Date: "మంగళవారము, అక్టోబర్ 29, 2024",
            DurMuhurtamulu: "08:21 నుండి 09:07 వరకు",
            GulikaiKalam: "12:00 నుండి 13:26 వరకు",
            Karanamulu: "తైతిల 10:31 వరకు, బవ 23:53 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "ఉత్తర 18:33 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "14:53 నుండి 16:19 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:14",
            Sunset: "17:46",
            Thiti: "ద్వాదశి 10:32 వరకు",
            Yamaganda: "09:07 నుండి 10:33 వరకు",
            Yoga: "ఐన్ద్ర 07:48 వరకు",
            Varjyam: "04:04 నుండి 05:53 వరకు",
            Moonrise: "04:19, అక్టోబర్ 30",
            Moonset: "15:53",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "14:56 నుండి 16:45 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య",
            Date: "బుధవారము, అక్టోబర్ 30, 2024",
            DurMuhurtamulu: "11:24 నుండి 12:10 వరకు",
            GulikaiKalam: "10:33 నుండి 12:00 వరకు",
            Karanamulu: "వణిజ 13:15 వరకు, విష్టి 02:35, అక్టోబర్ 31 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "హస్త 21:43 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:00 నుండి 13:26 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:14",
            Sunset: "17:45",
            Thiti: "త్రయోదశి 13:16 వరకు",
            Yamaganda: "07:40 నుండి 09:07 వరకు",
            Yoga: "వైధృతి 08:52 వరకు",
            Varjyam: "ఏమిలేదు",
            Moonrise: "05:05, అక్టోబర్ 31",
            Moonset: "16:25",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "17:32 నుండి 19:20 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య 11:16 వరకు",
            Date: "గురువారము, అక్టోబర్ 31, 2024",
            DurMuhurtamulu: "09:53 నుండి 10:38 వరకు",
            GulikaiKalam: "09:07 నుండి 10:33 వరకు",
            Karanamulu: "శకుని 15:52 వరకు, చతుష్పాద 05:06, నవంబర్ 01 వరకు",
            Masam: "ఆశ్వీయుజ మాసం",
            Nakshatra1: "చిత్తా 12:44 వరకు",
            MainNakshatra: "చిత్తా",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:26 నుండి 14:52 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:14",
            Sunset: "17:45",
            Thiti: "చతుర్దశి 03:53 వరకు",
            Yamaganda: "06:14 నుండి 07:41 వరకు",
            Yoga: "విష్కుమ్భ 09:51 వరకు",
            Varjyam: "06:43 నుండి 08:31 వరకు",
            Moonrise: "05:54, నవంబర్ 01 వరకు",
            Moonset: "16:57",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: ""
          },
        ],
      },
      {
        day: [
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "17:42 నుండి 19:29 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల",
            Date: "శుక్రవారము, నవంబర్ 1, 2024",
            DurMuhurtamulu: "08:22 నుండి 09:08 వరకు",
            GulikaiKalam: "07:41 నుండి 09:07 వరకు",
            Karanamulu: "నాగ 18:16 వరకు, కింస్తుఘ్న నిండా రాత్రి వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "స్వాతి 03:30 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:33 నుండి 12:00 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:15",
            Sunset: "17:45",
            Thiti: "అమావాస్య 18:17 వరకు",
            Yamaganda: "14:52 నుండి 16:18 వరకు",
            Yoga: "ప్రీతి 10:41 వరకు",
            Varjyam: "07:00 నుండి 08:47 వరకు",
            Moonrise: "ఏమిలేదు",
            Moonset: "17:32",
            Shaka: "1946 క్రోధి",
            Amavasya: "amavasya",
            FestDate: "1",
            LordImage: require("../images/holidays/9-4)Deewali.png"),
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "20:16 నుండి 22:02 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల 23:23 వరకు",
            Date: "శనివారము, నవంబర్ 2, 2024",
            DurMuhurtamulu: "06:05 నుండి 07:36 వరకు",
            GulikaiKalam: "06:15 నుండి 07:41 వరకు",
            Karanamulu: "కింస్తుఘ్న 07:21 వరకు, బవ 20:21 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "విశాఖ 05:58 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:07 నుండి 10:33 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:15",
            Sunset: "17:44",
            Thiti: "పాడ్యమి 20:22 వరకు",
            Yamaganda: "13:26 నుండి 14:52 వరకు",
            Yoga: "ఆయుష్మాన్ 11:19 వరకు",
            Varjyam: "09:41 నుండి 11:27 వరకు",
            Moonrise: "06:44",
            Moonset: "18:11",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "20:45 నుండి 22:30 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "ఆదివారము, నవంబర్ 3, 2024",
            DurMuhurtamulu: "15:56 నుండి 16:41 వరకు",
            GulikaiKalam: "14:52 నుండి 16:18 వరకు",
            Karanamulu: "బాలవ 09:16 వరకు, కౌలవ 22:05 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "అనూరాధ నిండా రాత్రి వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "16:18 నుండి 17:44 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:16",
            Sunset: "17:44",
            Thiti: "విదియ 22:06 వరకు",
            Yamaganda: "12:00 నుండి 13:26 వరకు",
            Yoga: "సౌభాగ్య 11:40 వరకు",
            Varjyam: "10:19 నుండి 12:03 వరకు",
            Moonrise: "07:37",
            Moonset: "18:54",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "00:20, నవంబర్ 05 నుండి 02:03, నవంబర్ 05 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "సోమవారము, నవంబర్ 4, 2024",
            DurMuhurtamulu: "12:09 నుండి 12:54 వరకు",
            GulikaiKalam: "13:26 నుండి 14:51 వరకు",
            Karanamulu: "తైతిల 10:47 వరకు, గర 23:24 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "అనూరాధ 08:03 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:42 నుండి 09:08 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:16",
            Sunset: "17:43",
            Thiti: "తదియ 23:25 వరకు",
            Yamaganda: "10:34 నుండి 12:00 వరకు",
            Yoga: "శోభన 11:44 వరకు",
            Varjyam: "14:04 నుండి 15:47 వరకు",
            Moonrise: "08:32",
            Moonset: "19:42",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "04:16, నవంబర్ 06 నుండి 05:57, నవంబర్ 06 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము 09:45 వరకు",
            Date: "మంగళవారము, నవంబర్ 5, 2024",
            DurMuhurtamulu: "08:23 నుండి 09:08 వరకు",
            GulikaiKalam: "12:00 నుండి 13:25 వరకు",
            Karanamulu: "వణిజ 11:54 వరకు, విష్టి 00:16, నవంబర్ 06 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "జ్యేష్ఠ 09:45 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "14:51 నుండి 16:17 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:16",
            Sunset: "17:43",
            Thiti: "చవితి 00:17 వరకు",
            Yamaganda: "09:08 నుండి 10:34 వరకు",
            Yoga: "అతిగణ్డ 11:28 వరకు",
            Varjyam: "ఏమిలేదు",
            Moonrise: "09:29",
            Moonset: "20:35",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "ఏమిలేదు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "బుధవారము, నవంబర్ 6, 2024",
            DurMuhurtamulu: "11:24 నుండి 12:09 వరకు",
            GulikaiKalam: "10:34 నుండి 12:00 వరకు",
            Karanamulu: "బవ 12:32 వరకు, బాలవ 00:41, నవంబర్ 07 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "మూల 11:00 వరకు",
            MainNakshatra: "మూల",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:00 నుండి 13:25 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:17",
            Sunset: "17:43",
            Thiti: "పంచమి 00:41 వరకు",
            Yamaganda: "07:43 నుండి 09:08 వరకు",
            Yoga: "సుకర్మా 10:51 వరకు",
            Varjyam: "09:19 నుండి 11:00 వరకు",
            Moonrise: "10:24",
            Moonset: "21:32",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam:
              "06:50 నుండి 08:29 వరకు, 05:35, నవంబర్ 08 నుండి 07:12, నవంబర్ 08 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు 17:54 వరకు",
            Date: "గురువారము, నవంబర్ 7, 2024",
            DurMuhurtamulu: "09:53 నుండి 10:38 వరకు",
            GulikaiKalam: "09:09 నుండి 10:34 వరకు",
            Karanamulu: "కౌలవ 12:41 వరకు, తైతిల 00:34, నవంబర్ 08 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "పూర్వాషాఢ 11:46 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:25 నుండి 14:51 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:17",
            Sunset: "17:42",
            Thiti: "షష్టి 00:35",
            Yamaganda: "06:17 నుండి 07:43 వరకు",
            Yoga: "ధృతి 09:52 వరకు",
            Varjyam: "19:52 నుండి 21:29 వరకు",
            Moonrise: "11:17",
            Moonset: "22:31",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "01:30, నవంబర్ 09 నుండి 03:05, నవంబర్ 09 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము",
            Date: "శుక్రవారము, నవంబర్ 8, 2024",
            DurMuhurtamulu: "08:24 నుండి 09:09 వరకు",
            GulikaiKalam: "07:43 నుండి 09:09 వరకు",
            Karanamulu: "గర 12:19 వరకు, వణిజ 23:56 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "ఉత్తరాషాఢ 12:03 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:34 నుండి 12:00 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:18",
            Sunset: "17:42",
            Thiti: "సప్తమి 23:57 వరకు",
            Yamaganda: "14:51 నుండి 16:16 వరకు",
            Yoga: "శూల 08:28 వరకు",
            Varjyam: "16:00 నుండి 17:35 వరకు",
            Moonrise: "12:07",
            Moonset: "23:31",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "00:56, నవంబర్ 10 నుండి 02:29, నవంబర్ 10 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము 23:27 వరకు",
            Date: "శనివారము, నవంబర్ 9, 2024",
            DurMuhurtamulu: "06:09 నుండి 07:39 వరకు",
            GulikaiKalam: "06:18 నుండి 07:44 వరకు",
            Karanamulu: "విష్టి 11:24 వరకు, బవ 22:45 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "శ్రవణం 11:47 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:09 నుండి 10:34 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:18",
            Sunset: "17:42",
            Thiti: "అష్టమి 22:46 వరకు",
            Yamaganda: "13:25 నుండి 14:51 వరకు",
            Yoga: "గణ్డ 06:39 వరకు",
            Varjyam: "15:39 నుండి 17:12 వరకు",
            Moonrise: "12:52",
            Moonset: "00:30, నవంబర్ 10",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "02:52, నవంబర్ 11 నుండి 04:23, నవంబర్ 11 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము",
            Date: "ఆదివారము, నవంబర్ 10, 2024",
            DurMuhurtamulu: "15:55 నుండి 16:40 వరకు",
            GulikaiKalam: "14:51 నుండి 16:16 వరకు",
            Karanamulu: "బాలవ 09:57 వరకు, కౌలవ 21:01 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "ధనిష్ఠ 10:59 వరకు",
            MainNakshatra: "ధనిష్ఠ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "16:16 నుండి 17:41 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:19",
            Sunset: "17:41",
            Thiti: "నవమి 21:02 వరకు",
            Yamaganda: "12:00 నుండి 13:25 వరకు",
            Yoga: "ధ్రువ 01:42, నవంబర్ 11 వరకు",
            Varjyam: "17:47 నుండి 19:18 వరకు",
            Moonrise: "13:35",
            Moonset: "01:29, నవంబర్ 11",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "00:28, నవంబర్ 12 నుండి 01:57, నవంబర్ 12 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము 02:22, నవంబర్ 12 వరకు",
            Date: "సోమవారము, నవంబర్ 11, 2024",
            DurMuhurtamulu: "12:09 నుండి 12:54 వరకు",
            GulikaiKalam: "13:25 నుండి 14:51 వరకు",
            Karanamulu: "తైతిల 07:57 వరకు, బవ 18:46 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "శతభిషం 09:40 వరకు",
            MainNakshatra: "శతభిషం",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:44 నుండి 09:10 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:19",
            Sunset: "17:41",
            Thiti: "దశమి 18:47 వరకు",
            Yamaganda: "10:35 నుండి 12:00 వరకు",
            Yoga: "వ్యాఘాత 22:36 వరకు",
            Varjyam: "15:35 నుండి 17:04 వరకు",
            Moonrise: "14:15",
            Moonset: "02:27, నవంబర్ 12",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:38 నుండి 12:23 వరకు",
            Amrutakalam: "01:19, నవంబర్ 13 నుండి 02:46, నవంబర్ 13 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం",
            Date: "మంగళవారము, నవంబర్ 12, 2024",
            DurMuhurtamulu: "08:25 నుండి 09:10 వరకు",
            GulikaiKalam: "12:00 నుండి 13:25 వరకు",
            Karanamulu: "విష్టి 16:04 వరకు, బవ 02:35, నవంబర్ 13 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "పూర్వాభాద్ర 07:52 వరకు, ఉత్తరాభాద్ర 05:40 వరకు",
            MainNakshatra: "పూర్వాభాద్ర, ఉత్తరాభాద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "14:51 నుండి 16:16 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:20",
            Sunset: "17:41",
            Thiti: "ఏకాదశి 16:05 వరకు",
            Yamaganda: "09:10 నుండి 10:35 వరకు",
            Yoga: "హర్షణ 19:10 వరకు",
            Varjyam: "16:35 నుండి 18:02 వరకు",
            Moonrise: "14:55",
            Moonset: "03:26, నవంబర్ 13",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "01:02, నవంబర్ 14 నుండి 02:28, నవంబర్ 14 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం 03:11, నవంబర్ 14 వరకు",
            Date: "బుధవారము, నవంబర్ 13, 2024",
            DurMuhurtamulu: "11:24 నుండి 12:09 వరకు",
            GulikaiKalam: "10:35 నుండి 12:00 వరకు",
            Karanamulu: "బాలవ 13:01 వరకు, కౌలవ 23:23 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "రేవతి 03:10 వరకు",
            MainNakshatra: "రేవతి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:00 నుండి 13:25 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:20",
            Sunset: "17:41",
            Thiti: "ద్వాదశి 13:02 వరకు",
            Yamaganda: "07:45 నుండి 09:10 వరకు",
            Yoga: "వజ్ర 15:26 వరకు",
            Varjyam: "16:25 నుండి 17:51 వరకు",
            Moonrise: "15:36",
            Moonset: "04:26, నవంబర్ 14",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:38 నుండి 12:23 వరకు",
            Amrutakalam: "18:08 నుండి 19:34 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం",
            Date: "గురువారము, నవంబర్ 14, 2024",
            DurMuhurtamulu: "09:55 నుండి 10:40 వరకు",
            GulikaiKalam: "09:11 నుండి 10:36 వరకు",
            Karanamulu: "తైతిల 09:43 వరకు, బవ 20:01 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "అశ్విని 00:32 వరకు",
            MainNakshatra: "అశ్విని",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:26 నుండి 14:51 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:21",
            Sunset: "17:40",
            Thiti: "త్రయోదశి 09:44 వరకు",
            Yamaganda: "06:21 నుండి 07:46 వరకు",
            Yoga: "సిద్ధి 11:30 వరకు",
            Varjyam: "20:59 నుండి 22:24 వరకు",
            Moonrise: "16:19",
            Moonset: "05:30, నవంబర్ 15",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
           FestDate: "14",
            LordImage: require("../images/holidays/10-0)Childrensday.png"),
          },
          {
            Abhijit: "11:38 నుండి 12:23 వరకు",
            Amrutakalam: "17:38 నుండి 19:04 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం 03:17, నవంబర్ 16 వరకు",
            Date: "శుక్రవారము, నవంబర్ 15, 2024",
            DurMuhurtamulu: "08:26 నుండి 09:11 వరకు",
            GulikaiKalam: "07:46 నుండి 09:11 వరకు",
            Karanamulu: "విష్టి 16:37 వరకు, బవ 02:58, నవంబర్ 16 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "భరణి 21:54 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:36 నుండి 12:01 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:21",
            Sunset: "17:40",
            Thiti: "చతుర్దశి 06:19 వరకు, పౌర్ణమి 02:58 వరకు",
            Yamaganda: "14:51 నుండి 16:15 వరకు",
            Yoga: "వ్యతీపాత 07:30 వరకు",
            Varjyam: "09:05 నుండి 10:30 వరకు",
            Moonrise: "17:07",
            Moonset: "ఏమిలేదు",
            Shaka: "1946 క్రోధి",
            Amavasya: "pournami",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:38 నుండి 12:24 వరకు",
            Amrutakalam: "17:19 నుండి 18:45 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృషభం",
            Date: "శనివారము, నవంబర్ 16, 2024",
            DurMuhurtamulu: "06:12 నుండి 07:42 వరకు",
            GulikaiKalam: "06:22 నుండి 07:47 వరకు",
            Karanamulu: "బాలవ 13:21 వరకు, కౌలవ 23:50 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "కృత్తిక 19:27 వరకు",
            MainNakshatra: "కృత్తిక",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:11 నుండి 10:36 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:22",
            Sunset: "17:40",
            Thiti: "పాడ్యమి 23:51 వరకు",
            Yamaganda: "13:26 నుండి 14:51 వరకు",
            Yoga: "పరిఘ 23:48 వరకు",
            Varjyam: "08:41 నుండి 10:07 వరకు",
            Moonrise: "18:00",
            Moonset: "06:36",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:39 నుండి 12:24 వరకు",
            Amrutakalam: "14:27 నుండి 15:55 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృషభం 04:31, నవంబర్ 18 వరకు",
            Date: "ఆదివారము, నవంబర్ 17, 2024",
            DurMuhurtamulu: "15:54 నుండి 16:39 వరకు",
            GulikaiKalam: "14:51 నుండి 16:15 వరకు",
            Karanamulu: "తైతిల 10:24 వరకు, బవ 21:06 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "రోహిణి 05:22 వరకు",
            MainNakshatra: "రోహిణి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:15 నుండి 17:40 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:22",
            Sunset: "17:40",
            Thiti: "విదియ 21:07 వరకు",
            Yamaganda: "12:01 నుండి 13:26 వరకు",
            Yoga: "శివ 20:21 వరకు",
            Varjyam: "10:06 నుండి 11:34 వరకు",
            Moonrise: "18:58",
            Moonset: "07:44",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:39 నుండి 12:24 వరకు",
            Amrutakalam:
              "07:35 నుండి 09:05 వరకు, 05:18, నవంబర్ 19 నుండి 06:50, నవంబర్ 19 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం",
            Date: "సోమవారము, నవంబర్ 18, 2024",
            DurMuhurtamulu: "12:10 నుండి 12:55 వరకు",
            GulikaiKalam: "13:26 నుండి 14:51 వరకు",
            Karanamulu: "వణిజ 07:56 వరకు, విష్టి 18:55 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "మృగశిర 15:48 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "07:47 నుండి 09:12 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:23",
            Sunset: "17:40",
            Thiti: "తదియ 18:56 వరకు",
            Yamaganda: "10:37 నుండి 12:01 వరకు",
            Yoga: "సిద్ధ 17:22 వరకు",
            Varjyam: "23:53 నుండి 01:25 వరకు",
            Moonrise: "20:00",
            Moonset: "08:50",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:39 నుండి 12:24 వరకు",
            Amrutakalam: "ఏమిలేదు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం",
            Date: "మంగళవారము, నవంబర్ 19, 2024",
            DurMuhurtamulu: "08:28 నుండి 09:13 వరకు",
            GulikaiKalam: "12:02 నుండి 13:26 వరకు",
            Karanamulu: "బాలవ 17:28 వరకు, కౌలవ 05:02, నవంబర్ 20 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "ఆరుద్ర 14:55 వరకు",
            MainNakshatra: "ఆరుద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "14:51 నుండి 16:15 వరకు ",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:23",
            Sunset: "17:40",
            Thiti: "చవితి 17:29 వరకు",
            Yamaganda: "09:12 నుండి 10:37 వరకు",
            Yoga: "సాధ్య 14:56 వరకు",
            Varjyam: "02:52 నుండి 04:28 వరకు",
            Moonrise: "21:02",
            Moonset: "09:51",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "12:27 నుండి 14:02 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం 08:47 వరకు",
            Date: "బుధవారము, నవంబర్ 20, 2024",
            DurMuhurtamulu: "11:26 నుండి 12:11 వరకు",
            GulikaiKalam: "10:37 నుండి 12:02 వరకు",
            Karanamulu: "తైతిల 16:49 వరకు, బవ 04:49, నవంబర్ 21 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "పునర్వసు 14:50 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:02 నుండి 13:26 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:24",
            Sunset: "17:40",
            Thiti: "పంచమి 16:50 వరకు",
            Yamaganda: "07:48 నుండి 09:13 వరకు",
            Yoga: "శుభ 13:08 వరకు",
            Varjyam: "23:05 నుండి 00:44 వరకు",
            Moonrise: "22:02",
            Moonset: "10:45",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:40 నుండి 12:25 వరకు",
            Amrutakalam: "08:59 నుండి 10:38 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "గురువారము, నవంబర్ 21, 2024",
            DurMuhurtamulu: "09:58 నుండి 10:43 వరకు",
            GulikaiKalam: "09:13 నుండి 10:38 వరకు",
            Karanamulu: "వణిజ 17:03 వరకు, విష్టి 05:29, నవంబర్ 22 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "పుష్యమి 15:35 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:26 నుండి 14:51 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:24",
            Sunset: "17:40",
            Thiti: "షష్టి 17:04 వరకు",
            Yamaganda: "06:24 నుండి 07:49 వరకు",
            Yoga: "శుక్ల 12:01 వరకు",
            Varjyam: "05:13 లగాయతు",
            Moonrise: "22:59",
            Moonset: "11:32",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:40 నుండి 12:25 వరకు",
            Amrutakalam: "15:27 నుండి 17:10 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం 17:10 వరకు",
            Date: "శుక్రవారము, నవంబర్ 22, 2024",
            DurMuhurtamulu: "08:29 నుండి 09:14 వరకు",
            GulikaiKalam: "07:49 నుండి 09:14 వరకు",
            Karanamulu: "బవ 18:07 వరకు, బాలవ నిండా రాత్రి వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "ఆశ్లేష 17:09 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:38 నుండి 12:02 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:25",
            Sunset: "17:40",
            Thiti: "సప్తమి 18:08 వరకు",
            Yamaganda: "14:51 నుండి 16:15 వరకు",
            Yoga: "బ్రహ్మ 11:34 వరకు",
            Varjyam: "శే.వ.06:55 వరకు",
            Moonrise: "23:51",
            Moonset: "12:13",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:40 నుండి 12:25 వరకు",
            Amrutakalam: "16:49 నుండి 18:35 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం",
            Date: "శనివారము, నవంబర్ 23, 2024",
            DurMuhurtamulu: "06:16 నుండి 07:45 వరకు",
            GulikaiKalam: "06:26 నుండి 07:50 వరకు",
            Karanamulu: "బాలవ 06:57 వరకు, కౌలవ 19:56 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "మఖ 19:27 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:14 నుండి 10:38 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:26",
            Sunset: "17:40",
            Thiti: "అష్టమి 19:58 వరకు",
            Yamaganda: "13:27 నుండి 14:51 వరకు",
            Yoga: "ఐన్ద్ర 11:42 వరకు",
            Varjyam: "06:18 నుండి 08:03 వరకు",
            Moonrise: "00:41, నవంబర్ 24",
            Moonset: "12:49",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:40 నుండి 12:25 వరకు",
            Amrutakalam: "15:07 నుండి 16:55 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం 05:02, నవంబర్ 25 వరకు",
            Date: "ఆదివారము, నవంబర్ 24, 2024",
            DurMuhurtamulu: "15:54 నుండి 16:38 వరకు",
            GulikaiKalam: "14:51 నుండి 16:15 వరకు",
            Karanamulu: "తైతిల 09:05 వరకు, బవ 22:19 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "పుబ్బ 22:16 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:15 నుండి 17:40 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:26",
            Sunset: "17:40",
            Thiti: "నవమి 22:20 వరకు",
            Yamaganda: "12:03 నుండి 13:27 వరకు",
            Yoga: "వైధృతి 12:18 వరకు",
            Varjyam: "ఏమిలేదు",
            Moonrise: "01:28, నవంబర్ 25",
            Moonset: "13:22",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:41 నుండి 12:26 వరకు",
            Amrutakalam: "17:16 నుండి 19:04 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య",
            Date: "సోమవారము, నవంబర్ 25, 2024",
            DurMuhurtamulu: "12:13 నుండి 12:57 వరకు",
            GulikaiKalam: "13:27 నుండి 14:51 వరకు",
            Karanamulu: "వణిజ 11:39 వరకు, విష్టి 01:01, నవంబర్ 26 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "ఉత్తర 01:23 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "07:51 నుండి 09:15 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:27",
            Sunset: "17:40",
            Thiti: "దశమి 01:02 వరకు",
            Yamaganda: "10:39 నుండి 12:03 వరకు",
            Yoga: "విష్కుమ్భ 13:12 వరకు",
            Varjyam: "06:24 నుండి 08:12 వరకు",
            Moonrise: "02:14, నవంబర్ 26",
            Moonset: "13:54",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:41 నుండి 12:26 వరకు",
            Amrutakalam: "21:47 నుండి 23:36 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య",
            Date: "మంగళవారము, నవంబర్ 26, 2024",
            DurMuhurtamulu: "08:31 నుండి 09:15 వరకు",
            GulikaiKalam: "12:04 నుండి 13:28 వరకు",
            Karanamulu: "బవ 14:25 వరకు, బాలవ 03:47, నవంబర్ 27 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "హస్త 04:34 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "14:52 నుండి 16:16 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:27",
            Sunset: "17:40",
            Thiti: "ఏకాదశి 03:48 వరకు",
            Yamaganda: "09:15 నుండి 10:40 వరకు",
            Yoga: "ప్రీతి 14:14 వరకు",
            Varjyam: "10:54 నుండి 12:43 వరకు",
            Moonrise: "03:01, నవంబర్ 27",
            Moonset: "14:25",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "00:24, నవంబర్ 28 నుండి 02:12, నవంబర్ 28 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య 18:07 వరకు",
            Date: "బుధవారము, నవంబర్ 27, 20241",
            DurMuhurtamulu: "11:29 నుండి 12:13 వరకు",
            GulikaiKalam: "10:40 నుండి 12:04 వరకు",
            Karanamulu: "కౌలవ 17:07 వరకు, తైతిల 06:23, నవంబర్ 28 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "చిత్తా నిండా రాత్రి వరకు",
            MainNakshatra: "చిత్త",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:04 నుండి 13:28 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:28",
            Sunset: "17:40",
            Thiti: "ద్వాదశి పూర్తి",
            Yamaganda: "07:52 నుండి 09:16 వరకు",
            Yoga: "ఆయుష్మాన్ 15:13 వరకు",
            Varjyam: "13:34 నుండి 15:22 వరకు",
            Moonrise: "03:48, నవంబర్ 28",
            Moonset: "14:58",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:42 నుండి 12:27 వరకు",
            Amrutakalam: "00:30, నవంబర్ 29 నుండి 02:17, నవంబర్ 29 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల",
            Date: "గురువారము, నవంబర్ 28, 2024",
            DurMuhurtamulu: "10:00 నుండి 10:44 వరకు",
            GulikaiKalam: "09:16 నుండి 10:40 వరకు",
            Karanamulu: "బవ 19:34 వరకు, వణిజ నిండా రాత్రి వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "చిత్తా 07:35 వరకు",
            MainNakshatra: "చిత్త",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:28 నుండి 14:52 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:29",
            Sunset: "17:40",
            Thiti: "ద్వాదశి 06:24 వరకు",
            Yamaganda: "06:29 నుండి 07:52 వరకు",
            Yoga: "సౌభాగ్య 16:02 వరకు",
            Varjyam: "13:49 నుండి 15:36 వరకు",
            Moonrise: "04:38, నవంబర్ 29",
            Moonset: "15:32",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:42 నుండి 12:27 వరకు",
            Amrutakalam: "02:56, నవంబర్ 30 నుండి 04:42, నవంబర్ 30 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల 06:03, నవంబర్ 30 వరకు",
            Date: "శుక్రవారము, నవంబర్ 29, 2024",
            DurMuhurtamulu: "08:32 నుండి 09:17 వరకు",
            GulikaiKalam: "07:53 నుండి 09:17 వరకు",
            Karanamulu: "వణిజ 08:39 వరకు, విష్టి 21:38 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "స్వాతి 10:17 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:41 నుండి 12:05 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:29",
            Sunset: "17:40",
            Thiti: "త్రయోదశి 08:40 వరకు",
            Yamaganda: "14:52 నుండి 16:16 వరకు",
            Yoga: "శోభన 16:34 వరకు",
            Varjyam: "16:25 నుండి 18:10 వరకు",
            Moonrise: "05:31, నవంబర్ 30",
            Moonset: "16:09",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:43 నుండి 12:27 వరకు",
            Amrutakalam: "03:12, డిసెంబర్ 01 నుండి 04:56, డిసెంబర్ 01 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "శనివారము, నవంబర్ 30, 2024",
            DurMuhurtamulu: "06:20 నుండి 07:49 వరకు",
            GulikaiKalam: "06:30 నుండి 07:54 వరకు",
            Karanamulu: "శకుని 10:29 వరకు, చతుష్పాద 23:14 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "విశాఖ 12:34 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:17 నుండి 10:41 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:30",
            Sunset: "17:40",
            Thiti: "చతుర్దశి 10:30 వరకు",
            Yamaganda: "13:29 నుండి 14:53 వరకు",
            Yoga: "అతిగణ్డ 16:45 వరకు",
            Varjyam: "16:52 నుండి 18:35 వరకు",
            Moonrise: "06:26, డిసెంబర్ 01",
            Moonset: "16:51",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
        ],
      },
      {
        day: [
          {
            Abhijit: "11:43 నుండి 12:28 వరకు",
            Amrutakalam: "06:27, డిసెంబర్ 02 నుండి 08:09, డిసెంబర్ 02 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "ఆదివారము, డిసెంబర్ 1, 2024",
            DurMuhurtamulu: "16:11 నుండి 16:56 వరకు",
            GulikaiKalam: "14:53 నుండి 16:17 వరకు",
            Karanamulu: "నాగ 11:50 వరకు, కింస్తుఘ్న 00:20, డిసెంబర్ 02 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "అనూరాధ 14:23 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:17 నుండి 17:40 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:30",
            Sunset: "17:40",
            Thiti: "అమావాస్య 11:51 వరకు",
            Yamaganda: "12:05 నుండి 13:29 వరకు",
            Yoga: "సుకర్మా 16:34 వరకు",
            Varjyam: "20:18 నుండి 21:59 వరకు",
            Moonrise: "ఏమిలేదు",
            Moonset: "17:38",
            Shaka: "1946 క్రోధి",
            Amavasya: "amavasya",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:43 నుండి 12:28 వరకు",
            Amrutakalam: "ఏమిలేదు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము 15:45 వరకు",
            Date: "సోమవారము, డిసెంబర్ 2, 2024",
            DurMuhurtamulu: "12:28 నుండి 13:13 వరకు, 14:42 నుండి 15:27 వరకు",
            GulikaiKalam: "13:29 నుండి 14:53 వరకు",
            Karanamulu: "బవ 12:43 వరకు, బాలవ 00:59, డిసెంబర్ 03 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "జ్యేష్ఠ 15:45 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:55 నుండి 09:18 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:31",
            Sunset: "17:41",
            Thiti: "పాడ్యమి 12:44 వరకు",
            Yamaganda: "10:42 నుండి 12:06 వరకు",
            Yoga: "ధృతి 16:01 వరకు",
            Varjyam: "ఏమిలేదు",
            Moonrise: "07:23",
            Moonset: "18:30",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:44 నుండి 12:28 వరకు",
            Amrutakalam: "10:03 నుండి 11:42 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "మంగళవారము, డిసెంబర్ 3, 2024",
            DurMuhurtamulu: "08:45 నుండి 09:30 వరకు, 22:49 నుండి 23:41 వరకు",
            GulikaiKalam: "12:06 నుండి 13:30 వరకు",
            Karanamulu: "కౌలవ 13:09 వరకు, తైతిల 01:12, డిసెంబర్ 04 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "మూల 16:41 వరకు",
            MainNakshatra: "మూల",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "14:53 నుండి 16:17 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:32",
            Sunset: "17:41",
            Thiti: "విదియ 13:09 వరకు",
            Yamaganda: "09:19 నుండి 10:42 వరకు",
            Yoga: "శూల 15:08 వరకు",
            Varjyam: "15:01 నుండి 16:41 వరకు",
            Moonrise: "08:19",
            Moonset: "19:27",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "12:20 నుండి 13:58 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు 23:20 వరకు",
            Date: "బుధవారము, డిసెంబర్ 4, 2024",
            DurMuhurtamulu: "11:44 నుండి 12:29 వరకు",
            GulikaiKalam: "10:43 నుండి 12:07 వరకు",
            Karanamulu: "గర 13:10 వరకు, వణిజ 01:02, డిసెంబర్ 05 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "పూర్వాషాఢ 17:14 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:07 నుండి 13:30 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:32",
            Sunset: "17:41",
            Thiti: "తదియ 13:11 వరకు",
            Yamaganda: "07:56 నుండి 09:19 వరకు",
            Yoga: "గణ్డ 13:57 వరకు",
            Varjyam: "01:18 నుండి 02:56 వరకు",
            Moonrise: "09:14",
            Moonset: "20:26",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:45 నుండి 12:29 వరకు",
            Amrutakalam: "10:59 నుండి 12:36 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము",
            Date: "గురువారము, డిసెంబర్ 5, 2024",
            DurMuhurtamulu: "10:16 నుండి 11:00 వరకు, 14:43 నుండి 15:27 వరకు",
            GulikaiKalam: "09:20 నుండి 10:43 వరకు",
            Karanamulu: "విష్టి 12:49 వరకు, బవ 00:31, డిసెంబర్ 06 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "ఉత్తరాషాఢ 17:26 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:31 నుండి 14:54 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:33",
            Sunset: "17:41",
            Thiti: "చవితి 12:50 వరకు",
            Yamaganda: "06:33 నుండి 07:56 వరకు",
            Yoga: "వృద్ధి 12:28 వరకు",
            Varjyam: "21:13 నుండి 20:47 వరకు",
            Moonrise: "10:05",
            Moonset: "21:26",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:45 నుండి 12:30 వరకు",
            Amrutakalam: "06:58 నుండి 08:33 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము 05:07, డిసెంబర్ 07 వరకు",
            Date: "శుక్రవారము, డిసెంబర్ 6, 2024",
            DurMuhurtamulu: "08:47 నుండి 09:32 వరకు, 12:30 నుండి 13:14 వరకు",
            GulikaiKalam: "07:57 నుండి 09:20 వరకు",
            Karanamulu: "బాలవ 12:07 వరకు, కౌలవ 23:39 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "శ్రవణం 17:18 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:44 నుండి 12:07 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:33",
            Sunset: "17:41",
            Thiti: "పంచమి 12:08 వరకు",
            Yamaganda: "14:54 నుండి 16:18 వరకు",
            Yoga: "ధ్రువ 10:43 వరకు",
            Varjyam: "21:13 నుండి 20:47 వరకు",
            Moonrise: "10:51",
            Moonset: "22:25",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:46 నుండి 12:30 వరకు",
            Amrutakalam: "06:38 నుండి 08:12 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము",
            Date: "శనివారము, డిసెంబర్ 7, 2024",
            DurMuhurtamulu: "06:34 నుండి 07:18 వరకు, 07:18 నుండి 08:03 వరకు",
            GulikaiKalam: "06:34 నుండి 07:57 వరకు",
            Karanamulu: "తైతిల 11:05 వరకు, గర 22:27 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "ధనిష్ఠ 16:50 వరకు",
            MainNakshatra: "ధనిష్ఠ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:21 నుండి 10:44 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:34",
            Sunset: "17:42",
            Thiti: "షష్టి 11:06 వరకు",
            Yamaganda: "13:31 నుండి 14:55 వరకు",
            Yoga: "వ్యాఘాత 08:42 వరకు",
            Varjyam: "23:48 నుండి 01:21 వరకు",
            Moonrise: "11:34",
            Moonset: "23:22",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:46 నుండి 12:31 వరకు",
            Amrutakalam: "09:05 నుండి 10:38 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము",
            Date: "ఆదివారము, డిసెంబర్ 8, 2024",
            DurMuhurtamulu: "16:13 నుండి 16:57 వరకు",
            GulikaiKalam: "14:55 నుండి 16:19 వరకు",
            Karanamulu: "వణిజ 09:44 వరకు, విష్టి 20:55 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "శతభిషం 16:02 వరకు",
            MainNakshatra: "శతభిషం",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "16:19 నుండి 17:42 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:35",
            Sunset: "17:42",
            Thiti: "సప్తమి 09:45 వరకు",
            Yamaganda: "12:08 నుండి 13:32 వరకు",
            Yoga: "వజ్ర 03:54, డిసెంబర్ 09 వరకు",
            Varjyam: "22:08 నుండి 23:40 వరకు",
            Moonrise: "12:14",
            Moonset: "00:19, డిసెంబర్ 09",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:46 నుండి 12:31 వరకు",
            Amrutakalam: "07:18 నుండి 08:50 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము 09:14 వరకు",
            Date: "సోమవారము, డిసెంబర్ 9, 2024",
            DurMuhurtamulu: "12:31 నుండి 13:15 వరకు, 14:44 నుండి 15:29 వరకు",
            GulikaiKalam: "13:32 నుండి 14:55 వరకు",
            Karanamulu: "బవ 08:02 వరకు, బాలవ 19:04 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "పూర్వాభాద్ర 14:55 వరకు",
            MainNakshatra: "పూర్వాభాద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:59 నుండి 09:22 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:35",
            Sunset: "17:42",
            Thiti: "అష్టమి 08:03 వరకు, నవమి 06:02 వరకు",
            Yamaganda: "10:45 నుండి 12:09 వరకు",
            Yoga: "సిద్ధి 01:06, డిసెంబర్ 10 వరకు",
            Varjyam: "23:57 నుండి 01:27",
            Moonrise: "12:52",
            Moonset: "01:15, డిసెంబర్ 10",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:31 వరకు",
            Amrutakalam: "08:59 నుండి 10:30 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం",
            Date: "మంగళవారము, డిసెంబర్ 10, 2024",
            DurMuhurtamulu: "08:49 నుండి 09:34 వరకు, 22:52 నుండి 23:44 వరకు",
            GulikaiKalam: "12:09 నుండి 13:33 వరకు",
            Karanamulu: "తైతిల 16:54 వరకు, బవ 03:42, డిసెంబర్ 11 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "ఉత్తరాభాద్ర 13:30 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "14:56 నుండి 16:19 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:36",
            Sunset: "17:43",
            Thiti: "దశమి 03:43 వరకు",
            Yamaganda: "09:22 నుండి 10:46 వరకు",
            Yoga: "వ్యతీపాత 22:03 వరకు",
            Varjyam: "00:39 నుండి 02:08 వరకు",
            Moonrise: "13:31",
            Moonset: "02:13, డిసెంబర్ 11",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam:
              "09:34 నుండి 11:03 వరకు, 03:15, డిసెంబర్ 12 నుండి 04:43, డిసెంబర్ 12 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం 11:48 వరకు",
            Date: "బుధవారము, డిసెంబర్ 11, 2024",
            DurMuhurtamulu: "11:47 నుండి 12:32 వరకు",
            GulikaiKalam: "10:46 నుండి 12:10 వరకు",
            Karanamulu: "వణిజ 14:27 వరకు, విష్టి 01:09, డిసెంబర్ 12 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "రేవతి 11:47 వరకు",
            MainNakshatra: "రేవతి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:10 నుండి 13:33 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:36",
            Sunset: "17:43",
            Thiti: "ఏకాదశి 01:10 వరకు",
            Yamaganda: "08:00 నుండి 09:23 వరకు",
            Yoga: "వారీయన 18:48 వరకు",
            Varjyam: "06:11 లగాయతు",
            Moonrise: "14:11",
            Moonset: "03:13, డిసెంబర్ 12",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:32 వరకు",
            Amrutakalam: "03:26, డిసెంబర్ 13 నుండి 04:54, డిసెంబర్ 13 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం",
            Date: "గురువారము, డిసెంబర్ 12, 2024",
            DurMuhurtamulu: "10:19 నుండి 11:03 వరకు, 14:46 నుండి 15:30 వరకు",
            GulikaiKalam: "09:23 నుండి 10:47 వరకు",
            Karanamulu: "బవ 11:48 వరకు, బాలవ 22:26 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "అశ్విని 09:52 వరకు",
            MainNakshatra: "అశ్విని",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:33 నుండి 14:57 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:37",
            Sunset: "17:43",
            Thiti: "ద్వాదశి 22:27 వరకు",
            Yamaganda: "06:37 నుండి 08:00 వరకు",
            Yoga: "పరిఘ 15:23 వరకు",
            Varjyam: "శే.వ.07:39 వరకు",
            Moonrise: "14:55",
            Moonset: "04:16, డిసెంబర్ 13",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:33 వరకు",
            Amrutakalam: "03:36, డిసెంబర్ 14 నుండి 05:04, డిసెంబర్ 14 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం 13:19 వరకు",
            Date: "శుక్రవారము, డిసెంబర్ 13, 2024",
            DurMuhurtamulu: "08:51 నుండి 09:35 వరకు, 12:33 నుండి 13:17 వరకు",
            GulikaiKalam: "08:01 నుండి 09:24 వరకు",
            Karanamulu: "కౌలవ 09:03 వరకు, తైతిల 19:40 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "భరణి 07:49 వరకు, కృత్తిక 05:47 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:47 నుండి 12:11 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:37",
            Sunset: "17:44",
            Thiti: "త్రయోదశి 19:40 వరకు",
            Yamaganda: "14:57 నుండి 16:20 వరకు",
            Yoga: "శివ 11:54 వరకు",
            Varjyam: "18:48 నుండి 20:12 వరకు",
            Moonrise: "15:45",
            Moonset: "05:22, డిసెంబర్ 14",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:33 వరకు",
            Amrutakalam: "00:57, డిసెంబర్ 15 నుండి 02:26, డిసెంబర్ 15 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృషభం",
            Date: "శనివారము, డిసెంబర్ 14, 2024",
            DurMuhurtamulu: "06:38 నుండి 07:22 వరకు, 07:22 నుండి 08:07 వరకు",
            GulikaiKalam: "06:38 నుండి 08:01 వరకు",
            Karanamulu: "వణిజ 16:58 వరకు, విష్టి 03:42, డిసెంబర్ 15 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "రోహిణి 03:54 వరకు",
            MainNakshatra: "రోహిణి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:25 నుండి 10:48 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:38",
            Sunset: "17:44",
            Thiti: "చతుర్దశి 16:59 వరకు",
            Yamaganda: "13:34 నుండి 14:58 వరకు",
            Yoga: "సిద్ధ 08:27 వరకు",
            Varjyam: "20:32 నుండి 22:00 వరకు",
            Moonrise: "16:39",
            Moonset: "06:28, డిసెంబర్ 15",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:34 వరకు",
            Amrutakalam: "18:06 నుండి 19:36 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృషభం 15:04 వరకు",
            Date: "ఆదివారము, డిసెంబర్ 15, 2024",
            DurMuhurtamulu: "16:16 నుండి 17:00 వరకు",
            GulikaiKalam: "14:58 నుండి 16:21 వరకు",
            Karanamulu: "బవ 14:31 వరకు, బాలవ 01:25, డిసెంబర్ 16 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "మృగశిర 02:19 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "16:21 నుండి 17:44 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:39",
            Sunset: "17:44",
            Thiti: "పౌర్ణమి 14:32 వరకు",
            Yamaganda: "12:12 నుండి 13:35 వరకు",
            Yoga: "శుభ 02:04, డిసెంబర్ 16 వరకు",
            Varjyam: "09:07 నుండి 10:37 వరకు",
            Moonrise: "17:39",
            Moonset: "ఏమిలేదు",
            Shaka: "1946 క్రోధి",
            Amavasya: "pournami",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:50 నుండి 12:34 వరకు",
            Amrutakalam: "15:41 నుండి 17:13 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం",
            Date: "సోమవారము, డిసెంబర్ 16, 2024",
            DurMuhurtamulu: "12:34 నుండి 13:19 వరకు, 14:47 నుండి 15:32 వరకు",
            GulikaiKalam: "13:35 నుండి 14:58 వరకు",
            Karanamulu: "కౌలవ 12:27 వరకు, తైతిల 23:37 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "ఆరుద్ర 01:13 వరకు",
            MainNakshatra: "ఆరుద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "08:02 నుండి 09:26 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:39",
            Sunset: "17:45",
            Thiti: "పాడ్యమి 12:28 వరకు",
            Yamaganda: "10:49 నుండి 12:12 వరకు",
            Yoga: "శుక్ల 23:23 వరకు",
            Varjyam: "10:20 నుండి 11:52 వరకు",
            Moonrise: "18:42",
            Moonset: "07:32",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:50 నుండి 12:35 వరకు",
            Amrutakalam: "22:23 నుండి 23:57 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం 18:47 వరకు",
            Date: "మంగళవారము, డిసెంబర్ 17, 2024",
            DurMuhurtamulu: "08:53 నుండి 09:37 వరకు",
            GulikaiKalam: "12:13 నుండి 13:36 వరకు",
            Karanamulu: "బవ 10:56 వరకు, వణిజ 22:25 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "పునర్వసు 00:43 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "14:59 నుండి 16:22 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:40",
            Sunset: "17:45",
            Thiti: "విదియ 10:56 వరకు",
            Yamaganda: "09:26 నుండి 10:49 వరకు",
            Yoga: "బ్రహ్మ 21:11 వరకు",
            Varjyam: "12:58 నుండి 14:32 వరకు",
            Moonrise: "19:45",
            Moonset: "08:30",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "18:30 నుండి 20:07 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "బుధవారము, డిసెంబర్ 18, 2024",
            DurMuhurtamulu: "11:51 నుండి 12:35 వరకు",
            GulikaiKalam: "10:50 నుండి 12:13 వరకు",
            Karanamulu: "విష్టి 10:06 వరకు, బవ 21:58 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "పుష్యమి 00:58 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:13 నుండి 13:36 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:40",
            Sunset: "17:46",
            Thiti: "తదియ 10:06 వరకు",
            Yamaganda: "08:03 నుండి 09:27 వరకు",
            Yoga: "ఐన్ద్ర 19:34 వరకు",
            Varjyam: "08:48 నుండి 10:25 వరకు",
            Moonrise: "20:45",
            Moonset: "09:22",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:51 నుండి 12:36 వరకు",
            Amrutakalam: "00:19, డిసెంబర్ 20 నుండి 02:00, డిసెంబర్ 20 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం 02:00, డిసెంబర్ 20 వరకు",
            Date: "గురువారము, డిసెంబర్ 19, 2024",
            DurMuhurtamulu: "10:23 నుండి 11:07 వరకు, 14:49 నుండి 15:33 వరకు",
            GulikaiKalam: "09:27 నుండి 10:50 వరకు",
            Karanamulu: "బాలవ 10:02 వరకు, కౌలవ 22:19 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "ఆశ్లేష 01:59 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:37 నుండి 15:00 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:41",
            Sunset: "17:46",
            Thiti: "చవితి 10:03 వరకు",
            Yamaganda: "06:41 నుండి 08:04 వరకు",
            Yoga: "వైధృతి 18:34 వరకు",
            Varjyam: "14:18 నుండి 15:58 వరకు",
            Moonrise: "21:40",
            Moonset: "10:06",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:52 నుండి 12:36 వరకు",
            Amrutakalam: "01:12, డిసెంబర్ 21 నుండి 02:55, డిసెంబర్ 21 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం",
            Date: "శుక్రవారము, డిసెంబర్ 20, 2024",
            DurMuhurtamulu: "08:54 నుండి 09:39 వరకు, 12:36 నుండి 13:21 వరకు",
            GulikaiKalam: "08:04 నుండి 09:28 వరకు",
            Karanamulu: "తైతిల 10:48 వరకు, బవ 23:29 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "మఖ 03:46 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:51 నుండి 12:14 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:41",
            Sunset: "17:47",
            Thiti: "పంచమి 10:49 వరకు",
            Yamaganda: "15:00 నుండి 16:24 వరకు",
            Yoga: "విష్కుమ్భ 18:12 వరకు",
            Varjyam: "14:53 నుండి 16:36 వరకు",
            Moonrise: "22:32",
            Moonset: "10:45",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:52 నుండి 12:37 వరకు",
            Amrutakalam: "23:11 నుండి 00:57, డిసెంబర్ 22 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం",
            Date: "శనివారము, డిసెంబర్ 21, 2024",
            DurMuhurtamulu: "06:42 నుండి 07:26 వరకు, 07:26 నుండి 08:10 వరకు",
            GulikaiKalam: "06:42 నుండి 08:05 వరకు",
            Karanamulu: "వణిజ 12:21 వరకు, విష్టి 01:22, డిసెంబర్ 22 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "పుబ్బ 06:13 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:28 నుండి 10:51 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:42",
            Sunset: "17:47",
            Thiti: "షష్టి 12:22 వరకు",
            Yamaganda: "13:38 నుండి 15:01 వరకు",
            Yoga: "ప్రీతి 18:23 వరకు",
            Varjyam: "12:35 నుండి 14:21 వరకు",
            Moonrise: "23:21",
            Moonset: "11:20",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:37 వరకు",
            Amrutakalam: "01:04, డిసెంబర్ 23 నుండి 02:52, డిసెంబర్ 23 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం 12:56 వరకు",
            Date: "ఆదివారము, డిసెంబర్ 22, 2024",
            DurMuhurtamulu: "16:19 నుండి 17:03 వరకు",
            GulikaiKalam: "15:01 నుండి 16:25 వరకు",
            Karanamulu: "బవ 14:31 వరకు, బాలవ 03:47, డిసెంబర్ 23 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "ఉత్తర నిండా రాత్రి వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:25 నుండి 17:48 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:42",
            Sunset: "17:48",
            Thiti: "సప్తమి 14:32 వరకు",
            Yamaganda: "12:15 నుండి 13:38 వరకు",
            Yoga: "ఆయుష్మాన్ 19:00 వరకు",
            Varjyam: "14:18 నుండి 16:06 వరకు",
            Moonrise: "00:08, డిసెంబర్ 23",
            Moonset: "11:53",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:38 వరకు",
            Amrutakalam: "05:30, డిసెంబర్ 24 నుండి 07:19, డిసెంబర్ 24 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య",
            Date: "సోమవారము, డిసెంబర్ 23, 2024",
            DurMuhurtamulu: "12:38 నుండి 13:22 వరకు, 14:51 నుండి 15:35 వరకు",
            GulikaiKalam: "13:39 నుండి 15:02 వరకు",
            Karanamulu: "కౌలవ 17:07 వరకు, తైతిల 06:30, డిసెంబర్ 24 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "ఉత్తర 09:08 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "08:06 నుండి 09:29 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:43",
            Sunset: "17:48",
            Thiti: "అష్టమి 17:08 వరకు",
            Yamaganda: "10:52 నుండి 12:15 వరకు",
            Yoga: "సౌభాగ్య 19:55 వరకు",
            Varjyam: "18:38 నుండి 20:27 వరకు",
            Moonrise: "00:54, డిసెంబర్ 24",
            Moonset: "12:24",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:54 నుండి 12:38 వరకు",
            Amrutakalam: "ఏమిలేదు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య 01:51, డిసెంబర్ 25 వరకు",
            Date: "మంగళవారము, డిసెంబర్ 24, 2024",
            DurMuhurtamulu: "08:56 నుండి 09:41 వరకు",
            GulikaiKalam: "12:16 నుండి 13:39 వరకు",
            Karanamulu: "బవ 19:52 వరకు, వణిజ నిండా రాత్రి వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "హస్త 12:16 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:02 నుండి 16:26 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:43",
            Sunset: "17:49",
            Thiti: "నవమి 19:53 వరకు",
            Yamaganda: "09:30 నుండి 10:53 వరకు",
            Yoga: "శోభన 20:54 వరకు",
            Varjyam: "21:17 నుండి 23:05 వరకు",
            Moonrise: "01:41, డిసెంబర్ 25",
            Moonset: "12:56",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమిలేదు",
            Amrutakalam: "08:09 నుండి 09:57 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల",
            Date: "బుధవారము, డిసెంబర్ 25, 2024",
            DurMuhurtamulu: "11:54 నుండి 12:39 వరకు",
            GulikaiKalam: "10:53 నుండి 12:16 వరకు",
            Karanamulu: "వణిజ 09:12 వరకు, విష్టి 22:29 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "చిత్తా 15:21 వరకు",
            MainNakshatra: "చిత్త",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:16 నుండి 13:40 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:44",
            Sunset: "17:49",
            Thiti: "దశమి 22:30 వరకు",
            Yamaganda: "08:07 నుండి 09:30 వరకు",
            Yoga: "అతిగణ్డ 21:47 వరకు",
            Varjyam: "21:37 నుండి 23:24 వరకు",
            Moonrise: "02:30, డిసెంబర్ 26",
            Moonset: "13:29",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "25",
            LordImage: require("../images/holidays/11-1)Christmas.png"),
          },
          {
            Abhijit: "11:55 నుండి 12:39 వరకు",
            Amrutakalam: "08:20 నుండి 10:07 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల",
            Date: "గురువారము, డిసెంబర్ 26, 2024",
            DurMuhurtamulu: "10:26 నుండి 11:10 వరకు, 14:52 నుండి 15:37 వరకు",
            GulikaiKalam: "09:31 నుండి 10:54 వరకు",
            Karanamulu: "బవ 11:40 వరకు, బాలవ 00:43, డిసెంబర్ 27 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "స్వాతి 18:09 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:40 నుండి 15:03 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:44",
            Sunset: "17:50",
            Thiti: "ఏకాదశి 00:44 వరకు",
            Yamaganda: "06:44 నుండి 08:07 వరకు",
            Yoga: "సుకర్మా 22:24 వరకు",
            Varjyam: "00:18 నుండి 02:03 వరకు",
            Moonrise: "03:21, డిసెంబర్ 27",
            Moonset: "14:05",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:55 నుండి 12:40 వరకు",
            Amrutakalam: "10:49 నుండి 12:35 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల 13:57 వరకు",
            Date: "శుక్రవారము, డిసెంబర్ 27, 2024",
            DurMuhurtamulu: "08:58 నుండి 09:42 వరకు, 12:40 నుండి 13:24 వరకు",
            GulikaiKalam: "08:08 నుండి 09:31 వరకు",
            Karanamulu: "కౌలవ 13:39 వరకు, తైతిల 02:26, డిసెంబర్ 28 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "విశాఖ 20:28 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:54 నుండి 12:17 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:45",
            Sunset: "17:50",
            Thiti: "ద్వాదశి 02:27 వరకు",
            Yamaganda: "15:04 నుండి 16:27 వరకు",
            Yoga: "ధృతి 22:37 వరకు",
            Varjyam: "00:46 నుండి 02:29 వరకు",
            Moonrise: "04:16, డిసెంబర్ 28",
            Moonset: "14:45",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:40 వరకు",
            Amrutakalam: "11:04 నుండి 12:47 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "శనివారము, డిసెంబర్ 28, 2024",
            DurMuhurtamulu: "06:45 నుండి 07:29 వరకు, 07:29 నుండి 08:14 వరకు",
            GulikaiKalam: "06:45 నుండి 08:08 వరకు",
            Karanamulu: "బవ 15:04 వరకు, వణిజ 03:32, డిసెంబర్ 29 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "అనూరాధ 22:13 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:31 నుండి 10:55 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:45",
            Sunset: "17:51",
            Thiti: "త్రయోదశి 03:33 వరకు",
            Yamaganda: "13:41 నుండి 15:04 వరకు",
            Yoga: "శూల 22:24 వరకు",
            Varjyam: "04:05 నుండి 05:46 వరకు",
            Moonrise: "05:12, డిసెంబర్ 29",
            Moonset: "15:30",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:41 వరకు",
            Amrutakalam: "14:09 నుండి 15:49 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము 23:22 వరకు",
            Date: "ఆదివారము, డిసెంబర్ 29, 2024",
            DurMuhurtamulu: "16:23 నుండి 17:07 వరకు",
            GulikaiKalam: "15:05 నుండి 16:28 వరకు",
            Karanamulu: "విష్టి 15:51 వరకు, శకుని 04:01, డిసెంబర్ 30 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "జ్యేష్ఠ 23:21 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:28 నుండి 17:52 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:45",
            Sunset: "17:52",
            Thiti: "చతుర్దశి 04:02 వరకు",
            Yamaganda: "12:18 నుండి 13:42 వరకు",
            Yoga: "గణ్డ 21:41 వరకు",
            Varjyam: "ఏమిలేదు",
            Moonrise: "06:10, డిసెంబర్ 30",
            Moonset: "16:21",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:41 వరకు",
            Amrutakalam: "17:24 నుండి 19:02 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "సోమవారము, డిసెంబర్ 30, 2024",
            DurMuhurtamulu: "12:41 నుండి 13:26 వరకు, 14:54 నుండి 15:39 వరకు",
            GulikaiKalam: "13:42 నుండి 15:06 వరకు",
            Karanamulu: "చతుష్పాద 16:02 వరకు, నాగ 03:56, డిసెంబర్ 31 వరకు",
            Masam: "మార్గశిర మాసం",
            Nakshatra1: "మూల 11:57 వరకు",
            MainNakshatra: "మూల",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "08:09 నుండి 09:32 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:46",
            Sunset: "17:52",
            Thiti: "అమావాస్య 03:57 వరకు",
            Yamaganda: "10:56 నుండి 12:19 వరకు",
            Yoga: "వృద్ధి 20:32 వరకు",
            Varjyam: "22:17 నుండి 23:55 వరకు",
            Moonrise: "ఏమిలేదు",
            Moonset: "17:17",
            Shaka: "1946 క్రోధి",
            Amavasya: "amavasya",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:42 వరకు",
            Amrutakalam: "19:14 నుండి 20:51 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు 06:01, జనవరి 01 వరకు",
            Date: "మంగళవారము, డిసెంబర్ 31, 2024",
            DurMuhurtamulu: "08:59 నుండి 09:44 వరకు, 23:02 నుండి 23:54 వరకు",
            GulikaiKalam: "12:19 నుండి 13:43 వరకు",
            Karanamulu: "కింస్తుఘ్న 15:42 వరకు, బవ 03:21, జనవరి 01 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "పూర్వాషాఢ 00:03 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:06 నుండి 16:29 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:46",
            Sunset: "17:53",
            Thiti: "పాడ్యమి 03:22 వరకు",
            Yamaganda: "09:33 నుండి 10:56 వరకు",
            Yoga: "ధ్రువ 18:59 వరకు",
            Varjyam: "09:39 నుండి 10:12 వరకు",
            Moonrise: "07:06",
            Moonset: "18:17",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
        ],
      },
    ],
    2025: [
      {
        day: [
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "17:27 నుండి 19:01 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము",
            Date: "బుధవారము, జనవరి 01, 2025",
            DurMuhurtamulu: "11:58 నుండి 12:42 వరకు",
            GulikaiKalam: "10:57 నుండి 12:20 వరకు",
            Karanamulu: "బాలవ 14:55 వరకు, కౌలవ 02:24, జనవరి 02 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "ఉత్తరాషాఢ 23:46 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:20 నుండి 13:43 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:46",
            Sunset: "17:53",
            Thiti: "విదియ 02:24, జనవరి 02 వరకు",
            Yamaganda: "08:10 నుండి 09:33 వరకు",
            Yoga: "వ్యాఘాత 17:07 వరకు",
            Varjyam:
              "07:58 నుండి 09:32 వరకు, 03:40, జనవరి 02 నుండి 05:14, జనవరి 02 వరకు",
            Moonrise: "07:59",
            Moonset: "19:18",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "1",
            LordImage: require("../images/holidays/0-1)NewYear2025.png"),
          },
          {
            Abhijit: "11:58 నుండి 12:43 వరకు",
            Amrutakalam: "13:02 నుండి 14:35 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము",
            Date: "గురువారము, జనవరి 02, 2025",
            DurMuhurtamulu: "10:29 నుండి 11:14 వరకు, 14:56 నుండి 15:40 వరకు",
            GulikaiKalam: "09:34 నుండి 10:57 వరకు",
            Karanamulu: "తైతిల 13:48 వరకు, గర 01:08, జనవరి 03 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "శ్రవణం 23:10 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:44 నుండి 15:07 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:47",
            Sunset: "17:54",
            Thiti: "తదియ 01:08, జనవరి 03 వరకు",
            Yamaganda: "06:47 నుండి 08:10 వరకు",
            Yoga: "హర్షణ 14:58 వరకు",
            Varjyam: "03:02, జనవరి 03 నుండి 04:35, జనవరి 03 వరకు",
            Moonrise: "08:48",
            Moonset: "20:19",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:59 నుండి 12:43 వరకు",
            Amrutakalam: "12:19 నుండి 13:51 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము 10:47 వరకు",
            Date: "శుక్రవారము, జనవరి 03, 2025",
            DurMuhurtamulu: "09:01 నుండి 09:45 వరకు, 12:43 నుండి 13:28 వరకు",
            GulikaiKalam: "08:11 నుండి 09:34 వరకు",
            Karanamulu: "వణిజ 12:25 వరకు, విష్టి 23:39 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "ధనిష్ఠ 22:22 వరకు",
            MainNakshatra: "ధనిష్ఠ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:57 నుండి 12:21 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:47",
            Sunset: "17:55",
            Thiti: "చవితి 23:39 వరకు",
            Yamaganda: "15:08 నుండి 16:31 వరకు",
            Yoga: "వజ్ర 12:38 వరకు",
            Varjyam: "05:16, జనవరి 04 నుండి 06:48, జనవరి 04 వరకు",
            Moonrise: "09:33",
            Moonset: "21:18",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:59 నుండి 12:44 వరకు",
            Amrutakalam: "14:29 నుండి 16:01 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము",
            Date: "శనివారము, జనవరి 04, 2025",
            DurMuhurtamulu: "06:47 నుండి 07:32 వరకు, 07:32 నుండి 08:16 వరకు",
            GulikaiKalam: "06:47 నుండి 08:11 వరకు",
            Karanamulu: "బవ 10:51 వరకు, బాలవ 22:00 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "శతభిషం 21:23 వరకు",
            MainNakshatra: "శతభిషం",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:34 నుండి 10:58 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:47",
            Sunset: "17:55",
            Thiti: "పంచమి 22:00 వరకు",
            Yamaganda: "13:45 నుండి 15:08 వరకు",
            Yoga: "సిద్ధి 10:08 వరకు",
            Varjyam: "03:30, జనవరి 05 నుండి 05:01, జనవరి 05 వరకు",
            Moonrise: "10:14",
            Moonset: "22:15",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:00 నుండి 12:44 వరకు",
            Amrutakalam: "12:39 నుండి 14:11 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము 14:35 వరకు",
            Date: "ఆదివారము, జనవరి 05, 2025",
            DurMuhurtamulu: "16:27 నుండి 17:11 వరకు",
            GulikaiKalam: "15:09 నుండి 16:32 వరకు",
            Karanamulu: "కౌలవ 09:08 వరకు, తైతిల 20:15 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "పూర్వాభాద్ర 20:18 వరకు",
            MainNakshatra: "పూర్వాభాద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "16:32 నుండి 17:56 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:48",
            Sunset: "17:56",
            Thiti: "షష్టి 20:15 వరకు",
            Yamaganda: "12:22 నుండి 13:45 వరకు",
            Yoga: "వ్యతీపాత 07:32 వరకు",
            Varjyam: "05:25, జనవరి 06 నుండి 06:56, జనవరి 06 వరకు",
            Moonrise: "10:53",
            Moonset: "23:11",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:00 నుండి 12:44 వరకు",
            Amrutakalam: "14:32 నుండి 16:04 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం",
            Date: "సోమవారము, జనవరి 06, 2025",
            DurMuhurtamulu: "12:44 నుండి 13:29 వరకు, 14:58 నుండి 15:43 వరకు",
            GulikaiKalam: "13:46 నుండి 15:09 వరకు",
            Karanamulu: "గర 07:20 వరకు, వణిజ 18:23 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "ఉత్తరాభాద్ర 19:06 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "08:12 నుండి 09:35 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:48",
            Sunset: "17:56",
            Thiti: "సప్తమి 18:23 వరకు",
            Yamaganda: "10:59 నుండి 12:22 వరకు",
            Yoga: "పరిఘ 02:05, జనవరి 07 వరకు",
            Varjyam: "06:28, జనవరి 07 నుండి 07:59, జనవరి 07 వరకు",
            Moonrise: "11:31",
            Moonset: "00:07, జనవరి 07",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:00 నుండి 12:45 వరకు",
            Amrutakalam: "15:33 నుండి 17:04 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం 17:50 వరకు",
            Date: "మంగళవారము, జనవరి 07, 2025",
            DurMuhurtamulu: "09:02 నుండి 09:47 వరకు, 23:06 నుండి 23:57 వరకు",
            GulikaiKalam: "12:23 నుండి 13:46 వరకు",
            Karanamulu: "బవ 16:26 వరకు, బాలవ 03:26, జనవరి 08 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "రేవతి 17:50 వరకు",
            MainNakshatra: "రేవతి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:10 నుండి 16:33 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:48",
            Sunset: "17:57",
            Thiti: "అష్టమి 16:26 వరకు",
            Yamaganda: "09:35 నుండి 10:59 వరకు",
            Yoga: "శివ 23:16 వరకు",
            Varjyam: "ఏమి లేదు",
            Moonrise: "12:10",
            Moonset: "01:05, జనవరి 08",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "09:41 నుండి 11:12 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం",
            Date: "బుధవారము, జనవరి 08, 2025",
            DurMuhurtamulu: "12:01 నుండి 12:45 వరకు",
            GulikaiKalam: "10:59 నుండి 12:23 వరకు",
            Karanamulu: "కౌలవ 14:25 వరకు, తైతిల 01:24, జనవరి 09 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "అశ్విని 16:29 వరకు",
            MainNakshatra: "అశ్విని",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:23 నుండి 13:47 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:48",
            Sunset: "17:58",
            Thiti: "నవమి 14:25 వరకు",
            Yamaganda: "08:12 నుండి 09:36 వరకు",
            Yoga: "సిద్ధ 20:23 వరకు",
            Varjyam:
              "12:43 నుండి 14:13 వరకు, 01:32, జనవరి 09 నుండి 03:03, జనవరి 09 వరకు",
            Moonrise: "12:52",
            Moonset: "02:05, జనవరి 09",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:02 నుండి 12:46 వరకు",
            Amrutakalam: "10:35 నుండి 12:06 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం 20:46 వరకు",
            Date: "గురువారము, జనవరి 09, 2025",
            DurMuhurtamulu: "10:32 నుండి 11:16 వరకు, 15:00 నుండి 15:44 వరకు",
            GulikaiKalam: "09:36 నుండి 11:00 వరకు",
            Karanamulu: "బవ 12:22 వరకు, వణిజ 23:20 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "భరణి 15:07 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:47 నుండి 15:11 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:49",
            Sunset: "17:58",
            Thiti: "దశమి 12:22 వరకు",
            Yamaganda: "06:49 నుండి 08:12 వరకు",
            Yoga: "సాధ్య 17:29 వరకు",
            Varjyam: "02:26, జనవరి 10 నుండి 03:57, జనవరి 10 వరకు",
            Moonrise: "13:37",
            Moonset: "03:08, జనవరి 10",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:02 నుండి 12:46 వరకు",
            Amrutakalam: "11:29 నుండి 13:00 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృషభం",
            Date: "శుక్రవారము, జనవరి 10, 2025",
            DurMuhurtamulu: "09:03 నుండి 09:48 వరకు, 12:46 నుండి 13:31 వరకు",
            GulikaiKalam: "08:13 నుండి 09:36 వరకు",
            Karanamulu: "విష్టి 10:19 వరకు, బవ 21:19 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "కృత్తిక 13:45 వరకు",
            MainNakshatra: "కృత్తిక",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "11:00 నుండి 12:24 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:49",
            Sunset: "17:59",
            Thiti: "ఏకాదశి 10:19 వరకు",
            Yamaganda: "15:11 నుండి 16:35 వరకు",
            Yoga: "శుభ 14:37 వరకు",
            Varjyam: "04:54, జనవరి 11 నుండి 06:25, జనవరి 11 వరకు",
            Moonrise: "14:28",
            Moonset: "04:12, జనవరి 11",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:02 నుండి 12:47 వరకు",
            Amrutakalam: "09:27 నుండి 10:58 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృషభం 23:55 వరకు",
            Date: "శనివారము, జనవరి 11, 2025",
            DurMuhurtamulu: "06:49 నుండి 07:34 వరకు, 07:34 నుండి 08:18 వరకు",
            GulikaiKalam: "06:49 నుండి 08:13 వరకు",
            Karanamulu: "బాలవ 08:21 వరకు, కౌలవ 19:25 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "రోహిణి 12:29 వరకు",
            MainNakshatra: "రోహిణి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:37 నుండి 11:00 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:49",
            Sunset: "17:59",
            Thiti: "ద్వాదశి 08:21 వరకు",
            Yamaganda: "13:48 నుండి 15:12 వరకు",
            Yoga: "శుక్ల 11:49 వరకు",
            Varjyam: "17:50 నుండి 19:22 వరకు",
            Moonrise: "15:25",
            Moonset: "05:16, జనవరి 12",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:02 నుండి 12:47 వరకు",
            Amrutakalam: "00:57, జనవరి 13 నుండి 02:30, జనవరి 13 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం",
            Date: "ఆదివారము, జనవరి 12, 2025",
            DurMuhurtamulu: "16:31 నుండి 17:15 వరకు",
            GulikaiKalam: "15:12 నుండి 16:36 వరకు",
            Karanamulu: "బవ 17:45 వరకు, వణిజ 05:03, జనవరి 13 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "మృగశిర 11:24 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "16:36 నుండి 18:00 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:49",
            Sunset: "18:00",
            Thiti: "చతుర్దశి 05:03, జనవరి 13 వరకు",
            Yamaganda: "12:25 నుండి 13:48 వరకు",
            Yoga: "బ్రహ్మ 09:09 వరకు",
            Varjyam: "19:32 నుండి 21:05 వరకు",
            Moonrise: "16:26",
            Moonset: "06:16, జనవరి 13",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:03 నుండి 12:47 వరకు",
            Amrutakalam: "ఏమి లేదు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిథునం 04:19, జనవరి 14 వరకు",
            Date: "సోమవారము, జనవరి 13, 2025",
            DurMuhurtamulu: "12:47 నుండి 13:32 వరకు, 15:02 నుండి 15:46 వరకు",
            GulikaiKalam: "13:49 నుండి 15:13 వరకు",
            Karanamulu: "విష్టి 16:26 వరకు, బవ 03:56, జనవరి 14 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "అర్ద్ర 10:38 వరకు",
            MainNakshatra: "అర్ద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "08:13 నుండి 09:37 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:49",
            Sunset: "18:01",
            Thiti: "పూర్ణిమ 03:56, జనవరి 14 వరకు",
            Yamaganda: "11:01 నుండి 12:25 వరకు",
            Yoga: "వైధృతి 04:39, జనవరి 14 వరకు",
            Varjyam: "22:27 నుండి 00:02, జనవరి 14",
            Moonrise: "17:28",
            Moonset: "చంద్రాస్తమయం లేదు",
            Shaka: "1946 క్రోధి",
            Amavasya: "pournami",
            FestDate: "13",
            LordImage: require("../images/holidays/0-3)Bhogi.png"),
          },
          {
            Abhijit: "12:03 నుండి 12:48 వరకు",
            Amrutakalam:
              "07:55 నుండి 09:29 వరకు, 04:01, జనవరి 15 నుండి 05:38, జనవరి 15 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "మంగళవారము, జనవరి 14, 2025",
            DurMuhurtamulu: "09:04 నుండి 09:49 వరకు, 23:09 నుండి 24:00 వరకు",
            GulikaiKalam: "12:25 నుండి 13:49 వరకు",
            Karanamulu: "బాలవ 15:34 వరకు, కౌలవ 03:21, జనవరి 15 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "పునర్వసు 10:17 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:13 నుండి 16:37 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:49",
            Sunset: "18:01",
            Thiti: "పాడ్యమి 03:21, జనవరి 15 వరకు",
            Yamaganda: "09:37 నుండి 11:01 వరకు",
            Yoga: "విశ్కంభ 02:58, జనవరి 15 వరకు",
            Varjyam: "18:20 నుండి 19:57 వరకు",
            Moonrise: "18:29",
            Moonset: "07:10",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "14",
            LordImage: require("../images/holidays/0-4)Sankranthi.png"),
          },
          {
            Abhijit: "12:04 నుండి 12:48 వరకు",
            Amrutakalam: "23:42 నుండి 01:21, జనవరి 16 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "బుధవారము, జనవరి 15, 2025",
            DurMuhurtamulu: "12:03 నుండి 12:48 వరకు",
            GulikaiKalam: "11:02 నుండి 12:26 వరకు",
            Karanamulu: "తైతిల 15:17 వరకు, బవ 03:23, జనవరి 16 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "పుష్యమి 10:28 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:26 నుండి 13:50 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:50",
            Sunset: "18:02",
            Thiti: "విదియ 03:23, జనవరి 16 వరకు",
            Yamaganda: "08:14 నుండి 09:38 వరకు",
            Yoga: "ప్రీతి 01:47, జనవరి 16 వరకు",
            Varjyam: "23:42 నుండి 01:21, జనవరి 16 వరకు",
            Moonrise: "19:27",
            Moonset: "07:58",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "15",
            LordImage: require("../images/holidays/0-5)Kanuma.png"),
          },
          {
            Abhijit: "12:04 నుండి 12:49 వరకు",
            Amrutakalam: "09:37 నుండి 11:16 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం 11:16 వరకు",
            Date: "గురువారము, జనవరి 16, 2025",
            DurMuhurtamulu: "10:34 నుండి 11:19 వరకు, 15:03 నుండి 15:48 వరకు",
            GulikaiKalam: "09:38 నుండి 11:02 వరకు",
            Karanamulu: "వణిజ 15:39 వరకు, విష్టి 04:06, జనవరి 17 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "అశ్లేష 11:16 వరకు",
            MainNakshatra: "అశ్లేష",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:50 నుండి 15:14 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:50",
            Sunset: "18:02",
            Thiti: "తదియ 04:06, జనవరి 17 వరకు",
            Yamaganda: "06:50 నుండి 08:14 వరకు",
            Yoga: "ఆయుష్మాన్ 01:06, జనవరి 17 వరకు",
            Varjyam: "00:00, జనవరి 17 నుండి 01:42, జనవరి 17 వరకు",
            Moonrise: "20:21",
            Moonset: "08:39",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:04 నుండి 12:49 వరకు",
            Amrutakalam: "10:12 నుండి 11:54 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం",
            Date: "శుక్రవారము, జనవరి 17, 2025",
            DurMuhurtamulu: "09:04 నుండి 09:49 వరకు, 12:49 నుండి 13:34 వరకు",
            GulikaiKalam: "08:14 నుండి 09:38 వరకు",
            Karanamulu: "బవ 16:43 వరకు, బాలవ 05:30, జనవరి 18 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "మఖ 12:45 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "11:02 నుండి 12:26 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:50",
            Sunset: "18:03",
            Thiti: "చతుర్థి 05:30, జనవరి 18 వరకు",
            Yamaganda: "15:15 నుండి 16:39 వరకు",
            Yoga: "సౌభాగ్య 00:57, జనవరి 18 వరకు",
            Varjyam: "21:27 నుండి 23:11 వరకు",
            Moonrise: "21:12",
            Moonset: "09:16",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:04 నుండి 12:49 వరకు",
            Amrutakalam: "07:53 నుండి 09:38 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం 21:28 వరకు",
            Date: "శనివారము, జనవరి 18, 2025",
            DurMuhurtamulu: "06:50 నుండి 07:35 వరకు, 07:35 నుండి 08:20 వరకు",
            GulikaiKalam: "06:50 నుండి 08:14 వరకు",
            Karanamulu: "కౌలవ 18:26 వరకు, తైతిల నిండా రాత్రి వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "పుబ్బ 14:51 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:38 నుండి 11:02 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:50",
            Sunset: "18:04",
            Thiti: "పంచమి నిండా రాత్రి వరకు",
            Yamaganda: "13:51 నుండి 15:15 వరకు",
            Yoga: "శోభన 01:16, జనవరి 19 వరకు",
            Varjyam: "22:51 నుండి 00:37, జనవరి 19 వరకు",
            Moonrise: "22:00",
            Moonset: "09:50",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:05 నుండి 12:50 వరకు",
            Amrutakalam: "09:30 నుండి 11:17 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య",
            Date: "ఆదివారము, జనవరి 19, 2025",
            DurMuhurtamulu: "16:34 నుండి 17:19 వరకు",
            GulikaiKalam: "15:16 నుండి 16:40 వరకు",
            Karanamulu: "తైతిల 07:30 వరకు, బవ 20:41 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "ఉత్తర 17:30 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:40 నుండి 18:04 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:50",
            Sunset: "18:04",
            Thiti: "పంచమి 07:30 వరకు",
            Yamaganda: "12:27 నుండి 13:51 వరకు",
            Yoga: "అతిగణ్డ 01:58, జనవరి 20 వరకు",
            Varjyam: "02:57 నుండి 04:45, జనవరి 20",
            Moonrise: "22:47",
            Moonset: "10:23",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:05 నుండి 12:50 వరకు",
            Amrutakalam: "13:45 నుండి 15:33 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య",
            Date: "సోమవారము, జనవరి 20, 2025",
            DurMuhurtamulu: "12:50 నుండి 13:35 వరకు, 15:05 నుండి 15:50 వరకు",
            GulikaiKalam: "13:52 నుండి 15:16 వరకు",
            Karanamulu: "వణిజ 09:58 వరకు, విష్టి 23:18 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "హస్త 20:30 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "08:14 నుండి 09:39 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:50",
            Sunset: "18:05",
            Thiti: "షష్టి 09:58 వరకు",
            Yamaganda: "11:03 నుండి 12:27 వరకు",
            Yoga: "సుకర్మా 02:53, జనవరి 21 వరకు",
            Varjyam: "05:32 నుండి 07:21, జనవరి 21",
            Moonrise: "23:34",
            Moonset: "10:54",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:05 నుండి 12:50 వరకు",
            Amrutakalam: "16:23 నుండి 18:11 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య 10:03 వరకు",
            Date: "మంగళవారము, జనవరి 21, 2025",
            DurMuhurtamulu:
              "09:05 నుండి 09:50 వరకు, 23:11 నుండి 00:02, జనవరి 22 వరకు",
            GulikaiKalam: "12:28 నుండి 13:52 వరకు",
            Karanamulu: "బవ 12:39 వరకు, బాలవ 02:00, జనవరి 22 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "చిత్తా 23:36 వరకు",
            MainNakshatra: "చిత్తా",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:17 నుండి 16:41 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:50",
            Sunset: "18:05",
            Thiti: "సప్తమి 12:39 వరకు",
            Yamaganda: "09:39 నుండి 11:03 వరకు",
            Yoga: "ధృతి 03:50, జనవరి 22 వరకు",
            Varjyam: "05:54 నుండి 07:42 వరకు",
            Moonrise: "00:22, జనవరి 22",
            Moonset: "11:27",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "16:41 నుండి 18:29 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల",
            Date: "బుధవారము, జనవరి 22, 2025",
            DurMuhurtamulu: "12:05 నుండి 12:50 వరకు",
            GulikaiKalam: "11:03 నుండి 12:28 వరకు",
            Karanamulu: "కౌలవ 15:18 వరకు, తైతిల 04:31, జనవరి 23 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "స్వాతి 02:34, జనవరి 23 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:28 నుండి 13:52 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:50",
            Sunset: "18:06",
            Thiti: "అష్టమి 15:18 వరకు",
            Yamaganda: "08:14 నుండి 09:39 వరకు",
            Yoga: "శూల 04:38, జనవరి 23 వరకు",
            Varjyam: "ఏమి లేదు",
            Moonrise: "01:11, జనవరి 23",
            Moonset: "12:01",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:06 నుండి 12:51 వరకు",
            Amrutakalam: "19:24 నుండి 21:10 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల 22:32 వరకు",
            Date: "గురువారము, జనవరి 23, 2025",
            DurMuhurtamulu: "10:35 నుండి 11:20 వరకు, 15:06 నుండి 15:51 వరకు",
            GulikaiKalam: "09:39 నుండి 11:04 వరకు",
            Karanamulu: "బవ 17:37 వరకు, వణిజ 06:36, జనవరి 24 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "విశాఖ 05:08, జనవరి 24 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:53 నుండి 15:17 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:50",
            Sunset: "18:07",
            Thiti: "నవమి 17:37 వరకు",
            Yamaganda: "06:50 నుండి 08:14 వరకు",
            Yoga: "గణ్డ 05:07, జనవరి 24 వరకు",
            Varjyam: "08:46 నుండి 10:32 వరకు",
            Moonrise: "02:04, జనవరి 24",
            Moonset: "12:39",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:06 నుండి 12:51 వరకు",
            Amrutakalam: "19:52 నుండి 21:36 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "శుక్రవారము, జనవరి 24, 2025",
            DurMuhurtamulu: "09:05 నుండి 09:50 వరకు, 12:51 నుండి 13:36 వరకు",
            GulikaiKalam: "08:14 నుండి 09:39 వరకు",
            Karanamulu: "విష్టి 19:25 వరకు, బవ నిండా రాత్రి వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "అనూరాధ నిండా రాత్రి వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "11:04 నుండి 12:28 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:50",
            Sunset: "18:07",
            Thiti: "దశమి 19:25 వరకు",
            Yamaganda: "15:18 నుండి 16:43 వరకు",
            Yoga: "వృద్ధి 05:09, జనవరి 25 వరకు",
            Varjyam: "09:28 నుండి 11:12 వరకు",
            Moonrise: "02:59, జనవరి 25",
            Moonset: "13:22",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:06 నుండి 12:51 వరకు",
            Amrutakalam: "23:09 నుండి 00:50, జనవరి 26 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "శనివారము, జనవరి 25, 2025",
            DurMuhurtamulu: "06:49 నుండి 07:35 వరకు, 07:35 నుండి 08:20 వరకు",
            GulikaiKalam: "06:49 నుండి 08:14 వరకు",
            Karanamulu: "బవ 08:03 వరకు, బాలవ 20:31 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "అనూరాధ 07:07 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:39 నుండి 11:04 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:49",
            Sunset: "18:08",
            Thiti: "ఏకాదశి 20:31 వరకు",
            Yamaganda: "13:53 నుండి 15:18 వరకు",
            Yoga: "ధ్రువ 04:38, జనవరి 26 వరకు",
            Varjyam: "13:02 నుండి 14:43 వరకు",
            Moonrise: "03:56, జనవరి 26",
            Moonset: "14:09",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:06 నుండి 12:51 వరకు",
            Amrutakalam: "02:28, జనవరి 27 నుండి 04:07, జనవరి 27 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము 08:26 వరకు",
            Date: "ఆదివారము, జనవరి 26, 2025",
            DurMuhurtamulu: "16:38 నుండి 17:23 వరకు",
            GulikaiKalam: "15:19 నుండి 16:43 వరకు",
            Karanamulu: "కౌలవ 08:48 వరకు, తైతిల 20:54 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "జ్యేష్ఠ 08:26 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:43 నుండి 18:08 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:49",
            Sunset: "18:08",
            Thiti: "ద్వాదశి 20:54 వరకు",
            Yamaganda: "12:29 నుండి 13:54 వరకు",
            Yoga: "వ్యాఘాత 03:34, జనవరి 27 వరకు",
            Varjyam: "16:38 నుండి 18:16 వరకు",
            Moonrise: "04:53, జనవరి 27",
            Moonset: "15:03",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "26",
            LordImage: require("../images/holidays/0-8)Independenceday_Republic.png"),
          },
          {
            Abhijit: "12:06 నుండి 12:52 వరకు",
            Amrutakalam: "04:11, జనవరి 28 నుండి 05:47, జనవరి 28 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "సోమవారము, జనవరి 27, 2025",
            DurMuhurtamulu: "12:52 నుండి 13:37 వరకు, 15:08 నుండి 15:53 వరకు",
            GulikaiKalam: "13:54 నుండి 15:19 వరకు",
            Karanamulu: "బవ 08:49 వరకు, వణిజ 20:34 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "మూల 09:02 వరకు",
            MainNakshatra: "మూల",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "08:14 నుండి 09:39 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:49",
            Sunset: "18:09",
            Thiti: "త్రయోదశి 20:34 వరకు",
            Yamaganda: "11:04 నుండి 12:29 వరకు",
            Yoga: "హర్షణ 01:57, జనవరి 28 వరకు",
            Varjyam: "07:24 నుండి 09:02 వరకు, 18:36 నుండి 20:12 వరకు",
            Moonrise: "05:48, జనవరి 28",
            Moonset: "16:02",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:52 వరకు",
            Amrutakalam: "02:06, జనవరి 29 నుండి 03:40, జనవరి 29 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు 14:52 వరకు",
            Date: "మంగళవారము, జనవరి 28, 2025",
            DurMuhurtamulu:
              "09:05 నుండి 09:50 వరకు, 23:13 నుండి 00:04, జనవరి 29 వరకు",
            GulikaiKalam: "12:29 నుండి 13:54 వరకు",
            Karanamulu: "విష్టి 08:09 వరకు, శకుని 19:35 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "పూర్వాషాఢ 08:58 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:19 నుండి 16:44 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:49",
            Sunset: "18:09",
            Thiti: "చతుర్దశి 19:35 వరకు",
            Yamaganda: "09:39 నుండి 11:04 వరకు",
            Yoga: "వజ్ర 23:52 వరకు",
            Varjyam: "16:46 నుండి 18:19 వరకు",
            Moonrise: "06:40, జనవరి 29",
            Moonset: "17:03",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "21:19 నుండి 22:51 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము",
            Date: "బుధవారము, జనవరి 29, 2025",
            DurMuhurtamulu: "12:07 నుండి 12:52 వరకు",
            GulikaiKalam: "11:04 నుండి 12:29 వరకు",
            Karanamulu:
              "చతుష్పాద 06:54 వరకు, నాగ 18:05 వరకు, కింస్తుఘ్న 05:10, జనవరి 30 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "ఉత్తరాషాఢ 08:20 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:29 నుండి 13:55 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:49",
            Sunset: "18:10",
            Thiti: "అమావాస్య 18:05 వరకు",
            Yamaganda: "08:14 నుండి 09:39 వరకు",
            Yoga: "సిద్ధి 21:22 వరకు",
            Varjyam: "12:09 నుండి 13:41 వరకు",
            Moonrise: "చంద్రోదయం లేదు",
            Moonset: "18:05",
            Shaka: "1946 క్రోధి",
            Amavasya: "amavasya",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:52 వరకు",
            Amrutakalam: "20:03 నుండి 21:33 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము 18:35 వరకు",
            Date: "గురువారము, జనవరి 30, 2025",
            DurMuhurtamulu: "10:36 నుండి 11:21 వరకు, 15:09 నుండి 15:54 వరకు",
            GulikaiKalam: "09:39 నుండి 11:04 వరకు",
            Karanamulu: "బవ 16:10 వరకు, బాలవ 03:06, జనవరి 31 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "శ్రవణం 07:15 వరకు, ధనిష్ఠ 05:50, జనవరి 31 వరకు",
            MainNakshatra: "శ్రవణం, ధనిష్ఠ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:55 నుండి 15:20 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:49",
            Sunset: "18:11",
            Thiti: "పాడ్యమి 16:10 వరకు",
            Yamaganda: "06:49 నుండి 08:14 వరకు",
            Yoga: "వ్యతీపాత 18:33 వరకు",
            Varjyam: "11:01 నుండి 12:31 వరకు",
            Moonrise: "07:27",
            Moonset: "19:07",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:52 వరకు",
            Amrutakalam: "21:31 నుండి 23:01 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము",
            Date: "శుక్రవారము, జనవరి 31, 2025",
            DurMuhurtamulu: "09:05 నుండి 09:50 వరకు, 12:52 నుండి 13:38 వరకు",
            GulikaiKalam: "08:14 నుండి 09:39 వరకు",
            Karanamulu: "కౌలవ 13:59 వరకు, తైతిల 00:49, ఫిబ్రవరి 01 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "శతభిషం 04:14, ఫిబ్రవరి 01 వరకు",
            MainNakshatra: "శతభిషం",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "11:04 నుండి 12:30 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:48",
            Sunset: "18:11",
            Thiti: "విదియ 13:59 వరకు",
            Yamaganda: "15:20 నుండి 16:46 వరకు",
            Yoga: "వారీయన 15:33 వరకు",
            Varjyam: "12:33 నుండి 14:03 వరకు",
            Moonrise: "08:10",
            Moonset: "20:06",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
        ],
      },
      {
        day: [
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "19:06 నుండి 20:36 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము 20:58 వరకు",
            Date: "శనివారము, ఫిబ్రవరి 01, 2025",
            DurMuhurtamulu: "06:48 నుండి 07:34 వరకు, 07:34 నుండి 08:19 వరకు",
            GulikaiKalam: "06:48 నుండి 08:14 వరకు",
            Karanamulu: "గర 11:38 వరకు, వణిజ 22:26 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "పూర్వాభాద్ర 02:33, ఫిబ్రవరి 02 వరకు",
            MainNakshatra: "పూర్వాభాద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:39 నుండి 11:04 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:48",
            Sunset: "18:12",
            Thiti: "తదియ 11:38 వరకు",
            Yamaganda: "13:55 నుండి 15:21 వరకు",
            Yoga: "పరిఘ 12:25 వరకు",
            Varjyam: "10:11 నుండి 11:40 వరకు",
            Moonrise: "08:51",
            Moonset: "21:04",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "20:24 నుండి 21:53 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనము",
            Date: "ఆదివారము, ఫిబ్రవరి 02, 2025",
            DurMuhurtamulu: "16:41 నుండి 17:26 వరకు",
            GulikaiKalam: "15:21 నుండి 16:47 వరకు",
            Karanamulu: "విష్టి 09:14 వరకు, బవ 20:02 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "ఉత్తరాభాద్ర 00:52, ఫిబ్రవరి 03 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "16:47 నుండి 18:12 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:48",
            Sunset: "18:12",
            Thiti: "చవితి 09:14 వరకు",
            Yamaganda: "12:30 నుండి 13:56 వరకు",
            Yoga: "శివ 09:14 వరకు, సిద్ధ 06:06, ఫిబ్రవరి 03 వరకు",
            Varjyam: "11:28 నుండి 12:58 వరకు",
            Moonrise: "09:30",
            Moonset: "22:02",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "21:02 నుండి 22:32 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం 23:16 వరకు",
            Date: "సోమవారము, ఫిబ్రవరి 03, 2025",
            DurMuhurtamulu: "12:53 నుండి 13:39 వరకు, 15:10 నుండి 15:56 వరకు",
            GulikaiKalam: "13:56 నుండి 15:21 వరకు",
            Karanamulu: "బాలవ 06:52 వరకు, కౌలవ 17:43 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "రేవతి 23:16 వరకు",
            MainNakshatra: "రేవతి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "08:13 నుండి 09:39 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:48",
            Sunset: "18:13",
            Thiti: "పంచమి 06:52 వరకు, షష్టి 04:37, ఫిబ్రవరి 04 వరకు",
            Yamaganda: "11:04 నుండి 12:30 వరకు",
            Yoga: "సాధ్య 03:03, ఫిబ్రవరి 04 వరకు",
            Varjyam: "12:04 నుండి 13:34 వరకు",
            Moonrise: "10:10",
            Moonset: "23:00",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "15:03 నుండి 16:34 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషము",
            Date: "మంగళవారము, ఫిబ్రవరి 04, 2025",
            DurMuhurtamulu:
              "09:04 నుండి 09:50 వరకు, 23:15 నుండి 00:05, ఫిబ్రవరి 05 వరకు",
            GulikaiKalam: "12:30 నుండి 13:56 వరకు",
            Karanamulu: "గర 15:32 వరకు, వణిజ 02:30, ఫిబ్రవరి 05 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "అశ్విని 21:49 వరకు",
            MainNakshatra: "అశ్విని",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:22 నుండి 16:47 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:47",
            Sunset: "18:13",
            Thiti: "సప్తమి 02:30, ఫిబ్రవరి 05 వరకు",
            Yamaganda: "09:39 నుండి 11:04 వరకు",
            Yoga: "శుభ 00:06, ఫిబ్రవరి 05 వరకు",
            Varjyam: "18:04 నుండి 19:34 వరకు",
            Moonrise: "10:51",
            Moonset: "24:00",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "16:00 నుండి 17:31 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం 02:16, ఫిబ్రవరి 06 వరకు",
            Date: "బుధవారము, ఫిబ్రవరి 05, 2025",
            DurMuhurtamulu: "12:07 నుండి 12:53 వరకు",
            GulikaiKalam: "11:04 నుండి 12:30 వరకు",
            Karanamulu: "విష్టి 13:31 వరకు, బవ 00:35, ఫిబ్రవరి 06 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "భరణి 20:33 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:30 నుండి 13:56 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:47",
            Sunset: "18:14",
            Thiti: "అష్టమి 00:35, ఫిబ్రవరి 06 వరకు",
            Yamaganda: "08:13 నుండి 09:39 వరకు",
            Yoga: "శుక్ల 21:19 వరకు",
            Varjyam: "06:55 నుండి 08:26 వరకు",
            Moonrise: "11:35",
            Moonset: "01:01, ఫిబ్రవరి 06",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "17:12 నుండి 18:43 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం",
            Date: "గురువారము, ఫిబ్రవరి 06, 2025",
            DurMuhurtamulu: "10:36 నుండి 11:22 వరకు, 15:11 నుండి 15:57 వరకు",
            GulikaiKalam: "09:39 నుండి 11:04 వరకు",
            Karanamulu: "బాలవ 11:42 వరకు, కౌలవ 22:53 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "కృత్తిక 19:29 వరకు",
            MainNakshatra: "కృత్తిక",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:56 నుండి 15:22 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:47",
            Sunset: "18:14",
            Thiti: "నవమి 22:53 వరకు",
            Yamaganda: "06:47 నుండి 08:13 వరకు",
            Yoga: "బ్రహ్మ 18:42 వరకు",
            Varjyam: "08:01 నుండి 09:33 వరకు",
            Moonrise: "12:24",
            Moonset: "02:05, ఫిబ్రవరి 07",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "15:34 నుండి 17:07 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం 06:21, ఫిబ్రవరి 8 వరకు",
            Date: "శుక్రవారము, ఫిబ్రవరి 07, 2025",
            DurMuhurtamulu: "09:04 నుండి 09:50 వరకు, 12:53 నుండి 13:39 వరకు",
            GulikaiKalam: "08:12 నుండి 09:38 వరకు",
            Karanamulu: "తైతిల 10:07 వరకు, బవ 21:26 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "రోహిణి 18:40 వరకు",
            MainNakshatra: "రోహిణి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "11:04 నుండి 12:30 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:46",
            Sunset: "18:15",
            Thiti: "దశమి 21:26 వరకు",
            Yamaganda: "15:22 నుండి 16:49 వరకు",
            Yoga: "ఐన్ద్ర 16:17 వరకు",
            Varjyam:
              "10:56 నుండి 12:29 వరకు, 00:08, ఫిబ్రవరి 8 నుండి 01:42, ఫిబ్రవరి 8 వరకు",
            Moonrise: "13:18",
            Moonset: "03:08, ఫిబ్రవరి 8",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "09:31 నుండి 11:05 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం",
            Date: "శనివారము, ఫిబ్రవరి 08, 2025",
            DurMuhurtamulu: "06:46 నుండి 07:32 వరకు, 07:32 నుండి 08:18 వరకు",
            GulikaiKalam: "06:46 నుండి 08:12 వరకు",
            Karanamulu: "వణిజ 08:48 వరకు, విష్టి 20:15 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "మృగశిర 18:07 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:38 నుండి 11:04 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:46",
            Sunset: "18:15",
            Thiti: "ఏకాదశి 20:15 వరకు",
            Yamaganda: "13:57 నుండి 15:23 వరకు",
            Yoga: "వైధృతి 14:04 వరకు",
            Varjyam: "02:26, ఫిబ్రవరి 9 నుండి 04:01, ఫిబ్రవరి 9 వరకు",
            Moonrise: "14:16",
            Moonset: "04:08, ఫిబ్రవరి 9",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "07:58 నుండి 09:34 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం",
            Date: "ఆదివారము, ఫిబ్రవరి 09, 2025",
            DurMuhurtamulu: "16:43 నుండి 17:29 వరకు",
            GulikaiKalam: "15:23 నుండి 16:49 వరకు",
            Karanamulu: "బవ 07:47 వరకు, బాలవ 19:25 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "ఆరుద్ర 17:53 వరకు",
            MainNakshatra: "ఆరుద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "16:49 నుండి 18:15 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:46",
            Sunset: "18:15",
            Thiti: "ద్వాదశి 19:25 వరకు",
            Yamaganda: "12:30 నుండి 13:57 వరకు",
            Yoga: "విష్కుమ్భ 12:07 వరకు",
            Varjyam: "05:57, ఫిబ్రవరి 10 నుండి 07:33, ఫిబ్రవరి 10 వరకు",
            Moonrise: "15:17",
            Moonset: "05:03, ఫిబ్రవరి 10",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:54 వరకు",
            Amrutakalam: "15:36 నుండి 17:12 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం 11:56 వరకు",
            Date: "సోమవారము, ఫిబ్రవరి 10, 2025",
            DurMuhurtamulu: "12:54 నుండి 13:40 వరకు, 15:12 నుండి 15:58 వరకు",
            GulikaiKalam: "13:57 నుండి 15:23 వరకు",
            Karanamulu: "కౌలవ 07:08 వరకు, తైతిల 18:57 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "పునర్వసు 18:01 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "08:11 నుండి 09:38 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:45",
            Sunset: "18:16",
            Thiti: "త్రయోదశి 18:57 వరకు",
            Yamaganda: "11:04 నుండి 12:31 వరకు",
            Yoga: "ప్రీతి 10:27 వరకు",
            Varjyam: "02:12, ఫిబ్రవరి 11 నుండి 03:50, ఫిబ్రవరి 11 వరకు",
            Moonrise: "16:17",
            Moonset: "05:52, ఫిబ్రవరి 11",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:54 వరకు",
            Amrutakalam: "12:01 నుండి 13:39 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "మంగళవారము, ఫిబ్రవరి 11, 2025",
            DurMuhurtamulu:
              "09:03 నుండి 09:49 వరకు, 23:15 నుండి 00:05, ఫిబ్రవరి 12 వరకు",
            GulikaiKalam: "12:31 నుండి 13:57 వరకు",
            Karanamulu: "బవ 06:52 వరకు, వణిజ 18:55 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "పుష్యమి 18:34 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:23 నుండి 16:50 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:45",
            Sunset: "18:16",
            Thiti: "చతుర్దశి 18:55 వరకు",
            Yamaganda: "09:38 నుండి 11:04 వరకు",
            Yoga: "ఆయుష్మాన్ 09:06 వరకు",
            Varjyam: "ఏమి లేదు",
            Moonrise: "17:16",
            Moonset: "06:35, ఫిబ్రవరి 12",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "17:55 నుండి 19:35 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం 19:35 వరకు",
            Date: "బుధవారము, ఫిబ్రవరి 12, 2025",
            DurMuhurtamulu: "12:07 నుండి 12:54 వరకు",
            GulikaiKalam: "11:04 నుండి 12:30 వరకు",
            Karanamulu: "విష్టి 07:05 వరకు, బవ 19:22 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "ఆశ్లేష 19:35 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:30 నుండి 13:57 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:44",
            Sunset: "18:17",
            Thiti: "పౌర్ణమి 19:22 వరకు",
            Yamaganda: "08:11 నుండి 09:37 వరకు",
            Yoga: "సౌభాగ్య 08:07 వరకు",
            Varjyam: "07:55 నుండి 09:35 వరకు",
            Moonrise: "18:11",
            Moonset: "చంద్రాస్తమయం లేదు",
            Shaka: "1946 క్రోధి",
            Amavasya: "pournami",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:54 వరకు",
            Amrutakalam: "18:34 నుండి 20:16 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం",
            Date: "గురువారము, ఫిబ్రవరి 13, 2025",
            DurMuhurtamulu: "10:35 నుండి 11:21 వరకు, 15:12 నుండి 15:58 వరకు",
            GulikaiKalam: "09:37 నుండి 11:04 వరకు",
            Karanamulu: "బాలవ 07:48 వరకు, కౌలవ 20:21 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "మఖ 21:07 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:57 నుండి 15:24 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:44",
            Sunset: "18:17",
            Thiti: "పాడ్యమి 20:21 వరకు",
            Yamaganda: "06:44 నుండి 08:10 వరకు",
            Yoga: "శోభన 07:31 వరకు",
            Varjyam:
              "08:21 నుండి 10:03 వరకు, 05:48, ఫిబ్రవరి 14 నుండి 07:32, ఫిబ్రవరి 14 వరకు",
            Moonrise: "19:03",
            Moonset: "07:13",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:54 వరకు",
            Amrutakalam: "16:12 నుండి 17:57 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం 05:44, ఫిబ్రవరి 15 వరకు",
            Date: "శుక్రవారము, ఫిబ్రవరి 14, 2025",
            DurMuhurtamulu: "09:02 నుండి 09:48 వరకు, 12:54 నుండి 13:40 వరకు",
            GulikaiKalam: "08:10 నుండి 09:37 వరకు",
            Karanamulu: "తైతిల 09:03 వరకు, బవ 21:52 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "పుబ్బ 23:09 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "11:04 నుండి 12:30 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:43",
            Sunset: "18:18",
            Thiti: "విదియ 21:52 వరకు",
            Yamaganda: "15:24 నుండి 16:51 వరకు",
            Yoga: "అతిగణ్డ 07:20 వరకు",
            Varjyam: "ఏమి లేదు",
            Moonrise: "19:52",
            Moonset: "07:48",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:54 వరకు",
            Amrutakalam: "17:42 నుండి 19:28 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య",
            Date: "శనివారము, ఫిబ్రవరి 15, 2025",
            DurMuhurtamulu: "06:43 నుండి 07:29 వరకు, 07:29 నుండి 08:16 వరకు",
            GulikaiKalam: "06:43 నుండి 08:10 వరకు",
            Karanamulu: "వణిజ 10:48 వరకు, విష్టి 23:52 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "ఉత్తర 01:39, ఫిబ్రవరి 16 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:37 నుండి 11:03 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:43",
            Sunset: "18:18",
            Thiti: "తదియ 23:52 వరకు",
            Yamaganda: "13:57 నుండి 15:24 వరకు",
            Yoga: "సుకర్మా 07:33 వరకు",
            Varjyam: "07:06 నుండి 08:52 వరకు",
            Moonrise: "20:40",
            Moonset: "08:21",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:54 వరకు",
            Amrutakalam: "21:48 నుండి 23:36 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య",
            Date: "ఆదివారము, ఫిబ్రవరి 16, 2025",
            DurMuhurtamulu: "16:46 నుండి 17:32 వరకు",
            GulikaiKalam: "15:24 నుండి 16:51 వరకు",
            Karanamulu: "బవ 13:01 వరకు, బాలవ 02:15, ఫిబ్రవరి 17 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "హస్త 04:31, ఫిబ్రవరి 17 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:51 నుండి 18:18 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:42",
            Sunset: "18:18",
            Thiti: "చవితి 02:15, ఫిబ్రవరి 17 వరకు",
            Yamaganda: "12:30 నుండి 13:57 వరకు",
            Yoga: "ధృతి 08:06 వరకు",
            Varjyam: "11:03 నుండి 12:51 వరకు",
            Moonrise: "21:27",
            Moonset: "08:53",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "00:22, ఫిబ్రవరి 18 నుండి 02:10, ఫిబ్రవరి 18 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య 18:02 వరకు",
            Date: "సోమవారము, ఫిబ్రవరి 17, 2025",
            DurMuhurtamulu: "12:53 నుండి 13:40 వరకు, 15:13 నుండి 15:59 వరకు",
            GulikaiKalam: "13:57 నుండి 15:24 వరకు",
            Karanamulu: "కౌలవ 15:33 వరకు, తైతిల 04:53, ఫిబ్రవరి 18 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "చిత్తా నిండా రాత్రి వరకు",
            MainNakshatra: "చిత్తా",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "08:09 నుండి 09:36 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:42",
            Sunset: "18:19",
            Thiti: "పంచమి 04:53, ఫిబ్రవరి 18 వరకు",
            Yamaganda: "11:03 నుండి 12:30 వరకు",
            Yoga: "శూల 08:55 వరకు",
            Varjyam: "13:32 నుండి 15:21 వరకు",
            Moonrise: "22:14",
            Moonset: "09:25",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "00:44, ఫిబ్రవరి 19 నుండి 02:32, ఫిబ్రవరి 19 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల",
            Date: "మంగళవారము, ఫిబ్రవరి 18, 2025",
            DurMuhurtamulu:
              "09:01 నుండి 09:47 వరకు, 23:16 నుండి 00:05, ఫిబ్రవరి 19 వరకు",
            GulikaiKalam: "12:30 నుండి 13:57 వరకు",
            Karanamulu: "బవ 18:13 వరకు, వణిజ నిండా రాత్రి వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "చిత్తా 07:35 వరకు",
            MainNakshatra: "చిత్తా",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:25 నుండి 16:52 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:41",
            Sunset: "18:19",
            Thiti: "షష్టి నిండా రాత్రి వరకు",
            Yamaganda: "09:36 నుండి 11:03 వరకు",
            Yoga: "గణ్డ 09:52 వరకు",
            Varjyam: "13:54 నుండి 15:43 వరకు",
            Moonrise: "23:03",
            Moonset: "09:59",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "03:40, ఫిబ్రవరి 20 నుండి 05:27, ఫిబ్రవరి 20 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల",
            Date: "బుధవారము, ఫిబ్రవరి 19, 2025",
            DurMuhurtamulu: "12:07 నుండి 12:53 వరకు",
            GulikaiKalam: "11:03 నుండి 12:30 వరకు",
            Karanamulu: "వణిజ 07:32 వరకు, విష్టి 20:47 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "స్వాతి 10:40 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:30 నుండి 13:57 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:41",
            Sunset: "18:19",
            Thiti: "షష్టి 07:32 వరకు",
            Yamaganda: "08:08 నుండి 09:35 వరకు",
            Yoga: "వృద్ధి 10:48 వరకు",
            Varjyam: "16:55 నుండి 18:43 వరకు",
            Moonrise: "23:54",
            Moonset: "10:35",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "04:27, ఫిబ్రవరి 21 నుండి 06:13, ఫిబ్రవరి 21 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల 06:49 వరకు",
            Date: "గురువారము, ఫిబ్రవరి 20, 2025",
            DurMuhurtamulu: "10:33 నుండి 11:20 వరకు, 15:13 నుండి 16:00 వరకు",
            GulikaiKalam: "09:35 నుండి 11:03 వరకు",
            Karanamulu: "బవ 09:58 వరకు, బాలవ 23:02 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "విశాఖ 13:30 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:57 నుండి 15:25 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:40",
            Sunset: "18:20",
            Thiti: "సప్తమి 09:58 వరకు",
            Yamaganda: "06:40 నుండి 08:08 వరకు",
            Yoga: "ధ్రువ 11:34 వరకు",
            Varjyam: "17:54 నుండి 19:40 వరకు",
            Moonrise: "00:47, ఫిబ్రవరి 21",
            Moonset: "11:15",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:07 నుండి 12:53 వరకు",
            Amrutakalam: "ఏమి లేదు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "శుక్రవారము, ఫిబ్రవరి 21, 2025",
            DurMuhurtamulu: "09:00 నుండి 09:46 వరకు, 12:53 నుండి 13:40 వరకు",
            GulikaiKalam: "08:07 నుండి 09:35 వరకు",
            Karanamulu: "కౌలవ 11:57 వరకు, తైతిల 00:43, ఫిబ్రవరి 22 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "అనూరాధ 15:54 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "11:02 నుండి 12:30 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:40",
            Sunset: "18:20",
            Thiti: "అష్టమి 11:57 వరకు",
            Yamaganda: "15:25 నుండి 16:53 వరకు",
            Yoga: "వ్యాఘాత 11:59 వరకు",
            Varjyam: "21:55 నుండి 23:38 వరకు",
            Moonrise: "01:43, ఫిబ్రవరి 22",
            Moonset: "12:00",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:06 నుండి 12:53 వరకు",
            Amrutakalam: "08:13 నుండి 09:56 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము 17:40 వరకు",
            Date: "శనివారము, ఫిబ్రవరి 22, 2025",
            DurMuhurtamulu: "06:39 నుండి 07:26 వరకు, 07:26 నుండి 08:13 వరకు",
            GulikaiKalam: "06:39 నుండి 08:07 వరకు",
            Karanamulu: "బవ 13:19 వరకు, వణిజ 01:43, ఫిబ్రవరి 23 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "జ్యేష్ఠ 17:40 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:34 నుండి 11:02 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:39",
            Sunset: "18:20",
            Thiti: "నవమి 13:19 వరకు",
            Yamaganda: "13:57 నుండి 15:25 వరకు",
            Yoga: "హర్షణ 11:56 వరకు",
            Varjyam: "02:01, ఫిబ్రవరి 23 నుండి 03:41, ఫిబ్రవరి 23 వరకు",
            Moonrise: "02:39, ఫిబ్రవరి 23",
            Moonset: "12:50",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:06 నుండి 12:53 వరకు",
            Amrutakalam: "12:02 నుండి 13:42 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "ఆదివారము, ఫిబ్రవరి 23, 2025",
            DurMuhurtamulu: "16:47 నుండి 17:34 వరకు",
            GulikaiKalam: "15:25 నుండి 16:53 వరకు",
            Karanamulu: "విష్టి 13:55 వరకు, బవ 01:56, ఫిబ్రవరి 24 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "మూల 18:43 వరకు",
            MainNakshatra: "మూల",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:53 నుండి 18:21 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:38",
            Sunset: "18:21",
            Thiti: "దశమి 13:55 వరకు",
            Yamaganda: "12:30 నుండి 13:57 వరకు",
            Yoga: "వజ్ర 11:19 వరకు",
            Varjyam:
              "17:02 నుండి 18:43 వరకు, 04:25, ఫిబ్రవరి 24 నుండి 06:02, ఫిబ్రవరి 24 వరకు",
            Moonrise: "03:34",
            Moonset: "13:45",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:06 నుండి 12:53 వరకు",
            Amrutakalam: "14:07 నుండి 15:45 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు 00:56, ఫిబ్రవరి 25 వరకు",
            Date: "సోమవారము, ఫిబ్రవరి 24, 2025",
            DurMuhurtamulu: "12:53 నుండి 13:40 వరకు, 15:14 నుండి 16:00 వరకు",
            GulikaiKalam: "13:57 నుండి 15:25 వరకు",
            Karanamulu: "బాలవ 13:44 వరకు, కౌలవ 01:21, ఫిబ్రవరి 25 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "పూర్వాషాఢ 18:59 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "08:06 నుండి 09:34 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:38",
            Sunset: "18:21",
            Thiti: "ఏకాదశి 13:44 వరకు",
            Yamaganda: "11:02 నుండి 12:29 వరకు",
            Yoga: "సిద్ధి 10:05 వరకు",
            Varjyam: "02:49, ఫిబ్రవరి 25 నుండి 04:23, ఫిబ్రవరి 25 వరకు",
            Moonrise: "04:27, ఫిబ్రవరి 25",
            Moonset: "14:45",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:06 నుండి 12:53 వరకు",
            Amrutakalam: "12:14 నుండి 13:48 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము",
            Date: "మంగళవారము, ఫిబ్రవరి 25, 2025",
            DurMuhurtamulu:
              "08:58 నుండి 09:45 వరకు, 23:15 నుండి 00:04, ఫిబ్రవరి 26 వరకు",
            GulikaiKalam: "12:29 నుండి 13:57 వరకు",
            Karanamulu: "తైతిల 12:47 వరకు, బవ 00:02, ఫిబ్రవరి 26 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "ఉత్తరాషాఢ 18:31 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:25 నుండి 16:53 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:37",
            Sunset: "18:21",
            Thiti: "ద్వాదశి 12:47 వరకు",
            Yamaganda: "09:33 నుండి 11:01 వరకు",
            Yoga: "వ్యతీపాత 08:15 వరకు, వారీయన 05:51, ఫిబ్రవరి 26 వరకు",
            Varjyam: "22:19 నుండి 23:51 వరకు",
            Moonrise: "05:16, ఫిబ్రవరి 26",
            Moonset: "15:46",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam:
              "07:28 నుండి 09:00 వరకు, 06:03 నుండి 07:32, ఫిబ్రవరి 27 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము 04:37, ఫిబ్రవరి 27 వరకు",
            Date: "బుధవారము, ఫిబ్రవరి 26, 2025",
            DurMuhurtamulu: "12:06 నుండి 12:53 వరకు",
            GulikaiKalam: "11:01 నుండి 12:29 వరకు",
            Karanamulu: "వణిజ 11:08 వరకు, విష్టి 22:05 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "శ్రవణం 17:23 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:29 నుండి 13:57 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:36",
            Sunset: "18:22",
            Thiti: "త్రయోదశి 11:08 వరకు",
            Yamaganda: "08:05 నుండి 09:33 వరకు",
            Yoga: "పరిఘ 02:58, ఫిబ్రవరి 27 వరకు",
            Varjyam: "21:06 నుండి 22:36 వరకు",
            Moonrise: "06:01, ఫిబ్రవరి 27",
            Moonset: "16:48",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "26",
            LordImage: require("../images/holidays/2-4)Shiva.png"),
          },
          {
            Abhijit: "12:05 నుండి 12:52 వరకు",
            Amrutakalam: "ఏమి లేదు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము",
            Date: "గురువారము, ఫిబ్రవరి 27, 2025",
            DurMuhurtamulu: "10:31 నుండి 11:18 వరకు, 15:14 నుండి 16:01 వరకు",
            GulikaiKalam: "09:32 నుండి 11:01 వరకు",
            Karanamulu: "శకుని 08:54 వరకు, చతుష్పాద 19:37 వరకు",
            Masam: "మాఘ మాసం",
            Nakshatra1: "ధనిష్ఠ 15:43 వరకు",
            MainNakshatra: "ధనిష్ఠ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:57 నుండి 15:25 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:36",
            Sunset: "18:22",
            Thiti: "చతుర్దశి 08:54 వరకు, అమావాస్య 06:14, ఫిబ్రవరి 28 వరకు",
            Yamaganda: "06:36 నుండి 08:04 వరకు",
            Yoga: "శివ 23:41 వరకు",
            Varjyam: "22:18 నుండి 23:46 వరకు",
            Moonrise: "చంద్రోదయం లేదు",
            Moonset: "17:49",
            Shaka: "1946 క్రోధి",
            Amavasya: "amavasya",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:05 నుండి 12:52 వరకు",
            Amrutakalam:
              "07:05 నుండి 08:33 వరకు, 04:08, మార్చి 01 నుండి 05:35, మార్చి 01 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము 05:58, మార్చి 01 వరకు",
            Date: "శుక్రవారము, ఫిబ్రవరి 28, 2025",
            DurMuhurtamulu: "08:57 నుండి 09:44 వరకు, 12:52 నుండి 13:39 వరకు",
            GulikaiKalam: "08:04 నుండి 09:32 వరకు",
            Karanamulu: "కింస్తుఘ్న 16:47 వరకు, బవ 03:16, మార్చి 01 వరకు",
            Masam: "ఫాల్గుణము",
            Nakshatra1: "శతభిషం 13:40 వరకు",
            MainNakshatra: "శతభిషం",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "11:00 నుండి 12:29 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:35",
            Sunset: "18:22",
            Thiti: "పాడ్యమి 03:16, మార్చి 01 వరకు",
            Yamaganda: "15:26 నుండి 16:54 వరకు",
            Yoga: "సిద్ధ 20:08 వరకు",
            Varjyam: "19:27 నుండి 20:54 వరకు",
            Moonrise: "06:44",
            Moonset: "18:49",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
        ],
      },
      {
        day: [
          {
            Abhijit: "12:05 నుండి 12:52 వరకు",
            Amrutakalam: "04:40, మార్చి 02 నుండి 06:06, మార్చి 02 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం",
            Date: "శనివారము, మార్చి 01, 2025",
            DurMuhurtamulu: "06:34 నుండి 07:22 వరకు, 07:22 నుండి 08:09 వరకు",
            GulikaiKalam: "06:34 నుండి 08:03 వరకు",
            Karanamulu: "బాలవ 13:43 వరకు, కౌలవ 00:09, మార్చి 02 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "పూర్వాభాద్ర 11:22 వరకు",
            MainNakshatra: "పూర్వాభాద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:32 నుండి 11:00 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:34",
            Sunset: "18:23",
            Thiti: "విదియ 00:09, మార్చి 02 వరకు",
            Yamaganda: "13:57 నుండి 15:26 వరకు",
            Yoga: "సాధ్య 16:25 వరకు",
            Varjyam: "20:01 నుండి 21:27 వరకు",
            Moonrise: "07:25",
            Moonset: "19:49",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:05 నుండి 12:52 వరకు",
            Amrutakalam: "04:29, మార్చి 03 నుండి 05:55, మార్చి 03 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం",
            Date: "ఆదివారము, మార్చి 02, 2025",
            DurMuhurtamulu: "16:48 నుండి 17:36 వరకు",
            GulikaiKalam: "15:26 నుండి 16:54 వరకు",
            Karanamulu: "తైతిల 10:35 వరకు, గర 21:01 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "ఉత్తరాభాద్ర 08:59 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "16:54 నుండి 18:23 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:34",
            Sunset: "18:23",
            Thiti: "తదియ 21:01 వరకు",
            Yamaganda: "12:28 నుండి 13:57 వరకు",
            Yoga: "శుభ 12:39 వరకు",
            Varjyam: "19:49 నుండి 21:16 వరకు",
            Moonrise: "08:05",
            Moonset: "20:49",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:04 నుండి 12:52 వరకు",
            Amrutakalam: "21:56 నుండి 23:24 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం 06:39 వరకు",
            Date: "సోమవారము, మార్చి 03, 2025",
            DurMuhurtamulu: "12:52 నుండి 13:39 వరకు, 15:14 నుండి 16:01 వరకు",
            GulikaiKalam: "13:57 నుండి 15:26 వరకు",
            Karanamulu: "వణిజ 07:30 వరకు, విష్టి 18:02 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "రేవతి 06:39 వరకు, అశ్విని 04:29, మార్చి 04 వరకు",
            MainNakshatra: "రేవతి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "08:02 నుండి 09:31 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:33",
            Sunset: "18:23",
            Thiti: "చవితి 18:02 వరకు",
            Yamaganda: "10:59 నుండి 12:28 వరకు",
            Yoga: "శుక్ల 08:57 వరకు, బ్రహ్మ 05:25, మార్చి 04 వరకు",
            Varjyam: "00:51, మార్చి 04 నుండి 02:18, మార్చి 04 వరకు",
            Moonrise: "08:47",
            Moonset: "21:50",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:04 నుండి 12:52 వరకు",
            Amrutakalam: "22:12 నుండి 23:40 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం",
            Date: "మంగళవారము, మార్చి 04, 2025",
            DurMuhurtamulu:
              "08:55 నుండి 09:42 వరకు, 23:15 నుండి 00:03, మార్చి 05 వరకు",
            GulikaiKalam: "12:28 నుండి 13:57 వరకు",
            Karanamulu: "బాలవ 15:16 వరకు, కౌలవ 02:01, మార్చి 05 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "భరణి 02:37, మార్చి 05 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:26 నుండి 16:55 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:32",
            Sunset: "18:23",
            Thiti: "పంచమి 15:16 వరకు",
            Yamaganda: "09:30 నుండి 10:59 వరకు",
            Yoga: "ఐన్ద్ర 02:07, మార్చి 05 వరకు",
            Varjyam: "13:21 నుండి 14:49 వరకు",
            Moonrise: "09:31",
            Moonset: "22:53",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "22:53 నుండి 00:23, మార్చి 06 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం 08:13 వరకు",
            Date: "బుధవారము, మార్చి 05, 2025",
            DurMuhurtamulu: "12:04 నుండి 12:51 వరకు",
            GulikaiKalam: "10:59 నుండి 12:28 వరకు",
            Karanamulu: "తైతిల 12:51 వరకు, గర 23:47 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "కృత్తిక 01:08, మార్చి 06 వరకు",
            MainNakshatra: "కృత్తిక",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:28 నుండి 13:57 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:32",
            Sunset: "18:24",
            Thiti: "షష్టి 12:51 వరకు",
            Yamaganda: "08:01 నుండి 09:30 వరకు",
            Yoga: "వైధృతి 23:07 వరకు",
            Varjyam: "13:53 నుండి 15:23 వరకు",
            Moonrise: "10:20",
            Moonset: "23:58",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:04 నుండి 12:51 వరకు",
            Amrutakalam: "21:02 నుండి 22:34 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం",
            Date: "గురువారము, మార్చి 06, 2025",
            DurMuhurtamulu: "10:29 నుండి 11:16 వరకు, 15:14 నుండి 16:01 వరకు",
            GulikaiKalam: "09:29 నుండి 10:58 వరకు",
            Karanamulu: "వణిజ 10:50 వరకు, విష్టి 22:01 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "రోహిణి 00:05, మార్చి 07 వరకు",
            MainNakshatra: "రోహిణి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:57 నుండి 15:26 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:31",
            Sunset: "18:24",
            Thiti: "సప్తమి 10:50 వరకు",
            Yamaganda: "06:31 నుండి 08:00 వరకు",
            Yoga: "విష్కుమ్భ 20:29 వరకు",
            Varjyam:
              "16:26 నుండి 17:58 వరకు, 05:33, మార్చి 07 నుండి 07:07, మార్చి 07 వరకు",
            Moonrise: "11:13",
            Moonset: "01:02, మార్చి 07",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:03 నుండి 12:51 వరకు",
            Amrutakalam: "14:56 నుండి 16:30 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం 11:45 వరకు",
            Date: "శుక్రవారము, మార్చి 07, 2025",
            DurMuhurtamulu: "08:53 నుండి 09:41 వరకు, 12:51 నుండి 13:39 వరకు",
            GulikaiKalam: "07:59 నుండి 09:29 వరకు",
            Karanamulu: "బవ 09:18 వరకు, బాలవ 20:43 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "మృగశిర 23:32 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:58 నుండి 12:27 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:30",
            Sunset: "18:24",
            Thiti: "అష్టమి 09:18 వరకు",
            Yamaganda: "15:26 నుండి 16:55 వరకు",
            Yoga: "ప్రీతి 18:15 వరకు",
            Varjyam: "ఏమి లేదు",
            Moonrise: "12:11",
            Moonset: "02:03, మార్చి 08",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:03 నుండి 12:51 వరకు",
            Amrutakalam: "13:30 నుండి 15:05 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం",
            Date: "శనివారము, మార్చి 08, 2025",
            DurMuhurtamulu: "06:30 నుండి 07:17 వరకు, 07:17 నుండి 08:05 వరకు",
            GulikaiKalam: "06:30 నుండి 07:59 వరకు",
            Karanamulu: "కౌలవ 08:16 వరకు, తైతిల 19:56 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "ఆరుద్ర 23:28 వరకు",
            MainNakshatra: "ఆరుద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:28 నుండి 10:58 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:30",
            Sunset: "18:24",
            Thiti: "నవమి 08:16 వరకు",
            Yamaganda: "13:56 నుండి 15:26 వరకు",
            Yoga: "ఆయుష్మాన్ 16:24 వరకు",
            Varjyam: "07:54 నుండి 09:30 వరకు",
            Moonrise: "13:11",
            Moonset: "02:59, మార్చి 09",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:03 నుండి 12:51 వరకు",
            Amrutakalam: "21:28 నుండి 23:06 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం 17:45 వరకు",
            Date: "ఆదివారము, మార్చి 09, 2025",
            DurMuhurtamulu: "16:49 నుండి 17:37 వరకు",
            GulikaiKalam: "15:26 నుండి 16:55 వరకు",
            Karanamulu: "బవ 07:45 వరకు, వణిజ 19:41 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "పునర్వసు 23:55 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "16:55 నుండి 18:25 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:29",
            Sunset: "18:25",
            Thiti: "దశమి 07:45 వరకు",
            Yamaganda: "12:27 నుండి 13:56 వరకు",
            Yoga: "సౌభాగ్య 14:59 వరకు",
            Varjyam: "11:42 నుండి 13:19 వరకు",
            Moonrise: "14:11",
            Moonset: "03:49, మార్చి 10",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:03 నుండి 12:50 వరకు",
            Amrutakalam: "18:12 నుండి 19:52 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "సోమవారము, మార్చి 10, 2025",
            DurMuhurtamulu: "12:50 నుండి 13:38 వరకు, 15:14 నుండి 16:02 వరకు",
            GulikaiKalam: "13:56 నుండి 15:26 వరకు",
            Karanamulu: "విష్టి 07:44 వరకు, బవ 19:55 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "పుష్యమి 00:51, మార్చి 11 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:58 నుండి 09:27 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:28",
            Sunset: "18:25",
            Thiti: "ఏకాదశి 07:44 వరకు",
            Yamaganda: "10:57 నుండి 12:26 వరకు",
            Yoga: "శోభన 13:57 వరకు",
            Varjyam: "08:14 నుండి 09:53 వరకు",
            Moonrise: "15:09",
            Moonset: "04:33, మార్చి 11",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:02 నుండి 12:50 వరకు",
            Amrutakalam: "00:33, మార్చి 12 నుండి 02:15, మార్చి 12 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం 02:15, మార్చి 12 వరకు",
            Date: "మంగళవారము, మార్చి 11, 2025",
            DurMuhurtamulu:
              "08:51 నుండి 09:39 వరకు, 23:14 నుండి 00:02, మార్చి 12 వరకు",
            GulikaiKalam: "12:26 నుండి 13:56 వరకు",
            Karanamulu: "బాలవ 08:13 వరకు, కౌలవ 20:39 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "ఆశ్లేష 02:15, మార్చి 12 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:26 నుండి 16:55 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:27",
            Sunset: "18:25",
            Thiti: "ద్వాదశి 08:13 వరకు",
            Yamaganda: "09:27 నుండి 10:56 వరకు",
            Yoga: "అతిగణ్డ 13:18 వరకు",
            Varjyam: "14:24 నుండి 16:05 వరకు",
            Moonrise: "16:05",
            Moonset: "05:13, మార్చి 12",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "01:30, మార్చి 13 నుండి 03:14, మార్చి 13 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం",
            Date: "బుధవారము, మార్చి 12, 2025",
            DurMuhurtamulu: "12:02 నుండి 12:50 వరకు",
            GulikaiKalam: "10:56 నుండి 12:26 వరకు",
            Karanamulu: "తైతిల 09:11 వరకు, బవ 21:50 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "మఖ 04:05, మార్చి 13 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:26 నుండి 13:56 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:27",
            Sunset: "18:25",
            Thiti: "త్రయోదశి 09:11 వరకు",
            Yamaganda: "07:56 నుండి 09:26 వరకు",
            Yoga: "సుకర్మా 13:00 వరకు",
            Varjyam: "15:10 నుండి 16:53 వరకు",
            Moonrise: "16:57",
            Moonset: "05:48, మార్చి 13",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:02 నుండి 12:50 వరకు",
            Amrutakalam: "23:19 నుండి 01:04, మార్చి 14 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం",
            Date: "గురువారము, మార్చి 13, 2025",
            DurMuhurtamulu: "10:26 నుండి 11:14 వరకు, 15:14 నుండి 16:02 వరకు",
            GulikaiKalam: "09:26 నుండి 10:56 వరకు",
            Karanamulu: "వణిజ 10:35 వరకు, విష్టి 23:26 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "పుబ్బ 06:19, మార్చి 14 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:56 నుండి 15:26 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:26",
            Sunset: "18:26",
            Thiti: "చతుర్దశి 10:35 వరకు",
            Yamaganda: "06:26 నుండి 07:56 వరకు",
            Yoga: "ధృతి 13:03 వరకు",
            Varjyam: "12:50 నుండి 14:35 వరకు",
            Moonrise: "17:46",
            Moonset: "06:21, మార్చి 14",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:01 నుండి 12:49 వరకు",
            Amrutakalam: "00:56, మార్చి 15 నుండి 02:42, మార్చి 15 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం 12:56 వరకు",
            Date: "శుక్రవారము, మార్చి 14, 2025",
            DurMuhurtamulu: "08:49 నుండి 09:37 వరకు, 12:49 నుండి 13:37 వరకు",
            GulikaiKalam: "07:55 నుండి 09:25 వరకు",
            Karanamulu: "బవ 12:23 వరకు, బాలవ 01:26, మార్చి 15 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "ఉత్తర నిండా రాత్రి వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:55 నుండి 12:25 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:25",
            Sunset: "18:26",
            Thiti: "పౌర్ణమి 12:23 వరకు",
            Yamaganda: "15:26 నుండి 16:56 వరకు",
            Yoga: "శూల 13:24 వరకు",
            Varjyam: "14:18 నుండి 16:04 వరకు",
            Moonrise: "18:34",
            Moonset: "చంద్రాస్తమయం లేదు",
            Shaka: "1946 క్రోధి",
            Amavasya: "pournami",
            FestDate: "14",
            LordImage: require("../images/holidays/2-3)Holi.png"),
          },
          {
            Abhijit: "12:01 నుండి 12:49 వరకు",
            Amrutakalam: "05:02, మార్చి 16 నుండి 06:50, మార్చి 16 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య",
            Date: "శనివారము, మార్చి 15, 2025",
            DurMuhurtamulu: "06:24 నుండి 07:12 వరకు, 07:12 నుండి 08:00 వరకు",
            GulikaiKalam: "06:24 నుండి 07:54 వరకు",
            Karanamulu: "కౌలవ 14:33 వరకు, తైతిల 03:43, మార్చి 16 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "ఉత్తర 08:54 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:25 నుండి 10:55 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:24",
            Sunset: "18:26",
            Thiti: "పాడ్యమి 14:33 వరకు",
            Yamaganda: "13:55 నుండి 15:26 వరకు",
            Yoga: "గణ్డ 14:00 వరకు",
            Varjyam: "18:18 నుండి 20:05 వరకు",
            Moonrise: "19:21",
            Moonset: "06:53",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:01 నుండి 12:49 వరకు",
            Amrutakalam: "ఏమి లేదు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య 01:15, మార్చి 17 వరకు",
            Date: "ఆదివారము, మార్చి 16, 2025",
            DurMuhurtamulu: "16:50 నుండి 17:38 వరకు",
            GulikaiKalam: "15:26 నుండి 16:56 వరకు",
            Karanamulu: "బవ 16:58 వరకు, వణిజ 06:14, మార్చి 17 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "హస్త 11:45 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:56 నుండి 18:26 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:23",
            Sunset: "18:26",
            Thiti: "విదియ 16:58 వరకు",
            Yamaganda: "12:25 నుండి 13:55 వరకు",
            Yoga: "వృద్ధి 14:49 వరకు",
            Varjyam: "20:46 నుండి 22:34 వరకు",
            Moonrise: "20:09",
            Moonset: "07:25",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:00 నుండి 12:49 వరకు",
            Amrutakalam: "07:34 నుండి 09:23 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల",
            Date: "సోమవారము, మార్చి 17, 2025",
            DurMuhurtamulu: "12:49 నుండి 13:37 వరకు, 15:13 నుండి 16:02 వరకు",
            GulikaiKalam: "13:55 నుండి 15:25 వరకు",
            Karanamulu: "విష్టి 19:33 వరకు, బవ నిండా రాత్రి వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "చిత్తా 14:47 వరకు",
            MainNakshatra: "చిత్తా",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "07:53 నుండి 09:24 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:23",
            Sunset: "18:26",
            Thiti: "తదియ 19:33 వరకు",
            Yamaganda: "10:54 నుండి 12:25 వరకు",
            Yoga: "ధ్రువ 15:45 వరకు",
            Varjyam: "21:06 నుండి 22:54 వరకు",
            Moonrise: "20:57",
            Moonset: "07:58",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "12:00 నుండి 12:48 వరకు",
            Amrutakalam: "07:56 నుండి 09:44 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల",
            Date: "మంగళవారము, మార్చి 18, 2025",
            DurMuhurtamulu:
              "08:47 నుండి 09:35 వరకు, 23:12 నుండి 00:00, మార్చి 19 వరకు",
            GulikaiKalam: "12:24 నుండి 13:55 వరకు",
            Karanamulu: "బవ 08:51 వరకు, బాలవ 22:09 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "స్వాతి 17:52 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:25 నుండి 16:56 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:22",
            Sunset: "18:27",
            Thiti: "చవితి 22:09 వరకు",
            Yamaganda: "09:23 నుండి 10:54 వరకు",
            Yoga: "వ్యాఘాత 16:44 వరకు",
            Varjyam: "00:09, మార్చి 19 నుండి 01:57, మార్చి 19 వరకు",
            Moonrise: "21:47",
            Moonset: "08:33",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "10:57 నుండి 12:44 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల 14:07 వరకు",
            Date: "బుధవారము, మార్చి 19, 2025",
            DurMuhurtamulu: "12:00 నుండి 12:48 వరకు",
            GulikaiKalam: "10:53 నుండి 12:24 వరకు",
            Karanamulu: "కౌలవ 11:24 వరకు, తైతిల 00:36, మార్చి 20 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "విశాఖ 20:50 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:24 నుండి 13:55 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:21",
            Sunset: "18:27",
            Thiti: "పంచమి 00:36, మార్చి 20 వరకు",
            Yamaganda: "07:52 నుండి 09:23 వరకు",
            Yoga: "హర్షణ 17:38 వరకు",
            Varjyam: "01:17, మార్చి 20 నుండి 03:04, మార్చి 20 వరకు",
            Moonrise: "22:39",
            Moonset: "09:11",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:59 నుండి 12:48 వరకు",
            Amrutakalam: "11:57 నుండి 13:44 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "గురువారము, మార్చి 20, 2025",
            DurMuhurtamulu: "10:23 నుండి 11:11 వరకు, 15:13 నుండి 16:02 వరకు",
            GulikaiKalam: "09:22 నుండి 10:53 వరకు",
            Karanamulu: "బవ 13:44 వరకు, వణిజ 02:45, మార్చి 21 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "అనూరాధ 23:31 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:55 నుండి 15:25 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:20",
            Sunset: "18:27",
            Thiti: "షష్టి 02:45, మార్చి 21 వరకు",
            Yamaganda: "06:20 నుండి 07:51 వరకు",
            Yoga: "వజ్ర 18:20 వరకు",
            Varjyam: "05:39, మార్చి 21 నుండి 07:24, మార్చి 21 వరకు",
            Moonrise: "23:33",
            Moonset: "09:54",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:59 నుండి 12:48 వరకు",
            Amrutakalam: "16:08 నుండి 17:53 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "శుక్రవారము, మార్చి 21, 2025",
            DurMuhurtamulu: "08:45 నుండి 09:34 వరకు, 12:48 నుండి 13:36 వరకు",
            GulikaiKalam: "07:50 నుండి 09:21 వరకు",
            Karanamulu: "విష్టి 15:38 వరకు, బవ 04:23, మార్చి 22 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "జ్యేష్ఠ 01:46, మార్చి 22 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:52 నుండి 12:23 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:19",
            Sunset: "18:27",
            Thiti: "సప్తమి 04:23, మార్చి 22 వరకు",
            Yamaganda: "15:25 నుండి 16:56 వరకు",
            Yoga: "సిద్ధి 18:42 వరకు",
            Varjyam: "ఏమి లేదు",
            Moonrise: "00:28, మార్చి 22",
            Moonset: "10:41",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:59 నుండి 12:47 వరకు",
            Amrutakalam: "20:33 నుండి 22:16 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "శనివారము, మార్చి 22, 2025",
            DurMuhurtamulu: "06:19 నుండి 07:07 వరకు, 07:07 నుండి 07:56 వరకు",
            GulikaiKalam: "06:19 నుండి 07:50 వరకు",
            Karanamulu: "బాలవ 16:58 వరకు, కౌలవ 05:23, మార్చి 23 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "మూల 03:23, మార్చి 23 వరకు",
            MainNakshatra: "మూల",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:21 నుండి 10:52 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:19",
            Sunset: "18:27",
            Thiti: "అష్టమి 05:23, మార్చి 23 వరకు",
            Yamaganda: "13:54 నుండి 15:25 వరకు",
            Yoga: "వ్యతీపాత 18:37 వరకు",
            Varjyam:
              "10:18 నుండి 12:01 వరకు, 01:41, మార్చి 23 నుండి 03:23, మార్చి 23 వరకు",
            Moonrise: "01:22, మార్చి 23",
            Moonset: "11:33",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:58 నుండి 12:47 వరకు",
            Amrutakalam: "23:19 నుండి 00:59, మార్చి 24 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "ఆదివారము, మార్చి 23, 2025",
            DurMuhurtamulu: "16:50 నుండి 17:39 వరకు",
            GulikaiKalam: "15:25 నుండి 16:56 వరకు",
            Karanamulu: "తైతిల 17:36 వరకు, బవ 05:38, మార్చి 24 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "పూర్వాషాఢ 04:18, మార్చి 24 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:56 నుండి 18:28 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:18",
            Sunset: "18:28",
            Thiti: "నవమి 05:37, మార్చి 24 వరకు",
            Yamaganda: "12:23 నుండి 13:54 వరకు",
            Yoga: "వారీయన 17:59 వరకు",
            Varjyam: "13:21 నుండి 15:01 వరకు",
            Moonrise: "02:15, మార్చి 24",
            Moonset: "12:30",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:58 నుండి 12:47 వరకు",
            Amrutakalam: "22:00 నుండి 23:37 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు 10:25 వరకు",
            Date: "సోమవారము, మార్చి 24, 2025",
            DurMuhurtamulu: "12:47 నుండి 13:36 వరకు, 15:13 నుండి 16:02 వరకు",
            GulikaiKalam: "13:54 నుండి 15:25 వరకు",
            Karanamulu: "వణిజ 17:27 వరకు, విష్టి 05:05, మార్చి 25 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "ఉత్తరాషాఢ 04:27, మార్చి 25 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "07:48 నుండి 09:20 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:17",
            Sunset: "18:28",
            Thiti: "దశమి 05:05, మార్చి 25 వరకు",
            Yamaganda: "10:51 నుండి 12:22 వరకు",
            Yoga: "పరిఘ 16:45 వరకు",
            Varjyam: "12:21 నుండి 13:57 వరకు",
            Moonrise: "03:04, మార్చి 25",
            Moonset: "13:29",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:58 నుండి 12:47 వరకు",
            Amrutakalam: "17:41 నుండి 19:15 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము",
            Date: "మంగళవారము, మార్చి 25, 2025",
            DurMuhurtamulu: "08:43 నుండి 09:31 వరకు, 23:11 నుండి 23:58 వరకు",
            GulikaiKalam: "12:22 నుండి 13:54 వరకు",
            Karanamulu: "బవ 16:30 వరకు, బాలవ 03:45, మార్చి 26 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "శ్రవణం 03:49, మార్చి 26 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:25 నుండి 16:57 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:16",
            Sunset: "18:28",
            Thiti: "ఏకాదశి 03:45, మార్చి 26 వరకు",
            Yamaganda: "09:19 నుండి 10:51 వరకు",
            Yoga: "శివ 14:53 వరకు",
            Varjyam: "08:20 నుండి 09:54 వరకు",
            Moonrise: "03:51, మార్చి 26",
            Moonset: "14:29",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "16:40 నుండి 18:11 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము 15:14 వరకు",
            Date: "బుధవారము, మార్చి 26, 2025",
            DurMuhurtamulu: "11:57 నుండి 12:46 వరకు",
            GulikaiKalam: "10:50 నుండి 12:22 వరకు",
            Karanamulu: "కౌలవ 14:49 వరకు, తైతిల 01:42, మార్చి 27 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "ధనిష్ఠ 02:30, మార్చి 27 వరకు",
            MainNakshatra: "ధనిష్ఠ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:22 నుండి 13:53 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:16",
            Sunset: "18:28",
            Thiti: "ద్వాదశి 01:42, మార్చి 27 వరకు",
            Yamaganda: "07:47 నుండి 09:19 వరకు",
            Yoga: "సిద్ధ 12:26 వరకు",
            Varjyam: "07:36 నుండి 09:07 వరకు",
            Moonrise: "04:34, మార్చి 27",
            Moonset: "15:30",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:46 వరకు",
            Amrutakalam: "17:56 నుండి 19:25 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము",
            Date: "గురువారము, మార్చి 27, 2025",
            DurMuhurtamulu: "10:19 నుండి 11:08 వరకు, 15:13 నుండి 16:02 వరకు",
            GulikaiKalam: "09:18 నుండి 10:50 వరకు",
            Karanamulu: "బవ 12:27 వరకు, వణిజ 23:03 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "శతభిషం 00:34, మార్చి 28 వరకు",
            MainNakshatra: "శతభిషం",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:53 నుండి 15:25 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:15",
            Sunset: "18:28",
            Thiti: "త్రయోదశి 23:03 వరకు",
            Yamaganda: "06:15 నుండి 07:46 వరకు",
            Yoga: "సాధ్య 09:25 వరకు, శుభ 05:57, మార్చి 28 వరకు",
            Varjyam: "09:07 నుండి 10:35 వరకు",
            Moonrise: "05:15, మార్చి 28",
            Moonset: "16:30",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:46 వరకు",
            Amrutakalam: "14:57 నుండి 16:24 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము 16:48 వరకు",
            Date: "శుక్రవారము, మార్చి 28, 2025",
            DurMuhurtamulu: "08:41 నుండి 09:30 వరకు, 12:46 నుండి 13:35 వరకు",
            GulikaiKalam: "07:46 నుండి 09:18 వరకు",
            Karanamulu:
              "విష్టి 09:32 వరకు, శకుని 19:55 వరకు, చతుష్పాద 06:13, మార్చి 29 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "పూర్వాభాద్ర 22:09 వరకు",
            MainNakshatra: "పూర్వాభాద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:49 నుండి 12:21 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:14",
            Sunset: "18:29",
            Thiti: "చతుర్దశి 19:55 వరకు",
            Yamaganda: "15:25 నుండి 16:57 వరకు",
            Yoga: "శుక్ల 02:07, మార్చి 29 వరకు",
            Varjyam: "06:19 నుండి 07:45 వరకు",
            Moonrise: "05:56, మార్చి 29",
            Moonset: "17:29",
            Shaka: "1946 క్రోధి",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:45 వరకు",
            Amrutakalam: "15:11 నుండి 16:36 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనము",
            Date: "శనివారము, మార్చి 29, 2025",
            DurMuhurtamulu: "06:13 నుండి 07:02 వరకు, 07:02 నుండి 07:51 వరకు",
            GulikaiKalam: "06:13 నుండి 07:45 వరకు",
            Karanamulu: "నాగ 16:27 వరకు, కింస్తుఘ్న 02:39, మార్చి 30 వరకు",
            Masam: "ఫాల్గుణ మాసం",
            Nakshatra1: "ఉత్తరాభాద్ర 19:26 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:17 నుండి 10:49 వరకు",
            Ruthuvu: "శిశిర ఋతువు",
            Sunrise: "06:13",
            Sunset: "18:29",
            Thiti: "అమావాస్య 16:27 వరకు",
            Yamaganda: "13:53 నుండి 15:25 వరకు",
            Yoga: "బ్రహ్మ 22:04 వరకు",
            Varjyam:
              "06:40 నుండి 08:05 వరకు, 06:01, మార్చి 30 నుండి 07:25, మార్చి 30 వరకు",
            Moonrise: "చంద్రోదయం లేదు",
            Moonset: "18:30",
            Shaka: "1946 క్రోధి",
            Amavasya: "amavasya",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:45 వరకు",
            Amrutakalam: "14:28 నుండి 15:52 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనము 16:35 వరకు",
            Date: "ఆదివారము, మార్చి 30, 2025",
            DurMuhurtamulu: "16:51 నుండి 17:40 వరకు",
            GulikaiKalam: "15:25 నుండి 16:57 వరకు",
            Karanamulu: "బవ 12:49 వరకు, బాలవ 22:59 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "రేవతి 16:35 వరకు",
            MainNakshatra: "రేవతి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "16:57 నుండి 18:29 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "06:12",
            Sunset: "18:29",
            Thiti: "పాడ్యమి 12:49 వరకు",
            Yamaganda: "12:21 నుండి 13:53 వరకు",
            Yoga: "ఐన్ద్ర 17:54 వరకు",
            Varjyam: "ఏమి లేదు",
            Moonrise: "06:38",
            Moonset: "19:32",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "30",
            LordImage: require("../images/holidays/3-1)Ugadi.png"),
          },
          {
            Abhijit: "11:56 నుండి 12:45 వరకు",
            Amrutakalam: "07:24 నుండి 08:48 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం",
            Date: "సోమవారము, మార్చి 31, 2025",
            DurMuhurtamulu: "12:45 నుండి 13:34 వరకు, 15:12 నుండి 16:02 వరకు",
            GulikaiKalam: "13:53 నుండి 15:25 వరకు",
            Karanamulu: "కౌలవ 09:11 వరకు, తైతిల 19:24 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "అశ్విని 13:45 వరకు",
            MainNakshatra: "అశ్విని",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:44 నుండి 09:16 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "06:12",
            Sunset: "18:29",
            Thiti: "విదియ 09:11 వరకు, తదియ 05:42, ఏప్రిల్ 01 వరకు",
            Yamaganda: "10:48 నుండి 12:20 వరకు",
            Yoga: "వైధృతి 13:46 వరకు",
            Varjyam: "10:13 నుండి 11:38 వరకు, 22:17 నుండి 23:43 వరకు",
            Moonrise: "07:22",
            Moonset: "20:37",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "31",
            LordImage: require("../images/holidays/Jamat_Ul_Vida.png"),
          },
        ],
      },
      {
        day: [
          {
            Abhijit: "11:55 నుండి 12:45 వరకు",
            Amrutakalam: "06:50 నుండి 08:16 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం",
            Date: "మంగళవారము, ఏప్రిల్ 01, 2025 విశ్వావసు",
            DurMuhurtamulu: "08:38 నుండి 09:28 వరకు, 23:10 నుండి 23:56 వరకు",
            GulikaiKalam: "12:20 నుండి 13:52 వరకు",
            Karanamulu: "వణిజ 16:04 వరకు, విష్టి 02:32, ఏప్రిల్ 02 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "భరణి 11:06 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:25 నుండి 16:57 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "06:11",
            Sunset: "18:29",
            Thiti: "చవితి 02:32, ఏప్రిల్ 02 వరకు",
            Yamaganda: "09:15 నుండి 10:48 వరకు",
            Yoga: "విష్కుమ్భ 09:48 వరకు",
            Varjyam: "21:58 నుండి 23:25 వరకు",
            Moonrise: "08:11",
            Moonset: "21:43",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam:
              "06:39 నుండి 08:06 వరకు, 04:04, ఏప్రిల్ 03 నుండి 05:33, ఏప్రిల్ 03 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం",
            Date: "బుధవారము, ఏప్రిల్ 02, 2025",
            DurMuhurtamulu: "11:55 నుండి 12:44 వరకు",
            GulikaiKalam: "10:47 నుండి 12:20 వరకు",
            Karanamulu: "బవ 13:07 వరకు, బాలవ 23:49 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "కృత్తిక 08:49 వరకు",
            MainNakshatra: "కృత్తిక",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:20 నుండి 13:52 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "06:10",
            Sunset: "18:30",
            Thiti: "పంచమి 23:49 వరకు",
            Yamaganda: "07:42 నుండి 09:15 వరకు",
            Yoga: "ఆయుష్మాన్ 02:50, ఏప్రిల్ 03 వరకు",
            Varjyam: "23:38 నుండి 01:07, ఏప్రిల్ 03 వరకు",
            Moonrise: "09:04",
            Moonset: "22:50",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:55 నుండి 12:44 వరకు",
            Amrutakalam: "21:29 నుండి 23:00 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం 18:22 వరకు",
            Date: "గురువారము, ఏప్రిల్ 03, 2025",
            DurMuhurtamulu: "10:16 నుండి 11:05 వరకు, 15:12 నుండి 16:02 వరకు",
            GulikaiKalam: "09:14 నుండి 10:47 వరకు",
            Karanamulu: "కౌలవ 10:40 వరకు, తైతిల 21:41 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "రోహిణి 07:02 వరకు, మృగశిర 05:51, ఏప్రిల్ 04 వరకు",
            MainNakshatra: "రోహిణి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:52 నుండి 15:25 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "06:09",
            Sunset: "18:30",
            Thiti: "షష్టి 21:41 వరకు",
            Yamaganda: "06:09 నుండి 07:42 వరకు",
            Yoga: "సౌభాగ్య 00:01, ఏప్రిల్ 04 వరకు",
            Varjyam: "12:21 నుండి 13:53 వరకు",
            Moonrise: "10:02",
            Moonset: "23:55",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:54 నుండి 12:44 వరకు",
            Amrutakalam: "19:33 నుండి 21:07 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం",
            Date: "శుక్రవారము, ఏప్రిల్ 04, 2025",
            DurMuhurtamulu: "08:37 నుండి 09:26 వరకు, 12:44 నుండి 13:33 వరకు",
            GulikaiKalam: "07:41 నుండి 09:14 వరకు",
            Karanamulu: "గర 08:51 వరకు, వణిజ 20:12 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "ఆరుద్ర 05:20, ఏప్రిల్ 05 వరకు",
            MainNakshatra: "ఆరుద్ర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:47 నుండి 12:19 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "06:08",
            Sunset: "18:30",
            Thiti: "సప్తమి 20:12 వరకు",
            Yamaganda: "15:25 నుండి 16:57 వరకు",
            Yoga: "శోభన 21:45 వరకు",
            Varjyam: "14:04 నుండి 15:38 వరకు",
            Moonrise: "11:03",
            Moonset: "00:54, ఏప్రిల్ 05",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:54 నుండి 12:44 వరకు",
            Amrutakalam: "03:07, ఏప్రిల్ 06 నుండి 04:43, ఏప్రిల్ 06 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం 23:25 వరకు",
            Date: "శనివారము, ఏప్రిల్ 05, 2025",
            DurMuhurtamulu: "06:08 నుండి 06:57 వరకు, 06:57 నుండి 07:47 వరకు",
            GulikaiKalam: "06:08 నుండి 07:40 వరకు",
            Karanamulu: "విష్టి 07:44 వరకు, బవ 19:26 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "పునర్వసు 05:32, ఏప్రిల్ 06 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:13 నుండి 10:46 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "06:08",
            Sunset: "18:30",
            Thiti: "అష్టమి 19:26 వరకు",
            Yamaganda: "13:52 నుండి 15:25 వరకు",
            Yoga: "అతిగణ్డ 20:03 వరకు",
            Varjyam: "17:26 నుండి 19:03 వరకు",
            Moonrise: "12:05",
            Moonset: "01:47, ఏప్రిల్ 06",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "5",
            LordImage: require("../images/holidays/3-2)babu-jagjivan-ram-original-imafjz89pyrevwnu.png"),
          },
          {
            Abhijit: "11:54 నుండి 12:43 వరకు",
            Amrutakalam: "23:46 నుండి 01:26, ఏప్రిల్ 07 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "ఆదివారము, ఏప్రిల్ 06, 2025",
            DurMuhurtamulu: "16:51 నుండి 17:41 వరకు",
            GulikaiKalam: "15:24 నుండి 16:57 వరకు",
            Karanamulu: "బాలవ 07:19 వరకు, కౌలవ 19:23 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "పుష్యమి నిండా రాత్రి వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "16:57 నుండి 18:30 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "06:07",
            Sunset: "18:30",
            Thiti: "నవమి 19:22 వరకు",
            Yamaganda: "12:19 నుండి 13:52 వరకు",
            Yoga: "సుకర్మా 18:55 వరకు",
            Varjyam: "13:49 నుండి 15:29 వరకు",
            Moonrise: "13:04",
            Moonset: "02:33, ఏప్రిల్ 07",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "6",
            LordImage: require("../images/holidays/3-3)Sriramanavami.png"),
          },
          {
            Abhijit: "11:54 నుండి 12:43 వరకు",
            Amrutakalam: "ఏమి లేదు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "సోమవారము, ఏప్రిల్ 07, 2025",
            DurMuhurtamulu: "12:43 నుండి 13:33 వరకు, 15:12 నుండి 16:02 వరకు",
            GulikaiKalam: "13:51 నుండి 15:24 వరకు",
            Karanamulu: "తైతిల 07:36 వరకు, బవ 20:00 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "పుష్యమి 06:25 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:39 నుండి 09:12 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "06:06",
            Sunset: "18:31",
            Thiti: "దశమి 20:00 వరకు",
            Yamaganda: "10:45 నుండి 12:18 వరకు",
            Yoga: "ధృతి 18:19 వరకు",
            Varjyam: "20:01 నుండి 21:43 వరకు",
            Moonrise: "14:01",
            Moonset: "03:13, ఏప్రిల్ 9",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:43 వరకు",
            Amrutakalam: "06:13 నుండి 07:55 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం 07:55 వరకు",
            Date: "మంగళవారము, ఏప్రిల్ 08, 2025",
            DurMuhurtamulu: "08:34 నుండి 09:24 వరకు, 23:08 నుండి 23:55 వరకు",
            GulikaiKalam: "12:18 నుండి 13:51 వరకు",
            Karanamulu: "వణిజ 08:32 వరకు, విష్టి 21:12 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "ఆశ్లేష 07:55 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:24 నుండి 16:58 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "06:05",
            Sunset: "18:31",
            Thiti: "ఏకాదశి 21:12 వరకు",
            Yamaganda: "09:12 నుండి 10:45 వరకు",
            Yoga: "శూల 18:11 వరకు",
            Varjyam: "20:56 నుండి 22:40 వరకు",
            Moonrise: "14:53",
            Moonset: "03:50, ఏప్రిల్ 10",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam:
              "07:21 నుండి 09:05 వరకు, 05:21, ఏప్రిల్ 10 నుండి 07:07, ఏప్రిల్ 10 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం",
            Date: "బుధవారము, ఏప్రిల్ 09, 2025",
            DurMuhurtamulu: "11:53 నుండి 12:43 వరకు",
            GulikaiKalam: "10:45 నుండి 12:18 వరకు",
            Karanamulu: "బవ 10:00 వరకు, బాలవ 22:55 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "మఖ 09:57 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:18 నుండి 13:51 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "06:05",
            Sunset: "18:31",
            Thiti: "ద్వాదశి 22:55 వరకు",
            Yamaganda: "07:38 నుండి 09:11 వరకు",
            Yoga: "గణ్డ 18:26 వరకు",
            Varjyam: "18:46 నుండి 20:32 వరకు",
            Moonrise: "15:43",
            Moonset: "04:23, ఏప్రిల్ 11",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:42 వరకు",
            Amrutakalam: "ఏమి లేదు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం 19:04 వరకు",
            Date: "గురువారము, ఏప్రిల్ 10, 2025",
            DurMuhurtamulu: "10:13 నుండి 11:03 వరకు, 15:12 నుండి 16:02 వరకు",
            GulikaiKalam: "09:11 నుండి 10:44 వరకు",
            Karanamulu: "కౌలవ 11:55 వరకు, తైతిల 01:00, ఏప్రిల్ 11 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "పుబ్బ 12:24 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "13:51 నుండి 15:24 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "06:04",
            Sunset: "18:31",
            Thiti: "త్రయోదశి 01:00, ఏప్రిల్ 11 వరకు",
            Yamaganda: "06:04 నుండి 07:37 వరకు",
            Yoga: "వృద్ధి 18:59 వరకు",
            Varjyam: "20:26 నుండి 22:13 వరకు",
            Moonrise: "16:31",
            Moonset: "04:55, ఏప్రిల్ 11",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:52 నుండి 12:42 వరకు",
            Amrutakalam: "07:08 నుండి 08:55 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య",
            Date: "శుక్రవారము, ఏప్రిల్ 11, 2025",
            DurMuhurtamulu: "08:33 నుండి 09:23 వరకు, 12:42 నుండి 13:32 వరకు",
            GulikaiKalam: "07:37 నుండి 09:10 వరకు",
            Karanamulu: "బవ 14:09 వరకు, వణిజ 03:21, ఏప్రిల్ 12 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "ఉత్తర 15:10 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "10:44 నుండి 12:17 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "06:03",
            Sunset: "18:31",
            Thiti: "చతుర్దశి 03:21, ఏప్రిల్ 12 వరకు",
            Yamaganda: "15:24 నుండి 16:58 వరకు",
            Yoga: "ధ్రువ 19:46 వరకు",
            Varjyam: "00:36, ఏప్రిల్ 12 నుండి 02:24, ఏప్రిల్ 12 వరకు",
            Moonrise: "17:18",
            Moonset: "05:27, ఏప్రిల్ 13",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:52 నుండి 12:42 వరకు",
            Amrutakalam: "11:23 నుండి 13:11 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య",
            Date: "శనివారము, ఏప్రిల్ 12, 2025",
            DurMuhurtamulu: "06:02 నుండి 06:52 వరకు, 06:52 నుండి 07:42 వరకు",
            GulikaiKalam: "06:02 నుండి 07:36 వరకు",
            Karanamulu: "విష్టి 16:35 వరకు, బవ 05:51, ఏప్రిల్ 13 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "హస్త 18:08 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "09:10 నుండి 10:43 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "06:02",
            Sunset: "18:31",
            Thiti: "పౌర్ణమి 05:51, ఏప్రిల్ 13 వరకు",
            Yamaganda: "13:51 నుండి 15:24 వరకు",
            Yoga: "వ్యాఘాత 20:41 వరకు",
            Varjyam: "03:09, ఏప్రిల్ 13 నుండి 04:57, ఏప్రిల్ 13 వరకు",
            Moonrise: "18:05",
            Moonset: "05:59, ఏప్రిల్ 13",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "pournami",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:52 నుండి 12:42 వరకు",
            Amrutakalam: "13:58 నుండి 15:46 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య",
            Date: "ఆదివారము, ఏప్రిల్ 13, 2025",
            DurMuhurtamulu: "16:52 నుండి 17:42 వరకు",
            GulikaiKalam: "15:24 నుండి 16:58 వరకు",
            Karanamulu: "బాలవ 19:08 వరకు, కౌలవ నిండా రాత్రి వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "చిత్తా 21:11 వరకు",
            MainNakshatra: "చిత్తా",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:58 నుండి 18:32 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "06:02",
            Sunset: "18:32",
            Thiti: "పాడ్యమి నిండా రాత్రి వరకు",
            Yamaganda: "12:17 నుండి 13:51 వరకు",
            Yoga: "హర్షణ 21:40 వరకు",
            Varjyam: "03:29, ఏప్రిల్ 14 నుండి 05:18, ఏప్రిల్ 14 వరకు",
            Moonrise: "18:53",
            Moonset: "చంద్రాస్తమయం లేదు",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:51 నుండి 12:42 వరకు",
            Amrutakalam: "14:18 నుండి 16:07 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల",
            Date: "సోమవారము, ఏప్రిల్ 14, 2025",
            DurMuhurtamulu: "12:42 నుండి 13:32 వరకు, 15:12 నుండి 16:02 వరకు",
            GulikaiKalam: "13:50 నుండి 15:24 వరకు",
            Karanamulu: "కౌలవ 08:25 వరకు, తైతిల 21:40 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "స్వాతి 00:13, ఏప్రిల్ 15 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "07:35 నుండి 09:09 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "06:01",
            Sunset: "18:32",
            Thiti: "పాడ్యమి 08:25 వరకు",
            Yamaganda: "10:43 నుండి 12:17 వరకు",
            Yoga: "వజ్ర 22:39 వరకు",
            Varjyam: "ఏమి లేదు",
            Moonrise: "19:42",
            Moonset: "06:34",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "14",
            LordImage: require("../images/holidays/3-4)Ambedkar.png"),
          },
          {
            Abhijit: "11:51 నుండి 12:41 వరకు",
            Amrutakalam: "17:17 నుండి 19:05 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల 20:27 వరకు",
            Date: "మంగళవారము, ఏప్రిల్ 15, 2025",
            DurMuhurtamulu: "08:31 నుండి 09:21 వరకు, 23:07 నుండి 23:53 వరకు",
            GulikaiKalam: "12:16 నుండి 13:50 వరకు",
            Karanamulu: "బవ 10:55 వరకు, వణిజ 00:07, ఏప్రిల్ 16 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "విశాఖ 03:10, ఏప్రిల్ 16 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:24 నుండి 16:58 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "06:00",
            Sunset: "18:32",
            Thiti: "విదియ 10:55 వరకు",
            Yamaganda: "09:08 నుండి 10:42 వరకు",
            Yoga: "సిద్ధి 23:33 వరకు",
            Varjyam: "06:31 నుండి 08:19 వరకు",
            Moonrise: "20:34",
            Moonset: "07:11",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "18:20 నుండి 20:06 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "బుధవారము, ఏప్రిల్ 16, 2025",
            DurMuhurtamulu: "11:51 నుండి 12:41 వరకు",
            GulikaiKalam: "10:42 నుండి 12:16 వరకు",
            Karanamulu: "విష్టి 13:16 వరకు, బవ 02:22, ఏప్రిల్ 17 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "అనూరాధ 05:55, ఏప్రిల్ 17 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:16 నుండి 13:50 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "06:00",
            Sunset: "18:32",
            Thiti: "తదియ 13:16 వరకు",
            Yamaganda: "07:34 నుండి 09:08 వరకు",
            Yoga: "వ్యతీపాత 00:19, ఏప్రిల్ 17 వరకు",
            Varjyam: "07:38 నుండి 09:25 వరకు",
            Moonrise: "21:27",
            Moonset: "07:52",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:51 నుండి 12:41 వరకు",
            Amrutakalam: "22:39 నుండి 00:25, ఏప్రిల్ 18 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "గురువారము, ఏప్రిల్ 17, 2025",
            DurMuhurtamulu: "10:10 నుండి 11:00 వరకు, 15:12 నుండి 16:02 వరకు",
            GulikaiKalam: "09:07 నుండి 10:42 వరకు",
            Karanamulu: "బాలవ 15:23 వరకు, కౌలవ 04:18, ఏప్రిల్ 18 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "జ్యేష్ఠ నిండా రాత్రి వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:50 నుండి 15:24 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:59",
            Sunset: "18:33",
            Thiti: "చవితి 15:23 వరకు",
            Yamaganda: "05:59 నుండి 07:33 వరకు",
            Yoga: "వారీయన 00:50, ఏప్రిల్ 18 వరకు",
            Varjyam: "12:05 నుండి 13:51 వరకు",
            Moonrise: "22:21",
            Moonset: "08:37",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:50 నుండి 12:41 వరకు",
            Amrutakalam: "03:25, ఏప్రిల్ 19 నుండి 05:09, ఏప్రిల్ 19 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము 08:21 వరకు",
            Date: "శుక్రవారము, ఏప్రిల్ 18, 2025",
            DurMuhurtamulu: "08:29 నుండి 09:19 వరకు, 12:41 నుండి 13:31 వరకు",
            GulikaiKalam: "07:33 నుండి 09:07 వరకు",
            Karanamulu: "తైతిల 17:07 వరకు, బవ 05:48, ఏప్రిల్ 19 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "జ్యేష్ఠ 08:21 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:41 నుండి 12:16 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:58",
            Sunset: "18:33",
            Thiti: "పంచమి 17:07 వరకు",
            Yamaganda: "15:24 నుండి 16:59 వరకు",
            Yoga: "పరిఘ 01:04, ఏప్రిల్ 19 వరకు",
            Varjyam: "17:01 నుండి 18:45 వరకు",
            Moonrise: "23:15",
            Moonset: "09:27",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "18",
            LordImage: require("../images/holidays/3-6)GoodFriday.png"),
          },
          {
            Abhijit: "11:50 నుండి 12:41 వరకు",
            Amrutakalam: "ఏమి లేదు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "శనివారము, ఏప్రిల్ 19, 2025",
            DurMuhurtamulu: "05:58 నుండి 06:48 వరకు, 06:48 నుండి 07:38 వరకు",
            GulikaiKalam: "05:58 నుండి 07:32 వరకు",
            Karanamulu: "వణిజ 18:21 వరకు, విష్టి నిండా రాత్రి వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "మూల 10:21 వరకు",
            MainNakshatra: "మూల",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:06 నుండి 10:41 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:58",
            Sunset: "18:33",
            Thiti: "షష్టి 18:21 వరకు",
            Yamaganda: "13:50 నుండి 15:24 వరకు",
            Yoga: "శివ 00:53, ఏప్రిల్ 20 వరకు",
            Varjyam: "08:37 నుండి 10:21 వరకు, 20:32 నుండి 22:13 వరకు",
            Moonrise: "00:08, ఏప్రిల్ 20",
            Moonset: "10:21",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:50 నుండి 12:40 వరకు",
            Amrutakalam: "06:43 నుండి 08:24 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు 18:04 వరకు",
            Date: "ఆదివారము, ఏప్రిల్ 20, 2025",
            DurMuhurtamulu: "16:53 నుండి 17:43 వరకు",
            GulikaiKalam: "15:24 నుండి 16:59 వరకు",
            Karanamulu: "విష్టి 06:46 వరకు, బవ 19:00 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "పూర్వాషాఢ 11:48 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:59 నుండి 18:33 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:57",
            Sunset: "18:33",
            Thiti: "సప్తమి 19:00 వరకు",
            Yamaganda: "12:15 నుండి 13:50 వరకు",
            Yoga: "సిద్ధ 00:13, ఏప్రిల్ 21 వరకు",
            Varjyam: "20:04 నుండి 21:44 వరకు",
            Moonrise: "00:57, ఏప్రిల్ 21",
            Moonset: "11:18",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:50 నుండి 12:40 వరకు",
            Amrutakalam:
              "06:00 నుండి 07:39 వరకు, 02:17, ఏప్రిల్ 22 నుండి 03:53, ఏప్రిల్ 22 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము",
            Date: "సోమవారము, ఏప్రిల్ 21, 2025",
            DurMuhurtamulu: "12:40 నుండి 13:31 వరకు, 15:12 నుండి 16:02 వరకు",
            GulikaiKalam: "13:50 నుండి 15:24 వరకు",
            Karanamulu: "బాలవ 07:04 వరకు, కౌలవ 18:58 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "ఉత్తరాషాఢ 12:37 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "07:31 నుండి 09:06 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:56",
            Sunset: "18:34",
            Thiti: "అష్టమి 18:58 వరకు",
            Yamaganda: "10:40 నుండి 12:15 వరకు",
            Yoga: "సాధ్య 23:00 వరకు",
            Varjyam: "16:38 నుండి 18:15 వరకు",
            Moonrise: "01:43, ఏప్రిల్ 22",
            Moonset: "12:16",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:40 వరకు",
            Amrutakalam: "01:59, ఏప్రిల్ 23 నుండి 03:33, ఏప్రిల్ 23 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము 00:31, ఏప్రిల్ 23 వరకు",
            Date: "మంగళవారము, ఏప్రిల్ 22, 2025",
            DurMuhurtamulu: "08:27 నుండి 09:18 వరకు, 23:06 నుండి 23:52 వరకు",
            GulikaiKalam: "12:15 నుండి 13:50 వరకు",
            Karanamulu: "తైతిల 06:41 వరకు, బవ 18:12 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "శ్రవణం 12:44 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "15:24 నుండి 16:59 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:56",
            Sunset: "18:34",
            Thiti: "నవమి 18:12 వరకు",
            Yamaganda: "09:05 నుండి 10:40 వరకు",
            Yoga: "శుభ 21:13 వరకు",
            Varjyam: "16:38 నుండి 18:11 వరకు",
            Moonrise: "02:26, ఏప్రిల్ 23",
            Moonset: "13:15",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "04:01, ఏప్రిల్ 24 నుండి 05:31, ఏప్రిల్ 24 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము",
            Date: "బుధవారము, ఏప్రిల్ 23, 2025",
            DurMuhurtamulu: "11:49 నుండి 12:40 వరకు",
            GulikaiKalam: "10:40 నుండి 12:15 వరకు",
            Karanamulu: "విష్టి 16:43 వరకు, బవ 03:42, ఏప్రిల్ 24 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "ధనిష్ఠ 12:07 వరకు",
            MainNakshatra: "ధనిష్ఠ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "12:15 నుండి 13:49 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:55",
            Sunset: "18:34",
            Thiti: "దశమి 16:43 వరకు",
            Yamaganda: "07:30 నుండి 09:05 వరకు",
            Yoga: "శుక్ల 18:51 వరకు",
            Varjyam: "18:56 నుండి 20:27 వరకు",
            Moonrise: "03:06, ఏప్రిల్ 24",
            Moonset: "14:13",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:40 వరకు",
            Amrutakalam: "01:32, ఏప్రిల్ 25 నుండి 03:00, ఏప్రిల్ 25 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము 03:26, ఏప్రిల్ 25 వరకు",
            Date: "గురువారము, ఏప్రిల్ 24, 2025",
            DurMuhurtamulu: "10:08 నుండి 10:58 వరకు, 15:12 నుండి 16:02 వరకు",
            GulikaiKalam: "09:04 నుండి 10:39 వరకు",
            Karanamulu: "బాలవ 14:32 వరకు, కౌలవ 01:12, ఏప్రిల్ 25 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "శతభిషం 10:49 వరకు",
            MainNakshatra: "శతభిషం",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "13:49 నుండి 15:24 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:54",
            Sunset: "18:34",
            Thiti: "ఏకాదశి 14:32 వరకు",
            Yamaganda: "05:54 నుండి 07:29 వరకు",
            Yoga: "బ్రహ్మ 15:56 వరకు",
            Varjyam: "16:42 నుండి 18:11 వరకు",
            Moonrise: "03:46, ఏప్రిల్ 25",
            Moonset: "15:11",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:40 వరకు",
            Amrutakalam: "02:08, ఏప్రిల్ 26 నుండి 03:35, ఏప్రిల్ 26 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం",
            Date: "శుక్రవారము, ఏప్రిల్ 25, 2025",
            DurMuhurtamulu: "08:26 నుండి 09:17 వరకు, 12:40 నుండి 13:30 వరకు",
            GulikaiKalam: "07:29 నుండి 09:04 వరకు",
            Karanamulu: "తైతిల 11:44 వరకు, బవ 22:09 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "పూర్వాభాద్ర 08:53 వరకు",
            MainNakshatra: "పూర్వాభాద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:39 నుండి 12:14 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:54",
            Sunset: "18:35",
            Thiti: "ద్వాదశి 11:44 వరకు",
            Yamaganda: "15:24 నుండి 17:00 వరకు",
            Yoga: "ఐన్ద్ర 12:31 వరకు",
            Varjyam: "17:31 నుండి 18:57 వరకు",
            Moonrise: "04:27, ఏప్రిల్ 26",
            Moonset: "16:10",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:39 వరకు",
            Amrutakalam: "01:31, ఏప్రిల్ 27 నుండి 02:56, ఏప్రిల్ 27 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం 03:39, ఏప్రిల్ 27 వరకు",
            Date: "శనివారము, ఏప్రిల్ 26, 2025",
            DurMuhurtamulu: "05:53 నుండి 06:44 వరకు, 06:44 నుండి 07:35 వరకు",
            GulikaiKalam: "05:53 నుండి 07:28 వరకు",
            Karanamulu:
              "వణిజ 08:27 వరకు, విష్టి 18:40 వరకు, శకుని 04:49, ఏప్రిల్ 27 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "ఉత్తరాభాద్ర 06:27 వరకు, రేవతి 03:39, ఏప్రిల్ 27 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:04 నుండి 10:39 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:53",
            Sunset: "18:35",
            Thiti: "త్రయోదశి 08:27 వరకు, చతుర్దశి 04:49, ఏప్రిల్ 27 వరకు",
            Yamaganda: "13:49 నుండి 15:25 వరకు",
            Yoga: "వైధృతి 08:42 వరకు",
            Varjyam: "17:03 నుండి 18:28 వరకు",
            Moonrise: "05:10, ఏప్రిల్ 27",
            Moonset: "17:10",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:39 వరకు",
            Amrutakalam: "18:20 నుండి 19:44 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం",
            Date: "ఆదివారము, ఏప్రిల్ 27, 2025",
            DurMuhurtamulu: "16:54 నుండి 17:44 వరకు",
            GulikaiKalam: "15:25 నుండి 17:00 వరకు",
            Karanamulu: "చతుష్పాద 14:55 వరకు, నాగ 01:00, ఏప్రిల్ 28 వరకు",
            Masam: "చైత్ర మాసం",
            Nakshatra1: "అశ్విని 00:38, ఏప్రిల్ 28 వరకు",
            MainNakshatra: "అశ్విని",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "17:00 నుండి 18:35 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:53",
            Sunset: "18:35",
            Thiti: "అమావాస్య 01:00, ఏప్రిల్ 28 వరకు",
            Yamaganda: "12:14 నుండి 13:49 వరకు",
            Yoga: "ప్రీతి 00:19, ఏప్రిల్ 28 వరకు",
            Varjyam: "21:08 నుండి 22:32 వరకు",
            Moonrise: "చంద్రోదయం లేదు",
            Moonset: "18:14",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "amavasya",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:39 వరకు",
            Amrutakalam: "17:26 నుండి 18:50 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం 02:53, ఏప్రిల్ 29 వరకు",
            Date: "సోమవారము, ఏప్రిల్ 28, 2025",
            DurMuhurtamulu: "12:39 నుండి 13:30 వరకు, 15:12 నుండి 16:03 వరకు",
            GulikaiKalam: "13:49 నుండి 15:25 వరకు",
            Karanamulu: "కింస్తుఘ్న 11:05 వరకు, బవ 21:10 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "భరణి 21:37 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "07:27 నుండి 09:03 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:52",
            Sunset: "18:36",
            Thiti: "పాడ్యమి 21:10 వరకు",
            Yamaganda: "10:38 నుండి 12:14 వరకు",
            Yoga: "ఆయుష్మాన్ 20:03 వరకు",
            Varjyam: "09:02 నుండి 10:26 వరకు",
            Moonrise: "05:56",
            Moonset: "19:21",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:39 వరకు",
            Amrutakalam: "16:40 నుండి 18:04 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం",
            Date: "మంగళవారము, ఏప్రిల్ 29, 2025",
            DurMuhurtamulu: "08:24 నుండి 09:15 వరకు, 23:06 నుండి 23:51 వరకు",
            GulikaiKalam: "12:14 నుండి 13:49 వరకు",
            Karanamulu: "బాలవ 07:19 వరకు, కౌలవ 17:31 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "కృత్తిక 18:47 వరకు",
            MainNakshatra: "కృత్తిక",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "15:25 నుండి 17:00 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:51",
            Sunset: "18:36",
            Thiti: "విదియ 17:31 వరకు",
            Yamaganda: "09:02 నుండి 10:38 వరకు",
            Yoga: "సౌభాగ్య 15:54 వరకు",
            Varjyam: "08:12 నుండి 09:37 వరకు",
            Moonrise: "06:49",
            Moonset: "20:30",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "13:26 నుండి 14:52 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం 03:15, మే 01 వరకు",
            Date: "బుధవారము, ఏప్రిల్ 30, 2025",
            DurMuhurtamulu: "11:48 నుండి 12:39 వరకు",
            GulikaiKalam: "10:38 నుండి 12:13 వరకు",
            Karanamulu: "గర 14:12 వరకు, వణిజ 00:43, మే 01 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "రోహిణి 16:18 వరకు",
            MainNakshatra: "రోహిణి",
            Paksham: "శుక్ల పక్షము",
            RahuKalam: "12:13 నుండి 13:49 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:51",
            Sunset: "18:36",
            Thiti: "తదియ 14:12 వరకు",
            Yamaganda: "07:26 నుండి 09:02 వరకు",
            Yoga: "శోభన 12:02 వరకు",
            Varjyam: "09:07 నుండి 10:33 వరకు, 21:26 నుండి 22:55 వరకు",
            Moonrise: "07:46",
            Moonset: "21:38",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
        ],
      },
      {
        day: [
          {
            Abhijit: "11:48 నుండి 12:39 వరకు",
            Amrutakalam: "06:16 నుండి 07:44 వరకు, 03:36 నుండి 05:07 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం",
            Date: "గురువారము, మే 01, 2025",
            DurMuhurtamulu: "10:06 నుండి 10:57 వరకు, 15:12 నుండి 16:03 వరకు",
            GulikaiKalam: "09:02 నుండి 10:38 వరకు",
            Karanamulu: "విష్టి 11:23 వరకు, బవ 22:13 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "మృగశిర 14:21 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "13:49 నుండి 15:25 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:50",
            Sunset: "18:36",
            Thiti: "చవితి 11:23 వరకు",
            Yamaganda: "05:50 నుండి 07:26 వరకు",
            Yoga: "అతిగణ్డ 08:34 వరకు, సుకర్మా 05:39 వరకు",
            Varjyam: "22:18 నుండి 23:49 వరకు",
            Moonrise: "08:49",
            Moonset: "22:42",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "1",
            LordImage: require("../images/holidays/4-0)Labourday.png"),
          },
          {
            Abhijit: "11:48 నుండి 12:39 వరకు",
            Amrutakalam: "ఏమి లేదు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం",
            Date: "శుక్రవారము, మే 02, 2025",
            DurMuhurtamulu: "08:23 నుండి 09:14 వరకు, 12:39 నుండి 13:30 వరకు",
            GulikaiKalam: "07:26 నుండి 09:01 వరకు",
            Karanamulu: "బాలవ 09:14 వరకు, కౌలవ 20:27 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "ఆరుద్ర 13:04 వరకు",
            MainNakshatra: "ఆరుద్ర",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "10:37 నుండి 12:13 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:50",
            Sunset: "18:37",
            Thiti: "పంచమి 09:14 వరకు",
            Yamaganda: "15:25 నుండి 17:01 వరకు",
            Yoga: "ధృతి 03:20 వరకు",
            Varjyam: "00:49 నుండి 02:23 వరకు",
            Moonrise: "09:53",
            Moonset: "23:39",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:39 వరకు",
            Amrutakalam: "10:13 నుండి 11:47 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం 06:37 వరకు",
            Date: "శనివారము, మే 03, 2025",
            DurMuhurtamulu: "05:49 నుండి 06:40 వరకు, 06:40 నుండి 07:32 వరకు",
            GulikaiKalam: "05:49 నుండి 07:25 వరకు",
            Karanamulu: "తైతిల 07:51 వరకు, గర 19:28 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "పునర్వసు 12:34 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "09:01 నుండి 10:37 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:49",
            Sunset: "18:37",
            Thiti: "షష్టి 07:51 వరకు",
            Yamaganda: "13:49 నుండి 15:25 వరకు",
            Yoga: "శూల 01:41 వరకు",
            Varjyam: "20:40 నుండి 22:18 వరకు",
            Moonrise: "10:55",
            Moonset: "00:29",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "06:24 నుండి 08:01 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "ఆదివారము, మే 04, 2025",
            DurMuhurtamulu: "16:55 నుండి 17:46 వరకు",
            GulikaiKalam: "15:25 నుండి 17:01 వరకు",
            Karanamulu: "వణిజ 07:18 వరకు, విష్టి 19:21 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "పుష్యమి 12:53 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "17:01 నుండి 18:37 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:49",
            Sunset: "18:37",
            Thiti: "సప్తమి 07:18 వరకు",
            Yamaganda: "12:13 నుండి 13:49 వరకు",
            Yoga: "గణ్డ 00:42 వరకు",
            Varjyam: "02:17 నుండి 03:58 వరకు",
            Moonrise: "11:54",
            Moonset: "01:13",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "12:20 నుండి 14:01 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం 14:01 వరకు",
            Date: "సోమవారము, మే 05, 2025",
            DurMuhurtamulu: "12:39 నుండి 13:30 వరకు, 15:12 నుండి 16:04 వరకు",
            GulikaiKalam: "13:49 నుండి 15:25 వరకు",
            Karanamulu: "బవ 07:35 వరకు, బాలవ 20:01 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "ఆశ్లేష 14:01 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "07:24 నుండి 09:01 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:48",
            Sunset: "18:38",
            Thiti: "అష్టమి 07:35 వరకు",
            Yamaganda: "10:37 నుండి 12:13 వరకు",
            Yoga: "వృద్ధి 00:20 వరకు",
            Varjyam: "02:56 నుండి 04:40 వరకు",
            Moonrise: "12:49",
            Moonset: "01:51",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "13:17 నుండి 15:00 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం",
            Date: "మంగళవారము, మే 06, 2025",
            DurMuhurtamulu: "08:22 నుండి 09:13 వరకు, 23:06 నుండి 23:50 వరకు",
            GulikaiKalam: "12:13 నుండి 13:49 వరకు",
            Karanamulu: "కౌలవ 08:38 వరకు, తైతిల 21:24 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "మఖ 15:52 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "15:25 నుండి 17:02 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:48",
            Sunset: "18:38",
            Thiti: "నవమి 08:38 వరకు",
            Yamaganda: "09:00 నుండి 10:37 వరకు",
            Yoga: "ధ్రువ 00:30 వరకు",
            Varjyam: "00:40 నుండి 02:26 వరకు",
            Moonrise: "13:40",
            Moonset: "02:25",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "11:14 నుండి 13:00 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం 00:57, మే 08 వరకు",
            Date: "బుధవారము, మే 07, 2025",
            DurMuhurtamulu: "11:47 నుండి 12:39 వరకు",
            GulikaiKalam: "10:36 నుండి 12:13 వరకు",
            Karanamulu: "బవ 10:19 వరకు, వణిజ 23:21 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "పుబ్బ 18:17 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "12:13 నుండి 13:49 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:47",
            Sunset: "18:38",
            Thiti: "దశమి 10:19 వరకు",
            Yamaganda: "07:24 నుండి 09:00 వరకు",
            Yoga: "వ్యాఘాత 01:05 వరకు",
            Varjyam: "02:20 నుండి 04:07 వరకు",
            Moonrise: "14:29",
            Moonset: "02:57",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:38 వరకు",
            Amrutakalam: "13:03 నుండి 14:51 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య",
            Date: "గురువారము, మే 08, 2025",
            DurMuhurtamulu: "10:04 నుండి 10:56 వరకు, 15:13 నుండి 16:04 వరకు",
            GulikaiKalam: "09:00 నుండి 10:36 వరకు",
            Karanamulu: "విష్టి 12:29 వరకు, బవ 01:41 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "ఉత్తర 21:06 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "13:49 నుండి 15:26 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:47",
            Sunset: "18:39",
            Thiti: "ఏకాదశి 12:29 వరకు",
            Yamaganda: "05:47 నుండి 07:23 వరకు",
            Yoga: "హర్షణ 01:57 వరకు",
            Varjyam: "ఏమి లేదు",
            Moonrise: "15:16",
            Moonset: "03:29",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:38 వరకు",
            Amrutakalam: "17:23 నుండి 19:11 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య",
            Date: "శుక్రవారము, మే 09, 2025",
            DurMuhurtamulu: "08:21 నుండి 09:12 వరకు, 12:38 నుండి 13:30 వరకు",
            GulikaiKalam: "07:23 నుండి 09:00 వరకు",
            Karanamulu: "బాలవ 14:56 వరకు, కౌలవ 04:12 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "హస్త 00:09 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "10:36 నుండి 12:13 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:46",
            Sunset: "18:39",
            Thiti: "ద్వాదశి 14:56 వరకు",
            Yamaganda: "15:26 నుండి 17:02 వరకు",
            Yoga: "వజ్ర 02:58 వరకు",
            Varjyam: "06:34 నుండి 08:22 వరకు",
            Moonrise: "16:02",
            Moonset: "04:01",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:38 వరకు",
            Amrutakalam: "20:01 నుండి 21:50 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య 13:42 వరకు",
            Date: "శనివారము, మే 10, 2025",
            DurMuhurtamulu: "05:46 నుండి 06:38 వరకు, 06:38 నుండి 07:29 వరకు",
            GulikaiKalam: "05:46 నుండి 07:23 వరకు",
            Karanamulu: "తైతిల 17:29 వరకు, బవ నిండా రాత్రి వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "చిత్తా 03:15 వరకు",
            MainNakshatra: "చిత్తా",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "08:59 నుండి 10:36 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:46",
            Sunset: "18:39",
            Thiti: "త్రయోదశి 17:29 వరకు",
            Yamaganda: "13:49 నుండి 15:26 వరకు",
            Yoga: "సిద్ధి 04:01 వరకు",
            Varjyam: "09:11 నుండి 10:59 వరకు",
            Moonrise: "16:50",
            Moonset: "04:35",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:38 వరకు",
            Amrutakalam: "20:22 నుండి 22:10 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల",
            Date: "ఆదివారము, మే 11, 2025",
            DurMuhurtamulu: "16:56 నుండి 17:48 వరకు",
            GulikaiKalam: "15:26 నుండి 17:03 వరకు",
            Karanamulu: "బవ 06:46 వరకు, వణిజ 20:01 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "స్వాతి నిండా రాత్రి వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "17:03 నుండి 18:40 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:46",
            Sunset: "18:40",
            Thiti: "చతుర్దశి 20:01 వరకు",
            Yamaganda: "12:13 నుండి 13:49 వరకు",
            Yoga: "వ్యతీపాత 05:01 వరకు",
            Varjyam: "09:34 నుండి 11:22 వరకు",
            Moonrise: "17:38",
            Moonset: "05:11",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:38 వరకు",
            Amrutakalam: "23:18 నుండి 01:05, మే 13 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల 02:27, మే 13 వరకు",
            Date: "సోమవారము, మే 12, 2025",
            DurMuhurtamulu: "12:38 నుండి 13:30 వరకు, 15:13 నుండి 16:05 వరకు",
            GulikaiKalam: "13:49 నుండి 15:26 వరకు",
            Karanamulu: "విష్టి 09:14 వరకు, బవ 22:25 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "స్వాతి 06:17 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "07:22 నుండి 08:59 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:45",
            Sunset: "18:40",
            Thiti: "పౌర్ణమి 22:25 వరకు",
            Yamaganda: "10:36 నుండి 12:13 వరకు",
            Yoga: "వారీయన నిండా రాత్రి వరకు",
            Varjyam: "12:33 నుండి 14:21 వరకు",
            Moonrise: "18:29",
            Moonset: "ఏమి లేదు",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "pournami",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:38 వరకు",
            Amrutakalam: "00:14 నుండి 02:01, మే 14 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "మంగళవారము, మే 13, 2025",
            DurMuhurtamulu: "08:20 నుండి 09:12 వరకు, 23:06 నుండి 23:50 వరకు",
            GulikaiKalam: "12:13 నుండి 13:50 వరకు",
            Karanamulu: "బాలవ 11:32 వరకు, కౌలవ 00:35 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "విశాఖ 09:09 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "15:26 నుండి 17:03 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:45",
            Sunset: "18:40",
            Thiti: "పాడ్యమి 00:35 వరకు",
            Yamaganda: "08:59 నుండి 10:36 వరకు",
            Yoga: "వారీయన 05:53 వరకు",
            Varjyam: "13:35 నుండి 15:22 వరకు",
            Moonrise: "19:22",
            Moonset: "05:51",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:38 వరకు",
            Amrutakalam: "04:28 నుండి 06:13, మే 15 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "బుధవారము, మే 14, 2025",
            DurMuhurtamulu: "11:47 నుండి 12:38 వరకు",
            GulikaiKalam: "10:36 నుండి 12:13 వరకు",
            Karanamulu: "తైతిల 13:34 వరకు, బవ 02:29 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "అనూరాధ 11:47 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "12:13 నుండి 13:50 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:45",
            Sunset: "18:41",
            Thiti: "విదియ 02:29 వరకు",
            Yamaganda: "07:22 నుండి 08:59 వరకు",
            Yoga: "పరిఘ 06:34 వరకు",
            Varjyam: "17:56 నుండి 19:41 వరకు",
            Moonrise: "20:17",
            Moonset: "06:35",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "ఏమి లేదు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము 14:07 వరకు",
            Date: "గురువారము, మే 15, 2025",
            DurMuhurtamulu: "10:03 నుండి 10:55 వరకు, 15:14 నుండి 16:06 వరకు",
            GulikaiKalam: "08:58 నుండి 10:36 వరకు",
            Karanamulu: "వణిజ 15:18 వరకు, విష్టి 04:02, మే 16 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "జ్యేష్ఠ 14:07 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "13:50 నుండి 15:27 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:44",
            Sunset: "18:41",
            Thiti: "తదియ 04:02 వరకు",
            Yamaganda: "05:44 నుండి 07:21 వరకు",
            Yoga: "శివ 07:02 వరకు",
            Varjyam: "22:47 నుండి 00:31, మే 16 వరకు",
            Moonrise: "21:11",
            Moonset: "07:24",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "09:11 నుండి 10:55 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "శుక్రవారము, మే 16, 2025",
            DurMuhurtamulu: "08:19 నుండి 09:11 వరకు, 12:39 నుండి 13:30 వరకు",
            GulikaiKalam: "07:21 నుండి 08:58 వరకు",
            Karanamulu: "బవ 16:41 వరకు, బాలవ 05:13, మే 17 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "మూల 16:07 వరకు",
            MainNakshatra: "మూల",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "10:35 నుండి 12:13 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:44",
            Sunset: "18:41",
            Thiti: "చవితి 05:13 వరకు",
            Yamaganda: "15:27 నుండి 17:04 వరకు",
            Yoga: "సిద్ధ 07:15 వరకు",
            Varjyam:
              "14:23 నుండి 16:07 వరకు, 02:22, మే 17 నుండి 04:04, మే 17 వరకు",
            Moonrise: "22:04",
            Moonset: "08:17",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "12:36 నుండి 14:19 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు 00:04, మే 18 వరకు",
            Date: "శనివారము, మే 17, 2025",
            DurMuhurtamulu: "05:44 నుండి 06:36 వరకు, 06:36 నుండి 07:27 వరకు",
            GulikaiKalam: "05:44 నుండి 07:21 వరకు",
            Karanamulu: "కౌలవ 17:39 వరకు, తైతిల నిండా రాత్రి వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "పూర్వాషాఢ 17:44 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "08:58 నుండి 10:35 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:44",
            Sunset: "18:42",
            Thiti: "పంచమి నిండా రాత్రి వరకు",
            Yamaganda: "13:50 నుండి 15:27 వరకు",
            Yoga: "సాధ్య 07:09 వరకు",
            Varjyam: "02:07, మే 18 నుండి 03:47, మే 18 వరకు",
            Moonrise: "22:54",
            Moonset: "09:13",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "12:10 నుండి 13:51 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము",
            Date: "ఆదివారము, మే 18, 2025",
            DurMuhurtamulu: "16:58 నుండి 17:50 వరకు",
            GulikaiKalam: "15:27 నుండి 17:05 వరకు",
            Karanamulu: "తైతిల 05:57 వరకు, బవ 18:08 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "ఉత్తరాషాఢ 18:52 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "17:05 నుండి 18:42 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:43",
            Sunset: "18:42",
            Thiti: "పంచమి 05:57 వరకు",
            Yamaganda: "12:13 నుండి 13:50 వరకు",
            Yoga: "శుభ 06:43 వరకు",
            Varjyam: "22:59 నుండి 00:37, మే 19 వరకు",
            Moonrise: "23:40",
            Moonset: "10:10",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "08:49 నుండి 10:28 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము",
            Date: "సోమవారము, మే 19, 2025",
            DurMuhurtamulu: "12:39 నుండి 13:31 వరకు, 15:15 నుండి 16:07 వరకు",
            GulikaiKalam: "13:50 నుండి 15:28 వరకు",
            Karanamulu: "వణిజ 06:11 వరకు, విష్టి 18:05 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "శ్రవణం 19:29 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "07:21 నుండి 08:58 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:43",
            Sunset: "18:42",
            Thiti: "షష్టి 06:11 వరకు",
            Yamaganda: "10:35 నుండి 12:13 వరకు",
            Yoga: "శుక్ల 05:53 వరకు",
            Varjyam: "23:30 నుండి 01:06, మే 20 వరకు",
            Moonrise: "00:22, మే 20",
            Moonset: "11:07",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "09:07 నుండి 10:43 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము 07:35 వరకు",
            Date: "మంగళవారము, మే 20, 2025",
            DurMuhurtamulu: "08:19 నుండి 09:11 వరకు, 23:07 నుండి 23:51 వరకు",
            GulikaiKalam: "12:13 నుండి 13:50 వరకు",
            Karanamulu:
              "బవ 05:51 వరకు, బాలవ 17:28 వరకు, కౌలవ 04:55, మే 21 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "ధనిష్ఠ 19:32 వరకు",
            MainNakshatra: "ధనిష్ఠ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "15:28 నుండి 17:05 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:43",
            Sunset: "18:43",
            Thiti: "సప్తమి 05:51 వరకు, అష్టమి 04:55, మే 21 వరకు",
            Yamaganda: "08:58 నుండి 10:35 వరకు",
            Yoga: "ఐన్ద్ర 02:50 వరకు",
            Varjyam: "02:34 నుండి 04:07 వరకు",
            Moonrise: "01:03",
            Moonset: "12:03",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "11:56 నుండి 13:30 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము",
            Date: "బుధవారము, మే 21, 2025",
            DurMuhurtamulu: "11:47 నుండి 12:39 వరకు",
            GulikaiKalam: "10:35 నుండి 12:13 వరకు",
            Karanamulu: "తైతిల 16:13 వరకు, బవ 03:21, మే 22 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "శతభిషం 18:58 వరకు",
            MainNakshatra: "శతభిషం",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "12:13 నుండి 13:50 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:43",
            Sunset: "18:43",
            Thiti: "నవమి 03:21 వరకు",
            Yamaganda: "07:20 నుండి 08:58 వరకు",
            Yoga: "వైధృతి 00:35 వరకు",
            Varjyam: "01:03 నుండి 02:34 వరకు",
            Moonrise: "01:41, మే 22",
            Moonset: "12:59",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "10:11 నుండి 11:42 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము 12:08 వరకు",
            Date: "గురువారము, మే 22, 2025",
            DurMuhurtamulu: "10:03 నుండి 10:55 వరకు, 15:15 నుండి 16:07 వరకు",
            GulikaiKalam: "08:58 నుండి 10:35 వరకు",
            Karanamulu: "వణిజ 14:21 వరకు, విష్టి 01:12, మే 23 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "పూర్వాభాద్ర 17:47 వరకు",
            MainNakshatra: "పూర్వాభాద్ర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "13:51 నుండి 15:28 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:42",
            Sunset: "18:44",
            Thiti: "దశమి 01:12 వరకు",
            Yamaganda: "05:42 నుండి 07:20 వరకు",
            Yoga: "విష్కుమ్భ 21:50 వరకు",
            Varjyam: "02:41 నుండి 04:10 వరకు",
            Moonrise: "02:20",
            Moonset: "13:56",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "11:35 నుండి 13:04 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం",
            Date: "శుక్రవారము, మే 23, 2025",
            DurMuhurtamulu: "08:19 నుండి 09:11 వరకు, 12:39 నుండి 13:31 వరకు",
            GulikaiKalam: "07:20 నుండి 08:58 వరకు",
            Karanamulu: "బవ 11:54 వరకు, బాలవ 22:29 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "ఉత్తరాభాద్ర 16:02 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "10:35 నుండి 12:13 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:42",
            Sunset: "18:44",
            Thiti: "ఏకాదశి 22:29 వరకు",
            Yamaganda: "15:28 నుండి 17:06 వరకు",
            Yoga: "ప్రీతి 18:37 వరకు",
            Varjyam: "02:55 నుండి 04:22 వరకు",
            Moonrise: "03:00, మే 24",
            Moonset: "14:53",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "11:37 నుండి 13:05 వరకు, 04:47 నుండి 06:12 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం 13:48 వరకు",
            Date: "శనివారము, మే 24, 2025",
            DurMuhurtamulu: "05:42 నుండి 06:34 వరకు, 06:34 నుండి 07:26 వరకు",
            GulikaiKalam: "05:42 నుండి 07:20 వరకు",
            Karanamulu:
              "కౌలవ 08:57 వరకు, తైతిల 19:20 వరకు, బవ 05:37, మే 25 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "రేవతి 13:48 వరకు",
            MainNakshatra: "రేవతి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "08:58 నుండి 10:35 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:42",
            Sunset: "18:44",
            Thiti: "ద్వాదశి 19:20 వరకు",
            Yamaganda: "13:51 నుండి 15:29 వరకు",
            Yoga: "ఆయుష్మాన్ 15:01 వరకు",
            Varjyam: "ఏమి లేదు",
            Moonrise: "03:44",
            Moonset: "15:53",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "04:09 నుండి 05:34 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం",
            Date: "ఆదివారము, మే 25, 2025",
            DurMuhurtamulu: "17:00 నుండి 17:52 వరకు",
            GulikaiKalam: "15:29 నుండి 17:07 వరకు",
            Karanamulu: "వణిజ 15:51 వరకు, విష్టి 02:01, మే 26 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "అశ్విని 11:12 వరకు",
            MainNakshatra: "అశ్విని",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "17:07 నుండి 18:45 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:42",
            Sunset: "18:45",
            Thiti: "త్రయోదశి 15:51 వరకు",
            Yamaganda: "12:13 నుండి 13:51 వరకు",
            Yoga: "సౌభాగ్య 11:06 వరకు",
            Varjyam: "07:38 నుండి 09:04 వరకు, 19:41 నుండి 21:05 వరకు",
            Moonrise: "04:32",
            Moonset: "16:57",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:39 వరకు",
            Amrutakalam: "03:25, మే 27 నుండి 04:50, మే 27 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం 13:40 వరకు",
            Date: "సోమవారము, మే 26, 2025",
            DurMuhurtamulu: "12:39 నుండి 13:32 వరకు, 15:16 నుండి 16:08 వరకు",
            GulikaiKalam: "13:51 నుండి 15:29 వరకు",
            Karanamulu: "శకుని 12:11 వరకు, చతుష్పాద 22:21 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "భరణి 08:23 వరకు, కృత్తిక 05:32 వరకు",
            MainNakshatra: "కృత్తిక",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "07:20 నుండి 08:58 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:42",
            Sunset: "18:45",
            Thiti: "చతుర్దశి 12:11 వరకు",
            Yamaganda: "10:35 నుండి 12:13 వరకు",
            Yoga: "శోభన 07:02 వరకు, అతిగణ్డ 02:55 వరకు",
            Varjyam: "18:58 నుండి 20:22 వరకు",
            Moonrise: "05:27, మే 27",
            Moonset: "18:05",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:40 వరకు",
            Amrutakalam: "24:00 నుండి 01:25, మే 28 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం",
            Date: "మంగళవారము, మే 27, 2025",
            DurMuhurtamulu: "08:18 నుండి 09:11 వరకు, 23:08 నుండి 23:52 వరకు",
            GulikaiKalam: "12:13 నుండి 13:51 వరకు",
            Karanamulu:
              "నాగ 08:31 వరకు, కింస్తుఘ్న 18:45 వరకు, బవ 05:02, మే 28 వరకు",
            Masam: "వైశాఖము",
            Nakshatra1: "రోహిణి 02:50 వరకు",
            MainNakshatra: "రోహిణి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "15:29 నుండి 17:07 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:42",
            Sunset: "18:45",
            Thiti: "అమావాస్య 08:31 వరకు, పాడ్యమి 05:02 వరకు",
            Yamaganda: "08:57 నుండి 10:35 వరకు",
            Yoga: "సుకర్మా 22:54 వరకు",
            Varjyam: "19:44 నుండి 21:09 వరకు",
            Moonrise: "ఏమి లేదు",
            Moonset: "19:14",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "amavasya",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "16:33 నుండి 17:59 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం 13:36 వరకు",
            Date: "బుధవారము, మే 28, 2025",
            DurMuhurtamulu: "11:47 నుండి 12:40 వరకు",
            GulikaiKalam: "10:36 నుండి 12:14 వరకు",
            Karanamulu: "బాలవ 15:25 వరకు, కౌలవ 01:54, మే 29 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "మృగశిర 00:29, మే 29 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "12:14 నుండి 13:52 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:41",
            Sunset: "18:46",
            Thiti: "విదియ 01:54 వరకు",
            Yamaganda: "07:19 నుండి 08:57 వరకు",
            Yoga: "ధృతి 19:09 వరకు",
            Varjyam: "07:53 నుండి 09:20 వరకు",
            Moonrise: "06:28",
            Moonset: "20:22",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:40 వరకు",
            Amrutakalam: "13:24 నుండి 14:53 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం",
            Date: "గురువారము, మే 29, 2025",
            DurMuhurtamulu: "10:03 నుండి 10:55 వరకు, 15:17 నుండి 16:09 వరకు",
            GulikaiKalam: "08:58 నుండి 10:36 వరకు",
            Karanamulu: "తైతిల 12:31 వరకు, గర 23:18 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "ఆరుద్ర 22:38 వరకు",
            MainNakshatra: "ఆరుద్ర",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "13:52 నుండి 15:30 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:41",
            Sunset: "18:46",
            Thiti: "తదియ 23:18 వరకు",
            Yamaganda: "05:41 నుండి 07:19 వరకు",
            Yoga: "శూల 15:47 వరకు",
            Varjyam: "08:14 నుండి 09:43 వరకు",
            Moonrise: "07:33",
            Moonset: "21:25",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:40 వరకు",
            Amrutakalam: "19:12 నుండి 20:43 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం 15:42 వరకు",
            Date: "శుక్రవారము, మే 30, 2025",
            DurMuhurtamulu: "08:18 నుండి 09:11 వరకు, 12:40 నుండి 13:32 వరకు",
            GulikaiKalam: "07:19 నుండి 08:58 వరకు",
            Karanamulu: "వణిజ 10:14 వరకు, విష్టి 21:22 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "పునర్వసు 21:29 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "10:36 నుండి 12:14 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:41",
            Sunset: "18:46",
            Thiti: "చవితి 21:22 వరకు",
            Yamaganda: "15:30 నుండి 17:08 వరకు",
            Yoga: "గణ్డ 12:57 వరకు",
            Varjyam: "10:04 నుండి 11:35 వరకు, 05:22 నుండి 06:56, మే 31 వరకు",
            Moonrise: "08:39",
            Moonset: "22:20",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:40 వరకు",
            Amrutakalam: "14:49 నుండి 16:23 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "శనివారము, మే 31, 2025",
            DurMuhurtamulu: "05:41 నుండి 06:34 వరకు, 06:34 నుండి 07:26 వరకు",
            GulikaiKalam: "05:41 నుండి 07:19 వరకు",
            Karanamulu: "బవ 08:42 వరకు, బాలవ 20:15 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "పుష్యమి 21:07 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "08:58 నుండి 10:36 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:41",
            Sunset: "18:47",
            Thiti: "పంచమి 20:15 వరకు",
            Yamaganda: "13:52 నుండి 15:30 వరకు",
            Yoga: "వృద్ధి 10:44 వరకు",
            Varjyam: "ఏమి లేదు",
            Moonrise: "09:41",
            Moonset: "23:07",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
        ],
      },
      {
        day: [
          {
            Abhijit: "11:48 నుండి 12:40 వరకు",
            Amrutakalam: "19:58 నుండి 21:36 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం 21:36 వరకు",
            Date: "ఆదివారము, జూన్ 01, 2025",
            DurMuhurtamulu: "17:02 నుండి 17:55 వరకు",
            GulikaiKalam: "15:31 నుండి 17:09 వరకు",
            Karanamulu: "కౌలవ 08:00 వరకు, తైతిల 19:59 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "ఆశ్లేష 21:36 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "17:09 నుండి 18:47 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:41",
            Sunset: "18:47",
            Thiti: "షష్టి 19:59 వరకు",
            Yamaganda: "12:14 నుండి 13:52 వరకు",
            Yoga: "ధ్రువ 09:11 వరకు",
            Varjyam: "10:11 నుండి 11:49 వరకు",
            Moonrise: "10:40",
            Moonset: "23:48",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:41 వరకు",
            Amrutakalam: "20:23 నుండి 22:05 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం",
            Date: "సోమవారము, జూన్ 02, 2025",
            DurMuhurtamulu: "12:41 నుండి 13:33 వరకు, 15:18 నుండి 16:10 వరకు",
            GulikaiKalam: "13:53 నుండి 15:31 వరకు",
            Karanamulu: "గర 08:11 వరకు, వణిజ 20:34 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "మఖ 22:55 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "07:19 నుండి 08:58 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:41",
            Sunset: "18:48",
            Thiti: "సప్తమి 20:34 వరకు",
            Yamaganda: "10:36 నుండి 12:14 వరకు",
            Yoga: "వ్యాఘాత 08:21 వరకు",
            Varjyam: "10:16 నుండి 11:57 వరకు",
            Moonrise: "11:33",
            Moonset: "00:24",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:41 వరకు",
            Amrutakalam: "18:02 నుండి 19:46 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం",
            Date: "మంగళవారము, జూన్ 03, 2025",
            DurMuhurtamulu: "08:18 నుండి 09:11 వరకు, 23:09 నుండి 23:53 వరకు",
            GulikaiKalam: "12:14 నుండి 13:53 వరకు",
            Karanamulu: "విష్టి 09:10 వరకు, బవ 21:56 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "పుబ్బ 00:58 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "15:31 నుండి 17:10 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:41",
            Sunset: "18:48",
            Thiti: "అష్టమి 21:56 వరకు",
            Yamaganda: "08:58 నుండి 10:36 వరకు",
            Yoga: "హర్షణ 08:09 వరకు",
            Varjyam: "07:36 నుండి 09:21 వరకు",
            Moonrise: "12:24",
            Moonset: "00:58, జూన్ 04",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:41 వరకు",
            Amrutakalam: "19:36 నుండి 21:23 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం 07:35 వరకు",
            Date: "బుధవారము, జూన్ 04, 2025",
            DurMuhurtamulu: "11:48 నుండి 12:41 వరకు",
            GulikaiKalam: "10:36 నుండి 12:15 వరకు",
            Karanamulu: "బాలవ 10:51 వరకు, కౌలవ 23:54 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "ఉత్తర 03:35 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "12:15 నుండి 13:53 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:41",
            Sunset: "18:48",
            Thiti: "నవమి 23:54 వరకు",
            Yamaganda: "07:19 నుండి 08:58 వరకు",
            Yoga: "వజ్ర 08:29 వరకు",
            Varjyam: "08:58 నుండి 10:44 వరకు",
            Moonrise: "13:12",
            Moonset: "01:30",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:41 వరకు",
            Amrutakalam: "23:49 నుండి 01:37, జూన్ 06 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య",
            Date: "గురువారము, జూన్ 05, 2025",
            DurMuhurtamulu: "10:04 నుండి 10:56 వరకు, 15:19 నుండి 16:11 వరకు",
            GulikaiKalam: "08:58 నుండి 10:36 వరకు",
            Karanamulu: "తైతిల 13:02 వరకు, బవ 02:15, జూన్ 06 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "హస్త నిండా రాత్రి వరకు",
            MainNakshatra: "హస్త",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "13:53 నుండి 15:32 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:41",
            Sunset: "18:49",
            Thiti: "దశమి 02:15 వరకు",
            Yamaganda: "05:41 నుండి 07:19 వరకు",
            Yoga: "సిద్ధి 09:14 వరకు",
            Varjyam: "13:02 నుండి 14:50 వరకు",
            Moonrise: "13:59",
            Moonset: "02:02",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:41 వరకు",
            Amrutakalam: "02:26, జూన్ 07 నుండి 04:14, జూన్ 07 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య 20:06 వరకు",
            Date: "శుక్రవారము, జూన్ 06, 2025",
            DurMuhurtamulu: "08:19 నుండి 09:11 వరకు, 12:41 నుండి 13:34 వరకు",
            GulikaiKalam: "07:20 నుండి 08:58 వరకు",
            Karanamulu: "వణిజ 15:31 వరకు, విష్టి 04:47, జూన్ 07 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "హస్త 06:34 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "10:36 నుండి 12:15 వరకు",
            Ruthuvu: "వసంత ఋతువు",
            Sunrise: "05:41",
            Sunset: "18:49",
            Thiti: "ఏకాదశి 04:47 వరకు",
            Yamaganda: "15:32 నుండి 17:10 వరకు",
            Yoga: "వ్యతీపాత 10:13 వరకు",
            Varjyam: "15:36 నుండి 17:24 వరకు",
            Moonrise: "14:46",
            Moonset: "02:35",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:41 వరకు",
            Amrutakalam: "02:47, జూన్ 08 నుండి 04:35, జూన్ 08 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల",
            Date: "శనివారము, జూన్ 07, 2025",
            DurMuhurtamulu: "05:41 నుండి 06:34 వరకు, 06:34 నుండి 07:26 వరకు",
            GulikaiKalam: "05:41 నుండి 07:20 వరకు",
            Karanamulu: "బవ 18:03 వరకు, బాలవ నిండా రాత్రి వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "చిత్తా 09:40 వరకు",
            MainNakshatra: "చిత్తా",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "08:58 నుండి 10:37 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:41",
            Sunset: "18:49",
            Thiti: "ద్వాదశి నిండా రాత్రి వరకు",
            Yamaganda: "13:54 నుండి 15:32 వరకు",
            Yoga: "వారీయన 11:18 వరకు",
            Varjyam: "15:58 నుండి 17:46 వరకు",
            Moonrise: "15:34",
            Moonset: "03:10",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "7",
            LordImage: require("../images/holidays/6-0)Bakrid.png"),
          },
          {
            Abhijit: "11:49 నుండి 12:42 వరకు",
            Amrutakalam:
              "05:41, జూన్ 09 నుండి 07:28, జూన్ 09 వరకు, 18:57 నుండి 20:44 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల",
            Date: "ఆదివారము, జూన్ 08, 2025",
            DurMuhurtamulu: "17:04 నుండి 17:57 వరకు",
            GulikaiKalam: "15:32 నుండి 17:11 వరకు",
            Karanamulu: "బాలవ 07:17 వరకు, కౌలవ 20:28 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "స్వాతి 12:42 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "17:11 నుండి 18:50 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:41",
            Sunset: "18:50",
            Thiti: "ద్వాదశి 07:17 వరకు",
            Yamaganda: "12:15 నుండి 13:54 వరకు",
            Yoga: "పరిఘ 12:18 వరకు",
            Varjyam: "18:57 నుండి 20:44 వరకు",
            Moonrise: "16:24",
            Moonset: "03:49",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:42 వరకు",
            Amrutakalam: "ఏమి లేదు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల 08:50 వరకు",
            Date: "సోమవారము, జూన్ 09, 2025",
            DurMuhurtamulu: "12:42 నుండి 13:34 వరకు, 15:20 నుండి 16:12 వరకు",
            GulikaiKalam: "13:54 నుండి 15:33 వరకు",
            Karanamulu: "తైతిల 09:35 వరకు, బవ 22:38 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "విశాఖ 15:31 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "07:20 నుండి 08:58 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:41",
            Sunset: "18:50",
            Thiti: "త్రయోదశి 09:35 వరకు",
            Yamaganda: "10:37 నుండి 12:16 వరకు",
            Yoga: "శివ 13:09 వరకు",
            Varjyam: "19:56 నుండి 21:42 వరకు",
            Moonrise: "17:17",
            Moonset: "04:32",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:42 వరకు",
            Amrutakalam: "06:32 నుండి 08:18 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "మంగళవారము, జూన్ 10, 2025",
            DurMuhurtamulu: "08:19 నుండి 09:12 వరకు, 23:11 నుండి 23:54 వరకు",
            GulikaiKalam: "12:16 నుండి 13:54 వరకు",
            Karanamulu: "వణిజ 11:35 వరకు, విష్టి 00:27, జూన్ 11 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "అనూరాధ 18:02 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "15:33 నుండి 17:12 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:41",
            Sunset: "18:50",
            Thiti: "చతుర్దశి 11:35 వరకు",
            Yamaganda: "08:58 నుండి 10:37 వరకు",
            Yoga: "సిద్ధ 13:45 వరకు",
            Varjyam: "00:08 నుండి 01:52, జూన్ 11 వరకు",
            Moonrise: "18:11",
            Moonset: "05:20",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "10:35 నుండి 12:20 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము 20:10 వరకు",
            Date: "బుధవారము, జూన్ 11, 2025",
            DurMuhurtamulu: "11:50 నుండి 12:42 వరకు",
            GulikaiKalam: "10:37 నుండి 12:16 వరకు",
            Karanamulu: "బవ 13:13 వరకు, బాలవ 01:53, జూన్ 12 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "జ్యేష్ఠ 20:10 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "12:16 నుండి 13:55 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:41",
            Sunset: "18:51",
            Thiti: "పౌర్ణమి 13:13 వరకు",
            Yamaganda: "07:20 నుండి 08:59 వరకు",
            Yoga: "సాధ్య 14:04 వరకు",
            Varjyam: "04:46 నుండి 06:29, జూన్ 12 వరకు",
            Moonrise: "19:06",
            Moonset: "ఏమి లేదు",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "pournami",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:50 నుండి 12:42 వరకు",
            Amrutakalam: "15:04 నుండి 16:48 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "గురువారము, జూన్ 12, 2025",
            DurMuhurtamulu: "10:05 నుండి 10:57 వరకు, 15:20 నుండి 16:13 వరకు",
            GulikaiKalam: "08:59 నుండి 10:37 వరకు",
            Karanamulu: "కౌలవ 14:27 వరకు, తైతిల 02:56, జూన్ 13 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "మూల 21:57 వరకు",
            MainNakshatra: "మూల",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "13:55 నుండి 15:33 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:41",
            Sunset: "18:51",
            Thiti: "పాడ్యమి 14:27 వరకు",
            Yamaganda: "05:41 నుండి 07:20 వరకు",
            Yoga: "శుభ 14:05 వరకు",
            Varjyam: "20:14 నుండి 21:57 వరకు",
            Moonrise: "20:00",
            Moonset: "06:12",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:50 నుండి 12:43 వరకు",
            Amrutakalam: "18:16 నుండి 19:57 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు 05:38, జూన్ 14 వరకు",
            Date: "శుక్రవారము, జూన్ 13, 2025",
            DurMuhurtamulu: "08:19 నుండి 09:12 వరకు, 12:43 నుండి 13:35 వరకు",
            GulikaiKalam: "07:20 నుండి 08:59 వరకు",
            Karanamulu: "బవ 15:18 వరకు, వణిజ 03:35, జూన్ 14 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "పూర్వాషాఢ 23:21 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "10:38 నుండి 12:16 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:42",
            Sunset: "18:51",
            Thiti: "విదియ 15:18 వరకు",
            Yamaganda: "15:34 నుండి 17:12 వరకు",
            Yoga: "శుక్ల 13:48 వరకు",
            Varjyam: "08:06 నుండి 09:48 వరకు",
            Moonrise: "20:51",
            Moonset: "07:08",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:50 నుండి 12:43 వరకు",
            Amrutakalam: "17:41 నుండి 19:21 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము",
            Date: "శనివారము, జూన్ 14, 2025",
            DurMuhurtamulu: "05:42 నుండి 06:34 వరకు, 06:34 నుండి 07:27 వరకు",
            GulikaiKalam: "05:42 నుండి 07:20 వరకు",
            Karanamulu: "విష్టి 15:46 వరకు, బవ 03:51, జూన్ 15 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "ఉత్తరాషాఢ 00:22, జూన్ 15 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "08:59 నుండి 10:38 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:42",
            Sunset: "18:51",
            Thiti: "తదియ 15:46 వరకు",
            Yamaganda: "13:55 నుండి 15:34 వరకు",
            Yoga: "బ్రహ్మ 13:13 వరకు",
            Varjyam: "07:41 నుండి 09:21 వరకు, 04:28 నుండి 06:06, జూన్ 15 వరకు",
            Moonrise: "21:38",
            Moonset: "08:05",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:50 నుండి 12:43 వరకు",
            Amrutakalam: "14:19 నుండి 15:58 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము",
            Date: "ఆదివారము, జూన్ 15, 2025",
            DurMuhurtamulu: "17:06 నుండి 17:59 వరకు",
            GulikaiKalam: "15:34 నుండి 17:13 వరకు",
            Karanamulu: "బాలవ 15:51 వరకు, కౌలవ 03:44, జూన్ 16 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "శ్రవణం 01:00 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "17:13 నుండి 18:52 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:42",
            Sunset: "18:52",
            Thiti: "చవితి 15:51 వరకు",
            Yamaganda: "12:17 నుండి 13:55 వరకు",
            Yoga: "ఐన్ద్ర 12:20 వరకు",
            Varjyam: "05:02 నుండి 06:39, జూన్ 16 వరకు",
            Moonrise: "22:22",
            Moonset: "09:03",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:51 నుండి 12:43 వరకు",
            Amrutakalam: "14:43 నుండి 16:20 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము 13:10 వరకు",
            Date: "సోమవారము, జూన్ 16, 2025",
            DurMuhurtamulu: "12:43 నుండి 13:36 వరకు, 15:21 నుండి 16:14 వరకు",
            GulikaiKalam: "13:56 నుండి 15:34 వరకు",
            Karanamulu: "తైతిల 15:31 వరకు, బవ 03:12, జూన్ 17 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "ధనిష్ఠ 01:13 వరకు",
            MainNakshatra: "ధనిష్ఠ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "07:21 నుండి 08:59 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:42",
            Sunset: "18:52",
            Thiti: "పంచమి 15:31 వరకు",
            Yamaganda: "10:38 నుండి 12:17 వరకు",
            Yoga: "వైధృతి 11:07 వరకు",
            Varjyam: "ఏమి లేదు",
            Moonrise: "23:02",
            Moonset: "09:59",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:51 నుండి 12:44 వరకు",
            Amrutakalam: "17:53 నుండి 19:28 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము",
            Date: "మంగళవారము, జూన్ 17, 2025",
            DurMuhurtamulu: "08:20 నుండి 09:13 వరకు, 23:12 నుండి 23:56 వరకు",
            GulikaiKalam: "12:17 నుండి 13:56 వరకు",
            Karanamulu: "వణిజ 14:46 వరకు, విష్టి 02:13, జూన్ 18 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "శతభిషం 01:01 వరకు",
            MainNakshatra: "శతభిషం",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "15:35 నుండి 17:13 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:42",
            Sunset: "18:52",
            Thiti: "షష్టి 14:46 వరకు",
            Yamaganda: "09:00 నుండి 10:38 వరకు",
            Yoga: "విష్కుమ్భ 09:34 వరకు",
            Varjyam: "08:22 నుండి 09:57 వరకు",
            Moonrise: "23:40",
            Moonset: "10:54",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "16:36 నుండి 18:09 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము 18:35 వరకు",
            Date: "బుధవారము, జూన్ 18, 2025",
            DurMuhurtamulu: "11:51 నుండి 12:44 వరకు",
            GulikaiKalam: "10:39 నుండి 12:17 వరకు",
            Karanamulu: "బవ 13:34 వరకు, బాలవ 00:48, జూన్ 19 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "పూర్వాభాద్ర 00:23 వరకు",
            MainNakshatra: "పూర్వాభాద్ర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "12:17 నుండి 13:56 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:42",
            Sunset: "18:52",
            Thiti: "సప్తమి 13:34 వరకు",
            Yamaganda: "07:21 నుండి 09:00 వరకు",
            Yoga: "ప్రీతి 07:40 వరకు",
            Varjyam: "07:15 నుండి 08:48 వరకు",
            Moonrise: "00:18",
            Moonset: "11:49",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:51 నుండి 12:44 వరకు",
            Amrutakalam: "18:42 నుండి 20:13 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం",
            Date: "గురువారము, జూన్ 19, 2025",
            DurMuhurtamulu: "10:06 నుండి 10:59 వరకు, 15:22 నుండి 16:15 వరకు",
            GulikaiKalam: "09:00 నుండి 10:39 వరకు",
            Karanamulu: "కౌలవ 11:55 వరకు, తైతిల 22:55 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "ఉత్తరాభాద్ర 23:17 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "13:56 నుండి 15:35 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:43",
            Sunset: "18:53",
            Thiti: "అష్టమి 11:55 వరకు",
            Yamaganda: "05:43 నుండి 07:21 వరకు",
            Yoga: "సౌభాగ్య 02:46 వరకు",
            Varjyam: "09:32 నుండి 11:04 వరకు",
            Moonrise: "00:56",
            Moonset: "12:44",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:51 నుండి 12:44 వరకు",
            Amrutakalam: "19:30 నుండి 21:00 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం 21:45 వరకు",
            Date: "శుక్రవారము, జూన్ 20, 2025",
            DurMuhurtamulu: "08:21 నుండి 09:13 వరకు, 12:44 నుండి 13:37 వరకు",
            GulikaiKalam: "07:22 నుండి 09:00 వరకు",
            Karanamulu: "బవ 09:49 వరకు, వణిజ 20:36 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "రేవతి 21:45 వరకు",
            MainNakshatra: "రేవతి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "10:39 నుండి 12:18 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:43",
            Sunset: "18:53",
            Thiti: "నవమి 09:49 వరకు",
            Yamaganda: "15:35 నుండి 17:14 వరకు",
            Yoga: "శోభన 23:47 వరకు",
            Varjyam: "10:31 నుండి 12:00 వరకు",
            Moonrise: "01:37",
            Moonset: "13:41",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:52 నుండి 12:44 వరకు",
            Amrutakalam: "13:12 నుండి 14:41 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం",
            Date: "శనివారము, జూన్ 21, 2025",
            DurMuhurtamulu: "05:43 నుండి 06:36 వరకు, 06:36 నుండి 07:28 వరకు",
            GulikaiKalam: "05:43 నుండి 07:22 వరకు",
            Karanamulu:
              "విష్టి 07:18 వరకు, బవ 17:55 వరకు, బాలవ 04:27, జూన్ 22 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "అశ్విని 19:50 వరకు",
            MainNakshatra: "అశ్విని",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "09:00 నుండి 10:39 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:43",
            Sunset: "18:53",
            Thiti: "దశమి 07:18 వరకు, ఏకాదశి 04:27 వరకు",
            Yamaganda: "13:57 నుండి 15:36 వరకు",
            Yoga: "అతిగణ్డ 20:29 వరకు",
            Varjyam: "16:09 నుండి 17:37 వరకు, 04:33 నుండి 06:00, జూన్ 22 వరకు",
            Moonrise: "02:22",
            Moonset: "14:42",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:52 నుండి 12:45 వరకు",
            Amrutakalam: "13:16 నుండి 14:44 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మేషం 23:03 వరకు",
            Date: "ఆదివారము, జూన్ 22, 2025",
            DurMuhurtamulu: "17:08 నుండి 18:01 వరకు",
            GulikaiKalam: "15:36 నుండి 17:15 వరకు",
            Karanamulu: "కౌలవ 14:56 వరకు, తైతిల 01:21, జూన్ 23 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "భరణి 17:38 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "17:15 నుండి 18:53 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:43",
            Sunset: "18:53",
            Thiti: "ద్వాదశి 01:21 వరకు",
            Yamaganda: "12:18 నుండి 13:57 వరకు",
            Yoga: "సుకర్మా 16:57 వరకు",
            Varjyam: "04:27 నుండి 05:54 వరకు, జూన్ 23",
            Moonrise: "03:12",
            Moonset: "15:46",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:52 నుండి 12:45 వరకు",
            Amrutakalam: "13:07 నుండి 14:33 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం",
            Date: "సోమవారము, జూన్ 23, 2025",
            DurMuhurtamulu: "12:45 నుండి 13:37 వరకు, 15:23 నుండి 16:16 వరకు",
            GulikaiKalam: "13:57 నుండి 15:36 వరకు",
            Karanamulu: "బవ 11:46 వరకు, వణిజ 22:09 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "కృత్తిక 15:16 వరకు",
            MainNakshatra: "కృత్తిక",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "07:22 నుండి 09:01 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:43",
            Sunset: "18:54",
            Thiti: "త్రయోదశి 22:09 వరకు",
            Yamaganda: "10:40 నుండి 12:18 వరకు",
            Yoga: "ధృతి 13:17 వరకు",
            Varjyam: "05:41 నుండి 07:08 వరకు, జూన్ 24",
            Moonrise: "04:10",
            Moonset: "16:53",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:52 నుండి 12:45 వరకు",
            Amrutakalam: "02:41 నుండి 04:08 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృషభం 23:45 వరకు",
            Date: "మంగళవారము, జూన్ 24, 2025",
            DurMuhurtamulu: "08:22 నుండి 09:14 వరకు, 23:14 నుండి 23:57 వరకు",
            GulikaiKalam: "12:19 నుండి 13:57 వరకు",
            Karanamulu: "విష్టి 08:33 వరకు, శకుని 18:59 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "రోహిణి 12:54 వరకు",
            MainNakshatra: "రోహిణి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "15:36 నుండి 17:15 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:44",
            Sunset: "18:54",
            Thiti: "చతుర్దశి 18:59 వరకు",
            Yamaganda: "09:01 నుండి 10:40 వరకు",
            Yoga: "శూల 09:36 వరకు",
            Varjyam: "17:59 నుండి 19:26 వరకు",
            Moonrise: "05:13",
            Moonset: "18:01",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "23:34 నుండి 01:02, జూన్ 26 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం",
            Date: "బుధవారము, జూన్ 25, 2025",
            DurMuhurtamulu: "11:53 నుండి 12:45 వరకు",
            GulikaiKalam: "10:40 నుండి 12:19 వరకు",
            Karanamulu: "నాగ 16:00 వరకు, కింస్తుఘ్న 02:39, జూన్ 26 వరకు",
            Masam: "జ్యేష్ఠము",
            Nakshatra1: "మృగశిర 10:40 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "12:19 నుండి 13:58 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:44",
            Sunset: "18:54",
            Thiti: "అమావాస్య 16:00 వరకు",
            Yamaganda: "07:23 నుండి 09:01 వరకు",
            Yoga: "గణ్డ 06:00 వరకు, వృద్ధి 02:39, జూన్ 26 వరకు",
            Varjyam: "18:24 నుండి 19:53 వరకు",
            Moonrise: "కాదు చంద్రోదయం",
            Moonset: "19:06",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "amavasya",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:45 వరకు",
            Amrutakalam: "05:06, జూన్ 27 నుండి 06:36, జూన్ 27 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మిధునం 01:39, జూన్ 27 వరకు",
            Date: "గురువారము, జూన్ 26, 2025",
            DurMuhurtamulu: "10:07 నుండి 11:00 వరకు, 15:23 నుండి 16:16 వరకు",
            GulikaiKalam: "09:02 నుండి 10:40 వరకు",
            Karanamulu: "బవ 13:24 వరకు, బాలవ 00:17, జూన్ 27 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "ఆరుద్ర 08:46 వరకు",
            MainNakshatra: "ఆరుద్ర",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "13:58 నుండి 15:37 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:44",
            Sunset: "18:54",
            Thiti: "పాడ్యమి 13:24 వరకు",
            Yamaganda: "05:44 నుండి 07:23 వరకు",
            Yoga: "ధ్రువ 23:40 వరకు",
            Varjyam: "20:04 నుండి 21:34 వరకు",
            Moonrise: "06:18",
            Moonset: "20:05",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:46 వరకు",
            Amrutakalam: "00:24, జూన్ 28 నుండి 01:57, జూన్ 28 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "శుక్రవారము, జూన్ 27, 2025",
            DurMuhurtamulu: "08:22 నుండి 09:15 వరకు, 12:46 నుండి 13:38 వరకు",
            GulikaiKalam: "07:23 నుండి 09:02 వరకు",
            Karanamulu: "కౌలవ 11:19 వరకు, తైతిల 22:31 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "పునర్వసు 07:22 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "10:41 నుండి 12:19 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:44",
            Sunset: "18:54",
            Thiti: "విదియ 11:19 వరకు",
            Yamaganda: "15:37 నుండి 17:15 వరకు",
            Yoga: "వ్యాఘాత 21:10 వరకు",
            Varjyam: "15:06 నుండి 16:39 వరకు",
            Moonrise: "07:24",
            Moonset: "20:57",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:46 వరకు",
            Amrutakalam: "04:58, జూన్ 29 నుండి 06:34, జూన్ 29 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "శనివారము, జూన్ 28, 2025",
            DurMuhurtamulu: "05:45 నుండి 06:37 వరకు, 06:37 నుండి 07:30 వరకు",
            GulikaiKalam: "05:45 నుండి 07:23 వరకు",
            Karanamulu: "గర 09:53 వరకు, వణిజ 21:28 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "పుష్యమి 06:35 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "09:02 నుండి 10:41 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:45",
            Sunset: "18:54",
            Thiti: "తదియ 09:53 వరకు",
            Yamaganda: "13:58 నుండి 15:37 వరకు",
            Yoga: "హర్షణ 19:15 వరకు",
            Varjyam: "19:23 నుండి 20:58 వరకు",
            Moonrise: "08:25",
            Moonset: "21:41",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:46 వరకు",
            Amrutakalam: "04:52, జూన్ 30 నుండి 06:31, జూన్ 30 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కర్కాటకం 06:34 వరకు",
            Date: "ఆదివారము, జూన్ 29, 2025",
            DurMuhurtamulu: "17:09 నుండి 18:02 వరకు",
            GulikaiKalam: "15:37 నుండి 17:16 వరకు",
            Karanamulu: "విష్టి 09:14 వరకు, బవ 21:13 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "ఆశ్లేష 06:34 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "17:16 నుండి 18:54 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:45",
            Sunset: "18:54",
            Thiti: "చవితి 09:14 వరకు",
            Yamaganda: "12:20 నుండి 13:58 వరకు",
            Yoga: "వజ్ర 17:59 వరకు",
            Varjyam: "18:57 నుండి 20:36 వరకు",
            Moonrise: "09:22",
            Moonset: "22:21",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:46 వరకు",
            Amrutakalam: "02:05, జూలై 01 నుండి 03:47, జూలై 01 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం",
            Date: "సోమవారము, జూన్ 30, 2025",
            DurMuhurtamulu: "12:46 నుండి 13:39 వరకు, 15:24 నుండి 16:17 వరకు",
            GulikaiKalam: "13:59 నుండి 15:37 వరకు",
            Karanamulu: "బాలవ 09:23 వరకు, కౌలవ 21:46 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "మఖ 07:20 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "07:24 నుండి 09:03 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:45",
            Sunset: "18:55",
            Thiti: "పంచమి 09:23 వరకు",
            Yamaganda: "10:41 నుండి 12:20 వరకు",
            Yoga: "సిద్ధి 17:21 వరకు",
            Varjyam: "15:52 నుండి 17:34 వరకు",
            Moonrise: "10:15",
            Moonset: "22:56",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
        ],
      },
      {
        day: [
          {
            Abhijit: "11:54 నుండి 12:46 వరకు",
            Amrutakalam: "03:15, జూలై 02 నుండి 05:00, జూలై 02 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "సింహం 15:23 వరకు",
            Date: "మంగళవారము, జూలై 01, 2025",
            DurMuhurtamulu: "08:23 నుండి 09:16 వరకు, 23:15 నుండి 23:59 వరకు",
            GulikaiKalam: "12:20 నుండి 13:59 వరకు",
            Karanamulu: "తైతిల 10:20 వరకు, గర 23:04 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "పుబ్బ 08:54 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "15:37 నుండి 17:16 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:46",
            Sunset: "18:55",
            Thiti: "షష్టి 10:20 వరకు",
            Yamaganda: "09:03 నుండి 10:41 వరకు",
            Yoga: "వ్యతీపాత 17:19 వరకు",
            Varjyam: "16:46 నుండి 18:31 వరకు",
            Moonrise: "11:05",
            Moonset: "23:29",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:54 నుండి 12:46 వరకు",
            Amrutakalam: "ఏమి లేదు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య",
            Date: "బుధవారము, జూలై 02, 2025",
            DurMuhurtamulu: "11:54 నుండి 12:47 వరకు",
            GulikaiKalam: "10:42 నుండి 12:20 వరకు",
            Karanamulu: "వణిజ 11:58 వరకు, విష్టి 00:59, జూలై 03 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "ఉత్తర 11:07 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "12:20 నుండి 13:59 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:46",
            Sunset: "18:55",
            Thiti: "సప్తమి 11:58 వరకు",
            Yamaganda: "07:24 నుండి 09:03 వరకు",
            Yoga: "వారీయన 17:47 వరకు",
            Varjyam: "20:28 నుండి 22:15 వరకు",
            Moonrise: "11:53",
            Moonset: "00:01, జూలై 03",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:54 నుండి 12:47 వరకు",
            Amrutakalam: "07:09 నుండి 08:56 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కన్య 03:19, జూలై 04 వరకు",
            Date: "గురువారము, జూలై 03, 2025",
            DurMuhurtamulu: "10:09 నుండి 11:02 వరకు, 15:24 నుండి 16:17 వరకు",
            GulikaiKalam: "09:03 నుండి 10:42 వరకు",
            Karanamulu: "బవ 14:06 వరకు, బాలవ 03:17, జూలై 04 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "హస్త 13:50 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "13:59 నుండి 15:38 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:46",
            Sunset: "18:55",
            Thiti: "అష్టమి 14:06 వరకు",
            Yamaganda: "05:46 నుండి 07:25 వరకు",
            Yoga: "పరిఘ 18:36 వరకు",
            Varjyam: "22:50 నుండి 00:38, జూలై 04 వరకు",
            Moonrise: "12:40",
            Moonset: "00:34, జూలై 04",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:54 నుండి 12:47 వరకు",
            Amrutakalam: "09:38 నుండి 11:26 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల",
            Date: "శుక్రవారము, జూలై 04, 2025",
            DurMuhurtamulu: "08:24 నుండి 09:17 వరకు, 12:47 నుండి 13:39 వరకు",
            GulikaiKalam: "07:25 నుండి 09:04 వరకు",
            Karanamulu: "కౌలవ 16:31 వరకు, తైతిల 05:45, జూలై 05 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "చిత్తా 16:50 వరకు",
            MainNakshatra: "చిత్తా",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "10:42 నుండి 12:21 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:46",
            Sunset: "18:55",
            Thiti: "నవమి 16:31 వరకు",
            Yamaganda: "15:38 నుండి 17:16 వరకు",
            Yoga: "శివ 19:36 వరకు",
            Varjyam: "23:08 నుండి 00:56, జూలై 05 వరకు",
            Moonrise: "13:28",
            Moonset: "01:09, జూలై 05",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:55 నుండి 12:47 వరకు",
            Amrutakalam: "09:57 నుండి 11:45 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల",
            Date: "శనివారము, జూలై 05, 2025",
            DurMuhurtamulu: "05:47 నుండి 06:39 వరకు, 06:39 నుండి 07:32 వరకు",
            GulikaiKalam: "05:47 నుండి 07:25 వరకు",
            Karanamulu: "బవ 18:58 వరకు, వణిజ నిండా రాత్రి వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "స్వాతి 19:51 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "09:04 నుండి 10:42 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:47",
            Sunset: "18:55",
            Thiti: "దశమి 18:58 వరకు",
            Yamaganda: "13:59 నుండి 15:38 వరకు",
            Yoga: "సిద్ధ 20:36 వరకు",
            Varjyam: "02:07, జూలై 06 నుండి 03:54, జూలై 06 వరకు",
            Moonrise: "14:18",
            Moonset: "01:46, జూలై 06",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:55 నుండి 12:47 వరకు",
            Amrutakalam: "12:51 నుండి 14:38 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "తుల 16:01 వరకు",
            Date: "ఆదివారము, జూలై 06, 2025",
            DurMuhurtamulu: "17:10 నుండి 18:02 వరకు",
            GulikaiKalam: "15:38 నుండి 17:16 వరకు",
            Karanamulu: "వణిజ 08:08 వరకు, విష్టి 21:14 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "విశాఖ 22:42 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "17:16 నుండి 18:55 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:47",
            Sunset: "18:55",
            Thiti: "ఏకాదశి 21:14 వరకు",
            Yamaganda: "12:21 నుండి 13:59 వరకు",
            Yoga: "సాధ్య 21:27 వరకు",
            Varjyam: "03:07, జూలై 07 నుండి 04:53, జూలై 07 వరకు",
            Moonrise: "15:09",
            Moonset: "02:27, జూలై 07",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "6",
            LordImage: require("../images/holidays/7-5)Moharam.png"),
          },
          {
            Abhijit: "11:55 నుండి 12:47 వరకు",
            Amrutakalam: "13:43 నుండి 15:29 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "సోమవారము, జూలై 07, 2025",
            DurMuhurtamulu: "12:47 నుండి 13:40 వరకు, 15:25 నుండి 16:17 వరకు",
            GulikaiKalam: "14:00 నుండి 15:38 వరకు",
            Karanamulu: "బవ 10:15 వరకు, బాలవ 23:10 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "అనూరాధ 01:11, జూలై 08 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "07:26 నుండి 09:04 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:47",
            Sunset: "18:55",
            Thiti: "ద్వాదశి 23:10 వరకు",
            Yamaganda: "10:43 నుండి 12:21 వరకు",
            Yoga: "శుభ 22:03 వరకు",
            Varjyam: "ఏమి లేదు",
            Moonrise: "16:03",
            Moonset: "03:13, జూలై 08",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:55 నుండి 12:48 వరకు",
            Amrutakalam: "17:42 నుండి 19:26 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "వృశ్చికము 03:15, జూలై 09 వరకు",
            Date: "మంగళవారము, జూలై 08, 2025",
            DurMuhurtamulu: "08:25 నుండి 09:18 వరకు, 23:16 నుండి 24:00 వరకు",
            GulikaiKalam: "12:21 నుండి 14:00 వరకు",
            Karanamulu: "కౌలవ 11:57 వరకు, తైతిల 00:38, జూలై 09 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "జ్యేష్ఠ 03:15, జూలై 09 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "15:38 నుండి 17:16 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:48",
            Sunset: "18:55",
            Thiti: "త్రయోదశి 00:38, జూలై 09 వరకు",
            Yamaganda: "09:05 నుండి 10:43 వరకు",
            Yoga: "శుక్ల 22:17 వరకు",
            Varjyam: "07:16 నుండి 09:01 వరకు",
            Moonrise: "16:58",
            Moonset: "04:04, జూలై 09",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:55 నుండి 12:48 వరకు",
            Amrutakalam: "22:00 నుండి 23:43 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "బుధవారము, జూలై 09, 2025",
            DurMuhurtamulu: "11:55 నుండి 12:48 వరకు",
            GulikaiKalam: "10:43 నుండి 12:21 వరకు",
            Karanamulu: "బవ 13:11 వరకు, వణిజ 01:36, జూలై 10 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "మూల 04:50, జూలై 10 వరకు",
            MainNakshatra: "మూల",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "12:21 నుండి 14:00 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:48",
            Sunset: "18:55",
            Thiti: "చతుర్దశి 01:36, జూలై 10 వరకు",
            Yamaganda: "07:26 నుండి 09:05 వరకు",
            Yoga: "బ్రహ్మ 22:09 వరకు",
            Varjyam: "11:46 నుండి 13:29 వరకు",
            Moonrise: "17:53",
            Moonset: "05:00, జూలై 10",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:55 నుండి 12:48 వరకు",
            Amrutakalam: "00:55, జూలై 11 నుండి 02:35, జూలై 11 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "గురువారము, జూలై 10, 2025",
            DurMuhurtamulu: "10:11 నుండి 11:03 వరకు, 15:25 నుండి 16:17 వరకు",
            GulikaiKalam: "09:05 నుండి 10:43 వరకు",
            Karanamulu: "విష్టి 13:55 వరకు, బవ 02:06, జూలై 11 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "పూర్వాషాఢ నిండా రాత్రి వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "14:00 నుండి 15:38 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:48",
            Sunset: "18:55",
            Thiti: "పౌర్ణమి 02:06, జూలై 11 వరకు",
            Yamaganda: "05:48 నుండి 07:27 వరకు",
            Yoga: "ఐన్ద్ర 21:38 వరకు",
            Varjyam: "14:52 నుండి 16:33 వరకు",
            Moonrise: "18:45",
            Moonset: "ఏమి లేదు",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "pournami",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:55 నుండి 12:48 వరకు",
            Amrutakalam: "00:01, జూలై 12 నుండి 01:40, జూలై 12 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "ధనస్సు 12:08 వరకు",
            Date: "శుక్రవారము, జూలై 11, 2025",
            DurMuhurtamulu: "08:26 నుండి 09:18 వరకు, 12:48 నుండి 13:40 వరకు",
            GulikaiKalam: "07:27 నుండి 09:05 వరకు",
            Karanamulu: "బాలవ 14:10 వరకు, కౌలవ 02:08, జూలై 12 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "పూర్వాషాఢ 05:56 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "10:43 నుండి 12:22 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:49",
            Sunset: "18:55",
            Thiti: "పాడ్యమి 02:08, జూలై 12 వరకు",
            Yamaganda: "15:38 నుండి 17:16 వరకు",
            Yoga: "వైధృతి 20:45 వరకు",
            Varjyam: "14:09 నుండి 15:48 వరకు",
            Moonrise: "19:35",
            Moonset: "05:58",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:48 వరకు",
            Amrutakalam: "20:21 నుండి 21:59 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము",
            Date: "శనివారము, జూలై 12, 2025",
            DurMuhurtamulu: "05:49 నుండి 06:41 వరకు, 06:41 నుండి 07:34 వరకు",
            GulikaiKalam: "05:49 నుండి 07:27 వరకు",
            Karanamulu: "తైతిల 14:00 వరకు, బవ 01:46, జూలై 13 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "ఉత్తరాషాఢ 06:36 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "09:05 నుండి 10:44 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:49",
            Sunset: "18:55",
            Thiti: "విదియ 01:46, జూలై 13 వరకు",
            Yamaganda: "14:00 నుండి 15:38 వరకు",
            Yoga: "విష్కుమ్భ 19:32 వరకు",
            Varjyam: "10:39 నుండి 12:16 వరకు",
            Moonrise: "20:20",
            Moonset: "06:56",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:48 వరకు",
            Amrutakalam: "20:26 నుండి 22:02 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మకరము 18:53 వరకు",
            Date: "ఆదివారము, జూలై 13, 2025",
            DurMuhurtamulu: "17:10 నుండి 18:02 వరకు",
            GulikaiKalam: "15:38 నుండి 17:16 వరకు",
            Karanamulu: "వణిజ 13:26 వరకు, విష్టి 01:02, జూలై 14 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "శ్రవణం 06:53 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "17:16 నుండి 18:54 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:49",
            Sunset: "18:54",
            Thiti: "తదియ 01:02, జూలై 14 వరకు",
            Yamaganda: "12:22 నుండి 14:00 వరకు",
            Yoga: "ప్రీతి 18:01 వరకు",
            Varjyam: "10:52 నుండి 12:28 వరకు",
            Moonrise: "21:02",
            Moonset: "07:54",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:48 వరకు",
            Amrutakalam: "23:21 నుండి 00:55, జూలై 15 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము",
            Date: "సోమవారము, జూలై 14, 2025",
            DurMuhurtamulu: "12:48 నుండి 13:41 వరకు, 15:25 నుండి 16:17 వరకు",
            GulikaiKalam: "14:00 నుండి 15:38 వరకు",
            Karanamulu: "బవ 12:33 వరకు, బాలవ 23:59 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "ధనిష్ఠ 06:49 వరకు",
            MainNakshatra: "ధనిష్ఠ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "07:28 నుండి 09:06 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:50",
            Sunset: "18:54",
            Thiti: "చవితి 23:59 వరకు",
            Yamaganda: "10:44 నుండి 12:22 వరకు",
            Yoga: "ఆయుష్మాన్ 16:14 వరకు",
            Varjyam: "13:54 నుండి 15:28 వరకు",
            Moonrise: "21:41",
            Moonset: "08:50",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:48 వరకు",
            Amrutakalam: "21:59 నుండి 23:33 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "కుంభము 23:58 వరకు",
            Date: "మంగళవారము, జూలై 15, 2025",
            DurMuhurtamulu:
              "08:27 నుండి 09:19 వరకు, 23:17 నుండి 00:00, జూలై 16 వరకు",
            GulikaiKalam: "12:22 నుండి 14:00 వరకు",
            Karanamulu: "కౌలవ 11:21 వరకు, తైతిల 22:38 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "శతభిషం 06:26 వరకు",
            MainNakshatra: "శతభిషం",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "15:38 నుండి 17:16 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:50",
            Sunset: "18:54",
            Thiti: "పంచమి 22:38 వరకు",
            Yamaganda: "09:06 నుండి 10:44 వరకు",
            Yoga: "సౌభాగ్య 14:12 వరకు",
            Varjyam: "12:39 నుండి 14:13 వరకు",
            Moonrise: "22:19",
            Moonset: "09:45",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:48 వరకు",
            Amrutakalam: "00:13, జూలై 17 నుండి 01:46, జూలై 17 వరకు",
            Ayanam: "ఉత్తరాయణం",
            Chandrashtama: "మీనం",
            Date: "బుధవారము, జూలై 16, 2025",
            DurMuhurtamulu: "11:56 నుండి 12:48 వరకు",
            GulikaiKalam: "10:44 నుండి 12:22 వరకు",
            Karanamulu: "బవ 09:52 వరకు, వణిజ 21:01 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "ఉత్తరాభాద్ర 04:50, జూలై 17 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "12:22 నుండి 14:00 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:50",
            Sunset: "18:54",
            Thiti: "షష్టి 21:01 వరకు",
            Yamaganda: "07:28 నుండి 09:06 వరకు",
            Yoga: "శోభన 11:57 వరకు",
            Varjyam: "15:00 నుండి 16:32 వరకు",
            Moonrise: "22:56",
            Moonset: "10:40",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:48 వరకు",
            Amrutakalam: "01:22, జూలై 18 నుండి 02:53, జూలై 18 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం 03:39, జూలై 18 వరకు",
            Date: "గురువారము, జూలై 17, 2025",
            DurMuhurtamulu: "10:12 నుండి 11:04 వరకు, 15:25 నుండి 16:17 వరకు",
            GulikaiKalam: "09:07 నుండి 10:44 వరకు",
            Karanamulu: "విష్టి 08:07 వరకు, బవ 19:08 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "రేవతి 03:39, జూలై 18 వరకు",
            MainNakshatra: "రేవతి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "14:00 నుండి 15:38 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:51",
            Sunset: "18:54",
            Thiti: "సప్తమి 19:08 వరకు",
            Yamaganda: "05:51 నుండి 07:29 వరకు",
            Yoga: "అతిగణ్డ 09:29 వరకు",
            Varjyam: "16:14 నుండి 17:46 వరకు",
            Moonrise: "23:36",
            Moonset: "11:36",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:48 వరకు",
            Amrutakalam: "19:27 నుండి 20:57 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం",
            Date: "శుక్రవారము, జూలై 18, 2025",
            DurMuhurtamulu: "08:28 నుండి 09:20 వరకు, 12:48 నుండి 13:41 వరకు",
            GulikaiKalam: "07:29 నుండి 09:07 వరకు",
            Karanamulu:
              "బాలవ 06:07 వరకు, కౌలవ 17:01 వరకు, తైతిల 03:53, జూలై 19 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "అశ్విని 02:14, జూలై 19 వరకు",
            MainNakshatra: "అశ్విని",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "10:45 నుండి 12:22 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:51",
            Sunset: "18:54",
            Thiti: "అష్టమి 17:01 వరకు",
            Yamaganda: "15:38 నుండి 17:16 వరకు",
            Yoga: "సుకర్మా 06:48 వరకు, ధృతి 03:56, జూలై 19 వరకు",
            Varjyam: "22:28 నుండి 23:58 వరకు",
            Moonrise: "00:18, జూలై 19",
            Moonset: "12:34",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:49 వరకు",
            Amrutakalam: "20:08 నుండి 21:38 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం",
            Date: "శనివారము, జూలై 19, 2025",
            DurMuhurtamulu: "05:51 నుండి 06:44 వరకు, 06:44 నుండి 07:36 వరకు",
            GulikaiKalam: "05:51 నుండి 07:29 వరకు",
            Karanamulu: "బవ 14:41 వరకు, వణిజ 01:28, జూలై 20 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "భరణి 00:37, జూలై 20 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "09:07 నుండి 10:45 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:51",
            Sunset: "18:54",
            Thiti: "నవమి 14:41 వరకు",
            Yamaganda: "14:00 నుండి 15:38 వరకు",
            Yoga: "శూల 00:55, జూలై 20 వరకు",
            Varjyam: "11:11 నుండి 12:40 వరకు",
            Moonrise: "01:05, జూలై 20",
            Moonset: "13:35",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:49 వరకు",
            Amrutakalam: "20:39 నుండి 22:08 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం 06:12 వరకు",
            Date: "ఆదివారము, జూలై 20, 2025",
            DurMuhurtamulu: "17:09 నుండి 18:01 వరకు",
            GulikaiKalam: "15:38 నుండి 17:16 వరకు",
            Karanamulu: "విష్టి 12:12 వరకు, బవ 22:56 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "కృత్తిక 22:53 వరకు",
            MainNakshatra: "కృత్తిక",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "07:30 నుండి 09:07 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:52",
            Sunset: "18:53",
            Thiti: "దశమి 12:12 వరకు",
            Yamaganda: "12:23 నుండి 14:00 వరకు",
            Yoga: "గణ్డ 21:48 వరకు",
            Varjyam: "11:45 నుండి 13:14 వరకు",
            Moonrise: "01:58, జూలై 21",
            Moonset: "14:39",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:49 వరకు",
            Amrutakalam: "18:09 నుండి 19:38 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృషభం",
            Date: "సోమవారము, జూలై 21, 2025",
            DurMuhurtamulu: "12:49 నుండి 13:41 వరకు, 15:25 నుండి 16:17 వరకు",
            GulikaiKalam: "14:00 నుండి 15:38 వరకు",
            Karanamulu: "బాలవ 09:38 వరకు, కౌలవ 20:21 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "రోహిణి 21:07 వరకు",
            MainNakshatra: "రోహిణి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "07:30 నుండి 09:07 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:52",
            Sunset: "18:53",
            Thiti: "ఏకాదశి 09:38 వరకు",
            Yamaganda: "10:45 నుండి 12:23 వరకు",
            Yoga: "వృద్ధి 18:39 వరకు",
            Varjyam:
              "13:42 నుండి 15:11 వరకు, 02:19, జూలై 22 నుండి 03:48, జూలై 22 వరకు",
            Moonrise: "02:57, జూలై 22",
            Moonset: "15:45",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:49 వరకు",
            Amrutakalam: "11:14 నుండి 12:43 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృషభం 08:15 వరకు",
            Date: "మంగళవారము, జూలై 22, 2025",
            DurMuhurtamulu:
              "08:28 నుండి 09:20 వరకు, 23:17 నుండి 00:01, జూలై 23 వరకు",
            GulikaiKalam: "12:23 నుండి 14:00 వరకు",
            Karanamulu:
              "తైతిల 07:05 వరకు, బవ 17:51 వరకు, వణిజ 04:39, జూలై 23 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "మృగశిర 19:24 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "15:38 నుండి 17:15 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:52",
            Sunset: "18:53",
            Thiti: "ద్వాదశి 07:05 వరకు, త్రయోదశి 04:39, జూలై 23 వరకు",
            Yamaganda: "09:07 నుండి 10:45 వరకు",
            Yoga: "ధ్రువ 15:32 వరకు",
            Varjyam: "03:17, జూలై 23 నుండి 04:47, జూలై 23 వరకు",
            Moonrise: "04:01, జూలై 23",
            Moonset: "16:50",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏదీకాదు",
            Amrutakalam: "08:32 నుండి 10:02 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం",
            Date: "బుధవారము, జూలై 23, 2025",
            DurMuhurtamulu: "11:57 నుండి 12:49 వరకు",
            GulikaiKalam: "10:45 నుండి 12:23 వరకు",
            Karanamulu: "విష్టి 15:31 వరకు, శకుని 02:28, జూలై 24 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "ఆరుద్ర 17:54 వరకు",
            MainNakshatra: "ఆరుద్ర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "12:23 నుండి 14:00 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:53",
            Sunset: "18:53",
            Thiti: "చతుర్దశి 02:28, జూలై 24 వరకు",
            Yamaganda: "07:30 నుండి 09:08 వరకు",
            Yoga: "వ్యాఘాత 12:34 వరకు",
            Varjyam: "05:19, జూలై 24 నుండి 06:50, జూలై 24 వరకు",
            Moonrise: "05:06, జూలై 24",
            Moonset: "17:51",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:49 వరకు",
            Amrutakalam: "14:26 నుండి 15:58 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం 10:59 వరకు",
            Date: "గురువారము, జూలై 24, 2025",
            DurMuhurtamulu: "10:13 నుండి 11:05 వరకు, 15:24 నుండి 16:16 వరకు",
            GulikaiKalam: "09:08 నుండి 10:45 వరకు",
            Karanamulu: "చతుష్పాద 13:31 వరకు, నాగ 00:40, జూలై 25 వరకు",
            Masam: "ఆషాఢము",
            Nakshatra1: "పునర్వసు 16:43 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "14:00 నుండి 15:37 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:53",
            Sunset: "18:52",
            Thiti: "అమావాస్య 00:40, జూలై 25 వరకు",
            Yamaganda: "05:53 నుండి 07:30 వరకు",
            Yoga: "హర్షణ 09:51 వరకు",
            Varjyam: "00:29, జూలై 25 నుండి 02:02, జూలై 25 వరకు",
            Moonrise: "ఏమి లేదు",
            Moonset: "18:46",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "amavasya",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:49 వరకు",
            Amrutakalam: "09:48 నుండి 11:21 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "శుక్రవారము, జూలై 25, 2025",
            DurMuhurtamulu: "08:29 నుండి 09:21 వరకు, 12:49 నుండి 13:41 వరకు",
            GulikaiKalam: "07:31 నుండి 09:08 వరకు",
            Karanamulu: "కింస్తుఘ్న 11:57 వరకు, బవ 23:22 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "పుష్యమి 16:00 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "10:45 నుండి 12:23 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:53",
            Sunset: "18:52",
            Thiti: "పాడ్యమి 23:22 వరకు",
            Yamaganda: "15:37 నుండి 17:15 వరకు",
            Yoga: "వజ్ర 07:28 వరకు, సిద్ధి 05:32, జూలై 26 వరకు",
            Varjyam: "04:44, జూలై 26 నుండి 06:19, జూలై 26 వరకు",
            Moonrise: "06:09",
            Moonset: "19:33",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:49 వరకు",
            Amrutakalam: "14:16 నుండి 15:52 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం 15:52 వరకు",
            Date: "శనివారము, జూలై 26, 2025",
            DurMuhurtamulu: "05:54 నుండి 06:46 వరకు, 06:46 నుండి 07:37 వరకు",
            GulikaiKalam: "05:54 నుండి 07:31 వరకు",
            Karanamulu: "బాలవ 10:57 వరకు, కౌలవ 22:41 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "ఆశ్లేష 15:52 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "09:08 నుండి 10:45 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:54",
            Sunset: "18:52",
            Thiti: "విదియ 22:41 వరకు",
            Yamaganda: "14:00 నుండి 15:37 వరకు",
            Yoga: "వ్యతీపాత 04:06, జూలై 27 వరకు",
            Varjyam: "04:07, జూలై 27 నుండి 05:45, జూలై 27 వరకు",
            Moonrise: "07:08",
            Moonset: "20:15",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:49 వరకు",
            Amrutakalam: "13:56 నుండి 15:34 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం",
            Date: "ఆదివారము, జూలై 27, 2025",
            DurMuhurtamulu: "17:08 నుండి 17:59 వరకు",
            GulikaiKalam: "15:37 నుండి 17:14 వరకు",
            Karanamulu: "తైతిల 10:36 వరకు, గర 22:41 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "మఖ 16:23 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "17:14 నుండి 18:51 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:54",
            Sunset: "18:51",
            Thiti: "తదియ 22:41 వరకు",
            Yamaganda: "12:23 నుండి 14:00 వరకు",
            Yoga: "వారీయన 03:13, జూలై 28 వరకు",
            Varjyam: "00:47, జూలై 28 నుండి 02:28, జూలై 28 వరకు",
            Moonrise: "08:03",
            Moonset: "20:52",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:48 వరకు",
            Amrutakalam: "10:52 నుండి 12:33 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం 24:00 వరకు",
            Date: "సోమవారము, జూలై 28, 2025",
            DurMuhurtamulu: "12:48 నుండి 13:40 వరకు, 15:24 నుండి 16:16 వరకు",
            GulikaiKalam: "14:00 నుండి 15:37 వరకు",
            Karanamulu: "వణిజ 10:57 వరకు, విష్టి 23:24 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "పుబ్బ 17:35 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "07:31 నుండి 09:08 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:54",
            Sunset: "18:51",
            Thiti: "చవితి 23:24 వరకు",
            Yamaganda: "10:46 నుండి 12:23 వరకు",
            Yoga: "పరిఘ 02:54, జూలై 29 వరకు",
            Varjyam: "01:21, జూలై 29 నుండి 03:04, జూలై 29 వరకు",
            Moonrise: "08:55",
            Moonset: "21:26",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:48 వరకు",
            Amrutakalam: "11:42 నుండి 13:25 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య",
            Date: "మంగళవారము, జూలై 29, 2025",
            DurMuhurtamulu:
              "08:30 నుండి 09:22 వరకు, 23:16 నుండి 00:01, జూలై 30 వరకు",
            GulikaiKalam: "12:23 నుండి 14:00 వరకు",
            Karanamulu: "బవ 12:00 వరకు, బాలవ 00:46, జూలై 30 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "ఉత్తర 19:27 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "15:37 నుండి 17:14 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:55",
            Sunset: "18:51",
            Thiti: "పంచమి 00:46, జూలై 30 వరకు",
            Yamaganda: "09:09 నుండి 10:46 వరకు",
            Yoga: "శివ 03:05, జూలై 30 వరకు",
            Varjyam: "04:42, జూలై 30 నుండి 06:28, జూలై 30 వరకు",
            Moonrise: "09:44",
            Moonset: "21:59",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏదీకాదు",
            Amrutakalam: "15:16 నుండి 17:02 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య",
            Date: "బుధవారము, జూలై 30, 2025",
            DurMuhurtamulu: "11:57 నుండి 12:48 వరకు",
            GulikaiKalam: "10:46 నుండి 12:23 వరకు",
            Karanamulu: "కౌలవ 13:40 వరకు, తైతిల 02:41, జూలై 31 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "హస్త 21:53 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "12:23 నుండి 13:59 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:55",
            Sunset: "18:50",
            Thiti: "షష్టి 02:41, జూలై 31 వరకు",
            Yamaganda: "07:32 నుండి 09:09 వరకు",
            Yoga: "సిద్ధ 03:40, జూలై 31 వరకు",
            Varjyam: "ఏమి లేదు",
            Moonrise: "10:33",
            Moonset: "22:32",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:48 వరకు",
            Amrutakalam: "17:32 నుండి 19:20 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య 11:15 వరకు",
            Date: "గురువారము, జూలై 31, 2025",
            DurMuhurtamulu: "10:13 నుండి 11:05 వరకు, 15:23 నుండి 16:15 వరకు",
            GulikaiKalam: "09:09 నుండి 10:46 వరకు",
            Karanamulu: "గర 15:47 వరకు, వణిజ 04:58, ఆగష్టు 01 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "చిత్తా 00:41, ఆగష్టు 01 వరకు",
            MainNakshatra: "చిత్తా",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "13:59 నుండి 15:36 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:55",
            Sunset: "18:50",
            Thiti: "సప్తమి 04:58, ఆగష్టు 01 వరకు",
            Yamaganda: "05:55 నుండి 07:32 వరకు",
            Yoga: "సాధ్య 04:32, ఆగష్టు 01 వరకు",
            Varjyam: "06:49 నుండి 08:36 వరకు",
            Moonrise: "11:21",
            Moonset: "23:06",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
        ],
      },
      {
        day: [
          {
            Abhijit: "11:57 నుండి 12:48 వరకు",
            Amrutakalam: "17:47 నుండి 19:34 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల",
            Date: "శుక్రవారము, ఆగష్టు 01, 2025",
            DurMuhurtamulu: "08:30 నుండి 09:22 వరకు, 12:48 నుండి 13:40 వరకు",
            GulikaiKalam: "07:32 నుండి 09:09 వరకు",
            Karanamulu: "విష్టి 18:10 వరకు, బవ నిండా రాత్రి వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "స్వాతి 03:40, ఆగష్టు 02 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "10:46 నుండి 12:22 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:55",
            Sunset: "18:49",
            Thiti: "అష్టమి నిండా రాత్రి వరకు",
            Yamaganda: "15:36 నుండి 17:13 వరకు",
            Yoga: "శుభ 05:30, ఆగష్టు 02 వరకు",
            Varjyam: "06:59 నుండి 08:47 వరకు",
            Moonrise: "12:10",
            Moonset: "23:42",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:48 వరకు",
            Amrutakalam: "20:43 నుండి 22:30 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల 23:52 వరకు",
            Date: "శనివారము, ఆగష్టు 02, 2025",
            DurMuhurtamulu: "05:56 నుండి 06:47 వరకు, 06:47 నుండి 07:39 వరకు",
            GulikaiKalam: "05:56 నుండి 07:32 వరకు",
            Karanamulu: "బవ 07:23 వరకు, బాలవ 20:34 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "విశాఖ నిండా రాత్రి వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "09:09 నుండి 10:46 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:56",
            Sunset: "18:49",
            Thiti: "అష్టమి 07:23 వరకు",
            Yamaganda: "13:59 నుండి 15:36 వరకు",
            Yoga: "శుక్ల నిండా రాత్రి వరకు",
            Varjyam: "09:57 నుండి 11:45 వరకు",
            Moonrise: "13:00",
            Moonset: "00:22, ఆగష్టు 03",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:48 వరకు",
            Amrutakalam: "21:40 నుండి 23:27 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "ఆదివారము, ఆగష్టు 03, 2025",
            DurMuhurtamulu: "17:05 నుండి 17:57 వరకు",
            GulikaiKalam: "15:35 నుండి 17:12 వరకు",
            Karanamulu: "కౌలవ 09:42 వరకు, తైతిల 22:44 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "విశాఖ 06:35 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "17:12 నుండి 18:48 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:56",
            Sunset: "18:48",
            Thiti: "నవమి 09:42 వరకు",
            Yamaganda: "12:22 నుండి 13:59 వరకు",
            Yoga: "శుక్ల 06:24 వరకు",
            Varjyam: "11:01 నుండి 12:48 వరకు",
            Moonrise: "13:53",
            Moonset: "01:06, ఆగష్టు 04",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:48 వరకు",
            Amrutakalam: "01:47, ఆగష్టు 05 నుండి 03:32, ఆగష్టు 05 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "సోమవారము, ఆగష్టు 04, 2025",
            DurMuhurtamulu: "12:48 నుండి 13:39 వరకు, 15:22 నుండి 16:14 వరకు",
            GulikaiKalam: "13:59 నుండి 15:35 వరకు",
            Karanamulu: "బవ 11:41 వరకు, వణిజ 00:31, ఆగష్టు 05 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "అనూరాధ 09:12 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "07:33 నుండి 09:09 వరకు",
            Ruthuvu: "గ్రీష్మ ఋతువు",
            Sunrise: "05:56",
            Sunset: "18:48",
            Thiti: "దశమి 11:41 వరకు",
            Yamaganda: "10:46 నుండి 12:22 వరకు",
            Yoga: "బ్రహ్మ 07:05 వరకు",
            Varjyam: "15:19 నుండి 17:03 వరకు",
            Moonrise: "14:48",
            Moonset: "01:55, ఆగష్టు 05",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:48 వరకు",
            Amrutakalam: "ఏమి లేదు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము 11:23 వరకు",
            Date: "మంగళవారము, ఆగష్టు 05, 2025",
            DurMuhurtamulu: "08:31 నుండి 09:22 వరకు, 23:15 నుండి 24:00 వరకు",
            GulikaiKalam: "12:22 నుండి 13:58 వరకు",
            Karanamulu: "విష్టి 13:12 వరకు, బవ 01:45, ఆగష్టు 06 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "జ్యేష్ఠ 11:23 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "15:35 నుండి 17:11 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "05:57",
            Sunset: "18:48",
            Thiti: "ఏకాదశి 13:12 వరకు",
            Yamaganda: "09:09 నుండి 10:46 వరకు",
            Yoga: "ఐన్ద్ర 07:25 వరకు",
            Varjyam: "19:55 నుండి 21:38 వరకు",
            Moonrise: "15:42",
            Moonset: "02:48, ఆగష్టు 06",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "06:10 నుండి 07:52 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "బుధవారము, ఆగష్టు 06, 2025",
            DurMuhurtamulu: "11:56 నుండి 12:48 వరకు",
            GulikaiKalam: "10:46 నుండి 12:22 వరకు",
            Karanamulu: "బాలవ 14:08 వరకు, కౌలవ 02:22, ఆగష్టు 07 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "మూల 13:00 వరకు",
            MainNakshatra: "మూల",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "12:22 నుండి 13:58 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "05:57",
            Sunset: "18:47",
            Thiti: "ద్వాదశి 14:08 వరకు",
            Yamaganda: "07:33 నుండి 09:09 వరకు",
            Yoga: "వైధృతి 07:18 వరకు",
            Varjyam:
              "11:17 నుండి 13:00 వరకు, 23:00 నుండి 00:40, ఆగష్టు 07 వరకు",
            Moonrise: "16:36",
            Moonset: "03:46, ఆగష్టు 07",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:47 వరకు",
            Amrutakalam: "09:01 నుండి 10:41 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు 20:11 వరకు",
            Date: "గురువారము, ఆగష్టు 07, 2025",
            DurMuhurtamulu: "10:14 నుండి 11:05 వరకు, 15:21 నుండి 16:13 వరకు",
            GulikaiKalam: "09:10 నుండి 10:46 వరకు",
            Karanamulu: "తైతిల 14:27 వరకు, బవ 02:24, ఆగష్టు 08 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "పూర్వాషాఢ 14:01 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "13:58 నుండి 15:34 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "05:57",
            Sunset: "18:46",
            Thiti: "త్రయోదశి 14:27 వరకు",
            Yamaganda: "05:57 నుండి 07:33 వరకు",
            Yoga: "విష్కుమ్భ 06:43 వరకు, ప్రీతి 05:39 వరకు",
            Varjyam: "22:10 నుండి 23:48 వరకు",
            Moonrise: "17:27",
            Moonset: "04:44, ఆగష్టు 08",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:47 వరకు",
            Amrutakalam:
              "07:57 నుండి 09:35 వరకు, 04:01, ఆగష్టు 09 నుండి 05:37, ఆగష్టు 09 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము",
            Date: "శుక్రవారము, ఆగష్టు 08, 2025",
            DurMuhurtamulu: "08:31 నుండి 09:22 వరకు, 12:47 నుండి 13:39 వరకు",
            GulikaiKalam: "07:33 నుండి 09:10 వరకు",
            Karanamulu: "వణిజ 14:12 వరకు, విష్టి 01:52, ఆగష్టు 09 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "ఉత్తరాషాఢ 14:28 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "10:46 నుండి 12:22 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "05:57",
            Sunset: "18:46",
            Thiti: "చతుర్దశి 14:12 వరకు",
            Yamaganda: "15:34 నుండి 17:10 వరకు",
            Yoga: "ఆయుష్మాన్ 04:09 వరకు",
            Varjyam: "18:27 నుండి 20:03 వరకు",
            Moonrise: "18:15",
            Moonset: "05:43, ఆగష్టు 09",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:47 వరకు",
            Amrutakalam: "03:42, ఆగష్టు 10 నుండి 05:16, ఆగష్టు 10 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము 02:11, ఆగష్టు 10 వరకు",
            Date: "శనివారము, ఆగష్టు 09, 2025",
            DurMuhurtamulu: "05:58 నుండి 06:49 వరకు, 06:49 నుండి 07:40 వరకు",
            GulikaiKalam: "05:58 నుండి 07:34 వరకు",
            Karanamulu: "బవ 13:24 వరకు, బాలవ 00:50, ఆగష్టు 10 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "శ్రవణం 14:23 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "09:10 నుండి 10:46 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "05:58",
            Sunset: "18:45",
            Thiti: "పౌర్ణమి 13:24 వరకు",
            Yamaganda: "13:58 నుండి 15:33 వరకు",
            Yoga: "సౌభాగ్య 02:15, ఆగష్టు 10 వరకు",
            Varjyam: "18:18 నుండి 19:52 వరకు",
            Moonrise: "18:59",
            Moonset: "కాదు చంద్రాస్తమయం",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "pournami",
            FestDate: "9",
            LordImage: require("../images/holidays/7-1)Rakshabandhan.png"),
          },
          {
            Abhijit: "11:56 నుండి 12:47 వరకు",
            Amrutakalam: "ఏమి లేదు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము",
            Date: "ఆదివారము, ఆగష్టు 10, 2025",
            DurMuhurtamulu: "17:03 నుండి 17:54 వరకు",
            GulikaiKalam: "15:33 నుండి 17:09 వరకు",
            Karanamulu: "కౌలవ 12:09 వరకు, తైతిల 23:24 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "ధనిష్ఠ 13:52 వరకు",
            MainNakshatra: "ధనిష్ఠ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "17:09 నుండి 18:45 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "05:58",
            Sunset: "18:45",
            Thiti: "పాడ్యమి 12:09 వరకు",
            Yamaganda: "12:21 నుండి 13:57 వరకు",
            Yoga: "శోభన 00:02, ఆగష్టు 11 వరకు",
            Varjyam: "20:49 నుండి 22:21 వరకు",
            Moonrise: "19:39",
            Moonset: "06:41",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:47 వరకు",
            Amrutakalam:
              "06:04 నుండి 07:36 వరకు, 04:14, ఆగష్టు 12 నుండి 05:46 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము",
            Date: "సోమవారము, ఆగష్టు 11, 2025",
            DurMuhurtamulu: "12:47 నుండి 13:38 వరకు, 15:20 నుండి 16:11 వరకు",
            GulikaiKalam: "13:57 నుండి 15:33 వరకు",
            Karanamulu: "బవ 10:33 వరకు, వణిజ 21:38 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "శతభిషం 13:00 వరకు",
            MainNakshatra: "శతభిషం",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "07:34 నుండి 09:10 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "05:58",
            Sunset: "18:44",
            Thiti: "విదియ 10:33 వరకు",
            Yamaganda: "10:45 నుండి 12:21 వరకు",
            Yoga: "అతిగణ్డ 21:34 వరకు",
            Varjyam: "19:06 నుండి 20:37 వరకు",
            Moonrise: "20:18",
            Moonset: "07:38",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:47 వరకు",
            Amrutakalam: "ఏమి లేదు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము 06:10 వరకు",
            Date: "మంగళవారము, ఆగష్టు 12, 2025",
            DurMuhurtamulu: "08:31 నుండి 09:23 వరకు, 23:14 నుండి 23:59 వరకు",
            GulikaiKalam: "12:21 నుండి 13:57 వరకు",
            Karanamulu: "విష్టి 08:40 వరకు, బవ 19:39 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "పూర్వాభాద్ర 11:52 వరకు",
            MainNakshatra: "పూర్వాభాద్ర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "15:32 నుండి 17:08 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "05:58",
            Sunset: "18:44",
            Thiti: "తదియ 08:40 వరకు",
            Yamaganda: "09:10 నుండి 10:45 వరకు",
            Yoga: "సుకర్మా 18:54 వరకు",
            Varjyam: "20:56 నుండి 22:26 వరకు",
            Moonrise: "20:56",
            Moonset: "08:34",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "06:00 నుండి 07:31 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం",
            Date: "బుధవారము, ఆగష్టు 13, 2025",
            DurMuhurtamulu: "11:55 నుండి 12:46 వరకు",
            GulikaiKalam: "10:45 నుండి 12:21 వరకు",
            Karanamulu:
              "బాలవ 06:35 వరకు, కౌలవ 17:30 వరకు, తైతిల 04:23, ఆగష్టు 14 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "ఉత్తరాభాద్ర 10:32 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "12:21 నుండి 13:56 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "05:59",
            Sunset: "18:43",
            Thiti: "చవితి 06:35 వరకు, పంచమి 04:23, ఆగష్టు 14 వరకు",
            Yamaganda: "07:34 నుండి 09:10 వరకు",
            Yoga: "ధృతి 16:05 వరకు",
            Varjyam: "21:49 నుండి 23:19 వరకు",
            Moonrise: "21:36",
            Moonset: "09:31",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:55 నుండి 12:46 వరకు",
            Amrutakalam:
              "06:50 నుండి 08:20 వరకు, 00:51, ఆగష్టు 15 నుండి 02:21, ఆగష్టు 15 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం 09:06 వరకు",
            Date: "గురువారము, ఆగష్టు 14, 2025",
            DurMuhurtamulu: "10:13 నుండి 11:04 వరకు, 15:19 నుండి 16:10 వరకు",
            GulikaiKalam: "09:10 నుండి 10:45 వరకు",
            Karanamulu: "బవ 15:15 వరకు, వణిజ 02:07, ఆగష్టు 15 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "రేవతి 09:06 వరకు",
            MainNakshatra: "రేవతి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "13:56 నుండి 15:32 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "05:59",
            Sunset: "18:43",
            Thiti: "షష్టి 02:07, ఆగష్టు 15 వరకు",
            Yamaganda: "05:59 నుండి 07:34 వరకు",
            Yoga: "శూల 13:12 వరకు",
            Varjyam: "03:51, ఆగష్టు 15 నుండి 05:21, ఆగష్టు 15 వరకు",
            Moonrise: "22:17",
            Moonset: "10:29",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:55 నుండి 12:46 వరకు",
            Amrutakalam: "01:36, ఆగష్టు 16 నుండి 03:06, ఆగష్టు 16 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం",
            Date: "శుక్రవారము, ఆగష్టు 15, 2025",
            DurMuhurtamulu: "08:32 నుండి 09:23 వరకు, 12:46 నుండి 13:37 వరకు",
            GulikaiKalam: "07:34 నుండి 09:10 వరకు",
            Karanamulu: "విష్టి 12:58 వరకు, బవ 23:49 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "అశ్విని 07:36 వరకు",
            MainNakshatra: "అశ్విని",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "10:45 నుండి 12:21 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "05:59",
            Sunset: "18:42",
            Thiti: "సప్తమి 23:49 వరకు",
            Yamaganda: "15:31 నుండి 17:07 వరకు",
            Yoga: "గణ్డ 10:17 వరకు",
            Varjyam: "16:36 నుండి 18:06 వరకు",
            Moonrise: "23:02",
            Moonset: "11:29",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "15",
            LordImage: require("../images/holidays/7-3)Independenceday.png"),
          },
          {
            Abhijit: "11:55 నుండి 12:46 వరకు",
            Amrutakalam: "02:23, ఆగష్టు 17 నుండి 03:53, ఆగష్టు 17 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం 11:43 వరకు",
            Date: "శనివారము, ఆగష్టు 16, 2025",
            DurMuhurtamulu: "05:59 నుండి 06:50 వరకు, 06:50 నుండి 07:41 వరకు",
            GulikaiKalam: "05:59 నుండి 07:35 వరకు",
            Karanamulu: "బాలవ 10:41 వరకు, కౌలవ 21:34 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "భరణి 06:06 వరకు, కృత్తిక 04:38, ఆగష్టు 17 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "09:10 నుండి 10:45 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "05:59",
            Sunset: "18:41",
            Thiti: "అష్టమి 21:34 వరకు",
            Yamaganda: "13:56 నుండి 15:31 వరకు",
            Yoga: "వృద్ధి 07:21 వరకు, ధ్రువ 04:28, ఆగష్టు 17 వరకు",
            Varjyam: "17:22 నుండి 18:52 వరకు",
            Moonrise: "23:53",
            Moonset: "12:32",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "16",
            LordImage: require("../images/holidays/7-6)Krishna.png"),
          },
          {
            Abhijit: "11:55 నుండి 12:45 వరకు",
            Amrutakalam: "00:16, ఆగష్టు 18 నుండి 01:47, ఆగష్టు 18 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృషభం",
            Date: "ఆదివారము, ఆగష్టు 17, 2025",
            DurMuhurtamulu: "16:59 నుండి 17:50 వరకు",
            GulikaiKalam: "15:30 నుండి 17:05 వరకు",
            Karanamulu: "తైతిల 08:28 వరకు, బవ 19:24 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "రోహిణి 03:17, ఆగష్టు 18 వరకు",
            MainNakshatra: "రోహిణి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "17:05 నుండి 18:41 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:00",
            Sunset: "18:41",
            Thiti: "నవమి 19:24 వరకు",
            Yamaganda: "12:20 నుండి 13:55 వరకు",
            Yoga: "వ్యాఘాత 01:40, ఆగష్టు 18 వరకు",
            Varjyam: "19:44 నుండి 21:15 వరకు",
            Moonrise: "00:50, ఆగష్టు 18",
            Moonset: "13:37",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:55 నుండి 12:45 వరకు",
            Amrutakalam: "17:44 నుండి 19:15 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృషభం 14:40 వరకు",
            Date: "సోమవారము, ఆగష్టు 18, 2025",
            DurMuhurtamulu: "12:45 నుండి 13:36 వరకు, 15:17 నుండి 16:08 వరకు",
            GulikaiKalam: "13:55 నుండి 15:30 వరకు",
            Karanamulu: "వణిజ 06:22 వరకు, విష్టి 17:22 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "మృగశిర 02:06, ఆగష్టు 19 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "07:35 నుండి 09:10 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:00",
            Sunset: "18:40",
            Thiti: "దశమి 17:22 వరకు",
            Yamaganda: "10:45 నుండి 12:20 వరకు",
            Yoga: "హర్షణ 23:00 వరకు",
            Varjyam: "08:36 నుండి 10:08 వరకు",
            Moonrise: "01:51, ఆగష్టు 19",
            Moonset: "14:41",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:54 నుండి 12:45 వరకు",
            Amrutakalam: "15:32 నుండి 17:04 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం",
            Date: "మంగళవారము, ఆగష్టు 19, 2025",
            DurMuhurtamulu: "08:32 నుండి 09:22 వరకు, 23:12 నుండి 23:57 వరకు",
            GulikaiKalam: "12:20 నుండి 13:55 వరకు",
            Karanamulu: "బాలవ 15:32 వరకు, కౌలవ 02:43, ఆగష్టు 20 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "ఆరుద్ర 01:07, ఆగష్టు 20 వరకు",
            MainNakshatra: "ఆరుద్ర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "15:29 నుండి 17:04 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:00",
            Sunset: "18:39",
            Thiti: "ఏకాదశి 15:32 వరకు",
            Yamaganda: "09:10 నుండి 10:45 వరకు",
            Yoga: "వజ్ర 20:30 వరకు",
            Varjyam: "10:09 నుండి 11:41 వరకు",
            Moonrise: "02:54, ఆగష్టు 20",
            Moonset: "15:42",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "22:07 నుండి 23:40 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం 18:35 వరకు",
            Date: "బుధవారము, ఆగష్టు 20, 2025",
            DurMuhurtamulu: "11:54 నుండి 12:45 వరకు",
            GulikaiKalam: "10:45 నుండి 12:19 వరకు",
            Karanamulu: "తైతిల 13:58 వరకు, బవ 01:18, ఆగష్టు 21 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "పునర్వసు 00:27, ఆగష్టు 21 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "12:19 నుండి 13:54 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:00",
            Sunset: "18:39",
            Thiti: "ద్వాదశి 13:58 వరకు",
            Yamaganda: "07:35 నుండి 09:10 వరకు",
            Yoga: "సిద్ధి 18:13 వరకు",
            Varjyam: "12:47 నుండి 14:20 వరకు",
            Moonrise: "03:56, ఆగష్టు 21",
            Moonset: "16:38",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:54 నుండి 12:44 వరకు",
            Amrutakalam: "17:49 నుండి 19:24 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "గురువారము, ఆగష్టు 21, 2025",
            DurMuhurtamulu: "10:13 నుండి 11:03 వరకు, 15:16 నుండి 16:06 వరకు",
            GulikaiKalam: "09:10 నుండి 10:44 వరకు",
            Karanamulu: "వణిజ 12:44 వరకు, విష్టి 00:16, ఆగష్టు 22 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "పుష్యమి 00:08, ఆగష్టు 22 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "13:54 నుండి 15:29 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:00",
            Sunset: "18:38",
            Thiti: "త్రయోదశి 12:44 వరకు",
            Yamaganda: "06:00 నుండి 07:35 వరకు",
            Yoga: "వ్యతీపాత 16:14 వరకు",
            Varjyam: "08:20 నుండి 09:55 వరకు",
            Moonrise: "04:56, ఆగష్టు 22",
            Moonset: "17:27",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:54 నుండి 12:44 వరకు",
            Amrutakalam: "22:40 నుండి 00:16, ఆగష్టు 23 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం 00:16, ఆగష్టు 23 వరకు",
            Date: "శుక్రవారము, ఆగష్టు 22, 2025",
            DurMuhurtamulu: "08:32 నుండి 09:22 వరకు, 12:44 నుండి 13:35 వరకు",
            GulikaiKalam: "07:35 నుండి 09:10 వరకు",
            Karanamulu: "శకుని 11:55 వరకు, చతుష్పాద 23:41 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "ఆశ్లేష 00:16, ఆగష్టు 23 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "10:44 నుండి 12:19 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:01",
            Sunset: "18:37",
            Thiti: "చతుర్దశి 11:55 వరకు",
            Yamaganda: "15:28 నుండి 17:03 వరకు",
            Yoga: "వారీయన 14:35 వరకు",
            Varjyam: "13:00 నుండి 14:37 వరకు",
            Moonrise: "05:52, ఆగష్టు 23",
            Moonset: "18:10",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:44 వరకు",
            Amrutakalam: "22:27 నుండి 00:05, ఆగష్టు 24 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం",
            Date: "శనివారము, ఆగష్టు 23, 2025",
            DurMuhurtamulu: "06:01 నుండి 06:51 వరకు, 06:51 నుండి 07:42 వరకు",
            GulikaiKalam: "06:01 నుండి 07:35 వరకు",
            Karanamulu: "నాగ 11:35 వరకు, కింస్తుఘ్న 23:37 వరకు",
            Masam: "శ్రావణము",
            Nakshatra1: "మఖ 00:54, ఆగష్టు 24 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "09:10 నుండి 10:44 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:01",
            Sunset: "18:37",
            Thiti: "అమావాస్య 11:35 వరకు",
            Yamaganda: "13:53 నుండి 15:28 వరకు",
            Yoga: "పరిఘ 13:20 వరకు",
            Varjyam: "12:35 నుండి 14:14 వరకు",
            Moonrise: "ఏమి లేదు",
            Moonset: "18:48",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "amavasya",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:44 వరకు",
            Amrutakalam: "19:22 నుండి 21:03 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం",
            Date: "ఆదివారము, ఆగష్టు 24, 2025",
            DurMuhurtamulu: "16:55 నుండి 17:45 వరకు",
            GulikaiKalam: "15:27 నుండి 17:01 వరకు",
            Karanamulu: "బవ 11:48 వరకు, బాలవ 00:07, ఆగష్టు 25 వరకు",
            Masam: "భాధ్రపదము",
            Nakshatra1: "పుబ్బ 02:05, ఆగష్టు 25 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "17:01 నుండి 18:36 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:01",
            Sunset: "18:36",
            Thiti: "పాడ్యమి 11:48 వరకు",
            Yamaganda: "12:18 నుండి 13:53 వరకు",
            Yoga: "శివ 12:30 వరకు",
            Varjyam: "09:18 నుండి 10:59 వరకు",
            Moonrise: "06:45",
            Moonset: "19:24",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:43 వరకు",
            Amrutakalam: "20:06 నుండి 21:49 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం 08:28 వరకు",
            Date: "సోమవారము, ఆగష్టు 25, 2025",
            DurMuhurtamulu: "12:43 నుండి 13:33 వరకు, 15:14 నుండి 16:04 వరకు",
            GulikaiKalam: "13:52 నుండి 15:27 వరకు",
            Karanamulu: "కౌలవ 12:34 వరకు, తైతిల 01:10, ఆగష్టు 26 వరకు",
            Masam: "భాధ్రపదము",
            Nakshatra1: "ఉత్తర 03:49, ఆగష్టు 26 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "07:35 నుండి 09:10 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:01",
            Sunset: "18:35",
            Thiti: "విదియ 12:34 వరకు",
            Yamaganda: "10:44 నుండి 12:18 వరకు",
            Yoga: "సిద్ధ 12:06 వరకు",
            Varjyam: "09:49 నుండి 11:32 వరకు",
            Moonrise: "07:36",
            Moonset: "19:57",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:43 వరకు",
            Amrutakalam: "23:30 నుండి 01:15, ఆగష్టు 27 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య",
            Date: "మంగళవారము, ఆగష్టు 26, 2025",
            DurMuhurtamulu: "08:32 నుండి 09:22 వరకు, 23:09 నుండి 23:55 వరకు",
            GulikaiKalam: "12:18 నుండి 13:52 వరకు",
            Karanamulu: "గర 13:54 వరకు, వణిజ 02:46, ఆగష్టు 27 వరకు",
            Masam: "భాధ్రపదము",
            Nakshatra1: "హస్త నిండా రాత్రి వరకు",
            MainNakshatra: "హస్త",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "15:26 నుండి 17:00 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:01",
            Sunset: "18:34",
            Thiti: "తదియ 13:54 వరకు",
            Yamaganda: "09:10 నుండి 10:44 వరకు",
            Yoga: "సాధ్య 12:09 వరకు",
            Varjyam: "13:01 నుండి 14:46 వరకు",
            Moonrise: "08:24",
            Moonset: "20:30",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "01:37, ఆగష్టు 28 నుండి 03:24, ఆగష్టు 28 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య 19:21 వరకు",
            Date: "బుధవారము, ఆగష్టు 27, 2025",
            DurMuhurtamulu: "11:52 నుండి 12:43 వరకు",
            GulikaiKalam: "10:44 నుండి 12:18 వరకు",
            Karanamulu: "విష్టి 15:44 వరకు, బవ 04:48, ఆగష్టు 28 వరకు",
            Masam: "భాధ్రపదము",
            Nakshatra1: "హస్త 06:04 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "12:18 నుండి 13:52 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:01",
            Sunset: "18:34",
            Thiti: "చవితి 15:44 వరకు",
            Yamaganda: "07:35 నుండి 09:09 వరకు",
            Yoga: "శుభ 12:35 వరకు",
            Varjyam: "14:57 నుండి 16:44 వరకు",
            Moonrise: "09:13",
            Moonset: "21:04",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "27",
            LordImage: require("../images/holidays/7-4)VinayakaChavithi.png"),
          },
          {
            Abhijit: "11:52 నుండి 12:42 వరకు",
            Amrutakalam: "01:46, ఆగష్టు 29 నుండి 03:34, ఆగష్టు 29 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల",
            Date: "గురువారము, ఆగష్టు 28, 2025",
            DurMuhurtamulu: "10:12 నుండి 11:02 వరకు, 15:13 నుండి 16:03 వరకు",
            GulikaiKalam: "09:09 నుండి 10:43 వరకు",
            Karanamulu: "బాలవ 17:56 వరకు, కౌలవ నిండా రాత్రి వరకు",
            Masam: "భాధ్రపదము",
            Nakshatra1: "చిత్తా 08:43 వరకు",
            MainNakshatra: "చిత్తా",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "13:51 నుండి 15:25 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:02",
            Sunset: "18:33",
            Thiti: "పంచమి 17:56 వరకు",
            Yamaganda: "06:02 నుండి 07:36 వరకు",
            Yoga: "శుక్ల 13:18 వరకు",
            Varjyam: "15:00 నుండి 16:48 వరకు",
            Moonrise: "10:02",
            Moonset: "21:39",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:52 నుండి 12:42 వరకు",
            Amrutakalam: "04:44, ఆగష్టు 30 నుండి 06:31, ఆగష్టు 30 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల",
            Date: "శుక్రవారము, ఆగష్టు 29, 2025",
            DurMuhurtamulu: "08:32 నుండి 09:22 వరకు, 12:42 నుండి 13:32 వరకు",
            GulikaiKalam: "07:36 నుండి 09:09 వరకు",
            Karanamulu: "కౌలవ 07:08 వరకు, తైతిల 20:21 వరకు",
            Masam: "భాధ్రపదము",
            Nakshatra1: "స్వాతి 11:38 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "10:43 నుండి 12:17 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:02",
            Sunset: "18:32",
            Thiti: "షష్టి 20:21 వరకు",
            Yamaganda: "15:24 నుండి 16:58 వరకు",
            Yoga: "బ్రహ్మ 14:13 వరకు",
            Varjyam: "17:56 నుండి 19:44 వరకు",
            Moonrise: "10:52",
            Moonset: "22:17",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:52 నుండి 12:42 వరకు",
            Amrutakalam: "05:49, ఆగష్టు 31 నుండి 07:37, ఆగష్టు 31 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల 07:53 వరకు",
            Date: "శనివారము, ఆగష్టు 30, 2025",
            DurMuhurtamulu: "06:02 నుండి 06:52 వరకు, 06:52 నుండి 07:42 వరకు",
            GulikaiKalam: "06:02 నుండి 07:36 వరకు",
            Karanamulu: "గర 09:34 వరకు, వణిజ 22:46 వరకు",
            Masam: "భాధ్రపదము",
            Nakshatra1: "విశాఖ 14:37 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "09:09 నుండి 10:43 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:02",
            Sunset: "18:32",
            Thiti: "సప్తమి 22:46 వరకు",
            Yamaganda: "13:50 నుండి 15:24 వరకు",
            Yoga: "ఐన్ద్ర 15:10 వరకు",
            Varjyam: "19:05 నుండి 20:53 వరకు",
            Moonrise: "11:44",
            Moonset: "22:59",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:51 నుండి 12:41 వరకు",
            Amrutakalam: "ఏమి లేదు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "ఆదివారము, ఆగష్టు 31, 2025",
            DurMuhurtamulu: "16:51 నుండి 17:41 వరకు",
            GulikaiKalam: "15:23 నుండి 16:57 వరకు",
            Karanamulu: "విష్టి 11:54 వరకు, బవ 00:57, సెప్టెంబర్ 01 వరకు",
            Masam: "బాధ్రపదము",
            Nakshatra1: "అనూరాధ 17:27 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "16:57 నుండి 18:31 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:02",
            Sunset: "18:31",
            Thiti: "అష్టమి 00:57, సెప్టెంబర్ 01 వరకు",
            Yamaganda: "12:16 నుండి 13:50 వరకు",
            Yoga: "వైధృతి 15:59 వరకు",
            Varjyam: "23:37 నుండి 01:23, సెప్టెంబర్ 01 వరకు",
            Moonrise: "12:37",
            Moonset: "23:46",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
        ],
      },
      {
        day: [
          {
            Abhijit: "11:51 నుండి 12:41 వరకు",
            Amrutakalam: "10:13 నుండి 11:58 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము 19:55 వరకు",
            Date: "సోమవారము, సెప్టెంబర్ 01, 2025",
            DurMuhurtamulu: "12:41 నుండి 13:31 వరకు, 15:10 నుండి 16:00 వరకు",
            GulikaiKalam: "13:49 నుండి 15:23 వరకు",
            Karanamulu: "బాలవ 13:54 వరకు, కౌలవ 02:43, సెప్టెంబర్ 02 వరకు",
            Masam: "బాధ్రపదము",
            Nakshatra1: "జ్యేష్ఠ 19:55 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "07:36 నుండి 09:09 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:02",
            Sunset: "18:30",
            Thiti: "నవమి 02:43, సెప్టెంబర్ 02 వరకు",
            Yamaganda: "10:43 నుండి 12:16 వరకు",
            Yoga: "విష్కుమ్భ 16:32 వరకు",
            Varjyam: "04:34, సెప్టెంబర్ 02 నుండి 06:17, సెప్టెంబర్ 02 వరకు",
            Moonrise: "13:31",
            Moonset: "00:37, సెప్టెంబర్ 02",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:51 నుండి 12:41 వరకు",
            Amrutakalam: "14:56 నుండి 16:40 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "మంగళవారము, సెప్టెంబర్ 02, 2025",
            DurMuhurtamulu: "08:32 నుండి 09:21 వరకు, 23:06 నుండి 23:53 వరకు",
            GulikaiKalam: "12:16 నుండి 13:49 వరకు",
            Karanamulu: "తైతిల 15:22 వరకు, బవ 03:53, సెప్టెంబర్ 03 వరకు",
            Masam: "బాధ్రపదము",
            Nakshatra1: "మూల 21:51 వరకు",
            MainNakshatra: "మూల",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "15:22 నుండి 16:56 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:02",
            Sunset: "18:29",
            Thiti: "దశమి 03:53, సెప్టెంబర్ 03 వరకు",
            Yamaganda: "09:09 నుండి 10:42 వరకు",
            Yoga: "ప్రీతి 16:40 వరకు",
            Varjyam: "20:07 నుండి 21:51 వరకు",
            Moonrise: "14:25",
            Moonset: "01:32, సెప్టెంబర్ 03",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "18:05 నుండి 19:46 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు 05:21, సెప్టెంబర్ 04 వరకు",
            Date: "బుధవారము, సెప్టెంబర్ 03, 2025",
            DurMuhurtamulu: "11:50 నుండి 12:40 వరకు",
            GulikaiKalam: "10:42 నుండి 12:15 వరకు",
            Karanamulu: "వణిజ 16:12 వరకు, విష్టి 04:21, సెప్టెంబర్ 04 వరకు",
            Masam: "బాధ్రపదము",
            Nakshatra1: "పూర్వాషాఢ 23:08 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "12:15 నుండి 13:49 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:03",
            Sunset: "18:28",
            Thiti: "ఏకాదశి 04:21, సెప్టెంబర్ 04 వరకు",
            Yamaganda: "07:36 నుండి 09:09 వరకు",
            Yoga: "ఆయుష్మాన్ 16:17 వరకు",
            Varjyam: "07:58 నుండి 09:39 వరకు",
            Moonrise: "15:17",
            Moonset: "02:29, సెప్టెంబర్ 04",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:50 నుండి 12:40 వరకు",
            Amrutakalam: "17:10 నుండి 18:49 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము",
            Date: "గురువారము, సెప్టెంబర్ 04, 2025",
            DurMuhurtamulu: "10:11 నుండి 11:01 వరకు, 15:09 నుండి 15:58 వరకు",
            GulikaiKalam: "09:09 నుండి 10:42 వరకు",
            Karanamulu: "బవ 16:20 వరకు, బాలవ 04:08, సెప్టెంబర్ 05 వరకు",
            Masam: "బాధ్రపదము",
            Nakshatra1: "ఉత్తరాషాఢ 23:44 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "13:48 నుండి 15:21 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:03",
            Sunset: "18:27",
            Thiti: "ద్వాదశి 04:08, సెప్టెంబర్ 05 వరకు",
            Yamaganda: "06:03 నుండి 07:36 వరకు",
            Yoga: "సౌభాగ్య 15:22 వరకు",
            Varjyam:
              "07:20 నుండి 08:58 వరకు, 03:43, సెప్టెంబర్ 05 నుండి 05:18, సెప్టెంబర్ 05 వరకు",
            Moonrise: "16:06",
            Moonset: "03:28, సెప్టెంబర్ 05",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:50 నుండి 12:39 వరకు",
            Amrutakalam: "13:16 నుండి 14:52 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము",
            Date: "శుక్రవారము, సెప్టెంబర్ 05, 2025",
            DurMuhurtamulu: "08:32 నుండి 09:21 వరకు, 12:39 నుండి 13:29 వరకు",
            GulikaiKalam: "07:36 నుండి 09:09 వరకు",
            Karanamulu: "కౌలవ 15:45 వరకు, తైతిల 03:12, సెప్టెంబర్ 06 వరకు",
            Masam: "బాధ్రపదము",
            Nakshatra1: "శ్రవణం 23:38 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "10:42 నుండి 12:15 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:03",
            Sunset: "18:27",
            Thiti: "త్రయోదశి 03:12, సెప్టెంబర్ 06 వరకు",
            Yamaganda: "15:21 నుండి 16:54 వరకు",
            Yoga: "శోభన 13:53 వరకు",
            Varjyam: "03:31, సెప్టెంబర్ 06 నుండి 05:04, సెప్టెంబర్ 06 వరకు",
            Moonrise: "16:51",
            Moonset: "04:27, సెప్టెంబర్ 06",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "5",
            LordImage: require("../images/holidays/8-0)TeachersDay.png"),
            LordImage1: require("../images/holidays/Jamat_Ul_Vida.png"),
          },
          {
            Abhijit: "11:50 నుండి 12:39 వరకు",
            Amrutakalam: "12:50 నుండి 14:23 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము 11:21 వరకు",
            Date: "శనివారము, సెప్టెంబర్ 06, 2025",
            DurMuhurtamulu: "06:03 నుండి 06:52 వరకు, 06:52 నుండి 07:42 వరకు",
            GulikaiKalam: "06:03 నుండి 07:36 వరకు",
            Karanamulu: "బవ 14:31 వరకు, వణిజ 01:41, సెప్టెంబర్ 07 వరకు",
            Masam: "బాధ్రపదము",
            Nakshatra1: "ధనిష్ఠ 22:55 వరకు",
            MainNakshatra: "ధనిష్ఠ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "09:09 నుండి 10:42 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:03",
            Sunset: "18:26",
            Thiti: "చతుర్దశి 01:41, సెప్టెంబర్ 07 వరకు",
            Yamaganda: "13:47 నుండి 15:20 వరకు",
            Yoga: "అతిగణ్డ 11:52 వరకు",
            Varjyam: "05:45, సెప్టెంబర్ 07 నుండి 07:16, సెప్టెంబర్ 07 వరకు",
            Moonrise: "17:33",
            Moonset: "05:25, సెప్టెంబర్ 07",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:39 వరకు",
            Amrutakalam: "14:51 నుండి 16:22 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము",
            Date: "ఆదివారము, సెప్టెంబర్ 07, 2025",
            DurMuhurtamulu: "16:46 నుండి 17:35 వరకు",
            GulikaiKalam: "15:19 నుండి 16:52 వరకు",
            Karanamulu: "విష్టి 12:43 వరకు, బవ 23:38 వరకు",
            Masam: "బాధ్రపదము",
            Nakshatra1: "శతభిషం 21:41 వరకు",
            MainNakshatra: "శతభిషం",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "16:52 నుండి 18:25 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:03",
            Sunset: "18:25",
            Thiti: "పౌర్ణమి 23:38 వరకు",
            Yamaganda: "12:14 నుండి 13:47 వరకు",
            Yoga: "సుకర్మా 09:23 వరకు",
            Varjyam: "03:39, సెప్టెంబర్ 08 నుండి 05:08, సెప్టెంబర్ 08 వరకు",
            Moonrise: "18:13",
            Moonset: "ఏమి లేదు",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "pournami",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:38 వరకు",
            Amrutakalam: "12:35 నుండి 14:04 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము 14:29 వరకు",
            Date: "సోమవారము, సెప్టెంబర్ 08, 2025",
            DurMuhurtamulu: "12:38 నుండి 13:28 వరకు, 15:07 నుండి 15:56 వరకు",
            GulikaiKalam: "13:46 నుండి 15:19 వరకు",
            Karanamulu: "బాలవ 10:27 వరకు, కౌలవ 21:11 వరకు",
            Masam: "బాధ్రపదము",
            Nakshatra1: "పూర్వాభాద్ర 20:02 వరకు",
            MainNakshatra: "పూర్వాభాద్ర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "07:36 నుండి 09:08 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:03",
            Sunset: "18:24",
            Thiti: "పాడ్యమి 21:11 వరకు",
            Yamaganda: "10:41 నుండి 12:14 వరకు",
            Yoga: "ధృతి 06:30 వరకు, శూల 03:20, సెప్టెంబర్ 09 వరకు",
            Varjyam: "04:52, సెప్టెంబర్ 09 నుండి 06:20, సెప్టెంబర్ 09 వరకు",
            Moonrise: "18:53",
            Moonset: "06:22",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:38 వరకు",
            Amrutakalam: "13:42 నుండి 15:10 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం",
            Date: "మంగళవారము, సెప్టెంబర్ 09, 2025",
            DurMuhurtamulu: "08:31 నుండి 09:21 వరకు, 23:03 నుండి 23:50 వరకు",
            GulikaiKalam: "12:13 నుండి 13:46 వరకు",
            Karanamulu: "తైతిల 07:51 వరకు, బవ 18:28 వరకు",
            Masam: "బాధ్రపదము",
            Nakshatra1: "ఉత్తరాభాద్ర 18:07 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "15:18 నుండి 16:51 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:03",
            Sunset: "18:23",
            Thiti: "విదియ 18:28 వరకు",
            Yamaganda: "09:08 నుండి 10:41 వరకు",
            Yoga: "గణ్డ 23:59 వరకు",
            Varjyam: "05:05, సెప్టెంబర్ 10 నుండి 06:33, సెప్టెంబర్ 10 వరకు",
            Moonrise: "19:32",
            Moonset: "07:20",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "13:51 నుండి 15:19 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం 16:03 వరకు",
            Date: "బుధవారము, సెప్టెంబర్ 10, 2025",
            DurMuhurtamulu: "11:48 నుండి 12:38 వరకు",
            GulikaiKalam: "10:41 నుండి 12:13 వరకు",
            Karanamulu: "విష్టి 15:37 వరకు, బవ 02:11, సెప్టెంబర్ 11 వరకు",
            Masam: "బాధ్రపదము",
            Nakshatra1: "రేవతి 16:03 వరకు",
            MainNakshatra: "రేవతి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "12:13 నుండి 13:45 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:04",
            Sunset: "18:22",
            Thiti: "తదియ 15:37 వరకు",
            Yamaganda: "07:36 నుండి 09:08 వరకు",
            Yoga: "వృద్ధి 20:31 వరకు",
            Varjyam: "ఏమి లేదు",
            Moonrise: "20:14",
            Moonset: "08:19",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:37 వరకు",
            Amrutakalam: "07:23 నుండి 08:51 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం",
            Date: "గురువారము, సెప్టెంబర్ 11, 2025",
            DurMuhurtamulu: "10:10 నుండి 10:59 వరకు, 15:05 నుండి 15:54 వరకు",
            GulikaiKalam: "09:08 నుండి 10:40 వరకు",
            Karanamulu: "బాలవ 12:45 వరకు, కౌలవ 23:20 వరకు",
            Masam: "బాధ్రపదము",
            Nakshatra1: "అశ్విని 13:58 వరకు",
            MainNakshatra: "అశ్విని",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "13:45 నుండి 15:17 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:04",
            Sunset: "18:22",
            Thiti: "చవితి 12:45 వరకు",
            Yamaganda: "06:04 నుండి 07:36 వరకు",
            Yoga: "ధ్రువ 17:05 వరకు",
            Varjyam:
              "10:18 నుండి 11:46 వరకు, 22:46 నుండి 00:14, సెప్టెంబర్ 12 వరకు",
            Moonrise: "20:59",
            Moonset: "09:21",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:37 వరకు",
            Amrutakalam: "07:34 నుండి 09:02 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం 17:30 వరకు",
            Date: "శుక్రవారము, సెప్టెంబర్ 12, 2025",
            DurMuhurtamulu: "08:31 నుండి 09:20 వరకు, 12:37 నుండి 13:26 వరకు",
            GulikaiKalam: "07:36 నుండి 09:08 వరకు",
            Karanamulu: "తైతిల 09:58 వరకు, బవ 20:39 వరకు",
            Masam: "బాధ్రపదము",
            Nakshatra1: "భరణి 11:58 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "10:40 నుండి 12:12 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:04",
            Sunset: "18:21",
            Thiti: "పంచమి 09:58 వరకు",
            Yamaganda: "15:17 నుండి 16:49 వరకు",
            Yoga: "వ్యాఘాత 13:44 వరకు",
            Varjyam: "23:05 నుండి 00:34, సెప్టెంబర్ 13 వరకు",
            Moonrise: "21:49",
            Moonset: "10:24",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:36 వరకు",
            Amrutakalam:
              "07:58 నుండి 09:27 వరకు, 05:41, సెప్టెంబర్ 14 నుండి 07:11, సెప్టెంబర్ 14 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృషభం",
            Date: "శనివారము, సెప్టెంబర్ 13, 2025",
            DurMuhurtamulu: "06:04 నుండి 06:53 వరకు, 06:53 నుండి 07:42 వరకు",
            GulikaiKalam: "06:04 నుండి 07:36 వరకు",
            Karanamulu:
              "వణిజ 07:23 వరకు, విష్టి 18:11 వరకు, బవ 05:04, సెప్టెంబర్ 14 వరకు",
            Masam: "బాధ్రపదము",
            Nakshatra1: "కృత్తిక 10:11 వరకు",
            MainNakshatra: "కృత్తిక",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "09:08 నుండి 10:40 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:04",
            Sunset: "18:20",
            Thiti: "షష్టి 07:23 వరకు, సప్తమి 05:04, సెప్టెంబర్ 14 వరకు",
            Yamaganda: "13:44 నుండి 15:16 వరకు",
            Yoga: "హర్షణ 10:33 వరకు",
            Varjyam: "01:11, సెప్టెంబర్ 14 నుండి 02:41, సెప్టెంబర్ 14 వరకు",
            Moonrise: "22:45",
            Moonset: "11:30",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:36 వరకు",
            Amrutakalam: "23:09 నుండి 00:40, సెప్టెంబర్ 15 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "ఆదివారము, సెప్టెంబర్ 14, 2025",
            DurMuhurtamulu: "16:41 నుండి 17:30 వరకు",
            GulikaiKalam: "15:15 నుండి 16:47 వరకు",
            Karanamulu: "బాలవ 16:02 వరకు, కౌలవ 03:06, సెప్టెంబర్ 15 వరకు",
            Masam: "బాధ్రపదము",
            Nakshatra1: "రోహిణి 08:41 వరకు",
            MainNakshatra: "రోహిణి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "16:47 నుండి 18:19 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:04",
            Sunset: "18:19",
            Thiti: "అష్టమి 03:06, సెప్టెంబర్ 15 వరకు",
            Yamaganda: "12:12 నుండి 13:43 వరకు",
            Yoga: "వజ్ర 07:35 వరకు, సిద్ధి 04:55, సెప్టెంబర్ 15 వరకు",
            Varjyam: "14:01 నుండి 15:32 వరకు",
            Moonrise: "23:44",
            Moonset: "12:35",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:36 వరకు",
            Amrutakalam: "21:05 నుండి 22:38 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం",
            Date: "సోమవారము, సెప్టెంబర్ 15, 2025",
            DurMuhurtamulu: "12:36 నుండి 13:25 వరకు, 15:02 నుండి 15:51 వరకు",
            GulikaiKalam: "13:43 నుండి 15:15 వరకు",
            Karanamulu: "తైతిల 14:15 వరకు, బవ 01:31, సెప్టెంబర్ 16 వరకు",
            Masam: "బాధ్రపదము",
            Nakshatra1: "మృగశిర 07:31 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "07:36 నుండి 09:08 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:04",
            Sunset: "18:18",
            Thiti: "నవమి 01:31, సెప్టెంబర్ 16 వరకు",
            Yamaganda: "10:39 నుండి 12:11 వరకు",
            Yoga: "వ్యతీపాత 02:34, సెప్టెంబర్ 16 వరకు",
            Varjyam: "15:39 నుండి 17:12 వరకు",
            Moonrise: "00:47, సెప్టెంబర్ 16",
            Moonset: "13:37",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:46 నుండి 12:35 వరకు",
            Amrutakalam: "04:04, సెప్టెంబర్ 17 నుండి 05:38, సెప్టెంబర్ 17 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం 00:28, సెప్టెంబర్ 17 వరకు",
            Date: "మంగళవారము, సెప్టెంబర్ 16, 2025",
            DurMuhurtamulu: "08:31 నుండి 09:20 వరకు, 23:00 నుండి 23:47 వరకు",
            GulikaiKalam: "12:11 నుండి 13:42 వరకు",
            Karanamulu: "వణిజ 12:53 వరకు, విష్టి 00:21, సెప్టెంబర్ 17 వరకు",
            Masam: "బాధ్రపదము",
            Nakshatra1: "ఆరుద్ర 06:46 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "15:14 నుండి 16:46 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:04",
            Sunset: "18:17",
            Thiti: "దశమి 00:21, సెప్టెంబర్ 17 వరకు",
            Yamaganda: "09:08 నుండి 10:39 వరకు",
            Yoga: "వారీయన 00:34, సెప్టెంబర్ 17 వరకు",
            Varjyam: "18:36 నుండి 20:10 వరకు",
            Moonrise: "చంద్రోదయం 01:49, సెప్టెంబర్ 17",
            Moonset: "14:34",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "00:06, సెప్టెంబర్ 18 నుండి 01:43, సెప్టెంబర్ 18 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "బుధవారము, సెప్టెంబర్ 17, 2025",
            DurMuhurtamulu: "11:46 నుండి 12:35 వరకు",
            GulikaiKalam: "10:39 నుండి 12:10 వరకు",
            Karanamulu: "బవ 11:57 వరకు, బాలవ 23:39 వరకు",
            Masam: "బాధ్రపదము",
            Nakshatra1: "ఆరుద్ర 06:46 వరకు",
            MainNakshatra: "ఆరుద్ర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "12:10 నుండి 13:42 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:04",
            Sunset: "18:17",
            Thiti: "ఏకాదశి 23:39 వరకు",
            Yamaganda: "07:36 నుండి 09:07 వరకు",
            Yoga: "పరిఘ 22:55 వరకు",
            Varjyam: "14:28 నుండి 16:04 వరకు",
            Moonrise: "02:49, సెప్టెంబర్ 18",
            Moonset: "15:24",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:46 నుండి 12:34 వరకు",
            Amrutakalam: "05:27, సెప్టెంబర్ 19 నుండి 07:05, సెప్టెంబర్ 19 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "గురువారము, సెప్టెంబర్ 18, 2025",
            DurMuhurtamulu: "10:08 నుండి 10:57 వరకు, 15:01 నుండి 15:49 వరకు",
            GulikaiKalam: "09:07 నుండి 10:39 వరకు",
            Karanamulu: "కౌలవ 11:28 వరకు, తైతిల 23:24 వరకు",
            Masam: "బాధ్రపదము",
            Nakshatra1: "పుష్యమి 06:32 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "13:42 నుండి 15:13 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:05",
            Sunset: "18:16",
            Thiti: "ద్వాదశి 23:24 వరకు",
            Yamaganda: "06:05 నుండి 07:36 వరకు",
            Yoga: "శివ 21:37 వరకు",
            Varjyam: "19:38 నుండి 21:16 వరకు",
            Moonrise: "03:45, సెప్టెంబర్ 19",
            Moonset: "16:08",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:45 నుండి 12:34 వరకు",
            Amrutakalam: "05:35, సెప్టెంబర్ 20 నుండి 07:15, సెప్టెంబర్ 20 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం 07:05 వరకు",
            Date: "శుక్రవారము, సెప్టెంబర్ 19, 2025",
            DurMuhurtamulu: "08:31 నుండి 09:19 వరకు, 12:34 నుండి 13:23 వరకు",
            GulikaiKalam: "07:36 నుండి 09:07 వరకు",
            Karanamulu: "బవ 11:27 వరకు, వణిజ 23:36 వరకు",
            Masam: "బాధ్రపదము",
            Nakshatra1: "ఆశ్లేష 07:05 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "10:38 నుండి 12:10 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:05",
            Sunset: "18:15",
            Thiti: "త్రయోదశి 23:36 వరకు",
            Yamaganda: "15:12 నుండి 16:44 వరకు",
            Yoga: "సిద్ధ 20:41 వరకు",
            Varjyam: "19:35 నుండి 21:15 వరకు",
            Moonrise: "04:38, సెప్టెంబర్ 20",
            Moonset: "16:47",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:45 నుండి 12:34 వరకు",
            Amrutakalam: "02:45, సెప్టెంబర్ 21 నుండి 04:26, సెప్టెంబర్ 21 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం",
            Date: "శనివారము, సెప్టెంబర్ 20, 2025",
            DurMuhurtamulu: "06:05 నుండి 06:53 వరకు, 06:53 నుండి 07:42 వరకు",
            GulikaiKalam: "06:05 నుండి 07:36 వరకు",
            Karanamulu: "విష్టి 11:53 వరకు, శకుని 00:16, సెప్టెంబర్ 21 వరకు",
            Masam: "బాధ్రపదము",
            Nakshatra1: "మఖ 08:05 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "09:07 నుండి 10:38 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:05",
            Sunset: "18:14",
            Thiti: "చతుర్దశి 00:16, సెప్టెంబర్ 21 వరకు",
            Yamaganda: "13:41 నుండి 15:12 వరకు",
            Yoga: "సాధ్య 20:07 వరకు",
            Varjyam: "16:34 నుండి 18:16 వరకు",
            Moonrise: "05:29, సెప్టెంబర్ 21",
            Moonset: "17:23",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:45 నుండి 12:33 వరకు",
            Amrutakalam: "03:38, సెప్టెంబర్ 22 నుండి 05:22, సెప్టెంబర్ 22 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం 15:57 వరకు",
            Date: "ఆదివారము, సెప్టెంబర్ 21, 2025",
            DurMuhurtamulu: "16:36 నుండి 17:25 వరకు",
            GulikaiKalam: "15:11 నుండి 16:42 వరకు",
            Karanamulu: "చతుష్పాద 12:46 వరకు, నాగ 01:23, సెప్టెంబర్ 22 వరకు",
            Masam: "బాధ్రపదము",
            Nakshatra1: "పుబ్బ 09:32 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "16:42 నుండి 18:13 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:05",
            Sunset: "18:13",
            Thiti: "అమావాస్య 01:23, సెప్టెంబర్ 22 వరకు",
            Yamaganda: "12:09 నుండి 13:40 వరకు",
            Yoga: "శుభ 19:53 వరకు",
            Varjyam: "17:17 నుండి 19:01 వరకు",
            Moonrise: "ఏమి లేదు",
            Moonset: "17:57",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "amavasya",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:44 నుండి 12:33 వరకు",
            Amrutakalam: "ఏమి లేదు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య",
            Date: "సోమవారము, సెప్టెంబర్ 22, 2025",
            DurMuhurtamulu: "12:33 నుండి 13:21 వరకు, 14:58 నుండి 15:47 వరకు",
            GulikaiKalam: "13:40 నుండి 15:11 వరకు",
            Karanamulu: "కింస్తుఘ్న 14:06 వరకు, బవ 02:55, సెప్టెంబర్ 23 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "ఉత్తర 11:24 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "07:36 నుండి 09:07 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:05",
            Sunset: "18:12",
            Thiti: "పాడ్యమి 02:55, సెప్టెంబర్ 23 వరకు",
            Yamaganda: "10:38 నుండి 12:09 వరకు",
            Yoga: "శుక్ల 19:59 వరకు",
            Varjyam: "20:35 నుండి 22:21 వరకు",
            Moonrise: "06:18",
            Moonset: "18:30",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:44 నుండి 12:33 వరకు",
            Amrutakalam: "07:06 నుండి 08:51 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య 02:56, సెప్టెంబర్ 24 వరకు",
            Date: "మంగళవారము, సెప్టెంబర్ 23, 2025",
            DurMuhurtamulu: "08:30 నుండి 09:19 వరకు, 22:57 నుండి 23:45 వరకు",
            GulikaiKalam: "12:08 నుండి 13:39 వరకు",
            Karanamulu: "బాలవ 15:51 వరకు, కౌలవ 04:51, సెప్టెంబర్ 24 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "హస్త 13:40 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "15:10 నుండి 16:41 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:05",
            Sunset: "18:12",
            Thiti: "విదియ 04:51, సెప్టెంబర్ 24 వరకు",
            Yamaganda: "09:07 నుండి 10:38 వరకు",
            Yoga: "బ్రహ్మ 20:23 వరకు",
            Varjyam: "22:32 నుండి 00:18, సెప్టెంబర్ 24 వరకు",
            Moonrise: "07:06",
            Moonset: "19:03",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "09:11 నుండి 10:57 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల",
            Date: "బుధవారము, సెప్టెంబర్ 24, 2025",
            DurMuhurtamulu: "11:44 నుండి 12:32 వరకు",
            GulikaiKalam: "10:37 నుండి 12:08 వరకు",
            Karanamulu: "తైతిల 17:56 వరకు, గర నిండా రాత్రి వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "చిత్తా 16:16 వరకు",
            MainNakshatra: "చిత్తా",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "12:08 నుండి 13:39 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:05",
            Sunset: "18:11",
            Thiti: "తదియ నిండా రాత్రి వరకు",
            Yamaganda: "07:36 నుండి 09:07 వరకు",
            Yoga: "ఐన్ద్ర 21:03 వరకు",
            Varjyam: "22:33 నుండి 00:20, సెప్టెంబర్ 25 వరకు",
            Moonrise: "07:55",
            Moonset: "19:37",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:44 నుండి 12:32 వరకు",
            Amrutakalam: "09:17 నుండి 11:05 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల",
            Date: "గురువారము, సెప్టెంబర్ 25, 2025",
            DurMuhurtamulu: "10:07 నుండి 10:55 వరకు, 14:57 నుండి 15:45 వరకు",
            GulikaiKalam: "09:07 నుండి 10:37 వరకు",
            Karanamulu: "గర 07:06 వరకు, వణిజ 20:18 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "స్వాతి 19:09 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "13:38 నుండి 15:09 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:05",
            Sunset: "18:10",
            Thiti: "తదియ 07:06 వరకు",
            Yamaganda: "06:05 నుండి 07:36 వరకు",
            Yoga: "వైధృతి 21:54 వరకు",
            Varjyam: "01:27, సెప్టెంబర్ 26 నుండి 03:15, సెప్టెంబర్ 26 వరకు",
            Moonrise: "08:45",
            Moonset: "20:14",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:43 నుండి 12:31 వరకు",
            Amrutakalam: "12:15 నుండి 14:03 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల 15:24 వరకు",
            Date: "శుక్రవారము, సెప్టెంబర్ 26, 2025",
            DurMuhurtamulu: "08:30 నుండి 09:19 వరకు, 12:31 నుండి 13:20 వరకు",
            GulikaiKalam: "07:36 నుండి 09:06 వరకు",
            Karanamulu: "విష్టి 09:32 వరకు, బవ 22:48 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "విశాఖ 22:09 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "10:37 నుండి 12:07 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:06",
            Sunset: "18:09",
            Thiti: "చవితి 09:32 వరకు",
            Yamaganda: "15:08 నుండి 16:39 వరకు",
            Yoga: "విష్కుమ్భ 22:51 వరకు",
            Varjyam: "02:39, సెప్టెంబర్ 27 నుండి 04:27, సెప్టెంబర్ 27 వరకు",
            Moonrise: "09:36",
            Moonset: "20:55",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:43 నుండి 12:31 వరకు",
            Amrutakalam: "13:26 నుండి 15:14 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "శనివారము, సెప్టెంబర్ 27, 2025",
            DurMuhurtamulu: "06:06 నుండి 06:54 వరకు, 06:54 నుండి 07:42 వరకు",
            GulikaiKalam: "06:06 నుండి 07:36 వరకు",
            Karanamulu: "బాలవ 12:03 వరకు, కౌలవ 01:16, సెప్టెంబర్ 28 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "అనూరాధ 01:08, సెప్టెంబర్ 28 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "09:06 నుండి 10:37 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:06",
            Sunset: "18:08",
            Thiti: "పంచమి 12:03 వరకు",
            Yamaganda: "13:37 నుండి 15:08 వరకు",
            Yoga: "ప్రీతి 23:46 వరకు",
            Varjyam: "ఏమి లేదు",
            Moonrise: "10:28",
            Moonset: "21:39",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:43 నుండి 12:31 వరకు",
            Amrutakalam: "18:05 నుండి 19:53 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము 03:55, సెప్టెంబర్ 29 వరకు",
            Date: "ఆదివారము, సెప్టెంబర్ 28, 2025",
            DurMuhurtamulu: "16:31 నుండి 17:19 వరకు",
            GulikaiKalam: "15:07 నుండి 16:37 వరకు",
            Karanamulu: "తైతిల 14:27 వరకు, గర 03:32, సెప్టెంబర్ 29 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "జ్యేష్ఠ 03:55, సెప్టెంబర్ 29 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "16:37 నుండి 18:07 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:06",
            Sunset: "18:07",
            Thiti: "షష్టి 14:27 వరకు",
            Yamaganda: "12:07 నుండి 13:37 వరకు",
            Yoga: "ఆయుష్మాన్ 00:32, సెప్టెంబర్ 29 వరకు",
            Varjyam: "07:23 నుండి 09:10 వరకు",
            Moonrise: "11:22",
            Moonset: "22:28",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:42 నుండి 12:30 వరకు",
            Amrutakalam: "23:15 నుండి 01:01, సెప్టెంబర్ 30 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "సోమవారము, సెప్టెంబర్ 29, 2025",
            DurMuhurtamulu: "12:30 నుండి 13:18 వరకు, 14:54 నుండి 15:42 వరకు",
            GulikaiKalam: "13:36 నుండి 15:06 వరకు",
            Karanamulu: "వణిజ 16:31 వరకు, విష్టి 05:23, సెప్టెంబర్ 30 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "మూల నిండా రాత్రి వరకు",
            MainNakshatra: "మూల",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "07:36 నుండి 09:06 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:06",
            Sunset: "18:07",
            Thiti: "సప్తమి 16:31 వరకు",
            Yamaganda: "10:36 నుండి 12:06 వరకు",
            Yoga: "సౌభాగ్య 01:01, సెప్టెంబర్ 30 వరకు",
            Varjyam: "12:42 నుండి 14:28 వరకు",
            Moonrise: "12:15",
            Moonset: "23:20",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:42 నుండి 12:30 వరకు",
            Amrutakalam: "02:56, అక్టోబర్ 01 నుండి 04:40, అక్టోబర్ 01 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "మంగళవారము, సెప్టెంబర్ 30, 2025",
            DurMuhurtamulu: "08:30 నుండి 09:18 వరకు, 22:54 నుండి 23:42 వరకు",
            GulikaiKalam: "12:06 నుండి 13:36 వరకు",
            Karanamulu: "బవ 18:06 వరకు, బాలవ నిండా రాత్రి వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "మూల 06:17 వరకు",
            MainNakshatra: "మూల",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "15:06 నుండి 16:36 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:06",
            Sunset: "18:06",
            Thiti: "అష్టమి 18:06 వరకు",
            Yamaganda: "09:06 నుండి 10:36 వరకు",
            Yoga: "శోభన 01:03, అక్టోబర్ 01 వరకు",
            Varjyam: "16:37 నుండి 18:20 వరకు",
            Moonrise: "13:07",
            Moonset: "00:16, అక్టోబర్ 01",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "30",
            LordImage: require("../images/holidays/9-1)Durgashtami.png"),
          },
        ],
      },
      {
        day: [
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "02:31, అక్టోబర్ 02 నుండి 04:12, అక్టోబర్ 02 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు 14:27 వరకు",
            Date: "బుధవారము, అక్టోబర్ 1, 2025",
            DurMuhurtamulu: "11:42 నుండి 12:30 వరకు",
            GulikaiKalam: "10:36 నుండి 12:06 వరకు",
            Karanamulu: "బాలవ 06:38 వరకు, కౌలవ 19:01 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "పూర్వాషాఢ 08:06 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "12:06 నుండి 13:35 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:06",
            Sunset: "18:05",
            Thiti: "నవమి 19:01 వరకు",
            Yamaganda: "07:36 నుండి 09:06 వరకు",
            Yoga: "అతిగణ్డ 00:34, అక్టోబర్ 02 వరకు",
            Varjyam: "16:28 నుండి 18:09 వరకు",
            Moonrise: "13:56",
            Moonset: "01:13, అక్టోబర్ 02",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "1",
            LordImage: require("../images/holidays/9-2)Dasara.png"),
          },
          {
            Abhijit: "11:41 నుండి 12:29 వరకు",
            Amrutakalam: "23:01 నుండి 00:38, అక్టోబర్ 03 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము",
            Date: "గురువారము, అక్టోబర్ 2, 2025",
            DurMuhurtamulu: "10:06 నుండి 10:54 వరకు, 14:53 నుండి 15:41 వరకు",
            GulikaiKalam: "09:06 నుండి 10:36 వరకు",
            Karanamulu: "తైతిల 07:11 వరకు, బవ 19:10 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "ఉత్తరాషాఢ 09:13 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "13:35 నుండి 15:05 వరకు",
            Ruthuvu: "వర్ష ఋతువు",
            Sunrise: "06:07",
            Sunset: "18:04",
            Thiti: "దశమి 19:10 వరకు",
            Yamaganda: "06:07 నుండి 07:36 వరకు",
            Yoga: "సుకర్మా 23:29 వరకు",
            Varjyam: "13:16 నుండి 14:54 వరకు",
            Moonrise: "14:42",
            Moonset: "02:11, అక్టోబర్ 03",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "2",
            LordImage: require("../images/holidays/9-2)Dasara.png"),
            LordImage1: require("../images/holidays/9-0)Gandhijayanthi.png"),
          },
          {
            Abhijit: "11:41 నుండి 12:29 వరకు",
            Amrutakalam: "22:56 నుండి 00:30, అక్టోబర్ 04 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము 21:27 వరకు",
            Date: "శుక్రవారము, అక్టోబర్ 3, 2025",
            DurMuhurtamulu: "08:30 నుండి 09:18 వరకు, 12:29 నుండి 13:17 వరకు",
            GulikaiKalam: "07:36 నుండి 09:06 వరకు",
            Karanamulu: "వణిజ 06:57 వరకు, విష్టి 18:32 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "శ్రవణం 09:34 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "10:35 నుండి 12:05 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:07",
            Sunset: "18:03",
            Thiti: "ఏకాదశి 18:32 వరకు",
            Yamaganda: "15:04 నుండి 16:34 వరకు",
            Yoga: "ధృతి 21:46 వరకు",
            Varjyam: "13:30 నుండి 15:04 వరకు",
            Moonrise: "15:25",
            Moonset: "03:08, అక్టోబర్ 04",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:41 నుండి 12:29 వరకు",
            Amrutakalam: "01:09, అక్టోబర్ 05 నుండి 02:41, అక్టోబర్ 05 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము",
            Date: "శనివారము, అక్టోబర్ 4, 2025",
            DurMuhurtamulu: "06:07 నుండి 06:55 వరకు, 06:55 నుండి 07:42 వరకు",
            GulikaiKalam: "06:07 నుండి 07:36 వరకు",
            Karanamulu: "బాలవ 17:09 వరకు, కౌలవ 04:11, అక్టోబర్ 05 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "ధనిష్ఠ 09:09 వరకు",
            MainNakshatra: "ధనిష్ఠ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "09:06 నుండి 10:35 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:07",
            Sunset: "18:03",
            Thiti: "ద్వాదశి 17:09 వరకు",
            Yamaganda: "13:34 నుండి 15:04 వరకు",
            Yoga: "శూల 19:27 వరకు",
            Varjyam: "16:00 నుండి 17:32 వరకు",
            Moonrise: "16:05",
            Moonset: "04:05, అక్టోబర్ 05",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:41 నుండి 12:28 వరకు",
            Amrutakalam: "22:51 నుండి 00:20, అక్టోబర్ 06 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము 00:45, అక్టోబర్ 06 వరకు",
            Date: "ఆదివారము, అక్టోబర్ 5, 2025",
            DurMuhurtamulu: "16:26 నుండి 17:14 వరకు",
            GulikaiKalam: "15:03 నుండి 16:32 వరకు",
            Karanamulu: "తైతిల 15:03 వరకు, బవ 01:47, అక్టోబర్ 06 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "శతభిషం 08:01 వరకు",
            MainNakshatra: "శతభిషం",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "16:32 నుండి 18:02 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:07",
            Sunset: "18:02",
            Thiti: "త్రయోదశి 15:03 వరకు",
            Yamaganda: "12:04 నుండి 13:34 వరకు",
            Yoga: "గణ్డ 16:34 వరకు",
            Varjyam: "13:57 నుండి 15:26 వరకు",
            Moonrise: "16:45",
            Moonset: "05:03, అక్టోబర్ 06",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:40 నుండి 12:28 వరకు",
            Amrutakalam: "23:40 నుండి 01:07, అక్టోబర్ 07 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం",
            Date: "సోమవారము, అక్టోబర్ 6, 2025",
            DurMuhurtamulu: "12:28 నుండి 13:15 వరకు, 14:51 నుండి 15:38 వరకు",
            GulikaiKalam: "13:33 నుండి 15:03 వరకు",
            Karanamulu: "వణిజ 12:23 వరకు, విష్టి 22:53 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1:
              "పూర్వాభాద్ర 06:16 వరకు, ఉత్తరాభాద్ర 04:01, అక్టోబర్ 07 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "07:36 నుండి 09:06 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:07",
            Sunset: "18:01",
            Thiti: "చతుర్దశి 12:23 వరకు",
            Yamaganda: "10:35 నుండి 12:04 వరకు",
            Yoga: "వృద్ధి 13:14 వరకు",
            Varjyam: "14:58 నుండి 16:25 వరకు",
            Moonrise: "17:24",
            Moonset: "06:02, అక్టోబర్ 07",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:40 నుండి 12:28 వరకు",
            Amrutakalam: "23:19 నుండి 00:45, అక్టోబర్ 08 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం 01:28, అక్టోబర్ 08 వరకు",
            Date: "మంగళవారము, అక్టోబర్ 7, 2025",
            DurMuhurtamulu: "08:30 నుండి 09:18 వరకు, 22:51 నుండి 23:40 వరకు",
            GulikaiKalam: "12:04 నుండి 13:33 వరకు",
            Karanamulu:
              "బవ 09:16 వరకు, బాలవ 19:36 వరకు, కౌలవ 05:53, అక్టోబర్ 08 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "రేవతి 01:28, అక్టోబర్ 08 వరకు",
            MainNakshatra: "రేవతి",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "15:02 నుండి 16:31 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:07",
            Sunset: "18:00",
            Thiti: "పౌర్ణమి 09:16 వరకు, పాడ్యమి 05:53, అక్టోబర్ 08 వరకు",
            Yamaganda: "09:06 నుండి 10:35 వరకు",
            Yoga: "ధ్రువ 09:31 వరకు, వ్యాఘాత 05:35, అక్టోబర్ 08 వరకు",
            Varjyam: "14:45 నుండి 16:10 వరకు",
            Moonrise: "18:06",
            Moonset: "ఏమి లేదు",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "pournami",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "16:21 నుండి 17:47 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం",
            Date: "బుధవారము, అక్టోబర్ 8, 2025",
            DurMuhurtamulu: "11:40 నుండి 12:27 వరకు",
            GulikaiKalam: "10:35 నుండి 12:04 వరకు",
            Karanamulu: "తైతిల 16:08 వరకు, బవ 02:22, అక్టోబర్ 09 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "అశ్విని 22:44 వరకు",
            MainNakshatra: "అశ్విని",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "12:04 నుండి 13:33 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:08",
            Sunset: "17:59",
            Thiti: "విదియ 02:22, అక్టోబర్ 09 వరకు",
            Yamaganda: "07:37 నుండి 09:06 వరకు",
            Yoga: "హర్షణ 01:33, అక్టోబర్ 09 వరకు",
            Varjyam: "19:12 నుండి 20:37 వరకు",
            Moonrise: "18:51",
            Moonset: "07:04",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:40 నుండి 12:27 వరకు",
            Amrutakalam: "15:47 నుండి 17:12 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం 01:23, అక్టోబర్ 10 వరకు",
            Date: "గురువారము, అక్టోబర్ 9, 2025",
            DurMuhurtamulu: "10:05 నుండి 10:52 వరకు, 14:49 నుండి 15:37 వరకు",
            GulikaiKalam: "09:06 నుండి 10:34 వరకు",
            Karanamulu: "వణిజ 12:37 వరకు, విష్టి 22:54 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "భరణి 20:02 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "13:32 నుండి 15:01 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:08",
            Sunset: "17:59",
            Thiti: "తదియ 22:54 వరకు",
            Yamaganda: "06:08 నుండి 07:37 వరకు",
            Yoga: "వజ్ర 21:32 వరకు",
            Varjyam: "07:16 నుండి 08:41 వరకు",
            Moonrise: "19:40",
            Moonset: "08:09",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:39 నుండి 12:27 వరకు",
            Amrutakalam: "15:22 నుండి 16:48 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృషభం",
            Date: "శుక్రవారము, అక్టోబర్ 10, 2025",
            DurMuhurtamulu: "08:30 నుండి 09:17 వరకు, 12:27 నుండి 13:14 వరకు",
            GulikaiKalam: "07:37 నుండి 09:06 వరకు",
            Karanamulu:
              "బవ 09:14 వరకు, బాలవ 19:38 వరకు, కౌలవ 06:07, అక్టోబర్ 11 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "కృత్తిక 17:31 వరకు",
            MainNakshatra: "కృత్తిక",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "10:34 నుండి 12:03 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:08",
            Sunset: "17:58",
            Thiti: "చవితి 19:38 వరకు",
            Yamaganda: "15:00 నుండి 16:29 వరకు",
            Yoga: "సిద్ధి 17:41 వరకు",
            Varjyam: "06:47 నుండి 08:12 వరకు",
            Moonrise: "20:36",
            Moonset: "09:16",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:39 నుండి 12:26 వరకు",
            Amrutakalam:
              "12:25 నుండి 13:52 వరకు, 05:26, అక్టోబర్ 12 నుండి 06:55, అక్టోబర్ 12 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృషభం 02:24, అక్టోబర్ 12 వరకు",
            Date: "శనివారము, అక్టోబర్ 11, 2025",
            DurMuhurtamulu: "06:08 నుండి 06:56 వరకు, 06:56 నుండి 07:43 వరకు",
            GulikaiKalam: "06:08 నుండి 07:37 వరకు",
            Karanamulu: "తైతిల 16:43 వరకు, బవ 03:26, అక్టోబర్ 12 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "రోహిణి 15:20 వరకు",
            MainNakshatra: "రోహిణి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "09:05 నుండి 10:34 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:08",
            Sunset: "17:57",
            Thiti: "పంచమి 16:43 వరకు",
            Yamaganda: "13:31 నుండి 15:00 వరకు",
            Yoga: "వ్యతీపాత 14:07 వరకు",
            Varjyam: "08:03 నుండి 09:31 వరకు, 20:31 నుండి 22:01 వరకు",
            Moonrise: "21:36",
            Moonset: "10:24",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:39 నుండి 12:26 వరకు",
            Amrutakalam: "02:56, అక్టోబర్ 13 నుండి 04:27, అక్టోబర్ 13 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం",
            Date: "ఆదివారము, అక్టోబర్ 12, 2025",
            DurMuhurtamulu: "16:22 నుండి 17:09 వరకు",
            GulikaiKalam: "14:59 నుండి 16:28 వరకు",
            Karanamulu: "వణిజ 14:16 వరకు, విష్టి 01:15, అక్టోబర్ 13 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "మృగశిర 13:36 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "16:28 నుండి 17:56 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:08",
            Sunset: "17:56",
            Thiti: "షష్టి 14:16 వరకు",
            Yamaganda: "12:02 నుండి 13:31 వరకు",
            Yoga: "వారీయన 10:55 వరకు",
            Varjyam: "21:36 నుండి 23:07 వరకు",
            Moonrise: "22:39",
            Moonset: "11:29",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:39 నుండి 12:26 వరకు",
            Amrutakalam: "ఏమి లేదు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం 05:58, అక్టోబర్ 14 వరకు",
            Date: "సోమవారము, అక్టోబర్ 13, 2025",
            DurMuhurtamulu: "12:26 నుండి 13:13 వరకు, 14:47 నుండి 15:34 వరకు",
            GulikaiKalam: "13:31 నుండి 14:59 వరకు",
            Karanamulu: "బవ 12:24 వరకు, బాలవ 23:41 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "ఆరుద్ర 12:26 వరకు",
            MainNakshatra: "ఆరుద్ర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "07:37 నుండి 09:05 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:09",
            Sunset: "17:56",
            Thiti: "సప్తమి 12:24 వరకు",
            Yamaganda: "10:34 నుండి 12:02 వరకు",
            Yoga: "పరిఘ 08:10 వరకు, శివ 05:55, అక్టోబర్ 14 వరకు",
            Varjyam: "00:10, అక్టోబర్ 14 నుండి 01:44, అక్టోబర్ 14 వరకు",
            Moonrise: "23:43",
            Moonset: "12:29",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:38 నుండి 12:26 వరకు",
            Amrutakalam: "05:34, అక్టోబర్ 15 నుండి 07:10, అక్టోబర్ 15 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "మంగళవారము, అక్టోబర్ 14, 2025",
            DurMuhurtamulu: "08:30 నుండి 09:17 వరకు, 22:49 నుండి 23:38 వరకు",
            GulikaiKalam: "12:02 నుండి 13:30 వరకు",
            Karanamulu: "కౌలవ 11:09 వరకు, తైతిల 22:46 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "పునర్వసు 11:54 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "14:59 నుండి 16:27 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:09",
            Sunset: "17:55",
            Thiti: "అష్టమి 11:09 వరకు",
            Yamaganda: "09:05 నుండి 10:34 వరకు",
            Yoga: "సిద్ధ 04:11, అక్టోబర్ 15 వరకు",
            Varjyam: "19:56 నుండి 21:32 వరకు",
            Moonrise: "00:44, అక్టోబర్ 15",
            Moonset: "13:22",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "ఏమి లేదు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "బుధవారము, అక్టోబర్ 15, 2025",
            DurMuhurtamulu: "11:38 నుండి 12:25 వరకు",
            GulikaiKalam: "10:34 నుండి 12:02 వరకు",
            Karanamulu: "బవ 10:33 వరకు, వణిజ 22:29 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "పుష్యమి 12:00 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "12:02 నుండి 13:30 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:09",
            Sunset: "17:54",
            Thiti: "నవమి 10:33 వరకు",
            Yamaganda: "07:37 నుండి 09:05 వరకు",
            Yoga: "సాధ్య 02:57, అక్టోబర్ 16 వరకు",
            Varjyam: "01:10, అక్టోబర్ 16 నుండి 02:49, అక్టోబర్ 16 వరకు",
            Moonrise: "01:41, అక్టోబర్ 16",
            Moonset: "14:08",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:38 నుండి 12:25 వరకు",
            Amrutakalam: "11:03 నుండి 12:42 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం 12:42 వరకు",
            Date: "గురువారము, అక్టోబర్ 16, 2025",
            DurMuhurtamulu: "10:04 నుండి 10:51 వరకు, 14:46 నుండి 15:33 వరకు",
            GulikaiKalam: "09:05 నుండి 10:34 వరకు",
            Karanamulu: "విష్టి 10:35 వరకు, బవ 22:49 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "ఆశ్లేష 12:42 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "13:30 నుండి 14:58 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:09",
            Sunset: "17:54",
            Thiti: "దశమి 10:35 వరకు",
            Yamaganda: "06:09 నుండి 07:37 వరకు",
            Yoga: "శుభ 02:11, అక్టోబర్ 17 వరకు",
            Varjyam: "01:20, అక్టోబర్ 17 నుండి 03:01, అక్టోబర్ 17 వరకు",
            Moonrise: "02:35, అక్టోబర్ 17",
            Moonset: "14:48",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:38 నుండి 12:25 వరకు",
            Amrutakalam: "11:03 నుండి 12:42 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం",
            Date: "శుక్రవారము, అక్టోబర్ 17, 2025",
            DurMuhurtamulu: "08:30 నుండి 09:17 వరకు, 12:25 నుండి 13:12 వరకు",
            GulikaiKalam: "07:38 నుండి 09:06 వరకు",
            Karanamulu: "బాలవ 11:12 వరకు, కౌలవ 23:42 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "మఖ 13:57 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "10:33 నుండి 12:01 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:10",
            Sunset: "17:53",
            Thiti: "ఏకాదశి 11:12 వరకు",
            Yamaganda: "14:57 నుండి 16:25 వరకు",
            Yoga: "శుక్ల 01:49, అక్టోబర్ 18 వరకు",
            Varjyam: "22:32 నుండి 00:15, అక్టోబర్ 18 వరకు",
            Moonrise: "03:25, అక్టోబర్ 18",
            Moonset: "15:24",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:38 నుండి 12:25 వరకు",
            Amrutakalam: "08:50 నుండి 10:33 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం 22:11 వరకు",
            Date: "శనివారము, అక్టోబర్ 18, 2025",
            DurMuhurtamulu: "06:10 నుండి 06:57 వరకు, 06:57 నుండి 07:44 వరకు",
            GulikaiKalam: "06:10 నుండి 07:38 వరకు",
            Karanamulu: "తైతిల 12:18 వరకు, బవ 01:02, అక్టోబర్ 19 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "పుబ్బ 15:41 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "09:06 నుండి 10:33 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:10",
            Sunset: "17:52",
            Thiti: "ద్వాదశి 12:18 వరకు",
            Yamaganda: "13:29 నుండి 14:57 వరకు",
            Yoga: "బ్రహ్మ 01:48, అక్టోబర్ 19 వరకు",
            Varjyam: "23:32 నుండి 01:16, అక్టోబర్ 19 వరకు",
            Moonrise: "04:14, అక్టోబర్ 19",
            Moonset: "15:58",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:38 నుండి 12:24 వరకు",
            Amrutakalam: "09:59 నుండి 11:44 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య",
            Date: "ఆదివారము, అక్టోబర్ 19, 2025",
            DurMuhurtamulu: "16:18 నుండి 17:05 వరకు",
            GulikaiKalam: "14:56 నుండి 16:24 వరకు",
            Karanamulu: "వణిజ 13:51 వరకు, విష్టి 02:45, అక్టోబర్ 20 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "ఉత్తర 17:49 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "16:24 నుండి 17:52 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:10",
            Sunset: "17:52",
            Thiti: "త్రయోదశి 13:51 వరకు",
            Yamaganda: "12:01 నుండి 13:29 వరకు",
            Yoga: "ఐన్ద్ర 02:05, అక్టోబర్ 20 వరకు",
            Varjyam: "03:05, అక్టోబర్ 20 నుండి 04:51, అక్టోబర్ 20 వరకు",
            Moonrise: "05:02, అక్టోబర్ 20",
            Moonset: "16:31",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:24 వరకు",
            Amrutakalam: "13:40 నుండి 15:26 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య",
            Date: "సోమవారము, అక్టోబర్ 20, 2025",
            DurMuhurtamulu: "112:24 నుండి 13:11 వరకు, 14:44 నుండి 15:31 వరకు",
            GulikaiKalam: "13:28 నుండి 14:56 వరకు",
            Karanamulu: "శకుని 15:44 వరకు, చతుష్పాద 04:47, అక్టోబర్ 21 వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "హస్త 20:17 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "07:38 నుండి 09:06 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:11",
            Sunset: "17:51",
            Thiti: "చతుర్దశి 15:44 వరకు",
            Yamaganda: "10:33 నుండి 12:01 వరకు",
            Yoga: "వైధృతి 02:35, అక్టోబర్ 21 వరకు",
            Varjyam: "05:11, అక్టోబర్ 21 నుండి 06:57, అక్టోబర్ 21 వరకు",
            Moonrise: "05:50, అక్టోబర్ 21",
            Moonset: "17:04",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:24 వరకు",
            Amrutakalam: "15:51 నుండి 17:38 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య 09:36 వరకు",
            Date: "మంగళవారము, అక్టోబర్ 21, 2025",
            DurMuhurtamulu: "08:31 నుండి 09:17 వరకు, 22:47 నుండి 23:36 వరకు",
            GulikaiKalam: "12:01 నుండి 13:28 వరకు",
            Karanamulu: "నాగ 17:54 వరకు, కింస్తుఘ్న నిండా రాత్రి వరకు",
            Masam: "ఆశ్వయుజము",
            Nakshatra1: "చిత్తా 22:59 వరకు",
            MainNakshatra: "చిత్తా",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "14:56 నుండి 16:23 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:11",
            Sunset: "17:50",
            Thiti: "అమావాస్య 17:54 వరకు",
            Yamaganda: "09:06 నుండి 10:33 వరకు",
            Yoga: "విష్కుమ్భ 03:17, అక్టోబర్ 22 వరకు",
            Varjyam: "05:15, అక్టోబర్ 22 నుండి 07:02, అక్టోబర్ 22 వరకు",
            Moonrise: "ఏమి లేదు",
            Moonset: "17:38",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "amavasya",
            FestDate: "21",
            LordImage: require("../images/holidays/9-4)Deewali.png"),
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "16:00 నుండి 17:48 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల",
            Date: "బుధవారము, అక్టోబర్ 22, 2025",
            DurMuhurtamulu: "11:37 నుండి 12:24 వరకు",
            GulikaiKalam: "10:33 నుండి 12:00 వరకు",
            Karanamulu: "కింస్తుఘ్న 07:04 వరకు, బవ 20:16 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "స్వాతి 01:52, అక్టోబర్ 23 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "12:00 నుండి 13:28 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:11",
            Sunset: "17:50",
            Thiti: "పాడ్యమి 20:16 వరకు",
            Yamaganda: "07:38 నుండి 09:06 వరకు",
            Yoga: "ప్రీతి 04:06, అక్టోబర్ 23 వరకు",
            Varjyam: "ఏమి లేదు",
            Moonrise: "06:39",
            Moonset: "18:14",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:24 వరకు",
            Amrutakalam: "18:57 నుండి 20:45 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల 22:06 వరకు",
            Date: "గురువారము, అక్టోబర్ 23, 2025",
            DurMuhurtamulu: "10:04 నుండి 10:51 వరకు, 14:43 నుండి 15:30 వరకు",
            GulikaiKalam: "09:06 నుండి 10:33 వరకు",
            Karanamulu: "బాలవ 09:30 వరకు, కౌలవ 22:46 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "విశాఖ 04:51, అక్టోబర్ 24 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "13:28 నుండి 14:55 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:11",
            Sunset: "17:49",
            Thiti: "విదియ 22:46 వరకు",
            Yamaganda: "06:11 నుండి 07:39 వరకు",
            Yoga: "ఆయుష్మాన్ 05:00, అక్టోబర్ 24 వరకు",
            Varjyam: "08:09 నుండి 09:57 వరకు",
            Moonrise: "07:30",
            Moonset: "18:53",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "20:09 నుండి 21:57 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "శుక్రవారము, అక్టోబర్ 24, 2025",
            DurMuhurtamulu: "08:31 నుండి 09:18 వరకు, 12:23 నుండి 13:10 వరకు",
            GulikaiKalam: "07:39 నుండి 09:06 వరకు",
            Karanamulu: "తైతిల 12:03 వరకు, గర 01:19, అక్టోబర్ 25 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "అనూరాధ నిండా రాత్రి వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "10:33 నుండి 12:00 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:12",
            Sunset: "17:49",
            Thiti: "తదియ 01:19, అక్టోబర్ 25 వరకు",
            Yamaganda: "14:54 నుండి 16:22 వరకు",
            Yoga: "సౌభాగ్య 05:55, అక్టోబర్ 25 వరకు",
            Varjyam: "09:21 నుండి 11:09 వరకు",
            Moonrise: "08:22",
            Moonset: "19:36",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "00:54, అక్టోబర్ 26 నుండి 02:42, అక్టోబర్ 26 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "శనివారము, అక్టోబర్ 25, 2025",
            DurMuhurtamulu: "06:12 నుండి 06:58 వరకు, 06:58 నుండి 07:45 వరకు",
            GulikaiKalam: "06:12 నుండి 07:39 వరకు",
            Karanamulu: "వణిజ 14:34 వరకు, విష్టి 03:48, అక్టోబర్ 26 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "అనూరాధ 07:51 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "09:06 నుండి 10:33 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:12",
            Sunset: "17:48",
            Thiti: "చవితి 03:48, అక్టోబర్ 26 వరకు",
            Yamaganda: "13:27 నుండి 14:54 వరకు",
            Yoga: "శోభన నిండా రాత్రి వరకు",
            Varjyam: "14:08 నుండి 15:56 వరకు",
            Moonrise: "09:15",
            Moonset: "20:23",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "19:40 నుండి 21:27 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము 10:46 వరకు",
            Date: "ఆదివారము, అక్టోబర్ 26, 2025",
            DurMuhurtamulu: "16:15 నుండి 17:01 వరకు",
            GulikaiKalam: "14:54 నుండి 16:21 వరకు",
            Karanamulu: "బవ 16:58 వరకు, బాలవ 06:04, అక్టోబర్ 27 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "జ్యేష్ఠ 10:46 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "16:21 నుండి 17:48 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:12",
            Sunset: "17:48",
            Thiti: "పంచమి 06:04, అక్టోబర్ 27 వరకు",
            Yamaganda: "12:00 నుండి 13:27 వరకు",
            Yoga: "శోభన 06:46 వరకు",
            Varjyam: "19:40 నుండి 21:27 వరకు",
            Moonrise: "10:08",
            Moonset: "21:13",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "06:20 నుండి 08:07 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "సోమవారము, అక్టోబర్ 27, 2025",
            DurMuhurtamulu: "12:23 నుండి 13:09 వరకు, 14:42 నుండి 15:28 వరకు",
            GulikaiKalam: "13:27 నుండి 14:53 వరకు",
            Karanamulu: "కౌలవ 19:05 వరకు, తైతిల నిండా రాత్రి వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "మూల 13:27 వరకు",
            MainNakshatra: "మూల",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "07:40 నుండి 09:06 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:13",
            Sunset: "17:47",
            Thiti: "షష్టి నిండా రాత్రి వరకు",
            Yamaganda: "10:33 నుండి 12:00 వరకు",
            Yoga: "అతిగణ్డ 07:27 వరకు",
            Varjyam:
              "11:41 నుండి 13:27 వరకు, 23:58 నుండి 01:44, అక్టోబర్ 28 వరకు",
            Moonrise: "11:00",
            Moonset: "22:07",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "10:29 నుండి 12:15 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు 22:14 వరకు",
            Date: "మంగళవారము, అక్టోబర్ 28, 2025",
            DurMuhurtamulu: "08:32 నుండి 09:18 వరకు, 22:45 నుండి 23:35 వరకు",
            GulikaiKalam: "12:00 నుండి 13:26 వరకు",
            Karanamulu: "తైతిల 07:59 వరకు, గర 20:45 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "పూర్వాషాఢ 15:45 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "14:53 నుండి 16:20 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:13",
            Sunset: "17:47",
            Thiti: "షష్టి 07:59 వరకు",
            Yamaganda: "09:06 నుండి 10:33 వరకు",
            Yoga: "సుకర్మా 07:51 వరకు",
            Varjyam: "00:20, అక్టోబర్ 29 నుండి 02:03, అక్టోబర్ 29 వరకు",
            Moonrise: "11:49",
            Moonset: "23:02",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "10:38 నుండి 12:21 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము",
            Date: "బుధవారము, అక్టోబర్ 29, 2025",
            DurMuhurtamulu: "11:37 నుండి 12:23 వరకు",
            GulikaiKalam: "10:33 నుండి 12:00 వరకు",
            Karanamulu: "వణిజ 09:23 వరకు, విష్టి 21:50 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "ఉత్తరాషాఢ 17:29 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "12:00 నుండి 13:26 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:13",
            Sunset: "17:46",
            Thiti: "సప్తమి 09:23 వరకు",
            Yamaganda: "07:40 నుండి 09:07 వరకు",
            Yoga: "ధృతి 07:51 వరకు",
            Varjyam: "21:40 నుండి 23:20 వరకు",
            Moonrise: "12:35",
            Moonset: "23:58",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "07:42 నుండి 09:22 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము",
            Date: "గురువారము, అక్టోబర్ 30, 2025",
            DurMuhurtamulu: "10:04 నుండి 10:51 వరకు, 14:41 నుండి 15:27 వరకు",
            GulikaiKalam: "09:07 నుండి 10:33 వరకు",
            Karanamulu: "బవ 10:06 వరకు, బాలవ 22:10 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "శ్రవణం 18:33 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "13:26 నుండి 14:53 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:14",
            Sunset: "17:46",
            Thiti: "అష్టమి 10:06 వరకు",
            Yamaganda: "06:14 నుండి 07:40 వరకు",
            Yoga: "శూల 07:21 వరకు",
            Varjyam: "22:36 నుండి 00:13, అక్టోబర్ 31 వరకు",
            Moonrise: "13:18",
            Moonset: "00:54, అక్టోబర్ 31",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "08:19 నుండి 09:56 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము 06:48 వరకు",
            Date: "శుక్రవారము, అక్టోబర్ 31, 2025",
            DurMuhurtamulu: "08:32 నుండి 09:18 వరకు, 12:23 నుండి 13:09 వరకు",
            GulikaiKalam: "07:41 నుండి 09:07 వరకు",
            Karanamulu: "కౌలవ 10:03 వరకు, తైతిల 21:43 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "ధనిష్ఠ 18:51 వరకు",
            MainNakshatra: "ధనిష్ఠ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "10:33 నుండి 12:00 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:14",
            Sunset: "17:45",
            Thiti: "నవమి 10:03 వరకు",
            Yamaganda: "14:52 నుండి 16:19 వరకు",
            Yoga: "గణ్డ 06:16 వరకు, వృద్ధి 04:32, నవంబర్ 1 వరకు",
            Varjyam: "01:54, నవంబర్ 1 నుండి 03:28, నవంబర్ 1 వరకు",
            Moonrise: "13:58",
            Moonset: "01:49, నవంబర్ 1",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
        ],
      },
      {
        day: [
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "11:17 నుండి 12:51 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము",
            Date: "శనివారము, నవంబర్ 1, 2025",
            DurMuhurtamulu: "06:15 నుండి 07:01 వరకు, 07:01 నుండి 07:47 వరకు",
            GulikaiKalam: "06:15 నుండి 07:41 వరకు",
            Karanamulu: "బవ 09:11 వరకు, వణిజ 20:27 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "శతభిషం 18:20 వరకు",
            MainNakshatra: "శతభిషం",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "09:07 నుండి 10:33 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:15",
            Sunset: "17:45",
            Thiti: "దశమి 09:11 వరకు",
            Yamaganda: "13:26 నుండి 14:52 వరకు",
            Yoga: "ధ్రువ 02:09, నవంబర్ 2 వరకు",
            Varjyam: "00:24, నవంబర్ 2 నుండి 01:55, నవంబర్ 2 వరకు",
            Moonrise: "14:36",
            Moonset: "02:45, నవంబర్ 2",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "09:29 నుండి 11:00 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము 11:27 వరకు",
            Date: "ఆదివారము, నవంబర్ 2, 2025",
            DurMuhurtamulu: "16:12 నుండి 16:58 వరకు",
            GulikaiKalam: "14:52 నుండి 16:18 వరకు",
            Karanamulu: "విష్టి 07:31 వరకు, బవ 18:24 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "పూర్వాభాద్ర 17:03 వరకు",
            MainNakshatra: "పూర్వాభాద్ర",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "16:18 నుండి 17:44 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:15",
            Sunset: "17:44",
            Thiti: "ఏకాదశి 07:31 వరకు",
            Yamaganda: "12:00 నుండి 13:26 వరకు",
            Yoga: "వ్యాఘాత 23:11 వరకు",
            Varjyam: "01:52, నవంబర్ 3 నుండి 03:20, నవంబర్ 3 వరకు",
            Moonrise: "15:15",
            Moonset: "03:42, నవంబర్ 3",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "10:41 నుండి 12:09 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం",
            Date: "సోమవారము, నవంబర్ 3, 2025",
            DurMuhurtamulu: "12:23 నుండి 13:08 వరకు, 14:40 నుండి 15:26 వరకు",
            GulikaiKalam: "13:26 నుండి 14:52 వరకు",
            Karanamulu: "కౌలవ 15:40 వరకు, తైతిల 02:05, నవంబర్ 4 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "ఉత్తరాభాద్ర 15:05 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "07:41 నుండి 09:08 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:15",
            Sunset: "17:44",
            Thiti: "త్రయోదశి 02:05, నవంబర్ 4 వరకు",
            Yamaganda: "10:34 నుండి 12:00 వరకు",
            Yoga: "హర్షణ 19:40 వరకు",
            Varjyam: "01:50, నవంబర్ 4 నుండి 03:16, నవంబర్ 4 వరకు",
            Moonrise: "15:55",
            Moonset: "04:42, నవంబర్ 4",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "10:25 నుండి 11:51 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం 12:34 వరకు",
            Date: "మంగళవారము, నవంబర్ 4, 2025",
            DurMuhurtamulu: "08:33 నుండి 09:19 వరకు, 22:45 నుండి 23:35 వరకు",
            GulikaiKalam: "12:00 నుండి 13:26 వరకు",
            Karanamulu: "బవ 12:23 వరకు, వణిజ 22:36 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "రేవతి 12:34 వరకు",
            MainNakshatra: "రేవతి",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "14:51 నుండి 16:17 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:16",
            Sunset: "17:43",
            Thiti: "చతుర్దశి 22:36 వరకు",
            Yamaganda: "09:08 నుండి 10:34 వరకు",
            Yoga: "వజ్ర 15:43 వరకు",
            Varjyam: "06:09 నుండి 07:33, నవంబర్ 5 వరకు",
            Moonrise: "16:38",
            Moonset: "05:46, నవంబర్ 5",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "02:23, నవంబర్ 6 నుండి 03:47, నవంబర్ 6 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం",
            Date: "బుధవారము, నవంబర్ 5, 2025",
            DurMuhurtamulu: "11:37 నుండి 12:23 వరకు",
            GulikaiKalam: "10:34 నుండి 12:00 వరకు",
            Karanamulu: "విష్టి 08:44 వరకు, బవ 18:48 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "అశ్విని 09:40 వరకు",
            MainNakshatra: "అశ్విని",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "12:00 నుండి 13:25 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:16",
            Sunset: "17:43",
            Thiti: "పౌర్ణమి 18:48 వరకు",
            Yamaganda: "07:42 నుండి 09:08 వరకు",
            Yoga: "సిద్ధి 11:28 వరకు",
            Varjyam: "18:01 నుండి 19:25 వరకు",
            Moonrise: "17:26",
            Moonset: "ఏమి లేదు",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "pournami",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "01:22, నవంబర్ 7 నుండి 02:46, నవంబర్ 7 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం 11:47 వరకు",
            Date: "గురువారము, నవంబర్ 6, 2025",
            DurMuhurtamulu: "10:05 నుండి 10:51 వరకు, 14:40 నుండి 15:25 వరకు",
            GulikaiKalam: "09:08 నుండి 10:34 వరకు",
            Karanamulu: "కౌలవ 14:54 వరకు, తైతిల 00:58, నవంబర్ 7 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "భరణి 06:34 వరకు, కృత్తిక 03:28, నవంబర్ 7 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "13:25 నుండి 14:51 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:17",
            Sunset: "17:43",
            Thiti: "పాడ్యమి 14:54 వరకు",
            Yamaganda: "06:17 నుండి 07:42 వరకు",
            Yoga: "వ్యతీపాత 07:05 వరకు",
            Varjyam: "17:01 నుండి 18:24 వరకు",
            Moonrise: "18:19",
            Moonset: "06:53",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "21:44 నుండి 23:09 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృషభం",
            Date: "శుక్రవారము, నవంబర్ 7, 2025",
            DurMuhurtamulu: "08:34 నుండి 09:20 వరకు, 12:23 నుండి 13:08 వరకు",
            GulikaiKalam: "07:43 నుండి 09:08 వరకు",
            Karanamulu: "బవ 11:05 వరకు, వణిజ 21:16 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "రోహిణి 00:33, నవంబర్ 8 వరకు",
            MainNakshatra: "రోహిణి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "10:34 నుండి 12:00 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:17",
            Sunset: "17:42",
            Thiti: "విదియ 11:05 వరకు",
            Yamaganda: "14:51 నుండి 16:17 వరకు",
            Yoga: "పరిఘ 22:28 వరకు",
            Varjyam:
              "17:31 నుండి 18:56 వరకు, 05:34, నవంబర్ 08 నుండి 07:00, నవంబర్ 08 వరకు",
            Moonrise: "19:20",
            Moonset: "08:03",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "14:09 నుండి 15:35 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృషభం 11:14 వరకు",
            Date: "శనివారము, నవంబర్ 8, 2025",
            DurMuhurtamulu: "06:18 నుండి 07:03 వరకు, 07:03 నుండి 07:49 వరకు",
            GulikaiKalam: "06:18 నుండి 07:43 వరకు",
            Karanamulu: "విష్టి 07:32 వరకు, బవ 17:54 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "మృగశిర 22:02 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "09:09 నుండి 10:34 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:18",
            Sunset: "17:42",
            Thiti: "తదియ 07:32 వరకు",
            Yamaganda: "13:25 నుండి 14:51 వరకు",
            Yoga: "శివ 18:32 వరకు",
            Varjyam: "05:45, నవంబర్ 9 నుండి 07:13, నవంబర్ 9 వరకు",
            Moonrise: "20:25",
            Moonset: "09:13",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "10:53 నుండి 12:21 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం",
            Date: "ఆదివారము, నవంబర్ 9, 2025",
            DurMuhurtamulu: "16:11 నుండి 16:56 వరకు",
            GulikaiKalam: "14:51 నుండి 16:16 వరకు",
            Karanamulu: "కౌలవ 15:05 వరకు, తైతిల 01:54, నవంబర్ 10 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "ఆరుద్ర 20:04 వరకు",
            MainNakshatra: "ఆరుద్ర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "16:16 నుండి 17:42 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:18",
            Sunset: "17:42",
            Thiti: "చవితి 04:25, నవంబర్ 10 వరకు",
            Yamaganda: "12:00 నుండి 13:25 వరకు",
            Yoga: "సిద్ధ 15:02 వరకు",
            Varjyam: "ఏమి లేదు",
            Moonrise: "21:31",
            Moonset: "10:18",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "16:31 నుండి 18:02 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం 13:02 వరకు",
            Date: "సోమవారము, నవంబర్ 10, 2025",
            DurMuhurtamulu: "12:23 నుండి 13:08 వరకు, 14:39 నుండి 15:25 వరకు",
            GulikaiKalam: "13:25 నుండి 14:51 వరకు",
            Karanamulu: "బవ 12:55 వరకు, వణిజ 00:07, నవంబర్ 11 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "పునర్వసు 18:48 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "07:44 నుండి 09:09 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:19",
            Sunset: "17:41",
            Thiti: "షష్టి 00:07, నవంబర్ 11 వరకు",
            Yamaganda: "10:35 నుండి 12:00 వరకు",
            Yoga: "సాధ్య 12:05 వరకు",
            Varjyam:
              "07:26 నుండి 08:57 వరకు, 02:38, నవంబర్ 11 నుండి 04:12, నవంబర్ 11 వరకు",
            Moonrise: "22:35",
            Moonset: "11:15",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "12:01 నుండి 13:35 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "మంగళవారము, నవంబర్ 11, 2025",
            DurMuhurtamulu: "08:35 నుండి 09:21 వరకు, 22:45 నుండి 23:35 వరకు",
            GulikaiKalam: "12:00 నుండి 13:25 వరకు",
            Karanamulu: "విష్టి 11:32 వరకు, బవ 23:08 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "పుష్యమి 18:17 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "14:51 నుండి 16:16 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:19",
            Sunset: "17:41",
            Thiti: "సప్తమి 23:08 వరకు",
            Yamaganda: "09:10 నుండి 10:35 వరకు",
            Yoga: "శుభ 09:44 వరకు",
            Varjyam: "ఏమి లేదు",
            Moonrise: "23:35",
            Moonset: "12:05",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:37 నుండి 12:23 వరకు",
            Amrutakalam: "16:58 నుండి 18:35 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం 18:35 వరకు",
            Date: "బుధవారము, నవంబర్ 12, 2025",
            DurMuhurtamulu: "11:38 నుండి 12:23 వరకు",
            GulikaiKalam: "10:35 నుండి 12:00 వరకు",
            Karanamulu: "బాలవ 10:57 వరకు, కౌలవ 22:58 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "ఆశ్లేష 18:35 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "12:00 నుండి 13:25 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:20",
            Sunset: "17:41",
            Thiti: "అష్టమి 22:58 వరకు",
            Yamaganda: "07:45 నుండి 09:10 వరకు",
            Yoga: "శుక్ల 08:02 వరకు",
            Varjyam: "07:15 నుండి 08:52 వరకు",
            Moonrise: "00:31, నవంబర్ 13",
            Moonset: "12:48",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:38 నుండి 12:23 వరకు",
            Amrutakalam: "17:08 నుండి 18:48 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం",
            Date: "గురువారము, నవంబర్ 13, 2025",
            DurMuhurtamulu: "10:07 నుండి 10:52 వరకు, 14:39 నుండి 15:25 వరకు",
            GulikaiKalam: "09:10 నుండి 10:35 వరకు",
            Karanamulu: "తైతిల 11:10 వరకు, బవ 23:33 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "మఖ 19:38 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "13:25 నుండి 14:51 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:20",
            Sunset: "17:41",
            Thiti: "నవమి 23:33 వరకు",
            Yamaganda: "06:20 నుండి 07:45 వరకు",
            Yoga: "బ్రహ్మ 06:58 వరకు",
            Varjyam:
              "07:06 నుండి 08:47 వరకు, 04:12, నవంబర్ 14 నుండి 05:55, నవంబర్ 14 వరకు",
            Moonrise: "01:22, నవంబర్ 14",
            Moonset: "13:25",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:38 నుండి 12:23 వరకు",
            Amrutakalam: "14:29 నుండి 16:12 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం 03:51, నవంబర్ 15 వరకు",
            Date: "శుక్రవారము, నవంబర్ 14, 2025",
            DurMuhurtamulu: "08:37 నుండి 09:22 వరకు, 12:23 నుండి 13:09 వరకు",
            GulikaiKalam: "07:46 నుండి 09:11 వరకు",
            Karanamulu: "వణిజ 12:07 వరకు, విష్టి 00:49, నవంబర్ 15 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "పుబ్బ 21:20 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "10:36 నుండి 12:01 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:21",
            Sunset: "17:40",
            Thiti: "దశమి 00:49, నవంబర్ 15 వరకు",
            Yamaganda: "14:51 నుండి 16:15 వరకు",
            Yoga: "ఐన్ద్ర 06:28 వరకు",
            Varjyam: "05:13, నవంబర్ 15 నుండి 06:57, నవంబర్ 15 వరకు",
            Moonrise: "02:11, నవంబర్ 15",
            Moonset: "14:00",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "14",
            LordImage: require("../images/holidays/10-0)Childrensday.png"),
          },
          {
            Abhijit: "11:38 నుండి 12:23 వరకు",
            Amrutakalam: "15:42 నుండి 17:27 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య",
            Date: "శనివారము, నవంబర్ 15, 2025",
            DurMuhurtamulu: "06:21 నుండి 07:06 వరకు, 07:06 నుండి 07:52 వరకు",
            GulikaiKalam: "06:21 నుండి 07:46 వరకు",
            Karanamulu: "బవ 13:40 వరకు, బాలవ 02:37, నవంబర్ 16 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "ఉత్తర 23:34 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "09:11 నుండి 10:36 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:21",
            Sunset: "17:40",
            Thiti: "ఏకాదశి 02:37, నవంబర్ 16 వరకు",
            Yamaganda: "13:26 నుండి 14:50 వరకు",
            Yoga: "వైధృతి 06:26 వరకు",
            Varjyam: "ఏమి లేదు",
            Moonrise: "02:59, నవంబర్ 16",
            Moonset: "14:33",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:38 నుండి 12:23 వరకు",
            Amrutakalam: "19:32 నుండి 21:18 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య",
            Date: "ఆదివారము, నవంబర్ 16, 2025",
            DurMuhurtamulu: "16:10 నుండి 16:55 వరకు",
            GulikaiKalam: "14:51 నుండి 16:15 వరకు",
            Karanamulu: "కౌలవ 15:40 వరకు, తైతిల 04:47, నవంబర్ 17 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "హస్త 02:11, నవంబర్ 17 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "16:15 నుండి 17:40 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:22",
            Sunset: "17:40",
            Thiti: "ద్వాదశి 04:47, నవంబర్ 17 వరకు",
            Yamaganda: "12:01 నుండి 13:26 వరకు",
            Yoga: "విష్కుమ్భ 06:47 వరకు",
            Varjyam: "08:53 నుండి 10:39 వరకు",
            Moonrise: "03:47, నవంబర్ 17",
            Moonset: "15:06",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:38 నుండి 12:24 వరకు",
            Amrutakalam: "21:52 నుండి 23:39 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య 15:35 వరకు",
            Date: "సోమవారము, నవంబర్ 17, 2025",
            DurMuhurtamulu: "12:24 నుండి 13:09 వరకు, 14:39 నుండి 15:24 వరకు",
            GulikaiKalam: "13:26 నుండి 14:51 వరకు",
            Karanamulu: "బవ 17:58 వరకు, వణిజ నిండా రాత్రి వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "చిత్తా 05:01, నవంబర్ 18 వరకు",
            MainNakshatra: "చిత్తా",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "07:47 నుండి 09:12 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:22",
            Sunset: "17:40",
            Thiti: "త్రయోదశి నిండా రాత్రి వరకు",
            Yamaganda: "10:36 నుండి 12:01 వరకు",
            Yoga: "ప్రీతి 07:23 వరకు",
            Varjyam: "11:08 నుండి 12:55 వరకు",
            Moonrise: "04:36, నవంబర్ 18",
            Moonset: "15:39",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:39 నుండి 12:24 వరకు",
            Amrutakalam: "22:06 నుండి 23:54 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల",
            Date: "మంగళవారము, నవంబర్ 18, 2025",
            DurMuhurtamulu: "08:38 నుండి 09:23 వరకు, 22:45 నుండి 23:36 వరకు",
            GulikaiKalam: "12:01 నుండి 13:26 వరకు",
            Karanamulu: "వణిజ 07:12 వరకు, విష్టి 20:27 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "స్వాతి నిండా రాత్రి వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "14:51 నుండి 16:15 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:23",
            Sunset: "17:40",
            Thiti: "త్రయోదశి 07:12 వరకు",
            Yamaganda: "09:12 నుండి 10:37 వరకు",
            Yoga: "ఆయుష్మాన్ 08:09 వరకు",
            Varjyam: "11:19 నుండి 13:07 వరకు",
            Moonrise: "05:26, నవంబర్ 19",
            Moonset: "16:14",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "01:05, నవంబర్ 20 నుండి 02:53, నవంబర్ 20 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల 04:14, నవంబర్ 20 వరకు",
            Date: "బుధవారము, నవంబర్ 19, 2025",
            DurMuhurtamulu: "11:39 నుండి 12:24 వరకు",
            GulikaiKalam: "10:37 నుండి 12:02 వరకు",
            Karanamulu: "శకుని 09:43 వరకు, చతుష్పాద 23:00 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "స్వాతి 07:59 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "12:02 నుండి 13:26 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:23",
            Sunset: "17:40",
            Thiti: "చతుర్దశి 09:43 వరకు",
            Yamaganda: "07:48 నుండి 09:12 వరకు",
            Yoga: "సౌభాగ్య 09:01 వరకు",
            Varjyam: "14:17 నుండి 16:05 వరకు",
            Moonrise: "06:17, నవంబర్ 20",
            Moonset: "16:52",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:39 నుండి 12:24 వరకు",
            Amrutakalam: "02:15, నవంబర్ 21 నుండి 04:03, నవంబర్ 21 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "గురువారము, నవంబర్ 20, 2025",
            DurMuhurtamulu: "10:09 నుండి 10:54 వరకు, 14:39 నుండి 15:25 వరకు",
            GulikaiKalam: "09:13 నుండి 10:37 వరకు",
            Karanamulu: "నాగ 12:16 వరకు, కింస్తుఘ్న 01:32, నవంబర్ 21 వరకు",
            Masam: "కార్తీకము",
            Nakshatra1: "విశాఖ 10:58 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "13:26 నుండి 14:51 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:24",
            Sunset: "17:40",
            Thiti: "అమావాస్య 12:16 వరకు",
            Yamaganda: "06:24 నుండి 07:48 వరకు",
            Yoga: "శోభన 09:53 వరకు",
            Varjyam: "15:28 నుండి 17:16 వరకు",
            Moonrise: "కాదు",
            Moonset: "17:34",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "amavasya",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:39 నుండి 12:25 వరకు",
            Amrutakalam: "ఏమి లేదు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "శుక్రవారము, నవంబర్ 21, 2025",
            DurMuhurtamulu: "08:39 నుండి 09:24 వరకు, 12:25 నుండి 13:10 వరకు",
            GulikaiKalam: "07:49 నుండి 09:13 వరకు",
            Karanamulu: "బవ 14:47 వరకు, బాలవ 04:00, నవంబర్ 23 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "అనూరాధ 13:56 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "10:38 నుండి 12:02 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:24",
            Sunset: "17:40",
            Thiti: "పాడ్యమి 14:47 వరకు",
            Yamaganda: "14:51 నుండి 16:15 వరకు",
            Yoga: "అతిగణ్డ 10:44 వరకు",
            Varjyam: "ఏమి లేదు",
            Moonrise: "07:10",
            Moonset: "18:20",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:40 నుండి 12:25 వరకు",
            Amrutakalam: "06:56 నుండి 08:43 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము 16:47 వరకు",
            Date: "శనివారము, నవంబర్ 22, 2025",
            DurMuhurtamulu: "06:25 నుండి 07:10 వరకు, 07:10 నుండి 07:55 వరకు",
            GulikaiKalam: "06:25 నుండి 07:49 వరకు",
            Karanamulu: "కౌలవ 17:11 వరకు, తైతిల 06:19, నవంబర్ 24 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "జ్యేష్ఠ 16:47 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "09:14 నుండి 10:38 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:25",
            Sunset: "17:40",
            Thiti: "విదియ 17:11 వరకు",
            Yamaganda: "13:27 నుండి 14:51 వరకు",
            Yoga: "సుకర్మా 11:30 వరకు",
            Varjyam: "01:40, నవంబర్ 24 నుండి 03:27, నవంబర్ 24 వరకు",
            Moonrise: "08:03",
            Moonset: "19:09",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:40 నుండి 12:25 వరకు",
            Amrutakalam: "12:21 నుండి 14:07 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "ఆదివారము, నవంబర్ 23, 2025",
            DurMuhurtamulu: "16:10 నుండి 16:55 వరకు",
            GulikaiKalam: "14:51 నుండి 16:15 వరకు",
            Karanamulu: "గర 19:24 వరకు, వణిజ నిండా రాత్రి వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "మూల 19:28 వరకు",
            MainNakshatra: "మూల",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "16:15 నుండి 17:40 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:25",
            Sunset: "17:40",
            Thiti: "తదియ 19:24 వరకు",
            Yamaganda: "12:03 నుండి 13:27 వరకు",
            Yoga: "ధృతి 12:09 వరకు",
            Varjyam:
              "17:41 నుండి 19:28 వరకు, 06:02, నవంబర్ 24 నుండి 07:48, నవంబర్ 24 వరకు",
            Moonrise: "08:56",
            Moonset: "20:02",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:40 నుండి 12:25 వరకు",
            Amrutakalam: "16:36 నుండి 18:22 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు 04:27, నవంబర్ 25 వరకు",
            Date: "సోమవారము, నవంబర్ 24, 2025",
            DurMuhurtamulu: "12:25 నుండి 13:10 వరకు, 14:40 నుండి 15:25 వరకు",
            GulikaiKalam: "13:27 నుండి 14:51 వరకు",
            Karanamulu: "వణిజ 08:25 వరకు, విష్టి 21:22 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "పూర్వాషాఢ 21:53 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "07:50 నుండి 09:14 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:26",
            Sunset: "17:40",
            Thiti: "చవితి 21:22 వరకు",
            Yamaganda: "10:39 నుండి 12:03 వరకు",
            Yoga: "శూల 12:37 వరకు",
            Varjyam: "ఏమి లేదు",
            Moonrise: "09:45",
            Moonset: "20:57",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:41 నుండి 12:26 వరకు",
            Amrutakalam: "17:00 నుండి 18:45 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము",
            Date: "మంగళవారము, నవంబర్ 25, 2025",
            DurMuhurtamulu: "08:41 నుండి 09:26 వరకు, 22:47 నుండి 23:38 వరకు",
            GulikaiKalam: "12:03 నుండి 13:27 వరకు",
            Karanamulu: "బవ 10:12 వరకు, బాలవ 22:56 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "ఉత్తరాషాఢ 23:57 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "14:51 నుండి 16:16 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:27",
            Sunset: "17:40",
            Thiti: "పంచమి 22:56 వరకు",
            Yamaganda: "09:15 నుండి 10:39 వరకు",
            Yoga: "గణ్డ 12:50 వరకు",
            Varjyam:
              "06:35 నుండి 08:19 వరకు, 04:13, నవంబర్ 26 నుండి 05:56, నవంబర్ 26 వరకు",
            Moonrise: "10:32",
            Moonset: "21:52",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "14:27 నుండి 16:10 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము",
            Date: "బుధవారము, నవంబర్ 26, 2025",
            DurMuhurtamulu: "11:41 నుండి 12:26 వరకు",
            GulikaiKalam: "10:39 నుండి 12:03 వరకు",
            Karanamulu: "కౌలవ 11:33 వరకు, తైతిల 00:01, నవంబర్ 27 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "శ్రవణం 01:32, నవంబర్ 27 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "12:03 నుండి 13:28 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:27",
            Sunset: "17:40",
            Thiti: "షష్టి 00:01, నవంబర్ 27 వరకు",
            Yamaganda: "07:51 నుండి 09:15 వరకు",
            Yoga: "వృద్ధి 12:43 వరకు",
            Varjyam: "05:42, నవంబర్ 27 నుండి 07:22, నవంబర్ 27 వరకు",
            Moonrise: "11:15",
            Moonset: "22:46",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:41 నుండి 12:26 వరకు",
            Amrutakalam: "15:42 నుండి 17:22 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము 14:07 వరకు",
            Date: "గురువారము, నవంబర్ 27, 2025",
            DurMuhurtamulu: "10:12 నుండి 10:57 వరకు, 14:41 నుండి 15:25 వరకు",
            GulikaiKalam: "09:16 నుండి 10:40 వరకు",
            Karanamulu: "గర 12:20 వరకు, వణిజ 00:29, నవంబర్ 28 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "ధనిష్ఠ 02:32, నవంబర్ 28 వరకు",
            MainNakshatra: "ధనిష్ఠ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "13:28 నుండి 14:52 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:28",
            Sunset: "17:40",
            Thiti: "సప్తమి 00:29, నవంబర్ 28 వరకు",
            Yamaganda: "06:28 నుండి 07:52 వరకు",
            Yoga: "ధ్రువ 12:09 వరకు",
            Varjyam: "ఏమి లేదు",
            Moonrise: "11:55",
            Moonset: "23:40",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:42 నుండి 12:27 వరకు",
            Amrutakalam: "19:32 నుండి 21:09 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము",
            Date: "శుక్రవారము, నవంబర్ 28, 2025",
            DurMuhurtamulu: "08:43 నుండి 09:27 వరకు, 12:27 నుండి 13:11 వరకు",
            GulikaiKalam: "07:52 నుండి 09:16 వరకు",
            Karanamulu: "విష్టి 12:28 వరకు, బవ 00:15, నవంబర్ 29 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "శతభిషం 02:49, నవంబర్ 29 వరకు",
            MainNakshatra: "శతభిషం",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "10:40 నుండి 12:04 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:28",
            Sunset: "17:40",
            Thiti: "అష్టమి 00:15, నవంబర్ 29 వరకు",
            Yamaganda: "14:52 నుండి 16:16 వరకు",
            Yoga: "వ్యాఘాత 11:05 వరకు",
            Varjyam: "09:49 నుండి 11:26 వరకు",
            Moonrise: "12:32",
            Moonset: "00:33, నవంబర్ 29",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:42 నుండి 12:27 వరకు",
            Amrutakalam: "18:31 నుండి 20:05 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము 20:33 వరకు",
            Date: "శనివారము, నవంబర్ 29, 2025",
            DurMuhurtamulu: "06:29 నుండి 07:14 వరకు, 07:14 నుండి 07:58 వరకు",
            GulikaiKalam: "06:29 నుండి 07:53 వరకు",
            Karanamulu: "బాలవ 11:50 వరకు, కౌలవ 23:15 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "పూర్వాభాద్ర 02:22, నవంబర్ 30 వరకు",
            MainNakshatra: "పూర్వాభాద్ర",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "09:17 నుండి 10:41 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:29",
            Sunset: "17:40",
            Thiti: "నవమి 23:15 వరకు",
            Yamaganda: "13:28 నుండి 14:52 వరకు",
            Yoga: "హర్షణ 09:27 వరకు",
            Varjyam: "09:06 నుండి 10:40 వరకు",
            Moonrise: "13:09",
            Moonset: "01:28, నవంబర్ 30",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:43 నుండి 12:27 వరకు",
            Amrutakalam: "20:37 నుండి 22:08 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం",
            Date: "ఆదివారము, నవంబర్ 30, 2025",
            DurMuhurtamulu: "16:11 నుండి 16:55 వరకు",
            GulikaiKalam: "14:52 నుండి 16:16 వరకు",
            Karanamulu: "తైతిల 10:27 వరకు, బవ 21:29 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "ఉత్తరాభాద్ర 01:11, డిసెంబర్ 01 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "16:16 నుండి 17:40 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:30",
            Sunset: "17:40",
            Thiti: "దశమి 21:29 వరకు",
            Yamaganda: "12:05 నుండి 13:29 వరకు",
            Yoga: "వజ్ర 07:12 వరకు, సిద్ధి 04:22, డిసెంబర్ 01 వరకు",
            Varjyam: "11:30 నుండి 13:01 వరకు",
            Moonrise: "13:47",
            Moonset: "02:24, డిసెంబర్ 01",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
        ],
      },
      {
        day: [
          {
            Abhijit: "11:43 నుండి 12:28 వరకు",
            Amrutakalam: "21:05 నుండి 22:34 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం 23:18 వరకు",
            Date: "సోమవారము, డిసెంబర్ 01, 2025",
            DurMuhurtamulu: "12:28 నుండి 13:12 వరకు, 14:42 నుండి 15:26 వరకు",
            GulikaiKalam: "13:29 నుండి 14:53 వరకు",
            Karanamulu:
              "వణిజ 08:20 వరకు, విష్టి 19:01 వరకు, బవ 05:33, డిసెంబర్ 02 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "రేవతి 23:18 వరకు",
            MainNakshatra: "రేవతి",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "07:54 నుండి 09:18 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:30",
            Sunset: "17:40",
            Thiti: "ఏకాదశి 19:01 వరకు",
            Yamaganda: "10:41 నుండి 12:05 వరకు",
            Yoga: "వ్యతీపాత 00:59, డిసెంబర్ 02 వరకు",
            Varjyam: "12:14 నుండి 13:43 వరకు",
            Moonrise: "14:27",
            Moonset: "03:24, డిసెంబర్ 02",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:43 నుండి 12:28 వరకు",
            Amrutakalam: "14:23 నుండి 15:49 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం",
            Date: "మంగళవారము, డిసెంబర్ 02, 2025",
            DurMuhurtamulu: "08:45 నుండి 09:29 వరకు, 22:49 నుండి 23:40 వరకు",
            GulikaiKalam: "12:06 నుండి 13:29 వరకు",
            Karanamulu: "బాలవ 15:57 వరకు, కౌలవ 02:14, డిసెంబర్ 03 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "అశ్విని 20:51 వరకు",
            MainNakshatra: "అశ్విని",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "14:53 నుండి 16:17 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:31",
            Sunset: "17:40",
            Thiti: "ద్వాదశి 15:57 వరకు",
            Yamaganda: "09:18 నుండి 10:42 వరకు",
            Yoga: "వారీయన 21:08 వరకు",
            Varjyam:
              "17:16 నుండి 18:42 వరకు, 05:19, డిసెంబర్ 03 నుండి 06:43, డిసెంబర్ 03 వరకు",
            Moonrise: "15:11",
            Moonset: "04:28, డిసెంబర్ 03",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "13:46 నుండి 15:10 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం 23:14 వరకు",
            Date: "బుధవారము, డిసెంబర్ 03, 2025",
            DurMuhurtamulu: "11:44 నుండి 12:28 వరకు",
            GulikaiKalam: "10:42 నుండి 12:06 వరకు",
            Karanamulu: "తైతిల 12:25 వరకు, బవ 22:33 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "భరణి 17:59 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "12:06 నుండి 13:30 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:31",
            Sunset: "17:41",
            Thiti: "త్రయోదశి 12:25 వరకు",
            Yamaganda: "07:55 నుండి 09:19 వరకు",
            Yoga: "పరిఘ 16:57 వరకు",
            Varjyam: "04:27, డిసెంబర్ 04 నుండి 05:50, డిసెంబర్ 04 వరకు",
            Moonrise: "16:00",
            Moonset: "05:36, డిసెంబర్ 04",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:44 నుండి 12:29 వరకు",
            Amrutakalam: "12:48 నుండి 14:12 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృషభం",
            Date: "గురువారము, డిసెంబర్ 04, 2025",
            DurMuhurtamulu: "10:15 నుండి 11:00 వరకు, 14:43 నుండి 15:27 వరకు",
            GulikaiKalam: "09:19 నుండి 10:43 వరకు",
            Karanamulu:
              "వణిజ 08:37 వరకు, విష్టి 18:40 వరకు, బవ 04:43, డిసెంబర్ 05 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "కృత్తిక 14:54 వరకు",
            MainNakshatra: "కృత్తిక",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "13:30 నుండి 14:54 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:32",
            Sunset: "17:41",
            Thiti: "చతుర్దశి 08:37 వరకు, పౌర్ణమి 04:43, డిసెంబర్ 05 వరకు",
            Yamaganda: "06:32 నుండి 07:56 వరకు",
            Yoga: "శివ 12:34 వరకు",
            Varjyam: "04:49, డిసెంబర్ 05 నుండి 06:12, డిసెంబర్ 05 వరకు",
            Moonrise: "16:57",
            Moonset: "ఏమి లేదు",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "pournami",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:45 నుండి 12:29 వరకు",
            Amrutakalam:
              "08:59 నుండి 10:23 వరకు, 01:06, డిసెంబర్ 06 నుండి 02:30, డిసెంబర్ 06 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృషభం 22:15 వరకు",
            Date: "శుక్రవారము, డిసెంబర్ 05, 2025",
            DurMuhurtamulu: "08:46 నుండి 09:31 వరకు, 12:29 నుండి 13:14 వరకు",
            GulikaiKalam: "07:56 నుండి 09:20 వరకు",
            Karanamulu: "బాలవ 14:48 వరకు, కౌలవ 00:55, డిసెంబర్ 06 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "రోహిణి 11:46 వరకు",
            MainNakshatra: "రోహిణి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "10:43 నుండి 12:07 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:33",
            Sunset: "17:41",
            Thiti: "పాడ్యమి 00:55, డిసెంబర్ 06 వరకు",
            Yamaganda: "14:54 నుండి 16:18 వరకు",
            Yoga: "సిద్ధ 08:08 వరకు, సాధ్య 03:49, డిసెంబర్ 06 వరకు",
            Varjyam: "16:41 నుండి 18:05 వరకు",
            Moonrise: "18:01",
            Moonset: "06:47",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:45 నుండి 12:30 వరకు",
            Amrutakalam: "21:18 నుండి 22:43 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం",
            Date: "శనివారము, డిసెంబర్ 06, 2025",
            DurMuhurtamulu: "06:33 నుండి 07:18 వరకు, 07:18 నుండి 08:02 వరకు",
            GulikaiKalam: "06:33 నుండి 07:57 వరకు",
            Karanamulu: "తైతిల 11:07 వరకు, బవ 21:25 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "మృగశిర 08:48 వరకు, ఆరుద్ర 06:13, డిసెంబర్ 07 వరకు",
            MainNakshatra: "మృగశిర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "09:20 నుండి 10:44 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:33",
            Sunset: "17:41",
            Thiti: "విదియ 21:25 వరకు",
            Yamaganda: "13:31 నుండి 14:54 వరకు",
            Yoga: "శుభ 23:46 వరకు",
            Varjyam: "16:18 నుండి 17:44 వరకు",
            Moonrise: "19:09",
            Moonset: "07:56",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:45 నుండి 12:30 వరకు",
            Amrutakalam: "01:59, డిసెంబర్ 08 నుండి 03:27, డిసెంబర్ 08 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం 22:38 వరకు",
            Date: "ఆదివారము, డిసెంబర్ 07, 2025",
            DurMuhurtamulu: "16:13 నుండి 16:57 వరకు",
            GulikaiKalam: "14:55 నుండి 16:18 వరకు",
            Karanamulu:
              "వణిజ 07:50 వరకు, విష్టి 18:24 వరకు, బవ 05:08, డిసెంబర్ 08 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "పునర్వసు 04:11, డిసెంబర్ 08 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "16:18 నుండి 17:42 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:34",
            Sunset: "17:42",
            Thiti: "తదియ 18:24 వరకు",
            Yamaganda: "12:08 నుండి 13:31 వరకు",
            Yoga: "శుక్ల 20:07 వరకు",
            Varjyam: "17:12 నుండి 18:40 వరకు",
            Moonrise: "20:17",
            Moonset: "08:59",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:46 నుండి 12:30 వరకు",
            Amrutakalam: "20:49 నుండి 22:20 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "సోమవారము, డిసెంబర్ 08, 2025",
            DurMuhurtamulu: "12:30 నుండి 13:15 వరకు, 14:44 నుండి 15:28 వరకు",
            GulikaiKalam: "13:32 నుండి 14:55 వరకు",
            Karanamulu: "బాలవ 16:03 వరకు, కౌలవ 03:09, డిసెంబర్ 09 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "పుష్యమి 02:52, డిసెంబర్ 09 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "07:58 నుండి 09:21 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:34",
            Sunset: "17:42",
            Thiti: "చవితి 16:03 వరకు",
            Yamaganda: "10:45 నుండి 12:08 వరకు",
            Yoga: "బ్రహ్మ 17:01 వరకు",
            Varjyam: "11:45 నుండి 13:16 వరకు",
            Moonrise: "21:21",
            Moonset: "09:54",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:46 నుండి 12:31 వరకు",
            Amrutakalam: "00:48, డిసెంబర్ 10 నుండి 02:22, డిసెంబర్ 10 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం 02:22, డిసెంబర్ 10 వరకు",
            Date: "మంగళవారము, డిసెంబర్ 09, 2025",
            DurMuhurtamulu: "08:48 నుండి 09:33 వరకు, 22:52 నుండి 23:43 వరకు",
            GulikaiKalam: "12:09 నుండి 13:32 వరకు",
            Karanamulu: "తైతిల 14:28 వరకు, బవ 02:00, డిసెంబర్ 10 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "ఆశ్లేష 02:22, డిసెంబర్ 10 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "14:55 నుండి 16:19 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:35",
            Sunset: "17:42",
            Thiti: "పంచమి 14:28 వరకు",
            Yamaganda: "09:22 నుండి 10:45 వరకు",
            Yoga: "ఐన్ద్ర 14:33 వరకు",
            Varjyam: "15:24 నుండి 16:58 వరకు",
            Moonrise: "22:21",
            Moonset: "10:42",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "00:18, డిసెంబర్ 11 నుండి 01:55, డిసెంబర్ 11 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం",
            Date: "బుధవారము, డిసెంబర్ 10, 2025",
            DurMuhurtamulu: "11:47 నుండి 12:31 వరకు",
            GulikaiKalam: "10:46 నుండి 12:09 వరకు",
            Karanamulu: "వణిజ 13:46 వరకు, విష్టి 01:45, డిసెంబర్ 11 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "మఖ 02:44, డిసెంబర్ 11 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "12:09 నుండి 13:32 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:36",
            Sunset: "17:43",
            Thiti: "షష్టి 13:46 వరకు",
            Yamaganda: "07:59 నుండి 09:22 వరకు",
            Yoga: "వైధృతి 12:46 వరకు",
            Varjyam: "14:33 నుండి 16:11 వరకు",
            Moonrise: "23:16",
            Moonset: "11:23",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:47 నుండి 12:32 వరకు",
            Amrutakalam: "21:12 నుండి 22:53 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం",
            Date: "గురువారము, డిసెంబర్ 11, 2025",
            DurMuhurtamulu: "10:18 నుండి 11:03 వరకు, 14:45 నుండి 15:30 వరకు",
            GulikaiKalam: "09:23 నుండి 10:46 వరకు",
            Karanamulu: "బవ 13:56 వరకు, బాలవ 02:21, డిసెంబర్ 12 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "పుబ్బ 03:55, డిసెంబర్ 12 వరకు",
            MainNakshatra: "పుబ్బ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "13:33 నుండి 14:56 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:36",
            Sunset: "17:43",
            Thiti: "సప్తమి 13:56 వరకు",
            Yamaganda: "06:36 నుండి 07:59 వరకు",
            Yoga: "విష్కుమ్భ 11:40 వరకు",
            Varjyam: "11:08 నుండి 12:49 వరకు",
            Moonrise: "00:07, డిసెంబర్ 12",
            Moonset: "12:00",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:32 వరకు",
            Amrutakalam: "22:04 నుండి 23:47 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం 10:20 వరకు",
            Date: "శుక్రవారము, డిసెంబర్ 12, 2025",
            DurMuhurtamulu: "08:50 నుండి 09:34 వరకు, 12:32 నుండి 13:17 వరకు",
            GulikaiKalam: "08:00 నుండి 09:23 వరకు",
            Karanamulu: "కౌలవ 14:56 వరకు, తైతిల 03:42, డిసెంబర్ 13 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "ఉత్తర 05:50, డిసెంబర్ 13 వరకు",
            MainNakshatra: "ఉత్తర",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "10:47 నుండి 12:10 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:37",
            Sunset: "17:43",
            Thiti: "అష్టమి 14:56 వరకు",
            Yamaganda: "14:57 నుండి 16:20 వరకు",
            Yoga: "ప్రీతి 11:12 వరకు",
            Varjyam: "11:42 నుండి 13:25 వరకు",
            Moonrise: "00:56, డిసెంబర్ 13",
            Moonset: "12:34",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:48 నుండి 12:33 వరకు",
            Amrutakalam: "01:41, డిసెంబర్ 14 నుండి 03:27, డిసెంబర్ 14 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య",
            Date: "శనివారము, డిసెంబర్ 13, 2025",
            DurMuhurtamulu: "06:37 నుండి 07:22 వరకు, 07:22 నుండి 08:06 వరకు",
            GulikaiKalam: "06:37 నుండి 08:01 వరకు",
            Karanamulu: "బవ 16:37 వరకు, వణిజ 05:40, డిసెంబర్ 14 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "హస్త నిండా రాత్రి వరకు",
            MainNakshatra: "హస్త",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "09:24 నుండి 10:47 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:37",
            Sunset: "17:44",
            Thiti: "నవమి 16:37 వరకు",
            Yamaganda: "13:34 నుండి 14:57 వరకు",
            Yoga: "ఆయుష్మాన్ 11:17 వరకు",
            Varjyam: "15:06 నుండి 16:52 వరకు",
            Moonrise: "01:44, డిసెంబర్ 14",
            Moonset: "13:07",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:33 వరకు",
            Amrutakalam: "03:59, డిసెంబర్ 15 నుండి 05:46, డిసెంబర్ 15 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కన్య 21:41 వరకు",
            Date: "ఆదివారము, డిసెంబర్ 14, 2025",
            DurMuhurtamulu: "16:15 నుండి 17:00 వరకు",
            GulikaiKalam: "14:57 నుండి 16:21 వరకు",
            Karanamulu: "విష్టి 18:49 వరకు, బవ నిండా రాత్రి వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "హస్త 08:18 వరకు",
            MainNakshatra: "హస్త",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "16:21 నుండి 17:44 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:38",
            Sunset: "17:44",
            Thiti: "దశమి 18:49 వరకు",
            Yamaganda: "12:11 నుండి 13:34 వరకు",
            Yoga: "సౌభాగ్య 11:45 వరకు",
            Varjyam: "17:15 నుండి 19:02 వరకు",
            Moonrise: "02:32, డిసెంబర్ 15",
            Moonset: "13:40",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:49 నుండి 12:34 వరకు",
            Amrutakalam: "04:15, డిసెంబర్ 16 నుండి 06:03, డిసెంబర్ 16 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల",
            Date: "సోమవారము, డిసెంబర్ 15, 2025",
            DurMuhurtamulu: "12:34 నుండి 13:18 వరకు, 14:47 నుండి 15:31 వరకు",
            GulikaiKalam: "13:35 నుండి 14:58 వరకు",
            Karanamulu: "బవ 08:03 వరకు, బాలవ 21:19 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "చిత్తా 11:08 వరకు",
            MainNakshatra: "చిత్తా",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "08:02 నుండి 09:25 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:38",
            Sunset: "17:44",
            Thiti: "ఏకాదశి 21:19 వరకు",
            Yamaganda: "10:48 నుండి 12:11 వరకు",
            Yoga: "శోభన 12:30 వరకు",
            Varjyam: "17:27 నుండి 19:15 వరకు",
            Moonrise: "03:21, డిసెంబర్ 16",
            Moonset: "14:14",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:50 నుండి 12:34 వరకు",
            Amrutakalam: "ఏమి లేదు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల",
            Date: "మంగళవారము, డిసెంబర్ 16, 2025",
            DurMuhurtamulu: "08:52 నుండి 09:37 వరకు, 22:55 నుండి 23:46 వరకు",
            GulikaiKalam: "12:12 నుండి 13:35 వరకు",
            Karanamulu: "కౌలవ 10:38 వరకు, తైతిల 23:57 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "స్వాతి 14:09 వరకు",
            MainNakshatra: "స్వాతి",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "14:58 నుండి 16:22 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:39",
            Sunset: "17:45",
            Thiti: "ద్వాదశి 23:57 వరకు",
            Yamaganda: "09:25 నుండి 10:49 వరకు",
            Yoga: "అతిగణ్డ 13:23 వరకు",
            Varjyam: "20:28 నుండి 22:16 వరకు",
            Moonrise: "04:12, డిసెంబర్ 17",
            Moonset: "14:51",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "07:17 నుండి 09:05 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "తుల 10:26 వరకు",
            Date: "బుధవారము, డిసెంబర్ 17, 2025",
            DurMuhurtamulu: "11:50 నుండి 12:35 వరకు",
            GulikaiKalam: "10:49 నుండి 12:12 వరకు",
            Karanamulu: "బవ 13:15 వరకు, వణిజ 02:32, డిసెంబర్ 18 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "విశాఖ 17:11 వరకు",
            MainNakshatra: "విశాఖ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "12:12 నుండి 13:36 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:40",
            Sunset: "17:45",
            Thiti: "త్రయోదశి 02:32, డిసెంబర్ 18 వరకు",
            Yamaganda: "08:03 నుండి 09:26 వరకు",
            Yoga: "సుకర్మా 14:17 వరకు",
            Varjyam: "21:40 నుండి 23:28 వరకు",
            Moonrise: "05:05, డిసెంబర్ 18",
            Moonset: "15:32",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:51 నుండి 12:35 వరకు",
            Amrutakalam: "08:27 నుండి 10:14 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము",
            Date: "గురువారము, డిసెంబర్ 18, 2025",
            DurMuhurtamulu: "10:22 నుండి 11:06 వరకు, 14:48 నుండి 15:33 వరకు",
            GulikaiKalam: "09:26 నుండి 10:50 వరకు",
            Karanamulu: "విష్టి 15:47 వరకు, శకుని 04:59, డిసెంబర్ 19 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "అనూరాధ 20:07 వరకు",
            MainNakshatra: "అనూరాధ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "13:36 నుండి 14:59 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:40",
            Sunset: "17:46",
            Thiti: "చతుర్దశి 04:59, డిసెంబర్ 19 వరకు",
            Yamaganda: "06:40 నుండి 08:03 వరకు",
            Yoga: "ధృతి 15:06 వరకు",
            Varjyam: "02:21, డిసెంబర్ 19 నుండి 04:08, డిసెంబర్ 19 వరకు",
            Moonrise: "05:58, డిసెంబర్ 19",
            Moonset: "16:16",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:51 నుండి 12:36 వరకు",
            Amrutakalam: "13:03 నుండి 14:50 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "వృశ్చికము 22:51 వరకు",
            Date: "శుక్రవారము, డిసెంబర్ 19, 2025",
            DurMuhurtamulu: "08:54 నుండి 09:38 వరకు, 12:36 నుండి 13:20 వరకు",
            GulikaiKalam: "08:04 నుండి 09:27 వరకు",
            Karanamulu: "చతుష్పాద 18:07 వరకు, నాగ నిండా రాత్రి వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "జ్యేష్ఠ 22:51 వరకు",
            MainNakshatra: "జ్యేష్ఠ",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "10:50 నుండి 12:13 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:41",
            Sunset: "17:46",
            Thiti: "అమావాస్య నిండా రాత్రి వరకు",
            Yamaganda: "15:00 నుండి 16:23 వరకు",
            Yoga: "శూల 15:47 వరకు",
            Varjyam: "ఏమి లేదు",
            Moonrise: "ఏమి లేదు",
            Moonset: "17:05",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "amavasya",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:52 నుండి 12:36 వరకు",
            Amrutakalam: "18:17 నుండి 20:03 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "శనివారము, డిసెంబర్ 20, 2025",
            DurMuhurtamulu: "06:41 నుండి 07:25 వరకు, 07:25 నుండి 08:10 వరకు",
            GulikaiKalam: "06:41 నుండి 08:04 వరకు",
            Karanamulu: "నాగ 07:12 వరకు, కింస్తుఘ్న 20:13 వరకు",
            Masam: "మార్గశిరము",
            Nakshatra1: "మూల 01:21, డిసెంబర్ 21 వరకు",
            MainNakshatra: "మూల",
            Paksham: "కృష్ణ పక్షములు",
            RahuKalam: "09:27 నుండి 10:51 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:41",
            Sunset: "17:47",
            Thiti: "అమావాస్య 07:12 వరకు",
            Yamaganda: "13:37 నుండి 15:00 వరకు",
            Yoga: "గణ్డ 16:17 వరకు",
            Varjyam: "07:41 నుండి 09:27 వరకు",
            Moonrise: "06:51",
            Moonset: "17:58",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "amavasya",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:52 నుండి 12:37 వరకు",
            Amrutakalam: "22:21 నుండి 00:06, డిసెంబర్ 22 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు",
            Date: "ఆదివారము, డిసెంబర్ 21, 2025",
            DurMuhurtamulu: "16:18 నుండి 17:03 వరకు",
            GulikaiKalam: "15:01 నుండి 16:24 వరకు",
            Karanamulu: "బవ 09:10 వరకు, బాలవ 22:03 వరకు",
            Masam: "పుష్యము",
            Nakshatra1: "పూర్వాషాఢ 03:36, డిసెంబర్ 22 వరకు",
            MainNakshatra: "పూర్వాషాఢ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "16:24 నుండి 17:47 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:42",
            Sunset: "17:47",
            Thiti: "పాడ్యమి 09:10 వరకు",
            Yamaganda: "12:14 నుండి 13:38 వరకు",
            Yoga: "వృద్ధి 16:36 వరకు",
            Varjyam: "11:51 నుండి 13:36 వరకు",
            Moonrise: "07:42",
            Moonset: "18:52",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:37 వరకు",
            Amrutakalam: "22:37 నుండి 00:21, డిసెంబర్ 23 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "ధనస్సు 10:07 వరకు",
            Date: "సోమవారము, డిసెంబర్ 22, 2025",
            DurMuhurtamulu: "12:37 నుండి 13:21 వరకు, 14:50 నుండి 15:35 వరకు",
            GulikaiKalam: "13:38 నుండి 15:01 వరకు",
            Karanamulu: "కౌలవ 10:51 వరకు, తైతిల 23:34 వరకు",
            Masam: "పుష్యము",
            Nakshatra1: "ఉత్తరాషాఢ 05:32, డిసెంబర్ 23 వరకు",
            MainNakshatra: "ఉత్తరాషాఢ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "08:05 నుండి 09:28 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:42",
            Sunset: "17:48",
            Thiti: "విదియ 10:51 వరకు",
            Yamaganda: "10:52 నుండి 12:15 వరకు",
            Yoga: "ధ్రువ 16:41 వరకు",
            Varjyam: "12:14 నుండి 13:58 వరకు",
            Moonrise: "08:30",
            Moonset: "19:48",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:53 నుండి 12:38 వరకు",
            Amrutakalam: "20:02 నుండి 21:44 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము",
            Date: "మంగళవారము, డిసెంబర్ 23, 2025",
            DurMuhurtamulu: "08:56 నుండి 09:40 వరకు, 22:58 నుండి 23:50 వరకు",
            GulikaiKalam: "12:15 నుండి 13:39 వరకు",
            Karanamulu: "గర 12:12 వరకు, వణిజ 00:45, డిసెంబర్ 24 వరకు",
            Masam: "పుష్యము",
            Nakshatra1: "శ్రవణం నిండా రాత్రి వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "15:02 నుండి 16:25 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:43",
            Sunset: "17:48",
            Thiti: "తదియ 12:12 వరకు",
            Yamaganda: "09:29 నుండి 10:52 వరకు",
            Yoga: "వ్యాఘాత 16:30 వరకు",
            Varjyam: "09:48 నుండి 11:30 వరకు",
            Moonrise: "09:14",
            Moonset: "20:42",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఎదీకాదు",
            Amrutakalam: "21:23 నుండి 23:04 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మకరము 19:46 వరకు",
            Date: "బుధవారము, డిసెంబర్ 24, 2025",
            DurMuhurtamulu: "11:54 నుండి 12:38 వరకు",
            GulikaiKalam: "10:53 నుండి 12:16 వరకు",
            Karanamulu: "విష్టి 13:11 వరకు, బవ 01:30, డిసెంబర్ 25 వరకు",
            Masam: "పుష్యము",
            Nakshatra1: "శ్రవణం 07:07 వరకు",
            MainNakshatra: "శ్రవణం",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "12:16 నుండి 13:39 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:43",
            Sunset: "17:49",
            Thiti: "చవితి 13:11 వరకు",
            Yamaganda: "08:06 నుండి 09:29 వరకు",
            Yoga: "హర్షణ 16:02 వరకు",
            Varjyam: "11:19 నుండి 13:00 వరకు",
            Moonrise: "09:55",
            Moonset: "21:36",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:54 నుండి 12:39 వరకు",
            Amrutakalam: "01:35, డిసెంబర్ 26 నుండి 03:14, డిసెంబర్ 26 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము",
            Date: "గురువారము, డిసెంబర్ 25, 2025",
            DurMuhurtamulu: "10:25 నుండి 11:10 వరకు, 14:52 నుండి 15:36 వరకు",
            GulikaiKalam: "09:30 నుండి 10:53 వరకు",
            Karanamulu: "బాలవ 13:42 వరకు, కౌలవ 01:47, డిసెంబర్ 26 వరకు",
            Masam: "పుష్యము",
            Nakshatra1: "ధనిష్ఠ 08:18 వరకు",
            MainNakshatra: "ధనిష్ఠ",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "13:40 నుండి 15:03 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:44",
            Sunset: "17:49",
            Thiti: "పంచమి 13:42 వరకు",
            Yamaganda: "06:44 నుండి 08:07 వరకు",
            Yoga: "వజ్ర 15:14 వరకు",
            Varjyam: "15:43 నుండి 17:22 వరకు",
            Moonrise: "10:32",
            Moonset: "22:28",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "25",
            LordImage: require("../images/holidays/11-1)Christmas.png"),
          },
          {
            Abhijit: "11:55 నుండి 12:39 వరకు",
            Amrutakalam: "01:06, డిసెంబర్ 27 నుండి 02:43, డిసెంబర్ 27 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కుంభము 03:10, డిసెంబర్ 27 వరకు",
            Date: "శుక్రవారము, డిసెంబర్ 26, 2025",
            DurMuhurtamulu: "08:57 నుండి 09:42 వరకు, 12:39 నుండి 13:23 వరకు",
            GulikaiKalam: "08:07 నుండి 09:30 వరకు",
            Karanamulu: "తైతిల 13:43 వరకు, గర 01:31, డిసెంబర్ 27 వరకు",
            Masam: "పుష్యము",
            Nakshatra1: "శతభిషం 09:00 వరకు",
            MainNakshatra: "శతభిషం",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "10:54 నుండి 12:17 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:44",
            Sunset: "17:50",
            Thiti: "షష్టి 13:43 వరకు",
            Yamaganda: "15:03 నుండి 16:27 వరకు",
            Yoga: "సిద్ధి 14:01 వరకు",
            Varjyam: "15:27 నుండి 17:03 వరకు",
            Moonrise: "11:09",
            Moonset: "23:21",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:55 నుండి 12:40 వరకు",
            Amrutakalam: "04:00, డిసెంబర్ 28 నుండి 05:34, డిసెంబర్ 28 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం",
            Date: "శనివారము, డిసెంబర్ 27, 2025",
            DurMuhurtamulu: "06:44 నుండి 07:29 వరకు, 07:29 నుండి 08:13 వరకు",
            GulikaiKalam: "06:44 నుండి 08:08 వరకు",
            Karanamulu: "వణిజ 13:09 వరకు, విష్టి 00:39, డిసెంబర్ 28 వరకు",
            Masam: "పుష్యము",
            Nakshatra1: "పూర్వాభాద్ర 09:09 వరకు",
            MainNakshatra: "పూర్వాభాద్ర",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "09:31 నుండి 10:54 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:44",
            Sunset: "17:50",
            Thiti: "సప్తమి 13:09 వరకు",
            Yamaganda: "13:41 నుండి 15:04 వరకు",
            Yoga: "వ్యతీపాత 12:22 వరకు",
            Varjyam: "18:35 నుండి 20:09 వరకు",
            Moonrise: "11:45",
            Moonset: "00:15, డిసెంబర్ 28",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:40 వరకు",
            Amrutakalam: "05:23, డిసెంబర్ 29 నుండి 06:55, డిసెంబర్ 29 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం",
            Date: "ఆదివారము, డిసెంబర్ 28, 2025",
            DurMuhurtamulu: "16:22 నుండి 17:06 వరకు",
            GulikaiKalam: "15:04 నుండి 16:28 వరకు",
            Karanamulu: "బవ 11:59 వరకు, బాలవ 23:10 వరకు",
            Masam: "పుష్యము",
            Nakshatra1: "ఉత్తరాభాద్ర 08:43 వరకు",
            MainNakshatra: "ఉత్తరాభాద్ర",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "16:28 నుండి 17:51 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:45",
            Sunset: "17:51",
            Thiti: "అష్టమి 11:59 వరకు",
            Yamaganda: "12:18 నుండి 13:41 వరకు",
            Yoga: "వారీయన 10:13 వరకు",
            Varjyam: "20:12 నుండి 21:44 వరకు",
            Moonrise: "12:22",
            Moonset: "01:10, డిసెంబర్ 29",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:56 నుండి 12:41 వరకు",
            Amrutakalam: "23:21 నుండి 00:50, డిసెంబర్ 30 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మీనం 07:41 వరకు",
            Date: "సోమవారము, డిసెంబర్ 29, 2025",
            DurMuhurtamulu: "12:41 నుండి 13:25 వరకు, 14:54 నుండి 15:38 వరకు",
            GulikaiKalam: "13:42 నుండి 15:05 వరకు",
            Karanamulu: "కౌలవ 10:12 వరకు, తైతిల 21:05 వరకు",
            Masam: "పుష్యము",
            Nakshatra1: "రేవతి 07:41 వరకు, అశ్విని 06:04, డిసెంబర్ 30 వరకు",
            MainNakshatra: "రేవతి",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "08:09 నుండి 09:32 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:45",
            Sunset: "17:51",
            Thiti: "నవమి 10:12 వరకు",
            Yamaganda: "10:55 నుండి 12:18 వరకు",
            Yoga: "పరిఘ 07:36 వరకు, శివ 04:31, డిసెంబర్ 30 వరకు",
            Varjyam: "02:20, డిసెంబర్ 30 నుండి 03:50, డిసెంబర్ 30 వరకు",
            Moonrise: "13:03",
            Moonset: "02:10, డిసెంబర్ 30",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:57 నుండి 12:41 వరకు",
            Amrutakalam: "23:35 నుండి 01:03, డిసెంబర్ 31 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం",
            Date: "మంగళవారము, డిసెంబర్ 30, 2025",
            DurMuhurtamulu: "08:59 నుండి 09:43 వరకు, 23:02 నుండి 23:53 వరకు",
            GulikaiKalam: "12:19 నుండి 13:42 వరకు",
            Karanamulu:
              "బవ 07:50 వరకు, వణిజ 18:28 వరకు, విష్టి 05:00, డిసెంబర్ 31 వరకు",
            Masam: "పుష్యము",
            Nakshatra1: "భరణి 03:58, డిసెంబర్ 31 వరకు",
            MainNakshatra: "భరణి",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "15:05 నుండి 16:29 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:46",
            Sunset: "17:52",
            Thiti: "దశమి 07:50 వరకు, ఏకాదశి 05:00, డిసెంబర్ 31 వరకు",
            Yamaganda: "09:32 నుండి 10:56 వరకు",
            Yoga: "సిద్ధ 01:02, డిసెంబర్ 31 వరకు",
            Varjyam: "14:49 నుండి 16:17 వరకు",
            Moonrise: "13:48",
            Moonset: "03:14, డిసెంబర్ 31",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "ఏమి లేదు",
            Amrutakalam: "23:20 నుండి 00:46, జనవరి 01 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మేషం 09:23 వరకు",
            Date: "బుధవారము, డిసెంబర్ 31, 2025",
            DurMuhurtamulu: "11:57 నుండి 12:42 వరకు",
            GulikaiKalam: "10:56 నుండి 12:19 వరకు",
            Karanamulu: "బవ 15:26 వరకు, బాలవ 01:47, జనవరి 01 వరకు",
            Masam: "పుష్య మాసం",
            Nakshatra1: "కృత్తిక 01:29, జనవరి 01 వరకు",
            MainNakshatra: "కృత్తిక",
            Paksham: "శుక్ల పక్షములు",
            RahuKalam: "12:19 నుండి 13:43 వరకు",
            Ruthuvu: "హేమంత ఋతువు",
            Sunrise: "06:46",
            Sunset: "17:53",
            Thiti: "ద్వాదశి 01:47, జనవరి 01 వరకు",
            Yamaganda: "08:09 నుండి 09:33 వరకు",
            Yoga: "సాధ్య 21:13 వరకు",
            Varjyam: "14:44 నుండి 16:10 వరకు",
            Moonrise: "14:39",
            Moonset: "04:22, జనవరి 01",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:43 నుండి 12:27 వరకు",
            Amrutakalam: "14:06 నుండి 15:49 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "మిధునం 10:12 వరకు",
            Date: "సోమవారము, డిసెంబర్ 1, 2025",
            DurMuhurtamulu: "08:44 నుండి 09:29 వరకు, 12:27 నుండి 13:12 వరకు",
            GulikaiKalam: "07:54 నుండి 09:17 వరకు",
            Karanamulu: "బాలవ 15:31 వరకు, కౌలవ 04:18, డిసెంబర్ 02 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "పునర్వసు 16:40 వరకు",
            MainNakshatra: "పునర్వసు",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "10:41 నుండి 12:05 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:30",
            Sunset: "17:40",
            Thiti: "చవితి 15:31 వరకు",
            Yamaganda: "14:53 నుండి 16:16 వరకు",
            Yoga: "శుక్ల 20:04 వరకు",
            Varjyam: "01:25, డిసెంబర్ 02 నుండి 03:10, డిసెంబర్ 02 వరకు",
            Moonrise: "21:14",
            Moonset: "10:01",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:43 నుండి 12:28 వరకు",
            Amrutakalam: "11:54 నుండి 13:39 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం",
            Date: "మంగళవారము, డిసెంబర్ 2, 2025",
            DurMuhurtamulu: "06:31 నుండి 07:15 వరకు, 07:15 నుండి 08:00 వరకు",
            GulikaiKalam: "06:31 నుండి 07:54 వరకు",
            Karanamulu: "తైతిల 17:14 వరకు, బవ 06:17, డిసెంబర్ 03 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "పుష్యమి 18:54 వరకు",
            MainNakshatra: "పుష్యమి",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "09:18 నుండి 10:42 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:31",
            Sunset: "17:40",
            Thiti: "పంచమి 17:14 వరకు",
            Yamaganda: "13:29 నుండి 14:53 వరకు",
            Yoga: "బ్రహ్మ 20:19 వరకు",
            Varjyam: "ఏమిలేదు",
            Moonrise: "22:08",
            Moonset: "10:46",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:44 నుండి 12:28 వరకు",
            Amrutakalam: "19:49 నుండి 21:36 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "కర్కాటకం 21:36 వరకు",
            Date: "బుధవారము, డిసెంబర్ 3, 2025",
            DurMuhurtamulu: "16:11 నుండి 16:56 వరకు",
            GulikaiKalam: "14:53 నుండి 16:17 వరకు",
            Karanamulu: "వణిజ 19:27 వరకు, విష్టి నిండా రాత్రి వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "ఆశ్లేష 21:36 వరకు",
            MainNakshatra: "ఆశ్లేష",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "16:17 నుండి 17:41 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:31",
            Sunset: "17:41",
            Thiti: "షష్టి 19:27 వరకు",
            Yamaganda: "12:06 నుండి 13:30 వరకు",
            Yoga: "ఐన్ద్ర 20:56 వరకు",
            Varjyam: "09:08 నుండి 10:55 వర",
            Moonrise: "22:59",
            Moonset: "11:27",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
          {
            Abhijit: "11:44 నుండి 12:29 వరకు",
            Amrutakalam: "21:53 నుండి 23:41 వరకు",
            Ayanam: "దక్షిణాయణం",
            Chandrashtama: "సింహం",
            Date: "గురువారము, డిసెంబర్ 4, 2025",
            DurMuhurtamulu: "12:29 నుండి 13:13 వరకు, 14:42 నుండి 15:27 వరకు",
            GulikaiKalam: "13:30 నుండి 14:53 వరకు",
            Karanamulu: "విష్టి 08:41 వరకు, బవ 21:59 వరకు",
            Masam: "కార్తీక మాసం",
            Nakshatra1: "మఖ 00:35, డిసెంబర్ 05 వరకు",
            MainNakshatra: "మఖ",
            Paksham: "కృష్ణ పక్షము",
            RahuKalam: "07:55 నుండి 09:19 వరకు",
            Ruthuvu: "శరత్ ఋతువు",
            Sunrise: "06:32",
            Sunset: "17:41",
            Thiti: "సప్తమి 21:59 వరకు",
            Yamaganda: "10:43 నుండి 12:06 వరకు",
            Yoga: "వైధృతి 21:48 వరకు",
            Varjyam: "11:06 నుండి 12:53 వరకు",
            Moonrise: "23:48",
            Moonset: "12:03",
            Shaka: "1947 విశ్వావసు",
            Amavasya: "",
            FestDate: "",
            LordImage: "",
          },
        ],
      },
    ],
  },
};
