import React, { useState } from 'react';
import Header from '../header';
import Navbar from '../navbar';
import Footer from '../footer';
import sideGridRightImage from '../../assets/images/sideGridRight.png';
import ItaramuluData from '../../assets/jsons/ItaramuluData';

const Namakaranam = () => {
  const data = ItaramuluData.Namakaranam[0];
  const [leftSticky, setLeftSticky] = useState(true);
  const [rightSticky, setRightSticky] = useState(true);

  return (
    <div>
      <Header />
      <Navbar />
      <div className="container-fluid bgColor">
        <div className="row">
          <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
          <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 p-3"></div>
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 p-3"
            onMouseEnter={() => {
              setLeftSticky(false);
              setRightSticky(true);
            }}
            style={{
              overflowY: leftSticky ? 'hidden' : 'auto',
              height: '80vh',
              position: leftSticky ? 'sticky' : 'static',
              top: 0,
            }}
          >
            <div className="row">
              <table className="table table-bordered rightPanel">
                <thead style={{ backgroundColor: '#B35C0C', color: '#FFFFFF', textAlign: 'center' }}>
                  <tr>
                    <th>నక్షత్రాలు</th>
                    <th>మొదటి అక్షరాలు</th>
                    <th>మొదటి అక్షరాలు</th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: 'center' }}>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.data.NakshatraTel}</td>
                      <td>{item.data.Telugu}</td>
                      <td>{item.data.Eng}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 p-3"></div>
          <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Namakaranam;

