const otherMoreData = {
    "Astotraluall": {
      "CatID": [
        {
          "astotaralu": [
            {
              "title": "ఓం వేంకటేశాయ నమః"
            },
            {
              "title": "ఓం శ్రీనివాసాయ నమః"
            },
            {
              "title": "ఓం లక్ష్మీ పతయే నమః"
            },
            {
              "title": "ఓం అనామయాయ నమః"
            },
            {
              "title": "ఓం అమృతాంశాయ నమః"
            },
            {
              "title": "ఓం జగద్వంద్యాయ నమః"
            },
            {
              "title": "ఓం గోవిందాయ నమః"
            },
            {
              "title": "ఓం శాశ్వతాయ నమః"
            },
            {
              "title": "ఓం ప్రభవే నమః"
            },
            {
              "title": "ఓం శేషాద్రినిలయాయ నమః || 10 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం దేవాయ నమః"
            },
            {
              "title": "ఓం కేశవాయ నమః"
            },
            {
              "title": "ఓం మధుసూదనాయ నమః"
            },
            {
              "title": "ఓం అమృతాయ నమః"
            },
            {
              "title": "ఓం మాధవాయ నమః"
            },
            {
              "title": "ఓం కృష్ణాయ నమః"
            },
            {
              "title": "ఓం శ్రీహరయే నమః"
            },
            {
              "title": "ఓం జ్ఞానపంజరాయ నమః"
            },
            {
              "title": "ఓం శ్రీవత్స వక్షసే నమః"
            },
            {
              "title": "ఓం సర్వేశాయ నమః || 20 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం గోపాలాయ నమః"
            },
            {
              "title": "ఓం పురుషోత్తమాయ నమః"
            },
            {
              "title": "ఓం గోపీశ్వరాయ నమః"
            },
            {
              "title": "ఓం పరంజ్యోతిషే నమః"
            },
            {
              "title": "ఓం వైకుంఠపతయే నమః"
            },
            {
              "title": "ఓం అవ్యయాయ నమః"
            },
            {
              "title": "ఓం సుధాతనవే నమః"
            },
            {
              "title": "ఓం యాదవేంద్రాయ నమః"
            },
            {
              "title": "ఓం నిత్యయౌవనరూపవతే నమః"
            },
            {
              "title": "ఓం చతుర్వేదాత్మకాయ నమః || 30 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం విష్నవే నమః"
            },
            {
              "title": "ఓం అచ్యుతాయ నమః"
            },
            {
              "title": "ఓం పద్మినీప్రియాయ నమః"
            },
            {
              "title": "ఓం ధరావతయే నమః"
            },
            {
              "title": "ఓం సురవతయే నమః"
            },
            {
              "title": "ఓం నిర్మలాయ నమః"
            },
            {
              "title": "ఓం దేవపూజితాయ నమః"
            },
            {
              "title": "ఓం చతుర్భుజాయ నమః"
            },
            {
              "title": "ఓం త్రిధామ్నే నమః"
            },
            {
              "title": "ఓం త్రిగుణాశ్రేయాయ నమః || 40 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం నిర్వికల్పాయ నమః"
            },
            {
              "title": "ఓం నిష్కళంకాయ నమః"
            },
            {
              "title": "ఓం నీరాంతకాయ నమః"
            },
            {
              "title": "ఓం నిరంజనాయ నమః"
            },
            {
              "title": "ఓం నిరాభాసాయ నమః"
            },
            {
              "title": "ఓం సత్యతృప్తాయ నమః"
            },
            {
              "title": "ఓం నిరుపద్రవాయ నమః"
            },
            {
              "title": "ఓం నిర్గుణాయ నమః"
            },
            {
              "title": "ఓం గదాధరాయ నమః"
            },
            {
              "title": "ఓం శార్జగపాణే నమః || 50 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం నందకినే నమః"
            },
            {
              "title": "ఓం శంఖధారకాయ నమః"
            },
            {
              "title": "ఓం అనేకమూర్తయే నమః"
            },
            {
              "title": "ఓం అవ్యక్తాయ నమః"
            },
            {
              "title": "ఓం కటిహస్తాయ నమః"
            },
            {
              "title": "ఓం వరప్రదాయ నమః"
            },
            {
              "title": "ఓం అనేకాత్మనే నమః"
            },
            {
              "title": "ఓం దీనబంధనే నమః"
            },
            {
              "title": "ఓం ఆర్తలోకాభయప్రదాయ నమః"
            },
            {
              "title": "ఓం ఆకాశరాజవరదాయ నమః || 60 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం దామోదరాయ నమః"
            },
            {
              "title": "ఓం కరుణాకరాయ నమః"
            },
            {
              "title": "ఓం జగత్పాలాయపాపఘ్నాయ నమః"
            },
            {
              "title": "ఓం భక్తవత్సలాయ నమః"
            },
            {
              "title": "ఓం త్రివిక్రమాయ నమః"
            },
            {
              "title": "ఓం శింశుమారాయ నమః"
            },
            {
              "title": "ఓం జటామకుటశోభితాయ నమః"
            },
            {
              "title": "ఓం శంఖమధ్యోల్లసన్మంజు నమః"
            },
            {
              "title": "ఓం కింకిణాఢ్యకరండకాయ నమః || 70 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం నీలమేఘశ్యామతనవే నమః"
            },
            {
              "title": "ఓం బిల్వపత్రార్చనప్రియాయ నమః"
            },
            {
              "title": "ఓం జగద్వ్యాపినే నమః"
            },
            {
              "title": "ఓం జగత్కర్త్రే నమః"
            },
            {
              "title": "ఓం జగత్కాక్షిణే నమః"
            },
            {
              "title": "ఓం జగత్పతయే నమః"
            },
            {
              "title": "ఓం చింతితార్థప్రదాయకాయ నమః"
            },
            {
              "title": "ఓం జిష్ణవే నమః"
            },
            {
              "title": "ఓం దశార్హాయ నమః"
            },
            {
              "title": "ఓం దశరూపవతే నమః || 80 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం దేవకీనందనాయ నమః"
            },
            {
              "title": "ఓం శౌరయే నమః"
            },
            {
              "title": "ఓం హయగ్రీవాయ నమః"
            },
            {
              "title": "ఓం జనార్ధనాయ నమః"
            },
            {
              "title": "ఓం కన్యాశ్రవణతారేజ్యాయ నమః"
            },
            {
              "title": "ఓం పీతాంబరధరాయ నమః"
            },
            {
              "title": "ఓం అనఘాయ నమః"
            },
            {
              "title": "ఓం వనమాలినే నమః"
            },
            {
              "title": "ఓం పద్మనాభాయ నమః"
            },
            {
              "title": "ఓం మృగయాస్తమానసాయ నమః || 90 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం ఆశ్వారూఢాయ నమః"
            },
            {
              "title": "ఓం ఖడ్గధారిణే నమః"
            },
            {
              "title": "ఓం ధనార్జనసముత్సుకాయ నమః"
            },
            {
              "title": "ఓం ఘనసారలన్మధ్య నమః"
            },
            {
              "title": "ఓం కస్తూరీతిలకోజ్జ్వలాయ నమః"
            },
            {
              "title": "ఓం సచ్చిదానందరూపాయ నమః"
            },
            {
              "title": "ఓం జగన్మంగళదాయకాయ నమః"
            },
            {
              "title": "ఓం యజ్ఞరూపాయ నమః"
            },
            {
              "title": "ఓం యజ్ఞభోక్త్రే నమః"
            },
            {
              "title": "ఓం చిన్మయాయ నమః || 100 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం పరమేశ్వరాయ నమః"
            },
            {
              "title": "ఓంపరమార్థప్రదాయ నమః"
            },
            {
              "title": "ఓం శాంతాయ నమః"
            },
            {
              "title": "ఓం శ్రీమతే నమః"
            },
            {
              "title": "ఓం దోర్దండవిక్రమాయ నమః"
            },
            {
              "title": "ఓం పరబ్రహ్మణే నమః"
            },
            {
              "title": "ఓం శ్రీవిభవే నమః"
            },
            {
              "title": "ఓం జగదీశ్వరాయ నమః || 108 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఇతి శ్రీ వేంకటేశ్వర అష్టోత్తర శతనామావళి సంపూర్ణం ||"
            }
          ],
          "title": "శ్రీ వేంకటేశ్వర అష్టోత్తరము",
         //  "img": require('../imgs/Astotaram/VenkateswaraSwami.png')
        },
        {
          "astotaralu": [
            {
              "title": "ఓం పార్వత్యై నమః"
            },
            {
              "title": "ఓం మహా దేవ్యై నమః"
            },
            {
              "title": "ఓం జగన్మాత్రే నమః"
            },
            {
              "title": "ఓం సరస్వత్యై నమహ్"
            },
            {
              "title": "ఓం చండికాయై నమః"
            },
            {
              "title": "ఓం లోకజనన్యై నమః"
            },
            {
              "title": "ఓం సర్వదేవాదీ దేవతాయై నమః"
            },
            {
              "title": "ఓం గౌర్యై నమః"
            },
            {
              "title": "ఓం పరమాయై నమః"
            },
            {
              "title": "ఓం ఈశాయై నమః || 10 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం నాగేంద్రతనయాయై నమః"
            },
            {
              "title": "ఓం సత్యై నమః"
            },
            {
              "title": "ఓం బ్రహ్మచారిణ్యై నమః"
            },
            {
              "title": "ఓం శర్వాణ్యై నమః"
            },
            {
              "title": "ఓం దేవమాత్రే నమః"
            },
            {
              "title": "ఓం త్రిలోచన్యై నమః"
            },
            {
              "title": "ఓం బ్రహ్మణ్యై నమః"
            },
            {
              "title": "ఓం వైష్ణవ్యై నమః"
            },
            {
              "title": "ఓం రౌద్ర్యై నమః"
            },
            {
              "title": "ఓం కాళరాత్ర్యై నమః || 20 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం తపస్విన్యై నమః"
            },
            {
              "title": "ఓం శివదూత్యై నమః"
            },
            {
              "title": "ఓం విశాలాక్ష్యై నమః"
            },
            {
              "title": "ఓం చాముండాయై నమః"
            },
            {
              "title": "ఓం విష్ణుసోదరయ్యై నమః"
            },
            {
              "title": "ఓం చిత్కళాయై నమః"
            },
            {
              "title": "ఓం చిన్మయాకారాయై నమః"
            },
            {
              "title": "ఓం మహిషాసురమర్దిన్యై నమః"
            },
            {
              "title": "ఓం కాత్యాయిన్యై నమః"
            },
            {
              "title": "ఓం కాలరూపాయై నమః || 30 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం గిరిజాయై నమః"
            },
            {
              "title": "ఓం మేనకాత్మజాయై నమః"
            },
            {
              "title": "ఓం భవాన్యై నమః"
            },
            {
              "title": "ఓం మాతృకాయై నమః"
            },
            {
              "title": "ఓం శ్రీమాత్రేనమః"
            },
            {
              "title": "ఓం మహాగౌర్యై నమః"
            },
            {
              "title": "ఓం రామాయై నమః"
            },
            {
              "title": "ఓం శుచిస్మితాయై నమః"
            },
            {
              "title": "ఓం బ్రహ్మస్వరూపిణ్యై నమః"
            },
            {
              "title": "ఓం రాజ్యలక్ష్మ్యై నమః || 40 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం శివప్రియాయై నమః"
            },
            {
              "title": "ఓం నారాయణ్యై నమః"
            },
            {
              "title": "ఓం మాహాశక్త్యై నమః"
            },
            {
              "title": "ఓం నవోఢాయై నమః"
            },
            {
              "title": "ఓం భగ్యదాయిన్యై నమః"
            },
            {
              "title": "ఓం అన్నపూర్ణాయై నమః"
            },
            {
              "title": "ఓం సదానందాయై నమః"
            },
            {
              "title": "ఓం యౌవనాయై నమః"
            },
            {
              "title": "ఓం మోహిన్యై నమః"
            },
            {
              "title": "ఓం అజ్ఞానశుధ్యై నమః || 50 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం జ్ఞానగమ్యాయై నమః"
            },
            {
              "title": "ఓం నిత్యాయై నమః"
            },
            {
              "title": "ఓం నిత్యస్వరూపిణ్యై నమః"
            },
            {
              "title": "ఓం పుష్పాకారాయై నమః"
            },
            {
              "title": "ఓం పురుషార్ధప్రదాయిన్యై నమః"
            },
            {
              "title": "ఓం మహారూపాయై నమః"
            },
            {
              "title": "ఓం మహారౌద్ర్యై నమః"
            },
            {
              "title": "ఓం కామాక్ష్యై నమః"
            },
            {
              "title": "ఓం వామదేవ్యై నమః"
            },
            {
              "title": "ఓం వరదాయై నమః || 60 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం భయనాశిన్యై నమః"
            },
            {
              "title": "ఓం వాగ్దేవ్యై నమః"
            },
            {
              "title": "ఓం వచన్యై నమః"
            },
            {
              "title": "ఓం వారాహ్యై నమః"
            },
            {
              "title": "ఓం విశ్వతోషిన్యై నమః"
            },
            {
              "title": "ఓం వర్ధనీయాయై నమః"
            },
            {
              "title": "ఓం విశాలాక్షాయై నమః"
            },
            {
              "title": "ఓం కులసంపత్ప్రదాయిన్యై నమః"
            },
            {
              "title": "ఓం ఆర్ధదుఃఖచ్చేద దక్షాయై నమః"
            },
            {
              "title": "ఓం విశ్వజనన్యై నమః || 70 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం అంబాయై నమః"
            },
            {
              "title": "ఓం కమలాయై నమః"
            },
            {
              "title": "ఓం కమలాకారయై నమః"
            },
            {
              "title": "ఓం రక్తవర్ణాయై నమః"
            },
            {
              "title": "ఓం కళానిధయై నమః"
            },
            {
              "title": "ఓం మధుప్రియాయై నమః"
            },
            {
              "title": "ఓం కళ్యాణ్యై నమః"
            },
            {
              "title": "ఓం కరుణాయై నమః"
            },
            {
              "title": "ఓం జనస్ధానాయై నమః"
            },
            {
              "title": "ఓం వీరపత్న్యై నమః || 80 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం విరూపాక్ష్యై నమః"
            },
            {
              "title": "ఓం వీరాధితాయై నమః"
            },
            {
              "title": "ఓం హేమాభాసాయై నమః"
            },
            {
              "title": "ఓం సృష్టిరూపాయై నమః"
            },
            {
              "title": "ఓం సృష్టిసంహారకారిణ్యై నమః"
            },
            {
              "title": "ఓం రంజనాయై నమః"
            },
            {
              "title": "ఓం యౌవనాకారాయై నమః"
            },
            {
              "title": "ఓం పరమేశప్రియాయై నమః"
            },
            {
              "title": "ఓం పరాయై నమః"
            },
            {
              "title": "ఓం పుష్పిణ్యై నమః || 90 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం సదాపురస్థాయిన్యై నమః"
            },
            {
              "title": "ఓం తరోర్మూలతలంగతాయై నమః"
            },
            {
              "title": "ఓం హరవాహసమాయుక్తయై నమః"
            },
            {
              "title": "ఓం మోక్షపరాయణాయై నమః"
            },
            {
              "title": "ఓం ధరాధరభవాయై నమః"
            },
            {
              "title": "ఓం ముక్తాయై నమః"
            },
            {
              "title": "ఓం వరమంత్రాయై నమః"
            },
            {
              "title": "ఓం కరప్రదాయై నమః"
            },
            {
              "title": "ఓం వాగ్భవ్యై నమః"
            },
            {
              "title": "ఓం దేవ్యై నమః || 100 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం క్లీం కారిణ్యై నమః"
            },
            {
              "title": "ఓం సంవిదే నమః"
            },
            {
              "title": "ఓం ఈశ్వర్యై నమః"
            },
            {
              "title": "ఓం హ్రీంకారబీజాయై నమః"
            },
            {
              "title": "ఓం శాంభవ్యై నమః"
            },
            {
              "title": "ఓం ప్రణవాత్మికాయై నమః"
            },
            {
              "title": "ఓం శ్రీ మహాగౌర్యై నమః"
            },
            {
              "title": "ఓం శుభప్రదాయై నమః || 108 ||"
            },
            {
              "title": "ఇతి శ్రీ పార్వతీ అష్టోత్తర శతనామావళి సంపూర్ణం ||"
            }
          ],
          "title": "పార్వతీ అష్టోత్తరము",
         //  "img": require('../imgs/Astotaram/Parvathimatha.png')
        },
        {
          "astotaralu": [
            {
              "title": "ఓం ప్రకృత్యై నమః"
            },
            {
              "title": "ఓం వికృత్యై నమః"
            },
            {
              "title": "ఓం విద్యాయై నమః"
            },
            {
              "title": "ఓం సర్వభూతహితప్రదాయై నమః"
            },
            {
              "title": "ఓం శ్రద్ధాయై నమః"
            },
            {
              "title": "ఓం విభూత్యై నమః"
            },
            {
              "title": "ఓం సురభ్యై నమః"
            },
            {
              "title": "ఓం పరమాత్మికాయై నమః"
            },
            {
              "title": "ఓం వాచే నమః"
            },
            {
              "title": "ఓం పద్మాలయాయై నమః || 10 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం పద్మాయై నమః"
            },
            {
              "title": "ఓం శుచ్యై నమః"
            },
            {
              "title": "ఓం స్వాహాయై నమః"
            },
            {
              "title": "ఓం స్వధాయై నమః"
            },
            {
              "title": "ఓం సుధాయై నమః"
            },
            {
              "title": "ఓం ధన్యాయై నమః"
            },
            {
              "title": "ఓం హిరణ్మయ్యై నమః"
            },
            {
              "title": "ఓం లక్ష్మ్యై నమః"
            },
            {
              "title": "ఓం నిత్యపుష్టాయై నమః"
            },
            {
              "title": "ఓం విభావర్యై నమః || 20 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం అదిత్యై నమః"
            },
            {
              "title": "ఓం దిత్యై నమః"
            },
            {
              "title": "ఓం దీప్తాయై నమః"
            },
            {
              "title": "ఓం వసుధాయై నమః"
            },
            {
              "title": "ఓం కమలాయై నమః"
            },
            {
              "title": "ఓం కాంతాయై నమః"
            },
            {
              "title": "ఓం కామాక్ష్యై నమః"
            },
            {
              "title": "ఓం క్రోధసంభవాయై నమః"
            },
            {
              "title": "ఓం అనుగ్రహపరాయై నమః || 30 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం ఋద్ధయే నమః"
            },
            {
              "title": "ఓం అనఘాయై నమః"
            },
            {
              "title": "ఓం హరివల్లభాయై నమః"
            },
            {
              "title": "ఓం అశోకాయై నమః"
            },
            {
              "title": "ఓం అమృతాయై నమః"
            },
            {
              "title": "ఓం దీప్తాయై నమః"
            },
            {
              "title": "ఓం లోకశోక వినాశిన్యై నమః"
            },
            {
              "title": "ఓం ధర్మనిలయాయై నమః"
            },
            {
              "title": "ఓం కరుణాయై నమః"
            },
            {
              "title": "ఓం లోకమాత్రే నమః || 40 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం పద్మప్రియాయై నమః"
            },
            {
              "title": "ఓం పద్మహస్తాయై నమః"
            },
            {
              "title": "ఓం పద్మాక్ష్యై నమః"
            },
            {
              "title": "ఓం పద్మసుందర్యై నమః"
            },
            {
              "title": "ఓం పద్మోద్భవాయై నమః"
            },
            {
              "title": "ఓం పద్మముఖ్యై నమః"
            },
            {
              "title": "ఓం పద్మనాభప్రియాయై నమః"
            },
            {
              "title": "ఓం రమాయై నమః"
            },
            {
              "title": "ఓం పద్మమాలాధరాయై నమః"
            },
            {
              "title": "ఓం దేవ్యై నమః || 50 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం పద్మిన్యై నమః"
            },
            {
              "title": "ఓం పద్మగంథిన్యై నమః"
            },
            {
              "title": "ఓం పుణ్యగంధాయై నమః"
            },
            {
              "title": "ఓం సుప్రసన్నాయై నమః"
            },
            {
              "title": "ఓం ప్రసాదాభిముఖ్యై నమః"
            },
            {
              "title": "ఓం ప్రభాయై నమః"
            },
            {
              "title": "ఓం చంద్రవదనాయై నమః"
            },
            {
              "title": "ఓం చంద్రాయై నమః"
            },
            {
              "title": "ఓం చంద్రసహోదర్యై నమః"
            },
            {
              "title": "ఓం చతుర్భుజాయై నమః || 60 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం చంద్రరూపాయై నమః"
            },
            {
              "title": "ఓం ఇందిరాయై నమః"
            },
            {
              "title": "ఓం ఇందుశీతులాయై నమః"
            },
            {
              "title": "ఓం ఆహ్లోదజనన్యై నమః"
            },
            {
              "title": "ఓం పుష్ట్యై నమః"
            },
            {
              "title": "ఓం శివాయై నమః"
            },
            {
              "title": "ఓం శివకర్యై నమః"
            },
            {
              "title": "ఓం సత్యై నమః"
            },
            {
              "title": "ఓం విమలాయై నమః"
            },
            {
              "title": "ఓం విశ్వజనన్యై నమః || 70 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం తుష్ట్యై నమః"
            },
            {
              "title": "ఓం దారిద్ర్య నాశిన్యై నమః"
            },
            {
              "title": "ఓం ప్రీతిపుష్కరిణ్యై నమః"
            },
            {
              "title": "ఓం శాంతాయై నమః"
            },
            {
              "title": "ఓం శుక్లమాల్యాంబరాయై నమః"
            },
            {
              "title": "ఓం శ్రియై నమః"
            },
            {
              "title": "ఓం భాస్కర్యై నమః"
            },
            {
              "title": "ఓం బిల్వనిలయాయై నమః"
            },
            {
              "title": "ఓం వరారోహాయై నమః"
            },
            {
              "title": "ఓం యశస్విన్యై నమః || 80 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం వసుంధరాయై నమః"
            },
            {
              "title": "ఓం ఉదారాంగాయై నమః"
            },
            {
              "title": "ఓం హరిణ్యై నమః"
            },
            {
              "title": "ఓం హేమమాలిన్యై నమః"
            },
            {
              "title": "ఓం ధనధాన్య కర్యై నమః"
            },
            {
              "title": "ఓం సిద్ధయే నమః"
            },
            {
              "title": "ఓం స్త్రైణ సౌమ్యాయై నమః"
            },
            {
              "title": "ఓం శుభప్రదాయై నమః"
            },
            {
              "title": "ఓం నృపవేశ్మ గతానందాయై నమః"
            },
            {
              "title": "ఓం వరలక్ష్మ్యై నమః || 90 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం వసుప్రదాయై నమః"
            },
            {
              "title": "ఓం శుభాయై నమః"
            },
            {
              "title": "ఓం హిరణ్యప్రాకారాయై నమః"
            },
            {
              "title": "ఓం సముద్ర తనయాయై నమః"
            },
            {
              "title": "ఓం జయాయై నమః"
            },
            {
              "title": "ఓం మంగళాయై నమః"
            },
            {
              "title": "ఓం దేవ్యై నమః"
            },
            {
              "title": "ఓం విష్ణు వక్షఃస్థల స్థితాయై నమః"
            },
            {
              "title": "ఓం విష్ణుపత్న్యై నమః"
            },
            {
              "title": "ఓం ప్రసన్నాక్ష్యై నమః || 100 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం నారాయణ సమాశ్రితాయై నమః"
            },
            {
              "title": "ఓం దారిద్ర్య ధ్వంసిన్యై నమః"
            },
            {
              "title": "ఓం సర్వోపద్రవ వారిణ్యై నమః"
            },
            {
              "title": "ఓం నవదుర్గాయై నమః"
            },
            {
              "title": "ఓం మహాకాళ్యై నమః"
            },
            {
              "title": "ఓం బ్రహ్మ విష్ణు శివాత్మికాయై నమః"
            },
            {
              "title": "ఓం త్రికాల జ్ఞాన సంపన్నాయై నమః"
            },
            {
              "title": "ఓం భువనేశ్వర్యై నమః || 108 ||"
            },
            {
              "title": "ఇతి శ్రీ లక్ష్మీ అష్టోత్తర శతనామావళి సంపూర్ణం ||"
            }
  
          ],
          "title": "లక్ష్మీ అష్టోత్తరము",
         //  "img": require('../imgs/Astotaram/LakshmiDevi.png')
        },
        {
          "astotaralu": [
            {
              "title": "ఓం స్కందాయ నమః"
            },
            {
              "title": "ఓం గుహాయ నమః"
            },
            {
              "title": "ఓం షణ్ముఖాయ నమః"
            },
            {
              "title": "ఓం ఫాలనేత్రసుతాయ నమః"
            },
            {
              "title": "ఓం ప్రభవే నమః"
            },
            {
              "title": "ఓం పింగళాయ నమః"
            },
            {
              "title": "ఓం కృత్తికాసూనవే నమః"
            },
            {
              "title": "ఓం శిఖివాహాయ నమః"
            },
            {
              "title": "ఓం ద్విషడ్భుజాయ నమః"
            },
            {
              "title": "ఓం ద్విషణ్ణేత్రాయ నమః || 10 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం శక్తిధరాయ నమః"
            },
            {
              "title": "ఓం పిశితాశ ప్రభంజనాయ నమః"
            },
            {
              "title": "ఓం తారకాసుర సంహారిణే నమః"
            },
            {
              "title": "ఓం రక్షోబలవిమర్దనాయ నమః"
            },
            {
              "title": "ఓం మత్తాయ నమః"
            },
            {
              "title": "ఓం ప్రమత్తాయ నమః"
            },
            {
              "title": "ఓం ఉన్మత్తాయ నమః"
            },
            {
              "title": "ఓం సురసైన్య సురక్షకాయ నమః"
            },
            {
              "title": "ఓం దేవసేనాపతయే నమః"
            },
            {
              "title": "ఓం ప్రాజ్ఞాయ నమః || 20 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం కృపాళవే నమః"
            },
            {
              "title": "ఓం భక్తవత్సలాయ నమః"
            },
            {
              "title": "ఓం ఉమాసుతాయ నమః"
            },
            {
              "title": "ఓం శక్తిధరాయ నమః"
            },
            {
              "title": "ఓం కుమారాయ నమః"
            },
            {
              "title": "ఓం క్రౌంచదారణాయ నమః"
            },
            {
              "title": "ఓం సేనాన్యే నమః"
            },
            {
              "title": "ఓం అగ్నిజన్మనే నమః"
            },
            {
              "title": "ఓం విశాఖాయ నమః"
            },
            {
              "title": "ఓం శంకరాత్మజాయ నమః || 30 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం శివస్వామినే నమః"
            },
            {
              "title": "ఓం గణ స్వామినే నమః"
            },
            {
              "title": "ఓం సర్వస్వామినే నమః"
            },
            {
              "title": "ఓం సనాతనాయ నమః"
            },
            {
              "title": "ఓం అనంతశక్తయే నమః"
            },
            {
              "title": "ఓం అక్షోభ్యాయ నమః"
            },
            {
              "title": "ఓం పార్వతీప్రియనందనాయ నమః"
            },
            {
              "title": "ఓం గంగాసుతాయ నమః"
            },
            {
              "title": "ఓం శరోద్భూతాయ నమః"
            },
            {
              "title": "ఓం ఆహూతాయ నమః || 40 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం పావకాత్మజాయ నమః"
            },
            {
              "title": "ఓం జృంభాయ నమః"
            },
            {
              "title": "ఓం ప్రజృంభాయ నమః"
            },
            {
              "title": "ఓం ఉజ్జృంభాయ నమః"
            },
            {
              "title": "ఓం కమలాసన సంస్తుతాయ నమః"
            },
            {
              "title": "ఓం ఏకవర్ణాయ నమః"
            },
            {
              "title": "ఓం ద్వివర్ణాయ నమః"
            },
            {
              "title": "ఓం త్రివర్ణాయ నమః"
            },
            {
              "title": "ఓం సుమనోహరాయ నమః"
            },
            {
              "title": "ఓం చతుర్వర్ణాయ నమః || 50 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం పంచవర్ణాయ నమః"
            },
            {
              "title": "ఓం ప్రజాపతయే నమః"
            },
            {
              "title": "ఓం అహస్పతయే నమః"
            },
            {
              "title": "ఓం అగ్నిగర్భాయ నమః"
            },
            {
              "title": "ఓం శమీగర్భాయ నమః"
            },
            {
              "title": "ఓం విశ్వరేతసే నమః"
            },
            {
              "title": "ఓం సురారిఘ్నే నమః"
            },
            {
              "title": "ఓం హరిద్వర్ణాయ నమః"
            },
            {
              "title": "ఓం శుభకరాయ నమః"
            },
            {
              "title": "ఓం పటవే నమః || 60 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం వటువేషభృతే నమః"
            },
            {
              "title": "ఓం పూష్ణే నమః"
            },
            {
              "title": "ఓం గభస్తయే నమః"
            },
            {
              "title": "ఓం గహనాయ నమః"
            },
            {
              "title": "ఓం చంద్రవర్ణాయ నమః"
            },
            {
              "title": "ఓం కళాధరాయ నమః"
            },
            {
              "title": "ఓం మాయాధరాయ నమః"
            },
            {
              "title": "ఓం మహామాయినే నమః"
            },
            {
              "title": "ఓం కైవల్యాయ నమః"
            },
            {
              "title": "ఓం శంకరాత్మజాయ నమః || 70 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం విశ్వయోనయే నమః"
            },
            {
              "title": "ఓం అమేయాత్మనే నమః"
            },
            {
              "title": "ఓం తేజోనిధయే నమః"
            },
            {
              "title": "ఓం అనామయాయ నమః"
            },
            {
              "title": "ఓం పరమేష్ఠినే నమః"
            },
            {
              "title": "ఓం పరస్మై బ్రహ్మణే నమః"
            },
            {
              "title": "ఓం వేదగర్భాయ నమః"
            },
            {
              "title": "ఓం విరాట్సుతాయ నమః"
            },
            {
              "title": "ఓం పుళిందకన్యాభర్త్రే నమః"
            },
            {
              "title": "ఓం మహాసారస్వతావృతాయ నమః || 80 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం ఆశ్రితాఖిలదాత్రే నమః"
            },
            {
              "title": "ఓం చోరఘ్నాయ నమః"
            },
            {
              "title": "ఓం రోగనాశనాయ నమః"
            },
            {
              "title": "ఓం అనంతమూర్తయే నమః"
            },
            {
              "title": "ఓం ఆనందాయ నమః"
            },
            {
              "title": "ఓం శిఖిండికృత కేతనాయ నమః"
            },
            {
              "title": "ఓం డంభాయ నమః"
            },
            {
              "title": "ఓం పరమడంభాయ నమః"
            },
            {
              "title": "ఓం మహాడంభాయ నమః"
            },
            {
              "title": "ఓం వృషాకపయే నమః || 90 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం కారణోపాత్తదేహాయ నమః"
            },
            {
              "title": "ఓం కారణాతీతవిగ్రహాయ నమః"
            },
            {
              "title": "ఓం అనీశ్వరాయ నమః"
            },
            {
              "title": "ఓం అమృతాయ నమః"
            },
            {
              "title": "ఓం ప్రాణాయ నమః"
            },
            {
              "title": "ఓం ప్రాణాయామపరాయణాయ నమః"
            },
            {
              "title": "ఓం విరుద్ధహంత్రే నమః"
            },
            {
              "title": "ఓం వీరఘ్నాయ నమః"
            },
            {
              "title": "ఓం రక్తశ్యామగళాయ నమః"
            },
            {
              "title": "ఓం సుబ్రహ్మణ్యాయ నమః || 100 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం గుహాయ నమః"
            },
            {
              "title": "ఓం ప్రీతాయ నమః"
            },
            {
              "title": "ఓం బ్రాహ్మణ్యాయ నమః"
            },
            {
              "title": "ఓం బ్రాహ్మణప్రియాయ నమః"
            },
            {
              "title": "ఓం వంశవృద్ధికరాయ నమః"
            },
            {
              "title": "ఓం వేదాయ నమః"
            },
            {
              "title": "ఓం వేద్యాయ నమః"
            },
            {
              "title": "ఓం అక్షయఫలప్రదాయ నమః || 108 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఇతి శ్రీ సుబ్రహ్మణ్య అష్టోత్తర శతనామావళి సంపూర్ణం ||"
            }
          ],
          "title": "సుబ్రహ్మణ్య అష్టోత్తరము",
         //  "img": require('../imgs/Astotaram/Subramanyneswaraswami.png')
        },
        {
          "astotaralu": [
            {
              "title": "ఓం శ్రీరామాయ నమః"
            },
            {
              "title": "ఓం రామభద్రాయ నమః"
            },
            {
              "title": "ఓం రామచంద్రాయ నమః"
            },
            {
              "title": "ఓం శాశ్వతాయ నమః"
            },
            {
              "title": "ఓం రాజీవలోచనాయ నమః"
            },
            {
              "title": "ఓం శ్రీమతే నమః"
            },
            {
              "title": "ఓం రాజేంద్రాయ నమః"
            },
            {
              "title": "ఓం రఘుపుంగవాయ నమః"
            },
            {
              "title": "ఓం జానకీవల్లభాయ నమః"
            },
            {
              "title": "ఓం జైత్రాయ నమః || 10 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం జితామిత్రాయ నమః"
            },
            {
              "title": "ఓం జనార్ధనాయ నమః"
            },
            {
              "title": "ఓం విశ్వామిత్రప్రియాయ నమః"
            },
            {
              "title": "ఓం దాంతాయ నమః"
            },
            {
              "title": "ఓం శరణత్రాణతత్పరాయ నమః"
            },
            {
              "title": "ఓం వాలిప్రమాధనయ నమః"
            },
            {
              "title": "ఓం వాగ్మినే నమః"
            },
            {
              "title": "ఓం సత్యవాచే నమః"
            },
            {
              "title": "ఓం సత్యవిక్రమాయ నమః"
            },
            {
              "title": "ఓం వ్రతధరాయ నమః || 20 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం సదాహనుమదాశ్రితాయ నమః"
            },
            {
              "title": "ఓం కొసలేయాయ నమః"
            },
            {
              "title": "ఓం ఖరధ్వంసినే నమః"
            },
            {
              "title": "ఓం విరాధవధపందితాయ నమః"
            },
            {
              "title": "ఓం విభీషణ పరిత్రాత్రే నమః"
            },
            {
              "title": "ఓం హారకోదండఖండనాయ నమః"
            },
            {
              "title": "ఓం సప్తతాళభేత్రె నమః"
            },
            {
              "title": "ఓం దశగ్రీవశిరోహరాయ నమః"
            },
            {
              "title": "ఓం జామదగ్న్యమహాదర్ప నమః"
            },
            {
              "title": "ఓం దశనాయ నమః || 30 || "
            },
            {
              "title": ""
            },
            {
              "title": "ఓం తాటకాంతకాయ నమః"
            },
            {
              "title": "ఓం వేదాంతసారాయ నమః"
            },
            {
              "title": "ఓం వేదాత్మనే నమః"
            },
            {
              "title": "ఓం భవరోగస్యభేషజాయ నమః"
            },
            {
              "title": "ఓం దూషణత్రిశిరోహంత్రే నమః"
            },
            {
              "title": "ఓం త్రిమూర్తయే నమః"
            },
            {
              "title": "ఓం త్రిగుణాత్మకాయ నమః"
            },
            {
              "title": "ఓం త్రివిక్రమాయ నమః"
            },
            {
              "title": "ఓం త్రిలోకాత్మనే నమః"
            },
            {
              "title": "ఓం పుణ్యచారిత్రకీర్తనాయ నమః || 40 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం త్రిలోకరక్షకాయ నమః"
            },
            {
              "title": "ఓం ధన్వినే నమః"
            },
            {
              "title": "ఓం దండకారుణ్యవర్తనాయ నమః"
            },
            {
              "title": "ఓం అహల్యాశాపశమనాయ నమః"
            },
            {
              "title": "ఓం పితృభక్తాయ నమః"
            },
            {
              "title": "ఓం వరప్రదాయ నమః"
            },
            {
              "title": "ఓం జితేంద్రియాయ నమః"
            },
            {
              "title": "ఓం జితక్రోధాయ నమః"
            },
            {
              "title": "ఓం జితామిత్రాయ నమః"
            },
            {
              "title": "ఓం జగద్గురవే నమః || 50 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం ఋక్షవానరసంఘాతివే నమః"
            },
            {
              "title": "ఓం చిత్రకూటసముశ్రాయాయ నమః"
            },
            {
              "title": "ఓం జయంతత్రాణవరదాయ నమః"
            },
            {
              "title": "ఓం సుమిత్రాపుత్రసేవితాయ నమః"
            },
            {
              "title": "ఓం సర్వదేవాదిదేవాయ నమః"
            },
            {
              "title": "ఓం మృతవానరజీవనాయ నమః"
            },
            {
              "title": "ఓం మాయామారీచహంత్రే నమః"
            },
            {
              "title": "ఓం మహాదేవాయ నమః"
            },
            {
              "title": "ఓం మహాభుజాయ నమః"
            },
            {
              "title": "ఓం సర్వదేవస్తుత్యాయ నమః || 60 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం సౌమ్యాయ నమః"
            },
            {
              "title": "ఓం బ్రహ్మణ్యాయ నమః"
            },
            {
              "title": "ఓం మునిసంస్తుతాయ నమః"
            },
            {
              "title": "ఓం మహాయోగినే నమః"
            },
            {
              "title": "ఓం మహోదయ నమః"
            },
            {
              "title": "ఓం సుగ్రీవేప్సితరాజ్యదాయ నమః"
            },
            {
              "title": "ఓం సర్వపుణ్యాధికఫలాయ నమః"
            },
            {
              "title": "ఓం స్మృతసర్వాఘనాశనాయ నమః"
            },
            {
              "title": "ఓం ఆదిపురుషాయ నమః"
            },
            {
              "title": "ఓం పరమపురుషాయ నమః || 70 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం మహాపురుషాయ నమః"
            },
            {
              "title": "ఓం పుణ్యోదయాయ నమః"
            },
            {
              "title": "ఓం దయాసారాయ నమః"
            },
            {
              "title": "ఓం పురాణపురుషోత్తమాయ నమః"
            },
            {
              "title": "ఓం స్మితవక్త్రాయ నమః"
            },
            {
              "title": "ఓం మితభాషిణే నమః"
            },
            {
              "title": "ఓం పుర్వభాషిణే నమః"
            },
            {
              "title": "ఓం రాఘవాయ నమః"
            },
            {
              "title": "ఓం అనంతగుణగంబీరాయ నమః"
            },
            {
              "title": "ఓం ధీరోదాత్తగుణోత్తమాయ నమః || 80 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం మాయామానుషచారిత్రాయ నమః"
            },
            {
              "title": "ఓం మహాదేవాదిపూజితాయ నమః"
            },
            {
              "title": "ఓం సేతుకృతే నమః"
            },
            {
              "title": "ఓం జితవారాశయే నమః"
            },
            {
              "title": "ఓం సర్వాతీర్ధమయాయ నమః"
            },
            {
              "title": "ఓం హరయే నమః"
            },
            {
              "title": "ఓం శ్యామాంగాయ నమః"
            },
            {
              "title": "ఓం సుందరాయ నమః"
            },
            {
              "title": "ఓం శూరాయ నమః"
            },
            {
              "title": "ఓం పీతవాసనే నమః || 90 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం ధనుర్ధరాయ నమః"
            },
            {
              "title": "ఓం సర్వయజ్నాధిపాయ నమః"
            },
            {
              "title": "ఓం యజ్వినే నమః"
            },
            {
              "title": "ఓం జరామరణవర్జితాయ నమః"
            },
            {
              "title": "ఓం విభీషణప్రతిష్టాత్రీ నమః"
            },
            {
              "title": "ఓం సర్వావగుణవర్జితాయ నమః"
            },
            {
              "title": "ఓం పరమాత్మినే నమః"
            },
            {
              "title": "ఓం పరస్మై నమః"
            },
            {
              "title": "ఓం బ్రహ్మణే నమః"
            },
            {
              "title": "ఓం సచ్చిదానందవిగ్రహాయ నమః || 100 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం పరస్మైజ్యోతిషే నమః"
            },
            {
              "title": "ఓం పరస్మైధామ్నే నమః"
            },
            {
              "title": "ఓం పరాకాశాయ నమః"
            },
            {
              "title": "ఓం పరాత్పరాయ నమః"
            },
            {
              "title": "ఓం పరేశాయ నమః"
            },
            {
              "title": "ఓం పారాయ నమః"
            },
            {
              "title": "ఓం సర్వదేవాత్మకాయ నమః"
            },
            {
              "title": "ఓం పరస్మై నమః || 108 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఇతి శ్రీ రామ అష్టోత్తర శతనామావళి సంపూర్ణం"
            }
          ],
          "title": "శ్రీ రామచంద్ర మూర్తి అష్టోత్తరము",
         //  "img": require('../imgs/Astotaram/Rama.png')
        },
        {
          "astotaralu": [
            {
              "title": "ఓం గజాననాయ నమః"
            },
            {
              "title": "ఓం గణాధ్యక్షాయ నమః"
            },
            {
              "title": "ఓం విఘ్నరాజాయ నమః"
            },
            {
              "title": "ఓం విఘ్నేశ్వరాయ నమః"
            },
            {
              "title": "ఓం ద్వైమాతురాయ నమః"
            },
            {
              "title": "ఓం ద్విముఖాయ నమః"
            },
            {
              "title": "ఓం ప్రముఖాయ నమః"
            },
            {
              "title": "ఓం సుముఖాయ నమః"
            },
            {
              "title": "ఓం కృతినే నమః"
            },
            {
              "title": "ఓం సుప్రదీప్తాయ నమః || 10 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం సుఖనిధయే నమః"
            },
            {
              "title": "ఓం సురాధ్యక్షాయ నమః"
            },
            {
              "title": "ఓం సురారిఘ్నాయ నమః"
            },
            {
              "title": "ఓం మహాగణపతయే నమః"
            },
            {
              "title": "ఓం మాన్యాయ నమః"
            },
            {
              "title": "ఓం మహాకాలాయ నమః"
            },
            {
              "title": "ఓం మహాబలాయ నమః"
            },
            {
              "title": "ఓం హేరంబాయ నమః"
            },
            {
              "title": "ఓం లంబజఠరాయ నమః"
            },
            {
              "title": "ఓం హ్రస్వ గ్రీవాయ నమః || 20 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం ప్రథమాయ నమః"
            },
            {
              "title": "ఓం ప్రాజ్ఞాయ నమః"
            },
            {
              "title": "ఓం ప్రమోదాయ నమః"
            },
            {
              "title": "ఓం మోదకప్రియాయ నమః"
            },
            {
              "title": "ఓం విఘ్నకర్త్రే నమః"
            },
            {
              "title": "ఓం విఘ్నహంత్రే నమః"
            },
            {
              "title": "ఓం విశ్వనేత్రే నమః"
            },
            {
              "title": "ఓం విరాట్పతయే నమః"
            },
            {
              "title": "ఓం శ్రీపతయే నమః"
            },
            {
              "title": "ఓం వాక్పతయే నమః || 30 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం శృంగారిణే నమః"
            },
            {
              "title": "ఓం ఆశ్రిత వత్సలాయ నమః"
            },
            {
              "title": "ఓం శివప్రియాయ నమః"
            },
            {
              "title": "ఓం శీఘ్రకారిణే నమః"
            },
            {
              "title": "ఓం శాశ్వతాయ నమః"
            },
            {
              "title": "ఓం బల్వాన్వితాయ నమః"
            },
            {
              "title": "ఓం బలోద్దతాయ నమః"
            },
            {
              "title": "ఓం భక్తనిధయే నమః"
            },
            {
              "title": "ఓం భావగమ్యాయ నమః"
            },
            {
              "title": "ఓం భావాత్మజాయ నమః || 40 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం అగ్రగామినే నమః"
            },
            {
              "title": "ఓం మంత్రకృతే నమః"
            },
            {
              "title": "ఓం చామీకర ప్రభాయ నమః"
            },
            {
              "title": "ఓం సర్వాయ నమః"
            },
            {
              "title": "ఓం సర్వోపాస్యాయ నమః"
            },
            {
              "title": "ఓం సర్వకర్త్రే నమః"
            },
            {
              "title": "ఓం సర్వనేత్రే నమః"
            },
            {
              "title": "ఓం సర్వసిద్ధిప్రదాయ నమః"
            },
            {
              "title": "ఓం సర్వసిద్ధయే నమః"
            },
            {
              "title": "ఓం పంచహస్తాయ నమః || 50 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం పార్వతీనందనాయ నమః"
            },
            {
              "title": "ఓం ప్రభవే నమః"
            },
            {
              "title": "ఓం కుమారగురవే నమః"
            },
            {
              "title": "ఓం కుంజరాసురభంజనాయ నమః"
            },
            {
              "title": "ఓం కాంతిమతే నమః"
            },
            {
              "title": "ఓం ధృతిమతే నమః"
            },
            {
              "title": "ఓం కామినే నమః"
            },
            {
              "title": "ఓం కపిత్థఫలప్రియాయ నమః"
            },
            {
              "title": "ఓం బ్రహ్మ చారిణే నమః"
            },
            {
              "title": "ఓం బ్రహ్మరూపిణే నమః || 60 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం మహోదరాయ నమః"
            },
            {
              "title": "ఓం మదోత్కటాయ నమః"
            },
            {
              "title": "ఓం మహావీరాయ నమః"
            },
            {
              "title": "ఓం మంత్రిణే నమః"
            },
            {
              "title": "ఓం మంగళసుస్వరాయ నమః"
            },
            {
              "title": "ఓం ప్రమదాయ నమః"
            },
            {
              "title": "ఓం జ్యాయసే నమః"
            },
            {
              "title": "ఓం యక్షకిన్నర సేవితాయ నమః"
            },
            {
              "title": "ఓం గంగాసుతాయ నమః"
            },
            {
              "title": "ఓం గణాధీశాయ నమః || 70 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం గంభీరనినదాయ నమః"
            },
            {
              "title": "ఓం వటవే నమః"
            },
            {
              "title": "ఓం పరస్మే నమః"
            },
            {
              "title": "ఓం జ్యోతిషే నమః"
            },
            {
              "title": "ఓం ఆక్రాంతపదచిత్ప్రభవే నమః"
            },
            {
              "title": "ఓం అభీష్టవరదాయ నమః"
            },
            {
              "title": "ఓం మంగళప్రదాయ నమః"
            },
            {
              "title": "ఓం అవ్యక్త రూపాయ నమః"
            },
            {
              "title": "ఓం పురాణపురుషాయ నమః"
            },
            {
              "title": "ఓం పూష్ణే నమః || 80 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం పుష్కరోత్షిప్త వారణాయ నమః"
            },
            {
              "title": "ఓం అగ్రగణ్యాయ నమః"
            },
            {
              "title": "ఓం అగ్రపూజ్యాయ నమః"
            },
            {
              "title": "ఓం అపాకృతపరాక్రమాయ నమః"
            },
            {
              "title": "ఓం సత్యధర్మిణే నమః"
            },
            {
              "title": "ఓం సఖ్యై నమః"
            },
            {
              "title": "ఓం సారాయ నమః"
            },
            {
              "title": "ఓం సరసాంబునిధయే నమః"
            },
            {
              "title": "ఓం మహేశాయ నమః"
            },
            {
              "title": "ఓం విశదాంగాయ నమః || 90 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం మణికింకిణీమేఖలాయ నమః"
            },
            {
              "title": "ఓం సమస్తదేవతామూర్తయే నమః"
            },
            {
              "title": "ఓం సహిష్ణవే నమః"
            },
            {
              "title": "ఓం బ్రహ్మవిద్యాది దానభువే నమః"
            },
            {
              "title": "ఓం జిష్ణువే నమః"
            },
            {
              "title": "ఓం విష్ణుప్రియాయ నమః"
            },
            {
              "title": "ఓం భక్తజీవితాయ నమః"
            },
            {
              "title": "ఓం జీవతమన్మధాయ నమః"
            },
            {
              "title": "ఓం ఐశ్వర్యకారణాయ నమః"
            },
            {
              "title": "ఓం సతతోత్థితాయ నమః || 100 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం విష్వగ్ధృశే నమః"
            },
            {
              "title": "ఓం విశ్వరక్షావిధానకృతే నమః"
            },
            {
              "title": "ఓం కళ్యాణ గురవే నమః"
            },
            {
              "title": "ఓం ఉన్మత్తవేషాయ నమః"
            },
            {
              "title": "ఓం పరజయినే నమః"
            },
            {
              "title": "ఓం సమస్తజగదాధారాయ నమః"
            },
            {
              "title": "ఓం సర్వైశ్వర్యప్రదాయ నమః"
            },
            {
              "title": "ఓం శ్రీ వినాయకాయ నమః || 108 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఇతి శ్రీ వినాయక అష్టోత్తర శతనామావళి సంపూర్ణం"
            }
          ],
          "title": "శ్రీ వినాయక అష్టోత్తరము",
         //  "img": require('../imgs/Astotaram/Vinayaka.png')
        },
        {
          "astotaralu": [
            {
              "title": "ఓం శ్రీ శంకరాచార్యవర్యాయ నమః |"
            },
            {
              "title": "ఓం బ్రహ్మానందప్రదాయకాయ నమః |"
            },
            {
              "title": "ఓం అజ్ఞానతిమిరాదిత్యాయ నమః |"
            },
            {
              "title": "ఓం సుజ్ఞానామ్బుధిచంద్రమసే నమః |"
            },
            {
              "title": "ఓం వర్ణాశ్రమప్రతిష్ఠాత్రే నమః |"
            },
            {
              "title": "ఓం శ్రీమతే నమః |"
            },
            {
              "title": "ఓం ముక్తిప్రదాయకాయ నమః |"
            },
            {
              "title": "ఓం శిష్యోపదేశనిరతాయ నమః |"
            },
            {
              "title": "ఓం భక్తాభీష్టప్రదాయకాయ నమః |"
            },
            {
              "title": "ఓం సూక్ష్మతత్త్వరహస్యజ్ఞాయ నమః | 10 |"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం కార్యాకార్యప్రబోధకాయ నమః |"
            },
            {
              "title": "ఓం జ్ఞానముద్రాంచితకరాయ నమః |"
            },
            {
              "title": "ఓం శిష్యహృత్తాపహారకాయ నమః |"
            },
            {
              "title": "ఓం పరివ్రాజాశ్రమోద్ధర్త్రే నమః |"
            },
            {
              "title": "ఓం సర్వతంత్రస్వతంత్రధియే నమః |"
            },
            {
              "title": "ఓం అద్వైతస్థాపనాచార్యాయ నమః |"
            },
            {
              "title": "ఓం సాక్షాచ్ఛంకరరూపధృతే నమః |"
            },
            {
              "title": "ఓం షణ్మతస్థాపనాచార్యాయ నమః |"
            },
            {
              "title": "ఓం త్రయీమార్గప్రకాశకాయ నమః |"
            },
            {
              "title": "ఓం వేదవేదాంతతత్త్వజ్ఞాయ నమః | 20 |"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం దుర్వాదిమతఖండనాయ నమః |"
            },
            {
              "title": "ఓం వైరాగ్యనిరతాయ నమః |"
            },
            {
              "title": "ఓం శాంతాయ నమః |"
            },
            {
              "title": "ఓం సంసారార్ణవతారకాయ నమః |"
            },
            {
              "title": "ఓం ప్రసన్నవదనాంభోజాయ నమః"
            },
            {
              "title": "ఓం పరమార్థప్రకాశకాయ నమః |"
            },
            {
              "title": "ఓం పురాణస్మృతిసారజ్ఞాయ నమః |"
            },
            {
              "title": "ఓం నిత్యతృప్తాయ నమః |"
            },
            {
              "title": "ఓం మహతే నమః |"
            },
            {
              "title": "ఓం శుచయే నమః | 30 |"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం నిత్యానందాయ నమః |"
            },
            {
              "title": "ఓం నిరాతంకాయ నమః |"
            },
            {
              "title": "ఓం నిస్సంగాయ నమః |"
            },
            {
              "title": "ఓం నిర్మలాత్మకాయ నమః |"
            },
            {
              "title": "ఓం నిర్మమాయ నమః |"
            },
            {
              "title": "ఓం నిరహంకారాయ నమః |"
            },
            {
              "title": "ఓం విశ్వవంద్యపదాంబుజాయ నమః |"
            },
            {
              "title": "ఓం సత్త్వప్రధానాయ నమః |"
            },
            {
              "title": "ఓం సద్భావాయ నమః |"
            },
            {
              "title": "ఓం సంఖ్యాతీతగుణోజ్వలాయ నమః | 40 |"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం అనఘాయ నమః |"
            },
            {
              "title": "ఓం సారహృదయాయ నమః |"
            },
            {
              "title": "ఓం సుధియే నమః |"
            },
            {
              "title": "ఓం సారస్వతప్రదాయ నమః |"
            },
            {
              "title": "ఓం సత్యాత్మనే నమః |"
            },
            {
              "title": "ఓం పుణ్యశీలాయ నమః |"
            },
            {
              "title": "ఓం సాంఖ్యయోగవిచక్షణాయ నమః |"
            },
            {
              "title": "ఓం తపోరాశయే నమః |"
            },
            {
              "title": "ఓం మహాతేజసే నమః |"
            },
            {
              "title": "ఓం గుణత్రయవిభాగవిదే నమః | 50 |"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం కలిఘ్నాయ నమః |"
            },
            {
              "title": "ఓం కాలకర్మజ్ఞాయ నమః |"
            },
            {
              "title": "ఓం తమోగుణనివారకాయ నమః |"
            },
            {
              "title": "ఓం భగవతే నమః |"
            },
            {
              "title": "ఓం భారతీజేత్రే నమః |"
            },
            {
              "title": "ఓం శారదాహ్వానపండితాయ నమః |"
            },
            {
              "title": "ఓం ధర్మాధర్మవిభాగజ్ఞాయ నమః |"
            },
            {
              "title": "ఓం లక్ష్యభేదప్రదర్శకాయ నమః |"
            },
            {
              "title": "ఓం నాదబిందుకలాభిజ్ఞాయ నమః |"
            },
            {
              "title": "ఓం యోగిహృత్పద్మభాస్కరాయ నమః | 60 |"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం అతీంద్రియజ్ఞాననిధయే నమః |"
            },
            {
              "title": "ఓం నిత్యానిత్యవివేకవతే నమః |"
            },
            {
              "title": "ఓం చిదానందాయ నమః |"
            },
            {
              "title": "ఓం చిన్మయాత్మనే నమః |"
            },
            {
              "title": "ఓం పరకాయప్రవేశకృతే నమః |"
            },
            {
              "title": "ఓం అమానుషచరిత్రాఢ్యాయ నమః |"
            },
            {
              "title": "ఓం క్షేమదాయినే నమః |"
            },
            {
              "title": "ఓం క్షమాకరాయ నమః |"
            },
            {
              "title": "ఓం భవ్యాయ నమః |"
            },
            {
              "title": "ఓం భద్రప్రదాయ నమః | 70 |"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం భూరిమహిమ్నే నమః |"
            },
            {
              "title": "ఓం విశ్వరంజకాయ నమః |"
            },
            {
              "title": "ఓం స్వప్రకాశాయ నమః |"
            },
            {
              "title": "ఓం సదాధారాయ నమః |"
            },
            {
              "title": "ఓం విశ్వబంధవే నమః |"
            },
            {
              "title": "ఓం శుభోదయాయ నమః |"
            },
            {
              "title": "ఓం విశాలకీర్తయే నమః |"
            },
            {
              "title": "ఓం వాగీశాయ నమః |"
            },
            {
              "title": "ఓం సర్వలోకహితోత్సుకాయ నమః |"
            },
            {
              "title": "ఓం కైలాసయాత్రాసంప్రాప్తచంద్రమౌళిప్రపూజకాయ నమః | 80 |"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం కాంచ్యాం శ్రీచక్రరాజాఖ్యయంత్రస్థాపనదీక్షితాయ నమః |"
            },
            {
              "title": "ఓం శ్రీచక్రాత్మకతాటంకతోషితాంబామనోరథాయ నమః |"
            },
            {
              "title": "ఓం శ్రీబ్రహ్మసూత్రోపనిషద్భాష్యాదిగ్రంథకల్పకాయ నమః |"
            },
            {
              "title": "ఓం చతుర్దిక్చతురామ్నాయ ప్రతిష్ఠాత్రే నమః |"
            },
            {
              "title": "ఓం మహామతయే నమః |"
            },
            {
              "title": "ఓం ద్విసప్తతిమతోచ్చేత్రే నమః |"
            },
            {
              "title": "ఓం సర్వదిగ్విజయప్రభవే నమః |"
            },
            {
              "title": "ఓం కాషాయవసనోపేతాయ నమః |"
            },
            {
              "title": "ఓం భస్మోద్ధూళితవిగ్రహాయ నమః |"
            },
            {
              "title": "ఓం జ్ఞానాత్మకైకదండాఢ్యాయ నమః | 90 |"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం కమండలులసత్కరాయ నమః |"
            },
            {
              "title": "ఓం గురుభూమండలాచార్యాయ నమః |"
            },
            {
              "title": "ఓం భగవత్పాదసంజ్ఞకాయ నమః |"
            },
            {
              "title": "ఓం వ్యాససందర్శనప్రీతాయ నమః |"
            },
            {
              "title": "ఓం ఋష్యశృంగపురేశ్వరాయ నమః |"
            },
            {
              "title": "ఓం సౌందర్యలహరీముఖ్యబహుస్తోత్రవిధాయకాయ నమః |"
            },
            {
              "title": "ఓం చతుష్షష్టికలాభిజ్ఞాయ నమః |"
            },
            {
              "title": "ఓం బ్రహ్మరాక్షసమోక్షదాయ నమః |"
            },
            {
              "title": "ఓం శ్రీమన్మండనమిశ్రాఖ్యస్వయంభూజయసన్నుతాయ నమః"
            },
            {
              "title": "ఓం తోటకాచార్యసంపూజ్యాయ నమః | 100 |"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం పద్మపాదార్చితాంఘ్రికాయ నమః |"
            },
            {
              "title": "ఓం హస్తామలకయోగీంద్ర బ్రహ్మజ్ఞానప్రదాయకాయ నమః |"
            },
            {
              "title": "ఓం సురేశ్వరాఖ్యసచ్చిష్యసన్న్యాసాశ్రమదాయకాయ నమః |"
            },
            {
              "title": "ఓం నృసింహభక్తాయ నమః |"
            },
            {
              "title": "ఓం సద్రత్నగర్భహేరంబపూజకాయ నమః |"
            },
            {
              "title": "ఓం వ్యాఖ్యాసింహాసనాధీశాయ నమః |"
            },
            {
              "title": "ఓం జగత్పూజ్యాయ నమః |"
            },
            {
              "title": "ఓం జగద్గురవే నమః | 108 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఇతి శ్రీ ఆదిశంకరాచార్య అష్టోత్తర శతనామావళి సంపూర్ణం"
            }
          ],
          "title": "శ్రీ ఆదిశంకరాచార్య అష్టోత్తరము",
         //  "img": require('../imgs/Astotaram/Adi_sankara.png')
        },
        {
          "astotaralu": [
            {
              "title": "ఓం దుర్గాయై నమః"
            },
            {
              "title": "ఓం శివాయై నమః"
            },
            {
              "title": "ఓం మహాలక్ష్మ్యై నమః"
            },
            {
              "title": "ఓం మహాగౌర్యై నమః"
            },
            {
              "title": "ఓం చండికాయై నమః"
            },
            {
              "title": "ఓం సర్వఙ్ఞాయై నమః"
            },
            {
              "title": "ఓం సర్వాలోకేశ్యై నమః"
            },
            {
              "title": "ఓం సర్వకర్మ ఫలప్రదాయై నమః"
            },
            {
              "title": "ఓం సర్వతీర్ధ మయాయై నమః"
            },
            {
              "title": "ఓం పుణ్యాయై నమః ||10||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం దేవ యోనయే నమః"
            },
            {
              "title": "ఓం అయోనిజాయై నమః"
            },
            {
              "title": "ఓం భూమిజాయై నమః"
            },
            {
              "title": "ఓం నిర్గుణాయై నమః"
            },
            {
              "title": "ఓం ఆధారశక్త్యై నమః"
            },
            {
              "title": "ఓం అనీశ్వర్యై నమః"
            },
            {
              "title": "ఓం నిర్గుణాయై నమః"
            },
            {
              "title": "ఓం నిరహంకారాయై నమః"
            },
            {
              "title": "ఓం సర్వగర్వవిమర్దిన్యై నమః"
            },
            {
              "title": "ఓం సర్వలోకప్రియాయై నమః ||20||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం పార్వత్యై నమః"
            },
            {
              "title": "ఓం దేవమాత్రే నమః"
            },
            {
              "title": "ఓం వనీశ్యై నమః"
            },
            {
              "title": "ఓం వింధ్య వాసిన్యై నమః"
            },
            {
              "title": "ఓం తేజోవత్యై నమః"
            },
            {
              "title": "ఓం మహామాత్రే నమః"
            },
            {
              "title": "ఓం కోటిసూర్య సమప్రభాయై నమః"
            },
            {
              "title": "ఓం దేవతాయై నమః ||30||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం వహ్నిరూపాయై నమః"
            },
            {
              "title": "ఓం సతేజసే నమః"
            },
            {
              "title": "ఓం వర్ణరూపిణ్యై నమః"
            },
            {
              "title": "ఓం గుణాశ్రయాయై నమః"
            },
            {
              "title": "ఓం గుణమధ్యాయై నమః"
            },
            {
              "title": "ఓం గుణత్రయవివర్జితాయై నమః"
            },
            {
              "title": "ఓం కర్మఙ్ఞాన ప్రదాయై నమః"
            },
            {
              "title": "ఓం కాంతాయై నమః"
            },
            {
              "title": "ఓం సర్వసంహార కారిణ్యై నమః"
            },
            {
              "title": "ఓం ధర్మఙ్ఞానాయై నమః ||40||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం ధర్మనిష్టాయై నమః"
            },
            {
              "title": "ఓం సర్వకర్మవివర్జితాయై నమః"
            },
            {
              "title": "ఓం కామాక్ష్యై నమః"
            },
            {
              "title": "ఓం కామాసంహంత్ర్యై నమః"
            },
            {
              "title": "ఓం కామక్రోధ వివర్జితాయై నమః"
            },
            {
              "title": "ఓం శాంకర్యై నమః"
            },
            {
              "title": "ఓం శాంభవ్యై నమః"
            },
            {
              "title": "ఓం శాంతాయై నమః"
            },
            {
              "title": "ఓం చంద్రసుర్యాగ్నిలోచనాయై నమః"
            },
            {
              "title": "ఓం సుజయాయై నమః ||50||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం జయాయై నమః"
            },
            {
              "title": "ఓం భూమిష్ఠాయై నమః"
            },
            {
              "title": "ఓం జాహ్నవ్యై నమః"
            },
            {
              "title": "ఓం జనపూజితాయై నమః"
            },
            {
              "title": "ఓం శాస్త్రాయై నమః"
            },
            {
              "title": "ఓం శాస్త్రమయాయై నమః"
            },
            {
              "title": "ఓం నిత్యాయై నమః"
            },
            {
              "title": "ఓం శుభాయై నమః"
            },
            {
              "title": "ఓం చంద్రార్ధమస్తకాయై నమః"
            },
            {
              "title": "ఓం భారత్యై నమః ||60||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం భ్రామర్యై నమః"
            },
            {
              "title": "ఓం కల్పాయై నమః"
            },
            {
              "title": "ఓం కరాళ్యై నమః"
            },
            {
              "title": "ఓం కృష్ణ పింగళాయై నమః"
            },
            {
              "title": "ఓం బ్రాహ్మ్యై నమః"
            },
            {
              "title": "ఓం నారాయణ్యై నమః"
            },
            {
              "title": "ఓం రౌద్ర్యై నమః"
            },
            {
              "title": "ఓం చంద్రామృత పరివృతాయై నమః"
            },
            {
              "title": "ఓం జ్యేష్ఠాయై నమః"
            },
            {
              "title": "ఓం ఇందిరాయై నమః ||70||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం జగత్సృష్ట్యాధికారిణ్యై నమః"
            },
            {
              "title": "ఓం బ్రహ్మాండ కోటి సంస్థానాయై నమః"
            },
            {
              "title": "ఓం కామిన్యై నమః"
            },
            {
              "title": "ఓం కమలాలయాయై నమః"
            },
            {
              "title": "ఓం కాత్యాయన్యై నమః"
            },
            {
              "title": "ఓం కలాతీతాయై నమః"
            },
            {
              "title": "ఓం కాలసంహారకారిణ్యై నమః"
            },
            {
              "title": "ఓం యోగానిష్ఠాయై నమః"
            },
            {
              "title": "ఓం యోగిగమ్యాయై నమః ||80||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం యోగధ్యేయాయై నమః"
            },
            {
              "title": "ఓం తపస్విన్యై నమః"
            },
            {
              "title": "ఓం ఙ్ఞానరూపాయై నమః"
            },
            {
              "title": "ఓం నిరాకారాయై నమః"
            },
            {
              "title": "ఓం భక్తాభీష్ట ఫలప్రదాయై నమః"
            },
            {
              "title": "ఓం భూతాత్మికాయై నమః"
            },
            {
              "title": "ఓం భూతమాత్రే నమః"
            },
            {
              "title": "ఓం భూతేశ్యై నమః"
            },
            {
              "title": "ఓం భూతధారిణ్యై నమః"
            },
            {
              "title": "ఓం స్వధానారీ మధ్యగతాయై నమః ||90||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం షడాధారాధి వర్ధిన్యై నమః"
            },
            {
              "title": "ఓం మోహితాయై నమః"
            },
            {
              "title": "ఓం అంశుభవాయై నమః"
            },
            {
              "title": "ఓం శుభ్రాయై నమః"
            },
            {
              "title": "ఓం సూక్ష్మాయై నమః"
            },
            {
              "title": "ఓం మాత్రాయై నమః"
            },
            {
              "title": "ఓం నిరాలసాయై నమః"
            },
            {
              "title": "ఓం నిమగ్నాయై నమః"
            },
            {
              "title": "ఓం నీలసంకాశాయై నమః"
            },
            {
              "title": "ఓం నిత్యానందిన్యై నమః ||100||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం హరాయై నమః"
            },
            {
              "title": "ఓం పరాయై నమః"
            },
            {
              "title": "ఓం సర్వఙ్ఞానప్రదాయై నమః"
            },
            {
              "title": "ఓం అనంతాయై నమః"
            },
            {
              "title": "ఓం సత్యాయై నమః"
            },
            {
              "title": "ఓం దుర్లభ రూపిణ్యై నమః"
            },
            {
              "title": "ఓం సరస్వత్యై నమః"
            },
            {
              "title": "ఓం సర్వగతాయై నమః"
            },
            {
              "title": "ఓం సర్వాభీష్టప్రదాయిన్యై నమః || 108 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఇతి శ్రీ దుర్గా అష్టోత్తర శతనామావళీ సంపూర్ణం"
            }
          ],
          "title": "శ్రీ దుర్గా అష్టోత్తరము",
         //  "img": require('../imgs/Astotaram/Durga.png')
        },
        {
          "astotaralu": [
            {
              "title": "ఓం గౌర్యై నమః"
            },
            {
              "title": "ఓం గణేశజనన్యై నమః"
            },
            {
              "title": "ఓం గుహాంబికాయై నమః"
            },
            {
              "title": "ఓం జగన్నేత్రే నమః"
            },
            {
              "title": "ఓం గిరితనూభవాయై నమః"
            },
            {
              "title": "ఓం వీరభధ్రప్రసవే నమః"
            },
            {
              "title": "ఓం విశ్వవ్యాపిణ్యై నమః"
            },
            {
              "title": "ఓం విశ్వరూపిణ్యై నమః"
            },
            {
              "title": "ఓం అష్టమూర్త్యాత్మికాయై నమః"
            },
            {
              "title": "ఓం అష్టదారిద్ర్యశమన్యై నమః || 10 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం శివాయై నమః"
            },
            {
              "title": "ఓం శాంభవ్యై నమః"
            },
            {
              "title": "ఓం శాంకర్యై నమః"
            },
            {
              "title": "ఓం బాలాయై నమః"
            },
            {
              "title": "ఓం భవాన్యై నమః"
            },
            {
              "title": "ఓం హెమవత్యై నమః"
            },
            {
              "title": "ఓం పార్వత్యై నమః"
            },
            {
              "title": "ఓం కాత్యాయన్యై నమః"
            },
            {
              "title": "ఓం మాంగల్యధాయిన్యై నమః"
            },
            {
              "title": "ఓం సర్వమంగళాయై నమః || 20 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం మంజుభాషిణ్యై నమః"
            },
            {
              "title": "ఓం మహేశ్వర్యై నమః"
            },
            {
              "title": "ఓం మహామాయాయై నమః"
            },
            {
              "title": "ఓం మంత్రారాధ్యాయై నమః"
            },
            {
              "title": "ఓం మహాబలాయై నమః"
            },
            {
              "title": "ఓం సత్యై నమః"
            },
            {
              "title": "ఓం సర్వమయై నమః"
            },
            {
              "title": "ఓం సౌభాగ్యదాయై నమః"
            },
            {
              "title": "ఓం కామకలనాయై నమః"
            },
            {
              "title": "ఓం కాంక్షితార్ధప్రదాయై నమః || 30 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం చంద్రార్కయుత తాటంకాయై నమః"
            },
            {
              "title": "ఓం చిదంబరశరీరిణ్యై నమః"
            },
            {
              "title": "ఓం శ్రీ చక్రవాసిన్యై నమః"
            },
            {
              "title": "ఓం దేవ్యై నమః"
            },
            {
              "title": "ఓం కామేశ్వరపత్న్యై నమః"
            },
            {
              "title": "ఓం పాపనాశిన్యై నమః"
            },
            {
              "title": "ఓం నరాయణాంశజాయై నమః"
            },
            {
              "title": "ఓం నిత్యాయై నమః"
            },
            {
              "title": "ఓం నిర్మలాయై నమః"
            },
            {
              "title": "ఓం అంబికాయై నమః || 40 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం హిమాద్రిజాయై నమః"
            },
            {
              "title": "ఓం వేదాంతలక్షణాయై నమః"
            },
            {
              "title": "ఓం కర్మబ్రహ్మామయై నమః"
            },
            {
              "title": "ఓం గంగాధరకుటుంబిన్యై నమః"
            },
            {
              "title": "ఓం మృడాయై నమః"
            },
            {
              "title": "ఓం మునిసంసేవ్యాయై నమః"
            },
            {
              "title": "ఓం మాలిన్యై నమః"
            },
            {
              "title": "ఓం మేనకాత్మజాయై నమః"
            },
            {
              "title": "ఓం కుమార్యై నమః"
            },
            {
              "title": "ఓం కన్యకాయై నమః || 50 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం దుర్గాయై నమః"
            },
            {
              "title": "ఓం కలిదోషవిఘ్నాతిన్యై నమః"
            },
            {
              "title": "ఓం కమలాయై నమః"
            },
            {
              "title": "ఓం మురారిప్రియార్ధాంగ్యై నమః"
            },
            {
              "title": "ఓం పుత్రపౌత్రవరప్రదాయై నమః"
            },
            {
              "title": "ఓం పుణ్యాయై నమః"
            },
            {
              "title": "ఓం కృపాపూర్ణాయై నమః"
            },
            {
              "title": "ఓం కల్యాణ్యై నమః"
            },
            {
              "title": "ఓం కమలాయై నమః"
            },
            {
              "title": "ఓం అచింత్యాయై నమః || 60 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం త్రిపురాయై నమః"
            },
            {
              "title": "ఓం త్రిగుణాంబికాయై నమః"
            },
            {
              "title": "ఓం పురుషార్ధప్రదాయై నమః"
            },
            {
              "title": "ఓం సత్యధర్మరతాయై నమః"
            },
            {
              "title": "ఓం సర్వరక్షిణ్యై నమః"
            },
            {
              "title": "ఓం శశాంకరూపిణ్యై నమః"
            },
            {
              "title": "ఓం సరస్వత్యై నమః"
            },
            {
              "title": "ఓం విరజాయై నమః"
            },
            {
              "title": "ఓం స్వాహాయ్యై నమః"
            },
            {
              "title": "ఓం స్వధాయై నమః || 70 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం ప్రత్యంగిరాంబికాయైనమః"
            },
            {
              "title": "ఓం ఆర్యాయై నమః"
            },
            {
              "title": "ఓం దాక్షాయిణ్యై నమః"
            },
            {
              "title": "ఓం దీక్షాయై నమః"
            },
            {
              "title": "ఓం సర్వవస్తూత్తమోత్తమాయై నమః"
            },
            {
              "title": "ఓం శివాభినామధేయాయై నమః"
            },
            {
              "title": "ఓం శ్రీవిద్యాయై నమః"
            },
            {
              "title": "ఓం ప్రణవార్ధస్వరూపిణ్యై నమః"
            },
            {
              "title": "ఓం హ్రీంకార్త్యె నమః"
            },
            {
              "title": "ఓం నాదరూపాయై నమః || 80 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం సుందర్యై నమః"
            },
            {
              "title": "ఓం షోడాశాక్షరదీపికాయై నమః"
            },
            {
              "title": "ఓం మహాగౌర్యై నమః"
            },
            {
              "title": "ఓం శ్యామలాయై నమః"
            },
            {
              "title": "ఓం చండ్యై నమః"
            },
            {
              "title": "ఓం భగమాళిన్యై నమః"
            },
            {
              "title": "ఓం భగళాయై నమః"
            },
            {
              "title": "ఓం మాతృకాయై నమః"
            },
            {
              "title": "ఓం శూలిన్యై నమః"
            },
            {
              "title": "ఓం అమలాయై నమః || 90 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం అన్నపూర్ణాయై నమః"
            },
            {
              "title": "ఓం అఖిలాగమసంస్తుతాయై నమః"
            },
            {
              "title": "ఓం అంబాయై నమః"
            },
            {
              "title": "ఓం భానుకోటిసముద్యతాయై నమః"
            },
            {
              "title": "ఓం వరాయై నమః"
            },
            {
              "title": "ఓం శీతాంశుకృతశేఖరాయై నమః"
            },
            {
              "title": "ఓం సర్వకాలసుమంగళ్యై నమః"
            },
            {
              "title": "ఓం సోమశేఖర్యై నమః"
            },
            {
              "title": "ఓం సుఖసచ్చిత్పుధారసాయై నమః"
            },
            {
              "title": "ఓం బాలారాధిత భూతిదాయై నమః || 100 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం హిరణ్యాయై నమః"
            },
            {
              "title": "ఓం హరిద్రాకుంకుమారాధ్యాయై నమః"
            },
            {
              "title": "ఓం సర్వభోగప్రదాయై నమః"
            },
            {
              "title": "ఓం మార్కండేయవర ప్రదాయై నమః"
            },
            {
              "title": "ఓం అమరసంసేవ్యాయై నమః"
            },
            {
              "title": "ఓం అమరైశ్వర్యై నమః"
            },
            {
              "title": "ఓం సూక్ష్మాయై నమః"
            },
            {
              "title": "ఓం భద్రదాయిన్యై నమః || 108 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఇతి శ్రీ గౌరీ అష్టోత్తర శతనామావళీ సంపూర్ణం"
            }
          ],
          "title": "శ్రీ గౌరీ అష్టోత్తరము",
         //  "img": require('../imgs/Astotaram/Gowri.png')
        },
        {
          "astotaralu": [
            {
              "title": "ఓం అనాధ్యాయై నమః"
            },
            {
              "title": "ఓం అక్షుభ్జాయై నమః"
            },
            {
              "title": "ఓం అయోనిజాయై నమః"
            },
            {
              "title": "ఓం అనలప్రభావాయై నమః"
            },
            {
              "title": "ఓం అద్యా యై నమః"
            },
            {
              "title": "ఓం అపద్దారిణ్యై నమః"
            },
            {
              "title": "ఓం ఆదిత్యమండలగతాయైనమః"
            },
            {
              "title": "ఓం ఆకాశరూపిణ్యై నమః"
            },
            {
              "title": "ఓం ఇంద్రాణ్యై నమః"
            },
            {
              "title": "ఓం ఇంద్రార్చితాయై నమః || 10 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం ఇందుచూడాయై నమః"
            },
            {
              "title": "ఓం ఈ శిత్రై నమః"
            },
            {
              "title": "ఓం ఈశమాయాయై నమః"
            },
            {
              "title": "ఓం ఉగ్రచండాయై నమః"
            },
            {
              "title": "ఓం ఉగ్రవేగాయై నమః"
            },
            {
              "title": "ఓం ఉగ్రప్రభావత్యై నమః"
            },
            {
              "title": "ఓం ఉన్మత్తకేశిన్యై నమః"
            },
            {
              "title": "ఓం ఉన్మత్తభైరవన్యై నమః"
            },
            {
              "title": "ఓం ఋజుమార్గస్తాయై నమః"
            },
            {
              "title": "ఓం ఋషిదేవరనమస్కృతాయై నమః || 20 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం ఏకాక్షరాయై నమః"
            },
            {
              "title": "ఓం ఏకమాత్రాయై నమః"
            },
            {
              "title": "ఓం అండమధ్యస్థితాయై నమః"
            },
            {
              "title": "ఓం కరుణాకరాయై నమః"
            },
            {
              "title": "ఓం కమన్యై నమః"
            },
            {
              "title": "ఓం కమలస్తాయై నమః"
            },
            {
              "title": "ఓం కల్పవృక్షస్వరూపిణ్యై నమః"
            },
            {
              "title": "ఓం కాలజిహ్వాయై నమః"
            },
            {
              "title": "ఓం కైటభాసురమర్దిన్యై నమః"
            },
            {
              "title": "ఓం గీతనృతపరాయణాయై నమః || 30 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం గుహ్యకాళికాయై నమః"
            },
            {
              "title": "ఓం గుణైకనిలయాయై నమః"
            },
            {
              "title": "ఓం గుప్తస్థాననివాసిన్యై నమః"
            },
            {
              "title": "ఓం గోపకులోద్భవాయై నమః"
            },
            {
              "title": "ఓం చతువ్రక్ర్తయై నమః"
            },
            {
              "title": "ఓం చతుర్వేదాత్మికాయై నమః"
            },
            {
              "title": "ఓం చంద్రశేఖరవక్షస్తాయై నమః"
            },
            {
              "title": "ఓం చంద్రశేఖరవల్లభాయై నమః"
            },
            {
              "title": "ఓం చేతనాత్మికాయై నమః"
            },
            {
              "title": "ఓం జగద్రూపాయై నమః || 40 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం జన్మమృత్యుజరాతీతాయై నమః"
            },
            {
              "title": "ఓం జాతవేదస్వరూణ్యై నమః"
            },
            {
              "title": "ఓం జీవాత్మికాయై నమః"
            },
            {
              "title": "ఓం జ్వరాతీతాయై నమః"
            },
            {
              "title": "ఓం తప్తకాంచనసంకాశాయై నమః"
            },
            {
              "title": "ఓం తప్తకాంచనభూషణాయై నమః"
            },
            {
              "title": "ఓం తిలహోమప్రియాయై నమః"
            },
            {
              "title": "ఓం త్రిపురఘ్నే నమః"
            },
            {
              "title": "ఓం త్రిశూలవరధారిణ్యై నమః"
            },
            {
              "title": "ఓం త్ర్యై లోక్యజనన్యై నమః || 50 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం త్రైలోక్యమోహిన్యై నమః"
            },
            {
              "title": "ఓం దారిద్ర్యభేదిన్యై నమః"
            },
            {
              "title": "ఓం దివ్యనేత్రాయై నమః"
            },
            {
              "title": "ఓం దీననాధాయై నమః"
            },
            {
              "title": "ఓం దేవేంద్రవంద్య పాదాబ్జాయై నమః"
            },
            {
              "title": "ఓం నవనీతప్రియాయ నమః"
            },
            {
              "title": "ఓం నారాయణపదోద్భవాయై నమః"
            },
            {
              "title": "ఓం నిరాకారాయై నమః"
            },
            {
              "title": "ఓం నిసుంభహంత్యై నమః"
            },
            {
              "title": "ఓం నీలకంఠమనోరమాయై నమః || 60 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం నైమిశారణ్యవాసిన్యై నమః"
            },
            {
              "title": "ఓం పరాశక్తె నమః"
            },
            {
              "title": "ఓం పర్వతనందిన్యై నమః"
            },
            {
              "title": "ఓం పంచపాతకనాశిన్యై నమః"
            },
            {
              "title": "ఓం పరమాహ్లాదకారిణ్యై నమః"
            },
            {
              "title": "ఓం పద్మమాలవిభూషితాయై నమః"
            },
            {
              "title": "ఓం పూర్ణబ్రహ్మ స్వరూపిణ్యై నమః"
            },
            {
              "title": "ఓం ప్రణతైశ్వరదాయై నమః"
            },
            {
              "title": "ఓం ప్రధానపురుషారాధ్యాయై నమః"
            },
            {
              "title": "ఓం ప్రద్యుమ్నజనన్యై నమః || 70 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం ప్రత్యక్షబ్రహ్మరూపాయై నమః"
            },
            {
              "title": "ఓం ప్రాణశక్త్యే నమః"
            },
            {
              "title": "ఓం ప్రేత సంస్థాయై నమః"
            },
            {
              "title": "ఓం ఫణీంద్రభూషణాయై నమః"
            },
            {
              "title": "ఓం బగళాయై నమః"
            },
            {
              "title": "ఓం బదర్యా శ్రమవాసిన్యై నమః"
            },
            {
              "title": "ఓం బంధూకకుసుమాభాయై నమః"
            },
            {
              "title": "ఓం బిందునాధస్వరూపిణ్యై నమః"
            },
            {
              "title": "ఓం బ్రహ్మస్వరూపిణ్యై నమః"
            },
            {
              "title": "ఓం బ్రహ్మవిష్ణుశివారాధ్యాయై నమః || 80 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం భూతాంతరస్తాయై నమః"
            },
            {
              "title": "ఓం భూనాధప్రియాంగనాయై నమః"
            },
            {
              "title": "ఓం మంత్రాత్మికాయై నమః"
            },
            {
              "title": "ఓం మహాయోగీశ్వరేశ్వర్యై నమః"
            },
            {
              "title": "ఓం మహాచింతానాశిన్యై నమః"
            },
            {
              "title": "ఓం మధుకైటభసంహాత్రై నమః"
            },
            {
              "title": "ఓం మంజుభాషిణ్యై నమః"
            },
            {
              "title": "ఓం మరతకశ్యామాయై నమః"
            },
            {
              "title": "ఓం మందార కుసుమార్చితాయై నమః"
            },
            {
              "title": "ఓం మూలాధారనివాసిన్యై నమః || 90 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం యోగనిద్రాయై నమః"
            },
            {
              "title": "ఓం యోగివంద్యాయై నమః"
            },
            {
              "title": "ఓం రావణచేదకారిణ్యై నమః"
            },
            {
              "title": "ఓం వాయుమండలమధ్యస్థాయై నమః"
            },
            {
              "title": "ఓం వాజ పేయఫలప్రదాయై నమః"
            },
            {
              "title": "ఓం విశ్వాంబికాయై నమః"
            },
            {
              "title": "ఓం విశ్వం భరాయ నమః"
            },
            {
              "title": "ఓం విశ్వరూపిణై నమః"
            },
            {
              "title": "ఓం విశ్వ వినాశిన్యై నమః"
            },
            {
              "title": "ఓం విశ్వ ప్రాణాత్మికాయై నమః || 100 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం విరూపాక్షమనోరమాయై నమః"
            },
            {
              "title": "ఓం వేదవిద్యాయై నమః"
            },
            {
              "title": "ఓం వేదాక్షరపరీత్వాంగ్యై నమః"
            },
            {
              "title": "ఓం సంక్షోభనాశిన్యై నమః"
            },
            {
              "title": "ఓం సంసారార్ణవతారిణ్యై నమః"
            },
            {
              "title": "ఓం క్షేమదాయిన్యై నమః"
            },
            {
              "title": "ఓం సంసారబంధకర్తె నమః"
            },
            {
              "title": "ఓం సంసారపర్జితాయై నమః"
            },
            {
              "title": "శ్రీ దేవి లోకమాతాయై నమః || 108 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఇతి శ్రీ దేవీ అష్టోత్తర శతనామావళి సంపూర్ణం"
            }
          ],
          "title": "శ్రీ దేవీ అష్టోత్తరము",
         //  "img": require('../imgs/Astotaram/SriDevi.png')
        },
        {
          "astotaralu": [
            {
              "title": "ఓం సరస్వత్యై నమః"
            },
            {
              "title": "ఓం మహాభద్రాయై నమః"
            },
            {
              "title": "ఓం మహామాయాయై నమః"
            },
            {
              "title": "ఓం వరప్రదాయై నమః"
            },
            {
              "title": "ఓం శ్రీప్రదాయై నమః"
            },
            {
              "title": "ఓం పద్మనిలయాయై నమః"
            },
            {
              "title": "ఓం పద్మాక్ష్యై నమః"
            },
            {
              "title": "ఓం పద్మవక్త్రాయై నమః"
            },
            {
              "title": "ఓం శివానుజాయై నమః"
            },
            {
              "title": "ఓం పుస్తకభృతే నమః || 10 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం జ్ఞానముద్రాయై నమః"
            },
            {
              "title": "ఓం రమాయై నమః"
            },
            {
              "title": "ఓం పరాయై నమః"
            },
            {
              "title": "ఓం కామరూపాయై నమః"
            },
            {
              "title": "ఓం మహావిద్యాయై నమః"
            },
            {
              "title": "ఓం మహాపాతక నాశిన్యై నమః"
            },
            {
              "title": "ఓం మహాశ్రయాయై నమః"
            },
            {
              "title": "ఓం మాలిన్యై నమః"
            },
            {
              "title": "ఓం మహాభోగాయై నమః"
            },
            {
              "title": "ఓం మహాభుజాయై నమః || 20 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం మహాభాగాయై నమః"
            },
            {
              "title": "ఓం మహోత్సాహాయై నమః"
            },
            {
              "title": "ఓం దివ్యాఙ్గాయై నమః"
            },
            {
              "title": "ఓం సురవన్దితాయై నమః"
            },
            {
              "title": "ఓం మహాకాళై నమః"
            },
            {
              "title": "ఓం మహాపాశాయై నమః"
            },
            {
              "title": "ఓం మహాకారాయై నమః"
            },
            {
              "title": "ఓం మహాంకుశాయై నమః"
            },
            {
              "title": "ఓం పీతాయై నమః"
            },
            {
              "title": "ఓం విమలాయై నమః || 30 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం విశ్వాయై నమః"
            },
            {
              "title": "ఓం విద్యున్మాలాయై నమః"
            },
            {
              "title": "ఓం వైష్ణవ్యై నమః"
            },
            {
              "title": "ఓం చన్ద్రికాయై నమః"
            },
            {
              "title": "ఓం చన్ద్రవదనాయై నమః"
            },
            {
              "title": "ఓం చన్ద్రలేఖావిభూషితాయై నమః"
            },
            {
              "title": "ఓం సావిత్యై నమః"
            },
            {
              "title": "ఓం సురసాయై నమః"
            },
            {
              "title": "ఓం దేవ్యై నమః"
            },
            {
              "title": "ఓం దివ్యాలంకారభూషితాయై నమః || 40 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం వాగ్దేవ్యై నమః"
            },
            {
              "title": "ఓం వసుదాయై నమః"
            },
            {
              "title": "ఓం తీవ్రాయై నమః"
            },
            {
              "title": "ఓం మహాభద్రాయై నమః"
            },
            {
              "title": "ఓం మహాబలాయై నమః"
            },
            {
              "title": "ఓం భోగదాయై నమః"
            },
            {
              "title": "ఓం భారత్యై నమః"
            },
            {
              "title": "ఓం భామాయై నమః"
            },
            {
              "title": "ఓం గోవిన్దాయై నమః"
            },
            {
              "title": "ఓం గోమత్యై నమః || 50 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం శివాయై నమః"
            },
            {
              "title": "ఓం జటిలాయై నమః"
            },
            {
              "title": "ఓం విన్ధ్యవాసాయై నమః"
            },
            {
              "title": "ఓం విన్ధ్యాచలవిరాజితాయై నమః"
            },
            {
              "title": "ఓం చణ్డికాయై నమః"
            },
            {
              "title": "ఓం వైష్ణవ్యై నమః"
            },
            {
              "title": "ఓం బ్రాహ్మయై నమః"
            },
            {
              "title": "ఓం బ్రహ్మజ్ఞానైకసాధనాయై నమః"
            },
            {
              "title": "ఓం సౌదామన్యై నమః"
            },
            {
              "title": "ఓం సుధామూర్త్యై నమః || 60 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం సుభద్రాయై నమః"
            },
            {
              "title": "ఓం సురపూజితాయై నమః"
            },
            {
              "title": "ఓం సువాసిన్యై నమః"
            },
            {
              "title": "ఓం సునాసాయై నమః"
            },
            {
              "title": "ఓం వినిద్రాయై నమః"
            },
            {
              "title": "ఓం పద్మలోచనాయై నమః"
            },
            {
              "title": "ఓం విద్యారూపాయై నమః"
            },
            {
              "title": "ఓం విశాలాక్ష్యై నమః"
            },
            {
              "title": "ఓం బ్రహ్మజాయాయై నమః"
            },
            {
              "title": "ఓం మహాఫలాయై నమః || 70 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం త్రయీమూర్తయే నమః"
            },
            {
              "title": "ఓం త్రికాలజ్ఞాయై నమః"
            },
            {
              "title": "ఓం త్రిగుణాయై నమః"
            },
            {
              "title": "ఓం శాస్త్రరూపిణ్యై నమః"
            },
            {
              "title": "ఓం శంభాసురప్రమథిన్యై నమః"
            },
            {
              "title": "ఓం శుభదాయై నమః"
            },
            {
              "title": "ఓం స్వరాత్మికాయై నమః"
            },
            {
              "title": "ఓం రక్తబీజనిహన్త్ర్యై నమః"
            },
            {
              "title": "ఓం చాముణ్డాయై నమః"
            },
            {
              "title": "ఓం అమ్బికాయై నమః || 80 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం ముణ్డకాయప్రహరణాయై నమః"
            },
            {
              "title": "ఓం ధూమ్రలోచనమదనాయై నమః"
            },
            {
              "title": "ఓం సర్వదేవస్తుతాయై నమః"
            },
            {
              "title": "ఓం సౌమ్యాయై నమః"
            },
            {
              "title": "ఓం సురాసుర నమస్కృతాయై నమః"
            },
            {
              "title": "ఓం కాలరాత్ర్యై నమః"
            },
            {
              "title": "ఓం కలాధరాయై నమః"
            },
            {
              "title": "ఓం రూపసౌభాగ్యదాయిన్యై నమః"
            },
            {
              "title": "ఓం వాగ్దేవ్యై నమః"
            },
            {
              "title": "ఓం వరారోహాయై నమః || 90 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం వారాహ్యై నమః"
            },
            {
              "title": "ఓం వారిజాసనాయై నమః"
            },
            {
              "title": "ఓం చిత్రాంబరాయై నమః"
            },
            {
              "title": "ఓం చిత్రగన్ధాయై నమః"
            },
            {
              "title": "ఓం చిత్రమాల్య విభూషితాయై నమః"
            },
            {
              "title": "ఓం కాన్తాయై నమః"
            },
            {
              "title": "ఓం కామప్రదాయై నమః"
            },
            {
              "title": "ఓం వన్ద్యాయై నమః"
            },
            {
              "title": "ఓం విద్యాధర సుపూజితాయై నమః"
            },
            {
              "title": "ఓం శ్వేతాననాయై నమః || 100 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం నీలభుజాయై నమః"
            },
            {
              "title": "ఓం చతుర్వర్గ ఫలప్రదాయై నమః"
            },
            {
              "title": "ఓం చతురానన సామ్రాజ్యాయై నమః"
            },
            {
              "title": "ఓం రక్తమధ్యాయై నమః"
            },
            {
              "title": "ఓం నిరంజనాయై నమః"
            },
            {
              "title": "ఓం హంసాసనాయై నమః"
            },
            {
              "title": "ఓం నీలజఙ్ఘాయై నమః"
            },
            {
              "title": "ఓం బ్రహ్మవిష్ణుశివాత్మికాయై నమః || 108 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఇతి శ్రీ సరస్వతీ అష్టోత్తర శతనామావళి సంపూర్ణం."
            }
          ],
          "title": "శ్రీ సరస్వతీ అష్టోత్తరము",
         //  "img": require('../imgs/Astotaram/Saraswathi.png')
        },
        {
          "astotaralu": [
            {
              "title": "ఓం శ్రీ భవాన్యై నమః"
            },
            {
              "title": "ఓం శివాన్యై నమః"
            },
            {
              "title": "ఓం రుద్రాణ్యై నమః ఒరేయ్"
            },
            {
              "title": "ఓం మృడాన్యై నమః"
            },
            {
              "title": "ఓం కాళికాయై నమః"
            },
            {
              "title": "ఓం చండికాయై నమః"
            },
            {
              "title": "ఓం దుర్గాయై నమః"
            },
            {
              "title": "ఓం మహాలక్ష్మ్య నమః"
            },
            {
              "title": "ఓం మహామాయాయై నమః"
            },
            {
              "title": "ఓం పరాయై నమః || 10 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం అంబాయై నమః"
            },
            {
              "title": "ఓం అంబికాయై నమః"
            },
            {
              "title": "ఓం అఖిలాయై నమః"
            },
            {
              "title": "ఓం సనాతన్యై నమః"
            },
            {
              "title": "ఓం జగన్మాతృకాయై నమః"
            },
            {
              "title": "ఓం జగదాధారాయై నమః"
            },
            {
              "title": "ఓం సర్వదాయై నమః"
            },
            {
              "title": "ఓం సర్వగాయై నమః"
            },
            {
              "title": "ఓం సర్వాయై నమః"
            },
            {
              "title": "ఓం శర్వాణ్యై నమః || 20 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం గౌర్యై నమః"
            },
            {
              "title": "ఓం సింహాసనాసీనాయై నమః"
            },
            {
              "title": "ఓం కాళరాత్ర్యై’ నమః"
            },
            {
              "title": "ఓం సినీవాల్యై నమః"
            },
            {
              "title": "ఓం చిన్మయాయై నమః"
            },
            {
              "title": "ఓం మహాశక్త్యై నమః"
            },
            {
              "title": "ఓం విద్యుల్లతాయై నమః"
            },
            {
              "title": "ఓం అర్థమాత్రాయై నమః"
            },
            {
              "title": "ఓం సాక్షిణ్యై నమః"
            },
            {
              "title": "ఓం అలేఖాయై నమః || 30 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం అనూహ్యాయై నమః"
            },
            {
              "title": "ఓం అనుపమాయై నమః"
            },
            {
              "title": "ఓం మహిషమర్ధిన్యై నమః"
            },
            {
              "title": "ఓం వృత్రాసురనిర్మూలహేతవే నమః"
            },
            {
              "title": "ఓం త్రినేత్రాయై నమః"
            },
            {
              "title": "ఓం చంద్రచూడాయై నమః"
            },
            {
              "title": "ఓం సురారాధ్యాయై నమః"
            },
            {
              "title": "ఓం దుర్గాయై నమః"
            },
            {
              "title": "ఓం భ్రమరాంబాయై నమః"
            },
            {
              "title": "ఓం చండ్యై నమః || 40 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం చాముండాయై నమః"
            },
            {
              "title": "ఓం శివార్ధరూపిణ్యై నమః"
            },
            {
              "title": "ఓం సిద్దిదాయై నమః"
            },
            {
              "title": "ఓం పర్వతవర్దిన్యై నమః"
            },
            {
              "title": "ఓం సింహాధిష్ఠాయై నమః"
            },
            {
              "title": "ఓం భక్తహృదయాధిస్థాయై నమః"
            },
            {
              "title": "ఓం మహావిద్యాయై నమః"
            },
            {
              "title": "ఓం ప్రకృత్యై నమః"
            },
            {
              "title": "ఓం వికృత్యై నమః"
            },
            {
              "title": "ఓం సుకృత్యై నమః || 50 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం సర్వకృత్యై నమః"
            },
            {
              "title": "ఓం నిత్యై నమః"
            },
            {
              "title": "ఓం నిశ్చలాయై నమః"
            },
            {
              "title": "ఓం నిరాలంబాయై నమః"
            },
            {
              "title": "ఓం సర్వాధారాయై నమః"
            },
            {
              "title": "ఓం సర్వేశ్వర్యై నమః"
            },
            {
              "title": "ఓం వాగ్దేవతాయై నమః"
            },
            {
              "title": "ఓం కళాయై నమః"
            },
            {
              "title": "ఓం విశ్వంభరాయై నమః"
            },
            {
              "title": "ఓం విశ్వమోహిన్యై నమః || 60 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం సృష్టిస్థితిలయ హేతవే నమః"
            },
            {
              "title": "ఓం సర్వమంగళాయై నమః"
            },
            {
              "title": "ఓం లావణ్యాయై నమః"
            },
            {
              "title": "ఓం సౌందర్యలహర్యై నమః"
            },
            {
              "title": "ఓం ఆసన్ని వారిణ్యై నమః"
            },
            {
              "title": "ఓం సర్వతాపవారిణ్యై నమః"
            },
            {
              "title": "ఓం అమృతమణితాటంకాయై నమః"
            },
            {
              "title": "ఓం గాయత్ర్యై నమః"
            },
            {
              "title": "ఓం గాంధర్వాయై నమః"
            },
            {
              "title": "ఓం ఆఢ్యాయై నమః || 70 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం అభయాయై నమః"
            },
            {
              "title": "ఓం అజేయాయై నమః"
            },
            {
              "title": "ఓం అగమ్యా నమః"
            },
            {
              "title": "ఓం దుర్గమా నమః"
            },
            {
              "title": "ఓం చిదానందలహర్యై నమః"
            },
            {
              "title": "ఓం వేదాతీతాయై నమః"
            },
            {
              "title": "ఓం మణిద్వీపావాసాయై నమః"
            },
            {
              "title": "ఓం మహత్తరాయై నమః"
            },
            {
              "title": "ఓం జగద్దితభవాయై నమః"
            },
            {
              "title": "ఓం మహామత్యై నమః || 80 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం మేధాయై నమః"
            },
            {
              "title": "ఓం స్వధాయై నమః"
            },
            {
              "title": "ఓం స్వాహాయై నమః"
            },
            {
              "title": "ఓం వటుప్రియాయై నమః"
            },
            {
              "title": "ఓం దుర్గాసురభంజన్యై నమః"
            },
            {
              "title": "ఓం జగత్ శరణ్యాయై నమః"
            },
            {
              "title": "ఓం శివమంచస్థితాయై నమః"
            },
            {
              "title": "ఓం చింతామణిగృహిణ్యై నమః"
            },
            {
              "title": "ఓం స్తోత్రప్రియాయై నమః"
            },
            {
              "title": "ఓం సదాచారాయై నమః || 90 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం నిర్విచారాయై నమః"
            },
            {
              "title": "ఓం నిష్కామసేవాప్రియాయై నమః"
            },
            {
              "title": "ఓం వ్రతరూపాయై నమః"
            },
            {
              "title": "ఓం యజ్ఞమయాయై నమః"
            },
            {
              "title": "ఓం యజ్ఞేశాయై నమః"
            },
            {
              "title": "ఓం శివప్రియాయై నమః"
            },
            {
              "title": "ఓం ప్రాణసారాయై నమః"
            },
            {
              "title": "ఓం జగత్ప్రాణాయై నమః"
            },
            {
              "title": "ఓం అద్యంతరహత్యాయై నమః"
            },
            {
              "title": "ఓం ఇంద్రకీలాద్రివాసిన్యై నమః || 100 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం గుణత్రయవివర్జితాయై నమః"
            },
            {
              "title": "ఓం కోటిసూర్యప్రభాయై నమః"
            },
            {
              "title": "ఓం శాంభవ్యే నమః"
            },
            {
              "title": "ఓం హింగుళ్యై నమః"
            },
            {
              "title": "ఓం ప్రహ్లాదిన్యై నమః"
            },
            {
              "title": "ఓం వహ్నివాసిన్యై నమః"
            },
            {
              "title": "ఓం పతాకిన్యై నమః"
            },
            {
              "title": "ఓం పంచమప్రియాయై నమః || 108 ||"
            },
            {
              "title": ""
            },
            {
              "title": "ఇతి శ్రీ భవానీ అష్టోత్తర శతనామావళి సంపూర్ణం"
            },
          ],
          "title": "శ్రీ భవానీ అష్టోత్తరము",
         //  "img": require('../imgs/Astotaram/Bhavani.png')
        },
        {
          "astotaralu": [
           {
              "title": 'ఓం శ్రీ మాత్రే నమః',
           },
           {
              "title": 'ఓం శ్రీ మహారాజ్నై నమః',
           },
           {
              "title": 'ఓం శ్రీ మత్సింహాసనేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ మన్నారాయణప్రీతాయై నమః',
           },
           {
              "title": 'ఓం స్నిగ్దాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ మత్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ పతి ప్రియాయై నమః',
           },
           {
              "title": 'ఓం క్షీరసాగర సంభూతాయై నమః',
           },
           {
              "title": 'ఓం నారాయణ హృదాలయాయై నమః',
           },
           {
              "title": 'ఓం ఐరావణాది సంపూజ్యాయై నమః ||10||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం దిగ్గజానాం సహోదర్యై నమః',
           },
           {
              "title": 'ఓం ఉచ్చైస్రవస్యహోద్భూతాయై నమః',
           },
           {
              "title": 'ఓం హస్తినాదప్రభోదిన్యై నమః',
           },
           {
              "title": 'ఓం సామ్రాజ్య దాయిన్యై నమః',
           },
           {
              "title": 'ఓం దేవ్యై నమః',
           },
           {
              "title": 'ఓం గజలక్ష్మీస్వరూపిన్యై నమః',
           },
           {
              "title": 'ఓం సువర్ణాది ప్రదాత్ర్యై నమః',
           },
           {
              "title": 'ఓం సువర్ణాది స్వరూపిన్యై నమః',
           },
           {
              "title": 'ఓం ధనలక్ష్మే నమః',
           },
           {
              "title": 'ఓం మహోధరాయై నమః ||20||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం ప్రభూతైశ్వర్యదాయిన్యై నమః',
           },
           {
              "title": 'ఓం నవధాన్యస్వరూపాయై నమః',
           },
           {
              "title": 'ఓం లతాపాదపరూపిన్యై నమః',
           },
           {
              "title": 'ఓం మూలికాదిమహోరూపాయై నమః',
           },
           {
              "title": 'ఓం ధాన్యలక్ష్మీ మహాభిధాయై నమః',
           },
           {
              "title": 'ఓం పశుసంపత్స్వరూపాయై నమః',
           },
           {
              "title": 'ఓం ధనధాన్యవివర్దిన్యై నమః',
           },
           {
              "title": 'ఓం మాత్సర్య నాశిన్యై నమః',
           },
           {
              "title": 'ఓం క్రోధ భీతివినాశిన్యై నమః',
           },
           {
              "title": 'ఓం భేదబుద్ధిహరాయై నమః ||30||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సౌమ్యాయై నమః',
           },
           {
              "title": 'ఓం వినయాదికవర్దిన్యై నమః',
           },
           {
              "title": 'ఓం వినయాదిప్రదాయై నమః',
           },
           {
              "title": 'ఓం దీరాయై నమః',
           },
           {
              "title": 'ఓం వినీతార్చాను తోషిన్యై నమః',
           },
           {
              "title": 'ఓం ధైర్యప్రదాయై నమః',
           },
           {
              "title": 'ఓం ధైర్యలక్ష్మే నమః',
           },
           {
              "title": 'ఓం ధీరత్వగుణవర్దిన్యై  నమః',
           },
           {
              "title": 'ఓం పుత్రపౌత్రప్రదాయై నమః',
           },
           {
              "title": 'ఓం భ్రుత్యాదిక వివర్దిన్యై నమః ||40||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం దాంపత్యదాయిన్యై నమః',
           },
           {
              "title": 'ఓం పూర్ణాయై నమః',
           },
           {
              "title": 'ఓం పతిపత్నీసుతాకృత్యై నమః',
           },
           {
              "title": 'ఓం సంతన్వత్యైకుటుంబిన్యై నమః',
           },
           {
              "title": 'ఓం బహుబాంధవ్యదాయిన్యై నమః',
           },
           {
              "title": 'ఓం సంతానలక్ష్మీరూపాయై నమః',
           },
           {
              "title": 'ఓం సర్వంసంతన్వత్యై నమః',
           },
           {
              "title": 'ఓం మనోవికాసదాత్ర్యై నమః',
           },
           {
              "title": 'ఓం బుద్దేరైకాగ్ర్యదాయిన్యై నమః',
           },
           {
              "title": 'ఓం విద్యాకౌశలసంధాత్ర్యై నమః ||50||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం నానావిజ్ఞానవర్దిన్యై నమః',
           },
           {
              "title": 'ఓం బుద్ధి శుద్ధి ప్రదాత్యై నమః',
           },
           {
              "title": 'ఓం మహాదేవ్యై నమః',
           },
           {
              "title": 'ఓం సర్వసంపూజ్య తాదాత్ర్యై నమః',
           },
           {
              "title": 'ఓం విద్యామంగళదాయిన్యై నమః',
           },
           {
              "title": 'ఓం భోగవిద్యాప్రదాత్ర్యై నమః',
           },
           {
              "title": 'ఓం యోగవిద్యా ప్రదాత్ర్యై నమః',
           },
           {
              "title": 'ఓం బహిరంతస్పమారాధ్యాయై నమః',
           },
           {
              "title": 'ఓం జ్ఞానవిద్యానుదాయిన్యై నమః',
           },
           {
              "title": 'ఓం విద్యాలక్ష్మే నమః ||60||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం విద్యాగౌరవదాయిన్యై నమః',
           },
           {
              "title": 'ఓం విద్యానామకృత్యైశుభాయై నమః',
           },
           {
              "title": 'ఓం సౌభాగ్యభాగ్యదాయై నమః',
           },
           {
              "title": 'ఓం భోగభాగ్యవిధాయిన్యై నమః',
           },
           {
              "title": 'ఓం ప్రసన్నాయై నమః',
           },
           {
              "title": 'ఓం పరమాయై నమః',
           },
           {
              "title": 'ఓం ఆరాధ్యాయై నమః',
           },
           {
              "title": 'ఓం సౌశీల్యగునవర్దిన్యై నమః',
           },
           {
              "title": 'ఓం వరసంతానప్రదాయై నమః',
           },
           {
              "title": 'ఓం పుణ్యాయై నమః ||70||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సంతానవరదాయిన్యై నమః',
           },
           {
              "title": 'ఓం జగత్కుటుంబిన్యై నమః',
           },
           {
              "title": 'ఓం వరసౌభాగ్యదాయిన్యై నమః',
           },
           {
              "title": 'ఓం వరలక్ష్మే నమః',
           },
           {
              "title": 'ఓం ఆదిలక్ష్మే నమః',
           },
           {
              "title": 'ఓం భక్తరక్షణతత్పరాయై నమః',
           },
           {
              "title": 'ఓం సర్వశక్తిస్వరూపాయై నమః',
           },
           {
              "title": 'ఓం సర్వాసిద్ధిప్రదాయిన్యై నమః',
           },
           {
              "title": 'ఓం సర్వేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం సర్వపూజ్యాయై నమః ||80||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సర్వాలోకప్రపూజితాయై నమః',
           },
           {
              "title": 'ఓం దాక్షిణ్యపరవశాయై నమః',
           },
           {
              "title": 'ఓం లక్ష్మే నమః',
           },
           {
              "title": 'ఓం కృపాపూర్ణాయై నమః',
           },
           {
              "title": 'ఓం దయానిధయే నమః',
           },
           {
              "title": 'ఓం సర్వలోకసమార్చ్యయై నమః',
           },
           {
              "title": 'ఓం సర్వలోకేశ్వరేశ్వరీయై నమః',
           },
           {
              "title": 'ఓం సర్వోన్నత్యప్రదాయై నమః',
           },
           {
              "title": 'ఓం శ్రియే నమః',
           },
           {
              "title": 'ఓం సర్వత్ర విజయంకర్యై నమః ||90||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సర్వ శ్రియై నమః',
           },
           {
              "title": 'ఓం విజయలక్ష్మే నమః',
           },
           {
              "title": 'ఓం సర్వలక్ష్మే నమః',
           },
           {
              "title": 'ఓం శుభావహాయై నమః',
           },
           {
              "title": 'ఓం అష్టలక్ష్మీ స్వరూపాయై నమః',
           },
           {
              "title": 'ఓం సర్వాదిక్పాలపూజితాయై నమః',
           },
           {
              "title": 'ఓం దారిద్రదుఖహంత్ర్యై నమః',
           },
           {
              "title": 'ఓం అష్టలక్ష్మీసమాహారాయై నమః',
           },
           {
              "title": 'ఓం భక్తానుగ్రహకారిన్యై నమః',
           },
           {
              "title": 'ఓం పద్మాలయాయై నమః ||100||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం పాదపద్మాయై నమః',
           },
           {
              "title": 'ఓం కరపద్మాయై నమః',
           },
           {
              "title": 'ఓం ముఖాంబుజాయై నమః',
           },
           {
              "title": 'ఓం పద్మేక్షణాయై నమః',
           },
           {
              "title": 'ఓం పద్మగంధాయై నమః',
           },
           {
              "title": 'ఓం పద్మనాభహృదీశ్వర్యే నమః',
           },
           {
              "title": 'ఓం పద్మనాభహృదీశ్వర్యే నమః',
           },
           {
              "title": 'ఓం పద్మాసనస్వజనన్యై నమః',
           },
           {
              "title": 'ఓం హృదాంబుజవికాసిన్యై నమః ||108||',
           },
           {
              "title": '',
           },
           {
              "title": '|| ఇతి శ్రీ అష్టలక్ష్మీ అష్టోత్తర శతనామావళి సంపూర్ణం ||',
           }
         ],
          "title": 'శ్రీ అష్టలక్ష్మీ అష్టోత్తర శతనామావళి',
         // "img": require('../imgs/Astotaram/Ashtalakshmi.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం శ్రీ సాయినాధాయ నమః',
           },
           {
              "title": 'ఓం లక్ష్మీనారాయణాయ నమః',
           },
           {
              "title": 'ఓం కృష్ణరామశివమారుత్యాదిరూపాయ నమః',
           },
           {
              "title": 'ఓం శేషసాయినే నమః',
           },
           {
              "title": 'ఓం గోదావరీతటషిర్డివాసినే నమః',
           },
           {
              "title": 'ఓం భక్తహృదయాయ నమః',
           },
           {
              "title": 'ఓం సర్వహృద్వాసినే నమః',
           },
           {
              "title": 'ఓం భూతవాసాయ నమః',
           },
           {
              "title": 'ఓం భూతభవిష్యద్బావవర్జితాయ నమః',
           },
           {
              "title": 'ఓం కాలతీతాయ నమః ||10||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం కాలాయ నమః',
           },
           {
              "title": 'ఓం కాలకాలాయ నమః',
           },
           {
              "title": 'ఓం కాలదర్పదమనాయ నమః',
           },
           {
              "title": 'ఓం మృత్యుంజయాయ నమః',
           },
           {
              "title": 'ఓం మృత్యుంజయాయ నమః',
           },
           {
              "title": 'ఓం అమర్త్యాయ నమః',
           },
           {
              "title": 'ఓం ముర్త్యాభయప్రదాయ నమః',
           },
           {
              "title": 'ఓం జీవధారాయ నమః',
           },
           {
              "title": 'ఓం సర్వాధారాయ నమః',
           },
           {
              "title": 'ఓం భక్తవనసమర్ధాయ నమః',
           },
           {
              "title": 'ఓం భక్తావనప్రతిజ్ఞానసమార్థాయ నమః ||20||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అన్నవస్త్రదాయ నమః',
           },
           {
              "title": 'ఓం ఆరోగ్యక్షేమదాయ నమః',
           },
           {
              "title": 'ఓం ధనమాంగల్య ప్రదాయ నమః',
           },
           {
              "title": 'ఓం బుద్ధిసిద్ధిప్రదాయ నమః',
           },
           {
              "title": 'ఓం పుత్రమిత్రకళత్రబంధుదాయ నమః',
           },
           {
              "title": 'ఓం యోగక్షేమవహాయ నమః',
           },
           {
              "title": 'ఓం ఆపద్బాంధవాయ నమః',
           },
           {
              "title": 'ఓం మార్గబంధవే నమః',
           },
           {
              "title": 'ఓం భక్తిముక్తి స్వర్గాపదాయ నమః',
           },
           {
              "title": 'ఓం ప్రియాయ నమః ||30||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం ప్రీతివర్ధనాయనమః',
           },
           {
              "title": 'ఓం అంతర్యామినే నమః',
           },
           {
              "title": 'ఓం సచ్చిదాత్మనే నమః',
           },
           {
              "title": 'ఓం నిత్యానందాయ నమః',
           },
           {
              "title": 'ఓం పరమసుఖదాయ నమః',
           },
           {
              "title": 'ఓం పరమేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం పరబ్రహ్మణే నమః',
           },
           {
              "title": 'ఓం పరమాత్మనే నమః',
           },
           {
              "title": 'ఓం జ్ఞాన స్వరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం జగత్పిత్రే నమః ||40||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం భక్తానాంమాతృ నమః',
           },
           {
              "title": 'ఓం పితృపితామహాయ నమః',
           },
           {
              "title": 'ఓం భక్తాభయప్రదాయ నమః',
           },
           {
              "title": 'ఓం భక్తవత్సలాయ నమః',
           },
           {
              "title": 'ఓం భక్తానుగ్రహకాంతకాయ నమః',
           },
           {
              "title": 'ఓం శరణాగతవత్సలాయ నమః',
           },
           {
              "title": 'ఓం భక్తిశక్తి ప్రదాయ నమః',
           },
           {
              "title": 'ఓం జ్ఞానవైరాగ్యదాయ నమః',
           },
           {
              "title": 'ఓం ప్రేమప్రదాయ నమః',
           },
           {
              "title": 'ఓం సంసారధౌర్భల్యపావకర్మక్షమకారకాయ నమః ||50||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం హృదయగ్రంధిభేదకాయ నమః',
           },
           {
              "title": 'ఓం కర్మధ్వంసినే నమః',
           },
           {
              "title": 'ఓం శుద్ధసత్య స్థితాయ నమః',
           },
           {
              "title": 'ఓం గుణాతీతగుణాత్మనే నమః',
           },
           {
              "title": 'ఓం అనంతకళ్యాణగుణాయ నమః',
           },
           {
              "title": 'ఓం అమితపరాక్రమాయ నమః',
           },
           {
              "title": 'ఓం జయనే నమః',
           },
           {
              "title": 'ఓం దుర్ధర్షాక్షోభ్యాయ నమః',
           },
           {
              "title": 'ఓం అపరాజితాయ నమః',
           },
           {
              "title": 'ఓం త్రిలోకేషాదిపతయే నమః ||60||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అశత్యరహితాయ నమః',
           },
           {
              "title": 'ఓం సర్వశక్తిమూర్తయే నమః',
           },
           {
              "title": 'ఓం సులోచనాయ నమః',
           },
           {
              "title": 'ఓం బహురూప విశ్వమూర్తయే నమః',
           },
           {
              "title": 'ఓం అరూపవ్యక్తాయ నమః',
           },
           {
              "title": 'ఓం అచింత్యాయ నమః',
           },
           {
              "title": 'ఓం సూక్ష్మాయ నమః',
           },
           {
              "title": 'ఓం సర్వాంతర్యామినే నమః',
           },
           {
              "title": 'ఓం మనోవాగతీతాయ నమః',
           },
           {
              "title": 'ఓం ప్రేమమూర్తయే నమః ||70||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సులభదుర్లభాయ నమః',
           },
           {
              "title": 'ఓం అనహాయసహాయాయ నమః',
           },
           {
              "title": 'ఓం అనాధనాధదీనబాంధవే నమః',
           },
           {
              "title": 'ఓం సర్వభారబృతే నమః',
           },
           {
              "title": 'ఓం అకర్మానేకర్మసుకర్మిణే నమః',
           },
           {
              "title": 'ఓం పుణ్యశ్రవణకీర్తనాయ నమః',
           },
           {
              "title": 'ఓం తీర్థాయ నమః',
           },
           {
              "title": 'ఓం వాసుదేవాయ నమః',
           },
           {
              "title": 'ఓం సతాంగతయే నమః',
           },
           {
              "title": 'ఓం సత్సరాయణాయ నమః ||80||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం లోకనాథాయ నమః',
           },
           {
              "title": 'ఓం పావనానఘాయ నమః',
           },
           {
              "title": 'ఓం అమృతాంశవే నమః',
           },
           {
              "title": 'ఓం భాస్కరప్రభాయ నమః',
           },
           {
              "title": 'ఓం బ్రహ్మచర్యతపశ్చర్యానేనుదిసు వ్రతాయ నమః',
           },
           {
              "title": 'ఓం సత్యధర్మపరాయణాయ నమః',
           },
           {
              "title": 'ఓం సిద్దేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం సిద్దసంకల్పాయ నమః',
           },
           {
              "title": 'ఓం యోగీశ్వరాయ నమః',
           },
           {
              "title": 'ఓం భగవతే నమః ||90||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం భక్తవశ్యాయ నమః',
           },
           {
              "title": 'ఓం సర్పురుషాయ నమః',
           },
           {
              "title": 'ఓం పురుషోత్తమాయ నమః',
           },
           {
              "title": 'ఓం సత్యతత్వబోధకాయ నమః',
           },
           {
              "title": 'ఓం కామాదిసర్వాఙ్ఙానధ్వంసినే నమః',
           },
           {
              "title": 'ఓం అభేదానంద శుభప్రదాయ నమః',
           },
           {
              "title": 'ఓం సమసర్వమతసమ్మతాయ నమః',
           },
           {
              "title": 'ఓం దక్షినామూర్తయే నమః',
           },
           {
              "title": 'ఓం శ్రీ వేంకటేశారమణాయ నమః',
           },
           {
              "title": 'ఓం అద్భుతానంత చర్యాయ నమః ||100||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం ప్రసన్నార్తి హారాయ నమః',
           },
           {
              "title": 'ఓం సంసారసర్వదుఖక్షమాయ నమః',
           },
           {
              "title": 'ఓం సర్వవిత్సర్వతోముఖాయ నమః',
           },
           {
              "title": 'ఓం సర్వాంతర్భస్థితాయ నమః',
           },
           {
              "title": 'ఓం సర్వమంగళకరాయ నమః',
           },
           {
              "title": 'ఓం సర్వాభీష్టప్రదాయ నమః',
           },
           {
              "title": 'ఓం సమరససన్మార్గస్థాపనాయ నమః',
           },
           {
              "title": 'ఓం సమర్దసద్గురు శ్రీసాయినాథాయ నమః ||108||',
           },
           {
              "title": '',
           },
           {
              "title": '|| ఇతి శ్రీ షిరిడీసాయి అష్టోత్తర శతనామావళి సంపూర్ణం ||',
           },
           {
              "title": '',
           },
         ],
          "title": 'శ్రీ షిరిడీసాయి అష్టోత్తర శతనామావళి',
         // "img": require('../imgs/Astotaram/SaiBaba.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం విష్ణవే నమః',
           },
           {
              "title": 'ఓం లక్ష్మీపతయే నమః',
           },
           {
              "title": 'ఓం కృష్ణాయ నమః',
           },
           {
              "title": 'ఓం వైకుంఠాయ నమః',
           },
           {
              "title": 'ఓం గరుడధ్వజాయ నమః',
           },
           {
              "title": 'ఓం పరబ్రహ్మణే నమః',
           },
           {
              "title": 'ఓం జగన్నాథాయ నమః',
           },
           {
              "title": 'ఓం వాసుదేవాయ నమః',
           },
           {
              "title": 'ఓం త్రివిక్రమాయ నమః',
           },
           {
              "title": 'ఓం దైత్యాంతకాయ నమః ||10||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మధురిపవే నమః',
           },
           {
              "title": 'ఓం తార్క్ష్యవాహనాయ నమః',
           },
           {
              "title": 'ఓం సనాతనాయ నమః',
           },
           {
              "title": 'ఓం నారాయణాయ నమః',
           },
           {
              "title": 'ఓం పద్మనాభాయ నమః',
           },
           {
              "title": 'ఓం హృషీకేశాయ నమః',
           },
           {
              "title": 'ఓం సుధాప్రదాయ నమః ',
           },
           {
              "title": 'ఓం మాధవాయ నమః ',
           },
           {
              "title": 'ఓం పుండరీకాక్షాయ నమః',
           },
           {
              "title": 'ఓం స్థితికర్త్రే నమః ||20||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం పరాత్పరాయ నమః',
           },
           {
              "title": 'ఓం వనమాలినే నమః',
           },
           {
              "title": 'ఓం యజ్ఞరూపాయ నమః',
           },
           {
              "title": 'ఓం చక్రపాణయే నమః',
           },
           {
              "title": 'ఓం గదాధరాయ నమః',
           },
           {
              "title": 'ఓం ఉపేంద్రాయ నమః',
           },
           {
              "title": 'ఓం కేశవాయ నమః',
           },
           {
              "title": 'ఓం హంసాయ నమః',
           },
           {
              "title": 'ఓం సముద్రమథనాయ నమః',
           },
           {
              "title": 'ఓం హరయే నమః ||30||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం గోవిందాయ నమః',
           },
           {
              "title": 'ఓం బ్రహ్మజనకాయ నమః',
           },
           {
              "title": 'ఓం కైటభాసురమర్దనాయ నమః',
           },
           {
              "title": 'ఓం శ్రీధరాయ నమః',
           },
           {
              "title": 'ఓం కామజనకాయ నమః',
           },
           {
              "title": 'ఓం శేషశాయినే నమః',
           },
           {
              "title": 'ఓం చతుర్భుజాయ నమః',
           },
           {
              "title": 'ఓం పాంచజన్యధరాయ నమః',
           },
           {
              "title": 'ఓం శ్రీమతే నమః',
           },
           {
              "title": 'ఓం శార్ఙ్గపాణయే నమః ||40||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం జనార్దనాయ నమః',
           },
           {
              "title": 'ఓం పీతాంబరధరాయ నమః',
           },
           {
              "title": 'ఓం దేవాయ నమః',
           },
           {
              "title": 'ఓం సూర్యచంద్రవిలోచనాయ నమః',
           },
           {
              "title": 'ఓం మత్స్యరూపాయ నమః',
           },
           {
              "title": 'ఓం కూర్మతనవే నమః',
           },
           {
              "title": 'ఓం క్రోధరూపాయ నమః',
           },
           {
              "title": 'ఓం నృకేసరిణే నమః',
           },
           {
              "title": 'ఓం వామనాయ నమః',
           },
           {
              "title": 'ఓం భార్గవాయ నమః ||50||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం రామాయ నమః',
           },
           {
              "title": 'ఓం బలినే నమః',
           },
           {
              "title": 'ఓం కల్కినే నమః',
           },
           {
              "title": 'ఓం హయాననాయ నమః',
           },
           {
              "title": 'ఓం విశ్వంబరాయ నమః',
           },
           {
              "title": 'ఓం శిశుమారాయ నమః',
           },
           {
              "title": 'ఓం శ్రీకరాయ నమః',
           },
           {
              "title": 'ఓం కపిలాయ నమః',
           },
           {
              "title": 'ఓం ధ్రువాయ నమః',
           },
           {
              "title": 'ఓం దత్తాత్రేయాయ నమః ||60||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అచ్యుతాయ నమః',
           },
           {
              "title": 'ఓం అనంతాయ నమః',
           },
           {
              "title": 'ఓం ముకుందాయ నమః',
           },
           {
              "title": 'ఓం దధివామనాయ నమః',
           },
           {
              "title": 'ఓం ధన్వంతరాయ నమః',
           },
           {
              "title": 'ఓం శ్రీనివాసాయ నమః',
           },
           {
              "title": 'ఓం ప్రద్యుమ్నాయ నమః',
           },
           {
              "title": 'ఓం పురుషోత్తమాయ నమః',
           },
           {
              "title": 'ఓం శ్రీవత్సకౌస్తుభధరాయ నమః',
           },
           {
              "title": 'ఓం మురారాతయే నమః ||70||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అధోక్షజాయ నమః',
           },
           {
              "title": 'ఓం ఋషభాయ నమః',
           },
           {
              "title": 'ఓం మోహినీరూపధారిణే నమః',
           },
           {
              "title": 'ఓం సంకర్షణాయ నమః',
           },
           {
              "title": 'ఓం పృథవే నమః',
           },
           {
              "title": 'ఓం క్షీరాబ్ధిశాయినే నమః',
           },
           {
              "title": 'ఓం భూతాత్మనే నమః',
           },
           {
              "title": 'ఓం అనిరుద్ధాయ నమః',
           },
           {
              "title": 'ఓం భక్తవత్సలాయ నమః',
           },
           {
              "title": 'ఓం నరాయ నమః ||80||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం గజేంద్రవరదాయ నమః',
           },
           {
              "title": 'ఓం త్రిధామ్నే నమః',
           },
           {
              "title": 'ఓం భూతభావనాయ నమః',
           },
           {
              "title": 'ఓం శ్వేతద్వీపసువాస్తవ్యాయ నమః',
           },
           {
              "title": 'ఓం సనకాదిమునిధ్యేయాయ నమః',
           },
           {
              "title": 'ఓం భగవతే నమః',
           },
           {
              "title": 'ఓం శంకరప్రియాయ నమః',
           },
           {
              "title": 'ఓం నీలకాంతాయ నమః',
           },
           {
              "title": 'ఓం ధరాకాంతాయ నమః',
           },
           {
              "title": 'ఓం వేదాత్మనే నమః ||90||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం బాదరాయణాయ నమః',
           },
           {
              "title": 'ఓం భాగీరథీజన్మభూమిపాదపద్మాయ నమః',
           },
           {
              "title": 'ఓం సతాం ప్రభవే నమః',
           },
           {
              "title": 'ఓం స్వభువే నమః',
           },
           {
              "title": 'ఓం విభవే నమః',
           },
           {
              "title": 'ఓం ఘనశ్యామాయ నమః',
           },
           {
              "title": 'ఓం జగత్కారణాయ నమః',
           },
           {
              "title": 'ఓం అవ్యయాయ నమః',
           },
           {
              "title": 'ఓం బుద్ధావతారాయ నమః',
           },
           {
              "title": 'ఓం శాంతాత్మనే నమః ||100||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం లీలామానుషవిగ్రహాయ నమః',
           },
           {
              "title": 'ఓం దామోదరాయ నమః',
           },
           {
              "title": 'ఓం విరాడ్రూపాయ నమః',
           },
           {
              "title": 'ఓం భూతభవ్యభవత్ప్రభవే నమః',
           },
           {
              "title": 'ఓం ఆదిదేవాయ నమః',
           },
           {
              "title": 'ఓం దేవదేవాయ నమః',
           },
           {
              "title": 'ఓం ప్రహ్లాదపరిపాలకాయ నమః',
           },
           {
              "title": 'ఓం శ్రీమహావిష్ణవే నమః ||108||',
           },
           {
              "title": '',
           },
           {
              "title": '|| ఇతి శ్రీ మహావిష్ణు అష్టోత్తర శతనామావళి సమాప్తం ||',
           }
         ],
          "title": 'శ్రీ విష్ణు అష్టోత్తర శతనామావళి',
         // "img": require('../imgs/Astotaram/vishnu.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం శివాయై నమః',
           },
           {
              "title": 'ఓం భవాన్యై నమః',
           },
           {
              "title": 'ఓం కళ్యాణ్యై నమః',
           },
           {
              "title": 'ఓం గౌర్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ కాళ్యై నమః',
           },
           {
              "title": 'ఓం శివ ప్రియాయై నమః',
           },
           {
              "title": 'ఓం కాత్యాయన్యై నమః',
           },
           {
              "title": 'ఓం మహాదేవ్యై నమః',
           },
           {
              "title": 'ఓం దుర్గాయై నమః',
           },
           {
              "title": 'ఓం ఆర్యాయై నమః ||10||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం చండికాయై నమః',
           },
           {
              "title": 'ఓం భవాయై నమః',
           },
           {
              "title": 'ఓం చంద్రచూడాయై నమః',
           },
           {
              "title": 'ఓం చంద్రముఖ్యై నమః',
           },
           {
              "title": 'ఓం చంద్రమండలవాసిన్యై నమః',
           },
           {
              "title": 'ఓం చంద్రహాసకరాయై నమః',
           },
           {
              "title": 'ఓం చంద్రహాసిన్యై నమః',
           },
           {
              "title": 'ఓం చంద్రకోటిభాసాయై నమః',
           },
           {
              "title": 'ఓం చిద్రూపాయై నమః',
           },
           {
              "title": 'ఓం చిత్కళాయై నమః ||20||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం నిత్యాయై నమః',
           },
           {
              "title": 'ఓం నిర్మలాయై నమః',
           },
           {
              "title": 'ఓం నిష్కళాయై నమః',
           },
           {
              "title": 'ఓం కళాయై నమః',
           },
           {
              "title": 'ఓం భవ్యాయై నమః',
           },
           {
              "title": 'ఓం భవప్రియాయై నమః',
           },
           {
              "title": 'ఓం భవ్యరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం కలభాషిణ్యై నమః',
           },
           {
              "title": 'ఓం కవిప్రియాయై నమః',
           },
           {
              "title": 'ఓం కామకళాయై నమః ||30||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం కామదాయిన్యై నమః',
           },
           {
              "title": 'ఓం కామరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం కారుణ్యసాగరాయై నమః',
           },
           {
              "title": 'ఓం కాళ్యై నమః',
           },
           {
              "title": 'ఓం సంసారార్ణవతారికాయై నమః',
           },
           {
              "title": 'ఓం దూర్వాభాయై నమః',
           },
           {
              "title": 'ఓం దుష్టభయదాయై నమః',
           },
           {
              "title": 'ఓం దుర్జయాయై నమః',
           },
           {
              "title": 'ఓం దురితాపహారిణ్యై నమః',
           },
           {
              "title": 'ఓం లలితాయై నమః ||40||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం రాజ్యదాయిన్యై నమః',
           },
           {
              "title": 'ఓం సిద్ధాయై నమః',
           },
           {
              "title": 'ఓం సిద్ధేశ్యై నమః',
           },
           {
              "title": 'ఓం సిద్ధిదాయిన్యై నమః',
           },
           {
              "title": 'ఓం శరదాత్ర్యై నమః',
           },
           {
              "title": 'ఓం శాంత్యై నమః',
           },
           {
              "title": 'ఓం అవ్యక్తాయై నమః',
           },
           {
              "title": 'ఓం శంఖకుండల  మండితాయై నమః',
           },
           {
              "title": 'ఓం శారదాయై నమః',
           },
           {
              "title": 'ఓం శాంకర్యై నమః ||50||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సాధ్వ్యై నమః',
           },
           {
              "title": 'ఓం శ్యామలాయై నమః',
           },
           {
              "title": 'ఓం కోమలాకృత్యై నమః',
           },
           {
              "title": 'ఓం పుష్పిణ్యై నమః',
           },
           {
              "title": 'ఓం పుష్పబాణాయై నమః',
           },
           {
              "title": 'ఓం అంబాయై నమః',
           },
           {
              "title": 'ఓం కమలాయై నమః',
           },
           {
              "title": 'ఓం కమలాసనాయై నమః',
           },
           {
              "title": 'ఓం పంచబాణస్తుతాయై నమః',
           },
           {
              "title": 'ఓం పంచవర్ణరూపాయై నమః ||60||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సర్వేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం పంచమ్యై నమః',
           },
           {
              "title": 'ఓం పరమాయై నమః',
           },
           {
              "title": 'ఓం లక్ష్మై నమః',
           },
           {
              "title": 'ఓం పావన్యై నమః',
           },
           {
              "title": 'ఓం పాపహారిణ్యై నమః',
           },
           {
              "title": 'ఓం సర్వజ్ఞాయై నమః',
           },
           {
              "title": 'ఓం వృషభారూఢాయై నమః',
           },
           {
              "title": 'ఓం సర్వలోకైకశంకర్యై నమః',
           },
           {
              "title": 'ఓం సర్వస్వతంత్రాయై నమః ||70||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సర్వేశ్యై నమః',
           },
           {
              "title": 'ఓం సర్వమంగళకారిణ్యై నమః',
           },
           {
              "title": 'ఓం నిరవద్యాయై నమః',
           },
           {
              "title": 'ఓం నీరదాభాయై నమః',
           },
           {
              "title": 'ఓం నిర్మలాయై నమః',
           },
           {
              "title": 'ఓం నిశ్చయాత్మికాయై నమః',
           },
           {
              "title": 'ఓం నిర్మదాయై నమః',
           },
           {
              "title": 'ఓం నియతాచారాయై నమః',
           },
           {
              "title": 'ఓం నిష్కామాయై నమః',
           },
           {
              "title": 'ఓం నిగమాలయాయై నమః ||80||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అనాదిబోధాయై నమః',
           },
           {
              "title": 'ఓం బ్రహ్మాణ్యై నమః',
           },
           {
              "title": 'ఓం కౌమార్యై నమః',
           },
           {
              "title": 'ఓం గురురూపిణ్యై నమః',
           },
           {
              "title": 'ఓం వైష్ణవ్యై నమః',
           },
           {
              "title": 'ఓం సమయాచారాయై నమః',
           },
           {
              "title": 'ఓం కౌళిన్యై నమః',
           },
           {
              "title": 'ఓం కులదేవతాయై నమః',
           },
           {
              "title": 'ఓం సామగానప్రియాయై నమః',
           },
           {
              "title": 'ఓం సర్వవేదరూపాయై నమః ||90||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సరస్వత్యై నమః',
           },
           {
              "title": 'ఓం అంతర్యాగ ప్రియానందాయై నమః',
           },
           {
              "title": 'ఓం బహిర్యాగ వరార్చితాయై నమః',
           },
           {
              "title": 'ఓం వీణాగాన రసానందాయై నమః',
           },
           {
              "title": 'ఓం అర్ధాన్మీలితలోచనాయై నమః',
           },
           {
              "title": 'ఓం దివ్యచందననాగ్ధాంగ్యై నమః',
           },
           {
              "title": 'ఓం సర్వసామ్రాజ్య రూపిణ్యై నమః',
           },
           {
              "title": 'ఓం తరంగీ కృతాపాంగ వీక్షారక్షిత సజ్జనాయై నమః',
           },
           {
              "title": 'ఓం సుధాపానసముద్వేల హేలా మోహిత ధూర్జట్యై నమః',
           },
           {
              "title": 'ఓం మతంగముని సంపూజ్యాయై నమః ||100||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మతంగకుల భూషణాయై నమః',
           },
           {
              "title": 'ఓం మకుటాంగద మంజీర మేఖలాదామ భూషితాయై నమః',
           },
           {
              "title": 'ఓం ఊర్మికాకింకిణీరత్న కంకణాది పరిష్కృతాయై నమః',
           },
           {
              "title": 'ఓం మల్లికా మాలతీ కుంద మందారాంచిత మస్తకాయై నమః',
           },
           {
              "title": 'ఓం తాంబూల కబళోదంచత్క పోలతల శోభిన్యై నమః',
           },
           {
              "title": 'ఓం త్రిమూర్తి రూపాయై నమః',
           },
           {
              "title": 'ఓం త్రైలోక్య సుమోహన తను ప్రభాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ మచ్ఛక్రాధి నగరీ సామ్రాజ్య శ్రీ స్వరూపిణ్యై నమః ||108||',
           },
           {
              "title": '',
           },
           {
              "title": '|| ఇతి శ్రీ లలిత అష్టోత్తర శతనామావళి సమాప్తం  ||',
           }
         ],
          "title": 'శ్రీ లలిత అష్టోత్తర శతనామావళి',
         // "img": require('../imgs/Astotaram/lalitha.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం గౌర్యై నమః',
           },
           {
              "title": 'ఓం గణేశజనన్యై నమః',
           },
           {
              "title": 'ఓం గిరిరాజతనూద్భవాయై నమః',
           },
           {
              "title": 'ఓం గుహాంబికాయై నమః',
           },
           {
              "title": 'ఓం జగన్మాత్రే నమః',
           },
           {
              "title": 'ఓం గంగాధరకుటుంబిన్యై నమః',
           },
           {
              "title": 'ఓం వీరభద్రప్రసువే నమః',
           },
           {
              "title": 'ఓం విశ్వవ్యాపిన్యై నమః',
           },
           {
              "title": 'ఓం విశ్వరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం అష్టమూర్త్యాత్మికాయై నమః ||10||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం కష్టదారిద్య్రశమన్యై నమః',
           },
           {
              "title": 'ఓం శివాయై నమః',
           },
           {
              "title": 'ఓం శాంభవ్యై నమః',
           },
           {
              "title": 'ఓం శాంకర్యై నమః',
           },
           {
              "title": 'ఓం బాలాయై నమః',
           },
           {
              "title": 'ఓం భవాన్యై నమః',
           },
           {
              "title": 'ఓం భద్రదాయిన్యై నమః',
           },
           {
              "title": 'ఓం మాంగళ్యదాయిన్యై నమః',
           },
           {
              "title": 'ఓం సర్వమంగళాయై నమః',
           },
           {
              "title": 'ఓం మంజుభాషిణ్యై నమః ||20||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మహేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం మహామాయాయై నమః',
           },
           {
              "title": 'ఓం మంత్రారాధ్యాయై నమః',
           },
           {
              "title": 'ఓం మహాబలాయై నమః',
           },
           {
              "title": 'ఓం హేమాద్రిజాయై నమః',
           },
           {
              "title": 'ఓం హేమవత్యై నమః',
           },
           {
              "title": 'ఓం పార్వత్యై నమః',
           },
           {
              "title": 'ఓం పాపనాశిన్యై నమః',
           },
           {
              "title": 'ఓం నారాయణాంశజాయై నమః',
           },
           {
              "title": 'ఓం నిత్యాయై నమః ||30||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం నిరీశాయై నమః',
           },
           {
              "title": 'ఓం నిర్మలాయై నమః',
           },
           {
              "title": 'ఓం అంబికాయై నమః',
           },
           {
              "title": 'ఓం మృడాన్యై నమః',
           },
           {
              "title": 'ఓం మునిసంసేవ్యాయై నమః',
           },
           {
              "title": 'ఓం మానిన్యై నమః',
           },
           {
              "title": 'ఓం మేనకాత్మజాయై నమః',
           },
           {
              "title": 'ఓం కుమార్యై నమః',
           },
           {
              "title": 'ఓం కన్యకాయై నమః',
           },
           {
              "title": 'ఓం దుర్గాయై నమః ||40||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం కలిదోషనిషూదిన్యై నమః',
           },
           {
              "title": 'ఓం కాత్యాయిన్యై నమః',
           },
           {
              "title": 'ఓం కృపాపూర్ణాయై నమః',
           },
           {
              "title": 'ఓం కళ్యాణ్యై నమః',
           },
           {
              "title": 'ఓం కమలార్చితాయై నమః',
           },
           {
              "title": 'ఓం సత్యై నమః',
           },
           {
              "title": 'ఓం సర్వమయ్యై నమః',
           },
           {
              "title": 'ఓం సౌభాగ్యదాయై నమః',
           },
           {
              "title": 'ఓం సరస్వత్యై నమః',
           },
           {
              "title": 'ఓం అమలాయై నమః ||50||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అమరసంసేవ్యాయై నమః',
           },
           {
              "title": 'ఓం అన్నపూర్ణాయై నమః',
           },
           {
              "title": 'ఓం అమృతేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం అఖిలాగమసంస్తుత్యాయై నమః',
           },
           {
              "title": 'ఓం సుఖసచ్చిత్సుధారసాయై నమః',
           },
           {
              "title": 'ఓం బాల్యారాధితభూతేశాయై నమః',
           },
           {
              "title": 'ఓం భానుకోటిసమద్యుతయే నమః',
           },
           {
              "title": 'ఓం హిరణ్మయ్యై నమః',
           },
           {
              "title": 'ఓం పరాయై నమః',
           },
           {
              "title": 'ఓం సూక్ష్మాయై నమః ||60||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శీతాంశుకృతశేఖరాయై నమః',
           },
           {
              "title": 'ఓం హరిద్రాకుంకుమారాధ్యాయై నమః',
           },
           {
              "title": 'ఓం సర్వకాలసుమంగళ్యై నమః',
           },
           {
              "title": 'ఓం సర్వభోగప్రదాయై నమః',
           },
           {
              "title": 'ఓం సామశిఖాయై నమః',
           },
           {
              "title": 'ఓం వేదాంతలక్షణాయై నమః',
           },
           {
              "title": 'ఓం కర్మబ్రహ్మమయ్యై నమః',
           },
           {
              "title": 'ఓం కామకలనాయై నమః',
           },
           {
              "title": 'ఓం కాంక్షితార్థదాయై నమః',
           },
           {
              "title": 'ఓం చంద్రార్కాయితతాటంకాయై నమః ||70||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం చిదంబరశరీరిణ్యై నమః',
           },
           {
              "title": 'ఓం శ్రీచక్రవాసిన్యై నమః',
           },
           {
              "title": 'ఓం దేవ్యై నమః',
           },
           {
              "title": 'ఓం కామేశ్వరపత్న్యై నమః',
           },
           {
              "title": 'ఓం కమలాయై నమః',
           },
           {
              "title": 'ఓం మారారాతిప్రియార్ధాంగ్యై నమః',
           },
           {
              "title": 'ఓం మార్కండేయవరప్రదాయై నమః',
           },
           {
              "title": 'ఓం పుత్రపౌత్రవరప్రదాయై నమః',
           },
           {
              "title": 'ఓం పుణ్యాయై నమః',
           },
           {
              "title": 'ఓం పురుషార్థప్రదాయిన్యై నమః ||80||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సత్యధర్మరతాయై నమః',
           },
           {
              "title": 'ఓం సర్వసాక్షిణ్యై నమః',
           },
           {
              "title": 'ఓం శశాంకరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం శ్యామలాయై నమః',
           },
           {
              "title": 'ఓం బగళాయై నమః',
           },
           {
              "title": 'ఓం చండాయై నమః',
           },
           {
              "title": 'ఓం మాతృకాయై నమః',
           },
           {
              "title": 'ఓం భగమాలిన్యై నమః',
           },
           {
              "title": 'ఓం శూలిన్యై నమః',
           },
           {
              "title": 'ఓం విరజాయై నమః ||90||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం స్వాహాయై నమః',
           },
           {
              "title": 'ఓం స్వధాయై నమః',
           },
           {
              "title": 'ఓం ప్రత్యంగిరాంబికాయై నమః',
           },
           {
              "title": 'ఓం ఆర్యాయై నమః',
           },
           {
              "title": 'ఓం దాక్షాయిణ్యై నమః',
           },
           {
              "title": 'ఓం దీక్షాయై నమః',
           },
           {
              "title": 'ఓం సర్వవస్తూత్తమోత్తమాయై నమః',
           },
           {
              "title": 'ఓం శివాభిధానాయై నమః',
           },
           {
              "title": 'ఓం శ్రీవిద్యాయై నమః',
           },
           {
              "title": 'ఓం ప్రణవార్థస్వరూపిణ్యై నమః ||100||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం హ్రీంకార్యై నమః',
           },
           {
              "title": 'ఓం నాదరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం త్రిపురాయై నమః',
           },
           {
              "title": 'ఓం త్రిగుణాయై నమః',
           },
           {
              "title": 'ఓం ఈశ్వర్యై నమః',
           },
           {
              "title": 'ఓం సుందర్యై నమః',
           },
           {
              "title": 'ఓం స్వర్ణగౌర్యై నమః',
           },
           {
              "title": 'ఓం షోడశాక్షర దేవతాయై నమః ||108||',
           },
           {
              "title": '',
           },
           {
              "title": '|| ఇతి శ్రీ మంగళగౌరీ అష్టోత్తర శతనామావళి సమాప్తం ||',
           }
         ],
          "title": 'శ్రీ మంగళగౌరీ అష్టోత్తర శతనామావళి',
         // "img": require('../imgs/Astotaram/mangalagwori.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం భగవతే నమః',
           },
           {
              "title": 'ఓం సదాశివాయ నమః',
           },
           {
              "title": 'ఓం సకలతత్త్వాత్మకాయనమః',
           },
           {
              "title": 'ఓం సర్వమంత్రరూపాయ నమః',
           },
           {
              "title": 'ఓం సర్వయంత్రాధిష్ఠితాయ నమః',
           },
           {
              "title": 'ఓం తంత్రస్వరూపాయ నమః',
           },
           {
              "title": 'ఓం తత్త్వవిదూరాయ నమః',
           },
           {
              "title": 'ఓం బ్రహ్మరుద్రావతారిణే నమః',
           },
           {
              "title": 'ఓం నీలకంఠాయ నమః',
           },
           {
              "title": 'ఓం పార్వతీప్రియాయ నమః ||10||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సోమసూర్యాగ్నిలోచనాయనమః',
           },
           {
              "title": 'ఓం భస్మోద్ధూళిత విగ్రహాయ నమః',
           },
           {
              "title": 'ఓం మహామణిమకుట ధారణాయనమః',
           },
           {
              "title": 'ఓం మాణిక్య భూషణాయ నమః',
           },
           {
              "title": 'ఓం సృష్టిస్థితి ప్రళయకాల రౌద్రావతారాయ నమః',
           },
           {
              "title": 'ఓం దక్షాధ్వరధ్వంసకాయ నమః',
           },
           {
              "title": 'ఓం మహాకాల బేధకాయ నమః',
           },
           {
              "title": 'ఓం మూలాధారైక నిలయాయ నమః',
           },
           {
              "title": 'ఓం తత్త్వాతీకాయ నమః',
           },
           {
              "title": 'ఓం గంగాధరాయ నమః ||20||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సర్వదేవాధిదేవాయ నమః',
           },
           {
              "title": 'ఓం వేదాన్త సారాయ నమః',
           },
           {
              "title": 'ఓం త్రివర్గ సాధనాయ నమః',
           },
           {
              "title": 'ఓం అనేకకోటి బ్రహ్మాండనాయకాయ నమః',
           },
           {
              "title": 'ఓం అనంతాదినాగ కులభూషణాయ నమః',
           },
           {
              "title": 'ఓం ప్రణవ స్వరూపాయ నమః',
           },
           {
              "title": 'ఓం చిదాకాశాయ నమః',
           },
           {
              "title": 'ఓం ఆకాశాది స్వరూపాయ నమః',
           },
           {
              "title": 'ఓం గ్రహనక్షత్రమాలినే నమః',
           },
           {
              "title": 'ఓం సకలాయ నమః ||30||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం కళంకరహితాయ నమః',
           },
           {
              "title": 'ఓం సకలలోకైకకర్త్రే నమః',
           },
           {
              "title": 'ఓం సకలలోకైక సంహర్త్రే నమః',
           },
           {
              "title": 'ఓం సకలనిగమ గుహ్యాయ నమః',
           },
           {
              "title": 'ఓం సకలవేదాన్తపారగాయ నమః',
           },
           {
              "title": 'ఓం సకలలోకైక వరప్రదాయ నమః',
           },
           {
              "title": 'ఓం సకల లోకైక శంకరాయ నమః',
           },
           {
              "title": 'ఓం శశాంక శేఖరాయ నమః',
           },
           {
              "title": 'ఓం శాశ్వత నిజావాసాయ నమః',
           },
           {
              "title": 'ఓం నిరాభాసాయ నమః ||40||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం నిరామయాయ నమః',
           },
           {
              "title": 'ఓం నిర్మలాయ నమః',
           },
           {
              "title": 'ఓం నిర్లోభాయ నమః',
           },
           {
              "title": 'ఓం నిర్మోహాయ నమః',
           },
           {
              "title": 'ఓం నిర్మదాయ నమః',
           },
           {
              "title": 'ఓం నిశ్చినాయ నమః',
           },
           {
              "title": 'ఓం నిరహంకారాయ నమః',
           },
           {
              "title": 'ఓం నిరాకులాయ నమః',
           },
           {
              "title": 'ఓం నిష్కళంకాయ నమః',
           },
           {
              "title": 'ఓం నిర్గుణాయ నమః ||50||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం నిష్కామాయ నమః',
           },
           {
              "title": 'ఓం నిరుపప్లవాయ నమః',
           },
           {
              "title": 'ఓం నిరువద్యాయ నమః',
           },
           {
              "title": 'ఓం నిరన్తరాయ నమః',
           },
           {
              "title": 'ఓం నిష్కారణాయ నమః',
           },
           {
              "title": 'ఓం నిరాతంకాయ నమః',
           },
           {
              "title": 'ఓం నిష్ప్రపంచాయ నమః',
           },
           {
              "title": 'ఓం నిస్సంగాయ నమః',
           },
           {
              "title": 'ఓం నిర్ద్వంద్వాయ నమః',
           },
           {
              "title": 'ఓం నిరాధారాయ నమః ||60||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం నిరోగాయ నమః',
           },
           {
              "title": 'ఓం నిష్కోధాయ నమః',
           },
           {
              "title": 'ఓం నిర్గమాయ నమః',
           },
           {
              "title": 'ఓం నిర్భయాయ నమః',
           },
           {
              "title": 'ఓం నిర్వికల్పాయ నమః',
           },
           {
              "title": 'ఓం నిర్భేదాయ నమః',
           },
           {
              "title": 'ఓం నిష్కియాయ',
           },
           {
              "title": 'ఓం నిస్తులాయ నమః',
           },
           {
              "title": 'ఓం నిస్సంశయాయ నమః',
           },
           {
              "title": 'ఓం నిరంజనాయ నమః ||70||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం నిరూప విభవాయ నమః',
           },
           {
              "title": 'ఓం నిత్యశుద్ధబుద్ధ పరిపూర్ణాయ నమః',
           },
           {
              "title": 'ఓం నిత్యాయ నమః',
           },
           {
              "title": 'ఓం శుద్దాయ నమః',
           },
           {
              "title": 'ఓం బుద్దాయ నమః',
           },
           {
              "title": 'ఓం పరిపూర్ణాయ నమః',
           },
           {
              "title": 'ఓం సచ్చిదానందాయ నమః',
           },
           {
              "title": 'ఓం అదృశ్యాయ నమః',
           },
           {
              "title": 'ఓం పరమశాన స్వరూపాయ నమః',
           },
           {
              "title": 'ఓం తేజోరూపాయ నమః ||80||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం తేజోమయాయ నమః',
           },
           {
              "title": 'ఓం మహారౌద్రాయ నమః',
           },
           {
              "title": 'ఓం భద్రావతారాయ నమః',
           },
           {
              "title": 'ఓం మహాభైరవాయ నమః',
           },
           {
              "title": 'ఓం కాలభైరవాయ నమః',
           },
           {
              "title": 'ఓం కల్పాంత భైరవాయ నమః',
           },
           {
              "title": 'ఓం కపాలమాలాధరాయనమః',
           },
           {
              "title": 'ఓం ఖట్వాంగాయ నమః',
           },
           {
              "title": 'ఓం ఖడ్గపాశాంకుశధరాయనమః',
           },
           {
              "title": 'ఓం ఢమరుత్రిశూలచాపధరాయ నమః ||90||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం బాణగదాశక్తి భిన్డిపాలధరాయనమః',
           },
           {
              "title": 'ఓం తోమరముసలముద్గరధాయ నమః',
           },
           {
              "title": 'ఓం పట్టినపరశుపరిఘధరాయ నమః',
           },
           {
              "title": 'ఓం భుశుణ్ణిశతఘ్ని చక్రాద్యాయుధ ధరాయ నమః',
           },
           {
              "title": 'ఓం భీషణకర సహస్రముఖాయనమః',
           },
           {
              "title": 'ఓం వికటాట్టహాస విస్పారితాయ నమః',
           },
           {
              "title": 'ఓం బ్రహ్మాండమండలాయ నమః',
           },
           {
              "title": 'ఓం నాగేంద్రకుండలాయ నమః',
           },
           {
              "title": 'ఓం నాగేంద్రహారాయ నమః',
           },
           {
              "title": 'ఓం నాగేంద్రవలయాయ నమః ||100||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం నాగేంద్ర చర్మధరాయ నమః',
           },
           {
              "title": 'ఓం మృత్యుంజయాయ నమః',
           },
           {
              "title": 'ఓం త్ర్యంబకాయ నమః',
           },
           {
              "title": 'ఓం త్రిపురాన్తకాయ నమః',
           },
           {
              "title": 'ఓం విరూపాక్షాయ నమః',
           },
           {
              "title": 'ఓం విశ్వేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం విశ్వరూపాయ నమః',
           },
           {
              "title": 'ఓం విశ్వతోముఖాయ నమః ||108||',
           },
           {
              "title": '',
           },
           {
              "title": '|| ఇతి శ్రీ మృత్యుంజయ అష్టోత్తర శతనామావళి సమాప్తం ||',
           }
         ],
          "title": 'శ్రీ మృత్యుంజయ అష్టోత్తర శతనామావళి',
         // "img": require('../imgs/Astotaram/Mrityunjaya.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం దివ్యలోకవాసిన్యై నమః',
           },
           {
              "title": 'ఓం సర్వలోక సంరక్షణాయై నమః',
           },
           {
              "title": 'ఓం సర్వమృత్యుసర్వాపద్వినివారణ్యై నమః',
           },
           {
              "title": 'ఓం లలితాబాలా, దుర్గాశ్యామలాకృత్యై నమః',
           },
           {
              "title": 'ఓం గంగా,భవానీ గాయత్రీ స్వరూపాయై నమః',
           },
           {
              "title": 'ఓం లక్ష్మీ, పార్వతీ, సరస్వతీ, స్వరూప విభవాయై నమః',
           },
           {
              "title": 'ఓం రాజరాజేశ్వరీ దేవ్యై నమః',
           },
           {
              "title": 'ఓం భక్తాభీష్టదాయిన్యై నమః',
           },
           {
              "title": 'ఓం భక్తి భుక్తి ముక్తి ప్రదాయిన్యై నమః',
           },
           {
              "title": 'ఓం భక్తసంకల్పసిద్ధిదాయై నమః  ||10||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం పృధ్వీశ్వరీ దేవ్యై నమః',
           },
           {
              "title": 'ఓం ఆధివ్యాధి నివారిణ్యై నమః',
           },
           {
              "title": 'ఓం దౌర్భాగ్యనాశిన్యై నమః',
           },
           {
              "title": 'ఓం సౌభాగ్యదాయిన్యై నమః',
           },
           {
              "title": 'ఓం సృష్టి స్థితిలయాయై నమః',
           },
           {
              "title": 'ఓం అష్టసిద్ధి నవనిధి ప్రదాయిన్యై నమః',
           },
           {
              "title": 'ఓం అష్టదిక్పాలక వందితాయై నమః',
           },
           {
              "title": 'ఓం త్రికాల వేదిన్యై నమః',
           },
           {
              "title": 'ఓం షడ్గుణ సం సేవితాయై నమః',
           },
           {
              "title": 'ఓం షడ్రుతు పరివేష్టితాయై నమః  ||20||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం నవగ్రహవిధివిధానాధిష్టానాయై నమః',
           },
           {
              "title": 'ఓం సత్యధర్మ శాంతి ప్రేమ ప్రసాదిన్యై నమః',
           },
           {
              "title": 'ఓం సర్వకాల సర్వావస్థా సమస్థితాయై నమః',
           },
           {
              "title": 'ఓం అనంతసాగర, నదీనదా కృత్యై నమః',
           },
           {
              "title": 'ఓం కాంస్య లోహమయ ప్రాకారిణ్యై నమః',
           },
           {
              "title": 'ఓం పీత లోహమయి ప్రాకారిణ్యై నమః',
           },
           {
              "title": 'ఓం తామ్ర లోహమయ ప్రాకారిణ్యై నమః',
           },
           {
              "title": 'ఓం సీసలోహమయ ప్రాకారిణ్యై నమః',
           },
           {
              "title": 'ఓం పంచలోహమయ ప్రాకారిణ్యై నమః',
           },
           {
              "title": 'ఓం రజితసాల ప్రాకారిణ్యై నమః  ||30||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సువర్ణసాల ప్రాకారిణ్యై నమః',
           },
           {
              "title": 'ఓం పుష్యరాగమయ ప్రాకారిణ్యై నమః',
           },
           {
              "title": 'ఓం పద్మరాగమయ ప్రకారిణ్యై నమః',
           },
           {
              "title": 'ఓం గోమేధికమణిమయ ప్రాకారిణ్యై నమః',
           },
           {
              "title": 'ఓం వజ్రనిర్మిత ప్రాకారిణ్యై నమః',
           },
           {
              "title": 'ఓం వైడూర్యనిర్మిత ప్రాకారిణ్యై నమః',
           },
           {
              "title": 'ఓం ఇంద్రనీలమణిమయ ప్రాకారిణ్యై నమః',
           },
           {
              "title": 'ఓం మరకతసాలమయ ప్రాకారిణ్యై నమః',
           },
           {
              "title": 'ఓం ప్రవాళసాలమయ ప్రాకారిణ్యై నమః',
           },
           {
              "title": 'ఓం రత్నసాలమయ ప్రాకారిణ్యై నమః  ||40||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం చింతామణిమయ ప్రాకారిణ్యై నమః',
           },
           {
              "title": 'ఓం శృంగారమండప దేవదేవతాయై నమః',
           },
           {
              "title": 'ఓం జ్ఞానమండప జ్ఞానేశ్వరీదేవ్యై నమః',
           },
           {
              "title": 'ఓం ఏకాంతమండప ధ్యానేశ్వరీదేవ్యై',
           },
           {
              "title": 'ఓం ముక్తిమండప ముక్తేశ్వరీదేవ్యై నమః',
           },
           {
              "title": 'ఓం కాశ్మీరవన కామాక్షీదేవ్యై నమః',
           },
           {
              "title": 'ఓం మల్లికావన మహారాజ్ఞై నమః',
           },
           {
              "title": 'ఓం కుందవన కౌమారీదేవ్యై నమః',
           },
           {
              "title": 'ఓం కస్తూరీవనకామేశ్వరీ దేవ్యై నమః',
           },
           {
              "title": 'ఓం సాలోక్యముక్తి ప్రసాదిన్యై నమః  ||50||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సారూప్యముక్తి ప్రదాయిన్యై నమః',
           },
           {
              "title": 'ఓం సామీప్యముక్తిదాయిన్యై నమః',
           },
           {
              "title": 'ఓం సాయుజ్యముక్తి సుప్రసాదిన్యై నమః',
           },
           {
              "title": 'ఓం ఇచ్చాజ్ఞాన క్రియాశక్తి రూపిణ్యై నమః',
           },
           {
              "title": 'ఓం వరాంకుశపాశాభయ హస్తాయై నమః',
           },
           {
              "title": 'ఓం సహస్రకోటి సహస్రవదనాయై నమః',
           },
           {
              "title": 'ఓం మకరం దఘృతాంబుధయే నమః',
           },
           {
              "title": 'ఓం సహస్రకోటి సహస్రచంద్ర సమసుధానేత్రాయై నమః',
           },
           {
              "title": 'ఓం సహస్రకోటి సహస్ర సూర్య సమాభాసాయై నమః',
           },
           {
              "title": 'ఓం జరామరణ రహితాయై నమః  ||60||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం నారదతుంబురు సకల మునిగణవందితాయై నమః',
           },
           {
              "title": 'ఓం పంచభూతయజమాన స్వరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం జన్మజన్మాంతర దుఃఖభంజనాయై నమః',
           },
           {
              "title": 'ఓం లోకరక్షాకృత్యతత్పరాయై నమః',
           },
           {
              "title": 'ఓం బ్రహ్మవిష్ణు మహేశ్వర కోటి వందితాయై నమః',
           },
           {
              "title": 'ఓం చతుషష్టి కళా సంపూర్ణ స్వరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం షోడశకళా శక్తి సేనా సమన్వితాయై నమః',
           },
           {
              "title": 'ఓం సప్తకోటి ఘనమంత్ర విద్యాలయాయై నమః',
           },
           {
              "title": 'ఓం మదన విఘ్నేశ్వర కుమార మాతృకాయై నమః',
           },
           {
              "title": 'ఓం కుంకుమ శోభిత దివ్య వదనాయై నమః ||70||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అనంతనక్షత్ర గణనాయికాయై నమః',
           },
           {
              "title": 'ఓం చతుర్దశభువన కల్పితాయై నమః',
           },
           {
              "title": 'ఓం సురాధినాథ సత్సంగ సమాచార కార్యకలాపాయై నమః',
           },
           {
              "title": 'ఓం అనంగరూపపరిచారికా సేవతాయై నమః',
           },
           {
              "title": 'ఓం గంధర్వ యక్షకిన్నర కింపురుష వందితాయై నమః',
           },
           {
              "title": 'ఓం సంతాన కల్పవృక్ష సముదాయ భాసిన్యై నమః',
           },
           {
              "title": 'ఓం అనంతకోటి బ్రహ్మాండ సైనికాధ్యక్ష సేవితాయై నమః',
           },
           {
              "title": 'ఓం పారిజాత, కదంబనవిహారిణ్యై నమః',
           },
           {
              "title": 'ఓం సమస్తదేవీ కుటుంబ వందితాయై నమః',
           },
           {
              "title": 'ఓం చతుర్వేద కళాచాతుర్యై నమః  ||80||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం బ్రాహ్మీ మహేశ్వరీ వైష్ణవీ వారాహీ వందితాయై నమః',
           },
           {
              "title": 'ఓం చాముండీ మహాలక్ష్మీ ఇంద్రాణీ పరిపూజితాయై నమః',
           },
           {
              "title": 'ఓం షట్కోణ యంత్ర ప్రకాశిన్యై నమః',
           },
           {
              "title": 'ఓం సహస్రస్తంభ మండపవిహారిణ్యై నమః',
           },
           {
              "title": 'ఓం సమస్త పతివ్రతాసం సేవితాయై నమః',
           },
           {
              "title": 'ఓం నాదబిందు కళాతీత శ్రీ చక్రవాసిన్యై నమః',
           },
           {
              "title": 'ఓం పాపతాప దారిద్ర్య నాశిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రుతి, స్మృతి, పురాణ కావ్య సంరక్షణాయై నమః',
           },
           {
              "title": 'ఓం పంచబ్రహ్మాసన విరాజితాయై నమః',
           },
           {
              "title": 'ఓం వజ్రవైడూర్య మరకత మాణిక్య చంద్రకాంత రత్నసింహాసన శోభితాయై నమః ||90||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం దివ్యాంబర ప్రభాదివ్యతేజో విభాసాయై నమః',
           },
           {
              "title": 'ఓం పంచముఖ సర్వేశ్వర హృదయాధిష్టానాయై నమః',
           },
           {
              "title": 'ఓం ఆపాద మస్తక నవరత్న సువర్ణాభరణ ధారిణ్యై నమః',
           },
           {
              "title": 'ఓం విలాసినీ అఘోరా మంగళాసనా పీఠశక్తి వందితాయై నమః',
           },
           {
              "title": 'ఓం క్షమా, దయా, జయా, విజయా పీఠశక్తి పరిపాలితాయై నమః',
           },
           {
              "title": 'ఓం అజితా, అపరాజితా, నిత్యపీఠశక్తి పరిపూజితాయై నమః',
           },
           {
              "title": 'ఓం సిద్ధి, బుద్ధి, మేధా, లక్ష్మీ, శృతి పీఠశక్తి సేవితాయై నమః',
           },
           {
              "title": 'ఓం లజ్జాతుష్టిపుష్టి పీఠశక్తి ప్రభాసితాయై నమః',
           },
           {
              "title": 'ఓం నవరాత్ర దీక్షా ప్రియాయై నమః',
           },
           {
              "title": 'ఓం నామ, గాన, జ్ఞాన యజ్ఞ ప్రియాయై నమః ||100||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం జపతపో యోగత్యాగ సంతుష్టాయై నమః',
           },
           {
              "title": 'ఓం పంచదశీ మహావిద్యాయై నమః',
           },
           {
              "title": 'ఓం సదాషోడశ ప్రాయసర్వేశ్వర వల్లభాయై నమః',
           },
           {
              "title": 'ఓం ఓంకారాక్షర స్వరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం సకలయంత్ర సకల తంత్ర సమర్చితాయై నమః',
           },
           {
              "title": 'ఓం సహస్ర యోజన ప్రమాణ, చింతామణి గృహవాసిన్యై నమః',
           },
           {
              "title": 'ఓం మహాదేవసహిత శ్రీ పరమేశ్వరీ దేవ్యై నమః',
           },
           {
              "title": 'ఓం మణిద్వీప విరాజిత మహా భువనేశ్వరీ దేవ్యై నమః ||108||',
           },
           {
              "title": '',
           },
           {
              "title": '|| శ్రీ మణిద్వీపేశ్వరి అష్టోత్తర శతనామావళి సమాప్తం ||',
           }
         ],
          "title": 'శ్రీ మణిద్వీపేశ్వరి అష్టోత్తర శతనామావళి',
         // "img": require('../imgs/Astotaram/manidweepam.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం శ్రీమతే నమః',
           },
           {
              "title": 'ఓం వరాహాయ నమః',
           },
           {
              "title": 'ఓం సింహాద్రివాసాయ నమః',
           },
           {
              "title": 'ఓం శ్రీవత్సలక్షణాయ నమః',
           },
           {
              "title": 'ఓం అశ్రితాభీష్టవరదాయ నమః',
           },
           {
              "title": 'ఓం అమేయాయ నమః',
           },
           {
              "title": 'ఓం చతుర్భుజాయ నమః',
           },
           {
              "title": 'ఓం చందన ప్రవిలిప్తాంగాయ నమః',
           },
           {
              "title": 'ఓం సార్వభౌమాయ నమః',
           },
           {
              "title": 'ఓం దుష్టభూభృద్ధరాయ నమః ||10||',
           },
           {
              "title": '',
           }, {
              "title": 'ఓం స్వామినే నమః',
           },
           {
              "title": 'ఓం రాజారాజార్చితాయఃప్రభవే నమః',
           },
           {
              "title": 'ఓం పాతాళాంతస్థ పాదాబాయ నమః',
           },
           {
              "title": 'ఓం భక్తజీవనదాయ నమః',
           },
           {
              "title": 'ఓం పుంసే నమః',
           },
           {
              "title": 'ఓం యజ్ఞమూర్తయే నమః',
           },
           {
              "title": 'ఓం యజ్ఞసాక్షిణే నమః',
           },
           {
              "title": 'ఓం యజ్ఞభుజే నమః',
           },
           {
              "title": 'ఓం యజ్ఞరక్షకాయ నమః',
           },
           {
              "title": 'ఓం మహాయజ్ఞవరాహాయ నమః ||20||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మహాకారుణ్యరూపధృతే నమః',
           },
           {
              "title": 'ఓం ఘర్ఘరారావ నిర్దూత శాత్రవాయ నమః',
           },
           {
              "title": 'ఓం శ్రీకరాయ నమః',
           },
           {
              "title": 'ఓం శుచయే నమః',
           },
           {
              "title": 'ఓం మహాబ్ధేస్తేరవాసాయ నమః',
           },
           {
              "title": 'ఓం మహాతేజసే నమః',
           },
           {
              "title": 'ఓం ప్రహ్లాదార్చిత పాదాభాయ నమః',
           },
           {
              "title": 'ఓం ప్రభుసత్తమాసేవితాయ నమః',
           },
           {
              "title": 'ఓం దివ్యవైభవ సంయుక్తాయ నమః',
           },
           {
              "title": 'ఓం శ్రీనివాసాయ నమః ||30||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం కృపానిధయే నమః',
           },
           {
              "title": 'ఓం శ్రీరమాధిష్టితోరసే నమః',
           },
           {
              "title": 'ఓం శ్రితభక్తార్తినాశకాయ నమః',
           },
           {
              "title": 'ఓం విశాఖపట్టణాధీశాయ నమః',
           },
           {
              "title": 'ఓం సర్వరోగోపహాయ నమః',
           },
           {
              "title": 'ఓం ప్రభవే నమః',
           },
           {
              "title": 'ఓం హిరణ్యాక్ష నిహంత్రే నమః',
           },
           {
              "title": 'ఓం హిరణ్మయ విభూషణాయ నమః',
           },
           {
              "title": 'ఓం సింహాద్రిశిఖరావాసినే నమః',
           },
           {
              "title": 'ఓం ఆంధ్రభూపార్తితాయ నమః ||40||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం స్వరాజే నమః',
           },
           {
              "title": 'ఓం చందనాలంకృతాయ నమః',
           },
           {
              "title": 'ఓం మందారసమమాల్యవతే నమః',
           },
           {
              "title": 'ఓం వజ్రరోమధరాయ నమః',
           },
           {
              "title": 'ఓం విష్ణవే నమః',
           },
           {
              "title": 'ఓం వేదవ్యాసమునిస్తుతాయ నమః',
           },
           {
              "title": 'ఓం శ్వేతరుచే నమః',
           },
           {
              "title": 'ఓం శ్వేతదృశే నమః',
           },
           {
              "title": 'ఓం శ్వేతదంష్ట్రికాతుండ మండితాయ నమః',
           },
           {
              "title": 'ఓం మనోహరాయ నమః ||50||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శంఖచక్రగదా అభయధరాయ నమః',
           },
           {
              "title": 'ఓం హరయే నమః',
           },
           {
              "title": 'ఓం పాతాళమగ్న భూదేవీ రక్షకాయ నమః',
           },
           {
              "title": 'ఓం శౌనకాయ నమః',
           },
           {
              "title": 'ఓం స్వభువే నమః',
           },
           {
              "title": 'ఓం స్వామి పుష్కరిణీ తీరవాసాయ నమః',
           },
           {
              "title": 'ఓం వేంకటాశ్రయాయ నమః',
           },
           {
              "title": 'ఓం కుందమందార పున్నాగ పారిజాతార్చన ప్రియాయ నమః',
           },
           {
              "title": 'ఓం తాళోత్తుంగమహాదివ్య విమానాంతర సంస్థితాయ నమః',
           },
           {
              "title": 'ఓం మహామంటపయుక్తాయ నమః ||60||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం యతిరాజసమర్చితాయ నమః',
           },
           {
              "title": 'ఓం భూశయాయ నమః',
           },
           {
              "title": 'ఓం భూప్రియాయ నమః',
           },
           {
              "title": 'ఓం భూతయే నమః',
           },
           {
              "title": 'ఓం భూనాతి ప్రియాయ నమః',
           },
           {
              "title": 'ఓం అచ్యుతాయ నమః',
           },
           {
              "title": 'ఓం సులభాయ నమః',
           },
           {
              "title": 'ఓం సురశాయ నమః',
           },
           {
              "title": 'ఓం స్థూలాయ నమః',
           },
           {
              "title": 'ఓం సూక్ష్మాయ నమః ||70||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సర్వగుహాశయాయ నమః',
           },
           {
              "title": 'ఓం సర్వాత్మనే నమః',
           },
           {
              "title": 'ఓం సర్వలోకాత్మనే నమః',
           },
           {
              "title": 'ఓం రమాలింగితతోషితాయ నమః',
           },
           {
              "title": 'ఓం భక్తాభయప్రదాయ నమః',
           },
           {
              "title": 'ఓం భక్తవాంఛితార్థాయ నమః',
           },
           {
              "title": 'ఓం జగత్పతయే నమః',
           },
           {
              "title": 'ఓం జగద్ధాత్రే నమః',
           },
           {
              "title": 'ఓం జగత్తాత్రే నమః',
           },
           {
              "title": 'ఓం జగన్నేత్రే నమః ||80||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం జగత్పిత్రే నమః',
           },
           {
              "title": 'ఓం చిదచిద్రవిటణాయ నమః',
           },
           {
              "title": 'ఓం శాంగిణే నమః',
           },
           {
              "title": 'ఓం శంఖినే నమః',
           },
           {
              "title": 'ఓం చక్రిణే నమః',
           },
           {
              "title": 'ఓం గదినే నమః',
           },
           {
              "title": 'ఓం జయినే నమః',
           },
           {
              "title": 'ఓం శ్రీ ఘనాయ నమః',
           },
           {
              "title": 'ఓం శ్రీ నిధానాయ నమః',
           },
           {
              "title": 'ఓం శ్రియై నమః ||90||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శ్రియఃపతయే నమః',
           },
           {
              "title": 'ఓం శ్రీమతాంవరాయ నమః',
           },
           {
              "title": 'ఓం ధర్మకృతే నమః',
           },
           {
              "title": 'ఓం ధర్మభృతే నమః',
           },
           {
              "title": 'ఓం ధర్మిణే నమః',
           },
           {
              "title": 'ఓం ధర్మరూపిణే నమః',
           },
           {
              "title": 'ఓం ధనంజయాయ నమః',
           },
           {
              "title": 'ఓం పరాత్పరాయ నమః',
           },
           {
              "title": 'ఓం శేషిణే నమః',
           },
           {
              "title": 'ఓం పద్మమాలాప్రియాయ నమః ||100||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సమాయ నమః',
           },
           {
              "title": 'ఓం శ్రీమల్లక్ష్మీవరాహాయ నమః',
           },
           {
              "title": 'ఓం నిర్ణేతుకదయాంబుధయే నమః',
           },
           {
              "title": 'ఓం ప్రణతాభయదాయ నమః',
           },
           {
              "title": 'ఓం శ్రీశాయ నమః',
           },
           {
              "title": 'ఓం ప్రణవాత్మ స్వరూపాయ నమః',
           },
           {
              "title": 'ఓం సర్వభక్తభయాపహాయ నమః',
           },
           {
              "title": 'ఓం శ్రీలక్ష్మీవరాహాయ నమః ||108||',
           },
           {
              "title": '',
           },
           {
              "title": '|| ఇతి శ్రీ లక్ష్మీవరాహ అష్టోత్తర శతనామావళి సంపూర్ణం ||',
           }
         ],
          "title": 'శ్రీ లక్ష్మీ వరాహ అష్టోత్తర శతనామావళి',
         // "img": require('../imgs/Astotaram/lakshmi_varaha.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం శివాయ నమః',
           },
           {
              "title": 'ఓం సర్వేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం శంభవే నమః',
           },
           {
              "title": 'ఓం త్ర్యక్షాయ నమః',
           },
           {
              "title": 'ఓం దాక్షాయణీ పతయే నమః',
           },
           {
              "title": 'ఓం విశ్వేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం విశ్వయోనయో నమః',
           },
           {
              "title": 'ఓం శాశ్వతాయ నమః',
           },
           {
              "title": 'ఓం చంద్రశేఖరాయ నమః',
           },
           {
              "title": 'ఓం శంకరాయ నమః ||10||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం పంకజాలోకాయ నమః',
           },
           {
              "title": 'ఓం శూలపాణయే నమః',
           },
           {
              "title": 'ఓం త్రిలోచనాయ నమః',
           },
           {
              "title": 'ఓం కపర్దినే నమః',
           },
           {
              "title": 'ఓం కరుణాసింధవే నమః',
           },
           {
              "title": 'ఓం కాలకంఠాయ నమః',
           },
           {
              "title": 'ఓం కళానిధయే నమః',
           },
           {
              "title": 'ఓం విశ్వరూపాయ నమః',
           },
           {
              "title": 'ఓం విరూపాక్షాయ నమః',
           },
           {
              "title": 'ఓం శ్రుతివిదే నమః ||20||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం గిరిజాపతయే నమః',
           },
           {
              "title": 'ఓం అంధకధ్వంసనాయ నమః',
           },
           {
              "title": 'ఓం సత్యాయ నమః',
           },
           {
              "title": 'ఓం సత్యరూపిణే నమః',
           },
           {
              "title": 'ఓం సురేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం కపాలినే నమః',
           },
           {
              "title": 'ఓం కాలసర్పఘ్నాయ నమః',
           },
           {
              "title": 'ఓం కుంజరాసురభంజనాయ నమః',
           },
           {
              "title": 'ఓం పరమాత్మనే నమః',
           },
           {
              "title": 'ఓం పరానందమూర్తయే నమః ||30||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం పన్నగభూషణాయ నమః',
           },
           {
              "title": 'ఓం పంచవక్రాయ నమః',
           },
           {
              "title": 'ఓం పరబ్రహ్మణే నమః',
           },
           {
              "title": 'ఓం పార్వతీశాయ నమః',
           },
           {
              "title": 'ఓం పరాత్పరాయ నమః',
           },
           {
              "title": 'ఓం పుణ్యమూర్తయే నమః',
           },
           {
              "title": 'ఓం మహామూర్తయే నమః',
           },
           {
              "title": 'ఓం దక్షిణామూర్తయే నమః',
           },
           {
              "title": 'ఓం అవ్యయాయ నమః',
           },
           {
              "title": 'ఓం భవాయ నమః ||40||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం పరమకల్యాణనిధయే నమః',
           },
           {
              "title": 'ఓం భవభయాపహాయ నమః',
           },
           {
              "title": 'ఓం విశ్వత్రాయ నమః',
           },
           {
              "title": 'ఓం విశ్వరక్షైకాయ నమః',
           },
           {
              "title": 'ఓం విశ్వోద్భయై నమః',
           },
           {
              "title": 'ఓం విశ్వమంగళాయ నమః',
           },
           {
              "title": 'ఓం త్రిపురారయే నమః',
           },
           {
              "title": 'ఓం త్రిలోకేశాయ నమః',
           },
           {
              "title": 'ఓం త్రిధాఘ్నాయ నమః',
           },
           {
              "title": 'ఓం త్రిగుణాశ్రయాయ నమః ||50||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం గౌరీశాయ నమః',
           },
           {
              "title": 'ఓం శాస్త్రే నమః',
           },
           {
              "title": 'ఓం అఘోరాయ నమః',
           },
           {
              "title": 'ఓం నీలలోహితాయ నమః',
           },
           {
              "title": 'ఓం వ్యోమకేశాయ నమః',
           },
           {
              "title": 'ఓం త్రిలోకేశాయ నమః',
           },
           {
              "title": 'ఓం శంబరాసురభేదనాయ నమః',
           },
           {
              "title": 'ఓం జగదాదయే నమః',
           },
           {
              "title": 'ఓం జగన్నాథాయ నమః',
           },
           {
              "title": 'ఓం జగద్గురవే నమః ||60||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం భీమాయ నమః',
           },
           {
              "title": 'ఓం సురగణశ్రేష్ఠాయ నమః',
           },
           {
              "title": 'ఓం పినాకినే నమః',
           },
           {
              "title": 'ఓం పరమేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం జటిలాయ నమః',
           },
           {
              "title": 'ఓం నిటలాలోకాయ నమః',
           },
           {
              "title": 'ఓం నటాయ నమః',
           },
           {
              "title": 'ఓం నాట్యవిశారదాయ నమః',
           },
           {
              "title": 'ఓం గర్వితాసురభిదే నమః',
           },
           {
              "title": 'ఓం గాత్రే నమః ||70||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం గరభుజేనమః నమః',
           },
           {
              "title": 'ఓం గౌతమస్తుతాయ నమః',
           },
           {
              "title": 'ఓం సద్యోజాతాయ నమః',
           },
           {
              "title": 'ఓం సహస్రార్చిషే నమః',
           },
           {
              "title": 'ఓం స్వప్రకాశక చిన్నయాయ నమః',
           },
           {
              "title": 'ఓం ఉగ్రాయ నమః',
           },
           {
              "title": 'ఓం పశుపతయే నమః',
           },
           {
              "title": 'ఓం భర్గాయ నమః',
           },
           {
              "title": 'ఓం ధైర్యస్థైర్య విధాయకాయ నమః',
           },
           {
              "title": 'ఓం జటిలాయ నమః ||80||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సర్వభూతేశాయ నమః',
           },
           {
              "title": 'ఓం సమాయ నమః',
           },
           {
              "title": 'ఓం తత్పురుషాయ నమః',
           },
           {
              "title": 'ఓం శివాయ నమః',
           },
           {
              "title": 'ఓం గంగాధరాయ నమః',
           },
           {
              "title": 'ఓం కళాశాలినే నమః',
           },
           {
              "title": 'ఓం సర్వదేవ శిరోమణయే నమః',
           },
           {
              "title": 'ఓం గౌరీ పతయే నమః',
           },
           {
              "title": 'ఓం గణాధీశాయ నమః',
           },
           {
              "title": 'ఓం గిరిధన్వనే నమః ||90||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం వృషధ్వజాయ నమః',
           },
           {
              "title": 'ఓం భసితోళిద్ధూళితాకారాయ నమః',
           },
           {
              "title": 'ఓం శరణాగత కామధు ఘే నమః',
           },
           {
              "title": 'ఓం వామదేవాయ నమః',
           },
           {
              "title": 'ఓం మహాదేవాయ నమః',
           },
           {
              "title": 'ఓం వసురేతసే నమః',
           },
           {
              "title": 'ఓం విధిస్తుతాయ నమః',
           },
           {
              "title": 'ఓం శ్రీశైల శిఖరావాసినే నమః',
           },
           {
              "title": 'ఓం విలాసినే నమః',
           },
           {
              "title": 'ఓం విశ్వ మంగళాయ నమః ||100||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం బ్రహ్మేంద్రాదిసురోపాస్యాయ నమః',
           },
           {
              "title": 'ఓం యోషిత్పుంభావ విగ్రహాయ నమః',
           },
           {
              "title": 'ఓం స్మేరప్రసన్న వదనాయ నమః',
           },
           {
              "title": 'ఓం సర్వలోకేశ్వరేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం కల్యాణసుగుణాధారాయ నమః',
           },
           {
              "title": 'ఓం పార్వతీ ప్రాణవల్లభాయ నమః',
           },
           {
              "title": 'ఓం శ్రీ పర్వత మణేర్మూర్ని వాసాయ నమః',
           },
           {
              "title": 'ఓం శ్రీ మల్లికార్జునాయ నమః ||108||',
           },
           {
              "title": '',
           },
           {
              "title": '|| ఇతి శ్రీ శైల మల్లికార్జున అష్టోత్తర శతనామావళి సంపూర్ణం ||',
           }
         ],
          "title": 'శ్రీశైల మల్లికార్జున అష్టోత్తర శతనామావళి',
         // "img": require('../imgs/Astotaram/srisailam.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం శ్రీ మానసా దేవ్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ పరాశక్త్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ మహాదేవ్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ కశ్యప మానస పుత్రికాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ నిరంతర ధ్యాననిష్ఠాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ ఏకాగ్రచిత్తాయై నమః',
           },
           {
              "title": 'ఓం తాపస్యై నమః',
           },
           {
              "title": 'ఓం శ్రీకర్యై నమః',
           },
           {
              "title": 'ఓం శ్రీకృష్ణ ధ్యాన నిరతాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ కృష్ణ సేవితాయై నమః  ||10||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శ్రీ త్రిలోక పూజితాయై నమః',
           },
           {
              "title": 'ఓం సర్ప మంత్రాధిష్ఠాత్ర్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ సర్ప దర్ప వినాశిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ సర్పగర్వ విమర్దిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ సర్పదోష నివారిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ కాలసర్పదోష నివారిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ సర్పహత్యా దోష హరిణ్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ సర్పబంధన విచ్చిన్న దోష నివారిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ సర్ప శాప విమోచన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ వల్మీక విచ్చిన్న దోష ప్రశమన్యై నమః ||20||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శ్రీ శివధ్యాన తపోనిష్ఠాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ శివ భక్త పరాయణాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ శివసాక్షాత్కార సంకల్పాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ సిద్ధ యోగిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ శివసాక్షాత్కార సిద్ధి దాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ శివ పూజ తత్పరాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ ఈశ్వర సేవితాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ శంకరారాధ్య దేవ్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ జరత్కారు ప్రియాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ జరత్కారు పత్న్యై నమః ||30||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శ్రీ జరత్కారు వామాంక నిలయాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ జగధీశ్వర్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ ఆస్తీక మాతాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ తక్షక ఇంద్రా రాధ్యా దేవ్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ జనమేజయ సర్ప యాగ విధ్వంసిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ తక్షక ఇంద్ర ప్రాణ రక్షిణ్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ దేవేంద్రాది సేవితాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ నాగలోక ప్రవేసిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ నాగలోక రక్షిణ్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ నాగస్వర ప్రియాయై నమః ||40||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శ్రీ నాగేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ నవనాగ సేవితాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ నవనాగ ధారిణ్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ సర్పకిరీట శోభితాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ నాగయజ్ఞోపవీతిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ నాగాభరణ దారిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ విశ్వమాతాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ ద్వాదశ విధ కాలసర్ప దోష నివారిణ్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ నాగమల్లి పుష్పా రాధ్యాయైనమః',
           },
           {
              "title": 'ఓం శ్రీ పరిమళ పుష్ప మాలికా దారిన్యై నమః ||50||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శ్రీ జాజి చంపక మల్లికా కుసుమ ప్రియాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ క్షీరాభిషేక ప్రియాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ క్షీరప్రియాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ క్షీరాన్న ప్రీత మానసాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ పరమపావన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ పంచమ్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ పంచ భూతేశ్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ పంచోపచార పూజా ప్రియాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ నాగ పంచమీ పూజా ఫల ప్రదాయిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ పంచమీ తిధి పూజా ప్రియాయై నమః ||60||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శ్రీ హంసవాహిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ అభయప్రదాయిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ కమలహస్తాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ పద్మపీట వాసిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ పద్మమాలా ధరాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ పద్మిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ పద్మనేత్రాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ మీనాక్ష్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ కామాక్ష్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ విశాలాక్ష్యై నమః ||70||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శ్రీ త్రినేత్రాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ బ్రహ్మకుండ క్షేత్ర నివాసిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ బ్రహ్మకుండ క్షేత్ర పాలిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ బ్రహ్మకుండ గోదావరి స్నాన సంతుస్టాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ వల్మీక పూజా సంతుస్టా యై నమః',
           },
           {
              "title": 'ఓం శ్రీ వల్మీక దేవాలయ నివాసిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ భక్తాబీష్ట ప్రదాయిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ భవబంధ విమోచన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ కుటుంబ కలహ నివారిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ కుటుంబ సౌఖ్య ప్రదాయిన్యై నమః ||80||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శ్రీ సంపూర్ణ ఆరోగ్య ఆయ్యుషు ప్రదాయిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ బాలారిష్ట దోష నివారిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ సత్సంతాన ప్రదాయిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ సమస్త దుఖ దారిద్య కష్ట నష్ట ప్రసమన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ శాంతి హోమ ప్రియాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ యజ్ఞ ప్రియాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ నవగ్రహదోష ప్రశమన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ శాంత్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ సర్వమంగళాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ శత్రు సంహారిన్యై నమః ||90||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శ్రీ హరిద్రాకుంకుమార్చన ప్రియాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ అపమృత్యు నివారిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ మంత్ర యంత్ర తంత్రారాధ్యా యై నమః',
           },
           {
              "title": 'ఓం శ్రీ సుందరాంగ్యే నమః',
           },
           {
              "title": 'ఓం శ్రీ హ్రీంకారిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ శ్రీం భీజ నిలయాయై నమః',
           },
           {
              "title": 'ఓం క్లీం కార బీజ సర్వస్వాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ ఏం బీజ శక్త్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ యోగమాయాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ కుండలిన్యై నమః ||100||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శ్రీ షట్ చక్ర బెదిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ మోక్షప్రదాయిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ శ్రీధర గురు నిలయవాసిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ శ్రీధర హృద యాంతరంగిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ శ్రీధర సంరక్షిన్యై  నమః',
           },
           {
              "title": 'ఓం శ్రీ శ్రీధరా రాధ్యాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ శ్రీధర వైభవ కారిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ సర్వశుభంకరిన్యై నమః ||108||',
           },
           {
              "title": '',
           },
           {
              "title": '|| ఇతి శ్రీ మానసా దేవీ అష్టోత్తర శతనామావళి సంపూర్ణం ||',
           }
         ],
          "title": 'శ్రీ మానసా దేవీ అష్టోత్తర శతనామావళి',
         // "img": require('../imgs/Astotaram/mansa_mata.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం శనైశ్చరాయ నమః',
           },
           {
              "title": 'ఓం శాంతాయ నమః',
           },
           {
              "title": 'ఓం సర్వాభీష్టప్రదాయినే నమః',
           },
           {
              "title": 'ఓం శరణ్యాయ నమః',
           },
           {
              "title": 'ఓం వరేణ్యాయ నమః',
           },
           {
              "title": 'ఓం సర్వేశాయ నమః',
           },
           {
              "title": 'ఓం సౌమ్యాయ నమః',
           },
           {
              "title": 'ఓం సురవంద్యాయ నమః',
           },
           {
              "title": 'ఓం సురలోకవిహారిణే నమః',
           },
           {
              "title": 'ఓం సుఖాసనోపవిష్టాయ నమః ||10||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సుందరాయ  నమః',
           },
           {
              "title": 'ఓం ఘనరూపాయ నమః',
           },
           {
              "title": 'ఓం ఘనాభరణధారిణే నమః',
           },
           {
              "title": 'ఓం ఘనసారవిలేపనాయ నమః',
           },
           {
              "title": 'ఓం ఖద్యోతాయ నమః',
           },
           {
              "title": 'ఓం మందాయ నమః',
           },
           {
              "title": 'ఓం మందచేష్టాయ నమః',
           },
           {
              "title": 'ఓం వైరాగ్యదాయ  నమః',
           },
           {
              "title": 'ఓం వీరాయ నమః',
           },
           {
              "title": 'ఓం వీతరోగభయాయ నమః ||20||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం విపత్పరంపరేశాయ నమః',
           },
           {
              "title": 'ఓం విశ్వవంద్యాయ నమః',
           },
           {
              "title": 'ఓం గృధ్రవాహాయ నమః',
           },
           {
              "title": 'ఓం గూఢాయ నమః',
           },
           {
              "title": 'ఓం కూర్మాంగాయ నమః',
           },
           {
              "title": 'ఓం కురూపిణే నమః',
           },
           {
              "title": 'ఓం కుత్సితాయ నమః',
           },
           {
              "title": 'ఓం గుణాఢ్యాయ నమః',
           },
           {
              "title": 'ఓం గోచరాయ నమః',
           },
           {
              "title": 'ఓం అవిద్యామూలనాశాయ నమః ||30||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం విద్యావిద్యాస్వరూపిణే నమః',
           },
           {
              "title": 'ఓం ఆయుష్యకారణాయ నమః',
           },
           {
              "title": 'ఓం ఆపదుద్ధర్త్రే నమః',
           },
           {
              "title": 'ఓం విష్ణుభక్తాయ నమః',
           },
           {
              "title": 'ఓం వశినే నమః',
           },
           {
              "title": 'ఓం వివిధాగమవేదినే నమః',
           },
           {
              "title": 'ఓం విధిస్తుత్యాయ నమః',
           },
           {
              "title": 'ఓం మహానీయగుణాత్మనే నమః',
           },
           {
              "title": 'ఓం మర్త్యపావనపాదాయ నమః',
           },
           {
              "title": 'ఓం మహేశాయ నమః ||40||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం ఛాయాపుత్రాయ నమః',
           },
           {
              "title": 'ఓం శర్వాయ నమః',
           },
           {
              "title": 'ఓం శరతూణీరధారిణే నమః',
           },
           {
              "title": 'ఓం చరస్థిరస్వభావాయ నమః',
           },
           {
              "title": 'ఓం చంచలాయ నమః',
           },
           {
              "title": 'ఓం నీలవర్ణాయ నమః',
           },
           {
              "title": 'ఓం నిత్యాయ నమః',
           },
           {
              "title": 'ఓం నీలాంజననిభాయ నమః',
           },
           {
              "title": 'ఓం నీలాంబరవిభూషాయ  నమః',
           },
           {
              "title": 'ఓం నిశ్చలాయ నమః ||50||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం వేద్యాయ నమః',
           },
           {
              "title": 'ఓం విధిరూపాయ నమః',
           },
           {
              "title": 'ఓం విరోధాధారభూమయే నమః',
           },
           {
              "title": 'ఓం వేదాస్పదస్వభావాయ నమః',
           },
           {
              "title": 'ఓం వజ్రదేహాయ నమః',
           },
           {
              "title": 'ఓం వంద్యాయ నమః',
           },
           {
              "title": 'ఓం విరూపాక్షాయ నమః',
           },
           {
              "title": 'ఓం వరిష్ఠాయ నమః',
           },
           {
              "title": 'ఓం గరిష్ఠాయ నమః',
           },
           {
              "title": 'ఓం వజ్రాంకుశధరాయ నమః ||60||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం వరదాయ నమః',
           },
           {
              "title": 'ఓం అభయహస్తాయ నమః',
           },
           {
              "title": 'ఓం వామనాయ నమః',
           },
           {
              "title": 'ఓం జ్యేష్ఠపత్నీ సమేతాయ నమః',
           },
           {
              "title": 'ఓం శ్రేష్ఠాయ నమః',
           },
           {
              "title": 'ఓం అమితభూషిణే నమః',
           },
           {
              "title": 'ఓం కష్టౌఘనాశకాయ నమః',
           },
           {
              "title": 'ఓం ఆర్యపుష్టిదాయ నమః',
           },
           {
              "title": 'ఓం స్తుత్యాయ నమః',
           },
           {
              "title": 'ఓం స్తోత్రగమ్యాయ నమః ||70||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం భక్తివశ్యాయ నమః',
           },
           {
              "title": 'ఓం భానవే నమః',
           },
           {
              "title": 'ఓం భానుపుత్రాయ నమః',
           },
           {
              "title": 'ఓం భవ్యాయ నమః',
           },
           {
              "title": 'ఓం పావనాయ నమః',
           },
           {
              "title": 'ఓం ధనుర్మండల సంస్థాయ నమః',
           },
           {
              "title": 'ఓం ధనదాయ నమః',
           },
           {
              "title": 'ఓం ధనుష్మతే నమః',
           },
           {
              "title": 'ఓం తనుప్రకాశ దేహాయ నమః',
           },
           {
              "title": 'ఓం తామసాయ నమః ||80||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అశేషజన వంద్యాయ నమః',
           },
           {
              "title": 'ఓం విశేషఫలదాయినే నమః',
           },
           {
              "title": 'ఓం వశీకృతజనేశాయ నమః',
           },
           {
              "title": 'ఓం పసూనాంపతయే నమః',
           },
           {
              "title": 'ఓం ఖేచరాయ నమః',
           },
           {
              "title": 'ఓం ఖగేశాయ నమః',
           },
           {
              "title": 'ఓం ఘననీలాంబరాయ నమః',
           },
           {
              "title": 'ఓం కాఠిన్యమానసాయ నమః',
           },
           {
              "title": 'ఓం ఆర్యగణస్తుత్యాయ నమః',
           },
           {
              "title": 'ఓం నీలచ్ఛత్రాయ నమః ||90||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం నిత్యాయ నమః',
           },
           {
              "title": 'ఓం నిర్గుణాయ నమః',
           },
           {
              "title": 'ఓం గుణాత్మనే నమః',
           },
           {
              "title": 'ఓం నిరామయాయ నమః',
           },
           {
              "title": 'ఓం నింద్యాయ నమః',
           },
           {
              "title": 'ఓం వందనీయాయ నమః',
           },
           {
              "title": 'ఓం ధీరాయ నమః',
           },
           {
              "title": 'ఓం దివ్యదేహాయ నమః',
           },
           {
              "title": 'ఓం దీనార్తి హరణాయ నమః',
           },
           {
              "title": 'ఓం దైన్యనాశకరాయ నమః ||100||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం ఆర్యజనగణ్యాయ నమః',
           },
           {
              "title": 'ఓం క్రూరాయ నమః',
           },
           {
              "title": 'ఓం క్రూర చేష్టాయ నమః',
           },
           {
              "title": 'ఓం కామక్రోధకరాయ నమః',
           },
           {
              "title": 'ఓం కళత్రపుత్రశత్రుత్వకారణాయ నమః',
           },
           {
              "title": 'ఓం పరిపోషితభక్తాయ నమః',
           },
           {
              "title": 'ఓం పరభీతిహరాయ నమః',
           },
           {
              "title": 'ఓం భక్తసంఘ మనోభీష్ట ఫలదాయ నమః ||108||',
           },
 
           {
              "title": '|| ఇతి శ్రీ శని అష్టోత్తర శతనామావళి సంపూర్ణం ||',
           }
         ],
          "title": 'శని అష్టోత్తర శతనామావళి',
         // "img": require('../imgs/Astotaram/shani.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం శ్రీ మహాశాస్త్రే నమః',
           },
           {
              "title": 'ఓం విశ్వవాస్త్రే నమః',
           },
           {
              "title": 'ఓం లోక శాస్త్రే నమః',
           },
           {
              "title": 'ఓం మహాబలాయ నమః',
           },
           {
              "title": 'ఓం ధర్మ శాస్త్రే నమః',
           },
           {
              "title": 'ఓం వేద శాస్త్రే నమః',
           },
           {
              "title": 'ఓం కాల శాస్త్రే నమః',
           },
           {
              "title": 'ఓం మహాజసే నమః',
           },
           {
              "title": 'ఓం గజాధిపాయ నమః',
           },
           {
              "title": 'ఓం అంగపతయే నమః ||10||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం వ్యాఘ్రపతయే నమః',
           },
           {
              "title": 'ఓం మహాద్యుతాయ నమః',
           },
           {
              "title": 'ఓం గణాధ్యక్షాయ నమః',
           },
           {
              "title": 'ఓం అగ్రగణ్యాయ నమః',
           },
           {
              "title": 'ఓం మహా గుణ గణాలయ నమః',
           },
           {
              "title": 'ఓం ఋగ్వేదరూపాయ నమః',
           },
           {
              "title": 'ఓం నక్షత్రాయ నమః',
           },
           {
              "title": 'ఓం చంద్రరూపాయ నమః',
           },
           {
              "title": 'ఓం వలాహకాయ నమః',
           },
           {
              "title": 'ఓం దూర్వాయ నమః ||20||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శ్యామాయ నమః',
           },
           {
              "title": 'ఓం మహా రూపాయ నమః',
           },
           {
              "title": 'ఓం క్రూర దృష్టయే నమః',
           },
           {
              "title": 'ఓం అనామయాయ నమః',
           },
           {
              "title": 'ఓం త్రినేత్రాయ నమః',
           },
           {
              "title": 'ఓం ఉత్పాలాకారాయ నమః',
           },
           {
              "title": 'ఓం కాలాంతకాయ నమః',
           },
           {
              "title": 'ఓం నరాధిపాయ నమః',
           },
           {
              "title": 'ఓం దక్షమూషకాయ నమః',
           },
           {
              "title": 'ఓం కాల్హారకు సుమప్రియాయ నమః ||30||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మదనాయ నమః',
           },
           {
              "title": 'ఓం మాధవసుతాయ నమః',
           },
           {
              "title": 'ఓం మందారకుసుమ ప్రియాయ నమః',
           },
           {
              "title": 'ఓం మదాలసాయ నమః',
           },
           {
              "title": 'ఓం వీర శాస్త్రే నమః',
           },
           {
              "title": 'ఓం మహా సర్ప విభూషితాయ నమః',
           },
           {
              "title": 'ఓం మహాసూరాయ నమః',
           },
           {
              "title": 'ఓం మహాధీరాయ నమః',
           },
           {
              "title": 'ఓం మహాపాపవినాశకాయ నమః',
           },
           {
              "title": 'ఓం ఆసిహస్తాయ నమః ||40||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శరదరాయ నమః',
           },
           {
              "title": 'ఓం హలహల ధరసుతాయ నమః',
           },
           {
              "title": 'ఓం అగ్ని నయనాయ నమః',
           },
           {
              "title": 'ఓం అర్జునపతయే నమః',
           },
           {
              "title": 'ఓం అనంగామదనాతురాయ నమ',
           },
           {
              "title": 'ఓం దుష్టగ్రహాధిపాయ నమః',
           },
           {
              "title": 'ఓం శాస్త్రే నమః',
           },
           {
              "title": 'ఓం శిష్టరక్షణధీక్షితాయ నమః',
           },
           {
              "title": 'ఓం రాజరాజర్చితాయ నమః',
           },
           {
              "title": 'ఓం రాజ శేఖరాయ నమః ||50||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం రాజోత్తమాయ నమః',
           },
           {
              "title": 'ఓం మంజులేశాయ నమః',
           },
           {
              "title": 'ఓం వరరుచయే నమః',
           },
           {
              "title": 'ఓం వరదాయ నమః',
           },
           {
              "title": 'ఓం వాయువాహనాయ నమః',
           },
           {
              "title": 'ఓం వజ్రాంగాయ నమః',
           },
           {
              "title": 'ఓం విష్ణుపుత్రాయ నమః',
           },
           {
              "title": 'ఓం ఖడ్గప్రాణయే నమః',
           },
           {
              "title": 'ఓం బలోధ్యుతయ నమః',
           },
           {
              "title": 'ఓం త్రిలోకజ్ఞానాయ నమః ||60||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అతిబలాయ నమః',
           },
           {
              "title": 'ఓం కస్తూరితిలకాంచితాయ నమః',
           },
           {
              "title": 'ఓం పుష్కలాయ నమః',
           },
           {
              "title": 'ఓం పూర్ణధవళాయ నమః',
           },
           {
              "title": 'ఓం పూర్ణ లేశాయ నమః',
           },
           {
              "title": 'ఓం కృపాలయాయ నమః',
           },
           {
              "title": 'ఓం వనజనాధి పాయ నమః',
           },
           {
              "title": 'ఓం పాశహస్తాయ నమః',
           },
           {
              "title": 'ఓం భయాపహాయ నమః',
           },
           {
              "title": 'ఓం బకారరూపాయ నమః ||70||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం పాపఘ్నాయ నమః',
           },
           {
              "title": 'ఓం పాషండ రుధిశాయ నమః',
           },
           {
              "title": 'ఓం పంచపాండవసంరక్షకాయ నమః',
           },
           {
              "title": 'ఓం పరపాపవినాశకాయ నమః',
           },
           {
              "title": 'ఓం పంచవక్త్ర కుమారాయ నమః',
           },
           {
              "title": 'ఓం పంచాక్షక పారాయణాయ నమః',
           },
           {
              "title": 'ఓం పండితాయ నమః',
           },
           {
              "title": 'ఓం శ్రీ ధరసుతాయ నమః',
           },
           {
              "title": 'ఓం న్యాయాయ నమః',
           },
           {
              "title": 'ఓం కవచినే నమః ||80||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం కరీణామదిపాయ నమః',
           },
           {
              "title": 'ఓం కాండయుజుషే నమః',
           },
           {
              "title": 'ఓం తర్పణ ప్రియాయ నమః',
           },
           {
              "title": 'ఓం సోమరూపాయ నమః',
           },
           {
              "title": 'ఓం వన్యధన్యాయ నమః',
           },
           {
              "title": 'ఓం సత్పందాపాప వినాశకాయ నమః',
           },
           {
              "title": 'ఓం వ్యాగ్ర చర్మధరాయ నమః',
           },
           {
              "title": 'ఓం శూలినే నమః',
           },
           {
              "title": 'ఓం కృపాళాయ నమః',
           },
           {
              "title": 'ఓం వేణువదనాయ నమః ||90||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం కంచు కంటాయ నమః',
           },
           {
              "title": 'ఓం కరళవాయ నమః',
           },
           {
              "title": 'ఓం కిరీటాధివిభూషితాయ నమః',
           },
           {
              "title": 'ఓం దూర్జటినే నమః',
           },
           {
              "title": 'ఓం వీరనిలయాయ నమః',
           },
           {
              "title": 'ఓం వీరాయ నమః',
           },
           {
              "title": 'ఓం వీరేంద్రవందితాయ నమః',
           },
           {
              "title": 'ఓం విశ్వరూపాయ నమః',
           },
           {
              "title": 'ఓం విరపతయే నమః',
           },
           {
              "title": 'ఓం వివిధార్దఫలప్రదాయ నమః ||100||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మహారూపాయ నమః',
           },
           {
              "title": 'ఓం చతుర్భాహవే నమః',
           },
           {
              "title": 'ఓం పరపాపవిమోచకాయ నమః',
           },
           {
              "title": 'ఓం నాగ కుండలధరాయ నమః',
           },
           {
              "title": 'ఓం కిరీటాయ నమః',
           },
           {
              "title": 'ఓం జటాధరాయ నమః',
           },
           {
              "title": 'ఓం నాగాలంకారసంయుక్తాయ నమః',
           },
           {
              "title": 'ఓం నానారత్నవిభూషితాయ నమః ||108||',
           },
           {
              "title": '',
           },
           {
              "title": '|| ఇతి శ్రీ అయ్యప్ప అష్టోత్తర శతనామావళి సంపూర్ణం ||',
           }
         ],
          "title": 'శ్రీ అయ్యప్ప స్వామి అష్టోత్తర శతనామావళి',
         // "img": require('../imgs/Astotaram/Ayyapa_swamy.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం ఆంజనేయాయ నమః',
           },
           {
              "title": 'ఓం మహావీరాయ నమః',
           },
           {
              "title": 'ఓం హనుమతే నమః',
           },
           {
              "title": 'ఓం మారుతాత్మజాయ నమః',
           },
           {
              "title": 'ఓం తత్త్వజ్ఞానప్రదాయ నమః',
           },
           {
              "title": 'ఓం సీతాదేవీముద్రాప్రదాయకాయ నమః',
           },
           {
              "title": 'ఓం అశోకవనికాచ్ఛేత్రే నమః',
           },
           {
              "title": 'ఓం సర్వమాయావిభంజనాయ నమః',
           },
           {
              "title": 'ఓం సర్వబంధవిమోక్త్రే నమః',
           },
           {
              "title": 'ఓం రక్షోవిధ్వంసకారకాయ నమః ||10||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం పరవిద్యాపరీహారాయ నమః',
           },
           {
              "title": 'ఓం పరశౌర్యవినాశనాయ నమః',
           },
           {
              "title": 'ఓం పరమంత్రనిరాకర్త్రే నమః',
           },
           {
              "title": 'ఓం పరయంత్రప్రభేదకాయ నమః',
           },
           {
              "title": 'ఓం సర్వగ్రహవినాశినే నమః',
           },
           {
              "title": 'ఓం భీమసేనసహాయకృతే నమః',
           },
           {
              "title": 'ఓం సర్వదుఃఖహరాయ నమః',
           },
           {
              "title": 'ఓం సర్వలోకచారిణే నమః',
           },
           {
              "title": 'ఓం మనోజవాయ నమః',
           },
           {
              "title": 'ఓం పారిజాతద్రుమూలస్థాయ నమః ||20||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సర్వమంత్రస్వరూపిణే నమః',
           },
           {
              "title": 'ఓం సర్వతంత్రస్వరూపిణే నమః',
           },
           {
              "title": 'ఓం సర్వయంత్రాత్మకాయ నమః',
           },
           {
              "title": 'ఓం కపీశ్వరాయ నమః',
           },
           {
              "title": 'ఓం మహాకాయాయ నమః',
           },
           {
              "title": 'ఓం సర్వరోగహరాయ నమః',
           },
           {
              "title": 'ఓం ప్రభవే నమః',
           },
           {
              "title": 'ఓం బలసిద్ధికరాయ నమః',
           },
           {
              "title": 'ఓం సర్వవిద్యాసంపత్ప్రదాయకాయ నమః',
           },
           {
              "title": 'ఓం కపిసేనానాయకాయ నమః ||30||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం భవిష్యచ్చతురాననాయ నమః',
           },
           {
              "title": 'ఓం కుమారబ్రహ్మచారిణే నమః',
           },
           {
              "title": 'ఓం రత్నకుండలదీప్తిమతే నమః',
           },
           {
              "title": 'ఓం సంచలద్వాలసన్నద్ధలంబమానశిఖోజ్జ్వలాయ నమః',
           },
           {
              "title": 'ఓం గంధర్వవిద్యాతత్త్వజ్ఞాయ నమః',
           },
           {
              "title": 'ఓం మహాబలపరాక్రమాయ నమః',
           },
           {
              "title": 'ఓం కారాగృహవిమోక్త్రే నమః',
           },
           {
              "title": 'ఓం శృంఖలాబంధమోచకాయ నమః',
           },
           {
              "title": 'ఓం సాగరోత్తారకాయ నమః',
           },
           {
              "title": 'ఓం ప్రాజ్ఞాయ నమః ||40||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం రామదూతాయ నమః',
           },
           {
              "title": 'ఓం ప్రతాపవతే నమః',
           },
           {
              "title": 'ఓం వానరాయ నమః',
           },
           {
              "title": 'ఓం కేసరిసుతాయ నమః',
           },
           {
              "title": 'ఓం సీతాశోకనివారకాయ నమః',
           },
           {
              "title": 'ఓం అంజనాగర్భసంభూతాయ నమః',
           },
           {
              "title": 'ఓం బాలార్కసదృశాననాయ నమః',
           },
           {
              "title": 'ఓం విభీషణప్రియకరాయ నమః',
           },
           {
              "title": 'ఓం దశగ్రీవకులాంతకాయ నమః',
           },
           {
              "title": 'ఓం లక్ష్మణప్రాణదాత్రే నమః ||50||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం వజ్రకాయాయ నమః',
           },
           {
              "title": 'ఓం మహాద్యుతయే నమః',
           },
           {
              "title": 'ఓం చిరంజీవినే నమః',
           },
           {
              "title": 'ఓం రామభక్తాయ నమః',
           },
           {
              "title": 'ఓం దైత్యకార్యవిఘాతకాయ నమః',
           },
           {
              "title": 'ఓం అక్షహంత్రే నమః',
           },
           {
              "title": 'ఓం కాంచనాభాయ నమః',
           },
           {
              "title": 'ఓం పంచవక్త్రాయ నమః',
           },
           {
              "title": 'ఓం మహాతపసే నమః',
           },
           {
              "title": 'ఓం లంకిణీభంజనాయ నమః ||60||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శ్రీమతే నమః',
           },
           {
              "title": 'ఓం సింహికాప్రాణభంజనాయ నమః',
           },
           {
              "title": 'ఓం గంధమాదనశైలస్థాయ నమః',
           },
           {
              "title": 'ఓం లంకాపురవిదాహకాయ నమః',
           },
           {
              "title": 'ఓం సుగ్రీవసచివాయ నమః',
           },
           {
              "title": 'ఓం ధీరాయ నమః',
           },
           {
              "title": 'ఓం శూరాయ నమః',
           },
           {
              "title": 'ఓం దైత్యకులాంతకాయ నమః',
           },
           {
              "title": 'ఓం సురార్చితాయ నమః',
           },
           {
              "title": 'ఓం మహాతేజసే నమః ||70||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం రామచూడామణిప్రదాయ నమః',
           },
           {
              "title": 'ఓం కామరూపిణే నమః',
           },
           {
              "title": 'ఓం పింగళాక్షాయ నమః',
           },
           {
              "title": 'ఓం వార్ధిమైనాకపూజితాయ నమః',
           },
           {
              "title": 'ఓం కబళీకృతమార్తాండమండలాయ నమః',
           },
           {
              "title": 'ఓం విజితేంద్రియాయ నమః',
           },
           {
              "title": 'ఓం రామసుగ్రీవసంధాత్రే నమః',
           },
           {
              "title": 'ఓం మహిరావణమర్దనాయ నమః',
           },
           {
              "title": 'ఓం స్ఫటికాభాయ నమః',
           },
           {
              "title": 'ఓం వాగధీశాయ నమః ||80||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం నవవ్యాకృతిపండితాయ నమః',
           },
           {
              "title": 'ఓం చతుర్బాహవే నమః',
           },
           {
              "title": 'ఓం దీనబంధవే నమః',
           },
           {
              "title": 'ఓం మహాత్మనే నమః',
           },
           {
              "title": 'ఓం భక్తవత్సలాయ నమః',
           },
           {
              "title": 'ఓం సంజీవననగాహర్త్రే నమః',
           },
           {
              "title": 'ఓం శుచయే నమః',
           },
           {
              "title": 'ఓం వాగ్మినే నమః',
           },
           {
              "title": 'ఓం దృఢవ్రతాయ నమః',
           },
           {
              "title": 'ఓం కాలనేమిప్రమథనాయ నమః ||90||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం హరిమర్కటమర్కటాయ నమః',
           },
           {
              "title": 'ఓం దాంతాయ నమః',
           },
           {
              "title": 'ఓం శాంతాయ నమః',
           },
           {
              "title": 'ఓం ప్రసన్నాత్మనే నమః',
           },
           {
              "title": 'ఓం శతకంఠమదాపహృతే నమః',
           },
           {
              "title": 'ఓం యోగినే నమః',
           },
           {
              "title": 'ఓం రామకథాలోలాయ నమః',
           },
           {
              "title": 'ఓం సీతాన్వేషణపండితాయ నమః',
           },
           {
              "title": 'ఓం వజ్రదంష్ట్రాయ నమః',
           },
           {
              "title": 'ఓం వజ్రనఖాయ నమః ||100||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం రుద్రవీర్యసముద్భవాయ నమః',
           },
           {
              "title": 'ఓం ఇంద్రజిత్ప్రహితామోఘబ్రహ్మాస్త్రవినివారకాయ నమః',
           },
           {
              "title": 'ఓం పార్థధ్వజాగ్రసంవాసినే నమః',
           },
           {
              "title": 'ఓం శరపంజరభేదకాయ నమః',
           },
           {
              "title": 'ఓం దశబాహవే నమః',
           },
           {
              "title": 'ఓం లోకపూజ్యాయ నమః',
           },
           {
              "title": 'ఓం జాంబవత్ప్రీతివర్ధనాయ నమః',
           },
           {
              "title": 'ఓం సీతాసమేతశ్రీరామపాదసేవాధురంధరాయ నమః ||108||',
           },
           {
              "title": '',
           },
           {
              "title": '|| ఇతి శ్రీ ఆంజనేయ అష్టోత్తర శతనామావళి సంపూర్ణం ||',
           }
         ],
          "title": 'శ్రీ ఆంజనేయ అష్టోత్తర శతనామావళి',
         // "img": require('../imgs/Astotaram/Anjaneya.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం శ్రీ గాయత్రై నమః',
           },
           {
              "title": 'ఓం జగన్మాత్రే నమః',
           },
           {
              "title": 'ఓం పరబ్రహ్మస్వరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం పరమార్థప్రదాయై నమః',
           },
           {
              "title": 'ఓం జప్యాయై నమః',
           },
           {
              "title": 'ఓం బ్రహ్మతేజోవివర్థిన్యై నమః',
           },
           {
              "title": 'ఓం బ్రహ్మాస్త్రరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం భవ్యాయై నమః',
           },
           {
              "title": 'ఓం త్రికాలధ్యేయరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం త్రిమూర్తిరూపాయై నమః ||10||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సర్వజ్ఞాయై నమః',
           },
           {
              "title": 'ఓం వేదమాత్రే నమః',
           },
           {
              "title": 'ఓం మనోన్మన్యై నమః',
           },
           {
              "title": 'ఓం బాలికాయై నమః',
           },
           {
              "title": 'ఓం తరుణ్యై నమః',
           },
           {
              "title": 'ఓం వృద్ధాయై నమః',
           },
           {
              "title": 'ఓం సూర్యమండలవాసిన్యై నమః',
           },
           {
              "title": 'ఓం మందేహదానవధ్వంసకారిణ్యై నమః',
           },
           {
              "title": 'ఓం సర్వకారణాయై నమః',
           },
           {
              "title": 'ఓం హంసారూఢాయై నమః ||20||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం వృషారూఢాయై నమః',
           },
           {
              "title": 'ఓం గరుడారోహిణ్యై నమః',
           },
           {
              "title": 'ఓం శుభాయై నమః',
           },
           {
              "title": 'ఓం షట్కుక్షిణ్యై నమః',
           },
           {
              "title": 'ఓం త్రిపాదాయై నమః',
           },
           {
              "title": 'ఓం శుద్ధాయై నమః',
           },
           {
              "title": 'ఓం పంచశీర్షాయై నమః',
           },
           {
              "title": 'ఓం త్రిలోచనాయై నమః',
           },
           {
              "title": 'ఓం త్రివేదరూపాయై నమః',
           },
           {
              "title": 'ఓం త్రివిధాయై నమః ||30||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం త్రివర్గఫలదాయిన్యై నమః',
           },
           {
              "title": 'ఓం దశహస్తాయై నమః',
           },
           {
              "title": 'ఓం చంద్రవర్ణాయై నమః',
           },
           {
              "title": 'ఓం విశ్వామిత్రవరప్రదాయై నమః',
           },
           {
              "title": 'ఓం దశాయుధధరాయై నమః',
           },
           {
              "title": 'ఓం నిత్యాయై నమః',
           },
           {
              "title": 'ఓం సంతుష్టాయై నమః',
           },
           {
              "title": 'ఓం బ్రహ్మపూజితాయై నమః',
           },
           {
              "title": 'ఓం ఆదిశక్త్యై నమః',
           },
           {
              "title": 'ఓం మహావిద్యాయై నమః ||40||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సుషుమ్నాభాయై నమః',
           },
           {
              "title": 'ఓం సరస్వత్యై నమః',
           },
           {
              "title": 'ఓం చతుర్వింశత్యక్షరాఢ్యాయై నమః',
           },
           {
              "title": 'ఓం సావిత్ర్యై నమః',
           },
           {
              "title": 'ఓం సత్యవత్సలాయై నమః',
           },
           {
              "title": 'ఓం సంధ్యాయై నమః',
           },
           {
              "title": 'ఓం రాత్ర్యై నమః',
           },
           {
              "title": 'ఓం సంధ్యారాత్రిప్రభాతాఖ్యాయై నమః',
           },
           {
              "title": 'ఓం సాంఖ్యాయనకులోద్భవాయై నమః',
           },
           {
              "title": 'ఓం సర్వేశ్వర్యై నమః ||50||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సర్వవిద్యాయై నమః',
           },
           {
              "title": 'ఓం సర్వమంత్ర్యాద్యై నమః',
           },
           {
              "title": 'ఓం అవ్యాయై నమః',
           },
           {
              "title": 'ఓం శుద్ధవస్త్రాయై నమః',
           },
           {
              "title": 'ఓం శుద్ధవిద్యాయై నమః',
           },
           {
              "title": 'ఓం శుక్లమాల్యానులేపనాయై నమః',
           },
           {
              "title": 'ఓం సురసింధుసమాయై నమః',
           },
           {
              "title": 'ఓం సౌమ్యాయై నమః',
           },
           {
              "title": 'ఓం బ్రహ్మలోకనివాసిన్యై నమః',
           },
           {
              "title": 'ఓం ప్రణవప్రతిపాద్యార్థాయై నమః ||60||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం ప్రణతోద్ధరణక్షమాయై నమః',
           },
           {
              "title": 'ఓం జలాంజలి సుసంతుష్టాయై నమః',
           },
           {
              "title": 'ఓం జలగర్భాయై నమః',
           },
           {
              "title": 'ఓం జలప్రియాయై నమః',
           },
           {
              "title": 'ఓం స్వాహాయై నమః',
           },
           {
              "title": 'ఓం స్వధాయై నమః',
           },
           {
              "title": 'ఓం సుధాసంస్థాయై నమః',
           },
           {
              "title": 'ఓం శ్రౌషడ్వౌషటడ్వషట్ర్కియాయై నమః',
           },
           {
              "title": 'ఓం సురభ్యై నమః',
           },
           {
              "title": 'ఓం షోడశకలాయై నమః ||70||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మునిబృందనిషేవితాయై నమః',
           },
           {
              "title": 'ఓం యజ్ఞప్రియాయై నమః',
           },
           {
              "title": 'ఓం యజ్ఞమూర్త్యై నమః',
           },
           {
              "title": 'ఓం స్రుక్స్రువాజ్యస్వరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం అక్షమాలాధరాయై నమః',
           },
           {
              "title": 'ఓం అక్షమాలాసంస్థాయై నమః',
           },
           {
              "title": 'ఓం అక్షరాకృత్యై నమః',
           },
           {
              "title": 'ఓం మదుచ్ఛంద ఋషిప్రీతాయై నమః',
           },
           {
              "title": 'ఓం స్వచ్ఛందాయై నమః',
           },
           {
              "title": 'ఓం ఛందసాంనిధ్యై నమః ||80||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అంగుళీపర్వసంస్థానాయై నమః',
           },
           {
              "title": 'ఓం చతుర్వింశతిముద్రికాయై నమః',
           },
           {
              "title": 'ఓం బ్రహ్మమూర్త్యై నమః',
           },
           {
              "title": 'ఓం రుద్రశిఖరాయై నమః',
           },
           {
              "title": 'ఓం సహస్రపరమాయై నమః',
           },
           {
              "title": 'ఓం అంబికాయై నమః',
           },
           {
              "title": 'ఓం విష్ణుహృదయాయై నమః',
           },
           {
              "title": 'ఓం అగ్నిముఖ్యై నమః',
           },
           {
              "title": 'ఓం శతమధ్యాయై నమః',
           },
           {
              "title": 'ఓం శతావరాయై నమః ||90||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సహస్రదళపద్మస్థాయై నమః',
           },
           {
              "title": 'ఓం హంసరూపాయై నమః',
           },
           {
              "title": 'ఓం నిరంజనాయై నమః',
           },
           {
              "title": 'ఓం చరాచరస్థాయై నమః',
           },
           {
              "title": 'ఓం చతురాయై నమః',
           },
           {
              "title": 'ఓం సూర్యకోటిసమప్రభాయై నమః',
           },
           {
              "title": 'ఓం పంచవర్ణముఖ్యై నమః',
           },
           {
              "title": 'ఓం ధాత్ర్యై నమః',
           },
           {
              "title": 'ఓం చంద్రకోటిశుచిస్మితాయై నమః',
           },
           {
              "title": 'ఓం మహామాయాయై నమః ||100||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం విచిత్రాంగ్యై నమః',
           },
           {
              "title": 'ఓం మాయాబీజనివాసిన్యై నమః',
           },
           {
              "title": 'ఓం సర్వయంత్రాత్మికాయై నమః',
           },
           {
              "title": 'ఓం సర్వతంత్రస్వరూపాయై నమః',
           },
           {
              "title": 'ఓం జగద్ధితాయై నమః',
           },
           {
              "title": 'ఓం మర్యాదాపాలికాయై నమః',
           },
           {
              "title": 'ఓం మాన్యాయై నమః',
           },
           {
              "title": 'ఓం మహామంత్రఫలప్రదాయై నమః ||108||',
           },
           {
              "title": '|| ఇతి శ్రీ గాయత్రీ అష్టోత్తర శతనామావళి సమాప్తం ||',
           }
         ],
          "title": 'శ్రీ గాయత్రీ అష్టోత్తర శతనామావళి',
         // "img": require('../imgs/Astotaram/gayatri.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం సత్యదేవాయ నమః',
           },
           {
              "title": 'ఓం సత్యాత్మనే నమః',
           },
           {
              "title": 'ఓం సత్యభూతాయ నమః',
           },
           {
              "title": 'ఓం సత్యపురుషాయ నమః',
           },
           {
              "title": 'ఓం సత్యనాథాయ నమః',
           },
           {
              "title": 'ఓం సత్యసాక్షిణే నమః',
           },
           {
              "title": 'ఓం సత్యయోగాయ నమః',
           },
           {
              "title": 'ఓం సత్యజ్ఞానాయ నమః',
           },
           {
              "title": 'ఓం సత్యజ్ఞానప్రియాయ నమః',
           },
           {
              "title": 'ఓం సత్యనిధయే నమః ||10||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సత్యసంభవాయ నమః',
           },
           {
              "title": 'ఓం సత్యప్రభువే నమః',
           },
           {
              "title": 'ఓం సత్యేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం సత్యకర్మణే నమః',
           },
           {
              "title": 'ఓం సత్యపవిత్రాయ నమః',
           },
           {
              "title": 'ఓం సత్యమంగళాయ నమః',
           },
           {
              "title": 'ఓం సత్యగర్భాయ నమః',
           },
           {
              "title": 'ఓం సత్యప్రజాపతయే నమః',
           },
           {
              "title": 'ఓం సత్యవిక్రమాయ నమః',
           },
           {
              "title": 'ఓం సత్యసిద్ధాయ నమః ||20||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సత్యాచ్యుతాయ నమః',
           },
           {
              "title": 'ఓం సత్యవీరాయ నమః',
           },
           {
              "title": 'ఓం సత్యబోధాయ నమః',
           },
           {
              "title": 'ఓం సత్యధర్మాయ నమః',
           },
           {
              "title": 'ఓం సత్యగ్రజాయ నమః',
           },
           {
              "title": 'ఓం సత్యసంతుష్టాయ నమః',
           },
           {
              "title": 'ఓం సత్యవరాహాయ నమః',
           },
           {
              "title": 'ఓం సత్యపారాయణాయ నమః',
           },
           {
              "title": 'ఓం సత్యపూర్ణాయ నమః',
           },
           {
              "title": 'ఓం సత్యౌషధాయ నమః  ||30||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సత్యశాశ్వతాయ నమః',
           },
           {
              "title": 'ఓం సత్యప్రవర్ధనాయ నమః',
           },
           {
              "title": 'ఓం సత్యవిభవే నమః',
           },
           {
              "title": 'ఓం సత్యజ్యేష్ఠాయ నమః',
           },
           {
              "title": 'ఓం సత్యశ్రేష్ఠాయ నమః',
           },
           {
              "title": 'ఓం సత్యవిక్రమిణే నమః',
           },
           {
              "title": 'ఓం సత్యధన్వినే నమః',
           },
           {
              "title": 'ఓం సత్యమేధాయ నమః',
           },
           {
              "title": 'ఓం సత్యాధీశాయ నమః',
           },
           {
              "title": 'ఓం సత్యక్రతవే నమః  ||40||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సత్యకాలాయ నమః',
           },
           {
              "title": 'ఓం సత్యవత్సలాయ నమః',
           },
           {
              "title": 'ఓం సత్యవసవే నమః',
           },
           {
              "title": 'ఓం సత్యమేఘాయ నమః',
           },
           {
              "title": 'ఓం సత్యరుద్రాయ నమః',
           },
           {
              "title": 'ఓం సత్యబ్రహ్మణే నమః',
           },
           {
              "title": 'ఓం సత్యామృతాయ నమః',
           },
           {
              "title": 'ఓం సత్యవేదాంగాయ నమః',
           },
           {
              "title": 'ఓం సత్యచతురాత్మనే నమః',
           },
           {
              "title": 'ఓం సత్యభోక్త్రే నమః ||50||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సత్యశుచయే నమః',
           },
           {
              "title": 'ఓం సత్యార్జితాయ నమః',
           },
           {
              "title": 'ఓం సత్యేంద్రాయ నమః',
           },
           {
              "title": 'ఓం సత్యసంగరాయ నమః',
           },
           {
              "title": 'ఓం సత్యస్వర్గాయ నమః',
           },
           {
              "title": 'ఓం సత్యనియమాయ నమః',
           },
           {
              "title": 'ఓం సత్యమేధాయ నమః',
           },
           {
              "title": 'ఓం సత్యవేద్యాయ నమః',
           },
           {
              "title": 'ఓం సత్యపీయూషాయ నమః',
           },
           {
              "title": 'ఓం సత్యమాయాయ నమః  ||60||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సత్యమోహాయ నమః',
           },
           {
              "title": 'ఓం సత్యసురానందాయ నమః',
           },
           {
              "title": 'ఓం సత్యసాగరాయ నమః',
           },
           {
              "title": 'ఓం సత్యతపసే నమః',
           },
           {
              "title": 'ఓం సత్యసింహాయ నమః',
           },
           {
              "title": 'ఓం సత్యమృగాయ నమః',
           },
           {
              "title": 'ఓం సత్యలోకపాలకాయ నమః',
           },
           {
              "title": 'ఓం సత్యస్థితాయ నమః',
           },
           {
              "title": 'ఓం సత్యదిక్పాలకాయ నమః',
           },
           {
              "title": 'ఓం సత్యధనుర్ధరాయ నమః  ||70||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సత్యాంబుజాయ నమః',
           },
           {
              "title": 'ఓం సత్యవాక్యాయ నమః',
           },
           {
              "title": 'ఓం సత్యగురవే నమః',
           },
           {
              "title": 'ఓం సత్యన్యాయాయ నమః',
           },
           {
              "title": 'ఓం సత్యసాక్షిణే నమః',
           },
           {
              "title": 'ఓం సత్యసంవృతాయ నమః',
           },
           {
              "title": 'ఓం సత్యసంప్రదాయ నమః',
           },
           {
              "title": 'ఓం సత్యవహ్నయే నమః',
           },
           {
              "title": 'ఓం సత్యవాయవే నమః',
           },
           {
              "title": 'ఓం సత్యశిఖరాయ నమః  ||80||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సత్యానందాయ నమః',
           },
           {
              "title": 'ఓం సత్యాధిరాజాయ నమః',
           },
           {
              "title": 'ఓం సత్యశ్రీపాదాయ నమః',
           },
           {
              "title": 'ఓం సత్యగుహ్యాయ నమః',
           },
           {
              "title": 'ఓం సత్యోదరాయ నమః',
           },
           {
              "title": 'ఓం సత్యహృదయాయ నమః',
           },
           {
              "title": 'ఓం సత్యకమలాయ నమః',
           },
           {
              "title": 'ఓం సత్యనాళాయ నమః',
           },
           {
              "title": 'ఓం సత్యహస్తాయ నమః',
           },
           {
              "title": 'ఓం సత్యబాహవే నమః  ||90||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సత్యముఖాయ నమః',
           },
           {
              "title": 'ఓం సత్యజిహ్వాయ నమః',
           },
           {
              "title": 'ఓం సత్యదౌంష్ట్రాయ నమః',
           },
           {
              "title": 'ఓం సత్యనాశికాయ నమః',
           },
           {
              "title": 'ఓం సత్యశ్రోత్రాయ నమః',
           },
           {
              "title": 'ఓం సత్యచక్షుషే నమః',
           },
           {
              "title": 'ఓం సత్యశిరసే నమః',
           },
           {
              "title": 'ఓం సత్యముకుటాయ నమః',
           },
           {
              "title": 'ఓం సత్యాంబరాయ నమః',
           },
           {
              "title": 'ఓం సత్యాభరణాయ నమః  ||100||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సత్యాయుధాయ నమః',
           },
           {
              "title": 'ఓం సత్యశ్రీవల్లభాయ నమః',
           },
           {
              "title": 'ఓం సత్యపుష్కరాయ నమః',
           },
           {
              "title": 'ఓం సత్యదృఢాయ నమః',
           },
           {
              "title": 'ఓం సత్యభామావతారకాయ నమః',
           },
           {
              "title": 'ఓం సత్యగృహరూపిణే నమః',
           },
           {
              "title": 'ఓం సత్యప్రహరణాయుధాయ నమః  ||108||',
           },
           {
              "title": '',
           },
           {
              "title": '|| శ్రీ సత్యనారాయణ అష్టోత్తర శతనామావళి సంపూర్ణం ||',
           }
         ],
          "title": 'శ్రీ సత్యనారాయణ అష్టోత్తర శతనామావళి',
         // "img": require('../imgs/Astotaram/sathyanarayan.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం అనసూయాసుతాయ నమః',
           },
           {
              "title": 'ఓం దత్తాయ నమః',
           },
           {
              "title": 'ఓం అత్రిపుత్రాయ నమః',
           },
           {
              "title": 'ఓం మహామునయే నమః',
           },
           {
              "title": 'ఓం యోగీంద్రాయ నమః',
           },
           {
              "title": 'ఓం పుణ్యపురుషాయ నమః',
           },
           {
              "title": 'ఓం దేవేశాయ నమః',
           },
           {
              "title": 'ఓం జగదీశ్వరాయ నమః',
           },
           {
              "title": 'ఓం పరమాత్మనే నమః',
           },
           {
              "title": 'ఓం పరస్మై బ్రహ్మణే నమః ||10||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సదానందాయ నమః',
           },
           {
              "title": 'ఓం జగద్గురవే నమః',
           },
           {
              "title": 'ఓం నిత్యతృప్తాయ నమః',
           },
           {
              "title": 'ఓం నిర్వికారాయ నమః',
           },
           {
              "title": 'ఓం నిర్వికల్పాయ నమః',
           },
           {
              "title": 'ఓం నిరంజనాయ నమః',
           },
           {
              "title": 'ఓం గుణాత్మకాయ నమః',
           },
           {
              "title": 'ఓం గుణాతీతాయ నమః',
           },
           {
              "title": 'ఓం బ్రహ్మవిష్ణుశివాత్మకాయ నమః',
           },
           {
              "title": 'ఓం నానారూపధరాయ నమః ||20||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం నిత్యాయ నమః',
           },
           {
              "title": 'ఓం శాంతాయ నమః',
           },
           {
              "title": 'ఓం దాంతాయ నమః',
           },
           {
              "title": 'ఓం కృపానిధయే నమః',
           },
           {
              "title": 'ఓం భక్తిప్రియాయ నమః',
           },
           {
              "title": 'ఓం భవహరాయ నమః',
           },
           {
              "title": 'ఓం భగవతే నమః',
           },
           {
              "title": 'ఓం భవనాశనాయ నమః',
           },
           {
              "title": 'ఓం ఆదిదేవాయ నమః',
           },
           {
              "title": 'ఓం మహాదేవాయ నమః ||30||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సర్వేశాయ నమః',
           },
           {
              "title": 'ఓం భువనేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం వేదాంతవేద్యాయ నమః',
           },
           {
              "title": 'ఓం వరదాయ నమః',
           },
           {
              "title": 'ఓం విశ్వరూపాయ నమః',
           },
           {
              "title": 'ఓం అవ్యయాయ నమః',
           },
           {
              "title": 'ఓం హరయే నమః',
           },
           {
              "title": 'ఓం సచ్చిదానందాయ నమః',
           },
           {
              "title": 'ఓం సర్వేశాయ నమః',
           },
           {
              "title": 'ఓం యోగీశాయ నమః ||40||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం భక్తవత్సలాయ నమః',
           },
           {
              "title": 'ఓం దిగంబరాయ నమః',
           },
           {
              "title": 'ఓం దివ్యమూర్తయే నమః',
           },
           {
              "title": 'ఓం దివ్యవిభూతివిభూషణాయ నమః',
           },
           {
              "title": 'ఓం అనాదిసిద్ధాయ నమః',
           },
           {
              "title": 'ఓం సులభాయ నమః',
           },
           {
              "title": 'ఓం భక్తవాంఛితదాయకాయ నమః',
           },
           {
              "title": 'ఓం ఏకస్మై నమః',
           },
           {
              "title": 'ఓం అనేకాయ నమః',
           },
           {
              "title": 'ఓం అద్వితీయాయ నమః ||50||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం నిగమాగమవందితాయ నమః',
           },
           {
              "title": 'ఓం భుక్తిముక్తిప్రదాత్రే నమః',
           },
           {
              "title": 'ఓం కార్తవీర్యవరప్రదాయ నమః',
           },
           {
              "title": 'ఓం శాశ్వతాంగాయ నమః',
           },
           {
              "title": 'ఓం విశుద్ధాత్మనే నమః',
           },
           {
              "title": 'ఓం విశ్వాత్మనే నమః',
           },
           {
              "title": 'ఓం విశ్వతోముఖాయ నమః',
           },
           {
              "title": 'ఓం కృపాకరాయ నమః',
           },
           {
              "title": 'ఓం సర్వేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం సదాతుష్టాయ నమః ||60||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సర్వమంగళదాయకాయ నమః',
           },
           {
              "title": 'ఓం నిష్కళంకాయ నమః',
           },
           {
              "title": 'ఓం నిరాభాసాయ నమః',
           },
           {
              "title": 'ఓం నిర్వికల్పాయ నమః',
           },
           {
              "title": 'ఓం నిరాశ్రయాయ నమః',
           },
           {
              "title": 'ఓం పురుషోత్తమాయ నమః',
           },
           {
              "title": 'ఓం లోకనాథాయ నమః',
           },
           {
              "title": 'ఓం పురాణపురుషాయ నమః',
           },
           {
              "title": 'ఓం అనఘాయ నమః',
           },
           {
              "title": 'ఓం అపారమహిమ్నే నమః ||70||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అనంతాయ నమః',
           },
           {
              "title": 'ఓం ఆద్యంతరహితాకృతయే నమః',
           },
           {
              "title": 'ఓం సంసారవనదానాగ్నయే నమః',
           },
           {
              "title": 'ఓం భవసాగరతారకాయ నమః',
           },
           {
              "title": 'ఓం శ్రీనివాసాయ నమః',
           },
           {
              "title": 'ఓం విశాలాక్షాయ నమః',
           },
           {
              "title": 'ఓం క్షీరాబ్ధిశయనాయ నమః',
           },
           {
              "title": 'ఓం అచ్యుతాయ నమః',
           },
           {
              "title": 'ఓం సర్వపాపక్షయకరాయ నమః',
           },
           {
              "title": 'ఓం తాపత్రయనివారణాయ నమః ||80||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం లోకేశాయ నమః',
           },
           {
              "title": 'ఓం సర్వభూతేశాయ నమః',
           },
           {
              "title": 'ఓం వ్యాపకాయ నమః',
           },
           {
              "title": 'ఓం కరుణామయాయ నమః',
           },
           {
              "title": 'ఓం బ్రహ్మాదివందితపదాయ నమః',
           },
           {
              "title": 'ఓం మునివంద్యాయ నమః',
           },
           {
              "title": 'ఓం స్తుతిప్రియాయ నమః',
           },
           {
              "title": 'ఓం నామరూపక్రియాతీతాయ నమః',
           },
           {
              "title": 'ఓం నిఃస్పృహాయ నమః',
           },
           {
              "title": 'ఓం నిర్మలాత్మకాయ నమః ||90||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మాయాధీశాయ నమః',
           },
           {
              "title": 'ఓం మహాత్మనే నమః',
           },
           {
              "title": 'ఓం మహాదేవాయ నమః',
           },
           {
              "title": 'ఓం మహేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం వ్యాఘ్రచర్మాంబరధరాయ నమః',
           },
           {
              "title": 'ఓం నాగకుండలభూషణాయ నమః',
           },
           {
              "title": 'ఓం సర్వసిద్దిప్రదాయకాయ నమః',
           },
           {
              "title": 'ఓం సర్వజ్ఞాయ నమః',
           },
           {
              "title": 'ఓం కరుణాసింధవే నమః',
           },
           {
              "title": 'ఓం సర్పహారాయ నమః ||100||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సదాశివాయ నమః',
           },
           {
              "title": 'ఓం సహ్యాద్రివాసాయ నమః',
           },
           {
              "title": 'ఓం సర్వాత్మనే నమః',
           },
           {
              "title": 'ఓం భవబంధవిమోచనాయ నమః',
           },
           {
              "title": 'ఓం విశ్వంభరాయ నమః',
           },
           {
              "title": 'ఓం విశ్వనాథాయ నమః',
           },
           {
              "title": 'ఓం జగన్నాథాయ నమః',
           },
           {
              "title": 'ఓం జగత్ప్రభవే నమః ||108||',
           },
           {
              "title": '',
           },
           {
              "title": '|| ఇతి శ్రీ దత్తాత్రేయ అష్టోత్తర శతనామావళి సంపూర్ణం  ||',
           }
         ],
          "title": 'శ్రీ దత్తాత్రేయ అష్టోత్తర శతనామావళి',
         // "img": require('../imgs/Astotaram/Dattatreya.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం అన్నపూర్ణాయై నమః',
           },
           {
              "title": 'ఓం శివాయై నమః',
           },
           {
              "title": 'ఓం దేవ్యై నమః',
           },
           {
              "title": 'ఓం భీమాయై నమః',
           },
           {
              "title": 'ఓం పుష్ట్యై నమః',
           },
           {
              "title": 'ఓం సరస్వత్యై నమః',
           },
           {
              "title": 'ఓం సర్వజ్ఞాయై నమః',
           },
           {
              "title": 'ఓం పార్వత్యై నమః',
           },
           {
              "title": 'ఓం దుర్గాయై నమః',
           },
           {
              "title": 'ఓం శర్వాణ్యై నమః ||10||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శివవల్లభాయై నమః',
           },
           {
              "title": 'ఓం వేదవేద్యాయై నమః',
           },
           {
              "title": 'ఓం మహావిద్యాయై నమః',
           },
           {
              "title": 'ఓం విద్యాదాత్రై నమః',
           },
           {
              "title": 'ఓం విశారదాయై నమః',
           },
           {
              "title": 'ఓం కుమార్యై నమః',
           },
           {
              "title": 'ఓం త్రిపురాయై నమః',
           },
           {
              "title": 'ఓం బాలాయై నమః',
           },
           {
              "title": 'ఓం లక్ష్మ్యై నమః',
           },
           {
              "title": 'ఓం శ్రియై నమః ||20||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం భయహారిణ్యై నమః',
           },
           {
              "title": 'ఓం భవాన్యై నమః',
           },
           {
              "title": 'ఓం విష్ణుజనన్యై నమః',
           },
           {
              "title": 'ఓం బ్రహ్మాదిజనన్యై నమః',
           },
           {
              "title": 'ఓం గణేశజనన్యై నమః',
           },
           {
              "title": 'ఓం శక్త్యై నమః',
           },
           {
              "title": 'ఓం కుమారజనన్యై నమః',
           },
           {
              "title": 'ఓం శుభాయై నమః',
           },
           {
              "title": 'ఓం భోగప్రదాయై నమః',
           },
           {
              "title": 'ఓం భగవత్యై నమః ||30||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం భవరోగహరాయై నమః',
           },
           {
              "title": 'ఓం భవ్యాయై నమః',
           },
           {
              "title": 'ఓం శుభ్రాయై నమః',
           },
           {
              "title": 'ఓం పరమమంగళాయై నమః',
           },
           {
              "title": 'ఓం భవాన్యై నమః',
           },
           {
              "title": 'ఓం చంచలాయై నమః',
           },
           {
              "title": 'ఓం గౌర్యై నమః',
           },
           {
              "title": 'ఓం చారుచంద్రకళాధరాయై నమః',
           },
           {
              "title": 'ఓం విశాలాక్ష్యై నమః ||40||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం విశ్వమాత్రే నమః',
           },
           {
              "title": 'ఓం విశ్వవంద్యాయై నమః',
           },
           {
              "title": 'ఓం విలాసిన్యై నమః',
           },
           {
              "title": 'ఓం ఆర్యాయై నమః',
           },
           {
              "title": 'ఓం కళ్యాణనిలాయాయై నమః',
           },
           {
              "title": 'ఓం రుద్రాణ్యై నమః',
           },
           {
              "title": 'ఓం కమలాసనాయై నమః',
           },
           {
              "title": 'ఓం శుభప్రదాయై నమః',
           },
           {
              "title": 'ఓం శుభాయై నమః',
           },
           {
              "title": 'ఓం అనంతాయై నమః ||50||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం వృత్తపీనపయోధరాయై నమః',
           },
           {
              "title": 'ఓం అంబాయై నమః',
           },
           {
              "title": 'ఓం సంహారమథన్యై నమః',
           },
           {
              "title": 'ఓం మృడాన్యై నమః',
           },
           {
              "title": 'ఓం సర్వమంగళాయై నమః',
           },
           {
              "title": 'ఓం విష్ణుసంసేవితాయై నమః',
           },
           {
              "title": 'ఓం సిద్ధాయై నమః',
           },
           {
              "title": 'ఓం బ్రహ్మాణ్యై నమః',
           },
           {
              "title": 'ఓం సురసేవితాయై నమః',
           },
           {
              "title": 'ఓం పరమానందదాయై నమః ||60||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శాంత్యై నమః',
           },
           {
              "title": 'ఓం పరమానందరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం పరమానందజనన్యై నమః',
           },
           {
              "title": 'ఓం పరాయై నమః',
           },
           {
              "title": 'ఓం ఆనందప్రదాయిన్యై నమః',
           },
           {
              "title": 'ఓం పరోపకారనిరతాయై నమః',
           },
           {
              "title": 'ఓం పరమాయై నమః',
           },
           {
              "title": 'ఓం భక్తవత్సలాయై నమః',
           },
           {
              "title": 'ఓం పూర్ణచంద్రాభవదనాయై నమః',
           },
           {
              "title": 'ఓం పూర్ణచంద్రనిభాంశుకాయై నమః ||70||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శుభలక్షణసంపన్నాయై నమః',
           },
           {
              "title": 'ఓం శుభానందగుణార్ణవాయై నమః',
           },
           {
              "title": 'ఓం శుభసౌభాగ్యనిలయాయై నమః',
           },
           {
              "title": 'ఓం శుభదాయై నమః',
           },
           {
              "title": 'ఓం రతిప్రియాయై నమః',
           },
           {
              "title": 'ఓం చండికాయై నమః',
           },
           {
              "title": 'ఓం చండమథన్యై నమః',
           },
           {
              "title": 'ఓం చండదర్పనివారిణ్యై నమః',
           },
           {
              "title": 'ఓం మార్తాండనయనాయై నమః',
           },
           {
              "title": 'ఓం సాధ్వ్యై నమః ||80||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం చంద్రాగ్నినయనాయై నమః',
           },
           {
              "title": 'ఓం సత్యై నమః',
           },
           {
              "title": 'ఓం పుండరీకహరాయై నమః',
           },
           {
              "title": 'ఓం పూర్ణాయై నమః',
           },
           {
              "title": 'ఓం పుణ్యదాయై నమః',
           },
           {
              "title": 'ఓం పుణ్యరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం మాయాతీతాయై నమః',
           },
           {
              "title": 'ఓం శ్రేష్ఠమాయాయై నమః',
           },
           {
              "title": 'ఓం శ్రేష్ఠధర్మాత్మవందితాయై నమః',
           },
           {
              "title": 'ఓం అసృష్ట్యై నమః ||90||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సంగరహితాయై నమః',
           },
           {
              "title": 'ఓం సృష్టిహేతవే నమః',
           },
           {
              "title": 'ఓం కపర్దిన్యై నమః',
           },
           {
              "title": 'ఓం వృషారూఢాయై నమః',
           },
           {
              "title": 'ఓం శూలహస్తాయై నమః',
           },
           {
              "title": 'ఓం స్థితిసంహారకారిణ్యై నమః',
           },
           {
              "title": 'ఓం మందస్మితాయై నమః',
           },
           {
              "title": 'ఓం స్కందమాత్రే నమః',
           },
           {
              "title": 'ఓం శుద్ధచిత్తాయై నమః',
           },
           {
              "title": 'ఓం మునిస్తుతాయై నమః ||100||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మహాభగవత్యై నమః',
           },
           {
              "title": 'ఓం దక్షాయై నమః',
           },
           {
              "title": 'ఓం దక్షాధ్వరవినాశిన్యై నమః',
           },
           {
              "title": 'ఓం సర్వార్థదాత్ర్యై నమః',
           },
           {
              "title": 'ఓం సావిత్ర్యై నమః',
           },
           {
              "title": 'ఓం సదాశివకుటుంబిన్యై నమః',
           },
           {
              "title": 'ఓం నిత్యసుందరసర్వాంగ్యై నమః',
           },
           {
              "title": 'ఓం సచ్చిదానందలక్షణాయై నమః ||108||',
           },
           {
              "title": '',
           },
           {
              "title": '|| ఇతి శ్రీ అన్నపూర్ణా అష్టోత్తర శతనామావళి సమాప్తం ||',
           }
         ],
          "title": 'శ్రీ అన్నపూర్ణా అష్టోత్తర శతనామావళి',
         // "img": require('../imgs/Astotaram/annapurna.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం జ్యోతిర్లింగస్వరూపాయ నమః',
           },
           {
              "title": 'ఓం సౌరాఫ్టేసు సంస్థితాయ నమః',
           },
           {
              "title": 'ఓం శివాయ నమః',
           },
           {
              "title": 'ఓం సోమనాథనిలయాయ నమః',
           },
           {
              "title": 'ఓం సోమరాధితాయ నమః',
           },
           {
              "title": 'ఓం శంభవే నమః',
           },
           {
              "title": 'ఓం శ్రీహరాయం నమః',
           },
           {
              "title": 'ఓం శ్రీశైలాధినాథాయ నమః',
           },
           {
              "title": 'ఓం శ్రీభ్రమరాంబపతయే నమః',
           },
           {
              "title": 'ఓం భవాయ నమః ||10||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం త్రిలోకాధినాథాయ నమః',
           },
           {
              "title": 'ఓం నిత్యాయ నమః',
           },
           {
              "title": 'ఓం సర్వలక్షణ లక్షితాయనమః',
           },
           {
              "title": 'ఓం సోమసూర్యాగ్నిలోచనాయనమః',
           },
           {
              "title": 'ఓం దేవాసురగణాశ్రయాయ నమః',
           },
           {
              "title": 'ఓం సర్వబంధవిమోచనాయ నమః',
           },
           {
              "title": 'ఓం జితకామాయ  నమః',
           },
           {
              "title": 'ఓం తేజస్కరాయ నమః',
           },
           {
              "title": 'ఓం భక్తానుగ్రహకారకాయ నమః',
           },
           {
              "title": 'ఓం వరదాయ నమః ||20||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మల్లికార్జునాయ నమః',
           },
           {
              "title": 'ఓం ఉజ్జయినీ పురపతయే నమః',
           },
           {
              "title": 'ఓం మహాకాలయ నమః',
           },
           {
              "title": 'ఓం మహేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం విశ్వరూపాయ నమః',
           },
           {
              "title": 'ఓం విరూపాక్షాయ నమః',
           },
           {
              "title": 'ఓం దేవాసురవరప్రదాయ నమః',
           },
           {
              "title": 'ఓం వృషభారూఢాయ నమః',
           },
           {
              "title": 'ఓం త్రినేత్రాయ నమః',
           },
           {
              "title": 'ఓం త్రిపురాంతకాయ నమః ||30||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సర్వజ్ఞాయ నమః',
           },
           {
              "title": 'ఓం మహాకాళీనాథాయ నమః',
           },
           {
              "title": 'ఓం అవంతీపురాధిపతయే నమః',
           },
           {
              "title": 'ఓం విముక్తాయ నమః',
           },
           {
              "title": 'ఓం దూషణాసురమర్ధనాయ నమః',
           },
           {
              "title": 'ఓం మోక్షప్రదాయ నమః',
           },
           {
              "title": 'ఓం వేదప్రియాయ నమః',
           },
           {
              "title": 'ఓం ఓంకారపురవాసాయనమః',
           },
           {
              "title": 'ఓం వింధ్యాద్రిబలగర్వాపహరాయ నమః',
           },
           {
              "title": 'ఓం మాంధాతృపురనివాసాయ నమః ||40||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అన్నపూర్ణాపతయే నమః',
           },
           {
              "title": 'ఓం ఈశ్వరాయ నమః',
           },
           {
              "title": 'ఓం బదరికాశ్రమనివాసాయ నమః',
           },
           {
              "title": 'ఓం దేవాసురసం సేవితాయ నమః',
           },
           {
              "title": 'ఓం గౌరీనాథాయ నమః',
           },
           {
              "title": 'ఓం కేదారేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం అక్షయభాగ్యప్రదాయ నమః',
           },
           {
              "title": 'ఓం సర్వశుభంకరాయ నమః',
           },
           {
              "title": 'ఓం అవ్యయాయ నమః',
           },
           {
              "title": 'ఓం అనంతరూపాయ నమః ||50||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం వ్యోమకేశాయ నమః',
           },
           {
              "title": 'ఓం వారణాసీపురపతయే నమః',
           },
           {
              "title": 'ఓం విశ్వనాథాయ నమః',
           },
           {
              "title": 'ఓం సర్వాశ్రయాయ నమః',
           },
           {
              "title": 'ఓం సర్వదేవమునివందితాయ నమః',
           },
           {
              "title": 'ఓం యజ్ఞసమాహితాయ నమః',
           },
           {
              "title": 'ఓం మణికర్ణికా తీర్థనిలయాయనమః',
           },
           {
              "title": 'ఓం మరణాన్ముక్తిదాయ నమః',
           },
           {
              "title": 'ఓం గౌతమారాధితాయ నమః',
           },
           {
              "title": 'ఓం శివాయ నమః ||60||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం గౌతమీజన్మకారణాయ నమః',
           },
           {
              "title": 'ఓం బ్రహ్మహత్యాపాపహరాయనమః',
           },
           {
              "title": 'ఓం త్ర్యంబకాయ నమః',
           },
           {
              "title": 'ఓం సుతీర్థాయఓం శర్వాయ నమః',
           },
           {
              "title": 'ఓం దేవాసురవరప్రదాయ నమః',
           },
           {
              "title": 'ఓం యజ్ఞరూపాయ నమః',
           },
           {
              "title": 'ఓం మృత్యుంజయాయ నమః',
           },
           {
              "title": 'ఓం చితాభూమిష్ఠితావాసాయనమః',
           },
           {
              "title": 'ఓం వైద్యనాథప్రకీర్తితాయ నమః',
           },
           {
              "title": 'ఓం సర్వబాధానివాకరాయ నమః ||70||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం రావణగర్వమర్ధనాయ నమః',
           },
           {
              "title": 'ఓం సర్వభూతహరాయ నమః',
           },
           {
              "title": 'ఓం రుద్రాయ నమః',
           },
           {
              "title": 'ఓం సర్వకామదాయ నమః',
           },
           {
              "title": 'ఓం శాశ్వతాయ నమః',
           },
           {
              "title": 'ఓం ద్వారకా ప్రాంతనివాసాయ నమః',
           },
           {
              "title": 'ఓం నాగనాథాఖ్య వర్ణితాయ నమః',
           },
           {
              "title": 'ఓం దారకాది రాక్షసాంతకాయ నమః',
           },
           {
              "title": 'ఓం భక్తజనరక్షకాయ నమః',
           },
           {
              "title": 'ఓం ఢాకినీవాసాయ నమః ||80||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శంకరాయ నమః',
           },
           {
              "title": 'ఓం భీమాసుర విమర్ధనాయ నమః',
           },
           {
              "title": 'ఓం మాణిక్యాంబాప్రియనాథాయ నమః',
           },
           {
              "title": 'ఓం దక్షారామనివాసినే నమః',
           },
           {
              "title": 'ఓం అమృతాయ నమః',
           },
           {
              "title": 'ఓం సర్వపూజితాయ నమః',
           },
           {
              "title": 'ఓం చంద్రశేఖరాయ నమః',
           },
           {
              "title": 'ఓం సుముఖాయ నమః',
           },
           {
              "title": 'ఓం అవ్యక్తాయ నమః',
           },
           {
              "title": 'ఓం త్రిపురాంతకాయ నమః ||90||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం గిరిజాప్రియాయ నమః',
           },
           {
              "title": 'ఓం విముకాయ నమః',
           },
           {
              "title": 'ఓం శితికంఠాయ నమః',
           },
           {
              "title": 'ఓం శూలపాణయే నమః',
           },
           {
              "title": 'ఓం మహాక్రోథాయ నమః',
           },
           {
              "title": 'ఓం మహేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం దేవాసురమునివందితాయ నమః',
           },
           {
              "title": 'ఓం శ్రీరామప్రతిష్ఠితాయ నమః',
           },
           {
              "title": 'ఓం ధనుష్కోటి ప్రాంతవాసాయ నమః',
           },
           {
              "title": 'ఓం సీతారమసం సేవితాయ నమః ||100||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం హనుమత్సేవితాయ నమః',
           },
           {
              "title": 'ఓం సోమాయ నమః',
           },
           {
              "title": 'ఓం సర్వపాపనివారకాయ నమః',
           },
           {
              "title": 'ఓం దేవగిరిదుర్గవాసాయ నమః',
           },
           {
              "title": 'ఓం ఘశ్మలారాధితాయ నమః',
           },
           {
              "title": 'ఓం సాంబాయ నమః',
           },
           {
              "title": 'ఓం అపమృత్యు నివారకాయ నమః',
           },
           {
              "title": 'ఓం జ్యోతిర్లింగాధ్యక్షాయ నమః ||108||',
           },
           {
              "title": '',
           },
           {
              "title": '|| ఇతి శ్రీ ద్వాదశ జ్యోతిర్లింగ అష్టోత్తర శతనామావళి సంపూర్ణం ||',
           }
         ],
          "title": 'శ్రీ ద్వాదశ జ్యోతిర్లింగ అష్టోత్తర శతనామావళి',
         // "img": require('../imgs/Astotaram/Dwadasa_Jyotirlinga.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం శోణాద్రీశాయ నమః',
           },
           {
              "title": 'ఓం అరుణాద్రీశాయ నమః',
           },
           {
              "title": 'ఓం దేవాధీశాయ నమః',
           },
           {
              "title": 'ఓం జనప్రియాయ నమః',
           },
           {
              "title": 'ఓం ప్రపన్నరక్షకాయ నమః',
           },
           {
              "title": 'ఓం ధీరాయ నమః',
           },
           {
              "title": 'ఓం శివాయ నమః',
           },
           {
              "title": 'ఓం సేవకవర్ధకాయ నమః',
           },
           {
              "title": 'ఓం అక్షిపేయామృతేశానాయ నమః',
           },
           {
              "title": 'ఓం స్త్రీపుంభావప్రదాయకాయ నమః ||10||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం భక్తవిజ్ఞప్తిసమాదాత్రే నమః',
           },
           {
              "title": 'ఓం దీనబంధువిమోచకాయ నమః',
           },
           {
              "title": 'ఓం ముఖరాంఘ్రిపతయే నమః',
           },
           {
              "title": 'ఓం శ్రీమతే నమః',
           },
           {
              "title": 'ఓం మృడాయ నమః',
           },
           {
              "title": 'ఓం మృగమదేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం భక్తప్రేక్షణాకృతే నమః',
           },
           {
              "title": 'ఓం సాక్షిణే నమః',
           },
           {
              "title": 'ఓం భక్తదోషనివర్తకాయ నమః',
           },
           {
              "title": 'ఓం జ్ఞానసంబంధనాథాయ నమః ||20||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శ్రీహాలాహలసుందరాయ నమః',
           },
           {
              "title": 'ఓం ఆహువైశ్వర్యదాతాయ నమః',
           },
           {
              "title": 'ఓం స్మృతసర్వాఘనాశనాయ నమః',
           },
           {
              "title": 'ఓం వ్యతస్తనృత్యాయ నమః',
           },
           {
              "title": 'ఓం ధ్వజధృతే నమః',
           },
           {
              "title": 'ఓం సకాంతినే నమః',
           },
           {
              "title": 'ఓం నటనేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం సామప్రియాయ నమః',
           },
           {
              "title": 'ఓం కలిధ్వంసినే నమః',
           },
           {
              "title": 'ఓం వేదమూర్తినే నమః ||30||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం నిరంజనాయ నమః',
           },
           {
              "title": 'ఓం జగన్నాథాయ నమః',
           },
           {
              "title": 'ఓం మహాదేవాయ నమః',
           },
           {
              "title": 'ఓం త్రినేత్రే నమః',
           },
           {
              "title": 'ఓం త్రిపురాంతకాయ నమః',
           },
           {
              "title": 'ఓం భక్తాపరాధసోఢాయ నమః',
           },
           {
              "title": 'ఓం యోగీశాయ నమః',
           },
           {
              "title": 'ఓం భోగనాయకాయ నమః',
           },
           {
              "title": 'ఓం బాలమూర్తయే నమః',
           },
           {
              "title": 'ఓం క్షమారూపిణే నమః ||40||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం ధర్మరక్షకాయ నమః',
           },
           {
              "title": 'ఓం వృషధ్వజాయ నమః',
           },
           {
              "title": 'ఓం హరాయ నమః',
           },
           {
              "title": 'ఓం గిరీశ్వరాయ నమః',
           },
           {
              "title": 'ఓం భర్గాయ నమః',
           },
           {
              "title": 'ఓం చంద్రరేఖావతంసకాయ నమః',
           },
           {
              "title": 'ఓం స్మరాంతకాయ నమః',
           },
           {
              "title": 'ఓం అంధకరిపవే నమః',
           },
           {
              "title": 'ఓం సిద్ధరాజాయ నమః',
           },
           {
              "title": 'ఓం దిగంబరాయ నమః ||50||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం ఆగమప్రియాయ నమః',
           },
           {
              "title": 'ఓం ఈశానాయ నమః',
           },
           {
              "title": 'ఓం భస్మరుద్రాక్షలాంఛనాయ నమః',
           },
           {
              "title": 'ఓం శ్రీపతయే నమః',
           },
           {
              "title": 'ఓం శంకరాయ నమః',
           },
           {
              "title": 'ఓం సృష్టాయ నమః',
           },
           {
              "title": 'ఓం సర్వవిద్యేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం అనఘాయ నమః',
           },
           {
              "title": 'ఓం గంగాధరాయ నమః',
           },
           {
              "title": 'ఓం క్రతుధ్వంసినే నమః ||60||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం విమలాయ నమః',
           },
           {
              "title": 'ఓం నాగభూషణాయ నమః',
           },
           {
              "title": 'ఓం అరుణాయ నమః',
           },
           {
              "title": 'ఓం బహురూపాయ నమః',
           },
           {
              "title": 'ఓం విరూపాక్షాయ నమః',
           },
           {
              "title": 'ఓం అక్షరాకృతయే నమః',
           },
           {
              "title": 'ఓం అనాద్యంతరహితాయ నమః',
           },
           {
              "title": 'ఓం శివకామాయ నమః',
           },
           {
              "title": 'ఓం స్వయంప్రభవే నమః',
           },
           {
              "title": 'ఓం సచ్చిదానందరూపాయ నమః ||70||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సర్వాత్మాయ నమః',
           },
           {
              "title": 'ఓం జీవధారకాయ నమః',
           },
           {
              "title": 'ఓం స్త్రీసంగవామభాగాయ నమః',
           },
           {
              "title": 'ఓం విధయే నమః',
           },
           {
              "title": 'ఓం విహితసుందరాయ నమః',
           },
           {
              "title": 'ఓం జ్ఞానప్రదాయ నమః',
           },
           {
              "title": 'ఓం ముక్తిదాయ నమః',
           },
           {
              "title": 'ఓం భక్తవాంఛితదాయకాయ నమః',
           },
           {
              "title": 'ఓం ఆశ్చర్యవైభవాయ నమః',
           },
           {
              "title": 'ఓం కామినే నమః ||80||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం నిరవద్యాయ నమః',
           },
           {
              "title": 'ఓం నిధిప్రదాయ నమః',
           },
           {
              "title": 'ఓం శూలినే నమః',
           },
           {
              "title": 'ఓం పశుపతయే నమః',
           },
           {
              "title": 'ఓం శంభవే నమః',
           },
           {
              "title": 'ఓం స్వయంభువే నమః',
           },
           {
              "title": 'ఓం గిరీశాయ నమః',
           },
           {
              "title": 'ఓం సంగీతవేత్రే నమః',
           },
           {
              "title": 'ఓం నృత్యజ్ఞాయ నమః',
           },
           {
              "title": 'ఓం త్రివేదినే నమః ||90||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం వృద్ధవైదికాయ నమః',
           },
           {
              "title": 'ఓం త్యాగరాజాయ నమః',
           },
           {
              "title": 'ఓం కృపాసింధవే నమః',
           },
           {
              "title": 'ఓం సుగంధినే నమః',
           },
           {
              "title": 'ఓం సౌరభేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం కర్తవీరేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం శాంతాయ నమః',
           },
           {
              "title": 'ఓం కపాలినే నమః',
           },
           {
              "title": 'ఓం కలశప్రభవే నమః',
           },
           {
              "title": 'ఓం పాపహరాయ నమః ||100||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం దేవదేవాయ నమః',
           },
           {
              "title": 'ఓం సర్వనామ్నే నమః',
           },
           {
              "title": 'ఓం మనోవాసాయ నమః',
           },
           {
              "title": 'ఓం సర్వాయ నమః',
           },
           {
              "title": 'ఓం అరుణగిరీశ్వరాయ నమః',
           },
           {
              "title": 'ఓం కాలమూర్తయే నమః',
           },
           {
              "title": 'ఓం స్మృతిమాత్రేణసంతుష్టాయ నమః',
           },
           {
              "title": 'ఓం శ్రీమదపీతకుచాంబాసమేత శ్రీఅరుణాచలేశ్వరాయ నమః ||108||',
           },
           {
              "title": '',
           },
 
           {
              "title": '|| ఇతి శ్రీ అరుణాచలేశ్వర అష్టోత్తర శతనామావళి సంపూర్ణం ||',
           }
         ],
          "title": 'శ్రీ అరుణాచలేశ్వర అష్టోత్తర శతనామావళి',
         // "img": require('../imgs/Astotaram/Arunachaleswara.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం కాలకంఠ్యై నమః',
           },
           {
              "title": 'ఓం త్రిపురాయై నమః',
           },
           {
              "title": 'ఓం బాలాయై నమః',
           },
           {
              "title": 'ఓం మాయాయై నమః',
           },
           {
              "title": 'ఓం త్రిపురసుందర్యై నమః',
           },
           {
              "title": 'ఓం సుందర్యై నమః',
           },
           {
              "title": 'ఓం సౌభాగ్యవత్యై నమః',
           },
           {
              "title": 'ఓం క్లీంకార్యై నమః',
           },
           {
              "title": 'ఓం సర్వమంగళాయై నమః',
           },
           {
              "title": 'ఓం ఐంకార్యై నమః ||10||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం స్కందజనన్యై నమః',
           },
           {
              "title": 'ఓం పరాయై నమః',
           },
           {
              "title": 'ఓం పంచదశాక్షర్యై నమః',
           },
           {
              "title": 'ఓం త్రైలోక్యమోహనాధీశాయై నమః',
           },
           {
              "title": 'ఓం సర్వాశాపూరవల్లభాయై నమః',
           },
           {
              "title": 'ఓం సర్వసంక్షోభణాధీశాయై నమః',
           },
           {
              "title": 'ఓం సర్వసౌభాగ్యవల్లభాయై నమః',
           },
           {
              "title": 'ఓం సర్వార్థసాధకాధీశాయై నమః',
           },
           {
              "title": 'ఓం సర్వరక్షాకరాధిపాయై నమః',
           },
           {
              "title": 'ఓం సర్వరోగహరాధీశాయై నమః ||20||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సర్వసిద్ధిప్రదాధిపాయై నమః',
           },
           {
              "title": 'ఓం సర్వానందమయాధీశాయై నమః',
           },
           {
              "title": 'ఓం యోగినీచక్రనాయికాయై నమః',
           },
           {
              "title": 'ఓం భక్తానురక్తాయై నమః',
           },
           {
              "title": 'ఓం రక్తాంగ్యై నమః',
           },
           {
              "title": 'ఓం శంకరార్ధశరీరిణ్యై నమః',
           },
           {
              "title": 'ఓం పుష్పబాణేక్షుకోదండపాశాంకుశకరాయై నమః',
           },
           {
              "title": 'ఓం ఉజ్జ్వలాయై నమః',
           },
           {
              "title": 'ఓం సచ్చిదానందలహర్యై నమః',
           },
           {
              "title": 'ఓం శ్రీవిద్యాయై నమః ||30||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం పరమేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం అనంగకుసుమోద్యానాయై నమః',
           },
           {
              "title": 'ఓం చక్రేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం భువనేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం గుప్తాయై నమః',
           },
           {
              "title": 'ఓం గుప్తతరాయై నమః',
           },
           {
              "title": 'ఓం నిత్యాయై నమః',
           },
           {
              "title": 'ఓం నిత్యక్లిన్నాయై నమః',
           },
           {
              "title": 'ఓం మదద్రవాయై నమః',
           },
           {
              "title": 'ఓం మోహిన్యై నమః ||40||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం పరమానందాయై నమః',
           },
           {
              "title": 'ఓం కామేశ్యై నమః',
           },
           {
              "title": 'ఓం తరుణీకలాయై నమః',
           },
           {
              "title": 'ఓం కలావత్యై నమః',
           },
           {
              "title": 'ఓం భగవత్యై నమః',
           },
           {
              "title": 'ఓం పద్మరాగకిరీటాయై నమః',
           },
           {
              "title": 'ఓం రక్తవస్త్రాయై నమః',
           },
           {
              "title": 'ఓం రక్తభూషాయై నమః',
           },
           {
              "title": 'ఓం రక్తగంధానులేపనాయై నమః',
           },
           {
              "title": 'ఓం సౌగంధికలసద్వేణ్యై నమః ||50||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మంత్రిణ్యై నమః',
           },
           {
              "title": 'ఓం తంత్రరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం తత్త్వమయ్యై నమః',
           },
           {
              "title": 'ఓం సిద్ధాంతపురవాసిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీమత్యై నమః',
           },
           {
              "title": 'ఓం చిన్మయ్యై నమః',
           },
           {
              "title": 'ఓం దేవ్యై నమః',
           },
           {
              "title": 'ఓం కౌళిన్యై నమః',
           },
           {
              "title": 'ఓం పరదేవతాయై నమః',
           },
           {
              "title": 'ఓం కైవల్యరేఖాయై నమః ||60||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం వశిన్యై నమః',
           },
           {
              "title": 'ఓం సర్వేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం సర్వమాతృకాయై నమః',
           },
           {
              "title": 'ఓం విష్ణుస్వస్రే నమః',
           },
           {
              "title": 'ఓం వేదమయ్యై నమః',
           },
           {
              "title": 'ఓం సర్వసంపత్ప్రదాయిన్యై నమః',
           },
           {
              "title": 'ఓం కింకరీభూతగీర్వాణ్యై నమః',
           },
           {
              "title": 'ఓం సుతవాపివినోదిన్యై నమః',
           },
           {
              "title": 'ఓం మణిపూరసమాసీనాయై నమః',
           },
           {
              "title": 'ఓం అనాహతాబ్జవాసిన్యై నమః ||70||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం విశుద్ధిచక్రనిలయాయై నమః',
           },
           {
              "title": 'ఓం ఆజ్ఞాపద్మనివాసిన్యై నమః',
           },
           {
              "title": 'ఓం అష్టత్రింశత్కళామూర్త్యై నమః',
           },
           {
              "title": 'ఓం సుషుమ్నాద్వారమధ్యగాయై నమః',
           },
           {
              "title": 'ఓం యోగీశ్వరమనోధ్యేయాయై నమః',
           },
           {
              "title": 'ఓం పరబ్రహ్మస్వరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం చతుర్భుజాయై నమః',
           },
           {
              "title": 'ఓం చంద్రచూడాయై నమః',
           },
           {
              "title": 'ఓం పురాణాగమరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం ఓంకార్యై నమః ||80||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం విమలాయై నమః',
           },
           {
              "title": 'ఓం విద్యాయై నమః',
           },
           {
              "title": 'ఓం పంచప్రణవరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం భూతేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం భూతమయ్యై నమః',
           },
           {
              "title": 'ఓం పంచాశత్పీఠరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం షోడశన్యాసమహారూపిణ్యై నమః',
           },
           {
              "title": 'ఓం కామాక్ష్యై నమః',
           },
           {
              "title": 'ఓం దశమాతృకాయై నమః',
           },
           {
              "title": 'ఓం ఆధారశక్త్యై నమః ||90||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అరుణాయై నమః',
           },
           {
              "title": 'ఓం లక్ష్మ్యై నమః',
           },
           {
              "title": 'ఓం త్రిపురభైరవ్యై నమః',
           },
           {
              "title": 'ఓం రహఃపూజాసమాలోలాయై నమః',
           },
           {
              "title": 'ఓం రహోయంత్రస్వరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం త్రికోణమధ్యనిలయాయై నమః',
           },
           {
              "title": 'ఓం బిందుమండలవాసిన్యై నమః',
           },
           {
              "title": 'ఓం వసుకోణపురావాసాయై నమః',
           },
           {
              "title": 'ఓం దశారద్వయవాసిన్యై నమః',
           },
           {
              "title": 'ఓం చతుర్దశారచక్రస్థాయై నమః ||100||',
           },
           {
              "title": '',
           }, {
              "title": 'ఓం వసుపద్మనివాసిన్యై నమః',
           },
           {
              "title": 'ఓం స్వరాబ్జపత్రనిలయాయై నమః',
           },
           {
              "title": 'ఓం వృందత్రయవాసిన్యై నమః',
           },
           {
              "title": 'ఓం చతురస్రస్వరూపాస్యాయై నమః',
           },
           {
              "title": 'ఓం నవచక్రస్వరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం మహానిత్యాయై నమః',
           },
           {
              "title": 'ఓం విజయాయై నమః',
           },
           {
              "title": 'ఓం శ్రీ రాజరాజేశ్వర్యై నమః ||108||',
           },
           {
              "title": '',
           },
           {
              "title": '|| ఇతి శ్రీ కామాక్షి అష్టోత్తర శతనామావళి సంపూర్ణం ||',
           }
         ],
          "title": 'శ్రీ కామాక్షి అష్టోత్తర శతనామావళి',
         // "img": require('../imgs/Astotaram/Kamakshi.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం త్రిపురసుందర్యై నమః',
           },
           {
              "title": 'ఓం హృదయదేవ్యై నమః',
           },
           {
              "title": 'ఓం శిరోదేవ్యై నమః',
           },
           {
              "title": 'ఓం కవచదేవ్యై నమః',
           },
           {
              "title": 'ఓం అస్త్రదేవ్యై నమః',
           },
           {
              "title": 'ఓం కామేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం భగమాలిన్యై నమః',
           },
           {
              "title": 'ఓం భేరుండాయై నమః',
           },
           {
              "title": 'ఓం వహ్నివాసిన్యై నమః',
           },
           {
              "title": 'ఓం మహావజేశ్వర్యై నమః ||10||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శివదూత్యై నమః',
           },
           {
              "title": 'ఓం కులసుందర్యై నమః',
           },
           {
              "title": 'ఓం నిత్యాయై నమః',
           },
           {
              "title": 'ఓం నీలపతాకాయ నమః',
           },
           {
              "title": 'ఓం విజయాయై నమః',
           },
           {
              "title": 'ఓం సర్వమంగళాయై నమః',
           },
           {
              "title": 'ఓం చిత్రాయై నమః',
           },
           {
              "title": 'ఓం మహానిత్యాయై నమః',
           },
           {
              "title": 'ఓం మిత్రేశమయ్యై నమః',
           },
           {
              "title": 'ఓం షష్ఠీశమయై నమః ||20||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అగస్త్యమయ్యై నమః',
           },
           {
              "title": 'ఓం కాలతాపనమయ్యై నమః',
           },
           {
              "title": 'ఓం ధర్మాచార్యమయ్యై నమః',
           },
           {
              "title": 'ఓం విష్ణుదేవమయ్యై నమః',
           },
           {
              "title": 'ఓం ప్రభాకరదేవమయ్యై నమః',
           },
           {
              "title": 'ఓం తేజోదేవమయ్యై నమః',
           },
           {
              "title": 'ఓం మనోజదేవమయ్యై నమః',
           },
           {
              "title": 'ఓం కళ్యాణదేవమయై నమః',
           },
           {
              "title": 'ఓం వాసుదేవమయై నమః',
           },
           {
              "title": 'ఓం రత్నదేవమయై నమః ||30||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అణిమాసిద్ధయై నమః',
           },
           {
              "title": 'ఓం లఘిమాసిద్ధయే నమః',
           },
           {
              "title": 'ఓం గరిమాసిద్ధయే నమః',
           },
           {
              "title": 'ఓం మహిమాసిద్ధయే నమః',
           },
           {
              "title": 'ఓం ప్రాప్తిసిద్ధయే నమః',
           },
           {
              "title": 'ఓం ఈశత్వసిద్ధయే నమః',
           },
           {
              "title": 'ఓం ప్రాకామ్యసిద్ధయే నమః',
           },
           {
              "title": 'ఓం భుక్తి సిద్ధయే నమః',
           },
           {
              "title": 'ఓం సర్వకామసిద్ధయే నమః',
           },
           {
              "title": 'ఓం బ్రాహ్యై నమః ||40||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మహేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం క్రైమార్యై నమః',
           },
           {
              "title": 'ఓం వైష్ణవ్యై నమః',
           },
           {
              "title": 'ఓం వారాహ్యై నమః',
           },
           {
              "title": 'ఓం మాహేంద్ర్యై నమః',
           },
           {
              "title": 'ఓం చాముండాయై నమః',
           },
           {
              "title": 'ఓం సర్వసంక్షోభిణ్యై నమః',
           },
           {
              "title": 'ఓం సర్వవిద్రావిణ్యై నమః',
           },
           {
              "title": 'ఓం సర్వాకర్షిణ్యై నమః',
           },
           {
              "title": 'ఓం ప్రకటయోగిన్యై నమః ||50||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం కామకర్షిణ్యె నమః',
           },
           {
              "title": 'ఓం బుద్ధ్యాకర్షిణ్యై నమః',
           },
           {
              "title": 'ఓం అహంకారాకర్షిణ్యె నమః',
           },
           {
              "title": 'ఓం శబ్దాకర్షిణ్యై నమః',
           },
           {
              "title": 'ఓం స్పర్షాకర్షిణ్యై నమః',
           },
           {
              "title": 'ఓం రూపాకర్షిణ్యై నమః',
           },
           {
              "title": 'ఓం రసాకర్షిణ్యై నమః',
           },
           {
              "title": 'ఓం గంధాకర్షిణ్యై నమః',
           },
           {
              "title": 'ఓం చిత్తాకర్షిణ్యై నమః',
           },
           {
              "title": 'ఓం ధైర్యాకర్షిణ్యై నమః ||60||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం స్కృతాకర్షిణ్యై నమః',
           },
           {
              "title": 'ఓం బీజాకర్షిణ్యై నమః',
           },
           {
              "title": 'ఓం ఆత్మాకర్షిణ్యై నమః',
           },
           {
              "title": 'ఓం అమృతాకరిణ్యై నమః',
           },
           {
              "title": 'ఓం శరీరాకర్షిణ్యై నమః',
           },
           {
              "title": 'ఓం గుప్తయోగిన్యై నమః',
           },
           {
              "title": 'ఓం అనంగకుసుమాయై నమః',
           },
           {
              "title": 'ఓం అనంగమదనాయై నమః',
           },
           {
              "title": 'ఓం అనంగరేఖాయై నమః',
           },
           {
              "title": 'ఓం అనంగమాలిన్యై నమః ||70||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం గుప్తతరయోగిన్యై నమః',
           },
           {
              "title": 'ఓం సర్వాహ్లాదిన్యై నమః',
           },
           {
              "title": 'ఓం సర్వసంపత్తిపూరణ్యై నమః',
           },
           {
              "title": 'ఓం సర్వమంత్రమయ్యై నమః',
           },
           {
              "title": 'ఓం కులోత్తీర్ణయోగిన్యై నమః',
           },
           {
              "title": 'ఓం సర్వజ్ఞాయ నమః',
           },
           {
              "title": 'ఓం సర్వశక్తి నమః',
           },
           {
              "title": 'ఓం సర్వైశ్వరప్రదాయిన్యై నమః',
           },
           {
              "title": 'ఓం సర్వజ్ఞానమయై నమః',
           },
           {
              "title": 'ఓం సర్వవ్యాధివినాశిన్యై నమః ||80||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సర్వాధారస్వరూపాయై నమః',
           },
           {
              "title": 'ఓం సర్వపాపహరాయై నమః',
           },
           {
              "title": 'ఓం సర్వానందమయ్యై నమః',
           },
           {
              "title": 'ఓం సర్వరక్షాస్వరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం సర్వేప్సిత ఫలప్రదాయై నమః',
           },
           {
              "title": 'ఓం సర్వరక్షాకరచక్రస్వామిన్యై నమః',
           },
           {
              "title": 'ఓం నిగర్భయోగిన్యై నమః',
           },
           {
              "title": 'ఓం వశిన్యై నమః',
           },
           {
              "title": 'ఓం కామేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం మోదిన్యై నమః ||90||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం విమలాయై నమః',
           },
           {
              "title": 'ఓం అరుణాయై నమః',
           },
           {
              "title": 'ఓం జయిన్యై నమః',
           },
           {
              "title": 'ఓం సర్వేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం కౌళిణ్యై నమః',
           },
           {
              "title": 'ఓం రహస్యయోగిన్యై నమః',
           },
           {
              "title": 'ఓం బాణిన్యై నమః',
           },
           {
              "title": 'ఓం చాపిన్యై నమః',
           },
           {
              "title": 'ఓం పాశిన్యై నమః',
           },
           {
              "title": 'ఓం అంకుశిన్యై నమః ||100||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మహాకామేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం మహావజేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం మహాభగమాలిన్యై నమః',
           },
           {
              "title": 'ఓం సర్వసిద్ధి ప్రదచక్రస్వామిన్యై నమః',
           },
           {
              "title": 'ఓం అతిరహస్యయోగిన్యై నమః',
           },
           {
              "title": 'ఓం శ్రీ శ్రీ మహాభట్టారికాయై నమః',
           },
           {
              "title": 'ఓం మహాత్రిపురసుందర్యై నమః',
           },
           {
              "title": 'ఓం మహామహేశ్వర్యై నమః ||108||',
           },
           {
              "title": '',
           },
           {
              "title": '|| ఇతి శ్రీ దేవీ ఖడ్గమాలా అష్టోత్తర శతనామావళి సంపూర్ణం ||',
           }
         ],
          "title": 'శ్రీ దేవీ ఖడ్గమాలా అష్టోత్తర శతనామావళి',
         // "img": require('../imgs/Astotaram/Devi.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం నారశింహాయ నమః',
           },
           {
              "title": 'ఓం మహాసింహాయ నమః',
           },
           {
              "title": 'ఓం దివ్య సింహాయ నమః',
           },
           {
              "title": 'ఓం మహాబలాయ నమః',
           },
           {
              "title": 'ఓం ఉగ్ర సింహాయ నమః',
           },
           {
              "title": 'ఓం మహాదేవాయ నమః',
           },
           {
              "title": 'ఓం స్తంభజాయ నమః',
           },
           {
              "title": 'ఓం ఉగ్రలోచనాయ నమః',
           },
           {
              "title": 'ఓం రౌద్రాయ నమః',
           },
           {
              "title": 'ఓం సర్వాద్భుతాయ నమః ||10||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శ్రీమాత్రే నమః',
           },
           {
              "title": 'ఓం యోగనందాయ నమః',
           },
           {
              "title": 'ఓం త్రివిక్రమాయ నమః',
           },
           {
              "title": 'ఓం హరయే నమః',
           },
           {
              "title": 'ఓం కోలాహలాయ నమః',
           },
           {
              "title": 'ఓం చక్రిణే నమః',
           },
           {
              "title": 'ఓం విజయినే నమః',
           },
           {
              "title": 'ఓం జయ వర్ధనాయ నమః',
           },
           {
              "title": 'ఓం పంచాసనాయ నమః',
           },
           {
              "title": 'ఓం పరబ్రహ్మయ నమః ||20||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అఘోరాయ నమః',
           },
           {
              "title": 'ఓం ఘోరవిక్రమాయ నమః',
           },
           {
              "title": 'ఓం జ్వలన్ముఖాయ నమః',
           },
           {
              "title": 'ఓం జ్వాలామాలినే నమః',
           },
           {
              "title": 'ఓం మహా జ్వాలాయ నమః',
           },
           {
              "title": 'ఓం మహా ప్రభవే నమః',
           },
           {
              "title": 'ఓం నిటలాక్షాయ నమః',
           },
           {
              "title": 'ఓం సహస్రాక్షాయ నమః',
           },
           {
              "title": 'ఓం దుర్నిరీక్షాయ నమః',
           },
           {
              "title": 'ఓం ప్రతాపనాయ నమః ||30||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మహాదంష్ట్రాయుధాయ నమః',
           },
           {
              "title": 'ఓం ప్రజ్ఞాయ నమః',
           },
           {
              "title": 'ఓం చండకోపాయ నమః',
           },
           {
              "title": 'ఓం సదాశివాయ నమః',
           },
           {
              "title": 'ఓం హిరణ్యకశిపు ధ్వంసినే నమః',
           },
           {
              "title": 'ఓం దైత్యదాన భంజనాయ నమః',
           },
           {
              "title": 'ఓం గుణభద్రాయ నమః',
           },
           {
              "title": 'ఓం మహాభద్రాయ నమః',
           },
           {
              "title": 'ఓం బలభద్రాయ నమః',
           },
           {
              "title": 'ఓం సుభద్రాయ నమః ||40||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం కరాళాయ నమః',
           },
           {
              "title": 'ఓం వికరాళాయ నమః',
           },
           {
              "title": 'ఓం వికర్త్రే నమః',
           },
           {
              "title": 'ఓం సర్వకర్తృకాయ నమః',
           },
           {
              "title": 'ఓం శింశుమా రాయ నమః',
           },
           {
              "title": 'ఓం త్రిలోకాత్మనే నమః',
           },
           {
              "title": 'ఓం ఈశాయ నమః',
           },
           {
              "title": 'ఓం సర్వేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం విభవే నమః',
           },
           {
              "title": 'ఓం భైరవాడంబరాయ నమః ||50||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం దివ్యాయ నమః',
           },
           {
              "title": 'ఓం అచ్యుతాయ నమః',
           },
           {
              "title": 'ఓం కవి మాధవాయ నమః',
           },
           {
              "title": 'ఓం అధోక్షజాయ నమః',
           },
           {
              "title": 'ఓం అక్షరాయ నమః',
           },
           {
              "title": 'ఓం శర్వాయ నమః',
           },
           {
              "title": 'ఓం వనమాలినే నమః',
           },
           {
              "title": 'ఓం వరప్రదాయ నమః',
           },
           {
              "title": 'ఓం విశ్వంభరాయ నమః',
           },
           {
              "title": 'ఓం అధ్భుతాయ నమః ||60||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం భవ్యాయ నమః',
           },
           {
              "title": 'ఓం శ్రీ విష్ణవే నమః',
           },
           {
              "title": 'ఓం పురుషోత్తమాయ నమః',
           },
           {
              "title": 'ఓం అనఘాస్త్రాయ నమః',
           },
           {
              "title": 'ఓం నఖాస్త్రాయ నమః',
           },
           {
              "title": 'ఓం సూర్య జ్యోతిషే నమః',
           },
           {
              "title": 'ఓం సురేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం సహస్రబాహవే నమః',
           },
           {
              "title": 'ఓం సర్వజ్ఞాయ నమః',
           },
           {
              "title": 'ఓం సర్వసిద్ధిప్రదాయకాయ నమః ||70||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం వజ్ర దంష్ట్రాయ నమః',
           },
           {
              "title": 'ఓం వజ్రనఖాయ నమః',
           },
           {
              "title": 'ఓం మహానందాయ నమః',
           },
           {
              "title": 'ఓం పరంతపాయ నమః',
           },
           {
              "title": 'ఓం సర్వమంత్రైకరూపాయ నమః',
           },
           {
              "title": 'ఓం సర్వమంత్ర విదారణాయ నమః',
           },
           {
              "title": 'ఓం సర్వతంత్రాత్మకాయ నమః',
           },
           {
              "title": 'ఓం అవ్యక్తాయ నమః',
           },
           {
              "title": 'ఓం సువ్యక్తాయ నమః',
           },
           {
              "title": 'ఓం భక్తవత్సలాయ నమః ||80||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం వైశాఖ శుక్ల భూతోత్ధాయ నమః',
           },
           {
              "title": 'ఓం శరణాగతవత్సలాయ నమః',
           },
           {
              "title": 'ఓం ఉదారకీర్తయే నమః',
           },
           {
              "title": 'ఓం పుణ్యాత్మనే నమః',
           },
           {
              "title": 'ఓం మహాత్మనే నమః',
           },
           {
              "title": 'ఓం చండ విక్రమాయ నమః',
           },
           {
              "title": 'ఓం వేదత్రయ ప్రపూజ్యాయ నమః',
           },
           {
              "title": 'ఓం భగవతే నమః',
           },
           {
              "title": 'ఓం పరమేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం శ్రీవత్సాంకాయ నమః ||90||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శ్రీనివాసాయ నమః',
           },
           {
              "title": 'ఓం జగద్వ్యాపినే నమః',
           },
           {
              "title": 'ఓం జగన్మయాయ నమః',
           },
           {
              "title": 'ఓం జగత్పాలాయ నమః',
           },
           {
              "title": 'ఓం జగన్నాథాయ నమః',
           },
           {
              "title": 'ఓం మహాకాయాయ నమః',
           },
           {
              "title": 'ఓం ద్విరూపభృతే నమః',
           },
           {
              "title": 'ఓం పరమాత్మనే నమః',
           },
           {
              "title": 'ఓం పరంజ్యోతిషే నమః',
           },
           {
              "title": 'ఓం నిర్గుణాయ నమః ||100||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం నృకేసరిణే నమః',
           },
           {
              "title": 'ఓం పరతత్త్వాయ నమః',
           },
           {
              "title": 'ఓం పరంధామాయ నమః',
           },
           {
              "title": 'ఓం సచ్చిదానందవిగ్రహాయ నమః',
           },
           {
              "title": 'ఓం లక్ష్మీనృశింహాయ నమః',
           },
           {
              "title": 'ఓం సర్వాత్మనే నమః',
           },
           {
              "title": 'ఓం ధీరాయ నమః',
           },
           {
              "title": 'ఓం ప్రహ్లాద పాలకాయ నమః ||108||',
           },
           {
              "title": '',
           },
           {
              "title": '|| ఇతి శ్రీ లక్ష్మీ నరసింహ అష్టోత్తర శతనామావళి సంపూర్ణం ||',
           }
         ],
          "title": 'శ్రీ లక్ష్మీనరసింహ అష్టోత్తర శతనామావళి',
         // "img": require('../imgs/Astotaram/Lakshminarasimha.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం మహత్యై నమః',
           },
           {
              "title": 'ఓం చేతనాయై నమః',
           },
           {
              "title": 'ఓం మాయాయై నమః',
           },
           {
              "title": 'ఓం మహాగౌర్యై నమః',
           },
           {
              "title": 'ఓం మహేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం మహోదరాయై నమః',
           },
           {
              "title": 'ఓం మహాబుద్ధ్యై నమః',
           },
           {
              "title": 'ఓం మహాకాళ్యై నమః',
           },
           {
              "title": 'ఓం మహా బలాయై నమః',
           },
           {
              "title": 'ఓం మహా సుధాయై నమః ||10||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మహా నిద్రాయై నమః',
           },
           {
              "title": 'ఓం మహా ముద్రాయై నమః',
           },
           {
              "title": 'ఓం మహా దయాయై నమః',
           },
           {
              "title": 'ఓం మహా భోగాయై నమః',
           },
           {
              "title": 'ఓం మహా మోహాయై నమః',
           },
           {
              "title": 'ఓం మహా జయాయై నమః',
           },
           {
              "title": 'ఓం మహాతుష్ట్యై నమః',
           },
           {
              "title": 'ఓం మహా లజ్జాయై నమః',
           },
           {
              "title": 'ఓం మహాధృత్యై నమః',
           },
           {
              "title": 'ఓం మహా ఘోరాయై నమః ||20||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మహా దృష్ట్రాయై నమః',
           },
           {
              "title": 'ఓం మహాకాంత్యై నమః',
           },
           {
              "title": 'ఓం మహాకృత్యై నమః',
           },
           {
              "title": 'ఓం మహా పద్మాయై నమః',
           },
           {
              "title": 'ఓం మహా మేధాయై నమః',
           },
           {
              "title": 'ఓం మహా బోధాయై నమః',
           },
           {
              "title": 'ఓం మహా తపసే నమః',
           },
           {
              "title": 'ఓం మహా స్థానాయై నమః',
           },
           {
              "title": 'ఓం మహా రవాయై నమః',
           },
           {
              "title": 'ఓం మహా రోషాయై నమః ||30||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మహాయుధాయై నమః',
           },
           {
              "title": 'ఓం మహా బంధన సంహర్యై నమః',
           },
           {
              "title": 'ఓం మహాభయ వినాశిన్యై నమః',
           },
           {
              "title": 'ఓం మహా నేత్రాయై నమః',
           },
           {
              "title": 'ఓం మహా వక్త్రాయై నమః',
           },
           {
              "title": 'ఓం మహా వక్షసే నమః',
           },
           {
              "title": 'ఓం మహా భుజాయై నమః',
           },
           {
              "title": 'ఓం మహా మహీరుహాయై నమః',
           },
           {
              "title": 'ఓం పూర్ణాయై నమః',
           },
           {
              "title": 'ఓం మహా ఛాయాయై నమః ||40||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మహానఘాయై నమః',
           },
           {
              "title": 'ఓం మహాశాంత్యై నమః',
           },
           {
              "title": 'ఓం మహాశ్వాసాయై నమః',
           },
           {
              "title": 'ఓం మహా పర్వత నందిన్యై నమః',
           },
           {
              "title": 'ఓం మహా బ్రహ్మమయ్యై నమః',
           },
           {
              "title": 'ఓం మాత్రే నమః',
           },
           {
              "title": 'ఓం మహా సారాయై నమః',
           },
           {
              "title": 'ఓం మహా సురఘ్న్యై నమః',
           },
           {
              "title": 'ఓం మహత్యై నమః',
           },
           {
              "title": 'ఓం పార్వత్యై నమః ||50||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం చర్చితాయై నమః',
           },
           {
              "title": 'ఓం శివాయై నమః',
           },
           {
              "title": 'ఓం మహా క్షాంత్యై నమః',
           },
           {
              "title": 'ఓం మహా భ్రాంత్యై నమః',
           },
           {
              "title": 'ఓం మహా మంత్రాయై నమః',
           },
           {
              "title": 'ఓం మహా తంత్రాయై నమః',
           },
           {
              "title": 'ఓం మహామయ్యై నమః',
           },
           {
              "title": 'ఓం మహా కులాయై నమః',
           },
           {
              "title": 'ఓం మహా లోలాయై నమః',
           },
           {
              "title": 'ఓం మహా మాయాయై నమః ||60||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మహా ఫలాయై నమః',
           },
           {
              "title": 'ఓం మహా నిలాయై నమః',
           },
           {
              "title": 'ఓం మహా శీలాయై నమః',
           },
           {
              "title": 'ఓం మహా బాలాయై నమః',
           },
           {
              "title": 'ఓం మహా నిలయాయై నమః',
           },
           {
              "title": 'ఓం మహా కలాయై నమః',
           },
           {
              "title": 'ఓం మహా చిత్రాయై నమః',
           },
           {
              "title": 'ఓం మహా సేతవే నమః',
           },
           {
              "title": 'ఓం మహా హేతవే నమః',
           },
           {
              "title": 'ఓం యశస్విన్యై నమః ||70||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మహా విద్యాయై నమః',
           },
           {
              "title": 'ఓం మహా సాధ్యాయై నమః',
           },
           {
              "title": 'ఓం మహా సత్యాయై నమః',
           },
           {
              "title": 'ఓం మహా గత్యై నమః',
           },
           {
              "title": 'ఓం మహా సుఖిన్యై నమః',
           },
           {
              "title": 'ఓం మహా దుఃస్వప్న నాసిన్యై నమః',
           },
           {
              "title": 'ఓం మహా మోక్షప్రదాయై నమః',
           },
           {
              "title": 'ఓం మహా పక్షాయై నమః',
           },
           {
              "title": 'ఓం మహా యశస్విన్యై నమః',
           },
           {
              "title": 'ఓం మహా భద్రాయై నమః ||80||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మహా వాణ్యై నమః',
           },
           {
              "title": 'ఓం మహా రోగ వినాసిన్యై నమః',
           },
           {
              "title": 'ఓం మహా ధారాయై నమః',
           },
           {
              "title": 'ఓం మహా కారాయై నమః',
           },
           {
              "title": 'ఓం మహా మార్యై నమః',
           },
           {
              "title": 'ఓం ఖేచర్యై నమః',
           },
           {
              "title": 'ఓం మోహిణ్యై నమః',
           },
           {
              "title": 'ఓం మహా క్షేమంకర్యై నమః',
           },
           {
              "title": 'ఓం మహా క్షమాయై నమః',
           },
           {
              "title": 'ఓం మహైశ్వర్య ప్రదాయిన్యై నమః ||90||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మహా విషఘ్న్యై నమః',
           },
           {
              "title": 'ఓం విషదాయై నమః',
           },
           {
              "title": 'ఓం మహా దుఖః వినాసిన్యై నమః',
           },
           {
              "title": 'ఓం మహా వర్షాయై నమః',
           },
           {
              "title": 'ఓం మహా తత్త్వాయై నమః',
           },
           {
              "title": 'ఓం మహా కైలాస వాసిన్యై నమః',
           },
           {
              "title": 'ఓం మహా సుభద్రాయై నమః',
           },
           {
              "title": 'ఓం సుభగాయై నమః',
           },
           {
              "title": 'ఓం మహా విద్యాయై నమః',
           },
           {
              "title": 'ఓం మహా సత్యై నమః ||100||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మహా ప్రత్యంగిరాయై నమః',
           },
           {
              "title": 'ఓం మహా నిత్యాయై నమః',
           },
           {
              "title": 'ఓం మహా ప్రళయ కారిణ్యై నమః',
           },
           {
              "title": 'ఓం మహాశక్త్యై నమః',
           },
           {
              "title": 'ఓం మహామత్యై నమః',
           },
           {
              "title": 'ఓం మహా మంగల కారిణ్యై నమః',
           },
           {
              "title": 'ఓం మహాదేవ్యై నమః',
           },
           {
              "title": 'ఓం మహాసుర విమర్దిన్యై నమః ||108||',
           },
           {
              "title": '',
           },
           {
              "title": '|| ఇతి శ్రీ మహిషాసుర మర్దిని దేవి అష్టోత్తర శతనామావళి సమాప్తం ||',
           }
         ],
          "title": 'శ్రీ మహిషాసురమర్దిని దేవి అష్టోత్తర శతనామావళి',
         // "img": require('../imgs/Astotaram/Mahishasuramardini.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం అనంతాయ నమః',
           },
           {
              "title": 'ఓం పద్మనాభాయ నమః',
           },
           {
              "title": 'ఓం శేషాయ నమః',
           },
           {
              "title": 'ఓం సప్తఫణాన్వితాయ నమః',
           },
           {
              "title": 'ఓం తల్పాత్మకాయ నమః',
           },
           {
              "title": 'ఓం పద్మకరాయ నమః',
           },
           {
              "title": 'ఓం పింగప్రసన్నలోచనాయ నమః',
           },
           {
              "title": 'ఓం గదాధరాయ నమః',
           },
           {
              "title": 'ఓం చతుర్బాహవే నమః',
           },
           {
              "title": 'ఓం శంఖచక్రధరాయ నమః || 10 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అవ్యయాయ నమః',
           },
           {
              "title": 'ఓం నవామ్రపల్లవాభాసాయ నమః',
           },
           {
              "title": 'ఓం బ్రహ్మసూత్రవిరాజితాయ నమః',
           },
           {
              "title": 'ఓం శిలాసుపూజితాయ నమః',
           },
           {
              "title": 'ఓం దేవాయ నమః',
           },
           {
              "title": 'ఓం కౌండిన్యవ్రతతోషితాయ నమః',
           },
           {
              "title": 'ఓం నభస్యశుక్లస్తచతుర్దశీపూజ్యాయ నమః',
           },
           {
              "title": 'ఓం ఫణేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం సంకర్షణాయ నమః',
           },
           {
              "title": 'ఓం చిత్స్వరూపాయ నమః  || 20 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సూత్రగ్రంధిసుసంస్థితాయ నమః',
           },
           {
              "title": 'ఓం కౌండిన్యవరదాయ నమః',
           },
           {
              "title": 'ఓం పృథ్వీధారిణే నమః',
           },
           {
              "title": 'ఓం పాతాళనాయకాయ నమః',
           },
           {
              "title": 'ఓం సహస్రాక్షాయ నమః',
           },
           {
              "title": 'ఓం అఖిలాధారాయ నమః',
           },
           {
              "title": 'ఓం సర్వయోగికృపాకరాయ నమః',
           },
           {
              "title": 'ఓం సహస్రపద్మసంపూజ్యాయ నమః',
           },
           {
              "title": 'ఓం కేతకీకుసుమప్రియాయ నమః',
           },
           {
              "title": 'ఓం సహస్రబాహవే నమః || 30 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సహస్రశిరసే నమః',
           },
           {
              "title": 'ఓం శ్రితజనప్రియాయ నమః',
           },
           {
              "title": 'ఓం భక్తదుఃఖహరాయ నమః',
           },
           {
              "title": 'ఓం శ్రీమతే నమః',
           },
           {
              "title": 'ఓం భవసాగరతారకాయ నమః',
           },
           {
              "title": 'ఓం యమునాతీరసదృష్టాయ నమః',
           },
           {
              "title": 'ఓం సర్వనాగేంద్రవందితాయ నమః',
           },
           {
              "title": 'ఓం యమునారాధ్యపాదాబ్జాయ నమః',
           },
           {
              "title": 'ఓం యుధిష్ఠిరసుపూజితాయ నమః',
           },
           {
              "title": 'ఓం ధ్యేయాయ నమః || 40 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం విష్ణుపర్యంకాయ నమః',
           },
           {
              "title": 'ఓం చక్షుశ్రవణవల్లభాయ నమః',
           },
           {
              "title": 'ఓం సర్వకామప్రదాయ నమః',
           },
           {
              "title": 'ఓం సేవ్యాయ నమః',
           },
           {
              "title": 'ఓం భీమసేనామృతప్రదాయ నమః',
           },
           {
              "title": 'ఓం సురాసురేంద్రసంపూజ్యాయ నమః',
           },
           {
              "title": 'ఓం ఫణామణివిభూషితాయ నమః',
           },
           {
              "title": 'ఓం సత్యమూర్తయే నమః',
           },
           {
              "title": 'ఓం శుక్లతనవే నమః',
           },
           {
              "title": 'ఓం నీలవాససే నమః || 50 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం జగద్గురవే నమః',
           },
           {
              "title": 'ఓం అవ్యక్తపాదాయ నమః',
           },
           {
              "title": 'ఓం బ్రహ్మణ్యాయ నమః',
           },
           {
              "title": 'ఓం సుబ్రహ్మణ్యనివాసభువే నమః',
           },
           {
              "title": 'ఓం అనంతభోగశయనాయ నమః',
           },
           {
              "title": 'ఓం దివాకరమునీడితాయ నమః',
           },
           {
              "title": 'ఓం మధుకవృక్షసంస్థానాయ నమః',
           },
           {
              "title": 'ఓం దివాకరవరప్రదాయ నమః',
           },
           {
              "title": 'ఓం దక్షహస్తసదాపూజ్యాయ నమః',
           },
           {
              "title": 'ఓం శివలింగనివష్టధియే నమః || 60 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం త్రిప్రతీహారసందృశ్యాయ నమః',
           },
           {
              "title": 'ఓం ముఖదాపిపదాంబుజాయ నమః',
           },
           {
              "title": 'ఓం నృసింహక్షేత్రనిలయాయ నమః',
           },
           {
              "title": 'ఓం దుర్గాసమన్వితాయ నమః',
           },
           {
              "title": 'ఓం మత్స్యతీర్థవిహారిణే నమః',
           },
           {
              "title": 'ఓం ధర్మాధర్మాదిరూపవతే నమః',
           },
           {
              "title": 'ఓం మహారోగాయుధాయ నమః',
           },
           {
              "title": 'ఓం వార్థితీరస్థాయ నమః',
           },
           {
              "title": 'ఓం కరుణానిధయే నమః',
           },
           {
              "title": 'ఓం తామ్రపర్ణీపార్శ్వవర్తినే నమః || 70 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం ధర్మపరాయణాయ నమః',
           },
           {
              "title": 'ఓం మహాకావ్యప్రణేత్రే నమః',
           },
           {
              "title": 'ఓం నాగలోకేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం స్వభువే నమః',
           },
           {
              "title": 'ఓం రత్నసింహాసనాసీనాయ నమః',
           },
           {
              "title": 'ఓం స్ఫురన్మకరకుండలాయ నమః',
           },
           {
              "title": 'ఓం సహస్రాదిత్యసంకాశాయ నమః',
           },
           {
              "title": 'ఓం పురాణపురుషాయ నమః',
           },
           {
              "title": 'ఓం జ్వలత్రత్నకిరీటాఢ్యాయ నమః',
           },
           {
              "title": 'ఓం సర్వాభరణభూషితాయ నమః || 80 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం నాగకన్యాష్టతప్రాంతాయ నమః',
           },
           {
              "title": 'ఓం దిక్పాలకపరిపూజితాయ నమః',
           },
           {
              "title": 'ఓం గంధర్వగానసంతుష్టాయ నమః',
           },
           {
              "title": 'ఓం యోగశాస్త్రప్రవర్తకాయ నమః',
           },
           {
              "title": 'ఓం దేవవైణికసంపూజ్యాయ నమః',
           },
           {
              "title": 'ఓం వైకుంఠాయ నమః',
           },
           {
              "title": 'ఓం సర్వతోముఖాయ నమః',
           },
           {
              "title": 'ఓం రత్నాంగదలసద్బాహవే నమః',
           },
           {
              "title": 'ఓం బలభద్రాయ నమః',
           },
           {
              "title": 'ఓం ప్రలంబఘ్నే నమః || 90 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం కాంతీకర్షణాయ నమః',
           },
           {
              "title": 'ఓం భక్తవత్సలాయ నమః',
           },
           {
              "title": 'ఓం రేవతీప్రియాయ నమః',
           },
           {
              "title": 'ఓం నిరాధారాయ నమః',
           },
           {
              "title": 'ఓం కపిలాయ నమః',
           },
           {
              "title": 'ఓం కామపాలాయ నమః',
           },
           {
              "title": 'ఓం అచ్యుతాగ్రజాయ నమః',
           },
           {
              "title": 'ఓం అవ్యగ్రాయ నమః',
           },
           {
              "title": 'ఓం బలదేవాయ నమః',
           },
           {
              "title": 'ఓం మహాబలాయ నమః || 100 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అజాయ నమః',
           },
           {
              "title": 'ఓం వాతాశనాధీశాయ నమః',
           },
           {
              "title": 'ఓం మహాతేజసే నమః',
           },
           {
              "title": 'ఓం నిరంజనాయ నమః',
           },
           {
              "title": 'ఓం సర్వలోకప్రతాపనాయ నమః',
           },
           {
              "title": 'ఓం సజ్వాలప్రళయాగ్నిముఖే నమః',
           },
           {
              "title": 'ఓం సర్వలోకైకసంహర్త్రే నమః',
           },
           {
              "title": 'ఓం సర్వేష్టార్థప్రదాయకాయ నమః || 108 ||',
           },
           {
              "title": '',
           },
           {
              "title": '|| శ్రీ అనంత పద్మనాభ అష్టోత్తరశతనామావళి సమాప్త: ||',
           },
           {
              "title": '',
           },
         ],
          "title": 'శ్రీ అనంత పద్మనాభ అష్టోత్తర శత నామావళి',
         // "img": require('../imgs/Astotaram/ananthapadmanabha.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం కృష్ణాయ నమః',
           },
           {
              "title": 'ఓం కమలానాథాయ నమః',
           },
           {
              "title": 'ఓం వాసుదేవాయ నమః',
           },
           {
              "title": 'ఓం సనాతనాయ నమః',
           },
           {
              "title": 'ఓం వసుదేవాత్మజాయ నమః',
           },
           {
              "title": 'ఓం పుణ్యాయ నమః',
           },
           {
              "title": 'ఓం లీలామానుష విగ్రహాయ నమః',
           },
           {
              "title": 'ఓం శ్రీవత్స కౌస్తుభధరాయ నమః',
           },
           {
              "title": 'ఓం యశోదావత్సలాయ నమః',
           },
           {
              "title": 'ఓం హరయే నమః ॥ 10 ॥',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం చతుర్భుజాత్త చక్రాసిగదా శంఖాంద్యుదాయుధాయ నమః',
           },
           {
              "title": 'ఓం దేవకీనందనాయ నమః',
           },
           {
              "title": 'ఓం శ్రీశాయ నమః',
           },
           {
              "title": 'ఓం నందగోప ప్రియాత్మజాయ నమః',
           },
           {
              "title": 'ఓం యమునా వేగసంహారిణే నమః',
           },
           {
              "title": 'ఓం బలభద్ర ప్రియానుజాయ నమః',
           },
           {
              "title": 'ఓం పూతనా జీవితహరాయ నమః',
           },
           {
              "title": 'ఓం శకటాసుర భంజనాయ నమః',
           },
           {
              "title": 'ఓం నందవ్రజ జనానందినే నమః',
           },
           {
              "title": 'ఓం సచ్చిదానంద విగ్రహాయ నమః ॥ 20 ॥',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం నవనీత విలిప్తాంగాయ నమః',
           },
           {
              "title": 'ఓం నవనీత నటాయ నమః',
           },
           {
              "title": 'ఓం అనఘాయ నమః',
           },
           {
              "title": 'ఓం నవనీత నవాహారాయ నమః',
           },
           {
              "title": 'ఓం ముచుకుంద ప్రసాదకాయ నమః',
           },
           {
              "title": 'ఓం షోడశస్త్రీ సహస్రేశాయ నమః',
           },
           {
              "title": 'ఓం త్రిభంగి మధురాకృతయే నమః',
           },
           {
              "title": 'ఓం శుకవాగ మృతాబ్ధీందవే నమః',
           },
           {
              "title": 'ఓం గోవిందాయ నమః',
           },
           {
              "title": 'ఓం యోగినాం పతయే నమః ॥ 30 ॥',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం వత్సవాటచరాయ నమః',
           },
           {
              "title": 'ఓం అనంతాయ నమః',
           },
           {
              "title": 'ఓం దేనుకాసుర భంజనాయ నమః',
           },
           {
              "title": 'ఓం తృణీకృత తృణావర్తాయ నమః',
           },
           {
              "title": 'ఓం యమళార్జున భంజనాయ నమః',
           },
           {
              "title": 'ఓం ఉత్తాలతాలభేత్రే నమః',
           },
           {
              "title": 'ఓం తమాల శ్యామలాకృతయే నమః',
           },
           {
              "title": 'ఓం గోపగోపీశ్వరాయ నమః',
           },
           {
              "title": 'ఓం యోగినే నమః',
           },
           {
              "title": 'ఓం కోటిసూర్య సమప్రభాయ నమః ॥ 40 ॥',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం ఇలాపతయే నమః',
           },
           {
              "title": 'ఓం పరస్మై జ్యోతిషే నమః',
           },
           {
              "title": 'ఓం యాదవేంద్రాయ నమః',
           },
           {
              "title": 'ఓం యదూద్వహాయ నమః',
           },
           {
              "title": 'ఓం వనమాలినే నమః',
           },
           {
              "title": 'ఓం పీతవాససే నమః',
           },
           {
              "title": 'ఓం పారిజాతాపహారకాయ నమః',
           },
           {
              "title": 'ఓం గోవర్ధనాచలోద్ధర్త్రే నమః',
           },
           {
              "title": 'ఓం గోపాలాయ నమః',
           },
           {
              "title": 'ఓం సర్వపాలకాయ నమః ॥ 50 ॥',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అజాయ నమః',
           },
           {
              "title": 'ఓం నిరంజనాయ నమః',
           },
           {
              "title": 'ఓం కామజనకాయ నమః',
           },
           {
              "title": 'ఓం కంజలోచనాయ నమః',
           },
           {
              "title": 'ఓం మధుఘ్నే నమః',
           },
           {
              "title": 'ఓం మధురానాథాయ నమః',
           },
           {
              "title": 'ఓం ద్వారకానాయకాయ నమః',
           },
           {
              "title": 'ఓం బలినే నమః',
           },
           {
              "title": 'ఓం వృందావనాంత సంచారిణే నమః',
           },
           {
              "title": 'ఓం తులసీదామ భూషణాయ నమః ॥ 60 ॥',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శ్యమంతక మణేర్హర్త్రే నమః',
           },
           {
              "title": 'ఓం నరనారాయణాత్మకాయ నమః',
           },
           {
              "title": 'ఓం కుబ్జాకృష్ణాంబరధరాయ నమః',
           },
           {
              "title": 'ఓం మాయినే నమః',
           },
           {
              "title": 'ఓం పరమపూరుషాయ నమః',
           },
           {
              "title": 'ఓం ముష్టికాసుర చాణూర మల్లయుద్ధ విశారదాయ నమః',
           },
           {
              "title": 'ఓం సంసారవైరిణే నమః',
           },
           {
              "title": 'ఓం కంసారయే నమః',
           },
           {
              "title": 'ఓం మురారయే నమః',
           },
           {
              "title": 'ఓం నరకాంతకాయ నమః ॥ 70 ॥',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అనాది బ్రహ్మచారిణే నమః',
           },
           {
              "title": 'ఓం కృష్ణావ్యసన కర్శకాయ నమః',
           },
           {
              "title": 'ఓం శిశుపాల శిరశ్ఛేత్రే నమః',
           },
           {
              "title": 'ఓం దుర్యోధన కులాంతకాయ నమః',
           },
           {
              "title": 'ఓం విదురాక్రూర వరదాయ నమః',
           },
           {
              "title": 'ఓం విశ్వరూప ప్రదర్శకాయ నమః',
           },
           {
              "title": 'ఓం సత్యవాచే నమః',
           },
           {
              "title": 'ఓం సత్య సంకల్పాయ నమః',
           },
           {
              "title": 'ఓం సత్యభామారతాయ నమః',
           },
           {
              "title": 'ఓం జయినే నమః ॥ 80 ॥',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సుభద్రా పూర్వజాయ నమః',
           },
           {
              "title": 'ఓం జిష్ణవే నమః',
           },
           {
              "title": 'ఓం భీష్మముక్తి ప్రదాయకాయ నమః',
           },
           {
              "title": 'ఓం జగద్గురవే నమః',
           },
           {
              "title": 'ఓం జగన్నాథాయ నమః',
           },
           {
              "title": 'ఓం వేణునాద విశారదాయ నమః',
           },
           {
              "title": 'ఓం వృషభాసుర విధ్వంసినే నమః',
           },
           {
              "title": 'ఓం బాణాసుర కరాంతకాయ నమః',
           },
           {
              "title": 'ఓం యుధిష్ఠిర ప్రతిష్ఠాత్రే నమః',
           },
           {
              "title": 'ఓం బర్హిబర్హావతంసకాయ నమః ॥ 90 ॥',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం పార్థసారథయే నమః',
           },
           {
              "title": 'ఓం అవ్యక్తాయ నమః',
           },
           {
              "title": 'ఓం గీతామృత మహోదధయే నమః',
           },
           {
              "title": 'ఓం కాళీయ ఫణిమాణిక్య రంజిత శ్రీపదాంబుజాయ నమః',
           },
           {
              "title": 'ఓం దామోదరాయ నమః',
           },
           {
              "title": 'ఓం యజ్ఞ్నభోక్ర్తే నమః',
           },
           {
              "title": 'ఓం దానవేంద్ర వినాశకాయ నమః',
           },
           {
              "title": 'ఓం నారాయణాయ నమః',
           },
           {
              "title": 'ఓం పరస్మై బ్రహ్మణే నమః',
           },
           {
              "title": 'ఓం పన్నగాశన వాహనాయ నమః ॥ 100 ॥',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం జలక్రీడాసమాసక్త గోపీవస్త్రాపహారకాయ నమః',
           },
           {
              "title": 'ఓం పుణ్యశ్లోకాయ నమః',
           },
           {
              "title": 'ఓం తీర్థపాదాయ నమః',
           },
           {
              "title": 'ఓం వేదవేద్యాయ నమః',
           },
           {
              "title": 'ఓం దయానిధయే నమః',
           },
           {
              "title": 'ఓం సర్వతీర్థాత్మకాయ నమః',
           },
           {
              "title": 'ఓం సర్వగ్రహరూపిణే నమః',
           },
           {
              "title": 'ఓం పరాత్పరాయ నమః ॥ 108 ॥',
           },
           {
              "title": '',
           },
           {
              "title": '|| ఇతి శ్రీ కృష్ణాష్టోత్తర శతనామావళీస్సమాప్తా: ||',
           },
           {
              "title": '',
           },
         ],
          "title": 'శ్రీ కృష్ణాష్టోత్తర శత నామావళి',
         // "img": require('../imgs/Astotaram/srikrishna.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం విద్యారూపిణే నమః',
           },
           {
              "title": 'ఓం మహాయోగినే నమః',
           },
           {
              "title": 'ఓం శుద్ధజ్ఞానినే నమః',
           },
           {
              "title": 'ఓం పినాకధృతే నమః',
           },
           {
              "title": 'ఓం రత్నాలంకృతసర్వాంగినే నమః',
           },
           {
              "title": 'ఓం రత్నమౌళయే నమః',
           },
           {
              "title": 'ఓం జటాధరాయ నమః',
           },
           {
              "title": 'ఓం గంగాధరాయ నమః',
           },
           {
              "title": 'ఓం అచలవాసినే నమః',
           },
           {
              "title": 'ఓం మహాజ్ఞానినే నమః || 10 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సమాధికృతే నమః',
           },
           {
              "title": 'ఓం అప్రమేయాయ నమః',
           },
           {
              "title": 'ఓం యోగనిధయే నమః',
           },
           {
              "title": 'ఓం తారకాయ నమః',
           },
           {
              "title": 'ఓం భక్తవత్సలాయ నమః',
           },
           {
              "title": 'ఓం బ్రహ్మరూపిణే నమః',
           },
           {
              "title": 'ఓం జగద్వ్యాపినే నమః',
           },
           {
              "title": 'ఓం విష్ణుమూర్తయే నమః',
           },
           {
              "title": 'ఓం పురాతనాయ నమః',
           },
           {
              "title": 'ఓం ఉక్షవాహాయ నమః || 20 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం చర్మవాససే నమః',
           },
           {
              "title": 'ఓం పీతాంబర విభూషణాయ నమః',
           },
           {
              "title": 'ఓం మోక్షదాయినే నమః',
           },
           {
              "title": 'ఓం మోక్ష నిధయే నమః',
           },
           {
              "title": 'ఓం అంధకారయే నమః',
           },
           {
              "title": 'ఓం జగత్పతయే నమః',
           },
           {
              "title": 'ఓం విద్యాధారిణే నమః',
           },
           {
              "title": 'ఓం శుక్లతనవే నమః',
           },
           {
              "title": 'ఓం విద్యాదాయినే నమః',
           },
           {
              "title": 'ఓం గణాధిపాయ నమః || 30 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం ప్రౌఢాపస్మృతి సంహర్త్రే నమః',
           },
           {
              "title": 'ఓం శశిమౌళయే నమః',
           },
           {
              "title": 'ఓం మహాస్వనాయ నమః',
           },
           {
              "title": 'ఓం సామప్రియాయ నమః',
           },
           {
              "title": 'ఓం అవ్యయాయ నమః',
           },
           {
              "title": 'ఓం సాధవే నమః',
           },
           {
              "title": 'ఓం సర్వవేదైరలంకృతాయ నమః',
           },
           {
              "title": 'ఓం హస్తే వహ్ని ధరాయ నమః',
           },
           {
              "title": 'ఓం శ్రీమతే మృగధారిణే నమః',
           },
           {
              "title": 'ఓం వశంకరాయ నమః || 40 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం యజ్ఞనాథాయ నమః',
           },
           {
              "title": 'ఓం క్రతుధ్వంసినే నమః',
           },
           {
              "title": 'ఓం యజ్ఞభోక్త్రే నమః',
           },
           {
              "title": 'ఓం యమాంతకాయ నమః',
           },
           {
              "title": 'ఓం భక్తానుగ్రహమూర్తయే నమః',
           },
           {
              "title": 'ఓం భక్తసేవ్యాయ నమః',
           },
           {
              "title": 'ఓం వృషధ్వజాయ నమః',
           },
           {
              "title": 'ఓం భస్మోద్ధూళితసర్వాంగాయ నమః',
           },
           {
              "title": 'ఓం అక్షమాలాధరాయ నమః',
           },
           {
              "title": 'ఓం మహతే నమః || 50 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం త్రయీమూర్తయే నమః',
           },
           {
              "title": 'ఓం పరబ్రహ్మణే నమః',
           },
           {
              "title": 'ఓం నాగరాజైరలంకృతాయ నమః',
           },
           {
              "title": 'ఓం శాంతరూపాయమహాజ్ఞానినే నమః',
           },
           {
              "title": 'ఓం సర్వలోకవిభూషణాయ నమః',
           },
           {
              "title": 'ఓం అర్ధనారీశ్వరాయ నమః',
           },
           {
              "title": 'ఓం దేవాయ నమః',
           },
           {
              "title": 'ఓం మునిసేవ్యాయ నమః',
           },
           {
              "title": 'ఓం సురోత్తమాయ నమః',
           },
           {
              "title": 'ఓం వ్యాఖ్యానదేవాయ నమః || 60 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం భగవతే నమః',
           },
           {
              "title": 'ఓం రవిచంద్రాగ్నిలోచనాయ నమః',
           },
           {
              "title": 'ఓం జగద్గురవే నమః',
           },
           {
              "title": 'ఓం మహాదేవాయ నమః',
           },
           {
              "title": 'ఓం మహానంద పరాయణాయ నమః',
           },
           {
              "title": 'ఓం జటాధారిణే నమః',
           },
           {
              "title": 'ఓం మహాయోగినే నమః',
           },
           {
              "title": 'ఓం జ్ఞానమాలైరలంకృతాయ నమః',
           },
           {
              "title": 'ఓం వ్యోమగంగాజలస్థానాయ నమః',
           },
           {
              "title": 'ఓం విశుద్ధాయ నమః || 70 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం యతయే నమః',
           },
           {
              "title": 'ఓం ఊర్జితాయ నమః',
           },
           {
              "title": 'ఓం తత్త్వమూర్తయే నమః',
           },
           {
              "title": 'ఓం మహాయోగినే నమః',
           },
           {
              "title": 'ఓం మహాసారస్వతప్రదాయ నమః',
           },
           {
              "title": 'ఓం వ్యోమమూర్తయే నమః',
           },
           {
              "title": 'ఓం భక్తానామిష్టాయ నమః',
           },
           {
              "title": 'ఓం కామఫలప్రదాయ నమః',
           },
           {
              "title": 'ఓం పరమూర్తయే నమః',
           },
           {
              "title": 'ఓం చిత్స్వరూపిణే నమః || 80 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం తేజోమూర్తయే నమః',
           },
           {
              "title": 'ఓం అనామయాయ నమః',
           },
           {
              "title": 'ఓం వేదవేదాంగ తత్త్వజ్ఞాయ నమః',
           },
           {
              "title": 'ఓం చతుఃషష్టికళానిధయే నమః',
           },
           {
              "title": 'ఓం భవరోగభయధ్వంసినే నమః',
           },
           {
              "title": 'ఓం భక్తానామభయప్రదాయ నమః',
           },
           {
              "title": 'ఓం నీలగ్రీవాయ నమః',
           },
           {
              "title": 'ఓం లలాటాక్షాయ నమః',
           },
           {
              "title": 'ఓం గజచర్మణే నమః',
           },
           {
              "title": 'ఓం గతిప్రదాయ నమః || 90 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అరాగిణే నమః',
           },
           {
              "title": 'ఓం కామదాయ నమః',
           },
           {
              "title": 'ఓం తపస్వినే నమః',
           },
           {
              "title": 'ఓం విష్ణువల్లభాయ నమః',
           },
           {
              "title": 'ఓం బ్రహ్మచారిణే నమః',
           },
           {
              "title": 'ఓం సన్యాసినే నమః',
           },
           {
              "title": 'ఓం గృహస్థాశ్రమకారణాయ నమః',
           },
           {
              "title": 'ఓం దాంతాయ నమః',
           },
           {
              "title": 'ఓం శమవతాం శ్రేష్ఠాయ నమః',
           },
           {
              "title": 'ఓం సత్యరూపాయ నమః || 100 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం దయాపరాయ నమః',
           },
           {
              "title": 'ఓం యోగపట్టాభిరామాయ నమః',
           },
           {
              "title": 'ఓం వీణాధారిణే నమః',
           },
           {
              "title": 'ఓం విచేతనాయ నమః',
           },
           {
              "title": 'ఓం మతి ప్రజ్ఞాసుధాధారిణే నమః',
           },
           {
              "title": 'ఓం ముద్రాపుస్తకధారణాయ నమః',
           },
           {
              "title": 'ఓం రోగాణాం వినిహంత్రే నమః',
           },
           {
              "title": 'ఓం సురేశ్వరాయ నమః || 108 ||',
           },
           {
              "title": '',
           },
           {
              "title": '|| ఇతి శ్రీ దక్షిణామూర్తి అష్టోత్తరశతనామావళీ సంపూర్ణం ||',
           },
           {
              "title": '',
           },
         ],
          "title": 'శ్రీ దక్షిణామూర్తి అష్టోత్తరము',
         // "img": require('../imgs/Astotaram/sridakshinamurthy.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం భైరవాయ నమః',
           },
           {
              "title": 'ఓం భూతనాథాయ నమః',
           },
           {
              "title": 'ఓం భూతాత్మనే నమః',
           },
           {
              "title": 'ఓం భూతభావనాయ నమః',
           },
           {
              "title": 'ఓం క్షేత్రదాయ నమః',
           },
           {
              "title": 'ఓం క్షేత్రపాలాయ నమః',
           },
           {
              "title": 'ఓం క్షేత్రజ్ఞాయ నమః',
           },
           {
              "title": 'ఓం క్షత్రియాయ నమః',
           },
           {
              "title": 'ఓం విరాజే నమః',
           },
           {
              "title": 'ఓం శ్మశానవాసినే నమః || 10 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మాంసాశినే నమః',
           },
           {
              "title": 'ఓం ఖర్పరాశినే నమః',
           },
           {
              "title": 'ఓం మఖాంతకృతే నమః',
           },
           {
              "title": 'ఓం రక్తపాయ నమః',
           },
           {
              "title": 'ఓం ప్రాణపాయ నమః',
           },
           {
              "title": 'ఓం సిద్ధాయ నమః',
           },
           {
              "title": 'ఓం సిద్ధిదాయ నమః',
           },
           {
              "title": 'ఓం సిద్ధసేవితాయ నమః',
           },
           {
              "title": 'ఓం కరాలాయ నమః',
           },
           {
              "title": 'ఓం కాలశమనాయ నమః || 20 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం కలాకాష్ఠాతనవే నమః',
           },
           {
              "title": 'ఓం కవయే నమః',
           },
           {
              "title": 'ఓం త్రినేత్రాయ నమః',
           },
           {
              "title": 'ఓం బహునేత్రాయ నమః',
           },
           {
              "title": 'ఓం పింగలలోచనాయ నమః',
           },
           {
              "title": 'ఓం శూలపాణయే నమః',
           },
           {
              "title": 'ఓం ఖడ్గపాణయే నమః',
           },
           {
              "title": 'ఓం కంకాలినే నమః',
           },
           {
              "title": 'ఓం ధూమ్రలోచనాయ నమః',
           },
           {
              "title": 'ఓం అభీరవే నమః || 30 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం భైరవాయ నమః',
           },
           {
              "title": 'ఓం భైరవీపతయే నమః',
           },
           {
              "title": 'ఓం భూతపాయ నమః',
           },
           {
              "title": 'ఓం యోగినీపతయే నమః',
           },
           {
              "title": 'ఓం ధనదాయ నమః',
           },
           {
              "title": 'ఓం ధనహారిణే నమః',
           },
           {
              "title": 'ఓం ధనపాయ నమః',
           },
           {
              "title": 'ఓం ప్రతిభావవతే నమః',
           },
           {
              "title": 'ఓం నాగహారాయ నమః',
           },
           {
              "title": 'ఓం నాగకేశాయ నమః || 40 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం వ్యోమకేశాయ నమః',
           },
           {
              "title": 'ఓం కపాలభృతే నమః',
           },
           {
              "title": 'ఓం కాలాయ నమః',
           },
           {
              "title": 'ఓం కపాలమాలినే నమః',
           },
           {
              "title": 'ఓం కమనీయాయ నమః',
           },
           {
              "title": 'ఓం కలానిధయే నమః',
           },
           {
              "title": 'ఓం త్రిలోచనాయ నమః',
           },
           {
              "title": 'ఓం జ్వలన్నేత్రాయ నమః',
           },
           {
              "title": 'ఓం త్రిశిఖినే నమః',
           },
           {
              "title": 'ఓం త్రిలోకభృతే నమః || 50 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం త్రివృత్తనయనాయ నమః',
           },
           {
              "title": 'ఓం డింభాయ నమః',
           },
           {
              "title": 'ఓం శాంతాయ నమః',
           },
           {
              "title": 'ఓం శాంతజనప్రియాయ నమః',
           },
           {
              "title": 'ఓం వటుకాయ నమః',
           },
           {
              "title": 'ఓం వటుకేశాయ నమః',
           },
           {
              "title": 'ఓం ఖట్వాంగవరధారకాయ నమః',
           },
           {
              "title": 'ఓం భూతాధ్యక్షాయ నమః',
           },
           {
              "title": 'ఓం పశుపతయే నమః',
           },
           {
              "title": 'ఓం భిక్షుకాయ నమః || 60 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం పరిచారకాయ నమః',
           },
           {
              "title": 'ఓం ధూర్తాయ నమః',
           },
           {
              "title": 'ఓం దిగంబరాయ నమః',
           },
           {
              "title": 'ఓం సౌరిణే నమః',
           },
           {
              "title": 'ఓం హరిణే నమః',
           },
           {
              "title": 'ఓం పాండులోచనాయ నమః',
           },
           {
              "title": 'ఓం ప్రశాంతాయ నమః',
           },
           {
              "title": 'ఓం శాంతిదాయ నమః',
           },
           {
              "title": 'ఓం శుద్ధాయ నమః',
           },
           {
              "title": 'ఓం శంకరప్రియబాంధవాయ నమః || 70 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అష్టమూర్తయే నమః',
           },
           {
              "title": 'ఓం నిధీశాయ నమః',
           },
           {
              "title": 'ఓం జ్ఞానచక్షుషే నమః',
           },
           {
              "title": 'ఓం తమోమయాయ నమః',
           },
           {
              "title": 'ఓం అష్టాధారాయ నమః',
           },
           {
              "title": 'ఓం కళాధారాయ నమః',
           },
           {
              "title": 'ఓం సర్పయుక్తాయ నమః',
           },
           {
              "title": 'ఓం శశీశిఖాయ నమః',
           },
           {
              "title": 'ఓం భూధరాయ నమః',
           },
           {
              "title": 'ఓం భూధరాధీశాయ నమః || 80 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం భూపతయే నమః',
           },
           {
              "title": 'ఓం భూధరాత్మకాయ నమః',
           },
           {
              "title": 'ఓం కంకాలధారిణే నమః',
           },
           {
              "title": 'ఓం ముండినే నమః',
           },
           {
              "title": 'ఓం వ్యాలయజ్ఞోపవీతవతే నమః',
           },
           {
              "title": 'ఓం జృంభణాయ నమః',
           },
           {
              "title": 'ఓం మోహనాయ నమః',
           },
           {
              "title": 'ఓం స్తంభినే నమః',
           },
           {
              "title": 'ఓం మారణాయ నమః',
           },
           {
              "title": 'ఓం క్షోభణాయ నమః || 90 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శుద్ధనీలాంజనప్రఖ్యదేహాయ నమః',
           },
           {
              "title": 'ఓం ముండవిభూషితాయ నమః',
           },
           {
              "title": 'ఓం బలిభుజే నమః',
           },
           {
              "title": 'ఓం బలిభుతాత్మనే నమః',
           },
           {
              "title": 'ఓం కామినే నమః',
           },
           {
              "title": 'ఓం కామపరాక్రమాయ నమః',
           },
           {
              "title": 'ఓం సర్వాపత్తారకాయ నమః',
           },
           {
              "title": 'ఓం దుర్గాయ నమః',
           },
           {
              "title": 'ఓం దుష్టభూతనిషేవితాయ నమః',
           },
           {
              "title": 'ఓం కామినే నమః || 100 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం కలానిధయే నమః',
           },
           {
              "title": 'ఓం కాంతాయ నమః',
           },
           {
              "title": 'ఓం కామినీవశకృతే నమః',
           },
           {
              "title": 'ఓం వశినే నమః',
           },
           {
              "title": 'ఓం సర్వసిద్ధిప్రదాయ నమః',
           },
           {
              "title": 'ఓం వైద్యాయ నమః',
           },
           {
              "title": 'ఓం ప్రభవిష్ణవే నమః',
           },
           {
              "title": 'ఓం ప్రభావవతే నమః || 108 ||',
           },
           {
              "title": '',
           },
           {
              "title": '|| ఇతి శ్రీ బటుక భైరవ అష్టోత్తరశతనామావళీ సంపూర్ణం ||',
           },
         ],
          "title": 'శ్రీ బటుక భైరవ అష్టోత్తరశతనామావళీ',
         // "img": require('../imgs/Astotaram/kalabhairava.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం భువనేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం రాజేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం రాజరాజేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం కామేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం బాలాత్రిపుర సుందర్యై నమః',
           },
           {
              "title": 'ఓం సర్వేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం కళ్యాణ్యై నమః',
           },
           {
              "title": 'ఓం సర్వసంక్షోభిణ్యై నమః',
           },
           {
              "title": 'ఓం సర్వలోకశరీరిణ్యై నమః',
           },
           {
              "title": 'ఓం సౌగంధికపరిమళాయై నమః || 10 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మంత్రిణే నమః',
           },
           {
              "title": 'ఓం మంత్రరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం ప్రాకృత్యై నమః',
           },
           {
              "title": 'ఓం వికృత్యై నమః',
           },
           {
              "title": 'ఓం ఆదిత్యై నమః',
           },
           {
              "title": 'ఓం సౌభాగ్యవత్యై నమః',
           },
           {
              "title": 'ఓం పద్మావత్యై నమః',
           },
           {
              "title": 'ఓం భగవత్యై నమః',
           },
           {
              "title": 'ఓం శ్రీమత్యై నమః',
           },
           {
              "title": 'ఓం సత్యవత్యై నమః || 20 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం ప్రియకృత్యై నమః',
           },
           {
              "title": 'ఓం మాయాయై నమః',
           },
           {
              "title": 'ఓం సర్వమంగళాయై నమః',
           },
           {
              "title": 'ఓం సర్వలోకమోహాధీశాన్యై నమః',
           },
           {
              "title": 'ఓం కింకరీభూతగీర్వాణ్యై నమః',
           },
           {
              "title": 'ఓం పరబ్రహ్మస్వరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం పురాణాగమరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం పంచప్రణవరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం సర్వగ్రహరూపిణ్యై నమః',
           },
           {
              "title": 'ఓం రక్తగంధకస్తూరీ విలేప్యై నమః || 30 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం నానాయై నమః',
           },
           {
              "title": 'ఓం శరణ్యాయై నమః',
           },
           {
              "title": 'ఓం నిఖిల విద్యేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం జనేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం భూతేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం సర్వసాక్షిణ్యై నమః',
           },
           {
              "title": 'ఓం క్షేమకారిణ్యై నమః',
           },
           {
              "title": 'ఓం పుణ్యాయై నమః',
           },
           {
              "title": 'ఓం సర్వరక్షణ్యై నమః',
           },
           {
              "title": 'ఓం సకలధర్మిణ్యే నమః || 40 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం విశ్వకర్మిణే నమః',
           },
           {
              "title": 'ఓం సురముని దేవనుతాయై నమః',
           },
           {
              "title": 'ఓం సర్వలోకారాధ్యాయై నమః',
           },
           {
              "title": 'ఓం పద్మాసనాసీనాయై నమః',
           },
           {
              "title": 'ఓం యూగీశ్వరమనోధ్యేయాయై నమః',
           },
           {
              "title": 'ఓం చతుర్భుజే నమః',
           },
           {
              "title": 'ఓం సర్వార్థ సాధనాధీశాయై నమః',
           },
           {
              "title": 'ఓం పూర్వాయై నమః',
           },
           {
              "title": 'ఓం నిత్యాయై నమః',
           },
           {
              "title": 'ఓం పరమానందాయై నమః || 50 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం కళాయై నమః',
           },
           {
              "title": 'ఓం అనంగాయై నమః',
           },
           {
              "title": 'ఓం వసుంధరాయై నమః',
           },
           {
              "title": 'ఓం శుభదాయై నమః',
           },
           {
              "title": 'ఓం త్రికాలజ్ఞాన సంపన్నాయై నమః',
           },
           {
              "title": 'ఓం పీతాంబరధరే నమః',
           },
           {
              "title": 'ఓం అనంతాయై నమః',
           },
           {
              "title": 'ఓం భక్తవత్సలాయై నమః',
           },
           {
              "title": 'ఓం పాదపద్మాయై నమః',
           },
           {
              "title": 'ఓం జగత్కారిణే నమః || 60 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అవ్యయాయై నమః',
           },
           {
              "title": 'ఓం లీలామానుష విగ్రహాయై నమః',
           },
           {
              "title": 'ఓం సర్వమాయాయై నమః',
           },
           {
              "title": 'ఓం మృత్యుంజయాయై నమః',
           },
           {
              "title": 'ఓం కోటిసూర్యసమప్రభాయై నమః',
           },
           {
              "title": 'ఓం పవిత్రాయై నమః',
           },
           {
              "title": 'ఓం ప్రాణదాయై నమః',
           },
           {
              "title": 'ఓం విమలాయై నమః',
           },
           {
              "title": 'ఓం మహాభూషాయై నమః',
           },
           {
              "title": 'ఓం సర్వభూతహితప్రదాయై నమః || 70 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం పద్మాలయాయై నమః',
           },
           {
              "title": 'ఓం సుధాయై నమః',
           },
           {
              "title": 'ఓం స్వాంగాయై నమః',
           },
           {
              "title": 'ఓం పద్మరాగకిరీటినే నమః',
           },
           {
              "title": 'ఓం సర్వపాపవినాశిన్యై నమః',
           },
           {
              "title": 'ఓం సకలసంపత్ర్పదాయిన్యై నమః',
           },
           {
              "title": 'ఓం పద్మగంధిన్యై నమః',
           },
           {
              "title": 'ఓం సర్వవిఘ్నక్లేశధ్వంసినే నమః',
           },
           {
              "title": 'ఓం హేమమాలిన్యై నమః',
           },
           {
              "title": 'ఓం విశ్వమూర్త్యే నమః || 80 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అగ్నికల్పాయై నమః',
           },
           {
              "title": 'ఓం పుండరీకాక్షిణ్యై నమః',
           },
           {
              "title": 'ఓం మహాశక్త్యై నమః',
           },
           {
              "title": 'ఓం బుద్ధ్యే నమః',
           },
           {
              "title": 'ఓం భూతేశ్వర్యై నమః',
           },
           {
              "title": 'ఓం అదృశ్యాయై నమః',
           },
           {
              "title": 'ఓం శుభేక్షణాయై నమః',
           },
           {
              "title": 'ఓం సర్వదర్శిణే నమః',
           },
           {
              "title": 'ఓం ప్రాణాయై నమః',
           },
           {
              "title": 'ఓం శ్రేష్ఠాయై నమః || 90 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శాంతాయై నమః',
           },
           {
              "title": 'ఓం తత్వాయై నమః',
           },
           {
              "title": 'ఓం సర్వజనన్యై నమః',
           },
           {
              "title": 'ఓం సర్వలోకవాసిన్యై నమః',
           },
           {
              "title": 'ఓం కైవల్యరేఖిన్యై నమః',
           },
           {
              "title": 'ఓం భక్తపోషణవినోదిన్యై నమః',
           },
           {
              "title": 'ఓం దారిద్ర్యనాశిన్యై నమః',
           },
           {
              "title": 'ఓం సర్వోపద్రవనివారిణ్యై నమః',
           },
           {
              "title": 'ఓం సంహృదానందలహర్యై నమః',
           },
           {
              "title": 'ఓం చతుర్దశాంత కోణస్థాయై నమః || 100 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సర్వాత్మాయై నమః',
           },
           {
              "title": 'ఓం సత్యవక్త్రే నమః',
           },
           {
              "title": 'ఓం న్యాయాయై నమః',
           },
           {
              "title": 'ఓం ధనధాన్యనిధ్యై నమః',
           },
           {
              "title": 'ఓం కాయకృత్త్యై నమః',
           },
           {
              "title": 'ఓం అనంతజిత్యై నమః',
           },
           {
              "title": 'ఓం అనంతగుణరూపే నమః',
           },
           {
              "title": 'ఓం స్థిరేరాజేశ్వర్యై నమః || 108 ||',
           },
           {
              "title": '',
           },
           {
              "title": '|| ఇతి శ్రీ రాజరాజేశ్వరీ అష్టోత్తర శతనామావళి సంపూర్ణం ||',
           },
           {
              "title": '',
           },
         ],
          "title": 'శ్రీ రాజరాజేశ్వరీదేవి అష్టోత్తర శతనామావళి',
         // "img": require('../imgs/Astotaram/Rajarajeswari.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం శ్రీ రామాయ నమః',
           },
           {
              "title": 'ఓం రామభద్రాయ నమః',
           },
           {
              "title": 'ఓం రామచంద్రాయ నమః',
           },
           {
              "title": 'ఓం శాశ్వతాయ నమః',
           },
           {
              "title": 'ఓం రాజీవలోచనాయ నమః',
           },
           {
              "title": 'ఓం శ్రీమతే నమః',
           },
           {
              "title": 'ఓం రాజేంద్రాయ నమః',
           },
           {
              "title": 'ఓం రఘుపుంగవాయ నమః',
           },
           {
              "title": 'ఓం జానకీవల్లభాయ నమః',
           },
           {
              "title": 'ఓం జైత్రాయ నమః ॥ 10 ॥',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం జితామిత్రాయ నమః',
           },
           {
              "title": 'ఓం జనార్దనాయ నమః',
           },
           {
              "title": 'ఓం విశ్వామిత్రప్రియాయ నమః',
           },
           {
              "title": 'ఓం దాంతాయ నమః',
           },
           {
              "title": 'ఓం శరణత్రాణతత్పరాయ నమః',
           },
           {
              "title": 'ఓం వాలిప్రమథనాయ నమః',
           },
           {
              "title": 'ఓం వాఙ్మినే నమః',
           },
           {
              "title": 'ఓం సత్యవాచే నమః',
           },
           {
              "title": 'ఓం సత్యవిక్రమాయ నమః',
           },
           {
              "title": 'ఓం సత్యవ్రతాయ నమః ॥ 20 ॥',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం వ్రతధరాయ నమః',
           },
           {
              "title": 'ఓం సదా హనుమదాశ్రితాయ నమః',
           },
           {
              "title": 'ఓం కోసలేయాయ నమః',
           },
           {
              "title": 'ఓం ఖరధ్వంసినే నమః',
           },
           {
              "title": 'ఓం విరాధవధపండితాయ నమః',
           },
           {
              "title": 'ఓం విభీషణపరిత్రాత్రే నమః',
           },
           {
              "title": 'ఓం హరకోదండ ఖండనాయ నమః',
           },
           {
              "title": 'ఓం సప్తసాల ప్రభేత్త్రే నమః',
           },
           {
              "title": 'ఓం దశగ్రీవశిరోహరాయ నమః',
           },
           {
              "title": 'ఓం జామదగ్న్యమహాదర్పదళనాయ నమః ॥ 30 ॥',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం తాటకాంతకాయ నమః',
           },
           {
              "title": 'ఓం వేదాంత సారాయ నమః',
           },
           {
              "title": 'ఓం వేదాత్మనే నమః',
           },
           {
              "title": 'ఓం భవరోగస్య భేషజాయ నమః',
           },
           {
              "title": 'ఓం దూషణత్రిశిరోహంత్రే నమః',
           },
           {
              "title": 'ఓం త్రిమూర్తయే నమః',
           },
           {
              "title": 'ఓం త్రిగుణాత్మకాయ నమః',
           },
           {
              "title": 'ఓం త్రివిక్రమాయ నమః',
           },
           {
              "title": 'ఓం త్రిలోకాత్మనే నమః',
           },
           {
              "title": 'ఓం పుణ్యచారిత్రకీర్తనాయ నమః ॥ 40 ॥',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం త్రిలోకరక్షకాయ నమః',
           },
           {
              "title": 'ఓం ధన్వినే నమః',
           },
           {
              "title": 'ఓం దండకారణ్యకర్తనాయ నమః',
           },
           {
              "title": 'ఓం అహల్యాశాపశమనాయ నమః',
           },
           {
              "title": 'ఓం పితృభక్తాయ నమః',
           },
           {
              "title": 'ఓం వరప్రదాయ నమః',
           },
           {
              "title": 'ఓం జితక్రోధాయ నమః',
           },
           {
              "title": 'ఓం జితామిత్రాయ నమః',
           },
           {
              "title": 'ఓం జగద్గురవే నమః',
           },
           {
              "title": 'ఓం ఋక్షవానరసంఘాతినే నమః ॥ 50॥',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం చిత్రకూటసమాశ్రయాయ నమః',
           },
           {
              "title": 'ఓం జయంతత్రాణ వరదాయ నమః',
           },
           {
              "title": 'ఓం సుమిత్రాపుత్ర సేవితాయ నమః',
           },
           {
              "title": 'ఓం సర్వదేవాదిదేవాయ నమః',
           },
           {
              "title": 'ఓం మృతవానరజీవనాయ నమః',
           },
           {
              "title": 'ఓం మాయామారీచహంత్రే నమః',
           },
           {
              "title": 'ఓం మహాదేవాయ నమః',
           },
           {
              "title": 'ఓం మహాభుజాయ నమః',
           },
           {
              "title": 'ఓం సర్వదేవస్తుతాయ నమః',
           },
           {
              "title": 'ఓం సౌమ్యాయ నమః ॥ 60 ॥',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం బ్రహ్మణ్యాయ నమః',
           },
           {
              "title": 'ఓం మునిసంస్తుతాయ నమః',
           },
           {
              "title": 'ఓం మహాయోగినే నమః',
           },
           {
              "title": 'ఓం మహోదారాయ నమః',
           },
           {
              "title": 'ఓం సుగ్రీవేప్సిత రాజ్యదాయ నమః',
           },
           {
              "title": 'ఓం సర్వపుణ్యాధిక ఫలాయ నమః',
           },
           {
              "title": 'ఓం స్మృతసర్వాఘనాశనాయ నమః',
           },
           {
              "title": 'ఓం ఆదిపురుషాయ నమః',
           },
           {
              "title": 'ఓం పరమపురుషాయ నమః',
           },
           {
              "title": 'ఓం మహాపురుషాయ నమః ॥ 70 ॥',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం పుణ్యోదయాయ నమః',
           },
           {
              "title": 'ఓం పురాణాయ నమః',
           },
           {
              "title": 'ఓం పురుషోత్తమాయ నమః',
           },
           {
              "title": 'ఓం స్మితవక్త్రాయ నమః',
           },
           {
              "title": 'ఓం మితభాషిణే నమః',
           },
           {
              "title": 'ఓం పూర్వభాషిణే నమః',
           },
           {
              "title": 'ఓం రాఘవాయ నమః',
           },
           {
              "title": 'ఓం అనంతగుణగంభీరాయ నమః',
           },
           {
              "title": 'ఓం ధీరోదాత్త గుణోత్తమాయ నమః ॥ 80 ॥',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మాయామానుషచారిత్రాయ నమః',
           },
           {
              "title": 'ఓం మహాదేవాది పూజితాయ నమః',
           },
           {
              "title": 'ఓం సేతుకృతే నమః',
           },
           {
              "title": 'ఓం జితవారాశయే నమః',
           },
           {
              "title": 'ఓం సర్వతీర్థమయాయ నమః',
           },
           {
              "title": 'ఓం హరయే నమః',
           },
           {
              "title": 'ఓం శ్యామాంగాయ నమః',
           },
           {
              "title": 'ఓం సుందరాయ నమః',
           },
           {
              "title": 'ఓం శూరాయ నమః',
           },
           {
              "title": 'ఓం పీతవాససే నమః ॥ 90 ॥',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం ధనుర్ధరాయ నమః',
           },
           {
              "title": 'ఓం సర్వయజ్ఞాధిపాయ నమః',
           },
           {
              "title": 'ఓం యజ్వనే నమః',
           },
           {
              "title": 'ఓం జరామరణవర్జితాయ నమః',
           },
           {
              "title": 'ఓం శివలింగప్రతిష్ఠాత్రే నమః',
           },
           {
              "title": 'ఓం సర్వావగుణవర్జితాయ నమః',
           },
           {
              "title": 'ఓం పరమాత్మనే నమః',
           },
           {
              "title": 'ఓం పరస్మై బ్రహ్మణే నమః',
           },
           {
              "title": 'ఓం సచ్చిదానంద విగ్రహాయ నమః',
           },
           {
              "title": 'ఓం పరస్మైజ్యోతిషే నమః ॥ 100 ॥',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం పరస్మై ధామ్నే నమః',
           },
           {
              "title": 'ఓం పరాకాశాయ నమః',
           },
           {
              "title": 'ఓం పరాత్పరాయ నమః',
           },
           {
              "title": 'ఓం పరేశాయ నమః',
           },
           {
              "title": 'ఓం పారగాయ నమః',
           },
           {
              "title": 'ఓం పారాయ నమః',
           },
           {
              "title": 'ఓం సర్వదేవాత్మకాయ నమః',
           },
           {
              "title": 'ఓం పరాయ నమః ॥ 108 ॥',
           },
           {
              "title": '',
           },
           {
              "title": 'ఇతి శ్రీ రామాష్టోత్తర శతనామావళీస్సమాప్త: ॥',
           },
           {
              "title": '',
           },
         ],
          "title": 'శ్రీ రామాష్టోత్తర శత నామావళి',
         // "img": require('../imgs/Astotaram/Rama.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం విష్ణవే నమః',
           },
           {
              "title": 'ఓం జిష్ణవే నమః',
           },
           {
              "title": 'ఓం వషట్కారాయ నమః',
           },
           {
              "title": 'ఓం దేవదేవాయ నమః',
           },
           {
              "title": 'ఓం వృషాకపయే నమః',
           },
           {
              "title": 'ఓం దామోదరాయ నమః',
           },
           {
              "title": 'ఓం దీనబంధవే నమః',
           },
           {
              "title": 'ఓం ఆదిదేవాయ నమః',
           },
           {
              "title": 'ఓం అదితేస్తుతాయ నమః',
           },
           {
              "title": 'ఓం పుండరీకాయ నమః || 10 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం పరానందాయ నమః',
           },
           {
              "title": 'ఓం పరమాత్మనే నమః',
           },
           {
              "title": 'ఓం పరాత్పరాయ నమః',
           },
           {
              "title": 'ఓం పరశుధారిణే నమః',
           },
           {
              "title": 'ఓం విశ్వాత్మనే నమః',
           },
           {
              "title": 'ఓం కృష్ణాయ నమః',
           },
           {
              "title": 'ఓం కలిమలాపహారిణే నమః',
           },
           {
              "title": 'ఓం కౌస్తుభోద్భాసితోరస్కాయ నమః',
           },
           {
              "title": 'ఓం నరాయ నమః',
           },
           {
              "title": 'ఓం నారాయణాయ నమః || 20 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం హరయే నమః',
           },
           {
              "title": 'ఓం హరాయ నమః',
           },
           {
              "title": 'ఓం హరప్రియాయ నమః',
           },
           {
              "title": 'ఓం స్వామినే నమః',
           },
           {
              "title": 'ఓం వైకుంఠాయ నమః',
           },
           {
              "title": 'ఓం విశ్వతోముఖాయ నమః',
           },
           {
              "title": 'ఓం హృషీకేశాయ నమః',
           },
           {
              "title": 'ఓం అప్రమేయాత్మనే నమః',
           },
           {
              "title": 'ఓం వరాహాయ నమః',
           },
           {
              "title": 'ఓం ధరణీధరాయ నమః || 30 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం వామనాయ నమః',
           },
           {
              "title": 'ఓం వేదవక్తాయ నమః',
           },
           {
              "title": 'ఓం వాసుదేవాయ నమః',
           },
           {
              "title": 'ఓం సనాతనాయ నమః',
           },
           {
              "title": 'ఓం రామాయ నమః',
           },
           {
              "title": 'ఓం విరామాయ నమః',
           },
           {
              "title": 'ఓం విరజాయ నమః',
           },
           {
              "title": 'ఓం రావణారయే నమః',
           },
           {
              "title": 'ఓం రమాపతయే నమః',
           },
           {
              "title": 'ఓం వైకుంఠవాసినే నమః || 40 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం వసుమతే నమః',
           },
           {
              "title": 'ఓం ధనదాయ నమః',
           },
           {
              "title": 'ఓం ధరణీధరాయ నమః',
           },
           {
              "title": 'ఓం ధర్మేశాయ నమః',
           },
           {
              "title": 'ఓం ధరణీనాథాయ నమః',
           },
           {
              "title": 'ఓం ధ్యేయాయ నమః',
           },
           {
              "title": 'ఓం ధర్మభృతాంవరాయ నమః',
           },
           {
              "title": 'ఓం సహస్రశీర్షాయ నమః',
           },
           {
              "title": 'ఓం పురుషాయ నమః',
           },
           {
              "title": 'ఓం సహస్రాక్షాయ నమః || 50 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సహస్రపాదే నమః',
           },
           {
              "title": 'ఓం సర్వగాయ నమః',
           },
           {
              "title": 'ఓం సర్వవిదే నమః',
           },
           {
              "title": 'ఓం సర్వాయ నమః',
           },
           {
              "title": 'ఓం శరణ్యాయ నమః',
           },
           {
              "title": 'ఓం సాధువల్లభాయ నమః',
           },
           {
              "title": 'ఓం కౌసల్యానందనాయ నమః',
           },
           {
              "title": 'ఓం శ్రీమతే నమః',
           },
           {
              "title": 'ఓం రక్షసఃకులనాశకాయ నమః',
           },
           {
              "title": 'ఓం జగత్కర్తాయ నమః || 60 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం జగద్ధర్తాయ నమః',
           },
           {
              "title": 'ఓం జగజ్జేతాయ నమః',
           },
           {
              "title": 'ఓం జనార్తిహరాయ నమః',
           },
           {
              "title": 'ఓం జానకీవల్లభాయ నమః',
           },
           {
              "title": 'ఓం దేవాయ నమః',
           },
           {
              "title": 'ఓం జయరూపాయ నమః',
           },
           {
              "title": 'ఓం జలేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం క్షీరాబ్ధివాసినే నమః',
           },
           {
              "title": 'ఓం క్షీరాబ్ధితనయావల్లభాయ నమః',
           },
           {
              "title": 'ఓం శేషశాయినే నమః || 70 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం పన్నగారివాహనాయ నమః',
           },
           {
              "title": 'ఓం విష్టరశ్రవసే నమః',
           },
           {
              "title": 'ఓం మాధవాయ నమః',
           },
           {
              "title": 'ఓం మథురానాథాయ నమః',
           },
           {
              "title": 'ఓం ముకుందాయ నమః',
           },
           {
              "title": 'ఓం మోహనాశనాయ నమః',
           },
           {
              "title": 'ఓం దైత్యారిణే నమః',
           },
           {
              "title": 'ఓం పుండరీకాక్షాయ నమః',
           },
           {
              "title": 'ఓం అచ్యుతాయ నమః',
           },
           {
              "title": 'ఓం మధుసూదనాయ నమః || 80 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సోమసూర్యాగ్నినయనాయ నమః',
           },
           {
              "title": 'ఓం నృసింహాయ నమః',
           },
           {
              "title": 'ఓం భక్తవత్సలాయ నమః',
           },
           {
              "title": 'ఓం నిత్యాయ నమః',
           },
           {
              "title": 'ఓం నిరామయాయ నమః',
           },
           {
              "title": 'ఓం శుద్ధాయ నమః',
           },
           {
              "title": 'ఓం నరదేవాయ నమః',
           },
           {
              "title": 'ఓం జగత్ప్రభవే నమః',
           },
           {
              "title": 'ఓం హయగ్రీవాయ నమః',
           },
           {
              "title": 'ఓం జితరిపవే నమః || 90 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం ఉపేంద్రాయ నమః',
           },
           {
              "title": 'ఓం రుక్మిణీపతయే నమః',
           },
           {
              "title": 'ఓం సర్వదేవమయాయ నమః',
           },
           {
              "title": 'ఓం శ్రీశాయ నమః',
           },
           {
              "title": 'ఓం సర్వాధారాయ నమః',
           },
           {
              "title": 'ఓం సనాతనాయ నమః',
           },
           {
              "title": 'ఓం సౌమ్యాయ నమః',
           },
           {
              "title": 'ఓం సౌమ్యప్రదాయ నమః',
           },
           {
              "title": 'ఓం స్రష్టే నమః',
           },
           {
              "title": 'ఓం విష్వక్సేనాయ నమః || 100 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం జనార్దనాయ నమః',
           },
           {
              "title": 'ఓం యశోదాతనయాయ నమః',
           },
           {
              "title": 'ఓం యోగినే నమః',
           },
           {
              "title": 'ఓం యోగశాస్త్రపరాయణాయ నమః',
           },
           {
              "title": 'ఓం రుద్రాత్మకాయ నమః',
           },
           {
              "title": 'ఓం రుద్రమూర్తయే నమః',
           },
           {
              "title": 'ఓం రాఘవాయ నమః',
           },
           {
              "title": 'ఓం మధుసూదనాయ నమః || 108 ||',
           },
           {
              "title": '',
           },
           {
              "title": '|| శ్రీ విష్ణు అష్టోత్తర శతనామావళి సమాప్త: ||',
           },
           {
              "title": '',
           },
         ],
          "title": 'శ్రీ విష్ణు అష్టోత్తర శత నామావళి',
         // "img": require('../imgs/Astotaram/vishnu.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం శ్రీ వేంకటేశాయ నమః',
           },
           {
              "title": 'ఓం శ్రీనివాసాయ నమః',
           },
           {
              "title": 'ఓం లక్ష్మీపతయే నమః',
           },
           {
              "title": 'ఓం అనామయాయ నమః',
           },
           {
              "title": 'ఓం అమృతాశాయ నమః',
           },
           {
              "title": 'ఓం జగద్వంద్యాయ నమః',
           },
           {
              "title": 'ఓం గోవిందాయ నమః',
           },
           {
              "title": 'ఓం శాశ్వతాయ నమః',
           },
           {
              "title": 'ఓం ప్రభవే నమః',
           },
           {
              "title": 'ఓం శేషాద్రినిలయాయ నమః || 10 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం దేవాయ నమః',
           },
           {
              "title": 'ఓం కేశవాయ నమః',
           },
           {
              "title": 'ఓం మధుసూదనాయ నమః',
           },
           {
              "title": 'ఓం అమృతాయ నమః',
           },
           {
              "title": 'ఓం మాధవాయ నమః',
           },
           {
              "title": 'ఓం కృష్ణాయ నమః',
           },
           {
              "title": 'ఓం శ్రీహరయే నమః',
           },
           {
              "title": 'ఓం జ్ఞానపంజరాయ నమః',
           },
           {
              "title": 'ఓం శ్రీవత్సవక్షసే నమః',
           },
           {
              "title": 'ఓం సర్వేశాయ నమః || 20 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం గోపాలాయ నమః',
           },
           {
              "title": 'ఓం పురుషోత్తమాయ నమః',
           },
           {
              "title": 'ఓం గోపీశ్వరాయ నమః',
           },
           {
              "title": 'ఓం పరస్మై జ్యోతిషే నమః',
           },
           {
              "title": 'ఓం వైకుంఠ పతయే నమః',
           },
           {
              "title": 'ఓం అవ్యయాయ నమః',
           },
           {
              "title": 'ఓం సుధాతనవే నమః',
           },
           {
              "title": 'ఓం యాదవేంద్రాయ నమః',
           },
           {
              "title": 'ఓం నిత్య యౌవనరూపవతే నమః',
           },
           {
              "title": 'ఓం చతుర్వేదాత్మకాయ నమః || 30 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం విష్ణవే నమః',
           },
           {
              "title": 'ఓం అచ్యుతాయ నమః',
           },
           {
              "title": 'ఓం పద్మినీప్రియాయ నమః',
           },
           {
              "title": 'ఓం ధరాపతయే నమః',
           },
           {
              "title": 'ఓం సురపతయే నమః',
           },
           {
              "title": 'ఓం నిర్మలాయ నమః',
           },
           {
              "title": 'ఓం దేవపూజితాయ నమః',
           },
           {
              "title": 'ఓం చతుర్భుజాయ నమః',
           },
           {
              "title": 'ఓం చక్రధరాయ నమః',
           },
           {
              "title": 'ఓం త్రిధామ్నే నమః || 40 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం త్రిగుణాశ్రయాయ నమః',
           },
           {
              "title": 'ఓం నిర్వికల్పాయ నమః',
           },
           {
              "title": 'ఓం నిష్కళంకాయ నమః',
           },
           {
              "title": 'ఓం నిరాంతకాయ నమః',
           },
           {
              "title": 'ఓం నిరంజనాయ నమః',
           },
           {
              "title": 'ఓం విరాభాసాయ నమః',
           },
           {
              "title": 'ఓం నిత్యతృప్తాయ నమః',
           },
           {
              "title": 'ఓం నిర్గుణాయ నమః',
           },
           {
              "title": 'ఓం నిరుపద్రవాయ నమః',
           },
           {
              "title": 'ఓం గదాధరాయ నమః || 50 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శార్-ంగపాణయే నమః',
           },
           {
              "title": 'ఓం నందకినే నమః',
           },
           {
              "title": 'ఓం శంఖధారకాయ నమః',
           },
           {
              "title": 'ఓం అనేకమూర్తయే నమః',
           },
           {
              "title": 'ఓం అవ్యక్తాయ నమః',
           },
           {
              "title": 'ఓం కటిహస్తాయ నమః',
           },
           {
              "title": 'ఓం వరప్రదాయ నమః',
           },
           {
              "title": 'ఓం అనేకాత్మనే నమః',
           },
           {
              "title": 'ఓం దీనబంధవే నమః',
           },
           {
              "title": 'ఓం ఆర్తలోకాభయప్రదాయ నమః || 60 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం ఆకాశరాజవరదాయ నమః',
           },
           {
              "title": 'ఓం యోగిహృత్పద్మమందిరాయ నమః',
           },
           {
              "title": 'ఓం దామోదరాయ నమః',
           },
           {
              "title": 'ఓం జగత్పాలాయ నమః',
           },
           {
              "title": 'ఓం పాపఘ్నాయ నమః',
           },
           {
              "title": 'ఓం భక్తవత్సలాయ నమః',
           },
           {
              "title": 'ఓం త్రివిక్రమాయ నమః',
           },
           {
              "title": 'ఓం శింశుమారాయ నమః',
           },
           {
              "title": 'ఓం జటామకుట శోభితాయ నమః',
           },
           {
              "title": 'ఓం శంఖమద్యోల్లసన్మంజుకింకిణ్యాఢ్యకరండకాయ నమః || 70 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం నీలమోఘశ్యామ తనవే నమః',
           },
           {
              "title": 'ఓం బిల్వపత్రార్చన ప్రియాయ నమః',
           },
           {
              "title": 'ఓం జగద్వ్యాపినే నమః',
           },
           {
              "title": 'ఓం జగత్కర్త్రే నమః',
           },
           {
              "title": 'ఓం జగత్సాక్షిణే నమః',
           },
           {
              "title": 'ఓం జగత్పతయే నమః',
           },
           {
              "title": 'ఓం చింతితార్థప్రదాయ నమః',
           },
           {
              "title": 'ఓం జిష్ణవే నమః',
           },
           {
              "title": 'ఓం దాశార్హాయ నమః',
           },
           {
              "title": 'ఓం దశరూపవతే నమః || 80 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం దేవకీ నందనాయ నమః',
           },
           {
              "title": 'ఓం శౌరయే నమః',
           },
           {
              "title": 'ఓం హయగ్రీవాయ నమః',
           },
           {
              "title": 'ఓం జనార్దనాయ నమః',
           },
           {
              "title": 'ఓం కన్యాశ్రవణతారేజ్యాయ నమః',
           },
           {
              "title": 'ఓం పీతాంబరధరాయ నమః',
           },
           {
              "title": 'ఓం అనఘాయ నమః',
           },
           {
              "title": 'ఓం వనమాలినే నమః',
           },
           {
              "title": 'ఓం పద్మనాభాయ నమః',
           },
           {
              "title": 'ఓం మృగయాసక్త మానసాయ నమః || 90 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అశ్వారూఢాయ నమః',
           },
           {
              "title": 'ఓం ఖడ్గధారిణే నమః',
           },
           {
              "title": 'ఓం ధనార్జన సముత్సుకాయ నమః',
           },
           {
              "title": 'ఓం ఘనసారల సన్మధ్యకస్తూరీ తిలకోజ్జ్వలాయ నమః',
           },
           {
              "title": 'ఓం సచ్చితానందరూపాయ నమః',
           },
           {
              "title": 'ఓం జగన్మంగళ దాయకాయ నమః',
           },
           {
              "title": 'ఓం యజ్ఞరూపాయ నమః',
           },
           {
              "title": 'ఓం యజ్ఞభోక్త్రే నమః',
           },
           {
              "title": 'ఓం చిన్మయాయ నమః',
           },
           {
              "title": 'ఓం పరమేశ్వరాయ నమః || 100 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం పరమార్థప్రదాయకాయ నమః',
           },
           {
              "title": 'ఓం శాంతాయ నమః',
           },
           {
              "title": 'ఓం శ్రీమతే నమః',
           },
           {
              "title": 'ఓం దోర్దండ విక్రమాయ నమః',
           },
           {
              "title": 'ఓం పరాత్పరాయ నమః',
           },
           {
              "title": 'ఓం పరస్మై బ్రహ్మణే నమః',
           },
           {
              "title": 'ఓం శ్రీవిభవే నమః',
           },
           {
              "title": 'ఓం జగదీశ్వరాయ నమః || 108 ||',
           },
           {
              "title": '',
           },
           {
              "title": '|| ఇతి శ్రీవేంకటేశ్వర అష్టోత్తర శతనామావళిః సంపూర్ణః ||',
           },
         ],
          "title": 'శ్రీ వేంకటేశ్వర అష్టోత్తర శత నామావళి',
         // "img": require('../imgs/Astotaram/Venkateswara.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం శివాయ నమః',
           },
           {
              "title": 'ఓం మహేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం శంభవే నమః',
           },
           {
              "title": 'ఓం పినాకినే నమః',
           },
           {
              "title": 'ఓం శశిశేఖరాయ నమః',
           },
           {
              "title": 'ఓం వామదేవాయ నమః',
           },
           {
              "title": 'ఓం విరూపాక్షాయ నమః',
           },
           {
              "title": 'ఓం కపర్దినే నమః',
           },
           {
              "title": 'ఓం నీలలోహితాయ నమః',
           },
           {
              "title": 'ఓం శంకరాయ నమః || 10 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శూలపాణినే నమః',
           },
           {
              "title": 'ఓం ఖట్వాంగినే నమః',
           },
           {
              "title": 'ఓం విష్ణువల్లభాయ నమః',
           },
           {
              "title": 'ఓం శిపివిష్టాయ నమః',
           },
           {
              "title": 'ఓం అంబికానాథాయ నమః',
           },
           {
              "title": 'ఓం శ్రీకంఠాయ నమః',
           },
           {
              "title": 'ఓం భక్తవత్సలాయ నమః',
           },
           {
              "title": 'ఓం భవాయ నమః',
           },
           {
              "title": 'ఓం శర్వాయ నమః',
           },
           {
              "title": 'ఓం త్రిలోకేశాయ నమః || 20 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం శితికంఠాయ నమః',
           },
           {
              "title": 'ఓం శివాప్రియాయ నమః',
           },
           {
              "title": 'ఓం ఉగ్రాయ నమః',
           },
           {
              "title": 'ఓం కపాలినే నమః',
           },
           {
              "title": 'ఓం కామారయే నమః',
           },
           {
              "title": 'ఓం అంధకాసురసూదనాయ నమః',
           },
           {
              "title": 'ఓం గంగాధరాయ నమః',
           },
           {
              "title": 'ఓం లలాటాక్షాయ నమః',
           },
           {
              "title": 'ఓం కాలకాలాయ నమః',
           },
           {
              "title": 'ఓం కృపానిధయే నమః || 30 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం భీమాయ నమః',
           },
           {
              "title": 'ఓం పరశుహస్తాయ నమః',
           },
           {
              "title": 'ఓం మృగపాణయే నమః',
           },
           {
              "title": 'ఓం జటాధరాయ నమః',
           },
           {
              "title": 'ఓం కైలాసవాసినే నమః',
           },
           {
              "title": 'ఓం కవచినే నమః',
           },
           {
              "title": 'ఓం కఠోరాయ నమః',
           },
           {
              "title": 'ఓం త్రిపురాంతకాయ నమః',
           },
           {
              "title": 'ఓం వృషాంకాయ నమః',
           },
           {
              "title": 'ఓం వృషభారూఢాయ నమః || 40 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం భస్మోద్ధూలితవిగ్రహాయ నమః',
           },
           {
              "title": 'ఓం సామప్రియాయ నమః',
           },
           {
              "title": 'ఓం స్వరమయాయ నమః',
           },
           {
              "title": 'ఓం త్రయీమూర్తయే నమః',
           },
           {
              "title": 'ఓం అనీశ్వరాయ నమః',
           },
           {
              "title": 'ఓం సర్వజ్ఞాయ నమః',
           },
           {
              "title": 'ఓం పరమాత్మనే నమః',
           },
           {
              "title": 'ఓం సోమసూర్యాగ్నిలోచనాయ నమః',
           },
           {
              "title": 'ఓం హవిషే నమః',
           },
           {
              "title": 'ఓం యజ్ఞమయాయ నమః || 50 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సోమాయ నమః',
           },
           {
              "title": 'ఓం పంచవక్త్రాయ నమః',
           },
           {
              "title": 'ఓం సదాశివాయ నమః',
           },
           {
              "title": 'ఓం విశ్వేశ్వరాయ నమః',
           },
           {
              "title": 'ఓం వీరభద్రాయ నమః',
           },
           {
              "title": 'ఓం గణనాథాయ నమః',
           },
           {
              "title": 'ఓం ప్రజాపతయే నమః',
           },
           {
              "title": 'ఓం హిరణ్యరేతసే నమః',
           },
           {
              "title": 'ఓం దుర్ధర్షాయ నమః',
           },
           {
              "title": 'ఓం గిరీశాయ నమః || 60 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం గిరిశాయ నమః',
           },
           {
              "title": 'ఓం అనఘాయ నమః',
           },
           {
              "title": 'ఓం భుజంగభూషణాయ నమః',
           },
           {
              "title": 'ఓం భర్గాయ నమః',
           },
           {
              "title": 'ఓం గిరిధన్వనే నమః',
           },
           {
              "title": 'ఓం గిరిప్రియాయ నమః',
           },
           {
              "title": 'ఓం కృత్తివాససే నమః',
           },
           {
              "title": 'ఓం పురారాతయే నమః',
           },
           {
              "title": 'ఓం భగవతే నమః',
           },
           {
              "title": 'ఓం ప్రమథాధిపాయ నమః || 70 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మృత్యుంజయాయ నమః',
           },
           {
              "title": 'ఓం సూక్ష్మతనవే నమః',
           },
           {
              "title": 'ఓం జగద్వ్యాపినే నమః',
           },
           {
              "title": 'ఓం జగద్గురువే నమః',
           },
           {
              "title": 'ఓం వ్యోమకేశాయ నమః',
           },
           {
              "title": 'ఓం మహాసేనజనకాయ నమః',
           },
           {
              "title": 'ఓం చారువిక్రమాయ నమః',
           },
           {
              "title": 'ఓం రుద్రాయ నమః',
           },
           {
              "title": 'ఓం భూతపతయే నమః',
           },
           {
              "title": 'ఓం స్థాణవే నమః || 80 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం అహిర్బుధ్న్యాయ నమః',
           },
           {
              "title": 'ఓం దిగంబరాయ నమః',
           },
           {
              "title": 'ఓం అష్టమూర్తయే నమః',
           },
           {
              "title": 'ఓం అనేకాత్మనే నమః',
           },
           {
              "title": 'ఓం సాత్వికాయ నమః',
           },
           {
              "title": 'ఓం శుద్ధవిగ్రహాయ నమః',
           },
           {
              "title": 'ఓం శాశ్వతాయ నమః',
           },
           {
              "title": 'ఓం ఖండపరశవే నమః',
           },
           {
              "title": 'ఓం అజాయ నమః',
           },
           {
              "title": 'ఓం పాశవిమోచకాయ నమః || 90 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మృడాయ నమః',
           },
           {
              "title": 'ఓం పశుపతయే నమః',
           },
           {
              "title": 'ఓం దేవాయ నమః',
           },
           {
              "title": 'ఓం మహాదేవాయ నమః',
           },
           {
              "title": 'ఓం అవ్యయాయ నమః',
           },
           {
              "title": 'ఓం హరయే నమః',
           },
           {
              "title": 'ఓం పూషదంతభిదే నమః',
           },
           {
              "title": 'ఓం అవ్యగ్రాయ నమః',
           },
           {
              "title": 'ఓం దక్షాధ్వరహరాయ నమః',
           },
           {
              "title": 'ఓం హరాయ నమః || 100 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం భగనేత్రభిదే నమః',
           },
           {
              "title": 'ఓం అవ్యక్తాయ నమః',
           },
           {
              "title": 'ఓం సహస్రాక్షాయ నమః',
           },
           {
              "title": 'ఓం సహస్రపదే నమః',
           },
           {
              "title": 'ఓం అపవర్గప్రదాయ నమః',
           },
           {
              "title": 'ఓం అనంతాయ నమః',
           },
           {
              "title": 'ఓం తారకాయ నమః',
           },
           {
              "title": 'ఓం పరమేశ్వరాయ నమః || 108 ||',
           },
           {
              "title": '',
           },
           {
              "title": '|| ఇతి శ్రీ శివ అష్టోత్తర శతనామావళిః సంపూర్ణం ||',
           },
         ],
          "title": 'శ్రీ శివ అష్టోత్తరశతనామావళి ',
         // "img": require('../imgs/Astotaram/siva.png'),
       },
       {
          "astotaralu": [
           {
              "title": 'ఓం శ్రీ సుదర్శనాయ నమః',
           },
           {
              "title": 'ఓం చక్రరాజాయ నమః',
           },
           {
              "title": 'ఓం తేజోవ్యూహాయ నమః',
           },
           {
              "title": 'ఓం మహాద్యుతయే నమః',
           },
           {
              "title": 'ఓం సహస్రబాహవే నమః',
           },
           {
              "title": 'ఓం దీప్తాంగాయ నమః',
           },
           {
              "title": 'ఓం అరుణాక్షాయ నమః',
           },
           {
              "title": 'ఓం ప్రతాపవతే నమః',
           },
           {
              "title": 'ఓం అనేకాదిత్యసంకాశాయ నమః',
           },
           {
              "title": 'ఓం ప్రోద్యజ్జ్వాలాభిరంజితాయ నమః || 10 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం సౌదామినీ సహస్రాభాయ నమః',
           },
           {
              "title": 'ఓం మణికుండల శోభితాయ నమః',
           },
           {
              "title": 'ఓం పంచభూతమనోరూపాయ నమః',
           },
           {
              "title": 'ఓం షట్కోణాంతర సంస్థితాయ నమః',
           },
           {
              "title": 'ఓం హరాంతఃకరణోద్భూతరోష భీషణ విగ్రహాయ నమః',
           },
           {
              "title": 'ఓం హరిపాణిలసత్పద్మవిహార మనోహరాయ నమః',
           },
           {
              "title": 'ఓం శ్రాకారరూపాయ నమః',
           },
           {
              "title": 'ఓం సర్వజ్ఞాయ నమః',
           },
           {
              "title": 'ఓం సర్వలోకార్చితప్రభవే నమః',
           },
           {
              "title": 'ఓం చతుర్దశసహస్రారాయ నమః || 20 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం చతుర్వేదమయాయ నమః',
           },
           {
              "title": 'ఓం అనలాయ నమః',
           },
           {
              "title": 'ఓం భక్తచాంద్రమస జ్యోతిషే నమః',
           },
           {
              "title": 'ఓం భవరోగ వినాశకాయ నమః',
           },
           {
              "title": 'ఓం రేఫాత్మకాయ నమః',
           },
           {
              "title": 'ఓం మకారాయ నమః',
           },
           {
              "title": 'ఓం రక్షోసృగ్రూషితాంగాయ నమః',
           },
           {
              "title": 'ఓం సర్వదైత్యగ్రీవానాల విభేదన మహాగజాయ నమః',
           },
           {
              "title": 'ఓం భీమ దంష్ట్రాయ నమః',
           },
           {
              "title": 'ఓం ఉజ్జ్వలాకారాయ నమః || 30 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం భీమకర్మణే నమః',
           },
           {
              "title": 'ఓం త్రిలోచనాయ నమః',
           },
           {
              "title": 'ఓం నీలవర్త్మనే నమః',
           },
           {
              "title": 'ఓం నిత్యసుఖాయ నమః',
           },
           {
              "title": 'ఓం నిర్మలశ్రియై నమః',
           },
           {
              "title": 'ఓం నిరంజనాయ నమః',
           },
           {
              "title": 'ఓం రక్తమాల్యాంబరధరాయ నమః',
           },
           {
              "title": 'ఓం రక్తచందన రూషితాయ నమః',
           },
           {
              "title": 'ఓం రజోగుణాకృతయే నమః',
           },
           {
              "title": 'ఓం శూరాయ నమః || 40 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం రక్షఃకుల యమోపమాయ నమః',
           },
           {
              "title": 'ఓం నిత్య క్షేమకరాయ నమః',
           },
           {
              "title": 'ఓం ప్రాజ్ఞాయ నమః',
           },
           {
              "title": 'ఓం పాషండజన ఖండనాయ నమః',
           },
           {
              "title": 'ఓం నారాయణాజ్ఞానువర్తినే నమః',
           },
           {
              "title": 'ఓం నైగమాంతః ప్రకాశకాయ నమః',
           },
           {
              "title": 'ఓం బలినందనదోర్దండఖండనాయ నమః',
           },
           {
              "title": 'ఓం విజయాకృతయే నమః',
           },
           {
              "title": 'ఓం మిత్రభావినే నమః',
           },
           {
              "title": 'ఓం సర్వమయాయ నమః || 50 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం తమో విధ్వంసకాయ నమః',
           },
           {
              "title": 'ఓం రజస్సత్త్వతమోద్వర్తినే నమః',
           },
           {
              "title": 'ఓం త్రిగుణాత్మనే నమః',
           },
           {
              "title": 'ఓం త్రిలోకధృతే నమః',
           },
           {
              "title": 'ఓం హరిమాయగుణోపేతాయ నమః',
           },
           {
              "title": 'ఓం అవ్యయాయ నమః',
           },
           {
              "title": 'ఓం అక్షస్వరూపభాజే నమః',
           },
           {
              "title": 'ఓం పరమాత్మనే నమః',
           },
           {
              "title": 'ఓం పరం జ్యోతిషే నమః',
           },
           {
              "title": 'ఓం పంచకృత్య పరాయణాయ నమః || 60 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం జ్ఞానశక్తి బలైశ్వర్య వీర్య తేజః ప్రభామయాయ నమః',
           },
           {
              "title": 'ఓం సదసత్ పరమాయ నమః',
           },
           {
              "title": 'ఓం పూర్ణాయ నమః',
           },
           {
              "title": 'ఓం వాఙ్మయాయ నమః',
           },
           {
              "title": 'ఓం వరదాయ నమః',
           },
           {
              "title": 'ఓం అచ్యుతాయ నమః',
           },
           {
              "title": 'ఓం జీవాయ నమః',
           },
           {
              "title": 'ఓం గురవే నమః',
           },
           {
              "title": 'ఓం హంసరూపాయ నమః',
           },
           {
              "title": 'ఓం పంచాశత్పీఠ రూపకాయ నమః || 70 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం మాతృకామండలాధ్యక్షాయ నమః',
           },
           {
              "title": 'ఓం మధు ధ్వంసినే నమః',
           },
           {
              "title": 'ఓం మనోమయాయ నమః',
           },
           {
              "title": 'ఓం బుద్ధిరూపాయ నమః',
           },
           {
              "title": 'ఓం చిత్తసాక్షిణే నమః',
           },
           {
              "title": 'ఓం సారాయ నమః',
           },
           {
              "title": 'ఓం హంసాక్షరద్వయాయ నమః',
           },
           {
              "title": 'ఓం మంత్ర యంత్ర ప్రభావజ్ఞాయ నమః',
           },
           {
              "title": 'ఓం మంత్ర యంత్రమయాయ నమః',
           },
           {
              "title": 'ఓం విభవే నమః || 80 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం స్రష్ట్రే నమః',
           },
           {
              "title": 'ఓం క్రియాస్పదాయ నమః',
           },
           {
              "title": 'ఓం శుద్ధాయ నమః',
           },
           {
              "title": 'ఓం ఆధారాయ నమః',
           },
           {
              "title": 'ఓం చక్ర రూపకాయ నమః',
           },
           {
              "title": 'ఓం నిరాయుధాయ నమః',
           },
           {
              "title": 'ఓం అసంరంభాయ నమః',
           },
           {
              "title": 'ఓం సర్వాయుధ సమన్వితాయ నమః',
           },
           {
              "title": 'ఓం ఓంకార రూపిణే నమః',
           },
           {
              "title": 'ఓం పూర్ణాత్మనే నమః || 90 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం ఆంకారస్సాధ్య బంధనాయ నమః',
           },
           {
              "title": 'ఓం ఐంకారాయ నమః',
           },
           {
              "title": 'ఓం వాక్ప్రదాయ నమః',
           },
           {
              "title": 'ఓం వాగ్మినే నమః',
           },
           {
              "title": 'ఓం శ్రీంకారైశ్వర్య వర్ధనాయ నమః',
           },
           {
              "title": 'ఓం క్లీంకార మోహనాకారాయ నమః',
           },
           {
              "title": 'ఓం హుంఫట్క్షోభణాకృతయే నమః',
           },
           {
              "title": 'ఓం ఇంద్రార్చిత మనోవేగాయ నమః',
           },
           {
              "title": 'ఓం ధరణీభార నాశకాయ నమః',
           },
           {
              "title": 'ఓం వీరారాధ్యాయ నమః || 100 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'ఓం విశ్వరూపాయ నమః',
           },
           {
              "title": 'ఓం వైష్ణవాయ నమః',
           },
           {
              "title": 'ఓం విష్ణు రూపకాయ నమః',
           },
           {
              "title": 'ఓం సత్యవ్రతాయ నమః',
           },
           {
              "title": 'ఓం సత్యపరాయ నమః',
           },
           {
              "title": 'ఓం సత్యధర్మానుషంగకాయ నమః',
           },
           {
              "title": 'ఓం నారాయణకృపావ్యూహతేజశ్చక్రాయ నమః',
           },
           {
              "title": 'ఓం సుదర్శనాయ నమః || 108 ||',
           },
           {
              "title": '',
           },
           {
              "title": 'శ్రీ విజయలక్ష్మీ సమేత శ్రీ సుదర్శన పరబ్రహ్మణే నమః',
           },
           {
              "title": '॥ శ్రీ సుదర్శనాష్టోత్తర శతనామావళి సంపూర్ణము ॥',
           },
           {
              "title": '',
           },
         ],
          "title": 'సుదర్శన అష్టోత్తర శత నామావళి',
         // "img": require('../imgs/Astotaram/Sudharsana.png'),
       }
    ]
   },
    
    "Slokalu": {
      "CatID": [
        {
          "slokalu": [
            {
              "title": "@శుక్లాంబరధరం"
            },
            {
              "title": "శుక్లాంబరధరం విష్ణుం శశివర్ణం చతుర్భుజం"
            },
            {
              "title": "ప్రసన్న వదనం ధ్యాయేత్ సర్వ విఘ్నోపశాంతయే"
            },
            {
              "title": "@అగజానన పద్మార్కం"
            },
            {
              "title": "అగజానన పద్మార్కం గజాననమహర్నిశం"
            },
            {
              "title": "అనేక దం తం భక్తానాం ఏకదంతముపాస్మహే"
            },
            {
              "title": "@శ్రీ వక్రతుండ మహాకాయ"
            },
            {
              "title": "శ్రీ వక్రతుండ మహాకాయ కోటిసూర్య సమప్రభ"
            },
            {
              "title": "నిర్విఘ్నం కురు మే దేవ సర్వ (శుభ) కార్యేషు సర్వదా"
            },
            {
              "title": "@గజాననం భూతగణాధి"
            },
            {
              "title": "గజాననం భూతగణాధిసేవితం కపిత్థం, జంబు ఫలసార భక్షణం"
            },
            {
              "title": "ఉమాసుతం శోకవినాశ కారణం నమామి విఘ్నేశ్వర పాద పంకజం"
            },
            {
              "title": "@మూషికవాహన"
            },
            {
              "title": "మూషికవాహన మోదకహస్త చామరకర్ణ విలంబిత సూత్ర"
            },
            {
              "title": "పార్వతి నందన మహేశ్వరపుత్ర విఘ్నవినాయక పాద నమస్తే"
            }
          ],
          "title": "గణేశ శ్లోకములు",
         //  "img": require('../imgs/Slokamulu/Vinayaka.png')
        },
        {
          "slokalu": [
            {
              "title": "@సరస్వతి నమస్తుభ్యం"
            },
            {
              "title": "సరస్వతి నమస్తుభ్యం వరదే కామరూపిణి"
            },
            {
              "title": "విద్యారంభం కరిష్యామి సిద్ధిర్భవతు మే సదా"
            },
            {
              "title": "@పద్మపత్ర విశాలాక్షి"
            },
            {
              "title": "పద్మపత్ర విశాలాక్షి పద్మకేసర వర్ణిని"
            },
            {
              "title": "నిత్యం పద్మాలయా దేవి సామాంపాతు సరస్వతి"
            }
          ],
          "title": "సరస్వతి శ్లోకములు",
         //  "img": require('../imgs/Slokamulu/Saraswathi.png')
        },
        {
          "slokalu": [
            {
              "title": "@గురుర్ బ్రహ్మ"
            },
            {
              "title": "గురుర్ బ్రహ్మ గురుర్ విష్ణుః గురుదేవో మహేశ్వరః"
            },
            {
              "title": "గురుస్సాక్షాత్ పరబ్రహ్మ తస్మై శ్రీ గురవేనమః"
            },
            {
              "title": "@లక్ష్మీదేవి శ్లోకం"
            },
            {
              "title": "లక్ష్మీం క్షీరసముద్రరాజ తనయాం శ్రీరంగ ధామేశ్వరీం"
            },
            {
              "title": "దాసీభూత సమస్తదేవ వనితాం లోకైక దీపాంకురాం"
            },
            {
              "title": "శ్రీమన్మంద కటాక్షలబ్ధ విభవత్ బ్రహ్మేంద్ర గంగాధరాం"
            },
            {
              "title": "త్వాం త్రైలోక్య కుటుంబినీం సరసిజాం వందే ముకుందప్రియాం"
            },
            {
              "title": "@దేవి/దుర్గా శ్లోకం"
            },
            {
              "title": "సర్వమంగళ మాంగల్యే శివే సర్వార్థ సాధికే"
            },
            {
              "title": "శరణ్యే త్రయంబకే గౌరీ నారాయణి నమోస్తుతే"
            },
            {
              "title": "@ఓంకార మంత్రం"
            },
            {
              "title": "ఓంకారం బిందు సంయుక్తం నిత్యం ధ్యాయంతి యోగినః"
            },
            {
              "title": "కామదం మోక్షదం చైవ ఓంకారాయ నమోనమః"
            },
            {
              "title": "@గాయత్రి మంత్రం"
            },
            {
              "title": "ఓం భూర్ భువస్వహ తత్స వితుర్వరేణ్యం"
            },
            {
              "title": "భర్గో దేవస్య ధీమహి ధియోయోన: ప్రచోదయాత్!!"
            }
          ],
          "title": "గురు శ్లోకములు",
         //  "img": require('../imgs/Slokamulu/Adisankara.png')
        },
        {
          "slokalu": [
            {
              "title": "@శ్రీరామ రామ రామేతి"
            },
            {
              "title": "శ్రీరామ రామ రామేతి రమేరామే మనోరమే"
            },
            {
              "title": "సహస్రనామ తత్తుల్యం రామనామ వరాననే"
            },
            {
              "title": "(మూడు సార్లు జపించాలి)"
            },
            {
              "title": "శ్రీ-రామనామ వరాననే ఓం నమః ఇతి"
            },
            {
              "title": "@శ్రీ రాఘవం"
            },
            {
              "title": "శ్రీరాఘవం దశరథాత్మజ మప్రమేయం"
            },
            {
              "title": "సీతాపతిం రఘుకులాన్వయ రత్నదీపం"
            },
            {
              "title": "ఆజానుబాహుం అరవింద దళాయతాక్షం"
            },
            {
              "title": "రామం నిశాచర వినాశకరం నమామి"
            },
            {
              "title": "@ఆపదామప హత్తారం"
            },
            {
              "title": "ఆపదామప హత్తారం దాతారం సర్వ సంపదః"
            },
            {
              "title": "లోకాభిరామం శ్రీరామం భూయో భూయో నమామ్యహం"
            }
          ],
          "title": "శ్రీరామ శ్లోకములు",
         //  "img": require('../imgs/Slokamulu/Rama.png')
        },
        {
          "slokalu": [
            {
              "title": "@వసుదేవసుతం దేవం"
            },
            {
              "title": "వసుదేవసుతం దేవం కంస చాణూర మర్దనం"
            },
            {
              "title": "దేవకీ పరమానందం కృష్ణం వందే జగద్గురుం"
            }
          ],
          "title": "శ్రీకృష్ణ శ్లోకములు",
         //  "img": require('../imgs/Slokamulu/Krishna.png')
        },
        {
          "slokalu": [
            {
              "title": "@శాంతాకారం భుజగ"
            },
            {
              "title": "శాంతాకారం భుజగశయనం పద్మనాభం సురేశం"
            },
            {
              "title": "విశ్వాధారం గగన సదృశం మేఘవర్ణం శుభాంగం"
            },
            {
              "title": "లక్ష్మీకాంతం కమలనయనం యోగి హృద్యానగమ్యం"
            },
            {
              "title": "వందే విష్ణుం భవభయహరం సర్వ లోకైకనాథం"
            },
            {
              "title": "@వనమాలీ గదీశార్‌ఙ్గీ"
            },
            {
              "title": "వనమాలీ గదీశార్‌ఙ్గీ శంఖీ చక్రీచ నందకి"
            },
            {
              "title": "శ్రీమన్నారాయణో విష్ణుర్ వాసుదేవోభి రక్షతు"
            },
            {
              "title": "(మూడు సార్లు జపించాలి)"
            },
            {
              "title": "శ్రీ-వాసుదేవోభి రక్షతు ఓం నమ:ఇతి"
            }
          ],
          "title": "విష్ణు శ్లోకములు",
         //  "img": require('../imgs/Slokamulu/Vishnu.png')
        },
        {
          "slokalu": [
            {
              "title": "@మనోజవం మారుత"
            },
            {
              "title": "మనోజవం మారుతతుల్య వేగం జితేంద్రియం బుద్ధిమతాంవరిష్టం"
            },
            {
              "title": "వాతాత్మజం వానరయూధముఖ్యం శ్రీరామదూతం శిరసానమామి"
            },
            {
              "title": "@యత్ర యత్ర రఘునాథ"
            },
            {
              "title": "యత్రయత్ర రఘునాథ కీర్తనం తత్ర తత్ర కృతమస్తకాంజలిం"
            },
            {
              "title": "భాష్పవారి పరిపూర్ణలోచనం మారుతిం నమత రాక్షసాంతకం"
            }
          ],
          "title": "హనుమాన్ శ్లోకములు",
         //  "img": require('../imgs/Slokamulu/Hanuman.png')
        },
        {
          "slokalu": [
            {
              "title": "ఓం త్రయంబకం యజామహే సుగంధిం పుష్టివర్ధనం"
            },
            {
              "title": "ఉర్వారుకమివ బంధనాన్ మృత్యోర్ ముక్షీయ మామృతాత్"
            },
            {
              "title": "(మూడుసార్లు గాని, మూడుకి రెట్టింపుసార్లు గాని పఠించాలి)"
            }
          ],
          "title": "మహామృత్యుంజయ మంత్రం",
         //  "img": require('../imgs/Slokamulu/Siva.png')
        },
        {
          "slokalu": [
            {
              "title": "వినా వేంకటేశం ననాథో ననాథః"
            },
            {
              "title": "సదా వేంకటేశం స్మరామి స్మరామి"
            },
            {
              "title": "హరే వేంకటేశం ప్రసీద ప్రసీద"
            },
            {
              "title": "ప్రియం వేంకటేశం ప్రయచ్ఛ ప్రయచ్ఛ"
            }
          ],
          "title": "శ్రీవేంకటేశ్వర శ్లోకము",
         //  "img": require('../imgs/Slokamulu/VenkateswaraSwami.png')
        },
        {
          "slokalu": [
            {
              "title": "ఓం హ్రీం క్లీం శ్రీం శివాయ బ్రహ్మణే నమ:"
            },
            {
              "title": "(12000 సార్లు స్మరించినచో శ్రీ శ్రీ శ్రీ విరాట్ పోతులూరి వీర బ్రహ్మేంద్ర స్వామి వారు కలలో కనపడతారు అని కాలజ్ఞానం లో చెప్పబడినది.)"
            }
          ],
          "title": "శ్రీ పోతులూరి వీరబ్రహ్మేంద్ర స్వామి",
         //  "img": require('../imgs/Slokamulu/PotuluriVeerabramendraswami.png')
        },
        {
          "slokalu": [
            {
              "title": "ఆదిత్యాయచ సోమాయ, మంగళాయ బుధాయచ"
            },
            {
              "title": "గురు శుక్ర శనిభ్యశ్చ రాహవే కేతవే నమః"
            }
          ],
          "title": "నవగ్రహ శ్లోకము",
         //  "img": require('../imgs/Slokamulu/Navagrahalu.png')
        },
        {
          "slokalu": [
            {
              "title": "యన్మూలే సర్వ తీర్థాణి యన్మధ్యే సర్వ దేవతాః"
            },
            {
              "title": "యదగ్రే సర్వవేదాశ్చ తులసీం త్వాం నమామ్యహం"
            }
          ],
          "title": "తులసీ శ్లోకము",
         //  "img": require('../imgs/Slokamulu/Tulasi.png')
        },
        {
          "slokalu": [
            {
              "title": "అసతోమా సద్గమయ"
            },
            {
              "title": "తమసోమా జ్యోతిర్గమయ"
            },
            {
              "title": "మృత్యోర్మా అమృతంగమయ"
            },
            {
              "title": "ఓం శాంతిః శాంతిః శాంతిః"
            }
          ],
          "title": "నామోచ్చరణ",
         //  "img": require('../imgs/Slokamulu/Namocharana.png')
        },
        {
          "slokalu": [
            {
              "title": "@దోహా"
            },
            {
              "title": "శ్రీ గురు చరణ సరోజ రజ నిజమన ముకుర సుధారి ।"
            },
            {
              "title": "వరణౌ రఘువర విమలయశ జో దాయక ఫలచారి ॥"
            },
            {
              "title": "బుద్ధిహీన తనుజానికై సుమిరౌ పవన కుమార ।"
            },
            {
              "title": "బల బుద్ధి విద్యా దేహు మోహి హరహు కలేశ వికార ॥"
            },
            {
              "title": "@ధ్యానం"
            },
            {
              "title": "గోష్పదీకృత వారాశిం మశకీకృత రాక్షసమ్ ।"
            },
            {
              "title": "రామాయణ మహామాలా రత్నం వందే-(అ)నిలాత్మజమ్ ॥"
            },
            {
              "title": "యత్ర యత్ర రఘునాథ కీర్తనం తత్ర తత్ర కృతమస్తకాంజలిమ్ ।"
            },
            {
              "title": "భాష్పవారి పరిపూర్ణ లోచనం మారుతిం నమత రాక్షసాంతకమ్ ॥"
            },
            {
              "title": "@చౌపాఈ"
            },
            {
              "title": "జయ హనుమాన జ్ఞాన గుణ సాగర ।"
            },
            {
              "title": "జయ కపీశ తిహు లోక ఉజాగర ॥ 1 ॥"
            },
            {
              "title": "రామదూత అతులిత బలధామా ।"
            },
            {
              "title": "అంజని పుత్ర పవనసుత నామా ॥ 2 ॥"
            },
            {
              "title": "మహావీర విక్రమ బజరంగీ ।"
            },
            {
              "title": "కుమతి నివార సుమతి కే సంగీ ॥3 ॥"
            },
            {
              "title": "కంచన వరణ విరాజ సువేశా ।"
            },
            {
              "title": "కానన కుండల కుంచిత కేశా ॥ 4 ॥"
            },
            {
              "title": "హాథవజ్ర ఔ ధ్వజా విరాజై ।"
            },
            {
              "title": "కాంథే మూంజ జనేవూ సాజై ॥ 5॥"
            },
            {
              "title": "శంకర సువన కేసరీ నందన ।"
            },
            {
              "title": "తేజ ప్రతాప మహాజగ వందన ॥ 6 ॥"
            },
            {
              "title": "విద్యావాన గుణీ అతి చాతుర ।"
            },
            {
              "title": "రామ కాజ కరివే కో ఆతుర ॥ 7 ॥"
            },
            {
              "title": "ప్రభు చరిత్ర సునివే కో రసియా ।"
            },
            {
              "title": "రామలఖన సీతా మన బసియా ॥ 8॥"
            },
            {
              "title": "సూక్ష్మ రూపధరి సియహి దిఖావా ।"
            },
            {
              "title": "వికట రూపధరి లంక జలావా ॥ 9 ॥"
            },
            {
              "title": "భీమ రూపధరి అసుర సంహారే ।"
            },
            {
              "title": "రామచంద్ర కే కాజ సంవారే ॥ 10 ॥"
            },
            {
              "title": "లాయ సంజీవన లఖన జియాయే ।"
            },
            {
              "title": "శ్రీ రఘువీర హరషి ఉరలాయే ॥ 11 ॥"
            },
            {
              "title": "రఘుపతి కీన్హీ బహుత బడాయీ ।"
            },
            {
              "title": "తుమ మమ ప్రియ భరత సమ భాయీ ॥ 12 ॥"
            },
            {
              "title": "సహస్ర వదన తుమ్హరో యశగావై ।"
            },
            {
              "title": "అస కహి శ్రీపతి కంఠ లగావై ॥ 13 ॥"
            },
            {
              "title": "సనకాదిక బ్రహ్మాది మునీశా ।"
            },
            {
              "title": "నారద శారద సహిత అహీశా ॥ 14 ॥"
            },
            {
              "title": "యమ కుబేర దిగపాల జహాం తే ।"
            },
            {
              "title": "కవి కోవిద కహి సకే కహాం తే ॥ 15 ॥"
            },
            {
              "title": "తుమ ఉపకార సుగ్రీవహి కీన్హా ।"
            },
            {
              "title": "రామ మిలాయ రాజపద దీన్హా ॥ 16 ॥"
            },
            {
              "title": "తుమ్హరో మంత్ర విభీషణ మానా ।"
            },
            {
              "title": "లంకేశ్వర భయే సబ జగ జానా ॥ 17 ॥"
            },
            {
              "title": "యుగ సహస్ర యోజన పర భానూ ।"
            },
            {
              "title": "లీల్యో తాహి మధుర ఫల జానూ ॥ 18 ॥"
            },
            {
              "title": "ప్రభు ముద్రికా మేలి ముఖ మాహీ ।"
            },
            {
              "title": "జలధి లాంఘి గయే అచరజ నాహీ ॥ 19 ॥"
            },
            {
              "title": "దుర్గమ కాజ జగత కే జేతే ।"
            },
            {
              "title": "సుగమ అనుగ్రహ తుమ్హరే తేతే ॥ 20 ॥"
            },
            {
              "title": "రామ దుఆరే తుమ రఖవారే ।"
            },
            {
              "title": "హోత న ఆజ్ఞా బిను పైసారే ॥ 21 ॥"
            },
            {
              "title": "సబ సుఖ లహై తుమ్హారీ శరణా ।"
            },
            {
              "title": "తుమ రక్షక కాహూ కో డర నా ॥ 22 ॥"
            },
            {
              "title": "ఆపన తేజ సమ్హారో ఆపై ।"
            },
            {
              "title": "తీనోం లోక హాంక తే కాంపై ॥ 23 ॥"
            },
            {
              "title": "భూత పిశాచ నికట నహి ఆవై ।"
            },
            {
              "title": "మహవీర జబ నామ సునావై ॥ 24 ॥"
            },
            {
              "title": "నాసై రోగ హరై సబ పీరా ।"
            },
            {
              "title": "జపత నిరంతర హనుమత వీరా ॥ 25 ॥"
            },
            {
              "title": "సంకట సే హనుమాన ఛుడావై ।"
            },
            {
              "title": "మన క్రమ వచన ధ్యాన జో లావై ॥ 26 ॥"
            },
            {
              "title": "సబ పర రామ తపస్వీ రాజా ।"
            },
            {
              "title": "తినకే కాజ సకల తుమ సాజా ॥ 27 ॥"
            },
            {
              "title": "ఔర మనోరధ జో కోయి లావై ।"
            },
            {
              "title": "తాసు అమిత జీవన ఫల పావై ॥ 28 ॥"
            },
            {
              "title": "చారో యుగ ప్రతాప తుమ్హారా ।"
            },
            {
              "title": "హై ప్రసిద్ధ జగత ఉజియారా ॥ 29 ॥"
            },
            {
              "title": "సాధు సంత కే తుమ రఖవారే ।"
            },
            {
              "title": "అసుర నికందన రామ దులారే ॥ 30 ॥"
            },
            {
              "title": "అష్ఠసిద్ధి నవ నిధి కే దాతా ।"
            },
            {
              "title": "అస వర దీన్హ జానకీ మాతా ॥ 31 ॥"
            },
            {
              "title": "రామ రసాయన తుమ్హారే పాసా ।"
            },
            {
              "title": "సదా రహో రఘుపతి కే దాసా ॥ 32 ॥"
            },
            {
              "title": "తుమ్హరే భజన రామకో పావై ।"
            },
            {
              "title": "జన్మ జన్మ కే దుఖ బిసరావై ॥ 33 ॥"
            },
            {
              "title": "అంత కాల రఘుపతి పురజాయీ ।"
            },
            {
              "title": "జహాం జన్మ హరిభక్త కహాయీ ॥ 34 ॥"
            },
            {
              "title": "ఔర దేవతా చిత్త న ధరయీ ।"
            },
            {
              "title": "హనుమత సేయి సర్వ సుఖ కరయీ ॥ 35 ॥"
            },
            {
              "title": "సంకట క(హ)టై మిటై సబ పీరా ।"
            },
            {
              "title": "జో సుమిరై హనుమత బల వీరా ॥ 36 ॥"
            },
            {
              "title": "జై జై జై హనుమాన గోసాయీ ।"
            },
            {
              "title": "కృపా కరహు గురుదేవ కీ నాయీ ॥ 37 ॥"
            },
            {
              "title": "జో శత వార పాఠ కర కోయీ ।"
            },
            {
              "title": "ఛూటహి బంది మహా సుఖ హోయీ ॥ 38 ॥"
            },
            {
              "title": "జో యహ పడై హనుమాన చాలీసా ।"
            },
            {
              "title": "హోయ సిద్ధి సాఖీ గౌరీశా ॥ 39 ॥"
            },
            {
              "title": "తులసీదాస సదా హరి చేరా ।"
            },
            {
              "title": "కీజై నాథ హృదయ మహ డేరా ॥ 40 ॥"
            },
            {
              "title": "@దోహా"
            },
            {
              "title": "పవన తనయ సంకట హరణ - మంగళ మూరతి రూప్ ।"
            },
            {
              "title": "రామ లఖన సీతా సహిత - హృదయ బసహు సురభూప్ ॥"
            },
            {
              "title": "సియావర రామచంద్రకీ జయ । పవనసుత హనుమానకీ జయ । బోలో భాయీ సబ సంతనకీ జయ ।"
            }
          ],
          "title": "హనుమాన్ చాలీసా",
         //  "img": require('../imgs/Slokamulu/Hanuman.png')
        },
        {
          "slokalu": [
            {
              "title": "@ప్రభాత శ్లోకః"
            },
            {
              "title": "కరాగ్రే వసతే లక్ష్మీః కరమధ్యే సరస్వతీ ।"
            },
            {
              "title": "కరమూలే స్థితా గౌరీ ప్రభాతే కరదర్శనమ్ ॥"
            },
            {
              "title": "[పాఠభేదః - కరమూలే తు గోవిందః ప్రభాతే కరదర్శనమ్ ॥]"
            },
            {
              "title": "@ప్రభాత భూమి శ్లోకః"
            },
            {
              "title": "సముద్ర వసనే దేవీ పర్వత స్తన మండలే ।"
            },
            {
              "title": "విష్ణుపత్ని నమస్తుభ్యం, పాదస్పర్శం క్షమస్వమే ॥"
            },
            {
              "title": "@సూర్యోదయ శ్లోకః"
            },
            {
              "title": "బ్రహ్మస్వరూప ముదయే మధ్యాహ్నేతు మహేశ్వరమ్ ।"
            },
            {
              "title": "సాహం ధ్యాయేత్సదా విష్ణుం త్రిమూర్తిం చ దివాకరమ్ ॥"
            },
            {
              "title": "@స్నాన శ్లోకః"
            },
            {
              "title": "గంగే చ యమునే చైవ గోదావరీ సరస్వతీ"
            },
            {
              "title": "నర్మదే సింధు కావేరీ జలేస్మిన్ సన్నిధిం కురు ॥"
            },
            {
              "title": "@నమస్కార శ్లోకః"
            },
            {
              "title": "త్వమేవ మాతా చ పితా త్వమేవ, త్వమేవ బంధుశ్చ సఖా త్వమేవ ।"
            },
            {
              "title": "త్వమేవ విద్యా ద్రవిణం త్వమేవ, త్వమేవ సర్వం మమ దేవదేవ ॥"
            },
            {
              "title": "@భస్మ ధారణ శ్లోకః"
            },
            {
              "title": "శ్రీకరం చ పవిత్రం చ శోక నివారణమ్ ।"
            },
            {
              "title": "లోకే వశీకరం పుంసాం భస్మం త్ర్యైలోక్య పావనమ్ ॥"
            },
            {
              "title": "@భోజన పూర్వ శ్లోకాః"
            },
            {
              "title": "బ్రహ్మార్పణం బ్రహ్మ హవిః బ్రహ్మాగ్నౌ బ్రహ్మణాహుతమ్ ।"
            },
            {
              "title": "బ్రహ్మైవ తేన గంతవ్యం బ్రహ్మ కర్మ సమాధినః ॥"
            },
            {
              "title": "అహం వైశ్వానరో భూత్వా ప్రాణినాం దేహమాశ్రితః ।"
            },
            {
              "title": "ప్రాణాపాన సమాయుక్తః పచామ్యన్నం చతుర్విధమ్ ॥"
            },
            {
              "title": "అన్నపూర్ణే సదా పూర్ణే శంకరప్రాణవల్లభే ।"
            },
            {
              "title": "జ్ఞానవైరాగ్య సిద్ధ్యర్థం భిక్షాం దేహి చ పార్వతి ॥"
            },
            {
              "title": "త్వదీయం వస్తు గోవింద తుభ్యమేవ సమర్పయే ।"
            },
            {
              "title": "గృహాణ సుముఖో భూత్వా ప్రసీద పరమేశ్వర ॥"
            },
            {
              "title": "@భోజనానంతర శ్లోకః"
            },
            {
              "title": "అగస్త్యం వైనతేయం చ శమీం చ బడబాలనమ్ ।"
            },
            {
              "title": "ఆహార పరిణామార్థం స్మరామి చ వృకోదరమ్ ॥"
            },
            {
              "title": "@సంధ్యా దీప దర్శన శ్లోకః"
            },
            {
              "title": "దీపజ్యోతిః పరం బ్రహ్మ దీపజ్యోతిర్జనార్దనః ।"
            },
            {
              "title": "దీపో హరతు మే పాపం దీపజ్యోతిర్నమోఽస్తుతే ॥"
            },
            {
              "title": "శుభం కరోతి కళ్యాణం ఆరోగ్యం ధనసంపదః ।"
            },
            {
              "title": "శత్రు-బుద్ధి-వినాశాయ దీపజ్యోతిర్నమోఽస్తుతే ॥"
            },
            {
              "title": "@నిద్రా శ్లోకః"
            },
            {
              "title": "రామం స్కంధం హనుమంతం వైనతేయం వృకోదరమ్ ।"
            },
            {
              "title": "శయనే యః స్మరేన్నిత్యం దుస్వప్న-స్తస్యనశ్యతి ॥"
            },
            {
              "title": "@అపరాధ క్షమాపణ స్తోత్రం"
            },
            {
              "title": "అపరాధ సహస్రాణి, క్రియంతేఽహర్నిశం మయా ।"
            },
            {
              "title": "దాసోఽయమితి మాం మత్వా, క్షమస్వ పరమేశ్వర ॥"
            },
            {
              "title": "కరచరణ కృతం వా కర్మ వాక్కాయజం వా"
            },
            {
              "title": "శ్రవణ నయనజం వా మానసం వాపరాధమ్ ।"
            },
            {
              "title": "విహిత మవిహితం వా సర్వమేతత్ క్షమస్వ"
            },
            {
              "title": "శివ శివ కరుణాబ్ధే శ్రీ మహాదేవ శంభో ॥"
            },
            {
              "title": "కాయేన వాచా మనసేంద్రియైర్వా"
            },
            {
              "title": "బుద్ధ్యాత్మనా వా ప్రకృతేః స్వభావాత్ ।"
            },
            {
              "title": "కరోమి యద్యత్సకలం పరస్మై"
            },
            {
              "title": "నారాయణాయేతి సమర్పయామి ॥"
            },
            {
              "title": "@దేవతా స్తోత్రాః"
            },
            {
              "title": "@కార్య ప్రారంభ స్తోత్రాః"
            },
            {
              "title": "శుక్లాం బరధరం విష్ణుం శశివర్ణం చతుర్భుజమ్ ।"
            },
            {
              "title": "ప్రసన్నవదనం ధ్యాయేత్ సర్వ విఘ్నోపశాంతయే ॥"
            },
            {
              "title": "యస్యద్విరద వక్త్రాద్యాః పారిషద్యాః పరశ్శతమ్ ।"
            },
            {
              "title": "విఘ్నం నిఘ్నంతు సతతం విష్వక్సేనం తమాశ్రయే ॥"
            },
            {
              "title": "@గణేశ స్తోత్రం"
            },
            {
              "title": "వక్రతుండ మహాకాయ సూర్యకోటి సమప్రభః ।"
            },
            {
              "title": "నిర్విఘ్నం కురు మే దేవ సర్వ కార్యేషు సర్వదా ॥"
            },
            {
              "title": "అగజానన పద్మార్కం గజానన మహర్నిశమ్ ।"
            },
            {
              "title": "అనేకదం-తం భక్తానాం-ఏకదంత-ముపాస్మహే ॥"
            },
            {
              "title": "@విష్ణు స్తోత్రం"
            },
            {
              "title": "శాంతాకారం భుజగశయనం పద్మనాభం సురేశం"
            },
            {
              "title": "విశ్వాధారం గగన సదృశం మేఘవర్ణం శుభాంగమ్ ।"
            },
            {
              "title": "లక్ష్మీకాంతం కమలనయనం యోగిహృద్ధ్యానగమ్యం"
            },
            {
              "title": "వందే విష్ణుం భవభయహరం సర్వలోకైకనాథమ్ ॥"
            },
            {
              "title": "@గాయత్రి మంత్రం"
            },
            {
              "title": "ఓం భూర్భువస్వః । తత్స వితుర్వరేణ్యం ।"
            },
            {
              "title": "భర్గో దేవస్య ధీమహి । ధియోయోనఃప్రచోదయాత్ ॥"
            },
            {
              "title": "@శివ స్తోత్రం"
            },
            {
              "title": "ఓం త్రయంబకం యజామహే సుగంధిం పుష్టి వర్ధనం |"
            },
            {
              "title": "ఉర్వారుకమివ బంధనాన్ మృత్యోర్ ముక్షీయ మామృతాత్ ||"
            },
            {
              "title": "వందే శంభుముమాపతిం సురగురుం వందే జగత్కారణం"
            },
            {
              "title": "వందే పన్నగభూషణం మృగధరం వందే పశూనాంపతిం"
            },
            {
              "title": "వందే సూర్యశశాంకవహ్నినయనం వందే ముకుందప్రియం ।"
            },
            {
              "title": "వందే భక్తజనాశ్రయం చ వరదం వందే శివం శంకరమ్ ॥"
            },
            {
              "title": "@సుబ్రహ్మణ్య స్తోత్రం"
            },
            {
              "title": "శక్తిహస్తం విరూపాక్షం శిఖివాహం షడాననం"
            },
            {
              "title": "దారుణం రిపురోగఘ్నం భావయే కుక్కుట ధ్వజమ్ ।"
            },
            {
              "title": "స్కందం షణ్ముఖం దేవం శివతేజం చతుర్భుజం"
            },
            {
              "title": "కుమారం స్వామినాధం తం కార్తికేయం నమామ్యహమ్ ॥"
            },
            {
              "title": "@గురు శ్లోకః"
            },
            {
              "title": "గురుర్బ్రహ్మా గురుర్విష్ణుః గురుర్దేవో మహేశ్వరః ।"
            },
            {
              "title": "గురుః సాక్షాత్ పరబ్రహ్మా తస్మై శ్రీ గురవే నమః ॥"
            },
            {
              "title": "@హనుమ స్తోత్రాః"
            },
            {
              "title": "మనోజవం మారుత తుల్యవేగం జితేంద్రియం బుద్ధిమతాం వరిష్టమ్ ।"
            },
            {
              "title": "వాతాత్మజం వానరయూధ ముఖ్యం శ్రీరామదూతం శిరసా నమామి ॥"
            },
            {
              "title": "బుద్ధిర్బలం యశోధైర్యం నిర్భయత్వమరోగతా ।"
            },
            {
              "title": "అజాడ్యం వాక్పటుత్వం చ హనుమస్స్మరణాద్-భవేత్ ॥"
            },
            {
              "title": "జయత్యతి బలో రామో లక్ష్మణస్య మహాబలః ।"
            },
            {
              "title": "రాజా జయతి సుగ్రీవో రాఘవేణాభి పాలితః ॥"
            },
            {
              "title": "దాసోఽహం కోసలేంద్రస్య రామస్యాక్లిష్ట కర్మణః ।"
            },
            {
              "title": "హనుమాన్ శత్రుసైన్యానాం నిహంతా మారుతాత్మజః ॥"
            },
            {
              "title": "@శ్రీరామ స్తోత్రాం"
            },
            {
              "title": "శ్రీ రామ రామ రామేతి రమే రామే మనోరమే"
            },
            {
              "title": "సహస్రనామ తత్తుల్యం రామ నామ వరాననే"
            },
            {
              "title": "శ్రీ రామచంద్రః శ్రితపారిజాతః సమస్త కళ్యాణ గుణాభిరామః ।"
            },
            {
              "title": "సీతాముఖాంభోరుహాచంచరీకో నిరంతరం మంగళమాతనోతు ॥"
            },
            {
              "title": "@శ్రీకృష్ణ స్తోత్రం"
            },
            {
              "title": "మందారమూలే మదనాభిరామం"
            },
            {
              "title": "బింబాధరాపూరిత వేణునాదమ్ ।"
            },
            {
              "title": "గోగోప గోపీజన మధ్యసంస్థం"
            },
            {
              "title": "గోపం భజే గోకుల పూర్ణచంద్రమ్ ॥"
            },
            {
              "title": "@గరుడ స్వామి స్తోత్రం"
            },
            {
              "title": "కుంకుమాంకితవర్ణాయ కుందేందు ధవళాయ చ ।"
            },
            {
              "title": "విష్ణు వాహ నమస్తుభ్యం పక్షిరాజాయ తే నమః ॥"
            },
            {
              "title": "@దక్షిణామూర్తి స్తోత్రం"
            },
            {
              "title": "గురవే సర్వలోకానాం భిషజే భవరోగిణామ్ ।"
            },
            {
              "title": "నిధయే సర్వ విద్యానాం శ్రీ దక్షిణామూర్తయే నమ ॥"
            },
            {
              "title": "@సరస్వతీ శ్లోకః"
            },
            {
              "title": "సరస్వతీ నమస్తుభ్యం వరదే కామరూపిణీ ।"
            },
            {
              "title": "విద్యారంభం కరిష్యామి సిద్ధిర్భవతు మే సదా ॥"
            },
            {
              "title": "యా కుందేందు తుషార హార ధవళా, యా శుభ్ర వస్త్రావృతా ।"
            },
            {
              "title": "యా వీణా వరదండ మండిత కరా, యా శ్వేత పద్మాసనా ।"
            },
            {
              "title": "యా బ్రహ్మాచ్యుత శంకర ప్రభృతిభిర్-దేవైః సదా పూజితా ।"
            },
            {
              "title": "సా మాం పాతు సరస్వతీ భగవతీ నిశ్శేషజాడ్యాపహా ॥"
            },
            {
              "title": "@లక్ష్మీ శ్లోకః"
            },
            {
              "title": "లక్ష్మీం క్షీరసముద్ర రాజ తనయాం శ్రీరంగ ధామేశ్వరీమ్ ।"
            },
            {
              "title": "దాసీభూత సమస్త దేవ వనితాం లోకైక దీపాంకురామ్ ।"
            },
            {
              "title": "శ్రీమన్మంధ కటాక్ష లబ్ధ విభవ బ్రహ్మేంద్ర గంగాధరామ్ ।"
            },
            {
              "title": "త్వాం త్రైలోక్యకుటుంబినీం సరసిజాం వందే ముకుందప్రియామ్ ॥"
            },
            {
              "title": "@దుర్గా దేవీ స్తోత్రం"
            },
            {
              "title": "సర్వ స్వరూపే సర్వేశే సర్వ శక్తి సమన్వితే ।"
            },
            {
              "title": "భయేభ్యస్తాహి నో దేవి దుర్గాదేవి నమోస్తుతే ॥"
            },
            {
              "title": "@త్రిపురసుందరీ స్తోత్రం"
            },
            {
              "title": "ఓంకార పంజర శుకీం ఉపనిషదుద్యాన కేళి కలకంఠీమ్ ।"
            },
            {
              "title": "ఆగమ విపిన మయూరీం ఆర్యాం అంతర్విభావయేద్గౌరీమ్ ॥"
            },
            {
              "title": "@దేవీ శ్లోకః"
            },
            {
              "title": "సర్వ మంగల మాంగల్యే శివే సర్వార్థ సాధికే ।"
            },
            {
              "title": "శరణ్యే త్ర్యంబకే దేవి నారాయణి నమోస్తుతే ॥"
            },
            {
              "title": "@వేంకటేశ్వర శ్లోకః"
            },
            {
              "title": "శ్రియః కాంతాయ కళ్యాణనిధయే నిధయేఽర్థినామ్ ।"
            },
            {
              "title": "శ్రీ వేంకట నివాసాయ శ్రీనివాసాయ మంగళమ్ ॥"
            },
            {
              "title": "@దక్షిణామూర్తి శ్లోకః"
            },
            {
              "title": "గురవే సర్వలోకానాం భిషజే భవరోగిణామ్ ।"
            },
            {
              "title": "నిధయే సర్వవిద్యానాం దక్షిణామూర్తయే నమః ॥"
            },
            {
              "title": "@బౌద్ధ ప్రార్థన"
            },
            {
              "title": "బుద్ధం శరణం గచ్ఛామి"
            },
            {
              "title": "ధర్మం శరణం గచ్ఛామి"
            },
            {
              "title": "సంఘం శరణం గచ్ఛామి"
            },
            {
              "title": "@శాంతి మంత్రం"
            },
            {
              "title": "అసతోమా సద్గమయా ।"
            },
            {
              "title": "తమసోమా జ్యోతిర్గమయా ।"
            },
            {
              "title": "మృత్యోర్మా అమృతంగమయా ।"
            },
            {
              "title": "ఓం శాంతిః శాంతిః శాంతిః"
            },
            {
              "title": "సర్వే భవంతు సుఖినః సర్వే సంతు నిరామయాః ।"
            },
            {
              "title": "సర్వే భద్రాణి పశ్యంతు మా కశ్చిద్దుఃఖ భాగ్భవేత్ ॥"
            },
            {
              "title": "ఓం శాంతిః శాంతిః శాంతిః"
            },
            {
              "title": "@స్వస్తి మంత్రాః"
            },
            {
              "title": "స్వస్తి ప్రజాభ్యః పరిపాలయంతాం"
            },
            {
              "title": "న్యాయేన మార్గేణ మహీం మహీశాః ।"
            },
            {
              "title": "గోబ్రాహ్మణేభ్య-శ్శుభమస్తు నిత్యం"
            },
            {
              "title": "లోకా-స్సమస్తా-స్సుఖినో భవంతు ॥"
            },
            {
              "title": "కాలే వర్షతు పర్జన్యః పృథివీ సస్యశాలినీ ।"
            },
            {
              "title": "దేశోయం క్షోభరహితో బ్రాహ్మణాస్సంతు నిర్భయాః ॥"
            },
            {
              "title": "@విశేష మంత్రాః"
            },
            {
              "title": "పంచాక్షరీ మంత్రం - ఓం నమశ్శివాయ"
            },
            {
              "title": "అష్టాక్షరీ మంత్రం - ఓం నమో నారాయణాయ"
            },
            {
              "title": "ద్వాదశాక్షరీ మంత్రం - ఓం నమో భగవతే వాసుదేవాయ"
            }
          ],
          "title": "నిత్య పారాయణ శ్లోకాః",
         //  "img": require('../imgs/Slokamulu/Nityaparayanam.png')
        },
        {
          "slokalu": [
            {
              "title": "శ్రీ ఆంజనేయం ప్రసన్నాంజనేయం"
            },
            {
              "title": "ప్రభాదివ్యకాయం ప్రకీర్తి ప్రదాయం"
            },
            {
              "title": "భజే వాయుపుత్రం భజే వాలగాత్రం భజేహం పవిత్రం"
            },
            {
              "title": "భజే సూర్యమిత్రం భజే రుద్రరూపం"
            },
            {
              "title": "భజే బ్రహ్మతేజం బటంచున్ ప్రభాతంబు"
            },
            {
              "title": "సాయంత్రమున్ నీనామసంకీర్తనల్ జేసి"
            },
            {
              "title": "నీ రూపు వర్ణించి నీమీద నే దండకం బొక్కటిన్ జేయ"
            },
            {
              "title": "నీ మూర్తిగావించి నీసుందరం బెంచి నీ దాసదాసుండవై"
            },
            {
              "title": "రామభక్తుండనై నిన్ను నేగొల్చెదన్"
            },
            {
              "title": "నీ కటాక్షంబునన్ జూచితే వేడుకల్ చేసితే"
            },
            {
              "title": "నా మొరాలించితే నన్ను రక్షించితే"
            },
            {
              "title": "అంజనాదేవి గర్భాన్వయా దేవ"
            },
            {
              "title": "నిన్నెంచ నేనెంతవాడన్"
            },
            {
              "title": "దయాశాలివై జూచియున్ దాతవై బ్రోచియున్"
            },
            {
              "title": "దగ్గరన్ నిల్చియున్ దొల్లి సుగ్రీవుకున్-మంత్రివై"
            },
            {
              "title": "స్వామి కార్యార్థమై యేగి"
            },
            {
              "title": "శ్రీరామ సౌమిత్రులం జూచి వారిన్విచారించి"
            },
            {
              "title": "సర్వేశు బూజించి యబ్భానుజుం బంటు గావించి"
            },
            {
              "title": "వాలినిన్ జంపించి కాకుత్థ్స తిలకున్ కృపాదృష్టి వీక్షించి"
            },
            {
              "title": "కిష్కింధకేతెంచి శ్రీరామ కార్యార్థమై లంక కేతెంచియున్"
            },
            {
              "title": "లంకిణిన్ జంపియున్ లంకనున్ గాల్చియున్"
            },
            {
              "title": "యభ్భూమిజం జూచి యానందముప్పొంగి యాయుంగరంబిచ్చి"
            },
            {
              "title": "యారత్నమున్ దెచ్చి శ్రీరామునకున్నిచ్చి సంతోషమున్  జేసి"
            },
            {
              "title": "సుగ్రీవునిన్ యంగదున్ జాంబవంతు న్నలున్నీలులన్ గూడి"
            },
            {
              "title": "యాసేతువున్ దాటి వానరుల్ మూకలై పెన్మూకలై"
            },
            {
              "title": "బ్రహ్మాండమైనట్టి యా శక్తినిన్ వైచి యాలక్షణున్ మూర్ఛనొందింపగానప్పుడే నీవు"
            },
            {
              "title": "సంజీవినిన్ దెచ్చి సౌమిత్రికిన్నిచ్చి ప్రాణంబు రక్షింపగా"
            },
            {
              "title": "కుంభకర్ణాదుల న్వీరులం బోర శ్రీరామ బాణాగ్ని"
            },
            {
              "title": "వారందరిన్ రావణున్ జంపగా నంత లోకంబు లానందమై యుండ"
            },
            {
              "title": "నవ్వేళను న్విభీషుణున్ వేడుకన్ దోడుకన్ వచ్చి పట్టాభిషేకంబు చేయించి,"
            },
            {
              "title": "సీతామహాదేవినిన్ దెచ్చి శ్రీరాముకున్నిచ్చి"
            },
            {
              "title": "యంతన్నయోధ్యాపురిన్ జొచ్చి పట్టాభిషేకంబు సంరంభమైయున్న"
            },
            {
              "title": "నీకన్న నాకెవ్వరున్ గూర్మి లేరంచు మన్నించి శ్రీరామభక్త ప్రశస్తంబుగా"
            },
            {
              "title": "నిన్ను సేవించి నీ కీర్తనల్ చేసినన్ పాపముల్ ల్బాయునే భయములున్"
            },
            {
              "title": "దీరునే భాగ్యముల్ గల్గునే సామ్రాజ్యముల్ గల్గు సంపత్తులున్ కల్గునో"
            },
            {
              "title": "వానరాకార యోభక్త మందార యోపుణ్య సంచార యోధీర యోవీర"
            },
            {
              "title": "నీవే సమస్తంబుగా నొప్పి యాతారక బ్రహ్మ మంత్రంబు పఠియించుచున్ స్థిరమ్ముగన్"
            },
            {
              "title": "వజ్రదేహంబునున్ దాల్చి శ్రీరామ శ్రీరామయంచున్ మనఃపూతమైన ఎప్పుడున్ తప్పకన్"
            },
            {
              "title": "తలతునా జిహ్వయందుండి నీ దీర్ఘదేహమ్ము త్రైలోక్య సంచారివై రామ"
            },
            {
              "title": "నామాంకితధ్యానివై బ్రహ్మతేజంబునన్ రౌద్రనీజ్వాల"
            },
            {
              "title": "కల్లోల హావీర హనుమంత ఓంకార శబ్దంబులన్ భూత ప్రేతంబులన్ బెన్"
            },
            {
              "title": "పిశాచంబులన్ శాకినీ ఢాకినీత్యాదులన్ గాలిదయ్యంబులన్"
            },
            {
              "title": "నీదు వాలంబునన్ జుట్టి నేలంబడం గొట్టి నీముష్టి ఘాతంబులన్"
            },
            {
              "title": "బాహుదండంబులన్ రోమఖండంబులన్ ద్రుంచి కాలాగ్ని"
            },
            {
              "title": "రుద్రుండవై నీవు బ్రహ్మప్రభాభాసితంబైన నీదివ్య తేజంబునున్ జూచి"
            },
            {
              "title": "రారోరి నాముద్దు నరసింహ యన్ చున్ దయాదృష్టి"
            },
            {
              "title": "వీక్షించి నన్నేలు నాస్వామియో యాంజనేయా"
            },
            {
              "title": "నమస్తే సదా బ్రహ్మచారీ"
            },
            {
              "title": "నమస్తే నమోవాయుపుత్రా నమస్తే నమః"
            }
          ],
          "title": "ఆంజనేయ దండకం",
         //  "img": require('../imgs/Slokamulu/Hanuman.png')
        },
        {
          "slokalu": [
            {
              "title": "కౌసల్యా సుప్రజా రామ పూర్వాసంధ్యా ప్రవర్తతే ।"
            },
            {
              "title": "ఉత్తిష్ఠ నరశార్దూల కర్తవ్యం దైవమాహ్నికమ్ ॥ 1 ॥"
            },
            {
              "title": "ఉత్తిష్ఠోత్తిష్ఠ గోవింద ఉత్తిష్ఠ గరుడధ్వజ ।"
            },
            {
              "title": "ఉత్తిష్ఠ కమలాకాంత త్రైలోక్యం మంగళం కురు ॥ 2 ॥"
            },
            {
              "title": "మాతస్సమస్త జగతాం మధుకైటభారేః"
            },
            {
              "title": "వక్షోవిహారిణి మనోహర దివ్యమూర్తే ।"
            },
            {
              "title": "శ్రీస్వామిని శ్రితజనప్రియ దానశీలే"
            },
            {
              "title": "శ్రీ వేంకటేశ దయితే తవ సుప్రభాతమ్ ॥ 3 ॥"
            },
            {
              "title": "తవ సుప్రభాతమరవింద లోచనే"
            },
            {
              "title": "భవతు ప్రసన్నముఖ చంద్రమండలే ।"
            },
            {
              "title": "విధి శంకరేంద్ర వనితాభిరర్చితే"
            },
            {
              "title": "వృశ శైలనాథ దయితే దయానిధే ॥ 4 ॥"
            },
            {
              "title": "అత్ర్యాది సప్త ఋషయస్సముపాస్య సంధ్యాం"
            },
            {
              "title": "ఆకాశ సింధు కమలాని మనోహరాణి ।"
            },
            {
              "title": "ఆదాయ పాదయుగ మర్చయితుం ప్రపన్నాః"
            },
            {
              "title": "శేషాద్రి శేఖర విభో తవ సుప్రభాతమ్ ॥ 5 ॥"
            },
            {
              "title": "పంచాననాబ్జ భవ షణ్ముఖ వాసవాద్యాః"
            },
            {
              "title": "త్రైవిక్రమాది చరితం విబుధాః స్తువంతి ।"
            },
            {
              "title": "భాషాపతిః పఠతి వాసర శుద్ధి మారాత్"
            },
            {
              "title": "శేషాద్రి శేఖర విభో తవ సుప్రభాతమ్ ॥ 6 ॥"
            },
            {
              "title": "ఈశత్-ప్రఫుల్ల సరసీరుహ నారికేళ"
            },
            {
              "title": "పూగద్రుమాది సుమనోహర పాలికానామ్ ।"
            },
            {
              "title": "ఆవాతి మందమనిలః సహదివ్య గంధైః"
            },
            {
              "title": "శేషాద్రి శేఖర విభో తవ సుప్రభాతమ్ ॥ 7 ॥"
            },
            {
              "title": "ఉన్మీల్యనేత్ర యుగముత్తమ పంజరస్థాః"
            },
            {
              "title": "పాత్రావసిష్ట కదలీ ఫల పాయసాని ।"
            },
            {
              "title": "భుక్త్వాః సలీల మథకేళి శుకాః పఠంతి"
            },
            {
              "title": "శేషాద్రి శేఖర విభో తవ సుప్రభాతమ్ ॥ 8 ॥"
            },
            {
              "title": "తంత్రీ ప్రకర్ష మధుర స్వనయా విపంచ్యా"
            },
            {
              "title": "గాయత్యనంత చరితం తవ నారదోఽపి ।"
            },
            {
              "title": "భాషా సమగ్ర మసత్-కృతచారు రమ్యం"
            },
            {
              "title": "శేషాద్రి శేఖర విభో తవ సుప్రభాతమ్ ॥ 9 ॥"
            },
            {
              "title": "భృంగావళీ చ మకరంద రసాను విద్ధ"
            },
            {
              "title": "ఝుంకారగీత నినదైః సహసేవనాయ ।"
            },
            {
              "title": "నిర్యాత్యుపాంత సరసీ కమలోదరేభ్యః"
            },
            {
              "title": "శేషాద్రి శేఖర విభో తవ సుప్రభాతమ్ ॥ 10 ॥"
            },
            {
              "title": "యోషాగణేన వరదధ్ని విమథ్యమానే"
            },
            {
              "title": "ఘోషాలయేషు దధిమంథన తీవ్రఘోషాః ।"
            },
            {
              "title": "రోషాత్కలిం విదధతే కకుభశ్చ కుంభాః"
            },
            {
              "title": "శేషాద్రి శేఖర విభో తవ సుప్రభాతమ్ ॥ 11 ॥"
            },
            {
              "title": "పద్మేశమిత్ర శతపత్ర గతాళివర్గాః"
            },
            {
              "title": "హర్తుం శ్రియం కువలయస్య నిజాంగలక్ష్మ్యాః ।"
            },
            {
              "title": "భేరీ నినాదమివ భిభ్రతి తీవ్రనాదం"
            },
            {
              "title": "శేషాద్రి శేఖర విభో తవ సుప్రభాతమ్ ॥ 12 ॥"
            },
            {
              "title": "శ్రీమన్నభీష్ట వరదాఖిల లోక బంధో"
            },
            {
              "title": "శ్రీ శ్రీనివాస జగదేక దయైక సింధో ।"
            },
            {
              "title": "శ్రీ దేవతా గృహ భుజాంతర దివ్యమూర్తే"
            },
            {
              "title": "శ్రీ వేంకటాచలపతే తవ సుప్రభాతమ్ ॥ 13 ॥"
            },
            {
              "title": "శ్రీ స్వామి పుష్కరిణికాప్లవ నిర్మలాంగాః"
            },
            {
              "title": "శ్రేయార్థినో హరవిరించి సనందనాద్యాః ।"
            },
            {
              "title": "ద్వారే వసంతి వరనేత్ర హతోత్త మాంగాః"
            },
            {
              "title": "శ్రీ వేంకటాచలపతే తవ సుప్రభాతమ్ ॥ 14 ॥"
            },
            {
              "title": "శ్రీ శేషశైల గరుడాచల వేంకటాద్రి"
            },
            {
              "title": "నారాయణాద్రి వృషభాద్రి వృషాద్రి ముఖ్యామ్ ।"
            },
            {
              "title": "ఆఖ్యాం త్వదీయ వసతే రనిశం వదంతి"
            },
            {
              "title": "శ్రీ వేంకటాచలపతే తవ సుప్రభాతమ్ ॥ 15 ॥"
            },
            {
              "title": "సేవాపరాః శివ సురేశ కృశానుధర్మ"
            },
            {
              "title": "రక్షోంబునాథ పవమాన ధనాధి నాథాః ।"
            },
            {
              "title": "బద్ధాంజలి ప్రవిలసన్నిజ శీర్షదేశాః"
            },
            {
              "title": "శ్రీ వేంకటాచలపతే తవ సుప్రభాతమ్ ॥ 16 ॥"
            },
            {
              "title": "ధాటీషు తే విహగరాజ మృగాధిరాజ"
            },
            {
              "title": "నాగాధిరాజ గజరాజ హయాధిరాజాః ।"
            },
            {
              "title": "స్వస్వాధికార మహిమాధిక మర్థయంతే"
            },
            {
              "title": "శ్రీ వేంకటాచలపతే తవ సుప్రభాతమ్ ॥ 17 ॥"
            },
            {
              "title": "సూర్యేందు భౌమ బుధవాక్పతి కావ్యశౌరి"
            },
            {
              "title": "స్వర్భానుకేతు దివిశత్-పరిశత్-ప్రధానాః ।"
            },
            {
              "title": "త్వద్దాసదాస చరమావధి దాసదాసాః"
            },
            {
              "title": "శ్రీ వేంకటాచలపతే తవ సుప్రభాతమ్ ॥ 18 ॥"
            },
            {
              "title": "తత్-పాదధూళి భరిత స్ఫురితోత్తమాంగాః"
            },
            {
              "title": "స్వర్గాపవర్గ నిరపేక్ష నిజాంతరంగాః ।"
            },
            {
              "title": "కల్పాగమా కలనయాఽఽకులతాం లభంతే"
            },
            {
              "title": "శ్రీ వేంకటాచలపతే తవ సుప్రభాతమ్ ॥ 19 ॥"
            },
            {
              "title": "త్వద్గోపురాగ్ర శిఖరాణి నిరీక్షమాణాః"
            },
            {
              "title": "స్వర్గాపవర్గ పదవీం పరమాం శ్రయంతః ।"
            },
            {
              "title": "మర్త్యా మనుష్య భువనే మతిమాశ్రయంతే"
            },
            {
              "title": "శ్రీ వేంకటాచలపతే తవ సుప్రభాతమ్ ॥ 20 ॥"
            },
            {
              "title": "శ్రీ భూమినాయక దయాది గుణామృతాబ్దే"
            },
            {
              "title": "దేవాదిదేవ జగదేక శరణ్యమూర్తే ।"
            },
            {
              "title": "శ్రీమన్ననంత గరుడాదిభి రర్చితాంఘ్రే"
            },
            {
              "title": "శ్రీ వేంకటాచలపతే తవ సుప్రభాతమ్ ॥ 21 ॥"
            },
            {
              "title": "శ్రీ పద్మనాభ పురుషోత్తమ వాసుదేవ"
            },
            {
              "title": "వైకుంఠ మాధవ జనార్ధన చక్రపాణే ।"
            },
            {
              "title": "శ్రీ వత్స చిహ్న శరణాగత పారిజాత"
            },
            {
              "title": "శ్రీ వేంకటాచలపతే తవ సుప్రభాతమ్ ॥ 22 ॥"
            },
            {
              "title": "కందర్ప దర్ప హర సుందర దివ్య మూర్తే"
            },
            {
              "title": "కాంతా కుచాంబురుహ కుట్మల లోలదృష్టే ।"
            },
            {
              "title": "కల్యాణ నిర్మల గుణాకర దివ్యకీర్తే"
            },
            {
              "title": "శ్రీ వేంకటాచలపతే తవ సుప్రభాతమ్ ॥ 23 ॥"
            },
            {
              "title": "మీనాకృతే కమఠకోల నృసింహ వర్ణిన్"
            },
            {
              "title": "స్వామిన్ పరశ్వథ తపోధన రామచంద్ర ।"
            },
            {
              "title": "శేషాంశరామ యదునందన కల్కిరూప"
            },
            {
              "title": "శ్రీ వేంకటాచలపతే తవ సుప్రభాతమ్ ॥ 24 ॥"
            },
            {
              "title": "ఏలాలవంగ ఘనసార సుగంధి తీర్థం"
            },
            {
              "title": "దివ్యం వియత్సరితు హేమఘటేషు పూర్ణమ్ ।"
            },
            {
              "title": "ధృత్వాద్య వైదిక శిఖామణయః ప్రహృష్టాః"
            },
            {
              "title": "తిష్ఠంతి వేంకటపతే తవ సుప్రభాతమ్ ॥ 25 ॥"
            },
            {
              "title": "భాస్వానుదేతి వికచాని సరోరుహాణి"
            },
            {
              "title": "సంపూరయంతి నినదైః కకుభో విహంగాః ।"
            },
            {
              "title": "శ్రీవైష్ణవాః సతత మర్థిత మంగళాస్తే"
            },
            {
              "title": "ధామాశ్రయంతి తవ వేంకట సుప్రభాతమ్ ॥ 26 ॥"
            },
            {
              "title": "బ్రహ్మాదయా స్సురవరా స్సమహర్షయస్తే"
            },
            {
              "title": "సంతస్సనందన ముఖాస్త్వథ యోగివర్యాః ।"
            },
            {
              "title": "ధామాంతికే తవ హి మంగళ వస్తు హస్తాః"
            },
            {
              "title": "శ్రీ వేంకటాచలపతే తవ సుప్రభాతమ్ ॥ 27 ॥"
            },
            {
              "title": "లక్శ్మీనివాస నిరవద్య గుణైక సింధో"
            },
            {
              "title": "సంసారసాగర సముత్తరణైక సేతో ।"
            },
            {
              "title": "వేదాంత వేద్య నిజవైభవ భక్త భోగ్య"
            },
            {
              "title": "శ్రీ వేంకటాచలపతే తవ సుప్రభాతమ్ ॥ 28 ॥"
            },
            {
              "title": "ఇత్థం వృషాచలపతేరిహ సుప్రభాతం"
            },
            {
              "title": "యే మానవాః ప్రతిదినం పఠితుం ప్రవృత్తాః ।"
            },
            {
              "title": "తేషాం ప్రభాత సమయే స్మృతిరంగభాజాం"
            },
            {
              "title": "ప్రజ్ఞాం పరార్థ సులభాం పరమాం ప్రసూతే ॥ 29 ॥"
            }
          ],
          "title": "శ్రీ వేంకటేశ్వర సుప్రభాతం",
         //  "img": require('../imgs/Slokamulu/VenkateswaraSwami.png')
        },
        {
          "slokalu": [
            {
              "title": "ఓం ధ్యాయేస్సదా సవితృమండలమధ్యవర్తీ"
            },
            {
              "title": "నారాయణస్సరసిజాసన సన్నివిష్టః ।"
            },
            {
              "title": "కేయూరవాన్ మకరకుండలవాన్ కిరీటీ"
            },
            {
              "title": "హారీ హిరణ్మయవపుః ధృతశంఖచక్రః ॥"
            },
            {
              "title": "ఓం మిత్రాయ నమః ।"
            },
            {
              "title": "ఓం రవయే నమః ।"
            },
            {
              "title": "ఓం సూర్యాయ నమః ।"
            },
            {
              "title": "ఓం భానవే నమః ।"
            },
            {
              "title": "ఓం ఖగాయ నమః ।"
            },
            {
              "title": "ఓం పూష్ణే నమః ।"
            },
            {
              "title": "ఓం హిరణ్యగర్భాయ నమః ।"
            },
            {
              "title": "ఓం మరీచయే నమః ।"
            },
            {
              "title": "ఓం ఆదిత్యాయ నమః ।"
            },
            {
              "title": "ఓం సవిత్రే నమః ।"
            },
            {
              "title": "ఓం అర్కాయ నమః ।"
            },
            {
              "title": "ఓం భాస్కరాయ నమః ।"
            },
            {
              "title": "ఓం శ్రీసవితృసూర్యనారాయణాయ నమః ॥"
            },
            {
              "title": "ఆదిత్యస్య నమస్కారాన్ యే కుర్వంతి దినే దినే ।"
            },
            {
              "title": "ఆయుః ప్రజ్ఞాం బలం వీర్యం తేజస్తేషాం చ జాయతే ॥"
            }
          ],
          "title": "శ్రీ సూర్య నమస్కార మంత్రం",
         //  "img": require('../imgs/Slokamulu/Suryabhagvan.png')
        }
      ]
    },

    "stotralu": {
      "CatID": [
        {
          "sotram": [
            {
              "title": "ఓం శుక్లాంబరధరం విష్ణుం శశివర్ణం చతుర్భుజమ్ ।"
            },
            {
              "title": "ప్రసన్నవదనం ధ్యాయేత్ సర్వవిఘ్నోపశాంతయే ॥ 1 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "యస్యద్విరదవక్త్రాద్యాః పారిషద్యాః పరః శతమ్ ।"
            },
            {
              "title": "విఘ్నం నిఘ్నంతి సతతం విష్వక్సేనం తమాశ్రయే ॥ 2 ॥"
            },
            {
              "title": "@పూర్వ పీఠికా"
            },
            {
              "title": "వ్యాసం వసిష్ఠ నప్తారం శక్తేః పౌత్రమకల్మషమ్ ।"
            },
            {
              "title": "పరాశరాత్మజం వందే శుకతాతం తపోనిధిమ్ ॥ 3 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "వ్యాసాయ విష్ణు రూపాయ వ్యాసరూపాయ విష్ణవే ।"
            },
            {
              "title": "నమో వై బ్రహ్మనిధయే వాసిష్ఠాయ నమో నమః ॥ 4 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అవికారాయ శుద్ధాయ నిత్యాయ పరమాత్మనే ।"
            },
            {
              "title": "సదైక రూప రూపాయ విష్ణవే సర్వజిష్ణవే ॥ 5 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "యస్య స్మరణమాత్రేణ జన్మసంసారబంధనాత్ ।"
            },
            {
              "title": "విముచ్యతే నమస్తస్మై విష్ణవే ప్రభవిష్ణవే ॥ 6 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం నమో విష్ణవే ప్రభవిష్ణవే ।"
            },
            {
              "title": ""
            },
            {
              "title": "@శ్రీ వైశంపాయన ఉవాచ"
            },
            {
              "title": "శ్రుత్వా ధర్మా నశేషేణ పావనాని చ సర్వశః ।"
            },
            {
              "title": "యుధిష్ఠిరః శాంతనవం పునరేవాభ్య భాషత ॥ 7 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@యుధిష్ఠిర ఉవాచ"
            },
            {
              "title": "కిమేకం దైవతం లోకే కిం వాఽప్యేకం పరాయణం"
            },
            {
              "title": "స్తువంతః కం కమర్చంతః ప్రాప్నుయుర్మానవాః శుభమ్ ॥ 8 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "కో ధర్మః సర్వధర్మాణాం భవతః పరమో మతః ।"
            },
            {
              "title": "కిం జపన్ముచ్యతే జంతుర్జన్మసంసార బంధనాత్ ॥ 9 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@శ్రీ భీష్మ ఉవాచ"
            },
            {
              "title": "జగత్ప్రభుం దేవదేవ మనంతం పురుషోత్తమమ్ ।"
            },
            {
              "title": "స్తువన్నామ సహస్రేణ పురుషః సతతోత్థితః ॥ 10 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "తమేవ చార్చయన్నిత్యం భక్త్యా పురుషమవ్యయమ్ ।"
            },
            {
              "title": "ధ్యాయన్ స్తువన్నమస్యంశ్చ యజమానస్తమేవ చ ॥ 11 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అనాది నిధనం విష్ణుం సర్వలోక మహేశ్వరమ్ ।"
            },
            {
              "title": "లోకాధ్యక్షం స్తువన్నిత్యం సర్వ దుఃఖాతిగో భవేత్ ॥ 12 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "బ్రహ్మణ్యం సర్వ ధర్మజ్ఞం లోకానాం కీర్తి వర్ధనమ్ ।"
            },
            {
              "title": "లోకనాథం మహద్భూతం సర్వభూత భవోద్భవం॥ 13 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఏష మే సర్వ ధర్మాణాం ధర్మోఽధిక తమోమతః ।"
            },
            {
              "title": "యద్భక్త్యా పుండరీకాక్షం స్తవైరర్చేన్నరః సదా ॥ 14 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "పరమం యో మహత్తేజః పరమం యో మహత్తపః ।"
            },
            {
              "title": "పరమం యో మహద్బ్రహ్మ పరమం యః పరాయణమ్ । 15 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "పవిత్రాణాం పవిత్రం యో మంగళానాం చ మంగళమ్ ।"
            },
            {
              "title": "దైవతం దేవతానాం చ భూతానాం యోఽవ్యయః పితా ॥ 16 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "యతః సర్వాణి భూతాని భవంత్యాది యుగాగమే ।"
            },
            {
              "title": "యస్మింశ్చ ప్రలయం యాంతి పునరేవ యుగక్షయే ॥ 17 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "తస్య లోక ప్రధానస్య జగన్నాథస్య భూపతే ।"
            },
            {
              "title": "విష్ణోర్నామ సహస్రం మే శ్రుణు పాప భయాపహమ్ ॥ 18 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "యాని నామాని గౌణాని విఖ్యాతాని మహాత్మనః ।"
            },
            {
              "title": "ఋషిభిః పరిగీతాని తాని వక్ష్యామి భూతయే ॥ 19 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఋషిర్నామ్నాం సహస్రస్య వేదవ్యాసో మహామునిః ॥"
            },
            {
              "title": "ఛందోఽనుష్టుప్ తథా దేవో భగవాన్ దేవకీసుతః ॥ 20 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అమృతాం శూద్భవో బీజం శక్తిర్దేవకినందనః ।"
            },
            {
              "title": "త్రిసామా హృదయం తస్య శాంత్యర్థే వినియుజ్యతే ॥ 21 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "విష్ణుం జిష్ణుం మహావిష్ణుం ప్రభవిష్ణుం మహేశ్వరమ్ ॥"
            },
            {
              "title": "అనేకరూప దైత్యాంతం నమామి పురుషోత్తమమ్ ॥ 22 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@పూర్వన్యాసః"
            },
            {
              "title": "అస్య శ్రీ విష్ణోర్దివ్య సహస్రనామ స్తోత్ర మహామంత్రస్య ॥"
            },
            {
              "title": "శ్రీ వేదవ్యాసో భగవాన్ ఋషిః ।"
            },
            {
              "title": "అనుష్టుప్ ఛందః ।"
            },
            {
              "title": "శ్రీమహావిష్ణుః పరమాత్మా శ్రీమన్నారాయణో దేవతా ।"
            },
            {
              "title": "అమృతాంశూద్భవో భానురితి బీజమ్ ।"
            },
            {
              "title": "దేవకీనందనః స్రష్టేతి శక్తిః ।"
            },
            {
              "title": "ఉద్భవః, క్షోభణో దేవ ఇతి పరమోమంత్రః ।"
            },
            {
              "title": "శంఖభృన్నందకీ చక్రీతి కీలకమ్ ।"
            },
            {
              "title": "శారంగధన్వా గదాధర ఇత్యస్త్రమ్ ।"
            },
            {
              "title": "రథాంగపాణి రక్షోభ్య ఇతి నేత్రమ్ ।"
            },
            {
              "title": "త్రిసామాసామగః సామేతి కవచమ్ ।"
            },
            {
              "title": "ఆనందం పరబ్రహ్మేతి యోనిః ।"
            },
            {
              "title": "ఋతుస్సుదర్శనః కాల ఇతి దిగ్బంధః ॥"
            },
            {
              "title": "శ్రీవిశ్వరూప ఇతి ధ్యానమ్ ।"
            },
            {
              "title": "శ్రీ మహావిష్ణు ప్రీత్యర్థే సహస్రనామ జపే పారాయణే వినియోగః ।"
            },
            {
              "title": ""
            },
            {
              "title": "@కరన్యాసః"
            },
            {
              "title": "విశ్వం విష్ణుర్వషట్కార ఇత్యంగుష్ఠాభ్యాం నమః"
            },
            {
              "title": "అమృతాం శూద్భవో భానురితి తర్జనీభ్యాం నమః"
            },
            {
              "title": "బ్రహ్మణ్యో బ్రహ్మకృత్ బ్రహ్మేతి మధ్యమాభ్యాం నమః"
            },
            {
              "title": "సువర్ణబిందు రక్షోభ్య ఇతి అనామికాభ్యాం నమః"
            },
            {
              "title": "నిమిషోఽనిమిషః స్రగ్వీతి కనిష్ఠికాభ్యాం నమః"
            },
            {
              "title": "రథాంగపాణి రక్షోభ్య ఇతి కరతల కరపృష్ఠాభ్యాం నమః"
            },
            {
              "title": ""
            },
            {
              "title": "@అంగన్యాసః"
            },
            {
              "title": "సువ్రతః సుముఖః సూక్ష్మ ఇతి జ్ఞానాయ హృదయాయ నమః"
            },
            {
              "title": "సహస్రమూర్తిః విశ్వాత్మా ఇతి ఐశ్వర్యాయ శిరసే స్వాహా"
            },
            {
              "title": "సహస్రార్చిః సప్తజిహ్వ ఇతి శక్త్యై శిఖాయై వషట్"
            },
            {
              "title": "త్రిసామా సామగస్సామేతి బలాయ కవచాయ హుం"
            },
            {
              "title": "రథాంగపాణి రక్షోభ్య ఇతి నేత్రాభ్యాం వౌషట్"
            },
            {
              "title": "శాంగధన్వా గదాధర ఇతి వీర్యాయ అస్త్రాయఫట్"
            },
            {
              "title": "ఋతుః సుదర్శనః కాల ఇతి దిగ్భంధః"
            },
            {
              "title": ""
            },
            {
              "title": "ధ్యానం"
            },
            {
              "title": "క్షీరోధన్వత్ప్రదేశే శుచిమణివిలసత్సైకతేమౌక్తికానాం"
            },
            {
              "title": "మాలాక్లుప్తాసనస్థః స్ఫటికమణినిభైర్మౌక్తికైర్మండితాంగః ।"
            },
            {
              "title": "శుభ్రైరభ్రైరదభ్రైరుపరివిరచితైర్ముక్తపీయూష వర్షైః"
            },
            {
              "title": "ఆనందీ నః పునీయాదరినలినగదా శంఖపాణిర్ముకుందః ॥ 1 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "భూః పాదౌ యస్య నాభిర్వియదసురనిలశ్చంద్ర సూర్యౌ చ నేత్రే"
            },
            {
              "title": "కర్ణావాశాః శిరోద్యౌర్ముఖమపి దహనో యస్య వాస్తేయమబ్ధిః ।"
            },
            {
              "title": "అంతఃస్థం యస్య విశ్వం సుర నరఖగగోభోగిగంధర్వదైత్యైః"
            },
            {
              "title": "చిత్రం రం రమ్యతే తం త్రిభువన వపుశం విష్ణుమీశం నమామి ॥ 2 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం నమో భగవతే వాసుదేవాయ !"
            },
            {
              "title": ""
            },
            {
              "title": "శాంతాకారం భుజగశయనం పద్మనాభం సురేశం"
            },
            {
              "title": "విశ్వాధారం గగనసదృశం మేఘవర్ణం శుభాంగమ్ ।"
            },
            {
              "title": "లక్ష్మీకాంతం కమలనయనం యోగిహృర్ధ్యానగమ్యం"
            },
            {
              "title": "వందే విష్ణుం భవభయహరం సర్వలోకైకనాథమ్ ॥ 3 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "మేఘశ్యామం పీతకౌశేయవాసం"
            },
            {
              "title": "శ్రీవత్సాకం కౌస్తుభోద్భాసితాంగమ్ ।"
            },
            {
              "title": "పుణ్యోపేతం పుండరీకాయతాక్షం"
            },
            {
              "title": "విష్ణుం వందే సర్వలోకైకనాథమ్ ॥ 4 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "నమః సమస్త భూతానాం ఆది భూతాయ భూభృతే ।"
            },
            {
              "title": "అనేకరూప రూపాయ విష్ణవే ప్రభవిష్ణవే ॥ 5॥"
            },
            {
              "title": ""
            },
            {
              "title": "సశంఖచక్రం సకిరీటకుండలం"
            },
            {
              "title": "సపీతవస్త్రం సరసీరుహేక్షణమ్ ।"
            },
            {
              "title": "సహార వక్షఃస్థల శోభి కౌస్తుభం"
            },
            {
              "title": "నమామి విష్ణుం శిరసా చతుర్భుజమ్ । 6॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఛాయాయాం పారిజాతస్య హేమసింహాసనోపరి"
            },
            {
              "title": "ఆసీనమంబుదశ్యామమాయతాక్షమలంకృతమ్ ॥ 7 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "చంద్రాననం చతుర్బాహుం శ్రీవత్సాంకిత వక్షసం"
            },
            {
              "title": "రుక్మిణీ సత్యభామాభ్యాం సహితం కృష్ణమాశ్రయే ॥ 8 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@పంచపూజ"
            },
            {
              "title": "లం - పృథివ్యాత్మనే గంథం సమర్పయామి"
            },
            {
              "title": "హం - ఆకాశాత్మనే పుష్పైః పూజయామి"
            },
            {
              "title": "యం - వాయ్వాత్మనే ధూపమాఘ్రాపయామి"
            },
            {
              "title": "రం - అగ్న్యాత్మనే దీపం దర్శయామి"
            },
            {
              "title": "వం - అమృతాత్మనే నైవేద్యం నివేదయామి"
            },
            {
              "title": "సం - సర్వాత్మనే సర్వోపచార పూజా నమస్కారాన్ సమర్పయామి"
            },
            {
              "title": ""
            },
            {
              "title": "స్తోత్రం"
            },
            {
              "title": "హరిః ఓం"
            },
            {
              "title": ""
            },
            {
              "title": "విశ్వం విష్ణుర్వషట్కారో భూతభవ్యభవత్ప్రభుః ।"
            },
            {
              "title": "భూతకృద్భూతభృద్భావో భూతాత్మా భూతభావనః ॥ 1 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "పూతాత్మా పరమాత్మా చ ముక్తానాం పరమాగతిః ।"
            },
            {
              "title": "అవ్యయః పురుషః సాక్షీ క్షేత్రజ్ఞోఽక్షర ఏవ చ ॥ 2 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "యోగో యోగవిదాం నేతా ప్రధాన పురుషేశ్వరః ।"
            },
            {
              "title": "నారసింహవపుః శ్రీమాన్ కేశవః పురుషోత్తమః ॥ 3 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సర్వః శర్వః శివః స్థాణుర్భూతాదిర్నిధిరవ్యయః ।"
            },
            {
              "title": "సంభవో భావనో భర్తా ప్రభవః ప్రభురీశ్వరః ॥ 4 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "స్వయంభూః శంభురాదిత్యః పుష్కరాక్షో మహాస్వనః ।"
            },
            {
              "title": "అనాదినిధనో ధాతా విధాతా ధాతురుత్తమః ॥ 5 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అప్రమేయో హృషీకేశః పద్మనాభోఽమరప్రభుః ।"
            },
            {
              "title": "విశ్వకర్మా మనుస్త్వష్టా స్థవిష్ఠః స్థవిరో ధ్రువః ॥ 6 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అగ్రాహ్యః శాశ్వతో కృష్ణో లోహితాక్షః ప్రతర్దనః ।"
            },
            {
              "title": "ప్రభూతస్త్రికకుబ్ధామ పవిత్రం మంగళం పరమ్ ॥ 7 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఈశానః ప్రాణదః ప్రాణో జ్యేష్ఠః శ్రేష్ఠః ప్రజాపతిః ।"
            },
            {
              "title": "హిరణ్యగర్భో భూగర్భో మాధవో మధుసూదనః ॥ 8 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఈశ్వరో విక్రమీధన్వీ మేధావీ విక్రమః క్రమః ।"
            },
            {
              "title": "అనుత్తమో దురాధర్షః కృతజ్ఞః కృతిరాత్మవాన్॥ 9 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సురేశః శరణం శర్మ విశ్వరేతాః ప్రజాభవః ।"
            },
            {
              "title": "అహస్సంవత్సరో వ్యాళః ప్రత్యయః సర్వదర్శనః ॥ 10 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అజస్సర్వేశ్వరః సిద్ధః సిద్ధిః సర్వాదిరచ్యుతః ।"
            },
            {
              "title": "వృషాకపిరమేయాత్మా సర్వయోగవినిస్సృతః ॥ 11 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "వసుర్వసుమనాః సత్యః సమాత్మా సమ్మితస్సమః ।"
            },
            {
              "title": "అమోఘః పుండరీకాక్షో వృషకర్మా వృషాకృతిః ॥ 12 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "రుద్రో బహుశిరా బభ్రుర్విశ్వయోనిః శుచిశ్రవాః ।"
            },
            {
              "title": "అమృతః శాశ్వతస్థాణుర్వరారోహో మహాతపాః ॥ 13 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సర్వగః సర్వ విద్భానుర్విష్వక్సేనో జనార్దనః ।"
            },
            {
              "title": "వేదో వేదవిదవ్యంగో వేదాంగో వేదవిత్కవిః ॥ 14 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "లోకాధ్యక్షః సురాధ్యక్షో ధర్మాధ్యక్షః కృతాకృతః ।"
            },
            {
              "title": "చతురాత్మా చతుర్వ్యూహశ్చతుర్దంష్ట్రశ్చతుర్భుజః ॥ 15 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "భ్రాజిష్ణుర్భోజనం భోక్తా సహిష్నుర్జగదాదిజః ।"
            },
            {
              "title": "అనఘో విజయో జేతా విశ్వయోనిః పునర్వసుః ॥ 16 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఉపేంద్రో వామనః ప్రాంశురమోఘః శుచిరూర్జితః ।"
            },
            {
              "title": "అతీంద్రః సంగ్రహః సర్గో ధృతాత్మా నియమో యమః ॥ 17 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "వేద్యో వైద్యః సదాయోగీ వీరహా మాధవో మధుః ।"
            },
            {
              "title": "అతీంద్రియో మహామాయో మహోత్సాహో మహాబలః ॥ 18 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "మహాబుద్ధిర్మహావీర్యో మహాశక్తిర్మహాద్యుతిః ।"
            },
            {
              "title": "అనిర్దేశ్యవపుః శ్రీమానమేయాత్మా మహాద్రిధృక్ ॥ 19 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "మహేశ్వాసో మహీభర్తా శ్రీనివాసః సతాంగతిః ।"
            },
            {
              "title": "అనిరుద్ధః సురానందో గోవిందో గోవిదాం పతిః ॥ 20 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "మరీచిర్దమనో హంసః సుపర్ణో భుజగోత్తమః ।"
            },
            {
              "title": "హిరణ్యనాభః సుతపాః పద్మనాభః ప్రజాపతిః ॥ 21 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అమృత్యుః సర్వదృక్ సింహః సంధాతా సంధిమాన్ స్థిరః ।"
            },
            {
              "title": "అజో దుర్మర్షణః శాస్తా విశ్రుతాత్మా సురారిహా ॥ 22 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "గురుర్గురుతమో ధామ సత్యః సత్యపరాక్రమః ।"
            },
            {
              "title": "నిమిషోఽనిమిషః స్రగ్వీ వాచస్పతిరుదారధీః ॥ 23 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అగ్రణీగ్రామణీః శ్రీమాన్ న్యాయో నేతా సమీరణః"
            },
            {
              "title": "సహస్రమూర్ధా విశ్వాత్మా సహస్రాక్షః సహస్రపాత్ ॥ 24 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఆవర్తనో నివృత్తాత్మా సంవృతః సంప్రమర్దనః ।"
            },
            {
              "title": "అహః సంవర్తకో వహ్నిరనిలో ధరణీధరః ॥ 25 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సుప్రసాదః ప్రసన్నాత్మా విశ్వధృగ్విశ్వభుగ్విభుః ।"
            },
            {
              "title": "సత్కర్తా సత్కృతః సాధుర్జహ్నుర్నారాయణో నరః ॥ 26 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అసంఖ్యేయోఽప్రమేయాత్మా విశిష్టః శిష్టకృచ్ఛుచిః ।"
            },
            {
              "title": "సిద్ధార్థః సిద్ధసంకల్పః సిద్ధిదః సిద్ధి సాధనః ॥ 27 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "వృషాహీ వృషభో విష్ణుర్వృషపర్వా వృషోదరః ।"
            },
            {
              "title": "వర్ధనో వర్ధమానశ్చ వివిక్తః శ్రుతిసాగరః ॥ 28 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సుభుజో దుర్ధరో వాగ్మీ మహేంద్రో వసుదో వసుః ।"
            },
            {
              "title": "నైకరూపో బృహద్రూపః శిపివిష్టః ప్రకాశనః ॥ 29 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఓజస్తేజోద్యుతిధరః ప్రకాశాత్మా ప్రతాపనః ।"
            },
            {
              "title": "ఋద్దః స్పష్టాక్షరో మంత్రశ్చంద్రాంశుర్భాస్కరద్యుతిః ॥ 30 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అమృతాంశూద్భవో భానుః శశబిందుః సురేశ్వరః ।"
            },
            {
              "title": "ఔషధం జగతః సేతుః సత్యధర్మపరాక్రమః ॥ 31 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "భూతభవ్యభవన్నాథః పవనః పావనోఽనలః ।"
            },
            {
              "title": "కామహా కామకృత్కాంతః కామః కామప్రదః ప్రభుః ॥ 32 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "యుగాది కృద్యుగావర్తో నైకమాయో మహాశనః ।"
            },
            {
              "title": "అదృశ్యో వ్యక్తరూపశ్చ సహస్రజిదనంతజిత్ ॥ 33 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఇష్టోఽవిశిష్టః శిష్టేష్టః శిఖండీ నహుషో వృషః ।"
            },
            {
              "title": "క్రోధహా క్రోధకృత్కర్తా విశ్వబాహుర్మహీధరః ॥ 34 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అచ్యుతః ప్రథితః ప్రాణః ప్రాణదో వాసవానుజః ।"
            },
            {
              "title": "అపాంనిధిరధిష్ఠానమప్రమత్తః ప్రతిష్ఠితః ॥ 35 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "స్కందః స్కందధరో ధుర్యో వరదో వాయువాహనః ।"
            },
            {
              "title": "వాసుదేవో బృహద్భానురాదిదేవః పురంధరః ॥ 36 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అశోకస్తారణస్తారః శూరః శౌరిర్జనేశ్వరః ।"
            },
            {
              "title": "అనుకూలః శతావర్తః పద్మీ పద్మనిభేక్షణః ॥ 37 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "పద్మనాభోఽరవిందాక్షః పద్మగర్భః శరీరభృత్ ।"
            },
            {
              "title": "మహర్ధిరృద్ధో వృద్ధాత్మా మహాక్షో గరుడధ్వజః ॥ 38 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అతులః శరభో భీమః సమయజ్ఞో హవిర్హరిః ।"
            },
            {
              "title": "సర్వలక్షణలక్షణ్యో లక్ష్మీవాన్ సమితింజయః ॥ 39 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "విక్షరో రోహితో మార్గో హేతుర్దామోదరః సహః ।"
            },
            {
              "title": "మహీధరో మహాభాగో వేగవానమితాశనః ॥ 40 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఉద్భవః, క్షోభణో దేవః శ్రీగర్భః పరమేశ్వరః ।"
            },
            {
              "title": "కరణం కారణం కర్తా వికర్తా గహనో గుహః ॥ 41 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "వ్యవసాయో వ్యవస్థానః సంస్థానః స్థానదో ధ్రువః ।"
            },
            {
              "title": "పరర్ధిః పరమస్పష్టః తుష్టః పుష్టః శుభేక్షణః ॥ 42 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "రామో విరామో విరజో మార్గోనేయో నయోఽనయః ।"
            },
            {
              "title": "వీరః శక్తిమతాం శ్రేష్ఠో ధర్మోధర్మ విదుత్తమః ॥ 43 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "వైకుంఠః పురుషః ప్రాణః ప్రాణదః ప్రణవః పృథుః ।"
            },
            {
              "title": "హిరణ్యగర్భః శత్రుఘ్నో వ్యాప్తో వాయురధోక్షజః ॥ 44 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఋతుః సుదర్శనః కాలః పరమేష్ఠీ పరిగ్రహః ।"
            },
            {
              "title": "ఉగ్రః సంవత్సరో దక్షో విశ్రామో విశ్వదక్షిణః ॥ 45 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "విస్తారః స్థావర స్థాణుః ప్రమాణం బీజమవ్యయమ్ ।"
            },
            {
              "title": "అర్థోఽనర్థో మహాకోశో మహాభోగో మహాధనః ॥ 46 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అనిర్విణ్ణః స్థవిష్ఠో భూద్ధర్మయూపో మహామఖః ।"
            },
            {
              "title": "నక్షత్రనేమిర్నక్షత్రీ క్షమః, క్షామః సమీహనః ॥ 47 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "యజ్ఞ ఇజ్యో మహేజ్యశ్చ క్రతుః సత్రం సతాంగతిః ।"
            },
            {
              "title": "సర్వదర్శీ విముక్తాత్మా సర్వజ్ఞో జ్ఞానముత్తమమ్ ॥ 48 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సువ్రతః సుముఖః సూక్ష్మః సుఘోషః సుఖదః సుహృత్ ।"
            },
            {
              "title": "మనోహరో జితక్రోధో వీర బాహుర్విదారణః ॥ 49 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "స్వాపనః స్వవశో వ్యాపీ నైకాత్మా నైకకర్మకృత్। ।"
            },
            {
              "title": "వత్సరో వత్సలో వత్సీ రత్నగర్భో ధనేశ్వరః ॥ 50 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ధర్మగుబ్ధర్మకృద్ధర్మీ సదసత్క్షరమక్షరం॥"
            },
            {
              "title": "అవిజ్ఞాతా సహస్త్రాంశుర్విధాతా కృతలక్షణః ॥ 51 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "గభస్తినేమిః సత్త్వస్థః సింహో భూత మహేశ్వరః ।"
            },
            {
              "title": "ఆదిదేవో మహాదేవో దేవేశో దేవభృద్గురుః ॥ 52 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఉత్తరో గోపతిర్గోప్తా జ్ఞానగమ్యః పురాతనః ।"
            },
            {
              "title": "శరీర భూతభృద్ భోక్తా కపీంద్రో భూరిదక్షిణః ॥ 53 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సోమపోఽమృతపః సోమః పురుజిత్ పురుసత్తమః ।"
            },
            {
              "title": "వినయో జయః సత్యసంధో దాశార్హః సాత్వతాం పతిః ॥ 54 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "జీవో వినయితా సాక్షీ ముకుందోఽమిత విక్రమః ।"
            },
            {
              "title": "అంభోనిధిరనంతాత్మా మహోదధి శయోంతకః ॥ 55 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అజో మహార్హః స్వాభావ్యో జితామిత్రః ప్రమోదనః ।"
            },
            {
              "title": "ఆనందోఽనందనోనందః సత్యధర్మా త్రివిక్రమః ॥ 56 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "మహర్షిః కపిలాచార్యః కృతజ్ఞో మేదినీపతిః ।"
            },
            {
              "title": "త్రిపదస్త్రిదశాధ్యక్షో మహాశృంగః కృతాంతకృత్ ॥ 57 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "మహావరాహో గోవిందః సుషేణః కనకాంగదీ ।"
            },
            {
              "title": "గుహ్యో గభీరో గహనో గుప్తశ్చక్ర గదాధరః ॥ 58 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "వేధాః స్వాంగోఽజితః కృష్ణో దృఢః సంకర్షణోఽచ్యుతః ।"
            },
            {
              "title": "వరుణో వారుణో వృక్షః పుష్కరాక్షో మహామనాః ॥ 59 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "భగవాన్ భగహాఽఽనందీ వనమాలీ హలాయుధః ।"
            },
            {
              "title": "ఆదిత్యో జ్యోతిరాదిత్యః సహిష్ణుర్గతిసత్తమః ॥ 60 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సుధన్వా ఖండపరశుర్దారుణో ద్రవిణప్రదః ।"
            },
            {
              "title": "దివఃస్పృక్ సర్వదృగ్వ్యాసో వాచస్పతిరయోనిజః ॥ 61 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "త్రిసామా సామగః సామ నిర్వాణం భేషజం భిషక్ ।"
            },
            {
              "title": "సన్యాసకృచ్ఛమః శాంతో నిష్ఠా శాంతిః పరాయణం। 62 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "శుభాంగః శాంతిదః స్రష్టా కుముదః కువలేశయః ।"
            },
            {
              "title": "గోహితో గోపతిర్గోప్తా వృషభాక్షో వృషప్రియః ॥ 63 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అనివర్తీ నివృత్తాత్మా సంక్షేప్తా క్షేమకృచ్ఛివః ।"
            },
            {
              "title": "శ్రీవత్సవక్షాః శ్రీవాసః శ్రీపతిః శ్రీమతాంవరః ॥ 64 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "శ్రీదః శ్రీశః శ్రీనివాసః శ్రీనిధిః శ్రీవిభావనః ।"
            },
            {
              "title": "శ్రీధరః శ్రీకరః శ్రేయః శ్రీమా~ంల్లోకత్రయాశ్రయః ॥ 65 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "స్వక్షః స్వంగః శతానందో నందిర్జ్యోతిర్గణేశ్వరః ।"
            },
            {
              "title": "విజితాత్మాఽవిధేయాత్మా సత్కీర్తిచ్ఛిన్నసంశయః ॥ 66 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఉదీర్ణః సర్వతశ్చక్షురనీశః శాశ్వతస్థిరః ।"
            },
            {
              "title": "భూశయో భూషణో భూతిర్విశోకః శోకనాశనః ॥ 67 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అర్చిష్మానర్చితః కుంభో విశుద్ధాత్మా విశోధనః ।"
            },
            {
              "title": "అనిరుద్ధోఽప్రతిరథః ప్రద్యుమ్నోఽమితవిక్రమః ॥ 68 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "కాలనేమినిహా వీరః శౌరిః శూరజనేశ్వరః ।"
            },
            {
              "title": "త్రిలోకాత్మా త్రిలోకేశః కేశవః కేశిహా హరిః ॥ 69 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "కామదేవః కామపాలః కామీ కాంతః కృతాగమః ।"
            },
            {
              "title": "అనిర్దేశ్యవపుర్విష్ణుర్వీరోఽనంతో ధనంజయః ॥ 70 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "బ్రహ్మణ్యో బ్రహ్మకృద్ బ్రహ్మా బ్రహ్మ బ్రహ్మవివర్ధనః ।"
            },
            {
              "title": "బ్రహ్మవిద్ బ్రాహ్మణో బ్రహ్మీ బ్రహ్మజ్ఞో బ్రాహ్మణప్రియః ॥ 71 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "మహాక్రమో మహాకర్మా మహాతేజా మహోరగః ।"
            },
            {
              "title": "మహాక్రతుర్మహాయజ్వా మహాయజ్ఞో మహాహవిః ॥ 72 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "స్తవ్యః స్తవప్రియః స్తోత్రం స్తుతిః స్తోతా రణప్రియః ।"
            },
            {
              "title": "పూర్ణః పూరయితా పుణ్యః పుణ్యకీర్తిరనామయః ॥ 73 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "మనోజవస్తీర్థకరో వసురేతా వసుప్రదః ।"
            },
            {
              "title": "వసుప్రదో వాసుదేవో వసుర్వసుమనా హవిః ॥ 74 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సద్గతిః సత్కృతిః సత్తా సద్భూతిః సత్పరాయణః ।"
            },
            {
              "title": "శూరసేనో యదుశ్రేష్ఠః సన్నివాసః సుయామునః ॥ 75 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "భూతావాసో వాసుదేవః సర్వాసునిలయోఽనలః ।"
            },
            {
              "title": "దర్పహా దర్పదో దృప్తో దుర్ధరోఽథాపరాజితః ॥ 76 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "విశ్వమూర్తిర్మహామూర్తిర్దీప్తమూర్తిరమూర్తిమాన్ ।"
            },
            {
              "title": "అనేకమూర్తిరవ్యక్తః శతమూర్తిః శతాననః ॥ 77 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఏకో నైకః సవః కః కిం యత్తత్ పదమనుత్తమమ్ ।"
            },
            {
              "title": "లోకబంధుర్లోకనాథో మాధవో భక్తవత్సలః ॥ 78 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సువర్ణవర్ణో హేమాంగో వరాంగశ్చందనాంగదీ ।"
            },
            {
              "title": "వీరహా విషమః శూన్యో ఘృతాశీరచలశ్చలః ॥ 79 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అమానీ మానదో మాన్యో లోకస్వామీ త్రిలోకధృక్ ।"
            },
            {
              "title": "సుమేధా మేధజో ధన్యః సత్యమేధా ధరాధరః ॥ 80 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "తేజోఽవృషో ద్యుతిధరః సర్వశస్త్రభృతాంవరః ।"
            },
            {
              "title": "ప్రగ్రహో నిగ్రహో వ్యగ్రో నైకశృంగో గదాగ్రజః ॥ 81 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "చతుర్మూర్తి శ్చతుర్బాహు శ్చతుర్వ్యూహ శ్చతుర్గతిః ।"
            },
            {
              "title": "చతురాత్మా చతుర్భావశ్చతుర్వేదవిదేకపాత్ ॥ 82 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సమావర్తోఽనివృత్తాత్మా దుర్జయో దురతిక్రమః ।"
            },
            {
              "title": "దుర్లభో దుర్గమో దుర్గో దురావాసో దురారిహా ॥ 83 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "శుభాంగో లోకసారంగః సుతంతుస్తంతువర్ధనః ।"
            },
            {
              "title": "ఇంద్రకర్మా మహాకర్మా కృతకర్మా కృతాగమః ॥ 84 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఉద్భవః సుందరః సుందో రత్ననాభః సులోచనః ।"
            },
            {
              "title": "అర్కో వాజసనః శృంగీ జయంతః సర్వవిజ్జయీ ॥ 85 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సువర్ణబిందురక్షోభ్యః సర్వవాగీశ్వరేశ్వరః ।"
            },
            {
              "title": "మహాహృదో మహాగర్తో మహాభూతో మహానిధిః ॥ 86 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "కుముదః కుందరః కుందః పర్జన్యః పావనోఽనిలః ।"
            },
            {
              "title": "అమృతాశోఽమృతవపుః సర్వజ్ఞః సర్వతోముఖః ॥ 87 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సులభః సువ్రతః సిద్ధః శత్రుజిచ్ఛత్రుతాపనః ।"
            },
            {
              "title": "న్యగ్రోధోఽదుంబరోఽశ్వత్థశ్చాణూరాంధ్ర నిషూదనః ॥ 88 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సహస్రార్చిః సప్తజిహ్వః సప్తైధాః సప్తవాహనః ।"
            },
            {
              "title": "అమూర్తిరనఘోఽచింత్యో భయకృద్భయనాశనః ॥ 89 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అణుర్బృహత్కృశః స్థూలో గుణభృన్నిర్గుణో మహాన్ ।"
            },
            {
              "title": "అధృతః స్వధృతః స్వాస్యః ప్రాగ్వంశో వంశవర్ధనః ॥ 90 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "భారభృత్ కథితో యోగీ యోగీశః సర్వకామదః ।"
            },
            {
              "title": "ఆశ్రమః శ్రమణః, క్షామః సుపర్ణో వాయువాహనః ॥ 91 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ధనుర్ధరో ధనుర్వేదో దండో దమయితా దమః ।"
            },
            {
              "title": "అపరాజితః సర్వసహో నియంతాఽనియమోఽయమః ॥ 92 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సత్త్వవాన్ సాత్త్వికః సత్యః సత్యధర్మపరాయణః ।"
            },
            {
              "title": "అభిప్రాయః ప్రియార్హోఽర్హః ప్రియకృత్ ప్రీతివర్ధనః ॥ 93 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "విహాయసగతిర్జ్యోతిః సురుచిర్హుతభుగ్విభుః ।"
            },
            {
              "title": "రవిర్విరోచనః సూర్యః సవితా రవిలోచనః ॥ 94 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అనంతో హుతభుగ్భోక్తా సుఖదో నైకజోఽగ్రజః ।"
            },
            {
              "title": "అనిర్విణ్ణః సదామర్షీ లోకధిష్ఠానమద్భుతః ॥ 95 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సనాత్సనాతనతమః కపిలః కపిరవ్యయః ।"
            },
            {
              "title": "స్వస్తిదః స్వస్తికృత్స్వస్తిః స్వస్తిభుక్ స్వస్తిదక్షిణః ॥ 96 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అరౌద్రః కుండలీ చక్రీ విక్రమ్యూర్జితశాసనః ।"
            },
            {
              "title": "శబ్దాతిగః శబ్దసహః శిశిరః శర్వరీకరః ॥ 97 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అక్రూరః పేశలో దక్షో దక్షిణః, క్షమిణాంవరః ।"
            },
            {
              "title": "విద్వత్తమో వీతభయః పుణ్యశ్రవణకీర్తనః ॥ 98 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఉత్తారణో దుష్కృతిహా పుణ్యో దుఃస్వప్ననాశనః ।"
            },
            {
              "title": "వీరహా రక్షణః సంతో జీవనః పర్యవస్థితః ॥ 99 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అనంతరూపోఽనంత శ్రీర్జితమన్యుర్భయాపహః ।"
            },
            {
              "title": "చతురశ్రో గభీరాత్మా విదిశో వ్యాదిశో దిశః ॥ 100 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అనాదిర్భూర్భువో లక్ష్మీః సువీరో రుచిరాంగదః ।"
            },
            {
              "title": "జననో జనజన్మాదిర్భీమో భీమపరాక్రమః ॥ 101 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఆధారనిలయోఽధాతా పుష్పహాసః ప్రజాగరః ।"
            },
            {
              "title": "ఊర్ధ్వగః సత్పథాచారః ప్రాణదః ప్రణవః పణః ॥ 102 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ప్రమాణం ప్రాణనిలయః ప్రాణభృత్ ప్రాణజీవనః ।"
            },
            {
              "title": "తత్త్వం తత్త్వవిదేకాత్మా జన్మమృత్యుజరాతిగః ॥ 103 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "భూర్భువః స్వస్తరుస్తారః సవితా ప్రపితామహః ।"
            },
            {
              "title": "యజ్ఞో యజ్ఞపతిర్యజ్వా యజ్ఞాంగో యజ్ఞవాహనః ॥ 104 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "యజ్ఞభృద్ యజ్ఞకృద్ యజ్ఞీ యజ్ఞభుక్ యజ్ఞసాధనః ।"
            },
            {
              "title": "యజ్ఞాంతకృద్ యజ్ఞగుహ్యమన్నమన్నాద ఏవ చ ॥ 105 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఆత్మయోనిః స్వయంజాతో వైఖానః సామగాయనః ।"
            },
            {
              "title": "దేవకీనందనః స్రష్టా క్షితీశః పాపనాశనః ॥ 106 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "శంఖభృన్నందకీ చక్రీ శారంగధన్వా గదాధరః ।"
            },
            {
              "title": "రథాంగపాణిరక్షోభ్యః సర్వప్రహరణాయుధః ॥ 107 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "శ్రీ సర్వప్రహరణాయుధ ఓం నమ ఇతి ।"
            },
            {
              "title": ""
            },
            {
              "title": "వనమాలీ గదీ శారంగీ శంఖీ చక్రీ చ నందకీ ।"
            },
            {
              "title": "శ్రీమాన్నారాయణో విష్ణుర్వాసుదేవోఽభిరక్షతు ॥ 108 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "శ్రీ వాసుదేవోఽభిరక్షతు ఓం నమ ఇతి ।"
            },
            {
              "title": ""
            },
            {
              "title": "ఉత్తర పీఠికా"
            },
            {
              "title": ""
            },
            {
              "title": "ఫలశ్రుతిః"
            },
            {
              "title": ""
            },
            {
              "title": "ఇతీదం కీర్తనీయస్య కేశవస్య మహాత్మనః ।"
            },
            {
              "title": "నామ్నాం సహస్రం దివ్యానామశేషేణ ప్రకీర్తితం। ॥ 1 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "య ఇదం శృణుయాన్నిత్యం యశ్చాపి పరికీర్తయేత్॥"
            },
            {
              "title": "నాశుభం ప్రాప్నుయాత్ కించిత్సోఽముత్రేహ చ మానవః ॥ 2 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "వేదాంతగో బ్రాహ్మణః స్యాత్ క్షత్రియో విజయీ భవేత్ ।"
            },
            {
              "title": "వైశ్యో ధనసమృద్ధః స్యాత్ శూద్రః సుఖమవాప్నుయాత్ ॥ 3 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ధర్మార్థీ ప్రాప్నుయాద్ధర్మమర్థార్థీ చార్థమాప్నుయాత్ ।"
            },
            {
              "title": "కామానవాప్నుయాత్ కామీ ప్రజార్థీ ప్రాప్నుయాత్ప్రజాం। ॥ 4 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "భక్తిమాన్ యః సదోత్థాయ శుచిస్తద్గతమానసః ।"
            },
            {
              "title": "సహస్రం వాసుదేవస్య నామ్నామేతత్ ప్రకీర్తయేత్ ॥ 5 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "యశః ప్రాప్నోతి విపులం యాతిప్రాధాన్యమేవ చ ।"
            },
            {
              "title": "అచలాం శ్రియమాప్నోతి శ్రేయః ప్రాప్నోత్యనుత్తమం। ॥ 6 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "న భయం క్వచిదాప్నోతి వీర్యం తేజశ్చ విందతి ।"
            },
            {
              "title": "భవత్యరోగో ద్యుతిమాన్ బలరూప గుణాన్వితః ॥ 7 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "రోగార్తో ముచ్యతే రోగాద్బద్ధో ముచ్యేత బంధనాత్ ।"
            },
            {
              "title": "భయాన్ముచ్యేత భీతస్తు ముచ్యేతాపన్న ఆపదః ॥ 8 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "దుర్గాణ్యతితరత్యాశు పురుషః పురుషోత్తమమ్ ।"
            },
            {
              "title": "స్తువన్నామసహస్రేణ నిత్యం భక్తిసమన్వితః ॥ 9 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "వాసుదేవాశ్రయో మర్త్యో వాసుదేవపరాయణః ।"
            },
            {
              "title": "సర్వపాపవిశుద్ధాత్మా యాతి బ్రహ్మ సనాతనం। ॥ 10 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "న వాసుదేవ భక్తానామశుభం విద్యతే క్వచిత్ ।"
            },
            {
              "title": "జన్మమృత్యుజరావ్యాధిభయం నైవోపజాయతే ॥ 11 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఇమం స్తవమధీయానః శ్రద్ధాభక్తిసమన్వితః ।"
            },
            {
              "title": "యుజ్యేతాత్మ సుఖక్షాంతి శ్రీధృతి స్మృతి కీర్తిభిః ॥ 12 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "న క్రోధో న చ మాత్సర్యం న లోభో నాశుభామతిః ।"
            },
            {
              "title": "భవంతి కృతపుణ్యానాం భక్తానాం పురుషోత్తమే ॥ 13 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ద్యౌః సచంద్రార్కనక్షత్రా ఖం దిశో భూర్మహోదధిః ।"
            },
            {
              "title": "వాసుదేవస్య వీర్యేణ విధృతాని మహాత్మనః ॥ 14 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ససురాసురగంధర్వం సయక్షోరగరాక్షసమ్ ।"
            },
            {
              "title": "జగద్వశే వర్తతేదం కృష్ణస్య స చరాచరం। ॥ 15 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఇంద్రియాణి మనోబుద్ధిః సత్త్వం తేజో బలం ధృతిః ।"
            },
            {
              "title": "వాసుదేవాత్మకాన్యాహుః, క్షేత్రం క్షేత్రజ్ఞ ఏవ చ ॥ 16 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సర్వాగమానామాచారః ప్రథమం పరికల్పతే ।"
            },
            {
              "title": "ఆచారప్రభవో ధర్మో ధర్మస్య ప్రభురచ్యుతః ॥ 17 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఋషయః పితరో దేవా మహాభూతాని ధాతవః ।"
            },
            {
              "title": "జంగమాజంగమం చేదం జగన్నారాయణోద్భవమ్ ॥ 18 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "యోగోజ్ఞానం తథా సాంఖ్యం విద్యాః శిల్పాదికర్మ చ ।"
            },
            {
              "title": "వేదాః శాస్త్రాణి విజ్ఞానమేతత్సర్వం జనార్దనాత్ ॥ 19 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఏకో విష్ణుర్మహద్భూతం పృథగ్భూతాన్యనేకశః ।"
            },
            {
              "title": "త్రీంలోకాన్వ్యాప్య భూతాత్మా భుంక్తే విశ్వభుగవ్యయః ॥ 20 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఇమం స్తవం భగవతో విష్ణోర్వ్యాసేన కీర్తితమ్ ।"
            },
            {
              "title": "పఠేద్య ఇచ్చేత్పురుషః శ్రేయః ప్రాప్తుం సుఖాని చ ॥ 21 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "విశ్వేశ్వరమజం దేవం జగతః ప్రభుమవ్యయం।"
            },
            {
              "title": "భజంతి యే పుష్కరాక్షం న తే యాంతి పరాభవమ్ ॥ 22 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "న తే యాంతి పరాభవం ఓం నమ ఇతి ।"
            },
            {
              "title": ""
            },
            {
              "title": "@అర్జున ఉవాచ"
            },
            {
              "title": "పద్మపత్ర విశాలాక్ష పద్మనాభ సురోత్తమ ।"
            },
            {
              "title": "భక్తానా మనురక్తానాం త్రాతా భవ జనార్దన ॥ 23 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@శ్రీభగవానువాచ"
            },
            {
              "title": "యో మాం నామసహస్రేణ స్తోతుమిచ్ఛతి పాండవ ।"
            },
            {
              "title": "సోఽహమేకేన శ్లోకేన స్తుత ఏవ న సంశయః ॥ 24 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "స్తుత ఏవ న సంశయ ఓం నమ ఇతి ।"
            },
            {
              "title": ""
            },
            {
              "title": "@వ్యాస ఉవాచ"
            },
            {
              "title": "వాసనాద్వాసుదేవస్య వాసితం భువనత్రయమ్ ।"
            },
            {
              "title": "సర్వభూతనివాసోఽసి వాసుదేవ నమోఽస్తు తే ॥ 25 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "శ్రీవాసుదేవ నమోస్తుత ఓం నమ ఇతి ।"
            },
            {
              "title": ""
            },
            {
              "title": "పార్వత్యువాచ"
            },
            {
              "title": "కేనోపాయేన లఘునా విష్ణోర్నామసహస్రకమ్ ।"
            },
            {
              "title": "పఠ్యతే పండితైర్నిత్యం శ్రోతుమిచ్ఛామ్యహం ప్రభో ॥ 26 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఈశ్వర ఉవాచ"
            },
            {
              "title": "శ్రీరామ రామ రామేతి రమే రామే మనోరమే ।"
            },
            {
              "title": "సహస్రనామ తత్తుల్యం రామనామ వరాననే ॥ 27 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "శ్రీరామ నామ వరానన ఓం నమ ఇతి ।"
            },
            {
              "title": ""
            },
            {
              "title": "@బ్రహ్మోవాచ"
            },
            {
              "title": "నమోఽస్త్వనంతాయ సహస్రమూర్తయే సహస్రపాదాక్షిశిరోరుబాహవే ।"
            },
            {
              "title": "సహస్రనామ్నే పురుషాయ శాశ్వతే సహస్రకోటీ యుగధారిణే నమః ॥ 28 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "శ్రీ సహస్రకోటీ యుగధారిణే నమ ఓం నమ ఇతి ।"
            },
            {
              "title": ""
            },
            {
              "title": "సంజయ ఉవాచ"
            },
            {
              "title": "యత్ర యోగేశ్వరః కృష్ణో యత్ర పార్థో ధనుర్ధరః ।"
            },
            {
              "title": "తత్ర శ్రీర్విజయో భూతిర్ధ్రువా నీతిర్మతిర్మమ ॥ 29 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@శ్రీ భగవాన్ ఉవాచ"
            },
            {
              "title": "అనన్యాశ్చింతయంతో మాం యే జనాః పర్యుపాసతే ।"
            },
            {
              "title": "తేషాం నిత్యాభియుక్తానాం యోగక్షేమం వహామ్యహం। ॥ 30 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "పరిత్రాణాయ సాధూనాం వినాశాయ చ దుష్కృతాం। ।"
            },
            {
              "title": "ధర్మసంస్థాపనార్థాయ సంభవామి యుగే యుగే ॥ 31 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఆర్తాః విషణ్ణాః శిథిలాశ్చ భీతాః ఘోరేషు చ వ్యాధిషు వర్తమానాః ।"
            },
            {
              "title": "సంకీర్త్య నారాయణశబ్దమాత్రం విముక్తదుఃఖాః సుఖినో భవంతి ॥ 32 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "కాయేన వాచా మనసేంద్రియైర్వా బుద్ధ్యాత్మనా వా ప్రకృతేః స్వభావాత్ ।"
            },
            {
              "title": "కరోమి యద్యత్సకలం పరస్మై నారాయణాయేతి సమర్పయామి ॥ 33 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "యదక్షర పదభ్రష్టం మాత్రాహీనం తు యద్భవేత్"
            },
            {
              "title": "తథ్సర్వం క్షమ్యతాం దేవ నారాయణ నమోఽస్తు తే ।"
            },
            {
              "title": "విసర్గ బిందు మాత్రాణి పదపాదాక్షరాణి చ"
            },
            {
              "title": "న్యూనాని చాతిరిక్తాని క్షమస్వ పురుషోత్తమః ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఇతి శ్రీ మహాభారతే శతసాహస్రికాయాం సంహితాయాం వైయాసిక్యామనుశాసన పర్వాంతర్గత ఆనుశాసనిక పర్వణి, మోక్షధర్మే భీష్మ యుధిష్ఠిర సంవాదే శ్రీ విష్ణోర్దివ్య సహస్రనామ స్తోత్రం నామైకోన పంచ శతాధిక శతతమోధ్యాయః ॥ శ్రీ విష్ణు సహస్రనామ స్తోత్రం సమాప్తమ్ ॥"
            },
            {
              "title": "ఓం తత్సత్ సర్వం శ్రీ కృష్ణార్పణమస్తు ॥"
            }
          ],
          "title": "శ్రీ విష్ణు సహస్ర నామ స్తోత్రం",
         //  "img": require('../imgs/Stotramulu/Vishnu.png')
        },
        {
          "sotram": [
            {
              "title": "అనాయాసేన మరణం"
            },
            {
              "title": "వినా ధైన్యేన జీవనం"
            },
            {
              "title": "దేహాంతే తవ సాన్నిధ్యం"
            },
            {
              "title": "దేహిమే పరమేశ్వరం"
            },
            {
              "title": ""
            },
            {
              "title": "ఓ పరమేశ్వరా! కష్టతరంకాని (శ్రమలేని) మరణాన్నీ, ఇతరుల దయమీద ఆధారపడని జీవనాన్నీ, ఈ శరీరాన్ని విడచిన తరువాత (మరణానంతరం) నీ సాన్నిధ్యాన్నీ నాకు ప్రసాదించు."
            }
          ],
          "title": "పరమ శివుడి స్తోత్రం",
         //  "img": require('../imgs/Stotramulu/Siva.png')
        },
        {
          "sotram": [
            {
              "title": "జ్ఞానానందమయం దేవం"
            },
            {
              "title": "నిర్మలస్ఫటికాకృతిం"
            },
            {
              "title": "ఆధారం సర్వవిద్యానాం"
            },
            {
              "title": "హయగ్రీవముపాస్మహే"
            },
            {
              "title": ""
            },
            {
              "title": "జ్ఞానంతో నిండి ఉన్న కారణంగా ఆనందంగా కనిపించేవాడూ, స్ఫటికంలా నిష్కల్మషుడై, సర్వవిద్యలకూ ఆధారభూతుడైన హయగ్రీవుడిని నేను ఉపాసిస్తున్నాను."
            }
          ],
          "title": "హయగ్రీవ స్తోత్రం",
         //  "img": require('../imgs/Stotramulu/Haygreeva.png')
        },
        {
          "sotram": [
            {
              "title": "@నవగ్రహ ధ్యాన శ్లోకం"
            },
            {
              "title": "ఆదిత్యాయ చ సోమాయ మంగళాయ బుధాయ చ ।"
            },
            {
              "title": "గురు శుక్ర శనిభ్యశ్చ రాహవే కేతవే నమః ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@రవిః"
            },
            {
              "title": "జపాకుసుమ సంకాశం కాశ్యపేయం మహాద్యుతిమ్ ।"
            },
            {
              "title": "తమోఽరిం సర్వ పాపఘ్నం ప్రణతోస్మి దివాకరమ్ ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@చంద్రః"
            },
            {
              "title": "దథిశంఖ తుషారాభం క్షీరార్ణవ సముద్భవం (క్షీరోదార్ణవ సంభవం) ।"
            },
            {
              "title": "నమామి శశినం సోమం శంభో-ర్మకుట భూషణమ్ ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@కుజః"
            },
            {
              "title": "ధరణీ గర్భ సంభూతం విద్యుత్కాంతి సమప్రభమ్ ।"
            },
            {
              "title": "కుమారం శక్తిహస్తం తం మంగళం ప్రణమామ్యహమ్ ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@బుధః"
            },
            {
              "title": "ప్రియంగు కలికాశ్యామం రూపేణా ప్రతిమం బుధమ్ ।"
            },
            {
              "title": "సౌమ్యం సౌమ్య (సత్వ) గుణోపేతం తం బుధం ప్రణమామ్యహమ్ ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@గురుః"
            },
            {
              "title": "దేవానాం చ ఋషీణాం చ గురుం కాంచనసన్నిభమ్ ।"
            },
            {
              "title": "బుద్ధిమంతం త్రిలోకేశం తం నమామి బృహస్పతిమ్ ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@శుక్రః"
            },
            {
              "title": "హిమకుంద మృణాళాభం దైత్యానం పరమం గురుమ్ ।"
            },
            {
              "title": "సర్వశాస్త్ర ప్రవక్తారం భార్గవం ప్రణమామ్యహమ్ ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@శనిః"
            },
            {
              "title": "నీలాంజన సమాభాసం రవిపుత్రం యమాగ్రజమ్ ।"
            },
            {
              "title": "ఛాయా మార్తాండ సంభూతం తం నమామి శనైశ్చరమ్ ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@రాహుః"
            },
            {
              "title": "అర్ధకాయం మహావీరం చంద్రాదిత్య విమర్ధనమ్ ।"
            },
            {
              "title": "సింహికా గర్భ సంభూతం తం రాహుం ప్రణమామ్యహమ్ ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@కేతుః"
            },
            {
              "title": "ఫలాశ పుష్ప సంకాశం తారకాగ్రహమస్తకమ్ ।"
            },
            {
              "title": "రౌద్రం రౌద్రాత్మకం ఘోరం తం కేతుం ప్రణమామ్యహమ్ ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఫలశ్రుతిః"
            },
            {
              "title": "ఇతి వ్యాస ముఖోద్గీతం యః పఠేత్సు సమాహితః ।"
            },
            {
              "title": "దివా వా యది వా రాత్రౌ విఘ్నశాంతి-ర్భవిష్యతి ॥"
            },
            {
              "title": ""
            },
            {
              "title": "నరనారీ-నృపాణాం చ భవే-ద్దుఃస్వప్న-నాశనమ్ ।"
            },
            {
              "title": "ఐశ్వర్యమతులం తేషామారోగ్యం పుష్టి వర్ధనమ్ ॥"
            },
            {
              "title": ""
            },
            {
              "title": "గ్రహనక్షత్రజాః పీడాస్తస్కరాగ్ని సముద్భవాః ।"
            },
            {
              "title": "తాస్సర్వాః ప్రశమం యాంతి వ్యాసో బ్రూతే న సంశయః ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఇతి వ్యాస విరచితం నవగ్రహ స్తోత్రం సంపూర్ణమ్ ।"
            }
          ],
          "title": "నవగ్రహ స్తోత్రం",
         //  "img": require('../imgs/Stotramulu/Navagrahalu.png')
        },
        {
          "sotram": [
            {
              "title": "@ఆదిలక్ష్మి"
            },
            {
              "title": "సుమనస వందిత సుందరి మాధవి, చంద్ర సహొదరి హేమమయే"
            },
            {
              "title": "మునిగణ వందిత మోక్షప్రదాయని, మంజుల భాషిణి వేదనుతే ।"
            },
            {
              "title": "పంకజవాసిని దేవ సుపూజిత, సద్గుణ వర్షిణి శాంతియుతే"
            },
            {
              "title": "జయ జయహే మధుసూదన కామిని, ఆదిలక్ష్మి పరిపాలయ మామ్ ॥ 1 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@ధాన్యలక్ష్మి"
            },
            {
              "title": "అయికలి కల్మష నాశిని కామిని, వైదిక రూపిణి వేదమయే"
            },
            {
              "title": "క్షీర సముద్భవ మంగళ రూపిణి, మంత్రనివాసిని మంత్రనుతే ।"
            },
            {
              "title": "మంగళదాయిని అంబుజవాసిని, దేవగణాశ్రిత పాదయుతే"
            },
            {
              "title": "జయ జయహే మధుసూదన కామిని, ధాన్యలక్ష్మి పరిపాలయ మామ్ ॥ 2 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@ధైర్యలక్ష్మి"
            },
            {
              "title": "జయవరవర్షిణి వైష్ణవి భార్గవి, మంత్ర స్వరూపిణి మంత్రమయే"
            },
            {
              "title": "సురగణ పూజిత శీఘ్ర ఫలప్రద, జ్ఞాన వికాసిని శాస్త్రనుతే ।"
            },
            {
              "title": "భవభయహారిణి పాపవిమోచని, సాధు జనాశ్రిత పాదయుతే"
            },
            {
              "title": "జయ జయహే మధు సూధన కామిని, ధైర్యలక్ష్మీ పరిపాలయ మామ్ ॥ 3 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@గజలక్ష్మి"
            },
            {
              "title": "జయ జయ దుర్గతి నాశిని కామిని, సర్వఫలప్రద శాస్త్రమయే"
            },
            {
              "title": "రధగజ తురగపదాతి సమావృత, పరిజన మండిత లోకనుతే ।"
            },
            {
              "title": "హరిహర బ్రహ్మ సుపూజిత సేవిత, తాప నివారిణి పాదయుతే"
            },
            {
              "title": "జయ జయహే మధుసూదన కామిని, గజలక్ష్మీ రూపేణ పాలయ మామ్ ॥ 4 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@సంతానలక్ష్మి"
            },
            {
              "title": "అయిఖగ వాహిని మోహిని చక్రిణి, రాగవివర్ధిని జ్ఞానమయే"
            },
            {
              "title": "గుణగణవారధి లోకహితైషిణి, సప్తస్వర భూషిత గాననుతే ।"
            },
            {
              "title": "సకల సురాసుర దేవ మునీశ్వర, మానవ వందిత పాదయుతే"
            },
            {
              "title": "జయ జయహే మధుసూదన కామిని, సంతానలక్ష్మీ పరిపాలయ మామ్ ॥ 5 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@విజయలక్ష్మి"
            },
            {
              "title": "జయ కమలాసిని సద్గతి దాయిని, జ్ఞానవికాసిని గానమయే"
            },
            {
              "title": "అనుదిన మర్చిత కుంకుమ ధూసర, భూషిత వాసిత వాద్యనుతే ।"
            },
            {
              "title": "కనకధరాస్తుతి వైభవ వందిత, శంకరదేశిక మాన్యపదే"
            },
            {
              "title": "జయ జయహే మధుసూదన కామిని, విజయలక్ష్మీ పరిపాలయ మామ్ ॥ 6 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@విద్యాలక్ష్మి"
            },
            {
              "title": "ప్రణత సురేశ్వరి భారతి భార్గవి, శోకవినాశిని రత్నమయే"
            },
            {
              "title": "మణిమయ భూషిత కర్ణవిభూషణ, శాంతి సమావృత హాస్యముఖే ।"
            },
            {
              "title": "నవనిధి దాయిని కలిమలహారిణి, కామిత ఫలప్రద హస్తయుతే"
            },
            {
              "title": "జయ జయహే మధుసూదన కామిని, విద్యాలక్ష్మీ సదా పాలయ మామ్ ॥ 7 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@ధనలక్ష్మి"
            },
            {
              "title": "ధిమిధిమి ధింధిమి ధింధిమి-దింధిమి, దుంధుభి నాద సుపూర్ణమయే"
            },
            {
              "title": "ఘుమఘుమ ఘుంఘుమ ఘుంఘుమ ఘుంఘుమ, శంఖ నినాద సువాద్యనుతే ।"
            },
            {
              "title": "వేద పూరాణేతిహాస సుపూజిత, వైదిక మార్గ ప్రదర్శయుతే"
            },
            {
              "title": "జయ జయహే మధుసూదన కామిని, ధనలక్ష్మి రూపేణా పాలయ మామ్ ॥ 8 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@ఫలశృతి"
            },
            {
              "title": "శ్లో॥ అష్టలక్ష్మీ నమస్తుభ్యం వరదే కామరూపిణి ।"
            },
            {
              "title": "విష్ణువక్షః స్థలా రూఢే భక్త మోక్ష ప్రదాయిని ॥"
            },
            {
              "title": ""
            },
            {
              "title": "శ్లో॥ శంఖ చక్రగదాహస్తే విశ్వరూపిణితే జయః ।"
            },
            {
              "title": "జగన్మాత్రే చ మోహిన్యై మంగళం శుభ మంగళమ్ ॥"
            }
          ],
          "title": "అష్ట లక్ష్మీ స్తోత్రం",
         //  "img": require('../imgs/Stotramulu/AshtaLakshmi.png')
        },
        {
          "sotram": [
            {
              "title": "వందే వందారు మందారమిందిరానంద కందలం"
            },
            {
              "title": "అమందానంద సందోహ బంధురం సింధురాననం"
            },
            {
              "title": ""
            },
            {
              "title": "అంగం హరేః పులకభూషణమాశ్రయంతీ"
            },
            {
              "title": "భృంగాంగనేవ ముకుళాభరణం తమాలమ్ ।"
            },
            {
              "title": "అంగీకృతాఖిల విభూతిరపాంగలీలా"
            },
            {
              "title": "మాంగల్యదాస్తు మమ మంగళదేవతాయాః ॥ 1 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ముగ్ధా ముహుర్విదధతీ వదనే మురారేః"
            },
            {
              "title": "ప్రేమత్రపాప్రణిహితాని గతాగతాని ।"
            },
            {
              "title": "మాలాదృశోర్మధుకరీవ మహోత్పలే యా"
            },
            {
              "title": "సా మే శ్రియం దిశతు సాగర సంభవా యాః ॥ 2 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఆమీలితాక్షమధిగ్యమ ముదా ముకుందం"
            },
            {
              "title": "ఆనందకందమనిమేషమనంగ తంత్రమ్ ।"
            },
            {
              "title": "ఆకేకరస్థితకనీనికపక్ష్మనేత్రం"
            },
            {
              "title": "భూత్యై భవన్మమ భుజంగ శయాంగనా యాః ॥ 3 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "బాహ్వంతరే మధుజితః శ్రితకౌస్తుభే యా"
            },
            {
              "title": "హారావళీవ హరినీలమయీ విభాతి ।"
            },
            {
              "title": "కామప్రదా భగవతోఽపి కటాక్షమాలా"
            },
            {
              "title": "కళ్యాణమావహతు మే కమలాలయా యాః ॥ 4 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "కాలాంబుదాళి లలితోరసి కైటభారేః"
            },
            {
              "title": "ధారాధరే స్ఫురతి యా తటిదంగనేవ ।"
            },
            {
              "title": "మాతుస్సమస్తజగతాం మహనీయమూర్తిః"
            },
            {
              "title": "భద్రాణి మే దిశతు భార్గవనందనా యాః ॥ 5 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ప్రాప్తం పదం ప్రథమతః ఖలు యత్ప్రభావాత్"
            },
            {
              "title": "మాంగల్యభాజి మధుమాథిని మన్మథేన ।"
            },
            {
              "title": "మయ్యాపతేత్తదిహ మంథరమీక్షణార్థం"
            },
            {
              "title": "మందాలసం చ మకరాలయ కన్యకా యాః ॥ 6 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "విశ్వామరేంద్ర పద విభ్రమ దానదక్షం"
            },
            {
              "title": "ఆనందహేతురధికం మురవిద్విషోఽపి ।"
            },
            {
              "title": "ఈషన్నిషీదతు మయి క్షణమీక్షణార్థం"
            },
            {
              "title": "ఇందీవరోదర సహోదరమిందిరా యాః ॥ 7 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఇష్టా విశిష్టమతయోపి యయా దయార్ద్ర"
            },
            {
              "title": "దృష్ట్యా త్రివిష్టపపదం సులభం లభంతే ।"
            },
            {
              "title": "దృష్టిః ప్రహృష్ట కమలోదర దీప్తిరిష్టాం"
            },
            {
              "title": "పుష్టిం కృషీష్ట మమ పుష్కర విష్టరా యాః ॥ 8 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "దద్యాద్దయాను పవనో ద్రవిణాంబుధారాం"
            },
            {
              "title": "అస్మిన్నకించన విహంగ శిశౌ విషణ్ణే ।"
            },
            {
              "title": "దుష్కర్మఘర్మమపనీయ చిరాయ దూరం"
            },
            {
              "title": "నారాయణ ప్రణయినీ నయనాంబువాహః ॥ 9 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "గీర్దేవతేతి గరుడధ్వజ సుందరీతి"
            },
            {
              "title": "శాకంబరీతి శశిశేఖర వల్లభేతి ।"
            },
            {
              "title": "సృష్టి స్థితి ప్రళయ కేళిషు సంస్థితాయై"
            },
            {
              "title": "తస్యై నమస్త్రిభువనైక గురోస్తరుణ్యై ॥ 10 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "శ్రుత్యై నమోఽస్తు శుభకర్మ ఫలప్రసూత్యై"
            },
            {
              "title": "రత్యై నమోఽస్తు రమణీయ గుణార్ణవాయై ।"
            },
            {
              "title": "శక్త్యై నమోఽస్తు శతపత్ర నికేతనాయై"
            },
            {
              "title": "పుష్ట్యై నమోఽస్తు పురుషోత్తమ వల్లభాయై ॥ 11 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "నమోఽస్తు నాళీక నిభాననాయై"
            },
            {
              "title": "నమోఽస్తు దుగ్ధోదధి జన్మభూమ్యై ।"
            },
            {
              "title": "నమోఽస్తు సోమామృత సోదరాయై"
            },
            {
              "title": "నమోఽస్తు నారాయణ వల్లభాయై ॥ 12 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "నమోఽస్తు హేమాంబుజ పీఠికాయై"
            },
            {
              "title": "నమోఽస్తు భూమండల నాయికాయై ।"
            },
            {
              "title": "నమోఽస్తు దేవాది దయాపరాయై"
            },
            {
              "title": "నమోఽస్తు శారంగాయుధ వల్లభాయై ॥ 13 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "నమోఽస్తు దేవ్యై భృగునందనాయై"
            },
            {
              "title": "నమోఽస్తు విష్ణోరురసి స్థితాయై ।"
            },
            {
              "title": "నమోఽస్తు లక్ష్మ్యై కమలాలయాయై"
            },
            {
              "title": "నమోఽస్తు దామోదర వల్లభాయై ॥ 14 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "నమోఽస్తు కాంత్యై కమలేక్షణాయై"
            },
            {
              "title": "నమోఽస్తు భూత్యై భువనప్రసూత్యై ।"
            },
            {
              "title": "నమోఽస్తు దేవాదిభిరర్చితాయై"
            },
            {
              "title": "నమోఽస్తు నందాత్మజ వల్లభాయై ॥ 15 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సంపత్కరాణి సకలేంద్రియ నందనాని"
            },
            {
              "title": "సామ్రాజ్య దానవిభవాని సరోరుహాక్షి ।"
            },
            {
              "title": "త్వద్వందనాని దురితా హరణోద్యతాని"
            },
            {
              "title": "మామేవ మాతరనిశం కలయంతు మాన్యే ॥ 16 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "యత్కటాక్ష సముపాసనా విధిః"
            },
            {
              "title": "సేవకస్య సకలార్థ సంపదః ।"
            },
            {
              "title": "సంతనోతి వచనాంగ మానసైః"
            },
            {
              "title": "త్వాం మురారిహృదయేశ్వరీం భజే ॥ 17 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సరసిజనిలయే సరోజహస్తే"
            },
            {
              "title": "ధవళతమాంశుక గంధమాల్యశోభే ।"
            },
            {
              "title": "భగవతి హరివల్లభే మనోజ్ఞే"
            },
            {
              "title": "త్రిభువనభూతికరీ ప్రసీదమహ్యమ్ ॥ 18 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "దిగ్ఘస్తిభిః కనక కుంభముఖావసృష్ట"
            },
            {
              "title": "స్వర్వాహినీ విమలచారుజలాప్లుతాంగీమ్ ।"
            },
            {
              "title": "ప్రాతర్నమామి జగతాం జననీమశేష"
            },
            {
              "title": "లోకధినాథ గృహిణీమమృతాబ్ధిపుత్రీమ్ ॥ 19 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "కమలే కమలాక్ష వల్లభే త్వం"
            },
            {
              "title": "కరుణాపూర తరంగితైరపాంగైః ।"
            },
            {
              "title": "అవలోకయ మామకించనానాం"
            },
            {
              "title": "ప్రథమం పాత్రమకృతిమం దయాయాః ॥ 20 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "దేవి ప్రసీద జగదీశ్వరి లోకమాతః"
            },
            {
              "title": "కళ్యాణగాత్రి కమలేక్షణ జీవనాథే ।"
            },
            {
              "title": "దారిద్ర్యభీతిహృదయం శరణాగతం మాం"
            },
            {
              "title": "ఆలోకయ ప్రతిదినం సదయైరపాంగైః ॥ 21 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "స్తువంతి యే స్తుతిభిరమీభిరన్వహం"
            },
            {
              "title": "త్రయీమయీం త్రిభువనమాతరం రమామ్ ।"
            },
            {
              "title": "గుణాధికా గురుతుర భాగ్య భాగినః"
            },
            {
              "title": "భవంతి తే భువి బుధ భావితాశయాః ॥ 22 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సువర్ణధారా స్తోత్రం యచ్ఛంకరాచార్య నిర్మితం"
            },
            {
              "title": "త్రిసంధ్యం యః పఠేన్నిత్యం స కుబేరసమో భవేత్ ॥"
            }
          ],
          "title": "కనకధారా స్తోత్రం",
         //  "img": require('../imgs/Stotramulu/LakshmiDevi.png')
        },
        {
          "sotram": [
            {
              "title": "నారాయణ నారాయణ జయ గోవింద హరే ॥"
            },
            {
              "title": "నారాయణ నారాయణ జయ గోపాల హరే ॥"
            },
            {
              "title": ""
            },
            {
              "title": "కరుణాపారావార వరుణాలయగంభీర నారాయణ ॥ 1 ॥"
            },
            {
              "title": "ఘననీరదసంకాశ కృతకలికల్మషనాశన నారాయణ ॥ 2 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "యమునాతీరవిహార ధృతకౌస్తుభమణిహార నారాయణ ॥ 3 ॥"
            },
            {
              "title": "పీతాంబరపరిధాన సురకళ్యాణనిధాన నారాయణ ॥ 4 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "మంజులగుంజాభూష మాయామానుషవేష నారాయణ ॥ 5 ॥"
            },
            {
              "title": "రాధాధరమధురసిక రజనీకరకులతిలక నారాయణ ॥ 6 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "మురళీగానవినోద వేదస్తుతభూపాద నారాయణ ॥ 7 ॥"
            },
            {
              "title": "బర్హినిబర్హాపీడ నటనాటకఫణిక్రీడ నారాయణ ॥ 8 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "వారిజభూషాభరణ రాజీవరుక్మిణీరమణ నారాయణ ॥ 9 ॥"
            },
            {
              "title": "జలరుహదళనిభనేత్ర జగదారంభకసూత్ర నారాయణ ॥ 10 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "పాతకరజనీసంహార కరుణాలయ మాముద్ధర నారాయణ ॥ 11 ॥"
            },
            {
              "title": "అఘ బకహయకంసారే కేశవ కృష్ణ మురారే నారాయణ ॥ 12 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "హాటకనిభపీతాంబర అభయం కురు మే మావర నారాయణ ॥ 13 ॥"
            },
            {
              "title": "దశరథరాజకుమార దానవమదసంహార నారాయణ ॥ 14 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "గోవర్ధనగిరి రమణ గోపీమానసహరణ నారాయణ ॥ 15 ॥"
            },
            {
              "title": "సరయుతీరవిహార సజ్జన^^ఋషిమందార నారాయణ ॥ 16 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "విశ్వామిత్రమఖత్ర వివిధవరానుచరిత్ర నారాయణ ॥ 17 ॥"
            },
            {
              "title": "ధ్వజవజ్రాంకుశపాద ధరణీసుతసహమోద నారాయణ ॥ 18 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "జనకసుతాప్రతిపాల జయ జయ సంస్మృతిలీల నారాయణ ॥ 19 ॥"
            },
            {
              "title": "దశరథవాగ్ధృతిభార దండక వనసంచార నారాయణ ॥ 20 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ముష్టికచాణూరసంహార మునిమానసవిహార నారాయణ ॥ 21 ॥"
            },
            {
              "title": "వాలివినిగ్రహశౌర్య వరసుగ్రీవహితార్య నారాయణ ॥ 22 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "మాం మురళీకర ధీవర పాలయ పాలయ శ్రీధర నారాయణ ॥ 23 ॥"
            },
            {
              "title": "జలనిధి బంధన ధీర రావణకంఠవిదార నారాయణ ॥ 24 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "తాటకమర్దన రామ నటగుణవివిధ సురామ నారాయణ ॥ 25 ॥"
            },
            {
              "title": "గౌతమపత్నీపూజన కరుణాఘనావలోకన నారాయణ ॥ 26 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సంభ్రమసీతాహార సాకేతపురవిహార నారాయణ ॥ 27 ॥"
            },
            {
              "title": "అచలోద్ధృతచంచత్కర భక్తానుగ్రహతత్పర నారాయణ ॥ 28 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "నైగమగానవినోద రక్షిత సుప్రహ్లాద నారాయణ ॥ 29 ॥"
            },
            {
              "title": "భారత యతవరశంకర నామామృతమఖిలాంతర నారాయణ ॥ 30 ॥"
            }
          ],
          "title": "నారాయణ స్తోత్రం",
         //  "img": require('../imgs/Stotramulu/Vishnu.png')
        },
        {
          "sotram": [
            {
              "title": "ఓం అస్య శ్రీ రామరక్షా స్తోత్రమంత్రస్య"
            },
            {
              "title": "బుధకౌశిక ఋషిః"
            },
            {
              "title": "శ్రీ సీతారామ చంద్రోదేవతా"
            },
            {
              "title": "అనుష్టుప్ ఛందః"
            },
            {
              "title": "సీతా శక్తిః"
            },
            {
              "title": "శ్రీమద్ హనుమాన్ కీలకం"
            },
            {
              "title": "శ్రీరామచంద్ర ప్రీత్యర్థే రామరక్షా స్తోత్రజపే వినియోగః ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@ధ్యానం"
            },
            {
              "title": "ధ్యాయేదాజానుబాహుం ధృతశర ధనుషం బద్ధ పద్మాసనస్థం"
            },
            {
              "title": "పీతం వాసోవసానం నవకమల దళస్పర్థి నేత్రం ప్రసన్నమ్ ।"
            },
            {
              "title": "వామాంకారూఢ సీతాముఖ కమలమిలల్లోచనం నీరదాభం"
            },
            {
              "title": "నానాలంకార దీప్తం దధతమురు జటామండలం రామచంద్రమ్ ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@స్తోత్రం"
            },
            {
              "title": "చరితం రఘునాథస్య శతకోటి ప్రవిస్తరమ్ ।"
            },
            {
              "title": "ఏకైకమక్షరం పుంసాం మహాపాతక నాశనమ్ ॥ 1 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ధ్యాత్వా నీలోత్పల శ్యామం రామం రాజీవలోచనమ్ ।"
            },
            {
              "title": "జానకీ లక్ష్మణోపేతం జటాముకుట మండితమ్ ॥ 2 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సాసితూణ ధనుర్బాణ పాణిం నక్తం చరాంతకమ్ ।"
            },
            {
              "title": "స్వలీలయా జగత్త్రాతు మావిర్భూతమజం విభుమ్ ॥ 3 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "రామరక్షాం పఠేత్ప్రాజ్ఞః పాపఘ్నీం సర్వకామదామ్ ।"
            },
            {
              "title": "శిరో మే రాఘవః పాతు ఫాలం దశరథాత్మజః ॥ 4 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "కౌసల్యేయో దృశౌపాతు విశ్వామిత్రప్రియః శృతీ ।"
            },
            {
              "title": "ఘ్రాణం పాతు మఖత్రాతా ముఖం సౌమిత్రివత్సలః ॥ 5 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "జిహ్వాం విద్యానిధిః పాతు కంఠం భరతవందితః ।"
            },
            {
              "title": "స్కంధౌ దివ్యాయుధః పాతు భుజౌ భగ్నేశకార్ముకః ॥ 6 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "కరౌ సీతాపతిః పాతు హృదయం జామదగ్న్యజిత్ ।"
            },
            {
              "title": "మధ్యం పాతు ఖరధ్వంసీ నాభిం జాంబవదాశ్రయః ॥ 7 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సుగ్రీవేశః కటిం పాతు సక్థినీ హనుమత్-ప్రభుః ।"
            },
            {
              "title": "ఊరూ రఘూత్తమః పాతు రక్షఃకుల వినాశకృత్ ॥ 8 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "జానునీ సేతుకృత్-పాతు జంఘే దశముఖాంతకః ।"
            },
            {
              "title": "పాదౌ విభీషణశ్రీదః పాతు రామోఽఖిలం వపుః ॥ 9 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఏతాం రామబలోపేతాం రక్షాం యః సుకృతీ పఠేత్ ।"
            },
            {
              "title": "స చిరాయుః సుఖీ పుత్రీ విజయీ వినయీ భవేత్ ॥ 10 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "పాతాళ-భూతల-వ్యోమ-చారిణ-శ్చద్మ-చారిణః ।"
            },
            {
              "title": "న ద్రష్టుమపి శక్తాస్తే రక్షితం రామనామభిః ॥ 11 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "రామేతి రామభద్రేతి రామచంద్రేతి వా స్మరన్ ।"
            },
            {
              "title": "నరో న లిప్యతే పాపైర్భుక్తిం ముక్తిం చ విందతి ॥ 12 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "జగజ్జైత్రైక మంత్రేణ రామనామ్నాభి రక్షితమ్ ।"
            },
            {
              "title": "యః కంఠే ధారయేత్తస్య కరస్థాః సర్వసిద్ధయః ॥ 13 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "వజ్రపంజర నామేదం యో రామకవచం స్మరేత్ ।"
            },
            {
              "title": "అవ్యాహతాజ్ఞః సర్వత్ర లభతే జయమంగళమ్ ॥ 14 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఆదిష్టవాన్-యథా స్వప్నే రామరక్షామిమాం హరః ।"
            },
            {
              "title": "తథా లిఖితవాన్-ప్రాతః ప్రబుద్ధౌ బుధకౌశికః ॥ 15 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఆరామః కల్పవృక్షాణాం విరామః సకలాపదామ్ ।"
            },
            {
              "title": "అభిరామ-స్త్రిలోకానాం రామః శ్రీమాన్ స నః ప్రభుః ॥ 16 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "తరుణౌ రూపసంపన్నౌ సుకుమారౌ మహాబలౌ ।"
            },
            {
              "title": "పుండరీక విశాలాక్షౌ చీరకృష్ణాజినాంబరౌ ॥ 17 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఫలమూలాశినౌ దాంతౌ తాపసౌ బ్రహ్మచారిణౌ ।"
            },
            {
              "title": "పుత్రౌ దశరథస్యైతౌ భ్రాతరౌ రామలక్ష్మణౌ ॥ 18 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "శరణ్యౌ సర్వసత్త్వానాం శ్రేష్ఠౌ సర్వధనుష్మతామ్ ।"
            },
            {
              "title": "రక్షఃకుల నిహంతారౌ త్రాయేతాం నో రఘూత్తమౌ ॥ 19 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఆత్త సజ్య ధనుషా విషుస్పృశా వక్షయాశుగ నిషంగ సంగినౌ ।"
            },
            {
              "title": "రక్షణాయ మమ రామలక్షణావగ్రతః పథి సదైవ గచ్ఛతామ్ ॥ 20 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సన్నద్ధః కవచీ ఖడ్గీ చాపబాణధరో యువా ।"
            },
            {
              "title": "గచ్ఛన్ మనోరథాన్నశ్చ (మనోరథోఽస్మాకం) రామః పాతు స లక్ష్మణః ॥ 21 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "రామో దాశరథి శ్శూరో లక్ష్మణానుచరో బలీ ।"
            },
            {
              "title": "కాకుత్సః పురుషః పూర్ణః కౌసల్యేయో రఘూత్తమః ॥ 22 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "వేదాంతవేద్యో యజ్ఞేశః పురాణ పురుషోత్తమః ।"
            },
            {
              "title": "జానకీవల్లభః శ్రీమానప్రమేయ పరాక్రమః ॥ 23 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఇత్యేతాని జపేన్నిత్యం మద్భక్తః శ్రద్ధయాన్వితః ।"
            },
            {
              "title": "అశ్వమేధాధికం పుణ్యం సంప్రాప్నోతి న సంశయః ॥ 24 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "రామం దూర్వాదళ శ్యామం పద్మాక్షం పీతవాససమ్ ।"
            },
            {
              "title": "స్తువంతి నాభి-ర్దివ్యై-ర్నతే సంసారిణో నరాః ॥ 25 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "రామం లక్ష్మణ పూర్వజం రఘువరం సీతాపతిం సుందరం"
            },
            {
              "title": "కాకుత్స్థం కరుణార్ణవం గుణనిధిం విప్రప్రియం ధార్మికమ్ ।"
            },
            {
              "title": "రాజేంద్రం సత్యసంధం దశరథతనయం శ్యామలం శాంతమూర్తిం"
            },
            {
              "title": "వందే లోకాభిరామం రఘుకుల తిలకం రాఘవం రావణారిమ్ ॥ 26 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "రామాయ రామభద్రాయ రామచంద్రాయ వేధసే ।"
            },
            {
              "title": "రఘునాథాయ నాథాయ సీతాయాః పతయే నమః ॥ 27 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "శ్రీరామ రామ రఘునందన రామ రామ"
            },
            {
              "title": "శ్రీరామ రామ భరతాగ్రజ రామ రామ ।"
            },
            {
              "title": "శ్రీరామ రామ రణకర్కశ రామ రామ"
            },
            {
              "title": "శ్రీరామ రామ శరణం భవ రామ రామ ॥ 28 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "శ్రీరామ చంద్ర చరణౌ మనసా స్మరామి"
            },
            {
              "title": "శ్రీరామ చంద్ర చరణౌ వచసా గృహ్ణామి ।"
            },
            {
              "title": "శ్రీరామ చంద్ర చరణౌ శిరసా నమామి"
            },
            {
              "title": "శ్రీరామ చంద్ర చరణౌ శరణం ప్రపద్యే ॥ 29 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "మాతా రామో మత్-పితా రామచంద్రః"
            },
            {
              "title": "స్వామీ రామో మత్-సఖా రామచంద్రః ।"
            },
            {
              "title": "సర్వస్వం మే రామచంద్రో దయాళుః"
            },
            {
              "title": "నాన్యం జానే నైవ న జానే ॥ 30 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "దక్షిణే లక్ష్మణో యస్య వామే చ (తు) జనకాత్మజా ।"
            },
            {
              "title": "పురతో మారుతిర్యస్య తం వందే రఘునందనమ్ ॥ 31 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "లోకాభిరామం రణరంగధీరం"
            },
            {
              "title": "రాజీవనేత్రం రఘువంశనాథమ్ ।"
            },
            {
              "title": "కారుణ్యరూపం కరుణాకరం తం"
            },
            {
              "title": "శ్రీరామచంద్రం శరణ్యం ప్రపద్యే ॥ 32 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "మనోజవం మారుత తుల్య వేగం"
            },
            {
              "title": "జితేంద్రియం బుద్ధిమతాం వరిష్టమ్ ।"
            },
            {
              "title": "వాతాత్మజం వానరయూథ ముఖ్యం"
            },
            {
              "title": "శ్రీరామదూతం శరణం ప్రపద్యే ॥ 33 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "కూజంతం రామరామేతి మధురం మధురాక్షరమ్ ।"
            },
            {
              "title": "ఆరుహ్యకవితా శాఖాం వందే వాల్మీకి కోకిలమ్ ॥ 34 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఆపదామపహర్తారం దాతారం సర్వసంపదామ్ ।"
            },
            {
              "title": "లోకాభిరామం శ్రీరామం భూయోభూయో నమామ్యహమ్ ॥ 35 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "భర్జనం భవబీజానామర్జనం సుఖసంపదామ్ ।"
            },
            {
              "title": "తర్జనం యమదూతానాం రామ రామేతి గర్జనమ్ ॥ 36 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "రామో రాజమణిః సదా విజయతే రామం రమేశం భజే"
            },
            {
              "title": "రామేణాభిహతా నిశాచరచమూ రామాయ తస్మై నమః ।"
            },
            {
              "title": "రామాన్నాస్తి పరాయణం పరతరం రామస్య దాసోస్మ్యహం"
            },
            {
              "title": "రామే చిత్తలయః సదా భవతు మే భో రామ మాముద్ధర ॥ 37 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "శ్రీరామ రామ రామేతి రమే రామే మనోరమే ।"
            },
            {
              "title": "సహస్రనామ తత్తుల్యం రామ నామ వరాననే ॥ 38 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఇతి శ్రీబుధకౌశికముని విరచితం శ్రీరామ రక్షాస్తోత్రం సంపూర్ణమ్ ।"
            },
            {
              "title": ""
            },
            {
              "title": "శ్రీరామ జయరామ జయజయరామ ।"
            }
          ],
          "title": "శ్రీ రామ రక్షా స్తోత్రం",
         //  "img": require('../imgs/Stotramulu/Rama.png')
        },
        {
          "sotram": [
            {
              "title": "శివాయ నమః ||"
            },
            {
              "title": ""
            },
            {
              "title": "శివాపరాధ క్షమాపన స్తోత్రం"
            },
            {
              "title": ""
            },
            {
              "title": "ఆదౌ కర్మప్రసంగాత్ కలయతి కలుషం మాతృకుక్షౌ స్థితం మాం"
            },
            {
              "title": "విణ్మూత్రామేధ్యమధ్యే క్కథయతి నితరాం జాఠరో జాతవేదాః |"
            },
            {
              "title": "యద్యద్వై తత్ర దుఃఖం వ్యథయతి నితరాం శక్యతే కేన వక్తుం"
            },
            {
              "title": "క్షన్తవ్యో మేఽపరాధః శివ శివ శివ భోః శ్రీమహాదేవ శంభో ||౧||"
            },
            {
              "title": ""
            },
            {
              "title": "బాల్యే దుఃఖాతిరేకాన్మలలులితవపుః స్తన్యపానే పిపాసా"
            },
            {
              "title": "నో శక్తశ్చేన్ద్రియేభ్యో భవగుణజనితా జన్తవో మాం తుదన్తి |"
            },
            {
              "title": "నానారోగాదిదుఃఖాదుదన పరవశః శంకరం న స్మరామి"
            },
            {
              "title": "క్షన్తవ్యో మేఽపరాధః శివ శివ శివ భోః శ్రీమహాదేవ శంభో || ౨||"
            },
            {
              "title": ""
            },
            {
              "title": "ప్రౌఢోఽహం యౌవనస్థో విషయవిషధరైః పంచభిర్మర్మసన్ధౌ"
            },
            {
              "title": "దష్టో నష్టోఽవివేకః సుతధనయువతిస్వాదుసౌఖ్యే నిషణ్ణః |"
            },
            {
              "title": "శైవీ చిన్తావిహీనం మమ హృదయమహో మానగర్వాధిరూఢం"
            },
            {
              "title": "క్షన్తవ్యో మేఽపరాధః శివ శివ శివ భోః శ్రీమహాదేవ శంభో || ౩||"
            },
            {
              "title": ""
            },
            {
              "title": "వార్ధక్యే చేన్ద్రియాణాం విగతగతిమతిశ్చాధిదైవాది తాపైః"
            },
            {
              "title": "పాపైర్రోగైర్వియోగై-స్త్వనవ సితవపుః ప్రౌఢిహీనం చ దీనమ్ |"
            },
            {
              "title": "మిథ్యామోహాభిలాషైర్భ్రమతి మమ మనో ధూర్జటేర్ధ్యానశూన్యం"
            },
            {
              "title": "క్షన్తవ్యో మేఽపరాధః శివ శివ శివ భోః శ్రీమహాదేవ శంభో ||౪||"
            },
            {
              "title": ""
            },
            {
              "title": "నో శక్యం స్మార్తకర్మ ప్రతిపదగహనప్రత్యవాయాకులాఖ్యం"
            },
            {
              "title": "శ్రౌతే వార్తా కథం మే ద్విజకులవిహితే బ్రహ్మమార్గే సుసారే |"
            },
            {
              "title": "జ్ఞాతో*ధర్మో విచారైః శ్రవణమననయోః కిం నిదిధ్యాసితవ్యం"
            },
            {
              "title": "క్షన్తవ్యో మేఽపరాధః శివ శివ శివ భోః శ్రీమహాదేవ శంభో ||౫||"
            },
            {
              "title": ""
            },
            {
              "title": "స్నాత్వా ప్రత్యూషకాలే స్నపనవిధివిధౌ నాహృతం గాంగతోయం"
            },
            {
              "title": "పూజార్థం వా కదాచిద్వహు-తరగహనాత్ఖణ్డబిల్వీదలాని |"
            },
            {
              "title": "క్షన్తవ్యో మేఽపరాధః శివ శివ శివ భోః శ్రీమహాదేవ శంభో ||౬||"
            },
            {
              "title": ""
            },
            {
              "title": "దుగ్ధైర్మధ్వాజ్య యుక్తైర్దధిసితసహితైః స్నాపితం నైవ లింగం"
            },
            {
              "title": "నో లిప్తం చన్దనాద్యైః కనక విరచితైః పూజితం న ప్రసూనైః |"
            },
            {
              "title": "ధూపైః కర్పూరదీపైర్వివిధరసయుతైనైవ భక్ష్యోపహారైః"
            },
            {
              "title": "క్షన్తవ్యో మేఽపరాధః శివ శివ శివ భోః శ్రీమహాదేవ శంభో || ౭||"
            },
            {
              "title": ""
            },
            {
              "title": "దుగ్ధైర్మధ్వాజ్య యుక్తైర్దధిసితసహితైః స్నాపితం నైవ లింగం"
            },
            {
              "title": "నో లిప్తం చన్దనాద్యైః కనక విరచితైః పూజితం న ప్రసూనైః |"
            },
            {
              "title": "ధూపైః కర్పూరదీపైర్వివిధరసయుతైనైవ భక్ష్యోపహారైః"
            },
            {
              "title": "క్షన్తవ్యో మేఽపరాధః శివ శివ శివ భోః శ్రీమహాదేవ శంభో || ౭||"
            },
            {
              "title": ""
            },
            {
              "title": "ధ్యాత్వా చిత్తే శివాఖ్యం ప్రచురతరధనం నైవ దత్తం ద్విజేభ్యో"
            },
            {
              "title": "హవ్యం తే లక్షసంఖ్యైర్హుతవహవదనే నార్పితం బీజమన్త్రైః |"
            },
            {
              "title": "నో తప్తం గాంగతీరే వ్రతజపనియమై రుద్రజాప్యైర్న వేదైః"
            },
            {
              "title": "క్షన్తవ్యో మేఽపరాధః శివ శివ శివ భోః శ్రీమహాదేవ శంభో ||౮||"
            },
            {
              "title": ""
            },
            {
              "title": "స్థిత్వా స్థానే సరోజే ప్రణవమయమరుత్కుణ్డలే సూక్ష్మమార్గే"
            },
            {
              "title": "శాన్తే స్వాన్తే ప్రలీనే ప్రకటితబిభవే జ్యోతిరూపేఽపరాఖ్యే |"
            },
            {
              "title": "లింగజ్ఞే బ్రహ్మవాక్యే సకలతనుగతం శంకరం న స్మరామి"
            },
            {
              "title": "క్షన్తవ్యో మేఽపరాధః శివ శివ శివ భోః శ్రీమహాదేవ శంభో ||౯||"
            },
            {
              "title": ""
            },
            {
              "title": "నగ్నో నిఃసంగశుద్ధస్త్రిగుణవిరహితో ధ్వస్తమోహాన్ధకారో"
            },
            {
              "title": "నాసాగ్రే న్యస్తద్రుష్టిర్విదితభవగుణో నైవ ద్రుష్టః కదాచిత్ |"
            },
            {
              "title": "ఉన్మన్యాఽవస్థయా త్వాం విగతకలిమలం శంకరం న స్మరామి"
            },
            {
              "title": "క్షన్తవ్యో మేఽపరాధః శివ శివ శివ భోః శ్రీమహాదేవ శంభో || ౧౦||"
            },
            {
              "title": ""
            },
            {
              "title": "చన్ద్రోద్భాసితశేఖరే స్మరహరే గంగాధరే శంకరే"
            },
            {
              "title": "సర్పైర్భూషిత కణ్ఠకర్ణవివరే నేత్రోత్థవైశ్వానరే |"
            },
            {
              "title": "దన్తిత్వక్కౄతసున్దరాంబరధరే త్రైలోక్యసారే హరే"
            },
            {
              "title": "మోక్షార్థం కురు చితవృత్తిమఖిలామన్యైస్తు కిం కర్మభిః  || ౧౧||"
            },
            {
              "title": ""
            },
            {
              "title": "కిం వాఽనేన ధనేన వాజికరిభిః ప్రాప్తేన రాజ్యేన కిం"
            },
            {
              "title": "కిం వా పుత్రకళత్ర-మిత్రపశుభిర్దేహేన గేహేన కిమ్"
            },
            {
              "title": "జ్ఞాత్వైతత్క్షణభఙ్గురం సపదిరే త్యాజ్యం మనో దూరతః"
            },
            {
              "title": "స్వాత్మార్థం గురువాక్యతో భజ భజ శ్రీపార్వతీవల్లభమ్ || ౧౨||"
            },
            {
              "title": ""
            },
            {
              "title": "ఆయుర్నశ్యతి పశ్యతాం ప్రతిదినం యాతి క్షయం యౌవనం"
            },
            {
              "title": "ప్రాత్యాయాన్తి గతాః పునర్న దివసాః కాలో జగద్భక్షకః"
            },
            {
              "title": "లక్ష్మీస్తోయతరంగభంగచపలా విద్యుచ్చలం జీవితం"
            },
            {
              "title": "తస్మాన్మాం శరణాగతం శరణద త్వం రక్ష రక్షాధునా || ౧౩||"
            },
            {
              "title": ""
            },
            {
              "title": "కరచరణకృతం వాక్కాయజం కర్మజం వా"
            },
            {
              "title": "శ్రవణనయనజం వా మానసం వాఽపరాధమ్ |"
            },
            {
              "title": "విహితమవిహితం వా సర్వమేతత్క్షమస్వ"
            },
            {
              "title": "జయ జయ కరుణాబ్ధే శ్రీమహాదేవ శంభో|| ౧౪||"
            },
            {
              "title": ""
            },
            {
              "title": "ఇతి శ్రీమచ్ఛఙ్కరాచార్యవిరచితం శివాపరాధక్షమాపనస్తోత్రం సంపూర్ణమ్ ||"
            }
          ],
          "title": "శివాపరాధ క్షమాపన స్తోత్రం",
         //  "img": require('../imgs/Stotramulu/Siva.png')
        },
        {
          "sotram": [
            {
              "title": "యా కుందేందు తుషారహారధవళా యా శుభ్రవస్త్రావృతా"
            },
            {
              "title": "యా వీణావరదండమండితకరా యా శ్వేతపద్మాసనా ।"
            },
            {
              "title": "యా బ్రహ్మాచ్యుత శంకరప్రభృతిభిర్దేవైస్సదా పూజితా"
            },
            {
              "title": "సా మాం పాతు సరస్వతీ భగవతీ నిశ్శేషజాడ్యాపహా ॥ 1 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "దోర్భిర్యుక్తా చతుర్భిః స్ఫటికమణినిభై రక్షమాలాందధానా"
            },
            {
              "title": "హస్తేనైకేన పద్మం సితమపిచ శుకం పుస్తకం చాపరేణ ।"
            },
            {
              "title": "భాసా కుందేందుశంఖస్ఫటికమణినిభా భాసమానాzసమానా"
            },
            {
              "title": "సా మే వాగ్దేవతేయం నివసతు వదనే సర్వదా సుప్రసన్నా ॥ 2 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సురాసురైస్సేవితపాదపంకజా కరే విరాజత్కమనీయపుస్తకా ।"
            },
            {
              "title": "విరించిపత్నీ కమలాసనస్థితా సరస్వతీ నృత్యతు వాచి మే సదా ॥ 3 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సరస్వతీ సరసిజకేసరప్రభా తపస్వినీ సితకమలాసనప్రియా ।"
            },
            {
              "title": "ఘనస్తనీ కమలవిలోలలోచనా మనస్వినీ భవతు వరప్రసాదినీ ॥ 4 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సరస్వతి నమస్తుభ్యం వరదే కామరూపిణి ।"
            },
            {
              "title": "విద్యారంభం కరిష్యామి సిద్ధిర్భవతు మే సదా ॥ 5 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సరస్వతి నమస్తుభ్యం సర్వదేవి నమో నమః ।"
            },
            {
              "title": "శాంతరూపే శశిధరే సర్వయోగే నమో నమః ॥ 6 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "నిత్యానందే నిరాధారే నిష్కళాయై నమో నమః ।"
            },
            {
              "title": "విద్యాధరే విశాలాక్షి శుద్ధజ్ఞానే నమో నమః ॥ 7 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "శుద్ధస్ఫటికరూపాయై సూక్ష్మరూపే నమో నమః ।"
            },
            {
              "title": "శబ్దబ్రహ్మి చతుర్హస్తే సర్వసిద్ధ్యై నమో నమః ॥ 8 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ముక్తాలంకృత సర్వాంగ్యై మూలాధారే నమో నమః ।"
            },
            {
              "title": "మూలమంత్రస్వరూపాయై మూలశక్త్యై నమో నమః ॥ 9 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "మనోన్మని మహాభోగే వాగీశ్వరి నమో నమః ।"
            },
            {
              "title": "వాగ్మ్యై వరదహస్తాయై వరదాయై నమో నమః ॥ 10 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "వేదాయై వేదరూపాయై వేదాంతాయై నమో నమః ।"
            },
            {
              "title": "గుణదోషవివర్జిన్యై గుణదీప్త్యై నమో నమః ॥ 11 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సర్వజ్ఞానే సదానందే సర్వరూపే నమో నమః ।"
            },
            {
              "title": "సంపన్నాయై కుమార్యై చ సర్వజ్ఞే తే నమో నమః ॥ 12 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "యోగానార్య ఉమాదేవ్యై యోగానందే నమో నమః ।"
            },
            {
              "title": "దివ్యజ్ఞాన త్రినేత్రాయై దివ్యమూర్త్యై నమో నమః ॥ 13 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అర్ధచంద్రజటాధారి చంద్రబింబే నమో నమః ।"
            },
            {
              "title": "చంద్రాదిత్యజటాధారి చంద్రబింబే నమో నమః ॥ 14 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అణురూపే మహారూపే విశ్వరూపే నమో నమః ।"
            },
            {
              "title": "అణిమాద్యష్టసిద్ధాయై ఆనందాయై నమో నమః ॥ 15 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "జ్ఞాన విజ్ఞాన రూపాయై జ్ఞానమూర్తే నమో నమః ।"
            },
            {
              "title": "నానాశాస్త్ర స్వరూపాయై నానారూపే నమో నమః ॥ 16 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "పద్మజా పద్మవంశా చ పద్మరూపే నమో నమః ।"
            },
            {
              "title": "పరమేష్ఠ్యై పరామూర్త్యై నమస్తే పాపనాశినీ ॥ 17 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "మహాదేవ్యై మహాకాళ్యై మహాలక్ష్మ్యై నమో నమః ।"
            },
            {
              "title": "బ్రహ్మవిష్ణుశివాయై చ బ్రహ్మనార్యై నమో నమః ॥ 18 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "కమలాకరపుష్పా చ కామరూపే నమో నమః ।"
            },
            {
              "title": "కపాలికర్మదీప్తాయై కర్మదాయై నమో నమః ॥ 19 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సాయం ప్రాతః పఠేన్నిత్యం షణ్మాసాత్సిద్ధిరుచ్యతే ।"
            },
            {
              "title": "చోరవ్యాఘ్రభయం నాస్తి పఠతాం శృణ్వతామపి ॥ 20 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఇత్థం సరస్వతీ స్తోత్రమగస్త్యముని వాచకమ్ ।"
            },
            {
              "title": "సర్వసిద్ధికరం నౄణాం సర్వపాపప్రణాశనమ్ ॥ 21 ॥"
            }
          ],
          "title": "సరస్వతీ స్తోత్రం",
         //  "img": require('../imgs/Stotramulu/Saraswathi.png')
        },
        {
          "sotram": [
            {
              "title": "@శాంతిపాఠః"
            },
            {
              "title": "ఓం యో బ్రహ్మాణం విదధాతి పూర్వం"
            },
            {
              "title": "యో వై వేదాంశ్చ ప్రహిణోతి తస్మై ।"
            },
            {
              "title": "తంహదేవమాత్మ బుద్ధిప్రకాశం"
            },
            {
              "title": "ముముక్షుర్వై శరణమహం ప్రపద్యే ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@ధ్యానం"
            },
            {
              "title": "ఓం మౌనవ్యాఖ్యా ప్రకటితపరబ్రహ్మతత్వంయువానం"
            },
            {
              "title": "వర్శిష్ఠాంతేవసదృషిగణైరావృతం బ్రహ్మనిష్ఠైః ।"
            },
            {
              "title": "ఆచార్యేంద్రం కరకలిత చిన్ముద్రమానందమూర్తిం"
            },
            {
              "title": "స్వాత్మరామం ముదితవదనం దక్షిణామూర్తిమీడే ॥"
            },
            {
              "title": ""
            },
            {
              "title": "వటవిటపిసమీపే భూమిభాగే నిషణ్ణం"
            },
            {
              "title": "సకలమునిజనానాం జ్ఞానదాతారమారాత్ ।"
            },
            {
              "title": "త్రిభువనగురుమీశం దక్షిణామూర్తిదేవం"
            },
            {
              "title": "జననమరణదుఃఖచ్ఛేద దక్షం నమామి ॥"
            },
            {
              "title": ""
            },
            {
              "title": "చిత్రం వటతరోర్మూలే వృద్ధాః శిష్యాః గురుర్యువా ।"
            },
            {
              "title": "గురోస్తు మౌనవ్యాఖ్యానం శిష్యాస్తుచ్ఛిన్నసంశయాః ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం నమః ప్రణవార్థాయ శుద్ధజ్ఞానైకమూర్తయే ।"
            },
            {
              "title": "నిర్మలాయ ప్రశాంతాయ దక్షిణామూర్తయే నమః ॥"
            },
            {
              "title": ""
            },
            {
              "title": "గురుర్బ్రహ్మా గురుర్విష్ణుః గురుర్దేవో మహేశ్వరః ।"
            },
            {
              "title": "గురుస్సాక్షాత్ పరం బ్రహ్మా తస్మై శ్రీ గురవే నమః ॥"
            },
            {
              "title": ""
            },
            {
              "title": "నిధయే సర్వవిద్యానాం భిషజే భవరోగిణామ్ ।"
            },
            {
              "title": "గురవే సర్వలోకానాం దక్షిణామూర్తయే నమః ॥"
            },
            {
              "title": ""
            },
            {
              "title": "చిదోఘనాయ మహేశాయ వటమూలనివాసినే ।"
            },
            {
              "title": "సచ్చిదానంద రూపాయ దక్షిణామూర్తయే నమః ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఈశ్వరో గురురాత్మేతి మూర్తిభేద విభాగినే ।"
            },
            {
              "title": "వ్యోమవద్-వ్యాప్తదేహాయ దక్షిణామూర్తయే నమః ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అంగుష్ఠతర్జనీ యోగముద్రా వ్యాజేనయోగినామ్ ।"
            },
            {
              "title": "శృత్యర్థం బ్రహ్మజీవైక్యం దర్శయన్యోగతా శివః ॥"
            },
            {
              "title": ""
            },
            {
              "title": "ఓం శాంతిః శాంతిః శాంతిః ॥"
            },
            {
              "title": ""
            },
            {
              "title": "@స్తోత్రం"
            },
            {
              "title": "విశ్వందర్పణ దృశ్యమాన నగరీ తుల్యం నిజాంతర్గతం"
            },
            {
              "title": "పశ్యన్నాత్మని మాయయా బహిరివోద్భూతం యథానిద్రయా ।"
            },
            {
              "title": "యస్సాక్షాత్కురుతే ప్రభోధసమయే స్వాత్మానమే వాద్వయం"
            },
            {
              "title": "తస్మై శ్రీగురుమూర్తయే నమ ఇదం శ్రీ దక్షిణామూర్తయే ॥ 1 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "బీజస్యాంతతి వాంకురో జగదితం ప్రాఙ్నర్వికల్పం పునః"
            },
            {
              "title": "మాయాకల్పిత దేశకాలకలనా వైచిత్ర్యచిత్రీకృతమ్ ।"
            },
            {
              "title": "మాయావీవ విజృంభయత్యపి మహాయోగీవ యః స్వేచ్ఛయా"
            },
            {
              "title": "తస్మై శ్రీగురుమూర్తయే నమ ఇదం శ్రీ దక్షిణామూర్తయే ॥ 2 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "యస్యైవ స్ఫురణం సదాత్మకమసత్కల్పార్థకం భాసతే"
            },
            {
              "title": "సాక్షాత్తత్వమసీతి వేదవచసా యో బోధయత్యాశ్రితాన్ ।"
            },
            {
              "title": "యస్సాక్షాత్కరణాద్భవేన్న పురనావృత్తిర్భవాంభోనిధౌ"
            },
            {
              "title": "తస్మై శ్రీగురుమూర్తయే నమ ఇదం శ్రీ దక్షిణామూర్తయే ॥ 3 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "నానాచ్ఛిద్ర ఘటోదర స్థిత మహాదీప ప్రభాభాస్వరం"
            },
            {
              "title": "జ్ఞానం యస్య తు చక్షురాదికరణ ద్వారా బహిః స్పందతే ।"
            },
            {
              "title": "జానామీతి తమేవ భాంతమనుభాత్యేతత్సమస్తం జగత్"
            },
            {
              "title": "తస్మై శ్రీ గురుమూర్తయే నమ ఇదం శ్రీ దక్షిణామూర్తయే ॥ 4 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "దేహం ప్రాణమపీంద్రియాణ్యపి చలాం బుద్ధిం చ శూన్యం విదుః"
            },
            {
              "title": "స్త్రీ బాలాంధ జడోపమాస్త్వహమితి భ్రాంతాభృశం వాదినః ।"
            },
            {
              "title": "మాయాశక్తి విలాసకల్పిత మహావ్యామోహ సంహారిణే"
            },
            {
              "title": "తస్మై శ్రీ గురుమూర్తయే నమ ఇదం శ్రీ దక్షిణామూర్తయే ॥ 5 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "రాహుగ్రస్త దివాకరేందు సదృశో మాయా సమాచ్ఛాదనాత్"
            },
            {
              "title": "సన్మాత్రః కరణోప సంహరణతో యోఽభూత్సుషుప్తః పుమాన్ ।"
            },
            {
              "title": "ప్రాగస్వాప్సమితి ప్రభోదసమయే యః ప్రత్యభిజ్ఞాయతే"
            },
            {
              "title": "తస్మై శ్రీ గురుమూర్తయే నమ ఇదం శ్రీ దక్షిణామూర్తయే ॥ 6 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "బాల్యాదిష్వపి జాగ్రదాదిషు తథా సర్వాస్వవస్థాస్వపి"
            },
            {
              "title": "వ్యావృత్తా స్వను వర్తమాన మహమిత్యంతః స్ఫురంతం సదా ।"
            },
            {
              "title": "స్వాత్మానం ప్రకటీకరోతి భజతాం యో ముద్రయా భద్రయా"
            },
            {
              "title": "తస్మై శ్రీ గురుమూర్తయే నమ ఇదం శ్రీ దక్షిణామూర్తయే ॥ 7 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "విశ్వం పశ్యతి కార్యకారణతయా స్వస్వామిసంబంధతః"
            },
            {
              "title": "శిష్యచార్యతయా తథైవ పితృ పుత్రాద్యాత్మనా భేదతః ।"
            },
            {
              "title": "స్వప్నే జాగ్రతి వా య ఏష పురుషో మాయా పరిభ్రామితః"
            },
            {
              "title": "తస్మై శ్రీ గురుమూర్తయే నమ ఇదం శ్రీ దక్షిణామూర్తయే ॥ 8 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "భూరంభాంస్యనలోఽనిలోంబర మహర్నాథో హిమాంశుః పుమాన్"
            },
            {
              "title": "ఇత్యాభాతి చరాచరాత్మకమిదం యస్యైవ మూర్త్యష్టకమ్ ।"
            },
            {
              "title": "నాన్యత్కించన విద్యతే విమృశతాం యస్మాత్పరస్మాద్విభో"
            },
            {
              "title": "తస్మై గురుమూర్తయే నమ ఇదం శ్రీ దక్షిణామూర్తయే ॥ 9 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సర్వాత్మత్వమితి స్ఫుటీకృతమిదం యస్మాదముష్మిన్ స్తవే"
            },
            {
              "title": "తేనాస్వ శ్రవణాత్తదర్థ మననాద్ధ్యానాచ్చ సంకీర్తనాత్ ।"
            },
            {
              "title": "సర్వాత్మత్వమహావిభూతి సహితం స్యాదీశ్వరత్వం స్వతః"
            },
            {
              "title": "సిద్ధ్యేత్తత్పునరష్టధా పరిణతం చైశ్వర్య మవ్యాహతమ్ ॥ 10 ॥"
            },
            {
              "title": ""
            },
            {
              "title": "॥ ఇతి శ్రీమచ్ఛంకరాచార్యవిరచితం దక్షిణాముర్తిస్తోత్రం సంపూర్ణమ్ ॥"
            }
          ],
          "title": "దక్షిణా మూర్తి స్తోత్రం",
         //  "img": require('../imgs/Stotramulu/Dakshinamurthy.png')
        },
        {
          "sotram": [
            {
              "title": "కమలాకుచ చూచుక కుంకమతో"
            },
            {
              "title": "నియతారుణి తాతుల నీలతనో ।"
            },
            {
              "title": "కమలాయత లోచన లోకపతే"
            },
            {
              "title": "విజయీభవ వేంకట శైలపతే ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సచతుర్ముఖ షణ్ముఖ పంచముఖే"
            },
            {
              "title": "ప్రముఖా ఖిలదైవత మౌళిమణే ।"
            },
            {
              "title": "శరణాగత వత్సల సారనిధే"
            },
            {
              "title": "పరిపాలయ మాం వృష శైలపతే ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అతివేలతయా తవ దుర్విషహై"
            },
            {
              "title": "రను వేలకృతై రపరాధశతైః ।"
            },
            {
              "title": "భరితం త్వరితం వృష శైలపతే"
            },
            {
              "title": "పరయా కృపయా పరిపాహి హరే ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అధి వేంకట శైల ముదారమతే-"
            },
            {
              "title": "ర్జనతాభి మతాధిక దానరతాత్ ।"
            },
            {
              "title": "పరదేవతయా గదితానిగమైః"
            },
            {
              "title": "కమలాదయితాన్న పరంకలయే ॥"
            },
            {
              "title": ""
            },
            {
              "title": "కల వేణుర వావశ గోపవధూ"
            },
            {
              "title": "శత కోటి వృతాత్స్మర కోటి సమాత్ ।"
            },
            {
              "title": "ప్రతి పల్లవికాభి మతాత్-సుఖదాత్"
            },
            {
              "title": "వసుదేవ సుతాన్న పరంకలయే ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అభిరామ గుణాకర దాశరధే"
            },
            {
              "title": "జగదేక ధనుర్థర ధీరమతే ।"
            },
            {
              "title": "రఘునాయక రామ రమేశ విభో"
            },
            {
              "title": "వరదో భవ దేవ దయా జలధే ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అవనీ తనయా కమనీయ కరం"
            },
            {
              "title": "రజనీకర చారు ముఖాంబురుహమ్ ।"
            },
            {
              "title": "రజనీచర రాజత మోమి హిరం"
            },
            {
              "title": "మహనీయ మహం రఘురామమయే ॥"
            },
            {
              "title": ""
            },
            {
              "title": "సుముఖం సుహృదం సులభం సుఖదం"
            },
            {
              "title": "స్వనుజం చ సుకాయమ మోఘశరమ్ ।"
            },
            {
              "title": "అపహాయ రఘూద్వయ మన్యమహం"
            },
            {
              "title": "న కథంచన కంచన జాతుభజే ॥"
            },
            {
              "title": ""
            },
            {
              "title": ""
            },
            {
              "title": "వినా వేంకటేశం న నాథో న నాథః"
            },
            {
              "title": "సదా వేంకటేశం స్మరామి స్మరామి ।"
            },
            {
              "title": "హరే వేంకటేశ ప్రసీద ప్రసీద"
            },
            {
              "title": "ప్రియం వేంకటెశ ప్రయచ్ఛ ప్రయచ్ఛ ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అహం దూరదస్తే పదాం భోజయుగ్మ"
            },
            {
              "title": "ప్రణామేచ్ఛయా గత్య సేవాం కరోమి ।"
            },
            {
              "title": "సకృత్సేవయా నిత్య సేవాఫలం త్వం"
            },
            {
              "title": "ప్రయచ్ఛ పయచ్ఛ ప్రభో వేంకటేశ ॥"
            },
            {
              "title": ""
            },
            {
              "title": "అజ్ఞానినా మయా దోషా న శేషాన్విహితాన్ హరే ।"
            },
            {
              "title": "క్షమస్వ త్వం క్షమస్వ త్వం శేషశైల శిఖామణే ॥"
            }
          ],
          "title": "శ్రీ వేంకటేశ్వర స్తోత్రం",
         //  "img": require('../imgs/Stotramulu/VenkateswaraSwami.png')
        },
        {
          "sotram": [
            {
              "title": "అకారణాయాఖిలకారణాయ నమో మహాకారణకారణాయ |"
            },
            {
              "title": "నమోఽస్తు కాలానలలోచనాయ కృతాగసం మామవ విశ్వమూర్తే ||౧||"
            },
            {
              "title": ""
            },
            {
              "title": "నమోఽస్త్వహీనాభరణాయ నిత్యం నమః పశూనాం పతయే మృడాయ |"
            },
            {
              "title": "వేదాన్తవేద్యాయ నమో నమస్తే కృతాగసం మామవ విశ్వమూర్తే ||౨||"
            },
            {
              "title": ""
            },
            {
              "title": "నమోఽస్తు భక్తేహితదానదాత్రే సర్వౌషధీనాం పతయే నమోఽస్తు |"
            },
            {
              "title": "బ్రహ్మణ్యదేవాయ నమో నమస్తే కృతాగసం మామవ విశ్వమూర్తే||౩||"
            },
            {
              "title": ""
            },
            {
              "title": "కాలాయ కాలానలసన్నిభాయ హిరణ్యగర్భాయ నమో నమస్తే |"
            },
            {
              "title": "హాలాహలాదాయ సదా నమస్తే కృతాగసం మామవ విశ్వమూర్తే ||౪||"
            },
            {
              "title": ""
            },
            {
              "title": "విరిఞ్చినారాయణశక్రముఖ్యైరజ్ఞాతవీర్యాయ నమో నమస్తే |"
            },
            {
              "title": "సూక్ష్మాతిసూక్ష్మాయ నమోఽఘహన్త్రే కృతాగసం మామవ విశ్వమూర్తే ||౫||"
            },
            {
              "title": ""
            },
            {
              "title": "అనేకకోటీన్దునిభాయ తేఽస్తు నమో గిరీణాం పతయేఽఘహన్త్రే |"
            },
            {
              "title": "నమోఽస్తు తే భక్తవిపద్ధరాయ కృతాగసం మామవ విశ్వమూర్తే || ౬||"
            },
            {
              "title": ""
            },
            {
              "title": "సర్వాన్తరస్థాయ విశుద్ధధామ్నే నమోఽస్తు తే దుష్టకులాన్తకాయ |"
            },
            {
              "title": "సమస్తతేజోనిధయే నమస్తే కృతాగసం మామవ విశ్వమూర్తే ||౭||"
            },
            {
              "title": ""
            },
            {
              "title": "యజ్ఞాయ యజ్ఞాదిఫలప్రదాత్రే యజ్ఞస్వరూపాయ నమో నమస్తే |"
            },
            {
              "title": "నమో మహానన్దమయాయ నిత్యం కృతాగసం మామవ విశ్వమూర్తే ||౮||"
            },
            {
              "title": ""
            },
            {
              "title": "ఇతి స్తుతో మహాదేవో దక్షం ప్రాహ కృతాఞ్జలిమ్ |"
            },
            {
              "title": "యత్తేఽభిలషితం దక్ష తత్తే దాస్యామ్యహం ధ్రువమ్ ||౯|| "
            },
            {
              "title": ""
            },
            {
              "title": "అన్యచ్చ శ్రృణు భో దక్ష యచ్చ కిఞ్చిద్బ్రవీమ్యహమ్ |"
            },
            {
              "title": "యత్కృతం హి మమ స్తోత్రం త్వయా భక్త్యా ప్రజాపతే ||౧౦||"
            },
            {
              "title": ""
            },
            {
              "title": "యే శ్రద్ధయా పఠిష్యన్తి మానవాః ప్రత్యహం శుభమ్ |"
            },
            {
              "title": "నిష్కల్మషా భవిష్యన్తి సాపరాధా అపి ధ్రువమ్ ||౧౧||"
            },
            {
              "title": ""
            },
            {
              "title": "ఇతి దక్షకృతం విశ్వమూర్తిస్తోత్రం సంపూర్ణమ్ ||"
            }
          ],
          "title": "విశ్వమూర్తి స్తోత్రం",
         //  "img": require('../imgs/Stotramulu/Siva.png')
        },
        {
          "sotram": [
            {
               "title": 'లంకాయాం శాంకరీ దేవీ కామాక్షీ కాంచికాపురే |',
            },
            {
               "title": 'ప్రద్యుమ్నే శృంఖలాదేవీ చాముండీ క్రౌంచపట్టణే ||',
            },
            {
               "title": '',
            },
            {
               "title": 'అలంపురే జోగులాంబా శ్రీశైలే భ్రమరాంబికా |',
            },
            {
               "title": 'కోల్హాపురే మహాలక్ష్మీ మాహూర్యే ఏకవీరికా ||',
            },
            {
               "title": '',
            },
            {
               "title": 'ఉజ్జయిన్యాం మహాకాలీ పీఠిక్యాం పురుహూతికా |',
            },
            {
               "title": 'ఓఢ్యాయాం గిరిజాదేవీ మాణిక్యా దక్షవాటకే ||',
            },
            {
               "title": '',
            },
            {
               "title": 'హరిక్షేత్రే కామరూపా ప్రయాగే మాధవేశ్వరీ |',
            },
            {
               "title": 'జ్వాలాయాం వైష్ణవీ దేవీ గయా మాంగల్యగౌరికా ||',
            },
            {
               "title": '',
            },
            {
               "title": 'వారణస్యాం విశాలాక్షీ కాశ్మీరేషు సరస్వతీ |',
            },
            {
               "title": 'అష్టాదశ సుపీఠాని యోగినామపి దుర్లభం ||',
            },
            {
               "title": '',
            },
            {
               "title": 'సాయంకాలే పఠేన్నిత్యం సర్వశత్రువినాశనం |',
            },
            {
               "title": 'సర్వరోగహరం దివ్యం సర్వసంపత్కరం శుభం ||',
            },
            {
               "title": '',
            },
            {
               "title": 'ఇతి అష్టాదశ శక్తిపీఠ స్తుతిః',
            }
          ],
           "title": 'అష్టాదశశక్తి పీఠ స్తోత్రం',
         //  "img": require('../imgs/Stotramulu/AshtaLakshmi.png'),
        },
   
      //   {
      //     "sotram": [
      //       {
      //          "title": 'దేవరాజ సేవ్యమాన పావనాంఘ్రి పంకజం',
      //       },
      //       {
      //          "title": 'వ్యాళయజ్ఞ సూత్రమిందు శేఖరం కృపాకరమ్ |',
      //       },
      //       {
      //          "title": 'నారదాది యోగిబృంద వందితం దిగంబరం',
      //       },
      //       {
      //          "title": 'కాశికాపురాధినాథ కాలభైరవం భజే || 1 ||',
      //       },
      //       {
      //          "title": '',
      //       },
      //       {
      //          "title": 'భానుకోటి భాస్వరం భవబ్ధితారకం పరం',
      //       },
      //       {
      //          "title": 'నీలకంఠ మీప్సితార్ధ దాయకం త్రిలోచనమ్ |',
      //       },
      //       {
      //          "title": 'కాలకాల మంబుజాక్ష మస్తశూన్య మక్షరం',
      //       },
      //       {
      //          "title": 'కాశికాపురాధినాథ కాలభైరవం భజే || 2 ||',
      //       },
      //       {
      //          "title": '',
      //       },
      //       {
      //          "title": 'శూలటంక పాశదండ పాణిమాది కారణం',
      //       },
      //       {
      //          "title": 'శ్యామకాయ మాదిదేవ మక్షరం నిరామయమ్ |',
      //       },
      //       {
      //          "title": 'భీమవిక్రమం ప్రభుం విచిత్ర తాండవ ప్రియం',
      //       },
      //       {
      //          "title": 'కాశికాపురాధినాథ కాలభైరవం భజే || 3 ||',
      //       },
      //       {
      //          "title": '',
      //       },
      //       {
      //          "title": 'భుక్తి ముక్తి దాయకం ప్రశస్తచారు విగ్రహం',
      //       },
      //       {
      //          "title": 'భక్తవత్సలం స్థితం సమస్తలోక విగ్రహమ్ |',
      //       },
      //       {
      //          "title": 'నిక్వణన్-మనోజ్ఞ హేమ కింకిణీ లసత్కటిం',
      //       },
      //       {
      //          "title": 'కాశికాపురాధినాథ కాలభైరవం భజే || 4 ||',
      //       },
      //       {
      //          "title": '',
      //       },
      //       {
      //          "title": 'ధర్మసేతు పాలకం త్వధర్మమార్గ నాశకం',
      //       },
      //       {
      //          "title": 'కర్మపాశ మోచకం సుశర్మ దాయకం విభుమ్ |',
      //       },
      //       {
      //          "title": 'స్వర్ణవర్ణ కేశపాశ శొభితాంగ నిర్మలం',
      //       },
      //       {
      //          "title": 'కాశికాపురాధినాథ కాలభైరవం భజే || 5 ||',
      //       },
      //       {
      //          "title": '',
      //       },
      //       {
      //          "title": 'రత్న పాదుకా ప్రభాభిరామ పాదయుగ్మకం',
      //       },
      //       {
      //          "title": 'నిత్య మద్వితీయ మిష్ట దైవతం నిరంజనమ్ |',
      //       },
      //       {
      //          "title": 'మృత్యుదర్ప నాశనం కరాళదంష్ట్ర భూషణం',
      //       },
      //       {
      //          "title": 'కాశికాపురాధినాథ కాలభైరవం భజే || 6 ||',
      //       },
      //       {
      //          "title": '',
      //       },
      //       {
      //          "title": 'అట్టహాస భిన్న పద్మజాండకోశ సంతతిం',
      //       },
      //       {
      //          "title": 'దృష్టిపాత నష్టపాప జాలముగ్ర శాసనమ్ |',
      //       },
      //       {
      //          "title": 'అష్టసిద్ధి దాయకం కపాలమాలికా ధరం',
      //       },
      //       {
      //          "title": 'కాశికాపురాధినాథ కాలభైరవం భజే || 7 ||',
      //       },
      //       {
      //          "title": '',
      //       },
      //       {
      //          "title": 'భూతసంఘ నాయకం విశాలకీర్తి దాయకం',
      //       },
      //       {
      //          "title": 'కాశివాసి లోక పుణ్యపాప శోధకం విభుమ్ |',
      //       },
      //       {
      //          "title": 'నీతిమార్గ కోవిదం పురాతనం జగత్పతిం',
      //       },
      //       {
      //          "title": 'కాశికాపురాధినాథ కాలభైరవం భజే || 8 ||',
      //       },
      //       {
      //          "title": '',
      //       },
      //       {
      //          "title": 'కాలభైరవాష్టకం పఠంతి యే మనోహరం',
      //       },
      //       {
      //          "title": 'జ్ఞానముక్తి సాధకం విచిత్ర పుణ్య వర్ధనమ్ |',
      //       },
      //       {
      //          "title": 'శోకమోహ లోభదైన్య కోపతాప నాశనం',
      //       },
      //       {
      //          "title": 'తే ప్రయాంతి కాలభైరవాంఘ్రి సన్నిధిం ధ్రువమ్ |',
      //       },
      //       {
      //          "title": '',
      //       },
      //       {
      //          "title": 'ఇతి శ్రీమచ్చంకరాచార్య విరచితం కాలభైరవాష్టకం సంపూర్ణమ్ |',
      //       }
      //     ],
      //      "title": 'కాలభైరవాష్టకం',
      //     "img": require('../imgs/Stotramulu/Siva.png')
      //   },
       
        {
          "sotram": [
            {
               "title": 'విశ్వేశ్వరాయ నరకార్ణవ తారణాయ',
            },
            {
               "title": 'కర్ణామృతాయ శశిశేఖర ధారణాయ ।',
            },
            {
               "title": 'కర్పూరకాంతి ధవళాయ జటాధరాయ',
            },
            {
               "title": 'దారిద్ర్యదుఃఖ దహనాయ నమశ్శివాయ || 1 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'గౌరీప్రియాయ రజనీశ కళాధరాయ',
            },
            {
               "title": 'కాలాంతకాయ భుజగాధిప కంకణాయ ।',
            },
            {
               "title": 'గంగాధరాయ గజరాజ విమర్ధనాయ',
            },
            {
               "title": 'దారిద్ర్యదుఃఖ దహనాయ నమశ్శివాయ || 2 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'భక్తప్రియాయ భవరోగ భయాపహాయ',
            },
            {
               "title": 'ఉగ్రాయ దుఃఖ భవసాగర తారణాయ ।',
            },
            {
               "title": 'జ్యోతిర్మయాయ గుణనామ సునృత్యకాయ',
            },
            {
               "title": 'దారిద్ర్యదుఃఖ దహనాయ నమశ్శివాయ || 3 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'చర్మాంబరాయ శవభస్మ విలేపనాయ',
            },
            {
               "title": 'ఫాలేక్షణాయ మణికుండల మండితాయ ।',
            },
            {
               "title": 'మంజీరపాదయుగళాయ జటాధరాయ',
            },
            {
               "title": 'దారిద్ర్యదుఃఖ దహనాయ నమశ్శివాయ || 4 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'పంచాననాయ ఫణిరాజ విభూషణాయ',
            },
            {
               "title": 'హేమాంకుశాయ భువనత్రయ మండితాయ',
            },
            {
               "title": 'ఆనంద భూమి వరదాయ తమోపయాయ ।',
            },
            {
               "title": 'దారిద్ర్యదుఃఖ దహనాయ నమశ్శివాయ || 5 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'భానుప్రియాయ భవసాగర తారణాయ',
            },
            {
               "title": 'కాలాంతకాయ కమలాసన పూజితాయ ।',
            },
            {
               "title": 'నేత్రత్రయాయ శుభలక్షణ లక్షితాయ',
            },
            {
               "title": 'దారిద్ర్యదుఃఖ దహనాయ నమశ్శివాయ || 6 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'రామప్రియాయ రఘునాథ వరప్రదాయ',
            },
            {
               "title": 'నాగప్రియాయ నరకార్ణవ తారణాయ ।',
            },
            {
               "title": 'పుణ్యాయ పుణ్యభరితాయ సురార్చితాయ',
            },
            {
               "title": 'దారిద్ర్యదుఃఖ దహనాయ నమశ్శివాయ || 7 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'ముక్తేశ్వరాయ ఫలదాయ గణేశ్వరాయ',
            },
            {
               "title": 'గీతాప్రియాయ వృషభేశ్వర వాహనాయ ।',
            },
            {
               "title": 'మాతంగచర్మ వసనాయ మహేశ్వరాయ',
            },
            {
               "title": 'దారిద్ర్యదుఃఖ దహనాయ నమశ్శివాయ || 8 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'వసిష్ఠేన కృతం స్తోత్రం సర్వరోగ నివారణమ్ ।',
            },
            {
               "title": 'సర్వసంపత్కరం శీఘ్రం పుత్రపౌత్రాది వర్ధనమ్ ।',
            },
            {
               "title": 'త్రిసంధ్యం యః పఠేన్నిత్యం స హి స్వర్గ మవాప్నుయాత్',
            },
            {
               "title": '',
            },
            {
               "title": '|| ఇతి శ్రీ వసిష్ఠ విరచితం దారిద్ర్యదహన శివస్తోత్రం సంపూర్ణమ్ ||',
            }
          ],
           "title": 'దారిద్ర్య దహన శివ స్తోత్రం',
         //  "img": require('../imgs/Stotramulu/umamaheswara.png')
        },
        {
          "sotram": [
            {
               "title": 'శ్రీగణేశాయ నమః |',
            },
            {
               "title": 'ఓం అస్య శ్రీమహామృత్యుంజయస్తోత్రమంత్రస్య శ్రీ మార్కండేయ ఋషిః,',
            },
            {
               "title": 'అనుష్టుప్ఛందః, శ్రీమృత్యుంజయో దేవతా, గౌరీ శక్తిః,',
            },
            {
               "title": 'మమ సర్వారిష్టసమస్తమృత్యుశాంత్యర్థం సకలైశ్వర్యప్రాప్త్యర్థం',
            },
            {
               "title": 'జపే వినోయోగః |',
            },
            {
               "title": '',
            },
            {
               "title": 'ధ్యానం',
            },
            {
               "title": 'చంద్రార్కాగ్నివిలోచనం స్మితముఖం పద్మద్వయాంతస్థితం',
            },
            {
               "title": 'ముద్రాపాశమృగాక్షసత్రవిలసత్పాణిం హిమాంశుప్రభమ్ |',
            },
            {
               "title": 'కోటీందుప్రగలత్సుధాప్లుతతముం హారాదిభూషోజ్జ్వలం',
            },
            {
               "title": 'కాంతం విశ్వవిమోహనం పశుపతిం మృత్యుంజయం భావయేత్ ||',
            },
            {
               "title": '',
            },
            {
               "title": 'రుద్రం పశుపతిం స్థాణుం నీలకంఠముమాపతిమ్ |',
            },
            {
               "title": 'నమామి శిరసా దేవం కిం నో మృత్యుః కరిష్యతి  || 1 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'నీలకంఠం కాలమూర్త్తిం కాలజ్ఞం కాలనాశనమ్ |',
            },
            {
               "title": 'నమామి శిరసా దేవం కిం నో మృత్యుః కరిష్యతి  || 2 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'నీలకంఠం విరూపాక్షం నిర్మలం నిలయప్రదమ్ |',
            },
            {
               "title": 'నమామి శిరసా దేవం కిం నో మృత్యుః కరిష్యతి  || 3 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'వామదేవం మహాదేవం లోకనాథం జగద్గురుమ్ |',
            },
            {
               "title": 'నమామి శిరసా దేవం కిం నో మృత్యుః కరిష్యతి  || 4 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'దేవదేవం జగన్నాథం దేవేశం వృషభధ్వజమ్ |',
            },
            {
               "title": 'నమామి శిరసా దేవం కిం నో మృత్యుః కరిష్యతి  || 5 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'త్ర్యక్షం చతుర్భుజం శాంతం జటామకుటధారిణమ్ |',
            },
            {
               "title": 'నమామి శిరసా దేవం కిం నో మృత్యుః కరిష్యతి  || 6 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'భస్మోద్ధూలితసర్వాంగం నాగాభరణభూషితమ్ |',
            },
            {
               "title": 'నమామి శిరసా దేవం కిం నో మృత్యుః కరిష్యతి  || 7 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'అనంతమవ్యయం శాంతం అక్షమాలాధరం హరమ్ |',
            },
            {
               "title": 'నమామి శిరసా దేవం కిం నో మృత్యుః కరిష్యతి  || 8 ||',
            },
            {
               "title": ' ',
            },
            {
               "title": 'ఆనందం పరమం నిత్యం కైవల్యపదదాయినమ్ |',
            },
            {
               "title": 'నమామి శిరసా దేవం కిం నో మృత్యుః కరిష్యతి  || 9 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'అర్ద్ధనారీశ్వరం దేవం పార్వతీప్రాణనాయకమ్ |',
            },
            {
               "title": 'నమామి శిరసా దేవం కిం నో మృత్యుః కరిష్యతి  || 10 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'ప్రలయస్థితికర్త్తారమాదికర్త్తారమీశ్వరమ్ |',
            },
            {
               "title": 'నమామి శిరసా దేవం కిం నో మృత్యుః కరిష్యతి  || 11 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'వ్యోమకేశం విరూపాక్షం చంద్రార్ద్ధకృతశేఖరమ్ |',
            },
            {
               "title": 'నమామి శిరసా దేవం కిం నో మృత్యుః కరిష్యతి  || 12 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'గంగాధరం శశిధరం శంకరం శూలపాణినమ్ |',
            },
            {
               "title": '(పాఠభేదః) గంగాధరం మహాదేవం సర్వాభరణభూషితమ్ |',
            },
            {
               "title": 'నమామి శిరసా దేవం కిం నో మృత్యుః కరిష్యతి  || 13 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'అనాథః పరమానంతం కైవల్యపదగామిని |',
            },
            {
               "title": 'నమామి శిరసా దేవం కిం నో మృత్యుః కరిష్యతి  || 14 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'స్వర్గాపవర్గదాతారం సృష్టిస్థిత్యంతకారణమ్ |',
            },
            {
               "title": 'నమామి శిరసా దేవం కిం నో మృత్యుః కరిష్యతి  || 15 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'కల్పాయుర్ద్దేహి మే పుణ్యం యావదాయురరోగతామ్ |',
            },
            {
               "title": 'నమామి శిరసా దేవం కిం నో మృత్యుః కరిష్యతి  || 16 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'శివేశానాం మహాదేవం వామదేవం సదాశివమ్ |',
            },
            {
               "title": 'నమామి శిరసా దేవం కిం నో మృత్యుః కరిష్యతి  || 17 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'ఉత్పత్తిస్థితిసంహారకర్తారమీశ్వరం గురుమ్ |',
            },
            {
               "title": 'నమామి శిరసా దేవం కిం నో మృత్యుః కరిష్యతి  || 18 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'ఫలశ్రుతి',
            },
            {
               "title": 'మార్కండేయకృతం స్తోత్రం యః పఠేచ్ఛివసన్నిధౌ |',
            },
            {
               "title": 'తస్య మృత్యుభయం నాస్తి నాగ్నిచౌరభయం క్వచిత్  || 19 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'శతావర్త్తం ప్రకర్తవ్యం సంకటే కష్టనాశనమ్ |',
            },
            {
               "title": 'శుచిర్భూత్వా పథేత్స్తోత్రం సర్వసిద్ధిప్రదాయకమ్  || 20 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'మృత్యుంజయ మహాదేవ త్రాహి మాం శరణాగతమ్ |',
            },
            {
               "title": 'జన్మమృత్యుజరారోగైః పీడితం కర్మబంధనైః  || 21 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'తావకస్త్వద్గతః ప్రాణస్త్వచ్చిత్తోఽహం సదా మృడ |',
            },
            {
               "title": 'ఇతి విజ్ఞాప్య దేవేశం త్ర్యంబకాఖ్యమనుం జపేత్  || 23 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'నమః శివాయ సాంబాయ హరయే పరమాత్మనే |',
            },
            {
               "title": 'ప్రణతక్లేశనాశాయ యోగినాం పతయే నమః  || 24 ||',
            },
            {
               "title": '',
            },
            {
               "title": 'శతాంగాయుర్మంత్రః |',
            },
            {
               "title": 'ఓం హ్రీం శ్రీం హ్రీం హ్రైం హ్రః',
            },
            {
               "title": 'హన హన దహ దహ పచ పచ గృహాణ గృహాణ',
            },
            {
               "title": 'మారయ మారయ మర్దయ మర్దయ మహామహాభైరవ భైరవరూపేణ',
            },
            {
               "title": 'ధునయ ధునయ కంపయ కంపయ విఘ్నయ విఘ్నయ విశ్వేశ్వర',
            },
            {
               "title": 'క్షోభయ క్షోభయ కటుకటు మోహయ మోహయ హుం ఫట్',
            },
            {
               "title": 'స్వాహా ఇతి మంత్రమాత్రేణ సమాభీష్టో భవతి  ||',
            },
            {
               "title": '',
            },
            {
               "title": ' || ఇతి శ్రీమార్కండేయపురాణే మార్కండేయకృత మహామృత్యుంజయస్తోత్రం',
            },
            {
               "title": 'సంపూర్ణమ్  ||',
            }
          ],
           "title": 'మహామృత్యుంజయస్తోత్రం (రుద్రం పశుపతిం)',
         //  "img": require('../imgs/Stotramulu/Siva.png')
        },
        {
          "sotram": [
            {
               "title": 'మహేశ్వరం మహోన్నతం మహేశ్వరం సదానమ-',
            },
            {
               "title": 'ద్గణేశ్వరం గుణాన్వితం గణేశ్వరం జగన్నుతం |',
            },
            {
               "title": 'అనీశ్వరం వృషాశ్వరంహసాశ్వరుద్రగామినం',
            },
            {
               "title": 'సదా భజామి కాలహస్తిసాంబమూర్తిమీశ్వరం ||',
            },
            {
               "title": '',
            },
            {
               "title": 'నిటాలవిస్ఫుటైకదృక్తటాలవహ్నిచిచ్ఛటా',
            },
            {
               "title": 'లసద్ధ్వనిప్రకృజ్జటాలనిష్ఠహైమనం |',
            },
            {
               "title": 'ఘటీభవాదిమౌనిహృత్కుటీభవత్పదం త్రిగుం(విభుం)',
            },
            {
               "title": 'సదా భజామి కాలహస్తిసాంబమూర్తిమీశ్వరం ||',
            },
            {
               "title": '',
            },
            {
               "title": 'ఘనాఘనాదియజ్ఞభుగ్ఘనాఘనప్రచారకృద్-',
            },
            {
               "title": 'ద్యునాఘనామహస్సుహృద్దృఢాసుహృద్ధనుస్స్వనం |',
            },
            {
               "title": 'ఘనాఘనవ్యపేటికాఘనాఘనప్రభంజనం',
            },
            {
               "title": 'సదా భజామి కాలహస్తిసాంబమూర్తిమీశ్వరం ||',
            },
            {
               "title": '',
            },
            {
               "title": 'జటాకుటీరధన్యధున్యభంగభంగసంఘటా-',
            },
            {
               "title": 'ఘుమంఘుమంఘుమంఘుమద్ధ్వనిధ్వనద్దరీస్ఫుటం |',
            },
            {
               "title": 'కటీతటీఘటీకృతోత్కటాజినం కకుప్పటం',
            },
            {
               "title": 'సదా భజామి కాలహస్తిసాంబమూర్తిమీశ్వరం ||',
            },
            {
               "title": '',
            },
            {
               "title": 'సుధాశనాధిరాట్పురీసుగహ్వరీజమంజరీ-',
            },
            {
               "title": 'స్ఫురన్మరందనిర్ఝరీధురాధురాధురీణగీర్గణం |',
            },
            {
               "title": 'విరాట్తురంగమార్గణం విశేషవిష్ణుమార్గణం ',
            },
            {
               "title": 'సదా భజామి కాలహస్తిసాంబమూర్తిమీశ్వరం ||',
            },
            {
               "title": '',
            },
            {
               "title": '|| ఇతి శ్రీ కాళహస్తీశ్వర స్తోత్రం సంపూర్ణం ||',
            }
          ],
           "title": 'శ్రీ కాళహస్తీశ్వర స్తోత్రం',
         //  "img": require('../imgs/Stotramulu/Siva.png')
        },
        {
          "sotram": [
            {
               "title": 'అస్య శ్రీవారాహీ ద్వాదశ నామ స్తోత్రస్య అశ్వానన ఋషిః |',
            },
            {
               "title": 'అనుష్టుప్ఛందః | శ్రీవారాహీ దేవతా |',
            },
            {
               "title": 'శ్రీవారాహి ప్రసాద సిద్ధ్యర్థం |',
            },
            {
               "title": 'సర్వ సంకట హరణ జపే వినియోగః ||',
            },
            {
               "title": '',
            },
            {
               "title": 'పంచమీ దండనాథా చ సంకేతా సమయేశ్వరీ |',
            },
            {
               "title": 'తథా సమయసంకేతా వారాహీ పోత్రిణీ శివా ||',
            },
            {
               "title": '',
            },
            {
               "title": 'వార్తాలీ చ మహాసేనాఽఽజ్ఞాచక్రేశ్వరీ తథా |',
            },
            {
               "title": 'అరిఘ్నీ చేతి సంప్రోక్తం నామ ద్వాదశకం మునే ||',
            },
            {
               "title": '',
            },
            {
               "title": 'నామ ద్వాదశధాభిజ్ఞ వజ్రపంజరమధ్యగః |',
            },
            {
               "title": 'సఙకటే దుఃఖమాప్నోతి న కదాచన మానవః ||',
            },
            {
               "title": '',
            },
            {
               "title": 'ఇతి శ్రీ వారాహీ ద్వాదశనామస్తోత్రం సంపూర్ణం',
            }
          ],
           "title": 'శ్రీ వారాహీ ద్వాదశ నామ స్తోత్రం ',
         //  "img": require('../imgs/Stotramulu/VarahiAmmavaru.png')
        },
        {
          "sotram": [
            {
               "title": 'అధ్బుత విగ్రహ అమరాదీశ్వర అగణిత గుణ గణ అమృత శివ',
            },
            {
               "title": 'ఆనందామృత ఆశ్రిత రక్షక ఆత్మానంద మహేశ శివ',
            },
            {
               "title": 'ఇందు కళాధర ఇంద్రాది ప్రియ సుందర రూప సురేశ శివ',
            },
            {
               "title": 'ఈశ సురేశ మహేశ జనప్రియ కేశవ సేవిత పాద శివ',
            },
            {
               "title": 'ఉరగాది ప్రియ భూషణ శంకర నరక వినాశ నటేశ శివ',
            },
            {
               "title": 'ఊర్జిత దానవ నాశ పరాత్పర ఆర్జిత పాప వినాశ శివ',
            },
            {
               "title": 'సాంబ సదాశివ, సాంబ సదాశివ, సాంబ సదాశివ, సాంబ శివ ||',
            },
            {
               "title": '',
            },
            {
               "title": 'ఋగ్వేద శ్రుతి మౌళి విభూషణ రవి చంద్రాగ్ని త్రినేత్ర శివ',
            },
            {
               "title": 'ఋప మనాది ప్రపంచ విలక్షణ తాప నివారణ తత్త్వ శివ',
            },
            {
               "title": 'లింగ స్వరూప సర్వ బుధ ప్రియ మంగళ మూర్తి మహేశ శివ',
            },
            {
               "title": 'లూతాదీశ్వర రూప ప్రియ శివ వేదాంత ప్రియ వేద్య శివ',
            },
            {
               "title": 'ఎకానేక స్వరూప విశ్వేశ్వర యోగి హృది ప్రియ వాస శివ',
            },
            {
               "title": 'ఐశ్వర్యా శ్రయ చిన్మయ చిద్ఘన అచ్యుతానంత మహేశ శివ',
            },
            {
               "title": 'సాంబ సదాశివ, సాంబ సదాశివ, సాంబ సదాశివ, సాంబ శివ ||',
            },
            {
               "title": '',
            },
            {
               "title": 'ఓంకార ప్రియ ఉరగ విభూషణ హ్రీంకారాది మహేశ శివ',
            },
            {
               "title": 'ఔర సలాలిత అంత కనాశన గౌరీ సమేత మహేశ శివ',
            },
            {
               "title": 'అంబర వాస చిదంబర నాయక తుంబురు నారద సేవ్య శివ',
            },
            {
               "title": 'ఆహార ప్రియ ఆది గిరీశ్వర భోగాది ప్రియ పూర్ణ శివ',
            },
            {
               "title": 'కమలాస్యార్చిత కైలాస ప్రియ కరుణా సాగర కాంతి శివ',
            },
            {
               "title": 'గంగా గిరి సుత వల్లభ గుణ హిత శంకర సర్వ జనేశ శివ',
            },
            {
               "title": 'ఖడ్గ శైల మృదుడ క్కాద్యా యుధ విక్రమ రూప విశ్వేశ శివ',
            },
            {
               "title": 'ఘాతుక బంజన పాతక నాశన గౌరీ సమేత గిరీశ శివ',
            },
            {
               "title": 'జజశ్రిత శ్రుతి మౌళి విభూషణ వేద స్వరూప విశ్వేశ శివ',
            },
            {
               "title": 'సాంబ సదాశివ, సాంబ సదాశివ, సాంబ సదాశివ, సాంబ శివ ||',
            },
            {
               "title": '',
            },
            {
               "title": 'చండ వినాశన సకల జన ప్రియ మండలా దీశ మహేశ శివ',
            },
            {
               "title": 'చత్ర కిరీట సుకుండల శోభిత పుత్ర ప్రియ భువనేశ శివ',
            },
            {
               "title": 'జన్మ జరా మృతి నాశన కల్మష రహిత తాప వినాశ శివ',
            },
            {
               "title": 'ఝంకారా శ్రయ బృంగి రిటి ప్రియ ఓం కారేశ మహేశ శివ',
            },
            {
               "title": 'జ్ఞానా జ్ఞానా వినాశక నిర్మల దీన జన ప్రియ దీప్త శివ',
            },
            {
               "title": 'సాంబ సదాశివ, సాంబ సదాశివ, సాంబ సదాశివ, సాంబ శివ ||',
            },
            {
               "title": '',
            },
            {
               "title": 'టంకాద్యాయుధ ధారణ సత్వర హ్రీంకాది సురేశ శివ',
            },
            {
               "title": 'రంక స్వరూప సహకారోత్తమ వాగీశ్వర వరదేవ శివ',
            },
            {
               "title": 'డంబ వినాశన డిండి మ భూషణ అంబర వాస చిదీశ శివ',
            },
            {
               "title": 'డం డం డమరుక ధరణీ నిశ్చల డుండి వినాయక సేవ్య శివ',
            },
            {
               "title": 'ణలిన విలోచన నటన మనోహర అళి కుల భూషణ అమృత శివ',
            },
            {
               "title": 'సాంబ సదాశివ, సాంబ సదాశివ, సాంబ సదాశివ, సాంబ శివ ||',
            },
            {
               "title": '',
            },
            {
               "title": 'తత్వమ సీత్యాది వాక్య స్వరూపక నిత్యానంద మహేశ శివ',
            },
            {
               "title": 'స్థావర జంగమ భువన విలక్షణ భావుక మునివర సేవ్య శివ',
            },
            {
               "title": 'దుఃఖ వినాశక దళిత మనోన్మన చందన లేపిత చరణ శివ',
            },
            {
               "title": 'ధరణీ ధర శుభ దవళ మనోన్మన చందన లేపిత చరణ శివ',
            },
            {
               "title": 'నానా మణి గణ భూషణ నిర్గుణ నట జన సుప్రియ నాట్య శివ',
            },
            {
               "title": 'సాంబ సదాశివ, సాంబ సదాశివ, సాంబ సదాశివ, సాంబ శివ ||',
            },
            {
               "title": '',
            },
            {
               "title": 'పన్నగ భూషణ పార్వతి నాయక పరమానంద పరేశ శివ',
            },
            {
               "title": 'ఫాల విలోచన భాను కోటి ప్రభ హాలా హల ధర అమృత శివ',
            },
            {
               "title": 'బంధ వినాశన బృహదీశామర స్కందాది ప్రియ కనక శివ',
            },
            {
               "title": 'భస్మ విలేపన భవ భయ నాశన విస్మయ రూప విశ్వేస శివ',
            },
            {
               "title": 'మన్మధ నాశన మధుపాన ప్రియ మందర పర్వత వాస శివ',
            },
            {
               "title": 'సాంబ సదాశివ, సాంబ సదాశివ, సాంబ సదాశివ, సాంబ శివ ||',
            },
            {
               "title": '',
            },
            {
               "title": 'యతి జన హృదయ నివాసిత ఈశ్వర విధి విష్ణ్యాది సురేశ శివ',
            },
            {
               "title": 'రామేశ్వర రమణీయ ముఖాంభుజ సోమ శేఖర సుకృతి శివ',
            },
            {
               "title": 'లంకాదీశ్వర సుర గణ సేవిత లావణ్యా మృత లసిత శివ',
            },
            {
               "title": 'వరదా భయకర వాసుకి భూషణ వన మాలాది విభూష శివ',
            },
            {
               "title": 'సాంబ సదాశివ, సాంబ సదాశివ, సాంబ సదాశివ, సాంబ శివ ||',
            },
            {
               "title": '',
            },
            {
               "title": 'శాంతి స్వరూప జగత్త్రయ చిన్మయ కాంతి మతి ప్రియ కనక శివ',
            },
            {
               "title": 'షణ్ముఖ జనక సురేంద్ర ముని ప్రియ షాడ్గుణ్యాది సమేత శివ',
            },
            {
               "title": 'సంసారార్ణవ నాశన శాశ్వత సాధు హృది ప్రియ వాస శివ',
            },
            {
               "title": 'హర పురుషోత్తమ అద్వైతామృత పూర్ణ మురారి సుసేవ్య శివ',
            },
            {
               "title": 'ళాళిత భక్త జనేశ నిజేశ్వర కాళీ నటేశ్వర కామ శివ',
            },
            {
               "title": 'క్షర రూపాది ప్రియాన్విత సుందర సాక్షి జగత్రయ స్వామి శివ',
            },
            {
               "title": 'సాంబ సదాశివ, సాంబ సదాశివ, సాంబ సదాశివ, సాంబ శివ ||',
            }
          ],
           "title": 'శ్రీ శివ వర్ణమాలా స్తోత్రం ',
         //  "img": require('../imgs/Stotramulu/Siva.png')
        },
        {
          "sotram": [
            {
               "title": 'అయోధ్యా పుర నేతారం మిథిలా పుర నాయికాం',
            },
            {
               "title": 'రాఘవాణాం అలంకారం వైదేహీనాం అలంక్రియాం ||',
            },
            {
               "title": '',
            },
            {
               "title": 'రఘూణం కుల దీపం చ నిమీనం కుల దీపికం',
            },
            {
               "title": 'సూర్య వంశ సముద్భూతమ్ సోమ వంశ సముద్భవాం ||',
            },
            {
               "title": '',
            },
            {
               "title": 'పుత్రం దశరథస్యాద్యమ్ పుత్రీం జనక భూపతే',
            },
            {
               "title": 'వసిష్టాను మతాచారం శతానంద మతానుగం ||',
            },
            {
               "title": '',
            },
            {
               "title": 'కౌసల్యా గర్భ సంభూతం వేడి గర్భోదితం స్వయం',
            },
            {
               "title": 'పుండరీక విశాలాక్షం స్ఫురదిందీ వరేక్షణాం ||',
            },
            {
               "title": '',
            },
            {
               "title": 'చంద్రకాంతాననాంభోజం చంద్ర బింబోపమాననం',
            },
            {
               "title": 'మత్త మాతంగ గమనం మత్త హంస వధూ గతాం ||',
            },
            {
               "title": '',
            },
            {
               "title": 'చందనార్ద్ర భుజా మధ్యం కుంకుమార్ద్ర కుచస్థలీం',
            },
            {
               "title": 'చాపాలంకృత హస్తాబ్జం పద్మాలంకృత పాణికాం ||',
            },
            {
               "title": '',
            },
            {
               "title": 'శరణాగత గోప్తారం ప్రణిపాత ప్రసాదికాం',
            },
            {
               "title": 'కాలమేఘ నిభం రామం కార్త స్వర సమ ప్రభాం ||',
            },
            {
               "title": '',
            },
            {
               "title": 'దివ్య సింహాసనాసీనం దివ్యస్రగ్వస్త్ర భూషణాం',
            },
            {
               "title": 'అనుక్షణం కటాక్షాభ్యాం అన్యోన్య క్షణ కాంక్షిణూ ||',
            },
            {
               "title": '',
            },
            {
               "title": 'అన్యోన్య సదృశాకారౌ త్రిలోక్య గ్రహ దంపతి',
            },
            {
               "title": 'ఇమౌ యువాం ప్రణమ్యాహం భజామ్యద్య కృతార్థతాం ||',
            },
            {
               "title": '',
            },
            {
               "title": 'అనేన స్తోతి యః స్తుత్యం రామం సీతాంచ భక్తితః',
            },
            {
               "title": 'తస్య తౌ తనుతాం పుణ్యాస్సంపదః సకలార్థదాః ||',
            },
            {
               "title": '',
            },
            {
               "title": 'ఏవం శ్రీరామ చంద్రస్య జానక్యాశ్చ విశేషతః',
            },
            {
               "title": 'కృతం హనుమతా పుణ్యం స్తోత్రం సద్యో విముక్తిదం',
            },
            {
               "title": 'యః పఠేత్ ప్రాతరుత్థాయ సర్వాన్ కామనవాప్నుయాత్ ||',
            }
          ],
           "title": 'శ్రీ సీతా రామ స్తోత్రం',
         //  "img": require('../imgs/Stotramulu/Rama.png')
        }
      ]
    },

    "neetipadyalu": {
      "CatID": [
        {
          "Padyalu": [
            {
              "padyam": [
                {
                  "title": "చిత్త శుద్ధి కలిగి చేసిన పుణ్యంబు"
                },
                {
                  "title": "కొంచమైన నదియు గొదవుగాదు"
                },
                {
                  "title": "విత్తనంబు మఱ్ఱి వృక్షంబునకు నెంత"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "మంచి మనసుతొ చేసిన చిన్న పనియైన మంచి ఫలితాన్నిస్తుంది. పెద్ద మర్రిచెట్టుకి కూడ విత్తనము చిన్నదేకదా!"
            },
            {
              "padyam": [
                {
                  "title": "ఆత్మశుద్ధి లేని యాచారమది యేల"
                },
                {
                  "title": "భాండశుద్ధి లేని పాకమేల?"
                },
                {
                  "title": "చిత్తశుద్దిలేని శివపూజలేలరా?"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "మనసు నిర్మలముగా లేనట్లయితే ఆచారములు పాతించతంవల్ల ప్రయోజనం లేదు. పాత్రలు శుభ్రముగాలేని వంట, మనసు స్థిరముగా లేని శివ పూజ వ్యర్థములే అవుతాయి. ఏమీ ప్రయోజనముండదు."
            },
            {
              "padyam": [
                {
                  "title": "గంగిగోవుపాలు గరిటెడైనను చాలు"
                },
                {
                  "title": "కడివెడైన నేమి ఖరము పాలు"
                },
                {
                  "title": "భక్తిగలుగు కూడు పట్టెడైనను చాలు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "కడివెడు గాడిదపాలకంటె గరిటెడు ఆవుపాలు మేలును కలిగించును. భక్తితో పెట్టిన కూడు పట్టెడు అయినప్పటికి తృప్తిని కలిగిస్తుంది."
            },
            {
              "padyam": [
                {
                  "title": "నిక్క మైన మంచినీల మొక్కటి చాల"
                },
                {
                  "title": "తళుకు బెళుకు రాళ్ళు తట్టెడేల?"
                },
                {
                  "title": "చాటుపద్యములను చాలదా ఒక్కటి"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "తట్టెడు గులకరాళ్ళ కంటె ఒకమంచి నీలము శ్రేష్ఠము. అదే విధముగ వ్యర్ధమైన పద్యముల వంటె ఒక చక్కని చాటు పద్యము శ్రేష్ఠమవుతుంది."
            },
            {
              "padyam": [
                {
                  "title": "మిరపగింజచూడ మీద నల్లగనుండు"
                },
                {
                  "title": "కొరికిచూడు లోనచురుకు మనును"
                },
                {
                  "title": "సజ్జను లగునారి సారమిట్లుండురా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "మిరియపుగింజ మీద నల్లగానుప్పటికి దానిని కొరికిన వెంటనే చురుక్కుమంటుంది. మంచి వారు పైకి ఏవిధముగా కనిపించినప్పటికీ అతనిని జాగ్రత్తగా గమనించినచో అసలు విషయము బయటపడుతుంది."
            },
            {
              "padyam": [
                {
                  "title": "మృగమదంబు చూడ మీఁద నల్లగనుండు"
                },
                {
                  "title": "బరిఢవిల్లు దాని పరిమళంబు"
                },
                {
                  "title": "గురువులైన వారి గుణము లీలాగురా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "కస్తూరి చూడటానికి నల్లగా ఉన్నప్పతికి దాని సువాసన నాలుగు దిక్కులకు వెదజల్లునట్లు పెద్దలైన వారు బయటికి ఆడంబరముగ కనపడక గొప్ప గుణములు కలవారై ఉండురు."
            },
            {
              "padyam": [
                {
                  "title": "మేడిపండు చూడ మేలిమై యుండు"
                },
                {
                  "title": "పొట్టవిప్పి చూడ పురుగులుండు"
                },
                {
                  "title": "బిరికి వాని మదిని బింకమీలాగురా"
                },
                {
                  "title": "విశ్వ దాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "అత్తిపండు పైకందముగా కనపడుతుంది. దానిలొపల పురుగులుంటాయి. అదే విధముగ పిరికి వాని ధేర్యము కూడ పైన పటారము లొన లొటారముగ ఉంటుంది."
            },
            {
              "padyam": [
                {
                  "title": "నేర నన్నవాఁడు నెరజాణ మహిలోన"
                },
                {
                  "title": "నేర్తునన్న వాఁడు నింద జెందు"
                },
                {
                  "title": "ఊరుకున్న వాఁడె యుత్తమయోగిరా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "తనను ఏమీ రాదు అని చెప్పుకొనే వాడు నిజముగా తేలివైనవాడు. అన్నీ వచ్చుటకు చెప్పువాడు గౌరవాన్ని పొందలేడు. మౌనముగానున్నవాడే ఉత్తమ యౌగి అనిపించుకొంటాడు."
            },
            {
              "padyam": [
                {
                  "title": "గంగ పాఱు నెపుడు కదలని గతితోడ"
                },
                {
                  "title": "ముఱికి వాగు పాఱు మ్రోఁతతోడ"
                },
                {
                  "title": "పెద్ద పిన్నతనము పేరిమి యీలాగు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "గొప్పదైన గంగానది కూడ ప్రశాంతంగా ప్రవహిస్తుంది. చిన్నదైన మురికి కాలువ పెద్ద శబ్ధం చేస్తూ ప్రవహిస్తుంది. గొప్పవారికి, నీచునికి ఈ రకమైన భేదమే ఉన్నది."
            },
            {
              "padyam": [
                {
                  "title": "నిండునదులు పారు నిల్చి గంభీరమై"
                },
                {
                  "title": "వెఱ్ఱివాగు పాఱు వేగబొర్లి"
                },
                {
                  "title": "అల్పుడాడు రీతి నధికుండు నాడునా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ! 10"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఓ వేమా ! నీటితో నిండియున్న నదులు గంభీరముగ నిల్లిచి ప్రవహించుచుండును. చిన్న సెలయేరులు పైకి పొర్లి వేగముగ ప్రవహించుచుండును. చెడ్డగుణములు గలవారు మాటలాడినంతటి తొందరగా, మంచిగుణములు గలవారు మాట్లాడరు."
            },
            {
              "padyam": [
                {
                  "title": "అల్పుడెపుడు పల్కు నాడంబరము గాను"
                },
                {
                  "title": "సజ్జనుండు బల్కు చల్లగాను"
                },
                {
                  "title": "కంచు మ్రోగినట్లు కనకంబు మ్రోగునా"
                },
                {
                  "title": "విశ్వ దాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ప్రపంచములొ ఉన్న జనులకు ప్రియమైన పలుకులతో ఆనందము కలిగించు వేమనా! అల్పుడు శాంతముతో మాట్లాడతాడు. కంఛు ధ్వని చేసినట్లుగా బంగాము ధ్వని చేయదుకదా! అల్పుడు కంచుతోనూ, సజ్జనుడు బంగారముతోనూ సమానము."
            },
            {
              "padyam": [
                {
                  "title": "కులము లోన నొకడు గుణవంతుడుండిన"
                },
                {
                  "title": "కులము వెలయు వాని గుణము చేత"
                },
                {
                  "title": "వెలయు వనములోన మలయజంబున్నట్లు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "కులములో ఒక వ్యక్తి గుణవంతుడన్నట్లయితే ఆ కులమంతా అతనివలన గౌరవాన్ని పొందుతుంది. వనములో ఒక్క మంచి గంధపు చెట్టు ఉన్నప్పటికీ ఆ వనమంతా వాసన వెదజల్లుతుంది."
            },
            {
              "padyam": [
                {
                  "title": "పూజకన్న నెంచ బుద్ధి నిదానంబు"
                },
                {
                  "title": "మాటకన్న నెంచ మనసు దృఢము"
                },
                {
                  "title": "కులముకన్న మిగుల గుణము ప్రధానంబు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "పూజపునస్కారముల కంటె బుద్ధి ప్రధానము. మాటకంటె మనసు ప్రధానము. కులముకంటె గుణము ప్రధానము."
            },
            {
              "padyam": [
                {
                  "title": "ఉత్తముని కడుపున నోగు జన్మించిన"
                },
                {
                  "title": "వాఁడె చెఱకు వాని వంశమెల్లఁ"
                },
                {
                  "title": "జెఱకు వెన్నుపుట్టి చెరపదా! తీపెల్ల"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "చెరకు మొక్క చివర కంకిపుట్టి చెరకు యొక్క తీపిని చెరచునట్లుగా, ఉత్తమ వంశములో దుష్టుడు పుట్టిన ఆ వంశము యొక్క గౌరవము నశించును."
            },
            {
              "padyam": [
                {
                  "title": "కులములోన నొకఁడు గుణహీనుఁడుండిన"
                },
                {
                  "title": "కులము చెడును కాని గుణము వలన"
                },
                {
                  "title": "వెలయు జెఱకునందు వెన్ను వెడలి నట్లు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "చెరకు గడకు చివర వెన్నులడితే చప్పబారినట్లుగా గుణహీనుడైనవ్యక్తి వలన ఆ కులమంతా చెడిపోవును."
            },
            {
              "padyam": [
                {
                  "title": "రాముఁడొకఁడు పుట్టి రవికుల మీడేర్చె"
                },
                {
                  "title": "కురుపతి జనియించి కులముఁ జెఱచె"
                },
                {
                  "title": "ఇలనుఁ బుణ్యపాప మీలాగు గాదొకో"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "రాముని పుట్టుకతో రఘువంశము ఉద్ధరింపబడింది. దుర్యోధనుని పుట్టుకతో కురువంశము నశించింది. ప్రపంచములో పుణ్య పాపములు విధముగానే ఉంటాయి."
            },
            {
              "padyam": [
                {
                  "title": "హీనగుణమువాని నిలుజేరనిచ్చిన\n"
                },
                {
                  "title": "నెంతవానికైన నిడుము గలుగు\n"
                },
                {
                  "title": "ఈఁగ కడుఁపు జొచ్చి యిట్టట్టు సేయదా\n"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!\n"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "నీచుణ్ణి ఇంటిలో పెట్టిన ఎటువంటి వానికైన కష్టము కలుగుతుంది. ఈగ కడుపులోకి వెళితె వికారమును కలిగిస్తుంది."
            },
            {
              "padyam": [
                {
                  "title": "వేరుపురుగుచేరి వృక్షంబుజెఱచుఁను"
                },
                {
                  "title": "చీడ పురుగు జేరి చెట్టుఁజెఱచుఁ"
                },
                {
                  "title": "కుత్సితుండు చేరి గుణవంతుఁజెఱచురా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "వేరువురుగు పెద్ద వృక్షాన్ని పాడుచేస్తుంది. చీడ పురుగు చిన్న చెట్టుని నశింపజేస్తుంది. చెడ్డవాడు గుణవంతుని చేరి అతన్ని నాశనము చేస్తాడు."
            },
            {
              "padyam": [
                {
                  "title": "హీనుఁడెన్ని విద్యలు నేర్చినఁగాని"
                },
                {
                  "title": "ఘనుఁడుఁగాడు హీనజనుఁడె కాని"
                },
                {
                  "title": "పరిమళములు మోయ ఖరము తా గజమౌనె"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "నీచుడైన వ్యక్తి ఎంత చదువు చదివినా అతడు గొప్పవాడుకాలేడు. సుగంధ ద్రవ్యములు మోసినంత మాత్రాన గాడిద ఏనుగు కాలేదు."
            },
            {
              "padyam": [
                {
                  "title": "విద్యలేనివాడు విద్వాంసు చేరువ"
                },
                {
                  "title": "నుండగానె పండితుండు గాడు"
                },
                {
                  "title": "కొలని హంసలకడ గొక్కెర లున్నట్లు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ! 20"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "హంసలతో కలిసినంత మాత్రమున కొంగమారనట్లుగా, పండితులతో కలిసినప్పటికి మూర్ఖుడు మారడు."
            },
            {
              "padyam": [
                {
                  "title": "అల్పజాతి వాని కధికార మిచ్చిన"
                },
                {
                  "title": "దొడ్డవారినెల్ల తొలగగొట్టు"
                },
                {
                  "title": "చెప్పు తినెడు కుక్క చెరకు తీపెరుగునా?"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "దుష్టునకు అధికారము నిచ్చిన యెడల మంచి వారందరినీ వెడల కొట్టును. చెప్పుతినెడి కుక్క, చెరకుతీపియేరుగదు."
            },
            {
              "padyam": [
                {
                  "title": "అల్పుఁ డైన వాని కధిక భాగ్యము గల్గ"
                },
                {
                  "title": "దొడ్డవారి దిట్టి తొలగఁ గొట్టు"
                },
                {
                  "title": "అల్పబుద్ధి వా డధికుల నెఱఁగునా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "మూర్ణునికి సంపదగలిగినట్లయితే పెద్ద వారినందరిని తిరస్కరించి తిరుగుతాడు. అల్పుడైన వానికి గొప్ప వారి యొక్క శక్తి గురించి ఏమి తెలుస్తుంది."
            },
            {
              "padyam": [
                {
                  "title": "ఎద్దుకైనఁగాని యేడాది తెల్పిన"
                },
                {
                  "title": "మాట దెలసి నడచు మర్మ మెఱిఁగి"
                },
                {
                  "title": "మొప్పె తెలియలేడు ముప్పదేండ్లకునైన"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఒక సంవత్సరముపాటు బోధించినట్లెతే ఎద్దుకూడ మర్మములను తెలిసికొని నడుచుకుంటుంది. కాని ముప్ప్తె సంవత్సరాల నేర్పినప్పటికీ మూర్ఖుడు తెలిసికొనలేడు."
            },
            {
              "padyam": [
                {
                  "title": "ఎలుకతోలుఁదెచ్చి యేడాది యుతికిన"
                },
                {
                  "title": "నలుపు నలుపేగాని తెలుపురాదు"
                },
                {
                  "title": "కొయ్యబొమ్మను దెచ్చి కొట్టినఁ బలుకునా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఎలుక తోలు ఎంతసేపు ఉతికినప్పటికీ అది తెలుపుగా మారదు. కర్ర్తో చేసిన బొమ్మ ఎంత కొట్టినప్పటికీ మాట్లడదు."
            },
            {
              "padyam": [
                {
                  "title": "పాము కన్న లేదు పాపిష్టి జీవంబు"
                },
                {
                  "title": "అట్టి పాము చెప్పినట్లు వినును"
                },
                {
                  "title": "ఖలుని గుణము మాన్పు ఘను లెవ్వరును లేరు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "పామువంటి పాపిష్టి జీవికూడ ఏదైన చెఊఇన వింటుంది కాని మూర్ఖునికి ఎంత చెప్పిన అతని గుణము మారదు."
            },
            {
              "padyam": [
                {
                  "title": "వేము పాలువోసి ప్రేమతో బెంచిన"
                },
                {
                  "title": "చేదువిరిగి తీపజెందబోదు"
                },
                {
                  "title": "ఓగు నోగెగాక యుచితజ్ఞు డెటులౌను"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "వేప చెట్టుకి పాలు పోసి పెంచినప్పటికి చేస్దు విరిగి తీపెక్కదు. అదే విధంగా చెడ్డవాడు చెడ్డవాడే కాని మంచివాడు కాలేడు."
            },
            {
              "padyam": [
                {
                  "title": "ముష్టి వేపచెట్లు మొదలంట ప్రజలకు"
                },
                {
                  "title": "పరగ మూలికకుఁ బనికివచ్చు"
                },
                {
                  "title": "నిర్దయాత్మకుండు నీచుఁడెందునకౌను"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఎంత పంచదార పోసి వండినప్పటికి పాపరపండ్లలో తీపి ఎక్కడు. అదే విధముగ దోష్టులకు మంచి గుణము అలవడదు."
            },
            {
              "padyam": [
                {
                  "title": "పాలు పంచదార పాపర పండ్లలోఁ"
                },
                {
                  "title": "జాలఁబోసి వండఁ జవికిరావు"
                },
                {
                  "title": "కుటిల మానవులకు గుణమేల కల్గురా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఎంత పంచదార పోసి వండినప్పటికి పాపరపండ్లలో తీపి ఎక్కడు. అదే విధముగ దోప్పులకు మంచి గుణము అలవడదు."
            },
            {
              "padyam": [
                {
                  "title": "పాల నీడిగింట గ్రోలుచునుండెనా"
                },
                {
                  "title": "మనుజులెల్లఁగూడి మద్యమండ్రు"
                },
                {
                  "title": "నిలువఁదగని చోట నిలువ నిందలు వచ్చు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఈడిగవాని ఇంటిలో పాలు తగినా అవి మద్యమని లోకులు భావిస్తారు. నిలువ గూడని స్థలములో నిలిస్తే అపకీర్తి కలుగుతుంది."
            },
            {
              "padyam": [
                {
                  "title": "కానివాతోడఁ గలసి మెలఁగుచున్నఁ"
                },
                {
                  "title": "గానివానిగానె కాంతు రవని"
                },
                {
                  "title": "తాటి క్రింద పాలు ద్రాగిన చందమౌ"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ! 30"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "పనికిరానివానితో తిరిగిన వారిని అందరూ పనికిరానివానిగానే చూస్తారు. తాటిచెట్టు కింద పాలు త్రాగినప్పటికి కల్లు త్రాగినట్లుగానే అందరూ భావిస్తారు."
            },
            {
              "padyam": [
                {
                  "title": "తామసించి చేయఁదగ దెట్టికార్యంబు"
                },
                {
                  "title": "వేగిరింప నదియు విషమెయగును"
                },
                {
                  "title": "పచ్చికాయదెచ్చి బడవేయ ఫలమౌన?"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "కోపముతో ఏపనీ చేయకూడదు. అలా చేసినట్లై ఆపని జరగదు. వ్యతిరేకంగా కూడ జరుగుతుంది. పచ్చికాయనుతెచ్చి మూసలో వేసినంత మాత్రాన అది పండు కాదుగదా!"
            },
            {
              "padyam": [
                {
                  "title": "కోపమునను ఘనత కొంచమైపోవును"
                },
                {
                  "title": "కోపమును మిగులఁగోడు గలుగుఁ"
                },
                {
                  "title": "గోపమడచెనేని గోర్కెలునీడేరు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "కోపము వలన గొప్పతనము నశించటమే గాక దుఃఖము కలుగుతుంది. కోపమును తగ్గించుకొన్న యెడల అన్ని కోరికలు ఫలిస్తాయి."
            },
            {
              "padyam": [
                {
                  "title": "నీళ్ళలోన మొసలి నిగిడి యేనుఁగు దీయు"
                },
                {
                  "title": "బయట కుక్కచేత భంగపడును"
                },
                {
                  "title": "స్థానబలిమిగాని తన బలిమి కాదయా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "నీటిలో నున్నపుడు మొసలి ఏనుగును కూడ జయింస్తుంది. కాని బయట కుక్కను కూడ ఏమి చేయలేదు. అది స్థానమహిమేకాని తనమహిమకాదు."
            },
            {
              "padyam": [
                {
                  "title": "నీళ్ళలోన మీను నిగిడి దూరముపారు"
                },
                {
                  "title": "బైట మూరుడైన బారలేదు"
                },
                {
                  "title": "స్ధానబల్మిగాని తనబల్మి కాదయా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "నీటిలో స్వేచ్చగ సంచరించే చేప భూమి మీదకు రాగానే చనిపోతుంది. అదిస్థాన మహిమకాని తనమహిమ మాత్రం కాదుకదా!"
            },
            {
              "padyam": [
                {
                  "title": "నీళ్ళమీదనోడ తిన్నగఁబ్రాకు"
                },
                {
                  "title": "బైట మూరుడై బారలేదు"
                },
                {
                  "title": "నెలవు దప్పుచోట నేర్పరి కొఱగాడు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "నీటిమీద ఏ ఆటంకము లేకుండ తిరిగి ఓడ భూమి పై ఒక మూరెడు కూడ వెళ్ళలేదు. ఎంత నేర్పరి అయినప్పటికీ తన స్థానము మారిన పనికి రాని వాడవుతాడు."
            },
            {
              "padyam": [
                {
                  "title": "కులము లేని వాడు కలిమిచే వెలయును"
                },
                {
                  "title": "కలిమిలేనివాని కులము దిగును"
                },
                {
                  "title": "కులముకన్న భువిని కలిమి ఎక్కువ సుమీ"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "తక్కువ కులము వాడైనప్పటికి ధనమున్నట్లయితే అతడు గౌరవాన్ని పొందును. ధనము లేనట్లయితే ఉన్నత కులస్థుడు కూడ రాణింపదు. కాబట్టి కాలముకంటే ధనము ఎక్కువ."
            },
            {
              "padyam": [
                {
                  "title": "కులము గలుగువాఁడు గోత్రంబు గలవాఁడు"
                },
                {
                  "title": "విద్యచేత విఱ్ఱవీగువాఁడు"
                },
                {
                  "title": "పసిడి గలుగువాని బానిస కొడుకులు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "మంచి కులము గలవాడు, మంచి గోత్రముకలవాడు, చదువు కలిగిన వాడు బంగారము గలవానికి బానిసలవు అవుతారు. లోకములో ధనమే ప్రధానము."
            },
            {
              "padyam": [
                {
                  "title": "కనియు గానలేఁడు కదలింపఁడా నోరు"
                },
                {
                  "title": "వినియు వినగలేడు విస్మయమున"
                },
                {
                  "title": "సంపద గలవాని సన్నిపాతంబిది"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ధనమున్నవాడు సన్నిపాత రోగం వచ్చిన వచ్చినవలె ఎవరైన తనని చూచిన చూడనట్లుగా, వినినప్పటికీ విననట్లుగా నటిస్తాడు."
            },
            {
              "padyam": [
                {
                  "title": "ఏమి గొంచువచ్చె నేమితాఁ గొనిపోవుఁ"
                },
                {
                  "title": "బుట్టువేళ నరుడు గిట్టువేళ"
                },
                {
                  "title": "ధనము లెచటికేగు దానెచ్చటికినేగు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "మనిషి పుట్టీంపుడు తన కూడ తీసికొని రాలేదు. చనిపోయినప్పుదు కూద ఏమి తీసికొని వెళ్ళలేడు తానెక్కదికిపోతడో, సంపదలు ఎక్కడికి పోతాయో తెలియక లోభియై గర్వించటం వ్యర్ధము."
            },
            {
              "padyam": [
                {
                  "title": "తనువ దెవరి సొమ్ము తనదని పోషించి"
                },
                {
                  "title": "ద్రవ్య మెవరిసొమ్ము దాచుకొనcగ"
                },
                {
                  "title": "ప్రాణ మెవరిసొమ్ము పారిపోవక నిల్వ"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ! 40"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "తనస్వంతమని పోషించుటకు ఈ సరీరము ఎవరిదీకాదు. దాచినపెట్టుటకు ధనము ఎవరిదీకాదు. పారిపోకుండ నిలుచుటకు ఈ ప్రాణము ఎవరిదీకాదు. ఇవి ఏమియు శాశ్వతములు కావు."
            },
            {
              "padyam": [
                {
                  "title": "గొడ్డుటావు బదుక గుండ గొంపోయిన"
                },
                {
                  "title": "పాలనీక తన్ను పండ్లురాల"
                },
                {
                  "title": "లోభివాని నడుగ లాభంబు లేదయా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "గొడ్డు బోతైన ఆవు దగ్గరకి పాలుపితకటానికి కుండను తీసికొనివెళ్తే పండ్లు రాలేటట్టు తన్నుతుంది కాని పాలు ఇవ్వదు అదే విధముగా లోభిని యాచించటం కూడ వ్యర్థము."
            },
            {
              "padyam": [
                {
                  "title": "మేక కుతికబట్టి మెడచన్ను గుడవంగ"
                },
                {
                  "title": "ఆఁకలేల మాను ఆశగాక"
                },
                {
                  "title": "లోభివాని నడుగ లాభంబు లేదయా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "మేక మెడకిందనున్న చన్నులను కుడిచిన పాలు దొరకవు. ఇదే రీతిగాలోభిని యాచించిన ప్రయోజనముండదు."
            },
            {
              "padyam": [
                {
                  "title": "పెట్టిపోయలేని వట్టి నరులు భూమిఁ"
                },
                {
                  "title": "పుట్టనేమి వారు గిట్టనేమి"
                },
                {
                  "title": "పుట్టలోనఁ జెదలు పుట్టవా గిట్టవా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఎదుతి వారికి సహాయము చేయనివాడు పుట్టినా చచ్చినా ఒకటే. పుట్టలో చెదలు పుట్టినా, చచ్చినా ఒకటే కదా!"
            },
            {
              "padyam": [
                {
                  "title": "ఆశచేత మనుజు లాయువు గలనాళ్ళు"
                },
                {
                  "title": "తిరుగుచుండ్రు భ్రమను ద్రిప్పలేక"
                },
                {
                  "title": "మురికి భాండమందు ముసుగు నీగల భంగి"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఆయువు ఉన్నంత కాలము మనుష్యులు ఆశ వదలలేక కాలము గడుపుచుందురు. మురికి కుండలో ఈగలు ముసిరినట్లే వారు సంచరించుదురు."
            },
            {
              "padyam": [
                {
                  "title": "ఆశ పాపజాతి యన్నింటికంటెను"
                },
                {
                  "title": "ఆశచేత యతులు మోసపోరె"
                },
                {
                  "title": "ఆశ బుట్టి మనుజు డారీతి చెడిపోవు"
                },
                {
                  "title": "చూచి విడుచువారె శుద్ధాత్ములెందైన"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఆశ చాలా పాపమయినది. అశచే మునులు సహితము చెడిపోయిరి. ఆ ఆశను విడిచినవారే నిష్కల్మషమయిన మనసు గలవారు."
            },
            {
              "padyam": [
                {
                  "title": "అన్నిదానములను నన్నదానమె గొప్ప"
                },
                {
                  "title": "కన్నతల్లికంటె ఘనములేదు"
                },
                {
                  "title": "ఎన్న గురునికన్న నెక్కుడులేదయా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "అన్ని దానములకంటె అన్నదానము గొప్పది. కన్నతల్లి కంటె మించినదిలేదు. గురువుకంటె గొప్పదిలేదు."
            },
            {
              "padyam": [
                {
                  "title": "ఆశకోసి వేసి యనలంబు చలార్చి"
                },
                {
                  "title": "గోఁచి బిగియగట్టి గుట్టు దెలసి"
                },
                {
                  "title": "నిలిచి నట్టివాఁడె నెఱయోగి యెందైన"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఆశ వదలి, ఆశలను అగ్ని చల్లార్చుకొని, కామమువదలి గోచిబిగించి కట్టి, జ్ఞానము తెలుసుకొనువాడే నేర్పరియైన యోగి."
            },
            {
              "padyam": [
                {
                  "title": "కనకమృగము భువిని గద్దు లేదనకను"
                },
                {
                  "title": "తరుణి విడిచి చనియె దాశరధియు"
                },
                {
                  "title": "తెలివిలేనివాడు దేవుడెట్లాయెరా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భూమిపై బంగారులేళ్ళు వున్నవో లేవో అని ఆలోచింపకయే శ్రీరాముడు భార్యను విడచి ఆ లేడి వెంటబడెను. ఆ మాత్రము తెలుసుకొన లేనివాడు దేవుడెట్లయ్యెను?"
            },
            {
              "padyam": [
                {
                  "title": "చచ్చిపడిన పశువు చర్మంబు కండలు"
                },
                {
                  "title": "పట్టి పుఱికి తినును పరగ గ్రద్ద"
                },
                {
                  "title": "గ్రద్ద వంటివాడు జగపతి కాడొకో"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ! 50"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "గ్రద్ద చనిపోయిన పశువుయొక్క చర్మమును, కండలను ఊడబెరికి తినును, ఈ రాజులును ఆ గ్రద్దవంటివారే కదా."
            },
            {
              "padyam": [
                {
                  "title": "ఆలను బుగ్గ పుట్టినప్పుడే క్షయమౌను"
                },
                {
                  "title": "గలలఁ గాంచులక్ష్మి గనుటలేదు"
                },
                {
                  "title": "ఇలను భోగభాగ్య మీతీరు గాదొకో"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "కెరటములో బుట్టిన బుడగలు అప్పుడే నిశించును. కలలోకనబడులక్ష్మిని పొందలేము. ఈభూమిలో భోగభాగ్యములుకూడా ఇట్టివేకదా!"
            },
            {
              "padyam": [
                {
                  "title": "కోతి నొనరదెచ్చి కొత్త పుట్టము గట్టి"
                },
                {
                  "title": "కొండముచ్చులెల్ల గొలిచినట్లు"
                },
                {
                  "title": "నీతిహీనునొద్ద నిర్భాగ్యుడుండుట"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "కొండముచ్చులు కోతిని తెచ్చి, క్రొత్తవస్త్రమునట్టి పూజించినట్లే నిర్భాగ్యులు గుణము లేనివారిని కొలుచుచుందురు."
            },
            {
              "padyam": [
                {
                  "title": "కల్లలాడువాని గ్రామకర్త యరుగు"
                },
                {
                  "title": "సత్యమాడువాని స్వామి యరుగు"
                },
                {
                  "title": "బెక్కుతిండపోతుఁబెండ్లా మెరుంగురా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "అబద్ధమాడు వానిని గ్రామపెద్ద తెలుసుకొనును. సత్యవంతుని భగవంతుడు తెలుసుకొనును. తిండిపోతుని భార్య యెరుగును."
            },
            {
              "padyam": [
                {
                  "title": "కల్ల నిజమెల్ల గరకంఠు డెరుగును,"
                },
                {
                  "title": "నీరు పల్లమెరుగు నిజముగాను"
                },
                {
                  "title": "తల్లితానెరుగు తనయుని జన్మంబు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "నీరు పల్లమెరుగును, సత్యము అసత్యము భగవంతుడు తెలుసుకొనును. కుమారుని పెట్టుక తల్లికే తెలుసును."
            },
            {
              "padyam": [
                {
                  "title": "మైలకోకతోడ మాసినతలతోడ"
                },
                {
                  "title": "ఒడలు మురికితోడ నుండెనేమి"
                },
                {
                  "title": "అగ్రకులజుఁడైన నట్టిట్టు పిల్వరు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "మాసిన చీరతోను, మాసినతలతోను, మురికిగాయున్న శరీరముతోనువున్నచో గొప్పకులమునందు బుట్టినవారినయినను హినముగాచూతురు."
            },
            {
              "padyam": [
                {
                  "title": "ఉప్పులేనికూర హీనంబు రుచులకు"
                },
                {
                  "title": "పప్పులేని తిండి ఫలములేదు"
                },
                {
                  "title": "యప్పులేనివాడె యధిక సంపన్నుండు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఉప్పులేని కూర రుచిగావుండదు. పప్పులేని భోజనము బలవర్ధకముకాదు. అప్పులేనివాడే ధనవంతుడు."
            },
            {
              "padyam": [
                {
                  "title": "చెట్టుపాలు జనులు చేదందు రిలలోన"
                },
                {
                  "title": "ఎనుపగొడ్డు పాలదెంత హితవు"
                },
                {
                  "title": "పదుగురాడుమాట పాటియై ధరజెల్లు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఈ ప్రపంచములో జనులు చెట్లపాలు మంచివి గావందురు. గేదెపాలు వారికి హితముగా నుండును. ఈ ప్రపంచములో పదిమందీ ఆడుమాటయే చెల్లును."
            },
            {
              "padyam": [
                {
                  "title": "పట్టుపట్టరాదు పట్టి విడువరాదు"
                },
                {
                  "title": "పట్టేనేని బిగియ బట్టవలయు"
                },
                {
                  "title": "బట్టివిడుచుకన్న బరగ జచ్చుటమేలు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "పట్టుదలయే వహింపరాదు. వహించినచో ఆ పట్టు వదలరాదు. పట్టినపట్టు నడిమిలోనే విడచుటకంటే మరణము మేలు."
            },
            {
              "padyam": [
                {
                  "title": "తప్పులెన్నువారు తండోపతండంబు"
                },
                {
                  "title": "లుర్విజనులకెల్ల నుండు తప్పు"
                },
                {
                  "title": "తప్పులెన్నువారు తనతప్పులెరుగరు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఇతరుల తప్పులను పట్టుకొనువారు, అనేకులు గలరు. కాని తమ తప్పులను తాము తెలుసుకొనలేరు."
            },
            {
              "padyam": [
                {
                  "title": "అనగ ననగ రాగ మతిశయిల్లుచునుండు"
                },
                {
                  "title": "తినగ తినగ వేము తియ్యనుండు"
                },
                {
                  "title": "సాధనమున పనులు సమకూరు ధరలోన"
                },
                {
                  "title": "విశ్వధాభిరామ! వినుర వేమ! 60"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "తరచుగ పాడుచుండిన కంఠధ్వని మాధుర్యముగ నుండును. ప్రతిదినము తినుచుండిన వేపవేరైనను తియ్యగ నుండును. ప్రయత్నము చేయచుండిన పనులు నేరవేరును. ఈ ప్రపంచమున పద్ధతులు యీ విధముగ ఉండును."
            },
            {
              "padyam": [
                {
                  "title": "తమకుగల్గు పెక్కు తప్పులునుండగా"
                },
                {
                  "title": "ఓగు నేరమెంచు నొరులగాంచి"
                },
                {
                  "title": "చక్కిలంబుగాంచి జంతిక నగినట్లు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "తనయందు అనేక్ తప్పులు పెట్టుకొని, దుర్మార్గులు ఇతరుల తప్పులను యెన్నుచుదురు. చక్కిలమునుచూచి జంతిక నవ్వినట్లు వుండును కదా!"
            },
            {
              "padyam": [
                {
                  "title": "ఇనుము విరిగె నేని యిరుమారు ముమ్మారు"
                },
                {
                  "title": "కాచి యతుకవచ్చు క్రమముగాను"
                },
                {
                  "title": "మనసు విరిగెనేని మరియంట నేర్చునా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఇనుము విరిగిన కాల్చి, అతుకవచ్చును, మనసు విరిగినచో మరల అంటీంచుట ఎవరితనము కాదు."
            },
            {
              "padyam": [
                {
                  "title": "ఒరుని చెరచదమని యుల్లమం దెంతురు"
                },
                {
                  "title": "తమకుచే టెరుగని ధరణి నరులు"
                },
                {
                  "title": "తమ్ము జెఱచువాడు దేవుడు లేడొకో"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఇతరులను పాదుచేయవలెయునని కొందరు ఆలోచనచేయుదురు. కాని, తమకు కలుగు ఆపదలను గ్రహింపలేరు. ఒకరిని పాదుచేయ వలయుననిన, భగవంతుడు వారినే పాడుచేయును."
            },
            {
              "padyam": [
                {
                  "title": "కానివాని చేతగాసు వీసంబిచ్చి"
                },
                {
                  "title": "వెంటదిరుగువాడె వెఱ్రివాడు"
                },
                {
                  "title": "పిల్లి తిన్న కోడి పిలిచిన పలుకునా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "దుర్మార్గుని చేతికి ధనముయిచ్చి, దానికై మరల అతని వెంట తిరుగుట తెలివితక్కువతనము. పిల్లిమ్రింగినకోడి పిలిచిననూ పలుకదుకదా."
            },
            {
              "padyam": [
                {
                  "title": "మాటలాడనేర్చి మనసు రాజిలజేసి"
                },
                {
                  "title": "పరగఁ బ్రియము చెప్పి బడలకున్న"
                },
                {
                  "title": "నొకరి చేత సొమ్ము లూరక వచ్చువా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఇతరులకు సంతోషము కలుగునట్లు మాటలాదు విధానము నేర్చుకొని వారి మనస్సు ఆనందపరచి, శ్రమపడకుండ వారి నుండి చేతిలో సొమ్ము తేరగనే రాదు."
            },
            {
              "padyam": [
                {
                  "title": "చంపదగినయట్టి శత్రువు తనచేత"
                },
                {
                  "title": "జిక్కెనేని కీడు సేయరాదు"
                },
                {
                  "title": "పొసగ మేలు చేసి పొమ్మనుటె చాలు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "చంపదగినట్టి శత్రువు తన చేతిలో చిక్కిననూ, అపకారము చేయక, దగిన ఉపకారమునే చేసి విడిచిపెట్టుట మంచిది."
            },
            {
              "padyam": [
                {
                  "title": "వాన గురియకున్న వచ్చును క్షామంబు"
                },
                {
                  "title": "వాన గురిసెనేని వరదపారు"
                },
                {
                  "title": "వరద కరవు రెండు వలసతో నెరుగుడీ"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "వానగురియనచో కరువు వచ్చును, వానకురిసిన వరద వచ్చును. వరదా కరువూ రెండునూ కదాని వెంటా మరియెకటి వచ్చునని తెలుసుకొనవలెను."
            },
            {
              "padyam": [
                {
                  "title": "పుట్టిన జనులెల్ల భూమిలో నుండిన"
                },
                {
                  "title": "పట్టునా జగంబు వట్టిదెపుడు"
                },
                {
                  "title": "యముని లెక్క రీతి అరుగుచు నుందురు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "పుట్టిన వారందరూ మరణించనిచో యీ భూగోళము పట్టదు. యమునిలెక్క ప్రకారము ఒకరి తరువాత ఒకరుచనిపొవుచునే యుందురు."
            },
            {
              "padyam": [
                {
                  "title": "వాన రాకడయును బ్రాణాంబు పోకడ"
                },
                {
                  "title": "కానబడ దదెంత ఘనునికైన"
                },
                {
                  "title": "కానపడిన మీద కలియెట్లు నడచురా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఈ కలియుగములో వానరాకడ, ప్రాణము పోకడ ముందుగా యెవరునూ తెలుసుకొనలేరు. ఇది కలియుగ ధర్మము."
            },
            {
              "padyam": [
                {
                  "title": "చిప్పబడ్డ స్వాతిచినుకు ముత్యంబాయె"
                },
                {
                  "title": "నీటిబడ్డ చినుకు నీటఁగలిసె"
                },
                {
                  "title": "బ్రాప్తిగల్గుచోట ఫలమేల తప్పురా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ! 70"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "స్వాతికార్తిలో ముత్యపు చిప్పలో పదినచినుకు ముత్యమగును నీటబదినది నీటిలో కలిసిపోవును. ప్రాప్తించుచోట ఫలము తప్పదు."
            },
            {
              "padyam": [
                {
                  "title": "ఎన్నిచోట్లు తిరిగి యేపాట్లు పడినను"
                },
                {
                  "title": "అంటనియ్యక శని వెంటదిరుగు"
                },
                {
                  "title": "భూమి క్రొత్తలైన భుక్తులు క్రొత్తలా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఎన్నిచోట్ల తిరిగి ఎన్ని కష్తములు పడినను, లాభము కలుగునీయక శని వెంటాడి తిరుగుచుండును. తమ ప్రదేశము క్రొత్తదైననూ, తినువారు క్రొత్తవారు కాదుగదా."
            },
            {
              "padyam": [
                {
                  "title": "కర్మ మధికమై గడచి పోవగరాదు"
                },
                {
                  "title": "ధర్మరాజు దెచ్చి తగని చోట"
                },
                {
                  "title": "గంకుబటుఁ జేసిఁ గటకటా దైవంబు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "పూర్వజన్మమున చేసిన కర్మ అనుభవింఒపక తప్పదు. ధర్మరాజు వంటివాడు. ఒక సామాన్యమైన చిన్నరాజు దగ్గర కొంతకాలము కంకుభట్టుగా వుండెను."
            },
            {
              "padyam": [
                {
                  "title": "అనువుగాని చోట అధికుల మనరాదు,"
                },
                {
                  "title": "కొంచెముండుటెల్ల కొదువ కాదు"
                },
                {
                  "title": "కొండ అద్దమందు కొంచమై యుండదా?"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "విలుగానిచోట అధికుదనని సంచరించరాదు. సామాన్యముగనుండుట నీచముగాదు. అద్దములో కొంత చిన్నదిగ కంపించిననూ అసలు చిన్నది కాదుగకా."
            },
            {
              "padyam": [
                {
                  "title": "ఇమ్ము దప్పువేళ నెమ్మెలన్ని యుమాని"
                },
                {
                  "title": "కాలమొక్కరీతి గదపవలయు"
                },
                {
                  "title": "విజయ డిమ్ము దప్పి విరటుని గొల్వడా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "చెడ్డకాలము వచ్చినపుదు భోగములన్నియు వదులుకొని సామాన్యముగ కాలము గడుపవలయును. అర్జునుడు రాజ్యము పోగొట్టుకొని విరాటరాజు కొలువులో చేరెనుగదా."
            },
            {
              "padyam": [
                {
                  "title": "చిక్కియున్ననేళ సింహబునైనను"
                },
                {
                  "title": "బక్కకుక్క కరచి బాధచేయు"
                },
                {
                  "title": "కలిమిలేనివేళఁ బంతంబు చెల్లదు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "బలము బలము లేనప్పుదు సింహమునైనను బక్కకుక్క కరచి బాధపెట్టును. సక్తిలేనప్పుడుపంతంములకుపోకతలవంచుకొని తిరుగుటమంచిది."
            },
            {
              "padyam": [
                {
                  "title": "లక్ష్మి యేలినట్టి లంకాధిపతి పురి"
                },
                {
                  "title": "పిల్ల కోతి పౌజు కొల్ల పెట్టెఁ"
                },
                {
                  "title": "జేటు కాలమయిన జెఱుప నల్పులె జాలు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "గొప్ప ధనవంతుదైన రావణుని లంకను సామాన్యమైన కోతులు నాసనము చేసెను. చెడ్డకాలము వచ్చినప్పుదు సామాన్యులైనను అపకారము చేయుదురు."
            },
            {
              "padyam": [
                {
                  "title": "మొదట ఆశపెట్టి తుదిలేదుపొమ్మను"
                },
                {
                  "title": "పరలోభులైన పాపులకును"
                },
                {
                  "title": "ఉసురు తప్పకంటు నుండేలు దెబ్బగా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "మొదట ఉపకారము చేసెదననిచెప్పి, త్రిప్పిత్రిప్పి తరువాత పొమ్మను లోభులకు, అపకారము వుండేలు దెబ్బవలె తప్పక తగులును."
            },
            {
              "padyam": [
                {
                  "title": "ఇచ్చువానియొద్ద నీయని వాడున్న"
                },
                {
                  "title": "ఇచ్చుగాని యీవి సాగనీడు"
                },
                {
                  "title": "కల్పతరువు క్రింద గచ్చ చెట్టున్నట్లు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "దాతదగ్గర లోభిచేరినచో, చచ్చినను ధర్మము, పరోపకారము చేయనీయుడు. సకల కోరికలనిచ్చు కల్పవృక్షము క్రింద ముండ్లపొదవుండినచో కల్పవృషము దగ్గరకు పోనీయదుగదా."
            },
            {
              "padyam": [
                {
                  "title": "అరయ నాస్తియనక యడ్డుమాటాడక"
                },
                {
                  "title": "పట్టుపడక మదిని దన్ను కొనక"
                },
                {
                  "title": "తనది గాదనుకోని తాబెట్టునదె పెట్టు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఆలోచింపగా, లేదనక అడ్డుచెప్పక తట్టుపడక మనస్సులో యీయనా ? వద్దా ! అని ఆలోచింపక తనది కాదని ఇతరులకు పెట్టుటే మంచిదే."
            },
            {
              "padyam": [
                {
                  "title": "ధనము కూడబెట్టి దానంబు చేయక"
                },
                {
                  "title": "తాను దినక లెస్స దాచుకొనగ"
                },
                {
                  "title": "తేనె టీగ గూర్చి తెరువరి కియ్యదా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ! 80"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ధనము సంపాదించి, దానమీయక, తాను తినక, దాచుకొనుట, తేనెటీగ తేనెను ప్రోగుచేసి బాటసారికి యిచ్చునట్లుగనే ఇతరుల పాలు చేయుట అగును."
            },
            {
              "padyam": [
                {
                  "title": "కొంకణంబు పోవఁ గుక్క సింహము కాదు"
                },
                {
                  "title": "కాశి కరుగఁ బంది గజము కాదు"
                },
                {
                  "title": "వేరుజాతి వాడు విప్రుండు కాలేడు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "కేరళ దేశము పోయిననూ కుక్క సింహము కాలేదు. కాశీకి పోయినను పంది యేనుగు కాలేదు. ఇతర కులము వారు బ్రహ్మణులు కాలేరు."
            },
            {
              "padyam": [
                {
                  "title": "తవిటి కరయ వోయ దండులంబులగంప"
                },
                {
                  "title": "శ్వాన మాక్రమించు సామ్యమగును"
                },
                {
                  "title": "వైశ్వవరుని సొమ్ము వసుధ నీచుల కబ్బు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "తవుడును చూచుటకు బోవగా బియ్యము గంప కుక్క తినివేసినట్లుగ, వైశ్యునిసొమ్ము నీచుల పాలగు చుండును."
            },
            {
              "padyam": [
                {
                  "title": "దాత కాని వాని దరచుగా వేఁడిన"
                },
                {
                  "title": "వాఁడు దాత యౌనె వసుధలోన"
                },
                {
                  "title": "ఆరు దర్భయౌనె యబ్ధిలో ముంచినా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "దాతృత్వము లేనివానిని యెన్ని సార్లు అడిగినను యేమియు లాభములేదు. సముద్రములో ముంచిననూ అవురుగడ్డి దర్భగాదు."
            },
            {
              "padyam": [
                {
                  "title": "పరగ రాతి గుండు పగుల గొట్ట వచ్చు"
                },
                {
                  "title": "కొండలన్ని పిండి కొట్టవచ్చు"
                },
                {
                  "title": "కట్టినచిత్తు మనసు కరిగింపగారాదు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "రాతి గుండు పగులగొట్టవచ్చును. కొండలన్నియు డండిగొట్ట వచ్చును. కఠిన హృదయుని మనసు మాత్రము మార్చలేము."
            },
            {
              "padyam": [
                {
                  "title": "వంపుకర్రగాల్చి వంపు దీర్పగవచ్చు"
                },
                {
                  "title": "కొండలన్ని పిండి గొట్టవచ్చు"
                },
                {
                  "title": "కఠినచిత్తు మనసు కరిగింపగరాదు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "వంకరగా నువ్నె కర్రను కాల్చి దాని వంపు తీయవచ్చును. కొండ లన్నిటినీ పిండిగొట్ట వచ్చును. కాని కఠిన హృదయము మనసు మాత్రము మార్చలేము."
            },
            {
              "padyam": [
                {
                  "title": "విత్తముగలవాని వీపు పుండైనను"
                },
                {
                  "title": "వసుధలోన జాల వార్తకెక్కు"
                },
                {
                  "title": "బేద వానియింట బెండ్లయిననెరుగరు"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ధనవంతుని వీపుపై పుండు పుట్టినను, ఆ విషయమును లోకములో అందరును చెప్పుకొందురు. పేదవాని యింటిలో పెండ్లి అయినను చెప్పుకొనరు."
            },
            {
              "padyam": [
                {
                  "title": "ఆపదల వేళ బంధులరసిజూడు"
                },
                {
                  "title": "భయమువేళ జూడు బంటుతనము"
                },
                {
                  "title": "పేదవేళ జూడు పెండ్లాము గుణమును"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "కష్టములు కలిగినప్పుడు బంధువులు దగ్గరకు పోయిపరిశీలిపపుము, భయము కలిగినప్పుడు, ధైర్యమును పరీక్షింపుము. దరిద్రముగా వున్నప్పుడు భార్యగుణము పరీక్షింపుము."
            },
            {
              "padyam": [
                {
                  "title": "ఆలిమాటలు విని అన్నదమ్ములబాసి"
                },
                {
                  "title": "వేఱె పొవువాడు వెఱ్రివాడు"
                },
                {
                  "title": "కుక్క తోకబట్టి గోదావ రీదునా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భార్యమాటలు విని అన్నదమ్ములను వదలిపోవుట అజ్ఞానము కుక్కతోక పట్టుకొని గోదవరి ఈదుట అసాధ్యము అనితెలుసుకొనుము."
            },
            {
              "padyam": [
                {
                  "title": "మగని కాలమందు మగువ కష్టించిన"
                },
                {
                  "title": "సుతుల కాలమందు సుఖమునందు"
                },
                {
                  "title": "కలిమి లేమి రెండు గల వెంతవారికి"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భర్తకాలములో కష్టపడి గృహమును కాపాడినచో, కొడుకులు పెద్దవారైనప్పుడు సుఖపడ వచ్చును. ఎంతవారికైననూ కలిమి, లేమి రెండునూ జీవితములో వచ్చుచుండును."
            },
            {
              "padyam": [
                {
                  "title": "చెప్పులోని రాయి చెవిలోని జోరీగ"
                },
                {
                  "title": "కంటిలోని నలుసు కాలిముల్లు"
                },
                {
                  "title": "ఇంటిలోనిపోరు నింతింత గాదయా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ! 90"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "చెప్పులో ఉన్నరాయి, చెవిలో దూరిన జోరీగ, కంటొలోపడిన నలసు కాలిముల్లు, ఇంటిలోని జగడం వెంటనే తగ్గక చాలా బాధిస్తాయి."
            },
            {
              "padyam": [
                {
                  "title": "తల్లిదండ్రి మీద దయ లేని పుత్రుండు"
                },
                {
                  "title": "పుట్టనేమి? వాడు గిట్టనేమి?"
                },
                {
                  "title": "పుట్టలోన చెదలు పుట్టదా గిట్టదా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "తల్లిదండ్రులపై ప్రేమ లేని పుత్రుడు పుట్టినా చనిపోయినా నష్టములేదు. పుట్టలో చెదలు పుడుతూ ఉంటాయి. నశిస్తూ ఉంటాయి."
            },
            {
              "padyam": [
                {
                  "title": "తనకు లేనినాడు దైవంబు దూరును"
                },
                {
                  "title": "తనకు గల్గెనేమి దైవమేల?"
                },
                {
                  "title": "తనకు దైవమునకు దగులాట మెట్టిదో"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "మనిషి తనకు లేనప్పుడు దేవుని దూషిస్తాడు. ఉన్నప్పుడు దేవుని మరచిపోతాడు. ఇదే మనిషికి దేవునికి సంబంధమై ఉంటుందేమోకదా!"
            },
            {
              "padyam": [
                {
                  "title": "మాటలాడు నొకటి మనసులోన నొకటి"
                },
                {
                  "title": "ఒడలి గుణము వేరె యోచన వేరె"
                },
                {
                  "title": "ఎట్లుగల్గు ముక్తి యీలాగు తానుండ"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "మనసులో ఉన్నది ఒకటి, పైకి మాటాదేది మరొకటి. తన గుణము ఒకటి, అలోచన వేరొకటి ఉన్నవానికి మోక్షము దొరకదు."
            },
            {
              "padyam": [
                {
                  "title": "మ్రుచ్చు గుడికి పోయి ముడివిప్పునే కాని"
                },
                {
                  "title": "పొసగ స్వామిజూచి మ్రొక్కడతడు"
                },
                {
                  "title": "కుక్క యిల్లుసొచ్చి కుండలు వెదుకదా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఇంటిలో ప్రవేశించిన కుక్క కుండలు వెదనుకునట్లుగ గదిలోకి వచ్చిన దొంగ ధనము కొరకు వెదుకునుగాని దేవునికిమ్రొక్కడు."
            },
            {
              "padyam": [
                {
                  "title": "అంతరంగమందు సపరాధములు చేసి"
                },
                {
                  "title": "మంచివానివలెను మనుజు డుండు"
                },
                {
                  "title": "ఇతరు లెరుగకున్న నీశ్వరుఁ డెరుంగడా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "మనిషి చాటు మాటూగ అనేక తప్పుచేసి ఇతరుల ఎదుట మంచివాడుగా నటించవచ్చును. కాని సర్వము తెలిసిన భగవంతుడు మనిషి చేసిన తప్పులనుగుర్తిస్తాడు."
            },
            {
              "padyam": [
                {
                  "title": "వేషభాష లెరిగి కాషాయవస్త్రముల్"
                },
                {
                  "title": "గట్టగానె ముక్తి గలుగబోదు"
                },
                {
                  "title": "తలలు బోడులైన తలుపులు బోడులా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "వేష భాషలు నేర్చుకొని కాషాయ బట్టలు కట్టినంత మాత్రాన మోక్షమురాదు. తలలు చేసినంత మాత్రాన అతని మనసు బోడిది కాదుకదా!"
            },
            {
              "padyam": [
                {
                  "title": "ఓగు నోగు మెచ్చు నొనరంగ నజ్ఞాని"
                },
                {
                  "title": "భావమిచ్చి మెచ్చు బరమలుబ్ధు"
                },
                {
                  "title": "పంది బురద మెచ్చు బన్నీరు మెచ్చునా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "మూర్ఖుణ్ని మూర్ణుడే మెచ్చుకొంటాడు. అజ్ఞానియైన వాడు లోభివానినే మెచ్చుకుంటాడు. పంది బురదనే కోరుకుంటుంది. కాని పన్నీరును కోరుకోదు."
            },
            {
              "padyam": [
                {
                  "title": "గాజుకుప్పెలోన గడఁగుచు దీపంబ"
                },
                {
                  "title": "దెట్టు లుండు జ్ఞాన మట్టులుండు"
                },
                {
                  "title": "దెలిసినట్టి వారి దేహంబులందును"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "గాజు బుడ్డిలో ఏవిధముగా దీపము నిలకడతో వెలుగుతుందో అదే విధముగ తెలివిగల వారియండు జ్ఞాన దీపము ప్రవేశిస్తుండి."
            },
            {
              "padyam": [
                {
                  "title": "అన్న మిడుటకన్న అధిక దానంబుల"
                },
                {
                  "title": "నెన్ని చేయనేమి యేన్నఁబోరు"
                },
                {
                  "title": "అన్న మెన్న జీవనాధార మగునయా"
                },
                {
                  "title": "విశ్వదాభిరామ! వినుర వేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఇతర దానములు ఎన్ని చేసిననూ అన్నదానముతో సాటిగావు. లేలోచించినచో అన్నమే యీ లోకములో జీవనాధారము."
            },
            {
              "padyam": [
                {
                  "title": "ఇహరంబులకును నిది సాధనంబని"
                },
                {
                  "title": "వ్రాసి చదివిన విన్నవారికెల్ల"
                },
                {
                  "title": "మంగళంబు లొనరు మహిలోన నిది నిజము"
                },
                {
                  "title": "విశ్వదాభిరామ! వినురవేమ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఈ లోక మందును, పరలోక మందును గూడసుఖపడుటకు మార్గముగ, నుందునని ఈ శతకము వ్రాసితిని. దీనిని చదివిన వారికిని విన్నవారికిని శుభములు కలుగును. ఇది నిజము."
            }
          ],
          "title": "వేమన పద్యాలు",
         //  "img": require("../imgs/NeethiPadyalu/Vemana_Sathakam.png")
        },
        {
          "Padyalu": [
            {
              "padyam": [
                {
                  "title": "శ్రీ రఘురామ! చారుతుల-సీతాదళధామ శమక్షమాది శృం"
                },
                {
                  "title": "గార గుణాభిరామ! త్రిజ-గన్నుత శౌర్య రమాలలామ దు"
                },
                {
                  "title": "ర్వార కబంధరాక్షస వి-రామ! జగజ్జన కల్మషార్నవో"
                },
                {
                  "title": "త్తారకనామ! భద్రగిరి-దాశరధీ కరుణాపయోనిధీ"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "రఘువంశమున బుట్టినవాడవు, సొంపైన తులసీదండలు గలవాడవు, శాంతి, ఓరిమి మొదలు గుణములచే నొప్పువాడవు, ముల్లోకముల బొగడదగిన పరాక్రమలక్ష్మికి ఆభరణమైనవాడా! వారింపనలవికాని కబంధుడను రాక్షసుని సంహరించినవాడా, జనుల పాపములను సముద్రమును దాటించు నామము గలవాడా! దయకు సముద్రమువంటివాడా! భద్రాచలమందుండు శ్రీరామా!"
            },
            {
              "padyam": [
                {
                  "title": "రామవిశాల విక్రమ పరాజిత భార్గవరామ సద్గుణ"
                },
                {
                  "title": "స్తోమ పరాంగనావిముఖ సువ్రత కామ వినీల నీరద"
                },
                {
                  "title": "శ్యామ కకుత్ధ్సవంశ కలశాంభుధిసోమ సురారిదోర్భలో"
                },
                {
                  "title": "ద్ధామ విరామ భద్రగిరి - దాశరధీ కరుణాపయోనిధీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "జనులను సంతోషింపజేయువాడవు, పరశురాముని జయించినవాడవు, పరస్రీలయందాసక్తి లేనివాడవు, నల్లని మేఘమువంటి శరీర కాంతిగలవాడవు, కాకుత్ స్థ వంశమును సముద్రమునకు చంద్రునివంటి వాడవు, రాక్షసుల సంహరించిన వాడవునైన భద్రాచల రామా!"
            },
            {
              "padyam": [
                {
                  "title": "అగణిత సత్యభాష, శరణాగతపోష, దయాలసజ్ఘరీ"
                },
                {
                  "title": "విగత సమస్తదోష, పృథివీసురతోష, త్రిలోక పూతకృ"
                },
                {
                  "title": "ద్గగ నధునీమరంద పదకంజ విశేష మణిప్రభా ధగ"
                },
                {
                  "title": "ద్ధగిత విభూష భద్రగిరి దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "సత్యము మాట్లాడువాఁడవు, శరణన్న వారిని రక్ష్మించువాడవు, దయచేతఁ బాపములఁ బోగొట్టువాడవు, బ్రాహ్మణుల సంతోషింపజేయువాడవు, గంగానది పుట్టిన పాదపద్మములు గలవాడవు, మణులచే నిగ నిగ మెఱయు సొమ్ములు గలవాడవు, భద్రాచల రామా!"
            },
            {
              "padyam": [
                {
                  "title": "రంగదరాతిభంగ, ఖగ రాజతురంగ, విపత్పరంపరో"
                },
                {
                  "title": "త్తుంగ తమఃపతంగ, పరి తోషితరంగ, దయాంతరంగ స"
                },
                {
                  "title": "త్సంగ ధరాత్మజా హృదయ సారసభృంగ నిశాచరాబ్జమా"
                },
                {
                  "title": "తంగ, శుభాంగ, భద్రగిరి దాశరథీ కరుణాపయోనిథీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "శత్రువుల సంహరించినవాడవు, గరుత్మంతుడు వాహనముగ గలవాడవు, ఆపదల బోగొట్టువాడవు, రంగనాధునిచే సేవింపబడిన వాడవు, దయతో నొప్పు మనస్సుగలవాడవు, సత్సంగుడవు, సీతాహృదయమును పద్మమునకు తుమ్మెదవంటివాడవు, రాక్షసులకు బీభత్స కరుడవు, శుభాంగుడవునైన భద్రాచల రామా!"
            },
            {
              "padyam": [
                {
                  "title": "శ్రీద సనందనాది మునిసేవిత పాద దిగంతకీర్తిసం"
                },
                {
                  "title": "పాద సమస్తభూత పరిపాల వినోద విషాద వల్లి కా"
                },
                {
                  "title": "చ్ఛేద ధరాధినాథకుల సింధుసుధామయపాద నృత్తగీ"
                },
                {
                  "title": "తాది వినోద భద్రగిరి దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "సంపదల నిచ్చువాడవు, మునులచే బూజింపబడినవాడవు, కీర్తిమంతుడవు, అన్ని భూతములను పాలించువాడవు, దుఖఃముల బోగొట్టువాడవు, క్షత్రియ కులమును సముద్రమునకు జంద్రుడవు, నృత్యము, గానము వేడుకగా గలవాడవు, భద్ర - నిధీ!"
            },
            {
              "padyam": [
                {
                  "title": "ఆర్యుల కెల్ల మ్రొక్కివిన తాంగుడనై రఘునాధ భట్టరా"
                },
                {
                  "title": "రార్యుల కంజలెత్తి కవి సత్తములన్ వినుతించి కార్య సౌ"
                },
                {
                  "title": "కర్య మెలర్పనొక్క శతకంబొన గూర్చి రచింతునేడుతా"
                },
                {
                  "title": "త్పర్యమునన్ గ్రహింపుమిది దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "పెద్దల కందఱికి మ్రొక్కి, వంచిన శరీరము గలవాడనై గురువైన రఘునాధభట్టునకు నమస్కరించి, కవిశ్రేష్ఠులను పొగడి, కార్య లాభమునకై యొక శతకంబును వ్రాసెదను. దీని నిష్టముతో గైకొనుము దాశ - నిధీ!"
            },
            {
              "padyam": [
                {
                  "title": "మసకొని రేంగుబండ్లుకును మౌక్తికముల్ వెలవోసినట్లుదు"
                },
                {
                  "title": "ర్వ్యసనముజెంది కావ్యము దురాత్ములకిచ్చితిమోస మయ్యె నా"
                },
                {
                  "title": "రసనకుఁ బూతవృత్తిసుక రంబుగ జేకురునట్లు వాక్సుధా"
                },
                {
                  "title": "రసములుచిల్క బద్యుముఖ రంగమునందునటింప వయ్యసం"
                },
                {
                  "title": "తసము జెంది భద్రగిరి దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "రేగుపండ్లను ముత్తెములుపోసి కొనినట్లు దురాశతో మోసపోయి నా కావ్యములను దుర్మార్గుల కిచ్చితిని నా నాల్కకు పవిత్రత సులభముగ గల్గునట్లును, పలుకుదేనియలు చిల్కునట్లు నా పద్యము ముఖమును నాట్యరంగమునందు సంతోషముతో నీవు నటింపుము. భద్ర - నిధీ!"
            },
            {
              "padyam": [
                {
                  "title": "శ్రీరమణీయహార యతసీ కుసుమాభశరీర, భక్త మం"
                },
                {
                  "title": "దార, వికారదూర, పరతత్త్వవిహార త్రిలోక చేతనో"
                },
                {
                  "title": "దార, దురంత పాతక వితాన విదూర, ఖరాది దైత్యకాం"
                },
                {
                  "title": "తార కుఠార భద్రగిరి దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "హారములు గలవాడవు, అవిసెపూవువంటి శరీరకాంతి గలవాడవు, భక్తులకు కల్పవృక్షమవు, వికారములు లేనివాడవు, దేవతాతత్త్వమందు విహరించువాడవు, మూడులోకముల గల ప్రాణులను బోషించువాడవు, పాపముల బోగొట్టువాడవు, ఖరాది రాక్షసారణ్యమునకు గొడ్డలివంటి వాడవు, భద్ర - నిధీ!"
            },
            {
              "padyam": [
                {
                  "title": "దురితలతాలవిత్ర, ఖర దూషణకాననవీతిహొత్ర, భూ"
                },
                {
                  "title": "భరణకళావిచిత్ర, భవ బంధవిమోచనసూత్ర, చారువి"
                },
                {
                  "title": "స్ఫురదరవిందనేత్ర, ఘన పుణ్యచరిత్ర, వినీలభూరికం"
                },
                {
                  "title": "ధరసమగాత్ర, భద్రగిరి దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "పాపమను తీగలకు కొడవలివంటివాడవు, ఖరదూషణాదుల నెడి యడవికి యగ్నివంటివాడవు, భూమిని రక్షించుటయందు విచిత్రుడవు, పుట్టుకయను ముడిని విడదీయుటయే విధిగాగలవాడవు, ప్రకాశించు పద్మములవంటి నేత్రములు గలవాడవు, పుణ్యచరిత్రుడవు, మేఘకాంతి వంటి శరీరకాంతి గలవాడవు."
            },
            {
              "padyam": [
                {
                  "title": "కనకవిశాలచేల భవకానన శాతకుఠారధార స"
                },
                {
                  "title": "జ్జనపరిపాలశీల దివిజస్తుత సద్గుణ కాండకాండ సం"
                },
                {
                  "title": "జనిత పరాక్రమక్రమ విశారద శారద కందకుంద చం"
                },
                {
                  "title": "దన ఘనసార సారయశ దాశరథీ కరుణాపయోనిధీ. 10"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "బంగారు మయమైన వస్త్రములు గలవాడవు, సంసారమను నడవికి గొడ్డలి మొనవంటివాడవు, సజ్జనుల పరిపాలించెడివాడవు, దేవతలచే బొగడబడినవాడవు, మంచి గుణములు గలవాడవు, బాణవిద్యలో బండితుండవు, శరత్కాలపు మేఘము, మొల్లలు, గంధము పచ్చ కర్పూరము వంటి నిగ్గైన కీర్తిగలవాడవు."
            },
            {
              "padyam": [
                {
                  "title": "శ్రీ రఘువంశ తోయధికి శీతమయూఖుడవైన నీ పవి"
                },
                {
                  "title": "త్రోరుపదాబ్జముల్ వికసితోత్పల చంపక వృత్తమాధురీ"
                },
                {
                  "title": "పూరితవాక్ప్రసూనముల బూజలొనర్చెద జిత్తగింపుమీ"
                },
                {
                  "title": "తారకనామ భద్రగిరి దాశరథీ కరుణాపయోనిధీ"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "రఘువంశమునకు జంద్రునివంటివాడవు, అట్టి నీ చరణముల నుత్పలము, చంపకము మొదలగు పద్యవృత్తములను పూలచే బూజించును. నా పూజలను గైకొనుము."
            },
            {
              "padyam": [
                {
                  "title": "గురుతరమైన కావ్యరస గుంభనకబ్బుర మందిముష్కరుల్"
                },
                {
                  "title": "సరసులమాడ్కి సంతసిల జూలుదురోటుశశాంక చంద్రికాం"
                },
                {
                  "title": "కురముల కిందు కాంతమణి కోటిస్రవించిన భంగివింధ్యభూ"
                },
                {
                  "title": "ధరమున జాఱునే శిలలు దాశరథీ కరుణాపయోనిధీ"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "మూఢులు గ్రంధములలోని రసముయొక్క కూర్పునకు రసికుల వలె సంతోషింపజాలరు. ఎట్లన చంద్రుని వెన్నెలకు చంద్రకాంత శిలలు కఱగి జాఱునట్లు వింధ్యపర్వతమున నుండు ఱాళ్ళు జాఱవు."
            },
            {
              "padyam": [
                {
                  "title": "తరణికులేశ నానుడుల దప్పులు గల్గిన నీదునామ స"
                },
                {
                  "title": "ద్విరచితమైన కావ్యము పవిత్రముగాదె వియన్నదీజలం"
                },
                {
                  "title": "బరగుచువంకయైన మలినాకృతి బాఱిన దన్మహత్వముం"
                },
                {
                  "title": "దరమె గణింప నెవ్వరికి దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "నా మాటలలో దప్పులున్నను నీ పేరుతో వ్రాయబడు కావ్యము పవిత్రమైనదే, ఎట్లన గంగానది నీరు వంకరగ బాఱినను, ముఱికిగ మాఱినను దాని గొప్పతన మెక్కడ పోవును?"
            },
            {
              "padyam": [
                {
                  "title": "దారుణపాత కాబ్ధికి సదా బడబాగ్ని భవాకులార్తివి"
                },
                {
                  "title": "స్తారదవానలార్చికి సుధారసవృష్టి దురంత దుర్మతా"
                },
                {
                  "title": "చారభయంక రాటవికి జండకఠోరకుఠారధార నీ"
                },
                {
                  "title": "తారకనామ మెన్నుకొన దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "నీ పేరు పాపమను సముద్రమునకు బడబాగ్ని వంటిది, సంసారమను కార్చిచ్చునకు నమృతపు వాన, దుర్మతాచారములకు గొడ్డలి మొన వంటిది."
            },
            {
              "padyam": [
                {
                  "title": "హరునకు నవ్విభీషణునక ద్రిజకుం దిరుమంత్ర రాజమై"
                },
                {
                  "title": "కరికి సహల్యకుం ద్రుపదకన్యకు నార్తిహరించుచుట్టమై"
                },
                {
                  "title": "పరగినయట్టి నీపతిత పావననామము జిహ్వపై నిరం"
                },
                {
                  "title": "తరము నటింపజేయుమిక దాశరథీ కరుణాపయోనిధీ"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "నీ నామ మీశ్వరునకు, విభీషణునకు, పార్వతికిని శ్రేష్ఠమగు మంత్రమైనది. అట్టి పరమ పవిత్రమైన నీ నామము నా నాల్కయం దెప్పుడు నాడునట్లు చేయుము."
            },
            {
              "padyam": [
                {
                  "title": "ముప్పున గాలకింకరులు ముంగిటవచ్చిన వేళ, రోగముల్"
                },
                {
                  "title": "గొప్పరమైనచో గఫము కుత్తుక నిండినవేళ, బాంధవుల్"
                },
                {
                  "title": "గప్పినవేళ, మీస్మరణ గల్గునొ గల్గదొ నాటి కిప్పుడే"
                },
                {
                  "title": "తప్పకచేతు మీభజన దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ముసలితనమున యమభటులు వాకిట ముందునకు వచ్చి యుండగా, రోగ మెక్కువై కఫము గొంతులో నిండినప్పుడు, బంధువులు చుట్టుకొన్నప్పుడు మిమ్ము తలతునో తలపలేనో, భజింతునో భజింపలేనో కాబట్టి యిప్పుడే యా పని నెరవేర్చెదను."
            },
            {
              "padyam": [
                {
                  "title": "పరమదయానిధే పతితపావననామ హరే యటంచు సు"
                },
                {
                  "title": "స్ధిరమతులై సదాభజన సేయు మహత్ముల పాదధూళి నా"
                },
                {
                  "title": "శిరమునదాల్తుమీరటకు జేరకుడంచు యముండు కింకరో"
                },
                {
                  "title": "త్కరముల కాన బెట్టునట దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "దయకు సముద్రమువంటివాడవు, పాపులనుద్ధరించు పేరుగలవాడవు. హరీ యని నిలుకడగల బుధ్ధితో గొలుచు మహాత్ముల కాళ్ళ దుమ్ము నా నెత్తిపై దాల్తును. అప్పుడు యముడు తన భటులను నా జోలికి పోవద్దని యాజ్ఞాపించును."
            },
            {
              "padyam": [
                {
                  "title": "అజునకు తండ్రివయ్యు సనకాదులకుం బరతత్త్వమయ్యుస"
                },
                {
                  "title": "ద్ద్విజమునికోటికెల్లబర దేతవయ్యు దినేశవంశ భూ"
                },
                {
                  "title": "భుజులకు మేటివయ్యుబరి పూర్ణుడవై వెలిగొందుపక్షిరా"
                },
                {
                  "title": "డ్ధ్వజమిము బ్రస్తుతించెదను దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "బ్రహ్మకు దండ్రివి, సనకాదులకున్ బరతత్త్వమవు, బ్రాహ్మణులకు, ఋషులకు ముఖ్య దేవుడవు, సూర్యవంశపు రాజులలో నధికుడవు, అట్టి నిన్ను పొగడెదను."
            },
            {
              "padyam": [
                {
                  "title": "పండిత రక్షకుం డఖిల పాపవిమొచను డబ్జసంభవా"
                },
                {
                  "title": "ఖండల పూజితుండు దశకంఠ విలుంఠన చండకాండకో"
                },
                {
                  "title": "దండకళా ప్రవీణుడవు తావక కీర్తి వధూటి కిత్తుపూ"
                },
                {
                  "title": "దండలు గాగ నా కవిత దాశరధీ కరుణాపయోనిధీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "పండిత రక్షకుఁడు, పాపములఁ బోఁగొట్టువాఁడు, బ్రహ్మేంద్రాదులచే బూజింపఁబడినవాఁడు, రావణాసురిని సంహరించినవాడను నీ కీర్తి కన్యకు నా కవిత్వమును బూదండవలెనిత్తును."
            },
            {
              "padyam": [
                {
                  "title": "శ్రీరమ సీతగాగ నిజసేవక బృందము వీరవైష్ణవా"
                },
                {
                  "title": "చార జవంబుగాగ విరజానది గౌతమిగా వికుంఠము"
                },
                {
                  "title": "న్నారయభద్ర శైలశిఖరాగ్రముగాగ వసించు చేతనో"
                },
                {
                  "title": "ద్ధారకుడైన విష్ణుడవు దాశరథీ కరుణాపయోనిధీ. 20"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "లక్ష్మీదేవి సీత, సేవకులు వైష్ణవజనులు, విరజానది, గోదావరి, వైకుంఠము, భద్రాచలము కాగా ప్రాణుల నుధ్ధరించునట్టి విష్ణువుడ నీవు దా - నిధీ!"
            },
            {
              "padyam": [
                {
                  "title": "కంటి నదీతటంబుబొడగంటిని భద్రనగాధివాసమున్"
                },
                {
                  "title": "గంటి నిలాతనూజనురు కార్ముక మార్గణశంఖచక్రముల్"
                },
                {
                  "title": "గంటిని మిమ్ము లక్ష్మణుని గంటి కృతార్ధుడ నైతి నో జగ"
                },
                {
                  "title": "త్కంటక దైత్యనిర్ధళన దాశరధీ కరుణాపయోనిధీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఏటిదరిని భద్రాచలమునం దుండుట జూచితిని, సీతను జూచితిని, గొప్పవైన ధనువును, బాణములను, శంఖచక్రముల జూచితిని, మిమ్ము, లక్ష్మణుని జూచి కృతార్ధుడనైతి."
            },
            {
              "padyam": [
                {
                  "title": "హలికునకున్ హలాగ్రమున నర్ధము సేకురుభంగి దప్పిచే"
                },
                {
                  "title": "నలమట జెందువానికి సురాపగలో జల మబ్బినట్లు దు"
                },
                {
                  "title": "ర్మలిన మనోవికారియగు మర్త్యుని నన్నొడగూర్చి నీపయిన్"
                },
                {
                  "title": "దలవు ఘటింపజేసితివె దాశరధీ కరుణాపయోనిధీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "రైతునకు నాగేటి చివర ధనమిచ్చినట్లును, దప్పితో బాధ పడువానికి గంగానదీజల మబ్బినట్లును, చెడు మనస్సు గల నాకు నీపై భక్తి కలుగునట్లు చేసితివి."
            },
            {
              "padyam": [
                {
                  "title": "కొంజకతర్క వాదమను గుద్దలిచే బరతత్త్వభూస్ధలిన్"
                },
                {
                  "title": "రంజిలద్రవ్వి కన్గొనని రామనిధానము నేడు భక్తిసి"
                },
                {
                  "title": "ద్ధాంజనమందుహస్తగత మయ్యెబళీ యనగా మదీయహృ"
                },
                {
                  "title": "త్కంజమునన్ వసింపుమిక దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "తర్కవాదముచేనైన గన్గొనరాని రాముడను నిధి, భక్తియను బైరాగుల కాటుకతో నందఱు సెబాసనగా జేజిక్కిన దయ్యెను. ఇంక నా మనస్సునందు స్థావరముగా నిలువుము."
            },
            {
              "padyam": [
                {
                  "title": "రాముఁడు ఘోర పాతక విరాముడు సద్గుణకల్పవల్లికా"
                },
                {
                  "title": "రాముడుషడ్వికారజయ రాముడు సాధుజనావనవ్రతో"
                },
                {
                  "title": "ద్దాముఁడు రాముడే పరమ దైవము మాకని మీ యడుంగు గెం"
                },
                {
                  "title": "దామరలే భుజించెదను దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "పాపములను పోగొట్టువాడు, మంచిగుణములను కల్పవృక్షపు తీగెలకు దోటవంటివాడు, వికారములను జయించినవాడు, మంచివారిని రక్షించువాడు నైన రాముడే ముఖ్య దేవుడుగా నీ యడుగు లను పద్మముల గొలుతును."
            },
            {
              "padyam": [
                {
                  "title": "చక్కెరమానివేముదిన జాలినకైవడి మానవాధముల్"
                },
                {
                  "title": "పెక్కురు ఒక్క దైవముల వేమఱుగొల్చెదరట్ల కాదయా"
                },
                {
                  "title": "మ్రొక్కిననీకు మ్రొక్కవలె మోక్ష మొసంగిన నీవయీవలెం"
                },
                {
                  "title": "దక్కినమాట లేమిటికి దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "హీనులనేకులు నిన్ను విడిచి ఇంకొకరిని గొలిచెదరు. అనగా తియ్యని చక్కెరను తినలేక వేప వస్తువగు చేదును తినుటకు నేర్చినట్లున్నది.మ్రొక్క దగినవాడవు నీవే , మోక్షదాయకుడవు నీవే !"
            },
            {
              "padyam": [
                {
                  "title": "'రా' కలుషంబులెల్ల బయలంబడద్రోచిన 'మా'క వాటమై"
                },
                {
                  "title": "డీకొనిప్రోవుచునిక్క మనిధీయుతులెన్నఁదదీయ వర్ణముల్"
                },
                {
                  "title": "గైకొని భక్తి చే నుడువఁగానరు గాక విపత్పరంపరల్"
                },
                {
                  "title": "దాకొనునే జగజ్జనుల దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "'రా' యను నక్షరము పాపముల బారద్రోలగా, 'మా' యను నక్షరము వాకిలియై పాపముల జొరనీయకుండును అని పెద్దలైనవారు పై 'రామ' యను నక్షరముల బుద్ధిమంతులు భక్తితో బలుకకుందురే గాని, పలికినట్లైన యాపదలు ప్రపంచ జనుల గ్రమ్ముకొనవు."
            },
            {
              "padyam": [
                {
                  "title": "రామహరే కకుత్ధ్సకుల రామహరే రఘురామరామశ్రీ"
                },
                {
                  "title": "రామహరేయటంచు మది రంజిల భేకగళంబులీల నీ"
                },
                {
                  "title": "నామము సంస్మరించిన జనంబు భవంబెడబాసి తత్పరం"
                },
                {
                  "title": "ధామ నివాసులౌదురట దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "రామ హరే యని నీ పేరును గప్ప గొంతుకవలె దలఁచిన జనులు జన్మరహితులై మోక్షము జెందుదురట."
            },
            {
              "padyam": [
                {
                  "title": "చక్కెర లప్పకున్ మిగుల జవ్వని కెంజిగురాకు మోవికిం"
                },
                {
                  "title": "జొక్కపుజుంటి తేనియకు జొక్కులుచుంగన లేరు గాక నే"
                },
                {
                  "title": "డక్కట రామనామమధు రామృతమానుటకంటె సౌఖ్యామా"
                },
                {
                  "title": "తక్కినమాధురీ మహిమ దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "అయ్యో! ఈ కాలమువారు చక్కెరరాశికిని, యువతి యొక్క పెదవికిని, తేనెటీగలు పెట్టిన తేనెకు నాసపడుచున్నారు. రాముని పేరులో గల తీపిని నెఱుంగలేరు. రాముని పేరులో గల తీయదనము కంటే వానిలో గల తీయదన మంత సుఖమా!"
            },
            {
              "padyam": [
                {
                  "title": "అండజవాహ నిన్ను హృదయంబుననమ్మిన వారి పాపముల్"
                },
                {
                  "title": "కొండలవంటివైన వెసగూలి నశింపక యున్నె సంత తా"
                },
                {
                  "title": "ఖండలవైభవోన్నతులు గల్గకమానునె మోక్ష లక్ష్మికై"
                },
                {
                  "title": "దండయొసంగకున్నె తుద దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "నిన్ను నమ్మి కొలిచినవారి పాపములు కొండలంతటివైనను నశించిపోవును. ఇంద్రవైభవములు కల్గును. మోక్షలక్ష్మి చేయూతనొసంగును."
            },
            {
              "padyam": [
                {
                  "title": "చిక్కనిపాలపై మిసిమి జెందిన మీగడ పంచదారతో"
                },
                {
                  "title": "మెక్కినభంగి మీవిమల మేచకరూప సుధారసంబు నా"
                },
                {
                  "title": "మక్కువ పళ్లేరంబున సమాహిత దాస్యము నేటిదో యిటన్"
                },
                {
                  "title": "దక్కెనటంచు జుఱ్ఱెదను దాశరథీ కరుణాపయోనిధీ. 30"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "చిక్కని పాలిమీద నిగనిగలాడు మీగడతో జక్కెర గలిపికొని తిన్నట్లుగ నీ రూప మనియెడు నమృతము నా ప్రేమ పాత్రలో దగిన దాస్యమును దోసిలియందు లభించిందని చెప్పి జుఱ్ఱుకొందును."
            },
            {
              "padyam": [
                {
                  "title": "సిరులిడసీత పీడలెగ జిమ్ముటకున్ హనుమంతుడార్తిసో"
                },
                {
                  "title": "దరుడు సుమిత్రసూతి దురితంబులుమానుప రామ నామముం"
                },
                {
                  "title": "గరుణదలిర్ప మానవులగావగ బన్నిన వజ్రపంజరో"
                },
                {
                  "title": "త్కరముగదా భవన్మహిమ దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "సంపద లిచ్చుటకు సీత, పీడల పోగొట్టుటకు హనుమంతుడు, ధుఃఖముబాప లక్ష్మణుడు పాపము హరించుటకు రామనామములను గరుణతో మానవుల రక్షిచుటకై యేర్పరుపబడినవి."
            },
            {
              "padyam": [
                {
                  "title": "హలికులిశాంకుశధ్వజ శరాసన శంఖరథాంగ కల్పకో"
                },
                {
                  "title": "జ్వలజలజాత రేఖలను సాంశములై కనుపట్టుచున్న మీ"
                },
                {
                  "title": "కలితపదాంబుజ ద్వయము గౌతమపత్ని కొసంగినట్లు నా"
                },
                {
                  "title": "తలపున జేర్చికావగదె దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "శంకచక్రాదులతో బ్రకాశించు పద్మరేఖల వలె చిహ్నములు గలవైన మీ పాదముల నహల్య కొసగినట్లు నా భామునందు గూడ నిలుచునట్లు చేయును."
            },
            {
              "padyam": [
                {
                  "title": "జలనిధిలోనదూఱి కుల శైలముమీటి ధరిత్రిగొమ్మునం"
                },
                {
                  "title": "దలవడమాటిరక్కసుని యంగముగీటిబలీంద్రునిన్ రసా"
                },
                {
                  "title": "తలమునమాటి పార్ధివక దంబముగూఱ్చిన మేటిరామ నా"
                },
                {
                  "title": "తలపుననాటి రాగదవె దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "మత్స్య, కూర్మ, వరాహ, నరసింహ, వామన, పరశురామాద్యవతారముల నెత్తినట్టి రామా! నా భావమునందు నిలువగా రమ్ము."
            },
            {
              "padyam": [
                {
                  "title": "భండన భీముడా ర్తజన బాంధవుడుజ్జ్వల బాణతూణకో"
                },
                {
                  "title": "దండకళాప్రచండ భుజ తాండవకీర్తికి రామమూర్తికిన్"
                },
                {
                  "title": "రెండవ సాటిదైవమిక లేడనుచున్ గడగట్టి భేరికా"
                },
                {
                  "title": "దాండద దాండ దాండ నిన దంబులజాండము నిండమత్తవే"
                },
                {
                  "title": "దండము నెక్కి చాటెదను దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "యుద్ధమునందు భయంకరుడు, దుఃఖితులకు జుట్టము, ధనుర్విద్యయందును, భుజబలము నందును పేరు గన్న రాముని వంటి దేవుడింకొకడు లేడు. ఈ విషయము నేను లోకమునకు జాటెదను."
            },
            {
              "padyam": [
                {
                  "title": "అవనిజ కన్నుదోయి తొగలందు వెలింగెడు సోమ, జానకీ"
                },
                {
                  "title": "కువలయనేత్ర గబ్బిచనుకొండల నుండు ఘనంబ మైధిలీ"
                },
                {
                  "title": "నవనవ యౌవనంబను వనంబుకున్ మదదంతి వీవెకా"
                },
                {
                  "title": "దవిలి భజింతు నెల్లపుడు దాశరధీ కరుణాపయోనిధీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "సీత కన్నులను కలువలకు జంద్రుడవు, సీత యొక్క యుబ్బిన స్తనములను కొండల నుండెడి మేఘమవు. సీత యొక్క కొంగ్రొత్త యౌవన మను వనమునకు మదించిన యేనుగువంటివాడవు నీవని యిష్టముతో గొలుతును."
            },
            {
              "padyam": [
                {
                  "title": "ఖరకరవంశజా విను ముఖండిత భూతపిశాచఢాకినీ"
                },
                {
                  "title": "జ్వర పరితాపసర్పభయ వారకమైన భవత్పదాబ్జ ని"
                },
                {
                  "title": "స్పుర దురువజ్రపంజరముజొచ్చితి, నీయెడ దీన మానవో"
                },
                {
                  "title": "ధ్ధర బిరుదంక మేమఱుకు దాశరధీ కరుణాపయోనిధీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భూత పిశాచాది భయముల బోగొట్టునవైన నీ పాదముల బ్రవేశించితిని. ఇపుడు దీనుల నుద్ధరించువాడవను నీ బిరుదు యొక్క చిహ్నము మఱవకుము."
            },
            {
              "padyam": [
                {
                  "title": "జుఱ్ఱెదమీక థామృతము జుఱ్ఱెదమీపదకంజతో యమున్"
                },
                {
                  "title": "జుఱ్ఱెద రామనామమున జొబ్బిలుచున్న సుధారసంబ నే"
                },
                {
                  "title": "జుఱ్ఱెద జుఱ్ఱుజుఱ్ఱుఁగ రుచుల్ గనువారిపదంబు గూర్పవే"
                },
                {
                  "title": "తుఱ్ఱులతోడి పొత్తిడక దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "మీ కధామృతమును మీ పాదపద్మములను జుఱ్ఱుకొందును. రామనామములో గారుచున్న యమృతరసమును జుఱ్ఱెదను. అందలి రుచుల నెఱిగినవారి స్థానమిమ్ము. దుర్మార్గుల స్నేహ మొసగకుము."
            },
            {
              "padyam": [
                {
                  "title": "ఘోరకృతాంత వీరభట కోటికి గుండెదిగుల్ దరిద్రతా"
                },
                {
                  "title": "కారపిశాచ సంహరణ కార్యవినోది వికుంఠ మందిర"
                },
                {
                  "title": "ద్వార కవాట భేది నిజదాస జనావళికెల్ల ప్రొద్దు నీ"
                },
                {
                  "title": "తారకనామ మెన్నుకొన దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "నీ నామము యమభటులకు గుండె దిగులు కలిగించునది, దరిద్ర పిశాచమును నాశనము చేయునది. నీ భక్తుల కెప్పటికిని వైకుంఠ ద్వారమున గల తలుపులను బ్రద్దలు గొట్టునటువంటిది."
            },
            {
              "padyam": [
                {
                  "title": "విన్నపమాలకించు రఘువీర నహిప్రతిలోకమందు నా"
                },
                {
                  "title": "కన్నదురాత్ముడుం బరమ కారుణికోత్తమ వేల్పులందు నీ"
                },
                {
                  "title": "కన్న మహాత్ముడుం బతిత కల్మషదూరుడు లేడునాకువి"
                },
                {
                  "title": "ద్వన్నుత నీవెనాకు గతి దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "నాకన్న దురాత్ముడు ప్రపంచమున లేడు. నీకన్న మహాత్ముడు దేవతలలో లేడు. కావున నాకు నీవె దిక్కు. మరియొకరు కాదు."
            },
            {
              "padyam": [
                {
                  "title": "పెంపునఁదల్లివై కలుష బృందసమాగమ మొందుకుండు ర"
                },
                {
                  "title": "క్షింపనుదండ్రివై మెయు వసించుదు శేంద్రియ రోగముల్ నివా"
                },
                {
                  "title": "రింపను వెజ్జవై కృప గుఱించి పరంబు దిరబుగాఁగ స"
                },
                {
                  "title": "త్సంపదలీయ నీవెగతి దాశరథీ కరుణాపయోనిధీ. 40"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "పోషించుటలో దల్లివి, పాపముల బొందకుండ రక్షించుటలో దండ్రివి, రోగమును వారించుటలో వైద్యుడవై, దయతో శాశ్వతమోక్ష మొసగి రక్షింపుము."
            },
            {
              "padyam": [
                {
                  "title": "కుక్షినజాండపం క్తులొన గూర్చి చరాచరజంతుకోటి సం"
                },
                {
                  "title": "రక్షణసేయు తండ్రివి పరంపర నీ తనయుండనైన నా"
                },
                {
                  "title": "పక్షము నీవుగావలదె పాపము లెన్ని యొనర్చినన్ జగ"
                },
                {
                  "title": "ద్రక్షక కర్తవీవెకద దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "కడుపులో బ్రహ్మాండముల నుంచుకొని చేతనా చేతన జంతువుల బాలించు నీవే నాకు దిక్కు. పాపములెన్ని చేసినను రక్షించు వాడవు నీవే సుమా!"
            },
            {
              "padyam": [
                {
                  "title": "గద్దరియో గిహృత్కమల గంధర సానుభవంబుఁజెందు పె"
                },
                {
                  "title": "న్నిద్దవు గండుఁ దేఁటి థరణీసుత కౌఁగిలిపంజరంబునన్"
                },
                {
                  "title": "ముద్దులుగుల్కు రాచిలుక ముక్తినిధానమురామరాఁగదే"
                },
                {
                  "title": "తద్దయు నేఁడు నాకడకు దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "మహాత్ముల హృదయ పద్మములనుండు మకరందమును గ్రోలుదుమ్మెదవంటివాడవు, ముక్తికి నిక్షేపమువంటివాడవు నైన, రామ! నేడు దయతో నా కడకు రమ్ము."
            },
            {
              "padyam": [
                {
                  "title": "కలియుగ మర్త్యకోటినిను గన్గొన రానివిధంబో భక్తవ"
                },
                {
                  "title": "త్సలతవహింపవో చటుల సాంద్రవిపద్దశ వార్ధి గ్రుంకుచో"
                },
                {
                  "title": "బిలిచిన బల్క వింతమఱపీ నరులిట్లనరాదు గాక నీ"
                },
                {
                  "title": "తలపున లేదె సీత చెఱ దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఈ కలియుగములోని మనుష్యులు నిన్ను గనలేకున్నారో లేక నీకు భక్తులపై దయలేదో యెఱుంగను. మిక్కిలి విశేషమైన యాపద లను సముద్రములో బడుచు బిలిచినను బలుకకున్నావు. మే మిట్లనగూడదు, సీత పడిన బాధ నప్పుడే మఱచితివా? (మమ్ములను మఱువకు మనుట.)"
            },
            {
              "padyam": [
                {
                  "title": "జనవర మీక థాలి వినసైఁపక కర్ణములందు ఘంటికా"
                },
                {
                  "title": "నినద వినోదముల్ సులుపునీచునకున్ వరమిచ్చినావు ని"
                },
                {
                  "title": "న్ననయమునమ్మి కొల్చిన మహాత్మునకేమి యొసంగు దోసనం"
                },
                {
                  "title": "దననుత మాకొసంగుమయ దాశరథీ కరుణాపయోనిధీ."
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "నీ కధలు చెవులతో విన నిష్టపడక గంటలమ్రోఁతల నానందపడు ఘంటాకర్ణాదులకు వరము లిచ్చితివి. నిన్నెప్పుడుఁ గొల్చువారి కే మోసంగితివి. మాకు మోక్ష మిమ్ము."
            }
          ],
          "title": "దాశరధి శతకము",
         //  "img": require("../imgs/NeethiPadyalu/Dasaradhi_Sathakam.png")
        },
        {
          "Padyalu": [
            {
              "padyam": [
                {
                  "title": "ఒక్కడు మాంసమిచ్చె మఱియొక్కడు చర్మము గోసి యిచ్చె వే"
                },
                {
                  "title": "ఱొక్కరు డస్థి నిచ్చె నిక నొక్కడు ప్రాణములిచ్చె వీరిలో"
                },
                {
                  "title": "నొక్కనిపట్టునన్ బ్రదుక నోపక యిచ్చిరొ కీర్తి కిచ్చిరో"
                },
                {
                  "title": "చక్కగ జూడు మంత్రి కుల సంభవ! రాయనమంత్రి భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! ఒకరు శరీరంలో నుండి మాంసాన్ని కోసి ఇచ్చారు. ఒకరు చర్మం కోసి ఇచ్చారు. మరొకరు వెన్నెముక ఇచ్చారు. ఇంకొకరు ప్రాణమే ఇచ్చారు. వీళ్ళంతా బతక లేక ఈ పనులు చేయలేదు, కీర్తికోసం చేయలేదు. ఓ మంత్రి కులంలో జన్మించిన రాయన భాస్కరుడా! బాగా ఆలోచించి చూడు(ఒక పావురాన్ని కాపాడటం కోసం శరీరం నుండి మాంసం కోసి ఇచ్చినవాడు శిబి చక్రవర్తి. ఇంద్రుడడిగితే సహజ సిద్ధమైన కవచకుండలాలను ఇచ్చినవాడు కర్ణుడు. రాక్షస సంహారానికి ఇంద్రునకు ఆయుధంగా తన వెన్నెముకను ఇచ్చినవాడు దధీచి. వామనుడడిగితే ప్రాణమే ఇచ్చినవాడు బలిచక్రవర్తి. వీళ్ళంతా త్యాగధనులు, మహాదాతలు)."
            },
            {
              "padyam": [
                {
                  "title": "పరహితమైన కార్యమతి భారతముతోడిదియైన పూను స"
                },
                {
                  "title": "త్పురుషుడు లోకము ల్పొగడ, పూర్వమునం దొక రాలవర్షమున్"
                },
                {
                  "title": "కురియగ చొచ్చినన్ కదిసి గొబ్బున గోజన రక్షణార్థమై"
                },
                {
                  "title": "గిరి నొక కేల ఎత్తెనట కృష్ణుడు ఛత్రము భాతి భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! లోకులకు హితాన్ని కలిగించే పని ఎంత కష్టమైనది అయినా మంచివాడు పూనుకుంటాడు. పూర్వం రాళ్ళ వర్షం కురిసినప్పుడు గోవులను, గోపాలకులనూ రక్షించటానికి కృష్ణుడు కొండనే ఎత్తి గొడుగుగా పట్టాడు."
            },
            {
              "padyam": [
                {
                  "title": "సిరిగల వాని కెయ్యెడల చేసిన మేలది నిష్పలం బగున్"
                },
                {
                  "title": "నెఱి గుఱిగాదు పేదలకు నేర్పున చేసిన సత్పలంబగున్"
                },
                {
                  "title": "వఱపున వచ్చి మేఘు డొక వర్షము వాడినచేలమీదటన్"
                },
                {
                  "title": "కురిసిన గాక అంబుధుల కుర్వగ నేమి ఫలంబు భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! ధనవంతునికి మనం చేసే మేలు వ్యర్థం. పేదవానికి చేస్తే ప్రయోజనం కలుగుతుంది. వానలు లేనపుడు ఎండిపోతూ ఉన్న చేల మీద మేఘుడు వాన కురిస్తే ఫలితం ఉంటుంది కానీ, సముద్రం మీద కురిస్తే ప్రయోజనం ఉండదు కదా!"
            },
            {
              "padyam": [
                {
                  "title": "దక్షుడు లేని యింటికిఁబదార్థము వేఱొక చోట నుండి వే"
                },
                {
                  "title": "లక్షలు వచ్చుచుండినఁబలాయనమై చనుఁగల్ల గాదు ప్ర"
                },
                {
                  "title": "త్యక్షము వాగులున్ వరద లన్నియు వచ్చిన నీరు నిల్చునే"
                },
                {
                  "title": "అక్షయమైన గండి తెగనట్టి తటాకములోన భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! గట్లు తెగి గండి పడి యున్న పెద్ద చెరువులోనికి ఎన్ని వైపులనుండి ఎంత గొప్ప ప్రవాహములు ఎన్ని వాగులు వచ్చి పడిననూ ఆ నీరు నిలువదు కదా! అదే విధంగా, ఒక కుటుంబమునకు ఎన్ని విధములుగా ఎంత ఆదాయము వచ్చిననూ ఆ సంపదను ఒక పద్ధతి ప్రకారము నిర్వహించు సమర్థుడైన యజమాని లేక పోయినచో ఆ సంపదలన్నియు వ్యర్థముగా ఖర్చయిపోవునని భావం."
            },
            {
              "padyam": [
                {
                  "title": "సన్నుత కార్యదక్షు డొకచాయ నిజప్రభ యప్రకాశమై"
                },
                {
                  "title": "యున్నపుడైన లోకులకు నొండక మేలొనరించు సత్వసం"
                },
                {
                  "title": "పన్నుడు భీము డా ద్విజులప్రాణము కావడె ఏకచక్రమం"
                },
                {
                  "title": "దెన్నికగా బకాసురుని నేపున రూపడగించి భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! భీముడు బ్రాహ్మణ వేషములో అజ్ఞాతముగా గడుపవలసి వచ్చిన సమయమున కూడా ఏకచక్ర పురము నందలి బ్రాహ్మణ కుటుంబమును బకాసురుని బారి నుండి రక్షించగలిగినవాడు. కనుక కార్యదక్షుడైనవాడు ఏ కారణము చేతనైననూ తన గొప్పతనమును మరుగు పరచుకొనవలసి వచ్చిననూ, ఇతరులకు తనకు చేతనైనంత మేలు ఏదైననూ చేయగలడని భావం."
            },
            {
              "padyam": [
                {
                  "title": "అడిగినయట్టి యాచకుల ఆశ లెరుంగక లోభవర్తియై"
                },
                {
                  "title": "కడపిన ధర్మదేవత యొకానొకయప్పుడు నీదు వానికె"
                },
                {
                  "title": "య్యెడల అదెట్లు పాలు తమకిచ్చునె యెచ్చటనైన లేగలన్"
                },
                {
                  "title": "కుడువగ నీనిచో కెరలి గోవులు తన్నును గాక భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! దూడలను తాగనియ్యక పాలు తీసుకోవాలని సిద్ధపడితే ఆవులు పాలియ్యవు సరి కదా తంతాయి. అలాగే ఏదో యిస్తారని ఆశతో వచ్చి చేయిచాచి అడిగే వారికి లోభితనముతో లేదు పొమ్మంటే ధర్మ దేవత ఆ లోభికి ధనం ఎప్పటికీ రాకుండా చేస్తుంది. అడిగిన వారికి ఎంత కొంత యిస్తూ ఉంటే ధనం ఏదో విధంగా వస్తూ ఉంటుంది. కాబట్టి యాచించే వారిని చులకనగా చూచి లేదుపో అని అనకూడదు."
            },
            {
              "padyam": [
                {
                  "title": "తగిలి మదంబుచే నెదిరి తన్ను నెఱుంగక దొడ్డవానితో"
                },
                {
                  "title": "పగఁగొని పోరుటెల్ల నతిపామరుఁడై చెడు, టింతెగాకఁ?"
                },
                {
                  "title": "నెగడి జయింప నేరఁ,డది నిక్కము, దప్పదు ధాత్రి లోపలన్"
                },
                {
                  "title": "దెగి యొక కొండతో తగరు ఢీకొని తాకిన నేమి భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! ఎదుటివాని శక్తిని, తన శక్తిని గ్రహించక గొప్పవానితో శత్రుత్వం పూని మదంతో పోరాడటం వల్ల, అవివేకియై చెడిపోవడమే గాని తాను జయింపలేడు. అది నిజము. పొట్టేలు సాహసంతో కొండను ఢీ కొంటే దాని ప్రాణం పోతుంది కదా!"
            },
            {
              "padyam": [
                {
                  "title": "ఊరక సజ్జనుండొదిగి యుండిననైన దురాత్మకుండు ని"
                },
                {
                  "title": "ష్కారణ మోర్వలేక అపకారము చేయుట వాని విద్యగా"
                },
                {
                  "title": "చీరలు నూరుటంకములు చేసెడివైనను పెట్టెనుండగా"
                },
                {
                  "title": "చేరి చినింగిపో గొరుకు చిమ్మట కేమి ఫలంబు? భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! ఎంతో విలువైన బట్టలు పెట్టెలో ఉండగా చిమ్మట పురుగు వాటికి చిల్లులు పెట్టి కొరికి పాడుచేస్తుంది. దానివల్ల ఆ పురుగుకి ఏమి లాభం లేదు. వాటికి పాడు చెయ్యటం ఒక స్వభావం. అలాగే ఎవ్వరినీ ఏమీ పెల్లెత్తు అనక తన ఇంట తానున్న సజ్జనుణ్ణి నిష్కారణంగా దుర్జనుడు అపకారం చేసి బాధ పెడతాడు. వాడికి వచ్చే లాభం ఏమీ లేదు. అది చెడ్డవాని గుణం."
            },
            {
              "padyam": [
                {
                  "title": "సంతత పుణ్యశాలి యొక జాడను సంపద వాసిపోయి తా"
                },
                {
                  "title": "నంతట పోకనెట్టుకొని యెప్పటియట్ల వసించియుండు మా"
                },
                {
                  "title": "సొంతము నందు చందురుని యన్ని కళల్ పెడబాసి పోయినన్"
                },
                {
                  "title": "కాంతి వహింప డోటు తిరుగంబడి దేహము నిండ! భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! పుణ్యాత్ముడు తన సంపద అంతా పోయినా బాధపడక ఎప్పటిలా ఉంటాడు. చంద్రుడు నెల చివర కళలన్నీ పోయినా మళ్ళీ కాంతివంతునిగా వెలుగొందును కదా!"
            },
            {
              "padyam": [
                {
                  "title": "శ్రీగల భాగ్యశాలిఁగడుఁజేరఁగవత్తురు తారుదారె దూ"
                },
                {
                  "title": "రాగమన ప్రయాసమున కాదట నోర్చియునైన నిల్వను"
                },
                {
                  "title": "ద్యోగముచేసి రత్ననిలయుండని కాదె సమస్త వాహినుల్"
                },
                {
                  "title": "సాగరు జేరుటెల్ల ముని సన్నుత మద్గురుమూర్తి భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! మునులచే స్తుతింపబడిన సర్వజనులకు గురుమూర్తియగు ఓ భాస్కరా! జనులు భాగ్యవంతుల వద్దకు చాలా దూరము. శ్రమయని గుర్తించక ఓర్పుతో నివశించుటకు, తమంతట తామే, ప్రేమతో పయనమై వస్తారు. ఎట్లనగా రత్నములకు నిధియగు సముద్రుని వద్దకు సాగరములన్నియును ప్రకృతి సిద్ధముగాచేరును గదా! అలాగే సమ్పదలున్నవాని వద్దకు జనులు ఆహ్వానము లేకుండానే వస్తారని భావం."
            },
            {
              "padyam": [
                {
                  "title": "అంగన నమ్మరాదు తనయంకెకురాని మహాబలాడ్యువే"
                },
                {
                  "title": "భంగుల మాయలొడ్డి చెఱపందల పెట్టు, వివేకియైన సా"
                },
                {
                  "title": "రంగధరుం బదంబుఁగరంబులు గోయఁగజేసెఁదొల్లిచి"
                },
                {
                  "title": "త్రాంగియనేకముల్ నుడవరాని కుయుక్తులు పన్ని భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! స్త్రీ వ్యామోహముచే పురుషుని కోరగా అతడు నిరాకరించినచో వానికి అనేక నిందారోపణములు చేసి, రాజులచే కఠిన శిక్ష విధించు వరకు తన పన్నాగము వదలదు. పూర్వము చిత్రాంగియను స్త్రీ సారంగధరుని ప్రేమించగా, అతడు నిరాకరణ తెలుపగా రాజు వద్దకు వెళ్ళి అనేక ఫిర్యాదులు చేసి అతని కాళ్ళు, చేతులు నరికించినది కదా! అందుకే అలాంటి స్త్రీలను నమ్మరాదు. బలవంతుడైననూ బాధలకు గురియగును."
            },
            {
              "padyam": [
                {
                  "title": "అక్కఱపాటు వచ్చు సమయంబునఁజుట్టములొక్కరొక్కరి"
                },
                {
                  "title": "న్మక్కువనుద్దరించుటలు మైత్రికిఁజూడగ యుక్తమేసుమీ"
                },
                {
                  "title": "యొక్కట నీటిలో మెరక నోడల బండ్లను బండ్లనోడలన్"
                },
                {
                  "title": "దక్కక వచ్చుచుండుట నిదానముగాదె తలంప భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! మానవులు ఇచ్చిపుచ్చుకొనుట సహజము. అలాగే అవసరమున్న వేళయందు బంధువులు ఒకరినోకరు ప్రేమతో, కష్టముతో నుండిన వేళలయందు ఉద్ధరించుటకు ప్రయత్నములు చేయుట స్నేహమునకు భావం. ఎలాగనగా, నీటిలో పడవల మీద బండ్లు తీలుకొని వెళ్లునట్లు.భూమి మీద బండ్ల మీద పడవలను తీసుకువెళ్ళునట్లు. అలాగే తగిన అవసరము వచ్చిన వేళ ఒకరినొకరు అన్యోన్యతలు పాటించాలని సారాంశము."
            },
            {
              "padyam": [
                {
                  "title": "అతిగుణహీనలోభికిఁబదార్థము గల్గిన లేకయుండినన్"
                },
                {
                  "title": "మితముగఁగాని కల్మిగల మీఁదటనైన భుజింప డింపుగా"
                },
                {
                  "title": "సతమని నమ్ము దేహమును సంపద, నేఱులునిండిపాఱినన్"
                },
                {
                  "title": "గతుకగఁజూచుఁగుక్కదన కట్టడ మీఱక యెందు భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! సద్గుణములు లేని లోభి వానికి సంపద కల్గిననూ, లేకపోయిననూ మితముగా భుజించను. సంపద కల్గిన మీదట కూడా లేని వానివలె భుజించును. ఎందుకనగా శరీరము, భాగ్యము, శాశ్వతమని నమ్మి జీవనము గడుపును. ఎట్లనగా నదులు సంపూర్తి జలముతో ప్రవహించుచూ గడుపును. ఎలాగనగా నదులు సంపూర్తి జలముతో ప్రవహించుచున్ననూ, కుక్క తన అలవాటు చొప్పున నాలుకతో నీటిని త్రాగును కదా!"
            },
            {
              "padyam": [
                {
                  "title": "అదను దలంచి కూర్చిప్రజ నాదర మొప్పవిభుండు కోరినన్"
                },
                {
                  "title": "గదిసి పదార్థ మిత్తు రటు కానక వేగమె కొట్టితెండనన్"
                },
                {
                  "title": "మొదటికి మోసమౌబొదుగు మూలము గోసిన బాలు గల్గునే"
                },
                {
                  "title": "పిదికినఁగాక భూమిఁబశు బృందము నెవ్వరికైన భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! పాలకోరకు పశువుల పొదుగు మూలమున పిదికినచో పాలు లభించును. పొదుగును కోసినచో పశు ప్రాణమునకే ముప్పు వచ్చును. అట్లే రాజు అవసరార్థము కనిపెట్టి ప్రజలను ఆహ్వానించి వినయముతో ధనమడిగిన వెంటనే తెచ్చి నిత్తురు. వానిని భయకంపితులను చేసి ధనమడిగినచో ముప్పు వచ్చును. రాజు పల్కిన ప్రల్లదనములను సహించక తిరుగుబాటు చేయుదురు ప్రజలు."
            },
            {
              "padyam": [
                {
                  "title": "అనఘునికైనఁజేకుఱు ననర్హుని గూడి చరించినంతలో"
                },
                {
                  "title": "మన మెరియంగ నప్పుఁడవమానము కీడుధరిత్రియందు నే"
                },
                {
                  "title": "యనువుననైనఁదప్పవు యథార్థము తానది యెట్టులన్నచో"
                },
                {
                  "title": "నినుమునుగూర్చి యగ్ని నలయింపదె సమ్మెటపెట్టు భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! ఇనుముతో కలిసియున్న అగ్నికి సమ్మెటపోటు పడినట్లుగా లోకము నందు తగనివానితో స్నేహము చేయుచూ సంచరించు వానికి, ఎంతటి సద్గుణ వంతునకైననూ నేదియో నొక సమయాన అవమానం, హాని, కలుగును. ఇయ్యది తప్పనిసరిగా గుర్తించు కొనును."
            },
            {
              "padyam": [
                {
                  "title": "అవని విభుండు నేరుపరియై చరియించిన గొల్పువార లె"
                },
                {
                  "title": "ట్లవగుణలైన నేమి పనులన్నియుఁజేకుఱు వారిచేతనే"
                },
                {
                  "title": "ప్రవిమల నీతిశాలియగు రాముని కార్యము మర్కటంబులే"
                },
                {
                  "title": "దవిలి యొనర్పవే? జలధి దాటి సూరారులద్రుంచి భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! మిక్కిలి రాజనీతిగల రాముని కార్యమును వానరులు పూనుకొని సముద్రమును దాటి లంకా నగరమును ప్రవేశించి, రాక్షసులను వధించి సీతను తీసుకువచ్చి, కార్యమును సాధించెను. అటులనే రాజు నేర్పరి యైనచో సేవకులు అల్పులైననూ వారిచే కార్యము లన్నియును సాధించి తీరగలడు. కొంచం ఆలస్యంగానైనా సాధించవచ్చు."
            },
            {
              "padyam": [
                {
                  "title": "ఈజగమందుఁదా మనుజు డెంత మపోహాత్మకుడైన దైవమా"
                },
                {
                  "title": "తేజము తప్పఁజూచునెడఁద్రిమ్మరికోల్పడుఁనెట్లన న్మహా"
                },
                {
                  "title": "రాజకుమారుఁడైన రఘురాముఁడు గాల్నడ గాయలాకులున్"
                },
                {
                  "title": "భోజనమై తగ న్వనికిఁబోయి, చరింపఁడె మున్ను భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! మానవుడు ఎంత గొప్పవాడైననూ గ్రహయోగ బలము వక్రించినచో నా గొప్పతనమంతయూ తగ్గిపోయి, దేశ సంచారియై తప్పక తిరగవలసిన స్థితి ఏర్పడవచ్చును. ఎట్లనగా శ్రీ రామచంద్రుడు కాలి నడకతో అడవి కేగి ఆకులు, కాయలు మున్నగునవి భుజించి, తిరిగి రాజ్యమునకు వచ్చెను కదా! అలాగే విధి వక్రించినచో ఎంత వాడైననూ విధిననుసరించి నడవవలసిందేనని భావం."
            },
            {
              "padyam": [
                {
                  "title": "ఉరుకరుణాయుతుండు సమయోచిత మాత్మ దలంచి యుగ్రవా"
                },
                {
                  "title": "కృరుషతజూపినన్ఫలముకల్గుట తథ్యముగాదె యంబురం"
                },
                {
                  "title": "బురిమిన యంతనే కురియకుండునె వర్షము లోకరక్షణ"
                },
                {
                  "title": "స్థిరతర పౌరుషంబున నశేషజనంబు లెఱుంగ భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! మేఘుడు ప్రాణంబున భయము గలుగునట్లు ఉరిమి వెంటనే జనులను రక్షించు పట్టుదలతో నానందము కలుగునట్లు వర్షించును. ఆ విధంగా మిక్కిలి దయగలవాడు సమయానుకూలంగా కఠిన వాక్యములు పల్కిననూ తదుపరి తప్పక మేలునే చేయును, కీడు మాత్రం చేయడు."
            },
            {
              "padyam": [
                {
                  "title": "ఊరక వచ్చుఁబాటుపడిన కుండిననై న ఫలం బదృష్టమే"
                },
                {
                  "title": "పారఁగఁగల్గువానికిఁబ్రయాసము నొందిన దేవదానవుల్"
                },
                {
                  "title": "వార లటుండగా నడుమ వచ్చిన శౌరికిఁగల్గెగాదె శృం"
                },
                {
                  "title": "గారపుఁబ్రోవు లచ్చియును గౌస్తుభరత్నము రెండు భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! సముద్రమునందు రత్నములు పుట్టును. దేవతలు, రాక్షసులు కౌస్తుభమణి కొరకు కష్టపడి సముద్రమును చిలికెను. కాని రాక్షస, దేవతల మధ్య శ్రీమహావిష్ణువునకు శృంగారవతియగు కౌస్తుభమణి లభించునని భావము. దరిద్రునకు కష్టించిననూ ఫలితం ఉండదని భావం"
            },
            {
              "padyam": [
                {
                  "title": "ఎట్టుగఁబాటు పడ్డ నొకయించుక ప్రాప్తము లేక వస్తువుల్"
                },
                {
                  "title": "పట్టుపడంగా నేరవు నిబద్ద సురావళిఁగూడి రాక్షసుల్"
                },
                {
                  "title": "గట్టు పెకల్చి పాల్పడలిఁగవ్వము చేసి మథించి రంతయున్"
                },
                {
                  "title": "వెట్టియెకాక యే మనుభవించిరి వా రమృతంబు భాస్కరా!"
                },
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! అమృతము కొరకు రాక్షసులు దేవతలు స్నేహముతో కలసి మందరగిరిని ద్రెచ్చి కవ్వముగా జేసి, పాల సముద్రమును చిలికిరి. వీరు పడిన పాట్లు వ్యర్థము. కాని అమృతం లభించలేదు. ఆ విధముగానే మానవుడెంత కష్టపడిననూ దాననుభవింపదగిన అదృష్టము లేకపోయినచో ఫలితము లభించదు."
            },
            {
              "padyam": [
                {
                  "title": "ఎడ్డెమనుష్యుఁడేమెఱుఁగు నెన్ని దినంబులు గూడియుండినన్"
                },
                {
                  "title": "దొడ్డ గుణాడ్యునందుఁగల తోరపు వర్తనల్లఁబ్రజ్ఞ బే"
                },
                {
                  "title": "ర్పడ్డ వివేకి రీతి రుచిపాకము నాలుక గాకెఱుంగనే?"
                },
                {
                  "title": "తెడ్డది కూరలోఁగలయ ద్రిమ్మరుచుండినైన భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! మూఢాత్ముడు గుణవంతుని వెంట తిరుగుచున్ననూ అతని సద్గుణములతో కూడిన నడవడికలు గుర్తించలేడు, జ్ఞాని గుర్తించగలడు. ఎలాగనగా కూరలో తిరుగుచున్న తెడ్డు ఆ రుచిని గుర్తించలేదు. కూర యొక్క రుచి నాలుక గుర్తించును."
            },
            {
              "padyam": [
                {
                  "title": "ఘనుఁడొక వేళఁగీడ్పడిన గ్రమ్మఱ నాతని లేమి వాపఁగాఁ"
                },
                {
                  "title": "గనునొక నొక్కసత్ప్రభువు గాక నరాధము లోప రెందఱుం"
                },
                {
                  "title": "బెనుఁజెఱు వెండినట్టితఱిఁబెల్లున మేఘుఁడుగాక నీటితో"
                },
                {
                  "title": "దనుపఁదుషారముల్ శతశతంబులు చాలునటయ్య భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! పెద్ద తటాకము నందు నీరు యెండిపోయినచో మరల నీటితో నింపుటకు మేఘమే వర్శించవలెను కాని మంచు బిందువులెన్ని వర్షించిననూ తటాకము నీటిలో నిండదు కదా! అలాగే గొప్పవానికి కష్ట దశ ప్రాప్తించి కష్టములనుభవించుచున్నచో, నా కష్టములను తీర్చుటకు ఒక రాజు కావలెయును కాని, సామాన్య మానవుడు ఏమియునూ చేయలేడు. అది క్షణకాల భోగము మాత్రమేయగునని భావం."
            },
            {
              "padyam": [
                {
                  "title": "ఏల సమస్తవిద్యల నొకించుక భాగ్యము గల్గియుండినన్"
                },
                {
                  "title": "జాలు ననేక మార్గములసన్నుతి కెక్క నదెట్లొకో యనన్"
                },
                {
                  "title": "ఱాలకు నేడ విద్యలు? తిరంబగు దేవత రూప చేసినన్"
                },
                {
                  "title": "వ్రాలి నమస్కరించి ప్రసవంబులు పెట్టరె మీద భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! రాళ్ళు విద్య నేర్వకున్ననూ వాటి అదృష్టముచే దేవతా ప్రతిమలగును. జనులు ఆ ప్రతిమలకు పూలతో అర్చనలు, పూజలు చేసి తరిస్తారు. అటులనే అదృష్టరేఖ ఉన్నచో సమాజమునందు కీర్తి ప్రతిష్టలతో విరాజిల్లుతారు. అంటే విద్యలేకపోయిననూ అదృష్ట రేఖ ముఖ్యమని భావము."
            },
            {
              "padyam": [
                {
                  "title": "ఒక్కఁడెచాలు నిశ్చలబలోన్నతుఁడెంతటి కార్యమైనఁదాఁ"
                },
                {
                  "title": "జక్కనొనర్ప గౌరవు లసంఖ్యులు పుట్టిన ధేనుకోటులం"
                },
                {
                  "title": "జిక్కగనీక తత్ప్రబలసేన ననేక శిలీముఖంబులన్"
                },
                {
                  "title": "మొక్కపడంగఁజేసి తుదుముట్టఁడె యొక్క కిరీటి భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! దుర్యోధనాదులు గొప్పసేనతో కూడి విరాటరాజు యొక్క ఆవుల మందను తన వశము చేసుకొని తోలుకొని పోవుచుండగా అర్జునుడొక్కడే అనేక బాణములతో యుద్ధము చేసి వారలను జయించి గోవులను మరలించుకొని విరాటరజునకప్పగించెను. అటులనే స్థిరమైన బలవంతుడెంతటి కార్యమునైననూ చేయగలడు."
            },
            {
              "padyam": [
                {
                  "title": "పట్టుచుఁదండ్రి యత్యథమువర్తనుఁడైననుగాని వానికిం"
                },
                {
                  "title": "బుట్టిన పుత్రకుండ తన పుణ్యవశంబున దొడ్డ ధన్యుఁడౌ"
                },
                {
                  "title": "నెట్టన మఱ్ఱివిత్తు మునుపెంతయు గొంచెము దానబుట్టునా"
                },
                {
                  "title": "చెట్టు మహోన్నతత్వమును జెందదే శాఖలనిండి భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! మర్రిచెట్టు విత్తనము చిన్నదైననూ దాని నుండి పెరిగిన వృక్షము శాఖోపశాఖలుగా మహావృక్షమగును. అలాగే తండ్రి నీచప్రవర్తన గలవాడైననూ వానికి పుట్టిన కుమారుడు తన పూర్వపుణ్యాన గొప్పవాడుగా కావచ్చని భావం."
            },
            {
              "padyam": [
                {
                  "title": "స్థానము తప్పివచ్చునెడఁ తానెటువంటి బలాడ్యుడున్ నిజ"
                },
                {
                  "title": "స్థానికుడైన యల్పుని కతంబుననైనను మోసపోవుగా"
                },
                {
                  "title": "కానలలోపలన్ వెడలి గందగజం బొకనాఁడు నీటిలో"
                },
                {
                  "title": "గానక చొచ్చినన్ మొసలికాటున లోఁబడ దోటు భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! మదించిన ఏనుగు అడవిని వదిలి నీటియందు ప్రవేశము గల్గిన తోడనే మొసలికి లొంగిపోయినది గదా! అలాగే మానవుడెంత బలము గల వాడైననూ తన స్థానమును విడిచి, అన్య స్థానమును చేరినచో బలము తగ్గి పరాభవములు పొందునని భావం."
            },
            {
              "padyam": [
                {
                  "title": "కట్టడదప్పి తాము చెడు కార్యముఁ చేయుచునుండిరేనిఁదో"
                },
                {
                  "title": "బుట్టినవారినైన విడిపోవుట కార్యము దౌర్మదాంధ్యముం"
                },
                {
                  "title": "దొట్టిన రావణాసురునితో నెడబాసి విభీషణాఖ్యుఁడా"
                },
                {
                  "title": "పట్టున రాముఁజేరి చిరపట్టము గట్టుకొనండె భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! దశకంఠుడగు రావణబ్రహ్మ యొక్క సోదరుడు విభీషణుడు అన్నను వదిలేసి, శ్రీ రామమూర్తికి నేస్తమై అతనిచే శాశ్వతమైన లంకానగరాథిపత్యమును పొందెను. అలాగే చెడ్డ పనులు చేసినచో సోదరుడైననూ వానిని విడిచిపెట్టుట తథ్యమని భావం. రావణబ్రహ్మ సీతను అపహరించుకొని పోవుటయే చెడ్డపని."
            },
            {
              "padyam": [
                {
                  "title": "ప్రేమను గూర్చి యల్పునకుఁబెద్దతనంబును దొడ్డవానికిం"
                },
                {
                  "title": "దామతి తుచ్ఛపుంబని నెదం బరికింపగా యీయరాదుగా"
                },
                {
                  "title": "వామకరంబుతోడ గుడువం గుడిచేత నపానమార్గయుం"
                },
                {
                  "title": "దోమగవచ్చునే మిగులఁదోచని చేతులుగాక భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! లోకంలో నీచునకు గొప్ప పదవిని, గొప్పవానికి చిన్న పదవి నిచ్చి పనులు చేయించుచో ఆ పాలనమంతయు ఆలోచన లేక ఎడమ చేతితో భుజించుటయును, కుడి చేతితో మల మూత్రములు శుభ్రపరచుటయును యగును."
            },
            {
              "padyam": [
                {
                  "title": "తెలియని కార్యమెల్లఁగడతేర్చుట కొక్కవివేకి జేకొనన్"
                },
                {
                  "title": "వలయునట్లైన దిద్దుకొనవచ్చుఁబ్రయోజనమాంద్యమేమియుం"
                },
                {
                  "title": "గలుగదు ఫాలమందు దిలకం బిడునప్పుడు చేతనద్దమున్"
                },
                {
                  "title": "గలిగిన జక్క జేసికొనుగాదె నరుం డది చూచి భాస్కరా!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భాస్కరా! మనుజుడు నుదుటి యందు బొట్టును పెట్టుకొనుచూ చేతి యందు అద్దముతో బొట్టును వంకర టింకర లేకుండా సరిచేసుకొనును. అలాగే నేర్పరి వద్దకెళ్ళి పనులను చక్కదిద్దుకొని సంతోషాతిశయమును తెలివిగలవాడు పొందునని భావం."
            }
          ],
          "title": "భాస్కర శతకము",
         //  "img": require("../imgs/NeethiPadyalu/Baskara_Sathakam.png")
        },
        {
          "Padyalu": [
            {
              "padyam": [
                {
                  "title": "స్త్రీల ఎడ వాదులాడక"
                },
                {
                  "title": "బాలురతో జెలిమిచేసి భాషింపకుమీ"
                },
                {
                  "title": "మేలైన గుణము విడువకు"
                },
                {
                  "title": "ఏలిన పతి నిందసేయ కెన్నడు సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "స్త్రీలతో ఎప్పుడూ గొడవపడద్దు. చిన్నపిల్లలతో స్నేహం చేసి మాట్లాడవద్దు. మంచి గుణాలను వదలవద్దు. యజమానిని దూషించవద్దు."
            },
            {
              "padyam": [
                {
                  "title": "సిరి దా వచ్చిన వచ్చును"
                },
                {
                  "title": "సలలితముగ నారికేళ సలిలము భంగిన్"
                },
                {
                  "title": "సిరి దాఁ బోయిన బోవును"
                },
                {
                  "title": "కరిమింగిన వెలగపండు కరణిని సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "సంపద వచ్చినప్పుడు కొబ్బరికాయలోకి నీరు వచ్చిన విధంగా రమ్యంగానే ఉంటుంది. అలాగే పోయినప్పుడు ఏనుగు మింగిన వెలగపండులో గుంజు మాయమైనట్లే పోతుంది."
            },
            {
              "padyam": [
                {
                  "title": "మేలెంచని మాలిన్యుని"
                },
                {
                  "title": "మాలను నగసాలివాని మంగలి హితుగా"
                },
                {
                  "title": "నేలిన నరపతి రాజ్యము"
                },
                {
                  "title": "నేలఁగలసిపోవుగాని నెగడదు సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఉపకారాన్ని గుర్తుంచుకోని దుర్మార్గుడ్ని, పంచముని, కంసాలివానిని, మంగలిని హితలుగా చేసుకొని పాలించే రాజు రాజ్యము మట్టిలో కలిసి నాశనం అవుతుంది కానీ కీర్తిని పొందదు."
            },
            {
              "padyam": [
                {
                  "title": "సరసము విరసము కొరకే"
                },
                {
                  "title": "పరిపూర్ణ సుఖంబు అధిక బాధల కొరకే"
                },
                {
                  "title": "పెరుగుట విరుగుట కొరకే"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "హాస్యపు మాటలు విరోధము కొరకే. సంపూర్ణ సౌఖ్యాలు విస్తారమైన బాధల కోసమే. పొడవుగా ఎదుగుట విరిగిపోవడానికే. ధరవరలు తగ్గడం మళ్లీ పెరగడానికే అని మనుషులు తెలుసుకోవాలి."
            },
            {
              "padyam": [
                {
                  "title": "శుభముల నొందని చదువును"
                },
                {
                  "title": "అభినయమున రాగరసము నందని పాటల్"
                },
                {
                  "title": "గుభగుభలు లేని కూటమి"
                },
                {
                  "title": "సభమెచ్చని మాటలెల్లఁ జప్పన సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "శుభాలు పొందని విద్య, నటన, సంగీత, సామరస్యంతో కూడిన పాటలు, సందడి లేని కలయిక, సభల్లో మెప్పు పొందని మాటలు రుచించవు. చప్పనయినవి."
            },
            {
              "padyam": [
                {
                  "title": "వేసరవు జాతి కానీ"
                },
                {
                  "title": "వీసముఁ దాజేయనట్టి వ్యర్థుడు గానీ"
                },
                {
                  "title": "దాసి కొడుకైన గాని"
                },
                {
                  "title": "కాసులు గలవాఁడే రాజు గదరా సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "నీచ జాతివాడైనా, నిష్ప్రయోజకుడైనా, దాసీ పుత్రుడైనా ధనం కలవాడే అధిపతి."
            },
            {
              "padyam": [
                {
                  "title": "వెలయాలు సేయు బాసలు"
                },
                {
                  "title": "వెలయఁగ నగపాలి పొందు, వెలమల చెలిమిన్"
                },
                {
                  "title": "గలలోఁన గన్న కలిమియు,"
                },
                {
                  "title": "విలసితముగ నమ్మరాదు వినరా సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "వేశ్య ప్రమాణాలు, విశ్వబ్రాహ్మణుని స్నేహం, వెలమదొరల జత, కలలో చూసిన సంపదలను స్పష్టంగా నమ్మరాదు."
            },
            {
              "padyam": [
                {
                  "title": "వెలయాలివలనఁ గూరిమి"
                },
                {
                  "title": "గలుగదు మరి గలిగెనేని కడతేరదుగా"
                },
                {
                  "title": "పలువురు నడిచెడి తెరుపునఁ"
                },
                {
                  "title": "బులు మొలవదు మొలిచెనేని బొదలదు సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "పదిమంది నడిచే బాటలో పచ్చగడ్డి మొలవదు. ఒకవేళ మొలిచినా పెరగదు. ఆ విధంగానే వేశ్యవల్ల ప్రేమ లభించదు. ఒకవేళ లభించినా ఎక్కువకాలం నిలవదు."
            },
            {
              "padyam": [
                {
                  "title": "వీడెము సేయని నోరును"
                },
                {
                  "title": "జేడెల యధరామృతంబుఁ జేయని నోరును"
                },
                {
                  "title": "బాడంగరాని నోరును"
                },
                {
                  "title": "బూడిద కిరవైన పాడు బొందర సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "తాంబూలం వేసుకోని, స్త్రీల అధరామృత పానం చేయని, గానం చేయని నోరు పెంట బూడిద పోసుకొనే గోయితో సమానం సుమా!"
            },
            {
              "padyam": [
                {
                  "title": "వినదగు నెవ్వరుచెప్పిన"
                },
                {
                  "title": "వినినంతనె వేగపడక వివరింపదగున్"
                },
                {
                  "title": "కనికల్ల నిజము దెలిసిన"
                },
                {
                  "title": "మనుజుడే పో నీతిపరుడు మహిలో సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఎవరు ఏం చెప్పినా వినవచ్చు. విన్నా వెంటనే తొందరపడకుండా బాగా పరిశీలన చేయాలి. అలా పరిశీలించి అది నిజమో అబద్దమో తెలుసుకొన్న మనిషే ధర్మాత్ముడు."
            },
            {
              "padyam": [
                {
                  "title": "వరి పంటలేని యూరును"
                },
                {
                  "title": "దొరయుండని యూరు తోడు దొరకని తెరువున్"
                },
                {
                  "title": "ధరను బతిలేని గృహమును"
                },
                {
                  "title": "అరయంగా రుద్రభూమి యనదగు సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ధాన్యం పంటలేని గ్రామం, రాజు నివశింపని నగరం, సహాయం దొరకని మార్గం, భర్త (రాజు)లేని గృహం ఆలోచించగా స్మశానంతో సమానమని చెప్పవచ్చు."
            },
            {
              "padyam": [
                {
                  "title": "వరదైన చేను దున్నకు"
                },
                {
                  "title": "కరవైనను బంధుజనుల కడకేగకుమీ"
                },
                {
                  "title": "పరులకు మర్మము సెప్పకు"
                },
                {
                  "title": "పిరికికి దళవాయితనము బెట్టకు సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "వరద ముంచిన చేనును దున్నవద్దు. కూడు కరవైనను బంధువుల ఇంటికి పోవద్దు. ఇతరులకు రహస్యాల్ని చెప్పవద్దు. పిరికివాడికి సేనానాయక పదవిని ఇయ్యవద్దు."
            },
            {
              "padyam": [
                {
                  "title": "లావుగలవానికంటెను"
                },
                {
                  "title": "భావింపఁగ నీతిపరుఁడు బలవంతుండౌ"
                },
                {
                  "title": "గ్రావంబంత గజంబును"
                },
                {
                  "title": "మావటివాఁడెక్కినట్లు మహిలో సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "పెద్ద పర్వతమంటి ఏనుగుకంటే చిన్నవాడైన మావటి లోబరుచుకుని ఎక్కుచున్నాడు కనక మావటి గొప్పవాడు. అలాగే శరీరబలం కలవాని కంటే బుద్ధిబలం కలవాడే నిజమైన బలవంతుడు."
            },
            {
              "padyam": [
                {
                  "title": "రూపించి పలికి బొంకకు"
                },
                {
                  "title": "ప్రాపగు చుట్టంబు నెగ్గు పలుకకు మదిలోఁ"
                },
                {
                  "title": "గోపించురాజుఁ గొల్వకు"
                },
                {
                  "title": "పాపుదేశంబు సొరకు పదిలము సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "సాక్షులతో నిర్ధారణ చేసి అబద్ధాన్ని నిజమని స్థిరపరచడం, ఆప్తబంధువులను నిందించడం, కోపిని సేవించడం, పాపభూమికి వెళ్లడం తగని పనులు. కావున ఈ విషయాల్లో జాగ్రత్తగా ఉండాలి."
            },
            {
              "padyam": [
                {
                  "title": "రా పొమ్మని పిలువని యా"
                },
                {
                  "title": "భూపాలునిఁ గొల్వ ముక్తి ముక్తులు గలవే"
                },
                {
                  "title": "దీపంబు లేని ఇంటను"
                },
                {
                  "title": "చెవుణికీళ్లాడినట్లు సిద్ధము సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "దీపంలేని ఇంట్లో చేవుణికీళ్లాట ఆడితే ఏవిధంగా ఆనందం కలగదో ఆ విధంగానే రమ్మని కానీ పొమ్మని కానీ చెప్పని రాజును సేవించడం వల్ల జీవమూ లేదు. మోక్షమూ లేదు. వట్టి నిష్ప్రయోజనం."
            },
            {
              "padyam": [
                {
                  "title": "నాది నొకని వలచియుండగ"
                },
                {
                  "title": "మదిచెడి యొక క్రూరవిటుడు మానక తిరుగున్"
                },
                {
                  "title": "బొది జిలుక పిల్లి పట్టిన"
                },
                {
                  "title": "జదువునె యా పంజరమున జగతిని సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "పిల్లి పంజరాన్ని పట్టుకుంటే ఆ పంజరంలో ఉన్న చిలుక మాట్లాడుతుందా? అలాగే, మనసులో ఒకతన్ని ప్రేమించిన స్త్రీ విటుడు ఎంత బతిమాలినా ప్రేమించదు."
            },
            {
              "padyam": [
                {
                  "title": "మానఘనుఁ డాత్మధృతిఁ జెడి"
                },
                {
                  "title": "హీనుండగువాని నాశ్రయించుట యెల్లన్"
                },
                {
                  "title": "మానెడు జలములలోపల"
                },
                {
                  "title": "నేనుఁగు మెయి దాఁచినట్టు లెరగుము సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "అభిమాన శ్రేష్టుడు మనోధైర్యం చెడి అల్పుని ఆశ్రయించడం మానెడు నీళ్లలో ఏనుగు తన శరీరాన్ని మరుగుపరచినట్లుండును."
            },
            {
              "padyam": [
                {
                  "title": "మాటకు బ్రాణము సత్యము"
                },
                {
                  "title": "కోటకుఁ బ్రాణంబు సుభట కోటి ధరిత్రిన్"
                },
                {
                  "title": "బోటికిఁ బ్రాణము మానము"
                },
                {
                  "title": "చీటికిఁ బ్రాణంబు వ్రాలు సిద్ధము సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "నోటిమాటకు సత్యం, పెద్ద దుర్గానికి గొప్ప సైన్య సమూహం, స్త్రీకి అభిమానం, పత్రానికి చేవ్రాలు ముఖ్యమైన ఆధారాలు."
            },
            {
              "padyam": [
                {
                  "title": "మంత్రిగలవాని రాజ్యము"
                },
                {
                  "title": "తంత్రము సెడకుండ నిలచుఁ దరచుగ ధరలో"
                },
                {
                  "title": "మంత్రి విహీనుని రాజ్యము"
                },
                {
                  "title": "జంత్రపుఁగీలూడినట్లు జరుగదు సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "సమర్థుడైన మంత్రి ఉంటే సామ, దాన, భేద, దండ వంటి ఉపాయాలు పాడుకాకుండా సాగిపోతాయి. అలాంటి మంత్రి లేకపోతే కీలూడిపోయిన యంత్రంలా ముందుకు సాగవు."
            },
            {
              "padyam": [
                {
                  "title": "మండలపతి సముఖంబున"
                },
                {
                  "title": "మెండైన ప్రధానిలేక మెలఁగుట యెల్లన్"
                },
                {
                  "title": "గొండంత మదపుటేనుగు"
                },
                {
                  "title": "తొండము లేకుండినట్లు తోచుర సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "కొండంత పెద్దదైన ఏనుగు అయినా తొండం లేకపోతే ఎలా శోభావిహీనంగా ఉంటుందో అలాగే, గొప్ప దేశాన్ని పరిపాలించే రాజు దగ్గర సమర్థుడైన మంత్రి లేకపోతే అతని పాలన అంతే శోభావిహీనమవుతుంది."
            },
            {
              "padyam": [
                {
                  "title": "బలవంతుడ నాకేమని"
                },
                {
                  "title": "పలువురితో నిగ్రహించి పలుకుట మేలా"
                },
                {
                  "title": "బలవంతమైన సర్పము"
                },
                {
                  "title": "చలిచీమల చేత జిక్కి చావదె సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "నేను చాలా బలవంతుడ్ని. నాకేమీ భయం లేదని నిర్లక్ష్యం చేసి విర్రవీగి విరోధం తెచ్చుకోవడం మంచిది కాదు. అది ఎప్పుడూ హాని కలిగిస్తుంది. ఎంతో బలం కలిగిన సర్పం కూడా చలిచీమలకు లోబడి చావడం లేదా?"
            },
            {
              "padyam": [
                {
                  "title": "బంగారు కుదువబెట్టకు"
                },
                {
                  "title": "సంగరమునఁ బారిపోకు సరసుఁడవగుచో"
                },
                {
                  "title": "నంగడి వెచ్చములాడకు"
                },
                {
                  "title": "వెంగలితో జెలిమి వలదు వినురా సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "బంగారు నగలను తాకట్టు పెట్టవద్దు. యుద్ధభూమి నుంచి వెన్నిచ్చి పారిపోవద్దు. దుకాణం నుంచి సరకులు అరువు తెచ్చుకోవద్దు. మూఢునితో స్నేహం చేయవద్దు."
            },
            {
              "padyam": [
                {
                  "title": "పొరుగున పగవాడుండిన"
                },
                {
                  "title": "నిర వొందగ వ్రాతగాఁడె ఏలికయైనన్"
                },
                {
                  "title": "ధరఁగాఁపు కొండెమాడినఁ"
                },
                {
                  "title": "గరణాలకు బ్రతుకులేదు గదరా సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఇంటి పక్కనే శతృవు ఉన్నా, బాగా రాయగలవాడే ప్రభువు అయినా, గ్రామ పెత్తందారు కొండెములు చెప్పేవాడయినా లేఖరుకు జీవితం గడవదు."
            },
            {
              "padyam": [
                {
                  "title": "పెట్టిన దినములలోపల"
                },
                {
                  "title": "నట్టడవులకైనవచ్చు నానార్థములున్"
                },
                {
                  "title": "బెట్టని దినములఁ గనకపు"
                },
                {
                  "title": "గట్టెక్కిన నేమిలేదు గదరా సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "అదృష్టం కలసివచ్చిన రోజుల్లో అడవి మధ్యలో ఉన్నా అన్ని సంపదలూ అక్కడికే వస్తాయి. దురదృష్టం వెన్నాడేటపుడు బంగారు పర్వతాన్ని ఎక్కినా ఏమీ లభించదు."
            },
            {
              "padyam": [
                {
                  "title": "పులిపాలు దెచ్చిఇచ్చిన"
                },
                {
                  "title": "నలవడఁగ గుండెగోసి యరచే నిడినం"
                },
                {
                  "title": "దలపొడుగు ధనము బోసిన"
                },
                {
                  "title": "వెలయాలికి గూర్మిలేదు వినురా సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "దుస్సాధ్యమైన పులిపాలు తెచ్చి ఇచ్చినా, హృదయాన్ని కోసి అరచేతిలో పెట్టినా, నిలువెత్తు ధనం పోసినా వేశ్యకు నిజమైన ప్రేమ ఉండదు."
            },
            {
              "padyam": [
                {
                  "title": "పుత్రోత్సాహము తండ్రికి"
                },
                {
                  "title": "పుత్రుడు జన్మించినపుడు పుట్టదు, జనులా"
                },
                {
                  "title": "పుత్రుని కనుగొని పొగడగ"
                },
                {
                  "title": "పుత్రోత్సాహంబు నాడు పొందుర సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "కుమారుడు పుట్టగానే తండ్రికి సంతోషం కలగదు. ప్రజలు ఆ కుమారుడ్ని మెచ్చిన రోజుననే నిజమైన సంతోషం కలుగుతుంది."
            },
            {
              "padyam": [
                {
                  "title": "పురికిని బ్రాణము కోమటి"
                },
                {
                  "title": "వరికిని బ్రాణంబు నీరు వసుమతిలోనం"
                },
                {
                  "title": "గరికిని బ్రాణము తొండము"
                },
                {
                  "title": "సిరికిని బ్రాణము మగువ సిద్ధము సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఈ లోకంలో పట్టణానికి వైశ్యుడు, వరిసస్యమునకు నీళ్లు, ఏనుగుకు తొండము, ఐశ్వర్యానికి స్త్రీ జీవం ఒసంగుదురు."
            },
            {
              "padyam": [
                {
                  "title": "పిలువని పనులకు బోవుట"
                },
                {
                  "title": "కలయని సతి రతియు రాజు గానని కొలువు"
                },
                {
                  "title": "బిలువని పేరంటంబును"
                },
                {
                  "title": "వలవని చెలిమియును జేయవలదుర సుమతీ"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "పిలవని కార్యక్రమాలకు వెళ్లడం, హృదయంతో కలవని స్త్రీతో సంభోగం, పాలకులు చూడని సేవ, పిలవని పేరంటం, కోరని స్నేహం చేయదగదు."
            },
            {
              "padyam": [
                {
                  "title": "పాలసునకైన యాపద"
                },
                {
                  "title": "జాలింబడి తీర్పఁదగదు సర్వజ్ఞునకుఁ"
                },
                {
                  "title": "దే లగ్నిబడగఁ బట్టిన"
                },
                {
                  "title": "మేలెరుగునె మీటుగాక మేదిని సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "తేలు నిప్పులో పడినప్పుడు దానిని జాలితో బయటకు తీసి పట్టుకొంటే కుడుతుంది. కానీ మనం చేసే మేలును తెలుసుకోలేదు. అలాగే జాలిపడి మూర్ఖునికి ఆపదలో సహాయం చేయజూస్తే తిరిగి మనకే ఆపకారం చేస్తాడు. కనుక అట్లు చేయరాదు."
            },
            {
              "padyam": [
                {
                  "title": "పాలను గలిసిన జలమును"
                },
                {
                  "title": "బాలవిధంబుననే యుండుఁ బరికింపగ"
                },
                {
                  "title": "బాల చవిఁజెరచు గావున"
                },
                {
                  "title": "బాలసుఁడగువాని పొందు వలదుర సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "పాలతో కలిసిన నీరు కూడా పైకి పాలలాగే కనపడుతుంది. పరిశీలిస్తే పాల రుచిని చెడగొడుతుంది. అలాగే, చెడ్డవారితో స్నేహం స్వగౌరవాన్ని కూడా పోగొట్టును. కనుక అలాంటి స్నేహం వలదు."
            },
            {
              "padyam": [
                {
                  "title": "పాటెరుగని పతికొలువును"
                },
                {
                  "title": "గూటంబున కెరుకపడని కోమలిరతియు"
                },
                {
                  "title": "జేటెత్తజేయు చెలిమియు"
                },
                {
                  "title": "నేటికి నెదిరీదినట్టు లెన్నగ సుమతీ"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "శ్రమను తెలుసుకోలేని ప్రభువును సేవించడం, కలయికకు తెలివిలేని స్త్రీతో సంభోగం, వెంటనే చెడిపోయేట్లున్న స్నేహం నదీ ప్రవాహానికి ఎదురీదినట్లే."
            },
            {
              "padyam": [
                {
                  "title": "పలుదోమి సేయు విడియము"
                },
                {
                  "title": "తలగడిగిన నాఁటినిద్ర తరుణులయెడలం"
                },
                {
                  "title": "బొలయలుక నాటి కూటమి"
                },
                {
                  "title": "వెల యింతని చెప్పరాదు వినురా సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "దంతధావనం చేసుకుని, తలంటు పోసుకుని, తాంబూలం వేసుకుని పోయిన నిద్ర, స్త్రీలతో ప్రణయకలహం వచ్చిన రోజు పొందు అత్యంత సౌఖ్యప్రదాలు. వాటి విలువ ఇంతని చెప్పలేము."
            },
            {
              "padyam": [
                {
                  "title": "పర్వముల సతులఁ గవయకు"
                },
                {
                  "title": "ముర్వీశ్వరుకరుణ నమ్మి యబ్బకు మదిలో"
                },
                {
                  "title": "గర్వింపనాలి బెంపకు"
                },
                {
                  "title": "నిర్వహణము లేనిచోట నిలువకు సుమతీ"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "పుణ్యదినాల్లో స్త్రీలతో కలవకుము. ప్రభువుల దయను నమ్మి మనసులో ఉప్పొంగకుము. గర్వంతో విర్రవీగే భార్యను పోషింపకు. సాగుదల లేనిచోట నిలవకు."
            },
            {
              "padyam": [
                {
                  "title": "పరుల కనిష్టము సెప్పకు"
                },
                {
                  "title": "పొరుగిండ్లకు బనులులేక పోవకు మెపుడున్"
                },
                {
                  "title": "బరుఁగదిసిన సతి గవయకు"
                },
                {
                  "title": "ఎరింగియు బిరుసై సహయము నెక్కకు సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఇతరులకు అప్రియములను పలకవద్దు. పనులు లేక పొరుగిళ్లకు పోవద్దు. ఎప్పుడూ ఇతరులు పొందిన భార్యను కలివవద్దు. తెలిసీ, పొగరుబోతైన గుర్రాన్ని ఎక్కవద్దు."
            },
            {
              "padyam": [
                {
                  "title": "పరసతుల గోష్టినుండి"
                },
                {
                  "title": "పురుషుడు గాంగేయుడైన భువి నిందబడున్"
                },
                {
                  "title": "బరుసతి సుశీయైనను"
                },
                {
                  "title": "బరుసంగతినున్న నింద పాలగు సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భూమిపై, పర స్త్రీలతో సరససల్లాపాలు ఆడితే భీష్ముడయినా నిందను ఎదుర్కొనవలసిందే. ఇతర స్త్రీ ఎంత మంచిదయినా పర పురుషునితో స్నేహం చేస్తే అపకీర్తి పాలగును."
            },
            {
              "padyam": [
                {
                  "title": "పరసతి కూటమి గోరకు"
                },
                {
                  "title": "పరధనముల కాసపడకు పరునెంచకుమీ"
                },
                {
                  "title": "సరిగాని గోష్టి సేయకు"
                },
                {
                  "title": "సిరిచెడి జుట్టంబుకడకుఁ జేరకు సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఇతర స్త్రీలతో కలయికను కోరవద్దు. ఇతరుల భాగ్యానికి ఆశపడకు. ఇతరుల దోషాలను లెక్కించవద్దు. మంచిది కాని సంభాషణ చేయవద్దు. భాగ్యం పోయినప్పుడు బంధువుల వద్దకు చేరకుము."
            },
            {
              "padyam": [
                {
                  "title": "పరనారీ సోదరుఁడై"
                },
                {
                  "title": "పరధనముల కాసపడక పరులకు హితుడైఁ"
                },
                {
                  "title": "పరుల దనుఁబొగడ నెగడక"
                },
                {
                  "title": "పరుఁలలిగిన నలుగనతఁడు పరముడు సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఇతర స్త్రీలను తోబుట్టువులుగా చూసుకొంటూ, ఇతరుల ధనానికి ఆశపడకుండా, అందరికీ ఇష్టుడై, ఇతరులు పొగుడుతుంటే ఉప్పొంగక, కోపం ప్రదర్శించినప్పుడు బాధ పడకుండా ఉండేవాడే శ్రేష్టుడు."
            },
            {
              "padyam": [
                {
                  "title": "పనిచేయునెడల దాసియు"
                },
                {
                  "title": "ననుభవమున రంభ మంత్రి యాలోచనలన్"
                },
                {
                  "title": "దనభుక్తి యెడలఁ దల్లియు"
                },
                {
                  "title": "ననఁ దన కులకాంత యుండనగురా సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భార్య ఇంటిపనుల్లో దాసిగా, సంభోగ సమయంలో రంభగా, సలహాలిచ్చేటప్పుడు మంత్రిగా, భోజనం పెట్టే సమయంలో తల్లిగా ప్రవర్తించేలా ఉండాలి."
            },
            {
              "padyam": [
                {
                  "title": "పతికడకుఁ దన్నుగూర్చిన"
                },
                {
                  "title": "సతికడకును వేల్పుకడకు సద్గురు కడకున్"
                },
                {
                  "title": "సుతుకడకు రిత్తచేతుల"
                },
                {
                  "title": "మతిమంతులు చనరు నీతిమార్గము సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ప్రభువు వద్దకు, భార్య దగ్గరికి, భగవంతుని సన్నిధానానికి, గురువు వద్దకు, కుమారుని దగ్గరకు బుద్ధిమంతులు వట్టిచేతులతో వెళ్లరు. ఇదియే నీతిమార్గము."
            },
            {
              "padyam": [
                {
                  "title": "పగవల దెవ్వరితోడను"
                },
                {
                  "title": "వగవంగా వలదు లేమి వచ్చిన పిదపన్"
                },
                {
                  "title": "దెగనాడవలదు సభలను"
                },
                {
                  "title": "మగువకు మనసీయవలదు మహిలో సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భూమిపై ఎవరితోనూ విరోధం మంచిది కాదు. దార్రిద్య్రం వచ్చిన తరువాత విచారింపరాదు. సభలో ఎవరినీ దూషింపరాదు. స్త్రీకి తన హృదయాన్ని తెలియనీరాదు."
            },
            {
              "padyam": [
                {
                  "title": "నవ్వకుమీ సభలోపల"
                },
                {
                  "title": "నవ్వకుమీ తల్లిదండ్రి నాధులతోడన్"
                },
                {
                  "title": "నవ్వకుమీ పరసతితో"
                },
                {
                  "title": "నవ్వకుమీ విప్రవరుల నయమిది సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "సభల్లో, తల్లిని, తండ్రిని, భర్తను, ఇతరుల భార్యను, బ్రాహ్మణులను చూసి నవ్వరాదు."
            },
            {
              "padyam": [
                {
                  "title": "నవరస భావాలంకృత"
                },
                {
                  "title": "కవితాగోష్ఠియును మధుర గానంబును"
                },
                {
                  "title": "నవివేకి కెంతఁజెప్పినఁ"
                },
                {
                  "title": "జెవిటికి శంఖూదినట్లు సిద్ధము సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "తొమ్మిది రసాలతోకూడిన మంచి భావాలతో శృంగారింపబడిన కవిత్వ సంబంధ సంభాషణ, కమ్మని సంగీతాన్ని జ్ఞానహీనునకు వినిపించడం, చెవిటి వాని ముందు శంఖం వూదినట్లే."
            },
            {
              "padyam": [
                {
                  "title": "నరపతుల మేరదప్పిన"
                },
                {
                  "title": "దిర మొప్పగ విధవ ఇంట దీర్పరియైనన్"
                },
                {
                  "title": "గరణము వైదికుఁడయినను"
                },
                {
                  "title": "మరణాంతకమౌనుగాని మానదు సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "రాజు హద్దుమీరి ప్రవర్తించినా, శాశ్వతంగా విధవ ఇంట పెత్తందారైనా, లేఖకుడు నియోగి కాక వైదికుడయినా ప్రాణము మీదికి వచ్చును. తథ్యము."
            },
            {
              "padyam": [
                {
                  "title": "నవమున బాలుంద్రావరు"
                },
                {
                  "title": "భయమునను విషమ్మునైన భక్షింతురుగా"
                },
                {
                  "title": "నయమెంత దోసకారియె"
                },
                {
                  "title": "భయమే చూపంగవలయు బాగుగ సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "మెత్తని మాటలతో పాలు కూడా తాగరు. భయపెడితే విషాన్నైనా తాగుతారు. మృదుత్వమెప్పుడూ చెడునే కలిగిస్తుంది. కనక చక్కగా భయాన్నే చూపవలయును."
            },
            {
              "padyam": [
                {
                  "title": "నమ్మకు సుంకరి జూదరి"
                },
                {
                  "title": "నమ్మకు మగసాలివాని నటు వెలయాలిన్"
                },
                {
                  "title": "నమ్మకు మంగలివానిని"
                },
                {
                  "title": "నమ్మకుమీ వామహస్తు నవనిని సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "పన్నులు వసూలు చేసే వానిని, జూదం ఆడేవాడిని, కమసాలివానిని, నటకుని, వేశ్యను, వర్తకుని, ఎడమ చేతితో పనులు చేసేవాడిని నమ్మవద్దు."
            },
            {
              "padyam": [
                {
                  "title": "నడువకుమీ తెరువొక్కటఁ"
                },
                {
                  "title": "గుడువకుమీ శతృనింటఁ గూరిమితోడన్"
                },
                {
                  "title": "ముడువకుమీ పరధనముల"
                },
                {
                  "title": "నుడువకుమీ యెరులమనసు నొవ్వగ సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "తోడులేకుండా వంటరిగా పోవద్దు. విరోధి ఇంట్లో భుజింపవద్దు. ఇతరుల ధనం దగ్గర ఉంచుకోవద్దు. ఇతరుల మనస్సు బాధపడేట్లు మాట్లాడవద్దు."
            },
            {
              "padyam": [
                {
                  "title": "ధీరులకుఁ జేయు మేలది"
                },
                {
                  "title": "సారంబగు నారికేళ సలిలము భంగిన్"
                },
                {
                  "title": "గారవమును మరిమీదట"
                },
                {
                  "title": "భూరిసుఖావహము నగును భువిలో సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "బుద్ధిమంతుడికి చేసే మేలు కొబ్బరికాయలోని నీరు వలే శ్రేష్టమైనది, ప్రియమైనది, గొప్ప సుఖానికి స్థానాన్ని ఇచ్చేదీ అగును."
            },
            {
              "padyam": [
                {
                  "title": "ధనపతి సఖుఁడై యుండియు"
                },
                {
                  "title": "నెనయంగా శివుఁడు భిక్షమెత్తగవలసెన్"
                },
                {
                  "title": "దనవారి కెంతకల గిన"
                },
                {
                  "title": "దనభాగ్యమె తనఁకుగాక తథ్యము సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "గొప్ప ధనవంతుడైన కుబేరుడు మిత్రడుగా ఉన్నా శివునికి బిచ్చం ఎత్తవలసి వచ్చింది. కనుక తాను సంపాదించుకున్న (తన దగ్గరున్న) భాగ్యమే తనకు సహాయపడాలి గానీ తన దగ్గర ఉన్నవారి దగ్గర ఎంత భాగ్యం ఉన్నా నిష్ప్రయోజనం."
            },
            {
              "padyam": [
                {
                  "title": "చేతులకు దొడవు దానము"
                },
                {
                  "title": "భూతలనాధులకుఁ దొడవు బొంకమి ధరలో"
                },
                {
                  "title": "నీతియ తొడ వెవ్వారికి"
                },
                {
                  "title": "నాతికి మానంబు తొడవు నయముగ సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "చేతులకు దానం, పాలకులకు అసత్యం పలకకుండడం, అందరికీ న్యాయం, స్త్రీకి అభిమానం అలంకారాలు."
            },
            {
              "padyam": [
                {
                  "title": "దగ్గర కొండెము సెప్పెడు"
                },
                {
                  "title": "ప్రెగ్గడ పలుకులకు రాజు ప్రియుఁడై మరితా"
                },
                {
                  "title": "నెగ్గుఁబ్రజ కాచరించుట"
                },
                {
                  "title": "బొగ్గులకై కల్పతరువు బొడచుట సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "మంత్రి చెప్పే చాడీ మాటలకు లోబడి మంచిచెడ్డలు తెలుసుకొనక రాజు ప్రజలను హింసించడం బొగ్గుల కోసం కోరిన కోరికలిచ్చే కల్పవృక్షాన్ని నరికేసుకోవడం వంటిది."
            },
            {
              "padyam": [
                {
                  "title": "తాను భుజింపని యర్థము"
                },
                {
                  "title": "మానవపతిఁ జేరుఁగొంత మరి భూగతమౌఁ"
                },
                {
                  "title": "గానల నీఁగల గూర్చిన"
                },
                {
                  "title": "దేనియ యెరుఁజేరునట్లు తిరగమున సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "అరణ్యంలో తేనెటీగలు కూడబెట్టిన తేనె కడకి తరుల పాలైనట్లు లోభి నోరు కట్టుకొని కూడబెట్టిన ధనం కొంత ప్రభువుల పాలును, మరికొంత భూమి పాలగును."
            },
            {
              "padyam": [
                {
                  "title": "తలమాసిన నొలుమాసిన"
                },
                {
                  "title": "వలువలు మాసినను బ్రాన వల్లభునైనం"
                },
                {
                  "title": "గులకాంతలైన రోఁతురు"
                },
                {
                  "title": "తిలకింపఁగ భూమిలోన దిరముగ సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భూలోకంలో తలమాసినా, శరీరానికి మురికి పట్టినా, మట్టలు మాసిపోయినా చేసుకొన్న భర్తనయినా ఇల్లాళ్లు ఏవగించుకుంటారు."
            },
            {
              "padyam": [
                {
                  "title": "తలనుండు విషము ఫణికిని"
                },
                {
                  "title": "వెలయంగా దోకనుండు వృశ్చికమునకున్"
                },
                {
                  "title": "దలతోఁక యనక యుండును"
                },
                {
                  "title": "ఖలునకు నిలువెల్ల విషము గదరా సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "పాముకి విషము తలలోను, తేలుకు తోకలోనూ, దుష్టునకు నిలువెల్లా విషం ఉంటుంది."
            },
            {
              "padyam": [
                {
                  "title": "తములము వేయని నోరును"
                },
                {
                  "title": "విమతులతో జెలిమిసేసి వెతఁబడు తెలివిన్"
                },
                {
                  "title": "గమలములు లేని కొలకుఁను"
                },
                {
                  "title": "హిమధాముఁడు లేని రాత్రి హీనము సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "తాంబూలం వేయని నోరు, దుర్మార్గులతో స్నేహం చేసి బాధపడే బుద్ధి, తామరపూలు లేని చెరువు, చంద్రుడు లేని రాత్రి శోభిల్లవు."
            },
            {
              "padyam": [
                {
                  "title": "తనవారు లేనిచోటను"
                },
                {
                  "title": "జనవించుక లేనిచోట జగడముచోటన్"
                },
                {
                  "title": "అనుమానమైన చోటను"
                },
                {
                  "title": "మనుజునకును నిలువఁదగదు మహిలో సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "కావాల్సిన చుట్టాలు లేనిచోట, మాట చెల్లుబడికాని ప్రదేశంలో, తగవులాడుకొనేచోట, తనను అవమానించే ప్రదేశాల్లో మానవుడు నిలువరాదు."
            },
            {
              "padyam": [
                {
                  "title": "తన కలిమి ఇంద్రభోగము"
                },
                {
                  "title": "తన లేమియె సర్వలోక దారిద్య్రంబున్"
                },
                {
                  "title": "తన చావు జతద్ప్రళయము"
                },
                {
                  "title": "తను వలచినదియె రంభ తథ్యము సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "తన ఐశ్వర్యమే దేవలోక వైభవం, తన దారిద్య్రమే సమస్తలోక దారిద్య్రం, తన చావే ప్రపంచానికి ప్రళయం, తాను ప్రేమించినదే రంభ, మనుషులు భావించేది ఈ విధంగానే ఇది నిజము."
            },
            {
              "padyam": [
                {
                  "title": "తనయూరి తపసితనమును"
                },
                {
                  "title": "దనపుత్త్రుని విద్య పెంపుఁ దన సతి రూపున్"
                },
                {
                  "title": "దన పెరటిచెట్టు మందును"
                },
                {
                  "title": "మనసున వర్ణింప రెట్టి మనుజులు సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "తన ఊరివాళ్ల తపోనిష్ఠ, కుమారుని విద్యాధిక్యత, భార్య సౌందర్యం, ఇంటి వైద్యాలను ఎవ్వరూ కూడా గొప్పగా వర్ణించి చెప్పరు."
            },
            {
              "padyam": [
                {
                  "title": "తన కోపమె తన శతృవు"
                },
                {
                  "title": "తన శాంతమె తనకు రక్ష దయ చుట్టంబౌఁ"
                },
                {
                  "title": "తన సంతోషమె స్వర్గము"
                },
                {
                  "title": "తన దుఃఖమె నరకమండ్రు తధ్యము సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "తన కోపమే తనకు శతృవువలే బాధించును. తన శాంతమే తనను రక్షించును. దయయే చుట్టాలవలే సాయపడును. ఆనందమే ఇంద్రలోక సౌఖ్యము. దుఃఖమే నరకం అగును. ఇది నిజం."
            },
            {
              "padyam": [
                {
                  "title": "తడ వోర్వక యొడలోర్వక"
                },
                {
                  "title": "కడువేగం బడచిపడినఁ గార్యంబగునే"
                },
                {
                  "title": "తడవోర్చిన నొడలోర్చినఁ"
                },
                {
                  "title": "జెడిపోయిన కార్యమెల్ల జేకురు సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఆలస్యాన్ని, శరీర శ్రమను సహించకుండా తొందరపడినా కార్యం కాదు. ఆలస్యాన్ని, శరీర శ్రమను ఓర్చుకొన్నప్పుడే చెడిపోయిన కార్యం కూడా నెరువేరుతుండును."
            },
            {
              "padyam": [
                {
                  "title": "చేతులకు దొడవు దానము"
                },
                {
                  "title": "భూతలనాధులకుఁ దొడవు బొంకమి ధరలో"
                },
                {
                  "title": "నీతియ తొడ వెవ్వారికి"
                },
                {
                  "title": "నాతికి మానంబు తొడవు నయముగ సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "చేతులకు దానం, పాలకులకు అసత్యం పలకకుండడం, అందరికీ న్యాయం, స్త్రీకి అభిమానం అలంకారాలు."
            },
            {
              "padyam": [
                {
                  "title": "చుట్టములు గానివారలు"
                },
                {
                  "title": "చుట్టములము నీకటంచు సొంపుదలర్పన్"
                },
                {
                  "title": "నెట్టుకొని యాశ్రయింతురు"
                },
                {
                  "title": "గట్టిగఁ ద్రవ్యంబు గలుగఁ గదరా సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ధనం ఎక్కువగా ఉన్నట్లయితే బంధువులు కాని వారు కూడా మేము మీకు బంధువులమే అంటూ పట్టుదలతో గట్టిగా మనల్ని ఆశ్రయించడానికి వస్తారు."
            },
            {
              "padyam": [
                {
                  "title": "కొరగాని కొడుకు పుట్టినఁ"
                },
                {
                  "title": "కొరగామియె కాదు తండ్రి గుణముల జెరచుం"
                },
                {
                  "title": "జెరకు తుద వెన్నుఁపుట్టిన"
                },
                {
                  "title": "జెరకునఁ దీపెల్ల జెరచు సిద్ధము సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "చెరకుగెడ చివర వెన్ను పుడితే అది చెరకులోని తీయదనాన్ని ఏ విధంగా పాడు చేస్తుందో అలాగే అప్రయోజకుడైన కుమారుడు పుట్టడం వల్ల ఆ కుటుంబానికి ఉపయోగ పడకపోగా తండ్రికి ఉన్న మంచి పేరును కూడా చెడగొడతాడు."
            },
            {
              "padyam": [
                {
                  "title": "కోమలి విశ్వాసంబును"
                },
                {
                  "title": "బాములతోఁ జెలిమి యన్యభామల వలపున్"
                },
                {
                  "title": "వేముల తియ్యఁదనంబును"
                },
                {
                  "title": "భూమీశుల నమ్మికలుసు బొంకుర సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "స్త్రీల పట్ల విశ్వాసం, పాములతో స్నేహం, పరస్త్రీల ప్రేమ, వేప చెట్లలో తీయదనం, రాజుల పట్ల నమ్మకం అన్నీ అసత్యాలు."
            },
            {
              "padyam": [
                {
                  "title": "గడనగల మననిఁజూచిన"
                },
                {
                  "title": "నడుగగడుగున మడుఁగులిడుచు రతివలు తమలో"
                },
                {
                  "title": "గడ నుడుగు మగనిఁ జూచిన"
                },
                {
                  "title": "నడుపీనుఁగు వచ్చెననుచు నగుదురు సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "స్త్రీలు సంపాదన ఉన్న భర్తను చూస్తే అడుగులకు మడుగులు ఒత్తుతారు, పూజిస్తారు. సంపాదన లేని మగడిని చూస్తే నడిచే శవం వచ్చిందని హీనంగా మాట్లాడతారు."
            },
            {
              "padyam": [
                {
                  "title": "చీమలు పెట్టిన పుట్టలు"
                },
                {
                  "title": "పాముల కిరవైన యట్లు పామరుఁడు దగన్"
                },
                {
                  "title": "హేమంబుఁ గూడఁబెట్టిన"
                },
                {
                  "title": "భూమీశులపాఁ జేరు భువిలో సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "చీమలు పెట్టిన పుట్టలు పాములకు నివాసమైనట్లు అజ్ఞాని కూడబెట్టిన బంగారమంతా రాజుల వశమై పోతుంది."
            },
            {
              "padyam": [
                {
                  "title": "కులకాంత తోడ నెప్పుడుఁ"
                },
                {
                  "title": "గలహింపకు వట్టి తప్పు ఘటియింపకుమీ"
                },
                {
                  "title": "కలకంఠి కంట కన్నీ"
                },
                {
                  "title": "రొలికిన సిరి యింటనుండ నొల్లదు సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భార్యతో ఎప్పుడూ తగాదా పడవద్దు. ఆమెపై లేనిపోని నేరాలను ఆరోపించవద్దు. ఉత్తమ ఇల్లాలు కంట నీరు కింద పడిన ఇంటిలో లక్ష్మిదేవి ఉండదు."
            },
            {
              "padyam": [
                {
                  "title": "కూరిమిగల దినములలో"
                },
                {
                  "title": "నేరము లెన్నఁడును గలుఁగ నేరవు మఱి యా"
                },
                {
                  "title": "కూరిమి విరసంబైనను"
                },
                {
                  "title": "నేరములే తోఁచుచుండు నిక్కము సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "పరస్పరం స్నేహం ఉన్న రోజుల్లో నేరాలు ఎప్పుడూ కనిపించవు. ఆ స్నేహం చెడగానే అన్ని తప్పులుగానే కనిపిస్తాయి. ఇది నిజం."
            },
            {
              "padyam": [
                {
                  "title": "కొంచెపు నరుసంగతిచే"
                },
                {
                  "title": "నంచితముగఁ గీడువచ్చు నదియెట్లన్నన్"
                },
                {
                  "title": "గించిత్తు నల్లి కుట్టిన"
                },
                {
                  "title": "మంచమునకుఁ జేటువచ్చు మహిలో సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "చిన్న నల్లి కుడితే ఆ నల్లి ఉన్న మంచాన్ని ఎండలో వేయడం, కర్రతో కొట్టడం, మరుగునీళ్లు పోయడం మొదలైన వన్నీ చేస్తాం. నల్లితో స్నేహం చేయడం వల్లే మంచానికే ఈ కష్టాలు. అలాగే అల్పుడైన వాడితో స్నేహం చేస్తే ఎలాంటి వారికైనా ఆపదలు వస్తాయి."
            },
            {
              "padyam": [
                {
                  "title": "కారణములేని నగవును"
                },
                {
                  "title": "బేరణమును లేని లేమ పృథివీస్థలిలో"
                },
                {
                  "title": "బూరణము లేని బూరెయు"
                },
                {
                  "title": "వీరణములేని పెండ్లి వృధరా సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "కారణం లేని నవ్వు, నృత్యం (రవిక) లేని స్త్రీ, పూర్ణం లేని బూరె, వీరణం లేని పెళ్లి వ్యర్థాలు."
            },
            {
              "padyam": [
                {
                  "title": "కాముకుడు దనిసి విడిచిన"
                },
                {
                  "title": "కోమలి బరవిటుడు గవయ గూడుట యెల్లన్"
                },
                {
                  "title": "బ్రేమమున జెరకు పిప్పికి"
                },
                {
                  "title": "జీమలు వెస మూగినట్లు సిద్ధము సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "విటుడు తృప్తిపడేట్లు భోగించి విడిచన కాంతను మరొకడు జారుడు అనుభవించాలని కోరడం చెరకులోని రసాన్ని సంపూర్ణంగా తీసివేసిన తరువాత పిప్పికై చీమలు ముసుకొన్నట్లు ఉండును."
            },
            {
              "padyam": [
                {
                  "title": "కాదు సుమీ దుస్సంగతి"
                },
                {
                  "title": "పోదుసుమీ కీర్తికాంత పొందిన పిదపన్"
                },
                {
                  "title": "వాదుసుమీ యప్పిచ్చుట"
                },
                {
                  "title": "లేదుసుమీ సతులవలపు లేశము సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "చెడ్డవారితో స్నేహం మంచిది కాదు. కిర్తి వచ్చిన తరువాత అది నశించదు. అప్పు తీసుకోవడం తగవులకు మూలం. స్త్రీల వద్ద ప్రేమ శూన్యం."
            },
            {
              "padyam": [
                {
                  "title": "కవిగానివాని వ్రాఁతయు"
                },
                {
                  "title": "నవరసభావములు లేని నాతుల వలపుం"
                },
                {
                  "title": "దవిలి చను పందినేయని"
                },
                {
                  "title": "వివిధాయుధ కౌశలంబు వృథరా సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "కవి కానివాడు రాసిన రచన, తొమ్మిది రసాల స్థితులు తెలియని స్త్రీ ప్రేమ, ముందుపోయే పందిని వెంబడించి కొట్టలేని వాని ఆయుధ విద్యలోని నేర్పరితనం వ్యర్థం."
            },
            {
              "padyam": [
                {
                  "title": "కసుగాయఁ గరచి చూచిన"
                },
                {
                  "title": "మసలక తన యోగరుగాక మధురంబగునా?"
                },
                {
                  "title": "పసగలుగు యువతులుండఁగఁ"
                },
                {
                  "title": "బసిబాలలఁ బొందువాఁడు పశువుర సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "పక్వానికి వచ్చిన పళ్లను వదిలి పచ్చికాయలను తింటే వగరుగా ఉన్నట్లే, చాతుర్యం గల పడుచులు ఉండగా పసిపాపలను కూడెడు వానికి సుఖము శూన్యము. అలాంటివాడు నిజంగా పశువే."
            },
            {
              "padyam": [
                {
                  "title": "కరణము సాధై యున్నను"
                },
                {
                  "title": "గరి మద ముడిగినను బాము కరవకయున్నన్"
                },
                {
                  "title": "ధరదేలు మీటకున్నను"
                },
                {
                  "title": "గర మరుదుగ లెక్క గొనరు గదరా సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "కరణం నెమ్మదస్తుడైనా, ఏనుగు మదం పోయినది అయినా, తాచుపాము కరవకున్నా, తేలు కుట్టకున్నా ఆశ్చర్యంతో మిక్కిలి తేలికగా చూస్తారు."
            },
            {
              "padyam": [
                {
                  "title": "కరణము గరణము నమ్మిన"
                },
                {
                  "title": "మరణాంతకమౌను గాని మనలేడు సుమీ"
                },
                {
                  "title": "కరణము తన సరి కరణము"
                },
                {
                  "title": "మరి నమ్మక మర్మమీక మనవలె సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఒక లేఖకుడు మరో లేఖకుని నమ్మితే మరణంతో సమానమైన ఆపదను కొనితెచ్చుకున్నట్లే. అందుకే, లేఖకుడు తనతో సమానమైన మరో లేఖరిని విశ్వసింపక, తన గుట్టును చెప్పక జీవించాలి."
            },
            {
              "padyam": [
                {
                  "title": "కమలములు నీడఁ బాసినఁ"
                },
                {
                  "title": "గమలాప్తుని రశ్మి సోకి కమలినభంగిన్"
                },
                {
                  "title": "దమ దమ నెలవులు దప్పినఁ"
                },
                {
                  "title": "దమ మిత్రులు శతృలౌట తథ్యము సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "కమలములు పుట్టిల్లయిన నీటిని విడిచిపెట్టి మిత్రుడు అయిన సూర్యుని ఎండ తాకిన వెంటనే కమిలిపోతున్నాయి. అలాగే, మానవులు తమ నివాసాలను విడిచిపెడితే స్నేహితులే శతృలవుతారు."
            },
            {
              "padyam": [
                {
                  "title": "కప్పకు నొరగాలైనను"
                },
                {
                  "title": "సప్పమునకు రోగమైన సతి తులువైనన్"
                },
                {
                  "title": "ముప్పున దరిద్రుడైనను"
                },
                {
                  "title": "దప్పదు మరి దుఃఖమగుట తథ్యము సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "కప్పకు కాలు విరిగినా, పాముకు రోగం వచ్చినా, భార్య దుష్టురాలైనా, ముసలితనంలో దారిద్య్రం సంభవించినా ఎక్కువ దుఃఖప్రదాలు అవుతాయి."
            },
            {
              "padyam": [
                {
                  "title": "కనకపు సింహాసనము"
                },
                {
                  "title": "శునకముఁ గూర్చుండబెట్టి శుభలగ్నమునఁ"
                },
                {
                  "title": "దొనరఁగ బట్టముగట్టిన"
                },
                {
                  "title": "వెనకటి గుణమేల మాను వినరా సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "శుభ ముహూర్తంలో కుక్కను తీసుకొచ్చి బంగారు సంహాసనంపై కూర్చోబెట్టి పట్టాభిషేకం చేసినా, దాని నిజనైజాన్ని ఎలా మానలేదో అల్పునికి ఎంత గౌరవం చేసి మంచి పదవి ఇచ్చినా తన నీచత్వాన్ని వదలలేడు."
            },
            {
              "padyam": [
                {
                  "title": "కడు బలవంతుడైనను"
                },
                {
                  "title": "బుడమిని బ్రాయంపుటాలి బుట్టినయింటం"
                },
                {
                  "title": "దడవుండనిచ్చె నేనియు"
                },
                {
                  "title": "బడుపుగ నంగడికి దానె పంపుట సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఎంత సమర్థత కలవాడైనా యవ్వనంలో భార్యను చిరకాలం పుట్టింట ఉండనిచ్చినచో తానే స్వయంగా భార్యను వ్యభిచార వృత్తికి దింపినవాడగును."
            },
            {
              "padyam": [
                {
                  "title": "ఓడల బండ్లును వచ్చును"
                },
                {
                  "title": "ఓడలు నాబండ్ల మీద నొప్పుగ వచ్చును"
                },
                {
                  "title": "ఓడలు బండ్లును వలెనే"
                },
                {
                  "title": "వాడంబడు గలిమిలేమి వసుధను సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "నావలపై బళ్లు, బళ్లపై నావలు వచ్చునట్లే, భాగ్యవంతులకు దారిద్య్రం, దరిద్రులకు భాగ్యం పర్యాయంగా వస్తూంటాయి."
            },
            {
              "padyam": [
                {
                  "title": "ఒల్లనిసతి నొల్లనిపతి"
                },
                {
                  "title": "నొల్లని చెలికాని విడువ నొల్లనివాఁడే"
                },
                {
                  "title": "గొల్లండు గాక ధరలో"
                },
                {
                  "title": "గొల్లండును గొల్లడౌను గుణమున సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "తన్ను ప్రేమించని భర్యను, యజమానిని, స్నేహితుడ్ని విడిచి పెట్టడానికి అంగీకరించనివాడే వెర్రి గొల్లవాడు గానీ జాతిచేత గొల్లవాడైనంత మాత్రాన గుణాల్లో వెర్రి గొల్లవాడు కాదు."
            },
            {
              "padyam": [
                {
                  "title": "ఒకయూరికి నొక కరణము"
                },
                {
                  "title": "నొక తీర్పరియైనఁ గాక వొగిఁదరుచైనం"
                },
                {
                  "title": "గకవికలు గాక యుండునె"
                },
                {
                  "title": "సకలంబును గొట్టువడక సహజము సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఒక గ్రామానికి ఒక లేఖరి, ఒక ధర్మాధికారి ఉండాలి. అలాకాక ఎక్కువమంది అయితే అనేక గందరగోళాలు పుట్టి సమస్తం చెడిపోవుట సహజము."
            },
            {
              "padyam": [
                {
                  "title": "ఏరకుమీ కసుగాయలు"
                },
                {
                  "title": "దోరకుమీ బంధుజనుల దోషముసుమ్మీ"
                },
                {
                  "title": "పారకుమీ రణమందున"
                },
                {
                  "title": "మీరకుమీ గురువులాజ్ఞ మేదిని సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "భూమిపై పచ్చికాయలు ఏరి తినకు. చుట్టాలను దూషించకు. యుద్ధం నుంచి వెనుతిరిగి పారిపోకు. పెద్దల ఆజ్ఞలను జవదాటకు సుమా!"
            },
            {
              "padyam": [
                {
                  "title": "ఎప్పుడు సంపద కలిగిన"
                },
                {
                  "title": "అప్పుడె బంధువులు వత్తురది యెట్లన్నన్"
                },
                {
                  "title": "దెప్పలుగ జెరువునిండిన"
                },
                {
                  "title": "గప్పలు పదివేలు చేరు గదరా సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "చెరువు నిండా నీరు చేరగానే వేలకొద్దీ కప్పలు అందులో చేరునట్లే సంపద కలిగిన వారి వద్దకే బంధువులు ఎక్కువగా జేరుకొందురు."
            },
            {
              "padyam": [
                {
                  "title": "ఎప్పుడు దప్పులు వెదకెడు"
                },
                {
                  "title": "నప్పురుషుని కొల్వ గూడ దదియెట్లన్నన్"
                },
                {
                  "title": "సర్పంబు పడగనీడను"
                },
                {
                  "title": "గప్పవసించిన విధంబు గదరా సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "నల్లతాచు నీడలో నివశించే కప్ప బతుకు ఎంత అస్థిరమో ఆవిధంగానే ఎప్పుడూ తప్పులు వెతికే యజమానిని సేవిచే వాడి బతుకూ ప్రాణభయంతో కూడినదే సుమా!"
            },
            {
              "padyam": [
                {
                  "title": "ఉపకారికి నుపకారము"
                },
                {
                  "title": "విపరీతముగాదు సేయ వివరింపంగా"
                },
                {
                  "title": "అపకారికి నుపకారము"
                },
                {
                  "title": "నెపమెన్నక సేయువాడు నేర్పరి సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "మేలు చేసిన వానికి మేలు చేయుట గొప్ప కాదు. హాని చేసిన వానికి అంతకుముందు వాడు చేసిన దోషాలను లెక్కచేయక ఉపకారం చేసేవాడే నేర్పరి."
            },
            {
              "padyam": [
                {
                  "title": "ఉదకము ద్రావెడు హయమును"
                },
                {
                  "title": "మదమున నుప్పొంగుచుండు మత్తేభంబున్"
                },
                {
                  "title": "మొదవుకడ నున్న వృషభము"
                },
                {
                  "title": "జదువని యా నీచుకడకుఁ జనకుర సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "నీరు తాగే గుర్రం దగ్గరకు, కొవ్వుతో విజృంభించే మదపుటేనుగు దగ్గరకు, ఆవు దగ్గర ఉన్న ఆబోతు వద్దకు, విద్యనేర్వని అల్పుని దగ్గరకు వెళ్లకుము."
            },
            {
              "padyam": [
                {
                  "title": "ఉత్తమ గుణములు నీచు"
                },
                {
                  "title": "కెత్తెర గునగలుగనేర్చు నెయ్యడలం దా"
                },
                {
                  "title": "నెత్తిచ్చి కరగిపోసిన"
                },
                {
                  "title": "నిత్తడి బంగారుమగునె ఇలలో సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "బంగారానికి సమానమైన ఎత్తు ఇత్తడిని తీసుకొని ఎన్నిసార్లు కరిగించిపోసినా బంగారం ఎట్లు కానేరదో అదేవిధంగా లోకంలో నీచునకు ఎక్కడా ఏ విధంగానూ మంచి గుణాలు కలగవు."
            },
            {
              "padyam": [
                {
                  "title": "ఉడుముండదె నూరేండ్లును"
                },
                {
                  "title": "బడియుండదె పేర్మి బాము పదినూరేండ్లున్"
                },
                {
                  "title": "మడుపునఁ గొక్కెర యుండదె"
                },
                {
                  "title": "కడు నిల బురుషార్థపరుడు గావలె సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఉడుము నూరేళ్లు, పాము పది వందల ఏళ్లు, కొంగ చెరువులో చిరకాలం జీవిస్తున్నాయి. వాటి జీవితాలన్నీ నిరుపయోగాలే. మానవుని జీవితం అలా కాక ధర్మార్థకామమోక్షాసక్తితో కూడినది కావాలి."
            },
            {
              "padyam": [
                {
                  "title": "ఇమ్ముగఁ జదువని నోరును"
                },
                {
                  "title": "అమ్మాయని పిలిచి యన్నమడుగని నోరున్"
                },
                {
                  "title": "దమ్ములఁ బిలువని నోరును"
                },
                {
                  "title": "గుమ్మరిమను ద్రవ్వినట్టి గుంటర సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "ఇంపుగా పఠింపని నోరు, అమ్మా అని పిలిచి అన్నం అడగని నోరు, తమ్ముడూ అని పిలవని నోరు కుమ్మరివాడు మన్ను తవ్విన గోయితో సమానం సుమా!"
            },
            {
              "padyam": [
                {
                  "title": "ఆఁకొన్న కూడె యమృతము"
                },
                {
                  "title": "తాఁకొందక నిచ్చువాఁడె దాత ధరిత్రిన్"
                },
                {
                  "title": "సోఁకోర్చువాఁడె మనుజుఁడు"
                },
                {
                  "title": "తేఁకువగలవాఁడె వంశ తిలకుఁడు సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "లోకంలో ఆకలి వేసినప్పుడు అన్నమే అమృతము, బాధ పొందకుండా ఇచ్చువాడే దాత, ఆవేశాన్ని ఓర్చుకొనేవాడే మానవుడు, ధైర్యం కలవాడే వంశశ్రేష్ఠుడు."
            },
            {
              "padyam": [
                {
                  "title": "అల్లుని మంచితనంబును"
                },
                {
                  "title": "గొల్లని సాహిత్యవిద్య, కోమలి నిజమున్"
                },
                {
                  "title": "బొల్లున దంచిన బియ్యముఁ"
                },
                {
                  "title": "దెల్లని కాకులును లేవు తెలియర సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "అల్లుడి మంచితనం, గొల్లవాని పాండిత్యజ్ఞానం, ఆడదానియందు నిజం, పొల్లు ధాన్యములో బియ్యం, తెల్లని కాకులూ లోకములో ఉండవు."
            },
            {
              "padyam": [
                {
                  "title": "అప్పుగొని సేయు విభవము"
                },
                {
                  "title": "ముప్పునఁ బ్రాయంపుటాలు మూర్ఖుని తపమున్"
                },
                {
                  "title": "దప్పురయని నృపురాజ్యము"
                },
                {
                  "title": "దెప్పరమై మీఁదఁ గీడు దెచ్చుర సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "రుణము తెచ్చుకొని అనుభవించు సౌఖ్యము, ముసలితనంలో పడుచు భార్య, తప్పులను కనిపెట్టని రాజు రాజ్యము సహింపరానివి. చివరకు హాని కలిగించేవి."
            },
            {
              "padyam": [
                {
                  "title": "అడియాస కొలువుఁ గొలువకు"
                },
                {
                  "title": "గుడిమణియము సేయఁబోకు కుజనులతోడన్"
                },
                {
                  "title": "విడువక కూరిమి సేయకు"
                },
                {
                  "title": "మడవినిఁదో డరయఁకొంటి నరుగకు సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "వృథా ప్రయాస అగు సేవను చేయకుము. గుడి ధర్మకర్తృత్వమును చేయకుము. చెడ్డవారితో స్నేహము చేయకుము. అడవిలో సహాయం లేకుండా ఒంటరిగా పోకుము."
            },
            {
              "padyam": [
                {
                  "title": "అడిగిన జీతంబియ్యని"
                },
                {
                  "title": "మిడిమేలపు దొరనుగొల్చి మిడుకుటకంటెన్"
                },
                {
                  "title": "వడిగల యెద్దుల గట్టుక"
                },
                {
                  "title": "మడి దున్నుకు బ్రతుకవచ్చు మహిలో సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "అడిగినప్పుడు జీతమును ఈయని గర్వి అయిన ప్రభువును సేవించి జీవించుట కంటే, వేగముగా పోగల ఎద్దులను నాగలికి కట్టుకుని పొలమును దున్నుకొని వ్యవసాయం చేసుకోవడం మంచిది."
            },
            {
              "padyam": [
                {
                  "title": "అక్కరకు రాని చుట్టము"
                },
                {
                  "title": "మ్రొక్కిన వరమీని వేల్పు మోహరమునఁదా"
                },
                {
                  "title": "నెక్కినఁ బారని గుర్రము"
                },
                {
                  "title": "గ్రక్కున విడువంగ వలయుఁ గదరా సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "అవసరమునకు పనికిరాని చుట్టమును, నమస్కరించి వేడిననూ కోరిక నెరవేర్చని భగవంతుని, యుద్ధ సమయమున ఎక్కినప్పుడు ముందుకు పరిగెత్తని గుర్రమును వెంటనే విడిచిపెట్టవలయును."
            },
            {
              "padyam": [
                {
                  "title": "శ్రీరాముని దయచేతను"
                },
                {
                  "title": "నారూఢిగ సకలజనులు నౌరాఁయనగా"
                },
                {
                  "title": "ధారాళమైన నీతులు"
                },
                {
                  "title": "నోరూరఁగఁ జవులుపుట్ట నుడివెద సుమతీ!"
                }
              ],
              "bavam": "తాత్పర్యం",
              "bavmdesc": "మంచిబుద్ధి గలవాడా! శ్రీరాముని దయవల్ల నిశ్చయముగా అందరు జనులనూ శెభాషని అనునట్లుగా నోటి నుంచి నీళ్లూరునట్లు రసములు పుట్టగా న్యాయమును బోధించు నీతులను చెప్పెదను."
            }
          ],
          "title": "సుమతీ శతకము",
         //  "img": require("../imgs/NeethiPadyalu/Sumathi_Sathakam.png")
        }
      ]
    },

   //  "astkamulu": {
   //    "CatID": [
   //      {
   //        "sotram": [
   //          {
   //            "title": "ఓం శుక్లాంబరధరం విష్ణుం శశివర్ణం చతుర్భుజమ్ ।"
   //          },
   //          {
   //            "title": "ప్రసన్నవదనం ధ్యాయేత్ సర్వవిఘ్నోపశాంతయే ॥ 1 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "యస్యద్విరదవక్త్రాద్యాః పారిషద్యాః పరః శతమ్ ।"
   //          },
   //          {
   //            "title": "విఘ్నం నిఘ్నంతి సతతం విష్వక్సేనం తమాశ్రయే ॥ 2 ॥"
   //          },
   //          {
   //            "title": "@పూర్వ పీఠికా"
   //          },
   //          {
   //            "title": "వ్యాసం వసిష్ఠ నప్తారం శక్తేః పౌత్రమకల్మషమ్ ।"
   //          },
   //          {
   //            "title": "పరాశరాత్మజం వందే శుకతాతం తపోనిధిమ్ ॥ 3 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "వ్యాసాయ విష్ణు రూపాయ వ్యాసరూపాయ విష్ణవే ।"
   //          },
   //          {
   //            "title": "నమో వై బ్రహ్మనిధయే వాసిష్ఠాయ నమో నమః ॥ 4 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అవికారాయ శుద్ధాయ నిత్యాయ పరమాత్మనే ।"
   //          },
   //          {
   //            "title": "సదైక రూప రూపాయ విష్ణవే సర్వజిష్ణవే ॥ 5 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "యస్య స్మరణమాత్రేణ జన్మసంసారబంధనాత్ ।"
   //          },
   //          {
   //            "title": "విముచ్యతే నమస్తస్మై విష్ణవే ప్రభవిష్ణవే ॥ 6 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఓం నమో విష్ణవే ప్రభవిష్ణవే ।"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@శ్రీ వైశంపాయన ఉవాచ"
   //          },
   //          {
   //            "title": "శ్రుత్వా ధర్మా నశేషేణ పావనాని చ సర్వశః ।"
   //          },
   //          {
   //            "title": "యుధిష్ఠిరః శాంతనవం పునరేవాభ్య భాషత ॥ 7 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@యుధిష్ఠిర ఉవాచ"
   //          },
   //          {
   //            "title": "కిమేకం దైవతం లోకే కిం వాఽప్యేకం పరాయణం"
   //          },
   //          {
   //            "title": "స్తువంతః కం కమర్చంతః ప్రాప్నుయుర్మానవాః శుభమ్ ॥ 8 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "కో ధర్మః సర్వధర్మాణాం భవతః పరమో మతః ।"
   //          },
   //          {
   //            "title": "కిం జపన్ముచ్యతే జంతుర్జన్మసంసార బంధనాత్ ॥ 9 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@శ్రీ భీష్మ ఉవాచ"
   //          },
   //          {
   //            "title": "జగత్ప్రభుం దేవదేవ మనంతం పురుషోత్తమమ్ ।"
   //          },
   //          {
   //            "title": "స్తువన్నామ సహస్రేణ పురుషః సతతోత్థితః ॥ 10 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "తమేవ చార్చయన్నిత్యం భక్త్యా పురుషమవ్యయమ్ ।"
   //          },
   //          {
   //            "title": "ధ్యాయన్ స్తువన్నమస్యంశ్చ యజమానస్తమేవ చ ॥ 11 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అనాది నిధనం విష్ణుం సర్వలోక మహేశ్వరమ్ ।"
   //          },
   //          {
   //            "title": "లోకాధ్యక్షం స్తువన్నిత్యం సర్వ దుఃఖాతిగో భవేత్ ॥ 12 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "బ్రహ్మణ్యం సర్వ ధర్మజ్ఞం లోకానాం కీర్తి వర్ధనమ్ ।"
   //          },
   //          {
   //            "title": "లోకనాథం మహద్భూతం సర్వభూత భవోద్భవం॥ 13 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఏష మే సర్వ ధర్మాణాం ధర్మోఽధిక తమోమతః ।"
   //          },
   //          {
   //            "title": "యద్భక్త్యా పుండరీకాక్షం స్తవైరర్చేన్నరః సదా ॥ 14 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "పరమం యో మహత్తేజః పరమం యో మహత్తపః ।"
   //          },
   //          {
   //            "title": "పరమం యో మహద్బ్రహ్మ పరమం యః పరాయణమ్ । 15 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "పవిత్రాణాం పవిత్రం యో మంగళానాం చ మంగళమ్ ।"
   //          },
   //          {
   //            "title": "దైవతం దేవతానాం చ భూతానాం యోఽవ్యయః పితా ॥ 16 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "యతః సర్వాణి భూతాని భవంత్యాది యుగాగమే ।"
   //          },
   //          {
   //            "title": "యస్మింశ్చ ప్రలయం యాంతి పునరేవ యుగక్షయే ॥ 17 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "తస్య లోక ప్రధానస్య జగన్నాథస్య భూపతే ।"
   //          },
   //          {
   //            "title": "విష్ణోర్నామ సహస్రం మే శ్రుణు పాప భయాపహమ్ ॥ 18 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "యాని నామాని గౌణాని విఖ్యాతాని మహాత్మనః ।"
   //          },
   //          {
   //            "title": "ఋషిభిః పరిగీతాని తాని వక్ష్యామి భూతయే ॥ 19 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఋషిర్నామ్నాం సహస్రస్య వేదవ్యాసో మహామునిః ॥"
   //          },
   //          {
   //            "title": "ఛందోఽనుష్టుప్ తథా దేవో భగవాన్ దేవకీసుతః ॥ 20 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అమృతాం శూద్భవో బీజం శక్తిర్దేవకినందనః ।"
   //          },
   //          {
   //            "title": "త్రిసామా హృదయం తస్య శాంత్యర్థే వినియుజ్యతే ॥ 21 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "విష్ణుం జిష్ణుం మహావిష్ణుం ప్రభవిష్ణుం మహేశ్వరమ్ ॥"
   //          },
   //          {
   //            "title": "అనేకరూప దైత్యాంతం నమామి పురుషోత్తమమ్ ॥ 22 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@పూర్వన్యాసః"
   //          },
   //          {
   //            "title": "అస్య శ్రీ విష్ణోర్దివ్య సహస్రనామ స్తోత్ర మహామంత్రస్య ॥"
   //          },
   //          {
   //            "title": "శ్రీ వేదవ్యాసో భగవాన్ ఋషిః ।"
   //          },
   //          {
   //            "title": "అనుష్టుప్ ఛందః ।"
   //          },
   //          {
   //            "title": "శ్రీమహావిష్ణుః పరమాత్మా శ్రీమన్నారాయణో దేవతా ।"
   //          },
   //          {
   //            "title": "అమృతాంశూద్భవో భానురితి బీజమ్ ।"
   //          },
   //          {
   //            "title": "దేవకీనందనః స్రష్టేతి శక్తిః ।"
   //          },
   //          {
   //            "title": "ఉద్భవః, క్షోభణో దేవ ఇతి పరమోమంత్రః ।"
   //          },
   //          {
   //            "title": "శంఖభృన్నందకీ చక్రీతి కీలకమ్ ।"
   //          },
   //          {
   //            "title": "శారంగధన్వా గదాధర ఇత్యస్త్రమ్ ।"
   //          },
   //          {
   //            "title": "రథాంగపాణి రక్షోభ్య ఇతి నేత్రమ్ ।"
   //          },
   //          {
   //            "title": "త్రిసామాసామగః సామేతి కవచమ్ ।"
   //          },
   //          {
   //            "title": "ఆనందం పరబ్రహ్మేతి యోనిః ।"
   //          },
   //          {
   //            "title": "ఋతుస్సుదర్శనః కాల ఇతి దిగ్బంధః ॥"
   //          },
   //          {
   //            "title": "శ్రీవిశ్వరూప ఇతి ధ్యానమ్ ।"
   //          },
   //          {
   //            "title": "శ్రీ మహావిష్ణు ప్రీత్యర్థే సహస్రనామ జపే పారాయణే వినియోగః ।"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@కరన్యాసః"
   //          },
   //          {
   //            "title": "విశ్వం విష్ణుర్వషట్కార ఇత్యంగుష్ఠాభ్యాం నమః"
   //          },
   //          {
   //            "title": "అమృతాం శూద్భవో భానురితి తర్జనీభ్యాం నమః"
   //          },
   //          {
   //            "title": "బ్రహ్మణ్యో బ్రహ్మకృత్ బ్రహ్మేతి మధ్యమాభ్యాం నమః"
   //          },
   //          {
   //            "title": "సువర్ణబిందు రక్షోభ్య ఇతి అనామికాభ్యాం నమః"
   //          },
   //          {
   //            "title": "నిమిషోఽనిమిషః స్రగ్వీతి కనిష్ఠికాభ్యాం నమః"
   //          },
   //          {
   //            "title": "రథాంగపాణి రక్షోభ్య ఇతి కరతల కరపృష్ఠాభ్యాం నమః"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@అంగన్యాసః"
   //          },
   //          {
   //            "title": "సువ్రతః సుముఖః సూక్ష్మ ఇతి జ్ఞానాయ హృదయాయ నమః"
   //          },
   //          {
   //            "title": "సహస్రమూర్తిః విశ్వాత్మా ఇతి ఐశ్వర్యాయ శిరసే స్వాహా"
   //          },
   //          {
   //            "title": "సహస్రార్చిః సప్తజిహ్వ ఇతి శక్త్యై శిఖాయై వషట్"
   //          },
   //          {
   //            "title": "త్రిసామా సామగస్సామేతి బలాయ కవచాయ హుం"
   //          },
   //          {
   //            "title": "రథాంగపాణి రక్షోభ్య ఇతి నేత్రాభ్యాం వౌషట్"
   //          },
   //          {
   //            "title": "శాంగధన్వా గదాధర ఇతి వీర్యాయ అస్త్రాయఫట్"
   //          },
   //          {
   //            "title": "ఋతుః సుదర్శనః కాల ఇతి దిగ్భంధః"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ధ్యానం"
   //          },
   //          {
   //            "title": "క్షీరోధన్వత్ప్రదేశే శుచిమణివిలసత్సైకతేమౌక్తికానాం"
   //          },
   //          {
   //            "title": "మాలాక్లుప్తాసనస్థః స్ఫటికమణినిభైర్మౌక్తికైర్మండితాంగః ।"
   //          },
   //          {
   //            "title": "శుభ్రైరభ్రైరదభ్రైరుపరివిరచితైర్ముక్తపీయూష వర్షైః"
   //          },
   //          {
   //            "title": "ఆనందీ నః పునీయాదరినలినగదా శంఖపాణిర్ముకుందః ॥ 1 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "భూః పాదౌ యస్య నాభిర్వియదసురనిలశ్చంద్ర సూర్యౌ చ నేత్రే"
   //          },
   //          {
   //            "title": "కర్ణావాశాః శిరోద్యౌర్ముఖమపి దహనో యస్య వాస్తేయమబ్ధిః ।"
   //          },
   //          {
   //            "title": "అంతఃస్థం యస్య విశ్వం సుర నరఖగగోభోగిగంధర్వదైత్యైః"
   //          },
   //          {
   //            "title": "చిత్రం రం రమ్యతే తం త్రిభువన వపుశం విష్ణుమీశం నమామి ॥ 2 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఓం నమో భగవతే వాసుదేవాయ !"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "శాంతాకారం భుజగశయనం పద్మనాభం సురేశం"
   //          },
   //          {
   //            "title": "విశ్వాధారం గగనసదృశం మేఘవర్ణం శుభాంగమ్ ।"
   //          },
   //          {
   //            "title": "లక్ష్మీకాంతం కమలనయనం యోగిహృర్ధ్యానగమ్యం"
   //          },
   //          {
   //            "title": "వందే విష్ణుం భవభయహరం సర్వలోకైకనాథమ్ ॥ 3 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "మేఘశ్యామం పీతకౌశేయవాసం"
   //          },
   //          {
   //            "title": "శ్రీవత్సాకం కౌస్తుభోద్భాసితాంగమ్ ।"
   //          },
   //          {
   //            "title": "పుణ్యోపేతం పుండరీకాయతాక్షం"
   //          },
   //          {
   //            "title": "విష్ణుం వందే సర్వలోకైకనాథమ్ ॥ 4 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "నమః సమస్త భూతానాం ఆది భూతాయ భూభృతే ।"
   //          },
   //          {
   //            "title": "అనేకరూప రూపాయ విష్ణవే ప్రభవిష్ణవే ॥ 5॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సశంఖచక్రం సకిరీటకుండలం"
   //          },
   //          {
   //            "title": "సపీతవస్త్రం సరసీరుహేక్షణమ్ ।"
   //          },
   //          {
   //            "title": "సహార వక్షఃస్థల శోభి కౌస్తుభం"
   //          },
   //          {
   //            "title": "నమామి విష్ణుం శిరసా చతుర్భుజమ్ । 6॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఛాయాయాం పారిజాతస్య హేమసింహాసనోపరి"
   //          },
   //          {
   //            "title": "ఆసీనమంబుదశ్యామమాయతాక్షమలంకృతమ్ ॥ 7 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "చంద్రాననం చతుర్బాహుం శ్రీవత్సాంకిత వక్షసం"
   //          },
   //          {
   //            "title": "రుక్మిణీ సత్యభామాభ్యాం సహితం కృష్ణమాశ్రయే ॥ 8 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@పంచపూజ"
   //          },
   //          {
   //            "title": "లం - పృథివ్యాత్మనే గంథం సమర్పయామి"
   //          },
   //          {
   //            "title": "హం - ఆకాశాత్మనే పుష్పైః పూజయామి"
   //          },
   //          {
   //            "title": "యం - వాయ్వాత్మనే ధూపమాఘ్రాపయామి"
   //          },
   //          {
   //            "title": "రం - అగ్న్యాత్మనే దీపం దర్శయామి"
   //          },
   //          {
   //            "title": "వం - అమృతాత్మనే నైవేద్యం నివేదయామి"
   //          },
   //          {
   //            "title": "సం - సర్వాత్మనే సర్వోపచార పూజా నమస్కారాన్ సమర్పయామి"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "స్తోత్రం"
   //          },
   //          {
   //            "title": "హరిః ఓం"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "విశ్వం విష్ణుర్వషట్కారో భూతభవ్యభవత్ప్రభుః ।"
   //          },
   //          {
   //            "title": "భూతకృద్భూతభృద్భావో భూతాత్మా భూతభావనః ॥ 1 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "పూతాత్మా పరమాత్మా చ ముక్తానాం పరమాగతిః ।"
   //          },
   //          {
   //            "title": "అవ్యయః పురుషః సాక్షీ క్షేత్రజ్ఞోఽక్షర ఏవ చ ॥ 2 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "యోగో యోగవిదాం నేతా ప్రధాన పురుషేశ్వరః ।"
   //          },
   //          {
   //            "title": "నారసింహవపుః శ్రీమాన్ కేశవః పురుషోత్తమః ॥ 3 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సర్వః శర్వః శివః స్థాణుర్భూతాదిర్నిధిరవ్యయః ।"
   //          },
   //          {
   //            "title": "సంభవో భావనో భర్తా ప్రభవః ప్రభురీశ్వరః ॥ 4 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "స్వయంభూః శంభురాదిత్యః పుష్కరాక్షో మహాస్వనః ।"
   //          },
   //          {
   //            "title": "అనాదినిధనో ధాతా విధాతా ధాతురుత్తమః ॥ 5 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అప్రమేయో హృషీకేశః పద్మనాభోఽమరప్రభుః ।"
   //          },
   //          {
   //            "title": "విశ్వకర్మా మనుస్త్వష్టా స్థవిష్ఠః స్థవిరో ధ్రువః ॥ 6 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అగ్రాహ్యః శాశ్వతో కృష్ణో లోహితాక్షః ప్రతర్దనః ।"
   //          },
   //          {
   //            "title": "ప్రభూతస్త్రికకుబ్ధామ పవిత్రం మంగళం పరమ్ ॥ 7 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఈశానః ప్రాణదః ప్రాణో జ్యేష్ఠః శ్రేష్ఠః ప్రజాపతిః ।"
   //          },
   //          {
   //            "title": "హిరణ్యగర్భో భూగర్భో మాధవో మధుసూదనః ॥ 8 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఈశ్వరో విక్రమీధన్వీ మేధావీ విక్రమః క్రమః ।"
   //          },
   //          {
   //            "title": "అనుత్తమో దురాధర్షః కృతజ్ఞః కృతిరాత్మవాన్॥ 9 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సురేశః శరణం శర్మ విశ్వరేతాః ప్రజాభవః ।"
   //          },
   //          {
   //            "title": "అహస్సంవత్సరో వ్యాళః ప్రత్యయః సర్వదర్శనః ॥ 10 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అజస్సర్వేశ్వరః సిద్ధః సిద్ధిః సర్వాదిరచ్యుతః ।"
   //          },
   //          {
   //            "title": "వృషాకపిరమేయాత్మా సర్వయోగవినిస్సృతః ॥ 11 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "వసుర్వసుమనాః సత్యః సమాత్మా సమ్మితస్సమః ।"
   //          },
   //          {
   //            "title": "అమోఘః పుండరీకాక్షో వృషకర్మా వృషాకృతిః ॥ 12 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "రుద్రో బహుశిరా బభ్రుర్విశ్వయోనిః శుచిశ్రవాః ।"
   //          },
   //          {
   //            "title": "అమృతః శాశ్వతస్థాణుర్వరారోహో మహాతపాః ॥ 13 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సర్వగః సర్వ విద్భానుర్విష్వక్సేనో జనార్దనః ।"
   //          },
   //          {
   //            "title": "వేదో వేదవిదవ్యంగో వేదాంగో వేదవిత్కవిః ॥ 14 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "లోకాధ్యక్షః సురాధ్యక్షో ధర్మాధ్యక్షః కృతాకృతః ।"
   //          },
   //          {
   //            "title": "చతురాత్మా చతుర్వ్యూహశ్చతుర్దంష్ట్రశ్చతుర్భుజః ॥ 15 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "భ్రాజిష్ణుర్భోజనం భోక్తా సహిష్నుర్జగదాదిజః ।"
   //          },
   //          {
   //            "title": "అనఘో విజయో జేతా విశ్వయోనిః పునర్వసుః ॥ 16 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఉపేంద్రో వామనః ప్రాంశురమోఘః శుచిరూర్జితః ।"
   //          },
   //          {
   //            "title": "అతీంద్రః సంగ్రహః సర్గో ధృతాత్మా నియమో యమః ॥ 17 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "వేద్యో వైద్యః సదాయోగీ వీరహా మాధవో మధుః ।"
   //          },
   //          {
   //            "title": "అతీంద్రియో మహామాయో మహోత్సాహో మహాబలః ॥ 18 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "మహాబుద్ధిర్మహావీర్యో మహాశక్తిర్మహాద్యుతిః ।"
   //          },
   //          {
   //            "title": "అనిర్దేశ్యవపుః శ్రీమానమేయాత్మా మహాద్రిధృక్ ॥ 19 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "మహేశ్వాసో మహీభర్తా శ్రీనివాసః సతాంగతిః ।"
   //          },
   //          {
   //            "title": "అనిరుద్ధః సురానందో గోవిందో గోవిదాం పతిః ॥ 20 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "మరీచిర్దమనో హంసః సుపర్ణో భుజగోత్తమః ।"
   //          },
   //          {
   //            "title": "హిరణ్యనాభః సుతపాః పద్మనాభః ప్రజాపతిః ॥ 21 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అమృత్యుః సర్వదృక్ సింహః సంధాతా సంధిమాన్ స్థిరః ।"
   //          },
   //          {
   //            "title": "అజో దుర్మర్షణః శాస్తా విశ్రుతాత్మా సురారిహా ॥ 22 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "గురుర్గురుతమో ధామ సత్యః సత్యపరాక్రమః ।"
   //          },
   //          {
   //            "title": "నిమిషోఽనిమిషః స్రగ్వీ వాచస్పతిరుదారధీః ॥ 23 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అగ్రణీగ్రామణీః శ్రీమాన్ న్యాయో నేతా సమీరణః"
   //          },
   //          {
   //            "title": "సహస్రమూర్ధా విశ్వాత్మా సహస్రాక్షః సహస్రపాత్ ॥ 24 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఆవర్తనో నివృత్తాత్మా సంవృతః సంప్రమర్దనః ।"
   //          },
   //          {
   //            "title": "అహః సంవర్తకో వహ్నిరనిలో ధరణీధరః ॥ 25 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సుప్రసాదః ప్రసన్నాత్మా విశ్వధృగ్విశ్వభుగ్విభుః ।"
   //          },
   //          {
   //            "title": "సత్కర్తా సత్కృతః సాధుర్జహ్నుర్నారాయణో నరః ॥ 26 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అసంఖ్యేయోఽప్రమేయాత్మా విశిష్టః శిష్టకృచ్ఛుచిః ।"
   //          },
   //          {
   //            "title": "సిద్ధార్థః సిద్ధసంకల్పః సిద్ధిదః సిద్ధి సాధనః ॥ 27 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "వృషాహీ వృషభో విష్ణుర్వృషపర్వా వృషోదరః ।"
   //          },
   //          {
   //            "title": "వర్ధనో వర్ధమానశ్చ వివిక్తః శ్రుతిసాగరః ॥ 28 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సుభుజో దుర్ధరో వాగ్మీ మహేంద్రో వసుదో వసుః ।"
   //          },
   //          {
   //            "title": "నైకరూపో బృహద్రూపః శిపివిష్టః ప్రకాశనః ॥ 29 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఓజస్తేజోద్యుతిధరః ప్రకాశాత్మా ప్రతాపనః ।"
   //          },
   //          {
   //            "title": "ఋద్దః స్పష్టాక్షరో మంత్రశ్చంద్రాంశుర్భాస్కరద్యుతిః ॥ 30 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అమృతాంశూద్భవో భానుః శశబిందుః సురేశ్వరః ।"
   //          },
   //          {
   //            "title": "ఔషధం జగతః సేతుః సత్యధర్మపరాక్రమః ॥ 31 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "భూతభవ్యభవన్నాథః పవనః పావనోఽనలః ।"
   //          },
   //          {
   //            "title": "కామహా కామకృత్కాంతః కామః కామప్రదః ప్రభుః ॥ 32 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "యుగాది కృద్యుగావర్తో నైకమాయో మహాశనః ।"
   //          },
   //          {
   //            "title": "అదృశ్యో వ్యక్తరూపశ్చ సహస్రజిదనంతజిత్ ॥ 33 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఇష్టోఽవిశిష్టః శిష్టేష్టః శిఖండీ నహుషో వృషః ।"
   //          },
   //          {
   //            "title": "క్రోధహా క్రోధకృత్కర్తా విశ్వబాహుర్మహీధరః ॥ 34 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అచ్యుతః ప్రథితః ప్రాణః ప్రాణదో వాసవానుజః ।"
   //          },
   //          {
   //            "title": "అపాంనిధిరధిష్ఠానమప్రమత్తః ప్రతిష్ఠితః ॥ 35 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "స్కందః స్కందధరో ధుర్యో వరదో వాయువాహనః ।"
   //          },
   //          {
   //            "title": "వాసుదేవో బృహద్భానురాదిదేవః పురంధరః ॥ 36 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అశోకస్తారణస్తారః శూరః శౌరిర్జనేశ్వరః ।"
   //          },
   //          {
   //            "title": "అనుకూలః శతావర్తః పద్మీ పద్మనిభేక్షణః ॥ 37 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "పద్మనాభోఽరవిందాక్షః పద్మగర్భః శరీరభృత్ ।"
   //          },
   //          {
   //            "title": "మహర్ధిరృద్ధో వృద్ధాత్మా మహాక్షో గరుడధ్వజః ॥ 38 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అతులః శరభో భీమః సమయజ్ఞో హవిర్హరిః ।"
   //          },
   //          {
   //            "title": "సర్వలక్షణలక్షణ్యో లక్ష్మీవాన్ సమితింజయః ॥ 39 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "విక్షరో రోహితో మార్గో హేతుర్దామోదరః సహః ।"
   //          },
   //          {
   //            "title": "మహీధరో మహాభాగో వేగవానమితాశనః ॥ 40 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఉద్భవః, క్షోభణో దేవః శ్రీగర్భః పరమేశ్వరః ।"
   //          },
   //          {
   //            "title": "కరణం కారణం కర్తా వికర్తా గహనో గుహః ॥ 41 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "వ్యవసాయో వ్యవస్థానః సంస్థానః స్థానదో ధ్రువః ।"
   //          },
   //          {
   //            "title": "పరర్ధిః పరమస్పష్టః తుష్టః పుష్టః శుభేక్షణః ॥ 42 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "రామో విరామో విరజో మార్గోనేయో నయోఽనయః ।"
   //          },
   //          {
   //            "title": "వీరః శక్తిమతాం శ్రేష్ఠో ధర్మోధర్మ విదుత్తమః ॥ 43 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "వైకుంఠః పురుషః ప్రాణః ప్రాణదః ప్రణవః పృథుః ।"
   //          },
   //          {
   //            "title": "హిరణ్యగర్భః శత్రుఘ్నో వ్యాప్తో వాయురధోక్షజః ॥ 44 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఋతుః సుదర్శనః కాలః పరమేష్ఠీ పరిగ్రహః ।"
   //          },
   //          {
   //            "title": "ఉగ్రః సంవత్సరో దక్షో విశ్రామో విశ్వదక్షిణః ॥ 45 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "విస్తారః స్థావర స్థాణుః ప్రమాణం బీజమవ్యయమ్ ।"
   //          },
   //          {
   //            "title": "అర్థోఽనర్థో మహాకోశో మహాభోగో మహాధనః ॥ 46 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అనిర్విణ్ణః స్థవిష్ఠో భూద్ధర్మయూపో మహామఖః ।"
   //          },
   //          {
   //            "title": "నక్షత్రనేమిర్నక్షత్రీ క్షమః, క్షామః సమీహనః ॥ 47 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "యజ్ఞ ఇజ్యో మహేజ్యశ్చ క్రతుః సత్రం సతాంగతిః ।"
   //          },
   //          {
   //            "title": "సర్వదర్శీ విముక్తాత్మా సర్వజ్ఞో జ్ఞానముత్తమమ్ ॥ 48 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సువ్రతః సుముఖః సూక్ష్మః సుఘోషః సుఖదః సుహృత్ ।"
   //          },
   //          {
   //            "title": "మనోహరో జితక్రోధో వీర బాహుర్విదారణః ॥ 49 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "స్వాపనః స్వవశో వ్యాపీ నైకాత్మా నైకకర్మకృత్। ।"
   //          },
   //          {
   //            "title": "వత్సరో వత్సలో వత్సీ రత్నగర్భో ధనేశ్వరః ॥ 50 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ధర్మగుబ్ధర్మకృద్ధర్మీ సదసత్క్షరమక్షరం॥"
   //          },
   //          {
   //            "title": "అవిజ్ఞాతా సహస్త్రాంశుర్విధాతా కృతలక్షణః ॥ 51 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "గభస్తినేమిః సత్త్వస్థః సింహో భూత మహేశ్వరః ।"
   //          },
   //          {
   //            "title": "ఆదిదేవో మహాదేవో దేవేశో దేవభృద్గురుః ॥ 52 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఉత్తరో గోపతిర్గోప్తా జ్ఞానగమ్యః పురాతనః ।"
   //          },
   //          {
   //            "title": "శరీర భూతభృద్ భోక్తా కపీంద్రో భూరిదక్షిణః ॥ 53 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సోమపోఽమృతపః సోమః పురుజిత్ పురుసత్తమః ।"
   //          },
   //          {
   //            "title": "వినయో జయః సత్యసంధో దాశార్హః సాత్వతాం పతిః ॥ 54 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "జీవో వినయితా సాక్షీ ముకుందోఽమిత విక్రమః ।"
   //          },
   //          {
   //            "title": "అంభోనిధిరనంతాత్మా మహోదధి శయోంతకః ॥ 55 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అజో మహార్హః స్వాభావ్యో జితామిత్రః ప్రమోదనః ।"
   //          },
   //          {
   //            "title": "ఆనందోఽనందనోనందః సత్యధర్మా త్రివిక్రమః ॥ 56 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "మహర్షిః కపిలాచార్యః కృతజ్ఞో మేదినీపతిః ।"
   //          },
   //          {
   //            "title": "త్రిపదస్త్రిదశాధ్యక్షో మహాశృంగః కృతాంతకృత్ ॥ 57 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "మహావరాహో గోవిందః సుషేణః కనకాంగదీ ।"
   //          },
   //          {
   //            "title": "గుహ్యో గభీరో గహనో గుప్తశ్చక్ర గదాధరః ॥ 58 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "వేధాః స్వాంగోఽజితః కృష్ణో దృఢః సంకర్షణోఽచ్యుతః ।"
   //          },
   //          {
   //            "title": "వరుణో వారుణో వృక్షః పుష్కరాక్షో మహామనాః ॥ 59 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "భగవాన్ భగహాఽఽనందీ వనమాలీ హలాయుధః ।"
   //          },
   //          {
   //            "title": "ఆదిత్యో జ్యోతిరాదిత్యః సహిష్ణుర్గతిసత్తమః ॥ 60 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సుధన్వా ఖండపరశుర్దారుణో ద్రవిణప్రదః ।"
   //          },
   //          {
   //            "title": "దివఃస్పృక్ సర్వదృగ్వ్యాసో వాచస్పతిరయోనిజః ॥ 61 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "త్రిసామా సామగః సామ నిర్వాణం భేషజం భిషక్ ।"
   //          },
   //          {
   //            "title": "సన్యాసకృచ్ఛమః శాంతో నిష్ఠా శాంతిః పరాయణం। 62 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "శుభాంగః శాంతిదః స్రష్టా కుముదః కువలేశయః ।"
   //          },
   //          {
   //            "title": "గోహితో గోపతిర్గోప్తా వృషభాక్షో వృషప్రియః ॥ 63 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అనివర్తీ నివృత్తాత్మా సంక్షేప్తా క్షేమకృచ్ఛివః ।"
   //          },
   //          {
   //            "title": "శ్రీవత్సవక్షాః శ్రీవాసః శ్రీపతిః శ్రీమతాంవరః ॥ 64 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "శ్రీదః శ్రీశః శ్రీనివాసః శ్రీనిధిః శ్రీవిభావనః ।"
   //          },
   //          {
   //            "title": "శ్రీధరః శ్రీకరః శ్రేయః శ్రీమా~ంల్లోకత్రయాశ్రయః ॥ 65 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "స్వక్షః స్వంగః శతానందో నందిర్జ్యోతిర్గణేశ్వరః ।"
   //          },
   //          {
   //            "title": "విజితాత్మాఽవిధేయాత్మా సత్కీర్తిచ్ఛిన్నసంశయః ॥ 66 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఉదీర్ణః సర్వతశ్చక్షురనీశః శాశ్వతస్థిరః ।"
   //          },
   //          {
   //            "title": "భూశయో భూషణో భూతిర్విశోకః శోకనాశనః ॥ 67 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అర్చిష్మానర్చితః కుంభో విశుద్ధాత్మా విశోధనః ।"
   //          },
   //          {
   //            "title": "అనిరుద్ధోఽప్రతిరథః ప్రద్యుమ్నోఽమితవిక్రమః ॥ 68 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "కాలనేమినిహా వీరః శౌరిః శూరజనేశ్వరః ।"
   //          },
   //          {
   //            "title": "త్రిలోకాత్మా త్రిలోకేశః కేశవః కేశిహా హరిః ॥ 69 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "కామదేవః కామపాలః కామీ కాంతః కృతాగమః ।"
   //          },
   //          {
   //            "title": "అనిర్దేశ్యవపుర్విష్ణుర్వీరోఽనంతో ధనంజయః ॥ 70 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "బ్రహ్మణ్యో బ్రహ్మకృద్ బ్రహ్మా బ్రహ్మ బ్రహ్మవివర్ధనః ।"
   //          },
   //          {
   //            "title": "బ్రహ్మవిద్ బ్రాహ్మణో బ్రహ్మీ బ్రహ్మజ్ఞో బ్రాహ్మణప్రియః ॥ 71 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "మహాక్రమో మహాకర్మా మహాతేజా మహోరగః ।"
   //          },
   //          {
   //            "title": "మహాక్రతుర్మహాయజ్వా మహాయజ్ఞో మహాహవిః ॥ 72 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "స్తవ్యః స్తవప్రియః స్తోత్రం స్తుతిః స్తోతా రణప్రియః ।"
   //          },
   //          {
   //            "title": "పూర్ణః పూరయితా పుణ్యః పుణ్యకీర్తిరనామయః ॥ 73 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "మనోజవస్తీర్థకరో వసురేతా వసుప్రదః ।"
   //          },
   //          {
   //            "title": "వసుప్రదో వాసుదేవో వసుర్వసుమనా హవిః ॥ 74 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సద్గతిః సత్కృతిః సత్తా సద్భూతిః సత్పరాయణః ।"
   //          },
   //          {
   //            "title": "శూరసేనో యదుశ్రేష్ఠః సన్నివాసః సుయామునః ॥ 75 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "భూతావాసో వాసుదేవః సర్వాసునిలయోఽనలః ।"
   //          },
   //          {
   //            "title": "దర్పహా దర్పదో దృప్తో దుర్ధరోఽథాపరాజితః ॥ 76 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "విశ్వమూర్తిర్మహామూర్తిర్దీప్తమూర్తిరమూర్తిమాన్ ।"
   //          },
   //          {
   //            "title": "అనేకమూర్తిరవ్యక్తః శతమూర్తిః శతాననః ॥ 77 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఏకో నైకః సవః కః కిం యత్తత్ పదమనుత్తమమ్ ।"
   //          },
   //          {
   //            "title": "లోకబంధుర్లోకనాథో మాధవో భక్తవత్సలః ॥ 78 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సువర్ణవర్ణో హేమాంగో వరాంగశ్చందనాంగదీ ।"
   //          },
   //          {
   //            "title": "వీరహా విషమః శూన్యో ఘృతాశీరచలశ్చలః ॥ 79 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అమానీ మానదో మాన్యో లోకస్వామీ త్రిలోకధృక్ ।"
   //          },
   //          {
   //            "title": "సుమేధా మేధజో ధన్యః సత్యమేధా ధరాధరః ॥ 80 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "తేజోఽవృషో ద్యుతిధరః సర్వశస్త్రభృతాంవరః ।"
   //          },
   //          {
   //            "title": "ప్రగ్రహో నిగ్రహో వ్యగ్రో నైకశృంగో గదాగ్రజః ॥ 81 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "చతుర్మూర్తి శ్చతుర్బాహు శ్చతుర్వ్యూహ శ్చతుర్గతిః ।"
   //          },
   //          {
   //            "title": "చతురాత్మా చతుర్భావశ్చతుర్వేదవిదేకపాత్ ॥ 82 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సమావర్తోఽనివృత్తాత్మా దుర్జయో దురతిక్రమః ।"
   //          },
   //          {
   //            "title": "దుర్లభో దుర్గమో దుర్గో దురావాసో దురారిహా ॥ 83 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "శుభాంగో లోకసారంగః సుతంతుస్తంతువర్ధనః ।"
   //          },
   //          {
   //            "title": "ఇంద్రకర్మా మహాకర్మా కృతకర్మా కృతాగమః ॥ 84 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఉద్భవః సుందరః సుందో రత్ననాభః సులోచనః ।"
   //          },
   //          {
   //            "title": "అర్కో వాజసనః శృంగీ జయంతః సర్వవిజ్జయీ ॥ 85 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సువర్ణబిందురక్షోభ్యః సర్వవాగీశ్వరేశ్వరః ।"
   //          },
   //          {
   //            "title": "మహాహృదో మహాగర్తో మహాభూతో మహానిధిః ॥ 86 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "కుముదః కుందరః కుందః పర్జన్యః పావనోఽనిలః ।"
   //          },
   //          {
   //            "title": "అమృతాశోఽమృతవపుః సర్వజ్ఞః సర్వతోముఖః ॥ 87 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సులభః సువ్రతః సిద్ధః శత్రుజిచ్ఛత్రుతాపనః ।"
   //          },
   //          {
   //            "title": "న్యగ్రోధోఽదుంబరోఽశ్వత్థశ్చాణూరాంధ్ర నిషూదనః ॥ 88 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సహస్రార్చిః సప్తజిహ్వః సప్తైధాః సప్తవాహనః ।"
   //          },
   //          {
   //            "title": "అమూర్తిరనఘోఽచింత్యో భయకృద్భయనాశనః ॥ 89 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అణుర్బృహత్కృశః స్థూలో గుణభృన్నిర్గుణో మహాన్ ।"
   //          },
   //          {
   //            "title": "అధృతః స్వధృతః స్వాస్యః ప్రాగ్వంశో వంశవర్ధనః ॥ 90 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "భారభృత్ కథితో యోగీ యోగీశః సర్వకామదః ।"
   //          },
   //          {
   //            "title": "ఆశ్రమః శ్రమణః, క్షామః సుపర్ణో వాయువాహనః ॥ 91 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ధనుర్ధరో ధనుర్వేదో దండో దమయితా దమః ।"
   //          },
   //          {
   //            "title": "అపరాజితః సర్వసహో నియంతాఽనియమోఽయమః ॥ 92 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సత్త్వవాన్ సాత్త్వికః సత్యః సత్యధర్మపరాయణః ।"
   //          },
   //          {
   //            "title": "అభిప్రాయః ప్రియార్హోఽర్హః ప్రియకృత్ ప్రీతివర్ధనః ॥ 93 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "విహాయసగతిర్జ్యోతిః సురుచిర్హుతభుగ్విభుః ।"
   //          },
   //          {
   //            "title": "రవిర్విరోచనః సూర్యః సవితా రవిలోచనః ॥ 94 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అనంతో హుతభుగ్భోక్తా సుఖదో నైకజోఽగ్రజః ।"
   //          },
   //          {
   //            "title": "అనిర్విణ్ణః సదామర్షీ లోకధిష్ఠానమద్భుతః ॥ 95 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సనాత్సనాతనతమః కపిలః కపిరవ్యయః ।"
   //          },
   //          {
   //            "title": "స్వస్తిదః స్వస్తికృత్స్వస్తిః స్వస్తిభుక్ స్వస్తిదక్షిణః ॥ 96 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అరౌద్రః కుండలీ చక్రీ విక్రమ్యూర్జితశాసనః ।"
   //          },
   //          {
   //            "title": "శబ్దాతిగః శబ్దసహః శిశిరః శర్వరీకరః ॥ 97 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అక్రూరః పేశలో దక్షో దక్షిణః, క్షమిణాంవరః ।"
   //          },
   //          {
   //            "title": "విద్వత్తమో వీతభయః పుణ్యశ్రవణకీర్తనః ॥ 98 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఉత్తారణో దుష్కృతిహా పుణ్యో దుఃస్వప్ననాశనః ।"
   //          },
   //          {
   //            "title": "వీరహా రక్షణః సంతో జీవనః పర్యవస్థితః ॥ 99 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అనంతరూపోఽనంత శ్రీర్జితమన్యుర్భయాపహః ।"
   //          },
   //          {
   //            "title": "చతురశ్రో గభీరాత్మా విదిశో వ్యాదిశో దిశః ॥ 100 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అనాదిర్భూర్భువో లక్ష్మీః సువీరో రుచిరాంగదః ।"
   //          },
   //          {
   //            "title": "జననో జనజన్మాదిర్భీమో భీమపరాక్రమః ॥ 101 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఆధారనిలయోఽధాతా పుష్పహాసః ప్రజాగరః ।"
   //          },
   //          {
   //            "title": "ఊర్ధ్వగః సత్పథాచారః ప్రాణదః ప్రణవః పణః ॥ 102 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ప్రమాణం ప్రాణనిలయః ప్రాణభృత్ ప్రాణజీవనః ।"
   //          },
   //          {
   //            "title": "తత్త్వం తత్త్వవిదేకాత్మా జన్మమృత్యుజరాతిగః ॥ 103 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "భూర్భువః స్వస్తరుస్తారః సవితా ప్రపితామహః ।"
   //          },
   //          {
   //            "title": "యజ్ఞో యజ్ఞపతిర్యజ్వా యజ్ఞాంగో యజ్ఞవాహనః ॥ 104 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "యజ్ఞభృద్ యజ్ఞకృద్ యజ్ఞీ యజ్ఞభుక్ యజ్ఞసాధనః ।"
   //          },
   //          {
   //            "title": "యజ్ఞాంతకృద్ యజ్ఞగుహ్యమన్నమన్నాద ఏవ చ ॥ 105 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఆత్మయోనిః స్వయంజాతో వైఖానః సామగాయనః ।"
   //          },
   //          {
   //            "title": "దేవకీనందనః స్రష్టా క్షితీశః పాపనాశనః ॥ 106 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "శంఖభృన్నందకీ చక్రీ శారంగధన్వా గదాధరః ।"
   //          },
   //          {
   //            "title": "రథాంగపాణిరక్షోభ్యః సర్వప్రహరణాయుధః ॥ 107 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "శ్రీ సర్వప్రహరణాయుధ ఓం నమ ఇతి ।"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "వనమాలీ గదీ శారంగీ శంఖీ చక్రీ చ నందకీ ।"
   //          },
   //          {
   //            "title": "శ్రీమాన్నారాయణో విష్ణుర్వాసుదేవోఽభిరక్షతు ॥ 108 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "శ్రీ వాసుదేవోఽభిరక్షతు ఓం నమ ఇతి ।"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఉత్తర పీఠికా"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఫలశ్రుతిః"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఇతీదం కీర్తనీయస్య కేశవస్య మహాత్మనః ।"
   //          },
   //          {
   //            "title": "నామ్నాం సహస్రం దివ్యానామశేషేణ ప్రకీర్తితం। ॥ 1 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "య ఇదం శృణుయాన్నిత్యం యశ్చాపి పరికీర్తయేత్॥"
   //          },
   //          {
   //            "title": "నాశుభం ప్రాప్నుయాత్ కించిత్సోఽముత్రేహ చ మానవః ॥ 2 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "వేదాంతగో బ్రాహ్మణః స్యాత్ క్షత్రియో విజయీ భవేత్ ।"
   //          },
   //          {
   //            "title": "వైశ్యో ధనసమృద్ధః స్యాత్ శూద్రః సుఖమవాప్నుయాత్ ॥ 3 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ధర్మార్థీ ప్రాప్నుయాద్ధర్మమర్థార్థీ చార్థమాప్నుయాత్ ।"
   //          },
   //          {
   //            "title": "కామానవాప్నుయాత్ కామీ ప్రజార్థీ ప్రాప్నుయాత్ప్రజాం। ॥ 4 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "భక్తిమాన్ యః సదోత్థాయ శుచిస్తద్గతమానసః ।"
   //          },
   //          {
   //            "title": "సహస్రం వాసుదేవస్య నామ్నామేతత్ ప్రకీర్తయేత్ ॥ 5 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "యశః ప్రాప్నోతి విపులం యాతిప్రాధాన్యమేవ చ ।"
   //          },
   //          {
   //            "title": "అచలాం శ్రియమాప్నోతి శ్రేయః ప్రాప్నోత్యనుత్తమం। ॥ 6 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "న భయం క్వచిదాప్నోతి వీర్యం తేజశ్చ విందతి ।"
   //          },
   //          {
   //            "title": "భవత్యరోగో ద్యుతిమాన్ బలరూప గుణాన్వితః ॥ 7 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "రోగార్తో ముచ్యతే రోగాద్బద్ధో ముచ్యేత బంధనాత్ ।"
   //          },
   //          {
   //            "title": "భయాన్ముచ్యేత భీతస్తు ముచ్యేతాపన్న ఆపదః ॥ 8 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "దుర్గాణ్యతితరత్యాశు పురుషః పురుషోత్తమమ్ ।"
   //          },
   //          {
   //            "title": "స్తువన్నామసహస్రేణ నిత్యం భక్తిసమన్వితః ॥ 9 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "వాసుదేవాశ్రయో మర్త్యో వాసుదేవపరాయణః ।"
   //          },
   //          {
   //            "title": "సర్వపాపవిశుద్ధాత్మా యాతి బ్రహ్మ సనాతనం। ॥ 10 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "న వాసుదేవ భక్తానామశుభం విద్యతే క్వచిత్ ।"
   //          },
   //          {
   //            "title": "జన్మమృత్యుజరావ్యాధిభయం నైవోపజాయతే ॥ 11 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఇమం స్తవమధీయానః శ్రద్ధాభక్తిసమన్వితః ।"
   //          },
   //          {
   //            "title": "యుజ్యేతాత్మ సుఖక్షాంతి శ్రీధృతి స్మృతి కీర్తిభిః ॥ 12 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "న క్రోధో న చ మాత్సర్యం న లోభో నాశుభామతిః ।"
   //          },
   //          {
   //            "title": "భవంతి కృతపుణ్యానాం భక్తానాం పురుషోత్తమే ॥ 13 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ద్యౌః సచంద్రార్కనక్షత్రా ఖం దిశో భూర్మహోదధిః ।"
   //          },
   //          {
   //            "title": "వాసుదేవస్య వీర్యేణ విధృతాని మహాత్మనః ॥ 14 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ససురాసురగంధర్వం సయక్షోరగరాక్షసమ్ ।"
   //          },
   //          {
   //            "title": "జగద్వశే వర్తతేదం కృష్ణస్య స చరాచరం। ॥ 15 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఇంద్రియాణి మనోబుద్ధిః సత్త్వం తేజో బలం ధృతిః ।"
   //          },
   //          {
   //            "title": "వాసుదేవాత్మకాన్యాహుః, క్షేత్రం క్షేత్రజ్ఞ ఏవ చ ॥ 16 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సర్వాగమానామాచారః ప్రథమం పరికల్పతే ।"
   //          },
   //          {
   //            "title": "ఆచారప్రభవో ధర్మో ధర్మస్య ప్రభురచ్యుతః ॥ 17 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఋషయః పితరో దేవా మహాభూతాని ధాతవః ।"
   //          },
   //          {
   //            "title": "జంగమాజంగమం చేదం జగన్నారాయణోద్భవమ్ ॥ 18 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "యోగోజ్ఞానం తథా సాంఖ్యం విద్యాః శిల్పాదికర్మ చ ।"
   //          },
   //          {
   //            "title": "వేదాః శాస్త్రాణి విజ్ఞానమేతత్సర్వం జనార్దనాత్ ॥ 19 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఏకో విష్ణుర్మహద్భూతం పృథగ్భూతాన్యనేకశః ।"
   //          },
   //          {
   //            "title": "త్రీంలోకాన్వ్యాప్య భూతాత్మా భుంక్తే విశ్వభుగవ్యయః ॥ 20 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఇమం స్తవం భగవతో విష్ణోర్వ్యాసేన కీర్తితమ్ ।"
   //          },
   //          {
   //            "title": "పఠేద్య ఇచ్చేత్పురుషః శ్రేయః ప్రాప్తుం సుఖాని చ ॥ 21 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "విశ్వేశ్వరమజం దేవం జగతః ప్రభుమవ్యయం।"
   //          },
   //          {
   //            "title": "భజంతి యే పుష్కరాక్షం న తే యాంతి పరాభవమ్ ॥ 22 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "న తే యాంతి పరాభవం ఓం నమ ఇతి ।"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@అర్జున ఉవాచ"
   //          },
   //          {
   //            "title": "పద్మపత్ర విశాలాక్ష పద్మనాభ సురోత్తమ ।"
   //          },
   //          {
   //            "title": "భక్తానా మనురక్తానాం త్రాతా భవ జనార్దన ॥ 23 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@శ్రీభగవానువాచ"
   //          },
   //          {
   //            "title": "యో మాం నామసహస్రేణ స్తోతుమిచ్ఛతి పాండవ ।"
   //          },
   //          {
   //            "title": "సోఽహమేకేన శ్లోకేన స్తుత ఏవ న సంశయః ॥ 24 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "స్తుత ఏవ న సంశయ ఓం నమ ఇతి ।"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@వ్యాస ఉవాచ"
   //          },
   //          {
   //            "title": "వాసనాద్వాసుదేవస్య వాసితం భువనత్రయమ్ ।"
   //          },
   //          {
   //            "title": "సర్వభూతనివాసోఽసి వాసుదేవ నమోఽస్తు తే ॥ 25 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "శ్రీవాసుదేవ నమోస్తుత ఓం నమ ఇతి ।"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "పార్వత్యువాచ"
   //          },
   //          {
   //            "title": "కేనోపాయేన లఘునా విష్ణోర్నామసహస్రకమ్ ।"
   //          },
   //          {
   //            "title": "పఠ్యతే పండితైర్నిత్యం శ్రోతుమిచ్ఛామ్యహం ప్రభో ॥ 26 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఈశ్వర ఉవాచ"
   //          },
   //          {
   //            "title": "శ్రీరామ రామ రామేతి రమే రామే మనోరమే ।"
   //          },
   //          {
   //            "title": "సహస్రనామ తత్తుల్యం రామనామ వరాననే ॥ 27 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "శ్రీరామ నామ వరానన ఓం నమ ఇతి ।"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@బ్రహ్మోవాచ"
   //          },
   //          {
   //            "title": "నమోఽస్త్వనంతాయ సహస్రమూర్తయే సహస్రపాదాక్షిశిరోరుబాహవే ।"
   //          },
   //          {
   //            "title": "సహస్రనామ్నే పురుషాయ శాశ్వతే సహస్రకోటీ యుగధారిణే నమః ॥ 28 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "శ్రీ సహస్రకోటీ యుగధారిణే నమ ఓం నమ ఇతి ।"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సంజయ ఉవాచ"
   //          },
   //          {
   //            "title": "యత్ర యోగేశ్వరః కృష్ణో యత్ర పార్థో ధనుర్ధరః ।"
   //          },
   //          {
   //            "title": "తత్ర శ్రీర్విజయో భూతిర్ధ్రువా నీతిర్మతిర్మమ ॥ 29 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@శ్రీ భగవాన్ ఉవాచ"
   //          },
   //          {
   //            "title": "అనన్యాశ్చింతయంతో మాం యే జనాః పర్యుపాసతే ।"
   //          },
   //          {
   //            "title": "తేషాం నిత్యాభియుక్తానాం యోగక్షేమం వహామ్యహం। ॥ 30 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "పరిత్రాణాయ సాధూనాం వినాశాయ చ దుష్కృతాం। ।"
   //          },
   //          {
   //            "title": "ధర్మసంస్థాపనార్థాయ సంభవామి యుగే యుగే ॥ 31 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఆర్తాః విషణ్ణాః శిథిలాశ్చ భీతాః ఘోరేషు చ వ్యాధిషు వర్తమానాః ।"
   //          },
   //          {
   //            "title": "సంకీర్త్య నారాయణశబ్దమాత్రం విముక్తదుఃఖాః సుఖినో భవంతి ॥ 32 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "కాయేన వాచా మనసేంద్రియైర్వా బుద్ధ్యాత్మనా వా ప్రకృతేః స్వభావాత్ ।"
   //          },
   //          {
   //            "title": "కరోమి యద్యత్సకలం పరస్మై నారాయణాయేతి సమర్పయామి ॥ 33 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "యదక్షర పదభ్రష్టం మాత్రాహీనం తు యద్భవేత్"
   //          },
   //          {
   //            "title": "తథ్సర్వం క్షమ్యతాం దేవ నారాయణ నమోఽస్తు తే ।"
   //          },
   //          {
   //            "title": "విసర్గ బిందు మాత్రాణి పదపాదాక్షరాణి చ"
   //          },
   //          {
   //            "title": "న్యూనాని చాతిరిక్తాని క్షమస్వ పురుషోత్తమః ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఇతి శ్రీ మహాభారతే శతసాహస్రికాయాం సంహితాయాం వైయాసిక్యామనుశాసన పర్వాంతర్గత ఆనుశాసనిక పర్వణి, మోక్షధర్మే భీష్మ యుధిష్ఠిర సంవాదే శ్రీ విష్ణోర్దివ్య సహస్రనామ స్తోత్రం నామైకోన పంచ శతాధిక శతతమోధ్యాయః ॥ శ్రీ విష్ణు సహస్రనామ స్తోత్రం సమాప్తమ్ ॥"
   //          },
   //          {
   //            "title": "ఓం తత్సత్ సర్వం శ్రీ కృష్ణార్పణమస్తు ॥"
   //          }
   //        ],
   //        "title": "శ్రీ విష్ణు సహస్ర నామ స్తోత్రం",
   //        "img": require('../imgs/Stotramulu/Vishnu.png')
   //      },
   //      {
   //        "sotram": [
   //          {
   //            "title": "అనాయాసేన మరణం"
   //          },
   //          {
   //            "title": "వినా ధైన్యేన జీవనం"
   //          },
   //          {
   //            "title": "దేహాంతే తవ సాన్నిధ్యం"
   //          },
   //          {
   //            "title": "దేహిమే పరమేశ్వరం"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఓ పరమేశ్వరా! కష్టతరంకాని (శ్రమలేని) మరణాన్నీ, ఇతరుల దయమీద ఆధారపడని జీవనాన్నీ, ఈ శరీరాన్ని విడచిన తరువాత (మరణానంతరం) నీ సాన్నిధ్యాన్నీ నాకు ప్రసాదించు."
   //          }
   //        ],
   //        "title": "పరమ శివుడి స్తోత్రం",
   //        "img": require('../imgs/Stotramulu/Siva.png')
   //      },
   //      {
   //        "sotram": [
   //          {
   //            "title": "జ్ఞానానందమయం దేవం"
   //          },
   //          {
   //            "title": "నిర్మలస్ఫటికాకృతిం"
   //          },
   //          {
   //            "title": "ఆధారం సర్వవిద్యానాం"
   //          },
   //          {
   //            "title": "హయగ్రీవముపాస్మహే"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "జ్ఞానంతో నిండి ఉన్న కారణంగా ఆనందంగా కనిపించేవాడూ, స్ఫటికంలా నిష్కల్మషుడై, సర్వవిద్యలకూ ఆధారభూతుడైన హయగ్రీవుడిని నేను ఉపాసిస్తున్నాను."
   //          }
   //        ],
   //        "title": "హయగ్రీవ స్తోత్రం",
   //        "img": require('../imgs/Stotramulu/Haygreeva.png')
   //      },
   //      {
   //        "sotram": [
   //          {
   //            "title": "@నవగ్రహ ధ్యాన శ్లోకం"
   //          },
   //          {
   //            "title": "ఆదిత్యాయ చ సోమాయ మంగళాయ బుధాయ చ ।"
   //          },
   //          {
   //            "title": "గురు శుక్ర శనిభ్యశ్చ రాహవే కేతవే నమః ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@రవిః"
   //          },
   //          {
   //            "title": "జపాకుసుమ సంకాశం కాశ్యపేయం మహాద్యుతిమ్ ।"
   //          },
   //          {
   //            "title": "తమోఽరిం సర్వ పాపఘ్నం ప్రణతోస్మి దివాకరమ్ ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@చంద్రః"
   //          },
   //          {
   //            "title": "దథిశంఖ తుషారాభం క్షీరార్ణవ సముద్భవం (క్షీరోదార్ణవ సంభవం) ।"
   //          },
   //          {
   //            "title": "నమామి శశినం సోమం శంభో-ర్మకుట భూషణమ్ ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@కుజః"
   //          },
   //          {
   //            "title": "ధరణీ గర్భ సంభూతం విద్యుత్కాంతి సమప్రభమ్ ।"
   //          },
   //          {
   //            "title": "కుమారం శక్తిహస్తం తం మంగళం ప్రణమామ్యహమ్ ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@బుధః"
   //          },
   //          {
   //            "title": "ప్రియంగు కలికాశ్యామం రూపేణా ప్రతిమం బుధమ్ ।"
   //          },
   //          {
   //            "title": "సౌమ్యం సౌమ్య (సత్వ) గుణోపేతం తం బుధం ప్రణమామ్యహమ్ ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@గురుః"
   //          },
   //          {
   //            "title": "దేవానాం చ ఋషీణాం చ గురుం కాంచనసన్నిభమ్ ।"
   //          },
   //          {
   //            "title": "బుద్ధిమంతం త్రిలోకేశం తం నమామి బృహస్పతిమ్ ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@శుక్రః"
   //          },
   //          {
   //            "title": "హిమకుంద మృణాళాభం దైత్యానం పరమం గురుమ్ ।"
   //          },
   //          {
   //            "title": "సర్వశాస్త్ర ప్రవక్తారం భార్గవం ప్రణమామ్యహమ్ ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@శనిః"
   //          },
   //          {
   //            "title": "నీలాంజన సమాభాసం రవిపుత్రం యమాగ్రజమ్ ।"
   //          },
   //          {
   //            "title": "ఛాయా మార్తాండ సంభూతం తం నమామి శనైశ్చరమ్ ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@రాహుః"
   //          },
   //          {
   //            "title": "అర్ధకాయం మహావీరం చంద్రాదిత్య విమర్ధనమ్ ।"
   //          },
   //          {
   //            "title": "సింహికా గర్భ సంభూతం తం రాహుం ప్రణమామ్యహమ్ ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@కేతుః"
   //          },
   //          {
   //            "title": "ఫలాశ పుష్ప సంకాశం తారకాగ్రహమస్తకమ్ ।"
   //          },
   //          {
   //            "title": "రౌద్రం రౌద్రాత్మకం ఘోరం తం కేతుం ప్రణమామ్యహమ్ ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఫలశ్రుతిః"
   //          },
   //          {
   //            "title": "ఇతి వ్యాస ముఖోద్గీతం యః పఠేత్సు సమాహితః ।"
   //          },
   //          {
   //            "title": "దివా వా యది వా రాత్రౌ విఘ్నశాంతి-ర్భవిష్యతి ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "నరనారీ-నృపాణాం చ భవే-ద్దుఃస్వప్న-నాశనమ్ ।"
   //          },
   //          {
   //            "title": "ఐశ్వర్యమతులం తేషామారోగ్యం పుష్టి వర్ధనమ్ ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "గ్రహనక్షత్రజాః పీడాస్తస్కరాగ్ని సముద్భవాః ।"
   //          },
   //          {
   //            "title": "తాస్సర్వాః ప్రశమం యాంతి వ్యాసో బ్రూతే న సంశయః ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఇతి వ్యాస విరచితం నవగ్రహ స్తోత్రం సంపూర్ణమ్ ।"
   //          }
   //        ],
   //        "title": "నవగ్రహ స్తోత్రం",
   //        "img": require('../imgs/Stotramulu/Navagrahalu.png')
   //      },
   //      {
   //        "sotram": [
   //          {
   //            "title": "@ఆదిలక్ష్మి"
   //          },
   //          {
   //            "title": "సుమనస వందిత సుందరి మాధవి, చంద్ర సహొదరి హేమమయే"
   //          },
   //          {
   //            "title": "మునిగణ వందిత మోక్షప్రదాయని, మంజుల భాషిణి వేదనుతే ।"
   //          },
   //          {
   //            "title": "పంకజవాసిని దేవ సుపూజిత, సద్గుణ వర్షిణి శాంతియుతే"
   //          },
   //          {
   //            "title": "జయ జయహే మధుసూదన కామిని, ఆదిలక్ష్మి పరిపాలయ మామ్ ॥ 1 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@ధాన్యలక్ష్మి"
   //          },
   //          {
   //            "title": "అయికలి కల్మష నాశిని కామిని, వైదిక రూపిణి వేదమయే"
   //          },
   //          {
   //            "title": "క్షీర సముద్భవ మంగళ రూపిణి, మంత్రనివాసిని మంత్రనుతే ।"
   //          },
   //          {
   //            "title": "మంగళదాయిని అంబుజవాసిని, దేవగణాశ్రిత పాదయుతే"
   //          },
   //          {
   //            "title": "జయ జయహే మధుసూదన కామిని, ధాన్యలక్ష్మి పరిపాలయ మామ్ ॥ 2 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@ధైర్యలక్ష్మి"
   //          },
   //          {
   //            "title": "జయవరవర్షిణి వైష్ణవి భార్గవి, మంత్ర స్వరూపిణి మంత్రమయే"
   //          },
   //          {
   //            "title": "సురగణ పూజిత శీఘ్ర ఫలప్రద, జ్ఞాన వికాసిని శాస్త్రనుతే ।"
   //          },
   //          {
   //            "title": "భవభయహారిణి పాపవిమోచని, సాధు జనాశ్రిత పాదయుతే"
   //          },
   //          {
   //            "title": "జయ జయహే మధు సూధన కామిని, ధైర్యలక్ష్మీ పరిపాలయ మామ్ ॥ 3 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@గజలక్ష్మి"
   //          },
   //          {
   //            "title": "జయ జయ దుర్గతి నాశిని కామిని, సర్వఫలప్రద శాస్త్రమయే"
   //          },
   //          {
   //            "title": "రధగజ తురగపదాతి సమావృత, పరిజన మండిత లోకనుతే ।"
   //          },
   //          {
   //            "title": "హరిహర బ్రహ్మ సుపూజిత సేవిత, తాప నివారిణి పాదయుతే"
   //          },
   //          {
   //            "title": "జయ జయహే మధుసూదన కామిని, గజలక్ష్మీ రూపేణ పాలయ మామ్ ॥ 4 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@సంతానలక్ష్మి"
   //          },
   //          {
   //            "title": "అయిఖగ వాహిని మోహిని చక్రిణి, రాగవివర్ధిని జ్ఞానమయే"
   //          },
   //          {
   //            "title": "గుణగణవారధి లోకహితైషిణి, సప్తస్వర భూషిత గాననుతే ।"
   //          },
   //          {
   //            "title": "సకల సురాసుర దేవ మునీశ్వర, మానవ వందిత పాదయుతే"
   //          },
   //          {
   //            "title": "జయ జయహే మధుసూదన కామిని, సంతానలక్ష్మీ పరిపాలయ మామ్ ॥ 5 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@విజయలక్ష్మి"
   //          },
   //          {
   //            "title": "జయ కమలాసిని సద్గతి దాయిని, జ్ఞానవికాసిని గానమయే"
   //          },
   //          {
   //            "title": "అనుదిన మర్చిత కుంకుమ ధూసర, భూషిత వాసిత వాద్యనుతే ।"
   //          },
   //          {
   //            "title": "కనకధరాస్తుతి వైభవ వందిత, శంకరదేశిక మాన్యపదే"
   //          },
   //          {
   //            "title": "జయ జయహే మధుసూదన కామిని, విజయలక్ష్మీ పరిపాలయ మామ్ ॥ 6 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@విద్యాలక్ష్మి"
   //          },
   //          {
   //            "title": "ప్రణత సురేశ్వరి భారతి భార్గవి, శోకవినాశిని రత్నమయే"
   //          },
   //          {
   //            "title": "మణిమయ భూషిత కర్ణవిభూషణ, శాంతి సమావృత హాస్యముఖే ।"
   //          },
   //          {
   //            "title": "నవనిధి దాయిని కలిమలహారిణి, కామిత ఫలప్రద హస్తయుతే"
   //          },
   //          {
   //            "title": "జయ జయహే మధుసూదన కామిని, విద్యాలక్ష్మీ సదా పాలయ మామ్ ॥ 7 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@ధనలక్ష్మి"
   //          },
   //          {
   //            "title": "ధిమిధిమి ధింధిమి ధింధిమి-దింధిమి, దుంధుభి నాద సుపూర్ణమయే"
   //          },
   //          {
   //            "title": "ఘుమఘుమ ఘుంఘుమ ఘుంఘుమ ఘుంఘుమ, శంఖ నినాద సువాద్యనుతే ।"
   //          },
   //          {
   //            "title": "వేద పూరాణేతిహాస సుపూజిత, వైదిక మార్గ ప్రదర్శయుతే"
   //          },
   //          {
   //            "title": "జయ జయహే మధుసూదన కామిని, ధనలక్ష్మి రూపేణా పాలయ మామ్ ॥ 8 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@ఫలశృతి"
   //          },
   //          {
   //            "title": "శ్లో॥ అష్టలక్ష్మీ నమస్తుభ్యం వరదే కామరూపిణి ।"
   //          },
   //          {
   //            "title": "విష్ణువక్షః స్థలా రూఢే భక్త మోక్ష ప్రదాయిని ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "శ్లో॥ శంఖ చక్రగదాహస్తే విశ్వరూపిణితే జయః ।"
   //          },
   //          {
   //            "title": "జగన్మాత్రే చ మోహిన్యై మంగళం శుభ మంగళమ్ ॥"
   //          }
   //        ],
   //        "title": "అష్ట లక్ష్మీ స్తోత్రం",
   //        "img": require('../imgs/Stotramulu/AshtaLakshmi.png')
   //      },
   //      {
   //        "sotram": [
   //          {
   //            "title": "వందే వందారు మందారమిందిరానంద కందలం"
   //          },
   //          {
   //            "title": "అమందానంద సందోహ బంధురం సింధురాననం"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అంగం హరేః పులకభూషణమాశ్రయంతీ"
   //          },
   //          {
   //            "title": "భృంగాంగనేవ ముకుళాభరణం తమాలమ్ ।"
   //          },
   //          {
   //            "title": "అంగీకృతాఖిల విభూతిరపాంగలీలా"
   //          },
   //          {
   //            "title": "మాంగల్యదాస్తు మమ మంగళదేవతాయాః ॥ 1 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ముగ్ధా ముహుర్విదధతీ వదనే మురారేః"
   //          },
   //          {
   //            "title": "ప్రేమత్రపాప్రణిహితాని గతాగతాని ।"
   //          },
   //          {
   //            "title": "మాలాదృశోర్మధుకరీవ మహోత్పలే యా"
   //          },
   //          {
   //            "title": "సా మే శ్రియం దిశతు సాగర సంభవా యాః ॥ 2 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఆమీలితాక్షమధిగ్యమ ముదా ముకుందం"
   //          },
   //          {
   //            "title": "ఆనందకందమనిమేషమనంగ తంత్రమ్ ।"
   //          },
   //          {
   //            "title": "ఆకేకరస్థితకనీనికపక్ష్మనేత్రం"
   //          },
   //          {
   //            "title": "భూత్యై భవన్మమ భుజంగ శయాంగనా యాః ॥ 3 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "బాహ్వంతరే మధుజితః శ్రితకౌస్తుభే యా"
   //          },
   //          {
   //            "title": "హారావళీవ హరినీలమయీ విభాతి ।"
   //          },
   //          {
   //            "title": "కామప్రదా భగవతోఽపి కటాక్షమాలా"
   //          },
   //          {
   //            "title": "కళ్యాణమావహతు మే కమలాలయా యాః ॥ 4 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "కాలాంబుదాళి లలితోరసి కైటభారేః"
   //          },
   //          {
   //            "title": "ధారాధరే స్ఫురతి యా తటిదంగనేవ ।"
   //          },
   //          {
   //            "title": "మాతుస్సమస్తజగతాం మహనీయమూర్తిః"
   //          },
   //          {
   //            "title": "భద్రాణి మే దిశతు భార్గవనందనా యాః ॥ 5 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ప్రాప్తం పదం ప్రథమతః ఖలు యత్ప్రభావాత్"
   //          },
   //          {
   //            "title": "మాంగల్యభాజి మధుమాథిని మన్మథేన ।"
   //          },
   //          {
   //            "title": "మయ్యాపతేత్తదిహ మంథరమీక్షణార్థం"
   //          },
   //          {
   //            "title": "మందాలసం చ మకరాలయ కన్యకా యాః ॥ 6 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "విశ్వామరేంద్ర పద విభ్రమ దానదక్షం"
   //          },
   //          {
   //            "title": "ఆనందహేతురధికం మురవిద్విషోఽపి ।"
   //          },
   //          {
   //            "title": "ఈషన్నిషీదతు మయి క్షణమీక్షణార్థం"
   //          },
   //          {
   //            "title": "ఇందీవరోదర సహోదరమిందిరా యాః ॥ 7 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఇష్టా విశిష్టమతయోపి యయా దయార్ద్ర"
   //          },
   //          {
   //            "title": "దృష్ట్యా త్రివిష్టపపదం సులభం లభంతే ।"
   //          },
   //          {
   //            "title": "దృష్టిః ప్రహృష్ట కమలోదర దీప్తిరిష్టాం"
   //          },
   //          {
   //            "title": "పుష్టిం కృషీష్ట మమ పుష్కర విష్టరా యాః ॥ 8 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "దద్యాద్దయాను పవనో ద్రవిణాంబుధారాం"
   //          },
   //          {
   //            "title": "అస్మిన్నకించన విహంగ శిశౌ విషణ్ణే ।"
   //          },
   //          {
   //            "title": "దుష్కర్మఘర్మమపనీయ చిరాయ దూరం"
   //          },
   //          {
   //            "title": "నారాయణ ప్రణయినీ నయనాంబువాహః ॥ 9 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "గీర్దేవతేతి గరుడధ్వజ సుందరీతి"
   //          },
   //          {
   //            "title": "శాకంబరీతి శశిశేఖర వల్లభేతి ।"
   //          },
   //          {
   //            "title": "సృష్టి స్థితి ప్రళయ కేళిషు సంస్థితాయై"
   //          },
   //          {
   //            "title": "తస్యై నమస్త్రిభువనైక గురోస్తరుణ్యై ॥ 10 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "శ్రుత్యై నమోఽస్తు శుభకర్మ ఫలప్రసూత్యై"
   //          },
   //          {
   //            "title": "రత్యై నమోఽస్తు రమణీయ గుణార్ణవాయై ।"
   //          },
   //          {
   //            "title": "శక్త్యై నమోఽస్తు శతపత్ర నికేతనాయై"
   //          },
   //          {
   //            "title": "పుష్ట్యై నమోఽస్తు పురుషోత్తమ వల్లభాయై ॥ 11 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "నమోఽస్తు నాళీక నిభాననాయై"
   //          },
   //          {
   //            "title": "నమోఽస్తు దుగ్ధోదధి జన్మభూమ్యై ।"
   //          },
   //          {
   //            "title": "నమోఽస్తు సోమామృత సోదరాయై"
   //          },
   //          {
   //            "title": "నమోఽస్తు నారాయణ వల్లభాయై ॥ 12 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "నమోఽస్తు హేమాంబుజ పీఠికాయై"
   //          },
   //          {
   //            "title": "నమోఽస్తు భూమండల నాయికాయై ।"
   //          },
   //          {
   //            "title": "నమోఽస్తు దేవాది దయాపరాయై"
   //          },
   //          {
   //            "title": "నమోఽస్తు శారంగాయుధ వల్లభాయై ॥ 13 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "నమోఽస్తు దేవ్యై భృగునందనాయై"
   //          },
   //          {
   //            "title": "నమోఽస్తు విష్ణోరురసి స్థితాయై ।"
   //          },
   //          {
   //            "title": "నమోఽస్తు లక్ష్మ్యై కమలాలయాయై"
   //          },
   //          {
   //            "title": "నమోఽస్తు దామోదర వల్లభాయై ॥ 14 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "నమోఽస్తు కాంత్యై కమలేక్షణాయై"
   //          },
   //          {
   //            "title": "నమోఽస్తు భూత్యై భువనప్రసూత్యై ।"
   //          },
   //          {
   //            "title": "నమోఽస్తు దేవాదిభిరర్చితాయై"
   //          },
   //          {
   //            "title": "నమోఽస్తు నందాత్మజ వల్లభాయై ॥ 15 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సంపత్కరాణి సకలేంద్రియ నందనాని"
   //          },
   //          {
   //            "title": "సామ్రాజ్య దానవిభవాని సరోరుహాక్షి ।"
   //          },
   //          {
   //            "title": "త్వద్వందనాని దురితా హరణోద్యతాని"
   //          },
   //          {
   //            "title": "మామేవ మాతరనిశం కలయంతు మాన్యే ॥ 16 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "యత్కటాక్ష సముపాసనా విధిః"
   //          },
   //          {
   //            "title": "సేవకస్య సకలార్థ సంపదః ।"
   //          },
   //          {
   //            "title": "సంతనోతి వచనాంగ మానసైః"
   //          },
   //          {
   //            "title": "త్వాం మురారిహృదయేశ్వరీం భజే ॥ 17 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సరసిజనిలయే సరోజహస్తే"
   //          },
   //          {
   //            "title": "ధవళతమాంశుక గంధమాల్యశోభే ।"
   //          },
   //          {
   //            "title": "భగవతి హరివల్లభే మనోజ్ఞే"
   //          },
   //          {
   //            "title": "త్రిభువనభూతికరీ ప్రసీదమహ్యమ్ ॥ 18 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "దిగ్ఘస్తిభిః కనక కుంభముఖావసృష్ట"
   //          },
   //          {
   //            "title": "స్వర్వాహినీ విమలచారుజలాప్లుతాంగీమ్ ।"
   //          },
   //          {
   //            "title": "ప్రాతర్నమామి జగతాం జననీమశేష"
   //          },
   //          {
   //            "title": "లోకధినాథ గృహిణీమమృతాబ్ధిపుత్రీమ్ ॥ 19 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "కమలే కమలాక్ష వల్లభే త్వం"
   //          },
   //          {
   //            "title": "కరుణాపూర తరంగితైరపాంగైః ।"
   //          },
   //          {
   //            "title": "అవలోకయ మామకించనానాం"
   //          },
   //          {
   //            "title": "ప్రథమం పాత్రమకృతిమం దయాయాః ॥ 20 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "దేవి ప్రసీద జగదీశ్వరి లోకమాతః"
   //          },
   //          {
   //            "title": "కళ్యాణగాత్రి కమలేక్షణ జీవనాథే ।"
   //          },
   //          {
   //            "title": "దారిద్ర్యభీతిహృదయం శరణాగతం మాం"
   //          },
   //          {
   //            "title": "ఆలోకయ ప్రతిదినం సదయైరపాంగైః ॥ 21 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "స్తువంతి యే స్తుతిభిరమీభిరన్వహం"
   //          },
   //          {
   //            "title": "త్రయీమయీం త్రిభువనమాతరం రమామ్ ।"
   //          },
   //          {
   //            "title": "గుణాధికా గురుతుర భాగ్య భాగినః"
   //          },
   //          {
   //            "title": "భవంతి తే భువి బుధ భావితాశయాః ॥ 22 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సువర్ణధారా స్తోత్రం యచ్ఛంకరాచార్య నిర్మితం"
   //          },
   //          {
   //            "title": "త్రిసంధ్యం యః పఠేన్నిత్యం స కుబేరసమో భవేత్ ॥"
   //          }
   //        ],
   //        "title": "కనకధారా స్తోత్రం",
   //        "img": require('../imgs/Stotramulu/LakshmiDevi.png')
   //      },
   //      {
   //        "sotram": [
   //          {
   //            "title": "నారాయణ నారాయణ జయ గోవింద హరే ॥"
   //          },
   //          {
   //            "title": "నారాయణ నారాయణ జయ గోపాల హరే ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "కరుణాపారావార వరుణాలయగంభీర నారాయణ ॥ 1 ॥"
   //          },
   //          {
   //            "title": "ఘననీరదసంకాశ కృతకలికల్మషనాశన నారాయణ ॥ 2 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "యమునాతీరవిహార ధృతకౌస్తుభమణిహార నారాయణ ॥ 3 ॥"
   //          },
   //          {
   //            "title": "పీతాంబరపరిధాన సురకళ్యాణనిధాన నారాయణ ॥ 4 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "మంజులగుంజాభూష మాయామానుషవేష నారాయణ ॥ 5 ॥"
   //          },
   //          {
   //            "title": "రాధాధరమధురసిక రజనీకరకులతిలక నారాయణ ॥ 6 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "మురళీగానవినోద వేదస్తుతభూపాద నారాయణ ॥ 7 ॥"
   //          },
   //          {
   //            "title": "బర్హినిబర్హాపీడ నటనాటకఫణిక్రీడ నారాయణ ॥ 8 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "వారిజభూషాభరణ రాజీవరుక్మిణీరమణ నారాయణ ॥ 9 ॥"
   //          },
   //          {
   //            "title": "జలరుహదళనిభనేత్ర జగదారంభకసూత్ర నారాయణ ॥ 10 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "పాతకరజనీసంహార కరుణాలయ మాముద్ధర నారాయణ ॥ 11 ॥"
   //          },
   //          {
   //            "title": "అఘ బకహయకంసారే కేశవ కృష్ణ మురారే నారాయణ ॥ 12 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "హాటకనిభపీతాంబర అభయం కురు మే మావర నారాయణ ॥ 13 ॥"
   //          },
   //          {
   //            "title": "దశరథరాజకుమార దానవమదసంహార నారాయణ ॥ 14 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "గోవర్ధనగిరి రమణ గోపీమానసహరణ నారాయణ ॥ 15 ॥"
   //          },
   //          {
   //            "title": "సరయుతీరవిహార సజ్జన^^ఋషిమందార నారాయణ ॥ 16 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "విశ్వామిత్రమఖత్ర వివిధవరానుచరిత్ర నారాయణ ॥ 17 ॥"
   //          },
   //          {
   //            "title": "ధ్వజవజ్రాంకుశపాద ధరణీసుతసహమోద నారాయణ ॥ 18 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "జనకసుతాప్రతిపాల జయ జయ సంస్మృతిలీల నారాయణ ॥ 19 ॥"
   //          },
   //          {
   //            "title": "దశరథవాగ్ధృతిభార దండక వనసంచార నారాయణ ॥ 20 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ముష్టికచాణూరసంహార మునిమానసవిహార నారాయణ ॥ 21 ॥"
   //          },
   //          {
   //            "title": "వాలివినిగ్రహశౌర్య వరసుగ్రీవహితార్య నారాయణ ॥ 22 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "మాం మురళీకర ధీవర పాలయ పాలయ శ్రీధర నారాయణ ॥ 23 ॥"
   //          },
   //          {
   //            "title": "జలనిధి బంధన ధీర రావణకంఠవిదార నారాయణ ॥ 24 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "తాటకమర్దన రామ నటగుణవివిధ సురామ నారాయణ ॥ 25 ॥"
   //          },
   //          {
   //            "title": "గౌతమపత్నీపూజన కరుణాఘనావలోకన నారాయణ ॥ 26 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సంభ్రమసీతాహార సాకేతపురవిహార నారాయణ ॥ 27 ॥"
   //          },
   //          {
   //            "title": "అచలోద్ధృతచంచత్కర భక్తానుగ్రహతత్పర నారాయణ ॥ 28 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "నైగమగానవినోద రక్షిత సుప్రహ్లాద నారాయణ ॥ 29 ॥"
   //          },
   //          {
   //            "title": "భారత యతవరశంకర నామామృతమఖిలాంతర నారాయణ ॥ 30 ॥"
   //          }
   //        ],
   //        "title": "నారాయణ స్తోత్రం",
   //        "img": require('../imgs/Stotramulu/Vishnu.png')
   //      },
   //      {
   //        "sotram": [
   //          {
   //            "title": "ఓం అస్య శ్రీ రామరక్షా స్తోత్రమంత్రస్య"
   //          },
   //          {
   //            "title": "బుధకౌశిక ఋషిః"
   //          },
   //          {
   //            "title": "శ్రీ సీతారామ చంద్రోదేవతా"
   //          },
   //          {
   //            "title": "అనుష్టుప్ ఛందః"
   //          },
   //          {
   //            "title": "సీతా శక్తిః"
   //          },
   //          {
   //            "title": "శ్రీమద్ హనుమాన్ కీలకం"
   //          },
   //          {
   //            "title": "శ్రీరామచంద్ర ప్రీత్యర్థే రామరక్షా స్తోత్రజపే వినియోగః ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@ధ్యానం"
   //          },
   //          {
   //            "title": "ధ్యాయేదాజానుబాహుం ధృతశర ధనుషం బద్ధ పద్మాసనస్థం"
   //          },
   //          {
   //            "title": "పీతం వాసోవసానం నవకమల దళస్పర్థి నేత్రం ప్రసన్నమ్ ।"
   //          },
   //          {
   //            "title": "వామాంకారూఢ సీతాముఖ కమలమిలల్లోచనం నీరదాభం"
   //          },
   //          {
   //            "title": "నానాలంకార దీప్తం దధతమురు జటామండలం రామచంద్రమ్ ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@స్తోత్రం"
   //          },
   //          {
   //            "title": "చరితం రఘునాథస్య శతకోటి ప్రవిస్తరమ్ ।"
   //          },
   //          {
   //            "title": "ఏకైకమక్షరం పుంసాం మహాపాతక నాశనమ్ ॥ 1 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ధ్యాత్వా నీలోత్పల శ్యామం రామం రాజీవలోచనమ్ ।"
   //          },
   //          {
   //            "title": "జానకీ లక్ష్మణోపేతం జటాముకుట మండితమ్ ॥ 2 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సాసితూణ ధనుర్బాణ పాణిం నక్తం చరాంతకమ్ ।"
   //          },
   //          {
   //            "title": "స్వలీలయా జగత్త్రాతు మావిర్భూతమజం విభుమ్ ॥ 3 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "రామరక్షాం పఠేత్ప్రాజ్ఞః పాపఘ్నీం సర్వకామదామ్ ।"
   //          },
   //          {
   //            "title": "శిరో మే రాఘవః పాతు ఫాలం దశరథాత్మజః ॥ 4 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "కౌసల్యేయో దృశౌపాతు విశ్వామిత్రప్రియః శృతీ ।"
   //          },
   //          {
   //            "title": "ఘ్రాణం పాతు మఖత్రాతా ముఖం సౌమిత్రివత్సలః ॥ 5 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "జిహ్వాం విద్యానిధిః పాతు కంఠం భరతవందితః ।"
   //          },
   //          {
   //            "title": "స్కంధౌ దివ్యాయుధః పాతు భుజౌ భగ్నేశకార్ముకః ॥ 6 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "కరౌ సీతాపతిః పాతు హృదయం జామదగ్న్యజిత్ ।"
   //          },
   //          {
   //            "title": "మధ్యం పాతు ఖరధ్వంసీ నాభిం జాంబవదాశ్రయః ॥ 7 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సుగ్రీవేశః కటిం పాతు సక్థినీ హనుమత్-ప్రభుః ।"
   //          },
   //          {
   //            "title": "ఊరూ రఘూత్తమః పాతు రక్షఃకుల వినాశకృత్ ॥ 8 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "జానునీ సేతుకృత్-పాతు జంఘే దశముఖాంతకః ।"
   //          },
   //          {
   //            "title": "పాదౌ విభీషణశ్రీదః పాతు రామోఽఖిలం వపుః ॥ 9 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఏతాం రామబలోపేతాం రక్షాం యః సుకృతీ పఠేత్ ।"
   //          },
   //          {
   //            "title": "స చిరాయుః సుఖీ పుత్రీ విజయీ వినయీ భవేత్ ॥ 10 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "పాతాళ-భూతల-వ్యోమ-చారిణ-శ్చద్మ-చారిణః ।"
   //          },
   //          {
   //            "title": "న ద్రష్టుమపి శక్తాస్తే రక్షితం రామనామభిః ॥ 11 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "రామేతి రామభద్రేతి రామచంద్రేతి వా స్మరన్ ।"
   //          },
   //          {
   //            "title": "నరో న లిప్యతే పాపైర్భుక్తిం ముక్తిం చ విందతి ॥ 12 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "జగజ్జైత్రైక మంత్రేణ రామనామ్నాభి రక్షితమ్ ।"
   //          },
   //          {
   //            "title": "యః కంఠే ధారయేత్తస్య కరస్థాః సర్వసిద్ధయః ॥ 13 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "వజ్రపంజర నామేదం యో రామకవచం స్మరేత్ ।"
   //          },
   //          {
   //            "title": "అవ్యాహతాజ్ఞః సర్వత్ర లభతే జయమంగళమ్ ॥ 14 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఆదిష్టవాన్-యథా స్వప్నే రామరక్షామిమాం హరః ।"
   //          },
   //          {
   //            "title": "తథా లిఖితవాన్-ప్రాతః ప్రబుద్ధౌ బుధకౌశికః ॥ 15 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఆరామః కల్పవృక్షాణాం విరామః సకలాపదామ్ ।"
   //          },
   //          {
   //            "title": "అభిరామ-స్త్రిలోకానాం రామః శ్రీమాన్ స నః ప్రభుః ॥ 16 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "తరుణౌ రూపసంపన్నౌ సుకుమారౌ మహాబలౌ ।"
   //          },
   //          {
   //            "title": "పుండరీక విశాలాక్షౌ చీరకృష్ణాజినాంబరౌ ॥ 17 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఫలమూలాశినౌ దాంతౌ తాపసౌ బ్రహ్మచారిణౌ ।"
   //          },
   //          {
   //            "title": "పుత్రౌ దశరథస్యైతౌ భ్రాతరౌ రామలక్ష్మణౌ ॥ 18 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "శరణ్యౌ సర్వసత్త్వానాం శ్రేష్ఠౌ సర్వధనుష్మతామ్ ।"
   //          },
   //          {
   //            "title": "రక్షఃకుల నిహంతారౌ త్రాయేతాం నో రఘూత్తమౌ ॥ 19 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఆత్త సజ్య ధనుషా విషుస్పృశా వక్షయాశుగ నిషంగ సంగినౌ ।"
   //          },
   //          {
   //            "title": "రక్షణాయ మమ రామలక్షణావగ్రతః పథి సదైవ గచ్ఛతామ్ ॥ 20 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సన్నద్ధః కవచీ ఖడ్గీ చాపబాణధరో యువా ।"
   //          },
   //          {
   //            "title": "గచ్ఛన్ మనోరథాన్నశ్చ (మనోరథోఽస్మాకం) రామః పాతు స లక్ష్మణః ॥ 21 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "రామో దాశరథి శ్శూరో లక్ష్మణానుచరో బలీ ।"
   //          },
   //          {
   //            "title": "కాకుత్సః పురుషః పూర్ణః కౌసల్యేయో రఘూత్తమః ॥ 22 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "వేదాంతవేద్యో యజ్ఞేశః పురాణ పురుషోత్తమః ।"
   //          },
   //          {
   //            "title": "జానకీవల్లభః శ్రీమానప్రమేయ పరాక్రమః ॥ 23 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఇత్యేతాని జపేన్నిత్యం మద్భక్తః శ్రద్ధయాన్వితః ।"
   //          },
   //          {
   //            "title": "అశ్వమేధాధికం పుణ్యం సంప్రాప్నోతి న సంశయః ॥ 24 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "రామం దూర్వాదళ శ్యామం పద్మాక్షం పీతవాససమ్ ।"
   //          },
   //          {
   //            "title": "స్తువంతి నాభి-ర్దివ్యై-ర్నతే సంసారిణో నరాః ॥ 25 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "రామం లక్ష్మణ పూర్వజం రఘువరం సీతాపతిం సుందరం"
   //          },
   //          {
   //            "title": "కాకుత్స్థం కరుణార్ణవం గుణనిధిం విప్రప్రియం ధార్మికమ్ ।"
   //          },
   //          {
   //            "title": "రాజేంద్రం సత్యసంధం దశరథతనయం శ్యామలం శాంతమూర్తిం"
   //          },
   //          {
   //            "title": "వందే లోకాభిరామం రఘుకుల తిలకం రాఘవం రావణారిమ్ ॥ 26 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "రామాయ రామభద్రాయ రామచంద్రాయ వేధసే ।"
   //          },
   //          {
   //            "title": "రఘునాథాయ నాథాయ సీతాయాః పతయే నమః ॥ 27 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "శ్రీరామ రామ రఘునందన రామ రామ"
   //          },
   //          {
   //            "title": "శ్రీరామ రామ భరతాగ్రజ రామ రామ ।"
   //          },
   //          {
   //            "title": "శ్రీరామ రామ రణకర్కశ రామ రామ"
   //          },
   //          {
   //            "title": "శ్రీరామ రామ శరణం భవ రామ రామ ॥ 28 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "శ్రీరామ చంద్ర చరణౌ మనసా స్మరామి"
   //          },
   //          {
   //            "title": "శ్రీరామ చంద్ర చరణౌ వచసా గృహ్ణామి ।"
   //          },
   //          {
   //            "title": "శ్రీరామ చంద్ర చరణౌ శిరసా నమామి"
   //          },
   //          {
   //            "title": "శ్రీరామ చంద్ర చరణౌ శరణం ప్రపద్యే ॥ 29 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "మాతా రామో మత్-పితా రామచంద్రః"
   //          },
   //          {
   //            "title": "స్వామీ రామో మత్-సఖా రామచంద్రః ।"
   //          },
   //          {
   //            "title": "సర్వస్వం మే రామచంద్రో దయాళుః"
   //          },
   //          {
   //            "title": "నాన్యం జానే నైవ న జానే ॥ 30 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "దక్షిణే లక్ష్మణో యస్య వామే చ (తు) జనకాత్మజా ।"
   //          },
   //          {
   //            "title": "పురతో మారుతిర్యస్య తం వందే రఘునందనమ్ ॥ 31 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "లోకాభిరామం రణరంగధీరం"
   //          },
   //          {
   //            "title": "రాజీవనేత్రం రఘువంశనాథమ్ ।"
   //          },
   //          {
   //            "title": "కారుణ్యరూపం కరుణాకరం తం"
   //          },
   //          {
   //            "title": "శ్రీరామచంద్రం శరణ్యం ప్రపద్యే ॥ 32 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "మనోజవం మారుత తుల్య వేగం"
   //          },
   //          {
   //            "title": "జితేంద్రియం బుద్ధిమతాం వరిష్టమ్ ।"
   //          },
   //          {
   //            "title": "వాతాత్మజం వానరయూథ ముఖ్యం"
   //          },
   //          {
   //            "title": "శ్రీరామదూతం శరణం ప్రపద్యే ॥ 33 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "కూజంతం రామరామేతి మధురం మధురాక్షరమ్ ।"
   //          },
   //          {
   //            "title": "ఆరుహ్యకవితా శాఖాం వందే వాల్మీకి కోకిలమ్ ॥ 34 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఆపదామపహర్తారం దాతారం సర్వసంపదామ్ ।"
   //          },
   //          {
   //            "title": "లోకాభిరామం శ్రీరామం భూయోభూయో నమామ్యహమ్ ॥ 35 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "భర్జనం భవబీజానామర్జనం సుఖసంపదామ్ ।"
   //          },
   //          {
   //            "title": "తర్జనం యమదూతానాం రామ రామేతి గర్జనమ్ ॥ 36 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "రామో రాజమణిః సదా విజయతే రామం రమేశం భజే"
   //          },
   //          {
   //            "title": "రామేణాభిహతా నిశాచరచమూ రామాయ తస్మై నమః ।"
   //          },
   //          {
   //            "title": "రామాన్నాస్తి పరాయణం పరతరం రామస్య దాసోస్మ్యహం"
   //          },
   //          {
   //            "title": "రామే చిత్తలయః సదా భవతు మే భో రామ మాముద్ధర ॥ 37 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "శ్రీరామ రామ రామేతి రమే రామే మనోరమే ।"
   //          },
   //          {
   //            "title": "సహస్రనామ తత్తుల్యం రామ నామ వరాననే ॥ 38 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఇతి శ్రీబుధకౌశికముని విరచితం శ్రీరామ రక్షాస్తోత్రం సంపూర్ణమ్ ।"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "శ్రీరామ జయరామ జయజయరామ ।"
   //          }
   //        ],
   //        "title": "శ్రీ రామ రక్షా స్తోత్రం",
   //        "img": require('../imgs/Stotramulu/Rama.png')
   //      },
   //      {
   //        "sotram": [
   //          {
   //            "title": "శివాయ నమః ||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "శివాపరాధ క్షమాపన స్తోత్రం"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఆదౌ కర్మప్రసంగాత్ కలయతి కలుషం మాతృకుక్షౌ స్థితం మాం"
   //          },
   //          {
   //            "title": "విణ్మూత్రామేధ్యమధ్యే క్కథయతి నితరాం జాఠరో జాతవేదాః |"
   //          },
   //          {
   //            "title": "యద్యద్వై తత్ర దుఃఖం వ్యథయతి నితరాం శక్యతే కేన వక్తుం"
   //          },
   //          {
   //            "title": "క్షన్తవ్యో మేఽపరాధః శివ శివ శివ భోః శ్రీమహాదేవ శంభో ||౧||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "బాల్యే దుఃఖాతిరేకాన్మలలులితవపుః స్తన్యపానే పిపాసా"
   //          },
   //          {
   //            "title": "నో శక్తశ్చేన్ద్రియేభ్యో భవగుణజనితా జన్తవో మాం తుదన్తి |"
   //          },
   //          {
   //            "title": "నానారోగాదిదుఃఖాదుదన పరవశః శంకరం న స్మరామి"
   //          },
   //          {
   //            "title": "క్షన్తవ్యో మేఽపరాధః శివ శివ శివ భోః శ్రీమహాదేవ శంభో || ౨||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ప్రౌఢోఽహం యౌవనస్థో విషయవిషధరైః పంచభిర్మర్మసన్ధౌ"
   //          },
   //          {
   //            "title": "దష్టో నష్టోఽవివేకః సుతధనయువతిస్వాదుసౌఖ్యే నిషణ్ణః |"
   //          },
   //          {
   //            "title": "శైవీ చిన్తావిహీనం మమ హృదయమహో మానగర్వాధిరూఢం"
   //          },
   //          {
   //            "title": "క్షన్తవ్యో మేఽపరాధః శివ శివ శివ భోః శ్రీమహాదేవ శంభో || ౩||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "వార్ధక్యే చేన్ద్రియాణాం విగతగతిమతిశ్చాధిదైవాది తాపైః"
   //          },
   //          {
   //            "title": "పాపైర్రోగైర్వియోగై-స్త్వనవ సితవపుః ప్రౌఢిహీనం చ దీనమ్ |"
   //          },
   //          {
   //            "title": "మిథ్యామోహాభిలాషైర్భ్రమతి మమ మనో ధూర్జటేర్ధ్యానశూన్యం"
   //          },
   //          {
   //            "title": "క్షన్తవ్యో మేఽపరాధః శివ శివ శివ భోః శ్రీమహాదేవ శంభో ||౪||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "నో శక్యం స్మార్తకర్మ ప్రతిపదగహనప్రత్యవాయాకులాఖ్యం"
   //          },
   //          {
   //            "title": "శ్రౌతే వార్తా కథం మే ద్విజకులవిహితే బ్రహ్మమార్గే సుసారే |"
   //          },
   //          {
   //            "title": "జ్ఞాతో*ధర్మో విచారైః శ్రవణమననయోః కిం నిదిధ్యాసితవ్యం"
   //          },
   //          {
   //            "title": "క్షన్తవ్యో మేఽపరాధః శివ శివ శివ భోః శ్రీమహాదేవ శంభో ||౫||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "స్నాత్వా ప్రత్యూషకాలే స్నపనవిధివిధౌ నాహృతం గాంగతోయం"
   //          },
   //          {
   //            "title": "పూజార్థం వా కదాచిద్వహు-తరగహనాత్ఖణ్డబిల్వీదలాని |"
   //          },
   //          {
   //            "title": "క్షన్తవ్యో మేఽపరాధః శివ శివ శివ భోః శ్రీమహాదేవ శంభో ||౬||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "దుగ్ధైర్మధ్వాజ్య యుక్తైర్దధిసితసహితైః స్నాపితం నైవ లింగం"
   //          },
   //          {
   //            "title": "నో లిప్తం చన్దనాద్యైః కనక విరచితైః పూజితం న ప్రసూనైః |"
   //          },
   //          {
   //            "title": "ధూపైః కర్పూరదీపైర్వివిధరసయుతైనైవ భక్ష్యోపహారైః"
   //          },
   //          {
   //            "title": "క్షన్తవ్యో మేఽపరాధః శివ శివ శివ భోః శ్రీమహాదేవ శంభో || ౭||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "దుగ్ధైర్మధ్వాజ్య యుక్తైర్దధిసితసహితైః స్నాపితం నైవ లింగం"
   //          },
   //          {
   //            "title": "నో లిప్తం చన్దనాద్యైః కనక విరచితైః పూజితం న ప్రసూనైః |"
   //          },
   //          {
   //            "title": "ధూపైః కర్పూరదీపైర్వివిధరసయుతైనైవ భక్ష్యోపహారైః"
   //          },
   //          {
   //            "title": "క్షన్తవ్యో మేఽపరాధః శివ శివ శివ భోః శ్రీమహాదేవ శంభో || ౭||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ధ్యాత్వా చిత్తే శివాఖ్యం ప్రచురతరధనం నైవ దత్తం ద్విజేభ్యో"
   //          },
   //          {
   //            "title": "హవ్యం తే లక్షసంఖ్యైర్హుతవహవదనే నార్పితం బీజమన్త్రైః |"
   //          },
   //          {
   //            "title": "నో తప్తం గాంగతీరే వ్రతజపనియమై రుద్రజాప్యైర్న వేదైః"
   //          },
   //          {
   //            "title": "క్షన్తవ్యో మేఽపరాధః శివ శివ శివ భోః శ్రీమహాదేవ శంభో ||౮||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "స్థిత్వా స్థానే సరోజే ప్రణవమయమరుత్కుణ్డలే సూక్ష్మమార్గే"
   //          },
   //          {
   //            "title": "శాన్తే స్వాన్తే ప్రలీనే ప్రకటితబిభవే జ్యోతిరూపేఽపరాఖ్యే |"
   //          },
   //          {
   //            "title": "లింగజ్ఞే బ్రహ్మవాక్యే సకలతనుగతం శంకరం న స్మరామి"
   //          },
   //          {
   //            "title": "క్షన్తవ్యో మేఽపరాధః శివ శివ శివ భోః శ్రీమహాదేవ శంభో ||౯||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "నగ్నో నిఃసంగశుద్ధస్త్రిగుణవిరహితో ధ్వస్తమోహాన్ధకారో"
   //          },
   //          {
   //            "title": "నాసాగ్రే న్యస్తద్రుష్టిర్విదితభవగుణో నైవ ద్రుష్టః కదాచిత్ |"
   //          },
   //          {
   //            "title": "ఉన్మన్యాఽవస్థయా త్వాం విగతకలిమలం శంకరం న స్మరామి"
   //          },
   //          {
   //            "title": "క్షన్తవ్యో మేఽపరాధః శివ శివ శివ భోః శ్రీమహాదేవ శంభో || ౧౦||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "చన్ద్రోద్భాసితశేఖరే స్మరహరే గంగాధరే శంకరే"
   //          },
   //          {
   //            "title": "సర్పైర్భూషిత కణ్ఠకర్ణవివరే నేత్రోత్థవైశ్వానరే |"
   //          },
   //          {
   //            "title": "దన్తిత్వక్కౄతసున్దరాంబరధరే త్రైలోక్యసారే హరే"
   //          },
   //          {
   //            "title": "మోక్షార్థం కురు చితవృత్తిమఖిలామన్యైస్తు కిం కర్మభిః  || ౧౧||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "కిం వాఽనేన ధనేన వాజికరిభిః ప్రాప్తేన రాజ్యేన కిం"
   //          },
   //          {
   //            "title": "కిం వా పుత్రకళత్ర-మిత్రపశుభిర్దేహేన గేహేన కిమ్"
   //          },
   //          {
   //            "title": "జ్ఞాత్వైతత్క్షణభఙ్గురం సపదిరే త్యాజ్యం మనో దూరతః"
   //          },
   //          {
   //            "title": "స్వాత్మార్థం గురువాక్యతో భజ భజ శ్రీపార్వతీవల్లభమ్ || ౧౨||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఆయుర్నశ్యతి పశ్యతాం ప్రతిదినం యాతి క్షయం యౌవనం"
   //          },
   //          {
   //            "title": "ప్రాత్యాయాన్తి గతాః పునర్న దివసాః కాలో జగద్భక్షకః"
   //          },
   //          {
   //            "title": "లక్ష్మీస్తోయతరంగభంగచపలా విద్యుచ్చలం జీవితం"
   //          },
   //          {
   //            "title": "తస్మాన్మాం శరణాగతం శరణద త్వం రక్ష రక్షాధునా || ౧౩||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "కరచరణకృతం వాక్కాయజం కర్మజం వా"
   //          },
   //          {
   //            "title": "శ్రవణనయనజం వా మానసం వాఽపరాధమ్ |"
   //          },
   //          {
   //            "title": "విహితమవిహితం వా సర్వమేతత్క్షమస్వ"
   //          },
   //          {
   //            "title": "జయ జయ కరుణాబ్ధే శ్రీమహాదేవ శంభో|| ౧౪||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఇతి శ్రీమచ్ఛఙ్కరాచార్యవిరచితం శివాపరాధక్షమాపనస్తోత్రం సంపూర్ణమ్ ||"
   //          }
   //        ],
   //        "title": "శివాపరాధ క్షమాపన స్తోత్రం",
   //        "img": require('../imgs/Stotramulu/Siva.png')
   //      },
   //      {
   //        "sotram": [
   //          {
   //            "title": "యా కుందేందు తుషారహారధవళా యా శుభ్రవస్త్రావృతా"
   //          },
   //          {
   //            "title": "యా వీణావరదండమండితకరా యా శ్వేతపద్మాసనా ।"
   //          },
   //          {
   //            "title": "యా బ్రహ్మాచ్యుత శంకరప్రభృతిభిర్దేవైస్సదా పూజితా"
   //          },
   //          {
   //            "title": "సా మాం పాతు సరస్వతీ భగవతీ నిశ్శేషజాడ్యాపహా ॥ 1 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "దోర్భిర్యుక్తా చతుర్భిః స్ఫటికమణినిభై రక్షమాలాందధానా"
   //          },
   //          {
   //            "title": "హస్తేనైకేన పద్మం సితమపిచ శుకం పుస్తకం చాపరేణ ।"
   //          },
   //          {
   //            "title": "భాసా కుందేందుశంఖస్ఫటికమణినిభా భాసమానాzసమానా"
   //          },
   //          {
   //            "title": "సా మే వాగ్దేవతేయం నివసతు వదనే సర్వదా సుప్రసన్నా ॥ 2 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సురాసురైస్సేవితపాదపంకజా కరే విరాజత్కమనీయపుస్తకా ।"
   //          },
   //          {
   //            "title": "విరించిపత్నీ కమలాసనస్థితా సరస్వతీ నృత్యతు వాచి మే సదా ॥ 3 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సరస్వతీ సరసిజకేసరప్రభా తపస్వినీ సితకమలాసనప్రియా ।"
   //          },
   //          {
   //            "title": "ఘనస్తనీ కమలవిలోలలోచనా మనస్వినీ భవతు వరప్రసాదినీ ॥ 4 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సరస్వతి నమస్తుభ్యం వరదే కామరూపిణి ।"
   //          },
   //          {
   //            "title": "విద్యారంభం కరిష్యామి సిద్ధిర్భవతు మే సదా ॥ 5 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సరస్వతి నమస్తుభ్యం సర్వదేవి నమో నమః ।"
   //          },
   //          {
   //            "title": "శాంతరూపే శశిధరే సర్వయోగే నమో నమః ॥ 6 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "నిత్యానందే నిరాధారే నిష్కళాయై నమో నమః ।"
   //          },
   //          {
   //            "title": "విద్యాధరే విశాలాక్షి శుద్ధజ్ఞానే నమో నమః ॥ 7 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "శుద్ధస్ఫటికరూపాయై సూక్ష్మరూపే నమో నమః ।"
   //          },
   //          {
   //            "title": "శబ్దబ్రహ్మి చతుర్హస్తే సర్వసిద్ధ్యై నమో నమః ॥ 8 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ముక్తాలంకృత సర్వాంగ్యై మూలాధారే నమో నమః ।"
   //          },
   //          {
   //            "title": "మూలమంత్రస్వరూపాయై మూలశక్త్యై నమో నమః ॥ 9 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "మనోన్మని మహాభోగే వాగీశ్వరి నమో నమః ।"
   //          },
   //          {
   //            "title": "వాగ్మ్యై వరదహస్తాయై వరదాయై నమో నమః ॥ 10 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "వేదాయై వేదరూపాయై వేదాంతాయై నమో నమః ।"
   //          },
   //          {
   //            "title": "గుణదోషవివర్జిన్యై గుణదీప్త్యై నమో నమః ॥ 11 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సర్వజ్ఞానే సదానందే సర్వరూపే నమో నమః ।"
   //          },
   //          {
   //            "title": "సంపన్నాయై కుమార్యై చ సర్వజ్ఞే తే నమో నమః ॥ 12 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "యోగానార్య ఉమాదేవ్యై యోగానందే నమో నమః ।"
   //          },
   //          {
   //            "title": "దివ్యజ్ఞాన త్రినేత్రాయై దివ్యమూర్త్యై నమో నమః ॥ 13 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అర్ధచంద్రజటాధారి చంద్రబింబే నమో నమః ।"
   //          },
   //          {
   //            "title": "చంద్రాదిత్యజటాధారి చంద్రబింబే నమో నమః ॥ 14 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అణురూపే మహారూపే విశ్వరూపే నమో నమః ।"
   //          },
   //          {
   //            "title": "అణిమాద్యష్టసిద్ధాయై ఆనందాయై నమో నమః ॥ 15 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "జ్ఞాన విజ్ఞాన రూపాయై జ్ఞానమూర్తే నమో నమః ।"
   //          },
   //          {
   //            "title": "నానాశాస్త్ర స్వరూపాయై నానారూపే నమో నమః ॥ 16 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "పద్మజా పద్మవంశా చ పద్మరూపే నమో నమః ।"
   //          },
   //          {
   //            "title": "పరమేష్ఠ్యై పరామూర్త్యై నమస్తే పాపనాశినీ ॥ 17 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "మహాదేవ్యై మహాకాళ్యై మహాలక్ష్మ్యై నమో నమః ।"
   //          },
   //          {
   //            "title": "బ్రహ్మవిష్ణుశివాయై చ బ్రహ్మనార్యై నమో నమః ॥ 18 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "కమలాకరపుష్పా చ కామరూపే నమో నమః ।"
   //          },
   //          {
   //            "title": "కపాలికర్మదీప్తాయై కర్మదాయై నమో నమః ॥ 19 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సాయం ప్రాతః పఠేన్నిత్యం షణ్మాసాత్సిద్ధిరుచ్యతే ।"
   //          },
   //          {
   //            "title": "చోరవ్యాఘ్రభయం నాస్తి పఠతాం శృణ్వతామపి ॥ 20 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఇత్థం సరస్వతీ స్తోత్రమగస్త్యముని వాచకమ్ ।"
   //          },
   //          {
   //            "title": "సర్వసిద్ధికరం నౄణాం సర్వపాపప్రణాశనమ్ ॥ 21 ॥"
   //          }
   //        ],
   //        "title": "సరస్వతీ స్తోత్రం",
   //        "img": require('../imgs/Stotramulu/Saraswathi.png')
   //      },
   //      {
   //        "sotram": [
   //          {
   //            "title": "@శాంతిపాఠః"
   //          },
   //          {
   //            "title": "ఓం యో బ్రహ్మాణం విదధాతి పూర్వం"
   //          },
   //          {
   //            "title": "యో వై వేదాంశ్చ ప్రహిణోతి తస్మై ।"
   //          },
   //          {
   //            "title": "తంహదేవమాత్మ బుద్ధిప్రకాశం"
   //          },
   //          {
   //            "title": "ముముక్షుర్వై శరణమహం ప్రపద్యే ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@ధ్యానం"
   //          },
   //          {
   //            "title": "ఓం మౌనవ్యాఖ్యా ప్రకటితపరబ్రహ్మతత్వంయువానం"
   //          },
   //          {
   //            "title": "వర్శిష్ఠాంతేవసదృషిగణైరావృతం బ్రహ్మనిష్ఠైః ।"
   //          },
   //          {
   //            "title": "ఆచార్యేంద్రం కరకలిత చిన్ముద్రమానందమూర్తిం"
   //          },
   //          {
   //            "title": "స్వాత్మరామం ముదితవదనం దక్షిణామూర్తిమీడే ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "వటవిటపిసమీపే భూమిభాగే నిషణ్ణం"
   //          },
   //          {
   //            "title": "సకలమునిజనానాం జ్ఞానదాతారమారాత్ ।"
   //          },
   //          {
   //            "title": "త్రిభువనగురుమీశం దక్షిణామూర్తిదేవం"
   //          },
   //          {
   //            "title": "జననమరణదుఃఖచ్ఛేద దక్షం నమామి ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "చిత్రం వటతరోర్మూలే వృద్ధాః శిష్యాః గురుర్యువా ।"
   //          },
   //          {
   //            "title": "గురోస్తు మౌనవ్యాఖ్యానం శిష్యాస్తుచ్ఛిన్నసంశయాః ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఓం నమః ప్రణవార్థాయ శుద్ధజ్ఞానైకమూర్తయే ।"
   //          },
   //          {
   //            "title": "నిర్మలాయ ప్రశాంతాయ దక్షిణామూర్తయే నమః ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "గురుర్బ్రహ్మా గురుర్విష్ణుః గురుర్దేవో మహేశ్వరః ।"
   //          },
   //          {
   //            "title": "గురుస్సాక్షాత్ పరం బ్రహ్మా తస్మై శ్రీ గురవే నమః ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "నిధయే సర్వవిద్యానాం భిషజే భవరోగిణామ్ ।"
   //          },
   //          {
   //            "title": "గురవే సర్వలోకానాం దక్షిణామూర్తయే నమః ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "చిదోఘనాయ మహేశాయ వటమూలనివాసినే ।"
   //          },
   //          {
   //            "title": "సచ్చిదానంద రూపాయ దక్షిణామూర్తయే నమః ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఈశ్వరో గురురాత్మేతి మూర్తిభేద విభాగినే ।"
   //          },
   //          {
   //            "title": "వ్యోమవద్-వ్యాప్తదేహాయ దక్షిణామూర్తయే నమః ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అంగుష్ఠతర్జనీ యోగముద్రా వ్యాజేనయోగినామ్ ।"
   //          },
   //          {
   //            "title": "శృత్యర్థం బ్రహ్మజీవైక్యం దర్శయన్యోగతా శివః ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఓం శాంతిః శాంతిః శాంతిః ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "@స్తోత్రం"
   //          },
   //          {
   //            "title": "విశ్వందర్పణ దృశ్యమాన నగరీ తుల్యం నిజాంతర్గతం"
   //          },
   //          {
   //            "title": "పశ్యన్నాత్మని మాయయా బహిరివోద్భూతం యథానిద్రయా ।"
   //          },
   //          {
   //            "title": "యస్సాక్షాత్కురుతే ప్రభోధసమయే స్వాత్మానమే వాద్వయం"
   //          },
   //          {
   //            "title": "తస్మై శ్రీగురుమూర్తయే నమ ఇదం శ్రీ దక్షిణామూర్తయే ॥ 1 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "బీజస్యాంతతి వాంకురో జగదితం ప్రాఙ్నర్వికల్పం పునః"
   //          },
   //          {
   //            "title": "మాయాకల్పిత దేశకాలకలనా వైచిత్ర్యచిత్రీకృతమ్ ।"
   //          },
   //          {
   //            "title": "మాయావీవ విజృంభయత్యపి మహాయోగీవ యః స్వేచ్ఛయా"
   //          },
   //          {
   //            "title": "తస్మై శ్రీగురుమూర్తయే నమ ఇదం శ్రీ దక్షిణామూర్తయే ॥ 2 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "యస్యైవ స్ఫురణం సదాత్మకమసత్కల్పార్థకం భాసతే"
   //          },
   //          {
   //            "title": "సాక్షాత్తత్వమసీతి వేదవచసా యో బోధయత్యాశ్రితాన్ ।"
   //          },
   //          {
   //            "title": "యస్సాక్షాత్కరణాద్భవేన్న పురనావృత్తిర్భవాంభోనిధౌ"
   //          },
   //          {
   //            "title": "తస్మై శ్రీగురుమూర్తయే నమ ఇదం శ్రీ దక్షిణామూర్తయే ॥ 3 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "నానాచ్ఛిద్ర ఘటోదర స్థిత మహాదీప ప్రభాభాస్వరం"
   //          },
   //          {
   //            "title": "జ్ఞానం యస్య తు చక్షురాదికరణ ద్వారా బహిః స్పందతే ।"
   //          },
   //          {
   //            "title": "జానామీతి తమేవ భాంతమనుభాత్యేతత్సమస్తం జగత్"
   //          },
   //          {
   //            "title": "తస్మై శ్రీ గురుమూర్తయే నమ ఇదం శ్రీ దక్షిణామూర్తయే ॥ 4 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "దేహం ప్రాణమపీంద్రియాణ్యపి చలాం బుద్ధిం చ శూన్యం విదుః"
   //          },
   //          {
   //            "title": "స్త్రీ బాలాంధ జడోపమాస్త్వహమితి భ్రాంతాభృశం వాదినః ।"
   //          },
   //          {
   //            "title": "మాయాశక్తి విలాసకల్పిత మహావ్యామోహ సంహారిణే"
   //          },
   //          {
   //            "title": "తస్మై శ్రీ గురుమూర్తయే నమ ఇదం శ్రీ దక్షిణామూర్తయే ॥ 5 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "రాహుగ్రస్త దివాకరేందు సదృశో మాయా సమాచ్ఛాదనాత్"
   //          },
   //          {
   //            "title": "సన్మాత్రః కరణోప సంహరణతో యోఽభూత్సుషుప్తః పుమాన్ ।"
   //          },
   //          {
   //            "title": "ప్రాగస్వాప్సమితి ప్రభోదసమయే యః ప్రత్యభిజ్ఞాయతే"
   //          },
   //          {
   //            "title": "తస్మై శ్రీ గురుమూర్తయే నమ ఇదం శ్రీ దక్షిణామూర్తయే ॥ 6 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "బాల్యాదిష్వపి జాగ్రదాదిషు తథా సర్వాస్వవస్థాస్వపి"
   //          },
   //          {
   //            "title": "వ్యావృత్తా స్వను వర్తమాన మహమిత్యంతః స్ఫురంతం సదా ।"
   //          },
   //          {
   //            "title": "స్వాత్మానం ప్రకటీకరోతి భజతాం యో ముద్రయా భద్రయా"
   //          },
   //          {
   //            "title": "తస్మై శ్రీ గురుమూర్తయే నమ ఇదం శ్రీ దక్షిణామూర్తయే ॥ 7 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "విశ్వం పశ్యతి కార్యకారణతయా స్వస్వామిసంబంధతః"
   //          },
   //          {
   //            "title": "శిష్యచార్యతయా తథైవ పితృ పుత్రాద్యాత్మనా భేదతః ।"
   //          },
   //          {
   //            "title": "స్వప్నే జాగ్రతి వా య ఏష పురుషో మాయా పరిభ్రామితః"
   //          },
   //          {
   //            "title": "తస్మై శ్రీ గురుమూర్తయే నమ ఇదం శ్రీ దక్షిణామూర్తయే ॥ 8 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "భూరంభాంస్యనలోఽనిలోంబర మహర్నాథో హిమాంశుః పుమాన్"
   //          },
   //          {
   //            "title": "ఇత్యాభాతి చరాచరాత్మకమిదం యస్యైవ మూర్త్యష్టకమ్ ।"
   //          },
   //          {
   //            "title": "నాన్యత్కించన విద్యతే విమృశతాం యస్మాత్పరస్మాద్విభో"
   //          },
   //          {
   //            "title": "తస్మై గురుమూర్తయే నమ ఇదం శ్రీ దక్షిణామూర్తయే ॥ 9 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సర్వాత్మత్వమితి స్ఫుటీకృతమిదం యస్మాదముష్మిన్ స్తవే"
   //          },
   //          {
   //            "title": "తేనాస్వ శ్రవణాత్తదర్థ మననాద్ధ్యానాచ్చ సంకీర్తనాత్ ।"
   //          },
   //          {
   //            "title": "సర్వాత్మత్వమహావిభూతి సహితం స్యాదీశ్వరత్వం స్వతః"
   //          },
   //          {
   //            "title": "సిద్ధ్యేత్తత్పునరష్టధా పరిణతం చైశ్వర్య మవ్యాహతమ్ ॥ 10 ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "॥ ఇతి శ్రీమచ్ఛంకరాచార్యవిరచితం దక్షిణాముర్తిస్తోత్రం సంపూర్ణమ్ ॥"
   //          }
   //        ],
   //        "title": "దక్షిణా మూర్తి స్తోత్రం",
   //        "img": require('../imgs/Stotramulu/Dakshinamurthy.png')
   //      },
   //      {
   //        "sotram": [
   //          {
   //            "title": "కమలాకుచ చూచుక కుంకమతో"
   //          },
   //          {
   //            "title": "నియతారుణి తాతుల నీలతనో ।"
   //          },
   //          {
   //            "title": "కమలాయత లోచన లోకపతే"
   //          },
   //          {
   //            "title": "విజయీభవ వేంకట శైలపతే ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సచతుర్ముఖ షణ్ముఖ పంచముఖే"
   //          },
   //          {
   //            "title": "ప్రముఖా ఖిలదైవత మౌళిమణే ।"
   //          },
   //          {
   //            "title": "శరణాగత వత్సల సారనిధే"
   //          },
   //          {
   //            "title": "పరిపాలయ మాం వృష శైలపతే ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అతివేలతయా తవ దుర్విషహై"
   //          },
   //          {
   //            "title": "రను వేలకృతై రపరాధశతైః ।"
   //          },
   //          {
   //            "title": "భరితం త్వరితం వృష శైలపతే"
   //          },
   //          {
   //            "title": "పరయా కృపయా పరిపాహి హరే ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అధి వేంకట శైల ముదారమతే-"
   //          },
   //          {
   //            "title": "ర్జనతాభి మతాధిక దానరతాత్ ।"
   //          },
   //          {
   //            "title": "పరదేవతయా గదితానిగమైః"
   //          },
   //          {
   //            "title": "కమలాదయితాన్న పరంకలయే ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "కల వేణుర వావశ గోపవధూ"
   //          },
   //          {
   //            "title": "శత కోటి వృతాత్స్మర కోటి సమాత్ ।"
   //          },
   //          {
   //            "title": "ప్రతి పల్లవికాభి మతాత్-సుఖదాత్"
   //          },
   //          {
   //            "title": "వసుదేవ సుతాన్న పరంకలయే ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అభిరామ గుణాకర దాశరధే"
   //          },
   //          {
   //            "title": "జగదేక ధనుర్థర ధీరమతే ।"
   //          },
   //          {
   //            "title": "రఘునాయక రామ రమేశ విభో"
   //          },
   //          {
   //            "title": "వరదో భవ దేవ దయా జలధే ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అవనీ తనయా కమనీయ కరం"
   //          },
   //          {
   //            "title": "రజనీకర చారు ముఖాంబురుహమ్ ।"
   //          },
   //          {
   //            "title": "రజనీచర రాజత మోమి హిరం"
   //          },
   //          {
   //            "title": "మహనీయ మహం రఘురామమయే ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సుముఖం సుహృదం సులభం సుఖదం"
   //          },
   //          {
   //            "title": "స్వనుజం చ సుకాయమ మోఘశరమ్ ।"
   //          },
   //          {
   //            "title": "అపహాయ రఘూద్వయ మన్యమహం"
   //          },
   //          {
   //            "title": "న కథంచన కంచన జాతుభజే ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "వినా వేంకటేశం న నాథో న నాథః"
   //          },
   //          {
   //            "title": "సదా వేంకటేశం స్మరామి స్మరామి ।"
   //          },
   //          {
   //            "title": "హరే వేంకటేశ ప్రసీద ప్రసీద"
   //          },
   //          {
   //            "title": "ప్రియం వేంకటెశ ప్రయచ్ఛ ప్రయచ్ఛ ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అహం దూరదస్తే పదాం భోజయుగ్మ"
   //          },
   //          {
   //            "title": "ప్రణామేచ్ఛయా గత్య సేవాం కరోమి ।"
   //          },
   //          {
   //            "title": "సకృత్సేవయా నిత్య సేవాఫలం త్వం"
   //          },
   //          {
   //            "title": "ప్రయచ్ఛ పయచ్ఛ ప్రభో వేంకటేశ ॥"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అజ్ఞానినా మయా దోషా న శేషాన్విహితాన్ హరే ।"
   //          },
   //          {
   //            "title": "క్షమస్వ త్వం క్షమస్వ త్వం శేషశైల శిఖామణే ॥"
   //          }
   //        ],
   //        "title": "శ్రీ వేంకటేశ్వర స్తోత్రం",
   //        "img": require('../imgs/Stotramulu/VenkateswaraSwami.png')
   //      },
   //      {
   //        "sotram": [
   //          {
   //            "title": "అకారణాయాఖిలకారణాయ నమో మహాకారణకారణాయ |"
   //          },
   //          {
   //            "title": "నమోఽస్తు కాలానలలోచనాయ కృతాగసం మామవ విశ్వమూర్తే ||౧||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "నమోఽస్త్వహీనాభరణాయ నిత్యం నమః పశూనాం పతయే మృడాయ |"
   //          },
   //          {
   //            "title": "వేదాన్తవేద్యాయ నమో నమస్తే కృతాగసం మామవ విశ్వమూర్తే ||౨||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "నమోఽస్తు భక్తేహితదానదాత్రే సర్వౌషధీనాం పతయే నమోఽస్తు |"
   //          },
   //          {
   //            "title": "బ్రహ్మణ్యదేవాయ నమో నమస్తే కృతాగసం మామవ విశ్వమూర్తే||౩||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "కాలాయ కాలానలసన్నిభాయ హిరణ్యగర్భాయ నమో నమస్తే |"
   //          },
   //          {
   //            "title": "హాలాహలాదాయ సదా నమస్తే కృతాగసం మామవ విశ్వమూర్తే ||౪||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "విరిఞ్చినారాయణశక్రముఖ్యైరజ్ఞాతవీర్యాయ నమో నమస్తే |"
   //          },
   //          {
   //            "title": "సూక్ష్మాతిసూక్ష్మాయ నమోఽఘహన్త్రే కృతాగసం మామవ విశ్వమూర్తే ||౫||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అనేకకోటీన్దునిభాయ తేఽస్తు నమో గిరీణాం పతయేఽఘహన్త్రే |"
   //          },
   //          {
   //            "title": "నమోఽస్తు తే భక్తవిపద్ధరాయ కృతాగసం మామవ విశ్వమూర్తే || ౬||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "సర్వాన్తరస్థాయ విశుద్ధధామ్నే నమోఽస్తు తే దుష్టకులాన్తకాయ |"
   //          },
   //          {
   //            "title": "సమస్తతేజోనిధయే నమస్తే కృతాగసం మామవ విశ్వమూర్తే ||౭||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "యజ్ఞాయ యజ్ఞాదిఫలప్రదాత్రే యజ్ఞస్వరూపాయ నమో నమస్తే |"
   //          },
   //          {
   //            "title": "నమో మహానన్దమయాయ నిత్యం కృతాగసం మామవ విశ్వమూర్తే ||౮||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఇతి స్తుతో మహాదేవో దక్షం ప్రాహ కృతాఞ్జలిమ్ |"
   //          },
   //          {
   //            "title": "యత్తేఽభిలషితం దక్ష తత్తే దాస్యామ్యహం ధ్రువమ్ ||౯|| "
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "అన్యచ్చ శ్రృణు భో దక్ష యచ్చ కిఞ్చిద్బ్రవీమ్యహమ్ |"
   //          },
   //          {
   //            "title": "యత్కృతం హి మమ స్తోత్రం త్వయా భక్త్యా ప్రజాపతే ||౧౦||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "యే శ్రద్ధయా పఠిష్యన్తి మానవాః ప్రత్యహం శుభమ్ |"
   //          },
   //          {
   //            "title": "నిష్కల్మషా భవిష్యన్తి సాపరాధా అపి ధ్రువమ్ ||౧౧||"
   //          },
   //          {
   //            "title": ""
   //          },
   //          {
   //            "title": "ఇతి దక్షకృతం విశ్వమూర్తిస్తోత్రం సంపూర్ణమ్ ||"
   //          }
   //        ],
   //        "title": "విశ్వమూర్తి స్తోత్రం",
   //        "img": require('../imgs/Stotramulu/Siva.png')
   //      }
   //    ]
   //  },
  }
  export default otherMoreData;