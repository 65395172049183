
const krishnaastakam = [
  {
    title: "ఓం శ్రీ కృష్ణాయ నమః"
  },
  {
    title: "ఓం కమలానాథాయ నమః"
  },
  {
    title: "ఓం వాసుదేవాయ నమః"
  },
  {
    title: "ఓం సనాతనాయ నమః"
  },
  {
    title: "ఓం వసుదేవత్మాజాయ నమః"
  },
  {
    title: "ఓం పుణ్యాయ నమః"
  },
  {
    title: "ఓం లీలామానుష విగ్రహాయ నమః"
  },
  {
    title: "ఓం శ్రీవత్స కౌస్తుభ ధరాయ నమః"
  },
  {
    title: "ఓం యశోదావత్సలాయ నమః"
  },
  {
    title: "ఓం హరిఃయే నమః || 10 ||"
  },
  {
    title: ""
  },
  {
    title: "ఓం చతుర్భుజాత్త చక్రాసిగదా నమః"
  },
  {
    title: "ఓం శంఖాంబుజాయుధాయ నమః"
  },
  {
    title: "ఓం దేవకీ నందనాయ- శ్రీ శాయ నమః"
  },
  {
    title: "ఓం నందగోప ప్రియాత్మజాయ నమః"
  },
  {
    title: "ఓం యమునావేగసంహారిణే నమః"
  },
  {
    title: "ఓం బలభద్ర ప్రియానుజాయ నమః"
  },
  {
    title: "ఓం పూతనాజీవితహరణాయ నమః"
  },
  {
    title: "ఓం శకటాసురభంజనాయ నమః"
  },
  {
    title: "ఓం నందవ్రజజానందినే నమః"
  },
  {
    title: "ఓం సచ్చిదానందవిగ్రహాయ నమః || 20 ||"
  },
  {
    title: ""
  },
  {
    title: "ఓం నవనీతవిలిప్తాంగాయ నమః"
  },
  {
    title: "ఓం నవనీతనటాయ నమః"
  },
  {
    title: "ఓం అనఘాయ నమః"
  },
  {
    title: "ఓం నవనీతనవహారాయ నమః"
  },
  {
    title: "ఓం ముచుకుద ప్రసాధకాయ నమః"
  },
  {
    title: "ఓం షోడశ స్త్రిసహస్రేశాయ నమః"
  },
  {
    title: "ఓం త్రిభంగినే నమః"
  },
  {
    title: "ఓం మధురాకృతయే నమః"
  },
  {
    title: "ఓం శుకవాగమృతాబ్ధీందనే నమః"
  },
  {
    title: "ఓం గోవిందాయ నమః || 30 ||"
  },
  {
    title: ""
  },
  {
    title: "ఓం యోగినాంపతయే నమః"
  },
  {
    title: "ఓం వత్సవాటచరాయ నమః"
  },
  {
    title: "ఓం అనంతాయ నమః"
  },
  {
    title: "ఓం ధేనుకాసుర భంజనాయ నమః"
  },
  {
    title: "ఓం తృణీకృత తృణావర్తాయ నమః"
  },
  {
    title: "ఓం యమళార్జున భంజనాయ నమః"
  },
  {
    title: "ఓం ఉత్తాలోత్తాలభేత్రే నమః"
  },
  {
    title: "ఓం తమా శ్యామలకృతయే నమః"
  },
  {
    title: "ఓం గోపగోపీశ్వరాయ నమః"
  },
  {
    title: "ఓం యోగినే నమః || 40 ||"
  },
  {
    title: ""
  },
  {
    title: "ఓం కోటిసూర్య సమప్రభాయ నమః"
  },
  {
    title: "ఓం ఇళాపతయే నమః"
  },
  {
    title: "ఓం పరంజ్యోతిషే నమః"
  },
  {
    title: "ఓం యాదవేంద్రాయ నమః"
  },
  {
    title: "ఓం యధూద్వహాయ నమః"
  },
  {
    title: "ఓం వనమాలినే నమః"
  },
  {
    title: "ఓం పీతవాససే నమః"
  },
  {
    title: "ఓం పారిజాతపహారకాయ నమః"
  },
  {
    title: "ఓం గోవర్ధన చలోర్దర్త్రే నమః"
  },
  {
    title: "ఓం గోపాలాయ నమః || 50 ||"
  },
  {
    title: ""
  },
  {
    title: "ఓం సర్వపాలకాయ నమః"
  },
  {
    title: "ఓం అజాయ- నిరంజనాయ నమః"
  },
  {
    title: "ఓం కామజనకాయ నమః"
  },
  {
    title: "ఓం కంజలోచనాయ నమః"
  },
  {
    title: "ఓం మధుఘ్నే నమః"
  },
  {
    title: "ఓం మధురానాథాయ నమః"
  },
  {
    title: "ఓం ద్వారకానాయకాయ నమః"
  },
  {
    title: "ఓం బలినే నమః"
  },
  {
    title: "ఓం బృందావనాంతసంచారిణే నమః"
  },
  {
    title: "ఓం తులసి దామ భూషణాయ నమః || 60 ||"
  },
  {
    title: ""
  },
  {
    title: "ఓం శ్యామంతమణిహర్త్రే నమః"
  },
  {
    title: "ఓం నరనారాయణాత్మకాయ నమః"
  },
  {
    title: "ఓం కుబ్జాకృష్ణాంబర ధరాయ నమః"
  },
  {
    title: "ఓం మాయినే నమః"
  },
  {
    title: "ఓం పరమ పురుషాయ నమః"
  },
  {
    title: "ఓం మిస్టి కాసు ర చాణూర నమః"
  },
  {
    title: "ఓం మల్లయుద్ధ విశారదాయ నమః"
  },
  {
    title: "ఓం సంసార వైరిణే నమః"
  },
  {
    title: "ఓం కంసారినే నమః"
  },
  {
    title: "ఓం మురారి నే నమః || 70 ||"
  },
  {
    title: ""
  },
  {
    title: "ఓం నరకాంతకాయ నమః"
  },
  {
    title: "ఓం అనాది బ్రహ్మచారిణే నమః"
  },
  {
    title: "ఓం కృష్ణావ్యసనకర్శ కాయ నమః"
  },
  {
    title: "ఓం శిశుపాలశిరచ్చేత్రే నమః"
  },
  {
    title: "ఓం దుర్యోధన కులాంత కృతే నమః"
  },
  {
    title: "ఓం విదుర క్రూర వరదాయ నమః"
  },
  {
    title: "ఓం విశ్వరూప ప్రదర్శ కాయ నమః"
  },
  {
    title: "ఓం సత్య వాచయే నమః"
  },
  {
    title: "ఓం సత్యసంకల్పాయ నమః"
  },
  {
    title: "ఓం సత్యభామారతాయ నమః || 80 ||"
  },
  {
    title: ""
  },
  {
    title: "ఓం జయినే నమః"
  },
  {
    title: "ఓం సుభద్రా పూర్వజాయ నమః"
  },
  {
    title: "ఓం విష్ణవే నమః"
  },
  {
    title: "ఓం భీష్మ ముక్తి ప్రదాయ కాయ నమః"
  },
  {
    title: "ఓం జగద్గురవే నమః"
  },
  {
    title: "ఓం జగన్నాధాయ నమః"
  },
  {
    title: "ఓం వేణునాద విశారదాయ నమః"
  },
  {
    title: "ఓం వృషభాసుర విధ్వంసినీ నమః"
  },
  {
    title: "ఓం బాణాసుర కరాంత కృతే నమః"
  },
  {
    title: "ఓం యుధిష్టర ప్రతిష్ట త్రే నమః || 90 ||"
  },
  {
    title: ""
  },
  {
    title: "ఓం బర్హిబర్హవతంసకాయ నమః"
  },
  {
    title: "ఓం పార్ధసారధియే నమః"
  },
  {
    title: "ఓం అవ్యక్తాయ నమః"
  },
  {
    title: "ఓం గీతామృతమశ్రీహోదధయే నమః"
  },
  {
    title: "ఓం కాళీయఫణిమాణిక్య రంజితశ్రీ పదాంబుజాయ నమః"
  },
  {
    title: "ఓం దామోదరాయ నమః"
  },
  {
    title: "ఓం యజ్ఞభోక్ష్యె నమః"
  },
  {
    title: "ఓం దానవేంద్రవినాశకాయ నమః"
  },
  {
    title: "ఓం నారాయణాయ నమః"
  },
  {
    title: "ఓం పరబ్రహ్మణే నమః || 100 ||"
  },
  {
    title: ""
  },
  {
    title: "ఓం పన్నాగాశనవాహయ నమః"
  },
  {
    title: "ఓం జలక్రీడాసమాసక్త గోపి వస్త్రాపహారకాయ నమః"
  },
  {
    title: "ఓం పుణ్యశ్లోకాయ నమః"
  },
  {
    title: "ఓం తీర్ధకృతే శ్రీ వేధవేద్యాయ నమః"
  },
  {
    title: "ఓం దయానిధాయే నమః"
  },
  {
    title: "ఓం సరస్వతీర్దాత్మకాయ నమః"
  },
  {
    title: "ఓం సర్వగ్రహరూపిణే నమః"
  },
  {
    title: "ఓం శ్రీ పరాత్పరాయ నమః || 108 ||"
  },
  {
    title: ""
  },
  {
    title: "ఇతి శ్రీ కృష్ణ అష్టోత్తరము సంపూర్ణం ||"
  }
  // img: require("../imgs/Astotaram/"),
]
export default krishnaastakam;