import React, { useEffect, useState } from "react";
import { LocalData } from "../assets/jsons/localdata";
import Rectangle1545 from '../assets/images/Rectangle 1545.png';
import Rectangle1547 from "../assets/images/Rectangle 1547.png";

function Pandagalu() {
    const [displayedFestivals, setDisplayedFestivals] = useState([]);
    const formatDate = (dateString) => {
        // Mapping of full day names to their shortened forms
        const dayOfWeekMap = {
            'ఆదివారము': 'ఆది',
            'సోమవారము': 'సోమ',
            'మంగళవారము': 'మంగళ',
            'బుధవారము': 'బుధ',
            'గురువారము': 'గురు',
            'శుక్రవారము': 'శుక్ర',
            'శనివారము': 'శని'
        };

        const [dayOfWeek, rest] = dateString.split(', ');
        const [day, monthYear] = rest.split(' ');

        // Shorten the day of the week using the map
        const shortenedDayOfWeek = dayOfWeekMap[dayOfWeek] || dayOfWeek;

        return { dayOfWeek: shortenedDayOfWeek, day, monthYear };
    };
    useEffect(() => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth(); // 0-indexed: 0 = January, 11 = December
        const currentDay = currentDate.getDate(); // Get the current day


        const currentYearData = LocalData.FestivalUpdated[0]; // Access the current year data


        let currentMonthData = currentYearData[currentMonth].day.filter(festival => {
            const festivalDay = parseInt(festival.Date.split(', ')[1].split(' ')[0], 10);

            return festivalDay >= currentDay; // Get festivals from today onwards
        });

        // If there are not enough festivals this month, go to the next month's data
        if (currentMonthData.length < 3 && currentMonth < 11) {
            const nextMonthData = currentYearData[currentMonth + 1].day;

            currentMonthData = currentMonthData.concat(nextMonthData.slice(0, 3 - currentMonthData.length));

        }

        // If still not enough festivals, check next year (if needed)
        if (currentMonthData.length < 3 && LocalData.FestivalUpdated.length > 1) {
            const nextYearData = LocalData.FestivalUpdated[1][0].day; // Start from January of the next year
            currentMonthData = currentMonthData.concat(nextYearData.slice(0, 3 - currentMonthData.length));
        }

        // Display only the next 3 upcoming festivals including today
        setDisplayedFestivals(currentMonthData);

    }, []);

    return (
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-3 center">
            <div className="schedule-card">
                <div className="overlay"></div><br></br>
                <div className="content">
                    <h1 className="Anek PandagaluTit" >సెలవులు & పండుగలు</h1>
                    <br />
                    <div style={{ marginTop: "-5px" }}>
                        <p>
                            {displayedFestivals.length > 0 ? (
                                displayedFestivals.map((festivalInfo, index) => {
                                    const { dayOfWeek, day, monthYear } = formatDate(festivalInfo.Date);
                                    return (
                                        <div key={index}>
                                            <div className="row" style={{ marginBottom: '-20px' }}>
                                                <div className="col-lg-3 col-md-4 col-sm-6">
                                                    <div className="rectangle" style={{ position: 'relative', width: '100%', marginBottom: '30px' }}>
                                                        <img className="rectangle" src={Rectangle1545} style={{height: 'auto' }} />
                                                        <div style={{
                                                            position: 'absolute',
                                                            top: '55%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            color: '#FFFFFF',
                                                            textAlign: 'center',
                                                            width: '100%',
                                                        }}>
                                                            <h5 className="PandagaluIndex" style={{ fontFamily: "Anek Telugu",color: '#FFFFFF', marginBottom: '3px' }}>{monthYear}</h5>
                                                            <div 
                                                            className="Insideborder"
                                                            style={{
                                                                
                                                                borderBottom: '2px solid #CB874A',
                                                                // width: '80%',
                                                                margin: '0 auto 10px auto',
                                                                marginBottom: '1px'
                                                            }}></div>
                                                            <small className="PandagaluIndex" style={{ fontFamily: 'Arbutus', position: 'relative', display: 'block' }}>{day}</small>
                                                            <small className="PandagaluIndex" style={{ fontFamily: "Anek Telugu", position: 'relative', display: 'block' }}>{dayOfWeek}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-9 col-md-8 col-sm-6 browncolor Indextext">
                                                    <p 
                                                    className="PandagaluIndex" 
                                                    style={{
                                                        textAlign: 'center',
                                                        // marginTop: '20px',
                                                        fontFamily: "Anek Telugu",
                                                        // fontSize: "20px",
                                                        marginRight: '10px',
                                                        color: 'white'
                                                    }}>
                                                        {festivalInfo.Festival}
                                                    </p>
                                                </div>

                                            </div>

                                            <div
                                             className="BorderIndex" style={{
                                                borderBottom: '2px solid #CB874A',
                                                width: '100%',
                                                margin: '0 auto 10px auto',
                                                marginBottom: '8px'
                                            }}></div>

                                        </div>

                                    );

                                })
                            ) : (
                                <p>దయచేసి తేదీని ఎంచుకోండి</p>
                            )}
                            <div style={{ position: 'relative' }} >
                                <img src={Rectangle1547} alt="Background Image" style={{ marginBottom: '20px' }} />
                                <div style={{
                                    position: 'absolute',
                                    top: '35%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: '#FFFFFF',
                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '10px'
                                }}>

<a 
  href="../Panchangam"
  style={{ margin: 0, fontFamily: "Amaranth", fontSize: "18px", textDecoration: "none", color: "inherit" }}
>
  Read More
</a>
                                </div>
                            </div>
                        </p>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Pandagalu
