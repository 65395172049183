import React, { useState, useEffect } from 'react';
import Header from '../header';
import Navbar from '../navbar';
import Footer from '../footer';
import sideGridRightImage from '../../assets/images/sideGridRight.png';
import ItaramuluData from '../../assets/jsons/ItaramuluData';

const Grahanam = () => {
  const [activeKey, setActiveKey] = useState(0);

  const handleToggle = (index) => {
    
    setActiveKey(activeKey === index ? null : index);
  };

  useEffect(() => {
    setActiveKey(0); // Default first item active
  }, []);

  const [leftSticky, setLeftSticky] = useState(true);
  const [rightSticky, setRightSticky] = useState(true);

  return (
    <div>
      <Header />
      <Navbar />
      <div className="container-fluid" style={{ background: '#ffe6d0' }}>
        <div className="row">
          <div
            className="col-lg-1 col-md-1"
            style={{
              background: `url(${sideGridRightImage})`,
              transform: 'rotateY(180deg)',
            }}
          ></div>

          <div
            className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
            onMouseEnter={() => {
              setLeftSticky(false);
              setRightSticky(true);
            }}
            // style={{
            //   overflowY: leftSticky ? 'hidden' : 'auto',
            //   height: '80vh',
            //   position: leftSticky ? 'sticky' : 'static',
            //   top: 0,
            // }}
            >
            <div 
            style={{
              overflowY: rightSticky ? 'scroll' : 'auto',
              height: '80vh',
              position: rightSticky ? 'sticky' : 'sticky',
              top: 0,
          }}
            className="row">
              <div id="accordion">
                {ItaramuluData.EclipseUpdated.map((item, i) => (
                  <div
                    className="card mb-3"
                    key={i}
                    style={{
                      backgroundColor: activeKey === i ? '#B35C0C' : '#754213',
                      cursor: 'pointer'
                    }}
                    onClick={() => handleToggle(i)}
                  >
                    <div className="card-header" id={`heading${i}`}>
                      <h5 className="mb-0">
                        <button
                          className="btn"
                          aria-expanded={activeKey === i}
                          aria-controls={`collapse${i}`}
                          style={{ color: '#FFFFFF' }}
                        >
                          {item.Data[i].Title}
                        </button>
                      </h5>
                    </div>

                    {activeKey === i && (
                      <div
                        id={`collapse${i}`}
                        className={`card ${activeKey === i ? 'show' : ''}`}
                        style={{ backgroundColor: '#FFAD61', color: '#FFFFFF' }}
                      >
                        <div className="card-body" style={{ color: '#000'}}>
                        {i == '0' ? (
                        item.Data[0].Desc.map((desc, idx) => (
                          <p key={idx}>{desc.txt}</p>
                        ))
                      ) : (
                        item.Data[1].Moon.map((desc, idx) => (
                          <p key={idx}>{desc.txt}</p>
                        ))
                      )}

                          {item.Data[i].ex.map((ex, idx) => (
                            <p key={idx}>{ex.Date} - {ex.Time}</p>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div
            className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
            onMouseEnter={() => {
              setLeftSticky(true);
              setRightSticky(false);
            }}
            style={{
              overflowY: rightSticky ? 'hidden' : 'auto',
              height: '80vh',
              position: rightSticky ? 'sticky' : 'static',
              top: 0,
            }}
          >
            {activeKey !== null &&  activeKey == '0' &&(
              <div
                id={`collapseDetails${activeKey}`}
                className={`collapse ${activeKey !== null ? 'show' : ''}`}
                aria-labelledby={`heading${activeKey}`}
                data-parent="#accordion"
                key={`collapse-content-${activeKey}`}
              >
                <div className="card-body rightPanel">
                  <h5>{ItaramuluData.EclipseUpdated[activeKey].Data[0].Title}</h5>
                  {ItaramuluData.EclipseUpdated[activeKey].Data[0].Sun.map((content, idx) => (
                    <ul key={idx}>{content.txt}</ul>
                  ))}
                  {ItaramuluData.EclipseUpdated[activeKey].Data[0].Suntakecare.map((care, idx) => (
                    <>
                      <ul key={idx}>{care.txt}</ul>
                    </>
                  ))}
                  <br></br>
                </div>

              </div>
            )}
          </div>

          <div
            className="col-lg-1 col-md-1"
            style={{
              background: `url(${sideGridRightImage})`,
            }}
          ></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Grahanam;
