const importantinfoData = {
  importantinfodescription: [
    {
      CatID: [
        // {
        //   title: "<span class='highlight'>అరుంధతీ నక్షత్రం ప్రాముఖ్యత",
        // },
        // {
        //   title: "",
        // },
        {
          title: "అరుంధతీ నక్షత్రం కనిపించేది రాత్రి పూట మాత్రమే. దీవి వెనుక ఒక కథ ఉన్నది. అరుంధతీదేవి మహా పతివ్రత .అగ్ని హోత్రుడు సప్తఋషుల భార్యల అందానికి మోహింపపడి క్షీణించిపోతూ ఉండగా వివరం తెలుసుకున్న అగ్ని హోత్రుడి భార్య స్వహా దేవి వశిష్టుడి భార్య ఐన అరంధతీ తప్ప మిగతా అందరి భార్యల వేషమూ వెయ్య గలిగింది, కానీ ఎంత ప్రయత్నించినా అరుంధతీ దేవి వేషం వెయ్యలేక పోయింది. అందుకనే మహా పతివ్రత అయిన అరుంధతిని  నూతన వధూవరులకు సప్తపది అయిన తరువాత దర్శనం చేసుకోమంటారు.",
        },
        {
          title: "",
        },
        {
          title: "అరుంధతి భారత పురాణాలలో వశిష్ట మహాముని భార్య మరియు మహా పతివ్రత. భారతీయుల వివాహములో అరుంధతి నక్షత్రాన్ని చూపించడం ఒక ముఖ్యవిధి.",
        },
        {
          title: "",
        },
        {
          title: "అరుంధతి వశిష్ఠ మహర్షి ధర్మపత్ని, మహా పతివ్రత అని ఆకాశం వంక పెళ్లిసమయంలో చూపించి చెబుతారు బ్రాహ్మణులు. అలా చేస్తే మీ సంసారిక జీవనం నల్లేరు మీద నడకలా సాగుతుందని పండితులు వధూవరులకు చెబుతారు. మాఘమాసాది పంచ మాసాల కాల మందు తప్ప ఈ నక్షత్రం సాయంత్రం వేళ కానరాదు.",
        },
        {
          title: "",
        },
        {
          title: "రాత్రిపూట చంద్రుడ్ని, నక్షత్రాలను చూడటం వల్ల కంటి శక్తి పెరుగుతుంది. అరుంధతి నక్షత్రం నుంచి వచ్చే కిరణాల వల్ల కంటి శక్తి మరింత పెరుగుతుంది. అరుంధతి నక్షత్రం సప్తర్షి మండలంలో ఉండే చిన్న నక్షత్రం, శిశిర, వసంత, గ్రీష్మ ఋతువులందు సాయంకాల సమయాన, మిగిలిన కాలాల్లో అర్థరాత్రి లేదా దాటిన తర్వాత తెల్లవారుజామున కనిపిస్తుంది.",
        },
        {
          title: "",
        },
        {
          title: "అరుంధతి నక్షత్రాన్ని చూడాలనుకుంటే జాగ్రత్తగా ఆకాశం వంక చూడండి. సప్తర్షి మండలంలో పక్కపక్కనే ఉండే నక్షత్రాలే అరుంధతి, వశిష్ఠులవారు. అరుంధతి నక్షత్రం చిన్నగా ఉంటుంది.",
        },
        {
          title: "",
        },
      ],
      title: "అరుంధతీ నక్షత్రం ప్రాముఖ్యత",
    },
    {
      CatID: [
        // {
        //   title: "<span class='highlight'>ఆచమనం విశిష్టత",
        // },
        // {
        //   title: "",
        // },
        {
          title: "మనం చేసే ఆచమనంలో వైదికాంశాలతోపాటు వైజ్ఞానిక రహస్యాలు కూడా ఇమిడి ఉన్నాయి. మన గొంతులో 'స్వరపేటిక' అనే శరీర అంతర్భాగం ఉంటుంది. మనం చేసే ధ్వనులు అంటే మన మాటలు స్వరపేటిక నుండే పుడతాయి. మన ధ్వని గాంభీర్యానికి, స్పష్టతకు ఈ స్వరపేటికే ఆధారం. స్వరపేటికలోకి గాలి జొరబడినప్పుడు అంటే మన శ్వాసకోశాల నుండి వెలువడే ఉచ్ఛ్వాస వాయువు ధ్వని తంతులమీదుగా పయనించినప్పుడు, ఈ ధ్వని తంతువులలో ఏర్పడిన శబ్దాలు బయటకు రావడానికి నోరు, ముక్కు రంధ్రాలు సహాయపడతాయి.",
        },
        {
          title: "",
        },
        {
          title: "అదేవిధంగా నాలుక పెదవులు శబ్దాల ఉచ్ఛారణకు, స్పష్టతకు దోహదం చేస్తాయి. ప్రతి అక్షరానికి తనదైన ధ్వని ఉంటుంది. నోటిలోని అవయవాలు కదులుతూ ఈ ధ్వనులను ఉత్పత్తి చేస్తాయి. ఈ ధ్వని (అక్షరం) ఏ భాగం నుండి ఉత్పత్తి అవుతుందో దాని ఆధారంగా అక్షరాలను కాంఠ్యాలు, తాలవ్యాలు మొదలైనవిగా విభజించారు. ఇక మనం వైదిక కర్మలను ఆచరించేటప్పుడు చేసే ఆచమనం వలన మన నాలుకకు, గొంతుకు ఒకరకమైన ఉత్తేజం కలుగుతుంది.",
        },
        {
          title: "",
        },
        {
          title: "అంతేకాకుండా మన గొంతునుండి మాట బయటకు వచ్చేటప్పుడు, ధ్వనితోపాటు గొంతు నుండి వాయువు కూడా బయటకు వస్తుంది. ఈ విధంగా లోపలి నుండి వాయువు బయటకు వచ్చేటప్పుడు ఎలాంటి అడ్డంకి లేకుండా ఉండేందుకు ఆచమనం ద్వారా మనం త్రాగే నీరు ఉపయోగపడుతుంది.",
        },
        {
          title: "",
        },
        {
          title: "నిర్దిష్ట పరమాణంలో మనం తీసుకున్న నీరు గొంతు నుండి వెలుపలివైపు మార్గాన్ని నునుపుగా చేసి మన మాట సులభంగా, స్పష్టంగా వచ్చేందుకు దోహదం చేస్తుంది. పూజాది వైదిక కార్యాలను ఆచరించేటప్పుడు మంత్రోచ్చారణ చేయవలసి ఉంటుంది. కాబట్టి ఆయా మంత్రాలన్నీ గొంతునుండి ఎలాంటి ఇబ్బంది లేకుండా వచ్చేందుకే మన మహర్షులు ఈ ఆచమన సంప్రదయాన్ని ఏర్పరచారు.",
        },
        {
          title: "",
        },
        {
          title: "ఇక ఆచమనంలో కేశవాది నామాలను ఉచ్చరించడంలో కూడా ఎంతో వైజ్ఞానిక అంశం ఇమిడి ఉంది. ఆచమనంలో ముందుగా 'కేశవాయస్వాహా:' అని చెప్పుకుంటారు. 'కే'  శబ్దము గొంతునుండి పుడుతుంది. తర్వాత పలికే 'నారయణస్వాహా' అనే నామము నాలుక నుండి వస్తుంది. ఇక మూడవసారి చెప్పుకునే 'మాధవాయస్వాహా' అనే పదము పెదవుల సహాయంతో పలుక బడుతుంది. కాబట్టి కేశవాది నామాలను పలకడం వలన గొంతుకు, నాలుకకు, పెదవులకు ఒకేసారి వ్యాయామం కలుగుతుంది మరియు ఆ తరువాత వచ్చే శబ్దాలకు ఉచ్చారణ కూడా స్పష్టంగా ఉంటుంది.",
        },
        {
          title: "",
        },
        {
          title: "మన శరీరము ఒక విద్యుత్ కేంద్రములాంటిది, మన శరీరమంతా విద్యుత్ ప్రవహిస్తూ ఉంటుంది. ఆచమన సమయంలో మనం అరచేతిలో తక్కువ ప్రమాణంలో నీటిని వేసుకున్నప్పుడు ఎలక్ట్రో మాగ్నిటిజమ్ పద్ధతిలో అరచేతిలో ఉన్న నీరు పీల్చుకొంటుంది. ఈ నీటిని త్రాగినప్పుడు, నీరు జీర్ణకోశమును చేరి, అక్కడి గోడలలో ప్రవహించే విద్యుత్తుతో కలిసి, శరీరమంతా ఒకే క్రమపద్ధతిలో విద్యుత్తు ప్రవహించేలాగా చేస్తుంది.",
        },
        {
          title: "",
        },
        {
          title: "ఇలా విద్యుత్తీకరణము చెందిన నీరువల్ల గొంతు, నాలుక, స్వరపేటిక మొదలైన భాగాలు కూడా ఉత్తేజము పొందుతాయి. ఇంతటి వైజ్ఞానికాంశాలు ఇమిడి ఉన్నాయి కాబట్టే, మన మహర్షులు ఆచమనాన్ని ఒక తప్పనిసరి వైదిక నియమంగా ఏర్పరిచారు.",
        },
        {
          title: "",
        },
      ],
      title: "ఆచమనం విశిష్టత",
    },
    {
      CatID: [
        // {
        //   title: "<span class='highlight'>ఆంజనేయుడు శనీశ్వరుడిని తలపై ఎందుకు కూర్చోబెట్టుకున్నాడు",
        // },
        // {
        //   title: "",
        // },
        {
          title: "పంచభూతాలను తన వశం చేసుకున్న పరమాత్ముడు ఆంజనేయుడు. అలాగే జ్ఞానేంద్రియాలను కూడా నియంత్రణలో ఉంచుకోగలిగిన మహాశక్తిశాలి హనుమంతుడు. శ్రీ రాముడు లంకకు చేరుకునేందుకు గాను రామసేతు నిర్మాణంలో ఆంజనేయుడు నిమగ్నమై వుండగా శనిభగవానుడు హనుమంతుడిని బంధించేందుకు వచ్చాడు. రెండున్నర గంటసేపు హనుమను పట్టేందుకు వచ్చిన శనీశ్వరుడికి ఆంజనేయుడు చుక్కలు చూపించాడు.",
        },
        {
          title: "",
        },
        {
          title: "రెండున్నర గంట పాటు హనుమా నిన్ను చెరపట్టాలి. నీ శరీరంలో ఏదైనా ఒక ప్రాంతాన్ని నాకివ్వమని అడుగుతాడు. అయితే రామసేతు నిర్మాణంలో తానుండగా చేస్తున్న పనికి ఆటంకం కలిగిస్తున్నావని హనుమ చెప్తాడు. అయినా నీకు నా తలభాగం ఇస్తాను. అక్కడ ఎక్కి కూర్చోమంటాడు. అంతే శని కూడా హనుమంతుడి తలపై ఎక్కి కూర్చుంటాడు. కానీ ఆంజనేయుడు రాళ్లను, కొండలను తన తలపై మోసాడు. భారం తాళలేక శనిభగవానుడు గగ్గోలు పెట్టగా, మాట తప్పకూడదు. రెండున్నర గంట సేపు అలానే ఉండాల్సిందేనని షరతు విధిస్తాడు.",
        },
        {
          title: "",
        },
        {
          title: "ఆ రెండున్నర గంటకు తర్వాతే హనుమంతుడు శనీశ్వరుడు తల నుంచి శనీశ్వరుడిని కిందికి దించుతాడు. ఆపై రామ భక్తులను, ఆంజనేయ భక్తులను శనీశ్వరుడు ఇక్కట్లకు గురిచేయకూడదని హెచ్చరించాడు. అలా శనీశ్వరుడి చెర నుంచి హనుమంతుడు తప్పుకున్నాడు. అలాంటి మహిమాన్వితుడైన హనుమంతునికి తులసీ మాలను సమర్పించుకుంటే శనిగ్రహ బాధల నుంచి గట్టెక్కవచ్చు. ఆంజనేయునికి రామనామం అంటే మహాప్రీతి. హనుమజ్జయంతి రోజున 'రామ రామ రామ' అనే మంత్రాన్ని పఠించడం చేయొచ్చు.",
        },
        {
          title: "",
        },
        {
          title: "'ఓం ఆంజనేయాయ విద్మహే వాయుపుత్రాయ ధీమహి తన్నో హనుమ ప్రచోదయాత్'",
        },
        {
          title: "",
        },
        {
          title: "అనే మంత్రాన్ని 11 సార్లు పఠించడం ద్వారా శనీశ్వర గ్రహ దోషాల నుంచి గట్టెక్కవచ్చు. హనుమజ్జయంతి రోజున తులసీమాల సమర్పించడం, తులసీ ఆకులతో అర్చన చేయడం ద్వారా సుఖసంతోషాలు వెల్లివిరుస్తాయి. ఆ రోజున వడమాల సమర్పించడం, వెన్నతో ఆంజనేయుడిని అలంకరించడం, అన్నదానం చేయడం ద్వారా విశేష ఫలితాలను పొందవచ్చు. మనోబలం, బుద్ధిబలం, శరీరబలం, ప్రాణ బలం కోసం ఆంజనేయ స్వామిని స్తుతించడం చేయాలి. ఆంజనేయ ఆరాధన ద్వారా వీరం, వివేకం పెంపొందుతుందని ఆధ్యాత్మిక పండితులు సూచిస్తున్నారు.",
        },
        {
          title: "",
        },
      ],
      title: "ఆంజనేయుడు శనీశ్వరుడిని తలపై ఎందుకు కూర్చోబెట్టుకున్నాడు",
    },
    {
      CatID: [
        // {
        //   title: "<span class='highlight'>ఆంజనేయునికి తమలపాకుల మాల వేయడములో గల అంతరార్ధము",
        // },
        // {
        //   title: "",
        // },
        {
          title: "సీతమ్మ తల్లిని రావణుడు అపహరించినపుడు రామచంద్రుడు సీతమ్మ కోసం అన్వేషణలో ఉండగా, రామునికి అన్వేషణలో సాయడపడుతోన్న ఆంజనేయుడు అశోకవనం చేరుకున్నాడు. సీతమ్మ అక్కడే ఉందన్న విషయాన్ని గ్రహించి విషయాన్ని శ్రీరామునితో చెప్పాలని బయలుదేరాడు. అతడు వెళ్లేటప్పుడు సీతమ్మ ఆశీర్వదించాలని ఆశిస్తుంది. అయితే ఆ వనంలో ఉన్న పుష్పాలు ఆమె చేతికి అందవు. దాంతో పుష్పాలకు బదులుగా తమలపాకును కోసి, ఆంజనేయుని తలమీద పెట్టి దీవిస్తుంది. అందుకే ఆంజనేయునికి తమలపాకు ప్రీతిపాత్రమైనది.",
        },
        {
          title: "",
        },
        {
          title: "అది మాత్రమే కాదు. సీతమ్మ వద్ద నుంచి తిరిగి వెళ్తూ ఆకాశంలో పయనిస్తూ గట్టిగా హూంకరిస్తాడు ఆంజనేయుడు. అది విన్న వానరులకు విషయం అర్థమైపోతుంది. ఆంజనేయుడు కచ్చితంగా సీతమ్మ జాడ తెలుసుకునే వస్తున్నాడని అర్థం చేసుకున్న వానరులంతా వేయి కళ్లతో ఆంజనేయుడి కోసం ఎదురు చూస్తారు. అతడు రాగానే తమలపాకుల తీగలతో సన్మానం చేస్తారు. అది చూసి హనుమంతుడు ఆనందంతో పొంగిపోతాడు. అందువల్లే ఆంజనేయునికి తమలపాకుల మాలను వేస్తే స్వామి పరమానందం చెంది అనుగ్రహిస్తాడు.",
        },
        {
          title: "",
        },
        {
          title: "హనుమంతుడు జ్యోతి స్వరూపుడు. ఆయన్ని పూజిస్తే కష్టాలు మాయమైపోతాయి. అవరోధాలు తొలగిపోతాయి. అందుకే ప్రతి మంగళ, శనివారాల్లో హనుమంతుని దేవాలయాలలో పూజలు జరుగుతాయి. పూజలో భాగంగా ఆయనకు ఎంతో ఇష్టమైన తమలపాకుల మాలను భక్తితో సమర్పిస్తే మనోభీష్టాలు నెరవేరతాయి. అది మాత్రమే కాక హనుమాన్ చాలీసాను సైతం పారాయణం చేస్తే సర్వసంపదలూ, సుఖసంతోషాలూ కలుగుతాయి.",
        },
        {
          title: "",
        },
        {
          title: "శ్రీరామ జయరామ జయ జయరామ !!",
        },
        {
          title: "",
        },
        {
          title: "లోకా సమస్తా సుఖినోభవంతు !!",
        },
        {
          title: "",
        },
      ],
      title: "ఆంజనేయునికి తమలపాకుల మాల వేయడములో గల అంతరార్ధము",
    },
    {
      CatID: [
        {
          title: <span class='highlight'>ఆత్మ</span> ,
        },
        {
          title: "",
        },
        {
          title: "1) ఆత్మ ఈ దేహంలో ఎక్కడ ఉంది?",
        },
        {
          title: "",
        },
        {
          title: "ఆత్మ ఈ దేహంలో రెండు కనుబొమల మధ్య బౄమద్య స్నానం లో ఆత్మ మెరుస్తున్న నక్షత్రంలా ఉంటుంది.",
        },
        {
          title: "",
        },
        {
          title: "2) మనం చేసిన తప్పుకు శిక్షలు ఈ దేహం ద్వారా ఆత్మ అనుభవిస్తూందా లేక ఆత్మ ద్వారా ఈ దేహం అనుభవిస్తుందా?",
        },
        {
          title: "",
        },
        {
          title: "మన చేసిన తప్పుకు శిక్షలను ఆత్మయే ఈ దేహం ద్వారా కర్మ బోగాన్ని అనుభవిస్తుంది. ఆత్మయే ఈ దేహంలో ఉంటూ మాట్లాడుతుంది, తింటుంది, తిరుగుతుంది, ఆడుతుంది, పాడుతుంది, నిద్రిస్తుంది, మేల్కోంటుంది. కర్మను చేసేది ఆత్మే, ఈ దేహం, దేహ సంబంధాలతో పాత్ర ఎప్పుడయితే పూర్తి అవుతుందో అప్పుడు ఆత్మ ఈ శరీరాన్ని విడిచి వెళ్ళిపోతుంది.",
        },
        {
          title: "",
        },
        {
          title: "3) ఈ దేహం నుంచి ఆత్మ బైటకు వచ్చిన తరువాత ఎక్కడకు వెళ్తుంది?",
        },
        {
          title: "",
        },
        {
          title: "ఈ దేహం నుంచి ఆత్మ బైటకు వచ్చిన తరువాత ఆత్మ తన పాత్రను అనుసరించి పునః జన్మలను తీసుకుంటుంది. ఈ జన్మల యొక్క చక్రం పూర్తి అయిన తరువాత పరంధామముకు చేరుకుంటుంది.",
        },
        {
          title: "",
        },
        {
          title: "ఆకాశతత్వానికి పైన సూక్ష్మ, శూన్య లోకానికి పైన పరంధామము ఉంటుంది. దీనినే ములవధనము, ముక్తిధామము, నిర్వాణధామము, బ్రహ్మాండము, శాంతీధామము ఇలా మొదలైన పేర్లతో పిలుస్తారు.",
        },
        {
          title: "",
        },
        {
          title: "పరంధామములో శాంతి సాగరుడు అయిన శివ పరమాత్మ ఉంటారు. పరమ శివునితో ఆత్మ కూడా పరధామములోనే ఉంటుంది. అక్కడ ఆత్మలు ఒక్క సూక్ష్మ నక్షత్ర రూపంలో ఉంటాయి. శివున్నే జ్ఞాన సాగరుడు, ప్రేమ సాగరుడు, పవిత్రతా సాగరుడు, సుఖము సాగరుడు, దివ్య బుద్ధి, శక్తులు, గుణాలు, ఖజానాలను ఇచ్చువాడు అని, యోగేశ్వరుడు అని అంటారు.",
        },
        {
          title: "",
        },
        {
          title: "మన అందరి ఇల్లు అయిన పరంధామము ఒక తిరగబడిన వృక్షంలా ఉంటుంది, వృక్షానికి బీజం వృక్షపతి శివుడు పైన ఉంటారు, శివుని పిల్లల మైన ఆత్మలు వేర్లు, కాండం, కొమ్మలు, ఆకులు వలే సూక్ష్మ నక్షత్ర రూపం కలిగి, అన్ని ధర్మాలకు చెందిన ఆత్మలు వారి వారి శాఖలలో చేరుకుంటారు.",
        },
        {
          title: "",
        },
      ],
      title: "ఆత్మ",
    },
    {
      CatID: [
        // {
        //   title: "<span class='highlight'>ఆలయ గోపురం ఎత్తుగా ఎందుకు కట్టడములో గల అంతరార్ధము",
        // },
        // {
        //   title: "",
        // },
        {
          title: "దేవాలయం అంటే లక్షోపలక్షల భక్తుల పుణ్యధామం. ఆగమశాస్త్రబద్ధంగా దేవాలయాలను నిర్మించవలసి వుంటుంది. ఆలయనిర్మాణం దేవుడు పడుకున్నట్లు శయనరీతిలో నిర్మిస్తారు. ఆలయ గోపురమే భగవంతుని పాదాలు. గర్భగుడి భగవంతుని శిరస్సు. ఆలయ మంటపం భగవంతుని కడుపు.",
        },
        {
          title: "",
        },
        {
          title: "దైవదర్శనం అంటే గుడిలోకి వెళ్ళి స్వామిని చూచి గంటకొట్టి నమస్కరించాలి  అనుకొంటుంటాం. ఆ పద్ధతినే పాటిస్తుంటాం. కాని దూరంగా వుండి కూడా ఆలయగోపురానికి నమస్కరించినా స్వామి పాదాలకు నమస్కరించినట్లే అవుతుంది.కాబట్టి ఆలయగోపురం ఎత్తుగా వుండాలి. ప్రకృతి ప్రళయసమయాలలో ప్రాణాలు కాపాడగల్గిన స్థలం దేవాలయమే!",
        },
        {
          title: "",
        },
        {
          title: "దేవుడు సర్వోన్నతుడు! ఈ సర్వోన్నత భావం దేవాలయాన్ని దర్శించిన ప్రతిసారీ మనిషికి, మనస్సుకీ బోధపడటానికి దేవాలయాన్నీ దేవాలయగోపురాన్నీ ఎంత వీలైతే అంతగా ఎత్తుకి నిర్మిస్తారు. హిందూదేవాలయాలే కాదు. మసీదుకి కూడా పొడవైన స్తంభం నిర్మిస్తారు. చర్చికి కూడా ముందుభాగంలో ఎత్తుగా దూరానికి కన్పించే విధంగా అంతస్థు నిర్మించి గంటను కడతారు.",
        },
        {
          title: "",
        },
      ],
      title: "ఆలయ గోపురం ఎత్తుగా ఎందుకు కట్టడములో గల అంతరార్ధము",
    },
    {
      CatID: [
        // {
        //   title: "<span class='highlight'>ఉగ్రవారాహీ అమ్మవారి దేవాలయం",
        // },
        // {
        //   title: "",
        // },
        {
          title: <span class='highlight'>వారణాసి భూగృహంలో ఉగ్రవారాహీ విచిత్ర దేవాలయం :</span> ,
        },
        {
          title: "",
        },
        {
          title: "మీరు కాశి వెళ్ళినప్పుడు ఎప్పుడైనా ఉగ్రవారాహి అమ్మవారి దేవాలయానికి వెళ్ళారా? వెళ్ళకపోతే మాత్రం ఖచ్చితంగా వెళ్ళండి.",
        },
        {
          title: "",
        },
        {
          title: "కాకపోతే ఈ ఆలయం వేళలు ఉదయం 4:30 నుండి 8:30 వరకు మాత్రమే. కేవలం నాలుగు గంటలు మాత్రమే దర్శనానికి అనుమతి ఇస్తారు. తరువాత మూసేస్తారు. ఎందుకనగా",
        },
        {
          title: "",
        },
        {
          title: "అమ్మవారు ఆ వారణాసి గ్రామదేవత. చీకటి పడింది మొదలు ఉదయం 3:30 వరకు గ్రామ సంచారం చేసి వచ్చి విశ్రమిస్తుంది. అందువలన అమ్మవారి ఆలయంలో 4 గంటల పాటు పూజ చేస్తారు. ఇక్కడ అమ్మవారు రెండు కన్నాలలో నుండి దర్శనం ఇస్తారు. ఒక కన్నం లో నుండి చూస్తే అమ్మవారి ముఖ భాగం, రెండవ కన్నం లో నుండి చూస్తే పాదాలు దర్శనం ఉంటుంది. అమ్మవారికి పూజ చేసే పూజారి మాత్రం నిమిషాల వ్యవధిలో అలంకరణ హారతి ఇచ్చేసి సెల్లార్లో నుండి బయటికి వచ్చేస్తాడు. ఆ తరువాత ఆ కన్నాలలో నుండి దర్శనానికి అనుమతి ఇస్తారు. మొన్నీమధ్య జరిగిన ఓ సంఘటన ఇక్కడ చెప్పుకోవాలి.",
        },
        {
          title: "",
        },
        {
          title: "క్రొత్తగా పెళ్ళైన జంట కొన్ని నెలల క్రితం అన్ని దేవాలయాలు దర్శనం చేస్తూ వారణాసి వచ్చి ఈ దేవాలయాన్ని సందర్శించారు. పూజారి ఎప్పటిలాగే కన్నంలో నుండి చూడమని చెబితే వినలేదు. పై పెచ్చు మూర్ఖపు వాదనకి దిగారు. అమ్మవారు ఉగ్రరూపంలో ఉంటుందా, భక్తులని చూడనివ్వదా అంటే కాదు నాయనా! శాంత కళ, ఉగ్ర కళ అని రెండు ఉంటాయి. శాంత కళతో ఉన్న అమ్మవారిని ఎదురుగా వెళ్లి దర్శనం చేసుకోవచ్చు. ఉగ్ర కళ అంటే దుష్ట సంహరార్థం ఎత్తిన అవతారం.",
        },
        {
          title: "",
        },
        {
          title: "ఆ కళని సామాన్యులు తట్టుకోలేరు. నేను వెళితేనే ఆ కళ తట్టుకోలేక త్వరగా ముగించి వచ్చేస్తాను. సూర్యుడిని ఉదయం చూసినట్లు మధ్యాహ్నం చూడలేము. ఉదయం ఉన్నది ఆ సూర్యుడే. మధ్యాహ్నం ఉన్నది ఆ సూర్యుడే కదా అని చూస్తాను అంటే సాధ్యమేనా, కళ్ళు టపాసుల్లా పేల్లిపోతాయ్, దృష్టి పోతుంది. అలాంటిది దుష్ట శిక్షనార్థం ఎత్తిన అవతారాలు చూడాలంటే మన శక్తి సరిపోదు, చూడకూడదు.",
        },
        {
          title: "",
        },
        {
          title: "అని ఎంతో శ్రద్దగా చేబియే వినకుండా చూడనివ్వకపొతే కోర్టుకి వెళ్లి మీకు వ్యతిరేకంగా ఆర్డర్ తెచ్చుకుంటాం అని మొండి పట్టుపట్టారు. దీంతో పోయే కాలం వచ్చినప్పుడు ఇలానే ఉంటుంది ప్రవర్తన అని సెల్లార్ లో ఉన్న అమ్మవారి వద్దకి ఆ కొత్త జంటని తీసుకెళ్ళాడు. క్షణాల వ్యవధిలో పూజారి హారతి వెలిగించి ఇచ్చే లోపు ఇద్దరు కిందపడి మరణించారు.",
        },
        {
          title: "",
        },
      ],
      title: "ఉగ్రవారాహీ అమ్మవారి దేవాలయం",
    },
    {
      CatID: [
        // {
        //   title: "<span class='highlight'>ఉత్తరం దిక్కున తలపెట్టి నిద్రించరాదు",
        // },
        // {
        //   title: "",
        // },
        {
          title: "మన భూమిలో గురుత్వాకర్షణశక్తి ఉత్తర దక్షిణాలుగా ఇమిడి ఉంటుంది. మనమూ అలానే నిద్రపోయామంటే ఉత్తర దక్షిణాల్లో ఇమిడి ఉన్న ఆ శక్తి యొక్క తరంగాలు మన మెదడులో దాగావున్న శక్తివంతమైన విద్యుత్ తరంగాలని తగ్గించి వేస్తాయి.",
        },
        {
          title: "",
        },
        {
          title: "దానివలన అనేక ఆరోగ్య, మానసిక సమస్యలు వస్తాయి. రక్త ప్రసరణ వ్యవస్థలో చాలా మార్పువస్తుంది. మెదడులో లోపాలు తల ఎత్తుతాయి. అలా కాకుండా తూర్పు పడమరల వైపు నిద్రిస్తే మెదడు సుఖవంత స్థానంలో ఉండి మెరుగు పడుతుంది. రక్త ప్రసరణ బాగా జరిగి శరీరానికి నూతన ఉత్తేజం వస్తుంది.",
        },
        {
          title: "",
        },
      ],
      title: "ఉత్తరం దిక్కున తలపెట్టి నిద్రించరాదు",
    },
    {
      CatID: [
        // {
        //   title: "<span class='highlight'>ఉపవాసం",
        // },
        // {
        //   title: "",
        // },
        {
          title: "ఈ రోజు నేను ఉపవాసమండీ అనే మాటని మనం తరచుగా వింటూ ఉంటాము. ముక్కోటి ఏకాదశి, శివరాత్రి వంటి పర్వ దినాల్లో చాలా మంది, దరిదాపుల్లో అందరు ఉపవాసాలుంటారు. ప్రతి మాస శివరాత్రికి, ఏకాదశికి ఉపవాసం ఉండేవారు కూడా చాలా మందే కనపడతారు. ఒక్కొక్క వారం ఒక్కొక్క దేవుడికి ప్రీతికరమని తమ ఇష్ట దైవానికి ప్రీతికరమైన రోజున ఉపవాసం ఉంటారు ఎంతో మంది. ఇక కార్తీక మాసం వచ్చిందంటే చెప్పనక్కర లేదు.",
        },
        {
          title: "",
        },
        {
          title: "అన్నీ రోజులు ఉపవాసాలే సోమ వారాలు, ఏకాదశులు, పూర్ణిమ, మాస శివరాత్రి. మిగిలిన రోజులు నక్తాలు. ఈ ఉపవాసాలు ఒక్కొక్కళ్ళు ఒక్కొక్క విధంగా చేస్తూంటారు. రోజంతా ఏమీ తినకుండా ఉండేవారు కొంతమంది. పగలు తిని రాత్రి తినని వారు, రాత్రి తిని పగలు తినని వారు, ఒక పూట అన్నం, మరొక పూట  ఫలహారం ( పిండి వంటలు, పండ్లు, పాలు) తినే వారు, వండినవి తినని వారు, ఇలా ఎన్నో రకాల వారు కనపడతారు. ఉపవాసాన్ని ఒక్క పొద్దు అనటం కూడా వింటాం. అంటే ఒక పూట మాత్రమే తింటారనే అర్థం వస్తుంది. ఇవన్నీ చూస్తే అసలు ఉపవాసం అంటే ఏమిటి? ఎలా చెయ్యాలి? అనే సందేహం రావటం సహజం.",
        },
        {
          title: "",
        },
        {
          title: "ఉప అంటే సమీపంలో వాసం అంటే ఉండటం. అంటే ఉపవాసం అనే పదానికి దగ్గరగా ఉండటం అని అర్థం. ఉపవాసం భగవదనుగ్రహం కోసం చేస్తారన్నది జగద్విదితమైన విషయం. కనుక ఉండవలసింది భగవంతుని సమీపంలో. ఇంటిపని వంట పని తగ్గితే సమయమంతా భగవద్ధ్యానంలో గడపటానికి వీలుగా ఉంటుంది. అప్పుడు ఒంటికి కూడా పని తగ్గుతుంది. బరువైన, అరగటానికి కష్టమైన ఆహారం తీసుకోకపోవటంతో జీర్ణ వ్యవస్థకి వెచ్చించాల్సిన శక్తి కూడా భగవద్ధ్యానానికో, పూజకో  వెచ్చించటానికి వీలవుతుంది. కడుపు నిండా తినగానే కునుకు వస్తుంది చాలమందికి.  ఎందుకంటే శక్తి అంతా జీర్ణాశయం దగ్గరకి వెళ్ళి పోయి ఉంటుంది. మెదడుకి శక్తి సరఫరా తగ్గుతుంది.",
        },
        {
          title: "",
        },
        {
          title: "దానితో మెదడులో చురుకుతనం తగ్గి మాదకత కలుగుతుంది. కళ్ళు మూతలు పడతాయి. అటువంటి సమయంలో పూజకో, ధ్యానానికో కూర్చుంటే ఇంకేముంది, హాయిగా నిద్ర ముంచుకు వస్తుంది. అందుకని మితాహారం నియమంగా పెట్టటం జరిగింది. అలాగని ఏమి తినకుండ ఉంటే నీరసం వచ్చి అసలు ప్రయోజనం దెబ్బ తింటుంది. అందుకని నీరసం రాకుండా శక్తినిస్తూ, జీర్ణశయానికి బరువు కలిగించకుండా తేలికగా వంట పట్టే ఆహారం తీసుకోవటం మంచిదని పెద్దల మాట. అటువంటి ఆహారాల్లో పాలు, పండ్లు శ్రేష్ఠమైనవి.  మామూలు పూజకైనా అంతే. పూజా ప్రారంభంలో ఆచమనీయం అని మూడు పుడిసిళ్ళ నీరు లోపలికి తీసుకుంటారు. అన్నం బదులు మరేదైనా తీసుకుంటారు కొందరు. అన్నం కన్నా తక్కువ తింటారు అనే ఉద్దేశంతో ఆహారం తగ్గించటం, మార్చటం వల్ల శరీరం అదుపులో ఉటుంది.",
        },
        {
          title: "",
        },
        {
          title: "పూర్తిగా రోజంతా ఏమి తినకుండా ఉండటం కష్టం కనుక ఒకపూట తినటం బాగా వ్యాప్తిలో ఉంది. అది పగలా? రాత్రా? అన్నది వారి వారి సౌకర్యాన్ని బట్టి ఉంటుంది.",
        },
        {
          title: "",
        },
        {
          title: "ఒక నెల పూర్తిగా ఇటువంటి ఉపవాస దీక్ష తీసుకునేది కార్తీక మాసంలో. ఇది శివకేశవులిద్దరికి ప్రీతి పాత్రమైన మాసం. ఈ నెలలో చాలా మంది నక్తాలుంటారు. అంటే నక్షత్ర దర్శనం అయ్యే దాకా పగలంతా ఉపవసించి ప్రదోష పూజ అయినాక భోజనం చేస్తారు. కొంత మంది అర్థనక్తాలు అని పొద్దు వాటారేదాకా ఉండి అప్పుడు భోజనం చేస్తారు. భోజనం ఎప్పుడు చేసినా అప్పటి వరకు రుద్రాభిషేకమో, విష్ణు సహస్ర నామ పారాయణమో చేస్తూ కాలం గడుపుతారు. కార్తీకమాసం చలి కాలంలో వస్తుంది, పగటి సమయం తక్కువ. ఎక్కువ తినాలని చలికి ముడుచుకుని వెచ్చగా కూర్చోవాలనో, పడుకోవాలనో అనిపిస్తుంది. నియంత్రించకపోతే ఆరోగ్యం దెబ్బ తినే ప్రమాదం ఉంది. కనుక ఆహార నియమం పెట్టి ఉంటారు.",
        },
        {
          title: "",
        },
        {
          title: "అసలు ఉపవాసం అంటే అన్నం తినకుండా ఉండటం అనేదే ఆరోగ్య సూత్రం. ఏ అనారోగ్యమైన ఆహారంతో ముడిపడి ఉంటుంది. దానిని సరిచేస్తే ఎన్నో సద్దుకుంటాయి అన్నది ఆయుర్వేద సిద్ధాంతం. వారానికి ఒక రోజు జీర్ణాశయానికి విశ్రాంతి ఆహారం తీసుకోకపోతే మనసు కేంద్రీకరించటం ఎక్కువగా ఉంటుందనటానికి నిరశన వ్రతాలు, సత్యాగ్రహాలే నిదర్శనం. విద్యార్థులకు ఏ విషయమైనా గుర్తుండకపోతే ఆకలిగా ఉన్నపుడు చదివి వెంటనే భోజనం చేస్తే మనసులో గట్టిగా నాటుకు పోతుందని ఈ మధ్య పాశ్చాత్యులు చేసిన ప్రయోగాలు నిరూపించాయి.",
        },
        {
          title: "",
        },
        {
          title: "కార్తీక మాస నక్తాలు కావచ్చు, ఏకాదశి ఉపవాసాలు కావచ్చు, శనివారపు ఒక్క పొద్దులు కావచ్చు  అన్నీ మరచి భగవంతుని అస్తిత్వంలో జీవ ప్రజ్ఞ నిలిచి ఉంటే అది ఉపవాసం అవుతుంది. లేకపోతే అది లంఖనం అవుతుంది. నిజానికి పూజలో కానీ, ధ్యానంలో కాని ఉన్నపుడు ఆహారం మీదికి మనసు వెళ్ళకూడదు. అలా వెళ్ళినప్పుడు తినేయటం మంచిది.",
        },
        {
          title: "",
        },
      ],
      title: "ఉపవాసం",
    },
    {
      CatID: [
        // {
        //   title: "<span class='highlight'>కార్యసిద్ది ఆంజనేయ స్వామి శ్లోకాలు",
        // },
        // {
        //   title: "",
        // },
        {
          title: "హనుమంతుడు కార్యసాధకుడు. భక్తితో హనుమంతుడిని కొలిచిన వారికి వారి కోరికలు తప్పక నెరవేరతాయి. భక్తులు వారి వారి కోరికను అనుసరించి ఆంజనేయ శ్లోకాలను భక్తితో స్మరిస్తే కార్యసిద్ధి సాధించగలుగుతారు.",
        },
        {
          title: "",
        },
        {
          title: <span class='highlight'>విద్యా ప్రాప్తికి :</span> ,
        },
        {
          title: "శ్లో: పూజ్యాయ, వాయుపుత్రాయ వాగ్ధోష వినాశన |",
        },
        {
          title: "సకల విద్యాంకురమే దేవ రామదూత నమోస్తుతే ||",
        },
        {
          title: "",
        },
        {
          title: <span class='highlight'>ఉద్యోగ ప్రాప్తికి :</span> ,
        },
        {
          title: "",
        },
        {
          title: "శ్లో: హనుమాన్ సర్వధర్మజ్ఞ సర్వా పీడా వినాశినే |",
        },
        {
          title: "ఉద్యోగ ప్రాప్త సిద్ధ్యర్థం శివరూపా నమోస్తుతే ||",
        },
        {
          title: "",
        },
        {
          title: <span class='highlight'>కార్య సాధనకు :</span> ,
        },
        {
          title: "",
        },
        {
          title: "శ్లో: అసాధ్య సాధక స్వామిన్ అసాధ్యం తమకిమ్ వద |",
        },
        {
          title: "రామదూత కృపాం సింధో మమకార్యమ్ సాధయప్రభో ||",
        },
        {
          title: "",
        },
        {
          title: <span class='highlight'>గ్రహదోష నివారణకు :</span> ,
        },
        {
          title: "",
        },
        {
          title: "శ్లో: మర్కటేశ మహోత్సాహా స్రవ గ్రహ నివారణ |",
        },
        {
          title: "శత్రూన్ సంహార మాం రక్ష శ్రియం దాపయామ్ ప్రభో ||",
        },
        {
          title: "",
        },
        {
          title: <span class='highlight'>ఆరోగ్యమునకు </span> ,
        },
        {
          title: "",
        },
        {
          title: "శ్లో: ఆయుః ప్రజ్ఞ యశోలక్ష్మీ శ్రద్ధా పుత్రాస్సుశీలతా |",
        },
        {
          title: "ఆరోగ్యం దేహ సౌఖ్యంచ కపినాథ నమోస్తుతే ||",
        },
        {
          title: "",
        },
        {
          title: <span class='highlight'>సంతాన ప్రాప్తికి :</span> ,
        },
        {
          title: "",
        },
        {
          title: "శ్లో: పూజ్యాయ ఆంజనేయ గర్భదోషాపహారిత్ |",
        },
        {
          title: "సంతానం కురమే దేవ రామదూత నమోస్తుతే ||",
        },
        {
          title: "",
        },
        {
          title: <span class='highlight'>వ్యాపారాభివృద్ధికి :</span> ,
        },
        {
          title: "",
        },
        {
          title: "శ్లో: సర్వ కళ్యాణ దాతరమ్ సర్వాపత్ నివారకమ్ |",
        },
        {
          title: "అపార కరుణామూర్తిం ఆంజనేయం నమామ్యహమ్ ||",
        },
        {
          title: "",
        },
        {
          title: <span class='highlight'>వివాహ ప్రాప్తికి :</span> ,
        },
        {
          title: "",
        },
        {
          title: "శ్లో: యోగి ధ్యే యాం ఘ్రి పద్మాయ జగతాం పతయే నమః|",
        },
        {
          title: "వివాహం కురమేదేవ రామదూత నమోస్తుతే ||",
        },
        {
          title: "",
        },
        {
          title: "ఈ శ్లోకాలను ఆయా కార్యసిద్ధిని కోరుకునేవారు 40 దినాలు నిష్ఠతో స్మరిస్తూ, ప్రతిరోజు ఆంజనేయ స్వామి గుడికి వెళ్ళి శక్తికొద్దీ ప్రదక్షణా సంఖ్యా నియమాన్ని అనుసరించి ప్రదక్షణాలు చేసి ఆ స్వామిని పూజిస్తే తమ తమ కార్యాలలో విజేతలు అవుతారు.",
        },
        {
          title: "",
        },
      ],
      title: "కార్యసిద్ది ఆంజనేయ స్వామి శ్లోకాలు",
    },
    {
      CatID: [
        // {
        //   title: "<span class='highlight'>దేవాలయ ప్రదక్షిణలో గల అంతరార్ధము",
        // },
        // {
        //   title: "",
        // },
        {
          title: "మనము దేవాలయమునకు వెళ్ళినప్పుడు స్వామివారిని దర్శించడానికి ముందు దేవాలయంలో ప్రదక్షిణ చేసేటప్పుడు తొందర పనికిరాదు.",
        },
        {
          title: "",
        },
        {
          title: "మనస్సును ప్రశాంతపరచి, స్వామివారిని ధ్యానిస్తూ మంత్రం గాని, అష్టోత్తరం గాని, ఇవి ఏవి తెలియని వారు ఆ స్వామివారి నామజపము చేస్తూ భక్తితో ఆ దైవం చుట్టూ ప్రదక్షిణ చేయాలి.",
        },
        {
          title: "",
        },
        {
          title: "ఆలయంలోని గర్భగుడిలో దేవతా విగ్రహం ఉంటుంది. ప్రతి నిత్యం పురోహితులు జరిపే అర్చనలలోని మంత్రాలలో ఉండే శక్తిని విగ్రహం క్రిందనున్న యంత్రం ఆ శక్తిని గ్రహించి, ఆ శక్తి ద్వారా మన కోర్కెలను తీరుస్తుంది.",
        },
        {
          title: "",
        },
        {
          title: "కనుక భగవంతుని దర్శనానికి వెళ్ళినప్పుడు మన మది నిండా భగవంతుని రూపమే నింపి నిదానంగా ప్రదక్షిణ చేసి ఆ స్వామి కృపకు పాత్రులు కావాలి.",
        },
        {
          title: "",
        },
      ],
      title: "దేవాలయ ప్రదక్షిణలో గల అంతరార్ధము",
    },

    {
      CatID: [
        // {
        //   title: "<span class='highlight'>ప్రదక్షిణలు - రకాలు",
        // },
        // {
        //   title: "",
        // },
        {
          title: "సనాతన ధర్మంలో ఆలయాలను దర్శించడం, ఆలయాల్లో భగవంతుని పూజించడం అత్యంత ప్రాధాన్యత కలిగిన అంశాలు. ఆలయాలను దర్శించినపుడు నేరుగా దైవదర్శనం చేయకుండా ప్రదక్షిణ చేయడం సనాతన ధర్మంలో ఉన్న ఆచారం. అక్కడ దైవాన్ని దర్శించేందుకు నేరుగా గుడిలోనికి వెళ్లకుండా, ఆలయ ప్రాంగణంలో ప్రదక్షిణలు చేయడం వస్తున్న ఆచారం. పాదాలు, చేతులు ప్రక్షాళన చేసుకోవడంతో మొదలవుతుంది భగవత్‌ కైంకర్యం.",
        },
        {
          title: "",
        },
        {
          title: "ప్రదక్షిణలు ఎన్ని రకాలు ఉంటాయి?",
        },
        {
          title: "",
        },
        {
          title: "అడుగులో అడుగు వేసుకుంటూ నడవడం, రెండు చేతులూ జోడించి నమస్కారం చేస్తూ నోటితో భగవన్నామస్మరణ చేస్తూ వెళ్ళడం, మనసులో తలుస్తూ వెళ్ళడం పరమాత్మున్ని. అలా చేసేటప్పుడు మన ధ్యాస, ధ్యానం ఆ దైవం పైనే లగ్నం కావడం ఆవశ్యకం. ప్రదక్షిణ చేసేటప్పుడు మన ఆత్మ, పరమాత్మ చుట్టూ పరిభ్రమించాలి.",
        },
        {
          title: "",
        },
        {
          title: "ఏ దేవాలయంలో ఏ దేవతలను ప్రధానంగా ప్రతిష్టించారో వారినే ధ్యానించాలి. విగ్రహాన్ని ప్రతిష్టాపన చేసేటప్పుడు ప్రత్యేక క్రతువు సాగుతుంది. ఎంతో శ్రమకోర్చి జపతపాదులు, హోమాలు, అనుష్టానాలు చేసి విగ్రహాన్ని మంత్ర యంత్ర బద్ధంగా ప్రతిష్టిస్తారు. ఆ యంత్రంలో నిక్షిప్తమై ఉన్న దివ్య తేజస్సు, శక్తి నలువైపులా కాంతిపుంజంలా వ్యాపించి పరిసరాల్లో ఉంటుంది. ప్రదక్షిణ చేసేటప్పుడు ఆ శక్తి మన శరీరాలను తాకి ఉపశమనాన్ని కలిగించి, మనోబలాన్ని ఇస్తుందంటారు.",
        },
        {
          title: "",
        },
        {
          title: "ప్రదక్షిణలో 3 రకాలున్నాయి. ",
        },
        {
          title: "",
        },
        {
          title: "1) ఆత్మప్రదక్షిణ : గుడిలో పూజ అనంతరం అర్చక స్వాములు ప్రదక్షిణలు చేయమంటారు. మన ఆత్మలోనే పరమాత్మ ఉంటాడని నమ్ముతాం. ఎవరికి వారే తమ చుట్టూ తాము తిరిగి చేసేదాన్ని ఆత్మ ప్రదక్షిణ అంటారు. ",
        },
        {
          title: "",
        },
        {
          title: "2) ప్రాకార ప్రదక్షిణ : ప్రాకార ప్రదక్షిణ అంటే మూల విగ్రహానికి చేసే ప్రదక్షిణ. ఇలాంటప్పుడు దైవానికి, అష్టదిక్పాలకులకు, ధ్వజస్తంభానికి నమస్కరిస్తారు.",
        },
        {
          title: "ప్రాకార ప్రదక్షిణలో నాలుగు రకాలున్నాయి.",
        },
        {
          title: "",
        },
        {
          title: "a) ధ్వజస్తంభ ప్రదక్షిణ: ధ్వజస్తంభ ప్రదక్షిణ ప్రాకార ప్రదక్షిణతో కలిసి ఉంటుంది.",
        },
        {
          title: "",
        },
        {
          title: "b) పాద ప్రదక్షిణ : దీన్నే పడి ప్రదక్షిణ అంటారు. పాదానికి పాదం తాకిస్తూ అడుగులు వేస్తూ చుట్టూ తిరగడం.",
        },
        {
          title: "",
        },
        {
          title: "c) అంగప్రదక్షిణ : దీన్నే పొర్లు దండాలు అని కూడా అంటారు. ధ్వజ స్తంభం నుంచి సాష్టాంగ నమస్మారంతో సవ్య దిశలో ప్రారంభించాలి. అలా పొర్లుకుంటూ తిరిగి ధ్వజస్తంభం వద్దకు చేరడంతో అంగప్రదక్షిణ పూర్తవుతుంది.",
        },
        {
          title: "",
        },
        {
          title: "d) అర్ధ ప్రదక్షిణ : శాస్త్రాలు, శివపురాణం ప్రకారం శివలింగానికి అర్ధ ప్రదక్షిణ చేస్తారు. అంటే సగం వరకు ప్రదక్షిణ చేసి మరల వెనుకకు తిరిగి ప్రదక్షిణ చేయాలి.",
        },
        {
          title: "",
        },
        {
          title: "3) గిరి ప్రదక్షిణ (కొండ చుట్టూ తిరిగే ప్రదక్షిణ) : గిరి ప్రదక్షిణ పూర్వకాలం నుంచి ప్రాముఖ్యం సంతరించుకుంది. దీనికి మరో పేరు పరిక్రమణ. ఒకసారి గిరి ప్రదక్షిణ చేస్తే కోటి సార్లు అంగప్రదక్షిణ చేసిన ఫలితం వస్తుందని శాస్త్రాలు చెబుతున్నాయి.",
        },
        {
          title: "",
        },
        {
          title: "వివాహ సమయంలో వధూవరులు అగ్ని ప్రదక్షిణ చేస్తే అగ్నిసాక్షి గా వివాహం జరిగినట్లు పరిగణనలోకి తీసుకుంటారు.",
        },
        {
          title: "",
        },
      ],
      title: "ప్రదక్షిణలు - రకాలు",
    },
    {
      CatID: [
        // {
        //   title: "<span class='highlight'>మహాలయ అమావాస్య",
        // },
        // {
        //   title: "",
        // },
        {
          title: "సాధారణంగా తండ్రి చనిపోయిన తిథినాడు 'మహాలయం' పెట్టడం ఉత్తమమం. ఏ కారణంచేతనైనా అలా పెట్టడం వీలుకాని పరిస్థతిలో 'మహాలయ అమావాస్య' నాడు పెట్టడం ప్రశస్తం. దీనినే 'సర్వ పితృ అమావాస్య' అంటారు. ఈ రోజునే మరణించిన బంధువులందరికీ వారి వారి తిథులతో సంబంధం లేకుండా 'మహాలయం' పెట్టాలి.",
        },
        {
          title: "",
        },
        {
          title: "మహాలయము పెట్టే కర్త శుచిగా స్నానం చేసి, పవిత్రమును (దర్భలతో చేసిన ఉంగరము) ధరించి, శ్రధ్ధగా, భక్తిగా, మంత్రపూర్వకంగా ఐదుగురు బ్రాహ్మణభోక్తలతో ఈ పితృకార్యాన్ని నిర్వహించాలి. ఐదుగురు భోక్తలు ఎందుకంటే",
        },
        {
          title: "",
        },
        {
          title: "1.పితృదేవతలకు అధిపతి శ్రీ మహావిష్ణువు. కనుక విష్ణుదేవతా ప్రీత్యర్థం ఒక భోక్త.",
        },
        {
          title: "",
        },
        {
          title: "2.ఇది విశ్వేదేవస్థానం. విశ్వ, ఆర్ద్ర దేవతల ప్రీత్యర్థం ఒక భోక్త.",
        },
        {
          title: "",
        },
        {
          title: "3.ఇది పితృస్థానం. తండ్రి, తాత, ముత్తాతల ప్రీత్యర్థం ఒక భోక్త.",
        },
        {
          title: "",
        },
        {
          title: "4.ఇది మాతామహస్థానం. తల్లి తండ్రి, తాత, ముత్తాతల ప్రీత్యర్థం ఒక భోక్త.",
        },
        {
          title: "",
        },
        {
          title: "5.ఇది సర్వ కారుణ్యస్థానం. తండ్రి తరపు బంధువుల, తల్లి తరపు బంధువుల, గురువుల, స్నేహితుల, తక్కినవారి ప్రీత్యర్థం ఒక భోక్త. ఈ విధంగా మీ పురోహితుని సూచనానుసారం ఈ పితృకార్యాన్ని హోమ, తర్పణ, పిండప్రదానాది విధులతో అత్యంత శ్రద్ధగా నిర్వహించాలి.",
        },
        {
          title: "",
        },
        {
          title: "ఎందుకంటే 'శ్రద్ధయా క్రియతే ధేయం శ్రాద్ధం' అన్నారు పెద్దలు. అయిదుగురు భోక్తలతో ఈ పితృయఙ్ఞం జరిపించే ఆర్థికస్థోమత లేని పక్షంలో కనీసం ఒక భోక్తతో అయినా ఈ క్రతువు జరపాలి గానీ మహాలయం పెట్టకుండా మాత్రం ఉండకూడదు.",
        },
        {
          title: "",
        },
        {
          title: "మనిషన్నాక బలహీనతలు సహజం. కనుక వివాహేతర సంబంధాల వల్ల జన్మించిన సంతానానికి మాత్రం ఈ పితృకార్యం చేసే అర్హత, అధికారం లేదు. శాస్త్రం ఆ వీలు కల్పించలేదు. కనుక మనకీ జన్మనిచ్చిన తల్లిదండ్రులకు విధివిధాన పితృకర్మలు నిర్వహించడం వారి పుత్రులుగా మన విధి, కర్తవ్యం, బాధ్యత. ఈ బాధ్యతను ప్రతి పుత్రుడు గుర్తించి, ఈ మహాలయ విధులు భక్తి, శ్రద్ధలతో నిర్వహించి పితృదేవతల ఆశీస్సులు అందుకుందాము.",
        },
        {
          title: "",
        },
        {
          title: <span class='highlight'>మహాలయ అమావాస్య పితృ దేవతలకు ప్రీతిపాత్రమైనది :</span> ,
        },
        {
          title: "",
        },
        {
          title: "సూర్యకిరణముల పరిపాక విశేషము వలన కాల భేదములేర్పడుతున్నాయి. తిధులు చంద్రునికి సంబంధించినవి. జాతక భాగంలో, రవిచంద్రులు ఉన్న స్థానములనుబట్టి, ఏ తిథినాడు ఆ జాతకుడు జన్మించాడో ఒక అవగాహన వస్తుంది. శుక్లపక్షంలో చంద్రుడు కృష్ణపక్షంలో రోజురోజూ తగ్గుదల పొంది, చివరకు పూర్తిగా క్షీణిస్తాడు. ఆ తిథియే అమావాస్య. భాద్రపద మాసంలో వచ్చే అమావాస్య, ఆశ్వయుజ అమావాస్యలు చెప్పుకోదగినవి. భాద్రపద అమావాస్యను 'మహాలయ అమావాస్య' అని, ఆశ్వీయుజ అమావాస్యని దీపావళి అమావాస్య అని పిలుస్తారు. ఈ రెండు అమావాస్యలు పితృదేవతలకు సంబంధించినవి.",
        },
        {
          title: "",
        },
        {
          title: "'ఆషాఢీ మవధిం కృత్వా పంచమం పక్షమ్మాతాః కాంక్షంతి పితరః క్లిష్టా అన్నమప్యన్వహం జలమ్' ఆషాడ పూర్ణిమ మొదలు అయిదవ పక్షమును అనగా ఆషాఢ కృష్ణపక్షం, శ్రావణ రెండు పక్షములు, భాద్రపద శుక్లపక్షం, వెరశి నాలుగు పక్షములు (పక్షం అంటే పదిహేను రోజులు) గడిచిన తరువాత వచ్చేది, అయిదవ పక్షం అదే 'మహాలయ పక్షము'. భాద్రపద బహుళ పాడ్యమి నుండి అమావాస్య వరకు 'మహాలయ పక్షము' అంటారు. చివరగా వచ్చే అమావాస్యను 'మహాలయ అమావాస్య' అంటారు.",
        },
        {
          title: "",
        },
        {
          title: "ఈ పక్షములో పితరులు అన్నాన్ని, ప్రతిరోజూ జలమును కోరుతారు. తండ్రి చనిపోయిన రోజున, మహాలయ పక్షములలో పితృతర్పణములు, యధావిధిగా శ్రాద్ధవిధులు నిర్వర్తిస్తే, పితృదేవతలంతా సంవత్సరమంతా తృప్తి చెందుతారు, తమ వంశాభివృద్ధిని గావిస్తారు. వారు ఉత్తమ గతిని పొందుతారు. ఈ విషయాలన్నీ నిర్ణయ సింధువు, నిర్ణయ దీపికా గ్రంథములు పేర్కొన్నాయి.",
        },
        {
          title: "",
        },
        {
          title: "భాద్రపద మాసంలో శుక్లపక్షం దేవపదము, కృష్ణపక్షం పితృపదము, అదే మహాలయ పక్షము. మహాలయమంటే అనగా పితృదేవతలకిది గొప్ప ఆలయము, పితృదేవతల యందు మనస్సు లీనమగుట, పుత్రులిచ్చు తర్పణాదులకు పితృదేవతలు తృప్తిని పొందుట అని అర్థములు. అమావాస్య అంతరార్థం 'అమా' అంటే 'దానితోపాటు', 'వాస్య' అంటే వహించటం. చంద్రుడు, సూర్యుడిలో చేరి, సూర్యుడితో పాటు వసించే రోజు కాబట్టి 'అమావాస్య' అన్నారు.",
        },
        {
          title: "",
        },
        {
          title: "సూర్యుడు స్వయం చైతన్యం. చంద్రుడు జీవుడే. మనస్సుకు అధిపతి. అదే చంద్రుని ఉపాధి. మనస్సు పరమ చైతన్యంలో లయమైతే, జీవుడికి జీవభావం పోయి దైవభావం సిద్ధిస్తుంది. అదే నిజమైన అమావాస్య. చంద్రమండలం ఉపరితలం మీద నివసించే పితృదేవతలకు, అమావాస్య తిధి మిట్టమధ్యాహ్నమవుతుంది. అందుకే భాద్రపద అమావాస్య రోజున, దీపావళి అమావాస్య రోజున పితృదేవతలు పుత్రులిచ్చే తర్పణములకు ఎదురు చూస్తూ ఉంటారని ధర్మగ్రంథాలు తెలుపుతున్నాయి.",
        },
        {
          title: "",
        },
        {
          title: <span class='highlight'>మత్స్యపురాణగాథ :</span> ,
        },
        {
          title: "",
        },
        {
          title: "పితృదేవతలు ఏడు గణములుగా ఉన్నారు. వారి మానవ పుత్రిక 'అచ్ఛోద'. పితృదేవతలు ఒక సరస్సును సృష్టించారు. ఆ సరస్సుకు పుత్రిక పేరు పెట్టారు. ఆ అచ్ఛోద సరస్సు తీరంలో తపస్సు చేసింది. పితృదేవతలు సంతుష్టులై ప్రత్యక్షమయ్యారు. వరము కోరుకోమన్నారు. ఆమె వారిలో 'మావసు'డను పితరుని కామ పరవశంతో వరునిగా కోరింది. యోగభష్ట్రురాలయింది. దేవత్వంపోయి, భూమి మీద కొచ్చింది. మావసుడు, అచ్చోదను కామించలేదు. కనుక అచ్ఛోద 'మావస్య' అనగా ప్రియురాలు అధీనురాలు కాలేకపోయింది. కనుక 'మావస్య' కాని ఆమె 'అమావస్య' లేక 'అమావాస్య' అయింది.",
        },
        {
          title: "",
        },
        {
          title: "తన తపస్సుచే పితరులను తృప్తినొందించిన  అమావాస్య అనగా అచ్ఛోద, పితరులకు ప్రీతిపాత్రమయింది. అందువలన పితృదేవతలకు అమావాస్య (అచ్ఛోద) తిథి యందు అర్పించిన తర్పణాది క్రియలు, అనంత ఫలప్రదము. ముఖ్యంగా సంతానమునకు క్షేమము, అభివృద్ధికరము. తప్పును తెలిసికొన్న అచ్ఛోది మరల తపోదీక్ష వహించింది. జననీ జనకులను ప్రేమానురాగాలను అందించి, మరణానంతరం కూడా వారి కోసం యథావిధిగా నైమిత్తిక కర్మల నాచరించి, పితృతర్పణాదులనిస్తే వారి ఋణం తీర్చుకున్న వాళ్లవుతారని పితరుల ఆశీస్సులతో వంశాభివృద్ధి జరుగుతుందని చెప్తోంది మహాలయ అమావాస్య.",
        },
        {
          title: "",
        },
        {
          title: <span class='highlight'>పితృకార్యం నియమాలు :</span> ,
        },
        {
          title: "",
        },
        {
          title: "ఇంటి ముందు ముగ్గు వేయకూడదు. ఇంటిలో కోడలు దీపం పెట్టాలి. ఉదయం అల్పాహారం తీసుకోవాలి ఉండగలిగితే పాలు తీసుకుని ఉండొచ్చు, మధ్యాహ్నం 12 గంటలకు మొదలవుతుంది కాబట్టి 5 నిమిషాలు ముందుగా సభ్యులంతా రావాలి. ఒక పంచ రెండు టవల్స్ తెచ్చుకోవాలి. మధ్యాహ్నం కాకికి కొంచెం అన్నం ప్లేట్ లో పెట్టి ఒక గ్లాసులో నీరు మన ఇంటి ముందు ఎక్కడైతే పెట్టుకోవచ్చు. రాత్రికి అల్పాహారం మాత్రమే తీసుకోవాలి.  స్త్రీ సాంగత్యం కూడదు.",
        },
        {
          title: "",
        },
        {
          title: "ఇవన్ని ఒక ఎత్తు అయితే ఈ పక్షం రోజులు మగవారు కటింగ్,షేవింగ్‌ చేసుకోరు. ఎందుకంటే ఈ దినాలను సంతాప దినాలుగా భావిస్తారు. ముఖ్యంగా పితృపక్షం ముగిసే వరకు ఏ శుభకార్యాలు, కొత్త పనులకు కూడా పూనుకోరు. అంటే కొత్త బట్టలు, వస్తువులు, బంగారం, ఏ ఇతర విలువైన వస్తువులను కొనుగోలు చేయరు. కొత్త ఇల్లు,వాహనాలు కూడా కొనుగోలు చేయరు.",
        },
        {
          title: "",
        },
      ],
      title: "మహాలయ అమావాస్య",
    },

    {
      CatID: [
        // {
        //   title: "<span class='highlight'>శివాలయంలో ప్రదక్షిణ చేయవలసిన విధానము",
        // },
        // {
        //   title: "",
        // },
        {
          title: "శివాలయంలో చేసే ప్రదక్షిణ, అన్ని దేవాలయాలలో చేసే ప్రదక్షిణకి భిన్నంగా ఉంటుంది. ఏ గుడిలోకి వెళ్ళినా సర్వ సాధారణంగా ప్రదక్షిణ చేస్తారు. కానీ శివాలయానికి ఓ ప్రత్యేకత ఉంది. ఇతర దేవాలయాలలో చేసిన విధంగా ఈశ్వరుని దేవాలయంలో ప్రదక్షిణ చేయకూడదు. శివాలయాల్లో ఏ విధంగా ప్రదక్షిణ చేయాలో లింగ పురాణం స్పష్టంగా వివరించింది. శివాలయంలో చేసే ప్రదక్షిణని చండీ ప్రదక్షిణం లేదా సోమసూత్ర ప్రదక్షిణ అంటారు. ఈ చండీ ప్రదక్షిణం చేయడం వలన ఎలాంటి ఫలితాలు పొందవచ్చో పురాణాల్లో వివరంగా పేర్కొన్నారు.",
        },
        {
          title: "",
        },
        {
          title: <span class='highlight'>లింగ పురాణం శివాలయంలో చేయవలసిన ప్రదక్షిణ గూర్చి ఈవిధంగా చెబుతోంది :</span> ,
        },
        {
          title: "",
        },
        {
          title: "శివాలయంలో ధ్వజస్థంభం వద్ద ప్రదక్షిణ ప్రారంభించి, ధ్వజస్థంభం నుండి చండీశ్వరుని వరకూ ప్రదక్షిణ చేసి చండీశ్వరుడిని దర్శించుకొని, అక్కడ నుండి మళ్లీ వెనక్కి తిరిగి ధ్వజస్థంభం దగ్గరకు వచ్చి ఒక్క క్షణం ఆగి మరలా ప్రదక్షిణ మొదలు పెట్టి సోమ సూత్రం (అభిషేక జలం బయటకు పోవు దారి) వరకు వెళ్లి, తిరిగి ధ్వజస్థంభం దగ్గరకు రావాలి. అలా వస్తే ఒక్క ప్రదక్షిణ పూర్తి అవుతుంది. వెనుదిరిగి నందిశ్వరుని చేరుకుంటే ఒక శివ ప్రదక్షిణ పూర్తి చేసినట్లు. ఈవిధంగా చేసే ప్రదక్షినకే చండీ ప్రదక్షిణం లేదా సోమసూత్ర ప్రదక్షిణ అని పేరు. శివ ప్రదక్షిణలో సోమసూత్రం దాటరాదు ఎందుకంటే ఆయనకు అభిషేకం చేసిన జలం సోమసూత్రం నుండి పోతుంది. అంతేకాక అక్కడ ప్రమధ గణాలు కొలువై ఉంటారు. అందుకే వారిని దాటితే శివుని కోపానికి గురి అవుతారు.",
        },
        {
          title: "",
        },
        {
          title: "ఈ విధంగా చేసే ప్రదక్షిణం పది వేల ప్రదక్షిణాలతో సమానమని లింగ పురాణంలో పేర్కొనబడింది. ఇలా మూడు ప్రదక్షిణలు చేయాలి. అయితే నందికి శివునికి మధ్యలో నడవకూడదు, ఎందుకంటే సదా నందీశ్వరుని చూపులు శివుని మీదే ఉంటాయి.",
        },
        {
          title: "",
        },
      ],
          title: "శివాలయంలో ప్రదక్షిణ చేయవలసిన విధానము",
    },

    {
      CatID: [
        // {
        //   title: "<span class='highlight'>హోమాలు - వాటి ప్రయోజనాలు",
        // },
        // {
        //   title: "",
        // },
        {
          title: <span class='highlight'>గణపతి హోమం :</span> ,
        },
        {
          title: "",
        },
        {
          title: "విఘ్నాలను తొలగించే విఘ్ననాయకుడు గణపతి. మానవులు ప్రారంభించే ప్రతి కార్యాల్లోనూ మొదటగా గణపతిని పూజించడం జరుగుతుంది. ప్రారంభించిన కార్యం ఎటువంటి ఆటంకాలు లేకుండా పూర్తి కావాలని కోరుతూ వినాయకుడిని పూజిస్తారు. జీవితంలో ఎదురయ్యే కష్టాలను, ప్రతికూల అంశాలను తొలగించడానికి వినాయకుడికి గణపతి హోమం నిర్వహిస్తాము. ఈ గణపతి హోమం చేయడం వలన విజయము, ఆరోగ్యము, సంపద కార్య సిద్ధి కలుగుతాయి. హిందూ ధర్మం ప్రకారం ఏ శుభకార్యం చేయాలన్నా మొదటగా గణపతి హోమంతోనే ప్రారంభించడం జరుగుతుంది. ఈ గణపతి హోమానికి అష్ట ద్రవ్యలు(8) రకాలు, దర్భ మొదలగునవి ఉపయోగించడం జరుగుతుంది.",
        },
        {
          title: "",
        },
        {
          title: <span class='highlight'>రుద్ర హోమం :</span> ,
        },
        {
          title: "",
        },
        {
          title: "పురాణ కథలను అనుసరించి రుద్ర అనునది శివునికి మరొక నామము. శివుడు లేదా రుద్రుని అనుగ్రహం కొరకు చేసే హోమాన్ని రుద్రహోమము అంటారు. ఈ హోమం చేయుట వలన శివుని అనుగ్రహం పొంది తద్వారా అపమృత్యు భయాలు తొలగింపబడి, దీర్ఘకాలిక అనారోగ్య సమస్యల నుండి విముక్తి పొంది శక్తి సంపన్నులు అవుతారు. దీర్ఘాయుష్షుని పొందడం జరుగుతుంది. మృత్యువు మీద విజయాన్ని సాధించడానికి కూడా ఈ రుద్ర హోమం చేస్తారు. ఏ వ్యక్తి అయితే రుద్ర హోమం చేస్తారో ఆ వ్యక్తి యొక్క జన్మ నక్షత్రం ఆధారంగా నిర్ణయించబడిన ముహూర్తానికి రుద్రహోమం జరపబడుతుంది. ఈ రుద్రహోమం అత్యంత శక్తివంతమైనది.",
        },
        {
          title: "",
        },
        {
          title: <span class='highlight'>చండీ హోమం :</span> ,
        },
        {
          title: "",
        },
        {
          title: "హిందూ పురాణాల ప్రకారం అత్యంత శక్తిస్వరూపిణి చండీ. జీవితంలో ఎదురయ్యే కష్టాలను తొలగించడానికి, ఆనందమైన జీవితాన్ని గడపడానికి, సిరిసంపదల కోసం చండి హోమం నిర్వహించడం జరుగుతుంది. చండీ హోమం నిర్వహించడం వలన జీవితంలో ఉన్న ప్రతికూల అంశాలన్నీ తొలగిపోతాయి. చండీ హోమం చేసేప్పుడు నవగ్రహాలను ఆవాహన చేసుకొని చేయడం జరుగుతుంది.చండీ హోమాన్ని ఎక్కువగా శుక్రవారం రోజు లేదా అష్టమి, నవములలో చేయడం శ్రేష్టం. సప్తశతిలో ఉన్నటువంటి 13 అధ్యాయాల ప్రకారంగా చండీహోమం చేసేందుకు 13 రకాల విభిన్నమైన పదార్థాలను వాడడం జరుగుతుంది.",
        },
        {
          title: "",
        },
        {
          title: <span class='highlight'>గరుడ హోమం :</span> ,
        },
        {
          title: "",
        },
        {
          title: "మానవుని శరీరాకృతి, గరుడుని ముఖము కలిగి శ్రీమహావిష్ణువు వాహనంగా పిలువబడే దైవ స్వరూపమే గరుడుడు. గరుడుడు అనంతమైన శక్తికి, జ్ఞానానికి స్వరూపం. గరుడార్, గరుడ భగవాన్ అని పిలిచుకొనే గరుడుడికి చేసే హోమమే గరుడ హోమం. సరైన విధివిధానాలతో కనుక గరుడ హోమం చేసినట్లయితే ఆకర్షణ శక్తి పెరగడం అలాగే అనేక విషయాల పట్ల, వ్యక్తుల పట్ల ఆధిపత్యాన్ని సాధించడం, శత్రువుల మీద విజయం, ప్రమాదాల నుంచి రక్షించబడడం, అన్ని శారీరక, మానసిక వ్యాధుల నుంచి ఉపశమనం మొదలగునవి లభిస్తాయి. అంతేకాకుండా ఈ గరుడ హోమం చేయడం వలన జ్ఞానము అలాగే జ్ఞాపకశక్తి వృద్ధి జరుగుతుంది.",
        },
        {
          title: "",
        },
        {
          title: <span class='highlight'>సుదర్శన హోమం :</span> ,
        },
        {
          title: "",
        },
        {
          title: "శ్రీమహావిష్ణుకు చెందిన అత్యంత శక్తివంతమైన ఆయుధమే సుదర్శన చక్రం. హిందూ పురాణాల ప్రకారం ఈ ఆయుధం చాలా శక్తివంతమైన ఆయుధం అవ్వడమే కాకుండా దైవిక శక్తి కలిగి ఉండి దుష్టశక్తులను సంహరిస్తుంది. మానవుని జీవితంలో లేదా కుటుంబంలో జరుగుతున్న ప్రతికూల అంశాలకు కారణమైన దుష్టశక్తుల నుండి రక్షింపబడడానికి, నరదృష్టి తొలగించడానికి ఈ సుదర్శన హోమం చేయడం జరుగుతుంది. ముఖ్యంగా గృహ ప్రవేశ సమయంలో మరియు మిగిలిన శుభకార్యాల సమయంలో కూడా సుదర్శన హోమం నిర్వహించబడుతుంది. హోమాగ్నికి అష్ట ద్రవ్యాలను సమర్పిస్తూ అత్యంత పవిత్రమైన సుదర్శన మంత్రాన్ని జపిస్తూ ఈ హోమం చేయడం జరుగుతుంది. మానవుని జన్మ నక్షత్రం ఆధారంగా నిర్ణయింపబడిన ముహూర్తాన్ని అనుసరించి ఈ హోమం చేయడం జరుగుతుంది.",
        },
        {
          title: "",
        },
        {
          title: <span class='highlight'>మన్యుసూక్త హోమం :</span> ,
        },
        {
          title: "",
        },
        {
          title: "వేదాల ననుసరించి మాన్యు అనగా ఆగ్రహం అని, లేదా మరొక అర్థంలో తీవ్రమైన భావావేశము అని చెప్పబడుతుంది. మాన్యు దేవుడి ఆశీస్సుల కోసం చేసే హోమము మన్యుసూక్త పాశుపత హోమం. ఈ హోమాన్ని ప్రధానంగా శత్రు సంహారం కోసం చేయడం జరుగుతుంది. కోర్టు కేసుల లాంటి దీర్ఘకాలిక సమస్యల నుండి విముక్తి కోసం కూడా ఈ హోమాన్ని చేస్తారు. ఈ హోమాన్ని శనివారం చేయడం ద్వారా ఉత్తమమైన ఫలితాలు పొందడం జరుగుతుంది.",
        },
        {
          title: "",
        },
        {
          title: <span class='highlight'>లక్ష్మీ కుబేర పాశుపతహోమం :</span> ,
        },
        {
          title: "",
        },
        {
          title: "హిందూ ధర్మానుసారంగా సంపదకి దేవతలుగా లక్ష్మీ దేవిని, కుబేరున్ని పూజిస్తాము.జీవితంలో ఆర్థికంగా కష్టాలను ఎదుర్కొంటున్న వారి కోసం సూచింపబడేదే లక్ష్మీ కుబేర పాశుపతహోమం. జీవితంలో ఆర్థిక వృద్ధి, సిరి సంపదల కొరకు లక్ష్మీదేవిని అలాగే కుబేరుడిని కూడా ఈ హోమంలో పూజించడం జరుగుతుంది. ప్రధానంగా ఈ హోమాన్ని శుక్రవారం రోజున చేయడం శ్రేష్టం. ఎందుకనగా శుక్రవారాన్ని లక్ష్మీ వారముగా పరిగణిస్తాము. కనుక హోమం చేసుకునే వ్యక్తి యొక్క జన్మ నక్షత్రాన్ని అనుసరించి నిర్ణయించబడిన ముహూర్తానికి ఈ హోమం చేయబడును. ఈ హోమం చేయడానికి కమలాలని వాడడం జరుగుతుంది.",
        },
        {
          title: "",
        },
        {
          title: <span class='highlight'>మృత్యుంజయ పాశుపత హోమం :</span> ,
        },
        {
          title: "",
        },
        {
          title: "మరణం నుంచి విజయాన్ని పొందడమే మృత్యుంజయం. పేరులో ఉన్నట్టుగానే మృత్యువుపైన విజయాన్ని సాధించడం కోసం మృత్యుంజయ పాశుపత హోమం నిర్వహిస్తారు. ప్రాణ హాని అలాగే తీవ్రమైన అనారోగ్య సమస్యల నుండి విముక్తి పొందడం కోసం ఈ హోమం చేయడం జరుగుతుంది. దుష్టశక్తులను అదుపుచేసి, సంహరించే భూత నాథుడిగా పిలవబడే ఆ శివుడిని ప్రసన్నం చేసుకోవడం కోసం ఈ హోమం చేస్తారు. ఈ హోమం చేసుకునేవారు హోమానికి సంబంధించిన మంత్రాన్ని 21సార్లు జపించవలసి ఉంటుంది. ఈ హోమం చేయడానికి కావాల్సిన ప్రధాన వస్తువులు దర్భ, అమృత మూలిక. దీర్ఘాయుష్షును కోరుతూ హోమము చేసే వారి జన్మదినం రోజున ఈ హోమాన్ని నిర్వహిస్తారు.",
        },
        {
          title: "",
        },
        {
          title: <span class='highlight'>నవదుర్గ పాశుపత హోమం :</span> ,
        },
        {
          title: "",
        },
        {
          title: "భక్తుల చేత దుర్గామాత నవదుర్గగా పూజింపబడుతుంది. జట దుర్గ, శాంతి దుర్గ, శూలిని దుర్గ, శబరి దుర్గ, లవణ దుర్గ, అసురి దుర్గ, దీప దుర్గా, వన దుర్గ, మరియు జ్వాలా దుర్గ. దుర్గామాత యొక్క ఈ తొమ్మిది రూపాలను పూజించడానికి చేసే హోమమే ఈ నవదుర్గ పాశుపత హోమం.ఈ హోమం చేయడం వలన దుష్ట శక్తుల నుంచి విముక్తి, శాంతి, సంపద, ఆరోగ్యం, ఆయుష్యు, సంతానం, విద్య మొదలైనవి లభించి ప్రతికూలమైన ఆలోచనలు, ప్రతికూలమైన అంశాలను నుండి విముక్తి కలిగుతుంది.",
        },
        {
          title: "",
        },
      ],
      title: "హోమాలు - వాటి ప్రయోజనాలు",
    },
  ],
};
export default importantinfoData;
