import React, { useState } from 'react'
// import IsconFest from '../../assets/jsons/Telugu/IsconFest'
import Header from '../header'
import Navbar from '../navbar'
import Footer from '../footer'
import LocalData from '../../assets/jsons/Telugu/festivals_2025'
import Pandugalu from '../pandugalu'


const Pandugaluu = () => {
  // const festivals = LocalData.FestivalUpdated[0][0].day;
  // const [leftSticky, setLeftSticky] = useState(true);
  // const [rightSticky, setRightSticky] = useState(true);
  return (
    <div>
      {/* <Header />
      <Navbar /> */}
      <div className="container-fluid" style={{ background: '#ffe6d0' }}>
        {/* <div className="row">
          <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
          <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-3"></div>
          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
           onMouseEnter={() => {
            setLeftSticky(false);
            setRightSticky(true);
          }}
          style={{
            overflowY: leftSticky ? 'hidden' : 'auto',
            height: '80vh',
            position: leftSticky ? 'sticky' : 'static',
            top: 0,
          }}
          >
            <div>
            {/* {festivals.map((festival, index) => (
          <li key={index}>
            <strong>{festival.Date}:</strong> {festival.Festival}
          </li>
        ))} */}
             {/* {festivals.map((festival, index) => (
                <>
                <div key={index} className="card-header card mb-3"
                  style={{
                    color: "#FFFFFF",
                    backgroundColor: '#754213', // Active and inactive background colors
                  }}>
                  <div>{festival.Date}</div>
                </div><div style={{textAlign:'center',marginBottom:'5px'}}>{festival.Festival}</div>
                </>
          
              ))}
            </div>

          </div>
          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 p-3"></div>
          <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
        </div> */} 
        <Pandugalu/>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default Pandugaluu

